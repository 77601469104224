import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useTabId, useUser } from "../../../hooks/user";
import DeleteIcon from "../../../assets/flag-icons/delete-placeholder.png";
import {
  activityScopes,
  alreadyReadNote,
  getLabelScope,
  handleMarkAsRead,
} from "../../../helpers/activitiesStream";
import { reduxState } from "../../../api/types/dbTables";
import { useDispatch } from "react-redux";
import { DELETE_ACTIVITY_STREAM } from "../../../actions/types";
import PaperNote from "./PaperNote";
import { useCurrentShipment } from "../../../hooks/shipments";
import { deleteDoc } from "firebase/firestore";
import { ActivityStyled } from "./styles";
import { cx } from "@emotion/css";
import TimerLoader from "../../Loaders/TimerLoader";

// import { trackEvent } from "../../helpers/analytics";
const fadeInit = {
  top: -100,
  opacity: 0,
};

const fadeActive = {
  top: 0,
  opacity: 1,
};
const slideInit = {
  top: -120,
};
const slideActive = {
  top: 0,
};

function Activity({
  activity,
  users,
  onEditNote,
  openModalNotes,
  salesOrder,
  purchaseOrder,
  handleOpenModal,
  addMentionBackdrop = () => {},
  currentNote,
  onReplyActivity,
  onShowThread,
  noteThread,
  hasThreadPermission,
  focusActivity,
  activityIndex,
}) {
  const isNewDate =
    activityIndex === 0 &&
    moment().diff(activity.creationDate, "seconds") <= 45;
  const [timer, setTimer] = useState("");
  const user = useUser();
  const currentShipment = useCurrentShipment({ purchaseOrder });
  const dispatch = useDispatch();
  const [styleAnimated, setStyleAnimated] = useState(
    isNewDate ? fadeInit : slideInit
  );
  const [userName] = useState(
    `@${
      users.find((user) => user.id === activity.user)
        ? users.find((user) => user.id === activity.user).displayName
        : "userdefault"
    }`
  );
  const currentTabId = useTabId();
  const [userEditId, setUserEditId] = useState(
    activity.tabId === currentTabId ? activity.enableToEdit : false
  );
  const [countingSeconds, setCountingSeconds] = useState(
    userEditId
      ? (moment(activity.updateDate || activity.creationDate)
          .add(7, "seconds")
          .valueOf() -
          moment.now().valueOf()) /
          1000
      : -1
  );
  const [newClassToDessapear, setNewClassToDissapear] = useState(
    "option-to-edit-activity-container"
  );

  useEffect(() => {
    if (activityIndex >= 0) {
      setStyleAnimated(isNewDate ? fadeActive : slideActive);
    }
  }, []);

  useEffect(() => {
    if (userEditId) {
      if (!openModalNotes && !timer) {
        setTimer(
          setInterval(() => {
            setCountingSeconds((t) => (t === 6 ? 6 - 1 : t - 1));
          }, 1000)
        );
      } else if (openModalNotes && timer && activity.id === currentNote.id) {
        clearInterval(timer);
        setCountingSeconds(6);
        setTimer(false);
      }
    }
  }, [openModalNotes]);

  useEffect(() => {
    if (userEditId && !openModalNotes && timer && countingSeconds <= -1) {
      setNewClassToDissapear("option-to-edit-activity-container dissapear");
      clearInterval(timer);
      setTimeout(() => {
        setUserEditId(false);
      }, 1000);
    }
  }, [countingSeconds]);

  useEffect(() => {
    if (!activity.enableToEdit) {
      setNewClassToDissapear("option-to-edit-activity-container dissapear");
      clearInterval(timer);
      setTimeout(() => {
        setUserEditId(false);
      }, 800);
    }
  }, [activity.enableToEdit]);

  function getSecond(second) {
    const parseSecond = second < 0 ? 0 : second;
    return second > 9 ? parseSecond : "0" + parseSecond;
  }

  const memoizedActivity = useMemo(() => {
    return (
      <PaperNote
        activity={activity}
        purchaseOrder={purchaseOrder}
        salesOrder={salesOrder}
        users={users}
        alreadyReadNote={alreadyReadNote({ activity, user })}
        user={user}
        username={userName}
        handleMarkAsRead={() =>
          handleMarkAsRead({
            activity,
            currentShipment,
            purchaseOrder,
            salesOrder,
            user,
          })
        }
        getLabelScope={getLabelScope}
        handleOpenModal={handleOpenModal}
        addMentionBackdrop={addMentionBackdrop}
        onReplyActivity={onReplyActivity}
        onShowThread={onShowThread}
        noteThread={noteThread}
        hasThreadPermission={hasThreadPermission}
        focusActivity={focusActivity}
      />
    );
  }, [
    activity.id,
    activity.detail,
    activity.scope,
    activity.lockNote,
    activity.userMentions,
    activity.numberOfReplies,
    purchaseOrder,
    salesOrder,
    currentShipment,
    noteThread,
    hasThreadPermission,
    focusActivity,
  ]);
  const seconds = parseInt(countingSeconds);
  const editNote = () => {
    if (userEditId === user.id && activity.enableToEdit) {
      onEditNote(activity);
    }
  };
  const handleEraseNote = (ev) => {
    if (userEditId === user.id && activity.enableToEdit) {
      deleteDoc(activity.ref);
      dispatch({
        type: DELETE_ACTIVITY_STREAM,
        table:
          activity.scope === activityScopes.PURCHASE_ORDER ||
          activity.scope === activityScopes.PURCHASE_QUOTE
            ? reduxState.PURCHASE_ORDER_ACTIVITIES
            : activity.scope === activityScopes.SALES_ORDER ||
              activity.scope === activityScopes.QUOTE
            ? reduxState.SALES_ORDER_ACTIVITIES
            : reduxState.SHIPMENT_ACTIVITIES,
        payload: activity,
        documentId:
          activity.scope === activityScopes.PURCHASE_ORDER ||
          activity.scope === activityScopes.PURCHASE_QUOTE
            ? activity.purchaseOrderId
            : activity.scope === activityScopes.SALES_ORDER ||
              activity.scope === activityScopes.QUOTE
            ? activity.salesOrderId
            : activity.shipmentId,
      });
      ev.stopPropagation();
    }
  };
  return (
    <ActivityStyled
      id={activity.id}
      key={activity.id}
      className={cx(
        "context-" + activity.id,
        activityIndex >= 0 ? "animated" : ""
      )}
      style={activityIndex >= 0 ? styleAnimated : {}}
    >
      {memoizedActivity}

      {userEditId === user.id && (
        <li className={newClassToDessapear}>
          <div style={{ width: 50, padding: "8px 0" }}></div>
          <div className={"activity-to-edit"}>
            <div
              className="activity-edit-and-delete-option"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TimerLoader
                onEditTask={editNote}
                seconds={seconds}
                userEditId={userEditId}
                userId={user.id}
              />
              <label className="edit-note" onClick={editNote}>
                Edit Note
              </label>
              <label style={{ margin: 0, padding: "0 8px", color: "gray" }}>
                00:{getSecond(seconds)} to edit
              </label>
            </div>
            <div>
              <img
                src={DeleteIcon}
                alt="delete"
                className="delete-note-activity-stream"
                onClick={handleEraseNote}
              />
            </div>
          </div>
        </li>
      )}
    </ActivityStyled>
  );
}

export default Activity;
