import React, { useState, useEffect } from "react";
import { GanttChartCalendarStyled, GanttChartPopover } from "./styles";
import {
  dayHeight,
  dayWidth,
  GENERAL_PERMISSION_VALUE,
} from "../../helpers/constants";
import {
  getWindowHeight,
  getWindowWidth,
  getFlagViewOptions,
  viewOptionLabels,
} from "../../helpers/timelineCalendar";
import { MultiGrid } from "react-virtualized";
import TaskPopover from "./TaskPopover";
import scrollDisclosureTriangle from "../../assets/flag-icons/scroll-disclosure-triangle.svg";
import CellRendererItem from "./CellRendererItem";
import { sortObjectsBy } from "../../helpers/helpers";
import moment from "moment";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { cx } from "@emotion/css";

const fixedRowCount = 3;

function TimeLineCalendar({
  milestones,
  tasks,
  flagDates,
  ghostTasks,
  onChangeTaskDuration,
  onDropTask,
  companyUsers,
  tableSize,
  onChangeTableSize,
  viewOptionsArray,
  onChangeViewOptions,
  isOpenAllSections,
  onChangeOpenAllSection,
  canRedo,
  canUndo,
  undoTasksState,
  redoTasksState,
  onChangeMilestone,
  onDeleteMilestone,
  onAddNewMilestone,
  taskPhases,
  onChangeTaskPhase,
  allTasks,
  onChangeTask,
  onHoverTaskIds = null,
  onCloseMilestone,
  onMoveRemainingTasks,
  selectedShipments,
  shipments,
  purchaseOrders,
  onMouseEnterInTaskDescription,
  filteredTasks,
  salesOrder,
}) {
  const [taskOnHover, setTaskOnHover] = useState({
    ids: [],
    verticalIndicator: false,
  });
  const [userOnHover, setUserOnHover] = useState(null);
  const flagViewOptions = getFlagViewOptions(viewOptionsArray);
  const [jumpButtons, setJumpButtons] = useState({
    left: false,
    right: true,
  });
  const isAllowed = useIsAllowedFunction();
  const isAbleToModifyTaskDueDate = isAllowed(
    GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES
  );

  const hasPermissionToReassign = isAllowed(
    GENERAL_PERMISSION_VALUE.REASSIGN_TASK
  );

  useEffect(() => {
    if (!onHoverTaskIds) {
      return setTaskOnHover({ ids: [], verticalIndicator: false });
    }
    return setTaskOnHover({ ids: onHoverTaskIds, verticalIndicator: false });
  }, [onHoverTaskIds]);

  useEffect(() => {
    if (!userOnHover) {
      setTaskOnHover({
        ids: [],
        verticalIndicator: false,
      });
    } else if (userOnHover) {
      const userTaskIds = tasks
        .filter(
          (task) =>
            task.assignedTo === userOnHover && task.status !== "complete"
        )
        .map((task) => task.id);
      setTaskOnHover({ ids: userTaskIds, verticalIndicator: false });
    }
    return;
  }, [userOnHover]);

  const open = !!taskOnHover.id;

  function verifyIsToUpdate({ task, taskOnHover, columnIndex }) {
    if (
      (taskOnHover && task && taskOnHover.ids.includes(task.id)) ||
      (taskOnHover &&
        taskOnHover.verticalIndicator &&
        moment(flagDates[columnIndex]).startOf("day").valueOf() >=
          moment(taskOnHover.startDate).startOf("day").valueOf() &&
        moment(flagDates[columnIndex]).startOf("day").valueOf() <=
          moment(taskOnHover.finishDate).startOf("day").valueOf())
    ) {
      return true;
    }
    if (taskOnHover && task && taskOnHover.id === task.dependency) {
      return true;
    }
    return false;
  }

  function cellRenderer({ columnIndex, key, rowIndex, style }) {
    const task = tasks.sort(sortObjectsBy("numberIndex", false))[rowIndex - 3];
    return (
      <CellRendererItem
        key={key}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        style={style}
        allTasks={allTasks}
        canRedo={canRedo}
        canUndo={canUndo}
        companyUsers={companyUsers}
        flagDates={flagDates}
        flagViewOptions={flagViewOptions}
        ghostTasks={ghostTasks}
        isOpenAllSections={isOpenAllSections}
        milestones={milestones}
        onAddNewMilestone={onAddNewMilestone}
        onChangeMilestone={onChangeMilestone}
        onChangeOpenAllSection={onChangeOpenAllSection}
        onChangeTableSize={onChangeTableSize}
        onChangeTask={onChangeTask}
        onChangeTaskDuration={onChangeTaskDuration}
        onChangeTaskPhase={onChangeTaskPhase}
        onChangeViewOptions={onChangeViewOptions}
        onCloseMilestone={onCloseMilestone}
        onDeleteMilestone={onDeleteMilestone}
        onDropTask={(taskId, offset) => {
          onDropTask(taskId, offset);
          setTaskOnHover({ verticalIndicator: false, ids: [] });
        }}
        onMoveRemainingTasks={onMoveRemainingTasks}
        redoTasksState={redoTasksState}
        tableSize={tableSize}
        taskOnHover={taskOnHover}
        taskPhases={taskPhases}
        tasks={tasks}
        undoTasksState={undoTasksState}
        onTaskOnHover={setTaskOnHover}
        onUserOnHover={setUserOnHover}
        isToUpdate={verifyIsToUpdate({ task, taskOnHover, columnIndex })}
        viewOptionsArray={viewOptionsArray}
        selectedShipments={selectedShipments}
        onMouseEnterInTaskDescription={onMouseEnterInTaskDescription}
        isAbleToModifyTaskDueDate={isAbleToModifyTaskDueDate}
        hasPermissionToReassign={hasPermissionToReassign}
        filteredTasks={filteredTasks}
        orders={{ salesOrder, purchaseOrders, shipments }}
      />
    );
  }

  function handleScrollToRight(ev) {
    const elements = document.getElementsByClassName(
      "ReactVirtualized__Grid__innerScrollContainer"
    );
    const element = elements[3];
    element.parentElement.scrollTo({
      left: element.clientWidth,
      top: element.clientHeight,
      behavior: "smooth",
    });
  }

  function handleScrollToLeft() {
    const elements = document.getElementsByClassName(
      "ReactVirtualized__Grid__innerScrollContainer"
    );
    const element = elements[3];
    element.parentElement.scrollTo({
      left: 0,
      behavior: "smooth",
      top: 0,
    });
  }

  function getCalendarContainerHeight() {
    const POBadgeContainerElement = document.getElementById(
      "purchase-order-badges-container"
    );
    if (!POBadgeContainerElement) {
      return 0;
    }
    return POBadgeContainerElement.clientHeight;
  }

  return (
    <GanttChartCalendarStyled
      id="canlendar-main-container"
      className={"calendarContainer"}
    >
      {flagViewOptions[viewOptionLabels.JUMP_TO_THE_END_BUTTONS] &&
        jumpButtons.right && (
          <div className={"scrollToRightButtonContainer"}>
            <img
              className="img-scroll-to-right"
              src={scrollDisclosureTriangle}
              alt="scroll-to-right"
              onClick={handleScrollToRight}
            />
          </div>
        )}
      {flagViewOptions[viewOptionLabels.JUMP_TO_THE_END_BUTTONS] &&
        jumpButtons.left && (
          <div className={"scrollToLeftButtonContainer"}>
            <img
              className="img-scroll-to-left"
              src={scrollDisclosureTriangle}
              alt="scroll-to-left"
              onClick={handleScrollToLeft}
            />
          </div>
        )}
      {open && (
        <GanttChartPopover
          id="mouse-over-popover"
          className="ganttChartPopover"
          classes={{
            paper: cx("calendarPaper", `paper_${taskOnHover.type}`),
          }}
          anchorPosition={{
            top: 36,
          }}
          open={open}
          anchorReference="anchorPosition"
          disableRestoreFocus
          slotProps={{
            paper: {
              style: {
                borderColor: taskOnHover.color,
              },
            },
          }}
        >
          <TaskPopover
            task={taskOnHover}
            companyUsers={companyUsers}
            predecesorTask={tasks.find(
              (task) => taskOnHover.dependency === task.id
            )}
            shipments={shipments}
            purchaseOrders={purchaseOrders}
          />
        </GanttChartPopover>
      )}
      <MultiGrid
        cellRenderer={cellRenderer}
        columnCount={flagDates.length}
        columnWidth={dayWidth}
        height={getWindowHeight(getCalendarContainerHeight())}
        rowCount={tasks.length + fixedRowCount}
        rowHeight={dayHeight}
        width={getWindowWidth()}
        fixedRowCount={fixedRowCount}
        fixedColumnCount={tableSize}
        data1={tasks}
        data2={taskOnHover}
        classNameBottomRightGrid={"gridVirtualized"}
        classNameTopRightGrid={"gridHeaderCalendarDate"}
        classNameBottomLeftGrid={"gridTableTask"}
        flagViewOptions={flagViewOptions}
        enableFixedColumnScroll
        onScroll={(params) => {
          const { clientWidth, scrollLeft, scrollWidth } = params;
          const scrollPosition = clientWidth + scrollLeft;
          setJumpButtons({
            left: scrollLeft <= 30 ? false : true,
            right:
              scrollLeft <= 30
                ? true
                : scrollPosition >= scrollWidth - 30
                ? false
                : true,
          });
        }}
      />
    </GanttChartCalendarStyled>
  );
}

export default TimeLineCalendar;
