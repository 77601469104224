import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { sortObjectsBy } from "../../helpers/helpers";
import { SettingScreenBox, SettingsPopover } from "./styles";
import {
  POHeaderSetup,
  columnHeaderSetup,
  taskHeaderSetup,
  SOHeaderSetup,
  orderByPhasePOSetup,
  shipmentHeaderSetup,
  expensesHeaderSetup,
} from "../../helpers/screenHeaderSetup";
import TooltipTD from "../Tooltip/TooltipTD";
import { SettingsActiveIcon } from "../../helpers/iconSvgPath";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Button, Checkbox, ListItemText, MenuItem } from "@mui/material";
import { cx } from "@emotion/css";

export default function MultipleSelect({
  items,
  onChange,
  type,
  onClose = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const indexAllowed = [];
    items.forEach((item, index) => {
      if (!item.unmovable && !item.disabledDraggable) {
        indexAllowed.push(index);
      }
    });
    const minRange = Math.min(...indexAllowed);
    const maxRange = Math.max(...indexAllowed);
    let unmovableColumns = minRange;

    let itemsCpy = [...items];
    const { destination, source } = result;
    const destinationIndex = destination.index + unmovableColumns;
    const sourceIndex = source.index + unmovableColumns;
    if (destinationIndex === sourceIndex) {
      return;
    }
    const [sourceElement] = itemsCpy.splice(sourceIndex, 1);
    const targetIndex =
      destinationIndex < minRange
        ? minRange
        : destinationIndex > maxRange
        ? maxRange
        : destinationIndex;

    itemsCpy.splice(targetIndex, 0, sourceElement);
    const updatedItems = itemsCpy.map((item, index) => ({ ...item, index }));
    onChange(updatedItems);
  };

  function onChangeAsDefault(items, newItems) {
    let itemsCpy = [...items];
    newItems.forEach((item) => {
      itemsCpy = itemsCpy.map((itemCpy) => {
        if (itemCpy.name === item.name) {
          return {
            ...itemCpy,
            index: item.index,
            enabled: item.enabled,
            unmovable: item.unmovable || false,
          };
        }
        return itemCpy;
      });
    });
    onChange(itemsCpy);
  }

  function handleRestoreAsDefault(ev) {
    console.log("HANDLE RESTORE AS DEFAULT:: ", type);
    switch (type) {
      case columnHeaderSetup.PO_HEADER_SETUP:
        return onChangeAsDefault(items, [...POHeaderSetup]);
      case columnHeaderSetup.SO_HEADER_SETUP:
        return onChangeAsDefault(items, [...SOHeaderSetup]);
      case columnHeaderSetup.TASK_HEADER_SETUP:
        return onChangeAsDefault(items, [...taskHeaderSetup]);
      case columnHeaderSetup.ORDER_BY_PHASE_PO_SETUP:
        return onChangeAsDefault(items, [...orderByPhasePOSetup]);
      case columnHeaderSetup.SHIPMENT_HEADER_SETUP:
        return onChangeAsDefault(items, [...shipmentHeaderSetup]);
      case columnHeaderSetup.EXPENSES_HEADER_SETUP:
        return onChangeAsDefault(items, [...expensesHeaderSetup]);
      default:
        break;
    }
    ev.preventDefault();
    ev.stopPropagation();
  }
  const menuItems = items
    .sort(sortObjectsBy("index", false))
    .filter((item) => item.filter);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <SettingScreenBox
      id="list-view-table-content-selectcolumns"
      className={"multiSelectContainer"}
      onClick={handleClick}
    >
      <TooltipTD
        label="Column settings"
        hasArrow={false}
        style={{ display: "flex" }}
      >
        <Button color="inherit" style={{ minWidth: 36 }}>
          <SettingsActiveIcon svgClass={cx("gearClose", { gearOpen: open })} />
        </Button>
      </TooltipTD>
      {open && (
        <SettingsPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          classes={{ paper: "paperPopover" }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-1">
              {(provided, snapshot) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {menuItems.map((item, index) => (
                      <Draggable
                        key={item.index + index}
                        draggableId={item.index.toString()}
                        index={index}
                        isDragDisabled={
                          item.unmovable || item.disabledDraggable
                        }
                      >
                        {(provided) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <MenuItem
                                key={item.index}
                                value={item}
                                classes={{ root: "menuItemOption" }}
                                style={{ display: item.styles.display }}
                                id={item.label}
                                onClick={(ev) => {
                                  let itemsCpy = [...items];
                                  itemsCpy = itemsCpy.map((el) => {
                                    if (el.label === item.label) {
                                      return { ...el, enabled: !item.enabled };
                                    } else {
                                      return el;
                                    }
                                  });
                                  onChange(itemsCpy);
                                  ev.stopPropagation();
                                }}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={item.enabled}
                                  className="checkBoxSelect"
                                />
                                <ListItemText
                                  className="listItemTextLabel"
                                  primary={item.label.replace(/\s\s+/g, " ")}
                                />
                                <MenuIcon
                                  className="imgListItemIconOrderPlaceholder"
                                  style={
                                    item.disabledDraggable
                                      ? { display: "none" }
                                      : {}
                                  }
                                />
                              </MenuItem>
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                    <div
                      className="defaultColumnsContainer"
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                      }}
                      style={snapshot.isDraggingOver ? { marginTop: 46 } : {}}
                    >
                      <span onClick={handleRestoreAsDefault}>
                        RESTORE DEFAULTS
                      </span>
                    </div>
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </SettingsPopover>
      )}
    </SettingScreenBox>
  );
}
