import React from "react";
import reassignedIcon from "../../assets/flag-icons/reassigned-icon.svg";
import styles from "./styles.module.scss";

function Badge({ className, isVoid }) {
  return (
    <div className={styles[className]}>
      <img src={reassignedIcon} alt={"voidedIcon"} />
    </div>
  );
}

export default Badge;
