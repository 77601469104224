import React from "react";
import PropTypes from "prop-types";
import MaximizeIcon from "@mui/icons-material/Maximize";
import { getUserAvatar } from "../../helpers/users";
import { activityScopes } from "../../helpers/activitiesStream";
import { PO_STATUS } from "../../helpers/constants";
import { VoidLabel } from "../../helpers/iconSvgPath";
import { Menu, MenuItem } from "@mui/material";

function NavPicker({
  anchorEl,
  open,
  onClose,
  list,
  scope,
  selectedId,
  handleDateActivities = () => {},
  user,
  onChangeSalesOrder = () => {},
  onClickAction,
  navStyles = {},
  hiddenAvatar = false,
  sizeNumber,
  sizeSubtitle,
}) {
  const hasMention = list.some((order) => {
    const userMentions = order.userMentions || {};
    return userMentions[user.id];
  });
  const getMessageIcon = (item) => {
    const hasMention = item.userMentions[user.id];
    if (hasMention) {
      return (
        <div
          onClick={handleDateActivities}
          style={{ minWidth: !hiddenAvatar ? 32 : 5 }}
        >
          {!hiddenAvatar &&
            getUserAvatar({
              user,
              styles: {
                width: 20,
                height: 20,
                outline: "2px solid #000",
                fontSize: 10,
              },
              hasToolTip: false,
            })}
        </div>
      );
    } else {
      return <span style={{ minWidth: !hiddenAvatar ? 32 : 5 }} />;
    }
  };

  return (
    <Menu
      open={open}
      style={{ ...navStyles }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      getContentAnchorEl={null}
      classes={{ paper: "paper-menu-container", list: "list-menu-container" }}
      className="Nav-picker"
      onClose={() => onClose()}
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      onChange={() => {}}
    >
      {list.map((item) => {
        const selectedStyle = item.id === selectedId ? "-selected" : "";
        return (
          <MenuItem
            key={item.id + "navTitle"}
            className={"list-navpick" + selectedStyle}
            onClick={() => {
              if (onClickAction) {
                onClickAction(item.id, item.scope);
                return;
              }
              if (scope === activityScopes.SALES_ORDER) {
                onChangeSalesOrder(item);
                onClose();
                return;
              }
              const itemElement = document.getElementById(scope + item.id);
              if (itemElement) {
                itemElement.click();
              }
              onClose();
            }}
            selected={item.id === selectedId}
          >
            {scope !== activityScopes.SALES_ORDER &&
              hasMention &&
              getMessageIcon(item)}

            <span
              className="item-listnumber"
              style={{
                marginRight: item.divider ? 0 : 20,
                width: sizeNumber ? sizeNumber * 8.5 + 20 : 40,
              }}
            >
              {item.name}
            </span>
            {item.divider && <MaximizeIcon className="item-divider" />}
            <span
              className="item-subtitle"
              style={{
                width: sizeSubtitle ? sizeSubtitle * 9.5 + 20 : 40,
              }}
            >
              {item.subTitle}
            </span>
            {item.status === PO_STATUS.VOIDED && <VoidLabel />}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

NavPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NavPicker;
