import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import CustomButton from "../Buttons/CustomButton";
import { sortObjectsBy } from "../../helpers/helpers";
import IntlMessages from "../../util/IntlMessages";
import AutocompleteUsers from "../TextFields/AutocompleteUsers";
import AutoCompleteUser from "../../api/model/AutoCompleteUser.model";

function UserPermissionsModal({
  open,
  onClose,
  users = [],
  value = [],
  onSave = () => {},
}) {
  const [usersSelected, setUsersSelected] = useState([]);

  useEffect(() => {
    if (open) {
      setUsersSelected(value);
    }
  }, [open]);

  function handleChange(value, lastValue) {
    setUsersSelected([...value]);
  }

  function save() {
    onSave(usersSelected);
    setUsersSelected([]);
    onClose();
  }

  function handleClose() {
    setUsersSelected([]);
    onClose();
  }
  return (
    <CustomModal
      header={
        <IntlMessages
          id={"components.userManagement.userPermissionsModal.addUserRoles"}
        />
      }
      isOpen={open}
      onClose={handleClose}
      isCentered
      bodyStyles={{
        width: 650,
      }}
    >
      <AutocompleteUsers
        options={users
          .sort(sortObjectsBy("displayName", false))
          .map((user) => ({
            ...new AutoCompleteUser({
              value: user.id,
              label: user.displayName,
              avatar: user.avatar,
              firstName: user.firstName,
              lastName: user.lastName,
            }),
          }))}
        onChange={handleChange}
        value={usersSelected
          .sort(sortObjectsBy("displayName", false))
          .map((user) => ({
            ...new AutoCompleteUser({
              value: user.id,
              label: user.displayName,
              avatar: user.avatar,
              firstName: user.firstName,
              lastName: user.lastName,
            }),
          }))}
        classes={{
          input: "input",
          inputFocused: "input-focused",
          paper: "paper",
          chip: "chip",
        }}
      />
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <CustomButton onClick={save} type="success">
          {"generic.text.save"}
        </CustomButton>
        <CustomButton
          onClick={() => {
            setUsersSelected([]);
            onClose();
          }}
          type="cancel"
        >
          {"generic.text.cancel"}
        </CustomButton>
      </div>
    </CustomModal>
  );
}

export default UserPermissionsModal;
