import React from "react";
import { LegendContainer } from "./styles";
import GreenDiamond from "../../assets/flag-icons/green_diamond.svg";
import LateIcon from "../../assets/flag-icons/red_diamond.svg";
import LateIconYellow from "../../assets/flag-icons/orange_diamond.svg";
import WhiteDiamond from "../../assets/flag-icons/white_diamond.svg";
import GreyDiamond from "../../assets/flag-icons/completed_diamond.svg";

function DiamondLegend({ style = {} }) {
  return (
    <LegendContainer
      className="root"
      style={{
        ...style,
      }}
    >
      <div className="legendContainer">
        <img
          src={LateIcon}
          width={24}
          height={24}
          style={{
            backgroundColor: "#FCE9E9", // "rgba(224,32,32,0.1)",
            padding: "4px 6px",
            borderRadius: "50%",
          }}
          alt=""
        />
        <span>Late</span>
        <img
          src={LateIconYellow}
          width={24}
          height={24}
          style={{
            backgroundColor: "#FFF5E8",
            padding: "4px 6px",
            borderRadius: "50%",
          }}
          alt=""
        />
        <span>Due Today or Tomorrow</span>
        <img
          src={GreenDiamond}
          width={24}
          height={24}
          style={{
            backgroundColor: "#EAFBEB",
            padding: "4px 6px",
            borderRadius: "50%",
          }}
          alt=""
        />
        <span>In Progress</span>
        <img
          src={WhiteDiamond}
          width={24}
          height={24}
          style={{
            backgroundColor: "#F2F2F2",
            padding: "4px 6px",
            borderRadius: "50%",
          }}
          alt=""
        />
        <span>Not Started</span>
        <img
          src={GreyDiamond}
          width={24}
          height={24}
          style={{
            backgroundColor: "#F2F2F2",
            padding: "1px",
            borderRadius: "50%",
          }}
          alt=""
        />
        <span>Completed</span>
      </div>
    </LegendContainer>
  );
}

export default DiamondLegend;
