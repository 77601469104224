import React from "react";
import EmptyMessage from "../General/EmptyMessage";
import { colors } from "../../assets/jss/variables";
import { ArrowsToSortIcon } from "../../helpers/iconSvgPath";
import {
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { cx } from "@emotion/css";
import { MainTableStyled } from "./tableStyles";

function MainTableRefactor({
  headCells,
  bodyRows,
  renderRow = () => {},
  footer,
  enabledToSee = true,
  noMessage,
  stylesTable = {},
  filters = {},
  onUpdateFilters = () => {},
  isThereColumnSetting = false,
  tableRowHeaderStyles = {},
  nonLookUpfilters = {},
  key = "",
  bodyTableId = "",
  sortArrowsColor = "#EEEEEE",
}) {
  function sortActualColumn(actualColumn) {
    if (filters.sortedColumn === actualColumn) {
      if (filters.orderBy === "ASC") {
        onUpdateFilters({ ...filters, orderBy: "DESC" });
      } else {
        onUpdateFilters({ ...filters, orderBy: "ASC" });
      }
    } else {
      onUpdateFilters({
        ...filters,
        sortedColumn: actualColumn,
        orderBy: "ASC",
      });
    }
  }
  function renderArrow(headerLabel, arrowStyles = {}) {
    if (filters.sortedColumn === headerLabel) {
      if (filters.orderBy === "ASC") {
        return (
          <ArrowsToSortIcon
            style={{
              opacity: 1,
            }}
            leftColor={colors.primaryDark}
            rightColor={sortArrowsColor}
          />
        );
      }
      return (
        <ArrowsToSortIcon
          style={{
            opacity: 1,
          }}
          rightColor={colors.primaryDark}
          leftColor={sortArrowsColor}
        />
      );
    }
    return (
      <ArrowsToSortIcon
        leftColor={sortArrowsColor}
        rightColor={sortArrowsColor}
      />
    );
  }

  return (
    <MainTableStyled
      stickyHeader
      className="mainTable"
      style={{ ...stylesTable }}
      key={key}
    >
      <TableHead>
        <TableRow
          classes={{ root: "tableHeaderRow" }}
          style={tableRowHeaderStyles}
        >
          {headCells.map((cell) => {
            let paddingLeft;
            let paddingRight;
            let paddings = { padding: cell.styles.padding };
            const isSortHeader =
              filters.sortedColumn === cell.label ||
              filters.sortedColumn === cell.reference;
            if (cell.dynamicPadding) {
              let totalDynamic = cell.dynamicPadding.reduce((acc, key) => {
                if (nonLookUpfilters[key]) {
                  return acc + 20;
                } else {
                  return acc;
                }
              }, 0);
              paddingLeft = cell.styles.paddingLeft + totalDynamic;
              paddingRight = cell.styles.paddingRight - totalDynamic;
              paddings = { paddingLeft, paddingRight };
            }
            return cell.enabled ? (
              <TableCell
                id={`headerCell-${cell.name}`}
                key={cell.id}
                className={cx("headerCell", {
                  sortedHeaderCell: isSortHeader,
                })}
                style={{
                  ...cell.styles,
                  ...paddings,
                }}
                onClick={() => {
                  if (!cell.canBeSort) {
                    return;
                  }
                  sortActualColumn(cell.reference || cell.label);
                }}
              >
                {cell.item && cell.item}
                <span
                  className="headerLabel"
                  style={{ width: cell.labelWidth }}
                >
                  {!cell.item && cell.label}
                </span>
                <span className="headerArrow" style={cell.arrowStyles}>
                  {cell.canBeSort &&
                    renderArrow(cell.reference || cell.label, cell.arrowStyles)}
                </span>
              </TableCell>
            ) : null;
          })}
          {isThereColumnSetting && <TableCell className="columnSettings" />}
        </TableRow>
      </TableHead>
      <TableBody id={bodyTableId}>
        {enabledToSee && bodyRows && bodyRows.length
          ? bodyRows.map(renderRow)
          : bodyRows.length === 0 &&
            !noMessage && (
              <TableRow>
                <TableCell
                  colSpan={headCells.length}
                  style={{ padding: 0 }}
                  rowSpan={5}
                  id="emptyMessage"
                >
                  <EmptyMessage />
                </TableCell>
              </TableRow>
            )}
      </TableBody>
      <TableFooter>{footer}</TableFooter>
    </MainTableStyled>
  );
}

export default MainTableRefactor;
