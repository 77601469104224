import React, { useState, useEffect } from "react";

import ListItemDocument from "./ListItemDocument";
import CreateNewFolder from "./CreateNewFolder";
import { dbTables } from "../../api/types/dbTables";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder, sortObjectsBy } from "../../helpers/helpers";
import { firestore } from "../../firebase";
import { doc, writeBatch } from "firebase/firestore";
import { List } from "@mui/material";

function FoldersStructure({
  companyID,
  documents,
  createDocument,
  typeDocument,
  onChange,
  folderName,
  handleSaveFolder,
  handleCancelSaveFolder,
  handleDeleteFolder,
  dbTable,
  onAddNewFolder,
  permissionGroupsDB,
  hasParentPermission = false,
  parentDocument = {},
  isReadOnly = false,
  onChangeShipment = () => {},
}) {
  const [currentDocuments, setCurrentDocuments] = useState([]);

  useEffect(() => {
    setCurrentDocuments(documents);
  }, [documents]);

  const MenuListFile = currentDocuments
    .sort(sortObjectsBy("type"))
    .map((document, index) => {
      return (
        <Draggable
          key={document.id}
          draggableId={document.id}
          index={index}
          isDragDisabled={isReadOnly}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <ListItemDocument
                key={document.id}
                currentDocument={document}
                handleDeleteFolder={handleDeleteFolder}
                provided={provided}
                onAddNewFolder={onAddNewFolder}
                documents={documents}
                companyID={companyID}
                dbTable={dbTable}
                permissionGroupsDB={permissionGroupsDB}
                hasParentPermission={hasParentPermission}
                parentDocument={parentDocument}
                onChangeShipment={onChangeShipment}
              />
            </div>
          )}
        </Draggable>
      );
    });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const reorderedDocuments = reorder(
      currentDocuments,
      result.source.index,
      result.destination.index
    );
    setCurrentDocuments(reorderedDocuments);
    const batch = writeBatch(firestore);
    reorderedDocuments.forEach((documentTemplate, index) =>
      batch.update(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyID}/${dbTable}/${documentTemplate.id}`
        ),
        { listIndex: index }
      )
    );
    batch.commit().finally();
  }
  return (
    <div className={"listItemDocumentContent"}>
      <List>
        {createDocument && (
          <CreateNewFolder
            folderName={folderName}
            handleSaveFolder={handleSaveFolder}
            handleCancelSaveFolder={handleCancelSaveFolder}
            onChange={onChange}
            typeDocument={typeDocument}
          />
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-placeholder-files">
            {(provided, snapshot) => (
              <React.Fragment>
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={"documentContent"}
                >
                  {MenuListFile}
                </div>
              </React.Fragment>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </div>
  );
}

export default FoldersStructure;
