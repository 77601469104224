import React from "react";
import {
  useCompanyPermissionUser,
  useCompanyUsers,
  useUser,
} from "../../hooks/user";
import UserProfileHeader from "../../components/ProfileDrawer/UserProfileHeader";
import UserDrawerContent from "../../components/ProfileDrawer/UserDrawerContent";
import UserAvatarModal from "../../components/ProfileDrawer/UserAvatarModal";

function ProfileDrawer({ openModal = false, handleLoading, handleOpenModal }) {
  const currentUser = useUser();
  const users = useCompanyUsers({});
  const sortPermissionGroup = useCompanyPermissionUser(currentUser);
  return (
    <React.Fragment>
      {openModal && (
        <UserAvatarModal
          currentUser={currentUser}
          handleOpenModal={(value) => handleOpenModal(value)}
          openModal={openModal}
        />
      )}
      <UserProfileHeader currentUser={currentUser} />
      <UserDrawerContent
        handleLoading={(isOpen) => {
          handleLoading(isOpen);
        }}
        currentUser={currentUser}
        users={users}
        onChangeAvatar={(ev) => {
          handleOpenModal(true);
          ev.stopPropagation();
        }}
        permissionGroup={sortPermissionGroup}
      />
    </React.Fragment>
  );
}

export default ProfileDrawer;
