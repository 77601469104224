import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const TaskStatusStyled = styled("div")(() => ({
  "& .stageRoot": {
    width: "21px",
    height: "15px",
    border: "1px solid #E1E1E1",
    margin: "1px",
    boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
    cursor: "pointer",
  },
  "& .notStarted": {
    background: "white",
    border: "1px solid #E1E1E1",
  },
  "& .inProgress": {
    background: "#2AC940",
    border: "1px solid #2AC940",
  },
  "& .nearDue": {
    background: "#FA9917",
    border: "1px solid #FA9917",
  },
  "& .late": {
    background: "#E02020",
    border: "1px solid #E02020",
  },
  "& .complete": {
    background: "#E1E1E1",
    border: "1px solid #E1E1E1",
  },
  "& .checkTask": {
    marginLeft: "6px",
    marginTop: "4px",
    width: "7px",
    height: "4px",
    borderLeft: "2px solid white",
    borderBottom: "2px solid white",
    transform: "rotate(315deg)",
  },
}));

export const InfoPopoverStyled = styled("div")(() => ({
  "&.rootContainerInfo": {
    padding: "0px 16px 16px 16px",
    display: "flex",
    flexDirection: "column",
    "& .generalInfo": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: 30,
      borderBottom: `1px solid ${colors.dividerColor}}`,
    },
  },
  "& .title": {
    minHeight: 50,
    borderBottom: `1px solid ${colors.dividerColor}`,
    display: "flex",
    alignItems: "center",
    width: 300,
    padding: "12px 0px",
  },
  "& .textBold": {
    fontSize: 16,
    fontWeight: 700,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 282,
    display: "inline-block",
  },

  "& .taskInfo": {
    fontSize: 14,
    fontWeight: 700,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 90,
    display: "inline-block",
    paddingLeft: 4,
  },
  "& .taskDescriptionNormal": {
    fontSize: 14,
    fontWeight: 400,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 210,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export const OrderPhaseListContainer = styled("div")(({ theme }) => ({
  "&.root": {
    height: "100%",
    overflowY: "auto",
    position: "relative",
    padding: 16,
    backgroundColor: "#F6FBFF",
    zIndex: 0,
  },
  "& .contentContainer": {
    marginTop: 16,
    width: "100%",
    boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
    backgroundColor: "white",
    position: "relative",
    height: "calc(100vh - 160px)",
    display: "flex",
    flexDirection: "column",
  },
  "& .buttonsFilter": {
    height: "auto",
    padding: "16px 16px 0px",
    display: "flex",
    gap: 16,
    "& .smallBox": {
      padding: 0,
      width: 55,
    },
    "& .bigBox": {
      width: 200,
      "& .childText": {
        fontSize: "1em",
      },
      [theme.breakpoints.down("xl")]: { width: 150 },
    },
  },
  "& .contentSettings": {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 25,
    marginBottom: 5,
    height: 40,
  },
}));

export const OrderPhaseTableContainer = styled("div")(() => ({
  "&.orderPhaseList": {
    "& .weekTableContainer": {
      marginBottom: 32,
    },
    "& .tableContainer": {
      height: "calc(99vh - 325px)",
      marginRight: 4,
      overflow: "auto",
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.thumbScrollColor,
        },
      },
      "&::-webkit-scrollbar": {
        width: 8,
        height: 8,
        marginRight: 3,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track:vertical": {
        marginTop: 52,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: 5,
        marginLeft: 3,
      },
    },
    "& .tableContainerWeek": {
      height: "calc(100vh - 260px)",
      marginRight: 3,
      marginBottom: 3,
      overflow: "auto",
    },
  },
}));
