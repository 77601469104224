export const colors = {
  primary: "#c0d5ee",
  primaryDark: "#25486D",
  primaryLight: "#e6f0f9",
  lightYellow: "rgba(255, 255, 0, 0.1)",
  onHoverColor: "rgb(246, 251, 255)",
  borderGray: "#4F4F4F",
  dangerRed: "#f44336",
  diamondRed: "#E02020",
  diamondOrange: "#FA9917",
  diamondGreen: "#30D536",
  diamondWhiteBorder: "#bdbdbd",
  sliderGreen: "#149532",
  skyBlue: "#CAE0FC",
  whiteBlue: "#D0DCEA",
  backgroundWhite: "#E0E0E0",
  backgroundWhiteAction: "#BCBCBC",
  backgroundBlue: "#B1D9FE",
  backgroundBlueAction: "#65B0F6",
  backgroundGreen: "#C5E569",
  backgroundGreenAction: "#9DC725",
  backgroundPink: "#F6C4FF",
  backgroundPinkAction: "#EA84FC",
  shipmentBadge: "#F6E6F9",
  buttomToolbarActive: "#EBEBEB",
  buttomDisabled: "#D0DCEA26",
  gray: "#DDDDDD",
  darkGray: "#757575",
  salesOrderBackgroundColor: "#EBF3D4",
  salesOrderBorderColor: "#B6C193",
  purchaseOrderBackgroundColor: "#E6F0F9",
  purchaseOrderBorderColor: "#90AED1",
  shipmentBorderColor: "#DAA2E3",
  dividerColor: "#87A3C2",
  thumbScrollColor: "#898989",
  tableHeaderColor: "#c5dbf6",
  touchRipple: "#000000",
};

export const boxShadowColor = {
  yellow: "rgba(255, 236, 215, 0.7)",
  yellowActive: "rgba(255, 236, 215, 1)",
  red: "rgba(224, 32, 32, 0.1)",
  redActive: "rgba(224, 32, 32, 0.25)",
  orange: "rgba(250, 153, 23, 0.1)",
  orangeActive: "rgba(250, 153, 23, 0.25)",
  green: "rgba(48, 213, 54, 0.1)",
  greenActive: "rgba(48, 213, 54, 0.25)",
  white: "rgba(189, 189, 189, 0.1)",
  whiteActive: "rgba(189, 189, 189, 0.25)",
  gray: "rgba(189, 189, 189, 0.1)",
  grayActive: "rgba(189, 189, 189, 0.25)",
};
