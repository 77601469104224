import React, { useCallback } from "react";
import {
  getTagsLabel,
  getTheDate,
  isTradeDashEmployee,
  getProgressValue,
  getActivitiesNotes,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import { getShipmentBadge } from "../../helpers/shipments";
import { NavLink, useNavigate } from "react-router-dom";
import numeral from "numeral";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  generalPermissions,
  GENERAL_PERMISSION_VALUE,
  headerColumns,
  LOCAL_STORAGE_KEY,
  notAllowedToSee,
  SHIPMENT_STATUS,
  TYPE_OF_TAGS,
  BACKDROP_TYPE,
} from "../../helpers/constants";
import TagsModal from "../../components/Modal/TagsModal";
import userTypes from "../../api/types/userTypes";
import { dbTables } from "../../api/types/dbTables";
import { useDispatch } from "react-redux";
import {
  setBackdropPanelSection,
  setGlobalNoteDate,
} from "../../actions/DataActions";
import { useStorage } from "../../hooks";
import { checkTagsInItem, tagsByType } from "../../hooks/tags";
import { FolderIcon } from "../../helpers/iconSvgPath";
import { getTagStatus, orderType } from "../../helpers/salesOrder";
import {
  checkSpecificPermissions,
  verifyPermission,
} from "../../hooks/permissions";
import { useFactories } from "../../hooks/factories";
import { hasShipmentRootFolderPermission } from "../../helpers/shipments";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import SalesOrdersItem from "./SalesOrderItem";
import POsDetailItemv2 from "../SalesOrdersScreen/PODetailsItemsv2";
import { TableCell } from "@mui/material";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { cx } from "@emotion/css";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import {
  getDiamondStatus,
  getDisclouserArrow,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";

function ShipmentsTableRow({
  item,
  favorites,
  user,
  isAllowed = () => {},
  customers,
  headers = [],
  tags,
  onUpdateItem,
  isThereColumnSetting = false,
  filters = { factory: [] },
  onChangeSetupStorage = () => {},
  shipmentScreenSetup = {},
  onOpenAttentionModal = () => {},
  favoritesDB = [],
}) {
  const navigate = useNavigate();
  const filterTag = filters.tag || [];
  const mismatchTags = tagsByType(tags, TYPE_OF_TAGS.MISMATCH_TAG);
  const isVoided = item.status === SHIPMENT_STATUS.VOIDED;
  const customer = customers.find((cus) => cus.id === item.customerId);
  const factories = useFactories();
  const dispatch = useDispatch();
  const [projectTaskStorage, setProjectTaskStorage] = useStorage(
    LOCAL_STORAGE_KEY.projectTasks
  );
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };

  const setNoteDate = useCallback(({ type, creationDate, noteId }) =>
    setGlobalNoteDate({
      type,
      creationDate,
      noteId,
    })(dispatch)
  );

  function zeroIsBlank(item, isCBM) {
    let dformat = "$0,0.00";
    if (isCBM) {
      dformat = "0.00";
    }
    return numeral(item).format("$0,0.00") === "$0.00"
      ? " "
      : numeral(item).format(dformat);
  }

  const onChangeDocumentTab = (id) => {
    const documentTab = projectTaskStorage.documentTab;
    setProjectTaskStorage("documentTab", {
      ...documentTab,
      [id]: 1,
    });
  };

  function handleChangeShipmentSetup() {
    const shipmentSetup = shipmentScreenSetup[item.id] || {};
    if (shipmentSetup.POs || shipmentSetup.SOs) {
      onChangeSetupStorage({ item, poValue: false, soValue: false });
    } else {
      onChangeSetupStorage({ item, poValue: true, soValue: true });
    }
  }

  function shipmentTableRowSelector({ column, enabled, index, header }) {
    const purchaseOrders = item.purchaseOrders || [];
    const filterPurchaseOrders = purchaseOrders.filter((po) =>
      verifyPermission({
        user: user,
        permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      })
    );
    switch (column) {
      case headerColumns.FAVORITE:
        return (
          <TableCell key={index} className="favorite">
            {getDisclouserArrow({
              currentSetup: shipmentScreenSetup[item.id],
              handleChangeSetup: handleChangeShipmentSetup,
              purchaseOrders: item.salesOrders,
              salesOrders: filterPurchaseOrders,
              type: orderType.SHIPMENT,
            })}
          </TableCell>
        );

      case headerColumns.DIAMOND:
        return (
          <TableCell key={index} className={"diamond"}>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              {getStarFavorite({
                favorites: favorites,
                itemId: item.id,
                removeFavorite: () =>
                  removeFavorite({
                    favoritesDB,
                    id: item.id,
                  }),
                addFavorite: () =>
                  addFavorite({
                    id: item.id,
                    user,
                    favoritesDB,
                    dbTable: dbTables.SHIPMENT_FAVORITES,
                  }),
              })}
              {getDiamondStatus({
                diamondStatus: item.diamondStatus,
                itemStatus: item.status,
              })}
            </div>
          </TableCell>
        );

      case headerColumns.PROGRESS:
        return isVoided ? (
          <TableCell key={index} />
        ) : (
          <TableCell key={index} className="circularProgress">
            <CircularStaticProgress
              label={(item.completedTasks || 0) + "/" + (item.totalTasks || 0)}
              value={+getProgressValue(item)}
              thickness={16}
              style={{
                transform: "rotate(270deg)",
                color: "#39F",
              }}
              size={18}
            />
          </TableCell>
        );

      case headerColumns.MENTIONS:
        const [poItem = {}] = filterPurchaseOrders;
        const [soItem = {}] = item.salesOrders;
        return (
          <TableCell key={column + index} className={"mention"}>
            {item.totalMentions > 0 && (
              <NavLink
                to={getDashboardSearchPath({
                  salesOrderId: soItem.id,
                  purchaseOrderId: poItem.id,
                  shipmentId: item.id,
                  section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                })}
                onClick={async (ev) => {
                  const shipmentActivity = await getActivitiesNotes({
                    companyId: item.companyId,
                    orderId: item.id,
                    userId: user.id,
                    orderTable: dbTables.SHIPMENTS,
                  });
                  if (shipmentActivity) {
                    setNoteDate({
                      type: dbTables.SHIPMENTS,
                      creationDate: shipmentActivity.creationDate,
                      noteId: shipmentActivity.id,
                    });
                  }
                  setBackdrop({
                    type: BACKDROP_TYPE.BADGE_NUMBER,
                    id: BACKDROP_TYPE.BADGE_NUMBER,
                  });
                }}
              >
                <span style={{ textDecoration: "underline" }}>
                  {item.totalMentions}
                </span>
              </NavLink>
            )}
          </TableCell>
        );

      case headerColumns.SHIPMENT:
        const [pursaseOrderItem = {}] = filterPurchaseOrders;
        const [salesOrderItem = {}] = item.salesOrders || [];
        const shipmentTags = item.tags || [];
        return (
          enabled && (
            <TableCell key={index} className={"number"}>
              <div className="number-table-container">
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={item.id}
                    label={getTagsLabel(tags, shipmentTags).tagsString}
                    tags={getTagsLabel(tags, shipmentTags).tagsArray}
                    allTags={tags}
                    item={item}
                    currentUser={user}
                    tagReference={dbTables.SHIPMENTS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: shipmentTags,
                      currentTags: filterTag,
                    })}
                  />
                )}
                <NavLink
                  to={
                    isVoided
                      ? false
                      : getDashboardSearchPath({
                          salesOrderId: salesOrderItem.id,
                          purchaseOrderId: pursaseOrderItem.id,
                          shipmentId: item.id,
                          section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                        })
                  }
                  style={{
                    overflow: "visible",
                    marginLeft: 0,
                    textDecoration: "none",
                  }}
                  id="list-view-content-shipment"
                >
                  {getShipmentBadge({
                    shipment: item,
                    classes: shipmentBadgeClass.medium,
                  })}
                </NavLink>
                {!isVoided && (
                  <div
                    id="list-view-content-shipmentfolder"
                    className="folder-shipment-icon"
                    onClick={async (ev) => {
                      const hasPermision =
                        await hasShipmentRootFolderPermission({
                          shipment: item,
                          user,
                        });
                      if (!hasPermision) {
                        onOpenAttentionModal();
                      } else {
                        navigate(
                          getDashboardSearchPath({
                            salesOrderId: salesOrderItem.id,
                            purchaseOrderId: pursaseOrderItem.id,
                            shipmentId: item.id,
                            fileId: item.id,
                            section:
                              ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                          })
                        );
                        onChangeDocumentTab(pursaseOrderItem.id);
                      }
                    }}
                  >
                    <FolderIcon size={12} />
                  </div>
                )}
              </div>
            </TableCell>
          )
        );

      case headerColumns.SALES_ORDER:
        return (
          enabled && (
            <TableCell key={index} className={"number"}>
              <SalesOrdersItem
                item={{ ...item, purchaseOrders: filterPurchaseOrders }}
                user={user}
                filters={filters}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={shipmentScreenSetup || {}}
                classContainer="SOs-container"
                onChangeSalesOrderSetup={() => {
                  const itemRow = shipmentScreenSetup[item.id] || {};
                  onChangeSetupStorage({
                    item,
                    soValue: !itemRow.SOs,
                  });
                }}
              />
            </TableCell>
          )
        );

      case headerColumns.PO:
        return (
          enabled && (
            <TableCell key={index} className={"number"}>
              <POsDetailItemv2
                item={item}
                user={user}
                filters={filters}
                isAllowed={isAllowed}
                isTradeDashEmployee={isTradeDashEmployee}
                onUpdateItem={onUpdateItem}
                tags={tags}
                salesOrderSetup={shipmentScreenSetup || {}}
                handleCheckTags={(purchaseOrderItem) =>
                  checkTagsInItem(purchaseOrderItem, mismatchTags, filters.tag)
                }
                factories={factories}
                onChangeSalesOrderSetup={() => {
                  const itemRow = shipmentScreenSetup[item.id] || {};
                  onChangeSetupStorage({
                    item,
                    poValue: !itemRow.POs,
                  });
                }}
                poNameWidth={header.poNameWidth}
              />
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER:
        return (
          enabled &&
          customer && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD label={customer.name}>
                <NavLink
                  to={
                    !(
                      user.role === userTypes.SUPER_ADMIN ||
                      user.role === userTypes.TRADEDASH_EMPLOYEE ||
                      checkSpecificPermissions({
                        userPermissions: user.permissions,
                        permissionToCheck: [
                          item.customerId,
                          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                        ],
                      })
                    )
                      ? "#"
                      : `/app/customers/${item.customerId}`
                  }
                  className="navLink"
                  id="list-view-content-customer"
                >
                  <span className="span-description">{customer.name}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CREATION_DATE:
        return (
          enabled && (
            <TableCell key={index} className={"date"} id="createDate">
              {getTheDate(item.creationDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.SHIP_DATE:
        return (
          enabled && (
            <TableCell key={index} className={"date"} id="shipDate">
              {getTheDate(item.shipDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.DELIVERY_DATE:
        return (
          enabled && (
            <TableCell key={index} className={"date"} id="deliveryDate">
              {getTheDate(item.deliveryDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.UNIQUE_ITEMS:
        return (
          enabled && (
            <TableCell
              key={index}
              className={"totalShipments"}
              id="uniqueItems"
            >
              {item.uniqueItems || ""}
            </TableCell>
          )
        );
      case headerColumns.SALES_INVOICE:
        return (
          enabled && (
            <TableCell key={index} className={"finalDestination"}>
              <TooltipTD
                label={item.invoice}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.invoice}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.BOL:
        return (
          enabled && (
            <TableCell key={index} className={"loadingPort"}>
              <TooltipTD
                label={item.billOfLading}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.billOfLading}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CBM:
        return (
          enabled && (
            <TableCell key={index} className="CBM" id="CBM">
              {" "}
              {zeroIsBlank(item.CBM, true)}
            </TableCell>
          )
        );

      case headerColumns.SHIPPING_TERMS:
        return (
          enabled && (
            <TableCell key={index} className="shippingTerms">
              {item.shippingTerms}
            </TableCell>
          )
        );

      case headerColumns.LOADING_PORT:
        return (
          enabled && (
            <TableCell key={index} className={"loadingPort"}>
              <TooltipTD
                label={item.loadingPort}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.loadingPort}
              </TooltipTD>
            </TableCell>
          )
        );
      case headerColumns.UNLOADING_PORT:
        return (
          enabled && (
            <TableCell key={index} className={"unloadingPort"}>
              <TooltipTD
                label={item.unloadingPort}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.unloadingPort}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.FINAL_DESTINATION:
        return (
          enabled && (
            <TableCell key={index} className={"finalDestination"}>
              <TooltipTD
                label={item.finalDestination}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.finalDestination}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.BOOKING:
        return (
          enabled && (
            <TableCell key={index} className={"finalDestination"}>
              <TooltipTD
                label={item.booking}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.booking}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CONTAINER:
        return (
          enabled && (
            <TableCell key={index} className={"finalDestination"}>
              <TooltipTD
                label={item.container}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.container}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER_PO:
        return (
          enabled && (
            <TableCell key={index} className={"customerPO"}>
              <TooltipTD
                label={item.customerPO}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.customerPO}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.FREIGHT_FORWARDER:
        return (
          enabled && (
            <TableCell key={index} className={"customerPO"}>
              <TooltipTD
                label={item.freightForwarder}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.freightForwarder}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.TOTAL_VALUE:
        return (
          enabled &&
          isAllowed(generalPermissions.SHIPMENT_AMOUNT) && (
            <TableCell key={column + index} className={"amount"} id="total">
              {isAllowed(generalPermissions.SHIPMENT_AMOUNT)
                ? zeroIsBlank(item.total)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.TOTAL_WEIGHT:
        return (
          enabled && (
            <TableCell key={column + index} className={"amount"} id="weight">
              {numeral(item.totalWeight).format("0,0.00")}
            </TableCell>
          )
        );
      case headerColumns.POTENTIAL_LATE_FEE:
        return (
          enabled &&
          isAllowed(generalPermissions.SHIPMENT_AMOUNT) && (
            <TableCell key={column + index} className={"amount"} id="lateFee">
              {isAllowed(generalPermissions.SHIPMENT_AMOUNT)
                ? zeroIsBlank(item.potentialLateFee)
                : notAllowedToSee}
            </TableCell>
          )
        );

      default:
        return <TableCell key={index} />;
    }
  }
  return (
    item && (
      <MainRowStyled
        key={item.id + "MainRowStyled"}
        className={cx(
          mainRowClasses.ShipmentTableRow,
          mainRowClasses.hoverEffect
        )}
      >
        {headers.map((header) =>
          shipmentTableRowSelector({
            column: header.name,
            enabled: header.enabled,
            index: item.id + header.name,
            header,
          })
        )}
        {isThereColumnSetting && <TableCell key={item.id} />}
      </MainRowStyled>
    )
  );
}

export default ShipmentsTableRow;
