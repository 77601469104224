import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
function CurrencyInput({ maskOptions, editing, ...inputProps }) {
  const inputRef = useRef(null);
  useEffect(() => {
    if (editing && inputRef && inputRef.current) {
      setTimeout(
        () => {
          inputRef.current.select();
        },
        50,
        inputRef
      );
    }
  }, [inputRef]);

  return <input {...inputProps} ref={inputRef} />;
}

CurrencyInput.defaultProps = {
  maskOptions: {},
};

CurrencyInput.propTypes = {
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
};

export default CurrencyInput;
