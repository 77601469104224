import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import {
  dayHeight,
  dayWidth,
  expandedTaskTableColumns,
  GENERAL_PERMISSION_VALUE,
  simpleTaskTableColumns,
  taskStatus,
} from "../../helpers/constants";
import {
  getCompanyUserDisplayName,
  getCompanyUserField,
  sortObjectsBy,
} from "../../helpers/helpers";
import {
  convertHex,
  getAffectedFieldValue,
  getPredecesorTask,
  getTaskBackgroundColor,
  getTaskDiamond,
  getTaskReference,
  isOrderVoided,
  TABLE_HEADERS_GANTT_CHART,
} from "../../helpers/timelineCalendar";
import TooltipTD from "../Tooltip/TooltipTD";
import EditableLabel from "../TextFields/EditableLabel";
import DatePicker from "../DatePicker/DatePicker";
import UserPicker from "../DatePicker/UserPicker";
import { TaskTableContainerStyled } from "./styles";
import CompletedTaskBadge from "./CompletedTaskBadge";
import { actionType } from "../../helpers/timelineModal";
import userTypes from "../../api/types/userTypes";
import { CalendarIcon } from "../../helpers/iconSvgPath";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { typeOfTask } from "../../api/types/dbTables";
import { getShipmentBadge } from "../../helpers/shipments";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { triggerTaskTypes } from "../../helpers/tasks";
import { useUser } from "../../hooks/user";
import {
  getCorrectTimezone,
  taskCompletionVerifier,
} from "../../helpers/ganttChart";
import { checkSpecificPermissions } from "../../hooks/permissions";
import AttentionModal from "../Modal/AttentionModal";
import { Avatar, Paper } from "@mui/material";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";

function TaskTableContent({
  styles,
  task,
  columnIndex,
  onHoverStyles,
  predecesorTask,
  companyUsers,
  onMouseEnter,
  onMouseLeave,
  tableSize,
  onChangeTask = () => {},
  onMoveRemainingTasks,
  selectedShipments,
  flagViewOptions,
  onMouseEnterInTaskDescription,
  alltasks = [],
  isAbleToModifyTaskDueDate,
  hasPermissionToReassign,
  orders,
}) {
  const [duration, setDuration] = useState(task.duration);
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openUserPicker, setOpenUserPicker] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isShipmentHovered, setIsShipmentHovered] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );

  const currentUser = useUser();

  useEffect(() => {
    const timer =
      isHovered &&
      setTimeout(() => {
        onMouseEnter(task.assignedTo);
      }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isHovered]);

  useEffect(() => {
    if (isShipmentHovered) {
      const timer = setTimeout(() => {
        onMouseEnterInTaskDescription({ shipmentId: task.shipmentId });
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      onMouseEnterInTaskDescription({ shipmentId: null });
    }
  }, [isShipmentHovered]);

  useEffect(() => {
    if (task.duration !== duration) {
      setDuration(task.duration);
    }
  }, [task.duration]);

  const customStyle = {
    width: dayWidth,
    height: dayHeight,
    zIndex: 3,
    position: "absolute",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  };
  const textStyle = {
    fontSize: 12,
    color: "#334C73",
    textAlign: "left",
    opacity: task.status === "complete" ? 0.3 : 1,
    margin: 0,
  };

  function handleOpenModalAttention() {
    setDescriptionModalAttention(
      <React.Fragment>
        You do not have permission to change task due dates
      </React.Fragment>
    );
    setOpenModalAttention(true);
  }

  function handleCloseModalAttention() {
    setOpenModalAttention(false);
    setDescriptionModalAttention(<React.Fragment></React.Fragment>);
  }

  const dateRender = ({
    task,
    customStyle,
    onHoverStyles,
    textStyle,
    field,
    className,
    affectedField,
  }) => {
    const isVoided = isOrderVoided({ task, orders });
    return (
      <div
        className={className}
        style={{
          ...customStyle,
          background: task.isAdHocTask
            ? convertHex("#B9B3B3", 0.5)
            : getTaskBackgroundColor({ task }),
          ...onHoverStyles,
          justifyContent: "center",
          width: dayWidth * 3,
          height: dayHeight - 1,
        }}
      >
        <p
          style={{ ...textStyle, cursor: "pointer" }}
          ref={dateRef}
          onClick={() => {
            if (
              (!isAbleToModifyTaskDueDate &&
                task.status !== taskStatus.COMPLETE) ||
              isVoided
            ) {
              handleOpenModalAttention();
            } else if (task.status !== taskStatus.COMPLETE) {
              setOpenDatePicker(true);
            }
          }}
        >
          {moment(task[field]).format("M/D/YY")}
          <CalendarIcon
            style={{ marginBottom: -1, marginLeft: 4 }}
            svgClass="calendarIcon"
          />
        </p>
        {openDatePicker && (
          <DatePicker
            el={dateRef.current}
            onChange={(value) => {
              const { affectedDate, offset } = getAffectedFieldValue({
                affectedField,
                task,
                field,
                value,
              });
              if (offset === 0) {
                return;
              }
              let newDate = moment(value).startOf("day").valueOf();
              let newAffectedDate = moment(affectedDate)
                .startOf("day")
                .valueOf();

              if (field === "finishDate") {
                newDate = moment(value).endOf("day").valueOf();
              }
              if (affectedField === "finishDate") {
                newAffectedDate = moment(affectedDate).endOf("day").valueOf();
              }
              onChangeTask(
                {
                  ...task,
                  [field]: getCorrectTimezone({
                    date: newDate,
                  }).valueOf(),
                  [affectedField]: getCorrectTimezone({
                    date: newAffectedDate,
                  }),
                },
                offset,
                actionType.MOVED_TASK
              );
              return setOpenDatePicker(false);
            }}
            open={openDatePicker}
            onClose={() => {
              setOpenDatePicker(false);
            }}
            value={moment(task[field])}
            cancelIcon={true}
          />
        )}
      </div>
    );
  };

  function getContent({ task, onHoverStyles, predecesorTask, key }) {
    switch (key) {
      case TABLE_HEADERS_GANTT_CHART.BADGE:
        return (
          <div
            className="taskStatus"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              justifyContent: "space-between",
              height: dayHeight - 1,
              width: dayWidth * 3,
            }}
          >
            {getTaskDiamond({
              status: task.status,
              styles: { marginLeft: 18 },
            })}
            {task.status === taskStatus.COMPLETE && (
              <CompletedTaskBadge
                task={task}
                onMoveRemainingTasks={(task) => {
                  if (!isAbleToModifyTaskDueDate) {
                    handleOpenModalAttention();
                  } else {
                    onMoveRemainingTasks(task);
                  }
                }}
              />
            )}
            <CustomCheckbox
              styles={{
                color: "#6B7A89",
                opacity: task.complete ? 0.3 : 1,
                background: "#FFF",
              }}
              checked={task.complete}
              onChange={() => {
                if (task.status !== taskStatus.COMPLETE) {
                  const dayOffset = moment()
                    .startOf("day")
                    .diff(moment(task.finishDate).startOf("day"), "days");
                  const { duration, finishDate, startDate } =
                    taskCompletionVerifier({
                      task,
                    });

                  // PENDING TO COMPLETE NOTIFICATIONS
                  // const fieldsToUpdate = {
                  //   notificationId,
                  //
                  onChangeTask(
                    {
                      ...task,
                      complete: true,
                      dayOffset,
                      completedBy: currentUser.id,
                      moved:
                        dayOffset !== 0
                          ? !alltasks.some(
                              (cTask) =>
                                cTask.dependency === task.id &&
                                cTask.status !== taskStatus.COMPLETE
                            )
                          : true,
                      duration,
                      finishDate,
                      startDate,
                      triggerType: triggerTaskTypes.COMPLETE,
                      templateDuration: task.duration,
                    },
                    false,
                    actionType.COMPLETED_TASK
                  );
                }
              }}
            />
          </div>
        );

      case TABLE_HEADERS_GANTT_CHART.REFERENCE:
        return tableSize === 3 ? (
          <div
            className="task-avatar"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              justifyContent: "center",
              width: dayWidth,
              height: dayHeight - 1,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => {
              setIsHovered(false);
              onMouseLeave();
            }}
          >
            <TooltipTD
              label={`@${getCompanyUserField({
                companyUsers,
                field: "displayName",
                userId: task.assignedTo,
              })}`}
            >
              <Avatar
                style={{ width: 22, height: 22 }}
                alt="avatar"
                src={getCompanyUserField({
                  companyUsers,
                  userId: task.assignedTo,
                  field: "avatar",
                })}
              />
            </TooltipTD>
          </div>
        ) : (
          <div
            className="task-reference"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              justifyContent: "center",
              width: dayWidth * 2,
              height: dayHeight - 1,
            }}
          >
            <p style={{ ...textStyle }}>{getTaskReference({ task })}</p>
          </div>
        );

      case TABLE_HEADERS_GANTT_CHART.STATUS:
        return (
          tableSize === 3 && (
            <div
              style={{
                ...customStyle,
                background: task.isAdHocTask
                  ? convertHex("#B9B3B3", 0.5)
                  : getTaskBackgroundColor({ task }),
                ...onHoverStyles,
                width: dayWidth,
                justifyContent: "flex-end",
                height: dayHeight - 1,
              }}
            >
              <div
                className="task-color-column"
                style={{
                  width: 11,
                  background: task.color,
                  height: dayHeight - 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...textStyle,
                }}
              />
            </div>
          )
        );
      case TABLE_HEADERS_GANTT_CHART.TASK_NAME: {
        return (
          <div
            className="task-description"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              width: dayWidth * 11,
              height: dayHeight - 1,
            }}
          >
            {task.type === typeOfTask.SHIPMENT &&
              getShipmentBadge({
                shipment: selectedShipments.find(
                  (sShipment) => sShipment.id === task.shipmentId
                ),
                classes: shipmentBadgeClass.secondary,
                styles: { marginRight: 2 },
              })}
            <TooltipTD
              label={task.description}
              style={{
                width: task.type === typeOfTask.SHIPMENT ? "88%" : "100%",
              }}
            >
              <p
                style={{
                  ...textStyle,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "100%",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
                onMouseEnter={() => {
                  if (task.type === typeOfTask.SHIPMENT) {
                    setIsShipmentHovered(true);
                  }
                  return;
                }}
                onMouseLeave={() => {
                  if (task.type === typeOfTask.SHIPMENT) {
                    setIsShipmentHovered(false);
                  }
                  return;
                }}
              >
                {task.description}
              </p>
            </TooltipTD>
          </div>
        );
      }
      case TABLE_HEADERS_GANTT_CHART.PREDECESOR:
        return (
          <div
            className="task-predecesor"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              justifyContent: "center",
              width: dayWidth * 2,
              height: dayHeight - 1,
            }}
          >
            <p style={{ ...textStyle }}>
              {getPredecesorTask({ task, predecesorTask })}
            </p>
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.DEPENDENCY:
        return (
          <div
            className="task-dependency-type"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              justifyContent: "center",
              width: dayWidth * 2,
              height: dayHeight - 1,
            }}
          >
            <p style={{ ...textStyle }}>{task.dependencyType}</p>
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.DURATION:
        return (
          <div
            className="task-duration"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              justifyContent: "center",
              width: dayWidth * 2,
              height: dayHeight - 1,
              fontWeight: 700,
            }}
          >
            {!isEditable ? (
              <span
                onClick={() => {
                  if (task.status !== taskStatus.COMPLETE) {
                    setIsEditable(true);
                  }
                }}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "90%",
                  minWidth: "90%",
                  ...textStyle,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                {duration}
                <EditIcon
                  className="edit-icon"
                  style={{
                    marginLeft: 4,
                    fontSize: 14,
                  }}
                />
              </span>
            ) : (
              <EditableLabel
                editable={isEditable}
                onChange={(ev) => setDuration(ev.target.value)}
                autoFocus={false}
                editing="false"
                disabeldBlur
                onValid={() => {
                  const durationOffset =
                    parseInt(duration) - parseInt(task.duration);
                  setIsEditable(false);
                  if (durationOffset === 0) {
                    return;
                  }
                  return onChangeTask(
                    {
                      ...task,
                      duration: +duration,
                      finishDate: getCorrectTimezone({
                        date: moment(task.finishDate).add(
                          durationOffset,
                          "days"
                        ),
                      }).valueOf(),
                      startDate: getCorrectTimezone({
                        date: task.startDate,
                      }).valueOf(),
                    },
                    durationOffset,
                    actionType.CHANGED_DURATION_TASK
                  );
                }}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "90%",
                  minWidth: "90%",
                  ...textStyle,
                }}
                children={duration}
                type="number"
                min={1}
                disabled={task.status === taskStatus.COMPLETE}
                onclose={() => setIsEditable(false)}
              />
            )}
          </div>
        );

      case TABLE_HEADERS_GANTT_CHART.ASSIGNED_TO:
        const isVoided = isOrderVoided({ task, orders });
        return (
          <div
            className="task-assigned-to"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              width: dayWidth * 5,
              justifyContent: "space-between",
              height: dayHeight - 1,
              fontWeight: 700,
            }}
          >
            <div
              style={{
                ...textStyle,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => {
                setIsHovered(false);
                onMouseLeave();
              }}
              ref={userRef}
              onClick={() => {
                if (task.status === taskStatus.COMPLETE) {
                  return;
                } else if (
                  (!hasPermissionToReassign &&
                    currentUser.role !== userTypes.SUPER_ADMIN) ||
                  isVoided
                ) {
                  setDescriptionModalAttention(
                    <React.Fragment>
                      You do not have permission to reassing a task
                    </React.Fragment>
                  );
                  setOpenModalAttention(true);
                } else {
                  setOpenUserPicker(!openUserPicker);
                }
              }}
            >
              <Avatar
                style={{ width: 22, height: 22, marginRight: 2 }}
                alt="avatar"
                src={getCompanyUserField({
                  companyUsers,
                  userId: task.assignedTo,
                  field: "avatar",
                })}
              />
              <span
                style={{
                  maxWidth: 76,
                  minWidth: "auto",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {getCompanyUserDisplayName(companyUsers, task.assignedTo)}
              </span>
              <ExpandMoreIcon className="arrowButton" />
              {openUserPicker && task.status !== taskStatus.COMPLETE && (
                <UserPicker
                  el={userRef.current}
                  onChange={(value, ev) => {
                    const user =
                      companyUsers.find((user) => user.id === value) || {};
                    const permissionToCheck =
                      task.type === typeOfTask.PURCHASE_ORDER
                        ? task.factoryId
                        : task.customerId;
                    const globalPermission =
                      task.type === typeOfTask.PURCHASE_ORDER
                        ? GENERAL_PERMISSION_VALUE.ALL_VENDORS
                        : GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS;
                    const checkSpecificPermission = checkSpecificPermissions({
                      permissionToCheck: [permissionToCheck, globalPermission],
                      userPermissions: user.permissions,
                    });
                    if (
                      user.role !== userTypes.SUPER_ADMIN &&
                      !checkSpecificPermission
                    ) {
                      ev.preventDefault();
                      ev.stopPropagation();
                      return;
                    }
                    onChangeTask(
                      {
                        ...task,
                        assignedTo: user.id,
                        lastAssignedTo: task.assignedTo,
                      },
                      null,
                      actionType.CHANGED_ASSIGNED_TO
                    );
                  }}
                  open={openUserPicker}
                  onClose={() => {
                    setOpenUserPicker(!openUserPicker);
                  }}
                  value={task.assignedTo}
                  users={companyUsers
                    .filter((user) => user.active)
                    .sort(sortObjectsBy("displayName", false))}
                  permissionToVerify={
                    task.type === typeOfTask.PURCHASE_ORDER
                      ? task.factoryId
                      : task.customerId
                  }
                  isTherePermission={true}
                  isCustomer={!task.factoryId ? true : false}
                />
              )}
            </div>
            {task.type === typeOfTask.PURCHASE_ORDER && (
              <div
                className="task-color-column"
                style={{
                  width: 11,
                  background: task.color,
                  height: dayHeight - 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...textStyle,
                }}
              />
            )}
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.CHECK_BOX_TO_COMPLETE:
        return (
          <div
            className="checkbox-completing"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              width: dayWidth * 1,
              justifyContent: "space-between",
              height: dayHeight - 1,
              fontWeight: 700,
            }}
          >
            <input type="checkbox" />
          </div>
        );

      case TABLE_HEADERS_GANTT_CHART.PO_BAR:
        return (
          <div
            className="task-po-bar"
            style={{
              ...customStyle,
              background: task.isAdHocTask
                ? convertHex("#B9B3B3", 0.5)
                : getTaskBackgroundColor({ task }),
              ...onHoverStyles,
              width: dayWidth,
              justifyContent: "flex-end",
              height: dayHeight - 1,
              fontWeight: 700,
            }}
          >
            {task.type === typeOfTask.PURCHASE_ORDER && (
              <div
                className="task-color-column"
                style={{
                  width: 11,
                  background: task.color,
                  height: dayHeight - 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...textStyle,
                }}
              />
            )}
          </div>
        );

      default:
        return <div>DEFAULT</div>;
    }
  }

  function getFullTaskContent(
    columnIndex,
    task,
    onHoverStyles,
    predecesorTask
  ) {
    switch (columnIndex) {
      case 1:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.BADGE,
        });

      case 4:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.REFERENCE,
        });

      case 6:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.TASK_NAME,
        });

      case 17:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.PREDECESOR,
        });

      case 19:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.DEPENDENCY,
        });

      case 21:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.DURATION,
        });

      case 23:
        return dateRender({
          task,
          field: "startDate",
          affectedField: "finishDate",
          customStyle,
          onHoverStyles,
          textStyle: { ...textStyle, fontWeight: 700 },
          className: "task-start-date",
        });
      case 26:
        return dateRender({
          task,
          field: "finishDate",
          affectedField: "startDate",
          customStyle,
          onHoverStyles,
          textStyle: { ...textStyle, fontWeight: 700 },
          className: "task-finish-date",
        });

      case 29:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.ASSIGNED_TO,
        });

      default:
        return null;
    }
  }

  function getSimpleTaskContent(
    columnIndex,
    task,
    onHoverStyles,
    predecesorTask
  ) {
    switch (columnIndex) {
      case 1:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.BADGE,
        });

      case 4:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.TASK_NAME,
        });

      case 15:
        return dateRender({
          task,
          field: "finishDate",
          affectedField: "startDate",
          customStyle,
          onHoverStyles,
          textStyle: { ...textStyle, fontWeight: 700 },
          className: "task-finish-date",
        });

      case 18:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.ASSIGNED_TO,
        });

      default:
        return null;
    }
  }

  function getCollapsedTaskContent(
    columnIndex,
    task,
    onHoverStyles,
    predecesorTask
  ) {
    switch (columnIndex) {
      case 1:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.BADGE,
        });

      case 4:
        return getContent({
          task,
          onHoverStyles,
          predecesorTask,
          key: TABLE_HEADERS_GANTT_CHART.PO_BAR,
        });

      default:
        return null;
    }
  }

  return (
    <TaskTableContainerStyled className="taskTableContainer">
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          onClick={handleCloseModalAttention}
          onClose={handleCloseModalAttention}
          isOpen={openModalAttention}
        />
      )}
      <Paper
        className={
          columnIndex === 0 ? "taskTableContentFirstColumn" : "taskTableContent"
        }
        style={{
          ...styles,
        }}
        elevation={0}
      >
        {tableSize === expandedTaskTableColumns
          ? getFullTaskContent(columnIndex, task, onHoverStyles, predecesorTask)
          : tableSize === simpleTaskTableColumns
          ? getSimpleTaskContent(
              columnIndex,
              task,
              onHoverStyles,
              predecesorTask
            )
          : getCollapsedTaskContent(
              columnIndex,
              task,
              onHoverStyles,
              predecesorTask
            )}
      </Paper>
    </TaskTableContainerStyled>
  );
}

export default TaskTableContent;
