import { Popover, styled, keyframes } from "@mui/material";

const changeBackgroundColor = keyframes`

  to {
    fill: #25486D;
    stroke: #25486D;
  }
  from {
    fill: #F7D401;
    stroke: #E02020;
  }
`;
const changeLinealColor = keyframes`
  0% {
    stroke: #black;
  }
  50% {
    stroke: #white;
  }
  100% {
    stroke: #white;
  }
`;

export const AlertTriangleMenuContainerStyled = styled("div")({
  "&.alertTriangleMenuContainer": {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    padding: 8,
    "& .warningIcon": {
      cursor: "pointer",
    },

    "& .backgroundClass": {
      animationName: changeBackgroundColor,
      animationDuration: "60s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      animationTimingFunction: "ease-in-out",
    },
    "& .linealClass": {
      animationName: changeLinealColor,
      animationDuration: "60s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
      animationTimingFunction: "ease-in-out",
    },
  },
});

export const AlertTrianglePopoverContainer = styled(Popover)({
  "&.alertTrianglePopoverContainer": {},
  "& .alertTriangleContainer": {
    borderRadius: 5,
    "& .description-content": {
      padding: "16px 15px",
      fontSize: 12,
      fontWeight: 400,
      color: "#25486D",
      lineHeight: 1.7,
      "& a": {
        color: "#25486D",
        textDecoration: "underline",
      },
      "& img": {
        width: 14,
        height: 14,
        position: "relative",
        top: -2,
      },
    },
  },
});
