export const tableheader = [
  "generic.textForm.firstName",
  "generic.textForm.lastName",
  "generic.textForm.displayName",
  "generic.textForm.cellPhone",
  "generic.textForm.email",
  "generic.textForm.role",
  "generic.textForm.permissionGroup",
  "userscompany.tableheader.analyticsPerson",
  "userscompany.tableheader.automaticSystemUser",
  "generic.textForm.avatar",
  "userscompany.tableheader.active",
];
