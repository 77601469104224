// /**
//  * App Routes
//  */
import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "react-sidebar";
// Components
import SidebarContent from "../Sidebar";

import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import PageTitleBar from "../PageTitleBar/PageTitleBar";
import {
  useCompanies,
  useCompanyId,
  useData,
  useDataNotifications,
  useDataSKU,
  useDataSO,
  useDraftNote,
  useQueryHook,
  useQueryParams,
  useStorage,
} from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import { useTabId, useUser } from "../../hooks/user";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { setGlobalNoteDate } from "../../actions/DataActions";
import { doc, updateDoc } from "firebase/firestore";
import { auth, firestore } from "../../firebase";
import { usePermissions } from "../../hooks/permissions";
import userTypes from "../../api/types/userTypes";
import {
  hasFeatureFlagPermission,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import { ROUTES_PERMISSION } from "../../helpers/constants";
import AttentionModal from "../Modal/AttentionModal";

function TradeDashLayout() {
  const companyId = useCompanyId();
  const user = useUser();
  const tabId = useTabId();
  const location = useLocation();
  const permissions = usePermissions();
  const featureFlags = useFeatureFlags({ companyId });
  const queryParams = useQueryParams();
  const [backdropStorage] = useStorage("backdrop");
  const [noteStorage, setNoteStorage] = useStorage("activityNoteDate");
  const [openModal, setOpenModal] = useState(false);
  const [company = {}] = useCompanies();

  const dispatch = useDispatch();
  useEffect(() => {
    if (backdropStorage && location.pathname.includes("app/dashboard")) {
      if (location.search.includes("taskId")) {
        return;
      }
      if (noteStorage && noteStorage.lastNoteDate) {
        setNoteStorage("lastNoteDate", undefined);
        setNoteDate({
          ...noteStorage.lastNoteDate,
        });
      }
    }
    const handleBeforeUnload = async (event) => {
      event.preventDefault();
      updateDoc(doc(firestore, `${dbTables.USERS}/${user.id}`), {
        checkDraft: true,
      });
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    const onPressFunction = (event) => {
      if (event.key === "/") {
        if (document.getElementById("generic-searchbox")) {
          document.getElementById("generic-searchbox").focus();
        }
      }
    };
    document.addEventListener("keyup", onPressFunction);

    return () => {
      document.removeEventListener("keyup", onPressFunction);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (user && user.companyId && !user.active && !openModal) {
      setOpenModal(true);
      setTimeout(async () => {
        auth.signOut();
        window.location.reload();
      }, 15000);
    }
  }, [user]);

  const setNoteDate = useCallback(({ type, creationDate, noteId }) =>
    setGlobalNoteDate({
      type,
      creationDate,
      noteId,
    })(dispatch)
  );

  useQueryHook(location);
  useDraftNote(tabId);
  useDataSO({
    salesOrderId: queryParams.salesOrderId,
    companyId,
  });

  useDataSKU({
    purchaseOrderId: queryParams.purchaseOrderId,
    companyId,
  });
  useData(
    [dbTables.COMPANIES, companyId, dbTables.PERMISSION_GROUPS],
    [companyId]
  );
  useData(
    [dbTables.COMPANIES, companyId, dbTables.CUSTOMERS_SUMMARY],
    [companyId]
  );
  useData(
    [dbTables.COMPANIES, companyId, dbTables.FACTORIES_SUMMARY],
    [companyId]
  );

  useData([dbTables.COMPANIES, companyId, dbTables.TAGS], [companyId]);
  useData([dbTables.COMPANIES, companyId, dbTables.FEATURE_FLAGS], [companyId]);
  useData([dbTables.COMPANIES, companyId, dbTables.REPORTS], [companyId]);
  useData([dbTables.COMPANIES, companyId, dbTables.GL_ACCOUNTS], [companyId]);

  useData(
    [dbTables.COMPANIES, companyId, dbTables.COMPANY_PERMISSION],
    [companyId]
  );
  useData(
    [dbTables.COMPANIES, companyId, dbTables.AUTOCOMPLETE_LISTS],
    [companyId]
  );

  useData(
    [dbTables.USERS, user.id, dbTables.SALES_ORDER_FAVORITES],
    [companyId]
  );
  useData(
    [dbTables.USERS, user.id, dbTables.PURCHASE_ORDER_FAVORITES],
    [companyId]
  );
  useData([dbTables.USERS, user.id, dbTables.SHIPMENT_FAVORITES], [companyId]);
  useData([dbTables.USERS, user.id, dbTables.REPORT_FAVORITES], [companyId]);
  useData([dbTables.USERS, user.id, dbTables.CUSTOM_SETTINGS], [companyId]);
  useData([dbTables.USERS, user.id, dbTables.DRAFTS], [companyId]);

  useDataNotifications(
    [dbTables.USERS, user.id, dbTables.NOTIFICATIONS],
    [user]
  );
  const roles = useData(
    [dbTables.COMPANIES, companyId, dbTables.ROLES],
    [user]
  );
  useData(
    [
      dbTables.COMPANIES,
      companyId,
      dbTables.ROLES,
      roles.find((role) => role.name === user.role)
        ? roles.find((role) => role.name === user.role).id
        : "",
      dbTables.PERMISSIONS,
    ],
    [user.role, companyId, roles]
  );

  function verifyPermissions() {
    let parsePathName = location.pathname;
    if (parsePathName.includes("/app/factories")) {
      parsePathName = "/app/factories";
    } else if (parsePathName.includes("/app/customers")) {
      parsePathName = "/app/customers";
    } else if (parsePathName.includes("app/admin")) {
      parsePathName = "/app/admin";
    }
    const { permissionId, permissionFlag, alwayShow } =
      ROUTES_PERMISSION[parsePathName] || {};
    if (isTradeDashEmployee(user)) {
      return !!user.observedCompany || alwayShow;
    }
    if (user.role === userTypes.SUPER_ADMIN) {
      return true;
    }
    const checkPermissionFlag = permissionFlag
      ? hasFeatureFlagPermission({
          featureFlags,
          user,
          featureFlagName: permissionFlag,
        })
      : true;

    if (
      !checkPermissionFlag ||
      !company ||
      (!isTradeDashEmployee(user) && !company.enabled)
    ) {
      return false;
    }

    return (
      permissions.some(
        (permission) => permission.enabled && permission.id === permissionId
      ) || alwayShow
    );
  }

  const tradedashEmployeBlocked = () => {
    return isTradeDashEmployee(user) && !user.observedCompany;
  };

  function getTitleBar() {
    const parsePathName = location.pathname;
    if (parsePathName.includes("/app/factories")) {
      return "Vendors";
    } else if (parsePathName.includes("/app/customers")) {
      return "Customers";
    } else if (parsePathName.includes("/app/admin")) {
      return "Administrative Options";
    } else if (parsePathName.includes("/app/sales_orders")) {
      return "Sales Orders";
    } else if (parsePathName.includes("/app/purchase_orders")) {
      return "Purchase Orders";
    } else if (parsePathName.includes("/app/shipments")) {
      return "Shipments";
    } else if (parsePathName.includes("/app/reports")) {
      return "Reports";
    } else if (parsePathName.includes("/app/tasks")) {
      return "Tasks";
    } else if (parsePathName.includes("/app/orders_by_phase")) {
      return "Orders By Phase";
    } else if (parsePathName.includes("/app/dashboard")) {
      return " ";
    } else if (parsePathName.includes("/app/week")) {
      return "Task - Weekly View";
    } else if (parsePathName.includes("/app/expenses")) {
      return "Expenses";
    }
  }
  const extraStyle = tradedashEmployeBlocked()
    ? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "92vh",
      }
    : {};

  return (
    <div className="app">
      {openModal && (
        <AttentionModal
          title="Attention"
          description={
            "Your user was deactivated, therefore you are going to be logged out automatically."
          }
          isOpen={openModal}
          actionModal={false}
        />
      )}
      <div className="app-main-container">
        <Sidebar
          sidebar={<SidebarContent />}
          open={true}
          docked={true}
          onSetOpen={() => {}}
          styles={{
            content: { overflow: "", position: "fixed" },
            root: { overflow: "none" },
          }}
          rootClassName="root-sidebar"
          sidebarClassName="sidebar-class"
        >
          <PageTitleBar title={getTitleBar()} />
          <div className="app-container">
            <div className="rct-app-content">
              <div style={{ backgroundColor: "#F6FBFF", ...extraStyle }}>
                {verifyPermissions() && <Outlet />}
                {tradedashEmployeBlocked() && (
                  <h2 style={{ color: "red" }}>
                    You need to observe a company to access this content
                  </h2>
                )}
              </div>
            </div>
          </div>
        </Sidebar>
      </div>
    </div>
  );
}

export default TradeDashLayout;
