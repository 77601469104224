import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import SalesOrderTask from "../../api/model/salesOrderTask";
import {
  getRandomId,
  reorder,
  listenToData,
  sortObjectsBy,
  isTradeDashEmployee,
  verifyTaskTemplateErrors,
} from "../../helpers/helpers";
import CustomButton from "../Buttons/CustomButton";
import SalesOrderTemplateObj from "../../api/model/salesOrderTemplate";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { useUser, useCompanyUsers } from "../../hooks/user";
import StageSection from "./StageSection";
import { STAGE_LIST } from "../../helpers/constants";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useSummaryFactories } from "../../hooks/factories";
import {
  shouldUpdateVendorTemplate,
  TASK_TEMPLATE_OPTIONS,
} from "../../helpers/salesOrderHelpers";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { unstable_usePrompt } from "react-router-dom";

function SalesOrderTemplate({ salesOrderTemplateId, companyId }) {
  const [taskList, setTaskList] = useState({
    PROPOSAL: [],
    PRE_PRODUCTION: [],
    PRODUCTION: [],
    BOOKING_TRANSIT: [],
    PAYMENT_BILLING: [],
  });
  //Redux data and Actions
  const user = useUser();
  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const summaryFactories = useSummaryFactories();

  const dispatch = useDispatch();
  const listenToSalesOrderTemplates = useCallback(() =>
    listenToData({
      path: [dbTables.COMPANIES, companyId, dbTables.SALES_ORDER_TEMPLATE],
    })(dispatch)
  );
  const [autofocusable, setAutofocusable] = useState(false);

  const [latestVersion, setLatestVersion] = useState(null);
  const [templateChanges, setTemplateChanges] = useState({});
  const [openError, setOpenError] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const combinedList = [
    ...taskList.PROPOSAL,
    ...taskList.PRE_PRODUCTION,
    ...taskList.PRODUCTION,
    ...taskList.BOOKING_TRANSIT,
    ...taskList.PAYMENT_BILLING,
  ];

  useEffect(() => {
    if (salesOrderTemplateId) {
      listenToSalesOrderTemplates();
    }
  }, [salesOrderTemplateId]);

  useEffect(() => {
    resetTemplate();
  }, [companyId]);

  function resetTemplate() {
    getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}`
        ),
        orderBy("version", "desc"),
        limit(1)
      )
    ).then((snapshot) => {
      if (!snapshot.docs[0]) {
        setLatestVersion({ version: 0, id: getRandomId() });
        setTaskList({
          PROPOSAL: [],
          PRE_PRODUCTION: [],
          PRODUCTION: [],
          BOOKING_TRANSIT: [],
          PAYMENT_BILLING: [],
        });

        return;
      }
      const template = snapshot.docs[0].data();
      setLatestVersion(template);
      getDocs(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}/${snapshot.docs[0].id}/${dbTables.SALES_ORDER_TASKS_TEMPLATE}`
        )
      ).then((taskSnap) => {
        let list = taskSnap.docs.map((doc) => doc.data());
        setTaskList({
          PROPOSAL: list
            .sort(sortObjectsBy("listIndex"))
            .filter((item) => item.stage === "PROPOSAL"),
          PRE_PRODUCTION: list
            .sort(sortObjectsBy("listIndex"))
            .filter((item) => item.stage === "PRE_PRODUCTION"),
          PRODUCTION: list
            .sort(sortObjectsBy("listIndex"))
            .filter((item) => item.stage === "PRODUCTION"),
          BOOKING_TRANSIT: list
            .sort(sortObjectsBy("listIndex"))
            .filter((item) => item.stage === "BOOKING_TRANSIT"),
          PAYMENT_BILLING: list
            .sort(sortObjectsBy("listIndex"))
            .filter((item) => item.stage === "PAYMENT_BILLING"),
        });
      });
    });
    setTemplateChanges({});
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (
      !result.destination ||
      (result.source.droppableId === result.destination.droppableId &&
        result.source.index === result.destination.index)
    ) {
      return;
    }
    const sourceStage = result.source.droppableId;
    const destinationStage = result.destination.droppableId;
    if (result.destination.droppableId === result.source.droppableId) {
      const reorderedItems = reorder(
        taskList[destinationStage],
        result.source.index,
        result.destination.index
      );
      setTaskList({
        ...taskList,
        [destinationStage]: reorderedItems.map((item, index) => ({
          ...item,
          listIndex: index + 1,
        })),
      });
      setTemplateChanges({
        ...templateChanges,
        [TASK_TEMPLATE_OPTIONS.TASK_CHANGED_POSITION]: true,
      });
      return;
    }
    const oldList = Array.from(taskList[sourceStage]);
    const newList = Array.from(taskList[destinationStage]);
    newList.splice(result.destination.index, 0, oldList[result.source.index]);
    oldList.splice(result.source.index, 1);
    setTaskList({
      ...taskList,
      [destinationStage]: newList.map((item, index) => ({
        ...item,
        listIndex: index + 1,
        stage: destinationStage,
      })),
      [sourceStage]: oldList.map((item, index) => ({
        ...item,
        listIndex: index + 1,
        stage: sourceStage,
      })),
    });
    setTemplateChanges({
      ...templateChanges,
      [TASK_TEMPLATE_OPTIONS.TASK_CHANGED_POSITION]: true,
    });
    return;
  }

  function addTemplateTask(stage) {
    if (isTradeDashEmployee(user)) {
      setReadOnlyModal(true);
      return;
    }
    const newTask = new SalesOrderTask({
      description: "New Task",
      salesOrderId: salesOrderTemplateId,
      listIndex: taskList[stage].length + 1,
      stage,
      duration: 1,
      offset: 0,
    });
    setTaskList({
      ...taskList,
      [stage]: [...taskList[stage], newTask],
    });
    setTemplateChanges({
      ...templateChanges,
      [TASK_TEMPLATE_OPTIONS.ADD_TASK]: true,
    });
  }

  function onChange(task, field, value, stage) {
    const taskToChangeIndex = taskList[stage].findIndex(
      (item) => item.id === task.id
    );
    const newArray = [...taskList[stage]];
    if (field === "notificationEarly" || field === "notificationLate") {
      const { permissionUsers, permissionGroups, ...rest } = value;
      newArray[taskToChangeIndex] = { ...rest };
    } else {
      newArray[taskToChangeIndex] = { ...task, [field]: value };
    }
    setTaskList({ ...taskList, [stage]: newArray });
    setTemplateChanges({
      ...templateChanges,
      [TASK_TEMPLATE_OPTIONS.CHANGED_DATA]: true,
    });
  }

  function deleteTask(taskId, stage) {
    if (isTradeDashEmployee(user)) {
      setReadOnlyModal(true);
      return;
    }
    setTaskList({
      ...taskList,
      [stage]: taskList[stage]
        .map((item, index) => ({ ...item, listIndex: index + 1 }))
        .filter((item) => item.id !== taskId),
    });
    setTemplateChanges({
      ...templateChanges,
      [TASK_TEMPLATE_OPTIONS.DELETE_TASK]: true,
    });
  }

  function validateCreation() {
    let ableToCreate = true;
    if (
      combinedList.some((item, index) => {
        if (combinedList.findIndex((task) => task.id === item.id) !== 0) {
          return !combinedList.map((task) => task.id).includes(item.dependency);
        } else {
          return false;
        }
      })
    ) {
      ableToCreate = false;
    }
    STAGE_LIST.forEach((stage) => {
      if (
        taskList[stage].some((item) => {
          if (combinedList.findIndex((task) => task.id === item.id) !== 0) {
            return (
              !item.assignedTo ||
              !item.duration ||
              !item.dependency ||
              !item.dependencyType
            );
          } else {
            return !item.assignedTo || !item.duration;
          }
        })
      ) {
        ableToCreate = false;
      }
    });
    if (!ableToCreate) {
      setDescriptionError(" Can't create template with an unassigned task");
      setOpenError(true);
      return false;
    }
    const { status } = verifyTaskTemplateErrors({
      isPOVerifier: false,
      SOTaskTemplate: combinedList,
    });
    if (status === 400) {
      setDescriptionError("Template cannot have a circular dependency");
      setOpenError(true);
      return false;
    }

    return true;
  }

  function updateTemplate() {
    if (!validateCreation()) {
      return;
    }
    if (shouldUpdateVendorTemplate({ changes: templateChanges })) {
      summaryFactories.forEach((summary) => {
        const factories = summary.factories.map((factory) => ({
          ...factory,
          isOutdated: true,
        }));
        updateDoc(summary.ref, {
          factories,
        });
      });
    }
    const batch = writeBatch(firestore);
    const newVersionId = getRandomId();
    batch.set(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}/${newVersionId}`
      ),
      {
        ...new SalesOrderTemplateObj({
          id: newVersionId,
          version: latestVersion ? +latestVersion.version + 1 : 1,
          user: user.id,
        }),
      }
    );

    setLatestVersion({
      ...new SalesOrderTemplateObj({
        id: newVersionId,
        version: latestVersion ? +latestVersion.version + 1 : 1,
      }),
    });

    STAGE_LIST.forEach((stage) => {
      taskList[stage].forEach((item, index) => {
        batch.set(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATE}/${newVersionId}/${dbTables.SALES_ORDER_TASKS_TEMPLATE}/${item.id}`
          ),
          {
            ...item,
            number:
              combinedList.findIndex((indexItem) => indexItem.id === item.id) +
              1,
            dependencyType:
              combinedList.findIndex(
                (indexItem) => indexItem.id === item.id
              ) !== 0
                ? item.dependencyType || ""
                : "",
            dependency:
              combinedList.findIndex(
                (indexItem) => indexItem.id === item.id
              ) !== 0
                ? item.dependency || ""
                : "",
          }
        );
      });
    });

    setTemplateChanges({});
    batch.commit();
  }
  //TODO
  unstable_usePrompt({
    when: !!Object.keys(templateChanges).length,
    message: "Are you sure you want to leave without saving?",
  });
  return (
    <div className="sales-order-container">
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      <CustomSnackbar
        handleCloseSnackbar={() => {
          setOpenError(false);
        }}
        variant="error"
        open={openError}
        message={descriptionError}
      />
      <div className="sales-order-title">
        <span>
          <IntlMessages id="salesordertemplate.title" />
        </span>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        {STAGE_LIST.map((stage) => {
          return (
            <StageSection
              key={stage}
              stage={stage}
              taskList={taskList[stage]}
              onAdd={addTemplateTask}
              companyId={companyId}
              onChangeTask={onChange}
              onDelete={deleteTask}
              combinedList={combinedList}
              autofocus={autofocusable}
              setAutofocus={setAutofocusable}
              companyUsers={companyUsers}
              disabled={isTradeDashEmployee(user)}
            />
          );
        })}
        {Object.keys(templateChanges).length > 0 && (
          <div
            style={{
              position: "sticky",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton onClick={resetTemplate}>
              {"components-salesOrders-salesOrderTemplate-DiscardChanges"}
            </CustomButton>
            <CustomButton onClick={updateTemplate} type="success">
              {
                "components-salesOrders-salesOrderTemplate-SavenewVersionofTemplate"
              }
            </CustomButton>
          </div>
        )}
      </DragDropContext>
    </div>
  );
}

export default SalesOrderTemplate;
