import React from "react";
import ManagementDocuments from "./ManagementDocuments";

function DocumentTemplate({ title, companyID, documents, dbTable, style }) {
  return (
    <div style={{ ...style }}>
      <ManagementDocuments
        companyID={companyID}
        documents={documents}
        title={title}
        dbTable={dbTable}
      />
    </div>
  );
}

export default DocumentTemplate;
