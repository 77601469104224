import React from "react";
import classnames from "classnames";
function GreenSmallSwitch({
  onChange = () => {},
  checked,
  value,
  disabled,
  width,
  height,
  positiveText = "YES",
  negativeText = "NO"
}) {
  return (
    <label className="switch" style={{ width: width || "", height: height || "" }}>
      <input
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        value={value}
        type="checkbox"
      />
      <span
        data-positive={positiveText}
        data-negative={negativeText}
        className={classnames("slider round", { small: width && height })}
        style={{ width: width || "", height: height || "" }}
      />
    </label>
  );
}

export default GreenSmallSwitch;
