import React, { useCallback, useState, useEffect } from "react";
import {
  PurchaseOrderIcon,
  SalesOrderIcon,
  ShipmentOrderIcon,
} from "../../helpers/iconSvgPath";
import { getShipmentBadge } from "../../helpers/shipments";
import { useNavigate } from "react-router-dom";
import {
  setBackdropPanelSection,
  setGlobalNoteDate,
} from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import {
  getDashboardSearchPath,
  getPurchaseOrdersPermission,
  pathToString,
  sortObjectsBy,
} from "../../helpers/helpers";
import { BACKDROP_TYPE, TOOLTIP_TYPE } from "../../helpers/constants";
import { useCompanyId, useQueryParams, useStorage } from "../../hooks";
import {
  getLastActivityDate,
  getShipmentsByPOs,
} from "../../helpers/salesOrderHelpers";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { getUserAvatar } from "../../helpers/users";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { dbTables } from "../../api/types/dbTables";
import { usePurchaseOrders } from "../../hooks/salesOrders";
import { MENTION_POPOVER_SCOPES } from "../../helpers/orderDashboard";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { colors } from "../../assets/jss/variables";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import { MentionPopoverStyled } from "./styles";

const REDIRECT_TO_TYPES = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
};

function MentionPopover({
  item,
  user,
  scope = MENTION_POPOVER_SCOPES.LIST_VIEW,
  shipmentsDB = [],
}) {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const [, setBackdropStorage] = useStorage("backdrop");
  const [, setActivityNoteDate] = useStorage("activityNoteDate");
  const [shipments, setShipments] = useState([]);
  const purchaseOrders = usePurchaseOrders();
  const queryParams = useQueryParams();

  const setShipmentsDB = async () => {
    setIsLoading(true);
    console.log("PURCHASE ORDERS LENGTH: ", purchaseOrders.length);
    const newShipments = await getShipmentsByPOs({
      purchaseOrders,
      path: pathToString([dbTables.COMPANIES, companyId, dbTables.SHIPMENTS]),
    });
    const shipmentsMention = [];

    newShipments.forEach((shipment) => {
      if (shipment.userMentions && shipment.userMentions[user.id]) {
        shipmentsMention.push({
          id: shipment.id,
          quantity: shipment.userMentions[user.id] || 0,
          number: shipment.number,
          purchaseOrderId:
            purchaseOrders.find((po) => po.shipmentIds.includes(shipment.id)) &&
            purchaseOrders.find((po) => po.shipmentIds.includes(shipment.id))
              .id,
        });
      }
    });
    setShipments(shipmentsMention.sort(sortObjectsBy("number", false)));
    setIsLoading(false);
  };

  useEffect(() => {
    if (anchorEl && scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD) {
      setShipmentsDB();
    }
  }, [anchorEl]);

  const handleNoteDateStorage = (data) => {
    setActivityNoteDate("lastNoteDate", {
      ...data,
    });
  };
  const setNoteDate = useCallback(({ type, creationDate, noteId }) =>
    setGlobalNoteDate({
      type,
      creationDate,
      noteId,
    })(dispatch)
  );
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };
  const handleBackdropStorage = (type, id, infoCard = true) => {
    setBackdropStorage("lastBackdrop", {
      type: type,
      isOpen: true,
      id: id || type,
      infoCard: infoCard,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function getFirstPO({ salesOrder = {} }) {
    let firstPO = salesOrder.POsMention.sort(sortObjectsBy("number", true))[0];
    const purchaseOrders = salesOrder.purchaseOrders || [];
    if (firstPO && purchaseOrders.find((po) => po.id === firstPO)) {
      return firstPO;
    }
    firstPO = purchaseOrders.sort(sortObjectsBy("number", true))[0];
    return firstPO || {};
  }

  function getCurrentPOAndShipmentToRedirect({
    salesOrder = {},
    purchaseOrder = {},
    shipment = {},
    type = REDIRECT_TO_TYPES.SALES_ORDER,
    scope = "",
  }) {
    if (scope === "ORDER_DASHBOARD") {
    }
    if (type === REDIRECT_TO_TYPES.SALES_ORDER) {
      const currentPO = getFirstPO({ salesOrder });
      return {
        salesOrderId: salesOrder.id,
        purchaseOrderId: currentPO.id,
        shipmentId: currentPO.shipmentIds[0] || "",
      };
    } else if (type === REDIRECT_TO_TYPES.PURCHASE_ORDER) {
      return {
        salesOrderId: salesOrder.id,
        purchaseOrderId: purchaseOrder.id,
        shipmentId:
          (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds[0]) || "",
      };
    } else if (type === REDIRECT_TO_TYPES.SHIPMENT) {
      return {
        salesOrderId: salesOrder.id,
        purchaseOrderId: shipment.purchaseOrderId,
        shipmentId: shipment.id,
      };
    }
    return {
      salesOrderId: "",
      purchaseOrderId: "",
      shipmentId: "",
    };
  }

  const shipmentsArr =
    scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD
      ? shipments
      : item.shipmentsMention;

  return (
    <>
      <div
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{
          textDecoration: "underline",
          display: "flex",
          justifyContent: "center",
          color: "#25486D",
          cursor: "pointer",
        }}
      >
        {scope === MENTION_POPOVER_SCOPES.LIST_VIEW
          ? item.totalMentions
          : getUserAvatar({
              user,
              styles: {
                width: 22,
                height: 22,
                fontSize: 11,
                cursor: "pointer",
                position: "relative",
                borderRadious: "50%",
                outline: "2px solid #000",
              },
            })}
      </div>
      {open && (
        <MentionPopoverStyled
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: "popoverContainer",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={"mentionPopoverHeader"}>
            <div className="developeIconContainer">
              {getUserAvatar({
                user,
                styles: {
                  width: 18,
                  height: 18,
                  fontSize: 10,
                  outline: "2px solid #000",
                },
                hasToolTip: false,
              })}
            </div>
            <div className="orderIconsContainer">
              <SalesOrderIcon width={18} height={18} />
              <PurchaseOrderIcon width={20} height={21} />
              <ShipmentOrderIcon width={20} height={21} />
            </div>
          </div>

          <div className={"mentionBodyContainer"}>
            {isLoading && <LoadingBackdrop withLogo={false} size={40} />}
            {Object.keys(item.salesOrderMention).length === 0 &&
              item.POsMention.length === 0 &&
              shipmentsArr.length === 0 && (
                <span
                  style={{
                    fontSize: 14,
                    paddingInline: 10,
                    width: 190,
                    display: "flex",
                    textAlign: "center",
                    color: colors.primaryDark,
                    fontWeight: 600,
                  }}
                >
                  You do no have mentions in this entire project
                </span>
              )}
            {Object.keys(item.salesOrderMention).length > 0 && (
              <div className="sales-order-mention">
                <div
                  className="mention-number-container"
                  onClick={async (ev) => {
                    if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                      const { salesOrderId, purchaseOrderId, shipmentId } =
                        getCurrentPOAndShipmentToRedirect({
                          salesOrder: item,
                          type: REDIRECT_TO_TYPES.SALES_ORDER,
                        });
                      const path = getDashboardSearchPath({
                        salesOrderId,
                        purchaseOrderId,
                        shipmentId,
                        section:
                          ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                      });
                      navigate(path);
                      setBackdrop({
                        type: BACKDROP_TYPE.BADGE_NUMBER,
                        id: BACKDROP_TYPE.BADGE_NUMBER,
                      });
                      const orderData = await getLastActivityDate({
                        companyId: item.companyId,
                        user,
                        salesOrderMention: item.salesOrderMention,
                      });
                      if (orderData) {
                        setNoteDate({
                          type: orderData.scope.toLowerCase(),
                          creationDate: orderData.creationDate,
                          noteId: orderData.id,
                        });
                      }
                      if (ev.ctrlKey) {
                        handleBackdropStorage(BACKDROP_TYPE.MENTION);
                        if (orderData) {
                          handleNoteDateStorage({
                            type: orderData.scope.toLowerCase(),
                            creationDate: orderData.creationDate,
                            noteId: orderData.id,
                          });
                        }
                      }
                    } else if (
                      scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD
                    ) {
                      setBackdrop({
                        type: BACKDROP_TYPE.BADGE_NUMBER,
                        id: BACKDROP_TYPE.BADGE_NUMBER,
                        infoCard: false,
                        dashboard: true,
                      });
                      const purchaseOrderId = getPurchaseOrdersPermission({
                        purchaseOrders,
                        queryParams,
                        user,
                      });
                      const path = getDashboardSearchPath({
                        ...queryParams,
                        purchaseOrderId,
                        section:
                          ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                      });
                      navigate(path);
                    }
                    setAnchorEl(null);
                    ev.preventDefault();
                    ev.stopPropagation();
                  }}
                >
                  <div className="quantity-mention">
                    <span style={{ textDecoration: "underline" }}>
                      {item.salesOrderMention.quantity}
                    </span>
                  </div>
                </div>

                <div
                  className="number-mention-container"
                  onClick={(ev) => {
                    if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                      const { salesOrderId, purchaseOrderId, shipmentId } =
                        getCurrentPOAndShipmentToRedirect({
                          salesOrder: item,
                          type: REDIRECT_TO_TYPES.SALES_ORDER,
                        });
                      const path = getDashboardSearchPath({
                        salesOrderId,
                        purchaseOrderId,
                        shipmentId,
                        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                      });
                      navigate(path);
                    } else if (
                      scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD
                    ) {
                      const purchaseOrderId = getPurchaseOrdersPermission({
                        purchaseOrders,
                        queryParams,
                        user,
                      });
                      const path = getDashboardSearchPath({
                        ...queryParams,
                        purchaseOrderId,
                        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                      });
                      navigate(path);
                    }
                    setAnchorEl(null);
                    ev.preventDefault();
                    ev.stopPropagation();
                  }}
                >
                  <div className="number-mention">
                    <span id={item.id}>{item.number}</span>
                  </div>
                </div>
              </div>
            )}

            {item.POsMention.sort(sortObjectsBy("number", false)).map(
              (mention, index) => {
                return (
                  <div className="po-mention" key={mention.id + index}>
                    <div
                      className="mention-number-container"
                      onClick={async (ev) => {
                        console.log("PO MENTION BADGE NUMBER CLICKED", mention);
                        const { salesOrderId, purchaseOrderId, shipmentId } =
                          getCurrentPOAndShipmentToRedirect({
                            salesOrder: item,
                            purchaseOrder: mention,
                            type: REDIRECT_TO_TYPES.PURCHASE_ORDER,
                          });
                        const path = getDashboardSearchPath({
                          salesOrderId,
                          purchaseOrderId,
                          shipmentId,
                          section:
                            ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                        });

                        navigate(path);

                        if (scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD) {
                          setBackdrop({
                            type: BACKDROP_TYPE.BADGE_NUMBER,
                            id: BACKDROP_TYPE.BADGE_NUMBER,
                            infoCard: false,
                            dashboard: true,
                          });
                          setAnchorEl(null);
                          ev.preventDefault();
                          ev.stopPropagation();
                        } else if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                          setBackdrop({
                            type: BACKDROP_TYPE.BADGE_NUMBER,
                            id: BACKDROP_TYPE.BADGE_NUMBER,
                          });
                          const orderData = await getLastActivityDate({
                            companyId: item.companyId,
                            user,
                            POsMention: item.POsMention.filter(
                              (POMention) => POMention.id === mention.id
                            ),
                          });
                          if (orderData) {
                            setNoteDate({
                              type: orderData.scope.toLowerCase(),
                              creationDate: orderData.creationDate,
                              noteId: orderData.id,
                            });
                          }
                          if (ev.ctrlKey) {
                            handleBackdropStorage(BACKDROP_TYPE.MENTION);
                            if (orderData) {
                              handleNoteDateStorage({
                                type: orderData.scope.toLowerCase(),
                                creationDate: orderData.creationDate,
                                noteId: orderData.id,
                              });
                            }
                          }
                        }
                      }}
                    >
                      <div className="quantity-mention">
                        <span style={{ textDecoration: "underline" }}>
                          {mention.quantity}
                        </span>
                      </div>
                    </div>
                    <div className="number-mention-container">
                      <div
                        onClick={(ev) => {
                          console.log("PO MENTION NUMBER", mention);
                          const { salesOrderId, purchaseOrderId, shipmentId } =
                            getCurrentPOAndShipmentToRedirect({
                              salesOrder: item,
                              purchaseOrder: mention,
                              type: REDIRECT_TO_TYPES.PURCHASE_ORDER,
                            });
                          const path = getDashboardSearchPath({
                            salesOrderId,
                            purchaseOrderId,
                            shipmentId,
                            section:
                              ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                          });
                          navigate(path);
                          setAnchorEl(null);
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                        className="number-mention"
                      >
                        <TooltipTD
                          label={mention.factoryName}
                          type={TOOLTIP_TYPE.BADGE}
                          showToolTip={true}
                          tooltipPlacement="top-start"
                        >
                          <span className="quantity-mention" id={mention.id}>
                            {mention.number}
                          </span>
                        </TooltipTD>
                      </div>
                    </div>
                  </div>
                );
              }
            )}

            {shipmentsArr
              .sort(sortObjectsBy("number", false))
              .map((mention, index) => {
                let shipment;
                if (scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD) {
                  shipment = shipmentsArr.find(
                    (shipment) => shipment.id === mention.id
                  );
                } else if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                  shipment = shipmentsDB.find(
                    (shipment) => shipment.id === mention.id
                  );
                }
                return (
                  <div className="shipment-mention" key={mention.id + index}>
                    <div className="mention-number-container">
                      <div
                        className="quantity-mention"
                        onClick={async (ev) => {
                          setAnchorEl(null);
                          const { salesOrderId, purchaseOrderId, shipmentId } =
                            getCurrentPOAndShipmentToRedirect({
                              salesOrder: item,
                              type: REDIRECT_TO_TYPES.SHIPMENT,
                              shipment: mention,
                            });
                          const path = getDashboardSearchPath({
                            salesOrderId,
                            purchaseOrderId,
                            shipmentId,
                            section:
                              ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                          });
                          navigate(path);

                          if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                            setBackdrop({
                              type: BACKDROP_TYPE.BADGE_NUMBER,
                              id: BACKDROP_TYPE.BADGE_NUMBER,
                            });
                            const orderData = await getLastActivityDate({
                              companyId: item.companyId,
                              user,
                              shipmentsMention: mention,
                            });
                            if (orderData) {
                              setNoteDate({
                                type: orderData.scope.toLowerCase(),
                                creationDate: orderData.creationDate,
                                noteId: orderData.id,
                              });
                            }
                            if (ev.ctrlKey) {
                              handleBackdropStorage(BACKDROP_TYPE.MENTION);
                              if (orderData) {
                                handleNoteDateStorage({
                                  type: orderData.scope.toLowerCase(),
                                  creationDate: orderData.creationDate,
                                  noteId: orderData.id,
                                });
                              }
                            }
                          } else if (
                            scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD
                          ) {
                            setBackdrop({
                              type: BACKDROP_TYPE.BADGE_NUMBER,
                              id: BACKDROP_TYPE.BADGE_NUMBER,
                              infoCard: false,
                              dashboard: true,
                            });
                          }
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                      >
                        <span style={{ textDecoration: "underline" }}>
                          {mention.quantity}
                        </span>
                      </div>
                    </div>
                    <div className="number-mention-container">
                      <div
                        onClick={async (ev) => {
                          console.log("SHIPMENT NUMBER 11", mention);
                          setAnchorEl(null);
                          const { salesOrderId, purchaseOrderId, shipmentId } =
                            getCurrentPOAndShipmentToRedirect({
                              salesOrder: item,
                              type: REDIRECT_TO_TYPES.SHIPMENT,
                              shipment: mention,
                            });
                          const path = getDashboardSearchPath({
                            salesOrderId,
                            purchaseOrderId,
                            shipmentId,
                            section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                          });
                          navigate(path);
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                        className="number-mention"
                      >
                        {getShipmentBadge({
                          shipment: shipment,
                          styles: { margin: 0 },
                          classes: shipmentBadgeClass.medium,
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </MentionPopoverStyled>
      )}
    </>
  );
}

export default MentionPopover;
