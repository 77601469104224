import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { useBackdropState } from "../../../hooks/user";
import { eraseBackdropData } from "../../../actions/DataActions";
import { useDispatch } from "react-redux";
import { Backdrop, styled } from "@mui/material";

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  "&.backdropRoot": {
    zIndex: 1200,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 500ms !important",
    opacity: 0,
  },
}));

export const BackdropDashboard = ({ backdropType, initTimer = 500 }) => {
  const backdropState = useBackdropState();
  const dispatch = useDispatch();

  const [showBackdrop, setShowBackdrop] = useState(false);
  const eraseBackdrop = useCallback(() => eraseBackdropData({})(dispatch));

  useEffect(() => {
    let timeout;
    if (showBackdrop) {
      timeout = setTimeout(() => {
        setShowBackdrop(false);
        eraseBackdrop();
      }, 1800);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showBackdrop]);

  useEffect(() => {
    if (backdropState.type === backdropType && backdropType !== "EMPTY") {
      handleClick();
    }
  }, [backdropState.type, backdropType]);

  const handleClick = () => {
    setTimeout(() => {
      setShowBackdrop(true);
    }, initTimer);
  };

  return (
    <BackdropStyled
      id={backdropType + "backdrop"}
      className="backdropRoot"
      open={showBackdrop}
    />
  );
};
