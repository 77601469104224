import React from "react";
import { Popover } from "@mui/material";
import PropTypes from "prop-types";
import TooltipTD from "../Tooltip/TooltipTD";
import { getUserAvatar } from "../../helpers/users";
import userTypes from "../../api/types/userTypes";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";

function UserPicker({
  el,
  open,
  onClose,
  onKeyDown,
  users,
  onChange,
  isTherePermission,
  permissionToVerify,
  isCustomer,
  value,
}) {
  function verifyPermision(companyUser, permissionToVerify) {
    const globalPermission = isCustomer
      ? GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS
      : GENERAL_PERMISSION_VALUE.ALL_VENDORS;
    const userPermissions = companyUser.permissions || {};
    if (companyUser.role === userTypes.SUPER_ADMIN) {
      return false;
    } else if (
      !userPermissions[permissionToVerify] &&
      !userPermissions[globalPermission]
    ) {
      return true;
    } else return false;
  }

  return (
    <Popover
      anchorEl={el}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      open={open}
      className="user-picker"
      onClose={() => onClose()}
      onKeyDown={onKeyDown}
      onKeyPress={(ev) => {
        const user = users.find(
          (user) => user.displayName[0].toLowerCase() === ev.key.toLowerCase()
        );
        if (user) {
          const userElement = document.getElementById(user.id);
          userElement.scrollIntoView();
        }
      }}
    >
      <ul>
        {users.map((companyUser) => {
          return (
            <li
              id={companyUser.id}
              key={companyUser.id}
              onClick={(ev) => onChange(companyUser.id, ev)}
              className={
                isTherePermission &&
                verifyPermision(companyUser, permissionToVerify)
                  ? "user-disable"
                  : companyUser.id === value
                  ? "user-selected"
                  : "user-option"
              }
            >
              {getUserAvatar({
                user: companyUser,
                styles: {
                  width: 30,
                  height: 30,
                  fontSize: 15,
                  outline: "2px solid #000",
                  marginRight: 4,
                },
              })}
              {verifyPermision(companyUser, permissionToVerify) ? (
                <TooltipTD
                  label={`This user does not have permission to see this ${
                    isCustomer ? "customer" : "vendor"
                  }`}
                >
                  <p>{companyUser.displayName}</p>
                </TooltipTD>
              ) : (
                <p>{companyUser.displayName}</p>
              )}
            </li>
          );
        })}
      </ul>
    </Popover>
  );
}

UserPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UserPicker;
