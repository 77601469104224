import React, { useEffect, useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import TagsModal from "../Modal/TagsModal";
import {
  getFactoryName,
  getTagsLabel,
  hasFeatureFlagPermission,
} from "../../helpers/helpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  BACKDROP_TYPE,
  featureFlagNames,
  GENERAL_PERMISSION_VALUE,
  PO_STATUS,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import { dbTables } from "../../api/types/dbTables";
import { useTags } from "../../hooks/tags";
import { getTagStatus, orderType } from "../../helpers/salesOrder";
import {
  getPOShipments,
  getShipmentBadge,
  orderNavList,
} from "../../helpers/shipments";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useFactories } from "../../hooks/factories";
import TooltipTD from "../Tooltip/TooltipTD";
import { colors } from "../../assets/jss/variables";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../actions/DataActions";
import { activityScopes } from "../../helpers/activitiesStream";
import { TitleNavDetail } from "./TitleNavDetail";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { useCustomers } from "../../hooks/customers";
import {
  getAllMentions,
  getOrderByIds,
  MENTION_POPOVER_SCOPES,
} from "../../helpers/orderDashboard";
import { ExpensesIcon, VoidLabel } from "../../helpers/iconSvgPath";
import ExpensesModal from "./Expenses/ExpensesModal";
import { useFavoritesList, useQueryParams } from "../../hooks";
import NavPickerv2 from "../DatePicker/NavPickerv2";
import MentionPopover from "../../container/SalesOrdersScreen/MentionPopover";
import { cx } from "@emotion/css";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";

function DashboardTitlev2({
  currentSalesOrder,
  customer,
  isReadOnly = false,
  currentPurchaseOrder = {},
  currentShipment = {},
  purchaseOrders,
  shipments,
  companyId,
  handleLoading,
  customerPermission,
  vendorPermission,
  user,
}) {
  const soFavorites = useFavoritesList({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const queryParam = useQueryParams();
  const tags = useTags();
  const featureFlags = useFeatureFlags({ companyId });
  const isAllowed = useIsAllowedFunction();
  const [anchorEl, setAnchorEl] = useState(null);
  const [salesOrders, setSalesOrders] = useState([]);
  const [scope, setScope] = useState("");
  const [openExpenses, setOpenExpenses] = useState({
    collection: "",
    documentId: "",
  });
  const factories = useFactories();
  const customers = useCustomers();
  const dispatch = useDispatch();
  const setBackdropNumberMention = () =>
    setBackdropPanelSection({
      type: BACKDROP_TYPE.BADGE_NUMBER,
      isOpen: true,
      id: BACKDROP_TYPE.BADGE_NUMBER,
      dispatch,
    });

  useEffect(() => {
    const getSOFavorites = async () => {
      const favoriteSalesOrders = await getOrderByIds({
        collection: dbTables.SALES_ORDERS,
        companyId,
        list: soFavorites,
      });
      setSalesOrders(favoriteSalesOrders);
      handleLoading(false);
      if (favoriteSalesOrders.length === 0) {
        setAnchorEl(null);
      }
    };
    if (anchorEl && scope === activityScopes.SALES_ORDER) {
      handleLoading(true);
      getSOFavorites();
    }
  }, [anchorEl]);

  const currentShipments = getPOShipments({
    shipments,
    purchaseOrder: currentPurchaseOrder,
  });

  const shipmentsIds = currentPurchaseOrder.shipmentIds || [];
  const vendor =
    getFactoryName(factories, currentPurchaseOrder.factoryId) || "";

  const salesOrderIsVoid =
    currentSalesOrder.status === SALES_ORDER_STATUS.VOIDED;

  function getAllShipmentsFromPOs({ purchaseOrders, shipments }) {
    const purchaseOrderIds = purchaseOrders.map((po) => po.id);
    const shipmentsFromPOs = shipments.filter((shipment) =>
      shipment.purchaseOrderIds.some((poId) => purchaseOrderIds.includes(poId))
    );
    return shipmentsFromPOs;
  }

  function isExpensesAllowed() {
    const enabledFeatureFlag = hasFeatureFlagPermission({
      featureFlags,
      user,
      featureFlagName: featureFlagNames.EXPENSES_V1,
    });
    const canEnterExpenseManually = isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_MANUALLY
    );
    const canEnterExpenseViaDragAndDrop = isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP
    );
    const canSeeAllExpenses = isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_EXPENSES
    );
    const canSeeOnlyExpensesEnteredBySelf = isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF
    );
    return (
      enabledFeatureFlag &&
      (canEnterExpenseManually ||
        canEnterExpenseViaDragAndDrop ||
        canSeeAllExpenses ||
        canSeeOnlyExpensesEnteredBySelf)
    );
  }

  function getSalesOrderTotalTasks({ salesOrder = {}, purchaseOrders = [] }) {
    let totalTasks = parseInt(salesOrder.totalTasks);
    let completedTasks = parseInt(salesOrder.completedTasks);
    purchaseOrders
      .filter((po) => po.status !== PO_STATUS.VOIDED)
      .forEach((po) => {
        totalTasks += parseInt(po.totalTasks);
        completedTasks += parseInt(po.completedTasks);
      });
    return { ...salesOrder, totalTasks, completedTasks };
  }

  const currentList = orderNavList({
    customers,
    factories,
    purchaseOrders,
    queryParam,
    salesOrders,
    shipmentClass: shipmentBadgeClass.medium,
    shipments: currentShipments,
    user,
  });

  const currentOrder = {
    [dbTables.SALES_ORDERS]: currentSalesOrder,
    [dbTables.PURCHASE_ORDERS]: currentPurchaseOrder,
    [dbTables.SHIPMENTS]: currentShipment,
  };

  return (
    <div id="title-dashboard" className="title-dashboard">
      {hasFeatureFlagPermission({
        featureFlags,
        user,
        featureFlagName: featureFlagNames.EXPENSES_V1,
      }) &&
        openExpenses &&
        openExpenses.collection && (
          <ExpensesModal
            open={!!openExpenses.collection}
            className={""}
            onClose={() =>
              setOpenExpenses({ collection: null, documentId: null })
            }
            companyId={companyId}
            collection={openExpenses.collection}
            documentId={openExpenses.documentId}
            order={currentOrder[openExpenses.collection]}
          />
        )}
      {anchorEl && (
        <NavPickerv2
          anchorEl={anchorEl}
          open={!!anchorEl}
          list={currentList[scope].list}
          onClose={() => {
            setAnchorEl(null);
          }}
          selectedId={currentList[scope].id}
          scope={currentList[scope].scope}
          sizeNumber={currentList[scope].sizeNumber}
          sizeSubtitle={currentList[scope].sizeSubtitle}
          handleDateActivities={setBackdropNumberMention}
          user={user}
          onChange={() => {}}
        />
      )}

      <TitleNavDetail
        currentSalesOrder={getSalesOrderTotalTasks({
          salesOrder: currentSalesOrder,
          purchaseOrders,
        })}
        purchaseOrders={purchaseOrders}
        companyId={companyId}
      />

      <div
        className={cx(
          "title-list-SO",
          soFavorites.length > 0 ? "title-list-SO-active" : ""
        )}
        onClick={async (ev) => {
          setAnchorEl(ev.currentTarget);
          setScope(activityScopes.SALES_ORDER);
          ev.persist();
        }}
      >
        {salesOrderIsVoid && <VoidLabel />}

        <MentionPopover
          item={{
            ...currentSalesOrder,
            ...getAllMentions({
              salesOrder: currentSalesOrder,
              purchaseOrders: purchaseOrders,
              shipments: getAllShipmentsFromPOs({
                purchaseOrders,
                shipments,
              }),
              userId: user.id,
              factories: factories,
            }),
          }}
          user={user}
          scope={MENTION_POPOVER_SCOPES.ORDER_DASHBOARD}
        />

        {isAllowed("see_tags") && tags && (
          <TagsModal
            key={currentSalesOrder.id}
            label={getTagsLabel(tags, currentSalesOrder.tags).tagsString}
            tags={getTagsLabel(tags, currentSalesOrder.tags).tagsArray}
            allTags={tags}
            item={currentSalesOrder}
            currentUser={user}
            tagReference={dbTables.SALES_ORDERS}
            onUpdateItem={() => {}}
            isThereItemRef={true}
            isReadOnly={isReadOnly}
            tooltipPlacement="bottom"
            status={getTagStatus({
              tags: currentSalesOrder.tags,
              currentTags: [],
              renderTag: true,
            })}
            containerStyle={{
              fontSize: "15px !important",
            }}
          />
        )}
        {isExpensesAllowed() && (
          <ExpensesIcon
            iconId="sales-order-expense-icon"
            style={{ marginRight: 6 }}
            onClick={(ev) => {
              setOpenExpenses({
                collection: dbTables.SALES_ORDERS,
                documentId: currentSalesOrder.id,
              });
              ev.preventDefault();
              ev.stopPropagation();
            }}
            width={18}
            height={17}
          />
        )}
        <span
          className="responsiveHiddenText"
          style={{ color: colors.darkGray }}
        >
          <IntlMessages
            id={
              currentSalesOrder.type === orderType.QUOTE
                ? "order.type.quote"
                : "order.type.sales.order"
            }
          />
          : &nbsp;
        </span>
        <span>{currentSalesOrder.number}</span>
        <span style={{ width: 16 }} />
        {customer && (
          <React.Fragment>
            <span
              className="responsiveHiddenText"
              style={{ color: colors.darkGray }}
            >
              Customer: &nbsp;
            </span>
            <span className="responsiveHiddenText">
              {customer.number}&nbsp;&nbsp;
            </span>
            <span className="strong-text">{customer.name}</span>
          </React.Fragment>
        )}
        {soFavorites.length > 0 && (
          <TooltipTD
            label={"Sales Orders that have a star are listed in this menu "}
            hasArrow={false}
          >
            <KeyboardArrowDownIcon
              className="arrowIcon"
              width={12}
              style={{ marginLeft: 4, marginTop: 5 }}
            />
          </TooltipTD>
        )}
      </div>
      {currentPurchaseOrder &&
        currentPurchaseOrder.id &&
        customerPermission && (
          <React.Fragment>
            <div
              className="title-list-PO"
              onClick={(ev) => {
                setAnchorEl(ev.currentTarget);
                setScope(activityScopes.PURCHASE_ORDER);
                ev.stopPropagation();
              }}
              id="long-button"
            >
              {currentPurchaseOrder.status === SALES_ORDER_STATUS.VOIDED && (
                <VoidLabel />
              )}
              {isAllowed("see_tags") && tags && (
                <TagsModal
                  key={currentPurchaseOrder.id}
                  label={
                    getTagsLabel(tags, currentPurchaseOrder.tags).tagsString
                  }
                  tags={getTagsLabel(tags, currentPurchaseOrder.tags).tagsArray}
                  allTags={tags}
                  item={currentPurchaseOrder}
                  currentUser={user}
                  tagReference={dbTables.SALES_ORDERS}
                  onUpdateItem={() => {}}
                  isThereItemRef={true}
                  isReadOnly={isReadOnly}
                  tooltipPlacement="bottom"
                  status={getTagStatus({
                    tags: currentPurchaseOrder.tags,
                    currentTags: [],
                    renderTag: true,
                  })}
                  containerStyle={{
                    fontSize: "15px !important",
                  }}
                />
              )}
              {isExpensesAllowed() && (
                <ExpensesIcon
                  iconId="purchase-order-expense-icon"
                  style={{ marginRight: 6 }}
                  onClick={(ev) => {
                    setOpenExpenses({
                      collection: dbTables.PURCHASE_ORDERS,
                      documentId: currentPurchaseOrder.id,
                    });
                    ev.preventDefault();
                    ev.stopPropagation();
                  }}
                  width={18}
                  height={17}
                />
              )}
              <span
                style={{ color: colors.darkGray }}
                className="responsiveHiddenText"
              >
                <IntlMessages
                  id={
                    currentPurchaseOrder.type === orderType.PURCHASE_QUOTE
                      ? "order.type.purchase.quote"
                      : "order.type.purchase.po"
                  }
                />
                : &nbsp;
              </span>
              <span style={{ maxWidth: "unset" }} className="strong-text">
                {currentPurchaseOrder.number}
              </span>
              <span style={{ width: 16 }} />
              {vendor && (
                <React.Fragment>
                  <span
                    className="responsiveHiddenText"
                    style={{ color: colors.darkGray }}
                  >
                    Vendor: &nbsp;
                  </span>
                  <span className="strong-text">{vendor}</span>
                </React.Fragment>
              )}
              <KeyboardArrowDownIcon
                className="arrowIcon"
                width={12}
                style={{ marginLeft: 4 }}
              />
            </div>
            {currentShipment &&
              currentShipment.id &&
              vendorPermission &&
              shipmentsIds.includes(currentShipment.id) && (
                <div
                  className="title-list-Shipment"
                  onClick={(ev) => {
                    setAnchorEl(ev.currentTarget);
                    setScope(activityScopes.SHIPMENT);
                  }}
                >
                  {isAllowed("see_tags") && tags && (
                    <TagsModal
                      key={currentShipment.id}
                      label={
                        getTagsLabel(tags, currentShipment.tags).tagsString
                      }
                      tags={getTagsLabel(tags, currentShipment.tags).tagsArray}
                      allTags={tags}
                      item={currentShipment}
                      currentUser={user}
                      tagReference={dbTables.SALES_ORDERS}
                      onUpdateItem={() => {}}
                      isThereItemRef={true}
                      isReadOnly={isReadOnly}
                      tooltipPlacement="bottom"
                      status={getTagStatus({
                        tags: currentShipment.tags,
                        currentTags: [],
                        renderTag: true,
                      })}
                      containerStyle={{
                        fontSize: "15px !important",
                      }}
                    />
                  )}
                  {isExpensesAllowed() && (
                    <ExpensesIcon
                      iconId="shipment-expense-icon"
                      style={{ marginRight: 6 }}
                      onClick={(ev) => {
                        setOpenExpenses({
                          collection: dbTables.SHIPMENTS,
                          documentId: currentShipment.id,
                        });
                        ev.preventDefault();
                        ev.stopPropagation();
                      }}
                      width={18}
                      height={17}
                    />
                  )}
                  <span
                    className="responsiveHiddenText"
                    style={{ color: colors.darkGray }}
                  >
                    Shipment :&nbsp;
                  </span>
                  {getShipmentBadge({
                    shipment: currentShipment,
                    classes: "shipmentBadgeClasses",
                    tooltipPlacement: "bottom",
                    styles: { margin: 0 },
                  })}
                  <span style={{ width: 16 }} />
                  {currentShipment.finalDestination && (
                    <React.Fragment>
                      <span
                        style={{ color: colors.darkGray }}
                        className="responsiveHiddenText"
                      >
                        Final Destination: &nbsp;
                      </span>
                      <span className="strong-text">
                        {currentShipment.finalDestination}
                      </span>
                    </React.Fragment>
                  )}
                  <KeyboardArrowDownIcon
                    className="arrowIcon"
                    width={12}
                    style={{ marginLeft: 4 }}
                  />
                </div>
              )}
          </React.Fragment>
        )}
    </div>
  );
}

export default DashboardTitlev2;
