import { ACTIVITIES_STREAM_STATUS, ACTIVITIES_STREAM_TYPE } from "./constants";
import Activity from "../api/model/activity";
import { documentScope } from "./documents";
import { dbTables } from "../api/types/dbTables";
import { firestore } from "../firebase";
import { orderType, salesOrderTriggerTypes } from "./salesOrder";
import { purchaseOrderTriggerTypes } from "./purchaseOrder";
import moment from "moment";
import { getCartons } from "./orderDashboardRefactored";
import { doc, setDoc, updateDoc } from "firebase/firestore";

const getNewItemDetail = ({ shipmentItem, item }) => {
  const detail = `  <p><strong>Shipment:</strong> <span class="mentionsShipmenClassUpdated"> ${
    shipmentItem.number
  }</span> <br/>
  <p><strong>Item:</strong> ${item.itemNumber} <br/>
  <strong>Desc:</strong> ${item.description} <br/>
  <strong>Quantity:</strong> ${shipmentItem.allocation} <br/>
  <strong>Cartons:</strong> ${getCartons({
    quantity: shipmentItem.allocation,
    pieces: item.piecesPerMaster,
  }).toFixed(1)} <br/>
  <strong>Cost:</strong> ${item.unitCost} <br/>
  <strong>Total CBM:</strong> ${item.cbmMaster} <br/>
  <strong>Total Weight:</strong> ${item.weight}</p>`;
  return detail;
};

const getUpdateItemDetail = ({
  oldValue,
  newValue,
  item,
  shipmentItem = {},
}) => {
  const detail = `<p><strong><span class="mentionsShipmenClassUpdated"> ${
    shipmentItem.number
  }</span> - ${item.itemNumber} - ${item.description} </strong></br>
   From
   <strong>Quantity </strong> from <strong>${oldValue}</strong> to <strong>${
    newValue === 0 ? 0 : newValue || "BLANK"
  }</strong>
  </p>`;
  return detail;
};

export const createShipmentActivityStream = ({
  shipmentItem,
  oldValue,
  newValue,
  type,
  saleOrderId,
  purchaseOrder,
  user,
  item,
  companyUsers = [],
  companyId = "",
}) => {
  const activityRef = `${dbTables.COMPANIES}/${user.companyId}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.ACTIVITIES}`;
  const unreadActivityUsers = purchaseOrder.unreadActivityUsers || [];
  let purchaseOrderCpy = {
    ...purchaseOrder,
    unreadActivityUsers: [...unreadActivityUsers],
  };
  switch (type) {
    case ACTIVITIES_STREAM_STATUS.NEW:
      const newActivity = {
        ...new Activity({
          detail: getNewItemDetail({ shipmentItem, item }),
          scope: purchaseOrder.type,
          type: ACTIVITIES_STREAM_TYPE.NEW_ITEM_SHIPMENT,
          user: user.id,
          companyId: user.companyId,
          shipmentId: shipmentItem.id,
        }),
      };
      updateCompanyUsersUnreadActivity({
        activity: newActivity,
        companyId,
        users: companyUsers,
        order: purchaseOrderCpy,
      });
      return setDoc(doc(firestore, `${activityRef}/${newActivity.id}`), {
        ...newActivity,
      });
    case ACTIVITIES_STREAM_STATUS.UPDATED:
      const updateActivity = {
        ...new Activity({
          detail: getUpdateItemDetail({
            item,
            oldValue,
            newValue,
            shipmentItem,
          }),
          scope: purchaseOrder.type,
          type: ACTIVITIES_STREAM_TYPE.UPDATED_ITEM_SHIPMENT,
          user: user.id,
          companyId: user.companyId,
          shipmentId: shipmentItem.id,
        }),
      };
      updateCompanyUsersUnreadActivity({
        activity: updateActivity,
        companyId,
        users: companyUsers,
        order: purchaseOrderCpy,
      });
      return setDoc(doc(firestore, `${activityRef}/${updateActivity.id}`), {
        ...updateActivity,
      });
    default:
      const eraseActivity = {
        ...new Activity({
          detail: getUpdateItemDetail({
            item,
            oldValue,
            newValue: 0,
            shipmentItem,
          }),
          scope: documentScope.PURCHASE_ORDER,
          type: ACTIVITIES_STREAM_TYPE.UPDATED_ITEM_SHIPMENT,
          user: user.id,
          companyId: user.companyId,
          shipmentId: shipmentItem.id,
        }),
      };
      updateCompanyUsersUnreadActivity({
        activity: eraseActivity,
        companyId,
        users: companyUsers,
        order: purchaseOrderCpy,
      });
      return setDoc(doc(firestore, `${activityRef}/${eraseActivity.id}`), {
        ...eraseActivity,
      });
  }
};

export const activityScopes = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
  QUOTE: "QUOTE",
  PURCHASE_QUOTE: "PURCHASE_QUOTE",
};

export async function updateCompanyUsersUnreadActivity({
  users,
  activity,
  companyId,
  order = {},
}) {
  if (!order.type) {
    return;
  }
  const unreadActivityUsersCpy = order.unreadActivityUsers || [];
  users.forEach((user) => {
    const unreadActivity = unreadActivityUsersCpy.find(
      (unreadActivity) => unreadActivity.userId === user.id
    );
    if (!unreadActivity) {
      unreadActivityUsersCpy.push({
        userId: user.id,
        activityId: activity.id,
        creationDate: activity.creationDate,
      });
    }
  });
  if (order.type === orderType.SALES_ORDER || order.type === orderType.QUOTE) {
    updateDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${order.id}`
      ),
      {
        unreadActivityUsers: unreadActivityUsersCpy,
        triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
      }
    );
  } else if (
    order.type === orderType.PURCHASE_ORDER ||
    order.type === orderType.PURCHASE_QUOTE
  ) {
    updateDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${order.id}`
      ),
      {
        unreadActivityUsers: unreadActivityUsersCpy,
        triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
      }
    );
  } else {
    updateDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${order.id}`
      ),
      {
        unreadActivityUsers: unreadActivityUsersCpy,
        triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
      }
    );
  }
  return;
}

export const activityType = {
  FILE_UPLOAD: "FILE_UPLOAD",
  FILE_UPLOAD_WITHOUT_PERMISSION: "FILE_UPLOAD_WITHOUT_PERMISSION",
  FILE_UPLOAD_WITH_ONE_USER: "FILE_UPLOAD_WITH_ONE_USER",
  PLACEHOLDER_FILE_UPLOAD_WITHOUT_PERMISSION:
    "PLACEHOLDER_FILE_UPLOAD_WITHOUT_PERMISSION",
  PLACEHOLDER_FILE_UPLOAD_WITH_ONE_USER:
    "PLACEHOLDER_FILE_UPLOAD_WITH_ONE_USER",
  PLACEHOLDER_FILE_UPLOAD: "PLACEHOLDER_FILE_UPLOAD",
  FOLDER_UPLOAD: "FOLDER_UPLOAD",
  VERSION_UPLOAD: "VERSION_UPLOAD",
  FILE_NAME_CHANGE: "FILE_NAME_CHANGE",
  FOLDER_NAME_CHANGE: "FOLDER_NAME_CHANGE",
  PROPERTY_CHANGE: "PROPERTY_CHANGE",
  TASK_COMPLETED: "TASK_COMPLETED",
  REASSIGNED_TASK: "REASSIGNED_TASK",
  DELETED_FOLDER: "DELETED_FOLDER",
  DELETED_FILE: "DELETED_FILE",
  TASK_MOVED: "TASK_MOVED",
  FILE_MOVED: "FILE_MOVED",
  FOLDER_MOVED: "FOLDER_MOVED",
  NEW_PO_ENTRY: "NEW_PO_ENTRY",
  FOLDER_CHANGE_PERMISSION: "FOLDER_CHANGE_PERMISSION",
  FILE_CHANGE_PERMISSION: "FILE_CHANGE_PERMISSION",
  TAGS_UPDATE: "TAGS_UPDATE",
  PROPERTY_CHANGE_SHIPMENT: "PROPERTY_CHANGE_SHIPMENT",
  ITEM_UPDATED: "ITEM_UPDATED",
  ITEM_CREATED: "ITEM_CREATED",
  ITEM_DELETED: "ITEM_DELETED",
  MINIMUM_ORDER_QUANTITY: "MINIMUM_ORDER_QUANTITY",
  MOVED_PROJECT_TASKS: "MOVED_PROJECT_TASKS",
  WITHOUT_TITLE: "WITHOUT_TITLE",
  NOTE: "NOTE",
};

export function handleMarkAsRead({
  activity,
  user,
  salesOrder,
  purchaseOrder,
  currentShipment,
}) {
  let userMentionsCpy = activity.userMentions;
  let readByUsersCpy = activity.readByUsers || {};
  userMentionsCpy[user.id] = !userMentionsCpy[user.id];
  // trackEvent(
  //   `PO Dashboard - Activity - ${activity.scope === "SALES_ORDER" ? "SO" : "PO"
  //   } - Read Indicator`
  // );
  if (!readByUsersCpy[user.id]) {
    readByUsersCpy[user.id] = moment().valueOf();
  }
  updateDoc(activity.ref, {
    userMentions: userMentionsCpy,
    readByUsers: readByUsersCpy,
  });

  const soUserMention = salesOrder.userMentions || {};
  const poUserMention = purchaseOrder.userMentions || {};
  const shipmentUserMention = currentShipment.userMentions || {};

  if (activity.userMentions[user.id]) {
    if (
      activity.scope === activityScopes.SALES_ORDER ||
      activity.scope === activityScopes.QUOTE
    ) {
      updateDoc(salesOrder.ref, {
        userMentions: {
          ...soUserMention,
          [user.id]: (soUserMention[user.id] || 1) - 1,
        },
        triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
      });
    } else if (
      activity.scope === activityScopes.PURCHASE_ORDER ||
      activity.scope === activityScopes.PURCHASE_QUOTE
    ) {
      updateDoc(purchaseOrder.ref, {
        userMentions: {
          ...poUserMention,
          [user.id]: (poUserMention[user.id] || 1) - 1,
        },
        triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
      });
    } else {
      updateDoc(currentShipment.ref, {
        userMentions: {
          ...shipmentUserMention,
          [user.id]: (shipmentUserMention[user.id] || 1) - 1,
        },
        triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
      });
    }
  } else {
    if (
      activity.scope === activityScopes.SALES_ORDER ||
      activity.scope === activityScopes.QUOTE
    ) {
      updateDoc(salesOrder.ref, {
        userMentions: {
          [user.id]: (soUserMention[user.id] || 0) + 1,
        },
        triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
      });
    } else if (
      activity.scope === activityScopes.PURCHASE_ORDER ||
      activity.scope === activityScopes.PURCHASE_QUOTE
    ) {
      updateDoc(purchaseOrder.ref, {
        userMentions: {
          ...poUserMention,
          [user.id]: (poUserMention[user.id] || 0) + 1,
        },
        triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
      });
    } else {
      updateDoc(currentShipment.ref, {
        userMentions: {
          ...shipmentUserMention,
          [user.id]: (shipmentUserMention[user.id] || 0) + 1,
        },
        triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
      });
    }
  }
}

export const getLabelScope = (scope) => {
  switch (scope) {
    case activityScopes.SALES_ORDER:
      return "SO";
    case activityScopes.PURCHASE_ORDER:
      return "PO";
    case activityScopes.QUOTE:
      return "QU";
    case activityScopes.PURCHASE_QUOTE:
      return "PQ";
    case activityScopes.SHIPMENT:
      return "SH";
    default:
      return "--";
  }
};

export function alreadyReadNote({ activity = {}, user }) {
  const userMentions = activity.userMentions || {};
  if (userMentions) {
    for (const key in userMentions) {
      if (key === user.id) {
        if (userMentions[user.id]) {
          return "read";
        } else {
          return "unread";
        }
      }
    }
  }
  return false;
}
