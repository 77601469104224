import React, { useState, useEffect } from "react";
import IntlMessages from "../../util/IntlMessages";
import AttentionModal from "../Modal/AttentionModal";
import { regexEmailValidator } from "../../helpers/constants";
import FieldByProperty from "../../components/Inputs/FieldByProperty";
import { updateDoc } from "firebase/firestore";
import { CustomerInfoStyled } from "./styles";

const customerFields = [
  "number",
  "inactive",
  "name",
  "mainContact",
  "jobTitle",
  "email",
  "cellPhone",
  "latePenaltyTerms",
  "notes",
];

function validateEmail(email) {
  /* eslint-disable no-useless-escape */
  return regexEmailValidator.test(String(email).toLowerCase());
}

function CustomerInfo({
  customer = {},
  customers = [],
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleUpdateSummary = () => {},
}) {
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);

  useEffect(() => {
    setCurrentCustomer(customer);
  }, [customer]);

  function handleInactiveCustomer(checked, property) {
    setCurrentCustomer({ ...currentCustomer, [property]: checked });
    updateDoc(customer.ref, { [property]: checked, triggerType: property });
    handleUpdateSummary({ ...customer, [property]: checked });
  }

  function handleSaveCustomer(property) {
    if (currentCustomer[property] === customer[property]) {
      return;
    }
    if (
      property === "email" &&
      currentCustomer[property] !== "" &&
      !validateEmail(currentCustomer[property])
    ) {
      setOpenAttentionModal(true);
      setDescriptionModal("Invalid email format, please choose a valid email");
      setCurrentCustomer({
        ...currentCustomer,
        [property]: customer[property] || "",
      });
    } else if (property === "notes") {
      updateDoc(customer.ref, {
        [property]: currentCustomer[property],
        triggerType: property,
      });
    } else {
      currentCustomer[property] = currentCustomer[property]
        .replace(/\s\s+/g, " ")
        .trim();
      updateDoc(customer.ref, {
        [property]: currentCustomer[property],
        triggerType: property,
      });
    }
  }

  function handleChange(event, property) {
    setCurrentCustomer({ ...currentCustomer, [property]: event.target.value });
  }

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          title="Attention"
          description={descriptionModal}
          isOpen={openAttentionModal}
          onClick={() => setOpenAttentionModal(false)}
          onClose={() => setOpenAttentionModal(false)}
        />
      )}
      <CustomerInfoStyled className="container">
        {customers.length > 0 && (
          <React.Fragment>
            <div className="documents-header">
              <h2>{<IntlMessages id={"general.basicinfo"} />}</h2>
            </div>
            <div className={"horizontalDivider"} />
            <ul>
              {customerFields.map((property) => (
                <li className={"infoField"} key={currentCustomer.id + property}>
                  <span>{<IntlMessages id={`customer.${property}`} />}</span> :{" "}
                  <FieldByProperty
                    property={property}
                    currentItem={currentCustomer}
                    allowedKey={"modify_customers"}
                    isReadOnly={isReadOnly}
                    handleSave={handleSaveCustomer}
                    handleChange={handleChange}
                    handleInactive={handleInactiveCustomer}
                    handleReadOnlyModal={handleReadOnlyModal}
                  />
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </CustomerInfoStyled>
    </React.Fragment>
  );
}

export default CustomerInfo;
