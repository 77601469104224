import React from "react";
import { MilestonePopoverStyled, MilestonesContainerStyled } from "./styles";
import milestoneIcon from "../../assets/flag-icons/milestone-filled-icon.png";

import { milestoneHeader } from "../../helpers/timelineCalendar";
import { collapsedTaskTableColumns } from "../../helpers/constants";
import TooltipTD from "../Tooltip/TooltipTD";
import MilestoneItem from "./MilestoneItem";
import { Button } from "@mui/material";

function Milestones({
  milestones,
  tableSize,
  styles = {},
  onChangeMilestone,
  onDeleteMilestone,
  onAddNewMilestone,
  onCloseMilestone,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <MilestonesContainerStyled className="milestonesContainer">
      <Button
        className={"buttonViewOptions"}
        aria-describedby="simple-popover"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ ...styles }}
      >
        {tableSize !== collapsedTaskTableColumns ? (
          <span>Milestones</span>
        ) : (
          <TooltipTD label={"Milestones"}>
            <img src={milestoneIcon} alt="milestone" />
          </TooltipTD>
        )}
      </Button>
      {open && (
        <MilestonePopoverStyled
          id="simple-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            onCloseMilestone(milestones);
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "milestonesPopover" }}
          className="milestonesPopoverContainer"
        >
          <div className={"milestoneHeader"}>
            {milestoneHeader.map((header) => (
              <div
                key={header.id}
                className="text-container"
                style={{ width: header.width }}
              >
                {header.label}
              </div>
            ))}
          </div>
          <div className={"milestoneBody"}>
            {milestones
              .filter((milestone) => !milestone.isToday)
              .map((milestone) => (
                <MilestoneItem
                  key={milestone.id}
                  milestone={milestone}
                  onChangeMilestone={onChangeMilestone}
                  onDeleteMilestone={onDeleteMilestone}
                />
              ))}
          </div>
          <div className={"milestoneFooter"}>
            <Button
              className="add-milestone"
              onClick={() => {
                if (milestones.length <= 5) {
                  onAddNewMilestone();
                }
              }}
              disabled={milestones.length >= 6}
            >
              {" "}
              + ADD MILESTONE
            </Button>
          </div>
        </MilestonePopoverStyled>
      )}
    </MilestonesContainerStyled>
  );
}

export default Milestones;
