import React, { useState, useEffect } from "react";
import { sortObjectsBy } from "../../helpers/helpers";
import { useCompanyId, usePermissionGroups } from "../../hooks";
import { useCompanyUsers } from "../../hooks/user";
import ModalPermissionUsers from "../Documents/ModalPermissionUsers";
import { CreatePermissionFormNotificationStyled } from "../Reports/styles";
import { Modal } from "@mui/material";

function PermissionsToNofity({ item, isOpen, onClose, onSubmit }) {
  const companyId = useCompanyId();

  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const permissionGroupsDB = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });
  const [permissions, setPermissions] = useState({
    permissionUsers: [],
    permissionGroups: [...permissionGroupsDB],
  });

  useEffect(() => {
    let permissionGroupsCpy = [
      ...permissions.permissionGroups.map((group) => ({
        ...group,
        enable: true,
      })),
    ];
    if (item.permissionGroups && item.permissionUsers) {
      permissionGroupsCpy = permissionGroupsCpy.map((group) => {
        if (item.permissionGroups[group.id]) {
          return { ...group, checked: true, enable: true };
        }
        return { ...group, enable: true, checked: false };
      });
      let permissionUsers = [];
      Object.keys(item.permissionUsers).forEach((key) => {
        const user = companyUsers.find((user) => user.id === key);
        if (user && !permissionUsers.find((pUser) => pUser.id === user.id)) {
          permissionUsers.push(user);
        }
      });
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers,
      });
    } else {
      setPermissions({
        permissionUsers: [],
        permissionGroups: [...permissionGroupsDB],
      });
    }
  }, [item]);

  function getUserSelected(permissions) {
    const userSelected = [...permissions.permissionUsers];
    permissions.permissionGroups.forEach((group) => {
      if (group.checked) {
        Object.keys(group.users).forEach((userId) => {
          const user = companyUsers.find((user) => user.id === userId);
          if (user && !userSelected.find((el) => el.id === userId)) {
            userSelected.push(user);
          }
        });
      }
    });
    return userSelected;
  }

  function handleChange(value, lastValue) {
    console.log("HANDLE CHANGE::: ", value, lastValue);
    value = value.filter((val) => !!val);
    const newElementA = value.filter(comparer(lastValue));
    const newElementB = lastValue.filter(comparer(value));
    const newElement = newElementA.concat(newElementB)[0];
    if (value.length > lastValue.length) {
      const permissionUsersCpy = [...permissions.permissionUsers];
      permissionUsersCpy.push(newElement);
      setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
    } else {
      const { permissionGroups } = permissions;
      if (permissionGroups.filter((group) => group.checked).length <= 0) {
        let permissionUsersCpy = [...permissions.permissionUsers];
        permissionUsersCpy = permissionUsersCpy.filter(
          (cUser) => cUser.id !== newElement.id
        );
        setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
      } else {
        onDisolveGroups(newElement);
      }
    }
  }

  function comparer(array) {
    return function (current) {
      return (
        array.filter((elem) => {
          return elem.id === current.id;
        }).length === 0
      );
    };
  }

  function onDisolveGroups(user) {
    let permissionGroupsCpy = [...permissions.permissionGroups];
    let permissionUsersCpy = [...permissions.permissionUsers];
    let allUsersToMove = {};
    let userBelongsToSomeGroup = false;
    permissionGroupsCpy = permissionGroupsCpy.map((permission) => {
      if (
        !!permission.checked &&
        (permission.users[user.id] || permission.users[user.id] === false)
      ) {
        userBelongsToSomeGroup = true;
        let usersCpy = permission.users;
        usersCpy[user.id] = false;
        Object.keys(usersCpy).forEach((key) => {
          if (usersCpy[key]) {
            allUsersToMove[key] = usersCpy[key];
          }
        });
        return { ...permission, checked: false };
      } else return permission;
    });
    if (!userBelongsToSomeGroup) {
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy.filter(
          (cUser) => cUser.id !== user.id
        ),
      });
    } else if (Object.keys(allUsersToMove).length > 0) {
      onMoveUsersFromDisolvedGroup(allUsersToMove, permissionGroupsCpy);
    } else {
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy.filter(
          (cUser) => cUser.id !== user.id
        ),
      });
    }
  }

  function onMoveUsersFromDisolvedGroup(allUsersToMove, permissionGroupsCpy) {
    permissionGroupsCpy
      .filter((permission) => permission.checked)
      .forEach((permission) => {
        Object.keys(permission.users).forEach(
          (key) => delete allUsersToMove[key]
        );
      });
    const permissionUsersCpy = [...permissions.permissionUsers];
    Object.keys(allUsersToMove).forEach((key) => {
      const user = companyUsers.find((user) => user.id === key);
      if (user && !permissionUsersCpy.find((pUser) => pUser.id === user.id)) {
        permissionUsersCpy.push(user);
      }
    });
    setPermissions({
      permissionGroups: permissionGroupsCpy,
      permissionUsers: permissionUsersCpy,
    });
  }

  function handleChangeGroups(permission, checked) {
    const { permissionGroups } = permissions;
    let permissionGroupsCpy = [...permissionGroups];
    permissionGroupsCpy = permissionGroupsCpy.map((elem) => {
      if (elem.id === permission.id) {
        if (checked) {
          let usersCpy = { ...elem.users };
          Object.keys(usersCpy).map((key) => (usersCpy[key] = true));
          return { ...elem, checked, users: usersCpy };
        } else {
          return { ...elem, checked };
        }
      } else {
        return elem;
      }
    });
    if (checked) {
      let permissionUsersCpy = [...permissions.permissionUsers];
      Object.keys(permission.users).forEach((key) => {
        permissionUsersCpy = permissionUsersCpy.filter(
          (user) => user.id !== key
        );
      });
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy,
      });
    } else {
      setPermissions({
        ...permissions,
        permissionGroups: permissionGroupsCpy,
      });
    }
  }

  function getUserAndGroupPermissionsObj() {
    let permissionUsersObj = {};
    let permissionGroupsObj = {};
    const { permissionUsers, permissionGroups } = permissions;
    permissionUsers.forEach((user) => (permissionUsersObj[user.id] = true));
    permissionGroups.forEach((group) => {
      if (!!group.checked) {
        permissionGroupsObj[group.id] = true;
      }
    });
    return {
      permissionUsers: permissionUsersObj,
      permissionGroups: permissionGroupsObj,
    };
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="modal-container-here"
    >
      <CreatePermissionFormNotificationStyled className="permissionNotificationContainer">
        <div className="modal-container-title">Access Groups</div>
        <div className={"permissionsGroupContainer"}>
          <div className={"permissionsGroupContent"}>
            <div className={"multiselect"}>
              {permissions.permissionGroups
                .sort(sortObjectsBy("name", false))
                .map((permission, index) => (
                  <div
                    key={permission.id + index}
                    className={"permissionContent"}
                  >
                    <input
                      type="checkbox"
                      checked={permission.checked}
                      onChange={(ev) =>
                        handleChangeGroups(permission, ev.target.checked)
                      }
                    />
                    <label
                      onClick={(ev) => {
                        handleChangeGroups(permission, !permission.checked);
                      }}
                      style={{ margin: 4, cursor: "pointer" }}
                    >
                      {permission.name}
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div
            className={"viewPermissionOnUpload"}
            style={{
              width: 500,
            }}
          >
            <ModalPermissionUsers
              users={companyUsers}
              userSelected={getUserSelected(permissions)}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div
          style={{
            paddingTop: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => {
              const { permissionGroups, permissionUsers } =
                getUserAndGroupPermissionsObj();
              onSubmit({
                item: { ...item, permissionGroups, permissionUsers },
              });
            }}
            style={{
              background: "#39F",
            }}
          >
            Save
          </button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </CreatePermissionFormNotificationStyled>
    </Modal>
  );
}

export default PermissionsToNofity;
