import React from "react";
import classNames from "classnames";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { orderType } from "../../helpers/salesOrder";
import { colors } from "../../assets/jss/variables";
import { PREFIX_ITEM_TABLE } from "../../helpers/constants";
import { TableHead, TableRow } from "@mui/material";
import { CellSKUHeader } from "./styles";
import { cx } from "@emotion/css";

export default function HeaderItemTable({
  columnsTable,
  headerClasses,
  multiHeaderId = [],
  sortTable,
  prefix,
  handleSorting,
  showImage,
  handleChange,
  getArrowIcon,
  subHeaders = [],
  handleShowImages,
  purchaseOrders,
  currentShipment,
  isCopyTable,
}) {
  const getHeaderLabel = (column) => {
    if (column.id !== "purchaseOrderId") {
      return column.label;
    }
    const filterPO = purchaseOrders.filter((po) =>
      po.shipmentIds.includes(currentShipment.id)
    );
    const hasRegularPO = filterPO.some(
      (po) => po.type === orderType.PURCHASE_ORDER
    );
    const hasQuotePO = filterPO.some(
      (po) => po.type === orderType.PURCHASE_QUOTE
    );

    if (hasRegularPO && hasQuotePO) {
      return "POs/PQs";
    } else if (hasRegularPO) {
      return "POs";
    } else {
      return "PQs";
    }
  };

  const headerStyle =
    prefix !== PREFIX_ITEM_TABLE.PURCHASE_ORDER
      ? {
          borderBottom: `2px solid ${colors.dividerColor}`,
          display: "block",
        }
      : {};

  return (
    <TableHead>
      <TableRow style={{ ...headerStyle }}>
        {columnsTable.map((column, index) => {
          const headerInlineStyle = column.borderStyle;
          const subHeaders = column.subHeader || [];
          const colSpan = column.multiHeader ? subHeaders.length : 1;
          const columnStyle = column.style || {};
          const headStyle = column.headStyle;
          let rowSpan = 1;
          if (multiHeaderId.length) {
            rowSpan = column.multiHeader ? 1 : 2;
          }
          const isSorting = sortTable.sortBy === column.id;
          return (
            <CellSKUHeader
              id={column.id + "-header"}
              className={classNames("headerRoot", {
                sorting: column.sort,
              })}
              key={`${prefix}_header_${column.id}_principal_${index * 10}`}
              style={{
                ...columnStyle,
                ...headStyle,
                ...headerInlineStyle,
                cursor: column.sort ? "pointer" : "",
              }}
              rowSpan={rowSpan}
              colSpan={colSpan}
              onClick={() => {
                handleSorting(column, isSorting);
              }}
            >
              {column.type === "image" && isCopyTable && (
                <CustomCheckbox
                  checked={showImage}
                  onChange={handleChange}
                  styles={{
                    width: 13,
                    height: 13,
                    margin: "0px 4px",
                    padding: 0,
                    background: "white",
                    boxShadow: "1px 1px 1px rgb(0 0 0 / 25%)",
                    borderRadius: 0,
                  }}
                  size="small"
                />
              )}
              <span
                id="headerTable"
                className={cx({ typeImage: column.type === "image" })}
                onClick={() => {
                  if (column.type === "image") {
                    handleShowImages(!showImage);
                  }
                }}
              >
                {getHeaderLabel(column)}
                {sortTable.orderBy === column.id ? (
                  <span className="visuallyHidden">
                    {!sortTable.asc ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </span>
              {column.sort && getArrowIcon(isSorting, column.arrowStyle)}
            </CellSKUHeader>
          );
        })}
      </TableRow>
      {multiHeaderId.length > 0 && subHeaders.length > 0 && (
        <TableRow>
          {subHeaders.map((subColumn, index) => (
            <CellSKUHeader
              className="headerRoot"
              key={`${prefix}_subHeader_${(subColumn.number, index)}`}
              style={{
                ...subColumn.columnStyle,
                ...subColumn.headerStyle,
                position: "sticky",
                top: 26.5,
                border: 0,
              }}
              id="headerTable"
            >
              {subColumn.label}
            </CellSKUHeader>
          ))}
        </TableRow>
      )}
    </TableHead>
  );
}
