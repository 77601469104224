import React from "react";
import folder from "../../assets/flag-icons/folder-placeholder.svg";
import { CreateNewFolderStyled } from "./styles";
import RenameFoldersFiles from "../TextFields/RenameFoldersFiles";
import { ListItem, ListItemIcon } from "@mui/material";

function CreateNewFolder({
  folderObj = {},
  handleSaveFolder,
  handleCancelSaveFolder,
  onChange,
}) {
  return (
    <CreateNewFolderStyled className="listem-item-document">
      <ListItem
        className={"listItemFolder"}
        style={{ padding: "0px 0px 0px 8px" }}
      >
        <ListItemIcon className={"listItemIcon"}>
          <img src={folder} className={"imgListItemIcon"} alt="folder" />
        </ListItemIcon>
        <RenameFoldersFiles
          editable={true}
          onChange={onChange}
          autoFocus={true}
          editing={true}
          defaultChildren="New Folder"
          onValid={handleSaveFolder}
          onClose={handleCancelSaveFolder}
          file={folderObj}
          folderName={folderObj.name}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "450px",
          }}
        />
      </ListItem>
    </CreateNewFolderStyled>
  );
}

export default CreateNewFolder;
