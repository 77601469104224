import React, { useState } from "react";
import TaskCategory from "./TaskCategory";
import taskStages from "../../api/types/taskStages";
import Task from "./Task";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import AttentionModal from "../Modal/AttentionModal";
import { sortObjectsBy, getRandomId } from "../../helpers/helpers";
import { now } from "moment";
import { useUser } from "../../hooks/user";
import {
  getDetailFromMoveDependantTask,
  triggerTaskTypes,
} from "../../helpers/tasks";
import { actionType, dependencyTypesOnMove } from "../../helpers/timelineModal";
import { useShipments } from "../../hooks/shipments";
import { firestore } from "../../firebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import ActivityTask from "../../api/model/ActivityTask.model";

function TaskListView({
  taskList,
  companyId,
  onClickTask,
  purchaseOrder,
  salesOrder,
  hasPermissionToModifiedDueDate,
  companyUsers = [],
  user,
  allTaskList,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleUpdateTotalTask = () => {},
  categoriesStatus = {},
  handleCategoriesStatus,
  currentShipment = {},
  hasPermissionToReassign,
  onChangeShipment,
  onCleanPendingTask,
  pendingToCompleted,
  showCompleted,
  taskFilter,
  setPendingToComplete = () => {},
  handleIsEditingTask,
  backdropId,
}) {
  const [taskModal, setTaskModal] = useState({ open: false, task: {} });
  const [remainingOffset, setRemainingOffset] = useState(0);
  const authUser = useUser();
  const shipments = useShipments();

  function onClickOffset({ task, changeStyleTo }) {
    if (isReadOnly) {
      handleReadOnlyModal();
      return;
    }
    setPendingToComplete((oldPending) => ({
      ...oldPending,
      [task.id]: true,
    }));
    changeStyleTo("project-task pendingSize");
    setTaskModal({ open: true, task: task });
    setRemainingOffset(Math.abs(task.dayOffset));
  }

  function handleMoveTasks(taskModal) {
    const task = taskModal.task;
    const functions = getFunctions();
    const callable = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: `moveDependencyTasks`,
        env: globalEnvironment,
      })
    );
    const shipment = shipments.find(
      (shipment) => shipment.id === task.shipmentId
    ) || { id: "" };
    callable({
      companyId: companyId,
      salesOrderId: salesOrder.id,
      purchaseOrderId: purchaseOrder.id,
      shipmentId: shipment.id,
      taskId: task.id,
      dayOffset: task.dayOffset,
      remainingDayOffset:
        task.dayOffset >= 0 ? remainingOffset : -remainingOffset,
      user: authUser.id,
      type: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
      taskType: task.type,
    });

    updateDoc(task.ref, {
      moved: true,
      triggerType: triggerTaskTypes.MOVED_REMAINING_TASK,
    });

    const dayOffsetAbs = Math.abs(task.dayOffset);
    const activity = {
      ...new ActivityTask({
        id: getRandomId(),
        scope: task.type,
        creationDate: now(),
        type: "MOVED_TASKS_DUE_DATES",
        detail: getDetailFromMoveDependantTask({
          companyUsers: companyUsers,
          dayOffsetAbs,
          remainingOffset,
          task,
          type: actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED,
          user: user,
        }),
        user: user.id || "",
        companyId: companyId,
        taskId: task.id,
      }),
    };
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    if (task.type === typeOfTask.SALES_ORDER) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        { ...activity, scope: salesOrder.type }
      );
    } else if (task.type === typeOfTask.PURCHASE_ORDER) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        { ...activity, scope: purchaseOrder.type }
      );
    } else if (task.type === typeOfTask.SHIPMENT) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SHIPMENTS}/${shipment.id}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        activity
      );
    }

    updateTaskNotifications(task.notificationId);
  }

  function updateTaskNotifications(notificationId) {
    const functions = getFunctions();
    const callable = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: `userNotifications`,
        env: globalEnvironment,
        params: `/updateTaskNotifications?notificationId=${notificationId}`,
      })
    );
    callable().then((result) => {
      console.log("RESULT:: ", result);
    });
  }

  function generateActivityEntryForNonMoveTasksDueDates(taskModal) {
    const task = taskModal.task;
    const dayOffsetAbs = Math.abs(task.dayOffset);
    const activity = {
      ...new ActivityTask({
        id: getRandomId(),
        scope: task.type,
        creationDate: now(),
        type: "MOVED_TASKS_DUE_DATES",
        detail: getDetailFromMoveDependantTask({
          companyUsers: [],
          task,
          dayOffsetAbs: dayOffsetAbs,
          type: actionType.DENIED_REMAINING_DAY_OFFSET_MOVED,
        }),
        user: user.id || "",
        companyId: companyId,
        taskId: task.id,
      }),
    };

    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    if (task.type === typeOfTask.SALES_ORDER) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        { ...activity, scope: salesOrder.type }
      );
    } else if (task.type === typeOfTask.PURCHASE_ORDER) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        { ...activity, scope: purchaseOrder.type }
      );
    } else if (task.type === typeOfTask.SHIPMENT) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SHIPMENTS}/${currentShipment.id}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        activity
      );
    }
  }

  function getSortedTasksByPhases({ tasks, stage, listIndexes = {} }) {
    const stageTasks = tasks
      .filter((task) => task.stage === stage)
      .map((item) =>
        listIndexes[item.taskTemplateId]
          ? {
              ...item,
              listIndex: listIndexes[item.taskTemplateId],
            }
          : item
      )
      .sort(sortObjectsBy("listIndex"));

    return stageTasks.map((item) => {
      return (
        <Task
          key={
            item.type === typeOfTask.SALES_ORDER
              ? item.id + salesOrder.id
              : item.id + salesOrder.id + purchaseOrder.id
          }
          onChangeShipment={onChangeShipment}
          item={item}
          onClickOffset={onClickOffset}
          handleUpdateTotalTask={handleUpdateTotalTask}
          completeTask={onClickTask}
          user={companyUsers.find((user) => user.id === item.assignedTo)}
          companyId={companyId}
          salesOrder={salesOrder}
          purchaseOrder={purchaseOrder}
          hasPermissionToModifiedDueDate={hasPermissionToModifiedDueDate}
          isReadOnly={isReadOnly}
          handleReadOnlyModal={handleReadOnlyModal}
          companyUsers={companyUsers}
          backdropId={backdropId}
          allTaskList={allTaskList}
          permissionToVerify={
            item.type === typeOfTask.PURCHASE_ORDER
              ? item.factoryId
              : item.customerId
          }
          currentShipment={currentShipment}
          hasPermissionToReassign={hasPermissionToReassign}
          onCleanPendingTask={onCleanPendingTask}
          pendingToCompleted={pendingToCompleted}
          showCompleted={showCompleted}
          taskFilter={taskFilter}
          setPendingToComplete={setPendingToComplete}
          handleIsEditingTask={handleIsEditingTask}
        />
      );
    });
  }

  return (
    <ul>
      {taskModal.open && (
        <AttentionModal
          isOpen={taskModal.open}
          title="Attention"
          cancellable
          description={
            <span className="task-sheet-modal-input">
              Move all dependant tasks
              <input
                style={{ width: 54, margin: "0 8px", textAlign: "center" }}
                value={remainingOffset}
                max="365"
                type="number"
                id="task-shit-modal-input"
                onChange={(ev) => {
                  const value = ev.target.value;
                  if (value > 0) {
                    setRemainingOffset(value > 364 ? remainingOffset : value);
                  } else {
                    setRemainingOffset(1);
                  }
                }}
              />
              {remainingOffset > 1 ? "days " : "day "}
              <span
                style={{
                  color: taskModal.task.dayOffset < 0 ? "green" : "red",
                }}
              >
                {taskModal.task.dayOffset < 0 ? "earlier?" : "later?"}
              </span>
            </span>
          }
          onClick={() => {
            if (taskModal.open) {
              handleMoveTasks(taskModal);
              setTaskModal({ open: false, task: {} });
            }
          }}
          onClose={() => {
            if (taskModal.open) {
              generateActivityEntryForNonMoveTasksDueDates(taskModal);
              setTaskModal({ open: false, task: {} });
              updateDoc(taskModal.task.ref, {
                moved: true,
                triggerType: triggerTaskTypes.MOVED_REMAINING_TASK,
              });
              updateTaskNotifications(taskModal.task.notificationId);
            }
          }}
          confirmationText="Yes"
        />
      )}
      <TaskCategory
        title={
          "Proposal/Quote  (" +
          taskList.filter((task) => task.stage === taskStages.PROPOSAL).length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.PROPOSAL
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.PROPOSAL
              )
            ? "nearDue"
            : false
        }
        style={{ marginTop: 0 }}
        isOpen={categoriesStatus["Proposal/Quote"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Proposal/Quote", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.PROPOSAL,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
      <TaskCategory
        title={
          "Pre-production  (" +
          taskList.filter((task) => task.stage === taskStages.PRE_PRODUCTION)
            .length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.PRE_PRODUCTION
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.PRE_PRODUCTION
              )
            ? "nearDue"
            : false
        }
        isOpen={categoriesStatus["Pre-production"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Pre-production", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.PRE_PRODUCTION,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
      <TaskCategory
        title={
          "Production  (" +
          taskList.filter((task) => task.stage === taskStages.PRODUCTION)
            .length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.PRODUCTION
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.PRODUCTION
              )
            ? "nearDue"
            : false
        }
        isOpen={categoriesStatus["Production"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Production", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.PRODUCTION,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
      <TaskCategory
        title={
          "Booking & Transit  (" +
          taskList.filter((task) => task.stage === taskStages.BOOKING_TRANSIT)
            .length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.BOOKING_TRANSIT
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.BOOKING_TRANSIT
              )
            ? "nearDue"
            : false
        }
        isOpen={categoriesStatus["Booking & Transit"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Booking & Transit", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.BOOKING_TRANSIT,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
      <TaskCategory
        title={
          "Payment & Billing  (" +
          taskList.filter((task) => task.stage === taskStages.PAYMENT_BILLING)
            .length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.PAYMENT_BILLING
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.PAYMENT_BILLING
              )
            ? "nearDue"
            : false
        }
        isOpen={categoriesStatus["Payment & Billing"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Payment & Billing", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.PAYMENT_BILLING,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
    </ul>
  );
}

export default TaskListView;
