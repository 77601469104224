import React, { useState, useEffect } from "react";
import InfoIcon from "../../assets/flag-icons/info-icon.svg";
import EditIcon from "../../assets/flag-icons/edit-icon.svg";
import { updateDoc } from "firebase/firestore";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { Popover, TextareaAutosize } from "@mui/material";

function ReportPopover({ report }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editing, setEditing] = useState(false);
  // const classes = reportDrawer();
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const [reportDescription, setReportDescription] = useState("");

  useEffect(() => {
    setReportDescription(report.description);
  }, [report.id, report.description]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    updateDoc(report.ref, {
      description: reportDescription,
    });
    setAnchorEl(null);
  };

  return (
    <div>
      <TooltipTD label="Report description">
        <img
          src={InfoIcon}
          width={18}
          alt="info"
          aria-describedby={id}
          onClick={handleClick}
        />
      </TooltipTD>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disablePortal
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ padding: "24px 24px 12px 24px" }}
        classes={{ paper: "reportPopoverContainer" }}
      >
        {editing ? (
          <TextareaAutosize
            style={{
              width: 350,
              fontSize: 13,
              color: "#25486D",
              lineHeight: 1.5,
            }}
            placeholder="empty"
            value={reportDescription}
            onBlur={() => {
              setEditing(false);
            }}
            onChange={(ev) => setReportDescription(ev.target.value)}
            autoFocus={true}
          />
        ) : (
          <div className="report-description">{reportDescription}</div>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src={EditIcon}
            alt="edit-icon"
            onClick={() => setEditing(true)}
          />
        </div>
      </Popover>
    </div>
  );
}

export default ReportPopover;
