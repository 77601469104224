import { useSelector } from "react-redux";
import { useCompanyId, useQueryParams } from ".";
import { dbTables } from "../api/types/dbTables";
import { sortObjectsBy } from "../helpers/helpers";

export function useSalesOrders() {
  const companyId = useCompanyId();
  const salesOrders = useSelector((state) => state.data[dbTables.SALES_ORDERS]);
  if (!companyId) return [];
  const salesOrderList = Object.values(salesOrders);
  return (
    salesOrderList.filter((so) => so.companyId === companyId || !so.exists) ||
    []
  );
}
export function useCurrentSalesOrder({ salesOrderId }) {
  const salesOrders = useSalesOrders();
  const queryParams = useQueryParams();
  const soId = salesOrderId || queryParams.salesOrderId;
  const currentSalesOrder = salesOrders.find(
    (salesOrder) => salesOrder.id === soId
  );
  return currentSalesOrder || {};
}

export function usePurchaseOrders() {
  const companyId = useCompanyId();
  const purchaseOrders =
    useSelector((state) => state.data[dbTables.PURCHASE_ORDERS][companyId]) ||
    [];
  if (!companyId) return [];
  return purchaseOrders || [];
}

export function useCurrentPurchaseOrder() {
  const purchaseOrders = usePurchaseOrders();
  const queryParams = useQueryParams();
  return (
    purchaseOrders.find(
      (purchaseOrder) => purchaseOrder.id === queryParams.purchaseOrderId
    ) || {}
  );
}

export function useSortPuchaseOrders() {
  const companyId = useCompanyId();
  const purchaseOrders =
    useSelector((state) => state.data[dbTables.PURCHASE_ORDERS][companyId]) ||
    [];
  if (!companyId) return [];
  const sortingPo = purchaseOrders.sort(sortObjectsBy("number"));
  return sortingPo.map((po, index) => ({ ...po, index }));
}

export function useOrderDashboardSetup() {
  const orderDashboardSetup = useSelector(
    (state) => state.data.orderDashboardSetup
  );
  return {
    salesOrderId: orderDashboardSetup.salesOrderId,
    purchaseOrderId: orderDashboardSetup.purchaseOrderId,
    shipmentId: orderDashboardSetup.shipmentId,
  };
}
