import { orderBy, query, startAfter, where, limit } from "firebase/firestore";
import {
  BY_FILTER_NOTIFICATION,
  FILTER_NOTIFICATION,
} from "../../helpers/constants";

export const getNotificationRef = ({ filters, collectionRef }) => {
  let unsubscribeNotificationsRef;
  let {
    lastDocument,
    activeTab,
    isSame,
    dateRange,
    unReadNotification,
    limit: limitFilter,
  } = filters;
  if (activeTab === FILTER_NOTIFICATION.ALL) {
    if (isSame) {
      if (dateRange) {
        if (unReadNotification) {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("read", "==", false),
            where("creationDate", ">=", dateRange.dateStart),
            where("creationDate", "<=", dateRange.dateEnd),
            orderBy("creationDate", "desc"),
            startAfter(lastDocument),
            limit(limitFilter)
          );
        } else {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("creationDate", ">=", dateRange.dateStart),
            where("creationDate", "<=", dateRange.dateEnd),
            orderBy("creationDate", "desc"),
            startAfter(lastDocument),
            limit(limitFilter)
          );
        }
      } else {
        if (unReadNotification) {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("read", "==", false),
            orderBy("creationDate", "desc"),
            startAfter(lastDocument),
            limit(limitFilter)
          );
        } else {
          unsubscribeNotificationsRef = query(
            collectionRef,
            orderBy("creationDate", "desc"),
            startAfter(lastDocument),
            limit(limitFilter)
          );
        }
      }
    } else {
      if (dateRange) {
        if (unReadNotification) {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("read", "==", false),
            where("creationDate", ">=", dateRange.dateStart),
            where("creationDate", "<=", dateRange.dateEnd),
            orderBy("creationDate", "desc"),
            limit(limitFilter)
          );
        } else {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("creationDate", ">=", dateRange.dateStart),
            where("creationDate", "<=", dateRange.dateEnd),
            orderBy("creationDate", "desc"),
            limit(limitFilter)
          );
        }
      } else {
        if (unReadNotification) {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("read", "==", false),
            orderBy("creationDate", "desc"),
            limit(limitFilter)
          );
        } else {
          unsubscribeNotificationsRef = query(
            collectionRef,
            orderBy("creationDate", "desc"),
            limit(limitFilter)
          );
        }
      }
    }
  } else if (BY_FILTER_NOTIFICATION[activeTab]) {
    if (isSame) {
      if (dateRange) {
        if (unReadNotification) {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("read", "==", false),
            where("creationDate", ">=", dateRange.dateStart),
            where("creationDate", "<=", dateRange.dateEnd),
            where("type", "==", BY_FILTER_NOTIFICATION[activeTab]),
            orderBy("creationDate", "desc"),
            startAfter(lastDocument),
            limit(limitFilter)
          );
        } else {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("creationDate", ">=", dateRange.dateStart),
            where("creationDate", "<=", dateRange.dateEnd),
            where("type", "==", BY_FILTER_NOTIFICATION[activeTab]),
            orderBy("creationDate", "desc"),
            startAfter(lastDocument),
            limit(limitFilter)
          );
        }
      } else {
        if (unReadNotification) {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("read", "==", false),
            where("type", "==", BY_FILTER_NOTIFICATION[activeTab]),
            orderBy("creationDate", "desc"),
            startAfter(lastDocument),
            limit(limitFilter)
          );
        } else {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("type", "==", BY_FILTER_NOTIFICATION[activeTab]),
            orderBy("creationDate", "desc"),
            startAfter(lastDocument),
            limit(limitFilter)
          );
        }
      }
    } else {
      if (dateRange) {
        if (unReadNotification) {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("read", "==", false),
            where("creationDate", ">=", dateRange.dateStart),
            where("creationDate", "<=", dateRange.dateEnd),
            where("type", "==", BY_FILTER_NOTIFICATION[activeTab]),
            orderBy("creationDate", "desc"),
            limit(limitFilter)
          );
        } else {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("creationDate", ">=", dateRange.dateStart),
            where("creationDate", "<=", dateRange.dateEnd),
            where("type", "==", BY_FILTER_NOTIFICATION[activeTab]),
            orderBy("creationDate", "desc"),
            limit(limitFilter)
          );
        }
      } else {
        if (unReadNotification) {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("read", "==", false),
            where("type", "==", BY_FILTER_NOTIFICATION[activeTab]),
            orderBy("creationDate", "desc"),
            limit(limitFilter)
          );
        } else {
          unsubscribeNotificationsRef = query(
            collectionRef,
            where("type", "==", BY_FILTER_NOTIFICATION[activeTab]),
            orderBy("creationDate", "desc"),
            limit(limitFilter)
          );
        }
      }
    }
  } else {
    unsubscribeNotificationsRef = query(
      collectionRef,
      orderBy("creationDate", "desc"),
      limit(limitFilter)
    );
  }
  return unsubscribeNotificationsRef;
};

export const filterNotifications = ({
  currentNotifications = [],
  filters = {},
}) => {
  if (currentNotifications.length === 0) {
    return [];
  }
  let currentNotificationsCpy = [...currentNotifications];
  const { customer = [], factory = [], assignedTo = [] } = filters;
  currentNotificationsCpy = currentNotificationsCpy.filter((notification) => {
    let result = true;
    if (customer.length > 0) {
      result = result && customer.includes(notification.customerId);
    }
    if (factory.length > 0) {
      result = result && factory.includes(notification.factoryId);
    }
    if (assignedTo.length > 0) {
      result =
        result &&
        (assignedTo.includes(notification.createdBy) ||
          assignedTo.includes(notification.userId));
    }

    return result;
  });
  return currentNotificationsCpy;
};
