import React from "react";
import { TableCell, TableRow } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import IntlMessages from "../../util/IntlMessages";
import { sortObjectsBy } from "../../helpers/helpers";
import TooltipTD from "../Tooltip/TooltipTD";
import { getUserAvatar } from "../../helpers/users";
import userTypes from "../../api/types/userTypes";
import PendingDivider from "../Dividers/PendingDivider";
import { Switch } from "@mui/material";

function UsersCompany({
  thead,
  users,
  analitycs,
  isTradedashEmployee,
  collectionPermissionGroups,
  handleOpenModal,
  canChangeUserStatus,
  canEditUser,
  onUserUpdate,
  handleChange,
  onChangeStatusUser,
}) {
  const cursorStyle = { cursor: canEditUser ? "" : "unset" };
  const cellClass = canEditUser ? "" : "noHover";
  const theadDisplay = thead
    .filter((thead) =>
      thead === "userscompany.tableheader.analyticsPerson" ||
      thead === "userscompany.tableheader.automaticSystemUser"
        ? !isTradedashEmployee
          ? false
          : !analitycs
        : true
    )
    .map((thead) => {
      return (
        <TableCell
          key={thead}
          style={{
            fontSize: "0.875rem",
          }}
        >
          <IntlMessages id={thead} />
        </TableCell>
      );
    });

  const tbodyDisplay = ({ users, isTheFirstInactiveItem = false }) => {
    return users.sort(sortObjectsBy("fullName", false)).map((user) => {
      let namePermissionGroups = [];
      collectionPermissionGroups.forEach((permissionGroupData) => {
        if (user.permissionGroups[permissionGroupData.id])
          namePermissionGroups.push(permissionGroupData.name);
      });
      return (
        <TableRow
          key={user.id}
          onClick={() => {
            if (canEditUser) {
              onUserUpdate(user);
            }
          }}
          className={"noHover"}
        >
          <TableCell className={cellClass}>{user.firstName}</TableCell>
          <TableCell className={cellClass}>{user.lastName}</TableCell>
          <TableCell className={cellClass}>@{user.displayName}</TableCell>
          <TableCell className={cellClass}>{user.cellPhone}</TableCell>
          <TableCell className={cellClass}>{user.email}</TableCell>
          <TableCell className={cellClass}>{user.role}</TableCell>
          <TableCell size="small" className={cellClass}>
            <TooltipTD
              label={namePermissionGroups.join(", ")}
              className={cellClass}
            >
              <div
                style={{
                  ...cursorStyle,
                  width: 120,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {namePermissionGroups.join(", ")}
              </div>
            </TooltipTD>
          </TableCell>

          {isTradedashEmployee && <TableCell>{user.typeOfUser}</TableCell>}
          {isTradedashEmployee && (
            <TableCell style={{ textAlign: "center" }}>
              <Switch
                checked={user.automaticSystemUser}
                onChange={handleChange}
                value={user.automaticSystemUser}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
                disabled={!isTradedashEmployee}
                onClick={(e) => {
                  onChangeStatusUser(user, "automaticSystemUser");
                  e.stopPropagation();
                }}
              />
            </TableCell>
          )}
          <TableCell className={cellClass}>
            {getUserAvatar({
              user,
              styles: {
                outline: "2px solid #000",
              },
            })}
          </TableCell>
          <TableCell className={canChangeUserStatus ? "" : "noHover"}>
            <Switch
              checked={user.active}
              onChange={handleChange}
              value={user.active}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={isTradedashEmployee || !canChangeUserStatus}
              onClick={(e) => {
                const adminUsers = users.filter(
                  (user) => user.role === userTypes.SUPER_ADMIN && user.active
                );
                if (
                  user.role === userTypes.SUPER_ADMIN &&
                  adminUsers.length <= 1 &&
                  user.active
                ) {
                  handleOpenModal(user);
                } else {
                  onChangeStatusUser(user, "active");
                }
                e.stopPropagation();
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <DataTable header={theadDisplay} className="row-hover-over">
      {tbodyDisplay({ users: users.filter((user) => user.active) })}
      <TableRow className={"noHover"}>
        <td
          colSpan={9}
          style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
        >
          <PendingDivider
            text={"Deactivated users"}
            containerStyle={{ padding: "34px 0px" }}
            style={{ left: 80, right: "auto", background: "transparent" }}
            firstLineStyles={{
              background: "transparent",
            }}
            lineNameStyles={{
              color: "#000",
              fontSize: 24,
              fontWeight: 400,
            }}
          />
        </td>
      </TableRow>
      {tbodyDisplay({ users: users.filter((user) => !user.active) })}
    </DataTable>
  );
}

export default UsersCompany;
