import React, { useState, useEffect } from "react";
import moment from "moment";
import EditableLabel from "../TextFields/EditableLabel";
import {
  getFolderSize,
  getFileName,
  getSizeTransformedAccordingKBSize,
} from "../../helpers/helpers";
import { ListItemTextDocumentStyled } from "./styles";
import { getShipmentBadge } from "../../helpers/shipments";
import { useCurrentShipment, useShipments } from "../../hooks/shipments";
import { documentScope } from "../../helpers/documents";
import { useCompanyUsers } from "../../hooks/user";
import { TYPE_OF_FILE } from "../../helpers/constants";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";

function getFileInformation(document) {
  const { type, child, size } = document;
  const sum = 0;
  switch (type) {
    case TYPE_OF_FILE.FOLDER:
      return child.length === 1 ? (
        <label className="filesize">
          {"(" +
            child.length +
            " Item, " +
            getSizeTransformedAccordingKBSize(
              getFolderSize(sum, child),
              document.type
            ) +
            ")"}
        </label>
      ) : (
        <label className="filesize">
          {"(" +
            child.length +
            " Items, " +
            getSizeTransformedAccordingKBSize(
              getFolderSize(sum, child),
              document.type
            ) +
            ")"}
        </label>
      );
    default:
      return (
        <label className="filesize">
          {"(" + getSizeTransformedAccordingKBSize(+size, document.type) + ")"}
        </label>
      );
  }
}

function getFileSubInformation({ date, currentDocument, users }) {
  const documentUser =
    users.find((user) => user.id === currentDocument.createdBy) || {};

  if (!currentDocument.placeholder) {
    return (
      <React.Fragment>
        <div className={"fileInformationContainer"}>
          <label style={{ marginBottom: 4 }} className="fileinfo">
            Added {date}
          </label>
        </div>
        <div className={"fileInformationBottom"}>
          <label className="fileinfo">
            by{" @"}
            {documentUser.displayName || "companyUser"}
          </label>
          <label className="fileinfo">
            {getFileInformation(currentDocument)}
          </label>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className={"fileInformationContainer"}>
          <label className="fileinfo" />
        </div>
      </React.Fragment>
    );
  }
}

function ListItemTextDocument({
  currentDocument,
  onRename,
  onEditing,
  onFolderName,
  editing,
  folderName,
  handleEnableContextMenu = () => {},
  disabled,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleOpenFolder = () => {},
  purchaseOrderID,
  onChangeShipment = () => {},
  nivel,
}) {
  const [date, setDate] = useState(
    moment(currentDocument.created).format("h:mm a M/D/YY")
  );

  const users = useCompanyUsers({
    showInactiveUsers: true,
    showBotUser: true,
  });
  const shipments = useShipments();
  const currentShipment = useCurrentShipment({});

  useEffect(() => {
    setDate(moment(currentDocument.created).format("h:mm a M/D/YY"));
  }, [currentDocument.created, currentDocument.lastModified]);

  const fileFolderName =
    currentDocument.type === TYPE_OF_FILE.FOLDER || currentDocument.placeholder
      ? currentDocument.name
      : getFileName(currentDocument.name);

  const sizeForLevel = 25 - nivel * 4;
  return (
    <ListItemTextDocumentStyled
      className="listItemText"
      classes={{ primary: "primaryItemText" }}
    >
      <div className={"listItemTextPrimary"}>
        <TooltipTD
          label={fileFolderName}
          showToolTip={fileFolderName.length > Math.max(sizeForLevel, 12)}
        >
          <EditableLabel
            onStartEditing={() => {
              onEditing();
              onFolderName(
                currentDocument.type === TYPE_OF_FILE.FOLDER
                  ? currentDocument.name
                  : getFileName(currentDocument.name)
              );
            }}
            onValid={() => onRename(folderName)}
            onChange={(ev) => onFolderName(ev.target.value)}
            disabledClick
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "450px",
            }}
            editing={editing}
            autofocus={editing}
            currentDocument={currentDocument}
            disabled={
              currentDocument.placeholder ||
              disabled ||
              (currentDocument.scope === documentScope.SHIPMENT &&
                currentDocument.isShipmentRootTask)
            }
            isReadOnly={isReadOnly}
            handleReadOnlyModal={handleReadOnlyModal}
            handleOpenFolder={handleOpenFolder}
            handleEnableContextMenu={
              currentDocument.placeholder && currentDocument.type === "file"
                ? handleEnableContextMenu
                : () => {}
            }
            inputStyle={{ marginBottom: "28px", fontSize: "14px" }}
            onclose={() =>
              onFolderName(
                currentDocument.type !== TYPE_OF_FILE.FOLDER
                  ? getFileName(currentDocument.name)
                  : currentDocument.name
              )
            }
          >
            {!editing ? fileFolderName : folderName}
          </EditableLabel>
        </TooltipTD>
      </div>
      <div className={"listItemTextSecondary"}>
        {getFileSubInformation({
          date,
          currentDocument,
          users,
        })}
        {currentDocument.scope === documentScope.SHIPMENT &&
          currentDocument.isShipmentRootTask &&
          getShipmentBadge({
            shipment: shipments.find(
              (shipment) => shipment.id === currentShipment.id
            ),
            styles: {
              margin: "0px",
            },
            classes: cx(shipmentBadgeClass.secondary, "shipmentBadgeDocument"),
            onChangeShipment: (shipment, ev) => {
              onChangeShipment(shipment.id);
              ev.stopPropagation();
            },
            tooltipStyle: {
              position: "absolute",
              right: 16,
              width: "fit-content",
              top: "40%",
            },
          })}
      </div>{" "}
    </ListItemTextDocumentStyled>
  );
}

export default ListItemTextDocument;
