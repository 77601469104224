import moment from "moment";

export function getStartAnchor(task) {
  if (!task) {
    return "left";
  }
  switch (task.dependencyType) {
    case "SS":
      return "left";
    case "FF":
      return "right";
    case "FS":
      return "left";
    default:
      return "middle";
  }
}
export function getEndAnchor(task) {
  if (!task) {
    return "right";
  }
  switch (task.dependencyType) {
    case "SS":
      return "left";
    case "FF":
    case "FS":
      return "right";
    default:
      return "middle";
  }
}

function getSSArrowValues(diffDays) {
  switch (diffDays) {
    case -1:
      return {
        _cpx1Offset: -40,
        _cpy1Offset: 0,
        _cpx2Offset: -20,
        _cpy2Offset: 0,
        curveness: 2,
      };
    case -2:
      return {
        _cpx1Offset: -40,
        _cpy1Offset: 0,
        _cpx2Offset: -20,
        _cpy2Offset: 0,
        curveness: 2,
      };
    case -3:
      return {
        _cpx1Offset: 0,
        _cpy1Offset: 0,
        _cpx2Offset: -20,
        _cpy2Offset: 0,
        curveness: 2,
      };

    case 0:
      return {
        _cpx1Offset: -40,
        _cpy1Offset: 0,
        _cpx2Offset: -20,
        _cpy2Offset: 0,
        curveness: 2,
      };
    case 1:
    case 2:
    case 3:
      return {
        _cpx1Offset: -80,
        _cpy1Offset: 0,
        _cpx2Offset: -40,
        _cpy2Offset: 0,
        curveness: 1,
      };
    case 4:
      return {
        _cpx1Offset: -40,
        _cpy1Offset: 0,
        _cpx2Offset: -20,
        _cpy2Offset: 0,
        curveness: 2,
      };
    case 30:
      return {
        _cpx1Offset: -40,
        _cpy1Offset: 0,
        _cpx2Offset: -20,
        _cpy2Offset: 0,
        curveness: 1,
      };
    default:
      return {
        _cpx1Offset: -40,
        _cpy1Offset: 0,
        _cpx2Offset: -20,
        _cpy2Offset: 0,
        curveness: 0,
      };
  }
}

function getFFArrowValues(diffDays) {
  switch (diffDays) {
    case -1:
    case -2:
    case -3:
    case -4:
      return {
        _cpx1Offset: 180,
        _cpy1Offset: 0,
        _cpx2Offset: 0,
        _cpy2Offset: 0,
        curveness: -1,
      };
    case -5:
    case -6:
    case -7:
    case -8:
    case -9:
    case -10:
    case -11:
    case -12:
    case -13:
    case -14:
    case -15:
    case -16:
    case -17:
    case -18:
    case -19:
    case -20:
    case -21:
    case -22:
    case -23:
      return {
        _cpx1Offset: 200,
        _cpy1Offset: 0,
        _cpx2Offset: 0,
        _cpy2Offset: 90,
        curveness: -0.5,
      };
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
      return {
        _cpx1Offset: 95 + Math.abs(diffDays * 15),
        _cpy1Offset: 0,
        _cpx2Offset: 50,
        _cpy2Offset: -20,
        curveness: 0.2,
      };

    default:
      return {
        _cpx1Offset: 95 + Math.abs(diffDays * 15),
        _cpy1Offset: 0,
        _cpx2Offset: 50,
        _cpy2Offset: -20,
        curveness: 0.2,
      };
  }
}

function getFSArrowValues(diffDays) {
  switch (diffDays) {
    case -1:
    case -2:
    case -3:
    case -4:
    case -5:
    case -6:
    case -7:
    case -8:
    case -9:
    case -10:
    case -11:
    case -12:
    case -13:
    case -14:
    case -15:
    case -16:
    case -17:
    case -18:
    case -19:
    case -20:
    case -21:
    case -22:
    case -23:
    case -24:
    case -25:
    case -26:
    case -27:
    case -28:
    case -29:
    case -30:
    case -31:
      return {
        _cpx1Offset: -50 + 73 * diffDays,
        _cpy1Offset: 0,
        _cpx2Offset: 50 + 73 * Math.abs(diffDays),
        _cpy2Offset: 0,
        curveness: 2,
      };
    case 0:
      return {
        _cpx1Offset: 0,
        _cpy1Offset: 0,
        _cpx2Offset: 0,
        _cpy2Offset: 0,
        curveness: 15,
      };
    case 1:
      return {
        _cpx1Offset: 0,
        _cpy1Offset: 0,
        _cpx2Offset: 0,
        _cpy2Offset: 0,
        curveness: 15,
      };
    case 2:
      return {
        _cpx1Offset: 0,
        _cpy1Offset: 0,
        _cpx2Offset: 0,
        _cpy2Offset: 0,
        curveness: 10,
      };
    case 3:
      return {
        _cpx1Offset: 0,
        _cpy1Offset: 0,
        _cpx2Offset: 0,
        _cpy2Offset: 0,
        curveness: 5,
      };
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
      return {
        _cpx1Offset: 0,
        _cpy1Offset: 0,
        _cpx2Offset: 0,
        _cpy2Offset: 0,
        curveness: 0.5,
      };
    default:
      return {
        _cpx1Offset: 0,
        _cpy1Offset: 0,
        _cpx2Offset: 0,
        _cpy2Offset: 0,
        curveness: 0.5,
      };
  }
}

export function getArrowConfiguration(task, predecesorTask) {
  if (!task || !predecesorTask) {
    return 1;
  }
  let diffDays;
  switch (task.dependencyType) {
    case "SS":
      diffDays = moment(task.startDate).diff(
        moment(predecesorTask.startDate),
        "days"
      );
      return getSSArrowValues(diffDays);
    case "FS":
      diffDays = moment(task.startDate).diff(
        moment(predecesorTask.finishDate),
        "days"
      );
      return getFSArrowValues(diffDays);
    case "FF":
      diffDays = moment(task.finishDate).diff(
        moment(predecesorTask.finishDate),
        "days"
      );
      return getFFArrowValues(diffDays);

    default:
      return getSSArrowValues(diffDays);
  }
}
