import React from "react";
import { TableCell } from "@mui/material";
import { itemTableRowHeight } from "../../helpers/orderDashboard";
import { itemTableReference } from "../../helpers/constants";
import { getDangerStyle } from "../../helpers/itemsTableHelper";
import CellSKUv2 from "./CellSKUv2";
import defaultImage from "../../assets/flag-icons/no_image_available.jpg";

function RowSKU({
  column,
  prefix,
  showImage = false,
  onChangeData = () => {},
  item,
  handlePhotosViewer,
  isAllowed = () => {},
  companyAllowed = () => {},
  focusPOTable = () => {},
  isVoided,
}) {
  let value = item[column.id];
  if (column.id === "image") {
    value = item[column.id][0] || defaultImage;
  }
  let cellClassName = "styleCellShipment";
  const mismatchStatus = item.mismatchStatus || {};
  let hasMismatch = false;
  let style = {
    ...column.style,
    ...column.rowStyle,
  };
  style = getDangerStyle({
    prefix,
    column,
    mismatch: mismatchStatus,
    row: item,
    style,
  });
  hasMismatch = style.color === "red";
  let cursor = column.select || column.poRedirect ? "pointer" : "";

  if (
    column.id === "number" &&
    column.reference === itemTableReference.SALES_ORDERS
  ) {
    if (item.number === item.currentSalesOrderNumber) {
      style = { ...style, textDecoration: "unset", cursor: "unset" };
      cursor = "unset";
    }
  }

  return (
    <TableCell
      id={column.id}
      key={`${prefix}_${column.id}_${item.id}_cell`}
      style={{
        ...style,
        height: showImage
          ? itemTableRowHeight.WITH_IMAGE
          : itemTableRowHeight.NORMAL,
        position: "relative",
      }}
      align={"center"}
      className={cellClassName}
    >
      <CellSKUv2
        column={column}
        focusPOTable={focusPOTable}
        item={item}
        prefix={prefix}
        isAllowed={isAllowed}
        companyAllowed={companyAllowed}
        cursor={cursor}
        isVoided={isVoided}
        hasMismatch={hasMismatch}
        onChangeLabel={(temporalLabel) => {
          onChangeData({
            item,
            row: { ...item, [column.id]: temporalLabel },
            reference: column.reference,
          });
        }}
        valueURL={value}
        showImage={showImage}
        onPhotosViewer={() => {
          if (item.image && item.image.length !== 0) {
            handlePhotosViewer({
              open: true,
              photos: [
                ...item.image.map((el) => ({
                  src: el || defaultImage,
                  number: item.itemNumber,
                  description: item.description,
                })),
              ],
            });
          }
        }}
      />
    </TableCell>
  );
}

export default RowSKU;
