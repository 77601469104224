import React from "react";
import EditableLabel from "../TextFields/EditableLabel";
import { colors } from "../../assets/jss/variables";
import { Checkbox, TextareaAutosize } from "@mui/material";

const lockFields = ["number", "name"];

function getFieldByProperty({
  property,
  currentItem,
  isReadOnly,
  handleSave = () => {},
  handleChange = () => {},
  handleReadOnlyModal = () => {},
  handleInactive = () => {},
  type = "text",
}) {
  const valueProperty = currentItem[property] || "";
  switch (property) {
    case "notes":
      return (
        <TextareaAutosize
          onChange={(ev) => handleChange(ev, property)}
          disabled={isReadOnly}
          value={valueProperty}
          onBlur={() => handleSave(property)}
          maxRows={20}
          style={{
            width: "58%",
            marginLeft: 17,
            color: colors.darkGray,
            fontSize: 14,
            borderColor: "#ccc",
            maxHeight: 350,
          }}
          aria-label="minimum height"
          minRows={8}
        />
      );
    case "inactive":
      return (
        <div style={{ margin: "0px 17px 0px 0px", height: 16 }}>
          <Checkbox
            style={{
              padding: 0,
              width: 38,
              height: 30,
              top: -6,
            }}
            checked={valueProperty === "" ? false : valueProperty}
            disabled={isReadOnly}
            onChange={(ev) => handleInactive(ev.target.checked, property)}
          />
        </div>
      );
    default:
      const lockField = lockFields.includes(property);
      return (
        <EditableLabel
          key={currentItem.id + property}
          onValid={() => {
            handleSave(property);
          }}
          children={valueProperty}
          onChange={(event) => handleChange(event, property)}
          editable={lockField || isReadOnly}
          disabled={lockField || isReadOnly}
          style={{ marginTop: 0 }}
          isReadOnly={isReadOnly}
          handleReadOnlyModal={handleReadOnlyModal}
          type={type}
        />
      );
  }
}

export default getFieldByProperty;
