import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UploadFiles from "../Documents/UploadFiles";
import FoldersStructure from "./FoldersStructure";
import MenuOptions from "./MenuOptions";
import { dbTables } from "../../api/types/dbTables";
import {
  typeModalPermissions,
  errorDocumentName,
  BACKDROP_TYPE,
  TYPE_OF_FILE,
  PO_STATUS,
} from "../../helpers/constants";
import {
  sortObjectsBy,
  getDataFromFirestore,
  onRenameDocuments,
  getDataFromUploadFile,
  pathToString,
  getRandomId,
  getDataFromFile,
  getFileName,
  getPathDocumentLocalStorage,
  searchDocumentInTree,
  getDashboardSearchPath,
  useLocationPath,
} from "../../helpers/helpers";
import folderPlaceholder from "../../assets/flag-icons/folder-placeholder.svg";
import { now } from "moment";
import {
  addGenericDataToFirestore,
  getNestedCollectionFromFirestoreTable,
  setGlobalBackdrop,
} from "../../actions/DataActions";

import { useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import TypeFileSelector from "./TypeFileSelector";
import ModalPermission from "./ModalPermission";
import MenuSelector from "./MenuSelector";
import ListItemTextDocument from "./ListItemTextDocument";
import AttentionModal from "../Modal/AttentionModal";
// import { trackEvent } from "../../helpers/analytics";
import {
  getselectedUsersObj,
  updateParentFolderTouched,
  superAdminAssignmentPermissions,
  addNewDocumentType,
  getDocumentPath,
  documentScope,
} from "../../helpers/documents";
import userTypes from "../../api/types/userTypes";
import PendingDivider from "../Dividers/PendingDivider";
import classNames from "classnames";
import { ListItemDocumentStyled } from "./styles";
import { useUser, useCompanyUsers, useBackdropState } from "../../hooks/user";
import { useCompany } from "../../hooks/company";
import { useJSONLocalStorage, useQueryParams } from "../../hooks";
import { storage } from "../../firebase";
import AppConfig from "../../constants/AppConfig";
import { getBackgroundColorByType } from "../../helpers/orderDashboard";
import {
  getDocumentId,
  getListItemClassName,
  hasExtension,
  replaceDocumentState,
  verifyFolderContainPlaceholderFile,
} from "./DocumentHelper";
import { updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import { orderType } from "../../helpers/salesOrder";
import { colors } from "../../assets/jss/variables";
import { Alert, Collapse, ListItem, ListItemIcon } from "@mui/material";
import {
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
} from "../../hooks/salesOrders";
import { useNavigate } from "react-router-dom";
import { cx } from "@emotion/css";

const backgroundColor = {
  [orderType.QUOTE]: colors.salesOrderBackgroundColor,
  [orderType.SALES_ORDER]: colors.salesOrderBackgroundColor,
  [orderType.PURCHASE_ORDER]: colors.purchaseOrderBackgroundColor,
  [orderType.PURCHASE_QUOTE]: colors.purchaseOrderBackgroundColor,
  [orderType.SHIPMENT]: colors.shipmentBadge,
};

function ListItemDocument({
  currentDocument,
  handleDeleteFolder,
  onAddNewFolder,
  companyID,
  mainTable,
  mainTableID,
  documents,
  dbTable,
  selectedDocumentTab,
  permissionGroupsDB,
  isLastTemplate,
  onDraggableDocument,
  onDragEnd = () => {},
  draggableDocument = {},
  isDraggableDocument = false,
  handleDraggableDocumentClean,
  documentFlat,
  hasParentPermission = false,
  parentDocument,
  disabled,
  isExpand,
  onOpenSnackBar,
  onShowModal,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  querySearch = "",
  handleExpandOrCollapse = () => {},
  newRootFolder,
  onChangeRootFolder = () => {},
  allowMultipleFiles,
  onChangeShipment = () => {},
  nivel = 13,
}) {
  const defaultFolderName = "New Folder";
  const path = useLocationPath();
  const pathLocalStorage = `${getPathDocumentLocalStorage(
    path
  )}DocumentStructure`;
  const { get, set } = useJSONLocalStorage(pathLocalStorage);
  const currentSalesOrder = useCurrentSalesOrder({});
  const { show } = useContextMenu({ id: currentDocument.id });
  const currentPurchaseOrder = useCurrentPurchaseOrder();
  const [snackOption, setSnackOption] = useState({});
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const setBackdrop = useCallback((id) =>
    setGlobalBackdrop({
      id,
      type: BACKDROP_TYPE.FOLDER,
      isOpen: true,
    })(dispatch)
  );
  const addDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [
        dbTables.COMPANIES,
        companyID,
        mainTable,
        mainTableID,
        dbTable,
        document.id,
      ],
      document
    )(dispatch)
  );

  const addShipmentDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [
        dbTables.COMPANIES,
        companyID,
        dbTables.SHIPMENTS,
        currentDocument.shipmentId,
        dbTables.SHIPMENT_DOCUMENTS,
        document.id,
      ],
      { ...document, shipmentId: currentDocument.shipmentId || "" }
    )(dispatch)
  );
  const addDocumentVersion = useCallback((document, documentVersionId) =>
    addGenericDataToFirestore(
      [
        dbTables.COMPANIES,
        companyID,
        mainTable,
        mainTableID,
        dbTable,
        documentVersionId,
        dbTables.DOCUMENT_VERSIONS,
        document.id,
      ],
      document
    )(dispatch)
  );

  const addShipmentDocumentVersion = useCallback(
    (document, documentVersionId) =>
      addGenericDataToFirestore(
        [
          dbTables.COMPANIES,
          companyID,
          dbTables.SHIPMENTS,
          currentDocument.shipmentId,
          dbTables.SHIPMENT_DOCUMENTS,
          documentVersionId,
          dbTables.DOCUMENT_VERSIONS,
          document.id,
        ],
        { ...document, shipmentId: currentDocument.shipmentId || "" }
      )(dispatch)
  );
  const localStorage = get() || {};
  const poDataLocalStorage = localStorage[mainTableID] || {};

  const isFolderOpen = poDataLocalStorage[currentDocument.id] || false;
  const [open, setOpen] = useState(
    isFolderOpen && !currentDocument.placeholder
  );
  const [createNewFolder, setCreateNewFolder] = useState(false);
  const [folderName, setFolderName] = useState(defaultFolderName);
  const [signClear, setSignClear] = useState(false);
  const [editing, setEditing] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);
  const [fileVersionToUpload, setFileVersionToUpload] = useState({});
  const [uploadFileVersion, setUploadFileVersion] = useState(false);
  const [enableRightClick, setEnableRightClick] = useState(false);
  const [stylesDiv, setStylesDiv] = useState("draggeable-file-disable");
  const [listenerList, setListenerList] = useState([]);
  const [openModalPermission, setOpenModalPermission] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");
  const [justViewPermissions, setJustViewPermission] = useState(false);

  const [showSelectedPermissions, setShowSelectedPermissions] = useState(false);
  const [typePermissionModal, setTypePermissionModal] = useState(
    typeModalPermissions.FILE_CHILD_UPLOAD
  );
  const [typeUpload, setTypeUpload] = useState({});

  const [fileUploading, setFileUploading] = useState([]);
  const fileUploaderRef = useRef(null);
  const company = useCompany();
  const backdropState = useBackdropState();
  const listenToDocumentsVersion = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyID,
        mainTable,
        mainTableID,
        dbTable,
        currentDocument.id,
        dbTables.DOCUMENT_VERSIONS,
      ]),
      getParentId: true,
    })(dispatch)
  );

  const listenToShipmentDocumentsVersion = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyID,
        dbTables.SHIPMENTS,
        currentDocument.shipmentId,
        dbTables.SHIPMENT_DOCUMENTS,
        currentDocument.id,
        dbTables.DOCUMENT_VERSIONS,
      ]),
      getParentId: true,
    })(dispatch)
  );

  const currentUser = useUser();
  const users = useCompanyUsers({
    showInactiveUsers: false,
    showBotUser: false,
  });

  const documentVersions = useSelector(
    (state) => state.data.document_versions[currentDocument.id]
  );

  const currentOrderVoid = () => {
    if (dbTable === dbTables.SALES_ORDER_DOCUMENTS) {
      return currentSalesOrder.status === PO_STATUS.VOIDED;
    } else {
      return currentPurchaseOrder.status === PO_STATUS.VOIDED;
    }
  };

  useEffect(() => {
    if (isFolderOpen !== open) {
      setOpen(isFolderOpen);
    }
  }, [mainTableID]);

  useEffect(() => {
    if (newRootFolder && newRootFolder.id === currentDocument.id) {
      onPermission();
      onChangeRootFolder();
    }
  }, [newRootFolder]);

  useEffect(() => {
    if (querySearch === "") {
      setOpen(isFolderOpen);
    } else {
      const searchQueryDocument = searchDocumentInTree(
        currentDocument,
        querySearch
      );
      setOpen(searchQueryDocument);
    }
  }, [querySearch]);

  const checkChidlFiles = (document, childId) => {
    let hasFile = false;
    const childs = document.child || [];
    childs.forEach((child) => {
      if (childId.includes(child.id)) {
        hasFile = true;
      } else if (child.child.length > 0) {
        hasFile = hasFile || checkChidlFiles(child, childId);
      }
    });
    return hasFile;
  };

  useEffect(() => {
    if (signClear) {
      navigate(
        getDashboardSearchPath({
          ...queryParams,
          fileId: false,
        }),
        { replace: true }
      );
      setSignClear(false);
    }
  }, [signClear]);

  const addBackdrop = (file) => {
    const elementId = getDocumentId(file);
    setTimeout(() => {
      setSignClear(true);
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({
          behavior: "auto",
          block: "start",
        });
      }
      setBackdrop(elementId);
    }, 400);
  };

  useEffect(() => {
    if (queryParams.fileId) {
      const fileId = queryParams.fileId;
      const currentVersion = `V${currentDocument.version}`;
      if (
        fileId.includes(currentDocument.id) ||
        (currentDocument.isShipmentRootTask &&
          fileId.includes(currentDocument.shipmentId))
      ) {
        const isDifferentVersion =
          !fileId.includes(currentVersion) &&
          currentDocument.type !== TYPE_OF_FILE.FOLDER;
        if (isDifferentVersion && !open) {
          handleClick();
        } else if (!isDifferentVersion) {
          addBackdrop(currentDocument);
        }
        return;
      } else if (
        currentDocument.mainFileId &&
        fileId.includes(currentDocument.mainFileId) &&
        fileId.includes(currentVersion)
      ) {
        addBackdrop(currentDocument);
        return;
      } else {
        const hasChildDocument = checkChidlFiles(currentDocument, fileId);
        if (hasChildDocument && !open) {
          handleClick();
        }
        return;
      }
    }
  }, [queryParams]);

  useEffect(() => {
    if (isFolderOpen !== open) {
      setOpen(isFolderOpen);
    }
  }, [isFolderOpen]);

  let companyUsers = [];
  if (!hasParentPermission) {
    companyUsers = users
      ? users.filter((user) => user.companyId === companyID)
      : [];
  } else {
    const selectedUsersObj = getselectedUsersObj(
      parentDocument.permissionUsers,
      parentDocument.permissionGroups,
      permissionGroupsDB
    );
    companyUsers = users
      ? users
          .filter((user) => user.companyId === companyID)
          .filter(
            (user) =>
              (selectedUsersObj && selectedUsersObj[user.id] === true) ||
              (user.id === currentUser.id &&
                currentUser.role === userTypes.SUPER_ADMIN)
          )
      : [];
  }
  if (documentVersions) {
    documentVersions.sort(sortObjectsBy("created", true));
  }

  const filterDocuments = [];
  if (documentVersions) {
    documentVersions.forEach((element) => {
      if (element.version !== currentDocument.version) {
        filterDocuments.push(element);
      }
    });
  }

  function handleClick(ev) {
    setEnableRightClick(false);
    if (enableRightClick && ev) {
      ev.stopPropagation();
      return;
    }
    setOpen(!open);
    if (currentOrderVoid()) {
      return;
    }
    if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
      const localStorage = get() || {};
      const poDataLocalStorage = localStorage[mainTableID] || {};
      const newLocalStorage = {
        ...localStorage,
        [mainTableID]: {
          ...poDataLocalStorage,
          [currentDocument.id]: !open === false ? undefined : !open,
        },
      };
      handleExpandOrCollapse({ ...newLocalStorage });
      set({ ...newLocalStorage });
    }
    setCreateNewFolder(false);
    if (
      currentDocument.type !== TYPE_OF_FILE.FOLDER &&
      currentDocument.mainFile
    ) {
      getDocumentVersion();
    }
  }

  function getDocumentVersion() {
    if (
      currentDocument.type !== TYPE_OF_FILE.FOLDER &&
      currentDocument.mainFile
    ) {
      if (!listenerList.includes(currentDocument.id)) {
        if (
          currentDocument.scope === documentScope.SALES_ORDER ||
          currentDocument.scope === documentScope.FACTORY ||
          currentDocument.scope === documentScope.CUSTOMER ||
          currentDocument.scope === documentScope.PURCHASE_ORDER
        ) {
          listenToDocumentsVersion();
        } else {
          listenToShipmentDocumentsVersion();
        }
        const cpyListeners = [...listenerList];
        cpyListeners.push(currentDocument.id);
        setListenerList(cpyListeners);
      }
    }
  }

  function getFileTypeToUpload(document) {
    const fileId = getRandomId();
    const fileVersionId = getRandomId();
    if (document.placeholder && document.type === "file") {
      setTypeUpload({ type: "uploadPlaceholder", fileId, fileVersionId });
    } else if (document.type === TYPE_OF_FILE.FOLDER) {
      setTypeUpload({ type: "uploadFileInFolder", fileId, fileVersionId });
    } else if (!document.placeholder && document.type !== TYPE_OF_FILE.FOLDER) {
      setTypeUpload({ type: "uploadNewVersionFile", fileId, fileVersionId });
    }
  }

  function onUploadFiles() {
    getFileTypeToUpload(currentDocument);
    if (
      currentDocument.placeholder &&
      currentDocument.type !== TYPE_OF_FILE.FOLDER
    ) {
      setOpen(false);
    } else {
      setOpen(true);
    }
    fileUploaderRef.current.click();
  }

  function iconUploadNewVersionFile() {
    setShowSelectedPermissions(false);
    setUploadFileVersion(true);
    onUploadFiles();
  }

  function handleVerifyExistPlaveholderFile(document, documents) {
    if (document.parentId !== "") {
      const parentDocument = documents.find(
        (doc) => doc.id === document.parentId
      );
      const parentDocumentChilds = documents.filter(
        (doc) => doc.parentId === parentDocument.id
      );
      let exists = false;
      parentDocumentChilds.forEach((docChild) => {
        if (
          docChild.id !== document.id &&
          docChild.placeholder &&
          docChild.type === TYPE_OF_FILE.FOLDER &&
          verifyFolderContainPlaceholderFile({
            folder: docChild,
            documents,
            currentUser,
            updateDoc,
          })
        ) {
          exists = true;
        }
        if (
          docChild.id !== document.id &&
          docChild.placeholder &&
          docChild.type !== TYPE_OF_FILE.FOLDER
        ) {
          exists = true;
        }
      });
      if (!exists) {
        if (parentDocument.placeholder) {
          updateDoc(parentDocument.ref, {
            lastModified: now(),
            created: now(),
            placeholder: false,
            user: currentUser.id,
            createdBy: currentUser.id,
          });
        } else {
          updateDoc(parentDocument.ref, {
            lastModified: now(),
            user: currentUser.id,
          });
        }
        handleVerifyExistPlaveholderFile(parentDocument, documents);
      }
    }
  }

  async function updateLastModifiedDateParentsFolders(document) {
    if (document.placeholder && document.type !== "folder") {
      handleVerifyExistPlaveholderFile(document, documentFlat);
    } else {
      const children = documentFlat.filter(
        (doc) => doc.parentId === document.id && doc.placeholder
      );
      if (children.length > 0) {
        return console.log("THERE ARE PENDING FILES::");
      } else {
        if (document.placeholder) {
          updateDoc(document.ref, {
            lastModified: now(),
            created: now(),
            placeholder: false,
            user: currentUser.id,
            createdBy: currentUser.id,
          });
        } else {
          updateDoc(document.ref, {
            lastModified: now(),
            user: currentUser.id,
          });
        }

        if (document.parentId) {
          let currentDocument;
          if (
            document.scope === documentScope.SALES_ORDER ||
            document.scope === documentScope.FACTORY ||
            document.scope === documentScope.CUSTOMER ||
            document.scope === documentScope.PURCHASE_ORDER
          ) {
            currentDocument = await getDataFromFirestore([
              dbTables.COMPANIES,
              companyID,
              mainTable,
              mainTableID,
              dbTable,
              document.parentId,
            ]);
          } else {
            currentDocument = await getDataFromFirestore([
              dbTables.COMPANIES,
              companyID,
              dbTables.SHIPMENTS,
              document.shipmentId,
              dbTables.SHIPMENT_DOCUMENTS,
              document.parentId,
            ]);
          }

          updateLastModifiedDateParentsFolders(currentDocument);
        }
      }
    }
  }

  async function onRename() {
    if (!navigator.onLine) {
      setCreateNewFolder(false);
      setEditing(false);
      setFolderName(defaultFolderName);
      onOpenSnackBar(
        currentDocument.type !== TYPE_OF_FILE.FOLDER
          ? "File name change"
          : "Folder name change"
      );
      return;
    }
    if (!folderName.trim()) {
      setCreateNewFolder(false);
      setEditing(false);
      setFolderName(defaultFolderName);
      return;
    }
    if (
      currentDocument.type === TYPE_OF_FILE.FOLDER &&
      currentDocument.name === folderName
    ) {
      setCreateNewFolder(false);
      setEditing(false);
      setFolderName(defaultFolderName);
      return;
    }
    if (
      currentDocument.type !== TYPE_OF_FILE.FOLDER &&
      getFileName(currentDocument.name) === folderName
    ) {
      setCreateNewFolder(false);
      setEditing(false);
      setFolderName(defaultFolderName);
      return;
    }
    if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - Folder - Renamed", {
      //   name: currentDocument.name
      // });
      //
    } else {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - File - Renamed", {
      //   name: currentDocument.name
      // });
      //
    }
    // IMPLEMENTING TO ADD SUPER ADMIN IF IT IS NOT A PART OF THIS DOCUMENT
    if (currentUser.role === userTypes.SUPER_ADMIN) {
      superAdminAssignmentPermissions({
        currentDocument,
        permissionGroupsDB,
        currentUser,
        allDocuments: documentFlat,
      });
    }
    //
    updateLastModifiedDateParentsFolders(currentDocument);
    const success = await onRenameDocuments(
      currentDocument,
      currentUser,
      documents,
      folderName
    );
    if (!success) {
      setOpenModalAttention(true);
      const errorMessage =
        currentDocument.type === TYPE_OF_FILE.FOLDER
          ? "errorWithEspecialCharacter"
          : "errorWithEspecialCharacterFile";
      setDescriptionModalAttention(errorMessage);
    }
    setCreateNewFolder(false);
    setEditing(false);
    setFolderName(defaultFolderName);
  }

  function onPermission() {
    if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - Folder - Permissions Updated");
      //
    } else {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - File - Permissions Updated");
      //
    }
    setJustViewPermission(true);
    setTypePermissionModal(typeModalPermissions.VIEW_PERMISSIONS);
    setShowSelectedPermissions(true);
    setOpenModalPermission(true);
  }

  function onOpenContentAndAddFolder(folder) {
    setOpen(true);
    setCreateNewFolder(true);
  }

  function handleCancelSaveFolder() {
    setFolderName(defaultFolderName);
    setCreateNewFolder(false);
  }

  function handleSaveFolder() {
    if (!folderName.trim()) {
      setCreateNewFolder(false);
      setFolderName(defaultFolderName);
      return;
    }
    if (folderName.includes("/")) {
      setCreateNewFolder(false);
      setFolderName(defaultFolderName);
      setOpenModalAttention(true);
      setDescriptionModalAttention("errorWithEspecialCharacter");
      return;
    }
    onAddNewFolder(currentDocument, folderName);
    setCreateNewFolder(false);
    setFolderName(defaultFolderName);
  }

  function handleFileNameError() {
    setOpenModalAttention(true);
    setDescriptionModalAttention("errorWithEspecialCharacterFile");
  }

  async function handleUploadPlaceholder(newFile) {
    const path = getDocumentPath({
      companyId: companyID,
      mainTable,
      mainTableId: mainTableID,
      dbTable,
      documentId: newFile.documentVersionId,
      documentVersionId: newFile.documentId,
      scope: newFile.scope,
      shipmentId: currentDocument.shipmentId,
    });
    const dataFile = await getDataFromUploadFile(
      newFile,
      path,
      currentUser,
      currentDocument,
      uploadFileVersion
    );
    setFileVersionToUpload((oldFiles) => {
      return oldFiles.map((file) => {
        if (file.name === newFile.name) {
          return {
            ...newFile,
            ...dataFile,
            newFile,
            completed: true,
          };
        } else {
          return file;
        }
      });
    });
  }
  async function handleUploadFileInFolder(newFile) {
    const path = getDocumentPath({
      companyId: companyID,
      mainTable,
      mainTableId: mainTableID,
      dbTable,
      documentId: newFile.documentVersionId,
      documentVersionId: newFile.documentId,
      scope: newFile.scope,
      shipmentId: currentDocument.shipmentId,
    });
    const dataFile = await getDataFromUploadFile(
      newFile,
      path,
      currentUser,
      currentDocument,
      uploadFileVersion
    );
    setFileVersionToUpload((oldFiles) => {
      return oldFiles.map((file) => {
        const name = newFile.name;
        if (file.name === name) {
          return {
            ...newFile,
            ...dataFile,
            newFile,
            completed: true,
            name: name,
          };
        } else {
          return file;
        }
      });
    });
  }

  async function handleUploadFileVersion(newFile) {
    const path = getDocumentPath({
      companyId: companyID,
      mainTable,
      mainTableId: mainTableID,
      dbTable,
      documentId: newFile.documentVersionId,
      documentVersionId: newFile.documentId,
      scope: newFile.scope,
      shipmentId: currentDocument.shipmentId,
    });
    const dataFile = await getDataFromUploadFile(
      newFile,
      path,
      currentUser,
      currentDocument,
      uploadFileVersion
    );
    setFileVersionToUpload((oldFiles) => {
      return oldFiles.map((file) => {
        const name = newFile.name;
        if (file.name === name) {
          return {
            ...newFile,
            ...dataFile,
            newFile,
            completed: true,
          };
        } else {
          return file;
        }
      });
    });
  }

  async function handleSuccess(data) {
    switch (typeUpload.type) {
      case "uploadPlaceholder":
        handleUploadPlaceholder(data);
        break;
      case "uploadFileInFolder":
        handleUploadFileInFolder(data);
        break;
      case "uploadNewVersionFile":
        handleUploadFileVersion(data);
        break;
      default:
        break;
    }
  }

  function handleUploadFiles() {
    if (navigator.onLine) {
      if (
        typeUpload.type === "uploadNewVersionFile" ||
        typeUpload.type === "uploadPlaceholder" ||
        typeUpload.type === "uploadFileInFolder"
      ) {
        if (typeUpload.type === "uploadFileInFolder") {
          // HERE PENDO
          // trackEvent("PO Dashboard - Documents - File - New in Folder");
          //
        } else if (typeUpload.type === "uploadPlaceholder") {
          // HERE PENDO
          // trackEvent("PO Dashboard - Documents - File - Uploaded Placeholder");
          //
        } else if (typeUpload.type === "uploadNewVersionFile") {
          // HERE PENDO
          // trackEvent("PO Dashboard - Documents - File - New Version");
          //
        }
        fileVersionToUpload.forEach((fileData) => {
          const { fileVersion, file, newFile } = fileData;
          if (!newFile) return;
          if (
            newFile.scope === documentScope.SALES_ORDER ||
            newFile.scope === documentScope.PURCHASE_ORDER ||
            newFile.scope === documentScope.CUSTOMER ||
            newFile.scope === documentScope.FACTORY
          ) {
            addDocumentVersion(
              {
                ...file,
                mainFileId: fileVersion.id,
                created: now(),
                lastModified: now(),
              },
              newFile.documentVersionId
            );
            addDocument({
              ...fileVersion,
              permissionUsers: currentDocument.permissionUsers,
              permissionGroups: currentDocument.permissionGroups,
              created: now(),
              lastModified: now(),
            });
          } else {
            addShipmentDocumentVersion(
              {
                ...file,
                mainFileId: fileVersion.id,
                created: now(),
                lastModified: now(),
              },
              newFile.documentVersionId
            );
            addShipmentDocument({
              ...fileVersion,
              permissionUsers: currentDocument.permissionUsers,
              permissionGroups: currentDocument.permissionGroups,
              created: now(),
              lastModified: now(),
            });
          }

          if (currentUser.role === userTypes.SUPER_ADMIN) {
            superAdminAssignmentPermissions({
              currentDocument,
              permissionGroupsDB,
              currentUser,
              allDocuments: documentFlat,
            });
          }
          addNewDocumentType({ company, document: file });
        });
        updateLastModifiedDateParentsFolders(currentDocument);
      }
    } else {
      onOpenSnackBar("File upload");
    }
    setOpenModalPermission(false);
    resetFiles();
  }

  const resetFiles = () => {
    setProgressUpload(0);
    setFileVersionToUpload([]);
    setFileUploading([]);
    setTypeUpload({});
    handleCloseAlert();
  };

  async function handleSuccessDrag(newFiles) {
    if (!navigator.onLine) {
      onOpenSnackBar("File upload");
      return;
    }

    newFiles.forEach(async (currentFile) => {
      const path = getDocumentPath({
        companyId: companyID,
        mainTable,
        mainTableId: mainTableID,
        dbTable,
        documentId: currentFile.documentVersionId,
        documentVersionId: currentFile.documentId,
        scope: currentFile.scope,
        shipmentId: currentDocument.shipmentId,
      });
      const dataFile = await getDataFromUploadFile(
        currentFile,
        path,
        currentUser,
        currentDocument
      );
      const { fileVersion, file } = dataFile;
      if (
        currentFile.scope === documentScope.SALES_ORDER ||
        currentFile.scope === documentScope.FACTORY ||
        currentFile.scope === documentScope.CUSTOMER ||
        currentFile.scope === documentScope.PURCHASE_ORDER
      ) {
        addDocument({
          ...fileVersion,
          size: currentFile.size,
          permissionUsers: currentDocument.permissionUsers,
          permissionGroups: currentDocument.permissionGroups,
          created: now(),
          lastModified: now(),
        });
        addDocumentVersion(
          {
            ...file,
            size: currentFile.size,
            mainFileId: fileVersion.id,
            created: now(),
            lastModified: now(),
          },
          currentFile.documentVersionId
        );
      } else {
        addShipmentDocument({
          ...fileVersion,
          size: currentFile.size,
          permissionUsers: currentDocument.permissionUsers,
          permissionGroups: currentDocument.permissionGroups,
          created: now(),
          lastModified: now(),
        });
        addShipmentDocumentVersion(
          {
            ...file,
            size: currentFile.size,
            mainFileId: fileVersion.id,
            created: now(),
            lastModified: now(),
          },
          currentFile.documentVersionId
        );
      }

      // trackEvent("PO Dashboard - Documents - Upload via Drag-and-Drop", {
      //   name: currentDocument.name
      // });
      updateDoc(currentDocument.ref, {
        lastModified: now(),
        user: currentUser.id,
      });
      if (currentUser.role === userTypes.SUPER_ADMIN) {
        superAdminAssignmentPermissions({
          currentDocument,
          permissionGroupsDB,
          currentUser,
          allDocuments: documentFlat,
        });
      }
      addNewDocumentType({ company, document: file });
    });

    updateLastModifiedDateParentsFolders(currentDocument);
    resetFiles();

    // trackEvent("PO Dashboard - Documents - Upload via Drag-and-Drop", {
    //   name: currentDocument.name
    // });

    updateLastModifiedDateParentsFolders(currentDocument);
  }

  function handleRename() {
    setEditing(true);
    setFolderName(
      currentDocument.type === TYPE_OF_FILE.FOLDER
        ? currentDocument.name
        : getFileName(currentDocument.name)
    );
  }

  async function handleFiles(event, byMenu = false) {
    if (disabled) {
      return;
    }
    let files = !byMenu ? event.dataTransfer.files : event.target.files;
    files = Array.from(files).sort((a, b) => {
      return b.name.localeCompare(a.name);
    });
    const filesUpload = files.filter((file) => hasExtension(file.name));
    if (filesUpload.length === 0) {
      setDescriptionModalAttention("blockUploadExtension");
      setOpenModalAttention(true);
      return;
    }
    if (filesUpload.length !== files.length) {
      setSnackOption({
        type: "warning",
        message:
          "Only files with extensions are being uploaded, the others will be skipped",
      });
    }
    files = filesUpload;
    const originalFile = [];
    const filesData = [];

    const maxFiles =
      currentDocument.type === TYPE_OF_FILE.FOLDER && allowMultipleFiles
        ? files.length
        : 1;

    for (let i = 0; i < maxFiles; i++) {
      const file = files[i];
      const fileData = getDataFromFile(file, currentDocument);
      if (!fileData.name.includes("/")) {
        const path = getDocumentPath({
          companyId: companyID,
          mainTable,
          mainTableId: mainTableID,
          dbTable,
          documentId: fileData.documentVersionId,
          documentVersionId: fileData.documentId,
          shipmentId: currentDocument.shipmentId,
          scope: currentDocument.scope,
        });
        filesData.push({
          ...fileData,
          scope: currentDocument.scope,
          path: `${path}/${fileData.name}`,
          file,
        });
        originalFile.push(file);
      }
    }
    if (filesData.length === 0) {
      return handleFileNameError();
    }
    setFileVersionToUpload(filesData);
    setTypePermissionModal(typeModalPermissions.FILE_CHILD_UPLOAD);
    setJustViewPermission(false);
    setOpenModalPermission(true);
    for (let j = 0; j < filesData.length; j++) {
      const fileData = filesData[j];

      const storageRef = ref(storage, fileData.path);
      const task = uploadBytesResumable(storageRef, originalFile[j]);
      task.on(
        "state_changed",
        (snapshot) => {
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressUpload((oldValues) => {
            const newValues = typeof oldValues === "object" ? oldValues : {};
            return {
              ...newValues,
              [fileData.name]: percent,
            };
          });
          if (percent === 0) {
            setFileUploading((oldFiles) => {
              const newFiles = oldFiles;
              newFiles.push(task);
              return newFiles;
            });
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          if (byMenu) {
            handleSuccess({
              ...fileData,
              completed: true,
            });
          } else {
            setFileVersionToUpload((oldData) =>
              replaceDocumentState({ oldData, fileData })
            );
          }
        }
      );
      await task;
    }
  }

  async function handleMoveFolderDependencies(
    draggableDocument,
    documentPath,
    documentId,
    permissionUsers,
    permissionGroups
  ) {
    updateDoc(draggableDocument.ref, {
      path: `${documentPath}/${draggableDocument.name}`,
      parentId: documentId,
      permissionUsers: permissionUsers ? permissionUsers : {},
      permissionGroups: permissionGroups ? permissionGroups : {},
    });
    if (draggableDocument.child.length > 0) {
      for (let index = 0; index < draggableDocument.child.length; index++) {
        handleMoveFolderDependencies(
          draggableDocument.child[index],
          `${documentPath}/${draggableDocument.name}`,
          draggableDocument.id,
          permissionUsers,
          permissionGroups
        );
      }
    }
  }

  async function handleDraggableDocument(document) {
    if (disabled) return;
    const allChildDocument = documentFlat.filter(
      (doc) => doc.parentId === draggableDocument.id
    );
    if (draggableDocument.scope !== document.scope) {
      setDescriptionModalAttention("cannotMoveShipmentDocumentOutside");
      setOpenModalAttention(true);
    } else if (
      draggableDocument.type === TYPE_OF_FILE.FOLDER &&
      allChildDocument.length !== draggableDocument.child.length
    ) {
      setDescriptionModalAttention("userNoHavePermissionForAllFiles");
      setOpenModalAttention(true);
    } else if (draggableDocument.placeholder) {
      setDescriptionModalAttention("cantMovePlaceholder");
      setOpenModalAttention(true);
    } else if (document.type !== TYPE_OF_FILE.FOLDER) {
      console.log("cannot move here...");
      return;
    } else if (draggableDocument.parentId === document.id) {
      console.log("cannot move here, same parent.....");
    } else if (
      draggableDocument.id === document.id ||
      document.path.includes(draggableDocument.path)
    ) {
      setDescriptionModalAttention("cantMoveToDependency");
      setOpenModalAttention(true);
    } else if (draggableDocument.type !== TYPE_OF_FILE.FOLDER) {
      console.log("MOVING FILE INTO A FOLDER");
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - File - Moved by Drag", {
      //   name: draggableDocument.name
      // });
      //
      updateParentFolderTouched(
        { ...draggableDocument },
        documentFlat,
        currentUser
      );
      updateDoc(draggableDocument.ref, {
        path: `${document.path}/${draggableDocument.name}`,
        parentId: document.id,
        user: currentUser.id,
        permissionUsers: currentDocument.permissionUsers,
        permissionGroups: currentDocument.permissionGroups,
      });
      updateDoc(currentDocument.ref, {
        user: currentUser.id,
        lastModified: now(),
      });
      updateParentFolderTouched(currentDocument, documentFlat, currentUser);
      updateLastModifiedDateParentsFolders(currentDocument);
      setOpenModalAttention(true);
      setDescriptionModalAttention("movingFile");
    } else {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - Folder - Moved by Drag", {
      //   name: draggableDocument.name
      // });
      //
      updateParentFolderTouched(
        { ...draggableDocument },
        documentFlat,
        currentUser
      );
      updateDoc(draggableDocument.ref, {
        user: currentUser.id,
        lastModified: now(),
      });
      handleMoveFolderDependencies(
        draggableDocument,
        document.path,
        document.id,
        currentDocument.permissionUsers,
        currentDocument.permissionGroups
      );
      updateDoc(currentDocument.ref, {
        user: currentUser.id,
        lastModified: now(),
      });
      updateParentFolderTouched(currentDocument, documentFlat, currentUser);
      setOpenModalAttention(true);
      setDescriptionModalAttention("movingFolder");
    }
  }

  function contextMenu(event) {
    if (currentOrderVoid()) {
      return;
    }
    if (isReadOnly) {
      handleReadOnlyModal();
      return;
    }
    if (disabled) {
      return;
    }
    show({ event });
    event.preventDefault();
    event.stopPropagation();
  }

  function onCloseModalPermission() {
    setOpenModalPermission(false);
  }

  function getDescriptionAttention() {
    switch (descriptionModalAttention) {
      case "movingFile":
        return (
          <React.Fragment>
            Moving this file to a different folder <br /> will assign it that
            folder's access permission
          </React.Fragment>
        );
      case "movingFolder":
        return (
          <React.Fragment>
            Moving this folder to a different folder <br /> will assign it that
            folder's access permission
          </React.Fragment>
        );
      case "cantMovePlaceholder":
        return (
          <React.Fragment>
            Placeholder files and folders cannot be moved
          </React.Fragment>
        );
      case "cantMoveToDependency":
        return (
          <React.Fragment>
            A folder cannot be moved inside of its inner folder
          </React.Fragment>
        );
      case "userNoHavePermissionForAllFiles":
        return (
          <React.Fragment>
            You do not have permission to some items within this folder;
            therefore, the move operation cannot be completed
          </React.Fragment>
        );
      case "cannotMoveShipmentDocumentOutside":
        return (
          <React.Fragment>
            It's not possible to move a file/folder outside of its scope
          </React.Fragment>
        );
      case "errorWithEspecialCharacter":
        return <React.Fragment>{errorDocumentName.folder}</React.Fragment>;
      case "errorWithEspecialCharacterFile":
        return <React.Fragment>{errorDocumentName.file}</React.Fragment>;
      case "blockUploadExtension":
        return (
          <React.Fragment>
            Only files with an extension at the end can be uploaded. Nothing was
            uploaded in this case.
          </React.Fragment>
        );
      default:
        return <React.Fragment>Default Message</React.Fragment>;
    }
  }

  async function handleUploadPermissionDependencies(
    document,
    permissionUsers,
    permissionGroups,
    rootDocument,
    permissionGroupsDB
  ) {
    const child = document.child || [];
    for (let index = 0; index < child.length; index++) {
      if (child[index].child.length > 0) {
        handleUploadPermissionDependencies(
          child[index],
          permissionUsers,
          permissionGroups,
          rootDocument,
          permissionGroupsDB
        );
      }
      const originalChildUser = child[index].permissionUsers;
      let childPermissionUsers = child[index].permissionUsers;
      let childPermissionGroups = child[index].permissionGroups;
      const parentGroupsList = Object.keys(permissionGroups).map(
        (group) => group
      );
      Object.keys(childPermissionGroups).forEach((group) => {
        if (!permissionGroups[group]) {
          delete childPermissionGroups[group];
        }
      });

      Object.keys(childPermissionUsers).forEach((userId) => {
        let erase = true;
        const currentUserDB = users.find((user) => user.id === userId);
        if (originalChildUser[userId] && permissionUsers[userId]) {
          erase = false;
        } else if (currentUserDB) {
          const userPermissionGroups = currentUserDB.permissionGroups || {};
          const userGroupsList = Object.keys(userPermissionGroups).map(
            (group) => group
          );
          const found = userGroupsList.some((permission) =>
            parentGroupsList.includes(permission)
          );
          if (found) {
            erase = false;
          }
        }
        if (erase) {
          delete childPermissionUsers[userId];
        }
      });
      updateDoc(child[index].ref, {
        permissionUsers: { ...childPermissionUsers, [currentUser.id]: true },
        permissionGroups: childPermissionGroups,
        hasPermissionActivity: false,
      });
    }

    if (document.id === rootDocument) {
      updateDoc(document.ref, {
        permissionUsers,
        permissionGroups,
        hasPermissionActivity: true,
        user: currentUser.id,
      });
    } else {
      updateDoc(document.ref, {
        permissionUsers,
        permissionGroups,
        hasPermissionActivity: false,
      });
    }
  }

  async function handleUploadUserPermissions(permissions) {
    const { permissionUsers, permissionGroups } = permissions;
    if (!justViewPermissions) {
      handleSuccessDrag(fileVersionToUpload);
      setOpenModalPermission(false);
      setTypeUpload({});
      return;
    }
    if (navigator.onLine) {
      handleUploadPermissionDependencies(
        currentDocument,
        permissionUsers,
        permissionGroups,
        currentDocument.id,
        permissionGroupsDB
      );
      updateLastModifiedDateParentsFolders(currentDocument);
    } else {
      onOpenSnackBar("Permission change");
    }
    setOpenModalPermission(false);
    setJustViewPermission(false);
  }

  function notificatorErrorRemoveUser() {
    console.log("this is for enable a modal");
  }

  function getTitleModalPermission() {
    if (typePermissionModal === typeModalPermissions.FILE_CHILD_UPLOAD) {
      return (
        <React.Fragment>
          This file will be assigned <br /> <i>{currentDocument.name}</i>{" "}
          folder's permissions{" "}
        </React.Fragment>
      );
    } else if (typePermissionModal === typeModalPermissions.FILE_ROOT_UPLOAD) {
      return "ROOT";
    } else {
      return "Access Groups";
    }
  }

  const handleUploadMenu = () => {
    setUploadFileVersion(true);
    onUploadFiles();
  };

  const folderColor =
    currentDocument.type === TYPE_OF_FILE.FOLDER &&
    backgroundColor[currentDocument.scope];
  const documentId = getDocumentId(currentDocument);

  const handleCloseAlert = (ev, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOption({});
  };

  return (
    <ListItemDocumentStyled className="listItemDocumentContainer">
      <div
        style={
          backdropState.id.includes(documentId)
            ? {
                zIndex: 1202,
                position: "relative",
                backgroundColor: folderColor,
              }
            : {}
        }
        id={getDocumentId(currentDocument)}
        onDragStart={(event) => {
          onDraggableDocument(currentDocument);
          event.stopPropagation();
        }}
        onDragEnd={(event) => {
          onDragEnd();
          event.stopPropagation();
        }}
        className={classNames(
          currentDocument.scope === documentScope.SHIPMENT &&
            currentDocument.type === TYPE_OF_FILE.FOLDER &&
            currentDocument.isShipmentRootTask
            ? "listemItemDocumentShipment"
            : "listemItemDocument",
          {
            [stylesDiv]: stylesDiv,
          }
        )}
        onDrop={(dropEvent) => {
          dropEvent.preventDefault();
          if (isReadOnly) {
            handleReadOnlyModal();
            return;
          }
          if (isDraggableDocument) {
            handleDraggableDocument(currentDocument);
          } else {
            setTypeUpload({ type: "draggableUpload" });
            handleFiles(dropEvent);
          }
          setStylesDiv("draggeable-file-disable");
          dropEvent.stopPropagation();
          handleDraggableDocumentClean();
        }}
        onDragOver={(dragOverEvent) => {
          dragOverEvent.preventDefault();
          setStylesDiv("draggeable-file-enable");
          dragOverEvent.stopPropagation();
        }}
        onDragEnter={(event) => {
          setStylesDiv("draggeable-file-disable");
          event.stopPropagation();
        }}
        onDragLeave={() => {
          setStylesDiv("draggeable-file-disable");
        }}
      >
        <UploadFiles
          onRef={(el) => {
            fileUploaderRef.current = el;
          }}
          companyID={companyID}
          mainTable={mainTable}
          mainTableID={mainTableID}
          documents={uploadFileVersion ? documents : currentDocument.child}
          currentDocument={currentDocument}
          dbTable={dbTable}
          selectedDocumentTab={selectedDocumentTab}
          typeUpload={typeUpload}
          handleFiles={handleFiles}
          allowMultipleFiles={allowMultipleFiles}
        />

        {openModalAttention && (
          <AttentionModal
            description={<span> {getDescriptionAttention()}</span>}
            isOpen={openModalAttention}
            onClose={() => setOpenModalAttention(false)}
            title={<span>Attention</span>}
            onClick={() => setOpenModalAttention(false)}
          />
        )}

        {openModalPermission && (
          <ModalPermission
            title={getTitleModalPermission()}
            onlyTitle
            open={openModalPermission}
            onClose={onCloseModalPermission}
            warningMessage={
              snackOption.message && (
                <Alert
                  style={{
                    width: "100%",
                    marginBottom: 18,
                    border: "2px solid #d5b54f",
                  }}
                  severity={snackOption.type}
                >
                  {snackOption.message}
                </Alert>
              )
            }
            file={
              typeUpload.type === "draggableUpload"
                ? fileVersionToUpload
                : (typeUpload.type === "uploadNewVersionFile" ||
                    typeUpload.type === "uploadPlaceholder" ||
                    typeUpload.type === "uploadFileInFolder") &&
                  !justViewPermissions
                ? fileVersionToUpload
                : currentDocument
            }
            handleCancel={() => {
              fileUploading.forEach((fileTask) => {
                Object.keys(fileTask).length > 0 && fileTask.cancel();
              });

              setOpenModalPermission(false);
              resetFiles();

              if (fileVersionToUpload.length) {
                fileVersionToUpload.forEach((file) => {
                  if (file.completed) {
                    deleteObject(ref(storage, file.path));
                  }
                });
              }
            }}
            handleDone={
              (typeUpload.type === "uploadNewVersionFile" ||
                typeUpload.type === "uploadPlaceholder" ||
                typeUpload.type === "uploadFileInFolder") &&
              !justViewPermissions
                ? handleUploadFiles
                : handleUploadUserPermissions
            }
            companyUsers={companyUsers}
            progressUpload={progressUpload}
            permissionGroupsDB={permissionGroupsDB}
            showSelectedPermissions={showSelectedPermissions}
            currentUser={currentUser.id}
            notificatorErrorRemoveUser={notificatorErrorRemoveUser}
            typePermissionModal={typePermissionModal}
            parentDocument={parentDocument}
          />
        )}

        <ListItem
          id={"menu-provider-list-item" + currentDocument.id}
          className={cx(
            getListItemClassName(currentDocument, open),
            "listDocumentsEffect"
          )}
          onClick={
            currentDocument.placeholder && currentDocument.type === "file"
              ? handleUploadMenu
              : handleClick
          }
          onContextMenu={
            currentDocument.placeholder && currentDocument.type === "file"
            ? handleUploadMenu
            : contextMenu
          }
          style={{
            borderBottom: currentDocument.isShipmentRootTask ? "none" : "",
          }}
        >
          {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
            <ListItemIcon className={"listItemIcon"}>
              <img
                className={"imgListItemIcon"}
                src={folderPlaceholder}
                alt="folder"
              />
            </ListItemIcon>
          ) : (
            <ListItemIcon className={"listItemIcon"}>
              <TypeFileSelector
                className={
                  currentDocument.mainFile
                    ? "labelVersionSmallCenter"
                    : "labelVersionBotton"
                }
                path={currentDocument.path}
                version={currentDocument.version}
                type={currentDocument.type}
                currentDocument={currentDocument}
              />
            </ListItemIcon>
          )}

          <ListItemTextDocument
            currentDocument={currentDocument}
            onRename={disabled ? null : onRename}
            onEditing={() =>
              disabled || currentDocument.placeholder ? null : setEditing(true)
            }
            onFolderName={(name) => {
              setFolderName(name);
            }}
            editing={editing}
            folderName={folderName}
            handleEnableContextMenu={contextMenu}
            disabled={disabled || currentDocument.mainFileId}
            isReadOnly={isReadOnly}
            handleReadOnlyModal={handleReadOnlyModal}
            handleOpenFolder={handleClick}
            purchaseOrderID={mainTableID}
            onChangeShipment={onChangeShipment}
            nivel={nivel}
          />

          {(currentDocument.placeholder &&
            currentDocument.type !== TYPE_OF_FILE.FOLDER) ||
          (currentDocument.placeholder && currentOrderVoid()) ? null : (
            <MenuSelector
              disabled={disabled}
              isOrderVoided={currentOrderVoid()}
              onOpenContentAndAddFolder={onOpenContentAndAddFolder}
              onUploadFiles={onUploadFiles}
              handleRename={handleRename}
              onPermission={onPermission}
              handleDeleteFolder={handleDeleteFolder}
              currentDocument={currentDocument}
              iconUploadNewVersionFile={iconUploadNewVersionFile}
              handleEnableRightClick={contextMenu}
              companyID={companyID}
              mainTable={mainTable}
              mainTableID={mainTableID}
              dbTable={dbTable}
              selectedDocumentTab={selectedDocumentTab}
              currentUser={currentUser}
              onOpenSnackBar={(description) => {
                onOpenSnackBar(description);
              }}
              documentFlat={documentFlat}
              onShowModal={onShowModal}
              permissionGroupsDB={permissionGroupsDB}
              isReadOnly={isReadOnly}
              handleReadOnlyModal={handleReadOnlyModal}
              companyUsers={companyUsers}
            />
          )}
        </ListItem>

        {((currentDocument.type === TYPE_OF_FILE.FOLDER &&
          currentDocument.placeholder) ||
          !currentDocument.placeholder) && (
          <MenuOptions
            onAddNewFolder={onOpenContentAndAddFolder}
            onUploadFiles={onUploadFiles}
            onRename={handleRename}
            onPermission={onPermission}
            onDeleteEvent={handleDeleteFolder}
            currentDocument={currentDocument}
            iconUploadNewVersionFile={iconUploadNewVersionFile}
            type="main"
            selectedDocumentTab={selectedDocumentTab}
            companyID={companyID}
            mainTable={mainTable}
            mainTableID={mainTableID}
            dbTable={dbTable}
            disabled={disabled}
            currentUser={currentUser}
            onOpenSnackBar={(description) => {
              onOpenSnackBar(description);
            }}
            isOrderVoided={currentOrderVoid()}
            calledByMenuOptions={false}
            documentFlat={documentFlat}
            onShowModal={onShowModal}
            permissionGroupsDB={permissionGroupsDB}
            isReadOnly={isReadOnly}
            handleReadOnlyModal={handleReadOnlyModal}
            companyUsers={companyUsers}
          />
        )}

        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          classes={{
            root: "container-collapse-item",
            entered: "entered-collapse-item",
            wrapper: "wrapper-collapse-item",
            wrapperInner: "wrapper-inner-collapse-item",
          }}
        >
          <div
            id={currentDocument.id + "item-document"}
            className={
              currentDocument.type === TYPE_OF_FILE.FOLDER
                ? "listItemDocumentCollapseItemsFolder"
                : "listItemDocumentCollapseItems"
            }
            style={{ paddingLeft: "12px" }}
          >
            {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
              <React.Fragment>
                <div
                  className={"leftContainerLineDivider"}
                  // style={{ width: "4%", marginTop: -18 }}
                >
                  <div
                    className="left-line-divider"
                    style={{
                      position: "relative",
                      background: AppConfig.themeColors.dividerColor,
                      width: 1,
                      height: "98%",
                      left: 16,
                    }}
                  />
                </div>
                <FoldersStructure
                  documents={currentDocument.child ? currentDocument.child : []}
                  handleDeleteFolder={handleDeleteFolder}
                  onAddNewFolder={onAddNewFolder}
                  createNewFolder={createNewFolder}
                  folderName={folderName}
                  onChange={(event) => setFolderName(event.target.value)}
                  handleSaveFolder={handleSaveFolder}
                  handleCancelSaveFolder={handleCancelSaveFolder}
                  companyID={companyID}
                  mainTable={mainTable}
                  mainTableID={mainTableID}
                  dbTable={dbTable}
                  selectedDocumentTab={selectedDocumentTab}
                  permissionGroupsDB={permissionGroupsDB}
                  onDraggableDocument={onDraggableDocument}
                  draggableDocument={draggableDocument}
                  isDraggableDocument={isDraggableDocument}
                  handleDraggableDocumentClean={handleDraggableDocumentClean}
                  documentFlat={documentFlat}
                  hasParentPermission={true}
                  parentDocument={currentDocument}
                  user={currentUser}
                  disabled={disabled}
                  isExpand={isExpand}
                  handleExpandOrCollapse={handleExpandOrCollapse}
                  onOpenSnackBar={onOpenSnackBar}
                  onShowModal={onShowModal}
                  isReadOnly={isReadOnly}
                  handleReadOnlyModal={handleReadOnlyModal}
                  querySearch={querySearch}
                  allowMultipleFiles={allowMultipleFiles}
                  onChangeShipment={onChangeShipment}
                  nivel={nivel + 1}
                />
              </React.Fragment>
            ) : (
              <FoldersStructure
                documents={filterDocuments}
                dbTable={dbTable}
                selectedDocumentTab={selectedDocumentTab}
                isDocumentVersion={true}
                companyID={companyID}
                mainTable={mainTable}
                mainTableID={mainTableID}
                documentFlat={documentFlat}
                permissionGroupsDB={permissionGroupsDB}
                hasParentPermission={true}
                parentDocument={currentDocument}
                user={currentUser}
                disabled={disabled}
                isExpand={isExpand}
                handleExpandOrCollapse={handleExpandOrCollapse}
                onOpenSnackBar={onOpenSnackBar}
                onShowModal={onShowModal}
                isReadOnly={isReadOnly}
                handleReadOnlyModal={handleReadOnlyModal}
                querySearch={querySearch}
                allowMultipleFiles={allowMultipleFiles}
                onChangeShipment={onChangeShipment}
                nivel={nivel + 1}
              />
            )}
          </div>
        </Collapse>
        {((open && currentDocument.type === TYPE_OF_FILE.FOLDER) ||
          (open && currentDocument.mainFile && currentDocument.version > 1)) &&
          !currentDocument.isShipmentRootTask && (
            <div
              className="botton-line-divider"
              style={{
                height: 1,
                background: AppConfig.themeColors.dividerColor,
                marginTop: 8,
                marginBottom: 2,
                marginLeft: 23,
                marginRight: 11,
              }}
            ></div>
          )}
      </div>
      {isLastTemplate && (
        <div className={"dividerPlaceholderDocuments"}>
          <PendingDivider
            text="Pending Placeholder Items"
            style={{
              background: getBackgroundColorByType({
                type:
                  currentDocument.scope === documentScope.SALES_ORDER
                    ? documentScope.SALES_ORDER
                    : documentScope.PURCHASE_ORDER,
              }),
            }}
          />
        </div>
      )}
    </ListItemDocumentStyled>
  );
}

export default ListItemDocument;
