import React, { useEffect, useState } from "react";
import { useCompanyId } from "../../hooks";
import { useCompanyUsers, useUser } from "../../hooks/user";
import IntlMessages from "../../util/IntlMessages";
import Badge from "../Badge/Badge";
import {
  featureFlagNames,
  GENERAL_PERMISSION_VALUE,
  PO_STATUS,
} from "../../helpers/constants";
import { useIsAllowedFunction } from "../../hooks/permissions";
import timeLineIconv1 from "../../assets/flag-icons/timeline-icon-v1.svg";
import timeLineIconv2 from "../../assets/flag-icons/timeline-icon-v2.png";
import CustomizedModal from "../Modal/CustomizedModal";
import TimelineStructure from "../Timeline/TimelineStructure";
import AttentionModal from "../Modal/AttentionModal";
import { dependencyTypesOnMove } from "../../helpers/timelineModal";
import { getActivityEntryFromMovedTask } from "../../helpers/orderDashboard";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { purchaseOrderTriggerTypes } from "../../helpers/purchaseOrder";
import { salesOrderTriggerTypes } from "../../helpers/salesOrder";
import { ProjectTaskFiltersStyled } from "./styles";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { getPOShipments } from "../../helpers/shipments";
import { useCurrentShipment, useShipments } from "../../hooks/shipments";
import { typeOfTask } from "../../api/types/dbTables";
import { activityScopes } from "../../helpers/activitiesStream";
import { triggerTaskTypes } from "../../helpers/tasks";
import { getDateByTimezone } from "../../helpers/ganttChart";
import { firestore } from "../../firebase";
import VendorDashboardTemplate from "./VendorDashboardTemplate";
import { useFactories } from "../../hooks/factories";
import { shipmentTriggerTypes } from "../../helpers/shipment";
import { increment, updateDoc, writeBatch } from "firebase/firestore";
import { VoidLabel } from "../../helpers/iconSvgPath";
import FilterTab from "./FilterTab";

const filters = { ALL: "ALL", MINE: "MINE", COMPLETED: "COMPLETED" };

function ProjectTaskFilters({
  onTabClick = () => {},
  filterValue = "",
  checkboxValue = false,
  onChangeCheckbox = () => {},
  purchaseOrder,
  SOTasks,
  POTasks,
  salesOrder,
  purchaseOrders,
}) {
  const isAllowed = useIsAllowedFunction();
  const filtersPOs = purchaseOrders.filter(
    (po) => po.status !== PO_STATUS.VOIDED
  );
  const companyID = useCompanyId();
  const user = useUser();
  const [completedTasks, setCompletedTasks] = useState({
    completedTasks: 0,
    totalTasks: 0,
  });
  const factories = useFactories();
  const [openModal, setOpenModal] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const featureFlags = useFeatureFlags({ companyId: companyID });
  const companyUsers = useCompanyUsers({});
  const shipments = useShipments();
  const currentShipment = useCurrentShipment({ purchaseOrder });

  useEffect(() => {
    const SOCompletedLength = SOTasks.filter((task) => task.complete).length;
    const POCompletedLength = POTasks.filter((task) => task.complete).length;
    setCompletedTasks({
      completedTasks: SOCompletedLength + POCompletedLength,
      totalTasks: SOTasks.length + POTasks.length,
    });
  }, [checkboxValue, POTasks, SOTasks]);

  function isThereTimelineFeatureFlag(featureFlags = [], user) {
    if (featureFlags.length === 0 || !user) {
      return null;
    }
    const featureFlagVersion1 = featureFlags.find(
      (feature) => feature.name === featureFlagNames.TIMELINE_V1
    );
    const featureFlagVersion2 = featureFlags.find(
      (feature) => feature.name === featureFlagNames.TIMELINE_V2
    );
    if (
      featureFlagVersion1 &&
      featureFlagVersion1.users &&
      featureFlagVersion1.users[user.id]
    ) {
      return (
        <img
          className="timeline-icon"
          src={timeLineIconv1}
          onClick={() => setOpenModal(true)}
          alt="timeline1"
        />
      );
    }
    if (
      featureFlagVersion2 &&
      featureFlagVersion2.users &&
      featureFlagVersion2.users[user.id]
    ) {
      return (
        <img
          className="timeline-icon"
          src={timeLineIconv2}
          onClick={() => setOpenModal(true)}
          alt="timeline2"
        />
      );
    }
    return null;
  }

  function getAllShipmentIds({ purchaseOrders }) {
    const shipmentIds = [];
    purchaseOrders.forEach((po) => {
      const POShipmentIds = po.shipmentIds || [];
      POShipmentIds.forEach((shipmentId) => {
        if (!shipmentIds.includes(shipmentId)) {
          shipmentIds.push(shipmentId);
        }
      });
    });
    return shipmentIds;
  }

  return (
    <ProjectTaskFiltersStyled className="projectTaskFilterContainer">
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={
            <React.Fragment>
              Are you sure to discard these changes?
            </React.Fragment>
          }
          isOpen={openModalAttention}
          onClick={() => {
            setOpenModal(false);
            setOpenModalAttention(false);
          }}
          onClose={() => setOpenModalAttention(false)}
          cancellable
          cancelText="No"
          confirmationText="Yes"
        />
      )}
      {openModal && (
        <CustomizedModal
          open={openModal}
          onClose={() => {
            if (salesOrder.status === PO_STATUS.VOIDED) {
              setOpenModal(false);
            } else {
              setOpenModalAttention(true);
            }
          }}
          body={
            <TimelineStructure
              salesOrder={salesOrder || {}}
              purchaseOrders={filtersPOs || []}
              shipments={getPOShipments({
                shipments,
                purchaseOrder: {
                  ...purchaseOrder,
                  shipmentIds: getAllShipmentIds({
                    purchaseOrders: filtersPOs,
                  }),
                },
              })}
              onCancelTimeLineModal={() => setOpenModal(false)}
              onSubmitTimeLineModal={({
                tasks,
                confirmedActions,
                salesOrder,
                purchaseOrders,
                shipments = [],
                viewOptions,
              }) => {
                let tasksCpy = [...tasks];
                tasksCpy.forEach((task) => {
                  delete task.numberIndex;
                  delete task.hasBeenUpdated;
                  delete task.sortedListIndex;
                });
                const tasksToSaveBatch = writeBatch(firestore);
                tasksCpy.forEach((task) => {
                  const newStartDate = getDateByTimezone({
                    date: task.startDate,
                    timestamp: true,
                    isServerTime: true,
                  });
                  const newFinishDate = getDateByTimezone({
                    date: task.finishDate,
                    timestamp: true,
                    isServerTime: true,
                  });
                  tasksToSaveBatch.set(task.ref, {
                    ...task,
                    ref: "",
                    startDate: newStartDate,
                    finishDate: newFinishDate,
                    triggerType: triggerTaskTypes.DISMISS_TRIGGER,
                  });
                });
                tasksToSaveBatch
                  .commit()
                  .then(() => console.log("SUCCESSFULLY SAVED TASKS..."));

                const salesOrderCompletedTasks = confirmedActions.filter(
                  (el) =>
                    el.triggerType === triggerTaskTypes.COMPLETE &&
                    el.salesOrderId === salesOrder.id &&
                    el.type === typeOfTask.SALES_ORDER
                );

                const posCompletedTasks = confirmedActions.filter(
                  (el) =>
                    el.triggerType === triggerTaskTypes.COMPLETE &&
                    el.type === typeOfTask.PURCHASE_ORDER
                );

                updateDoc(salesOrder.ref, {
                  completedTasks: increment(
                    salesOrderCompletedTasks.length + posCompletedTasks.length
                  ),
                  triggerType: salesOrderTriggerTypes.COMPLETED_TASKS,
                  milestones: salesOrder.milestones || [],
                });
                purchaseOrders.forEach((purchaseOrder) => {
                  delete purchaseOrder.isShownInTimeline;
                  const poCompletedTasks = confirmedActions.filter(
                    (el) =>
                      el.triggerType === triggerTaskTypes.COMPLETE &&
                      el.purchaseOrderId === purchaseOrder.id &&
                      el.type === typeOfTask.PURCHASE_ORDER
                  );
                  updateDoc(purchaseOrder.ref, {
                    completedTasks: increment(poCompletedTasks.length),
                    triggerType: purchaseOrderTriggerTypes.COMPLETED_TASKS,
                    color: purchaseOrder.color,
                  });
                });

                shipments.forEach((shipment) => {
                  delete shipment.isShownInTimeline;
                  const shipmentCompletedTasks = confirmedActions.filter(
                    (el) =>
                      el.triggerType === triggerTaskTypes.COMPLETE &&
                      el.shipmentId === shipment.id &&
                      el.type === typeOfTask.SHIPMENT
                  );
                  updateDoc(shipment.ref, {
                    completedTasks: increment(shipmentCompletedTasks.length),
                    triggerType: shipmentTriggerTypes.COMPLETED_TASKS,
                  });
                });

                const salesOrderCpy = {
                  ...salesOrder,
                  unreadActivityUsers: salesOrder.unreadActivityUsers
                    ? [...salesOrder.unreadActivityUsers]
                    : [],
                };
                const purchaseOrderCpy = {
                  ...purchaseOrder,
                  unreadActivityUsers: purchaseOrder.unreadActivityUsers
                    ? [...purchaseOrder.unreadActivityUsers]
                    : [],
                };

                let shipmentCpy = {};
                if (currentShipment && currentShipment.ref) {
                  if (currentShipment.unreadActivityUsers) {
                    shipmentCpy = {
                      ...currentShipment,
                      unreadActivityUsers: [
                        ...currentShipment.unreadActivityUsers,
                      ],
                    };
                  } else {
                    shipmentCpy = {
                      ...currentShipment,
                      unreadActivityUsers: [],
                    };
                  }
                }
                confirmedActions.forEach((confirmedAction) => {
                  const {
                    typeChange,
                    movedRemainingTask,
                    changedDurationTask,
                    offsetDaysChangedDuration,
                    offsetDaysMoved,
                    offsetRemainingTask,
                    creationDate,
                  } = confirmedAction;
                  getActivityEntryFromMovedTask({
                    task: {
                      ...confirmedAction,
                      assignedUserName: companyUsers.find(
                        (cUser) => cUser.id === confirmedAction.assignedTo
                      ).displayName,
                    },
                    user,
                    typeChange: typeChange,
                    isDependantTaskMoved: movedRemainingTask,
                    diffDaysTask: changedDurationTask
                      ? offsetDaysChangedDuration
                      : offsetDaysMoved,
                    diffDaysRemainingTasks: offsetRemainingTask,
                    isLate:
                      typeChange === dependencyTypesOnMove.START_DATE_CHANGED
                        ? offsetDaysChangedDuration > 0
                          ? false
                          : true
                        : typeChange ===
                          dependencyTypesOnMove.FINISH_DATE_CHANGED
                        ? offsetDaysChangedDuration > 0
                          ? true
                          : false
                        : offsetDaysMoved >= 0,
                    creationDate: creationDate,
                    isLonger: offsetDaysChangedDuration >= 0,
                    companyUsers: companyUsers,
                    scope:
                      confirmedAction.type === typeOfTask.SALES_ORDER
                        ? salesOrderCpy.type
                        : confirmedAction.type === typeOfTask.PURCHASE_ORDER
                        ? purchaseOrderCpy.type
                        : activityScopes.SHIPMENT,
                    companyId: companyID,
                    order:
                      confirmedAction.type === typeOfTask.SALES_ORDER
                        ? salesOrderCpy
                        : confirmedAction.type === typeOfTask.PURCHASE_ORDER
                        ? purchaseOrderCpy
                        : shipmentCpy,
                  });
                });
                setOpenModal(false);
              }}
            />
          }
        />
      )}

      <div className="title">
        {salesOrder.status === PO_STATUS.VOIDED && <VoidLabel />}
        {purchaseOrder.status === PO_STATUS.REASSIGNED && (
          <Badge className="po-status-reassigned" isVoid={false} />
        )}
        {isThereTimelineFeatureFlag(featureFlags, user)}
        <IntlMessages id="purchaseorderdashboard.tasks" />
        {purchaseOrder.isOutdated &&
          isAllowed(GENERAL_PERMISSION_VALUE.MODIFY_FACTORIES_TEMPLATES) && (
            <VendorDashboardTemplate
              vendor={factories.find(
                (factory) => factory.id === purchaseOrder.factoryId
              )}
              purchaseOrder={purchaseOrder}
              user={user}
              companyId={companyID}
              taskInfo={true}
              typeClass={"taskContainer"}
            />
          )}
      </div>

      <div className={"projectTaskFilters"}>
        <div className="general-filters">
          <FilterTab
            onClick={() => onTabClick(filters.ALL)}
            activeTab={filterValue}
            value={filters.ALL}
            label="purchaseorderdashboard.all"
            styles={{ fontSize: 11 }}
          />
          <FilterTab
            onClick={() => onTabClick(filters.MINE)}
            activeTab={filterValue}
            value={filters.MINE}
            label="purchaseorderdashboard.mine"
            noTransform={true}
            styles={{ fontSize: 11 }}
          />

          <div className="indicator" />
        </div>
        <div className="show-completed">
          <CustomCheckbox
            id="od-ts-custom-checkbox"
            checked={checkboxValue}
            onChange={onChangeCheckbox}
            styles={{
              width: 13,
              height: 13,
              margin: "0px 4px",
              background: "white",
              padding: "2px 0px 0px 0px",
            }}
            size="small"
          />
          <div
            id="od-ts-checkbox"
            className="sc-text-container"
            onClick={() => onChangeCheckbox(!checkboxValue)}
          >
            <span>
              <IntlMessages id="purchaseorderdashboard.completed" />
            </span>
            {checkboxValue && (
              <span style={{ margin: "0px 4px" }}>
                {`(${
                  completedTasks.completedTasks +
                  "/" +
                  completedTasks.totalTasks
                })`}
              </span>
            )}
          </div>
        </div>
      </div>
    </ProjectTaskFiltersStyled>
  );
}

export default ProjectTaskFilters;
