import React from "react";
import TooltipTD from "../Tooltip/TooltipTD";
import { TOOLTIP_TYPE } from "../../helpers/constants";
import { Clear as ClearIcon } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { cx } from "@emotion/css";
import { DisplayFiltersInfo } from "../../container/StyledComponent/DisplayFiltersInfo";

function FilterDisplay({
  tooltip,
  value,
  label,
  onClear,
  clearIconId = "",
  hiddenClose = false,
  filters = {},
}) {
  function getAllFilters() {
    let activeFiltersCount = 0;
    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        if (Array.isArray(filters[key]) && filters[key].length > 1) {
          activeFiltersCount++;
        }
      }
    }
    return activeFiltersCount >= 3;
  }

  const matches = useMediaQuery("(max-width:1425px)");
  const isAllFiltersActive =
    useMediaQuery("(max-width:1600px)") || getAllFilters();

  return (
    <TooltipTD
      classes={{ tooltip: "tooltip-filter" }}
      label={tooltip}
      type={TOOLTIP_TYPE.FILTER}
      showToolTip={!!tooltip}
    >
      <DisplayFiltersInfo
        className={cx("filterContainer", {
          "filterContainer-md": isAllFiltersActive && !matches,
          "filterContainer-sm": matches,
        })}
      >
        {!matches && <legend className="legendFilter">{label}</legend>}
        <div className="textFilter">{matches ? label : value}</div>
        {!hiddenClose && (
          <ClearIcon
            id={clearIconId}
            onClick={(ev) => {
              onClear();
              ev.stopPropagation();
            }}
            className="closeIcon"
          />
        )}
      </DisplayFiltersInfo>
    </TooltipTD>
  );
}

export default FilterDisplay;
