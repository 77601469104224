import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addGenericDataToFirestore } from "../../actions/DataActions";
import { dbTables } from "../../api/types/dbTables";
import File from "../../api/model/file";
import FoldersStructure from "./FoldersStructure";
import {
  convertToTree,
  verifyDuplicateNames,
  getRandomId,
  getPathDocumentLocalStorage,
  getIdFromStructureFolders,
  useLocationPath,
  debounce,
} from "../../helpers/helpers";
import ManagementDocumentsHeader from "./ManagementDocumentsHeader";
import { now } from "moment";
import { useUser, useCompanyUsers } from "../../hooks/user";
import AttentionModal from "../Modal/AttentionModal";
// import { trackEvent } from "../../helpers/analytics";
import {
  createActivityDelete,
  updateParentFolderTouched,
  superAdminAssignmentPermissions,
  documentScope,
  filtersDocumentsPermission,
} from "../../helpers/documents";
// import IntegrationNotistack from "../../routes/components/snackbar/component/NotisStack";
import userTypes from "../../api/types/userTypes";
import Mark from "mark.js";
import { ManagementDocumentsStyled } from "./styles";
import {
  errorDocumentName,
  PO_STATUS,
  TYPE_OF_FILE,
} from "../../helpers/constants";
import { useJSONLocalStorage } from "../../hooks";
import { firestore } from "../../firebase";
import {
  getBackgroundColorByType,
  getBorderColorByType,
} from "../../helpers/orderDashboard";
import { orderType } from "../../helpers/salesOrder";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
  usePurchaseOrders,
  useSalesOrders,
} from "../../hooks/salesOrders";
import { useShipments } from "../../hooks/shipments";
import { collection, deleteDoc, getDocs, updateDoc } from "firebase/firestore";

function ManagementDocuments({
  companyID,
  mainTable = dbTables.SALES_ORDERS,
  mainTableID,
  documents,
  dbTable,
  selectedDocumentTab,
  hasTitle = false,
  disabled,
  permissionGroupsDB,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  permissionScope,
  allowMultipleFiles = false,
  onChangeShipment = () => {},
}) {
  const defaultFolderName = "New Folder";
  const path = useLocationPath();
  const currentSalesOrder = useCurrentSalesOrder({});
  const currentPurchaseOrder = useCurrentPurchaseOrder();
  const pathLocalStorage = `${getPathDocumentLocalStorage(
    path
  )}DocumentStructure`;
  const { get, set } = useJSONLocalStorage(pathLocalStorage);

  const [documentTree, setDocumentTree] = useState([]);
  const currentUser = useUser();
  const [documentToDelete, setDocumentToDelete] = useState({});
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");
  const [canUserDelete, setCanUserDelete] = useState(false);
  const [draggableDocument, setDraggableDocument] = useState({});
  const [isDraggableDocument, setIsDraggableDocument] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const companyUsers = useCompanyUsers({
    showBotUser: false,
    showInactiveUsers: false,
  });
  const dispatch = useDispatch();
  const user = useUser();
  const orderIsVoided =
    mainTable === dbTables.SALES_ORDERS
      ? currentSalesOrder.status === PO_STATUS.VOIDED
      : currentPurchaseOrder.status === PO_STATUS.VOIDED;
  const [, setOpenSnackBar] = useState(false);
  const [, setDescriptionSnackBar] = useState("");
  const [createNewFolder, setCreateNewFolder] = React.useState(false);
  const [folderObj, setFolderObj] = useState({
    ...new File({
      id: getRandomId(),
      name: defaultFolderName,
      type: TYPE_OF_FILE.FOLDER,
    }),
  });
  const [search, setSearch] = useState("");
  const allowed = useIsAllowedFunction();
  const onDebounce = useCallback(
    debounce(() => {
      performMark(search);
    }, 300),
    [search]
  );
  const addDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [
        dbTables.COMPANIES,
        companyID,
        mainTable,
        mainTableID,
        dbTable,
        document.id,
      ],
      document
    )(dispatch)
  );

  const addShipmentDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [
        dbTables.COMPANIES,
        companyID,
        dbTables.SHIPMENTS,
        document.shipmentId,
        dbTables.SHIPMENT_DOCUMENTS,
        document.id,
      ],
      document
    )(dispatch)
  );

  useEffect(() => {
    const userDocuments = filtersDocumentsPermission({
      allowed,
      documents,
      permissionGroupsDB,
      permissionScope,
      user,
    });
    const currentDocumentTree = convertToTree(userDocuments);
    const allFolders = currentDocumentTree.filter(
      (document) => document.type === TYPE_OF_FILE.FOLDER
    );
    const localStorageData = get() || {};
    const poLocalStorage = localStorageData[mainTableID] || {};
    const isFolderOpen = allFolders.every(
      (folder) => poLocalStorage[folder.id]
    );
    if (isExpand !== isFolderOpen) {
      setIsExpand(isFolderOpen);
    }
    setDocumentTree(currentDocumentTree);
  }, [documents]);

  useEffect(() => {
    onDebounce();
  }, [search]);

  function handleAddNewFolder(event) {
    const isVoided = orderIsVoided;
    if (isReadOnly || isVoided) {
      handleReadOnlyModal();
      return;
    }
    if (disabled) return;
    setCreateNewFolder(true);
    const elementStructureList = document.getElementById(
      "folder-estructure-list"
    );
    elementStructureList.scrollTo({ top: 0, behavior: "smooth" });
  }

  function handleSaveFolder() {
    if (navigator.onLine) {
      if (disabled) return;
      const id = folderObj.id;
      if (!folderObj.name.trim()) {
        setCreateNewFolder(false);
        setFolderObj({
          ...new File({
            id: getRandomId(),
            name: defaultFolderName,
            type: TYPE_OF_FILE.FOLDER,
          }),
        });
        return;
      }

      const name = verifyDuplicateNames(
        folderObj.name.trim() ? folderObj.name : defaultFolderName,
        documentTree
      );
      if (name.includes("/")) {
        setCreateNewFolder(false);
        setFolderObj({
          ...new File({
            id: getRandomId(),
            name: defaultFolderName,
            type: TYPE_OF_FILE.FOLDER,
          }),
        });
        setOpenModalAttention(true);
        setCanUserDelete(false);
        setDescriptionModalAttention(errorDocumentName.folder);
        return;
      }
      const folder = new File({
        id,
        name,
        path: name,
        type: TYPE_OF_FILE.FOLDER,
        mainFile: true,
        user: currentUser.id,
        createdBy: currentUser.id,
        lastModified: now(),
        lastModifiedDate: now(),
      });
      addDocument({ ...folder, permissionUsers: { [user.id]: true } });
      setCreateNewFolder(false);
      setFolderObj({
        ...new File({
          id: getRandomId(),
          name: defaultFolderName,
          type: TYPE_OF_FILE.FOLDER,
        }),
      });
    } else {
      setDescriptionSnackBar("Folder create");
      setOpenSnackBar(true);
      console.log("LOST ETHERNET CONEXION..");
    }
  }

  function handleCancelSaveFolder() {
    setCreateNewFolder(false);
    setFolderObj({
      ...new File({
        id: getRandomId(),
        name: defaultFolderName,
        type: TYPE_OF_FILE.FOLDER,
      }),
    });
  }

  function handleChangeFolderName(event) {
    setFolderObj({ ...folderObj, name: event.target.value });
  }

  function handleDeleteDocumentChilds(document) {
    document.child.forEach((element) => {
      if (element.type === TYPE_OF_FILE.FOLDER) {
        handleDeleteDocumentChilds(element);
        deleteDoc(element.ref);
      } else {
        getDocs(
          collection(
            firestore,
            `${element.ref.path}/${dbTables.DOCUMENT_VERSIONS}`
          )
        ).then((snapshot) => {
          snapshot.docs.map((doc) => deleteDoc(doc.ref));
        });
        deleteDoc(element.ref);
      }
    });
  }

  const salesOrders = useSalesOrders();
  const purchaseOrders = usePurchaseOrders();
  const shipments = useShipments();

  async function handleDeleteFolder() {
    if (navigator.onLine) {
      updateParentFolderTouched(documentToDelete, documents, currentUser);
      if (documentToDelete.type !== TYPE_OF_FILE.FOLDER) {
        getDocs(
          collection(
            firestore,
            `${documentToDelete.ref.path}/${dbTables.DOCUMENT_VERSIONS}`
          )
        ).then((snapshot) => {
          snapshot.docs.map((doc) => deleteDoc(doc.ref));
        });

        getDocs(
          collection(
            firestore,
            `${documentToDelete.ref.path}/${dbTables.ACTIVITIES}`
          )
        ).then((snapshot) => {
          snapshot.docs.map((doc) => deleteDoc(doc.ref));
        });

        deleteDoc(documentToDelete.ref);
        // trackEvent("PO Dashboard - Documents - File - Delete");
      } else {
        // trackEvent("PO Dashboard - Documents - Folder - Delete");
        if (documentToDelete.child.length === 0) {
          deleteDoc(documentToDelete.ref);
        } else {
          handleDeleteDocumentChilds(documentToDelete);
          deleteDoc(documentToDelete.ref);
        }
      }
      let order = {};
      if (documentToDelete.scope === documentScope.SALES_ORDER) {
        const salesOrder = salesOrders.find(
          (salesOrder) => salesOrder.id === mainTableID
        );
        order = {
          ...salesOrder,
          unreadActivityUsers: [...salesOrder.unreadActivityUsers],
        };
      } else if (documentToDelete.scope === documentScope.PURCHASE_ORDER) {
        const purchaseOrder = purchaseOrders.find(
          (po) => po.id === mainTableID
        );
        order = {
          ...purchaseOrder,
          unreadActivityUsers: purchaseOrder.unreadActivityUsers
            ? [...purchaseOrder.unreadActivityUsers]
            : [],
        };
      } else {
        const currentShipment = shipments.find(
          (shipment) => shipment.id === documentToDelete.shipmentId
        );

        if (currentShipment && currentShipment.ref) {
          if (currentShipment.unreadActivityUsers) {
            order = {
              ...currentShipment,
              unreadActivityUsers: [...currentShipment.unreadActivityUsers],
            };
          } else {
            order = { ...currentShipment, unreadActivityUsers: [] };
          }
        }
      }
      createActivityDelete({
        companyID,
        mainTable:
          documentToDelete.scope === documentScope.SHIPMENT
            ? dbTables.SHIPMENTS
            : mainTable,
        mainTableID:
          documentToDelete.scope === documentScope.SHIPMENT
            ? documentToDelete.shipmentId
            : mainTableID,
        currentUser,
        currentDocument: documentToDelete,
        scope: documentToDelete.scope,
        order,
        companyUsers: companyUsers,
      });
    } else {
      setDescriptionSnackBar(
        documentToDelete.type === TYPE_OF_FILE.FOLDER
          ? "Folder delete"
          : "File delete"
      );
      setOpenSnackBar(true);
    }
    setOpenModalAttention(false);
  }

  function onAddNewFolder(document, currentName) {
    if (navigator.onLine) {
      const name = verifyDuplicateNames(
        currentName.trim() ? currentName : "New Folder",
        document.child
      );
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - Folder - New in Folder");
      //
      const id = getRandomId();
      let { path } = document;
      path = `${path}/${name}`;
      const folder = new File({
        id,
        name,
        parent: document.name,
        path,
        parentId: document.id,
        type: TYPE_OF_FILE.FOLDER,
        user: currentUser.id,
        createdBy: currentUser.id,
        lastModified: now(),
        lastModifiedDate: now(),
        shipmentId: document.shipmentId,
        scope: document.scope,
      });
      if (
        document.scope === documentScope.SALES_ORDER ||
        document.scope === documentScope.FACTORY ||
        document.scope === documentScope.CUSTOMER ||
        document.scope === documentScope.PURCHASE_ORDER
      ) {
        addDocument({ ...folder, permissionUsers: { [user.id]: true } });
      } else {
        addShipmentDocument({
          ...folder,
          permissionUsers: { [user.id]: true },
        });
      }
      if (currentUser.role === userTypes.SUPER_ADMIN) {
        superAdminAssignmentPermissions({
          currentDocument: document,
          permissionGroupsDB,
          currentUser,
          allDocuments: documents,
        });
      }
      updateLastModifiedDateParentsFolders(folder, documents);
    } else {
      setDescriptionSnackBar("Folder create");
      setOpenSnackBar(true);
    }
  }

  function updateLastModifiedDateParentsFolders(document, documents) {
    if (document.parentId) {
      const currentDocument = documents.find(
        (doc) => doc.id === document.parentId
      );
      updateDoc(currentDocument.ref, {
        lastModified: now(),
      });
      updateLastModifiedDateParentsFolders(currentDocument, documents);
    }
  }

  function handleAddNewFile() {
    const isVoided = orderIsVoided;
    if (isReadOnly || isVoided) {
      handleReadOnlyModal();
      return;
    }
    const inputDrag = document.getElementById("principalDropOption");
    if (inputDrag) {
      inputDrag.click();
    }
  }

  function onDraggableDocument(document) {
    setDraggableDocument(document);
    setIsDraggableDocument(true);
  }

  function onDragEnd() {
    setDraggableDocument({});
    setIsDraggableDocument(false);
  }

  function handleDraggableDocumentClean() {
    setDraggableDocument({});
    setIsDraggableDocument(false);
  }

  function verifyHandleDeleteFolder(event, document) {
    const allChildDocument = documents.filter(
      (doc) => doc.parentId === document.id
    );
    if (allChildDocument.length === document.child.length) {
      setCanUserDelete(true);
      if (document.child.length > 0) {
        setDescriptionModalAttention(
          <React.Fragment>
            Do you want to permanently delete folder{" "}
            <strong> {document.name} </strong> <br></br> and{" "}
            <strong>ALL the files</strong> whitin it? <br />
            This cannot be undone
          </React.Fragment>
        );
      } else {
        setDescriptionModalAttention(
          <React.Fragment>
            Do you want to permanently delete{" "}
            {document.type === TYPE_OF_FILE.FOLDER
              ? TYPE_OF_FILE.FOLDER
              : TYPE_OF_FILE.FILE}{" "}
            <strong> {document.name} </strong> ? <br />
            This cannot be undone
          </React.Fragment>
        );
      }
      setDocumentToDelete(document);
    } else {
      setCanUserDelete(false);
      setDescriptionModalAttention(
        <React.Fragment>
          You do not have permission to some items within this folder;
          therefore, the delete operation cannot be completed
        </React.Fragment>
      );
    }
    setOpenModalAttention(true);
  }

  function findDocumentChild(document, search) {
    if (document.type !== TYPE_OF_FILE.FOLDER) {
      return false;
    }
    const isThereDocuments = document.child.filter(
      (doc) =>
        doc.name.toLowerCase().includes(search.toLowerCase()) ||
        findDocumentChild(doc, search)
    );
    if (isThereDocuments.length > 0) return true;
    return false;
  }

  function updateLocalStorageData(value) {
    const idsFolder = getIdFromStructureFolders(documentTree);
    const localStorageData = get() || {};
    const newLocalStorageData = idsFolder.reduce((obj, item) => {
      return {
        ...obj,
        [item]: value === false ? undefined : value,
      };
    }, {});
    set({
      ...localStorageData,
      [mainTableID]: {
        ...newLocalStorageData,
      },
    });
  }

  function getDocumentThree(documentTree, search) {
    if (!search) {
      return documentTree;
    }
    return documentTree.filter(
      (document) =>
        document.name.toLowerCase().includes(search.toLowerCase()) ||
        findDocumentChild(document, search)
    );
  }

  const markInstance = new Mark(
    document.getElementById("folder-estructure-list")
  );
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [
        ".filesize",
        ".pending-placeholder-items-text",
        ".fileinfo",
        ".drag-and-drop-upload-files-text",
      ],
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => {
          markInstance.mark(keyword, options);
        }, 150);
      },
    });
  }
  let tabObject = {
    [dbTables.SALES_ORDERS]: orderType.SALES_ORDER,
    [dbTables.PURCHASE_ORDERS]: orderType.PURCHASE_ORDER,
  };

  return (
    <ManagementDocumentsStyled
      className="managementDocumentContainer"
      style={{
        background: getBackgroundColorByType({
          type: tabObject[mainTable],
        }),
        borderTop: `2px solid ${getBorderColorByType({
          type: tabObject[mainTable],
        })}`,
        borderRadius: "0px 0px 10px 10px",
      }}
    >
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          cancellable={canUserDelete}
          confirmationText={canUserDelete ? "Yes" : "Ok"}
          onClick={
            canUserDelete
              ? handleDeleteFolder
              : () => {
                  setOpenModalAttention(false);
                  setCanUserDelete(false);
                  setDescriptionModalAttention("");
                }
          }
          onClose={() => {
            setOpenModalAttention(false);
            setDocumentToDelete({});
          }}
          acceptBlue={false}
        />
      )}

      <ManagementDocumentsHeader
        allowMultipleFiles={allowMultipleFiles}
        handleAddNewFolder={handleAddNewFolder}
        handleAddNewFile={handleAddNewFile}
        hasTitle={hasTitle}
        handleExpandOrCollapse={(value) => {
          updateLocalStorageData(value);
          setIsExpand(value);
        }}
        isExpand={isExpand}
        onQuerySearch={(value) => setSearch(value)}
        mainTable={mainTable}
        mainTableID={mainTableID}
        isVoided={orderIsVoided}
      />
      <div className="folderStructureWrapper">
        <div className="folderStructureList" id="folder-estructure-list">
          <FoldersStructure
            allowMultipleFiles={allowMultipleFiles}
            documents={
              documentTree ? getDocumentThree(documentTree, search) : []
            }
            markAsTemplate={
              documentTree
                ? documentTree.filter((doc) => doc.placeholder === true).length
                : 0
            }
            onChangeShipment={onChangeShipment}
            handleDeleteFolder={verifyHandleDeleteFolder}
            onAddNewFolder={onAddNewFolder}
            createNewFolder={createNewFolder}
            folderObj={folderObj}
            onChange={handleChangeFolderName}
            handleSaveFolder={handleSaveFolder}
            handleCancelSaveFolder={handleCancelSaveFolder}
            companyID={companyID}
            mainTable={mainTable}
            mainTableID={mainTableID}
            dbTable={dbTable}
            selectedDocumentTab={selectedDocumentTab}
            permissionGroupsDB={permissionGroupsDB}
            onDraggableDocument={
              isReadOnly ? handleReadOnlyModal : onDraggableDocument
            }
            onDragEnd={isReadOnly ? handleReadOnlyModal : onDragEnd}
            draggableDocument={draggableDocument}
            isDraggableDocument={isDraggableDocument}
            handleDraggableDocumentClean={
              isReadOnly ? handleReadOnlyModal : handleDraggableDocumentClean
            }
            documentFlat={documents}
            disabled={disabled}
            user={user ? user : {}}
            isExpand={isExpand}
            handleExpandOrCollapse={(value) => {
              const userDocuments = filtersDocumentsPermission({
                allowed,
                documents,
                permissionGroupsDB,
                permissionScope,
                user,
              });
              const allFolders = userDocuments.filter(
                (document) => document.type === TYPE_OF_FILE.FOLDER
              );
              const localStorageData = value || {};
              const poLocalStorage = localStorageData[mainTableID] || {};
              const isFolderOpen = allFolders.every(
                (folder) => poLocalStorage[folder.id]
              );
              if (isExpand !== isFolderOpen) {
                setIsExpand(isFolderOpen);
              }
            }}
            querySearch={search}
            enableDragAndDropFiles={true}
            onOpenSnackBar={(description) => {
              setDescriptionSnackBar(description);
              setOpenSnackBar(true);
            }}
            isReadOnly={isReadOnly}
            handleReadOnlyModal={handleReadOnlyModal}
            onShowModal={() => {
              setCanUserDelete(false);
              setDescriptionModalAttention(
                <React.Fragment>
                  You do not have permission to some items within this folder;
                  therefore, it cannot be moved to the top level
                </React.Fragment>
              );
              setOpenModalAttention(true);
            }}
            nivel={1}
            orderIsVoided={orderIsVoided}
          />
        </div>
      </div>
    </ManagementDocumentsStyled>
  );
}

export default ManagementDocuments;
