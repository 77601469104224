import { colors } from "../../assets/jss/variables";
import { darkBlue } from "../../helpers/variableColors";
import { TableRow, styled } from "@mui/material";

export const TagsViewStyled = styled("div")(() => ({
  "&.tagsViewContainer": {
    height: "calc(100vh - 110px)",
    overflow: "auto",
    backgroundColor: "white",
    position: "relative",
    zIndex: 0,
    width: "100%",
  },
  "& .withoutPermissionTextContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  "& .withoutPermissionText": {
    fontSize: 72,
    color: colors.primaryDark,
    fontWeight: 700,
    fontFamily: "Roboto",
  },
  "& .divider": {
    background: "#D0DCEA",
    margin: "32px 40px 0px 40px",
    height: 3,
  },
  "& .infoContainer": {
    display: "flex",
    marginLeft: 40,
    "& .infoText": {
      width: 600,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "26px",
      textAlign: "left",
      color: colors.primaryDark,
    },
  },
  "& .tagList": {
    marginLeft: 40,
    fontSize: 14,
    color: darkBlue,
    fontStyle: "italic",
    paddingTop: 16,
    "& .divider": {
      width: 320,
      height: 2,
      background: "#D0DCEA",
      margin: "8px 0px",
    },
  },
}));

export const TagsTableRowStyled = styled(TableRow)(() => ({
  "&.main-table-row": {},
  "& .tableCell": {
    color: `${colors.primaryDark} !Important`,
    padding: "10px 16px !Important",
    height: 48,
    fontSize: "0.875rem",
  },
  "& .deleteContainer": {
    display: "flex",
    alignItems: "center",
  },
  "& .deleteIcon": {
    margin: "0px 8px",
    color: "red",
    width: 16,
    height: 18,
    "&:hover": {
      cursor: "pointer",
    },
  },
  "& .deleteText": {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
