import React, { useState } from "react";
import TooltipTD from "../Tooltip/TooltipTD";
import { MISMATCH_STATUS, notAllowedToSee } from "../../helpers/constants";
import { CellTableShipmentStyled } from "./styles";
import itemStatus from "../../api/types/itemStatus";
import SKUEditableLabel from "../TextFields/SKUEditableLabel";
import { usePurchaseOrders, useSalesOrders } from "../../hooks/salesOrders";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { useShipments } from "../../hooks/shipments";

const getValue = ({
  column,
  row,
  item,
  purchaseOrders,
  customers,
  salesOrders,
  shipments,
}) => {
  let value = column.complexValue
    ? column.complexValue(row, item, purchaseOrders)
    : row[column.id];
  let valueWithFormat = column.format
    ? column.format({ value, customers, salesOrders, shipments })
    : value;
  if (row.hiddenValue) {
    value = "";
    valueWithFormat = "";
  }
  const showValueText = column.showFormatValue ? valueWithFormat : value;
  return { value, valueWithFormat, showValueText };
};

export default function CellSKUv2({
  column,
  item,
  prefix,
  onChangeLabel,
  cursor,
  showBadge,
  showImage,
  valueURL = "",
  onPhotosViewer,
  isAllowed = () => {},
  companyAllowed = () => {},
  focusPOTable = () => {},
  hasMismatch,
  isVoided,
}) {
  const purchaseOrders = usePurchaseOrders();
  const factories = useFactories();
  const customers = useCustomers();
  const salesOrders = useSalesOrders();
  const shipments = useShipments();
  let { value, valueWithFormat, showValueText } = getValue({
    column,
    item,
    purchaseOrders,
    row: item,
    customers,
    salesOrders,
    shipments,
  });
  const [urlImage] = useState(
    column.type === "image" && valueURL.startsWith("http://")
      ? valueURL.replace("http://", "https://")
      : valueURL
  );
  const hasPermission = column.permission ? isAllowed(column.permission) : true;
  const amountPermission = column.amountPermission
    ? isAllowed(column.amountPermission)
    : true;
  const hasCompanyPermission = column.companyPermission
    ? companyAllowed(column.companyPermission)
    : true;
  const isReadOnly = item.status === itemStatus.READ_ONLY || isVoided;
  const isEditable =
    column.isEditable &&
    hasPermission &&
    hasCompanyPermission &&
    !isReadOnly &&
    amountPermission;

  const tooltipStyle = column.tooltipStyle || {};
  let showToolTip = column.showToolTip || false;
  let tooltipText = column.tooltipText
    ? column.tooltipText({ item, purchaseOrders, factories })
    : showValueText;
  const styleToolTip = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor,
    ...tooltipStyle,
  };
  const bodyStyle = column.bodyStyle || {};

  let showToolTipHelper = false;
  let cartonsStyle = {};
  if (column.checkRound && value % 1 !== 0) {
    valueWithFormat = value.toFixed(1);
    cartonsStyle = { color: "red" };
    showToolTipHelper = true;
    tooltipText = "The order quantity is not a whole number of cartons";
  }
  let editableTooltip = column.showFormatValue ? valueWithFormat : value;
  if (column.orderQuantityCheck && hasMismatch) {
    showToolTip = true;
    editableTooltip = column.orderQuantityCheck;
    tooltipText = column.orderQuantityCheck;
  }
  return (
    <CellTableShipmentStyled
      className="cellTableShipment"
      style={{
        ...bodyStyle,
        justifyContent: bodyStyle.justifyContent,
      }}
    >
      {column.type === "image" && showImage && (
        <img
          style={{
            width: 50,
            cursor: "pointer",
          }}
          src={urlImage}
          onClick={(ev) => {
            onPhotosViewer();
            ev.stopPropagation();
          }}
          alt={"MenuIcon"}
        />
      )}

      {!showBadge && column.type !== "image" && (
        <div
          style={{
            position: column.subColumn ? "relative" : "",
          }}
          className="table-shipment-wrapper"
        >
          {isEditable && amountPermission && (
            <SKUEditableLabel
              key={`${prefix}_${item.id}_editable`}
              tooltipLabel={editableTooltip}
              toolTipStyle={showToolTip ? styleToolTip : { cursor }}
              showToolTip={showToolTip}
              isNewData={item.status === MISMATCH_STATUS.NEW && column.canBeNew}
              showEditIcon={!item.hiddenValue}
              value={value}
              format={column.format}
              inputType={column.type}
              isNewValue={item.status === MISMATCH_STATUS.NEW}
              isShipmentNew={item.id === "new" && item.status === "new"}
              onChangeLabel={onChangeLabel}
              preventDelete={!column.canBeErase && item.id !== "new"}
              labelClass={"editable-text-item"}
              hiddenValue={item.hiddenValue}
              columnId={column.id}
            />
          )}

          {!isEditable && (
            <div
              className="cell-table-shipment-value"
              style={{
                display: "flex",
                overflow: "hidden",
                fontWeight: 400,
                marginRight:
                  column.isEditable &&
                  (isReadOnly || !hasCompanyPermission || !hasPermission)
                    ? 18
                    : null,
                ...cartonsStyle,
              }}
              id={column.id}
            >
              <TooltipTD
                label={tooltipText}
                style={showToolTip ? styleToolTip : { cursor, ...tooltipStyle }}
                showToolTip={showToolTip || showToolTipHelper}
                hasArrow={true}
                tooltipClick={(ev) => {
                  if (column.itemRedirect || column.poRedirect) {
                    return focusPOTable(item);
                  }
                  ev.stopPropagation();
                }}
              >
                {amountPermission ? valueWithFormat : notAllowedToSee}
              </TooltipTD>
            </div>
          )}
        </div>
      )}
    </CellTableShipmentStyled>
  );
}
