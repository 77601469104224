import React from "react";
import { dbTables } from "../../api/types/dbTables";
import { getUserAvatar } from "../../helpers/users";
import IntlMessages from "../../util/IntlMessages";
import { styled } from "@mui/material";
import { cx } from "@emotion/css";
import AppConfig from "../../constants/AppConfig";

function FilterTab({
  activeTab,
  noTransform,
  label = "",
  value = "",
  onClick = (value) => {},
  intlMessages = true,
  user = null,
  styles = {},
  labelStyles = {},
  avatarStyle = {},
  scope = dbTables.ACTIVITIES,
  activeClass = "",
}) {
  return (
    <FilterTabStyled
      className={cx(
        "filterTabContainer",
        activeTab === value ? "active-tab" : ""
      )}
      onClick={() => onClick(value)}
      style={{ textTransform: noTransform ? "capitalize" : "uppercase" }}
    >
      {user ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: "0px 4px",
          }}
          className="userFilterTab"
        >
          {getUserAvatar({
            user,
            className: "filterTabActivities",
            styles: {
              fontSize: 11,
              cursor: "pointer",
              position: "relative",
              borderRadious: "50%",
              outline: "2px solid #000",
              padding: 0,
              ...avatarStyle,
              marginBottom: 0,
            },
          })}
          {scope === dbTables.ACTIVITIES && (
            <span
              className="filterLabel"
              style={{ margin: 0, cursor: "pointer" }}
            >
              ONLY
            </span>
          )}
        </div>
      ) : (
        <label style={{ ...labelStyles }} className="filterLabel">
          {intlMessages ? <IntlMessages id={label} /> : <span>{label}</span>}
        </label>
      )}
      <div className="active-indicator" />
    </FilterTabStyled>
  );
}

const FilterTabStyled = styled("div")(() => ({
  "&.filterTabContainer": {
    position: "relative",
    margin: "0 4px",
    height: 20,
    alignItems: "center",
    display: "flex",
    borderRadius: 6,
    "& span": {
      cursor: "pointer !important",
      color: "#25486d",
      padding: "3px 6px",
      borderRadius: 6,
    },
    userSelect: "none",
    "& .active-indicator": {
      display: "none",
      position: "absolute",
      bottom: -7,
      width: "100%",
      backgroundColor: "#ffa21a",
      height: 3,
    },
    "&:hover": {
      backgroundColor: "rgba(255, 162, 26, 0.15)",
      fontWeight: 700,
    },
    "& .filterLabel": {
      padding: "0px 6px",
      textTransform: "uppercase",
      margin: 0,
      fontSize: 11,
      color: AppConfig.themeColors.blueTextColor,
      lineHeight: "initial",
      fontWeight:'bold'
    },
    "& .filterTabActivities": {
      padding: 0,
    },
  },
  "&.active-tab": {
    fontWeight: 700,
    borderRadius: 6,
    "& .active-indicator": {
      display: "block",
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
      zIndex: 1,
      fontWeight: 900,
    },
    "&:hover": {},
    "& .filterLabel": {
      fontWeight: 700,
      fontSize: 11,
    },
  },
}));

export default FilterTab;
