import React, { useEffect, useState } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import EditableLabel from "../../components/TextFields/EditableLabel";
import AttentionModal from "../../components/Modal/AttentionModal";
import { now } from "moment";
import { getCompanyUserDisplayName } from "../../helpers/helpers";
import DeleteIcon from "../../assets/flag-icons/menu-delete-file.svg";
import ReadOnlyModal from "../../components/Modal/ReadOnlyModal";
import { getUserAvatar } from "../../helpers/users";
import { deleteDoc, updateDoc } from "firebase/firestore";
import { TagsTableRowStyled } from "./styles";

function TagsTableRow({
  item,
  user,
  isAllowed = () => {},
  companyUsers = [],
  tags,
  isReadOnly = false,
}) {
  const [currentTagName, setCurrentTagName] = useState("");
  const [editing, setEditing] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");
  const [enableToDeleteTag, setEnableToDeleteTag] = useState(false);

  function handleModalPermission() {
    setDescriptionModalAttention("You do not have permission to rename labels");
    setOpenModalAttention(true);
  }
  useEffect(() => {
    setCurrentTagName(item.name);
  }, [item]);
  return (
    item && (
      <React.Fragment>
        {openReadOnlyModal && (
          <ReadOnlyModal
            isOpen={openReadOnlyModal}
            onClick={() => setReadOnlyModal(false)}
            onClose={() => setReadOnlyModal(false)}
          />
        )}
        {openModalAttention && (
          <AttentionModal
            title="Attention"
            isOpen={openModalAttention}
            description={descriptionModalAttention}
            onClose={() => {
              setOpenModalAttention(false);
              setDescriptionModalAttention(<React.Fragment></React.Fragment>);
              setTimeout(() => {
                setEnableToDeleteTag(false);
              }, 200);
            }}
            onClick={() => {
              if (enableToDeleteTag) {
                updateDoc(item.ref, { updatedBy: user.id });
                setTimeout(() => {
                  deleteDoc(item.ref);
                  setOpenModalAttention(false);
                  setDescriptionModalAttention(
                    <React.Fragment></React.Fragment>
                  );
                }, 150);
                setTimeout(() => {
                  setEnableToDeleteTag(false);
                }, 200);
              } else {
                setOpenModalAttention(false);
                setDescriptionModalAttention(<React.Fragment></React.Fragment>);
                setTimeout(() => {
                  setEnableToDeleteTag(false);
                }, 200);
              }
            }}
            confirmationText={enableToDeleteTag ? "Yes, delete it" : "Ok"}
            cancellable={enableToDeleteTag}
            cancelText={enableToDeleteTag ? "Nevermind" : "Ok"}
            acceptBlue={enableToDeleteTag}
          />
        )}

        <TagsTableRowStyled key={item.id} className="main-table-row">
          <TableCell className={"tableCell"}>
            <EditableLabel
              inputStyle={{ width: "100%" }}
              onValid={() => {
                const isThereTag = tags.find(
                  (tag) =>
                    tag.name.trim().toLowerCase() ===
                      currentTagName.trim().toLowerCase() && tag.id !== item.id
                );

                if (currentTagName.trim() === "") {
                  setDescriptionModalAttention("Label name cannot be blank");
                  setOpenModalAttention(true);
                  setCurrentTagName(item.name);
                } else if (isThereTag) {
                  setDescriptionModalAttention("Label name already exists");
                  setOpenModalAttention(true);
                  setCurrentTagName(item.name);
                } else if (currentTagName.trim().length > 40) {
                  setDescriptionModalAttention(
                    "Label name cannot exceed 40 characters"
                  );
                  setOpenModalAttention(true);
                  setCurrentTagName(currentTagName.slice(0, 40));
                  updateDoc(item.ref, {
                    name: currentTagName.slice(0, 40),
                    updatedBy: user.id,
                    updatedDate: now(),
                  });
                } else if (
                  item.name.trim().toLowerCase() !==
                  currentTagName.trim().toLowerCase()
                ) {
                  updateDoc(item.ref, {
                    name: currentTagName,
                    updatedBy: user.id,
                    updatedDate: now(),
                  });
                } else {
                  console.log("DO NOTHING");
                }
              }}
              onChange={(ev) => {
                setCurrentTagName(ev.target.value);
              }}
              autofocus={editing}
              children={currentTagName}
              editable={isAllowed("rename_tags")}
              disabled={!isAllowed("rename_tags")}
              isReadOnly={isReadOnly}
              handleReadOnlyModal={() => setReadOnlyModal(true)}
              handleEnableContextMenu={() => {
                isAllowed("rename_tags")
                  ? setEditing(true)
                  : handleModalPermission();
              }}
            />
          </TableCell>
          <TableCell className={"tableCell"}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {getUserAvatar({
                user: companyUsers.find(
                  (companyUser) => companyUser.id === item.createdBy
                ),
                styles: {
                  width: 24,
                  height: 24,
                  fontSize: 13,
                  outline: "2px solid #000",
                },
              })}
              <span style={{ position: "relative", paddingLeft: 5 }}>
                {getCompanyUserDisplayName(companyUsers, item.createdBy)}
              </span>
            </div>
          </TableCell>
          <TableCell className={"tableCell"}>
            {moment(item.createdDate).format("M/D/YYYY hh:mm A")}
          </TableCell>
          <TableCell className={"tableCell"}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {getUserAvatar({
                user: companyUsers.find(
                  (companyUser) => companyUser.id === item.updatedBy
                ),
                styles: {
                  width: 24,
                  height: 24,
                  fontSize: 13,
                  outline: "2px solid #000",
                },
              })}
              <span style={{ position: "relative", paddingLeft: 5 }}>
                {getCompanyUserDisplayName(companyUsers, item.updatedBy)}
              </span>
            </div>
          </TableCell>
          <TableCell className={"tableCell"}>
            {item.updatedDate
              ? moment(item.updatedDate).format("M/D/YYYY hh:mm A")
              : ""}
          </TableCell>
          <TableCell className={"tableCell"}>
            <div className={"deleteContainer"}>
              <img
                src={DeleteIcon}
                className={"deleteIcon"}
                onClick={() => {
                  if (isReadOnly) {
                    setReadOnlyModal(true);
                    return;
                  }
                  if (isAllowed("delete_tags")) {
                    setEnableToDeleteTag(true);
                    setDescriptionModalAttention(
                      <React.Fragment>
                        Deleting a label will permanently remove it from all
                        locations, this is not reversible
                      </React.Fragment>
                    );
                    setOpenModalAttention(true);
                  } else {
                    setDescriptionModalAttention(
                      "You do not have permission to delete labels"
                    );
                    setOpenModalAttention(true);
                  }
                }}
                alt="date"
              />

              <span
                className={"deleteText"}
                onClick={() => {
                  if (isReadOnly) {
                    setReadOnlyModal(true);
                    return;
                  }
                  if (isAllowed("delete_tags")) {
                    setEnableToDeleteTag(true);
                    setDescriptionModalAttention(
                      <React.Fragment>
                        Deleting a label will permanently remove it from all
                        locations, this is not reversible
                      </React.Fragment>
                    );
                    setOpenModalAttention(true);
                  } else {
                    setDescriptionModalAttention(
                      "You do not have permission to delete labels"
                    );
                    setOpenModalAttention(true);
                  }
                }}
              >
                Delete
              </span>
            </div>
          </TableCell>
        </TagsTableRowStyled>
      </React.Fragment>
    )
  );
}

export default TagsTableRow;
