import React, { useEffect, useState } from "react";

import ListItemDocument from "./ListItemDocument";
import CreateNewFolder from "./CreateNewFolder";

import { sortObjectsBy } from "../../helpers/helpers";
import DragAndDropUploadFile from "./DragAndDropUploadFile";
import { FolderStructureStyled } from "./styles";
import { documentScope } from "../../helpers/documents";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { List } from "@mui/material";

function FoldersStructure({
  documents,
  handleDeleteFolder,
  onAddNewFolder,
  createNewFolder,
  onChange,
  folderObj,
  handleSaveFolder,
  handleCancelSaveFolder,
  companyID,
  mainTable,
  mainTableID,
  dbTable,
  selectedDocumentTab,
  permissionGroupsDB,
  markAsTemplate,
  isDocumentVersion = false,
  onDraggableDocument,
  onDragEnd,
  draggableDocument,
  isDraggableDocument,
  handleDraggableDocumentClean,
  documentFlat,
  hasParentPermission = false,
  parentDocument = [],
  disabled,
  user = {},
  isExpand,
  enableDragAndDropFiles = false,
  onOpenSnackBar,
  onShowModal,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  querySearch = "",
  handleExpandOrCollapse = () => {},
  allowMultipleFiles = false,
  onChangeShipment = () => {},
  nivel = 1,
  orderIsVoided,
}) {
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const [newRootFolder, setNewRootFolder] = useState(false);

  useEffect(() => {
    const placeholderFiles = documents.filter(
      (doc) => doc.placeholder === true && doc.type === "file"
    );
    placeholderFiles.sort(sortObjectsBy("listIndex"));
    const placeholderFolders = documents.filter(
      (folder) =>
        folder.placeholder === true &&
        folder.type === TYPE_OF_FILE.FOLDER &&
        folder.scope !== documentScope.SHIPMENT
    );
    const shipmentFolders = documents.filter(
      (folder) =>
        folder.placeholder === true &&
        folder.type === TYPE_OF_FILE.FOLDER &&
        folder.scope === documentScope.SHIPMENT
    );
    placeholderFolders.sort(sortObjectsBy("listIndex"));

    const restOfDocuments = documents.filter(
      (doc) => doc.type !== "file" && doc.placeholder !== true
    );
    restOfDocuments.sort(sortObjectsBy("lastModified", true));
    restOfDocuments.sort(sortObjectsBy("isShipmentRootTask", true));

    const allDocuments = [];
    shipmentFolders.forEach((element) => {
      allDocuments.push(element);
    });
    placeholderFiles.forEach((element) => {
      allDocuments.push(element);
    });
    placeholderFolders.forEach((element) => {
      allDocuments.push(element);
    });
    restOfDocuments.forEach((element) => {
      allDocuments.push(element);
    });
    setCurrentDocuments(allDocuments);
    if (isDocumentVersion) {
      setCurrentDocuments(documents);
    }
  }, [documents]);

  const MenuListFolder = currentDocuments.map((document, index) => {
    return (
      <ListItemDocument
        key={document.id}
        currentDocument={document}
        handleDeleteFolder={handleDeleteFolder}
        onAddNewFolder={onAddNewFolder}
        companyID={companyID}
        mainTable={mainTable}
        mainTableID={mainTableID}
        documents={currentDocuments}
        dbTable={dbTable}
        selectedDocumentTab={selectedDocumentTab}
        permissionGroupsDB={permissionGroupsDB}
        isLastTemplate={index + 1 === markAsTemplate ? true : false}
        onDraggableDocument={onDraggableDocument}
        onDragEnd={onDragEnd}
        draggableDocument={draggableDocument}
        isDraggableDocument={isDraggableDocument}
        handleDraggableDocumentClean={handleDraggableDocumentClean}
        documentFlat={documentFlat}
        hasParentPermission={hasParentPermission}
        parentDocument={parentDocument}
        disabled={disabled}
        isExpand={isExpand}
        onOpenSnackBar={onOpenSnackBar}
        onShowModal={onShowModal}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        querySearch={querySearch}
        handleExpandOrCollapse={handleExpandOrCollapse}
        newRootFolder={newRootFolder}
        onChangeRootFolder={() => setNewRootFolder({})}
        allowMultipleFiles={allowMultipleFiles}
        onChangeShipment={onChangeShipment}
        nivel={nivel}
      />
    );
  });
  return (
    <FolderStructureStyled className="folderStructureListContainer">
      <List classes={{ root: "root-list-content", padding: "paddingList" }}>
        {createNewFolder && (
          <React.Fragment>
            <CreateNewFolder
              folderObj={folderObj}
              handleSaveFolder={(ev) => {
                handleSaveFolder();
                setNewRootFolder(folderObj);
              }}
              handleCancelSaveFolder={handleCancelSaveFolder}
              onChange={onChange}
            />
          </React.Fragment>
        )}
        {enableDragAndDropFiles && (
          <DragAndDropUploadFile
            companyID={companyID}
            mainTable={mainTable}
            mainTableID={mainTableID}
            dbTable={dbTable}
            documents={documents}
            permissionGroupsDB={permissionGroupsDB}
            selectedDocumentTab={selectedDocumentTab}
            disabled={disabled}
            isReadOnly={isReadOnly}
            handleReadOnlyModal={handleReadOnlyModal}
            allowMultipleFiles={allowMultipleFiles}
            orderIsVoided={orderIsVoided}
          />
        )}
        {MenuListFolder}
      </List>
    </FolderStructureStyled>
  );
}

export default FoldersStructure;
