import React from "react";
import { orderType } from "../../helpers/salesOrder";
import {
  NOTES_SCOPE,
  PO_STATUS,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import { colors } from "../../assets/jss/variables";
import {
  PurchaseOrderIcon,
  SalesOrderIcon,
  ShipmentOrderIcon,
} from "../../helpers/iconSvgPath";
import { CreateNoteCollapseStyled } from "./styles";
import { cx } from "@emotion/css";
import { Button, ButtonGroup } from "@mui/material";

function CreateNotes({
  salesOrder,
  purchaseOrder,
  shipment,
  onOpenNote,
  showButtons,
}) {
  const poName =
    purchaseOrder.type === orderType.PURCHASE_QUOTE
      ? "Purchase Quote"
      : "Purchase Order";
  const soName = salesOrder.type === orderType.QUOTE ? "Quote" : "Sales Order";
  return (
    <CreateNoteCollapseStyled
      in={showButtons}
      className={"createNoteCollapse"}
      timeout={750}
    >
      <span style={{ color: "#92A1B0", marginLeft: 4 }}>New note:</span>
      <ButtonGroup style={{ marginRight: 6, marginLeft: 4 }}>
        <Button
          className={cx("noteButton", NOTES_SCOPE.SALES_ORDER)}
          onClick={() => {
            onOpenNote(NOTES_SCOPE.SALES_ORDER);
          }}
          disabled={salesOrder.status === SALES_ORDER_STATUS.VOIDED}
        >
          <SalesOrderIcon
            width={18}
            height={18}
            color={colors.primaryDark}
            style={{
              marginRight: 8,
              opacity:
                salesOrder.status === SALES_ORDER_STATUS.VOIDED ? 0.6 : "",
            }}
          />
          {soName}
        </Button>
        <Button
          className={cx("noteButton", NOTES_SCOPE.PURCHASE_ORDER)}
          onClick={() => {
            onOpenNote(NOTES_SCOPE.PURCHASE_ORDER);
          }}
          disabled={
            purchaseOrder.status === PO_STATUS.VOIDED ||
            purchaseOrder.status === PO_STATUS.REASSIGNED
          }
        >
          <PurchaseOrderIcon
            width={18}
            height={18}
            color={colors.primaryDark}
            style={{
              marginRight: 8,
              opacity:
                purchaseOrder.status === PO_STATUS.VOIDED ||
                purchaseOrder.status === PO_STATUS.REASSIGNED
                  ? 0.6
                  : "",
            }}
          />
          {poName}
        </Button>
        <Button
          className={cx("noteButton", NOTES_SCOPE.SHIPMENT)}
          onClick={() => {
            onOpenNote(NOTES_SCOPE.SHIPMENT);
          }}
          disabled={!shipment.id}
        >
          <ShipmentOrderIcon
            width={18}
            height={18}
            color={!shipment.id ? "gray" : colors.primaryDark}
            style={{ marginRight: 8, opacity: !shipment.id ? 0.6 : "" }}
          />
          Shipment
        </Button>
      </ButtonGroup>
    </CreateNoteCollapseStyled>
  );
}

export default CreateNotes;
