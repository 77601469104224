import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";
import { orderType } from "../../helpers/salesOrder";
import { SALES_ORDER_STATUS } from "../../helpers/constants";

export default class SalesOrder {
  constructor({
    id = getRandomId(),
    date = null,
    shipDate = null,
    shipDates = [],
    CBM = 0,
    uniqueItems = 0,
    invoice = "",
    customerPO = "",
    potentialLateFee = parseFloat(0.0),
    amount = parseFloat(0.0),
    deposit = "",
    salesRepresentative = "",
    companyId = "",
    completedTasks = 0,
    creationDate = now(),
    customerId = "",
    number = 0,
    totalTasks = 0,
    updated_by = "",
    tags = [],
    type = orderType.SALES_ORDER,
    purchaseOrderIds = [],
    status = SALES_ORDER_STATUS.IN_PROGRESS,
    diamondStatus = "",
    discount = 0,
    referenceNumber = "",
    items = [],
  }) {
    this.id = id;
    this.date = date;
    this.shipDate = shipDate;
    this.shipDates = shipDates;
    this.CBM = CBM;
    this.uniqueItems = uniqueItems;
    this.invoice = invoice;
    this.customerPO = customerPO;
    this.potentialLateFee = potentialLateFee;
    this.amount = amount;
    this.deposit = deposit;
    this.salesRepresentative = salesRepresentative;
    this.companyId = companyId;
    this.completedTasks = completedTasks;
    this.creationDate = creationDate;
    this.customerId = customerId;
    this.number = number.toString();
    this.totalTasks = totalTasks;
    this.updated_by = updated_by;
    this.tags = tags;
    this.type = type;
    this.purchaseOrderIds = purchaseOrderIds;
    this.status = status;
    this.diamondStatus = diamondStatus;
    this.discount = discount;
    this.referenceNumber = referenceNumber;
    this.items = items;
  }
}
