import React, { useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import TypeFileSelector from "../../components/Documents/TypeFileSelector";
import { getSizeTransformedAccordingKBSize } from "../../helpers/helpers";
import AppConfig from "../../constants/AppConfig";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { MenuDowloadFileIcon } from "../../helpers/iconSvgPath";
import axios from "axios";
import Loader from "../../components/General/Loader";
import { documentScope } from "../../helpers/documents";
import { firestore } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { styled } from "@mui/material";

function DocumentLanding() {
  const [documentObj, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorHandling, setErrorHandling] = useState(null);
  const [sharedLink, setSharedLink] = useState({});

  async function downloadDocument(documentObj) {
    if (
      documentObj.scope === documentScope.SALES_ORDER ||
      documentObj.scope === documentScope.SHIPMENT ||
      documentObj.scope === documentScope.PURCHASE_ORDER
    ) {
      const url = getFunctionByName({
        name: "downloadDocument",
        env: globalEnvironment,
        params: "/createActivityEntry",
      });
      const response = await axios.post(url, {
        ...sharedLink,
        currentDocument: documentObj,
      });
      console.log("response", response.data);
    }
    if (documentObj.type === TYPE_OF_FILE.FOLDER) {
      const {
        companyId,
        parentCollection,
        parentDocumentId,
        collection,
        documentId,
        userId,
      } = sharedLink;
      let a = document.createElement("a");
      a.href = getFunctionByName({
        name: "downloadDocument",
        env: globalEnvironment,
        params: `?companyId=${companyId}&parentCollection=${parentCollection}&parentDocumentId=${parentDocumentId}&collection=${collection}&documentId=${documentId}&userId=${userId}`,
      });
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      window
        .fetch(documentObj.url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = documentObj.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }
  }

  async function getDocument({ link }) {
    const sharedLinkSnapDB = await getDocs(
      query(
        collection(firestore, dbTables.SHARED_LINKS),
        where("link", "==", link)
      )
    );

    const sharedLinkDB = sharedLinkSnapDB.docs.map((doc) => doc.data())[0];
    if (sharedLinkDB) {
      const { companyId, userId } = sharedLinkDB;
      const url = getFunctionByName({
        name: "downloadDocument",
        env: globalEnvironment,
        params: "/get",
      });
      const response = await axios.post(url, sharedLinkDB);
      const documentDB = response.data.document;
      setDocument({
        ...documentDB,
        companyId,
        userId,
      });
      setSharedLink(sharedLinkDB);
      setLoading(false);
    } else {
      setErrorHandling({
        status: 404,
        message: "No file/folder was found",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    const link = window.location.href;
    getDocument({ link });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <DocumentLandingPageStyled className="documentLandingPageContainer">
      <div className="header"></div>
      {errorHandling && errorHandling.status === 404 ? (
        <div className="errorHandling">{errorHandling.message}</div>
      ) : (
        <div className="content">
          <img
            className="companyLogo"
            src={documentObj.companyUrlLogo}
            alt={"company_logo"}
          />
          <section>
            <div>Download Document</div>
            <div>
              <span>
                <section>
                  <TypeFileSelector {...documentObj} />
                </section>
                <span className="name">{documentObj.name}</span>
                <span className="size">
                  {"(" +
                    getSizeTransformedAccordingKBSize(documentObj.size) +
                    ")"}
                </span>
                <span
                  onClick={() => downloadDocument(documentObj)}
                  className="download"
                >
                  DOWNLOAD
                  <MenuDowloadFileIcon color="#578EC7" />
                </span>
              </span>
            </div>
          </section>
        </div>
      )}
      <div className="tradedashLogo">
        Powered by{" "}
        <img
          src={AppConfig.appLogoWB}
          alt={"company_logo"}
          height={21}
          style={{ width: 146, margin: "0px 6px" }}
        />
      </div>
    </DocumentLandingPageStyled>
  );
}

const DocumentLandingPageStyled = styled("div")(({ theme }) => ({
  "& .documentLandingPageContainer": {
    position: "fixed",
    width: "100%",
    height: "100vh",
    backgroundColor: "#F6FBFF",
  },
  "& .header": {
    height: "6%",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0 1px 2px 0px rgba(0,0,0,0.5)",
  },
  "& .errorHandling": {
    height: "calc(100vh - 105px)",
    fontWeight: 700,
    fontSize: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .content": {
    width: "100%",
    height: "88%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 24,
    "& >section": {
      height: 300,
      width: 500,
      backgroundColor: "#FFF",
      boxShadow: "0 2px 6px 0 rgba(0,0,0,0.13)",
      display: "flex",
      flexDirection: "column",
      "& div": {
        "&:first-child": {
          height: 90,
          borderBottom: "1px solid #D4DFED",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#25486D",
          fontSize: 24,
          fontWeight: "100",
        },
        "&:last-child": {
          flex: 1,
          display: "flex",
          alignItems: "center",
          "& >span": {
            width: "100%",
            height: 85,
            backgroundColor: "#E5F2FF",
            borderLeft: "6px solid #25486D",
            display: "flex",
            alignItems: "center",
            padding: "0 28px",
            position: "relative",
          },
        },
      },
    },
  },
  "& .tradedashLogo": {
    height: "6%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: 8,
    fontSize: 14,
    fontWeight: 500,
  },
  "& .companyLogo": {
    height: 160,
    margin: 24,
  },
  "& .name": {
    fontSize: 14,
    fontWeight: "bold",
    color: "#25486D",
    marginLeft: 12,
    maxWidth: 150,
  },
  "& .size": {
    fontSize: 12,
    color: "#798A9B",
    marginLeft: 6,
  },
  "& .download": {
    position: "absolute",
    right: 28,
    fontSize: 14,
    color: "#39F",
    textDecoration: "underline",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
}));

export default DocumentLanding;
