import React, { useEffect, useRef, useState } from "react";
import { AcceptGreenIcon, CancelIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import ClearIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import { InputBase, ListItem, Typography, styled } from "@mui/material";

const InputField = styled(InputBase)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.common.white,
  border: "1px solid #D2DFEC",
  fontSize: 16,
  padding: "8px 30px 8px 10px",
  borderRadius: 4,
  color: colors.primaryDark,
}));

const RenameFoldersFiles = ({
  folderName,
  onChange,
  defaultChildren,
  onValid,
  onClose = () => {},
  style,
  editing,
}) => {
  const [name, setName] = useState(defaultChildren || folderName);
  const [idKey] = useState(moment().valueOf());
  const [validData, setValidData] = useState(false);
  const [isEditing, setIsEditing] = useState(editing);
  const inputRef = useRef(null);

  const submit = () => {
    onChange({
      target: {
        value: name,
      },
    });
    setValidData(true);
  };

  useEffect(() => {
    if (validData) {
      onValid();
      setValidData(false);
    }
  }, [validData]);

  useEffect(() => {
    if (isEditing && inputRef && inputRef.current) {
      inputRef.current.select();
    }
  }, [isEditing]);
  return (
    <div
      key={folderName}
      style={{
        ...style,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <ListItem
        style={{
          padding: "0px 10px 0px 16px",
        }}
        key={idKey}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            {!isEditing && (
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100%",
                }}
                varian="h4"
                key={`label_${idKey}`}
              >
                {`${name}`}
              </Typography>
            )}
            {isEditing && (
              <InputField
                autoFocus
                id={`item_${idKey}`}
                key={`input_${idKey}`}
                style={{ width: "100%" }}
                value={name}
                inputRef={inputRef}
                onChange={(e) => {
                  e.preventDefault();
                  let text = e.target.value.replace(/\s\s+/g, " ");
                  text = text.replace("/", "");
                  setName(text);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    submit();
                    setIsEditing(false);
                  }
                  if (ev.key === "Escape") {
                    onClose();
                    setIsEditing(false);
                    setName(defaultChildren || folderName);
                  }
                }}
              />
            )}
            <ClearIcon
              fontSize="small"
              style={{
                position: "relative",
                right: 25,
                width: 15,
                height: 15,
                color: colors.darkGray,
              }}
              onClick={() => {
                setName("");
                onChange({
                  target: {
                    value: "",
                  },
                });
              }}
            />
          </div>
          <div
            style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}
          >
            <CancelIcon
              style={{
                margin: "8px",
                cursor: "pointer",
              }}
              height={17}
              width={17}
              color={colors.diamondRed}
              onClick={(e) => {
                onClose();
                setIsEditing(false);
                setName(defaultChildren || folderName);
              }}
            />
            <AcceptGreenIcon
              style={{
                cursor: !name ? "" : "pointer",
                opacity: !name ? 0.6 : 1,
              }}
              onClick={(e) => {
                if (name) {
                  submit();
                  setIsEditing(false);
                }
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      </ListItem>
    </div>
  );
};

export default RenameFoldersFiles;
