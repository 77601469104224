import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox } from "@mui/material";
import React from "react";

function CustomCheckbox({
  checked,
  styles,
  onChange = () => {},
  onClick = () => {},
  size = "medium",
  disabled = false,
  id = "custom-checkbox",
}) {
  return (
    <Checkbox
      id={id}
      checked={checked}
      checkedIcon={<CheckBoxIcon className="check" />}
      size={size}
      style={{
        color: checked ? "#39f" : "#6B7A89",
        width: 15,
        height: 15,
        margin: "6px 8px",
        ...styles,
      }}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export default CustomCheckbox;
