import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const HeaderTaskGroup = styled("div")(() => ({
  "&.poHeader": {
    height: 40,
    display: "flex",
    alignItems: "center",
    minWidth: 0,
    maxWidth: "100%",
    position: "sticky",
    top: "0px",
    zIndex: 101,
    backgroundColor: "white",
  },
  "& .groupInfoContainer": {
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.primaryDark,
    fontWeight: "bold",
    color: "white",
    padding: 8,
    marginRight: 0,
    height: "100%",
    borderRight: "1px solid",
    position: "sticky",
    justifyContent: "center",
    minWidth: 100,
    "& a": {
      color: "#FFF",
      textDecoration: "underline",
    },
  },
}));

export const FilterTaskContainer = styled("div")(() => ({
  "&.root": {
    height: "100%",
    overflowY: "auto",
    position: "relative",
    padding: 16,
    backgroundColor: "#F6FBFF",
    zIndex: 0,
  },
  "& .contentContainer": {
    marginTop: 16,
    width: "100%",
    boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
    backgroundColor: "white",
    position: "relative",
    paddingBottom: 5,
  },

  "& .contentOptions": {
    padding: "16px 16px 0px 16px",
    height: 88,
  },
  "& .contentSettings": {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 25,
    marginBottom: 5,
    height: 40,
  },
  "& .buttonsFilter": {
    display: "flex",
    flex: "1 1 0%",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 7,
    position: "relative",
    gap: 15,
  },
}));

export const TaskTableContainer = styled("div")(() => ({
  "&.contentListTask ": {
    height: "calc(100vh - 335px)",
    width: "100%",
    backgroundColor: "white",
    "& .MuiTableCell-root": {
      borderBottom: `1px solid ${colors.dividerColor} !important`,
      color: colors.primaryDark,
    },
    marginRight: 3,
    overflow: "auto",
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.thumbScrollColor,
      },
    },
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
      marginRight: 3,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track:vertical": {
      marginTop: 52,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: 5,
      marginLeft: 3,
    },
  },
  "&.groupContainer": {
    paddingBottom: 0,
    "&::-webkit-scrollbar-track:vertical": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));
