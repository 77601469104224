import React from "react";
import { isEqual } from "lodash";
import { globalScopes } from "../../../api/types/dbTables";
import { generalPermissions } from "../../../helpers/constants";

import {
  shipmentAmountFields,
  shipmentFields,
  shipmentFinalDestinationField,
  shipmentObjFields,
} from "../../../helpers/orderDashboard";
import { useQueryParams } from "../../../hooks";
import { shipmentItemList, useItemsList } from "../../../hooks/itemTable";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { usePurchaseOrders, useSalesOrders } from "../../../hooks/salesOrders";
import { useUser } from "../../../hooks/user";
import { ShipmentTitleContainer } from "../styles";
import { colors } from "../../../assets/jss/variables";
import { useCurrentCustomer } from "../../../hooks/customers";
import FinalDestination from "../../../api/model/finalDestination";
import MetaDataShipment from "../../../api/model/MetadataShipment";
import MetadataProperty from "./MetadataProperty";
import { updateDoc } from "firebase/firestore";

const isDateField = {
  shipDate: true,
  deliveryDate: true,
};

function ShipmentMetadataInfo({ shipment }) {
  const queryParams = useQueryParams();
  const purchaseOrders = usePurchaseOrders();
  const salesOrders = useSalesOrders();

  const currentCustomer = useCurrentCustomer() || {};

  const currentPurchaseOrder =
    purchaseOrders.find(
      (purchaseOrder) => purchaseOrder.id === queryParams.purchaseOrderId
    ) || {};
  const currentSalesOrder =
    salesOrders.find(
      (salesOrder) => salesOrder.id === queryParams.salesOrderId
    ) || {};

  const isAllowed = useIsAllowedFunction();
  const user = useUser();
  const companyId = user.companyId;
  const isReadOnly = false;

  const poItemsList = useItemsList({
    currentPurchaseOrder: currentPurchaseOrder,
    user: user,
  });
  const soItemsList = useItemsList({
    salesOrder: currentSalesOrder,
    purchaseOrders,
    user: user,
  });
  const shipmentsItems = shipmentItemList({
    poItemsList,
    currentShipment: shipment,
    soItemsList,
    purchaseOrders,
    user,
  });

  function onChangeShipmentProperty(value, field) {
    if (isNaN(value) && isDateField[field]) {
      return;
    }
    if (isEqual(shipment[field], value)) {
      return;
    }
    updateDoc(shipment.ref, {
      [field]: value,
      updated_by: user.id,
      triggerType: field,
    });
  }
  function getTotalShipmentAmount({ items = [], shipmentId }) {
    const totalShipment = items.reduce((prevValue, currentValue) => {
      return (
        prevValue +
        currentValue.shipmentQuantity[shipmentId] * currentValue.unitCost
      );
    }, 0);
    return totalShipment;
  }

  return (
    <React.Fragment>
      <ShipmentTitleContainer className="shipmentTitleContainer">
        <div className="stc-shipment-number">
          <span
            style={{
              fontSize: 20,
              color: colors.darkGray,
              marginTop: 1,
              marginLeft: 0,
            }}
          >
            Shipment:
          </span>
          <span className="shipmentBadge">{shipment.number}</span>
        </div>
        <MetadataProperty
          onSave={onChangeShipmentProperty}
          property={shipmentObjFields.FINAL_DESTINATION}
          popperClass={""}
          item={shipment}
          disabled={isReadOnly || !shipmentFinalDestinationField.editable}
          companyId={companyId}
          propertyScope={globalScopes.SHIPMENT}
          propertyContainerStyles={{
            borderBottom: "none",
            marginLeft: 16,
            borderTop: "none",
          }}
          field={shipmentFinalDestinationField}
          secondaryClassName="property-as-title"
          currentOptions={Object.values(
            currentCustomer.finalDestinations || {}
          )}
          customer={currentCustomer || {}}
          metaDataModel={FinalDestination}
          customerMetadatas={currentCustomer.finalDestinations}
          metadataKey={"finalDestinations"}
        />
      </ShipmentTitleContainer>

      <div className="sales-order-info">
        <div className="metadata-info-container">
          <div className="metadata-info">
            {shipmentFields.map((item, index) => {
              const hasPermission = item.permission
                ? isAllowed(item.permission)
                : true;
              const disabled =
                isReadOnly ||
                !item.editable ||
                !hasPermission ||
                (shipmentAmountFields.includes(item.field) &&
                  !isAllowed(generalPermissions.SHIPMENT_AMOUNT));
              return (
                <div
                  style={{ gridColumnEnd: "span 1" }}
                  key={item.field || index}
                >
                  <MetadataProperty
                    onGetItemsTable={shipmentsItems}
                    onSave={onChangeShipmentProperty}
                    property={item.field}
                    item={{
                      ...shipment,
                      amount: getTotalShipmentAmount({
                        items: poItemsList.filter((item) =>
                          item.shipmentIds.includes(shipment.id)
                        ),
                        shipmentId: shipment.id,
                      }),
                    }}
                    disabled={disabled}
                    companyId={companyId}
                    propertyLabelStyles={
                      item.field === "totalUniqueItems"
                        ? { textOverflow: "unset" }
                        : {}
                    }
                    propertyScope={globalScopes.SHIPMENT}
                    field={item}
                    classProperty={
                      disabled ? "disabled-sales-order-property" : false
                    }
                    currentPurchaseOrder={currentPurchaseOrder}
                    currentOptions={
                      item.listValue
                        ? Object.values(currentCustomer[item.listValue] || {})
                        : []
                    }
                    customer={currentCustomer || {}}
                    metaDataModel={MetaDataShipment}
                    customerMetadatas={currentCustomer[item.listValue]}
                    metadataKey={item.listValue}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShipmentMetadataInfo;
