import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FilterTab from "./FilterTab";
import RecentActivity from "../Widgets/RecentActivity";
import { dbTables, reduxState } from "../../api/types/dbTables";
import {
  activitiesFilteredByPermission,
  debounce,
  getFilterActivitiesByDate,
  getRandomId,
  hasFeatureFlagPermission,
  replacePathReference,
} from "../../helpers/helpers";
import {
  setDraftActivity,
  useDataRefactored,
  useQueryParams,
  useStorage,
} from "../../hooks";
import {
  useBackdropState,
  useCurrentActivityNote,
  useTabId,
  useUser,
  useUserDraft,
} from "../../hooks/user";
import moment from "moment";
import SearchLabel from "../Inputs/SearchLabel";
import MultiUserPicker from "../DatePicker/MultiUserPicker";
import Notification from "../../api/model/Notification";
import Badge from "../Badge/Badge";
import { useCustomers } from "../../hooks/customers";
import { useFactories } from "../../hooks/factories";
import { purchaseOrderTriggerTypes } from "../../helpers/purchaseOrder";
import { salesOrderTriggerTypes } from "../../helpers/salesOrder";
import { setActivities, setGlobalNoteDate } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import {
  featureFlagNames,
  GENERAL_PERMISSION_VALUE,
  notesFilters,
  NOTES_SCOPE,
  NOTIFICATION_SCOPE,
  PO_STATUS,
  BACKDROP_TYPE,
} from "../../helpers/constants";
import Loader from "../General/Loader";
import {
  activityScopes,
  activityType,
  updateCompanyUsersUnreadActivity,
} from "../../helpers/activitiesStream";
import CreateNotes from "./CreateNotes";
import NotesSection from "../Notes/NotesSection";
import { now } from "moment";
import { firestore, performanceFirebase } from "../../firebase";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  SEARCH_BOX_TYPES,
  poAmountFieldsToVerify,
  salesOrderAmountFieldsToVerify,
  shipmentAmountFieldsToVerify,
} from "../../helpers/orderDashboard";
import { colors } from "../../assets/jss/variables";
import { getOldestNote } from "../../helpers/salesOrderHelpers";
import { usePurchaseOrders } from "../../hooks/salesOrders";
import {
  collection,
  getDocs,
  orderBy,
  limit,
  query as queryDB,
  doc,
  where,
  updateDoc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { ReplyIcon, VoidLabel } from "../../helpers/iconSvgPath";
import { ProjectNotesContainerStyled } from "./styles";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { useShipments } from "../../hooks/shipments";
import { emojisIcon } from "../../helpers/emojisHelper";
import TooltipTD from "../Tooltip/TooltipTD";
import { BackdropDashboard } from "./sections/BackdropDashboard";
import {
  checkOrderMention,
  getOrderUnreadUser,
  getPathByScope,
  getSeedByScope,
  getTotalThreads,
  isActivityNoteMention,
  updateUserMention,
} from "./HelperProjectNotes";
import ActivityNote from "../../api/model/ActivityNote.model";
import { trace } from "firebase/performance";
import { cx } from "@emotion/css";

let traceInstance = trace(
  performanceFirebase,
  "dashboard_activities_start_date"
);

const stopTrace = () => {
  if (traceInstance) {
    try {
      traceInstance.stop();
      traceInstance = false;
    } catch (error) {
      console.log(error, "traceError stop");
    }
  }
};

const startTrace = () => {
  if (traceInstance) {
    try {
      traceInstance.start();
    } catch (error) {
      console.log(error, "traceError stop");
    }
  }
};

const scope = {
  [activityScopes.PURCHASE_ORDER]: NOTES_SCOPE.PURCHASE_ORDER,
  [activityScopes.PURCHASE_QUOTE]: NOTES_SCOPE.PURCHASE_ORDER,
  [activityScopes.SALES_ORDER]: NOTES_SCOPE.SALES_ORDER,
  [activityScopes.QUOTE]: NOTES_SCOPE.SALES_ORDER,
  [activityScopes.SHIPMENT]: NOTES_SCOPE.SHIPMENT,
};

const currentActivityType = [
  activityType.FILE_CHANGE_PERMISSION,
  activityType.TASK_COMPLETED,
  activityType.TASK_MOVED,
  activityType.REASSIGNED_TASK,
  activityType.FILE_UPLOAD,
  activityType.WITHOUT_TITLE,
  activityType.ITEM_CREATED,
  activityType.ITEM_UPDATED,
  activityType.TASK_COMPLETED,
  activityType.PROPERTY_CHANGE_SHIPMENT,
  activityType.PROPERTY_CHANGE,
];

function ProjectNotesV2({
  purchaseOrder,
  salesOrder,
  companyId,
  companyUsers,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  currentShipment,
  onChangeShipment,
}) {
  const backdropState = useBackdropState();
  const currentUser = useUser();
  const shipments = useShipments();
  const tabId = useTabId();
  const creationDay = moment(salesOrder.creationDate).startOf("D").valueOf();
  const [storage, setStorage] = useStorage(purchaseOrder.id);
  const activityNote = useCurrentActivityNote();
  const [signClearMention, setSignClearMention] = useState(false);

  const featureFlags = useFeatureFlags({ companyId });
  const [limitDay, setLimitDay] = useState(false);
  const hasThreadPermission = hasFeatureFlagPermission({
    featureFlags,
    user: currentUser,
    featureFlagName: featureFlagNames.THREAD_NOTES,
  });
  const query = useQueryParams();
  const customers = useCustomers();
  const factories = useFactories();
  const dispatch = useDispatch();
  const isAllowed = useIsAllowedFunction();
  const purchaseOrders = usePurchaseOrders(companyId);
  const draftNote = useUserDraft();
  const getActivities = useCallback(
    ({ companyId, startDate, endDate, dbTable, dbTableId, reduxState }) => {
      setActivities({
        companyId,
        startDate,
        endDate,
        dbTable,
        dbTableId,
        reduxState,
      })(dispatch);
    }
  );

  const currentCustomer = customers.find(
    (customer) => customer.id === salesOrder.customerId
  );
  const currentFactory = factories.find(
    (factory) => factory.id === purchaseOrder.factoryId
  );

  const [activeTab, setActiveTab] = useState(
    !query.userMention
      ? storage.activityFilter || notesFilters.NOTES
      : notesFilters.MENTIONS
  );
  const [openModalNotes, setOpenModalNotes] = useState(false);
  const [editingNote, setEditingNote] = useState(false);
  const [activePOActivities, setActivePOActivities] = useState(false);
  const [activeShipmentActivities, setActiveShipmentActivities] =
    useState(false);
  const [signPO, setSignPO] = useState(false);
  const [signShipment, setSignShipment] = useState(false);
  const [currentNote, setCurrentNote] = useState({});
  const [querySearch, setQuerySearch] = useState("");
  const [noteThread, setNoteThread] = useState(false);
  const [filterUsersActivities, setFilterUsersActivities] = useState(
    query.noteId ? [] : storage.ONLY_FROM ? getUsersFromTheStorage() : []
  );
  const [cleanSearchBox, setCleanSearchBox] = useState(false);
  const setNoteDate = useCallback(
    ({ type = "", creationDate = "", noteId = "", wait = false }) =>
      setGlobalNoteDate({
        type,
        creationDate,
        noteId,
        wait,
      })(dispatch)
  );

  useEffect(() => {
    if (!traceInstance) {
      traceInstance = trace(
        performanceFirebase,
        "dashboard_activities_start_date"
      );
    }
    return () => setNoteDate({});
  }, []);

  const [currentDate, setCurrentDate] = useState(false);
  const [oldestDate, setOldestDate] = useState(moment().endOf("day").valueOf());
  const [isLoading, setIsLoading] = useState(false);
  const [showActivityOptions, setShowActivityOptions] = useState(null);
  const [replyActivity, setReplyActivity] = useState({});
  const [unreadActivity, setUnreadActivity] = useState({
    status: false,
    current: null,
  });

  const salesOrderActivities = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SALES_ORDERS,
      salesOrder.id,
      dbTables.ACTIVITIES,
    ],
    changingParams: [salesOrder.id],
    stateReference: reduxState.SALES_ORDER_ACTIVITIES,
    limit: 5,
    orderBy: {
      field: "creationDate",
      sort: "desc",
    },
  });

  const purchaseOrderActivities = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.PURCHASE_ORDERS,
      purchaseOrder.id,
      dbTables.ACTIVITIES,
    ],
    changingParams: [purchaseOrder.id],
    stateReference: reduxState.PURCHASE_ORDER_ACTIVITIES,
    limit: 5,
    orderBy: {
      field: "creationDate",
      sort: "desc",
    },
  });

  const shipmentActivities = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SHIPMENTS,
      currentShipment.id,
      dbTables.ACTIVITIES,
    ],
    changingParams: [currentShipment.id],
    stateReference: reduxState.SHIPMENT_ACTIVITIES,
    limit: 5,
    orderBy: {
      field: "creationDate",
      sort: "desc",
    },
  });

  useEffect(() => {
    if (signClearMention) {
      clearAllMention();
    }
  }, [signClearMention]);

  const onDebouncePO = useCallback(
    debounce(() => {
      setSignPO(true);
    }, 2500),
    []
  );
  const onDebounceShipment = useCallback(
    debounce(() => {
      setSignShipment(true);
    }, 1800),
    []
  );

  const numberOfActivities = getTotalThreads({
    noteThread,
    purchaseOrderActivities,
    salesOrderActivities,
    shipmentActivities,
  });
  function getOldActivityEntry({
    userId,
    salesOrder,
    purchaseOrder,
    shipment,
  }) {
    const unreadSalesOrderActivity = getOrderUnreadUser({
      order: salesOrder.unreadActivityUsers,
      userId,
    });
    const unreadPOActivity = getOrderUnreadUser({
      order: purchaseOrder.unreadActivityUsers,
      userId,
    });
    const unreadShipmentActivity = getOrderUnreadUser({
      order: shipment.unreadActivityUsers,
      userId,
    });

    const tomorrow = moment().add(1, "day").valueOf();

    const poCreationDate = unreadPOActivity.creationDate || tomorrow;
    const shipmentCreationDate = unreadPOActivity.creationDate || tomorrow;
    const soCreationDate = unreadSalesOrderActivity.creationDate || tomorrow;

    if (
      unreadSalesOrderActivity.creationDate <= poCreationDate &&
      unreadSalesOrderActivity.creationDate <= shipmentCreationDate
    ) {
      return unreadSalesOrderActivity;
    } else if (
      unreadPOActivity.creationDate <= soCreationDate &&
      unreadPOActivity.creationDate <= shipmentCreationDate
    ) {
      return unreadPOActivity;
    } else {
      return unreadShipmentActivity;
    }
  }

  useEffect(() => {
    const oldestUnreadActivity = getOldActivityEntry({
      purchaseOrder,
      salesOrder,
      shipment: currentShipment,
      userId: currentUser.id,
    });

    if (oldestUnreadActivity && oldestUnreadActivity.userId) {
      setUnreadActivity({
        status: true,
        current: oldestUnreadActivity,
      });
      setShowActivityOptions(null);
    } else {
      setUnreadActivity({
        status: false,
        current: null,
      });
    }
  }, [
    salesOrder.unreadActivityUsers,
    purchaseOrder.unreadActivityUsers,
    currentShipment && currentShipment.unreadActivityUsers,
  ]);

  function getUsersFromTheStorage() {
    const userList = companyUsers.filter((item) => {
      return storage.ONLY_FROM.includes(item.id);
    });
    return userList;
  }

  useEffect(() => {
    if (query.noteId && activeTab !== notesFilters.NOTES) {
      setStorage("ONLY_FROM", []);
      setFilterUsersActivities([]);
      setActiveTab(notesFilters.NOTES);
      setStorage("activityFilter", undefined);
    }
  }, [query.noteId]);

  useEffect(() => {
    setNoteThread(false);
    setReplyActivity({});
    if (storage.activityFilter && !query.noteId) {
      setActiveTab(storage.activityFilter);
    } else {
      setActiveTab(notesFilters.NOTES);
    }
    if (storage.ONLY_FROM && !query.noteId) {
      setFilterUsersActivities(getUsersFromTheStorage());
    } else {
      setFilterUsersActivities([]);
    }
  }, [purchaseOrder.id]);

  useEffect(() => {
    setNoteThread(false);
    setReplyActivity({});
  }, [currentShipment.id]);

  const getNumberMention = () => {
    let numberUserMentions = checkOrderMention(salesOrder, currentUser.id);
    numberUserMentions += checkOrderMention(purchaseOrder, currentUser.id);
    numberUserMentions += checkOrderMention(currentShipment, currentUser.id);
    return numberUserMentions;
  };

  function handleOpenModalNotes() {
    setOpenModalNotes(true);
  }

  function handleSubmit({
    detail,
    mentionsFormat,
    typeScope,
    editing,
    userMentions,
    lockNote,
    urlList,
    id,
    permissionGroupObjIds,
    shipmentObjIds,
    userObjIds,
    documentObjIds,
    threadId = "",
    iconIndex,
    temporalNote,
  }) {
    const newActivity = {};
    let notificationId = getRandomId();
    if (editing) {
      newActivity.id = currentNote.id;
      notificationId = currentNote.notificationId;
    } else {
      newActivity.id = id;
    }
    if (temporalNote) {
      deleteDoc(temporalNote.ref);
    }
    const { currentScope, seedArray, currentNumber, currentOrder } =
      getSeedByScope({
        currentShipment,
        newActivity,
        purchaseOrder,
        salesOrder,
        typeScope,
      });

    newActivity.salesOrderId = salesOrder.id;
    const currentIndex = seedArray[currentNumber];
    const type = activityType.NOTE;
    let activity = new ActivityNote({
      ...newActivity,
      detail,
      type,
      user: currentUser.id,
      mentionsFormat,
      userMentions,
      lockNote: lockNote,
      urlList,
      permissionGroups: permissionGroupObjIds,
      shipments: shipmentObjIds,
      onlyUsersMention: userObjIds,
      documentsMentions: documentObjIds,
      readByUsers: userMentions,
      threadId: replyActivity.threadId || replyActivity.id || threadId,
      iconIndex: iconIndex || iconIndex === 0 ? iconIndex : currentIndex,
    });
    const pathToSet = getPathByScope({
      activity,
      companyId,
      currentOrder,
      currentScope,
    });

    if (!pathToSet) {
      throw Error("Something goes wrong creating note");
    }

    updateUserMention({
      currentNote,
      currentOrder,
      editing,
      iconIndex,
      userMentions,
    });

    const scopeList = {
      [activityScopes.PURCHASE_ORDER]: NOTIFICATION_SCOPE.PO_MENTION,
      [activityScopes.SHIPMENT]: NOTIFICATION_SCOPE.SHIPMENT_MENTION,
      [activityScopes.SALES_ORDER]: NOTIFICATION_SCOPE.SO_MENTION,
    };

    const notification = new Notification({
      id: notificationId,
      companyId: companyId,
      salesOrderId: salesOrder.id,
      purchaseOrderId: purchaseOrder.id,
      shipmentId:
        scopeList[currentScope] === NOTIFICATION_SCOPE.SHIPMENT_MENTION
          ? currentShipment.id
          : "",
      SONumber: salesOrder.number,
      PONumber: purchaseOrder.number,
      customerName: currentCustomer.name.replace("&", "%26"),
      factoryName: currentFactory.name.replace("&", "%26"),
      customerId: currentCustomer.id,
      factoryId: currentFactory.id,
      scope: scopeList[currentScope],
      read: false,
      newNotification: true,
      creationDate: moment().valueOf(),
      mainDocumentId: activity.id,
      description: activity.detail,
      type: "directMessage",
      createdBy: currentUser.id,
      shipment:
        scopeList[currentScope] === NOTIFICATION_SCOPE.SHIPMENT_MENTION
          ? {
              id: currentShipment.id,
              number: currentShipment.number,
              finalDestination: currentShipment.finalDestination,
            }
          : {},
    });

    activity = {
      ...activity,
      tabId,
      enableToEdit: currentUser.id,
      pathToSet,
      userMentions,
      companyId: companyId,
      notificationId,
      updateDate: now(),
      editing: false,
      notification: {
        ...notification,
        userMentions,
        userId: currentUser.id,
      },
    };
    const [currentDraft] = draftNote;
    if (currentDraft && !currentDraft.editing) {
      const { tabId, pathToSet, notification, ...activity } = currentDraft;
      setDraftActivity({
        pathToSet,
        activity,
        notification,
        currentDraft,
      });
    }
    setDoc(
      doc(
        firestore,
        `${dbTables.USERS}/${currentUser.id}/${dbTables.DRAFTS}/${activity.id}`
      ),
      {
        ...activity,
      }
    );
    setReplyActivity({});
    setOpenModalNotes(false);
    setEditingNote(false);
    setActiveTab(notesFilters.NOTES);
    setStorage("ONLY_FROM", []);
    setFilterUsersActivities([]);
    setCleanSearchBox(true);
    setNoteThread(false);
    const orderCpy = {
      ...currentOrder,
      unreadActivityUsers: currentOrder.unreadActivityUsers
        ? [...currentOrder.unreadActivityUsers]
        : [],
    };
    updateCompanyUsersUnreadActivity({
      activity,
      users: companyUsers,
      companyId: companyId,
      order: orderCpy,
    });
  }

  function onEditNote(activity) {
    setOpenModalNotes(true);
    setEditingNote(true);
    setCurrentNote({
      ...activity,
      scope: scope[activity.scope] || activity.scope,
    });
  }

  const updateUnreadActivityOrder = (activity) => {
    if (!activity.userMentions) {
      return;
    } else if (activity.userMentions[currentUser.id] !== undefined) {
      if (!activity.userMentions[currentUser.id]) {
        let userMentionsCpy = activity.userMentions;
        userMentionsCpy[currentUser.id] = true;
        let readByUsersCpy = activity.readByUsers || {};
        if (!readByUsersCpy[currentUser.id]) {
          readByUsersCpy[currentUser.id] = moment().valueOf();
        }
        updateDoc(activity.ref, {
          userMentions: userMentionsCpy,
          readBy: { [currentUser.id]: true },
          readByUsers: readByUsersCpy,
        });
      }
    }
  };

  const clearAllMention = () => {
    const SOActivityNotes = salesOrderActivities.filter((activity) =>
      isActivityNoteMention(activity, currentUser.id)
    );
    const POActivityNotes = purchaseOrderActivities.filter((activity) =>
      isActivityNoteMention(activity, currentUser.id)
    );
    const SHActivityNotes = shipmentActivities.filter((activity) =>
      isActivityNoteMention(activity, currentUser.id)
    );
    SOActivityNotes.forEach((activity) => updateUnreadActivityOrder(activity));
    POActivityNotes.forEach((activity) => updateUnreadActivityOrder(activity));
    SHActivityNotes.forEach((activity) => updateUnreadActivityOrder(activity));
    setSignClearMention(false);
  };

  async function changeFilter(filter) {
    getActivitiesFromTheBegining();
    setActiveTab(filter);
    setStorage("activityFilter", filter);

    if (filter === notesFilters.MENTIONS) {
      setCleanSearchBox(true);
      const oldestActivityEntryDate = await getOldestNote({
        companyId: companyId,
        salesOrderId: salesOrder.id,
        purchaseOrderId: purchaseOrder.id,
        shipmentId: currentShipment.id,
        userId: currentUser.id,
      });
      if (
        moment(oldestActivityEntryDate).startOf("day").valueOf() <
        moment(currentDate).startOf("day").valueOf()
      ) {
        setCurrentDate(oldestActivityEntryDate);
      }
      setTimeout(() => {
        setSignClearMention(true);
      }, 2000);

      if (salesOrder.ref) {
        updateDoc(salesOrder.ref, {
          userMentions: { ...salesOrder.userMentions, [currentUser.id]: 0 },
          triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
        });
      }
      if (purchaseOrder.ref) {
        updateDoc(purchaseOrder.ref, {
          userMentions: { ...purchaseOrder.userMentions, [currentUser.id]: 0 },
          triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
        });
      }
      if (currentShipment.ref) {
        updateDoc(currentShipment.ref, {
          userMentions: {
            ...currentShipment.userMentions,
            [currentUser.id]: 0,
          },
          triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
        });
      }
    }
  }

  function jumpingToBottom() {
    setTimeout(() => {
      const activityScrollContainer = document.getElementById(
        "load-activities-container-id"
      );
      activityScrollContainer.scrollIntoView({
        behavior: "smooth",
      });
    }, 1500);
  }

  function changeDate(date) {
    setCurrentDate(date);
    jumpingToBottom();
  }

  const getStartDayDate = (date) => {
    return moment(date).startOf("day").valueOf();
  };

  const getFirestoreDocumentDate = async ({ path, lastDateFound }) => {
    let queryFirestore = lastDateFound
      ? queryDB(
          collection(firestore, path),
          where("creationDate", "<", lastDateFound),
          orderBy("creationDate", "desc"),
          limit(1)
        )
      : queryDB(
          collection(firestore, path),
          orderBy("creationDate", "desc"),
          limit(1)
        );

    if (activeTab === notesFilters.NOTES) {
      queryFirestore = lastDateFound
        ? queryDB(
            collection(firestore, path),
            where("type", "==", "NOTE"),
            where("creationDate", "<", lastDateFound),
            orderBy("creationDate", "desc"),
            limit(1)
          )
        : queryDB(
            collection(firestore, path),
            where("type", "==", "NOTE"),
            orderBy("creationDate", "desc"),
            limit(1)
          );
    } else if (activeTab === notesFilters.ACTIVITY) {
      queryFirestore = lastDateFound
        ? queryDB(
            collection(firestore, path),
            where("type", "in", currentActivityType),
            where("creationDate", "<", lastDateFound),
            orderBy("creationDate", "desc"),
            limit(1)
          )
        : queryDB(
            collection(firestore, path),
            where("type", "in", currentActivityType),
            orderBy("creationDate", "desc"),
            limit(1)
          );
    }
    queryFirestore = queryDB(queryFirestore);
    const orderActivitySnap = await getDocs(queryFirestore);
    const [orderActivity] = orderActivitySnap.docs.map((doc) => doc.data());
    if (orderActivity) {
      return getStartDayDate(orderActivity.creationDate);
    }
    return false;
  };

  const getQueryCreationDate = async (pathArray) => {
    try {
      for (let i = 0; i < pathArray.length; i++) {
        const path = pathArray[i];
        let currentNoteQuery = await getDoc(
          doc(firestore, `${path}/${query.noteId}`)
        );
        if (currentNoteQuery.exists()) {
          return getStartDayDate(currentNoteQuery.data().creationDate);
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  async function verifyNewStartDate(storeDate) {
    startTrace();
    if (moment().startOf("D").valueOf() <= creationDay) {
      return setCurrentDate(creationDay);
    }
    if (
      activeTab === notesFilters.THREADS ||
      activeTab === notesFilters.MENTIONS
    ) {
      return setCurrentDate(creationDay);
    }
    let lastDateFound = storeDate ? getStartDayDate(storeDate) : false;
    const shipmentId = currentShipment ? currentShipment.id || "_" : "_";
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    const soPath = `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.ACTIVITIES}`;
    const poPath = `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.ACTIVITIES}`;
    const shipmentPath = `${companyRef}/${dbTables.SHIPMENTS}/${shipmentId}/${dbTables.ACTIVITIES}`;

    if (query.noteId && !lastDateFound) {
      lastDateFound = await getQueryCreationDate([
        soPath,
        poPath,
        shipmentPath,
        soPath,
      ]);
    }
    if (lastDateFound && lastDateFound <= creationDay) {
      return setCurrentDate(creationDay);
    }

    if (!lastDateFound) {
      lastDateFound = await getFirestoreDocumentDate({ path: soPath });
    }
    if (lastDateFound) {
      const prevSODate = await getFirestoreDocumentDate({
        path: soPath,
        lastDateFound,
      });
      if (prevSODate) {
        return setCurrentDate(prevSODate);
      }
    }
    if (!lastDateFound) {
      lastDateFound = await getFirestoreDocumentDate({ path: poPath });
    }
    if (lastDateFound) {
      const prevPODate = await getFirestoreDocumentDate({
        path: poPath,
        lastDateFound,
      });
      if (prevPODate) {
        return setCurrentDate(prevPODate);
      }
    }
    if (currentShipment && currentShipment.id) {
      if (!lastDateFound) {
        lastDateFound = await getFirestoreDocumentDate({ path: shipmentPath });
      }
      if (lastDateFound) {
        const prevPODate = await getFirestoreDocumentDate({
          path: shipmentPath,
          lastDateFound,
        });
        if (prevPODate) {
          return setCurrentDate(prevPODate);
        }
      }
    }
    if (lastDateFound) {
      const lastDate = moment(lastDateFound).startOf("day").valueOf();
      return setCurrentDate(lastDate);
    } else {
      return setCurrentDate(creationDay);
    }
  }

  useEffect(() => {
    const startDate = activityNote.creationDate
      ? getStartDayDate(activityNote.creationDate)
      : false;
    setIsLoading(!!startDate);
    if (!currentDate && !activityNote.wait) {
      setNoteDate({ wait: true });
      setIsLoading(false);
      verifyNewStartDate(startDate);
      return;
    }
    if (currentDate && startDate && currentDate <= startDate) {
      setNoteDate({});
      return;
    } else if (startDate && currentDate > startDate && !limitDay) {
      setNoteDate({});
      verifyNewStartDate(startDate);
      return;
    }
    if (currentDate && !limitDay && currentDate !== oldestDate) {
      setNoteDate({});
      getActivities({
        companyId,
        startDate: currentDate,
        endDate: oldestDate,
        dbTable: dbTables.SALES_ORDERS,
        dbTableId: salesOrder.id,
        reduxState: reduxState.SALES_ORDER_ACTIVITIES,
      });
      setLimitDay(currentDate <= creationDay);
      setOldestDate(currentDate);
      setActivePOActivities(true);
      setActiveShipmentActivities(true);
      if (isLoading) {
        setTimeout(() => {
          setIsLoading(false);
        }, [2500]);
      }
      stopTrace();
      return;
    }
    if (activityNote.wait) {
      return;
    }
    setIsLoading(false);
  }, [currentDate, activityNote.creationDate]);

  useEffect(() => {
    if (!activeShipmentActivities || !currentShipment.id || !currentDate) {
      return;
    }
    if (!activeShipmentActivities.id) {
      setActiveShipmentActivities({
        id: currentShipment.id,
        startDate: currentDate,
        endDate: moment().endOf("day").valueOf(),
      });
    }
    if (
      currentShipment.id &&
      activeShipmentActivities.id &&
      (currentDate !== activeShipmentActivities.endDate ||
        activeShipmentActivities.id !== currentShipment.id)
    ) {
      onDebounceShipment();
    }
  }, [currentShipment.id, activeShipmentActivities, currentDate]);

  useEffect(() => {
    if (currentDate) {
      getActivities({
        companyId,
        startDate: currentDate,
        endDate: oldestDate,
        dbTable: dbTables.SALES_ORDERS,
        dbTableId: salesOrder.id,
        reduxState: reduxState.SALES_ORDER_ACTIVITIES,
      });
      setLimitDay(currentDate <= creationDay);
      setOldestDate(currentDate);
      setActivePOActivities(true);
      setActiveShipmentActivities(true);
      setIsLoading(false);
      return;
    }
  }, [salesOrder.id]);

  useEffect(() => {
    if (signShipment) {
      const { id, startDate, endDate } = activeShipmentActivities;
      if (id !== currentShipment.id) {
        setNoteThread(false);
      }
      getActivities({
        companyId,
        startDate: startDate,
        endDate:
          id === currentShipment.id ? endDate : moment().endOf("day").valueOf(),
        dbTable: dbTables.SHIPMENTS,
        dbTableId: currentShipment.id,
        reduxState: reduxState.SHIPMENT_ACTIVITIES,
      });

      setActiveShipmentActivities({
        id: id === currentShipment.id ? id : currentShipment.id,
        startDate: currentDate,
        endDate: currentDate,
      });
      setSignShipment(false);
    }
  }, [signShipment]);

  //
  useEffect(() => {
    if (!activePOActivities || !currentDate) {
      return;
    }
    if (!activePOActivities.id) {
      setActivePOActivities({
        id: purchaseOrder.id,
        startDate: currentDate,
        endDate: moment().endOf("day").valueOf(),
      });
    }
    if (
      activePOActivities.id &&
      (currentDate !== activePOActivities.endDate ||
        activePOActivities.id !== purchaseOrder.id)
    ) {
      onDebouncePO();
    }
  }, [purchaseOrder.id, activePOActivities, currentDate]);

  useEffect(() => {
    if (signPO) {
      const { id, startDate, endDate } = activePOActivities;
      if (id !== purchaseOrder.id) {
        setNoteThread(false);
      }

      getActivities({
        companyId,
        startDate: startDate,
        endDate:
          id === purchaseOrder.id ? endDate : moment().endOf("day").valueOf(),
        dbTable: dbTables.PURCHASE_ORDERS,
        dbTableId: purchaseOrder.id,
        reduxState: reduxState.PURCHASE_ORDER_ACTIVITIES,
      });
      setActivePOActivities({
        id: id === purchaseOrder.id ? id : purchaseOrder.id,
        startDate: currentDate,
        endDate: currentDate,
      });
      setSignPO(false);
    }
  }, [signPO]);

  const handleShowThread = async (currentReplyActivity) => {
    setCleanSearchBox(true);
    const replyId = currentReplyActivity.threadId || currentReplyActivity.id;
    let currentActivity =
      salesOrderActivities.find((activity) => activity.id === replyId) ||
      purchaseOrderActivities.find((activity) => activity.id === replyId) ||
      shipmentActivities.find((activity) => activity.id === replyId);
    if (currentActivity) {
      setNoteThread({
        ...currentActivity,
        numberOfReplies: currentReplyActivity.numberOfReplies,
      });
      setActiveTab(notesFilters.NOTES);
      if (currentDate > currentActivity.creationDate) {
        setCurrentDate(currentActivity.creationDate);
      }
    } else {
      const newPath = replacePathReference({
        oldPath: currentReplyActivity.ref.path,
        newId: replyId,
      });
      const snapDoc = await getDoc(doc(firestore, `${newPath}`));
      if (snapDoc.exists()) {
        const newCreationDate = snapDoc.data().creationDate;
        setNoteThread({
          ...snapDoc.data(),
          ref: snapDoc.ref,
          numberOfReplies: Math.max(
            snapDoc.data().numberOfReplies,
            currentReplyActivity.numberOfReplies
          ),
        });
        setActiveTab(notesFilters.NOTES);
        if (currentDate > newCreationDate) {
          setCurrentDate(newCreationDate);
        }
      } else {
      }
    }
  };

  const handleReplyActivity = (currentReplyActivity) => {
    const orderScope = {
      [activityScopes.PURCHASE_ORDER]: purchaseOrder,
      [activityScopes.PURCHASE_QUOTE]: purchaseOrder,
      [activityScopes.SALES_ORDER]: salesOrder,
      [activityScopes.QUOTE]: salesOrder,
      [activityScopes.SHIPMENT]: currentShipment,
    };
    const currentOrder = orderScope[currentReplyActivity.scope] || {};
    if (isReadOnly || currentOrder.status === PO_STATUS.VOIDED) {
      handleReadOnlyModal();
      return;
    }
    setCurrentNote({
      ...new ActivityNote({
        scope: scope[currentReplyActivity.scope],
        threadId: currentReplyActivity.threadId || currentReplyActivity.id,
        iconIndex: currentReplyActivity.iconIndex,
      }),
    });
    handleOpenModalNotes();
    setReplyActivity(currentReplyActivity);
  };

  function getActivitiesFromTheBegining() {
    if (!limitDay) {
      setCurrentDate(creationDay);
    }
  }
  const backdropBadge = backdropState.type === BACKDROP_TYPE.BADGE_NUMBER;
  return (
    <ProjectNotesContainerStyled
      className={cx(
        "orderDashboardNotesContainer",
        noteThread ? "active" : "inactive",
        noteThread.scope
      )}
      id="dashboard-activities"
    >
      <div className="content-card-header transparent">
        <BackdropDashboard backdropType={BACKDROP_TYPE.NOTE} initTimer={100} />
        <BackdropDashboard
          backdropType={
            getNumberMention() !== 0 ? BACKDROP_TYPE.BADGE_NUMBER : "EMPTY"
          }
          initTimer={800}
        />
        <div className="project-tasks">
          <div style={{ display: "flex", alignItems: "center" }}>
            {purchaseOrder.status === PO_STATUS.REASSIGNED && (
              <Badge className="po-status-reassigned" isVoid={false} />
            )}
            {salesOrder.status === PO_STATUS.VOIDED && <VoidLabel />}
            <span className="title">
              {noteThread && emojisIcon[noteThread.iconIndex] && (
                <img
                  width={30}
                  style={{ marginRight: 6 }}
                  src={emojisIcon[noteThread.iconIndex].src}
                  alt={emojisIcon[noteThread.iconIndex].alt}
                />
              )}
              {!noteThread
                ? "Activity Stream"
                : `Reply Thread (${numberOfActivities + 1})`}
            </span>
          </div>
          <div className="activity-entry-indicador">
            <div className="general-filters">
              {!noteThread && (
                <FilterTab
                  onClick={changeFilter}
                  activeTab={activeTab}
                  value={notesFilters.ALL}
                  label="purchaseorderdashboard.allNotes"
                  styles={{ alignItems: "center" }}
                  labelStyles={{ margin: 0 }}
                  activeClass="newFilterTab"
                />
              )}
              {!noteThread && (
                <FilterTab
                  onClick={changeFilter}
                  activeTab={activeTab}
                  value={notesFilters.ACTIVITY}
                  label="purchaseorderdashboard.activity"
                  styles={{ alignItems: "center" }}
                  labelStyles={{ margin: 0 }}
                  activeClass="newFilterTab"
                />
              )}
              <FilterTab
                onClick={changeFilter}
                activeTab={activeTab}
                value={notesFilters.NOTES}
                label="purchaseorderdashboard.notes"
                styles={{ alignItems: "center" }}
                labelStyles={{ margin: 0 }}
                activeClass="newFilterTab"
              />
              {!noteThread && hasThreadPermission && (
                <FilterTab
                  onClick={changeFilter}
                  activeTab={activeTab}
                  value={notesFilters.THREADS}
                  label="purchaseorderdashboard.threads"
                  styles={{ alignItems: "center" }}
                  labelStyles={{ margin: 0 }}
                  activeClass="newFilterTab"
                />
              )}
              <div
                className="mentions-filter"
                id="mentions-filter"
                style={{
                  zIndex: backdropBadge ? 1202 : "",
                  background: backdropBadge ? "white" : "",
                  borderRadius: backdropBadge ? 10 : 0,
                }}
              >
                <FilterTab
                  onClick={() =>
                    changeFilter(
                      "MENTIONS",
                      salesOrderActivities,
                      purchaseOrderActivities,
                      shipmentActivities
                    )
                  }
                  activeTab={activeTab}
                  value={notesFilters.MENTIONS}
                  label={"@" + currentUser.displayName}
                  noTransform
                  intlMessages={false}
                  styles={{
                    alignItems: "center",
                    paddingRight: 4,
                  }}
                  labelStyles={{ margin: 0 }}
                  user={currentUser}
                  avatarStyle={{
                    width: 20,
                    height: 20,
                    fontSize: 10,
                    marginRight: 2,
                  }}
                  scope={dbTables.ACTIVITIES}
                  activeClass="newFilterTab"
                />
                {getNumberMention() > 0 && (
                  <div
                    className="badge-notification-number"
                    id="badge-notification-number"
                    onClick={() =>
                      changeFilter(
                        "MENTIONS",
                        salesOrderActivities,
                        purchaseOrderActivities
                      )
                    }
                  >
                    <label className="label-activity-number">
                      {getNumberMention()}
                    </label>
                  </div>
                )}
              </div>
              <div
                className="indicator"
                style={{
                  width: noteThread ? "65%" : "",
                }}
              />
              {noteThread && (
                <CloseIcon
                  style={{
                    width: 16,
                    height: 16,
                    color: colors.primaryDark,
                    cursor: "pointer",
                    marginLeft: 50,
                  }}
                  onClick={() => {
                    setNoteThread(false);
                    setActiveTab(
                      !query.userMention
                        ? storage.activityFilter || notesFilters.ALL
                        : notesFilters.MENTIONS
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="subContainerFilter">
          {noteThread && (
            <div style={{ display: "flex" }}>
              <TooltipTD
                style={{
                  cursor: "pointer",
                  marginRight: 15,
                  display: "flex",
                }}
                label={"Add a note to this thread"}
              >
                <ReplyIcon
                  height={14}
                  style={{
                    cursor: "pointer",
                    marginRight: 12,
                    top: 2,
                    position: "relative",
                  }}
                  onClick={() => handleReplyActivity(noteThread)}
                />
              </TooltipTD>
              <div
                style={{
                  width: 2,
                  height: 20,
                  background: "#E6F0F9",
                  marginRight: 12,
                }}
              />
            </div>
          )}
          <SearchLabel
            cookie={purchaseOrder.id}
            returnQuery={(query) => {
              setQuerySearch(query);
              getActivitiesFromTheBegining();
            }}
            placeholderOnExpand="Search for anything in the Activity Stream"
            cleanSearchBox={cleanSearchBox}
            onDisableCleanSearchBox={() => setCleanSearchBox(false)}
            styles={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            searchLabelId="project-notes-search-box"
            type={SEARCH_BOX_TYPES.NOTES}
          />

          <MultiUserPicker
            list={companyUsers.filter((user) => user.active)}
            selectedOptions={(value) => {
              setStorage(
                "ONLY_FROM",
                value.map((item) => item.id)
              );
              setFilterUsersActivities(value);
              getActivitiesFromTheBegining();
            }}
            purchaseOrderId={purchaseOrder.id}
          />
        </div>
        <CreateNotes
          salesOrder={salesOrder}
          purchaseOrder={purchaseOrder}
          shipment={currentShipment || {}}
          onOpenNote={(value) => {
            if (isReadOnly) {
              handleReadOnlyModal();
              return;
            }
            setCurrentNote({ ...new ActivityNote({ scope: value }) });
            handleOpenModalNotes();
          }}
          showButtons={!noteThread}
        />
      </div>
      <div
        className="content-card-content"
        style={{
          opacity: isLoading && !query.noteId ? 0 : 1,
        }}
      >
        {Object.keys(salesOrder).length > 0 && (
          <RecentActivity
            key={salesOrder.id}
            limitDay={limitDay}
            salesOrder={salesOrder}
            purchaseOrder={purchaseOrder}
            currentShipment={currentShipment}
            companyId={companyId}
            activeTab={activeTab}
            onEditNote={onEditNote}
            onChangeShipment={onChangeShipment}
            openModalNotes={openModalNotes && editingNote}
            currentNote={currentNote}
            purchaseOrders={purchaseOrders}
            salesOrderActivities={getFilterActivitiesByDate({
              activitiesList: activitiesFilteredByPermission({
                activityList: salesOrderActivities,
                orderFieldsToVerify: salesOrderAmountFieldsToVerify,
                permissionEnabled: isAllowed(
                  GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT
                ),
              }),
              currentDate,
              currentUser,
              permissionToSeeLockNote: isAllowed(
                GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_NOTES
              ),
            })}
            purchaseOrderActivities={getFilterActivitiesByDate({
              activitiesList: [
                ...activitiesFilteredByPermission({
                  activityList: purchaseOrderActivities,
                  orderFieldsToVerify: poAmountFieldsToVerify,
                  permissionEnabled: isAllowed(
                    GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT
                  ),
                }),
                ...activitiesFilteredByPermission({
                  activityList: shipmentActivities,
                  orderFieldsToVerify: shipmentAmountFieldsToVerify,
                  permissionEnabled: isAllowed(
                    GENERAL_PERMISSION_VALUE.SHIPMENT_AMOUNT
                  ),
                }),
              ],
              currentDate,
              currentUser,
              permissionToSeeLockNote: isAllowed(
                GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_NOTES
              ),
            })}
            querySearch={querySearch}
            filterUsersActivities={filterUsersActivities}
            companyUsers={companyUsers}
            onChangeCalendar={() => {
              setCurrentDate(creationDay);
            }}
            currentDate={currentDate}
            onChangeDate={({ date, activityId }) => {
              if (moment(date).valueOf() >= moment(oldestDate).valueOf()) {
                const activityToJump = document.getElementById(
                  "activity-backdrop-" + activityId
                );
                activityToJump.scrollIntoView({
                  behavior: "smooth",
                });
              } else {
                changeDate(date);
              }
            }}
            showActivityOptions={showActivityOptions}
            draftNote={getFilterActivitiesByDate({
              activitiesList: activitiesFilteredByPermission({
                activityList: draftNote.filter(
                  (note) =>
                    note.pathToSet.includes(salesOrder.id) ||
                    note.pathToSet.includes(purchaseOrder.id) ||
                    note.pathToSet.includes(currentShipment.id)
                ),
              }),
              currentDate,
              currentUser,
              permissionToSeeLockNote: true,
            })}
            onChangeShowActivityOptions={(value, unReadActivities) => {
              if (unReadActivities) {
                setUnreadActivity({
                  status: false,
                  current: null,
                });
              }
              setShowActivityOptions(value);
            }}
            unreadActivity={unreadActivity}
            onChangeActiveTab={(value) => setActiveTab(value)}
            onReplyActivity={handleReplyActivity}
            onShowThread={handleShowThread}
            noteThread={noteThread}
            hasThreadPermission={hasThreadPermission}
            currentActivitiesList={[
              ...(salesOrderActivities || []),
              ...(purchaseOrderActivities || []),
              ...(shipmentActivities || []),
            ]}
          />
        )}
      </div>
      {(query.fileId || backdropState.type === BACKDROP_TYPE.FOLDER) && (
        <Loader
          style={{
            justifyContent: "center",
            display: "flex",
            zIndex: 1000,
            alignItems: "center",
            position: "absolute",
          }}
          id="activityStreamLoader"
        />
      )}

      {isLoading && !query.noteId && (
        <Loader
          style={{
            justifyContent: "center",
            display: "flex",
            zIndex: 1000,
            alignItems: "center",
            position: "absolute",
          }}
          id="activityStreamLoader"
        />
      )}
      {openModalNotes && (
        <NotesSection
          typeNote={currentNote.scope}
          users={companyUsers.filter(
            (user) => !user.automaticSystemUser && user.active
          )}
          openModalNotes={openModalNotes}
          purchaseOrders={purchaseOrders}
          onCloseModalNotes={({ memoryData }) => {
            if (memoryData && memoryData.path) {
              const { path, ...activityMemory } = memoryData;
              setDoc(doc(firestore, path), {
                ...activityMemory,
                createDate: moment().format("YYYY-MM-DD"),
              });
            }
            setOpenModalNotes(false);
            setEditingNote(false);
            setReplyActivity({});
          }}
          handleSubmit={handleSubmit}
          editingNote={editingNote}
          currentNote={currentNote}
          salesOrder={salesOrder}
          purchaseOrder={purchaseOrder}
          currentShipment={currentShipment}
          currentShipments={shipments}
          cleanReplyActivity={() => {
            setReplyActivity({});
          }}
        />
      )}
    </ProjectNotesContainerStyled>
  );
}

export default ProjectNotesV2;
