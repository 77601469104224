import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const FactoryDocumentsStyled = styled("div")(() => ({
  "&.container": {
    flex: 1,
    height: "100%",
    paddingLeft: 12,
    maxWidth: "calc(100vw - 1110px)",
    minWidth: 350,
    overflow: "auto",
  },
}));

export const FactoryInfoStyled = styled("div")(() => ({
  "&.container": {
    flex: 1.2,
    height: "100%",
    padding: 12,
    paddingTop: 47,
    "& h2": {
      color: colors.primaryDark,
      fontWeight: 700,
      fontSize: 17,
    },
    "& ul": {},
  },
  "& .infoField": {
    padding: 8,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    borderBottom: "1px solid #E5EDF7",
    width: "100%",
    "& span": {
      width: "42%",
      margin: "0 2px",
      paddingLeft: 15,
      fontFamily: "Arial",
      fontSize: 14,
      color: colors.darkGray,
      "&:first-child": {
        textAlign: "right",
      },
      "&:last-child": {
        textAlign: "left",
      },
    },
    "& div": {
      marginLeft: 17,
      marginTop: 8,

      fontWeight: 500,
      fontSize: 15,
      color: colors.primaryDark,
      width: "58%",
    },
  },
  "& .horizontalDivider": {
    height: 1,
    width: "100%",
    backgroundColor: "#E5EDF7",
  },
}));
