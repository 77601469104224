import React from "react";
import AutocompleteUsers from "../TextFields/AutocompleteUsers";
import { sortObjectsBy } from "../../helpers/helpers";
import AutoCompleteUser from "../../api/model/AutoCompleteUser.model";

function ModalPermissionUsers({
  users,
  userSelected,
  handleChange,
  cancellableDeleteCurrentUser = false,
  currentUser,
  notificatorErrorRemoveUser,
}) {
  return (
    <div
      className="modal-permission-users-on-upload"
      style={{
        width: "100%",
      }}
    >
      <AutocompleteUsers
        options={users
          .sort(sortObjectsBy("displayName", false))
          .map((user) => ({
            ...new AutoCompleteUser({
              value: user.id,
              label: user.displayName,
              avatar: user.avatar,
              firstName: user.firstName,
              lastName: user.lastName,
            }),
          }))}
        onChange={handleChange}
        value={userSelected
          .sort(sortObjectsBy("displayName", false))
          .map((user) => ({
            ...new AutoCompleteUser({
              value: user.id,
              label: user.displayName,
              avatar: user.avatar,
              firstName: user.firstName,
              lastName: user.lastName,
            }),
          }))}
        classes={{
          input: "input",
          inputFocused: "input-focused",
          paper: "paper",
          chip: "chip",
        }}
        cancellableDeleteCurrentUser={cancellableDeleteCurrentUser}
        currentUser={currentUser}
        notificatorErrorRemoveUser={notificatorErrorRemoveUser}
      />
    </div>
  );
}

export default ModalPermissionUsers;
