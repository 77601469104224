import AppConfig from "../../constants/AppConfig";
import { styled } from "@mui/material";

export const TemplateStageStyled = styled("div")(() => ({
  "&.templateStageContainer": { width: "100%" },
  "& .header": {
    width: "100%",
    backgroundColor: AppConfig.themeColors.tableHeaderColor,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: 13,
    fontWeight: "bold",
    borderTop: `1px solid ${AppConfig.themeColors.dividerColor}`,
    borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    "& :first-child": {
      flex: 1,
    },
  },

  "& .content": {
    margin: "20px 16px",
    "& li": {
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      padding: 16,
    },
  },
  "& .add": {
    fontSize: 12,
    backgroundColor: "transparent",
    fontWeight: "bold",
    justifySelf: "flex-end",
    color: "#25486D",
    "&::before": {
      content: '"+"',
      marginRight: 2,
    },
  },
  "& .taskSelect": {
    width: 120,
  },
}));
