import { increment, updateDoc } from "firebase/firestore";
import { dbTables } from "../../api/types/dbTables";
import { activityScopes, activityType } from "../../helpers/activitiesStream";
import { NOTES_SCOPE, notesFilters } from "../../helpers/constants";
import { purchaseOrderTriggerTypes } from "../../helpers/purchaseOrder";
import { pseudoRandom } from "../../hooks";
import { getTextFromType, sortObjectsBy } from "../../helpers/helpers";
import moment, { now } from "moment";

export const getOrderUnreadUser = ({ order = [], userId = "" }) =>
  order.find((unreadActivity) => unreadActivity.userId === userId) || {};

export const checkOrderMention = (order = {}, userId = "") => {
  if (order.userMentions && order.userMentions[userId]) {
    return order.userMentions[userId];
  }
  return 0;
};

export const isActivityNoteMention = (activity = {}, userId = "") => {
  const userMentions = activity.userMentions || {};
  if (
    userMentions[userId] === undefined ||
    activity.type !== activityType.NOTE
  ) {
    return false;
  }
  return userMentions[userId] === false;
};

export const getTotalThreads = ({
  noteThread,
  salesOrderActivities = [],
  purchaseOrderActivities = [],
  shipmentActivities = [],
}) => {
  if (!noteThread) {
    return 0;
  }
  let activities = [];
  switch (noteThread.scope) {
    case activityScopes.SALES_ORDER:
    case activityScopes.QUOTE:
      activities = salesOrderActivities;
      break;
    case activityScopes.PURCHASE_ORDER:
    case activityScopes.PURCHASE_QUOTE:
      activities = purchaseOrderActivities;
      break;
    default:
      activities = shipmentActivities;
      break;
  }
  const replyId = noteThread.threadId || noteThread.id;
  const threadActivities = activities.filter(
    (activity) => activity.threadId === replyId
  );
  const numberOfReplies = noteThread.numberOfReplies || 0;
  return Math.max(threadActivities.length, numberOfReplies);
};

export const getSeedByScope = ({
  typeScope,
  newActivity,
  purchaseOrder,
  salesOrder,
  currentShipment,
}) => {
  let currentScope;
  let seedArray;
  let currentNumber;
  let currentOrder;
  if (typeScope === NOTES_SCOPE.PURCHASE_ORDER) {
    newActivity.scope = purchaseOrder.type;
    newActivity.purchaseOrderId = purchaseOrder.id;
    currentScope = activityScopes.PURCHASE_ORDER;
    seedArray = pseudoRandom(purchaseOrder.number);
    currentNumber = purchaseOrder.iconIndex || 0;
    currentOrder = purchaseOrder;
  } else if (typeScope === NOTES_SCOPE.SALES_ORDER) {
    newActivity.scope = salesOrder.type;
    newActivity.purchaseOrderId = purchaseOrder.id;
    currentScope = activityScopes.SALES_ORDER;
    seedArray = pseudoRandom(salesOrder.number);
    currentNumber = salesOrder.iconIndex || 0;
    currentOrder = salesOrder;
  } else if (typeScope === NOTES_SCOPE.SHIPMENT) {
    currentScope = activityScopes.SHIPMENT;
    newActivity.scope = activityScopes.SHIPMENT;
    newActivity.shipmentId = currentShipment.id;
    newActivity.purchaseOrderId = purchaseOrder.id;
    seedArray = pseudoRandom(currentShipment.number);
    currentNumber = currentShipment.iconIndex || 0;
    currentOrder = currentShipment;
  }
  currentNumber = currentNumber === 160 ? 0 : currentNumber;
  return { currentScope, seedArray, currentNumber, currentOrder };
};

export const getPathByScope = ({
  currentScope,
  companyId,
  currentOrder,
  activity,
}) => {
  if (currentScope === activityScopes.SALES_ORDER) {
    return `${companyId}/${dbTables.SALES_ORDERS}/${currentOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`;
  } else if (currentScope === activityScopes.PURCHASE_ORDER) {
    return `${companyId}/${dbTables.PURCHASE_ORDERS}/${currentOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`;
  } else if (currentScope === activityScopes.SHIPMENT) {
    return `${companyId}/${dbTables.SHIPMENTS}/${currentOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`;
  }
};

export const checkUserMention = ({
  mentionsList = {},
  newMentions = {},
  editing,
  currentNote = {},
}) => {
  const currentMention = currentNote.userMentions;
  let addUserNotification = 1;
  const userMentionsCpy = mentionsList;
  if (!editing) {
    Object.keys(newMentions).forEach((key) => {
      userMentionsCpy[key] = (userMentionsCpy[key] || 0) + addUserNotification;
    });
  } else {
    const oldMentionList = Object.keys(currentMention);
    const newMentionsList = Object.keys(newMentions);
    const newUsers = newMentionsList.filter(
      (id) => !oldMentionList.includes(id)
    );
    const usersToDelete = oldMentionList.filter(
      (id) => !newMentionsList.includes(id)
    );
    newUsers.forEach(
      (id) => (userMentionsCpy[id] = (userMentionsCpy[id] || 0) + 1)
    );
    usersToDelete.forEach(
      (id) => (userMentionsCpy[id] = (userMentionsCpy[id] || 0) - 1)
    );
    Object.keys(userMentionsCpy).forEach((id) => {
      if (userMentionsCpy[id] <= 0) {
        delete userMentionsCpy[id];
      }
    });
  }
  return userMentionsCpy || {};
};

export const updateUserMention = ({
  iconIndex,
  currentOrder,
  userMentions,
  editing,
  currentNote,
}) => {
  const incrementValue = iconIndex ? 0 : 1;
  const newUserMention = checkUserMention({
    mentionsList: { ...currentOrder.userMentions },
    newMentions: userMentions,
    editing,
    currentNote,
  });
  if (currentOrder.ref) {
    updateDoc(currentOrder.ref, {
      userMentions: newUserMention,
      triggerType: purchaseOrderTriggerTypes.DISMISS_TRIGGER,
      iconIndex:
        !currentOrder.iconIndex || currentOrder.iconIndex === 160
          ? 1
          : increment(incrementValue),
    });
  }
};

export function getTodayActivities(activities) {
  const today = moment(now()).startOf("day");
  const todayActivities = activities.filter((activity) =>
    moment(activity.creationDate).isSame(today, "d")
  );
  return todayActivities;
}

export function getYesterdayActivities(activities) {
  const yesterday = moment()
    .subtract(1, "days")
    .startOf("day");
  const yesterdayActivities = activities.filter((activity) =>
    moment(activity.creationDate).isSame(yesterday, "day")
  );
  return yesterdayActivities;
}

export function getRestActivities(activities) {
  const today = moment(now()).startOf("day");
  const yesterday = moment(now())
    .subtract(1, "days")
    .startOf("yesterday");
  const restActivities = activities.filter(
    (activity) =>
      !moment(activity.creationDate).isSame(today, "d") &&
      !moment(activity.creationDate).isSame(yesterday, "d")
  );
  return restActivities;
}

export const getActivitiesByDate = ({
  allActivities,
  draftNote = [],
  salesOrderActivities,
  purchaseOrderActivities,
  querySearch = "",
  noteThread,
  activeTab,
  currentUser,
  currentActivitiesList,
  performMark = () => {},
}) => {
  if (allActivities.length !== 0) {
    const draftIds = draftNote.map((note) => note.id);
    let activities = [
      ...salesOrderActivities,
      ...purchaseOrderActivities,
    ].filter((activity) => !draftIds.includes(activity.id));
    activities = [...activities, ...draftNote];
    activities = activities.sort(sortObjectsBy("creationDate", true));
    if (querySearch) {
      activities = activities.filter(
        (activity) =>
          activity.detail.toLowerCase().includes(querySearch.toLowerCase()) ||
          getTextFromType(activity.type)
            .toLowerCase()
            .includes(querySearch.toLowerCase())
      );
    }
    if (noteThread) {
      activities = activities.filter(
        (activity) =>
          activity.threadId === noteThread.id || activity.id === noteThread.id
      );
    }
    activities = activities.filter((activity) => !activity.deleted);
    let currentActivities = [];
    if (activeTab === notesFilters.ALL) {
      activities.forEach((activity) => {
        currentActivities.push(activity);
      });
    } else if (activeTab === notesFilters.NOTES) {
      activities.forEach((activity) => {
        if (activity.type === activityType.NOTE) {
          currentActivities.push(activity);
        }
      });
    } else if (activeTab === notesFilters.ACTIVITY) {
      activities.forEach((activity) => {
        if (activity.type !== activityType.NOTE) {
          currentActivities.push(activity);
        }
      });
    } else if (activeTab === notesFilters.MENTIONS) {
      activities.forEach((activity) => {
        const userMentions = activity.userMentions || {};
        if (currentUser.id in userMentions) {
          currentActivities.push(activity);
        }
      });
    } else if (activeTab === notesFilters.THREADS) {
      activities.forEach((activity) => {
        if (activity.numberOfReplies) {
          currentActivities.push(activity);
        }
      });
    }
    currentActivities = currentActivities.map((activity) => {
      if (activity.numberOfReplies) {
        const childrenActivities = currentActivitiesList.filter(
          (childActivity) => childActivity.threadId === activity.id
        );
        return {
          ...activity,
          numberOfReplies: Math.max(
            activity.numberOfReplies,
            childrenActivities.length
          ),
        };
      }
      return activity;
    });
    let currentActivitiesObj = {};
    const todayActivities = getTodayActivities(currentActivities);
    const yesterdayActivities = getYesterdayActivities(currentActivities);
    const restActivities = getRestActivities(currentActivities);
    currentActivitiesObj["Today"] = todayActivities;
    currentActivitiesObj["Yesterday"] = yesterdayActivities;
    restActivities.forEach((activity) => {
      const dateKey = moment(activity.creationDate).format("MMM DD");
      if (currentActivitiesObj[dateKey]) {
        currentActivitiesObj[dateKey] = [
          ...currentActivitiesObj[dateKey],
          activity,
        ];
      } else {
        currentActivitiesObj[dateKey] = [activity];
      }
    });
    performMark(querySearch);
    return currentActivitiesObj;
  }
  return {};
};
