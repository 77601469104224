import { Button } from "@mui/material";
import React from "react";
import { getCustomerName } from "../../helpers/helpers";
import { getOrderDiamond } from "../../helpers/timelineCalendar";
import { SalesOrderBadgeContainerStyled } from "./styles";
import { SALES_ORDER_STATUS } from "../../helpers/constants";
import { VoidLabel } from "../../helpers/iconSvgPath";

function SalesOrderBadged({
  salesOrder,
  purchaseOrders,
  isSelectedAllPOs,
  onSelectAllPOs,
  customers = [],
  onSubmitTimeLineModal,
  onCancelTimeLineModal,
}) {
  return (
    <SalesOrderBadgeContainerStyled className="salesOrderBadgedWrapper">
      <div className={"salesOrderBadgedContainer"}>
        {getOrderDiamond({ order: salesOrder, styles: {} })}
        <span>
          {salesOrder.status === SALES_ORDER_STATUS.VOIDED && <VoidLabel />}
          {salesOrder.number} -{" "}
          {getCustomerName(customers, salesOrder.customerId)}
        </span>
        <Button
          classes={{ root: "buttonRoot", label: "buttonLabel" }}
          onClick={() => onSelectAllPOs(!isSelectedAllPOs)}
        >
          Select {isSelectedAllPOs ? "none" : "all"} POs (
          {purchaseOrders.length})
        </Button>
      </div>
      <div className={"salesOrderButtonContainer"}>
        <Button
          className="sobc-button-save-changes"
          onClick={onSubmitTimeLineModal}
          disabled={salesOrder.status === SALES_ORDER_STATUS.VOIDED}
        >
          Save Changes
        </Button>
        <Button
          className="sobc-button-cancel"
          onClick={() => {
            onCancelTimeLineModal({
              projectVoided: salesOrder.status === SALES_ORDER_STATUS.VOIDED,
            });
          }}
        >
          Cancel
        </Button>
      </div>
    </SalesOrderBadgeContainerStyled>
  );
}

export default SalesOrderBadged;
