import React from "react";
import { orderType } from "../../../helpers/salesOrder";
import { cx } from "@emotion/css";
import { Tab } from "@mui/material";
import { DocumentNoteTabsStyled } from "./styles";

function NoteDocumentsTab({
  onChange = () => {},
  value = 0,
  currentPurchaseOrder,
}) {
  return (
    <DocumentNoteTabsStyled
      value={value}
      variant="fullWidth"
      textColor="primary"
      className={"customTabs"}
      style={{
        margin: 0,
        position: "relative",
        bottom: -11,
      }}
      classes={{
        indicator: "custom-tab-indicator",
        flexContainer: "custom-tabs-container",
      }}
      onChange={(e, value) => onChange(value)}
    >
      <Tab
        classes={{
          selected: "active" + orderType.SALES_ORDER,
          wrapper: "wrapped",
        }}
        className={cx("custom-tab-note", orderType.SALES_ORDER)}
        label={`Sales Order`}
      />
      <Tab
        classes={{
          selected: "active" + orderType.PURCHASE_ORDER,
          wrapper: "wrapped",
        }}
        disabled={!currentPurchaseOrder}
        className={cx("custom-tab-note", orderType.PURCHASE_ORDER)}
        label={<span>{`${currentPurchaseOrder.number || ""}`}</span>}
      />
    </DocumentNoteTabsStyled>
  );
}

export default NoteDocumentsTab;
