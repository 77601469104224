import { Button, styled } from "@mui/material";

export const ButtonReset = styled(Button)(() => ({
  "&.root": {
    display: "flex",
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "13px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    height: "40px",
    width: "120px",
    backgroundColor: "rgba(255, 51, 102, 0.07)",
    border: "1px solid #FF7495 !important",
    boxSizing: "border-box",
    borderRadius: "4px",
    textTransform: "uppercase",
    color: "#25486D",
    position: "relative",
    fontWeight: 400,
    padding: "14px 16px",
    "@media screen and (max-width: 1425px)": {
      minWidth: 50,
      width: 50,
      maxWidth: 50,
    },
    "&:hover": {
      backgroundColor: "rgba(255, 51, 102, 0.2)",
    },
    "&:active": {
      transform: "scale(1)",
    },
    "& .MuiTouchRipple-child": {
      backgroundColor: "rgba(255,51,102,0.4)",
    },
  },
}));
