import React from "react";
import { ViewOptionsContainerStyled, ViewOptionsPopoverStyled } from "./styles";
import switchIcon from "../../assets/flag-icons/switch-filled-icon.png";
import { collapsedTaskTableColumns } from "../../helpers/constants";
import TooltipTD from "../Tooltip/TooltipTD";
import { Button, Checkbox } from "@mui/material";

function ViewOptions({
  viewOptionsArray,
  tableSize,
  styles = {},
  onChangeViewOptions,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const viewOptionItem = ({ option }) => {
    return (
      <div key={option.label} className="view-option-item-container">
        <Checkbox
          classes={{ root: "checkboxRoot" }}
          checked={option.enabled}
          onChange={() =>
            onChangeViewOptions({ ...option, enabled: !option.enabled })
          }
        />
        <span
          onClick={() =>
            onChangeViewOptions({ ...option, enabled: !option.enabled })
          }
        >
          {option.label}
        </span>
      </div>
    );
  };

  return (
    <ViewOptionsContainerStyled className="viewOptionsContainer">
      <Button
        className={"buttonViewOptions"}
        aria-describedby="simple-popover"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ ...styles }}
      >
        <TooltipTD label={"View options"}>
          <img
            style={
              tableSize !== collapsedTaskTableColumns ? { marginRight: 8 } : {}
            }
            src={switchIcon}
            alt="switch"
          />
        </TooltipTD>
        {tableSize !== collapsedTaskTableColumns && <span>View Options</span>}
      </Button>
      {open && (
        <ViewOptionsPopoverStyled
          id="simple-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "viewOptionsPopover" }}
          className="viewOptionsPopoverContainer"
        >
          <div className="view-options-left-container">
            {viewOptionsArray
              .filter((option) => option.position === "left")
              .map((option) => viewOptionItem({ option }))}
          </div>
          <div className="view-options-right-container">
            {viewOptionsArray
              .filter((option) => option.position === "right")
              .map((option) => viewOptionItem({ option }))}
          </div>
        </ViewOptionsPopoverStyled>
      )}
    </ViewOptionsContainerStyled>
  );
}

export default ViewOptions;
