import React, { useEffect, useRef } from "react";
import { getRandomId, getTypeFile } from "../../helpers/helpers";
import { now } from "moment";
// import { dbTables } from "../../api/types/dbTables";
import { getDocumentPath } from "../../helpers/documents";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable } from "firebase/storage";
import { TYPE_OF_FILE } from "../../helpers/constants";

function handleGetFileName(file, typeUpload, documents, currentDocument) {
  const { lastModifiedDate, size } = file;
  const lastModified = now();
  const type = getTypeFile(file.name);
  let documentVersionId = typeUpload.fileVersionId;
  let version = 1;
  let name = file.name;
  if (
    typeUpload.type === "uploadNewVersionFile" ||
    typeUpload.type === "uploadPlaceholder"
  ) {
    let nameWithoutExtension = "";
    const elements = currentDocument.name.split(".");
    let nameWithExtension = "";
    if (elements.length === 1) {
      nameWithExtension = currentDocument.name + "." + type;
    } else {
      elements.forEach((value, index) => {
        if (index !== elements.length - 1) {
          nameWithoutExtension = nameWithoutExtension + value + ".";
        }
      });
      nameWithExtension = nameWithoutExtension + type;
    }
    name = nameWithExtension;
    documentVersionId = currentDocument.id;
    version = currentDocument.version + 1;
  } else {
    documents.forEach((element) => {
      if (element.name === file.name) {
        documentVersionId = element.id;
        version = element.version + 1;
        name = file.name;
      }
    });
  }
  return {
    lastModified,
    size,
    type,
    lastModifiedDate,
    documentVersionId,
    version,
    name,
    documentId: typeUpload.fileId,
    scope: currentDocument.scope,
    shipmentId: currentDocument.shipmentId || "",
  };
}

function UploadFiles({
  id,
  handleSuccess = () => {},
  companyID,
  mainTable,
  mainTableID,
  documents,
  onRef,
  currentDocument = {},
  dbTable,
  onProgressUpload = () => {},
  onOpenModal = () => {},
  selectedDocumentTab = "sdf",
  typeUpload,
  handleSendFile,
  handleFileNameError = () => {},
  allowMultipleFiles,
  handleFiles,
}) {
  const inputRef = useRef(null);
  useEffect(() => {
    onRef(inputRef.current);
  }, []);

  async function handleChange(event) {
    if (handleFiles) {
      handleFiles(event, true);
      return;
    }
    let files = event.target.files;
    files = Array.from(files).sort((a, b) => {
      if (a.name < b.name) {
        return 1;
      } else if (a.name > b.name) {
        return -1;
      } else {
        return 0;
      }
    });
   
    const originalFile = [];
    const filesData = [];
    const filesDataId = [];
    const maxFile = allowMultipleFiles ? files.length : 1;
    try {
      for (let i = 0; i < maxFile; i++) {
        const fileData = handleGetFileName(
          files[i],
          typeUpload,
          documents,
          currentDocument
        );
        if (!fileData.name.includes("/")) {
          originalFile.push(files[i]);
          filesData.push(fileData);
          filesDataId.push({
            documentId: getRandomId(),
            documentVersionId: getRandomId(),
          });
        }
      }
      if (filesData.length === 0) {
        handleFileNameError();
      }
      handleSendFile(filesData, true);
      onOpenModal(true);
      for (let j = 0; j < filesData.length; j++) {
        const fileData = filesData[j];
        const currentId = filesDataId[j];
        const path = getDocumentPath({
          companyId: companyID,
          mainTable,
          mainTableId: mainTableID,
          dbTable,
          documentId: currentId.documentVersionId,
          documentVersionId: currentId.documentId,
          scope: currentDocument.scope,
          shipmentId: currentDocument.shipmentId || "",
        });
        const storageRef = ref(storage, `${path}/${fileData.name}`);
        const task = uploadBytesResumable(storageRef, originalFile[j]);
        task.on(
          "state_changed",
          (snapshot) => {
            const percent =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            onProgressUpload(percent, task, fileData.name);
          },
          (error) => {
            console.log(error);
          },
          () => {
            handleSuccess(fileData, {
              path,
              documentVersionId: currentId.documentVersionId,
              documentId: currentId.documentId,
            });
          }
        );
        await task;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <input
      id={id}
      ref={inputRef}
      type="file"
      onChange={(event) => handleChange(event)}
      style={{ display: "none" }}
      multiple={
        allowMultipleFiles &&
        (!currentDocument.id || currentDocument.type === TYPE_OF_FILE.FOLDER)
      }
      // disabled={is}
      // accept=".xlsx, .xls, .doc, .odt, .docx, .ppt, .pptx, .txt, .pdf, image/jpeg, image/jpg, image/png"
      // size="20MB"
    />
  );
}

export default UploadFiles;
