import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ORDER_DASHBOARD_ACTUAL_VIEW,
  ORDER_DASHBOARD_SETUP,
} from "../../actions/types";
import {
  useCompanyId,
  useDataCustomers,
  useDataSOnShipment,
  useQueryParams,
} from "../../hooks";
import { useOrderDashboardActualView, useUser } from "../../hooks/user";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "./sections/helpers";
import OrderDashboardHeader from "./sections/OrderDashboardHeader";
import PurchaseOrderMetadata from "./sections/PurchaseOrderMetadata";
import PurchaseOrderNavigation from "./sections/PurchaseOrderNavigation";
import SalesOrderMetadata from "./sections/salesOrderMetadata";
import ShipmentMetadata from "./sections/ShipmentMetadata";
import ShipmentNavigation from "./sections/ShipmentNavigation";
import ThreePanelSection from "./sections/ThreePanelSection";
import { OrderDashboardContainerStyled } from "./styles";
import {
  useOrderDashboardSetup,
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
} from "../../hooks/salesOrders";
import { setGlobalBackdrop } from "../../actions/DataActions";
import {
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
  notesFilters,
} from "../../helpers/constants";
import {
  getDashboardSearchPath,
  getPurchaseOrders,
  sortObjectsBy,
} from "../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import Loader from "../General/Loader";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore, performanceFirebase } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import { verifyPermission } from "../../hooks/permissions";
import AttentionModal from "../Modal/AttentionModal";
import { updateShipmentParams } from "../../helpers/orderDashboardRefactored";
import { colors } from "../../assets/jss/variables";
import { cx } from "@emotion/css";
import { trace } from "firebase/performance";

const CAN_SEE_PO_NAVIGATION = [
  ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
  ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
];
const CAN_SEE_SHIPMENT_NAVIGATION = [
  ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
  ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
];

const SALES_ORDER_INFO_CONTAINER_CLASS = {
  [ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION]: "three-panel-rendering",
  [ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT]: "shipment-rendering",
  [ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER]: "sales-order-rendering",
  [ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER]: "purchase-order-rendering",
};

function OrderDashboardView() {
  const actualView = useOrderDashboardActualView();
  const user = useUser();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const purchaseOrder = useCurrentPurchaseOrder();
  const salesOrder = useCurrentSalesOrder({});
  const [loading, setLoading] = useState(false);
  const [currentParam, setCurrentParam] = useState(false);
  const [searchShipment, setSearchShipment] = useState(false);
  const navigate = useNavigate();
  const [modalAttention, setModalAttention] = useState({
    open: false,
    description: <React.Fragment></React.Fragment>,
  });
  const { purchaseOrderId } = useOrderDashboardSetup();

  const setBackdrop = useCallback(({ id, type }) =>
    setGlobalBackdrop({
      id,
      type,
      isOpen: true,
    })(dispatch)
  );

  useEffect(() => {
    const permissions = verifyPermission({
      user: user,
      permissionToCheck: [
        salesOrder.customerId,
        GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
      ],
    });

    if (salesOrder.customerId && !permissions) {
      setModalAttention({
        open: true,
        description: (
          <React.Fragment>
            You do not have permission to see the customer
          </React.Fragment>
        ),
      });
    }
  }, [user.permissions, user.role, salesOrder.customerId]);

  useDataSOnShipment({
    purchaseOrder: purchaseOrder,
    companyId: companyId,
  });
  useDataCustomers({
    companyId: companyId,
    salesOrder: salesOrder,
  });

  function lookForDocumentToHighlight({
    taskId,
    noteId,
    queryParams = {},
    traceStop = () => {},
  }) {
    const TWO_SECONDS = 1500;
    const MAX_ATTEMPTS = 20;
    let elementId;
    let type;
    let block;

    if (taskId) {
      elementId = `${taskId}task-item`;
      type = BACKDROP_TYPE.TASK;
      block = "start";
    } else if (noteId) {
      elementId = "activity-backdrop-" + noteId;
      type = BACKDROP_TYPE.NOTE;
      localStorage.setItem(
        queryParams.purchaseOrderId,
        JSON.stringify({ activityFilter: notesFilters.NOTES })
      );
      block = "center";
    }

    let counter = 0;
    let timer;

    function scrollToElementAndCleanup() {
      const newElementToFocus = document.getElementById(elementId);
      if (newElementToFocus) {
        newElementToFocus.scrollIntoView({
          behavior: "smooth",
          block,
        });
        setBackdrop({ id: elementId, type });
        traceStop();
        clearTimeout(timer);
        clearInterval(timer);
        setCurrentParam(false);
        navigate(
          getDashboardSearchPath({
            ...queryParams,
            fileId: false,
            noteId: false,
            taskId: false,
          }),
          { replace: true }
        );

        setTimeout(() => {
          setSearchShipment(!searchShipment);
        }, 3000);
      } else {
        counter++;

        if (counter > MAX_ATTEMPTS) {
          clearTimeout(timer);
          clearInterval(timer);
          setCurrentParam(false);
          navigate(
            getDashboardSearchPath({
              ...queryParams,
              fileId: false,
              noteId: false,
              taskId: false,
            }),
            { replace: true }
          );
          traceStop();
          setTimeout(() => {
            setSearchShipment(!searchShipment);
          }, 3000);
        }
      }
    }
    timer = setInterval(scrollToElementAndCleanup, TWO_SECONDS);
  }

  const customerPermission = verifyPermission({
    user: user,
    permissionToCheck: [
      salesOrder.customerId,
      GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
    ],
  });

  const vendorPermission = verifyPermission({
    user: user,
    permissionToCheck: [
      purchaseOrder.factoryId,
      GENERAL_PERMISSION_VALUE.ALL_VENDORS,
    ],
  });

  async function getSalesOrders({ salesOrderIds, companyId, user }) {
    const salesOrdersSnapDB = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}`
        ),
        where("id", "in", salesOrderIds)
      )
    );
    let salesOrdersDB = salesOrdersSnapDB.docs.map((doc) => doc.data());
    salesOrdersDB = salesOrdersDB.filter((so) => {
      if (!so) return false;
      const hasPermission = verifyPermission({
        user: user,
        permissionToCheck: [
          so.customerId,
          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
        ],
      });
      return hasPermission;
    });
    return salesOrdersDB;
  }

  async function getShipments({ shipmentIds = [], companyId, user }) {
    const shipmentPromisses = [];
    shipmentIds.forEach((shipmentId) => {
      shipmentPromisses.push(
        getDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${shipmentId}`
          )
        )
      );
    });
    const shipmentsSnapDB = await Promise.all(shipmentPromisses);
    let shipmentsDB = shipmentsSnapDB.map((doc) => doc.data());
    console.log("SHIPMENTS DB: ", shipmentsDB);

    shipmentsDB = shipmentsDB.filter((shipment) => {
      if (!shipment) return false;
      const hasPermission = verifyPermission({
        user: user,
        permissionToCheck: [
          shipment.customerId,
          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
        ],
      });
      return hasPermission;
    });
    return shipmentsDB;
  }

  async function getDocumentAndVerifyPermission({
    collection,
    documentId,
    user,
    companyId,
    forCustomer = false,
    modalDescription = "",
  }) {
    const docSnapDB = await getDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${collection}/${documentId}`
      )
    );
    const docDB = docSnapDB.data();
    const hasPermission = verifyPermission({
      user: user,
      permissionToCheck: [
        forCustomer ? docDB.customerId : docDB.factoryId,
        forCustomer
          ? GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS
          : GENERAL_PERMISSION_VALUE.ALL_VENDORS,
      ],
    });

    if (!hasPermission) {
      console.log("NO PERMISSION");
      setModalAttention({
        open: true,
        description: <React.Fragment>{modalDescription}</React.Fragment>,
      });
      return null;
    }
    return docDB;
  }

  async function toVerifyFunc({ queryParams = {} }) {
    const { documentId, toVerify } = queryParams;
    if (toVerify === dbTables.SHIPMENTS) {
      const shipmentDB = await getDocumentAndVerifyPermission({
        collection: dbTables.SHIPMENTS,
        documentId,
        user,
        companyId,
        forCustomer: true,
        modalDescription: " You do not have permission to see this customer",
      });
      console.log("SHIPMENT DB: ", shipmentDB);
      if (!shipmentDB) return;

      const { purchaseOrderIds } = shipmentDB;
      const purchaseOrdersDB = await getPurchaseOrders({
        purchaseOrderIds,
        companyId,
        user,
      });
      if (purchaseOrdersDB.length === 0) {
        console.log("THERE IS NO PURCHASE ORDERS WITH PERMISSION");
        setModalAttention({
          open: true,
          description: (
            <React.Fragment>
              you do not have permission to see any vendors in this project
            </React.Fragment>
          ),
        });
        return;
      }
      const firstPO = purchaseOrdersDB.sort(sortObjectsBy("number", false))[0];
      console.log("FIRST PO: ", firstPO);
      const { salesOrderIds } = firstPO;
      // get all sos
      const salesOrdersDB = await getSalesOrders({
        salesOrderIds,
        companyId,
        user,
      });
      if (salesOrdersDB.length === 0) {
        console.log("THERE IS NO SALES ORDERS WITH PERMISSION");
        setModalAttention({
          open: true,
          description: (
            <React.Fragment>
              You do not have permission to see this customer
            </React.Fragment>
          ),
        });
      }
      const firstSO = salesOrdersDB.sort(sortObjectsBy("number", false))[0];
      console.log("FIRST SO: ", firstSO);
      navigate(
        getDashboardSearchPath({
          ...queryParams,
          salesOrderId: firstSO.id,
          purchaseOrderId: firstPO.id,
          shipmentId: shipmentDB.id,
        }),
        { replace: true }
      );
      setLoading(false);
    } else if (toVerify === dbTables.PURCHASE_ORDERS) {
      const purchaseOrderDB = await getDocumentAndVerifyPermission({
        collection: dbTables.PURCHASE_ORDERS,
        documentId,
        user,
        companyId,
        forCustomer: false,
        modalDescription: " You do not have permission to see the vendor",
      });
      if (!purchaseOrderDB) return;
      const { salesOrderIds, shipmentIds } = purchaseOrderDB;
      // get all sos
      const salesOrdersDB = await getSalesOrders({
        salesOrderIds,
        companyId,
        user,
      });

      if (salesOrdersDB.length === 0) {
        console.log("THERE IS NO SALES ORDERS WITH PERMISSION");
        setModalAttention({
          open: true,
          description: (
            <React.Fragment>
              You do not have permission to see this customer
            </React.Fragment>
          ),
        });
      }
      const firstSO = salesOrdersDB.sort(sortObjectsBy("number", false))[0];

      const shipmentsDB = await getShipments({
        companyId,
        shipmentIds,
        user,
      });
      if (shipmentsDB.length === 0) {
        navigate(
          getDashboardSearchPath({
            ...queryParams,
            salesOrderId: firstSO.id,
            purchaseOrderId: purchaseOrderDB.id,
            shipmentId: "",
          }),

          { replace: true }
        );
      } else {
        const firstShipment = shipmentsDB.sort(
          sortObjectsBy("number", false)
        )[0];
        console.log("FIRST SHIPMENT: ", firstSO);
        navigate(
          getDashboardSearchPath({
            ...queryParams,
            salesOrderId: firstSO.id,
            purchaseOrderId: purchaseOrderDB.id,
            shipmentId: firstShipment.id,
          }),
          { replace: true }
        );
      }
      setLoading(false);
    } else if (toVerify === dbTables.SALES_ORDERS) {
      const salesOrderDB = await getDocumentAndVerifyPermission({
        collection: dbTables.SALES_ORDERS,
        documentId,
        user,
        companyId,
        forCustomer: true,
        modalDescription: " You do not have permission to see this customer",
      });
      if (!salesOrderDB) return;

      const { purchaseOrderIds } = salesOrderDB;
      // get all pos
      const purchaseOrdersDB = await getPurchaseOrders({
        purchaseOrderIds,
        companyId,
        user,
      });

      if (purchaseOrdersDB.length === 0) {
        console.log("THERE IS NO PURCHASE ORDERS WITH PERMISSION");
        setModalAttention({
          open: true,
          description: (
            <React.Fragment>
              You do not have permission to see any vendor
            </React.Fragment>
          ),
        });
      }
      const firstPO = purchaseOrdersDB.sort(sortObjectsBy("number", false))[0];
      const { shipmentIds } = firstPO;
      const shipmentsDB = await getShipments({
        companyId,
        shipmentIds,
        user,
      });
      if (shipmentsDB.length === 0) {
        navigate(
          getDashboardSearchPath({
            ...queryParams,
            salesOrderId: salesOrderDB.id,
            purchaseOrderId: firstPO.id,
            shipmentId: "",
          }),
          { replace: true }
        );
      } else {
        const firstShipment = shipmentsDB.sort(
          sortObjectsBy("number", false)
        )[0];

        navigate(
          getDashboardSearchPath({
            ...queryParams,
            salesOrderId: salesOrderDB.id,
            purchaseOrderId: firstPO.id,
            shipmentId: firstShipment.id,
          })
        );
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    const {
      section,
      salesOrderId,
      purchaseOrderId,
      shipmentId,
      noteId,
      taskId,
      toVerify,
    } = queryParams;
    if (toVerify) {
      setLoading(true);
      toVerifyFunc({ queryParams });
      return;
    } else if (!salesOrderId && !purchaseOrderId && !shipmentId) {
      return;
    } else {
      dispatch({
        type: ORDER_DASHBOARD_SETUP,
        payload: queryParams,
      });
      const currentAditionalParam = noteId || taskId;
      const sameAditionalParam = currentAditionalParam === currentParam;
      if (currentAditionalParam && !sameAditionalParam) {
        let traceInstance = trace(
          performanceFirebase,
          noteId
            ? `Backdrop_${BACKDROP_TYPE.NOTE}`
            : `Backdrop_${BACKDROP_TYPE.TASK}`
        );
        traceInstance.start();
        setCurrentParam(currentAditionalParam);
        lookForDocumentToHighlight({
          noteId,
          taskId,
          queryParams,
          traceStop: () => {
            try {
              traceInstance.stop();
            } catch (error) {
              console.log("traceError", error);
            }
          },
        });
      }
      if (section) {
        dispatch({
          type: ORDER_DASHBOARD_ACTUAL_VIEW,
          payload: section ? section : "",
        });
      }
    }
  }, [queryParams]);

  useEffect(() => {
    const { shipmentId, taskId, noteId } = queryParams;
    if (taskId || noteId) {
      return;
    }
    const newQueryParams = updateShipmentParams({
      shipmentId,
      purchaseOrder,
      queryParams,
    });
    if (newQueryParams) {
      navigate(newQueryParams, { replace: true });
    }
  }, [purchaseOrder.shipmentIds, searchShipment]);

  return (
    actualView && (
      <React.Fragment>
        <OrderDashboardHeader
          customerPermission={customerPermission}
          vendorPermission={vendorPermission}
          user={user}
        />

        <OrderDashboardContainerStyled
          className={"orderDashboardContainer"}
          id="orderDashboardContainer-1"
        >
          {loading && <Loader />}
          {modalAttention.open && (
            <AttentionModal
              isOpen={modalAttention.open}
              description={modalAttention.description}
              onClick={() => {
                navigate("/app/tasks");
              }}
            />
          )}

          <div
            className={cx(
              "sales-order-info-container",
              SALES_ORDER_INFO_CONTAINER_CLASS[actualView]
            )}
          >
            <div className="min-height-metadata-content-so">
              {actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER && (
                <SalesOrderMetadata
                  salesOrder={salesOrder}
                  customerPermission={customerPermission}
                  user={user}
                />
              )}
            </div>

            {customerPermission && (
              <React.Fragment>
                {CAN_SEE_PO_NAVIGATION.includes(actualView) && (
                  <PurchaseOrderNavigation />
                )}
                <div
                  className={cx(
                    "purchase-order-info-container",
                    actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER
                      ? "purchase-order-info-container-visible"
                      : "purchase-order-info-container-hidden"
                  )}
                  style={!purchaseOrderId ? { display: "none" } : {}}
                >
                  <div className="min-height-metadata-content-po">
                    {actualView ===
                      ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER && (
                      <PurchaseOrderMetadata
                        vendorPermission={vendorPermission}
                      />
                    )}
                  </div>

                  {CAN_SEE_SHIPMENT_NAVIGATION.includes(actualView) && (
                    <ShipmentNavigation />
                  )}
                  <div
                    className={cx(
                      "shipment-info-container",
                      actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT
                        ? "shipment-info-container-visible"
                        : "shipment-info-container-hidden"
                    )}
                    style={{
                      opacity: !!queryParams.shipmentId ? 1 : 0,
                    }}
                  >
                    <div className="min-height-metadata-content-shipment">
                      {actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT &&
                        (vendorPermission ? (
                          <ShipmentMetadata />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "inherit",
                              fontSize: 21,
                              color: colors.dangerRed,
                            }}
                          >
                            This shipment is in a vendor that you do not have
                            permission to see it
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          <div
            className={cx(
              "min-height-metadata-content-three-panel-section",
              actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
                ? "min-height-metadata-content-three-panel-section-visible"
                : "min-height-metadata-content-three-panel-section-hidden"
            )}
            id="three-panel-section-to-scroll"
          >
            {actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION ? (
              <ThreePanelSection vendorPermission={vendorPermission} />
            ) : (
              <div className="three-panel-section-skeleton"></div>
            )}
          </div>
        </OrderDashboardContainerStyled>
      </React.Fragment>
    )
  );
}

export default OrderDashboardView;
