import { colors } from "../../assets/jss/variables";
import { styled, keyframes } from "@mui/material";

const slideInRight = keyframes`
  0% {
    width: 0;
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    width: 492px;
    opacity: 1;
    transform: translateX(0%);
  }
`;
const slideInLeft = keyframes`

  0% {
    width: 492px;
    opacity: 1; 
    transform: translateX(0%);
  }
  100% {
    width: 0;
    opacity: 0;
    transform: translateX(100%);
  }
  `;

export const PrincipalDrawerStyled = styled("div")(({ theme }) => ({
  "&.userDrawerRoot": {
    position: "fixed",
    height: "100vh",
    right: 0,
    top: 0,
    width: "calc(100vw)",
    background: "rgba(0, 0, 0, 0.25)",
    zIndex: 1000,
    display: "flex",
    flexDirection: "row-reverse",
  },
  "& .slideRight": {
    animationName: slideInRight,
    animationDuration: "1s",
  },
  "& .slideLeft": {
    animationName: slideInLeft,
    animationDuration: "1s",
  },

  "& .drawerContainer": {
    width: 492,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F1F6FA",
    boxShadow: "-15px -5px 21px rgb(0 0 0 / 25%)",
    overflow: "hidden",
    position: "sticky",
    background: "white",
    top: 0,
    zIndex: 1,
    height: "100%",
  },
  "& .header": {
    padding: "7px 27px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 19,
    "& .buttonLogout": {
      border: "1.5px solid #4F4F4F;",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "8px 10px",
      background: "white",
      color: colors.borderGray,
      height: 36,
      width: 88,
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 700,
      justifyContent: "space-between",
      marginTop: 7,
    },
  },
  "& .groupButton": {
    height: 40,
    borderRadius: " 5px 5px 0px 0px",
    right: 23,
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    gap: 17,
    "& .MuiButtonBase-root": {
      fontSize: 12,
      color: colors.borderGray,
      padding: "10px 18px",
      background: "#D7E3F0",
      borderColor: "transparent",
      zIndex: 4,
    },
    "& .selected": {
      background: "white",
      boxShadow: "-2px -6px 10px -3px rgb(0 0 0 / 25%)",
      zIndex: 6,
    },
  },
  "& .showContent": {
    animationName: "$slide-in-right",
    animationDuration: "0.5s",
  },
  "& .content": { height: "100%" },
  "& .paperContent": {
    width: 480,
    marginLeft: 12,
    boxShadow: "-4px -4px 5px 0px rgb(0 0 0 / 15%)",
    borderTop: "10px solid white",
    height: "calc(100vh - 110px)",
    overflow: "auto",
    zIndex: 5,
    marginTop: 32,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    right: 0,
  },
}));
