import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { PrincipalDrawerStyled } from "./styles";
import { BellIcon, LogoutIcon, SmileIcon } from "../../helpers/iconSvgPath";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import ProfileDrawer from "../../container/ProfileContainer/ProfileDrawer";
import { colors } from "../../assets/jss/variables";
import NotificationsContainer from "../NotificationsContainer/NotificationsContainer";
import { auth } from "../../firebase";
import { getUserAvatar } from "../../helpers/users";
import { globalScopes } from "../../api/types/dbTables";
import AttentionModal from "../../components/Modal/AttentionModal";
import { cx } from "@emotion/css";
import { Button, ButtonGroup, Paper, Portal } from "@mui/material";

const modalText = {
  [globalScopes.SALES_ORDER]: "You do not have access to see this customer",
  [globalScopes.PURCHASE_ORDER]: "You do not have access to see this vendor",
  [globalScopes.SHIPMENT]:
    "The shipment is no longer belong to this sales order",
};

const UserDrawer = ({ open, handleIsOpen, user }) => {
  const [closing, setClosing] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [divRef, setDivRef] = useState({ current: undefined });
  const [tab, setTab] = useState(0);
  const [openAttentionModal, setAttentionModal] = useState();

  function closeNotifications() {
    setClosing(false);
    handleIsOpen(false);
  }

  useEffect(() => {
    if (!divRef.current) return;
    if (openModal || isLoading) {
      return;
    }
    if (open) {
      if (divRef.current) {
        document.addEventListener("click", handleClick);
      }
    }
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [divRef.current, openModal]);

  let globalVar = "";
  function handleClick(ev) {
    const modalOpen = document.getElementById("attentionModal");
    if (modalOpen) {
      return;
    }
    const target = ev.target || {};
    if (
      typeof target.className !== "object" &&
      target.className.includes("MuiAutocomplete-option")
    ) {
      ev.stopPropagation();
      return;
    }
    if (document.getElementsByClassName("MuiPopover-root")[0]) {
      globalVar = document.getElementsByClassName("MuiPopover-root")[0];
    } else if (document.getElementsByClassName("MuiAutocomplete-popper")[0]) {
      globalVar = document.getElementsByClassName("MuiAutocomplete-popper")[0];
    } else if (document.getElementsByClassName("DateRangePicker calendar")[0]) {
      globalVar = document.getElementsByClassName(
        "DateRangePicker calendar"
      )[0];
    } else {
      globalVar = "";
    }
    if (!ev.composedPath().includes(divRef.current) && !globalVar) {
      setClosing(true);
      setTimeout(closeNotifications, 550);
    }
    ev.stopPropagation();
  }

  return (
    <Portal>
      <PrincipalDrawerStyled
        className="userDrawerRoot"
        style={{
          transform: open ? "translateX(0)" : "translateX(100vw)",
        }}
      >
        {!!openAttentionModal && (
          <AttentionModal
            title="Attention"
            description={modalText[openAttentionModal]}
            isOpen={openAttentionModal}
            onClick={() =>
              setTimeout(() => {
                setAttentionModal(false);
              }, 100)
            }
            confirmationText="Ok"
            onClose={() =>
              setTimeout(() => {
                setAttentionModal(false);
              }, 100)
            }
          />
        )}
        <div
          ref={(el) =>
            el && !divRef.current ? setDivRef({ current: el }) : null
          }
          className={classNames("drawerContainer", {
            slideRight: open,
            slideLeft: closing,
          })}
          id={"notifications"}
          style={{
            width: open ? 492 : 0,
          }}
        >
          {isLoading && (
            <LoadingBackdrop backdropStyle={{ left: 0, width: 560 }} />
          )}

          <div className={"header"}>
            <button
              className="buttonLogout"
              onClick={async () => {
                await auth.signOut();
                setTimeout(() => {
                  window.location.reload();
                }, 200);
              }}
            >
              Logout
              <LogoutIcon svgClass={"logoutIcon"} color={colors.borderGray} />
            </button>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setClosing(true);
                setTimeout(closeNotifications, 550);
              }}
            >
              {getUserAvatar({
                styles: {
                  width: 50,
                  height: 50,
                  outline: "3px solid #000",
                  fontSize: 26,
                },
                user,
              })}
            </div>
          </div>
          {!closing && open && (
            <div className="content">
              <ButtonGroup
                aria-label="outlined primary button group"
                className={cx("groupButton", {
                  showContent: open,
                })}
              >
                <Button
                  onClick={() => setTab(0)}
                  className={cx({
                    selected: tab === 0,
                  })}
                >
                  Notifications
                  <BellIcon
                    style={{ marginLeft: 7 }}
                    color={colors.borderGray}
                  />
                </Button>
                <Button
                  onClick={() => setTab(1)}
                  className={cx({
                    selected: tab === 1,
                  })}
                >
                  User Settings
                  <SmileIcon
                    style={{ marginLeft: 7 }}
                    color={colors.borderGray}
                  />
                </Button>
              </ButtonGroup>
              <Paper elevation={0} className={"paperContent"}>
                {tab === 0 && (
                  <NotificationsContainer
                    onClose={() => handleIsOpen(false)}
                    open={open}
                    handleOpenModal={setAttentionModal}
                    handleLoading={setLoading}
                  />
                )}
                {tab === 1 && (
                  <ProfileDrawer
                    openModal={openModal}
                    handleLoading={(value) => setLoading(value)}
                    handleOpenModal={(value) => setOpenModal(value)}
                  />
                )}
              </Paper>
            </div>
          )}
        </div>
      </PrincipalDrawerStyled>
    </Portal>
  );
};

export default UserDrawer;
