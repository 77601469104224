import React, { useEffect, useState } from "react";

import ListItemDocumentNote from "./ListDocumentNote";

import { sortObjectsBy } from "../../../helpers/helpers";
import { FolderStructureListNoteStyled } from "./styles";
import { TYPE_OF_FILE } from "../../../helpers/constants";
import { List } from "@mui/material";

function NotesFoldersStructure({
  documents,
  companyID,
  mainTable,
  mainTableID,
  selectedDocumentTab,
  permissionGroupsDB,
  markAsTemplate,
  isDocumentVersion = false,
  onDraggableDocument,
  onDragEnd,
  draggableDocument,
  isDraggableDocument,
  documentFlat,
  hasParentPermission = false,
  parentDocument = {},
  disabled,
  isExpand,
  querySearch = "",
  handleExpandOrCollapse = () => {},
  dbTable,
  handleEditPermission,
  rootStructure,
  currentShipment,
}) {
  const [currentDocuments, setCurrentDocuments] = useState([]);

  const checkChidlFiles = (childDocument) => {
    let hasFiles = false;
    if (
      childDocument.type !== TYPE_OF_FILE.FOLDER &&
      !childDocument.placeholder
    ) {
      hasFiles = true;
    } else if (
      childDocument.type === TYPE_OF_FILE.FOLDER &&
      childDocument.child.length > 0
    ) {
      const childs = childDocument.child;
      hasFiles = childs.some((child) => checkChidlFiles(child));
    }
    return hasFiles;
  };

  useEffect(() => {
    const placeholderFolders = documents.filter((folder) => {
      let showFolder = false;
      if (folder.type === TYPE_OF_FILE.FOLDER && folder.placeholder) {
        showFolder = folder.child.some((child) => checkChidlFiles(child));
      }
      return showFolder;
    });

    const restOfDocuments = documents.filter(
      (doc) => doc.type !== "file" && doc.placeholder !== true
    );
    restOfDocuments.sort(sortObjectsBy("lastModified", true));
    restOfDocuments.sort(sortObjectsBy("isShipmentRootTask", true));

    const allDocuments = [];
    placeholderFolders.forEach((element) => {
      allDocuments.push(element);
    });
    restOfDocuments.forEach((element) => {
      allDocuments.push(element);
    });
    allDocuments.sort(sortObjectsBy("isShipmentRootTask", true));
    setCurrentDocuments(allDocuments);
    if (isDocumentVersion) {
      setCurrentDocuments(documents);
    }
  }, [documents]);

  return (
    <FolderStructureListNoteStyled
      id="folder-structure-list-container"
      className={"folderStructureListContainer"}
    >
      <List classes={{ root: "root-list-content", padding: "paddingList" }}>
        {currentDocuments.map((document, index) => (
          <ListItemDocumentNote
            key={document.id}
            currentDocument={document}
            companyID={companyID}
            mainTable={mainTable}
            mainTableID={mainTableID}
            documents={currentDocuments}
            selectedDocumentTab={selectedDocumentTab}
            permissionGroupsDB={permissionGroupsDB}
            isLastTemplate={index + 1 === markAsTemplate ? true : false}
            onDraggableDocument={onDraggableDocument}
            onDragEnd={onDragEnd}
            draggableDocument={draggableDocument}
            isDraggableDocument={isDraggableDocument}
            documentFlat={documentFlat}
            hasParentPermission={hasParentPermission}
            parentDocument={parentDocument}
            disabled={disabled}
            isExpand={isExpand}
            querySearch={querySearch}
            handleExpandOrCollapse={handleExpandOrCollapse}
            dbTable={dbTable}
            handleEditPermission={handleEditPermission}
            rootStructure={rootStructure}
            currentShipment={currentShipment}
          />
        ))}
      </List>
    </FolderStructureListNoteStyled>
  );
}

export default NotesFoldersStructure;
