import React, { useState, useEffect } from "react";
import { FactoryInfoStyled } from "./styles";
import IntlMessages from "../../util/IntlMessages";
import AttentionModal from "../Modal/AttentionModal";
import {
  GENERAL_PERMISSION_VALUE,
  regexEmailValidator,
} from "../../helpers/constants";
import FieldByProperty from "../Inputs/FieldByProperty";
import { updateDoc } from "firebase/firestore";

const FACTORY_FIELD = {
  NUMBER: "number",
  INACTIVE: "inactive",
  NAME: "name",
  MAIN_CONTACT: "mainContact",
  JOB_TITLE: "jobTitle",
  EMAIL: "email",
  PHONE: "phone",
  TERMS: "terms",
  NOTES: "notes",
};

const factoryFields = [
  FACTORY_FIELD.NUMBER,
  FACTORY_FIELD.INACTIVE,
  FACTORY_FIELD.NAME,
  FACTORY_FIELD.MAIN_CONTACT,
  FACTORY_FIELD.JOB_TITLE,
  FACTORY_FIELD.EMAIL,
  FACTORY_FIELD.PHONE,
  FACTORY_FIELD.TERMS,
  FACTORY_FIELD.NOTES,
];

function validateEmail(email) {
  return regexEmailValidator.test(String(email).toLowerCase());
}

function FactoryInfo({
  factory = {},
  factories = [],
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleUpdateSummary = () => {},
  isAllowed = {},
}) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentFactory, setCurrentFactory] = useState({});
  const [openAttentionModal, setOpenAttentionModal] = useState(false);

  useEffect(() => {
    setCurrentFactory(factory);
  }, [factory]);

  function handleInactiveFactory(checked, property) {
    setCurrentFactory({ ...currentFactory, [property]: checked });
    handleUpdateSummary({ ...factory, [property]: checked });
    updateDoc(factory.ref, { [property]: checked, triggerType: property });
  }

  function handleSaveCustomer(property) {
    if (currentFactory[property] === factory[property]) {
      return;
    }
    if (
      property === "email" &&
      currentFactory[property] !== "" &&
      !validateEmail(currentFactory[property])
    ) {
      setOpenAttentionModal(true);
      setDescriptionModal("Invalid email format, please choose a valid email");
      setCurrentFactory({
        ...currentFactory,
        [property]: factory[property] || "",
      });
    } else if (property === "notes") {
      updateDoc(factory.ref, {
        [property]: currentFactory[property],
        triggerType: property,
      });
    } else {
      currentFactory[property] = currentFactory[property]
        .replace(/\s\s+/g, " ")
        .trim();
      updateDoc(factory.ref, {
        [property]: currentFactory[property],
        triggerType: property,
      });
    }
  }

  function handleChange(event, property) {
    setCurrentFactory({ ...currentFactory, [property]: event.target.value });
  }

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          title="Attention"
          description={descriptionModal}
          isOpen={openAttentionModal}
          onClick={() => setOpenAttentionModal(false)}
          onClose={() => setOpenAttentionModal(false)}
        />
      )}

      <FactoryInfoStyled className="container">
        {factories.length > 0 && (
          <React.Fragment>
            <div className="documents-header">
              <h2>{<IntlMessages id={"general.basicinfo"} />}</h2>
            </div>
            <div className={"horizontalDivider"}></div>
            <ul>
              {factoryFields.map((property) => (
                <li key={currentFactory.id + property} className={"infoField"}>
                  <span>{<IntlMessages id={`factory.${property}`} />}</span> :{" "}
                  <FieldByProperty
                    property={property}
                    currentItem={currentFactory}
                    isReadOnly={
                      isReadOnly ||
                      (property === FACTORY_FIELD.TERMS &&
                        !isAllowed(
                          GENERAL_PERMISSION_VALUE.CAN_CHANGE_VENDOR_PAYMENT_TERMS
                        ))
                    }
                    handleSave={handleSaveCustomer}
                    handleChange={handleChange}
                    handleInactive={handleInactiveFactory}
                    handleReadOnlyModal={handleReadOnlyModal}
                    type={property === FACTORY_FIELD.TERMS ? "number" : "text"}
                  />
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </FactoryInfoStyled>
    </React.Fragment>
  );
}

export default FactoryInfo;
