import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DocumentTemplate from "../DocumentTemplate/DocumentTemplate";
import { dbTables } from "../../api/types/dbTables";
import { getNestedCollectionFromFirestoreTable } from "../../actions/DataActions";
import {
  sortObjectsBy,
  pathToString,
  getRandomId,
} from "../../helpers/helpers";
import LegendDocumentOptions from "../DocumentTemplate/LegendDocumentOptions";
import File from "../../api/model/file";
import { shipmentPlaceholderFile } from "../../helpers/documents";
import { firestore } from "../../firebase";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { useUser } from "../../hooks/user";
import userTypes from "../../api/types/userTypes";
import { doc, setDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { DocumentPlaceholderStyled } from "./styles";

function Documents() {
  const user = useUser();
  const isCompanyUser = user.role !== userTypes.TRADEDASH_EMPLOYEE;
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const listenSalesOrderDocuments = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.SALES_ORDER_DOCUMENTS_TEMPLATE,
      ]),
    })(dispatch)
  );
  const listenPurchaseOrderDocuments = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.PURCHASE_ORDER_DOCUMENTS_TEMPLATE,
      ]),
    })(dispatch)
  );
  const listenShipmentDocuments = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.SHIPMENT_DOCUMENTS_TEMPLATE,
      ]),
    })(dispatch)
  );

  const salesOrderDocuments = useSelector(
    (state) => state.data.sales_order_documents_template[companyId]
  );
  const purchaseOrderDocuments = useSelector(
    (state) => state.data.purchase_order_documents_template[companyId]
  );
  const shipmentDocuments = useSelector(
    (state) => state.data.shipment_documents_template[companyId]
  );

  if (salesOrderDocuments) {
    salesOrderDocuments.sort(sortObjectsBy("listIndex"));
  }
  if (purchaseOrderDocuments) {
    purchaseOrderDocuments.sort(sortObjectsBy("listIndex"));
  }

  useEffect(() => {
    listenSalesOrderDocuments();
    listenPurchaseOrderDocuments();
    listenShipmentDocuments();
  }, [companyId]);

  useEffect(() => {
    if (shipmentDocuments && shipmentDocuments.length === 0) {
      const document = new File({
        id: getRandomId(),
        name: shipmentPlaceholderFile,
        type: TYPE_OF_FILE.FOLDER,
        placeholder: true,
        path: shipmentPlaceholderFile,
        mainFile: true,
      });
      setDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENT_DOCUMENTS_TEMPLATE}/${document.id}`
        ),
        { ...document }
      );
    }
  }, [shipmentDocuments]);

  return (
    <DocumentPlaceholderStyled
      className="documentPlaceholderContainer"
      style={
        isCompanyUser
          ? { maxWidth: "calc(100vw - 303px)", zIndex: 0 }
          : { maxWidth: "calc(100vw - 630px)", zIndex: 0 }
      }
    >
      <LegendDocumentOptions />
      <div className="document-template-container">
        <DocumentTemplate
          title="companytabs.documents.salesorders.title"
          companyID={companyId}
          documents={salesOrderDocuments}
          dbTable={dbTables.SALES_ORDER_DOCUMENTS_TEMPLATE}
        />
        <DocumentTemplate
          title="companytabs.documents.purchasesorders.title"
          companyID={companyId}
          documents={purchaseOrderDocuments}
          dbTable={dbTables.PURCHASE_ORDER_DOCUMENTS_TEMPLATE}
          style={{ borderInline: "1px solid #E5EDF7" }}
        />
        <DocumentTemplate
          title="companytabs.documents.shipments.title"
          companyID={companyId}
          documents={shipmentDocuments}
          dbTable={dbTables.SHIPMENT_DOCUMENTS_TEMPLATE}
          style={{ minWidth: 510 }}
        />
      </div>
    </DocumentPlaceholderStyled>
  );
}

export default Documents;
