import React from "react";
import GreenSmallSwitch from "../Inputs/GreenSmallSwitch";
import { dbTables } from "../../api/types/dbTables";
import { firestore } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Divider } from "@mui/material";
import { UserSettingsDrawerStyled } from "./styles";

function UserNotificationSetting({ setting, currentUser }) {
  const fieldToUpdate =
    setting.property === "messages"
      ? "mentionNotifications"
      : "taskNotifications";

  function onChange(ev) {
    updateDoc(doc(firestore, `${dbTables.USERS}/${currentUser.id}`), {
      [fieldToUpdate]: !currentUser[fieldToUpdate],
    });
  }

  return (
    <UserSettingsDrawerStyled key={setting.property} className={"setting"}>
      <div className="switchRow">
        <div style={{ marginTop: 2 }}>
          <GreenSmallSwitch
            onChange={onChange}
            checked={currentUser[fieldToUpdate]}
            width={76}
            height={23}
          />
        </div>
        <label className="settingLabel">{setting.label}</label>
      </div>
      <Divider style={{ marginLeft: 14, marginRight: 19 }} />
      {setting.description.map((string, index) => (
        <p
          className="settingDescription"
          key={`settingDescription_${setting.property + index}`}
        >
          {string.replace("{username}", currentUser.displayName)}
        </p>
      ))}
    </UserSettingsDrawerStyled>
  );
}

export default UserNotificationSetting;
