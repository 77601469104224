import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";
import Activity from "./activity";

export default class ActivityNote extends Activity {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    detail = "",
    scope = "",
    type = "",
    user = "",
    companyId = "",
    salesOrderId = "",
    purchaseOrderId = "",
    shipmentId = "",
    mentionsFormat = "",
    v2 = true,
    userMentions = {},
    lockNote = false,
    urlList = [],
    permissionGroups = {},
    shipments = {},
    onlyUsersMention = {},
    documentsMentions = {},
    readByUsers = {},
    threadId = "",
    iconIndex = "",
  }) {
    super({
      id,
      creationDate,
      detail,
      scope,
      type,
      user,
      companyId,
      salesOrderId,
      shipmentId,
      purchaseOrderId,
    });
    this.mentionsFormat = mentionsFormat;
    this.v2 = v2;
    this.userMentions = userMentions;
    this.lockNote = lockNote;
    this.urlList = urlList;
    this.permissionGroups = permissionGroups;
    this.shipments = shipments;
    this.onlyUsersMention = onlyUsersMention;
    this.documentsMentions = documentsMentions;
    this.readByUsers = readByUsers;
    this.threadId = threadId;
    this.iconIndex = iconIndex;
  }
}
