import React, { useEffect, useState } from "react";
import { getFileName } from "../../../helpers/helpers";
import { ListItemTextDocumentNoteStyled } from "./styles";

import { TYPE_OF_FILE } from "../../../helpers/constants";
import moment from "moment";
import { useCompanyUsers } from "../../../hooks/user";

function getFileSubInformation({ date, users = [], currentDocument }) {
  const documentUser =
    users.find((user) => user.id === currentDocument.createdBy) || {};

  if (!currentDocument.placeholder) {
    return (
      <React.Fragment>
        <div className={"fileInformationContainer"}>
          <label style={{ marginBottom: 4 }} className="fileinfo">
            Added {date}
          </label>
        </div>
        <div className={"fileInformationBottom"}>
          <label className="fileinfo">
            by{" @"}
            {documentUser.displayName || "companyUser"}
          </label>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className={"fileInformationContainer"}>
          <label className="fileinfo" />
        </div>
      </React.Fragment>
    );
  }
}

function NoteTextDocument({ currentDocument, purchaseOrderID }) {
  const isShipmentFolder = currentDocument.isShipmentRootTask;
  const [date, setDate] = useState(
    moment(currentDocument.lastModified).format("h:mm a M/D/YY")
  );
  const users = useCompanyUsers({
    showInactiveUsers: true,
    showBotUser: true,
  });
  useEffect(() => {
    setDate(moment(currentDocument.lastModified).format("h:mm a M/D/YY"));
  }, [currentDocument.lastModified]);

  return (
    <ListItemTextDocumentNoteStyled
      className="listItemText"
      classes={{
        primary: "primaryItemText",
      }}
    >
      <div className={"listItemTextPrimary"}>
        <label
          className="filename"
          style={{
            marginBottom: isShipmentFolder ? 0 : null,
          }}
        >
          {currentDocument.type === TYPE_OF_FILE.FOLDER ||
          currentDocument.placeholder
            ? currentDocument.name
            : getFileName(currentDocument.name)}
        </label>
      </div>
      <div className={"listItemTextSecondary"}>
        {!isShipmentFolder &&
          getFileSubInformation({
            date,
            users,
            currentDocument,
          })}
      </div>{" "}
    </ListItemTextDocumentNoteStyled>
  );
}

export default NoteTextDocument;
