import React, { useState, useEffect } from "react";
import { colors } from "../../assets/jss/variables";
import { NavLink } from "react-router-dom";
import {
  getCompanyUserDisplayName,
  getDateByTimezone,
  getDashboardSearchPath,
  getTagsLabel,
  getTheDate,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { useStorage } from "../../hooks";
import TagsModal from "../../components/Modal/TagsModal";
import {
  headerColumns,
  LOCAL_STORAGE_KEY,
  taskStatus,
} from "../../helpers/constants";
import { getUserAvatar } from "../../helpers/users";
import { getNumberOfLateDays } from "../../helpers/tasks";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import CustomCheckbox from "../../components/Inputs/CustomCheckbox";
import { getShipmentBadge } from "../../helpers/shipments";

import moment from "moment";
import { getTagStatus } from "../../helpers/salesOrder";
import { getBackgroundColorByType } from "../../helpers/orderDashboard";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { TableCell } from "@mui/material";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { CheckBoxIcon, DiamondIcon } from "../../helpers/iconSvgPath";

function TaskTableRow({
  task: item,
  index,
  onComplete,
  companyUsers,
  filters,
  tags = [],
  onUpdateItem,
  user,
  isAllowed,
  headerCells,
  isThereColumnSetting = false,
}) {
  const [completing, setCompleting] = useState(false);
  const [completed, setCompleted] = useState(false);

  const filterTag = filters.tag || [];
  const purchaseOrder = item.purchaseOrder || {};
  let purchaseOrderId = purchaseOrder.id || item.purchaseOrderId;
  if (!purchaseOrderId) {
    const salesOrder = item.salesOrder || {};
    const purchaseOrderIds = salesOrder.purchaseOrderIds || [];
    purchaseOrderId = purchaseOrderIds[0];
  }
  const [, setStorage] = useStorage(LOCAL_STORAGE_KEY.projectTasks);

  useEffect(() => {
    if (completed) {
      onComplete(item, index);
      setCompleting(false);
    }
  }, [completed]);

  function taskTableRowSelector({ column, enabled, index }) {
    const currentShipment = item.shipment || {};
    switch (column) {
      case headerColumns.DIAMOND:
        return (
          <TableCell key={index} className="diamond">
            {item.status === "late" && (
              <TooltipTD label={getNumberOfLateDays({ task: item })}>
                <DiamondIcon color={colors.diamondRed} height={16} />
              </TooltipTD>
            )}
            {item.status === "nearDue" && (
              <TooltipTD label="Due Today or Tomorrow">
                <DiamondIcon color={colors.diamondOrange} height={16} />
              </TooltipTD>
            )}
            {item.status === "inProgress" && (
              <TooltipTD label="In Progress">
                <DiamondIcon color={colors.diamondGreen} height={16} />
              </TooltipTD>
            )}
            {item.status === "notStarted" && (
              <TooltipTD label="Not Started">
                <DiamondIcon
                  color={"white"}
                  strokeColor={colors.diamondWhiteBorder}
                  height={16}
                />
              </TooltipTD>
            )}
            {item.complete && (
              <TooltipTD label="Completed">
                <CheckBoxIcon />
              </TooltipTD>
            )}
          </TableCell>
        );

      case headerColumns.SO:
        const salesOrderTag = item.salesOrder.tags || [];
        return (
          enabled && (
            <TableCell key={index} className="SONumber">
              <div className="number-table-container">
                {isAllowed("see_tags") && (
                  <TagsModal
                    label={getTagsLabel(tags, item.salesOrder.tags).tagsString}
                    tags={getTagsLabel(tags, item.salesOrder.tags).tagsArray}
                    allTags={tags}
                    item={{
                      ...item.salesOrder,
                      taskId: item.id,
                      salesOrderId: item.salesOrder.id,
                    }}
                    currentUser={user}
                    tagReference={dbTables.SALES_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: salesOrderTag,
                      currentTags: filterTag,
                    })}
                  />
                )}
                <NavLink
                  to={getDashboardSearchPath({
                    salesOrderId: item.salesOrderId,
                    purchaseOrderId,
                    shipmentId: currentShipment.id,
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                  })}
                  style={{
                    color: colors.primaryDark,
                    textDecoration: "underline",
                  }}
                  id="list-view-content-salesorder"
                >
                  <span id={item.salesOrderId}>{item.SONumber}</span>
                </NavLink>
              </div>
            </TableCell>
          )
        );

      case headerColumns.PO:
        const purchaseOrderTag = item.purchaseOrder.tags || [];
        return (
          enabled && (
            <TableCell key={index} className="PONumber">
              <div className="number-table-container">
                {isAllowed("see_tags") && (
                  <TagsModal
                    label={
                      getTagsLabel(tags, item.purchaseOrder.tags).tagsString
                    }
                    tags={getTagsLabel(tags, item.purchaseOrder.tags).tagsArray}
                    allTags={tags}
                    item={{
                      ...item.purchaseOrder,
                      taskId: item.id,
                      purchaseOrderId: item.purchaseOrder.id,
                    }}
                    currentUser={user}
                    tagReference={dbTables.PURCHASE_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: purchaseOrderTag,
                      currentTags: filterTag,
                    })}
                  />
                )}

                <NavLink
                  style={{
                    color: colors.primaryDark,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  to={getDashboardSearchPath({
                    salesOrderId: item.salesOrderId,
                    purchaseOrderId,
                    shipmentId: currentShipment.id,
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                  })}
                  id="list-view-content-purchaseorder"
                >
                  <span id={item.id}>{item.PONumber}</span>
                </NavLink>
              </div>
            </TableCell>
          )
        );

      case headerColumns.TASK:
        return (
          enabled && (
            <TableCell key={index} className="description">
              <div className="description-wrapper">
                {item.type === typeOfTask.SHIPMENT && (
                  <div className="shipment-badge-container">
                    <NavLink
                      to={getDashboardSearchPath({
                        salesOrderId: item.salesOrderId,
                        purchaseOrderId,
                        shipmentId: currentShipment.id,
                        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                      })}
                      style={{
                        color: colors.primaryDark,
                        overflow: "visible",
                        marginLeft: -5,
                        textDecoration: "none",
                      }}
                      id="list-view-content-shipment"
                    >
                      {getShipmentBadge({
                        shipment: currentShipment,
                        styles: { margin: "0px 4px 0px 0px" },
                        classes: shipmentBadgeClass.mediumInverted,
                        id: "mediumInverted",
                      })}
                    </NavLink>
                  </div>
                )}
                <div className="task-description-container">
                  <TooltipTD label={item.description}>
                    <NavLink
                      className={"ellipsisText"}
                      to={getDashboardSearchPath({
                        salesOrderId: item.salesOrderId,
                        purchaseOrderId,
                        shipmentId: currentShipment.id,
                        taskId: item.id,
                        section:
                          ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                      })}
                      onClick={() => {
                        setStorage("taskTab", 0);
                        setStorage("taskFilter", "ALL");
                        if (item.complete) {
                          setStorage("showCompleted", true);
                        }
                      }}
                      onContextMenu={() => {
                        setStorage("taskTab", 0);
                        setStorage("taskFilter", "ALL");
                        if (item.complete) {
                          setStorage("showCompleted", true);
                        }
                      }}
                      id="list-view-content-taskname"
                    >
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        {item.description}
                      </span>
                    </NavLink>
                  </TooltipTD>
                </div>
              </div>
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER:
        return (
          enabled && (
            <TableCell key={index} className={"customerName"}>
              <TooltipTD label={item.customerName}>
                <NavLink
                  to={`/app/customers/${item.customerId}`}
                  id="list-view-content-customer"
                >
                  <span className="span-description">{item.customerName}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.VENDOR:
        return (
          enabled && (
            <TableCell key={index} className={"factoryName"}>
              <TooltipTD label={item.factoryName}>
                <NavLink
                  to={`/app/factories/${item.factoryId}`}
                  id="list-view-content-factory"
                >
                  <span className="span-description">{item.factoryName}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.ASSIGNED_TO:
        return (
          enabled && (
            <TableCell key={index} className="assignedToName">
              {companyUsers.find((cu) => cu.id === item.assignedTo) ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {getUserAvatar({
                    user: companyUsers.find(
                      (companyUser) => companyUser.id === item.assignedTo
                    ),
                    styles: {
                      width: 24,
                      height: 24,
                      fontSize: 13,
                      outline: "2px solid #000",
                    },
                  })}
                  <span style={{ position: "relative", paddingLeft: 5 }}>
                    {getCompanyUserDisplayName(companyUsers, item.assignedTo)}
                  </span>
                </div>
              ) : (
                ""
              )}
            </TableCell>
          )
        );

      case headerColumns.START_DATE:
        return (
          enabled && (
            <TableCell key={index} className="date" id="startDate">
              {getTheDate(
                moment(
                  getDateByTimezone({
                    date: item.startDate,
                  })
                ),
                "M/D/YY"
              )}
            </TableCell>
          )
        );

      case headerColumns.DUE_DATE:
        return (
          enabled && (
            <TableCell key={index} className="date" id="dueDate">
              {getTheDate(
                moment(
                  getDateByTimezone({
                    date: item.finishDate,
                  })
                ),
                "M/D/YY"
              )}
            </TableCell>
          )
        );

      case headerColumns.ACTION:
        return (
          enabled && (
            <TableCell key={index} className="checkBoxCell" id="actionButton">
              {!item.complete ? (
                <div className={"checkBox"}>
                  <CustomCheckbox
                    checked={item.complete || false}
                    styles={{
                      width: 17,
                      height: 17,
                      background: "white",
                    }}
                    onChange={() => {
                      setCompleting(true);
                      setTimeout(() => setCompleted(true), 1000);
                    }}
                  />
                  {<span className={"checkBoxMarkDone"}>Mark Done</span>}
                </div>
              ) : (
                <div>
                  <CustomCheckbox
                    checked={item.complete}
                    styles={{ width: 17, height: 17, color: "#6B7A89" }}
                  />
                </div>
              )}
              {completing && <div className={"completing-task"}></div>}
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return enabled && <TableCell key={index} />;

      default:
        return <TableCell key={index} />;
    }
  }
  return (
    <MainRowStyled
      key={
        item.purchaseOrderId
          ? item.id + item.purchaseOrderId
          : item.id + item.salesOrderId
      }
      className={mainRowClasses.TaskTableRow}
      style={{
        background: getBackgroundColorByType({ type: item.type }),
        opacity: item.status === taskStatus.COMPLETE ? 0.5 : 1,
      }}
    >
      {headerCells.map((header) =>
        taskTableRowSelector({
          column: header.name,
          enabled: header.enabled,
          index: item.id + header.name,
        })
      )}
      {isThereColumnSetting && <TableCell />}
    </MainRowStyled>
  );
}

export default TaskTableRow;
