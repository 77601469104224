import React, { useState, useEffect } from "react";
import User from "../../api/model/user";
import moment from "moment";
import FilterActivities from "./FilterActivities";
import { getTextFromType } from "../../helpers/helpers";
import CancelIcon from "@mui/icons-material/Cancel";
import Mark from "mark.js";
import { useUser } from "../../hooks/user";
import { salesOrderTriggerTypes } from "../../helpers/salesOrder";
import AttentionModal from "../Modal/AttentionModal";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { updateDoc } from "firebase/firestore";
import { ActivityWidgedContainerStyled } from "../PurchaseOrderDashboard/styles";
import { getActivitiesByDate } from "../PurchaseOrderDashboard/HelperProjectNotes";

const SCROLLED_OPTIONS = {
  NOT_STARTED: "NOT_STARTED",
  SCROLLED: "SCROLLED",
};

function RecentActivity({
  activeTab,
  limitDay,
  onEditNote,
  openModalNotes,
  salesOrderActivities = [],
  purchaseOrderActivities = {},
  querySearch,
  filterUsersActivities,
  companyUsers,
  salesOrder = {},
  purchaseOrder = {},
  currentShipment = {},
  onChangeCalendar,
  currentDate,
  onChangeDate,
  showActivityOptions,
  onChangeShowActivityOptions,
  unreadActivity,
  purchaseOrders,
  onChangeActiveTab = () => {},
  currentNote,
  draftNote,
  onReplyActivity,
  onShowThread,
  noteThread,
  hasThreadPermission,
  currentActivitiesList = [],
  onChangeShipment,
}) {
  const [attentionData, setAttentionData] = useState({});
  const currentUser = { ...new User(useUser()) };
  const allActivities = [
    ...draftNote,
    ...salesOrderActivities,
    ...purchaseOrderActivities,
  ];
  const isAllowed = useIsAllowedFunction();
  useEffect(() => {
    const activityScrollContainer = document.getElementById(
      "activity-scroll-container"
    );
    activityScrollContainer.scrollTo({
      top: 0,
    });
    performMark(querySearch);
  }, [querySearch, activeTab]);

  useEffect(() => {
    performMark(querySearch);
  }, [activeTab, currentDate]);

  function isCurrentActivitiesEmpty(activities) {
    const isEmpty = Object.keys(activities).every((key) => {
      const activitiesFilter = activitiesByUsers(activities[key]).filter(
        (activity) => existQueryInActivity(querySearch, activity)
      );
      return activitiesFilter.length === 0;
    });
    return isEmpty;
  }

  function activitiesByUsers(activities) {
    let filteredActivities = activities;
    if (filterUsersActivities) {
      if (filterUsersActivities.length) {
        filteredActivities =
          !!activities &&
          activities.filter(
            (activity) =>
              !!filterUsersActivities.find((user) => {
                return activity.user === user.id;
              })
          );
      }
    } else {
      filteredActivities = [];
    }
    return filteredActivities;
  }

  function handleMarkAsReadUnreadActivities(allActivities) {
    const unreadActivities = allActivities.filter(
      (activity) => !activity.readBy || !activity.readBy[currentUser.id]
    );
    unreadActivities.forEach((activity) => {
      const readByCpy = { ...activity.readBy };
      readByCpy[currentUser.id] = true;
      updateDoc(activity.ref, { readBy: readByCpy });
    });
    let unreadSalesOrderActivitiesUsersCpy =
      salesOrder.unreadActivityUsers || [];
    unreadSalesOrderActivitiesUsersCpy =
      unreadSalesOrderActivitiesUsersCpy.filter(
        (unreadActivity) => unreadActivity.userId !== currentUser.id
      );
    updateDoc(salesOrder.ref, {
      unreadActivityUsers: unreadSalesOrderActivitiesUsersCpy,
      triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
    });

    let unreadPOActivitiesUsersCpy = purchaseOrder.unreadActivityUsers || [];
    unreadPOActivitiesUsersCpy = unreadPOActivitiesUsersCpy.filter(
      (unreadActivity) => unreadActivity.userId !== currentUser.id
    );
    updateDoc(purchaseOrder.ref, {
      unreadActivityUsers: unreadPOActivitiesUsersCpy,
      triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
    });
    if (currentShipment && currentShipment.ref) {
      let unreadShipmentActivitiesUsersCpy =
        currentShipment.unreadActivityUsers || [];
      unreadShipmentActivitiesUsersCpy =
        unreadShipmentActivitiesUsersCpy.filter(
          (unreadActivity) => unreadActivity.userId !== currentUser.id
        );
      updateDoc(currentShipment.ref, {
        unreadActivityUsers: unreadShipmentActivitiesUsersCpy,
        triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
      });
    }
  }

  const markInstance = new Mark(
    document.getElementById("activity-scroll-container")
  );
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [
        ".activity-stream-title-today",
        ".timestamp",
        ".text-info-edit",
        ".scope",
        ".empty-stream-title",
        ".activity-stream-search-text > *",
      ],
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => {
          markInstance.mark(keyword, options);
        }, 300);
      },
    });
  }

  function existQueryInActivity(query, activity) {
    let detail = activity.detail.replace(/<[^>]*>/gi, "").toLowerCase();
    detail = detail.replace(/\s/g, " ");
    if (detail.includes(query.toLowerCase())) {
      return true;
    }
    const activityType = getTextFromType(activity.type).toLowerCase();
    if (activityType.includes(query.toLowerCase())) {
      return true;
    }
    const username = `@${
      companyUsers.find((user) => user.id === activity.user)
        ? companyUsers.find((user) => user.id === activity.user).displayName
        : "userdefault"
    }`;
    if (username.toLowerCase().includes(query.toLowerCase())) {
      return true;
    }
    return false;
  }
  const hasNotification =
    querySearch === "" && unreadActivity.status && !noteThread;

  const activitiesByDate = getActivitiesByDate({
    activeTab,
    allActivities,
    currentActivitiesList,
    currentUser,
    noteThread,
    purchaseOrderActivities,
    salesOrderActivities,
    draftNote,
    performMark,
    querySearch,
  });

  return (
    <ActivityWidgedContainerStyled className="recentActivityContainer">
      {attentionData && attentionData.isOpen && (
        <AttentionModal
          title="Attention"
          description={attentionData.text}
          isOpen={attentionData.isOpen}
          onClick={() => setAttentionData({})}
          confirmationText="ok"
          onClose={() => setAttentionData({})}
        />
      )}
      {hasNotification && (
        <div className={"notificationUnreadContainer"}>
          <div
            className="notificationActivities"
            onClick={() => {
              if (showActivityOptions === SCROLLED_OPTIONS.SCROLLED) {
                onChangeShowActivityOptions(SCROLLED_OPTIONS.NOT_STARTED, true);
                const activityScrollContainer = document.getElementById(
                  "activity-scroll-container"
                );
                activityScrollContainer.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                handleMarkAsReadUnreadActivities(allActivities);
              } else if (!showActivityOptions) {
                onChangeActiveTab("ALL");
                setTimeout(() => {
                  if (
                    moment(unreadActivity.current.creationDate).valueOf() <
                    moment(currentDate).valueOf()
                  ) {
                    onChangeDate({
                      date: moment(
                        unreadActivity.current.creationDate
                      ).valueOf(),
                      activityId: unreadActivity.current.activityId,
                    });
                  } else {
                    const activityScrollContainer = document.getElementById(
                      "activity-backdrop-" + unreadActivity.current.activityId
                    );
                    activityScrollContainer.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                  onChangeShowActivityOptions(SCROLLED_OPTIONS.SCROLLED);
                }, 500);
              }
            }}
          >
            <div className="textUnreadActivities">
              {showActivityOptions === SCROLLED_OPTIONS.SCROLLED ? (
                <React.Fragment> Jump to Now </React.Fragment>
              ) : (
                <React.Fragment>
                  Show new activity since{" "}
                  {moment(unreadActivity.current.creationDate).format(
                    "MMMM D h:mma"
                  )}
                </React.Fragment>
              )}
            </div>
            <CancelIcon
              className="cancelIcon"
              onClick={(ev) => {
                handleMarkAsReadUnreadActivities(allActivities);
                onChangeShowActivityOptions(SCROLLED_OPTIONS.NOT_STARTED, true);
                ev.stopPropagation();
              }}
            />
          </div>
        </div>
      )}
      <div
        className="activityWidget"
        style={{
          maxHeight: hasNotification
            ? "calc(100vh - 270px)"
            : "calc(100vh - 247px)",
        }}
      >
        <div
          className="activityScrollContainer"
          id="activity-scroll-container"
          style={{ marginTop: 9 }}
        >
          {Object.keys(activitiesByDate).map((key, index) => {
            return (
              <FilterActivities
                key={key + index}
                index={index}
                title={key}
                setAttentionData={setAttentionData}
                isAllActivitiesEmpty={isCurrentActivitiesEmpty(
                  activitiesByDate
                )}
                currentNote={currentNote}
                filterActivities={activitiesByUsers(
                  activitiesByDate[key]
                ).filter((activity) => {
                  return existQueryInActivity(querySearch, activity);
                })}
                currentUser={currentUser}
                users={companyUsers}
                onEditNote={onEditNote}
                openModalNotes={openModalNotes}
                unreadActivities={unreadActivity.status}
                onChangeShipment={onChangeShipment}
                firstUnreadActivity={allActivities.find(
                  (activity) =>
                    unreadActivity.current &&
                    activity.id === unreadActivity.current.activityId
                )}
                scrolled={showActivityOptions} //PENDING TO REFACTOR
                currentFirstActivityUnread={allActivities.find(
                  (activity) =>
                    unreadActivity.current &&
                    activity.id === unreadActivity.current.activityId
                )}
                querySearch={querySearch}
                salesOrder={salesOrder}
                purchaseOrder={purchaseOrder}
                purchaseOrders={purchaseOrders}
                currentShipment={currentShipment}
                isAllowed={isAllowed}
                onReplyActivity={onReplyActivity}
                onShowThread={(activity) => {
                  onShowThread(activity);
                }}
                noteThread={noteThread}
                hasThreadPermission={hasThreadPermission}
              />
            );
          })}
          {moment(currentDate).valueOf() <=
            moment(salesOrder.creationDate).valueOf() && (
            <div className="activity-stream-search-text">
              <span>{`This is the beginning of ${
                noteThread ? "this thread" : "the Activity Stream"
              }`}</span>
            </div>
          )}
          {!limitDay && !noteThread && (
            <div
              id="load-activities-container-id"
              onClick={onChangeCalendar}
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <span className="load-activities-container">
                Load Activity Stream from the beginning
              </span>
            </div>
          )}
        </div>
      </div>
    </ActivityWidgedContainerStyled>
  );
}

export default RecentActivity;
