import React, { useState, useEffect } from "react";
import CategorySection from "./CategorySection";
import CustomButton from "../Buttons/CustomButton";
import taskStages from "../../api/types/taskStages";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  sortObjectsBy,
  sortObjectsByStage,
  addTaskListStage,
  updateTaskDataStage,
  moveTaskTemplate,
} from "../../helpers/helpers";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import WithConfirmation from "../ConfirmationDialog/WithConfirmation";
import { firestore } from "../../firebase";
import { STAGE_LIST } from "../../helpers/constants";
import { deleteDoc, doc } from "firebase/firestore";

function SalesOrderContainer({
  changed,
  setChanged,
  salesOrderTasks = [],
  onCancel = () => {},
  onSave = () => {},
  companyId,
  factoryId,
  onValidate,
  taskList = [],
  cloningFrom,
  disabled = false,
  companyUsers,
}) {
  const [deletedTasks, setDeletedTasks] = useState([]);
  const [listObject, setListObject] = useState({
    [taskStages.PROPOSAL]: [],
    [taskStages.PRE_PRODUCTION]: [],
    [taskStages.PRODUCTION]: [],
    [taskStages.BOOKING_TRANSIT]: [],
    [taskStages.PAYMENT_BILLING]: [],
  });

  const combinedLists = [
    ...listObject.PROPOSAL,
    ...listObject.PRE_PRODUCTION,
    ...listObject.PRODUCTION,
    ...listObject.BOOKING_TRANSIT,
    ...listObject.PAYMENT_BILLING,
  ];

  useEffect(() => {
    const templateTasks = [
      ...salesOrderTasks.map((task) =>
        cloningFrom
          ? {
              ...task,
              listIndex:
                cloningFrom.vendorTemplateIndexes &&
                cloningFrom.vendorTemplateIndexes[task.id],
            }
          : task
      ),
      ...taskList,
    ].sort(sortObjectsBy("listIndex"));
    const listObjectTask = {};
    STAGE_LIST.forEach((stage) => {
      listObjectTask[stage] = templateTasks
        ? templateTasks.filter((item) => item.stage === stage)
        : [];
    });

    setListObject({
      ...listObjectTask,
    });
  }, [taskList, salesOrderTasks, factoryId, cloningFrom.id]);

  function sortListObjectTasks(listObject) {
    const taskByStage = {};
    let numberPOTask = 0;
    for (let i = 0; i < STAGE_LIST.length; i++) {
      const stage = STAGE_LIST[i];
      let { sortedTask, poSize } = sortObjectsByStage({
        stageTasks: listObject[stage],
        numberTask: numberPOTask,
      });
      taskByStage[stage] = sortedTask;
      numberPOTask = poSize;
    }

    setListObject({
      ...taskByStage,
    });
  }

  // ADD TASKS
  function handleAddTask({ stage, task = {} }) {
    const listObjectCpy = {
      ...listObject,
      [stage]: addTaskListStage({
        combinedLists,
        listObject,
        salesOrderTasks,
        stage,
        task,
      }),
    };
    sortListObjectTasks(listObjectCpy);
  }

  function onChangePOTask({ event, field, value }) {
    setChanged(true);
    const newListObject = updateTaskDataStage({
      event,
      field,
      listObject,
      salesOrderTasks,
      value,
    });
    setListObject({ ...newListObject });
  }

  function onDragEnd(result) {
    setChanged(true);
    const newList = moveTaskTemplate({ result, listObject });
    if (newList) {
      sortListObjectTasks(newList);
    }
  }

  // DELETE TASK
  function deleteTask(task) {
    setChanged(true);
    deleteDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.FACTORIES}/${factoryId}/${dbTables.MINI_PROJECT_TEMPLATE}/${task.id}`
      )
    );
  }

  function deleteTasksLocally(task, stage) {
    setChanged(true);
    let newList = Array.from(listObject[stage]);
    setDeletedTasks([
      ...deletedTasks,
      newList[newList.findIndex((item) => item.id === task.id)],
    ]);
    newList.splice(
      newList.findIndex((item) => item.id === task.id),
      1
    );
    newList = newList.map((item, index) => ({ ...item, listIndex: index + 1 }));
    sortListObjectTasks({ ...listObject, [stage]: newList });
  }

  function save() {
    if (onValidate(combinedLists)) {
      const newList = [
        ...listObject.PROPOSAL.map((task, index) => ({
          ...task,
          listIndex: index + 1,
        })),
        ...listObject.PRE_PRODUCTION.map((task, index) => ({
          ...task,
          listIndex: index + 1,
        })),
        ...listObject.PRODUCTION.map((task, index) => ({
          ...task,
          listIndex: index + 1,
        })),
        ...listObject.BOOKING_TRANSIT.map((task, index) => ({
          ...task,
          listIndex: index + 1,
        })),
        ...listObject.PAYMENT_BILLING.map((task, index) => ({
          ...task,
          listIndex: index + 1,
        })),
      ];
      setChanged(false);
      setListObject({
        [taskStages.PROPOSAL]: [],
        [taskStages.PRE_PRODUCTION]: [],
        [taskStages.PRODUCTION]: [],
        [taskStages.BOOKING_TRANSIT]: [],
        [taskStages.PAYMENT_BILLING]: [],
      });
      deletedTasks.forEach((task) => deleteTask(task));
      onSave(newList);
    }
  }

  return (
    <div className="sales-order-container" style={{ minWidth: 1700 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.keys(listObject).map((key) => {
          return (
            <Droppable droppableId={key} key={key}>
              {(provided, snapshot) => (
                <CategorySection
                  selectUsers={
                    companyUsers &&
                    companyUsers
                      .sort(sortObjectsBy("displayName", false))
                      .map((user) => ({
                        value: user.id,
                        label: "@" + user.displayName,
                      }))
                  }
                  companyUsers={companyUsers}
                  onDelete={deleteTasksLocally}
                  salesOrderTasks={[
                    ...salesOrderTasks.map((item, index) => ({
                      ...item,
                      listIndex: index + 1,
                    })),
                  ]}
                  purchaseOrderTasks={combinedLists.filter(
                    (task) => task.type !== typeOfTask.SALES_ORDER
                  )}
                  provided={provided}
                  selectOptions={combinedLists
                    .sort(sortObjectsBy("number"))
                    .sort(sortObjectsBy("type"), true)
                    .map((item) => ({
                      value: item.id,
                      label:
                        (item.type === typeOfTask.SALES_ORDER ? "S." : "P.") +
                        item.number +
                        " " +
                        item.description,
                      type: item.type,
                    }))}
                  onChangeSelect={onChangePOTask}
                  onAdd={(task) => handleAddTask({ stage: key, task })}
                  title={key}
                  list={listObject[key].sort(sortObjectsBy("listIndex"))}
                  completeList={Object.keys(listObject).map(
                    (key) => listObject[key]
                  )}
                />
              )}
            </Droppable>
          );
        })}
      </DragDropContext>
      <div
        className="action-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <WithConfirmation
          text={"Discard changes and go Back?"}
          disabled={!changed || disabled}
        >
          <CustomButton onClick={onCancel}>
            {"components-salesOrders-salesOrderTemplate-DiscardChanges"}
          </CustomButton>
        </WithConfirmation>
        <CustomButton type="success" onClick={save} disabled={disabled}>
          {"components-salesOrders-salesOrderTemplate-SavenewVersionofTemplate"}
        </CustomButton>
      </div>
    </div>
  );
}

export default SalesOrderContainer;
