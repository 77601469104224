import React, { useState, useEffect } from "react";
import { getOrderDiamond } from "../../helpers/timelineCalendar";
import TooltipTD from "../Tooltip/TooltipTD";
import ColorPicker from "./ColorPicker";
import ShipmentPicker from "./ShipmentPicker";
import { POBadgeContainerStyled } from "./styles";

function POBadge({
  purchaseOrder,
  onChangePOBadge,
  onMouseEnter,
  onMouseLeave,
  purchaseOrders,
  selectedShipments,
  onChangeSelectedShipments,
  shipmentOnHover = null,
}) {
  const [PObadgeBackground, setPObadgeBackground] = useState("white");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const timer =
      isHovered &&
      setTimeout(() => {
        setPObadgeBackground(purchaseOrder.color);
        onMouseEnter(purchaseOrder);
      }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isHovered]);

  useEffect(() => {
    if (shipmentOnHover !== null) {
      if (
        purchaseOrder.shipmentIds &&
        purchaseOrder.shipmentIds.includes(shipmentOnHover)
      ) {
        setPObadgeBackground(purchaseOrder.color);
      }
      return;
    } else if (PObadgeBackground !== "white") {
      setPObadgeBackground("white");
      return;
    }
  }, [shipmentOnHover]);

  return (
    <POBadgeContainerStyled
      className={"POBadgeContainer"}
      style={{
        borderColor: purchaseOrder.color,
        backgroundColor: PObadgeBackground,
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (PObadgeBackground !== "white") {
          setPObadgeBackground("white");
          onMouseLeave();
        }
        setIsHovered(false);
      }}
    >
      <input
        className="check-box"
        type="checkbox"
        checked={purchaseOrder.isShownInTimeline}
        value={purchaseOrder.isShownInTimeline}
        onChange={() =>
          onChangePOBadge({
            purchaseOrder: {
              ...purchaseOrder,
              isShownInTimeline: !purchaseOrder.isShownInTimeline,
            },
            purchaseOrders,
          })
        }
      />
      {getOrderDiamond({ order: purchaseOrder, styles: {}, isOutlined: true })}
      <div className="description">
        <TooltipTD
          label={`${purchaseOrder.number} - ${
            purchaseOrder.factoryName || "factoryName"
          }`}
        >
          {purchaseOrder.number} - {purchaseOrder.factoryName || "factoryName"}
        </TooltipTD>
      </div>
      {selectedShipments.length > 0 && (
        <ShipmentPicker
          selectedShipments={selectedShipments}
          onChangeSelectedShipments={onChangeSelectedShipments}
        />
      )}
      <ColorPicker
        milestone={purchaseOrder}
        onChangeMilestone={(value) => {
          onChangePOBadge({ purchaseOrder: value, purchaseOrders });
          setPObadgeBackground("white");
        }}
      />
    </POBadgeContainerStyled>
  );
}

export default POBadge;
