import moment from "moment";
import { typeOfTask } from "../api/types/dbTables";
import { STAGE_LIST } from "./constants";
import { sortObjectsBy } from "./helpers";

function getTaskDuration({ startDate, finishDate }) {
  if (!startDate || !finishDate) {
    return 0;
  }
  const duration = moment(finishDate).diff(startDate, "days") + 1;
  return duration;
}

const getCorrectTimezone = ({
  date,
  isShowingDate = false,
  isServerTime = false,
}) => {
  const currentUTC = moment(date).format("Z");
  let minutes = moment.duration(currentUTC).asMinutes();
  if (isShowingDate) {
    minutes = -minutes;
  }
  if (isServerTime) {
    minutes = minutes * -1;
  }
  return moment(date).add(minutes, "minutes");
};

export function taskCompletionVerifier({ task }) {
  if (!task || !task.startDate || !task.finishDate) {
    console.log("taskCompletionVerifier ERROR HANDLING");
  }
  let { finishDate, startDate } = task;

  const offset = moment()
    .startOf("day")
    .diff(moment(finishDate).startOf("day"), "days");

  let startDateOffset = moment()
    .startOf("day")
    .diff(startDate, "days");

  startDateOffset = startDateOffset > 0 ? 0 : startDateOffset;
  if (offset === 0) {
    const newFinishDate = getCorrectTimezone({
      date: moment(task.finishDate)
        .endOf("day")
        .valueOf(),
    }).valueOf();
    const newStartDate = getCorrectTimezone({
      date: moment(task.startDate)
        .startOf("day")
        .valueOf(),
    }).valueOf();
    return {
      duration: task.duration,
      finishDate: newFinishDate,
      startDate: newStartDate,
    };
  }
  if (offset > 0) {
    const newFinishDate = getCorrectTimezone({
      date: moment(task.finishDate)
        .add(offset, "days")
        .valueOf(),
    }).valueOf();

    const newStartDate = getCorrectTimezone({
      date: moment(task.startDate).valueOf(),
    }).valueOf();
    return {
      duration: task.duration + offset,
      finishDate: newFinishDate,
      startDate: newStartDate,
    };
  } else {
    const newFinishDate = getCorrectTimezone({
      date: moment(task.finishDate)
        .add(offset, "days")
        .valueOf(),
    }).valueOf();
    const newStartDate = getCorrectTimezone({
      date: moment(task.startDate)
        .add(startDateOffset, "days")
        .valueOf(),
    }).valueOf();
    finishDate = newFinishDate;
    startDate = newStartDate;
    const duration = getTaskDuration({
      startDate,
      finishDate,
    });
    return {
      duration,
      finishDate,
      startDate,
    };
  }
}

export function getDateByTimezone({
  date,
  format = "M/D/YY",
  timestamp = false,
  isServerTime,
}) {
  const finishDate = getCorrectTimezone({
    date,
    isShowingDate: true,
    isServerTime,
  });
  if (timestamp) {
    return finishDate.valueOf();
  } else {
    return finishDate.format(format);
  }
}

export { getCorrectTimezone };

export function getTasksSortedByListIndex({ tasks = [], purchaseOrders = [] }) {
  let tasksCpy = [...tasks.filter((task) => !task.isAdHocTask)];
  const sortedTasks = [];
  let salesOrderTasks = tasksCpy.filter(
    (task) => task.type === typeOfTask.SALES_ORDER
  );

  let maximumTaskIndexesByPO = {};
  let lastMaximumSalesOrderTaskListIndex = 0;
  let lastSalesOrderTasksListIndex = {};
  let isNextTaskInDifferentStage = false;
  purchaseOrders.forEach((po) => {
    maximumTaskIndexesByPO[po.id] = 0;
    lastSalesOrderTasksListIndex[po.id] = 0;
  });
  let reviewedStages = {};
  salesOrderTasks = salesOrderTasks.map((task) => ({
    ...task,
    number: task.number || task.listIndex,
  }));
  salesOrderTasks
    .sort(sortObjectsBy("number", false))
    .forEach((salesOrderTask, salesOrderTaskIndex) => {
      purchaseOrders.sort(sortObjectsBy("number", false)).forEach((po) => {
        // console.log(
        //   "POOOOO ",
        //   po.vendorTemplateIndexes,
        //   salesOrderTask.taskTemplateId
        // );
        reviewedStages[salesOrderTask.stage] = true;
        const salesOrderTaskListIndex =
          (po.vendorTemplateIndexes &&
            po.vendorTemplateIndexes[salesOrderTask.taskTemplateId]) ||
          salesOrderTask.listIndex;
        const tasksInTheStage = tasksCpy.filter(
          (task) =>
            task.stage === salesOrderTask.stage &&
            task.type !== typeOfTask.SALES_ORDER &&
            task.purchaseOrderId === po.id
        );

        const aboveTasks = tasksInTheStage.filter((taskCpy) => {
          const taskCpyListIndex =
            (po.vendorTemplateIndexes &&
              po.vendorTemplateIndexes[taskCpy.id]) ||
            taskCpy.listIndex;

          if (
            taskCpyListIndex < salesOrderTaskListIndex &&
            taskCpyListIndex > lastSalesOrderTasksListIndex[po.id]
          ) {
            return true;
          }
          return false;
        });
        maximumTaskIndexesByPO[po.id] = aboveTasks.length;
        // console.log(
        //   "ABOVE TASKS ",
        //   salesOrderTask.description,
        //   " ++ ",
        //   aboveTasks.length
        // );
        aboveTasks
          .sort(sortObjectsBy("listIndex", false))
          .forEach((el, index) => {
            sortedTasks.push({
              ...el,
              sortedListIndex: lastMaximumSalesOrderTaskListIndex + index + 1,
            });
          });

        lastSalesOrderTasksListIndex[po.id] = salesOrderTaskListIndex;
      });
      const nextSalesOrderTask = salesOrderTasks.sort(
        sortObjectsBy("number", false)
      )[salesOrderTaskIndex + 1];
      isNextTaskInDifferentStage =
        nextSalesOrderTask && salesOrderTask.stage !== nextSalesOrderTask.stage;
      let maximumPOTaskIndex = 0;
      Object.keys(maximumTaskIndexesByPO).forEach((key) => {
        if (maximumTaskIndexesByPO[key] > maximumPOTaskIndex) {
          maximumPOTaskIndex = maximumTaskIndexesByPO[key];
        }
      });
      lastMaximumSalesOrderTaskListIndex =
        lastMaximumSalesOrderTaskListIndex + maximumPOTaskIndex + 1;

      if (
        isNextTaskInDifferentStage ||
        salesOrderTaskIndex === salesOrderTasks.length - 1
      ) {
        purchaseOrders.sort(sortObjectsBy("number", false)).forEach((po) => {
          const salesOrderTaskListIndex =
            (po.vendorTemplateIndexes &&
              po.vendorTemplateIndexes[salesOrderTask.taskTemplateId]) ||
            salesOrderTask.listIndex;
          const tasksInTheStage = tasksCpy.filter(
            (task) =>
              task.stage === salesOrderTask.stage &&
              task.type !== typeOfTask.SALES_ORDER &&
              task.purchaseOrderId === po.id
          );
          const belowTasks = tasksInTheStage.filter((taskCpy) => {
            const taskCpyListIndex =
              (po.vendorTemplateIndexes &&
                po.vendorTemplateIndexes[taskCpy.id]) ||
              taskCpy.listIndex;
            if (taskCpyListIndex > salesOrderTaskListIndex) {
              return true;
            }
            return false;
          });
          maximumTaskIndexesByPO[po.id] = belowTasks.length;

          belowTasks
            .sort(sortObjectsBy("listIndex", false))
            .forEach((el, index) => {
              sortedTasks.push({
                ...el,
                sortedListIndex: lastMaximumSalesOrderTaskListIndex + index + 1,
              });
            });
          lastSalesOrderTasksListIndex[po.id] = 0;
          maximumTaskIndexesByPO[po.id] = 0;
        });

        purchaseOrders.forEach((po) => {
          lastSalesOrderTasksListIndex[po.id] = 0;
        });

        isNextTaskInDifferentStage = false;
      }
      sortedTasks.push({
        ...salesOrderTask,
        sortedListIndex: lastMaximumSalesOrderTaskListIndex,
      });
    });

  const stagesToReview = STAGE_LIST.filter((stage) => !reviewedStages[stage]);
  stagesToReview.forEach((reviewStage) => {
    const tasksFromStage = tasks.filter(
      (task) => task.stage === reviewStage && !task.isAdHocTask
    );
    tasksFromStage.forEach((adHocTask) => {
      sortedTasks.push({
        ...adHocTask,
        sortedListIndex: adHocTask.listIndex,
      });
      return;
    });
  });

  const salesOrderAdHocTasks = tasks.filter(
    (task) => task.isAdHocTask && task.type === typeOfTask.SALES_ORDER
  );
  salesOrderAdHocTasks
    .sort(sortObjectsBy("listIndex", false))
    .forEach((adHocTask) => {
      const salesOrderTasksByPhase = sortedTasks.filter(
        (el) =>
          el.stage === adHocTask.stage && el.type === typeOfTask.SALES_ORDER
      );
      const oldSalesOrderTask = salesOrderTasksByPhase.sort(
        sortObjectsBy("sortedListIndex", true)
      )[0] || { sortedListIndex: 0 };

      sortedTasks.push({
        ...adHocTask,
        sortedListIndex: oldSalesOrderTask.sortedListIndex + 1,
      });
      return;
    });

  const purchaseOrderAdHocTasks = tasks.filter(
    (task) => task.isAdHocTask && task.type === typeOfTask.PURCHASE_ORDER
  );
  purchaseOrderAdHocTasks
    .sort(sortObjectsBy("listIndex", false))
    .forEach((adHocTask) => {
      const purchaseOrderTasksByPhase = sortedTasks.filter(
        (el) =>
          el.stage === adHocTask.stage && el.type === typeOfTask.PURCHASE_ORDER
      );
      const oldPurchaseOrderTask = purchaseOrderTasksByPhase.sort(
        sortObjectsBy("sortedListIndex", true)
      )[0] || { sortedListIndex: 0 };

      sortedTasks.push({
        ...adHocTask,
        sortedListIndex: oldPurchaseOrderTask.sortedListIndex + 1,
      });
      return;
    });

  const shipmentAdHocTasks = tasks.filter(
    (task) => task.isAdHocTask && task.type === typeOfTask.SHIPMENT
  );
  shipmentAdHocTasks
    .sort(sortObjectsBy("listIndex", false))
    .forEach((adHocTask) => {
      const shipmentTasksByPhase = sortedTasks.filter(
        (el) =>
          el.stage === adHocTask.stage &&
          (el.type === typeOfTask.SHIPMENT ||
            el.type === typeOfTask.PURCHASE_ORDER)
      );
      const oldShipmentTask = shipmentTasksByPhase.sort(
        sortObjectsBy("sortedListIndex", true)
      )[0] || { sortedListIndex: 0, listIndex: 0 };

      sortedTasks.push({
        ...adHocTask,
        sortedListIndex:
          oldShipmentTask.sortedListIndex -
          oldShipmentTask.listIndex +
          adHocTask.listIndex,
      });
      return;
    });

  return sortedTasks;
}
