import React from "react";
import PropTypes from "prop-types";
import MaximizeIcon from "@mui/icons-material/Maximize";
import { getUserAvatar } from "../../helpers/users";
import { activityScopes } from "../../helpers/activitiesStream";
import { PO_STATUS } from "../../helpers/constants";
import { VoidLabel } from "../../helpers/iconSvgPath";
import { useCompanyId, useQueryParams } from "../../hooks";
import {
  onChangeQueryParamsv2,
  QUERY_PARAMS_SCOPE,
} from "../../helpers/orderDashboard";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../PurchaseOrderDashboard/sections/helpers";
import { useOrderDashboardActualView } from "../../hooks/user";
import { NavPickerPopoverStyled } from "./styles";
import ShipmentPopover from "./ShipmentPopover";
import { getDashboardSearchPath } from "../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import { cx } from "@emotion/css";

function NavPickerv2({
  anchorEl,
  open,
  onClose,
  list,
  scope,
  selectedId,
  handleDateActivities = () => {},
  user,
  hiddenAvatar = false,
  sizeNumber,
  sizeSubtitle,
}) {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const actualView = useOrderDashboardActualView();
  const hasMention = list.some((order) => {
    const userMentions = order.userMentions || {};
    return userMentions[user.id];
  });

  const getMessageIcon = (item) => {
    const hasMention = item.userMentions[user.id];
    return (
      <div
        onClick={(ev) => {
          if (hasMention) {
            redirectAction({
              ...item,
              sectionPath: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
            });
            setTimeout(() => {
              handleDateActivities();
            }, 800);
            onClose();
            ev.stopPropagation();
          }
        }}
        className="avatarContainer"
        style={{
          minWidth: !hiddenAvatar ? 32 : 5,
        }}
      >
        {!hiddenAvatar && hasMention
          ? getUserAvatar({
              user,
              styles: {
                width: 20,
                height: 20,
                outline: "2px solid #000",
                fontSize: 10,
              },
              hasToolTip: false,
              className: "avatarNavLink",
            })
          : ""}
      </div>
    );
  };

  const redirectAction = (item) => {
    let section = ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION;
    if (scope === "purchase-order-tab-") {
      if (actualView !== ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION) {
        section = ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER;
      }
      section = item.sectionPath || section;
      onChangeQueryParamsv2({
        scope: QUERY_PARAMS_SCOPE.PURCHASE_ORDER,
        purchaseOrderId: item.id,
        queryParams: { ...queryParams, section },
        companyId: companyId,
        navigate,
        poRedirect: item,
      });
    } else if (scope === "shipment-tab-") {
      if (actualView !== ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION) {
        section = ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT;
      }
      section = item.sectionPath || section;
      onChangeQueryParamsv2({
        scope: QUERY_PARAMS_SCOPE.SHIPMENT,
        shipmentId: item.id,
        queryParams: { ...queryParams, section },
        companyId: companyId,
        navigate,
      });
    } else if (scope === "SALES_ORDER") {
      onChangeQueryParamsv2({
        scope: QUERY_PARAMS_SCOPE.SALES_ORDER,
        salesOrderId: item.id,
        queryParams: {
          ...queryParams,
          section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
        },
        companyId: companyId,
        navigate,
      });
    }

    onClose();
  };

  return (
    <NavPickerPopoverStyled
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: "paper" }}
      className="navPickerPopoverContainer"
      getContentAnchorEl={null}
      onClose={() => onClose()}
    >
      {list.map((item) => {
        const selectedStyle = item.id === selectedId ? "selectedStyle" : "";
        return (
          <div
            key={item.id + "navTitle"}
            className={cx("listContainer", selectedStyle)}
            onClick={(ev) => {
              redirectAction(item);
              ev.stopPropagation();
            }}
          >
            {scope !== activityScopes.SALES_ORDER &&
              hasMention &&
              getMessageIcon(item)}

            <span
              className="itemListNumber"
              style={{
                marginRight: item.divider ? 0 : 20,
                width: sizeNumber ? sizeNumber * 8.5 + 20 : 40,
              }}
            >
              {item.name}
            </span>
            {item.divider && <MaximizeIcon className="item-divider" />}
            <span
              className="item-subtitle"
              style={{
                width: sizeSubtitle ? sizeSubtitle * 9.5 + 20 : 40,
              }}
            >
              {item.subTitle}
            </span>
            {item.status === PO_STATUS.VOIDED && <VoidLabel />}
            {item.shipmentIds && item.shipmentIds.length > 0 && (
              <ShipmentPopover
                item={item}
                onShipmentChange={({ purchaseOrderId, shipmentId }) => {
                  onClose();
                  let section =
                    ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION;
                  if (
                    actualView !==
                    ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
                  ) {
                    section = ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT;
                  }
                  setTimeout(() => {
                    navigate(
                      getDashboardSearchPath({
                        ...queryParams,
                        purchaseOrderId,
                        shipmentId,
                        section,
                      }),
                      { replace: true }
                    );
                  }, 1000);
                }}
              />
            )}
          </div>
        );
      })}
    </NavPickerPopoverStyled>
  );
}

NavPickerv2.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NavPickerv2;
