import React, { useState, useEffect } from "react";
import { getProgressValue } from "../../helpers/helpers";
import { useUser } from "../../hooks/user";
import {
  SALES_ORDER_DIAMOND_STATUS,
  SALES_ORDER_STATUS,
  SHIPMENT_STATUS,
} from "../../helpers/constants";

import TooltipTD from "../Tooltip/TooltipTD";
import { useFavorites, useFavoritesList } from "../../hooks";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import FavoriteStar from "../../assets/flag-icons/favorite-star.png";
import FavoriteStarInactive from "../../assets/flag-icons/star_outline.png";
import LateIcon from "../../assets/flag-icons/red_diamond.svg";
import NearDueIcon from "../../assets/flag-icons/orange_diamond.svg";
import {
  arrPromisesResolved,
  getRemainingPromises,
} from "../../helpers/orderDashboard";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import { CircularProgress } from "@mui/material";

export function TitleNavDetail({
  currentSalesOrder,
  purchaseOrders,
  companyId,
}) {
  const [shipmentsProgress, setShipmentsProgress] = useState({
    totalTasks: 0,
    completedTasks: 0,
  });
  const [isHovered, setIsHovered] = useState(false);
  const [loadingShipments, setLoadingShipments] = useState(false);

  useEffect(() => {
    const timer =
      isHovered &&
      setTimeout(() => {
        updateShipmentProgress({ purchaseOrders });
      }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isHovered]);

  const user = useUser();
  const favorites = useFavoritesList({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const favoritesDB = useFavorites({ dbTable: dbTables.SALES_ORDER_FAVORITES });
  const salesOrderIsVoid =
    currentSalesOrder.status === SALES_ORDER_STATUS.VOIDED;
  const salesOrderDiamondStatus = currentSalesOrder.diamondStatus;
  const diamondImage = {
    [SALES_ORDER_DIAMOND_STATUS.LATE]: LateIcon,
    [SALES_ORDER_DIAMOND_STATUS.NEAR_DUE]: NearDueIcon,
  };
  const hasDiamond = !!diamondImage[salesOrderDiamondStatus];

  async function updateShipmentProgress({ purchaseOrders = [] }) {
    setLoadingShipments(true);
    const shipmentVectorIds = getRemainingPromises({
      list: purchaseOrders,
      field: "shipmentIds",
      actualList: [],
    });
    let remainingShipmentPromises = [];
    shipmentVectorIds.forEach((shipmentIdsArr) => {
      if (shipmentIdsArr.length > 0) {
        const salesOrderPromise = getDocs(
          query(
            collection(
              firestore,
              `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
            ),
            where("id", "in", shipmentIdsArr)
          )
        );

        remainingShipmentPromises.push(salesOrderPromise);
      }
    });

    const arrResolved = await arrPromisesResolved({
      arrPromises: remainingShipmentPromises,
    });
    const shipmentsDB = [...arrResolved];
    let totalShipmentsTasks = 0;
    let totalShipmentsCompletedTasks = 0;
    shipmentsDB
      .filter((shipment) => shipment.status !== SHIPMENT_STATUS.VOIDED)
      .forEach((shipment) => {
        totalShipmentsTasks += parseInt(shipment.totalTasks);
        totalShipmentsCompletedTasks += parseInt(shipment.completedTasks);
      });
    setShipmentsProgress({
      totalTasks: totalShipmentsTasks,
      completedTasks: totalShipmentsCompletedTasks,
    });
    setLoadingShipments(false);
  }

  const totalTasks =
    parseInt(currentSalesOrder.totalTasks) +
    parseInt(shipmentsProgress.totalTasks);
  const completedTasks =
    parseInt(currentSalesOrder.completedTasks) +
    parseInt(shipmentsProgress.completedTasks);
  return (
    <div
      className="detailInformation"
      style={{
        justifyContent: !hasDiamond ? "center" : "space-between",
      }}
    >
      {favorites
        .map((favoriteId) => favoriteId)
        .includes(currentSalesOrder.id) ? (
        <img
          src={FavoriteStar}
          width={16}
          height={15}
          onClick={() =>
            removeFavorite({ favoritesDB, id: currentSalesOrder.id })
          }
          className="favorite-star-cell"
          alt="startFav"
        />
      ) : (
        <img
          src={FavoriteStarInactive}
          width={16}
          height={15}
          onClick={() =>
            addFavorite({
              dbTable: dbTables.SALES_ORDER_FAVORITES,
              favoritesDB,
              id: currentSalesOrder.id,
              user,
            })
          }
          alt="star"
          className="hiddenStar"
        />
      )}
      {!salesOrderIsVoid && hasDiamond && (
        <div
          style={{
            height: 17,
            width: 12,
            display: "flex",
          }}
        >
          <img
            src={diamondImage[salesOrderDiamondStatus]}
            alt="diamond"
            height={17}
            width={12}
          />
        </div>
      )}

      <TooltipTD
        label={completedTasks + "/" + totalTasks}
        style={{ height: 14, width: 14, display: "flex" }}
      >
        <CircularProgress
          variant="determinate"
          value={
            +getProgressValue({
              ...currentSalesOrder,
              totalTasks: totalTasks,
              completedTasks: completedTasks,
            })
          }
          thickness={16}
          style={{
            transform: "rotate(270deg)",
            color: "#39F",
            background: "#f2f2f2",
            borderRadius: "100%",
            cursor: loadingShipments ? "progress" : "default",
          }}
          size={14}
          onMouseOverCapture={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </TooltipTD>
    </div>
  );
}
