import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";
// import { getFunctions, httpsCallableFromURL } from "firebase/functions";

import {
  globalEnvironment,
  globalEnvironments,
} from "../constants/globalVariables";

let config = null;
switch (globalEnvironment) {
  case globalEnvironments.DEV:
    config = {
      apiKey: "AIzaSyBVso6EA_O31fmtj10TMW7Xj_7zZ8ZbHxw",
      authDomain: "tradedash-dev.firebaseapp.com",
      databaseURL: "https://tradedash-dev.firebaseio.com",
      projectId: "tradedash-dev",
      storageBucket: "tradedash-dev.appspot.com",
      messagingSenderId: "725773308876",
      appId: "1:725773308876:web:919251bae9a382bf",
    };
    break;

  case globalEnvironments.TEST:
    config = {
      apiKey: "AIzaSyBSLXk0C0rAbKB9is7yHtC0bhocuLvAkN8",
      authDomain: "tradedash-test.firebaseapp.com",
      databaseURL: "https://tradedash-test.firebaseio.com",
      projectId: "tradedash-test",
      storageBucket: "tradedash-test.appspot.com",
      messagingSenderId: "90704287646",
      appId: "1:90704287646:web:2eb9f177fd9f28ed",
    };
    break;
  case globalEnvironments.STAGING:
    config = {
      apiKey: "AIzaSyAdUlsc-vauG7Mk8TXV0SFbTrCS7uufqRI",
      authDomain: "tradedash-staging.firebaseapp.com",
      databaseURL: "https://tradedash-staging.firebaseio.com",
      projectId: "tradedash-staging",
      storageBucket: "tradedash-staging.appspot.com",
      messagingSenderId: "1010391279041",
      appId: "1:1010391279041:web:9b1a6a47f13366db",
    };
    break;
  case globalEnvironments.TRAINING:
    config = {
      apiKey: "AIzaSyDQM81Suomdjvq2dmNT5fye2DychaHwj34",
      authDomain: "tradedash-training-site.firebaseapp.com",
      databaseURL: "https://tradedash-training-site.firebaseio.com",
      projectId: "tradedash-training-site",
      storageBucket: "tradedash-training-site.appspot.com",
      messagingSenderId: "815610082595",
      appId: "1:815610082595:web:293980d82eaa44f8e8c896",
      measurementId: "G-H1ERWETM1N",
    };
    break;
  case globalEnvironments.EXHART_PRODUCTION:
    config = {
      apiKey: "AIzaSyB-SbfD_v60mc-cbjGBvH7jLrM1jgtldHE",
      authDomain: "tradedash-exhart.firebaseapp.com",
      projectId: "tradedash-exhart",
      storageBucket: "tradedash-exhart.appspot.com",
      messagingSenderId: "523278442473",
      appId: "1:523278442473:web:54bac27b562a641bbb7578",
      measurementId: "G-FETLZ3PRKE",
    };
    break;
  default:
    config = {
      apiKey: "AIzaSyBVso6EA_O31fmtj10TMW7Xj_7zZ8ZbHxw",
      authDomain: "tradedash-dev.firebaseapp.com",
      databaseURL: "https://tradedash-dev.firebaseio.com",
      projectId: "tradedash-dev",
      storageBucket: "tradedash-dev.appspot.com",
      messagingSenderId: "725773308876",
      appId: "1:725773308876:web:919251bae9a382bf",
    };
}
const app = initializeApp(config);
const firestore = getFirestore(app);
const firestoreValue = firestore.FieldValue;
const auth = getAuth(app);
const authProvider = auth;
const storage = getStorage(app);
const functions = getFunctions(app);
const performanceFirebase = getPerformance(app);
const analytics = getAnalytics(app);

export {
  firestore,
  firestoreValue,
  authProvider,
  auth,
  storage,
  functions,
  performanceFirebase,
  analytics,
};
