import React, { useState, useEffect } from "react";
import CustomButton from "../Buttons/CustomButton";
import "react-phone-input-2/lib/plain.css";
import { reportFields } from "../../helpers/constants";
import { CreateReportFormContainerStyled } from "./styles";
import { isTradeDashEmployee, sortObjectsBy } from "../../helpers/helpers";
import IntlMessages from "../../util/IntlMessages";
import ModalPermissionUsers from "../Documents/ModalPermissionUsers";
import { getNewToolTipByPermissionGroup } from "../../helpers/purchaseOrderList";
import { CustomTooltip } from "../Tooltip/CustomToolTip";
import { Grid, TextareaAutosize } from "@mui/material";

function CreateReportForm({
  report,
  isUpdating,
  onChange,
  onSubmit = () => {},
  handleClose,
  companyUsers = [],
  permissionGroupsDB = [],
  user,
}) {
  const [permissions, setPermissions] = useState({
    permissionUsers: [],
    permissionGroups: [...permissionGroupsDB],
  });

  useEffect(() => {
    let permissionGroupsCpy = [
      ...permissions.permissionGroups.map((group) => ({
        ...group,
        enable: true,
      })),
    ];
    if (report.permissionGroups && report.permissionUsers) {
      permissionGroupsCpy = permissionGroupsCpy.map((group) => {
        if (report.permissionGroups[group.id]) {
          return { ...group, checked: true, enable: true };
        }
        return { ...group, enable: true, checked: false };
      });
      let permissionUsers = [];
      Object.keys(report.permissionUsers).forEach((key) => {
        const user = companyUsers.find((user) => user.id === key);
        if (user && !permissionUsers.find((pUser) => pUser.id === user.id)) {
          permissionUsers.push(user);
        }
      });
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers,
      });
    } else {
      setPermissions({
        permissionUsers: [],
        permissionGroups: [...permissionGroupsDB],
      });
    }
  }, [report]);

  function getUserSelected(permissions) {
    const userSelected = [...permissions.permissionUsers];
    permissions.permissionGroups.forEach((group) => {
      if (group.checked) {
        Object.keys(group.users).forEach((userId) => {
          const user = companyUsers.find((user) => user.id === userId);
          if (user && !userSelected.find((el) => el.id === userId)) {
            userSelected.push(user);
          }
        });
      }
    });
    return userSelected;
  }

  function handleChange(value, lastValue) {
    value = value.filter((val) => !!val);
    const newElementA = value.filter(comparer(lastValue));
    const newElementB = lastValue.filter(comparer(value));
    const newElement = newElementA.concat(newElementB)[0];
    if (value.length > lastValue.length) {
      const permissionUsersCpy = [...permissions.permissionUsers];
      permissionUsersCpy.push(newElement);
      setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
    } else {
      const { permissionGroups } = permissions;
      if (permissionGroups.filter((group) => group.checked).length <= 0) {
        let permissionUsersCpy = [...permissions.permissionUsers];
        permissionUsersCpy = permissionUsersCpy.filter(
          (cUser) => cUser.id !== newElement.id
        );
        setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
      } else {
        onDisolveGroups(newElement);
      }
    }
  }

  function comparer(array) {
    return function (current) {
      return (
        array.filter((elem) => {
          return elem.id === current.id;
        }).length === 0
      );
    };
  }

  function onDisolveGroups(user) {
    let permissionGroupsCpy = [...permissions.permissionGroups];
    let permissionUsersCpy = [...permissions.permissionUsers];
    let allUsersToMove = {};
    let userBelongsToSomeGroup = false;
    permissionGroupsCpy = permissionGroupsCpy.map((permission) => {
      if (
        !!permission.checked &&
        (permission.users[user.id] || permission.users[user.id] === false)
      ) {
        userBelongsToSomeGroup = true;
        let usersCpy = permission.users;
        usersCpy[user.id] = false;
        Object.keys(usersCpy).forEach((key) => {
          if (usersCpy[key]) {
            allUsersToMove[key] = usersCpy[key];
          }
        });
        return { ...permission, checked: false };
      } else return permission;
    });
    if (!userBelongsToSomeGroup) {
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy.filter(
          (cUser) => cUser.id !== user.id
        ),
      });
    } else if (Object.keys(allUsersToMove).length > 0) {
      onMoveUsersFromDisolvedGroup(allUsersToMove, permissionGroupsCpy);
    } else {
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy.filter(
          (cUser) => cUser.id !== user.id
        ),
      });
    }
  }

  function onMoveUsersFromDisolvedGroup(allUsersToMove, permissionGroupsCpy) {
    permissionGroupsCpy
      .filter((permission) => permission.checked)
      .forEach((permission) => {
        Object.keys(permission.users).forEach(
          (key) => delete allUsersToMove[key]
        );
      });
    const permissionUsersCpy = [...permissions.permissionUsers];
    Object.keys(allUsersToMove).forEach((key) => {
      const user = companyUsers.find((user) => user.id === key);
      if (user && !permissionUsersCpy.find((pUser) => pUser.id === user.id)) {
        permissionUsersCpy.push(user);
      }
    });
    setPermissions({
      permissionGroups: permissionGroupsCpy,
      permissionUsers: permissionUsersCpy,
    });
  }

  function handleChangeGroups(permission, checked) {
    const { permissionGroups } = permissions;
    let permissionGroupsCpy = [...permissionGroups];
    permissionGroupsCpy = permissionGroupsCpy.map((elem) => {
      if (elem.id === permission.id) {
        if (checked) {
          let usersCpy = { ...elem.users };
          Object.keys(usersCpy).map((key) => (usersCpy[key] = true));
          return { ...elem, checked, users: usersCpy };
        } else {
          return { ...elem, checked };
        }
      } else {
        return elem;
      }
    });
    if (checked) {
      let permissionUsersCpy = [...permissions.permissionUsers];
      Object.keys(permission.users).forEach((key) => {
        permissionUsersCpy = permissionUsersCpy.filter(
          (user) => user.id !== key
        );
      });
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy,
      });
    } else {
      setPermissions({
        ...permissions,
        permissionGroups: permissionGroupsCpy,
      });
    }
  }

  function getUserAndGroupPermissionsObj() {
    let permissionUsersObj = {};
    let permissionGroupsObj = {};
    const { permissionUsers, permissionGroups } = permissions;
    permissionUsers.forEach((user) => (permissionUsersObj[user.id] = true));
    permissionGroups.forEach((group) => {
      if (!!group.checked) {
        permissionGroupsObj[group.id] = true;
      }
    });
    return {
      permissionUsers: permissionUsersObj,
      permissionGroups: permissionGroupsObj,
    };
  }

  function onChangeReport({ field, value }) {
    const { permissionGroups, permissionUsers } =
      getUserAndGroupPermissionsObj();
    onChange({
      value: { ...report, permissionGroups, permissionUsers, [field]: value },
    });
  }

  return (
    <CreateReportFormContainerStyled
      onSubmit={(ev) => {
        const { permissionGroups, permissionUsers } =
          getUserAndGroupPermissionsObj();
        onSubmit({
          ev,
          currentReport: { ...report, permissionGroups, permissionUsers },
        });
        ev.preventDefault();
      }}
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          handleClose();
        }
      }}
      className="formContainer"
    >
      <Grid
        container
        spacing={5}
        alignItems="center"
        justifyContent="center"
        style={{
          maxHeight: "calc(100vh - 188px)",
          overflowY: "scroll",
          width: "auto",
          margin: 0,
          display: "block",
          padding: 20,
        }}
      >
        <div className={"itemFormContainer"}>
          <label>Name</label>
          <TextareaAutosize
            value={report[reportFields.NAME]}
            onChange={(ev) =>
              onChangeReport({
                value: ev.target.value,
                field: reportFields.NAME,
              })
            }
            placeholder="Report's name"
            required={true}
            autoFocus={true}
            minRows={2}
            style={{ width: 350 }}
            maxLength={140}
          />
        </div>

        <div className={"itemFormContainer"}>
          <label>Description</label>
          <TextareaAutosize
            value={report[reportFields.DESCRIPTION]}
            onChange={(ev) =>
              onChangeReport({
                value: ev.target.value,
                field: reportFields.DESCRIPTION,
              })
            }
            placeholder="Report's description"
            required={false}
            autoFocus={false}
            minRows={2}
            style={{ width: 350 }}
          />
        </div>

        {isTradeDashEmployee(user) && (
          <div className={"itemFormContainer"}>
            <label>Report embed</label>
            <TextareaAutosize
              value={report[reportFields.REPORT_EMBED]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.REPORT_EMBED,
                })
              }
              placeholder="Report's embed"
              required={true}
              autoFocus={false}
              minRows={2}
              style={{ width: 350 }}
            />
          </div>
        )}

        {isTradeDashEmployee(user) && (
          <div className={"itemFormContainer"}>
            <label>Loom embed</label>
            <TextareaAutosize
              value={report[reportFields.LOOM_EMBED]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.LOOM_EMBED,
                })
              }
              placeholder="Loom's embed"
              required={false}
              autoFocus={false}
              minRows={2}
              style={{ width: 350 }}
            />
          </div>
        )}

        {isTradeDashEmployee(user) && (
          <div className={"itemFormContainer"}>
            <label>Update API URL</label>
            <TextareaAutosize
              value={report[reportFields.URL_TO_UPDATE_REPORT]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.URL_TO_UPDATE_REPORT,
                })
              }
              placeholder="Update API URL"
              required={false}
              autoFocus={false}
              minRows={2}
              style={{ width: 350 }}
            />
          </div>
        )}

        {isTradeDashEmployee(user) && (
          <div className={"itemFormContainer"}>
            <label>Link to download</label>
            <TextareaAutosize
              value={report[reportFields.URL_TO_DOWNLOAD_REPORT]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.URL_TO_DOWNLOAD_REPORT,
                })
              }
              placeholder="Link to download"
              required={false}
              autoFocus={false}
              minRows={2}
              style={{ width: 350 }}
            />
          </div>
        )}

        {isTradeDashEmployee(user) && (
          <div className={"itemFormContainer"}>
            <label>Notes</label>
            <TextareaAutosize
              value={report[reportFields.NOTES]}
              onChange={(ev) =>
                onChangeReport({
                  value: ev.target.value,
                  field: reportFields.NOTES,
                })
              }
              placeholder="Notes"
              required={false}
              autoFocus={false}
              minRows={2}
              style={{ width: 350 }}
            />
          </div>
        )}

        <div className={"viewPermissionsLabelContainer"}>
          <label>
            {
              <IntlMessages id="components.documents.modalpermission.viewpermission" />
            }
          </label>
        </div>
        <div className={"permissionsGroupContainer"}>
          <div className={"permissionsGroupContent"}>
            <div className={"multiselect"}>
              {permissions.permissionGroups
                .sort(sortObjectsBy("name", false))
                .map((permission, index) => (
                  <div
                    key={permission.id + index}
                    className={"permissionContent"}
                    onClick={(ev) => {
                      if (!!permission.enable) {
                        handleChangeGroups(permission, !permission.checked);
                      }
                      ev.stopPropagation();
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={permission.checked}
                      disabled={!permission.enable}
                      className="permissionCheck"
                    />
                    <CustomTooltip
                      label={getNewToolTipByPermissionGroup({
                        permission,
                        users: companyUsers,
                      })}
                      style={{ color: permission.enable ? "" : "#ccc" }}
                    >
                      {permission.name}
                    </CustomTooltip>
                  </div>
                ))}
            </div>
          </div>
          <div className={"viewPermissionOnUpload"}>
            <ModalPermissionUsers
              users={companyUsers}
              userSelected={getUserSelected(permissions)}
              handleChange={handleChange}
            />
          </div>
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: 8 }}
      >
        <Grid item xs={10}>
          <CustomButton
            styleContainer={{
              marginLeft: "81%",
            }}
            type="success"
          >
            {isUpdating ? "generic.text.update" : "generic.text.create"}
          </CustomButton>
        </Grid>
        <Grid item xs={2}>
          <CustomButton type="cancel" onClick={handleClose} noSubmit>
            {"generic.text.cancel"}
          </CustomButton>
        </Grid>
      </Grid>
    </CreateReportFormContainerStyled>
  );
}

export default CreateReportForm;
