import React, { useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import CustomButton from "../Buttons/CustomButton";
import { dbTables } from "../../api/types/dbTables";
import { sortObjectsBy, isTradeDashEmployee } from "../../helpers/helpers";
import PermissionGroupItem from "../PermissionGroups/PermissionGroupItem";
import PermissionGroup from "../../api/model/permissionGroup";
import CustomModal from "../Modal/Modal";
import FormTextField from "../TextFields/FormTextField";
import { useCompanyUsers, useUser } from "../../hooks/user";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { firestore } from "../../firebase";
import { usePermissionGroups } from "../../hooks";
import { doc, setDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material";

const PermissionGroupsStyled = styled("div")(({ theme }) => ({
  "&.permission-groups-container": {
    boxShadow: "0 2px 4px 2px #ccc",
    height: "100%",
    width: "100%",
    overflow: "auto",
    "& .permission-groups-header": {
      position: "sticky",
      top: 0,
      zIndex: 1,
      minHeight: 70,
      padding: 16,
      display: "flex",
      alignItems: "center",
      fontSize: "1rem",
      fontWeight: 700,
      color: "#25486d",
      background: "white",
      "& .button-add": {
        border: "1px solid #25486d",
        backgroundColor: "#ffffff",
        outline: "none",
        color: "#25486d",
        fontWeight: "bold",
        fontSize: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
        margin: "0px 6px",
        cursor: "pointer",
        lineHeight: 1,
        textTransform: "uppercase",
      },
    },
    "& .permission-group": {
      color: "#25486d",
      border: "1px solid white",
      "& .root-width": {
        minWidth: 300,
      },
      display: "flex",
      alignItems: "center",
      padding: 8,
      "& li": {
        display: "flex",
        alignItems: "center",
        color: "#25486d",
      },
      "& .type-label": {
        fontSize: "0.875rem",
        display: "flex",
        flex: "0 0 auto",
        width: 345,
        overflow: "hidden",
        whiteSpace: "break-spaces",
        cursor: "pointer",
      },
      "& .permission-group-content": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        "& .add-remove-all-options": {
          display: "flex",
          flex: "0 0 auto",
          alignItems: "center",
          fontSize: "0.875rem",
        },
        "& .autocomplete-users-contenainer-options": {
          width: "100%",
        },
      },
    },
  },
}));

function PermissionGroups() {
  const user = useUser();
  const { companyId } = useParams();
  const permissionGroups = usePermissionGroups({
    companyId: companyId,
    filteringByInactive: false,
  });

  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });

  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [permissionGroupName, setPermissionGroupName] = useState("");
  const [currentPermissionGroup, setCurrentPermissionGroup] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const uniqueName = permissionGroups
    .filter((currentGroup) => currentPermissionGroup.id !== currentGroup.id)
    .every((currentGroup) => {
      let groupName = currentGroup.name || "";
      groupName = groupName.trim().toLowerCase();
      let currentName = permissionGroupName || "";
      currentName = currentName.trim().toLowerCase();
      return groupName !== currentName;
    });

  function addPermissionGroup() {
    let permissionGroup = "";
    if (isUploading) {
      permissionGroup = new PermissionGroup({
        id: currentPermissionGroup.id,
        name: permissionGroupName,
        users: currentPermissionGroup.users,
      });
    } else {
      permissionGroup = new PermissionGroup({ name: permissionGroupName });
    }
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.PERMISSION_GROUPS}/${permissionGroup.id}`
      ),
      { ...permissionGroup }
    );
    closeModal();
    setIsUploading(false);
    setCurrentPermissionGroup({});
  }

  function closeModal() {
    setIsEdit(false);
    setCustomModalOpen(false);
    setPermissionGroupName("");
  }
  function openModal() {
    if (isTradeDashEmployee(user)) {
      setReadOnlyModal(true);
    } else {
      setCurrentPermissionGroup({});
      setCustomModalOpen(true);
    }
  }

  function handleReadOnlyModal() {
    setReadOnlyModal(true);
  }

  function handleUpdateName(item) {
    setCurrentPermissionGroup(item);
    setCustomModalOpen(true);
    setPermissionGroupName(item.name.trim());
    setIsEdit(true);
    setIsUploading(true);
  }

  const getErrorStyle = () => {
    let errorMessage = "";
    let errorStyle = { height: 24 };

    if (!permissionGroupName.trim() || !uniqueName) {
      errorStyle = { height: 24, borderColor: "red" };
      errorMessage = !permissionGroupName.trim()
        ? "*name is required"
        : `*${permissionGroupName} is already exists`;
    }
    return { message: errorMessage, style: errorStyle };
  };

  return (
    <PermissionGroupsStyled className="permission-groups-container">
      <div className="permission-groups-header">
        <span style={{ flex: 1 }}>
          <IntlMessages id="components.companytabs.permissionGroups.title" />
        </span>
        <CustomButton type="create" onClick={openModal}>
          {"components.companytabs.permissionGroups.custombutton.addGroup"}
        </CustomButton>
      </div>

      <ul>
        <li className="permission-group" style={{ position: "relative" }}>
          <div className="type-label"></div>
          <div className="permission-group-content">
            <div
              className="add-remove-all-options"
              style={{
                fontSize: 11,
                marginLeft: 155,
              }}
            >
              Active /
              <br />
              Inactive
            </div>
            <div className="autocomplete-users-contenainer-options"></div>
          </div>
        </li>
        {permissionGroups
          .filter((permissionGroup) => !permissionGroup.isInactive)
          .sort(sortObjectsBy("name", false))
          .map((permissionGroup) => (
            <PermissionGroupItem
              permissionGroup={permissionGroup}
              handleUpdateName={handleUpdateName}
              companyId={companyId}
              disabled={isTradeDashEmployee(user)}
              handleReadOnlyModal={handleReadOnlyModal}
              key={permissionGroup.id}
              companyUsers={companyUsers}
            />
          ))}

        {permissionGroups
          .filter((permissionGroup) => permissionGroup.isInactive)
          .sort(sortObjectsBy("name", false))
          .map((permissionGroup, index) => (
            <PermissionGroupItem
              permissionGroup={permissionGroup}
              handleUpdateName={handleUpdateName}
              companyId={companyId}
              disabled={isTradeDashEmployee(user)}
              handleReadOnlyModal={handleReadOnlyModal}
              isTheFirstInactiveItem={index === 0 ? true : false}
              key={permissionGroup.id}
              companyUsers={companyUsers}
            />
          ))}
      </ul>
      {customModalOpen && (
        <CustomModal
          header={
            !isEdit ? (
              <IntlMessages id={"permissionGroup.create.custommodal"} />
            ) : (
              <IntlMessages id={"permissionGroup.edit.custommodal"} />
            )
          }
          isOpen={customModalOpen}
          onClose={closeModal}
        >
          <FormTextField
            value={permissionGroupName}
            onChange={(ev) => setPermissionGroupName(ev.target.value)}
            label="generic.textForm.name"
            disabled={isTradeDashEmployee(user)}
            containerClass="permissionGroupContainerForm"
            inputError={getErrorStyle().message}
            inputStyle={getErrorStyle().style}
            labelStyle={{
              display: "flex",
            }}
            required={true}
            spanErrorStyle={{
              top: 32,
            }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              onClick={addPermissionGroup}
              disabled={
                !permissionGroupName.trim() ||
                isTradeDashEmployee(user) ||
                !uniqueName
              }
              type="success"
            >
              {`generic.text.${!isEdit ? "create" : "submit"}`}
            </CustomButton>
            <CustomButton onClick={closeModal} type="cancel">
              {"generic.text.cancel"}
            </CustomButton>
          </div>
        </CustomModal>
      )}
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
    </PermissionGroupsStyled>
  );
}

export default PermissionGroups;
