import React from "react";
import { colors } from "../../assets/jss/variables";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { PO_DIAMOND_STATUS, PO_STATUS, TOOLTIP_TYPE } from "../constants";
import { DiamondIcon } from "../iconSvgPath";
import FavoriteStar from "../../assets/flag-icons/favorite-star.png";
import FavoriteStarInactive from "../../assets/flag-icons/star_outline.png";
import { orderType } from "../salesOrder";

export const getDiamondStatus = ({ diamondStatus, itemStatus }) => {
  if (itemStatus === PO_STATUS.VOIDED) {
    return <span style={{ width: 10 }} />;
  } else {
    if (diamondStatus === PO_DIAMOND_STATUS.LATE) {
      return (
        <TooltipTD
          label="Late"
          type={TOOLTIP_TYPE.DIAMOND}
          style={{ height: 18, width: 10, display: "flex" }}
        >
          <DiamondIcon color={colors.diamondRed} height={18} width={10} />
        </TooltipTD>
      );
    } else if (diamondStatus === PO_DIAMOND_STATUS.NEAR_DUE) {
      return (
        <TooltipTD
          label="Due Today or Tomorrow"
          type={TOOLTIP_TYPE.DIAMOND}
          style={{ height: 18, width: 10, display: "flex" }}
        >
          <DiamondIcon color={colors.diamondOrange} height={18} width={10} />
        </TooltipTD>
      );
    }
    return <span style={{ width: 10 }} />;
  }
};

export const getStarFavorite = ({
  favorites = [],
  itemId,
  removeFavorite = () => {},
  addFavorite = () => {},
}) => {
  const exist = favorites.includes(itemId);
  return (
    <span
      style={{ display: "flex", alignItems: "center" }}
      className={exist ? "" : "favorite-inactive-star"}
    >
      <img
        onClick={exist ? removeFavorite : addFavorite}
        width={16}
        height={18}
        src={exist ? FavoriteStar : FavoriteStarInactive}
        alt="FavoriteStar"
      />
    </span>
  );
};

export const getDisclouserArrow = ({
  currentSetup = {},
  shipments = [],
  purchaseOrders = [],
  salesOrders = [],
  items = [],
  type = orderType.SALES_ORDER,
  handleChangeSetup = () => {},
  min = 1,
}) => {
  let show, rowOpen;
  switch (type) {
    case orderType.SALES_ORDER:
      show = shipments.length > 1 || purchaseOrders.length > 1;
      rowOpen = currentSetup.shipments || currentSetup.POs;
      break;
    case orderType.PURCHASE_ORDER:
      show = shipments.length > 1 || salesOrders.length > 1;
      rowOpen = currentSetup.shipments || currentSetup.SOs;
      break;
    case orderType.SHIPMENT:
      show = salesOrders.length > 1 || purchaseOrders.length > 1;
      rowOpen = currentSetup.POs || currentSetup.SOs;
      break;
    default:
      show = items.length > min;
      rowOpen = currentSetup;
      break;
  }
  if (show) {
    return (
      <div onClick={handleChangeSetup} className="disclouserArrowStyle">
        <KeyboardArrowDownIcon
          className="arrow"
          style={{
            transform: !rowOpen ? "rotate(-90deg)" : "none",
          }}
        />
      </div>
    );
  } else {
    return <div style={{ width: 20 }} />;
  }
};
