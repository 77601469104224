import React, { useState, useEffect } from "react";
import { MultiUserPickerPopoverStyled, OnlyFromStyled } from "./styles";
import { useStorage, useQueryParams } from "../../hooks";
import { sortObjectsBy } from "../../helpers/helpers";
import { getUserAvatar } from "../../helpers/users";
import { DefaultUserIcon } from "../../helpers/iconSvgPath";
import { colors } from "../../assets/jss/variables";
import { Checkbox } from "@mui/material";

function MultiUserPicker({
  list,
  selectedOptions = () => {},
  purchaseOrderId,
}) {
  const [storage] = useStorage(purchaseOrderId);
  const query = useQueryParams();
  const [OpenUsers, setOpenUsers] = useState(false);
  const [SelectedElements, setSelectedElements] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const auxArray = [];
  let auxArrayTwo = [];

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
    setOpenUsers(true);
  }
  function handleClose() {
    setOpenUsers(false);
  }
  function handleClick(value, ev) {
    let elementExistsInTheSelectedElements = null;
    elementExistsInTheSelectedElements = [...SelectedElements].findIndex(
      (item) => item.id === value.id
    );
    if (elementExistsInTheSelectedElements !== -1) {
      auxArrayTwo = [...SelectedElements];
      auxArrayTwo.splice(elementExistsInTheSelectedElements, 1);
      setSelectedElements(auxArrayTwo);
      selectedOptions(auxArrayTwo);
    } else {
      auxArray.push(value);
      setSelectedElements(SelectedElements.concat([...auxArray]));
      selectedOptions(SelectedElements.concat([...auxArray]));
    }
  }

  function getUsersFromTheStorage() {
    const userList = list.filter((item) => {
      return storage.ONLY_FROM.includes(item.id);
    });
    return userList;
  }

  useEffect(() => {
    if (storage.ONLY_FROM) {
      setSelectedElements(getUsersFromTheStorage());
    } else {
      setSelectedElements([]);
    }
  }, [purchaseOrderId]);

  useEffect(() => {
    if (query && query.noteId) {
      setSelectedElements([]);
    } else {
      if (storage.ONLY_FROM) {
        setSelectedElements(getUsersFromTheStorage());
      } else {
        setSelectedElements([]);
      }
    }
  }, [list]);

  function renderDynamicSelectedUsers() {
    if (SelectedElements && SelectedElements.length > 1) {
      return SelectedElements.map((element) =>
        getUserAvatar({
          user: element,
          styles: {
            width: 20,
            height: 20,
            fontSize: 10,
            outline: "2px solid #000",
          },
        })
      );
    } else if (SelectedElements && SelectedElements.length === 1) {
      return (
        <div className="oneUser">
          {getUserAvatar({
            user: SelectedElements[0],
            styles: {
              width: 20,
              height: 20,
              fontSize: 10,
              outline: "2px solid #000",
            },
          })}
          <span>@{SelectedElements[0].displayName}</span>
        </div>
      );
    } else {
      return (
        <div className="onlyFromData">
          <DefaultUserIcon
            style={{ marginRight: 6 }}
            color={colors.primaryDark}
          />
          <span className="onlyFrom">ONLY FROM</span>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <OnlyFromStyled
        onClick={(ev) => handleOpen(ev)}
        className="onlyFromContainer"
      >
        {renderDynamicSelectedUsers()}
      </OnlyFromStyled>
      {OpenUsers && (
        <MultiUserPickerPopoverStyled
          open={OpenUsers}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className="multiUserPickerPopoverContainer"
        >
          <ul className={"listContainer"}>
            {list.sort(sortObjectsBy("displayName", false)).map((element) => (
              <li key={element.id} className={"listItem"}>
                <div id={element.id} className={"container"}>
                  <Checkbox
                    defaultChecked={
                      SelectedElements.find((item) => item.id === element.id)
                        ? true
                        : false
                    }
                    id={element.id}
                    onClick={(ev) => handleClick(element, ev)}
                    color="secondary"
                  />
                  {getUserAvatar({
                    user: element,
                    styles: {
                      width: 24,
                      height: 24,
                      fontSize: 13,
                      outline: "2px solid #000",
                    },
                  })}
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginLeft: 3,
                    }}
                  >
                    {" "}
                    {element.displayName}{" "}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </MultiUserPickerPopoverStyled>
      )}
    </React.Fragment>
  );
}

export default MultiUserPicker;
