import React from "react";
import CustomModal from "./Modal";
import TitleInformation from "../PurchaseOrderDashboard/TitleInformation";
import { useState } from "react";
import { useEffect } from "react";
import Mark from "mark.js";
import SOTableSKU from "../PurchaseOrderDashboard/SOTableSKU";
import { PREFIX_ITEM_TABLE } from "../../helpers/constants";
import ShipmentSKUTable from "../PurchaseOrderDashboard/ShipmentSKUTable";

function ExpandSimpleTableSKUModal({
  isOpen,
  setExpandModal,
  prefix,
  showTable,
  columnsTable,
  rowTable,
  currentPurchaseOrder,
  currentSalesOrder,
  currentShipment,
  purchaseOrders,
  handleShowImage,
  modalShowImage,
  parentShipment,
  onChangeShipment,
  allPoItems,
  hasMismatchTag,
  focusPOTable,
}) {
  const [cleanSearchBox, setCleanSearchBox] = useState(false);
  const [search, setSearch] = useState("");
  const markInstance = new Mark(document.getElementById("modal-item-table"));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [
        "#headerTable",
        "#totalLabels > *",
        "#footerCell",
        "#extendedPrice > *",
        "#cbmMaster > *",
        "#piecesPerMaster > *",
        "#cartons > *",
        "#totalCbm > *",
        "#unitCost > *",
        "#weight > *",
        "#extendedCost > *",
        "#salesOrderQuantity > *",
        "#salesOrderPrice > *",
        "#purchaseOrderQuantity > *",
        "#allocation > *",
        "#shipmentQuantity > *",
      ],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    setTimeout(() => performMark(search), 200);
  }, [search]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => {
        setExpandModal(false);
      }}
      className="expand-item-table"
      hiddenHeader
    >
      <TitleInformation
        type={prefix}
        purchaseOrder={currentPurchaseOrder}
        shipment={currentShipment}
        items={rowTable}
        salesOrder={currentSalesOrder}
      />

      <div className={"modal-item-table"} id="modal-item-table">
        {prefix === PREFIX_ITEM_TABLE.SALES_ORDER && (
          <SOTableSKU
            prefix={prefix}
            showTable={showTable}
            columnsTable={columnsTable}
            dataTable={rowTable}
            currentPurchaseOrder={currentPurchaseOrder}
            currentSalesOrder={currentSalesOrder}
            style={{ maxWidth: 1672 }}
            purchaseOrders={purchaseOrders}
            handleShowImage={handleShowImage}
            modalShowImage={modalShowImage}
            isModal
            modalCleanSearchBox={cleanSearchBox}
            modalOnWriteSearch={setSearch}
            modalHandleSearchBox={setCleanSearchBox}
            modalFilterText={search}
            hasMismatchTag={hasMismatchTag}
            focusPOTable={(item) => {
              setExpandModal(false);
              focusPOTable(item);
            }}
          />
        )}
        {prefix === PREFIX_ITEM_TABLE.SHIPMENT && (
          <ShipmentSKUTable
            prefix={prefix}
            showTable={showTable}
            columnsTable={columnsTable}
            dataTable={rowTable}
            currentPurchaseOrder={currentPurchaseOrder}
            currentSalesOrder={currentSalesOrder}
            style={{ maxWidth: 1605 }}
            purchaseOrders={purchaseOrders}
            handleShowImage={handleShowImage}
            modalShowImage={modalShowImage}
            isModal
            modalCleanSearchBox={cleanSearchBox}
            modalOnWriteSearch={setSearch}
            modalHandleSearchBox={setCleanSearchBox}
            modalFilterText={search}
            parentShipment={parentShipment}
            onChangeShipment={onChangeShipment}
            allPoItems={allPoItems}
            hasMismatchTag={hasMismatchTag}
            focusPOTable={(item) => {
              setExpandModal(false);
              focusPOTable(item);
            }}
          />
        )}
      </div>
    </CustomModal>
  );
}

export default ExpandSimpleTableSKUModal;
