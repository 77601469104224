/* eslint-disable no-use-before-define */
import React, { useRef, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import "./styles.scss";
import { sortObjectsBy } from "../../helpers/helpers";

export default function AutoCompleteSelect({
  options,
  handleAddNewElement,
  handleRemove = () => {},
  value,
  handleChange,
  textValue,
  mainClass,
  autoCompleteClasses,
  textFieldClasses,
  onTextFieldChange,
  textFieldValue,
  editing,
  handleKeyDown = () => {},
  isOpenList = false,
  handleOpenList = () => {},
  id = "autocomplete-id",
  maxCharacter = 40,
  sortBy,
  handleClose,
  onClose = () => {},
  forceInput,
  activeBlur = false,
}) {
  const inputRef = useRef(null);
  const [backspacePress, setBackspacePress] = useState(1);
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    if (editing) {
      setTimeout(() => {
        handleOpenList(!!value && !!value.id);
        inputRef.current.select();
        setBackspacePress(1);
      }, 50);
    }
  }, [editing]);

  const saveData = (ev) => {
    const currentValue = ev.target.value;
    if (!currentValue.trim().replace(/\s+/g, " ")) {
      if (activeBlur) {
        handleClose();
      }
      return;
    }
    if (options.find((value) => value.name === currentValue)) {
      handleChange(ev, currentValue);
    } else if (textFieldValue.length === 0) {
      handleAddNewElement(textFieldValue);
      return;
    } else {
      if (
        textFieldValue.length !== 0 &&
        textFieldValue.length <= maxCharacter
      ) {
        handleAddNewElement(textFieldValue);
        handleOpenList(false);
      }
    }
  };

  const sortOptions = sortBy
    ? options.sort(sortObjectsBy(sortBy))
    : options.sort((a, b) =>
        a.toString().toLowerCase().localeCompare(b.toString().toLowerCase())
      );
  return (
    <Autocomplete
      id={id}
      classes={autoCompleteClasses}
      open={isOpenList}
      className={mainClass}
      onClick={(ev) => {
        handleOpenList(!isOpenList);
        ev.stopPropagation();
      }}
      options={sortOptions}
      value={value}
      getOptionLabel={(option) => (sortBy ? option[sortBy] : option)}
      onChange={(ev, value, reason) => {
        if (reason === "selectOption" && ev.key === "Enter") {
          setLoading(true);
        }
        if (isOpenList) {
          handleOpenList(false);
        }
        handleChange(ev, value);
      }}
      renderOption={(props, option, { selected }) => {
        let currentSelected = false;
        if (value) {
          if (typeof option === "object") {
            currentSelected = option.id === value.id;
          } else {
            currentSelected = option === value;
          }
        }

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            {...props}
          >
            <div style={{ maxWidth: "calc(100% - 20px)", overflow: "overlay" }}>
              {sortBy ? option[sortBy] : option}
            </div>
            <div
              style={{
                display: "flex",
                width: 20,
                height: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!currentSelected && (
                <CancelIcon
                  style={{
                    width: 15,
                    height: 15,
                    color: "darkGray",
                  }}
                  id="icon"
                  onClick={(ev) => {
                    handleRemove(option);
                    ev.stopPropagation();
                  }}
                  children={{
                    id: "iconPath",
                  }}
                />
              )}
            </div>
          </div>
        );
      }}
      style={{ width: 250 }}
      renderInput={(params) => {
        const inputProps = params.inputProps;
        const currentValue = forceInput
          ? inputProps.value.replace(/\s+/g, " ")
          : inputProps.value;
        return (
          <TextField
            inputRef={inputRef}
            className="text-field"
            {...params}
            variant="outlined"
            onClick={() => handleOpenList(!isOpenList)}
            onKeyUp={(ev) => {
              if (
                ev.key === "Backspace" &&
                textFieldValue === "" &&
                isOpenList
              ) {
                setBackspacePress(backspacePress - 1);
                if (backspacePress <= 0) {
                  return handleOpenList(false);
                }
              }
              if (ev.key === "ArrowDown" && !isOpenList) {
                handleOpenList(true);
              }
              if (ev.key === "Enter") {
                if (loading) {
                  setLoading(false);
                  return;
                }
                saveData(ev);
              }
            }}
            inputProps={{
              ...params.inputProps,
              value: currentValue,
            }}
            InputProps={{
              ...params.InputProps,
              classes: textFieldClasses,
            }}
            autoFocus
            onChange={(ev) => {
              if (!isOpenList) {
                handleOpenList(true);
              }
              setBackspacePress(1);
              onTextFieldChange(ev);
            }}
            onKeyDown={handleKeyDown}
            onBlur={(ev) => {
              if (!activeBlur) {
                return;
              }
              if (isOpenList) {
                handleOpenList(false);
                setTimeout(() => {
                  inputRef.current.focus();
                }, 50);
              } else {
                saveData(ev);
              }
            }}
          />
        );
      }}
      disableClearable
      noOptionsText={textValue}
      onClose={() => {
        handleOpenList(false);
        onClose();
      }}
    />
  );
}
