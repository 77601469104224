import React from "react";
import { useCompanyId, useData } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import ManagementDocuments from "../Documents/ManagementDocuments";
import { documentScope } from "../../helpers/documents";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { CustomerDocumentsStyled } from "./styles";

function CustomerDocuments({
  customer,
  permissionGroups,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
}) {
  const companyId = useCompanyId();
  const customerDocuments = useData(
    [
      dbTables.COMPANIES,
      companyId,
      dbTables.CUSTOMERS,
      customer.id,
      dbTables.CUSTOMER_DOCUMENTS,
    ],
    [customer.id]
  );
  return (
    <CustomerDocumentsStyled className="documentContainer">
      <ManagementDocuments
        companyID={companyId}
        dbTable={dbTables.CUSTOMER_DOCUMENTS}
        mainTable={dbTables.CUSTOMERS}
        mainTableID={customer.id}
        documents={customerDocuments.map((document) => ({
          ...document,
          scope: documentScope.CUSTOMER,
        }))}
        selectedDocumentTab={0}
        hasTitle={true}
        permissionGroupsDB={permissionGroups}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        permissionScope={GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_CUSTOMER_DOCUMENT}
        allowMultipleFiles
      />
    </CustomerDocumentsStyled>
  );
}

export default CustomerDocuments;
