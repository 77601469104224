export const purchaseOrderTriggerTypes = {
  DATE: "date",
  SHIPDATE: "shipDate",
  CBM: "CBM",
  DELIVERY_DATE: "deliveryDate",
  LOADING_PORT: "loadingPort",
  UNLOADING_PORT: "unloadingPort",
  FINAL_DESTINATION: "finalDestination",
  SHIPPING_TERMS: "shippingTerms",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  AMOUNT: "amount",
  DEPOSIT: "deposit",
  NUMBER: "number",
  TAGS: "tags",
  COMPLETED_TASKS: "completedTasks",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  BUILDING_TASKS: "BUILDING_TASKS",
};

export const PO_FIELDS = {
  DATE: "date",
  ORDER_READY_DATE: "orderReadyDate",
  DEPOSIT: "deposit",
  INVOICE: "invoice",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  SHIPPING_TERMS: "shippingTerms",
  DISCOUNT: "discount",
  POTENTIAL_LATE_FEE: "potentialLateFee",
  CREATION_DATE: "creationDate",
  COMPLETED_TASKS: "completedTasks",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  SHIPMENT_IDS:'shipmentIds'
};
