import React, { useState } from "react";
import AutoCompleteSelect from "../../AutoCompleteSelect/AutoCompleteSelect";

let MAX_CHARACTER = 35;

function PaidToComponent({
  options,
  onChange,
  value,
  handleAddNewElement,
  onRemove,
}) {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const valueList = options.find((option) => option === value);
  return (
    <AutoCompleteSelect
      options={options}
      handleAddNewElement={handleAddNewElement}
      handleClose={() => setIsListOpen(false)}
      handleRemove={onRemove}
      value={valueList || value}
      maxCharacter={MAX_CHARACTER}
      handleChange={onChange}
      textValue={
        textFieldValue && textFieldValue.length <= MAX_CHARACTER
          ? `No matching Paid To, press enter to save`
          : `The Paid To name can be up to ${MAX_CHARACTER} letters only`
      }
      mainClass={"autocomplete-select"}
      autoCompleteClasses={{
        option: "option",
        listbox: "listbox",
        // popper: popperClass,
      }}
      textFieldClasses={{
        adornedEnd: "adornedend",
        inputAdornedEnd: "inputAdornedEnd",
        notchedOutline: "notchedOutline",
      }}
      onTextFieldChange={(ev) => {
        const value = ev.target.value;
        setTextFieldValue(value.trim().replace(/\s+/g, " "));
        ev.stopPropagation();
      }}
      textFieldValue={textFieldValue || value}
      isOpenList={isListOpen}
      handleOpenList={(value) => {
        setIsListOpen(value);
      }}
      onClose={() => setIsListOpen(false)}
      forceInput
    />
  );
}

export default PaidToComponent;
