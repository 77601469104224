import Task from "./task";

export default class SalesOrderTask extends Task {
  constructor({ listIndex = 0, duration = 1, offset = 0, ...rest }) {
    super(rest);
    this.listIndex = listIndex;
    this.duration = duration;
    this.offset = offset;
  }
}
