import React from "react";
import { globalScopes } from "../../../api/types/dbTables";
import { PREFIX_ITEM_TABLE } from "../../../helpers/constants";
import { getDashboardSearchPath } from "../../../helpers/helpers";
import { columnsShipmentTable } from "../../../helpers/itemsTableColumns";
import { getShipmentMismatch } from "../../../helpers/itemsTableHelper";
import { shipmentItemList, useItemsList } from "../../../hooks/itemTable";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { usePurchaseOrders } from "../../../hooks/salesOrders";
import { mismatchLabel, ORDER_DASHBOARD_ACTUAL_VIEWS } from "./helpers";
import ShipmentSKUTable from "../ShipmentSKUTable";
import { useNavigate } from "react-router-dom";

function SectionShipmentSKU({
  currentPurchaseOrder,
  currentSalesOrder,
  user,
  shipment,
  queryParams,
}) {
  const purchaseOrders = usePurchaseOrders();
  const isAllowed = useIsAllowedFunction();
  const navigate = useNavigate();
  const poItemsList = useItemsList({
    currentPurchaseOrder: currentPurchaseOrder,
    user: user,
  });
  const soItemsList = useItemsList({
    salesOrder: currentSalesOrder,
    purchaseOrders,
    user: user,
  });
  const shipmentsItems = shipmentItemList({
    poItemsList,
    currentShipment: shipment,
    soItemsList,
    purchaseOrders,
    user,
  });

  const hasShipmentMismatch = getShipmentMismatch({
    currentShipment: shipment,
    shipmentsItems,
  });

  return (
    <div id="item-table-Shipment">
      <ShipmentSKUTable
        prefix={PREFIX_ITEM_TABLE.SHIPMENT}
        currentPurchaseOrder={currentPurchaseOrder}
        currentSalesOrder={currentSalesOrder}
        showTable={true}
        allPoItems={poItemsList}
        columnsTable={columnsShipmentTable.filter((column) => {
          if (column.amountPermission && !isAllowed(column.amountPermission)) {
            return false;
          }
          return true;
        })}
        style={{ maxWidth: 1605 }}
        parentShipment={shipment}
        dataTable={shipmentsItems}
        purchaseOrders={purchaseOrders}
        focusPOTable={(data) => {
          if (data.purchaseOrderId !== currentPurchaseOrder.id) {
            navigate(
              getDashboardSearchPath({
                ...queryParams,
                purchaseOrderId: data.purchaseOrderId,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
              })
            );
          } else {
            navigate(
              getDashboardSearchPath({
                ...queryParams,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
              })
            );
          }
        }}
        onChangeShipment={(data) => {
          if (!data.erased) {
            return;
          }
          const shipmentIds = currentPurchaseOrder.shipmentIds || [];
          const newShipmentId = shipmentIds.find((id) => id !== data.id);
          if (newShipmentId) {
            navigate(
              getDashboardSearchPath({
                ...queryParams,
                shipmentId: newShipmentId,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
              })
            );
          } else {
            navigate(
              getDashboardSearchPath({
                ...queryParams,
                shipmentId: "",
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
              })
            );
          }
        }}
        hasMismatchTag={
          hasShipmentMismatch ? mismatchLabel[globalScopes.SHIPMENT] : false
        }
      />
    </div>
  );
}

export default SectionShipmentSKU;
