import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NotesFoldersStructure from "./NotesFoldersStructure";
import { dbTables } from "../../../api/types/dbTables";
import { SALES_ORDER_STATUS, TYPE_OF_FILE } from "../../../helpers/constants";
import {
  sortObjectsBy,
  pathToString,
  getPathDocumentLocalStorage,
  searchDocumentInTree,
  useLocationPath,
} from "../../../helpers/helpers";
import folderPlaceholder from "../../../assets/flag-icons/folder-placeholder.svg";
import { getNestedCollectionFromFirestoreTable } from "../../../actions/DataActions";

import TypeFileSelector from "../../Documents/TypeFileSelector";
import NoteTextDocument from "./NoteTextDocument";
// import { trackEvent } from "../../helpers/analytics";
import { documentScope } from "../../../helpers/documents";
import classNames from "classnames";
import { ListItemNoteDocumentStyled } from "./styles";
import { useUser } from "../../../hooks/user";
import { useJSONLocalStorage } from "../../../hooks";
import AppConfig from "../../../constants/AppConfig";
import { PermissionIcon, PlusSquare } from "../../../helpers/iconSvgPath";
import { getShipmentBadge } from "../../../helpers/shipments";
import { useShipments } from "../../../hooks/shipments";
import { getListItemClassName } from "../../Documents/DocumentHelper";
import TooltipTD from "../../Tooltip/TooltipTD";
import {
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
} from "../../../hooks/salesOrders";
import { cx } from "@emotion/css";
import { Collapse, ListItem, ListItemIcon } from "@mui/material";
import { shipmentBadgeClass } from "../../../container/StyledComponent/BadgeStyled";

function ListItemDocumentNote({
  currentDocument,
  companyID,
  mainTable,
  mainTableID,
  dbTable,
  selectedDocumentTab,
  permissionGroupsDB,
  onDraggableDocument,
  onDragEnd,
  draggableDocument = {},
  isDraggableDocument = false,
  documentFlat,
  disabled,
  isExpand,
  querySearch = "",
  handleExpandOrCollapse = () => {},
  handleEditPermission,
  parentDocument,
  currentShipment,
}) {
  const currentSalesOrder = useCurrentSalesOrder({});
  const currentPurchaseOrder = useCurrentPurchaseOrder();
  const path = useLocationPath();
  const pathLocalStorage = `${getPathDocumentLocalStorage(
    path
  )}DocumentStructure`;
  const { get } = useJSONLocalStorage(pathLocalStorage);
  const dispatch = useDispatch();
  const shipments = useShipments();
  const localStorage = get() || {};
  const poDataLocalStorage = localStorage[mainTableID] || {};

  const isFolderOpen = poDataLocalStorage[currentDocument.id] || false;
  const [open, setOpen] = useState(isFolderOpen);
  const [listenerList, setListenerList] = useState([]);

  const listenToDocumentsVersion = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyID,
        mainTable,
        mainTableID,
        dbTable,
        currentDocument.id,
        dbTables.DOCUMENT_VERSIONS,
      ]),
      getParentId: true,
    })(dispatch)
  );

  const listenToShipmentDocumentsVersion = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyID,
        dbTables.SHIPMENTS,
        currentDocument.shipmentId,
        dbTables.SHIPMENT_DOCUMENTS,
        currentDocument.id,
        dbTables.DOCUMENT_VERSIONS,
      ]),
      getParentId: true,
    })(dispatch)
  );

  const currentUser = useUser();

  const documentVersions = useSelector(
    (state) => state.data.document_versions[currentDocument.id]
  );

  useEffect(() => {
    if (isFolderOpen !== open) {
      setOpen(isFolderOpen);
    }
  }, [mainTableID]);

  useEffect(() => {
    if (querySearch === "") {
      setOpen(isFolderOpen);
    } else {
      const searchQueryDocument = searchDocumentInTree(
        currentDocument,
        querySearch
      );
      setOpen(searchQueryDocument);
    }
  }, [querySearch]);

  useEffect(() => {
    if (isFolderOpen !== open) {
      setOpen(isFolderOpen);
    }
  }, [isFolderOpen]);

  if (documentVersions) {
    documentVersions.sort(sortObjectsBy("created", true));
  }

  const filterDocuments = [];
  if (documentVersions) {
    documentVersions.forEach((element) => {
      if (element.version !== currentDocument.version) {
        filterDocuments.push(element);
      }
    });
  }

  function handleClick(ev) {
    setOpen(!open);
    if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
      const localStorage = get() || {};
      const poDataLocalStorage = localStorage[mainTableID] || {};
      const newLocalStorage = {
        ...localStorage,
        [mainTableID]: {
          ...poDataLocalStorage,
          [currentDocument.id]: !open === false ? undefined : !open,
        },
      };
      handleExpandOrCollapse({ ...newLocalStorage });
      // set({ ...newLocalStorage });
    }
    if (
      currentDocument.type !== TYPE_OF_FILE.FOLDER &&
      currentDocument.mainFile
    ) {
      if (!listenerList.includes(currentDocument.id)) {
        if (
          currentDocument.scope === documentScope.SALES_ORDER ||
          currentDocument.scope === documentScope.FACTORY ||
          currentDocument.scope === documentScope.CUSTOMER ||
          currentDocument.scope === documentScope.PURCHASE_ORDER
        ) {
          listenToDocumentsVersion();
        } else {
          listenToShipmentDocumentsVersion();
        }
        const cpyListeners = [...listenerList];
        cpyListeners.push(currentDocument.id);
        setListenerList(cpyListeners);
      }
    }
  }

  const isVoided =
    dbTable === dbTables.SALES_ORDER_DOCUMENTS
      ? currentSalesOrder.status === SALES_ORDER_STATUS.VOIDED
      : currentPurchaseOrder.status === SALES_ORDER_STATUS.VOIDED;

  const isShipment = currentDocument.scope === documentScope.SHIPMENT;
  const isShipmentFolder = currentDocument.isShipmentRootTask;
  const marginIconRight =
    isShipment && currentDocument.type !== TYPE_OF_FILE.FOLDER ? 5 : 15;
  return (
    <ListItemNoteDocumentStyled>
      <div
        id={`list-item-document_${
          isShipment
            ? `${currentDocument.scope}_${currentDocument.shipmentId}`
            : currentDocument.id
        }`}
        onDragStart={(event) => {
          onDraggableDocument(currentDocument);
          event.stopPropagation();
        }}
        onDragEnd={(event) => {
          onDragEnd();
          event.stopPropagation();
        }}
        className={classNames(
          isShipment &&
            currentDocument.type === TYPE_OF_FILE.FOLDER &&
            isShipmentFolder
            ? "listemItemDocumentShipment"
            : "listemItemDocument",
          {
            "draggeable-file-disable": "draggeable-file-disable",
          }
        )}
        style={{ margin: isShipmentFolder ? 10 : null }}
      >
        <ListItem
          id={"menu-provider-list-item" + currentDocument.id}
          className={cx(
            getListItemClassName(currentDocument, open),
            "listDocumentNote"
          )}
          onClick={(ev) => {
            handleClick();
            ev.stopPropagation();
          }}
          style={{
            borderBottom: isShipmentFolder ? "none" : "",
          }}
        >
          {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
            <ListItemIcon className={"listItemIcon"}>
              <img
                className={"imgListItemIcon"}
                src={folderPlaceholder}
                alt="folder"
                style={{
                  marginBottom: isShipmentFolder ? 0 : null,
                }}
              />
            </ListItemIcon>
          ) : (
            <ListItemIcon className={"listItemIcon"}>
              <TypeFileSelector
                className={
                  currentDocument.mainFile
                    ? "labelVersionSmallCenter"
                    : "labelVersionBotton"
                }
                path={currentDocument.path}
                version={currentDocument.version}
                type={currentDocument.type}
                mainFile={
                  currentDocument.mainFile || currentDocument.placeholder
                }
                currentDocument={currentDocument}
                parentId={currentDocument.parentId}
                placeholder={
                  currentDocument.placeholder &&
                  currentDocument.type !== TYPE_OF_FILE.FOLDER
                }
              />
            </ListItemIcon>
          )}

          <NoteTextDocument
            currentDocument={currentDocument}
            purchaseOrderID={mainTableID}
          />
          {isShipment &&
            isShipmentFolder &&
            getShipmentBadge({
              shipment: shipments.find(
                (shipment) => shipment.id === currentShipment.id
              ),
              styles: {
                margin: "0px",
              },
              classes: cx(
                shipmentBadgeClass.secondary,
                "shipmentBadgeDocument"
              ),
              tooltipStyle: {
                marginRight: 10,
                width: "fit-content",
              },
            })}
          <TooltipTD
            label={
              currentDocument.type === TYPE_OF_FILE.FOLDER
                ? "Add folder"
                : "Add file"
            }
            style={{ marginRight: 15, minWidth: 25 }}
          >
            <PlusSquare
              onClick={(ev) => {
                onDraggableDocument({ ...currentDocument, withButton: true });
                setTimeout(() => {
                  onDragEnd();
                }, 300);
                ev.stopPropagation();
              }}
              svgClass={"squareButton"}
              childClass={"line"}
              width={25}
              height={25}
            />
          </TooltipTD>
          {!isVoided && (
            <TooltipTD
              showToolTip={currentDocument.child !== undefined}
              style={{ width: 30, marginRight: marginIconRight }}
              tooltipClick={(ev) => {
                if (currentDocument.child !== undefined) {
                  handleEditPermission({ ...currentDocument, parentDocument });
                  ev.stopPropagation();
                }
              }}
              label={
                currentDocument.type === TYPE_OF_FILE.FOLDER
                  ? "Change folder view permissions"
                  : "Change file view permissions"
              }
            >
              {currentDocument.child !== undefined && (
                <PermissionIcon
                  width={30}
                  height={30}
                  svgClass={"permissionIcon"}
                  color="#25486D"
                />
              )}
            </TooltipTD>
          )}
        </ListItem>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          classes={{
            root: "container-collapse-item",
            entered: "entered-collapse-item",
            wrapper: "wrapper-collapse-item",
            wrapperInner: "wrapper-inner-collapse-item",
          }}
        >
          <div
            id={currentDocument.id + "item-document"}
            className={
              currentDocument.type === TYPE_OF_FILE.FOLDER
                ? "listItemDocumentCollapseItemsFolder"
                : "listItemDocumentCollapseItems"
            }
            style={{ paddingLeft: "12px" }}
          >
            {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
              <React.Fragment>
                <div className={"leftContainerLineDivider"}>
                  <div
                    className="left-line-divider"
                    style={{
                      position: "relative",
                      background: AppConfig.themeColors.dividerColor,
                      width: 1,
                      height: "98%",
                      left: 16,
                    }}
                  ></div>
                </div>
                <NotesFoldersStructure
                  documents={currentDocument.child ? currentDocument.child : []}
                  companyID={companyID}
                  mainTable={mainTable}
                  mainTableID={mainTableID}
                  dbTable={dbTable}
                  selectedDocumentTab={selectedDocumentTab}
                  permissionGroupsDB={permissionGroupsDB}
                  onDraggableDocument={onDraggableDocument}
                  draggableDocument={draggableDocument}
                  isDraggableDocument={isDraggableDocument}
                  documentFlat={documentFlat}
                  hasParentPermission={true}
                  parentDocument={currentDocument}
                  user={currentUser}
                  disabled={disabled}
                  isExpand={isExpand}
                  handleExpandOrCollapse={handleExpandOrCollapse}
                  querySearch={querySearch}
                  onDragEnd={onDragEnd}
                  handleEditPermission={handleEditPermission}
                  currentShipment={currentShipment}
                />
              </React.Fragment>
            ) : (
              <NotesFoldersStructure
                documents={filterDocuments}
                dbTable={dbTable}
                selectedDocumentTab={selectedDocumentTab}
                isDocumentVersion={true}
                companyID={companyID}
                mainTable={mainTable}
                mainTableID={mainTableID}
                documentFlat={documentFlat}
                permissionGroupsDB={permissionGroupsDB}
                hasParentPermission={true}
                parentDocument={currentDocument}
                user={currentUser}
                disabled={disabled}
                isExpand={isExpand}
                handleExpandOrCollapse={handleExpandOrCollapse}
                querySearch={querySearch}
                onDragEnd={onDragEnd}
                onDraggableDocument={onDraggableDocument}
                handleEditPermission={handleEditPermission}
                currentShipment={currentShipment}
              />
            )}
          </div>
        </Collapse>
        {((open && currentDocument.type === TYPE_OF_FILE.FOLDER) ||
          (open && currentDocument.mainFile && currentDocument.version > 1)) &&
          !isShipmentFolder && (
            <div
              className="botton-line-divider"
              style={{
                height: 1,
                background: AppConfig.themeColors.dividerColor,
                marginTop: 8,
                marginBottom: 2,
                marginLeft: 23,
                marginRight: 11,
              }}
            ></div>
          )}
      </div>
    </ListItemNoteDocumentStyled>
  );
}

export default ListItemDocumentNote;
