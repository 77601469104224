import React, { useEffect, useState } from "react";
import SearchBox from "../../SearchBox/SearchBox";
import { ExpensesContainerStyled } from "./styles";
import Expense from "../../../api/model/Expense";
import numeral from "numeral";
import { getBackgroundColorByType } from "../../../helpers/orderDashboard";
import ExpenseItem from "../../../api/model/ExpenseItem";
import { sortObjectsBy } from "../../../helpers/helpers";
import ExpenseItemComponent from "./ExpenseItem";
import { orderTypeSort } from "../../../helpers/salesOrderHelpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useGLAccountItems, useAutoCompleteLists } from "../../../hooks";
import {
  EXPENSES_HEADER_LABELS,
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
  TYPE_OF_AUTOCOMPLETE_LISTS,
} from "../../../helpers/constants";
import { EXPENSE_FIELDS, orderType } from "../../../helpers/salesOrder";
import { firestore, storage } from "../../../firebase";
import { dbTables } from "../../../api/types/dbTables";
import {
  EXCLUDE_PERFORM_MARK,
  expensesHeader,
  getGLAccount,
  getTotal,
  performMark,
} from "../../../helpers/expenses";
import AttentionModal from "../../Modal/AttentionModal";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { useUser } from "../../../hooks/user";
import Mark from "mark.js";
import { CancelIcon, ExpenseFileIcon } from "../../../helpers/iconSvgPath";
import axios from "axios";
import moment from "moment";
import Loader from "../../General/Loader";
import DocumentToTrain from "../../../api/model/DocumentToTrain.model";
import {
  onSnapshot,
  collection as collectionFirestore,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../../constants/globalVariables";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { colors } from "../../../assets/jss/variables";
import { Button, Grid, Modal } from "@mui/material";

let DB_TABLES_TO_SCOPE = {
  [dbTables.SALES_ORDERS]: orderType.SALES_ORDER,
  [dbTables.PURCHASE_ORDERS]: orderType.PURCHASE_ORDER,
  [dbTables.SHIPMENTS]: orderType.SHIPMENT,
};

const PROCESSOR_TYPE = {
  INVOICE: "INVOICE",
  RECEIPT: "RECEIPT",
};

function ExpensesModal({
  open,
  onClose,
  className,
  collection = "",
  documentId = "",
  companyId,
  expenseRef,
  order = {},
}) {
  const isVoided = order.status === SALES_ORDER_STATUS.VOIDED;
  const [expenses, setExpenses] = useState([]);
  const [editing, setEditing] = useState(null);
  const [filters, setFilters] = useState({
    query: "",
    sortedColumn: EXPENSE_FIELDS.DATE,
    orderBy: orderTypeSort.DESC,
  });
  const [expandConfig, setExpandConfig] = useState({});
  const [expenseToDelete, setExpenseToDelete] = useState({});
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [documentAI, setdocumentAI] = useState({});
  const [fileTransferedData, setFileTransferedData] = useState(null);
  const [openFileTransfered, setOpenFileTransfered] = useState(false);
  const [currentTotalActual, setCurrentTotalActual] = useState(0);

  const isAllowed = useIsAllowedFunction();
  const user = useUser();
  const GLAccountItems = useGLAccountItems();
  const paidToMiscellaneo = useAutoCompleteLists({
    id: TYPE_OF_AUTOCOMPLETE_LISTS.PAID_TO,
  });

  useEffect(() => {
    let expensesListener = () => {};
    if (documentId && collection && open) {
      if (!expenseRef) {
        expensesListener = onSnapshot(
          collectionFirestore(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${collection}/${documentId}/${dbTables.EXPENSES}`
          ),
          (snapshot) => {
            const expenses = snapshot.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            }));
            setExpenses(expenses);
          }
        );
      } else {
        expensesListener = onSnapshot(expenseRef, (snapshot) => {
          if (snapshot.exists) {
            const total = +getTotal({
              list: snapshot.data().items,
              field: EXPENSE_FIELDS.ACTUAL,
              format: false,
            });
            setCurrentTotalActual(total);
            setExpenses([{ ...snapshot.data(), ref: snapshot.ref }]);
            setExpandConfig({ ...expandConfig, [snapshot.id]: true });
            setEditing({ ...snapshot.data(), ref: snapshot.ref });
          } else {
            setExpenses([]);
          }
        });
      }
    }
    return function cleanup() {
      return expensesListener();
    };
  }, [documentId]);
  const markInstance = new Mark(document.getElementById("expenses-table-body"));

  useEffect(() => {
    performMark({
      keyword: filters.query,
      markInstance,
      exclude: EXCLUDE_PERFORM_MARK,
    });
  }, [filters]);

  function getFooterTotals({ expenses = [], header = {} }) {
    switch (header.label) {
      case EXPENSES_HEADER_LABELS.ACTUAL:
      case EXPENSES_HEADER_LABELS.FORECAST: {
        let total = 0;
        expenses.forEach((expense) => {
          total += getTotal({
            list: expense.items,
            field: header.field,
            format: false,
          });
        });
        return numeral(total).format("$0,0.00");
      }
      case EXPENSES_HEADER_LABELS.NOTES:
        return "Totals:";
      default:
        return "";
    }
  }

  function renderArrow(headerLabel) {
    if (filters.sortedColumn === headerLabel) {
      if (filters.orderBy === "ASC") {
        return <ArrowDropUpIcon className="header-arrow-button-active" />;
      }
      return <ArrowDropDownIcon className="header-arrow-button-active" />;
    }
    return <ArrowDropDownIcon className="header-arrow-button" />;
  }

  function filterList({ list = [], filters = {}, fieldsToSearch = [] }) {
    let filteredList = [];
    if (isAllowed(GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_EXPENSES)) {
      filteredList = [...list];
    } else if (
      isAllowed(GENERAL_PERMISSION_VALUE.CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF)
    ) {
      filteredList = [...list.filter((el) => el.createdBy === user.id)];
    }
    if (filters.query) {
      filteredList = filteredList.filter((el) => {
        const isMatchItem = el.items.some((item) => {
          return fieldsToSearch.some((field) => {
            if (
              field &&
              item[field] &&
              item[field]
                .toString()
                .toLowerCase()
                .includes(filters.query.toLowerCase())
            ) {
              return true;
            }
            return false;
          });
        });

        const isMatchEl = fieldsToSearch.some((field) => {
          if (
            field &&
            el[field] &&
            el[field]
              .toString()
              .toLowerCase()
              .includes(filters.query.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
        return isMatchEl || isMatchItem;
      });
    }
    if (editing && editing.isCreating) {
      filteredList = filteredList.map((item) => {
        if (item.id === editing.id) {
          return { ...item, isCreating: true };
        }
        return item;
      });
    }
    filteredList
      .sort(
        sortObjectsBy(
          filters.sortedColumn,
          filters.orderBy === orderTypeSort.ASC ? true : false
        )
      )
      .sort(sortObjectsBy("isCreating", true));
    return filteredList;
  }

  function formatExpenses({ expenses = [] }) {
    let expensesCpy = [...expenses];
    expensesCpy = expensesCpy.map((expenseCpy) => {
      let itemsCpy = [...expenseCpy.items];
      itemsCpy = itemsCpy.map((itemCpy) => {
        const { name, description } = getGLAccount({
          items: GLAccountItems,
          itemId: itemCpy.GLAccountId,
        });
        return {
          ...itemCpy,
          GLAccountName: name,
          GLAccountDescription: description,
        };
      });
      if (expenseCpy.items.length === 1) {
        const firstItem = expenseCpy.items[0];
        const { name, description } = getGLAccount({
          items: GLAccountItems,
          itemId: firstItem.GLAccountId,
        });
        return {
          ...expenseCpy,
          GLAccountName: name,
          GLAccountDescription: description,
          [EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES]:
            firstItem[EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES],
          [EXPENSE_FIELDS.NOTES]: firstItem[EXPENSE_FIELDS.NOTES],
          [EXPENSE_FIELDS.FORECAST]: +firstItem[EXPENSE_FIELDS.FORECAST],
          [EXPENSE_FIELDS.ACTUAL]: +firstItem[EXPENSE_FIELDS.ACTUAL],
          items: itemsCpy,
        };
      }

      return {
        ...expenseCpy,
        GLAccountName: "",
        GLAccountDescription: "",
        [EXPENSE_FIELDS.GL_ACCOUNT_ID]: "",
        [EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES]: "",
        [EXPENSE_FIELDS.NOTES]: "",
        [EXPENSE_FIELDS.FORECAST]: getTotal({
          list: expenseCpy.items,
          field: EXPENSE_FIELDS.FORECAST,
          format: false,
        }),
        [EXPENSE_FIELDS.ACTUAL]: getTotal({
          list: expenseCpy.items,
          field: EXPENSE_FIELDS.ACTUAL,
          format: false,
        }),
        items: itemsCpy,
      };
    });
    return expensesCpy;
  }

  function onCloseModalAttention() {
    setExpenseToDelete({});
    setDescriptionModalAttention(<React.Fragment></React.Fragment>);
    setOpenModalAttention(false);
  }

  const handleCloseExpensesModal = (newExpense) => {
    setEditing(null);
    setExpenses([]);
    setFilters({
      query: "",
      sortedColumn: EXPENSE_FIELDS.DATE,
      orderBy: orderTypeSort.DESC,
    });
    setExpandConfig({});
    setExpenseToDelete({});
    onClose(newExpense ? [newExpense] : expenses);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleDrop({ file, processorType }) {
    setLoadingPDF(true);
    let name = file.name;
    let base64 = await toBase64(file);
    base64 = base64.substring(28);
    const url = getFunctionByName({
      name: "documentAI",
      env: globalEnvironment,
    });
    const response = await axios.post(url, {
      file: base64,
      processorType,
    });
    const { expense, totalActual, status, confidenceAverage } = response.data;
    if (status === 200) {
      const expensesCpy = [...expenses];
      const dateformat = moment(expense.date).format("YYYY-MM-DD");
      if (
        !paidToMiscellaneo.list.includes(expense.paidTo) &&
        expense.paidTo !== ""
      ) {
        const listCpy = [...paidToMiscellaneo.list];
        listCpy.push(expense.paidTo);
        updateDoc(paidToMiscellaneo.ref, { list: listCpy });
      }
      const newExpense = {
        ...new Expense({
          ...expense,
          date: dateformat,
          companyId,
          parentDocumentId: documentId,
          type: DB_TABLES_TO_SCOPE[collection],
          createdBy: user.id,
        }),
      };

      const storageRef = ref(
        storage,
        `companies/${companyId}/${DB_TABLES_TO_SCOPE[collection]}/${documentId}/${dbTables.EXPENSES}/${newExpense.id}/${name}`
      );
      const task = uploadBytesResumable(storageRef, file);
      task.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          //
          getDownloadURL(task.snapshot.ref).then((downloadURL) => {
            newExpense.pdfURL = downloadURL;
            setdocumentAI({
              totalActual,
            });
            setEditing({ ...newExpense, isCreating: true });
            expensesCpy.push(newExpense);
            setExpenses(expensesCpy);
            setTimeout(() => {
              const dateElement = document.getElementById("expense_date_input");
              dateElement.focus();
            }, 50);
            setExpandConfig({ ...expandConfig, [expense.id]: true });
            if (confidenceAverage < 100) {
              const documentToTrain = new DocumentToTrain({
                companyId: companyId,
                parentId: documentId,
                scope: DB_TABLES_TO_SCOPE[collection],
                pdfURL: url,
                confidenceAverage,
              });
              setDoc(
                doc(
                  firestore,
                  `${dbTables.COMPANIES}/${companyId}/${dbTables.DOCUMENTS_TO_TRAIN}/${documentToTrain.id}`
                ),
                { ...documentToTrain }
              );
            }
          });
        }
      );
    }
    setLoadingPDF(false);
    setFileTransferedData(null);
  }

  function openTypeOfDocumentToProccess(ev) {
    const file = ev.dataTransfer.files[0];
    setFileTransferedData(file);
    setOpenFileTransfered(true);
  }

  function onProccessFile() {
    const checkboxValue = document.getElementById("processor-type-id").checked;
    setOpenFileTransfered(false);
    handleDrop({
      file: fileTransferedData,
      processorType: checkboxValue
        ? PROCESSOR_TYPE.RECEIPT
        : PROCESSOR_TYPE.INVOICE,
    });
  }

  return (
    <Modal open={open} onClose={handleCloseExpensesModal} className={className}>
      <ExpensesContainerStyled
        className="expensesBoxContainer"
        style={{
          background: "#fff",
          margin: "64px 24px 24px 24px",
          borderRadius: 6,
          position: "relative",
        }}
      >
        <CancelIcon
          style={{
            position: "absolute",
            top: 14,
            right: 22,
            cursor: "pointer",
            zIndex: 5,
          }}
          onClick={handleCloseExpensesModal}
        />
        {loadingPDF && (
          <Loader
            style={{
              justifyContent: "center",
              display: "flex",
              zIndex: 1000,
              alignItems: "center",
              top: 0,
              left: 0,
            }}
          />
        )}
        {openModalAttention && (
          <AttentionModal
            isOpen={openModalAttention}
            description={descriptionModalAttention}
            onClick={() => {
              deleteDoc(expenseToDelete.ref);
              onCloseModalAttention();
            }}
            onClose={onCloseModalAttention}
            title="Attention"
            acceptBlue={true}
            cancelText="No"
            confirmationText="Yes"
            cancellable={true}
          />
        )}

        {fileTransferedData && (
          <AttentionModal
            title="What type of document is this?"
            isOpen={openFileTransfered}
            description={
              <React.Fragment>
                <span style={{ color: colors.primaryDark }}>Invoice</span>
                <label
                  className="switch"
                  style={{ margin: "0px 50px", minWidth: 77 }}
                >
                  <input type="checkbox" id="processor-type-id" />
                  <span
                    data-positive="Receipt"
                    data-negative="Invoice"
                    className="slider round"
                    style={{
                      background: colors.primaryDark,
                    }}
                  />
                </label>
                <span style={{ color: colors.primaryDark }}>Receipt</span>
              </React.Fragment>
            }
            bodyStyles={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            bodyClassname="attentionModalBodyFileProcessor"
            confirmationText="Done"
            onClick={onProccessFile}
          />
        )}

        {!expenseRef && (
          <Grid
            container
            spacing={3}
            className={"expensesHeaderContainer"}
            style={{ opacity: editing ? 0.4 : 1 }}
          >
            <Grid item xs={4}>
              <div className="searchbox-expense-container">
                <SearchBox
                  value={filters.query}
                  onChange={(ev) =>
                    setFilters({
                      ...filters,
                      query: ev.target.value,
                    })
                  }
                  hasBeenReseted={() =>
                    setFilters({
                      ...filters,
                      query: "",
                    })
                  }
                  placeholder="Search"
                  disabled={!!editing}
                />
              </div>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "-webkit-center" }}>
              <div
                className={
                  isAllowed(
                    GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP
                  ) && !isVoided
                    ? "drag-and-drop-expense-pdf"
                    : "drag-and-drop-expense-pdf-disabled"
                }
                onDrop={openTypeOfDocumentToProccess}
              >
                <ExpenseFileIcon
                  style={{
                    margin: "0px 8px",
                    top: -1,
                    position: "relative",
                  }}
                />
                DRAG & DROP PDF INVOICE HERE
                {isAllowed(
                  GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP
                ) &&
                  !isVoided &&
                  !editing && (
                    <input className="input-to-load-pdf" type={"file"} />
                  )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="button-to-enter-expense-container">
                <Button
                  variant="contained"
                  color="primary"
                  className="button-to-enter-expense"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      query: "",
                    });
                    const expensesCpy = [...expenses];
                    const newExpense = {
                      ...new Expense({
                        companyId,
                        parentDocumentId: documentId,
                        type: DB_TABLES_TO_SCOPE[collection],
                        createdBy: user.id,
                      }),
                    };
                    setEditing({ ...newExpense, isCreating: true });
                    expensesCpy.push(newExpense);
                    setExpenses(expensesCpy);
                    setTimeout(() => {
                      const dateElement =
                        document.getElementById("expense_date_input");
                      dateElement.focus();
                    }, 50);
                  }}
                  disabled={
                    !!(
                      editing ||
                      !isAllowed(
                        GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_MANUALLY
                      ) ||
                      isVoided
                    )
                  }
                >
                  + ENTER EXPENSE MANUALLY
                </Button>
              </div>
            </Grid>
          </Grid>
        )}

        <div
          className={"expensesBodyContainer"}
          style={{
            background: getBackgroundColorByType({
              type: DB_TABLES_TO_SCOPE[collection],
            }),
          }}
        >
          <Grid container className="expenses-table-header">
            {expensesHeader.map((header) => {
              return (
                <Grid
                  key={header.id}
                  xs={header.xs}
                  style={header.headerStyles}
                >
                  <div
                    className="header-content"
                    onClick={() => {
                      if (!editing) {
                        setFilters({
                          ...filters,
                          sortedColumn: header.sortBy,
                          orderBy:
                            filters.orderBy === orderTypeSort.ASC
                              ? orderTypeSort.DESC
                              : orderTypeSort.ASC,
                        });
                      }
                    }}
                  >
                    {header.label}
                    {renderArrow(header.sortBy)}
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div className="expenses-table-body" id="expenses-table-body">
            {filterList({
              list: formatExpenses({ expenses }),
              fieldsToSearch: [
                EXPENSE_FIELDS.PAID_TO,
                "GLAccountName",
                "GLAccountDescription",
                EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES,
                EXPENSE_FIELDS.NOTES,
              ],
              filters,
            }).map((expense) => {
              return (
                <ExpenseItemComponent
                  expandConfig={expandConfig}
                  onExpand={(value) => {
                    setExpandConfig({ ...expandConfig, [expense.id]: value });
                  }}
                  expense={expense}
                  currentTotalActual={currentTotalActual}
                  expensesHeader={expensesHeader}
                  editing={editing}
                  onlyRead={isVoided}
                  onEdit={({ expense }) => {
                    setEditing(expense);
                  }}
                  onSave={async ({ expense }) => {
                    let itemsCpy = [...expense.items];
                    itemsCpy = itemsCpy.map((item) => ({
                      ...new ExpenseItem({ ...item }),
                    }));
                    const glAccountIds = itemsCpy.map(
                      (item) => item.GLAccountId
                    );
                    const newExpense = {
                      ...new Expense({
                        ...expense,
                        items: itemsCpy,
                        createdBy: expense.createdBy
                          ? expense.createdBy
                          : user.id,
                        glAccountIds,
                      }),
                    };
                    setDoc(
                      doc(
                        firestore,
                        `${dbTables.COMPANIES}/${companyId}/${collection}/${documentId}/${dbTables.EXPENSES}/${expense.id}`
                      ),
                      {
                        ...newExpense,
                      }
                    );
                    setEditing(null);
                    if (expenseRef) {
                      handleCloseExpensesModal({
                        ...newExpense,
                      });
                    }
                  }}
                  onDelete={({ expense }) => {
                    setExpenseToDelete(expense);
                    setDescriptionModalAttention(
                      <React.Fragment>
                        Do you want to delete this expense? <br /> This cannot
                        be undone
                      </React.Fragment>
                    );
                    setOpenModalAttention(true);
                  }}
                  GLAccountItems={GLAccountItems}
                  paidToMiscellaneo={paidToMiscellaneo}
                  // classes={classes}
                  isAllowed={isAllowed}
                  user={user}
                  documentAI={documentAI}
                  withRef={expenseRef ? "withRef" : ""}
                />
              );
            })}
          </div>
          <div className="expenses-table-body">
            <Grid container className="expense-item">
              {expensesHeader.map((header) => {
                return (
                  <Grid
                    key={header.id}
                    style={header.footerStyles}
                    xs={header.xs}
                  >
                    {getFooterTotals({
                      expenses: filterList({
                        list: formatExpenses({ expenses }),
                        fieldsToSearch: [
                          EXPENSE_FIELDS.PAID_TO,
                          "GLAccountName",
                          EXPENSE_FIELDS.DESCRIPTION_OF_CHARGES,
                          EXPENSE_FIELDS.NOTES,
                        ],
                        filters,
                      }),
                      header: header,
                    })}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </ExpensesContainerStyled>
    </Modal>
  );
}

export default ExpensesModal;
