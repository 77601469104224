import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import { sortObjectsBy, pathToString } from "../../helpers/helpers";
import { getNestedCollectionFromFirestoreTable } from "../../actions/DataActions";
import { TableCell, TableRow } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import moment from "moment";
import { firestore } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import { ErrorLogStyled } from "./styles";

function BackEndErrorLog() {
  const { companyId } = useParams();
  const companies = useSelector((state) => state.data.companies);
  const currentCompany = companies.find((company) => company.id === companyId);

  const dispatch = useDispatch();
  const listenErrorLog = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.BACKEND_ERROR_LOG,
      ]),
    })(dispatch)
  );

  let errorLogDB = useSelector(
    (state) => state.data.backend_error_log[companyId]
  );
  if (errorLogDB) {
    errorLogDB.sort(sortObjectsBy("date", true));
  }

  useEffect(() => {
    listenErrorLog();
  }, [companyId]);

  const tbodyDisplay =
    errorLogDB &&
    errorLogDB.map((item) => {
      return (
        <TableRow key={item.id}>
          <TableCell
            style={{
              fontSize: "0.875rem",
            }}
          >
            {moment(item.date).format("M/D/YY hh:mm:ss a")}
          </TableCell>
          <TableCell style={{ textAlign: "left", fontSize: "0.875rem" }}>
            {item.error_message_id}
          </TableCell>
          <TableCell
            style={{
              fontSize: "0.875rem",
            }}
          >
            {item.description}{" "}
            <span
              style={{
                background: "lightgray",
                borderRadius: 9,
                padding: "2px 6px",
                fontSize: 12,
                fontWeight: 500,
                color: "#25486d",
              }}
            >
              {item.end_point}
            </span>
            {item.end_point_type && (
              <span
                style={{
                  background: "lightgray",
                  borderRadius: 9,
                  padding: "2px 6px",
                  fontSize: 10,
                  fontWeight: 500,
                  color: "white",
                  backgroundColor: "#39F",
                }}
              >
                {item.end_point_type}
              </span>
            )}
          </TableCell>
        </TableRow>
      );
    });

  const thead = ["Date and Time", "Error ID", "Description"];

  const theadDisplay = thead.map((thead) => {
    return (
      <TableCell
        style={
          thead === "Description"
            ? { width: "80%", top: 40, fontSize: "0.875rem" }
            : { width: "10%", top: 40, fontSize: "0.875rem" }
        }
      >
        {thead}
      </TableCell>
    );
  });

  return (
    <ErrorLogStyled className="errorLogContainer">
      <div
        style={{
          textAlign: "right",
          height: 40,
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: "white",
        }}
      >
        <span>Enable Back-End error log</span>
        <Switch
          onChange={(ev, value) => {
            console.log("COMPANY", currentCompany);
            updateDoc(
              doc(firestore, `${dbTables.COMPANIES}/${currentCompany.id}`),
              { backend_error_log: value }
            );
          }}
          checked={currentCompany.backend_error_log}
        />
      </div>

      <DataTable header={theadDisplay} className="row-hover-over">
        {tbodyDisplay}
      </DataTable>
    </ErrorLogStyled>
  );
}

export default BackEndErrorLog;
