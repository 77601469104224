import React, { useState, useEffect } from "react";
import { dbTables } from "../../../api/types/dbTables";
import userTypes from "../../../api/types/userTypes";
import {
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  PO_STATUS,
} from "../../../helpers/constants";
import {
  getProgressValue,
  getDashboardSearchPath,
} from "../../../helpers/helpers";
import { useFavorites, useFavoritesList, useQueryParams } from "../../../hooks";
import { checkSpecificPermissions } from "../../../hooks/permissions";
import {
  useOrderDashboardSetup,
  useCurrentSalesOrder,
  useSortPuchaseOrders,
} from "../../../hooks/salesOrders";
import { useUser } from "../../../hooks/user";
import AttentionModal from "../../Modal/AttentionModal";
import { PurchaseOrderNavigationStyled } from "../styles";
import TabLabel from "../TablLabel";
import { TabScrollButton } from "../TabScrollButton";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "./helpers";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../../actions/DataActions";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";

function PurchaseOrderNavigation() {
  const dispatch = useDispatch();
  const { salesOrderId, purchaseOrderId } = useOrderDashboardSetup();
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const user = useUser();
  const purchaseOrders = useSortPuchaseOrders();
  const currentSalesOrder = useCurrentSalesOrder({
    salesOrderId,
  });
  const poFavorites = useFavoritesList({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const poFavoritesDB = useFavorites({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const newIndex = purchaseOrders.findIndex(
      (item) => item.id === purchaseOrderId
    );
    if (newIndex !== -1) {
      setCurrentTab(newIndex);
    }
  }, [purchaseOrderId, purchaseOrders.length]);

  const redirect = ({ hasPermission, item, badgeBackdrop }) => {
    if (hasPermission) {
      // trackEvent("PO Dashboard - PO - Tab");
      if (badgeBackdrop) {
        setBackdrop({
          type: BACKDROP_TYPE.BADGE_NUMBER,
          id: BACKDROP_TYPE.BADGE_NUMBER,
        });
      }
      navigate(
        getDashboardSearchPath({
          ...queryParams,
          purchaseOrderId: item.id,
          shipmentId: item.shipmentIds[0] || "",
          section: badgeBackdrop
            ? ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
            : ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
        }),
        { replace: true }
      );
    } else {
      setDescriptionModalAttention(
        <React.Fragment>
          You do not have permission to see <strong>{item.number}</strong>
        </React.Fragment>
      );
      setOpenModalAttention(true);
    }
  };

  return (
    <PurchaseOrderNavigationStyled
      className="purchaseOrderNavigationContainer"
      style={{}}
    >
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClick={() => setOpenModalAttention(false)}
        />
      )}
      {!!queryParams.purchaseOrderId ? (
        <div className="tabs-content">
          <Tabs
            id="tabs-container-id"
            value={currentTab}
            textColor="primary"
            className={"customTabsContainer"} // "custom-tabs"
            style={{
              margin: "0px 0px 0px 32px ",
              position: "relative",
              top: 2,
              minHeight: 40,
            }}
            classes={{
              indicator: "custom-tab-indicator",
              flexContainer: "custom-tabs-container",
              scrollButtons: "scroll-buttons",
              scroller: "custom-scroller",
            }}
            variant="scrollable"
            scrollButtons="auto"
            ScrollButtonComponent={(props, ref) => (
              <TabScrollButton
                props={{ ...props, scroller: "custom-scroller" }}
              />
            )}
          >
            {purchaseOrders.map((item, index) => {
              const poInactive =
                item.status === PO_STATUS.VOIDED ||
                item.status === PO_STATUS.REASSIGNED;
              const hasPermission =
                user.role === userTypes.SUPER_ADMIN ||
                user.role === userTypes.TRADEDASH_EMPLOYEE ||
                checkSpecificPermissions({
                  userPermissions: user.permissions,
                  permissionToCheck: [
                    item.factoryId,
                    GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                  ],
                });
              let classNamePOTab = poInactive
                ? "custom-tab reassigned"
                : hasPermission
                ? "custom-tab"
                : "custom-tab disabled";
              return (
                <Tab
                  id={`purchase-order-tab-${item.id}`}
                  key={item.id}
                  onClick={(ev) => {
                    redirect({ hasPermission, item });
                    ev.stopPropagation();
                  }}
                  classes={{
                    selected: "activeTab",
                    wrapper: "wrapped-po",
                  }}
                  style={{
                    margin: "0px 4px",
                    fontSize: 15,
                    backgroundColor: poInactive && "#ddd",
                  }}
                  className={classNamePOTab}
                  label={
                    <TabLabel
                      key={"po-navigation-tab-label-" + item.id}
                      currentIndex={index}
                      progress={getProgressValue(item)}
                      userId={user.id}
                      itemId={item.id}
                      po={{
                        ...item,
                      }}
                      label={item.number}
                      salesOrder={{ ...currentSalesOrder }}
                      currentTab={currentTab}
                      companyId={user.companyId}
                      user={user}
                      // isReadOnly={isReadOnly}
                      scope={NOTES_SCOPE.PURCHASE_ORDER}
                      onSetBackdrop={(ev) => {
                        redirect({ hasPermission, item, badgeBackdrop: true });
                        ev.stopPropagation();
                      }}
                      favoriteTable={dbTables.PURCHASE_ORDER_FAVORITES}
                      favorites={poFavorites}
                      favoritesDB={poFavoritesDB}
                    />
                  }
                />
              );
            })}
          </Tabs>
        </div>
      ) : (
        <div
          className="no-pos-connected-container"
          style={{
            fontSize: 24,
            fontWeight: 700,
            color: "#25486d",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {currentSalesOrder.id &&
            "THERE ARE NO POs CONNECTED TO THIS SALES ORDER"}
        </div>
      )}
    </PurchaseOrderNavigationStyled>
  );
}

export default PurchaseOrderNavigation;
