import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Photo as PhotoIcon } from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase";
import { Avatar } from "@mui/material";

function UploadAvatar({
  obj,
  storageLocation = "",
  onUpload,
  disabled,
  className,
  classNameHover = "avatar-hover",
  style = {
    zIndex: 10,
    opacity: 0,
    width: 50,
    height: 50,
    borderRadius: "50%",
    position: "absolute",
    cursor: "pointer",
  },
  styleContainer = {},
}) {
  const [avatarHoverClassname, setAvatarHoverClassname] =
    useState(classNameHover);
  const [warningMessage, setWarningMessage] = useState("");

  async function uploadFile(filename) {
    const storageRef = ref(storage, `${storageLocation}/${filename.name}`);
    const uploadFile = uploadBytesResumable(storageRef, filename);
    uploadFile.on(
      "state_changed",
      () => {},
      (err) => {
        console.log("error", err);
      },
      () => {
        getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          onUpload(downloadURL);
          setWarningMessage("");
        });
      }
    );
  }
  return (
    <div
      className="avatar-container"
      style={{
        ...styleContainer,
      }}
    >
      {!disabled && (
        <label
          onClick={(e) => e.stopPropagation()}
          onMouseLeave={() => {
            setAvatarHoverClassname(classNameHover);
          }}
          onMouseEnter={() => {
            setAvatarHoverClassname(avatarHoverClassname + " opaque");
          }}
          style={style}
        >
          <Dropzone
            onDrop={(accept, reject) => {
              const [fileAccept] = accept;
              if (fileAccept) uploadFile(fileAccept);
              if (reject.length)
                reject.forEach(({ file, errors }) => {
                  const [messageError] = errors;
                  console.log(messageError);
                  if (messageError) setWarningMessage(messageError.message);
                  else setWarningMessage("File Error");
                });
            }}
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpeg"],
            }}
            maxFiles={1}
            multiple={false}
            disabled={disabled}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </label>
      )}
      <Avatar src={obj.url_logo} alt={obj.name} className={className}>
        <div className={avatarHoverClassname}>
          <PhotoIcon />
        </div>
      </Avatar>
      <p style={{ margin: "10px", color: "red" }}>{warningMessage}</p>
    </div>
  );
}

export default UploadAvatar;
