import { typeOfTask } from "../../api/types/dbTables";
import { colors } from "../../assets/jss/variables";
import AppConfig from "../../constants/AppConfig";
import { dayHeight, dayWidth } from "../../helpers/constants";
import { hexToRgbA } from "../../helpers/helpers";
import { onHoverBackground } from "../../helpers/variableColors";
import { Paper, Popover, styled } from "@mui/material";

export const GanttChartStructureStyled = styled("div")(() => ({
  "&.ganttChartContainer": {
    height: "-webkit-fill-available",
    background: "#fff",
    margin: 16,
    borderRadius: 8,
  },
}));

const scrollButtonContainerStyles = {
  width: 80,
  height: 80,
  position: "absolute",
  zIndex: 10,
  background: "#8D8D8D",
  opacity: 0.3,
  borderRadius: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const GanttChartPopover = styled(Popover)(({ theme }) => ({
  "&.ganttChartPopover": {
    pointerEvents: "none",
    zIndex: 1300,
  },
  "& .calendarPaper": {
    padding: theme.spacing(1),
    borderRadius: 5,
    border: "2px solid white",
    borderLeft: "5px solid ",
    right: 36,
  },
  [`& .paper_${typeOfTask.PURCHASE_ORDER}`]: {
    background: colors.purchaseOrderBackgroundColor,
  },
  [`& .paper_${typeOfTask.SALES_ORDER}`]: {
    background: colors.salesOrderBackgroundColor,
    borderColor: colors.salesOrderBorderColor,
  },
  [`& .paper_${typeOfTask.SHIPMENT}`]: {
    background: colors.shipmentBadge,
    borderColor: colors.shipmentBorderColor,
  },
}));

export const GanttChartCalendarStyled = styled("div")(({ theme }) => ({
  "&.calendarContainer": {
    width: "100%",
    marginLeft: "auto",
    overflow: "hidden",
    boxShadow: "3px 2px 10px 0px #ccc",
    height: "auto",
    position: "relative",
    background: "#fff",
    padding: 12,
  },
  "& .ghostTask": {
    height: 30,
    opacity: 0.25,
    top: -8,
    position: "absolute",
    borderRadius: 5,
    zIndex: 1,
  },
  "& .gridTableTask": {
    willChange: "no !important",
    overflowY: "auto !Important",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      display: "none !Important",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        background: "#CCC",
      },
    },
    "& .startPhase": {
      zIndex: 3,
    },
    "& .descriptionPhase": {
      zIndex: 4,
      borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    },
    "& .endPhase": {
      zIndex: 3,
      borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    },
  },
  "& .gridHeaderCalendarDate": {
    willChange: "no !important",

    background: "#fff",
    "& .weekend": {
      background: "#F9F9F9",
    },
  },
  "& .gridVirtualized": {
    willChange: "no !important",
    background: "#fff",
    overflow: "auto !Important",
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        background: "#CCC",
      },
    },
    "& .weekend": {
      background: "#F9F9F9",
    },
    "& .task-middleSALES_ORDER": {
      background: hexToRgbA(
        AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        0.2
      ),
    },
    "& .task-endSALES_ORDER": {
      background: hexToRgbA(
        AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        0.2
      ),
      borderTopRightRadius: "4px !Important",
      borderBottomRightRadius: "4px !Important",
    },
    "& .task-startSALES_ORDER": {
      background: hexToRgbA(
        AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        0.2
      ),
      borderTopLeftRadius: "5px !Important",
      borderBottomLeftRadius: "5px !Important",
      height: "31px !Important ",
    },
    "& .task-middlePURCHASE_ORDER": {
      background: "#fff",
      borderBottom: "1px solid",
      borderTop: "1px solid",
      opacity: 0.4,
    },
    "& .task-endPURCHASE_ORDER": {
      background: "#fff",
      borderTopRightRadius: "4px !Important",
      borderBottomRightRadius: "4px !Important",
      borderBottom: "1px solid",
      borderTop: "1px solid",
      borderRight: "1px solid",
      opacity: 0.5,
    },
    "& .task-startPURCHASE_ORDER": {
      background: "#fff",
      borderTopLeftRadius: "5px !Important",
      borderBottomLeftRadius: "5px !Important",
      height: "31px !Important ",
    },
    "& .task-middleSHIPMENT": {
      background: hexToRgbA(
        AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        0.2
      ),
    },
    "& .task-endSHIPMENT": {
      background: hexToRgbA(
        AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        0.2
      ),
      borderTopRightRadius: "4px !Important",
      borderBottomRightRadius: "4px !Important",
    },
    "& .task-startSHIPMENT": {
      background: hexToRgbA(
        AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        0.2
      ),
      borderTopLeftRadius: "5px !Important",
      borderBottomLeftRadius: "5px !Important",
      height: "31px !Important ",
    },
    "& .task-start-with-duration-1": {
      borderRadius: "5px !Important",
      background: "#D8E3F0",
      height: "31px !Important ",
    },
    "& svg": {
      zIndex: "1 !Important",
    },
  },
  "& .calendarHeader": {
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 0,
    height: dayHeight,
    width: dayWidth,
    "& svg": {
      zIndex: "1 !Important",
    },
  },

  "& .phase": {
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 0,
    height: dayHeight,
    width: dayWidth,
    justifyContent: "flex-start !Important",
    "& svg": {
      zIndex: "1 !Important",
    },
    "& .start-phase": {
      "&::before": {
        background: "rgb(141, 156, 177)",
        display: "flex",
        width: 3,
        height: 16,
        content: '""',
        position: "absolute",
        top: -6,
      },
    },
    "& .finish-phase": {
      "&::after": {
        background: "rgb(141, 156, 177)",
        display: "flex",
        width: 3,
        height: 16,
        content: '""',
        position: "absolute",
        top: -6,
        right: 0,
      },
    },
    "& .middle-phase": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#334C73",
      fontWeight: 700,
      fontSize: 12,
      background: "transparent !Important",
    },
    "& .middle-phase-range": {
      background: "transparent !Important",
    },
  },
  "& .draggable": {
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 0,
    height: dayHeight,
    width: dayWidth,
    justifyContent: "flex-start !Important",
    "& svg": {
      zIndex: "1 !Important",
    },
  },
  "& .paperTask": {
    textAlign: "center",
    borderRadius: 0,
    height: dayHeight,
    width: dayWidth,
    display: "flex",
    alignItems: "center",
    // THIS IS WHAT I CHANGE
    justifyContent: "flex-start !Important",
    "& svg": {
      zIndex: "1 !Important",
    },
  },

  "& .scrollToRightButtonContainer": {
    ...scrollButtonContainerStyles,
    right: 20,
    top: 120,
    "& .img-scroll-to-right": {
      cursor: "pointer",
    },
  },
  "& .scrollToLeftButtonContainer": {
    ...scrollButtonContainerStyles,
    right: 105,
    top: 120,
    "& .img-scroll-to-left": {
      transform: "rotate(180deg)",
      cursor: "pointer",
    },
  },
}));

export const ResizableComponentStyled = styled("div")(() => ({
  "&.resizableContainer": {
    "& .rndContainer": {
      display: "flex !Important",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "solid 1px transparent",
      zIndex: 22,
      borderRadius: 5,
      "& .text-description": {
        overflow: "hidden",
        height: 15,
        position: "absolute",
        margin: "0px 8px",
        textAlign: "left",
        fontSize: 12,
        maxWidth: "-webkit-fill-available",
        whiteSpace: "nowrap",
      },
    },
    "& .taskContainerBox": {
      width: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: dayHeight,
    },
  },
}));

export const TaskTableHeaderStyled = styled(Paper)(({ theme }) => ({
  "&.taskTableHeaderContainer": {
    "& .tableHeaderText": {
      zIndex: 1,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: 12,
      color: "#395989",
      fontWeight: 400,
    },
  },
}));

export const TablePhaseStyled = styled(Paper)(() => ({
  "& .PhaseText": {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#334C73",
    textAlign: "left",
    fontSize: 14,
    fontWeight: 900,
    fontFamily: "Roboto",
    background: "#FFF",
    width: dayWidth,
    height: dayHeight,
    borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
  },
  "& .expandOrCollapseContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    "& .disclosure-triangle": {
      cursor: "pointer",
    },
  },
}));

export const TaskPopoverStyled = styled(Paper)(() => ({
  "&.taskPopoverContainer": {
    padding: "0px 16px 16px 16px",
    position: "relative",
    zIndex: 10000,
  },
  "& .taskDescriptionBold": {
    fontSize: 16,
    fontWeight: 700,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 282,
    display: "inline-block",
  },
  "& .taskInfo": {
    fontSize: 14,
    fontWeight: 700,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 90,
    display: "inline-block",
    paddingLeft: 4,
  },
  "& .taskDescriptionNormal": {
    fontSize: 14,
    fontWeight: 400,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 210,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "& .taskDescriptionContainer": {
    minHeight: 50,
    borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    display: "flex",
    alignItems: "center",
    width: 300,
    padding: "12px 0px",
  },
  "& .taskInfoContainer": {
    height: 30,
    borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    display: "flex",
    alignItems: "center",
  },
  "& .linkedPOsContainer": {
    height: "auto",
    padding: "4px 0px",
    borderBottom: "1px solid #C6D9EE",
    display: "flex",
    alignItems: "center",
  },
  "& .linkedPOsDescription": {
    fontSize: 14,
    fontWeight: 400,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 210,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export const OptionsTableContainerPaperStyled = styled(Paper)(() => ({
  "&.optionsTableContainer": {
    "& .buttonViewOptions": {
      width: "100%",
      height: 30,
      background: "#fff",
      border: "1px solid #D0DCEA",
      fontSize: 13,
      color: "#334C73",
      fontWeight: "bold !Important",
      padding: 0,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "transparent !Important",
        boxShadow: "none",
      },
      "&:focus": {
        border: "1px solid #D0DCEA",
      },
    },
    "& .undoAndRedoContainer": {
      width: 135,
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #D0DCEA",
      height: 30,
      alignItems: "center",
      borderRadius: 4,
    },
    "& .undoContainer": {
      width: "50%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      margin: 0,
      borderRight: "1px solid #D0DCEA",
      color: "#334C73",
      fontSize: 13,
      fontWeight: 400,
      cursor: "pointer",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      background: "#fff",
      boxShadow: "none",
      "&:hover": {
        background: "#fff",
      },
    },
    "& .redoContainer": {
      width: "50%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      margin: 0,
      color: "#334C73",
      fontSize: 13,
      fontWeight: 400,
      cursor: "pointer",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      background: "#fff",
      boxShadow: "none",
      "&:hover": {
        background: "#fff",
      },
    },
    "& .viewOptionsAndMilestonesContainer": {
      height: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

export const ViewOptionsPopoverStyled = styled(Popover)(() => ({
  "&.viewOptionsPopoverContainer": {
    "& .viewOptionsPopover": {
      width: 354,
      padding: 12,
      display: "flex",
      "& .view-options-left-container": {
        width: "50%",
      },
      "& .view-options-right-container": {
        width: "50%",
      },
      "& .view-option-item-container": {
        display: "flex",
        alignItems: "center",
        padding: "6px 0px",
        "& input": {
          height: 12,
        },
        "& span": {
          fontSize: 12,
          color: "#334C73",
          marginLeft: 6,
          height: 12,
          cursor: "pointer",
        },
      },
    },
  },
}));

export const ViewOptionsContainerStyled = styled(Paper)(() => ({
  "&.viewOptionsContainer": {
    "& .buttonViewOptions": {
      width: "100%",
      height: 30,
      background: "#fff",
      border: "1px solid #D0DCEA",
      fontSize: 13,
      color: "#334C73",
      fontWeight: "bold !Important",
      padding: 0,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "transparent !Important",
        boxShadow: "none",
      },
      "&:focus": {
        border: "1px solid #D0DCEA",
      },
    },

    "& .checkboxRoot": {
      padding: 0,
      "& span": {
        "& svg": {
          width: 16,
          height: 16,
        },
      },
    },
  },
}));

export const MilestonePopoverStyled = styled(Popover)(() => ({
  "&.milestonesPopoverContainer": {},
  "& .milestonesPopover": {
    width: 365,
  },
  "& .milestoneHeader": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #E0E9F8",
    height: 33,
    padding: 12,
    "& .text-container": {
      fontSize: 12,
      color: "#334C73",
      fontWeight: 400,
    },
  },
  "& .milestoneBody": {
    width: "100%",
    "& .milestone-container": {
      display: "flex",
      alignItems: "center",
      padding: "6px 12px",
      borderBottom: "1px solid #E0E9F8",
      "& span": {
        fontSize: 12,
        color: "#334C73",
        height: 12,
        width: "100%",
      },
    },
  },
  "& .milestoneFooter": {
    "& .add-milestone": {
      color: "#334C73",
      fontSize: 11,
      fontWeight: 700,
      padding: "4px 20px",
    },
  },
}));

export const MilestonesContainerStyled = styled("div")(() => ({
  "&.milestonesContainer": {
    "& .buttonViewOptions": {
      width: "100%",
      height: 30,
      background: "#fff",
      border: "1px solid #D0DCEA",
      fontSize: 13,
      color: "#334C73",
      fontWeight: "bold !Important",
      padding: 0,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "transparent !Important",
        boxShadow: "none",
      },
      "&:focus": {
        border: "1px solid #D0DCEA",
      },
    },
  },
}));

export const ColorPickerPopoverContainerStyled = styled(Popover)(() => ({
  "&.colorPickerPopoverContainer": {
    "& .colorPickerPaperContainer": {
      padding: 12,
    },
  },
}));

export const ColorPickerStyled = styled("div")(() => ({
  "&.colorPickerContainer": {
    display: "flex",
    position: "relative",
    zIndex: 1,
    width: 24,
    height: 24,
  },
  "& .arrowDropDownCircleWrapper": {
    position: "absolute",
    background: "white",
    borderRadius: "50%",
    width: 24,
    height: 24,
    "& svg": {
      width: "auto",
      height: "auto",
    },
  },
}));

export const ShipmentPickerPopoverContainerStyled = styled(Popover)(() => ({
  "&.shipmentPickerPopoverContainer": {
    "& .shipmentPickerPaperContainer": {
      "& .shipment-item-container": {
        display: "flex",
        alignItems: "center",
        padding: "2px 6px",
        fontSize: 12,
        color: "#25486D",
        fontWeight: 400,
        borderBottom: "1px solid #F2F2F2",
        "&:hover": {
          background: onHoverBackground,
          fontWeight: 600,
        },
      },
    },
  },
}));

export const ShipmentPickerContainerStyled = styled("div")(() => ({
  shipmentPickerContainer: {
    margin: "0px 8px",
    display: "flex",
    position: "relative",
    zIndex: 1,
    width: 24,
    height: 24,
  },
}));

export const SalesOrderBadgeContainerStyled = styled("div")(() => ({
  "&.salesOrderBadgedWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 12px 12px 20px",
    background: "#EBF4FF",
  },
  "& .salesOrderBadgedContainer": {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 20,
      fontWeight: 900,
      margin: "0px 6px",
    },
  },
  "& .salesOrderButtonContainer": {
    "& .sobc-button-save-changes": {
      background: "#218Cff",
      color: "#fff",
      borderRadius: 5,
      fontSize: 14,
      fontWeight: 700,
      width: 160,
      height: 38,
    },
    "& .sobc-button-cancel": {
      border: "1px solid #8D9CB1",
      height: 38,
      width: 88,
      color: "#334C73",
      fontSize: 14,
      fontWeight: 700,
      marginLeft: 13,
      borderRadius: 5,
      background: "#FFF",
    },
  },
  "& .buttonRoot": {
    fontSize: 11,
    border: "1px solid #334C73",
    minHeight: 20,
    height: 24,
    margin: "0px 8px 0px 32px",
    background: "#fff",
    color: "#334C73",
    padding: "0px 6px",
    fontWeight: 900,
    "&:hover": {
      background: "#fff",
    },
  },
}));

export const PurchaseOrderBadgeStyled = styled("div")(() => ({
  "&.purchaseOrderBadgedContainer": {
    display: "flex",
    alignItems: "center",
    padding: "12px 20px",
    background: "#EBF4FF",
    flexWrap: "wrap",
  },
}));

export const POBadgeContainerStyled = styled("div")(() => ({
  "&.POBadgeContainer": {
    display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    border: "2px solid",
    borderRadius: 5,
    borderLeft: "6px solid",
    margin: "5px 8px 5px 0px",
    gap: 8,
    "& .check-box": {},
    "& .description": {
      fontSize: 12,
      fontWeight: 700,
      color: "#334C73",
      cursor: "pointer",
      maxWidth: 160,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      "& span": {
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },
}));

export const TaskTableContainerStyled = styled("div")(() => ({
  "&.taskTableContainer": {
    "& .taskStatus": {
      zIndex: "4 !Important",
      "& .task-state-moved": {
        color: "#334C73",
        fontSize: 12,
        fontWeight: 400,
        opacity: 0.3,
        margin: "auto",
      },
      "& .task-state-to-move-forward": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 32,
        height: 25,
        textAlign: "center",
        boxShadow: "0px 2px 5px rgb(0 0 0 / 25%)",
        borderRadius: 33,
        color: "#E02020",
        fontSize: 14,
        fontWeight: 400,
        cursor: "pointer",
        marginLeft: 8,
        background: "#FFF",
      },
      "& .task-state-to-move-backward": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 32,
        height: 21,
        textAlign: "center",
        boxShadow: "0px 2px 5px rgb(0 0 0 / 25%)",
        borderRadius: 10,
        color: "#30D536",
        fontSize: 14,
        fontWeight: 400,
        cursor: "pointer",
        marginLeft: 8,
        background: "#FFF",
      },
    },
    "& .taskTableContentFirstColumn": {},
    "& .taskTableContent": {
      borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
      "& .task-start-date": {
        "& svg": {
          opacity: 0,
        },
        "&:hover": {
          "& svg": {
            opacity: 1,
          },
        },
      },
      "& .task-finish-date": {
        "& svg": {
          opacity: 0,
        },
        "&:hover": {
          "& svg": {
            opacity: 1,
          },
        },
      },
      "& .task-assigned-to": {
        "& .arrowButton": {
          opacity: 0,
        },
        "&:hover": {
          "& .arrowButton": {
            opacity: 1,
          },
        },
      },
      "& .task-duration": {
        "& .edit-icon": {
          opacity: 0,
        },
        "&:hover": {
          "& .edit-icon": {
            opacity: 1,
          },
        },
      },
    },
  },
}));
