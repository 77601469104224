/**
 * Sidebar Reducers
 */

// nav links
 import navLinks from "../components/Sidebar/NavLinks";

const INIT_STATE = {
  sidebarMenus: navLinks,
};

const sidebarReduder = (state = INIT_STATE, action) => {
  return { ...state };
};
export default sidebarReduder;
