import React from "react";
import { styled } from "@mui/material";

const MessageContainer = styled("div")({
  "&.containerMessage": {
    display: "flex",
    justifyContent: "center",
    margin: "28px 0px",
    width: "100vw",
  },
  "& .text": {
    fontWeight: "bold",
    fontSize: 40,
    width: "100%",
    textAlign: "center",
  },
});

function EmptyMessage() {
  return (
    <MessageContainer className="containerMessage">
      <span className="text">
        The current filter selection does not have any results
      </span>
    </MessageContainer>
  );
}
export default EmptyMessage;
