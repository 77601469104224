import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AvatarEditor from "react-avatar-editor";
import CustomButton from "../Buttons/CustomButton";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { colors } from "../../assets/jss/variables";
import Dropzone from "react-dropzone";
import { storage } from "../../firebase";
import { updateDoc } from "firebase/firestore";
import { dbTables } from "../../api/types/dbTables";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Slider } from "@mui/material";
import { UserAvatarModalStyled } from "./styles";

let editor = "";

function UserAvatarModal({ openModal, handleOpenModal, currentUser }) {
  const [picture, setPicture] = useState({
    img: false,
    zoom: 1,
    croppedImg: currentUser.avatar,
    cropperOpen: false,
  });

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (picture.img && picture.cropperOpen) {
      handleSave();
    }
  }, [picture.zoom]);

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    handleOpenModal(false);
  };

  const cancelImg = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
      croppedImg: currentUser.avatar,
    });
  };

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = (e) => {
    try {
      if (setEditorRef) {
        const canvasScaled = editor.getImageScaledToCanvas();
        const croppedImg = canvasScaled.toDataURL();

        setPicture({
          ...picture,
          croppedImg: croppedImg,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    let url = URL.createObjectURL(e);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
      // croppedImg: url,
    });
    setTimeout(() => {
      setPicture({
        ...picture,
        img: url,
        cropperOpen: true,
        zoom: picture.zoom - 0.01,
      });
    }, 200);
  };

  const convertToFile = async () => {
    const url = picture.croppedImg;
    const res = await fetch(url);
    const blob = await res.blob();
    const newFile = new File([blob], currentUser.id, { type: "image/png" });
    return newFile;
  };

  const saveImage = async () => {
    setLoading(true);
    const file = await convertToFile();
    if (file) {
      const storageRef = ref(
        storage,
        `${dbTables.USERS}/${currentUser.id}/${file.name}`
      );
      const uploadFile = uploadBytesResumable(storageRef, file);
      uploadFile.on(
        "state_changed",
        () => {},
        (err) => {
          console.log("error", err);
        },
        () => {
          getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            updateDoc(currentUser.ref, {
              avatar: downloadURL,
            });
            setLoading(false);
            closeModal();
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <UserAvatarModalStyled isOpen={openModal} onClose={closeModal}>
      {isLoading && <LoadingBackdrop />}

      <div className={"modalContainer"}>
        <div className="columnPreview" style={{ position: "relative" }}>
          {picture.cropperOpen && (
            <React.Fragment>
              <CloseIcon
                className="cancelImage"
                onClick={cancelImg}
                style={{
                  height: 20,
                  position: "absolute",
                  right: 20,
                  top: 20,
                  color: colors.dangerRed,
                  background: "white",
                }}
              />
              <AvatarEditor
                ref={setEditorRef}
                image={picture.img}
                width={200}
                height={200}
                border={5}
                borderRadius={200 / 0.5}
                color={[255, 255, 255, 0.6]} // RGBA
                rotate={0}
                scale={picture.zoom}
                onPositionChange={() => {
                  handleSave();
                }}
              />
              <Slider
                aria-label="raceSlider"
                value={picture.zoom}
                min={1}
                max={10}
                step={0.1}
                onChange={handleSlider}
              />
            </React.Fragment>
          )}
          {!picture.cropperOpen && (
            <Dropzone
              onDrop={(accept, reject) => {
                const [fileAccept] = accept;
                if (fileAccept) handleFileChange(fileAccept);
              }}
              accept={{
                "image/png": [".png"],
                "image/jpeg": [".jpeg"],
              }}
              maxFiles={1}
              multiple={false}
            >
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    {...getRootProps({
                      className: `dropzone`,
                      style: {
                        borderColor: isDragActive ? colors.primaryDark : "",
                      },
                    })}
                  >
                    <p>{`Drag & Drop Images`}</p>
                    <input {...getInputProps()} />
                  </div>
                );
              }}
            </Dropzone>
          )}
        </div>
        <div className="columnPreview">
          <h5>Preview</h5>
          <img
            alt=""
            style={{ width: 150, height: 150, borderRadius: 150 / 0.5 }}
            src={picture.croppedImg}
          />
        </div>
      </div>
      <div className={"modalBottons"}>
        <CustomButton
          type="cancel"
          onClick={closeModal}
          styleContainer={{ marginRight: 15 }}
        >
          {"generic.text.cancel"}
        </CustomButton>
        <CustomButton
          type="success"
          onClick={() => {
            setLoading(true);
            saveImage();
          }}
        >
          {"generic.text.save"}
        </CustomButton>
      </div>
    </UserAvatarModalStyled>
  );
}

export default UserAvatarModal;
