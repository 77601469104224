import {
  getDoc,
  doc,
  getDocs,
  query,
  collection,
  where,
} from "firebase/firestore";
import { uniq } from "lodash";
import numeral from "numeral";
import { dbTables } from "../api/types/dbTables";
import { firestore } from "../firebase";
import { getActivitiesNotes, sortObjectsBy } from "./helpers";

export async function getLastActivityDate({
  salesOrderMention = {},
  POsMention = [],
  shipmentsMention = [],
  user = {},
  companyId = "",
}) {
  if (salesOrderMention.id) {
    return await getActivitiesNotes({
      companyId: companyId,
      orderId: salesOrderMention.id,
      userId: user.id,
      orderTable: dbTables.SALES_ORDERS,
    });
  }
  if (POsMention.length > 0) {
    const purchaseOrder = POsMention.sort(sortObjectsBy("number", true))[0];
    return await getActivitiesNotes({
      companyId: companyId,
      orderId: purchaseOrder.id,
      userId: user.id,
      orderTable: dbTables.PURCHASE_ORDERS,
    });
  }
  if (shipmentsMention.length > 0) {
    const shipment = shipmentsMention.sort(sortObjectsBy("number", true))[0];
    return await getActivitiesNotes({
      companyId: companyId,
      orderId: shipment.id,
      userId: user.id,
      orderTable: dbTables.SHIPMENTS,
    });
  }
}

export async function getOldestNote({
  salesOrderId = "",
  purchaseOrderId = "",
  shipmentId = "",
  userId = "",
  companyId = "",
}) {
  const creationDateArr = [];
  let salesOrderActivity = null;
  if (salesOrderId) {
    const SOActivitiesSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${salesOrderId}/${dbTables.ACTIVITIES}`
        ),
        where("userMentions." + userId, "==", false)
      )
    );
    const SOActivities = SOActivitiesSnap.docs.map((doc) => doc.data());
    salesOrderActivity = SOActivities.sort(
      sortObjectsBy("creationDate", false)
    )[0];
    if (salesOrderActivity) {
      creationDateArr.push(salesOrderActivity.creationDate);
    }
  }

  let purchaseOrderActivity = null;
  if (purchaseOrderId) {
    const POActivitiesSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${purchaseOrderId}/${dbTables.ACTIVITIES}`
        ),
        where("userMentions." + userId, "==", false)
      )
    );
    const POActivities = POActivitiesSnap.docs.map((doc) => doc.data());
    purchaseOrderActivity = POActivities.sort(
      sortObjectsBy("creationDate", false)
    )[0];
    if (purchaseOrderActivity) {
      creationDateArr.push(purchaseOrderActivity.creationDate);
    }
  }

  let shipmentActivity = null;
  if (shipmentId) {
    const shipmentActivitiesSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${shipmentId}/${dbTables.ACTIVITIES}`
        ),
        where("userMentions." + userId, "==", false)
      )
    );
    const shipmentActivities = shipmentActivitiesSnap.docs.map((doc) =>
      doc.data()
    );
    shipmentActivity = shipmentActivities.sort(
      sortObjectsBy("creationDate", false)
    )[0];
    if (shipmentActivity) {
      creationDateArr.push(shipmentActivity.creationDate);
    }
  }

  return creationDateArr.sort((a, b) => a - b)[0];
}

export function searchPOsByQuery({ purchaseOrders = [], searchText }) {
  const purchaseOrdersDB = purchaseOrders.filter((purchaseOrder) =>
    purchaseOrder.number.toLowerCase().includes(searchText.toLowerCase())
  );
  return purchaseOrdersDB.length > 0 ? true : false;
}

export function searchPOsAnVendorByQuery({ purchaseOrders = [], searchText }) {
  const purchaseOrdersDB = purchaseOrders.filter(
    (purchaseOrder) =>
      purchaseOrder.number.toLowerCase().includes(searchText.toLowerCase()) ||
      purchaseOrder.factoryName.toLowerCase().includes(searchText.toLowerCase())
  );
  return purchaseOrdersDB.length > 0 ? true : false;
}

function searchSalesOrdersByQuery({ salesOrders = [], searchText }) {
  const salesOrdersDB = salesOrders.filter((salesOrder) =>
    salesOrder.number.toLowerCase().includes(searchText.toLowerCase())
  );
  return salesOrdersDB.length > 0 ? true : false;
}

export function searchByShipments({ shipments = [], searchText = "" }) {
  const shipmentsDB = shipments.filter((shipment) =>
    shipment.number
      .toString()
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );
  return shipmentsDB.length > 0 ? true : false;
}

export function filterList({
  list = [],
  nonLookUpfilters = {},
  headerCells,
  headerColumns,
  favorites,
  screenType = "",
}) {
  let filteredList = [...list];
  if (nonLookUpfilters.user) {
    filteredList = filteredList.filter((el) => el.totalMentions > 0);
  }
  if (nonLookUpfilters.favorite) {
    filteredList = filteredList.filter((item) =>
      favorites.map((favoriteId) => favoriteId).includes(item.id)
    );
  }
  if (nonLookUpfilters.query) {
    const enabledFieldToSearch = [];
    headerCells.forEach((header) => {
      if (header.enabled && !!header.searchBy) {
        enabledFieldToSearch.push(header.searchBy);
      }
    });
    filteredList = filteredList.filter((el) => {
      return enabledFieldToSearch.some((field) => {
        if (field && field === headerColumns.PO_ORDER_READY_DATE_VENDOR) {
          if (
            searchPOsAnVendorByQuery({
              purchaseOrders: el.purchaseOrders,
              searchText: nonLookUpfilters.query,
            })
          ) {
            return true;
          }
        } else if (field && field === headerColumns.POS) {
          if (
            searchPOsByQuery({
              purchaseOrders: el.purchaseOrders,
              searchText: nonLookUpfilters.query,
            })
          ) {
            return true;
          }
        } else if (field && field === headerColumns.SHIPMENTS) {
          if (
            searchByShipments({
              shipments: el.shipments,
              searchText: nonLookUpfilters.query,
            })
          ) {
            return true;
          }
        } else if (
          screenType === SCREEN_TYPE.SHIPMENT_LIST &&
          field &&
          field === "salesOrder"
        ) {
          if (
            searchSalesOrdersByQuery({
              salesOrders: el.salesOrders,
              searchText: nonLookUpfilters.query,
            })
          ) {
            return true;
          }
        } else if (
          field &&
          el[field] &&
          el[field]
            .toString()
            .toLowerCase()
            .includes(nonLookUpfilters.query.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    });
  }
  filteredList.sort(
    sortObjectsBy(
      nonLookUpfilters.sortedColumn,
      nonLookUpfilters.orderBy === orderTypeSort.ASC ? true : false
    )
  );

  let totals = 0;
  let totalSubtotals = 0;
  let totalDeposit = 0;
  let totalBalance = 0;
  let totalPotentialLateFee = 0;
  let totalCBM = 0;
  let totalDiscount = 0;
  let totalWeight = 0;

  filteredList.forEach((order) => {
    totals += +numeral(order.total).value() || 0;
    totalPotentialLateFee += +numeral(order.potentialLateFee).value() || 0;
    totalCBM += +numeral(order.CBM).value() || 0;
    totalDiscount += +numeral(order.discount).value() || 0;
    totalSubtotals += +numeral(order.subtotal).value() || 0;
    totalDeposit += +numeral(order.deposit).value() || 0;
    totalBalance += +numeral(order.balance).value() || 0;
    totalWeight += +numeral(order.totalWeight).value() || 0;
  });
  const totalAmounts = {
    totals: numeral(totals).format("$ 0,0.00"),
    totalPotentialLateFee: numeral(totalPotentialLateFee).format("$ 0,0.00"),
    totalCBM: numeral(totalCBM).format("0,0.00"),
    totalDiscount: numeral(totalDiscount).format("$ 0,0.00"),
    totalSubtotals: numeral(totalSubtotals).format("$ 0,0.00"),
    totalDeposit: numeral(totalDeposit).format("$ 0,0.00"),
    totalBalance: numeral(totalBalance).format("$ 0,0.00"),
    totalWeight: numeral(totalWeight).format("0,0.00"),
  };
  return { filteredList, totalAmounts };
}

export const orderTypeSort = {
  ASC: "ASC",
  DESC: "DESC",
};

export const SCREEN_TYPE = {
  SALES_ORDER_LIST: "SALES_ORDER_LIST",
  PURCHASE_ORDER_LIST: "PURCHASE_ORDER_LIST",
  SHIPMENT_LIST: "SHIPMENT_LIST",
};

export const TASK_TEMPLATE_OPTIONS = {
  DELETE_TASK: "DELETE_TASK",
  ADD_TASK: "ADD_TASK",
  TASK_CHANGED_POSITION: "TASK_CHANGED_POSITION",
  CHANGED_DATA: "CHANGED_DATA",
};

export function shouldUpdateVendorTemplate({ changes = {} }) {
  if (
    changes[TASK_TEMPLATE_OPTIONS.TASK_CHANGED_POSITION] ||
    changes[TASK_TEMPLATE_OPTIONS.DELETE_TASK] ||
    changes[TASK_TEMPLATE_OPTIONS.ADD_TASK]
  ) {
    return true;
  }
  return false;
}

export const getShipmentsByPOs = async ({ purchaseOrders = [], path = "" }) => {
  let currentShipmentIds = [];
  purchaseOrders.forEach(
    (po) =>
      (currentShipmentIds = [...currentShipmentIds, ...(po.shipmentIds || [])])
  );
  currentShipmentIds = uniq(currentShipmentIds);
  const shipmentPromises = [];
  currentShipmentIds.forEach((shipmentId) => {
    shipmentPromises.push(getDoc(doc(firestore, `${path}/${shipmentId}`)));
  });

  const resolved = await Promise.all(shipmentPromises);
  return resolved.map((doc) => ({ ...doc.data(), ref: doc.ref }));
};
