import React, { useState, useEffect, useMemo } from "react";
import ProjectTaskFilters from "./ProjectTaskFilters";
import TaskListView from "./TaskListView";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import AdHocTaskComponent from "./AdHocTask";

import moment, { now } from "moment";

import {
  sortObjectsBy,
  getRandomId,
  useTaskNotificationCreator,
  taskNotificationType,
} from "../../helpers/helpers";

import AttentionModal from "../Modal/AttentionModal";
import AdHocTask from "../../api/model/AdHocTask";
import { useDataRefactored, useStorage } from "../../hooks";
import {
  CATEGORY_STATUS_BY_STAGE,
  getCategoryStatus,
  getStatusTask,
} from "../../helpers/tasks";
import { useBackdropState, useUser } from "../../hooks/user";
import { salesOrderTriggerTypes } from "../../helpers/salesOrder";
import { useShipments } from "../../hooks/shipments";
import {
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
  LOCAL_STORAGE_KEY,
  TASK_FILTER,
} from "../../helpers/constants";
import { getShipmentValue } from "../../helpers/orderDashboard";
import { getCorrectTimezone } from "../../helpers/ganttChart";
import { firestore } from "../../firebase";
import {
  filterTaskList,
  getListIndexAdHoc,
  getTaskUpdated,
} from "./HelperProjectTasks";
import AppConfig from "../../constants/AppConfig";
import { usePermissions } from "../../hooks/permissions";
import userTypes from "../../api/types/userTypes";
import { doc, increment, setDoc, updateDoc } from "firebase/firestore";
import { TaskPanelStyled } from "./styles";
import { BackdropDashboard } from "./sections/BackdropDashboard";
import ActivityTask from "../../api/model/ActivityTask.model";

const defaults = {
  taskTab: 0,
  taskFilter: TASK_FILTER.MINE,
  showCompleted: false,
};

function ProjectTasksV2({
  companyId,
  salesOrder,
  purchaseOrder,
  hasPermissionToModifiedDueDate,
  companyUsers,
  purchaseOrders = [],
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  currentShipment,
  onChangeShipment,
}) {
  const [projectTaskStorage, setProjectTaskStorage] = useStorage(
    LOCAL_STORAGE_KEY.projectTasks
  );
  const [openModal, setOpenModal] = useState(false);

  const [pendingToCompleted, setPendingToComplete] = useState({});
  const [isEditingTask, setIsEditingTask] = useState(false);

  const shipments = useShipments();
  const createTaskNotification = useTaskNotificationCreator();
  const permissions = usePermissions();
  const user = useUser();
  const backdropState = useBackdropState();
  const [taskFilter, setTaskFilter] = useState(
    getDashboardPersistence("taskFilter") || TASK_FILTER.MINE
  );
  const [showCompleted, setShowCompleted] = useState(
    getDashboardPersistence("showCompleted")
  );

  useEffect(() => {
    if (backdropState.type === BACKDROP_TYPE.TASK) {
      setCategoriesStatus(getCategoryStatus(true));
    }
  }, [backdropState.type]);

  const storageCollapse = projectTaskStorage["categoryCollapse"] || {};
  const [categoriesStatus, setCategoriesStatus] = useState({
    ...getCategoryStatus(true),
    ...storageCollapse,
  });

  function getDashboardPersistence(item, id) {
    if (projectTaskStorage) {
      const storageItem = projectTaskStorage[item] || defaults[item];
      const value = !id ? storageItem : storageItem[id] || defaults[item];
      return value;
    }
    return defaults[item];
  }

  const salesOrderTasks = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SALES_ORDERS,
      salesOrder.id,
      dbTables.SALES_ORDER_TASKS,
    ],
    changingParams: [companyId, salesOrder.id],
    stateReference: dbTables.SALES_ORDER_TASKS,
  });

  const purchaseOrderTasks = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.PURCHASE_ORDERS,
      purchaseOrder.id,
      dbTables.PURCHASE_ORDER_TASKS,
    ],
    changingParams: [companyId, salesOrder.id, purchaseOrder.id],
    stateReference: dbTables.PURCHASE_ORDER_TASKS,
  });

  const shipmentTasks = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyId,
      dbTables.SHIPMENTS,
      currentShipment.id,
      dbTables.SHIPMENT_TASKS,
    ],
    changingParams: [companyId, currentShipment.id],
    stateReference: dbTables.SHIPMENT_TASKS,
  });

  function toggleCompleted(value) {
    setShowCompleted(value);
    updatePersistence("showCompleted", value);
  }

  function onClickTask({ task, changeStyleTo, setPending }) {
    if (isReadOnly) {
      handleReadOnlyModal();
      return;
    }
    const currentStatus = pendingToCompleted[task.id];
    if (currentStatus) {
      clearTimeout(currentStatus);
      setPendingToComplete((oldData) => ({
        ...oldData,
        [task.id]: false,
      }));
    } else {
      setPendingToComplete((oldData) => ({
        ...oldData,
        [task.id]: setTimeout(() => {
          completeTask(task);
          changeStyleTo(
            showCompleted
              ? "project-task completeOpacity"
              : "project-task pendingSize"
          );
        }, 5800),
      }));
    }
  }

  const completeTask = (task) => {
    const { dayOffset, notificationId, fieldsToUpdate, notificationTask } =
      getTaskUpdated({
        purchaseOrder,
        salesOrder,
        task,
        user,
        companyId,
        shipments,
      });
    if (dayOffset !== 0) {
      createTaskNotification({
        task: {
          ...notificationTask,
        },
        randomId: notificationId,
        type: taskNotificationType.COMPLETED,
      });
    }
    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    if (task.shipmentId) {
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SHIPMENTS}/${task.shipmentId}/${dbTables.SHIPMENT_TASKS}/${task.id}`
        ),
        { ...fieldsToUpdate }
      );
    } else if (task.type === typeOfTask.SALES_ORDER) {
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.SALES_ORDER_TASKS}/${task.id}`
        ),
        { ...fieldsToUpdate }
      );
    } else {
      updateDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.PURCHASE_ORDER_TASKS}/${task.id}`
        ),
        { ...fieldsToUpdate }
      );
    }
  };

  const cleanPendingComplete = (task) => {
    const timerId = pendingToCompleted[task.id];
    if (timerId) {
      clearTimeout(timerId);
      setPendingToComplete((oldData) => ({
        ...oldData,
        [task.id]: false,
      }));
    }
  };

  function isEmpty(inputText) {
    if (/^\s+$/.test(inputText) || !inputText) {
      return true;
    }
    return false;
  }

  function handleUpdateTotalTask({ type, offset, shipmentId }) {
    const parseOffset = parseInt(offset);
    const triggerType = salesOrderTriggerTypes.COMPLETED_TASKS;
    if (type === typeOfTask.SHIPMENT) {
      const shipment = shipments.find((shipment) => shipment.id === shipmentId);
      if (shipment) {
        updateDoc(shipment.ref, {
          totalTasks: increment(parseOffset),
          triggerType: triggerType,
        });
      }
    } else if (type === typeOfTask.SALES_ORDER) {
      updateDoc(salesOrder.ref, {
        totalTasks: increment(parseOffset),
        triggerType: triggerType,
      });
    } else {
      updateDoc(salesOrder.ref, {
        totalTasks: increment(parseOffset),
        triggerType: triggerType,
      });
      updateDoc(purchaseOrder.ref, {
        totalTasks: increment(parseOffset),
        triggerType: triggerType,
      });
    }
  }

  function onConfirmedCreatedAdHocTask({ task, type }) {
    changeTaskFilter("ALL");
    handleCategoriesStatus(CATEGORY_STATUS_BY_STAGE[task.stage], true);
    const notificationId = getRandomId();
    // setStorage("taskFilter", "ALL");
    let status = getStatusTask(task);
    const activity = new ActivityTask({
      id: task.id,
      companyId: companyId,
      creationDate: now(),
      detail: `<strong>${
        task.description
      }</strong> and assigned it to <strong>@${
        companyUsers.find((user) => user.id === task.assignedTo).displayName
      }</strong>`,
      scope:
        type === typeOfTask.SALES_ORDER
          ? "SALES_ORDER"
          : type === typeOfTask.PURCHASE_ORDER
          ? "PURCHASE_ORDER"
          : "SHIPMENT",
      type: "CREATED_ADHOC_TASK",
      user: user.id,
      taskId: task.id,
    });

    const companyRef = `${dbTables.COMPANIES}/${companyId}`;
    if (type === typeOfTask.SALES_ORDER) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        { ...activity }
      );
    } else if (type === typeOfTask.PURCHASE_ORDER) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        { ...activity }
      );
    } else {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SHIPMENTS}/${task.shipmentId}/${dbTables.ACTIVITIES}/${activity.id}`
        ),
        { ...activity }
      );
    }
    if (task.assignedTo !== user.id) {
      createTaskNotification({
        task: {
          ...task,
          salesOrderId: salesOrder.id,
          purchaseOrderId: purchaseOrder.id,
          customerId: salesOrder.customerId,
          factoryId: purchaseOrder.factoryId,
          PONumber: purchaseOrder.number,
          SONumber: salesOrder.number,
          shipmentNumber: getShipmentValue({
            shipments,
            shipmentId: task.shipmentId,
            field: "number",
          }),
          isAdHocTask: true,
          companyId: companyId,
          createdBy: user.id,
        },
        randomId: notificationId,
        type: taskNotificationType.ADHOC_TASK,
      });
    }
    const startDate = getCorrectTimezone({
      date: moment(now()).startOf("day").valueOf(),
    });
    const taskToCreate = {
      ...task,
      enableToEdit: user.id,
      creationDate: now(),
      startDate: startDate.valueOf(),
      status: status,
      companyId,
      salesOrderId: salesOrder.id,
      purchaseOrderId: purchaseOrder.id,
      isAdHocTask: true,
      inactive: false,
      SONumber: salesOrder.number,
      PONumber: purchaseOrder.number,
      customerId: salesOrder.customerId,
    };
    if (type === typeOfTask.SALES_ORDER) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.SALES_ORDER_TASKS}/${task.id}`
        ),
        {
          ...taskToCreate,
          factoryId: "",
        }
      );
    } else if (type === typeOfTask.PURCHASE_ORDER) {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.PURCHASE_ORDER_TASKS}/${task.id}`
        ),
        {
          ...taskToCreate,
          factoryId: purchaseOrder.factoryId,
        }
      );
    } else {
      setDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SHIPMENTS}/${task.shipmentId}/${dbTables.SHIPMENT_TASKS}/${task.id}`
        ),
        {
          ...taskToCreate,
          factoryId: "",
        }
      );
    }
    handleUpdateTotalTask({
      type,
      offset: 1,
      shipmentId: task.shipmentId,
    });

    setTimeout(() => {
      const adHocTaskElementTop = document.getElementById(
        task.id + "task-item"
      ).offsetTop;
      const elementStructureList = document.getElementById(
        "task-scroll-container"
      );
      elementStructureList.scrollTo({
        top: adHocTaskElementTop - 80,
        behavior: "smooth",
      });
    }, 500);
  }

  function createAdHocTask({ adHocTask, type }) {
    if (!isEmpty(adHocTask.description)) {
      const finishDate = getCorrectTimezone({
        date: moment(adHocTask.finishDate).endOf("day").valueOf(),
      });

      let task = new AdHocTask({
        ...adHocTask,
        listIndex: getListIndexAdHoc({
          salesOrderTasks,
          purchaseOrderTasks,
          adHocTask,
        }),
        finishDate: finishDate.valueOf(),
        type,
      });

      if (moment(task.finishDate).isBefore(moment(), "day")) {
        setOpenModal(true);
        return false;
      } else {
        onConfirmedCreatedAdHocTask({ task, type });
        return true;
      }
    }
  }

  const { POTasks, SOTasks } = filterTaskList({
    purchaseOrderTasks,
    salesOrderTasks,
    shipmentTasks,
    showCompleted,
    taskFilter,
    user,
    pendingToCompleted,
  });

  function changeTaskFilter(filter) {
    setTaskFilter(filter);
    updatePersistence("taskFilter", filter);
  }

  function updatePersistence(item, value) {
    setProjectTaskStorage(item, value);
  }

  function handleCategoriesStatus(key, value) {
    console.log("HANDLE CATEGORIES STATUS:: ", key, value);
    setProjectTaskStorage("categoryCollapse", {
      ...categoriesStatus,
      [key]: value,
    });
    setCategoriesStatus({
      ...categoriesStatus,
      [key]: value,
    });
  }

  function hasPermissionToReassign() {
    const reassingToPermission = permissions.find(
      (element) =>
        element.id === GENERAL_PERMISSION_VALUE.REASSIGN_TASK && element.enabled
    );
    if (
      user.role === userTypes.SUPER_ADMIN ||
      user.role === userTypes.TRADEDASH_EMPLOYEE ||
      reassingToPermission
    ) {
      return true;
    }
    return false;
  }

  const memoizedTaskListView = useMemo(() => {
    return (
      <TaskListView
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
        companyId={companyId}
        taskList={SOTasks.concat(POTasks).sort(sortObjectsBy("listIndex"))}
        onClickTask={onClickTask}
        hasPermissionToModifiedDueDate={hasPermissionToModifiedDueDate}
        companyUsers={companyUsers}
        user={user}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        handleUpdateTotalTask={handleUpdateTotalTask}
        allTaskList={SOTasks.concat(POTasks).sort(sortObjectsBy("listIndex"))}
        categoriesStatus={categoriesStatus}
        handleCategoriesStatus={handleCategoriesStatus}
        currentShipment={currentShipment}
        hasPermissionToReassign={hasPermissionToReassign()}
        onChangeShipment={onChangeShipment}
        pendingToCompleted={pendingToCompleted}
        onCleanPendingTask={cleanPendingComplete}
        showCompleted={showCompleted}
        taskFilter={taskFilter}
        setPendingToComplete={setPendingToComplete}
        handleIsEditingTask={setIsEditingTask}
        backdropId={backdropState.id}
      />
    );
  }, [
    salesOrderTasks,
    purchaseOrderTasks,
    shipmentTasks,
    taskFilter,
    showCompleted,
    taskFilter,
    pendingToCompleted,
    backdropState.id,
  ]);

  return (
    <TaskPanelStyled
      className={"tasksPanelContainer"} //"content-card"
      style={{
        minWidth: 400,
        flex: "unset",
        width: "38%",
        marginRight: 0,
        height: "100%",
        border: `1px solid ${AppConfig.themeColors.dividerColor}`,
      }}
      id="dashboard-tasks"
    >
      {openModal && (
        <AttentionModal
          isOpen={openModal}
          title="Attention"
          description="A task can't be created for a date in the past"
          onClick={() => {
            setOpenModal(false);
          }}
          onClose={() => setOpenModal(false)}
        />
      )}
      <BackdropDashboard backdropType={BACKDROP_TYPE.TASK} />

      <div
        className="content-card-header"
        // style={{ padding: "12px 19px 0px 9px" }}
      >
        <ProjectTaskFilters
          onTabClick={changeTaskFilter}
          filterValue={taskFilter}
          checkboxValue={showCompleted}
          onChangeCheckbox={() => toggleCompleted(!showCompleted)}
          purchaseOrder={purchaseOrder}
          SOTasks={SOTasks}
          POTasks={POTasks}
          salesOrder={salesOrder}
          purchaseOrders={purchaseOrders}
        />
      </div>
      <div
        className="content-card-content"
        style={{ height: "100%", flexDirection: "column" }}
      >
        <div
          id="task-scroll-container"
          className={"taskScrollContainer"}
          style={{ top: 0 }}
        >
          <AdHocTaskComponent
            companyUsers={companyUsers}
            createAdHocTask={createAdHocTask}
            salesOrder={salesOrder}
            purchaseOrder={purchaseOrder}
            isReadOnly={isReadOnly}
            handleReadOnlyModal={handleReadOnlyModal}
            isEditingTask={isEditingTask}
          />

          {memoizedTaskListView}
        </div>
      </div>
    </TaskPanelStyled>
  );
}

export default ProjectTasksV2;
