import React from "react";
import { Modal } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CustomModalBoxStyled } from "./style";

function CustomModal({
  header,
  headerStyles = {},
  children,
  bodyStyles = {},
  footer,
  footerStyles = {},
  isOpen,
  onClose = () => {},
  className,
  hidden,
  modalId,
  modalStyles = {},
  hasCloseIcon = true,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "6px",
  };

  return (
    <Modal
      autoFocus={false}
      open={isOpen}
      className={className}
      hidden={hidden}
      id={modalId}
      onClose={(ev) => {
        ev.stopPropagation();
        onClose(ev);
      }}
    >
      <CustomModalBoxStyled
        className="customModalContainer"
        sx={{ ...style, ...modalStyles }}
      >
        {hasCloseIcon && (
          <CloseIcon
            className={"closeModal"}
            onClick={onClose}
            style={{
              height: 24,
              width: 24,
            }}
          />
        )}

        {header && (
          <div
            className="modalHeader"
            style={{
              ...headerStyles,
            }}
          >
            {header}
          </div>
        )}
        <div
          className="modalBody"
          style={{
            ...bodyStyles,
          }}
        >
          {children}
        </div>
        <div
          className="modalFooter"
          style={{
            ...footerStyles,
          }}
        >
          {footer}
        </div>
      </CustomModalBoxStyled>
    </Modal>
  );
}

export default CustomModal;
