import React, { useState, useEffect } from "react";
import ProjectDocumentsTab from "./ProjectDocumentsTab";
import ManagementDocuments from "../Documents/ManagementDocuments";
import { dbTables } from "../../api/types/dbTables";
import { documentScope, getselectedUsersObj } from "../../helpers/documents";
import { DocumentPanelContainerStyled } from "./styles";
import userTypes from "../../api/types/userTypes";
import { useDataRefactored, useQueryParams, useStorage } from "../../hooks";
import {
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
  PO_STATUS,
} from "../../helpers/constants";
import AppConfig from "../../constants/AppConfig";
import { VoidLabel } from "../../helpers/iconSvgPath";
import { BackdropDashboard } from "./sections/BackdropDashboard";

const defaults = {
  documentTab: 0,
};

function ProjectDocumentsV2({
  companyID,
  currentSalesOrder,
  currentPurchaseOrder,
  user,
  permissionGroups,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  currentShipment,
  onChangeShipment,
}) {
  const [storage, setStorage] = useStorage("projectTasks");
  const queryParams = useQueryParams();
  const [forceTab, setForceTab] = useState(false);
  function getDashboardPersistence(item, id) {
    if (storage) {
      const storageItem = storage[item] || defaults[item];
      const value = !id ? storageItem : storageItem[id] || defaults[item];
      return value;
    }
    return defaults[item];
  }
  const [selectedDocumentTab, setSelectedDocumentTab] = useState(
    getDashboardPersistence("documentTab", currentPurchaseOrder.id) || 0
  );

  const salesOrderDocuments = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyID,
      dbTables.SALES_ORDERS,
      currentSalesOrder.id,
      dbTables.SALES_ORDER_DOCUMENTS,
    ],
    changingParams: [companyID, currentSalesOrder.id],
    stateReference: dbTables.SALES_ORDER_DOCUMENTS,
    query: null,
  });

  const purchasesOrderDocuments = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyID,
      dbTables.PURCHASE_ORDERS,
      currentPurchaseOrder.id,
      dbTables.PURCHASE_ORDER_DOCUMENTS,
    ],
    changingParams: [companyID, currentPurchaseOrder.id],
    stateReference: dbTables.PURCHASE_ORDER_DOCUMENTS,
    query: null,
  });

  const shipmentDocuments = useDataRefactored({
    path: [
      dbTables.COMPANIES,
      companyID,
      dbTables.SHIPMENTS,
      currentShipment.id,
      dbTables.SHIPMENT_DOCUMENTS,
    ],
    changingParams: [companyID, currentPurchaseOrder.id, currentShipment.id],
    stateReference: dbTables.SHIPMENT_DOCUMENTS,
    query: null,
  });

  useEffect(() => {
    if (queryParams.fileId && !forceTab) {
      const fileId = queryParams.fileId;
      let currentFile = salesOrderDocuments.find((file) =>
        fileId.includes(file.id)
      );
      if (currentFile) {
        changeDocumentTab(0);
        setForceTab(true);
        return;
      }
      currentFile = purchasesOrderDocuments.find((file) =>
        fileId.includes(file.id)
      );
      if (currentFile) {
        changeDocumentTab(1);
        setForceTab(true);
        return;
      }
      currentFile = shipmentDocuments.find(
        (file) =>
          fileId.includes(file.id) ||
          (file.isShipmentRootTask && fileId.includes(file.shipmentId))
      );
      if (currentFile) {
        changeDocumentTab(1);
        setForceTab(true);
        return;
      }
    } else if (!queryParams.fileId && forceTab) {
      setForceTab(false);
    }
  }, [
    salesOrderDocuments,
    purchasesOrderDocuments,
    shipmentDocuments,
    queryParams.fileId,
  ]);

  useEffect(() => {
    const documentTab = getDashboardPersistence(
      "documentTab",
      currentPurchaseOrder.id
    );
    if (selectedDocumentTab !== documentTab) {
      setSelectedDocumentTab(documentTab);
    }
  }, [currentPurchaseOrder]);

  function changeDocumentTab(tab) {
    setSelectedDocumentTab(tab);
    let documentTab = getDashboardPersistence("documentTab") || {};
    documentTab = {
      ...documentTab,
      [currentPurchaseOrder.id]: tab === 0 ? undefined : tab,
    };
    updatePersistence("documentTab", documentTab);
  }

  function updatePersistence(item, value) {
    setStorage(item, value);
  }

  function getDocumentLength(documents, user) {
    return documents.filter(
      (document) =>
        (user.role === userTypes.SUPER_ADMIN ||
          user.role === userTypes.TRADEDASH_EMPLOYEE ||
          (getselectedUsersObj(
            document.permissionUsers,
            document.permissionGroups,
            permissionGroups
          ) &&
            getselectedUsersObj(
              document.permissionUsers,
              document.permissionGroups,
              permissionGroups
            )[user.id] === true)) &&
        document.placeholder === false
    ).length;
  }

  const isOrderVoided =
    selectedDocumentTab === 0
      ? currentSalesOrder.status === PO_STATUS.VOIDED
      : currentPurchaseOrder.status === PO_STATUS.VOIDED ||
        currentPurchaseOrder.status === PO_STATUS.REASSIGNED;

  return (
    <React.Fragment>
      <BackdropDashboard backdropType={BACKDROP_TYPE.FOLDER} initTimer={200} />
      <DocumentPanelContainerStyled
        className="documentPanelContainer"
        style={{
          minWidth: 470,
          flex: "unset",
          width: "24%",
          border: `1px solid ${AppConfig.themeColors.dividerColor}`,
        }}
        id="dashboard-documents"
      >
        <div>
          <div className="content-card-header">
            <div
              style={{
                height: 30,
                textAlign: "center",
                fontSize: 23,
                fontWeight: "300",
              }}
            >
              <span className="documents-title">
                {isOrderVoided && <VoidLabel />}
                Documents
              </span>
            </div>
            <ProjectDocumentsTab
              onChange={changeDocumentTab}
              SODocsLength={getDocumentLength(salesOrderDocuments, user)}
              PODocsLength={getDocumentLength(purchasesOrderDocuments, user)}
              value={selectedDocumentTab}
            />
          </div>
        </div>

        <div
          className="content-card-content"
          style={{ height: "100%", flexDirection: "column" }}
        >
          <div className={"documentScrollContainer"}>
            {companyID && currentPurchaseOrder && (
              <ManagementDocuments
                allowMultipleFiles
                companyID={companyID}
                isReadOnly={isReadOnly}
                handleReadOnlyModal={handleReadOnlyModal}
                documents={
                  selectedDocumentTab === 0
                    ? salesOrderDocuments.map((document) => ({
                        ...document,
                        scope: documentScope.SALES_ORDER,
                      }))
                    : [
                        ...purchasesOrderDocuments.map((document) => ({
                          ...document,
                          scope: documentScope.PURCHASE_ORDER,
                        })),
                        ...shipmentDocuments.map((document) => ({
                          ...document,
                          scope: documentScope.SHIPMENT,
                        })),
                      ]
                }
                permissionGroupsDB={permissionGroups}
                dbTable={
                  selectedDocumentTab === 0
                    ? dbTables.SALES_ORDER_DOCUMENTS
                    : dbTables.PURCHASE_ORDER_DOCUMENTS
                }
                mainTable={
                  selectedDocumentTab === 0
                    ? dbTables.SALES_ORDERS
                    : dbTables.PURCHASE_ORDERS
                }
                mainTableID={
                  selectedDocumentTab === 0
                    ? currentSalesOrder.id
                    : currentPurchaseOrder.id
                }
                selectedDocumentTab={selectedDocumentTab}
                disabled={isOrderVoided}
                permissionScope={
                  GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT
                }
                onChangeShipment={onChangeShipment}
              />
              // <ManagementDocumentsV2
              //   documents={
              //     selectedDocumentTab === 0
              //       ? salesOrderDocuments.map((document) => ({
              //           ...document,
              //           scope: documentScope.SALES_ORDER,
              //         }))
              //       : [
              //           ...purchasesOrderDocuments.map((document) => ({
              //             ...document,
              //             scope: documentScope.PURCHASE_ORDER,
              //           })),
              //           ...shipmentDocuments.map((document) => ({
              //             ...document,
              //             scope: documentScope.SHIPMENT,
              //           })),
              //         ]
              //   }
              // />
            )}
          </div>
        </div>
      </DocumentPanelContainerStyled>
    </React.Fragment>
  );
}

export default ProjectDocumentsV2;
