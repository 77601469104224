import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import ItemTableButtons from "../Buttons/ItemTableButtons";
import { useTags, tagsByType } from "../../hooks/tags";
import { useCompanyId, useStorage } from "../../hooks/index";
import { useCustomers } from "../../hooks/customers";
import Shipment from "../../api/model/Shipment";
import { TableContainerStyled } from "../Tables/styles";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import {
  addItemActivityStream,
  filterPORowTableItem,
  formatCsvPOtable,
  getArrowIconSKU,
  parseRowTableItem,
  saveItem,
  updateShipmentItems,
  updateTags,
} from "../../helpers/itemsTableHelper";
import { useCurrentShipment, useShipments } from "../../hooks/shipments";
import {
  BACKDROP_TYPE,
  itemTableReference,
  ITEM_TABLE_ACTION,
  PO_STATUS,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import RowEmpty from "../Tables/RowEmpty";
import {
  useCompanyIsAllowed,
  useIsAllowedFunction,
} from "../../hooks/permissions";
import { useBackdropState, useCompanyUsers, useUser } from "../../hooks/user";
import { csvPOHeader, pdfPOHeader } from "../../helpers/itemsTableColumns";
import NewShipmentModal from "../Modal/NewShipmentModal";
import HeaderItemTable from "../Tables/HeaderItemTable";
import { onChangePODataShipment } from "../../helpers/orderDashboardRefactored";
import { colors } from "../../assets/jss/variables";
import { useSalesOrders } from "../../hooks/salesOrders";
import { updateDoc } from "firebase/firestore";
import RowCollapseV2 from "../Tables/RowCollapseV2";
import ViewerSKU from "./ViewerSKU";
import { BackdropDashboard } from "./sections/BackdropDashboard";
import ExpandItemTableModalV2 from "../Modal/ExpandItemTableModalV2";
import { performanceFirebase } from "../../firebase";
import { trace } from "firebase/performance";
import {
  Collapse,
  Grid,
  Grow,
  Snackbar,
  Table,
  TableBody,
} from "@mui/material";

function POTableSKU({
  showTable = false,
  columnsTable = [],
  dataTable = [],
  style = {},
  multiHeaderId = [],
  currentPurchaseOrder = {},
  currentSalesOrder = {},
  prefix = "",
  purchaseOrders = [],
  isModal,
  handleShowImage = () => {},
  modalShowImage,
  handleInformationSnackbar = () => {},
  cleanSearchBox,
  onWriteSearch,
  handleSearchBox,
  filterText,
  onClickRedirect,
  hasMismatchTag = false,
}) {
  const [imagesTableStorage, setImagesTableStorage] =
    useStorage("item_table_images");
  const backdropState = useBackdropState();
  const [openViewer, setOpenViewer] = useState(false);
  const companyId = useCompanyId();
  const customers = useCustomers();
  const tags = useTags();
  const shipments = useShipments();
  const currentUser = useUser();
  const [showImage, setShowImage] = useState(
    !imagesTableStorage[prefix] || !!modalShowImage
  );
  const [rowTable, setRowTable] = useState(dataTable);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyTable, setIsCopyTable] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [subHeaders, setSubHeaders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [sortTable, setSortTable] = useState({
    sortBy: "itemNumber",
    asc: true,
  });
  const isAllowed = useIsAllowedFunction();
  const [rowsIsOpen, setRowsIsOpen] = useState({});
  const [expandModal, setExpandModal] = useState(false);
  const [photosViewer, setPhotosViewer] = useState([
    { src: "", number: "", description: "" },
  ]);
  const [currentShipment, setCurrentShipment] = useState({
    ...new Shipment({
      companyId,
      customerId: "",
      CBM: "",
      invoice: "",
      totalValue: "",
      customerPO: "",
      totalUniqueItems: "",
    }),
  });
  const [itemRefToUpdate, setItemRefToUpdate] = useState({
    itemRef: null,
    rowRef: null,
    parentRowRef: null,
    referenceRef: null,
  });

  const salesOrders = useSalesOrders();
  const companyUsers = useCompanyUsers({ id: companyId });
  const currentShipmentDB = useCurrentShipment({
    purchaseOrder: currentPurchaseOrder,
  });

  const useCompanyAllowed = (id) => {
    return useCompanyIsAllowed(id, companyId);
  };

  useEffect(() => {
    if (_.difference(dataTable, rowTable)) {
      setRowTable(dataTable);
    }
  }, [dataTable]);

  const saveTags = ({
    reference,
    parentRow,
    row,
    allRows,
    currentShipmentId,
  }) => {
    try {
      let itemSalesOrder = currentSalesOrder;

      if (reference === itemTableReference.SHIPMENTS) {
        itemSalesOrder = salesOrders.find(
          (salesorderDb) => parentRow.id === salesorderDb.id
        );
      } else if (reference === itemTableReference.SALES_ORDERS) {
        itemSalesOrder = salesOrders.find(
          (salesorderDb) => row.id === salesorderDb.id
        );
      }

      updateTags({
        items: allRows,
        currentSalesOrder: itemSalesOrder,
        currentPurchaseOrder,
        tags: tagsByType(tags, TYPE_OF_TAGS.MISMATCH_TAG),
        allPurchaseOrders: purchaseOrders,
        prefix,
        poRowTable: dataTable,
        currentItem: row,
        currentShipmentId,
        setOpenSnackBar: handleInformationSnackbar,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  async function onChangeData({
    item,
    row,
    parentRow,
    reference,
    shipmentReference,
    blockRedirect,
  }) {
    const traceInstance = trace(performanceFirebase, "dashboard_update_po_sku");
    traceInstance.start();
    setIsLoading(true);
    if (!rowsIsOpen[item.id && reference === itemTableReference.SALES_ORDERS]) {
      setRowsIsOpen({ ...rowsIsOpen, [item.id]: true });
    }
    const currentShipmentId =
      row.action === ITEM_TABLE_ACTION.ASSIGNED_SHIPMENT
        ? row.id
        : currentShipmentDB.id;
    const newRow = onChangePODataShipment({
      item,
      row,
      parentRow,
      reference,
      currentPurchaseOrder,
    });
    await saveItem({ newRow });
    const allRows = rowTable.map((row) =>
      row.id === newRow.id ? newRow : row
    );
    setRowTable(allRows);
    saveTags({ reference, parentRow, row, allRows, newRow, currentShipmentId });

    if (reference === itemTableReference.SHIPMENTS) {
      const rowShipment =
        shipments.find((shipment) => shipment.id === row.id) ||
        shipmentReference;

      addItemActivityStream({
        row,
        parentRow,
        item,
        reference,
        currentShipmentId,
        user: currentUser,
        currentPurchaseOrder,
        companyUsers: companyUsers,
        currentShipment: rowShipment,
      });
      await updateShipmentItems({
        row,
        reference,
        allRows,
        currentPurchaseOrder,
        currentShipment: rowShipment,
        currentItem: item,
        setRedirectShipment: (row) => {
          if (!blockRedirect && currentShipmentId.id !== row.id) {
            console.log("redirect");
            onClickRedirect({
              item: rowShipment,
              erased: row.erased,
              editing: true,
            });
          }
        },
      });
    } else if (reference === itemTableReference.SALES_ORDERS) {
      const items = currentSalesOrder.items || [];
      const findIndex = items.findIndex((soItem) => soItem.id === item.id);
      const newItem = {
        id: item.id,
        cbmMaster: item.cbmMaster,
        description: item.description,
        image: [item.image[0] || ""],
        itemNumber: item.itemNumber,
        orderQuantity: parseFloat(row.allocation),
        piecesPerMaster: item.piecesPerMaster,
        price: parseFloat(item.salesOrderPrice[currentSalesOrder.id] || 0),
      };
      if (findIndex < 0) {
        items.push(newItem);
      } else {
        items[findIndex] = newItem;
      }
      await updateDoc(currentSalesOrder.ref, {
        items,
      });
    } else if (reference === itemTableReference.ITEM) {
      const cpyItems = allRows.map((cpyItem) => ({
        id: cpyItem.id,
        description: cpyItem.description,
        cbmMaster: cpyItem.cbmMaster,
        piecesPerMaster: cpyItem.piecesPerMaster,
        image: [cpyItem.image[0] || ""],
        price: parseFloat(cpyItem.unitCost),
        orderQuantity: parseFloat(cpyItem.purchaseOrderQuantity),
        itemNumber: cpyItem.itemNumber,
        weight: parseFloat(cpyItem.weight),
      }));
      await updateDoc(currentPurchaseOrder.ref, {
        items: cpyItems,
      });
    }
    traceInstance.stop();
    setIsLoading(false);
  }

  function updateCurrentShimpent(value, field) {
    setCurrentShipment({
      ...currentShipment,
      [field]: value || "",
    });
  }

  function handleClose() {
    const newShipment = new Shipment({
      companyId,
      customerId: currentSalesOrder.customerId || "",
      CBM: "",
      invoice: "",
      totalValue: "",
      customerPO: "",
      totalUniqueItems: "",
    });
    setCurrentShipment(newShipment);
    setItemRefToUpdate({
      itemRef: null,
      rowRef: null,
      parentRowRef: null,
      referenceRef: null,
    });
    setOpenModal(false);
  }

  useEffect(() => {
    let newSubHeader = [];
    columnsTable.forEach((column) => {
      if (column.multiHeader) {
        const subHeader = column.subHeader || [];
        const parseSubHeader = subHeader.map((subHeaderData) => ({
          ...subHeaderData,
          columnStyle: column.style,
        }));
        newSubHeader = [...newSubHeader, ...parseSubHeader];
      }
    });
    setSubHeaders(newSubHeader);
  }, []);

  useEffect(() => {
    if (backdropState.type === BACKDROP_TYPE.ITEM_TABLE_PO) {
      const element = document.getElementById(
        `purchaseOrder_${backdropState.id}`
      );
      if (element) {
        element.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }

      setRowsIsOpen({ ...rowsIsOpen, [backdropState.id]: true });
    }
  }, [backdropState]);

  const handleChange = (event) => {
    setImagesTableStorage(prefix, !event.target.checked);
    setShowImage(event.target.checked);
    handleShowImage(event.target.checked);
  };

  const currentRowTable = filterPORowTableItem({
    prefix,
    rowTable,
    searchText: filterText,
    salesOrders,
    shipments,
    customers,
  });

  const actualRow = parseRowTableItem({
    rows: currentRowTable,
    resetNew: false,
    sortTable,
    currentIdPath: {
      currentShipmentId: currentShipmentDB.id,
      currentSalesOrderId: currentSalesOrder.id,
      currentPurchaseOrder: currentPurchaseOrder.id,
    },
    columnsTable,
    purchaseOrders,
  });

  const getHeaderByScope = () =>
    csvPOHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const getPDFHeaderByScope = () =>
    pdfPOHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const handleSorting = (column, isSorting) => {
    if (column.sort) {
      if (!isSorting) {
        setSortTable({
          sortBy: column.id,
          asc: false,
        });
      } else {
        setSortTable({
          sortBy: column.id,
          asc: !sortTable.asc,
        });
      }
    }
  };
  const handleChangeShowImage = (value) => {
    setShowImage(value);
    handleShowImage(value);
  };

  function handlePhotosViewer({ open, photos = [] }) {
    setOpenViewer(open);
    if (photos.length > 0) {
      setPhotosViewer(photos);
    } else {
      setPhotosViewer([{ src: "" }]);
    }
  }

  const handleOpenRow = (item) => {
    const isOpen = !!rowsIsOpen[item.id];
    setRowsIsOpen({ ...rowsIsOpen, [item.id]: !isOpen });
  };

  const handleCollapseAll = (forceOpen) => {
    const isRowsOpen = actualRow.every((item) => rowsIsOpen[item.id]);
    if (isRowsOpen && !forceOpen) {
      setRowsIsOpen({});
    } else {
      let openAllRows = {};
      actualRow.forEach((item) => {
        openAllRows = { ...openAllRows, [item.id]: true };
      });
      setRowsIsOpen(openAllRows);
    }
  };
  const idExpandModal = isModal ? "ExpandModal" : "";
  return (
    <Fragment>
      <BackdropDashboard backdropType={BACKDROP_TYPE.ITEM_TABLE_PO} />
      <ViewerSKU
        showImage={showImage}
        handlePhotosViewer={handlePhotosViewer}
        photosViewer={photosViewer}
        openViewer={openViewer}
      />
      {openModal && (
        <NewShipmentModal
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          currentShipment={currentShipment}
          customers={customers}
          itemRefToUpdate={itemRefToUpdate}
          updateCurrentShimpent={updateCurrentShimpent}
          companyId={companyId}
          handleClose={handleClose}
          key={`${prefix}_newShipment`}
          onChangeData={onChangeData}
          onChangeShipment={(data) =>
            onClickRedirect({ item: data, editing: true})
          }
          openModal={openModal}
          setItemRefToUpdate={setItemRefToUpdate}
        />
      )}
      {expandModal && !isModal && (
        <ExpandItemTableModalV2
          isOpen={expandModal}
          hiddenHeader
          columnsTable={columnsTable}
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          currentShipment={currentShipmentDB}
          multiHeaderId={multiHeaderId}
          onClickRedirect={onClickRedirect}
          prefix={prefix}
          rowTable={rowTable}
          setExpandModal={setExpandModal}
          showTable={showTable}
          key={`${prefix}_expand`}
          handleShowImage={(show) => {
            setShowImage(show);
            setImagesTableStorage(prefix, !show);
          }}
          modalShowImage={showImage}
          hasMismatchTag={hasMismatchTag}
        />
      )}

      {showTable && (
        <ItemTableButtons
          isModal={isModal}
          idExpandModal={idExpandModal}
          buttonsStyle={style}
          prefix={prefix}
          isAllOpen={actualRow.every((item) => rowsIsOpen[item.id])}
          onCollapseAll={handleCollapseAll}
          csvData={formatCsvPOtable({
            rows: actualRow,
            shipments,
            customers,
            isPOTable: true,
            currentShipment: currentShipmentDB,
            currentSalesOrder,
            currentSalesOrders:salesOrders
          })}
          tableHeader={getHeaderByScope()}
          onExpandModal={() => setExpandModal(true)}
          pdfTableHeader={getPDFHeaderByScope()}
          handleOnCopyTable={(value) => setIsCopyTable(value)}
          handleOpenSnackBar={(value) =>
            setTimeout(() => {
              setOpenSnackBar(value);
            }, 50)
          }
          handleHiddenImages={() => {
            let currentShowImage = showImage;
            handleChangeShowImage(false);
            if (currentShowImage) {
              setTimeout(() => {
                handleChangeShowImage(currentShowImage);
              }, 500);
            }
          }}
          cleanSearchBox={cleanSearchBox}
          onWriteSearch={onWriteSearch}
          handleSearchBox={handleSearchBox}
          hasMismatchTag={hasMismatchTag}
        />
      )}
      <Grid container className="shipment-table-container">
        <Grid
          item
          xs={12}
          className="shipment-table-content"
          style={{ padding: !showTable ? "0px" : "", position: "relative" }}
        >
          {isLoading && (
            <LoadingBackdrop
              size={60}
              backdropStyle={{
                position: "fixed",
                zIndex: 10,
              }}
              withLogo={true}
            />
          )}
          <Collapse
            className="collapse-item-table-container"
            in={showTable}
            timeout="auto"
            unmountOnExit
          >
            <TableContainerStyled
              className={isModal ? "" : "shipmentTableContainerV2"}
              style={{
                maxWidth: style.maxWidth || "",
                outline: `2px solid ${colors.dividerColor}`,
                background: isModal ? "" : "white",
                height: "calc(100vh - 185px)",
              }}
              id={`${prefix}TableContainer${idExpandModal}`}
            >
              <Table
                stickyHeader
                aria-label="customized table"
                style={{
                  width: "100%",
                  ...style,
                  position: "relative",
                }}
                id={`itemTable${prefix}${idExpandModal}`}
              >
                <HeaderItemTable
                  columnsTable={
                    isCopyTable
                      ? columnsTable
                      : columnsTable.filter((column) => column.id !== "")
                  }
                  getArrowIcon={(value, style) =>
                    getArrowIconSKU({
                      isSorting: value,
                      additionalStyle: style,
                      sortTable,
                    })
                  }
                  handleChange={handleChange}
                  handleSorting={handleSorting}
                  handleShowImages={(value) => {
                    setImagesTableStorage(prefix, !value);
                    setShowImage(value);
                    handleShowImage(value);
                  }}
                  multiHeaderId={multiHeaderId}
                  prefix={prefix}
                  showImage={showImage}
                  sortTable={sortTable}
                  subHeaders={subHeaders}
                  purchaseOrders={purchaseOrders}
                  currentShipment={currentShipmentDB}
                  isCopyTable={isCopyTable}
                />
                {showTable && (
                  <TableBody>
                    {openSnackBar && (
                      <Snackbar
                        className={"snackbar-table-link"}
                        open={openSnackBar}
                        onClose={() => setOpenSnackBar(false)}
                        TransitionComponent={Grow}
                        ContentProps={{
                          "aria-describedby": "message-id",
                        }}
                        style={{ position: "absolute !important", top: 100 }}
                        autoHideDuration={1500}
                        message={
                          <span style={{ margin: "auto" }} id="message-id">
                            Copied to clipboard
                          </span>
                        }
                      />
                    )}
                    {actualRow.map((item, indexRow) => (
                      <RowCollapseV2
                        key={item.id + indexRow}
                        onClickRedirect={(data) =>
                          onClickRedirect({ ...data, sh: true })
                        }
                        id={`${prefix}_${item.id}_${item.itemNumber}_${indexRow}_row${idExpandModal}`}
                        item={{
                          ...item,
                          currentShipmentId: currentShipmentDB.id,
                          currentSalesOrderId: currentSalesOrder.id,
                          currentSalesOrderNumber: currentSalesOrder.number,
                          currentPurchaseOrder: currentPurchaseOrder.id,
                        }}
                        columns={columnsTable}
                        showImage={showImage}
                        multiHeaderId={multiHeaderId}
                        onChangeData={onChangeData}
                        onOpenModal={({ item, row, parentRow, reference }) => {
                          setItemRefToUpdate({
                            itemRef: item,
                            rowRef: row,
                            parentRowRef: parentRow,
                            referenceRef: reference,
                          });
                          setCurrentShipment({
                            ...currentShipment,
                            customerId: parentRow.customerId,
                          });
                          setOpenModal(true);
                        }}
                        indexRowParent={indexRow}
                        salesOrders={salesOrders}
                        shipments={shipments}
                        prefix={prefix}
                        handlePhotosViewer={handlePhotosViewer}
                        isAllowed={isAllowed}
                        companyAllowed={useCompanyAllowed}
                        handleOpenRow={handleOpenRow}
                        rowIsOpen={!!rowsIsOpen[item.id]}
                        isLastRow={actualRow.length - 1 === indexRow}
                        poisOutdated={!!currentPurchaseOrder.isOutdated}
                        isVoided={
                          currentPurchaseOrder.status === PO_STATUS.VOIDED
                        }
                        backdropStateId={backdropState.id}
                      />
                    ))}
                    {actualRow.length === 0 && (
                      <RowEmpty
                        columns={columnsTable}
                        subHeaders={subHeaders}
                        prefix={prefix}
                        multiHeaderId={multiHeaderId}
                        filterText={filterText}
                      />
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainerStyled>
          </Collapse>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default POTableSKU;
