import { now } from "moment";
import { TYPE_OF_FILE } from "../../helpers/constants";

export default class File {
  constructor({
    id = "",
    name = "",
    type = TYPE_OF_FILE.FOLDER,
    parent = "",
    parentId = "",
    path = "",
    url = "",
    version = 0,
    mainFileId = "",
    mainFile = false,
    listIndex = "",
    placeholder = false,
    lastModified = now(),
    lastModifiedDate = "",
    size = 0,
    user = "",
    created = now(),
    permissionUsers = {},
    permissionGroups = {},
    createdBy = "",
    storageRef = "",
    shipmentId = "",
    scope = "",
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.parent = parent;
    this.parentId = parentId;
    this.path = path;
    this.url = url;
    this.version = version;
    this.mainFileId = mainFileId;
    this.mainFile = mainFile;
    this.listIndex = listIndex;
    this.placeholder = placeholder;
    this.lastModified = lastModified;
    this.lastModifiedDate = lastModifiedDate;
    this.size = size !== "" ? size.toFixed(0) : "";
    this.user = user;
    this.created = created;
    this.permissionUsers = permissionUsers;
    this.permissionGroups = permissionGroups;
    this.createdBy = createdBy;
    this.storageRef = storageRef;
    this.shipmentId = shipmentId;
    this.scope = scope;
  }
}
