import React, { useRef, useState } from "react";
import DOMPurify from "dompurify";
import { getTextFromType, getTheDate } from "../../../helpers/helpers";
import { getUserAvatar } from "../../../helpers/users";
import {
  activityScopes,
  activityType,
  alreadyReadNote,
  getLabelScope,
} from "../../../helpers/activitiesStream";
import { ExpandedActivityModalStyled } from "./styles";
import { ImageResizeIcon, LockNoteIcon } from "../../../helpers/iconSvgPath";
import TooltipTD from "../../Tooltip/TooltipTD";
import { NOTES_SCOPE } from "../../../helpers/constants";
import { useCompanyUsers, useUser } from "../../../hooks/user";
import { cx } from "@emotion/css";

const salesOrderScope = [activityScopes.SALES_ORDER, activityScopes.QUOTE];
const purchaseOrderScope = [
  activityScopes.PURCHASE_ORDER,
  activityScopes.PURCHASE_QUOTE,
];

function ExpandActivityModal({
  openModalActivity,
  onCloseModalNotes,
  activity,
  onMarkRead = () => {},
  onAddMentionBackdrop,
}) {
  const users = useCompanyUsers({});
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(800);
  const currentUser = useUser();
  const containerRef = useRef();
  const noteIsReaded = alreadyReadNote({ activity, user: currentUser });
  const username = `@${
    users.find((user) => user.id === activity.user)
      ? users.find((user) => user.id === activity.user).displayName
      : "userdefault"
  }`;

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  function onMouseDown(ev, direction) {
    ev.preventDefault();
    setMouseIsDown(direction);
  }
  function onMouseUp(ev) {
    ev.preventDefault();
    setMouseIsDown(false);
  }

  const onMouseMove = (ev) => {
    if (!mouseIsDown) {
      return;
    }
    if (mouseIsDown === "L") {
      const addWidth = currentWidth - ev.movementX * 3;
      const maxWidth = window.screen.width;
      if (addWidth >= 800 && addWidth < maxWidth) {
        setCurrentWidth(addWidth);
      }
    }
    if (mouseIsDown === "R") {
      const addWidth = currentWidth + ev.movementX * 3;
      const maxWidth = window.screen.width;
      if (addWidth >= 800 && addWidth < maxWidth) {
        setCurrentWidth(addWidth);
      }
    }
  };

  const getLockIcon = () => {
    if (activity.lockNote) {
      return (
        <TooltipTD
          hasArrow={true}
          label={"Only users tagged in this note can see it"}
          style={{ display: "flex" }}
        >
          <LockNoteIcon size={16} style={{ marginRight: 15 }} />
        </TooltipTD>
      );
    } else {
      return <div style={{ width: 16, marginRight: 15 }} />;
    }
  };

  const getActivityClass = () => {
    return salesOrderScope.includes(activity.scope)
      ? NOTES_SCOPE.SALES_ORDER
      : purchaseOrderScope.includes(activity.scope)
      ? NOTES_SCOPE.PURCHASE_ORDER
      : NOTES_SCOPE.SHIPMENT;
  };

  return (
    <ExpandedActivityModalStyled
      open={openModalActivity}
      onClose={onCloseModalNotes}
      className={"modalActivity"}
      onMouseUp={onMouseUp}
      style={{ cursor: mouseIsDown ? "grabbing" : "" }}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseUp}
    >
      <div
        id={"activity-backdrop-" + activity.id}
        className={cx("activityStyle", getActivityClass(), "expandModalBody")}
        style={{ width: currentWidth }}
        ref={containerRef}
      >
        <div className="dragImages">
          <ImageResizeIcon
            onMouseDown={(ev) => onMouseDown(ev, "L")}
            svgClass={"iconResize"}
          />
          <ImageResizeIcon
            onMouseDown={(ev) => onMouseDown(ev, "R")}
            svgClass={"iconResize"}
          />
        </div>
        {activity.type !== activityType.WITHOUT_TITLE ? (
          <div className={cx("headerNote", "headerTitle")}>
            <span className="type">{getTextFromType(activity.type)}</span>
            <span className="timestamp">
              {getTheDate(activity.creationDate, "MMMM D, YYYY h:mma")}
            </span>
            <div className="icons" style={{ marginRight: 15 }}>
              {getLockIcon()}
              <span className="scope">{getLabelScope(activity.scope)}</span>
            </div>
          </div>
        ) : (
          <div className="activity-scope-label">
            {getLabelScope(activity.scope)}
          </div>
        )}
        <div className={cx("bodyNote", "bodyNoteExpand")}>
          <div className="avatarNote">
            {activity.user &&
              getUserAvatar({
                user: users.find((user) => user.id === activity.user),
                styles: {
                  outline: "2px solid #000",
                  zIndex: 1,
                  top: activity.type !== activityType.WITHOUT_TITLE ? 0 : 20,
                },
              })}
          </div>
          <div
            id={"note-detail" + activity.id}
            className={"note-detail"}
            dangerouslySetInnerHTML={createMarkup(activity.detail)}
            onClick={(ev) => {
              onCloseModalNotes();
              onAddMentionBackdrop({
                ev,
                activity,
              });
            }}
          />
          <div className="readGrid">
            {noteIsReaded && (
              <div
                onClick={() => {
                  onMarkRead(currentUser);
                }}
                className={
                  noteIsReaded === "read"
                    ? "activity-notification-read"
                    : "activity-notification-unread"
                }
              />
            )}
          </div>
        </div>
        <div className={"footer"}>
          {activity.user && <span className="username">{username}</span>}
        </div>
      </div>
    </ExpandedActivityModalStyled>
  );
}

export default ExpandActivityModal;
