import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { now } from "moment";
import { dbTables } from "../api/types/dbTables";
import userTypes from "../api/types/userTypes";
import { firestore } from "../firebase";
import {
  activityScopes,
  updateCompanyUsersUnreadActivity,
} from "./activitiesStream";
import { GENERAL_PERMISSION_VALUE, TYPE_OF_FILE } from "./constants";
import { getRandomId, sortObjectsBy } from "./helpers";

export function createActivityCopyLink({
  companyID,
  mainTableID,
  mainTable,
  currentUser,
  currentDocument,
  scope = "",
  companyUsers,
  order,
}) {
  let activity = {
    id: getRandomId(),
  };
  activity.companyId = companyID;
  activity.creationDate = now();
  activity.type =
    currentDocument.type === TYPE_OF_FILE.FOLDER
      ? "COPIED_FOLDER_LINK"
      : "COPIED_FILE_LINK";
  activity.detail = `A link to <strong>${currentDocument.path}</strong> was copied to the clipboard`;
  activity.user = currentUser.id || "";
  activity.scope = scope;
  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyID}/${mainTable}/${mainTableID}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    companyId: companyID,
    users: companyUsers,
    order,
  });
}

export function createActivityDownload({
  companyID,
  mainTableID,
  mainTable,
  userId,
  currentDocument,
  isLandingPage = false,
  scope,
  companyUsers,
  order,
}) {
  let activity = {
    id: getRandomId(),
  };
  activity.companyId = companyID;
  activity.creationDate = now();
  activity.type = isLandingPage
    ? currentDocument.type === TYPE_OF_FILE.FOLDER
      ? "DOWNLOADED_FOLDER_FROM_LINK"
      : "DOWNLOADED_FILE_FROM_LINK"
    : currentDocument.type === TYPE_OF_FILE.FOLDER
    ? "DOWNLOADED_FOLDER"
    : "DOWNLOADED_FILE";
  activity.detail = `<strong>${currentDocument.path}</strong>`;
  activity.user = userId || "";
  if (
    scope === documentScope.SALES_ORDER ||
    scope === documentScope.PURCHASE_ORDER
  ) {
    activity.scope =
      scope === documentScope.SALES_ORDER
        ? "SALES_ORDER"
        : scope === documentScope.PURCHASE_ORDER
        ? "PURCHASE_ORDER"
        : activityScopes.SHIPMENT;
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyID}/${mainTable}/${mainTableID}/${dbTables.ACTIVITIES}/${activity.id}`
      ),
      activity
    );
  } else if (scope === documentScope.SHIPMENT) {
    activity.scope = "SHIPMENT";
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyID}/${dbTables.SHIPMENTS}/${mainTableID}/${dbTables.ACTIVITIES}/${activity.id}`
      ),
      activity
    );
  }
  updateCompanyUsersUnreadActivity({
    activity,
    companyId: companyID,
    users: companyUsers,
    order,
  });
}

function getAllDocumentPaths(childs, detail) {
  const folderChildren = childs.filter(
    (doc) => doc.type === TYPE_OF_FILE.FOLDER
  );
  const filesChildren = childs.filter(
    (doc) => doc.type !== TYPE_OF_FILE.FOLDER
  );
  filesChildren.sort(sortObjectsBy("path", false)).forEach((element) => {
    detail += `${element.path} <br/>`;
  });
  folderChildren.sort(sortObjectsBy("path", false)).forEach((folderChild) => {
    detail += `${folderChild.path} <br/>`;
    if (folderChild.child.length > 0) {
      const childDetail = getAllDocumentPaths(folderChild.child, "");
      detail += childDetail;
    }
  });
  return detail;
}

function getDetailDelete(document) {
  let detail = "";
  if (document.type !== TYPE_OF_FILE.FOLDER || document.child.length === 0) {
    detail += `<strong>${document.path}</strong>`;
  } else {
    detail += `<strong>${document.path}</strong><br/> ${getAllDocumentPaths(
      document.child,
      detail
    )}`;
  }
  return detail;
}

export function createActivityDelete({
  companyID,
  mainTableID,
  mainTable,
  currentUser,
  currentDocument,
  scope = "",
  order,
  companyUsers,
}) {
  let activity = {
    id: getRandomId(),
  };
  activity.companyId = companyID;
  activity.creationDate = now();
  activity.type =
    currentDocument.type === TYPE_OF_FILE.FOLDER
      ? "DELETED_FOLDER"
      : "DELETED_FILE";
  activity.detail = getDetailDelete(currentDocument);
  activity.user = currentUser.id || "";
  activity.scope = scope;
  setDoc(
    doc(
      firestore,
      `${dbTables.COMPANIES}/${companyID}/${mainTable}/${mainTableID}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    activity
  );
  updateCompanyUsersUnreadActivity({
    activity,
    companyId: companyID,
    users: companyUsers,
    order,
  });
}

export function moveToRootLevelWithDependencies(
  document,
  documentPath,
  documentParent,
  documentParentId,
  currentUser
) {
  updateDoc(document.ref, {
    path: documentPath,
    parent: documentParent,
    parentId: documentParentId,
    user: currentUser.id,
  });
  if (document.child.length > 0) {
    document.child.forEach((element) => {
      moveToRootLevelWithDependencies(
        element,
        documentPath + "/" + element.name,
        document.name,
        document.id,
        currentUser
      );
    });
  }
}

export function getselectedUsersObj(
  permissionUsers = {},
  permissionGroups = {},
  permissionGroupsDB = []
) {
  const selectedUsersObj = {};
  Object.keys(permissionUsers).forEach(
    (user) => (selectedUsersObj[user] = true)
  );
  Object.keys(permissionGroups).forEach((permission) => {
    const permissionDB = permissionGroupsDB.find(
      (permissionGroup) => permissionGroup.id === permission
    );
    permissionDB &&
      Object.keys(permissionDB.users).forEach(
        (user) => (selectedUsersObj[user] = true)
      );
  });
  return selectedUsersObj;
}

export function filtersDocumentsPermission({
  documents = [],
  permissionGroupsDB = [],
  user = {},
  permissionScope = "",
  allowed = () => {},
}) {
  const userDocuments = documents.filter((document) => {
    const selectedUsersObj = getselectedUsersObj(
      document.permissionUsers,
      document.permissionGroups,
      permissionGroupsDB
    );

    return (
      user.role === userTypes.SUPER_ADMIN ||
      user.role === userTypes.TRADEDASH_EMPLOYEE ||
      allowed(permissionScope) ||
      (selectedUsersObj && selectedUsersObj[user.id] === true)
    );
  });
  return userDocuments;
}

export function getPermissionUsersAndGroups(userSelected, permissionGroups) {
  const userSelectedObj = {};
  userSelected.forEach((user) => (userSelectedObj[user.id] = true));
  const permissionGroupsFound = {};
  permissionGroups.forEach((permissionGroup) => {
    let hasPermissionGroup =
      Object.keys(permissionGroup.users).length > 0 ? true : false;
    Object.keys(permissionGroup.users).forEach((key) => {
      const existUser = userSelected.find((user) => user.id === key);
      if (!existUser) {
        hasPermissionGroup = false;
      }
    });
    if (hasPermissionGroup) {
      permissionGroupsFound[permissionGroup.id] = true;
    }
  });
  const userPermissionGroups = {};
  Object.keys(permissionGroupsFound).forEach((key) => {
    Object.keys(
      permissionGroups.find((permissionGroup) => permissionGroup.id === key)
        .users
    ).map((subKey) => (userPermissionGroups[subKey] = true));
  });
  Object.keys(userPermissionGroups).map((key) => delete userSelectedObj[key]);
  return {
    permissionUsers: userSelectedObj,
    permissionGroups: permissionGroupsFound,
  };
}

export function updateParentFolderTouched(
  currentDocument,
  documentFlat,
  currentUser
) {
  if (currentDocument.parentId && currentDocument.parentId !== "") {
    const parentDocument = documentFlat.find(
      (doc) => doc.id === currentDocument.parentId
    );
    updateDoc(parentDocument.ref, {
      user: currentUser.id,
      lastModified: now(),
    });
    updateParentFolderTouched(parentDocument, documentFlat, currentUser);
  }
}

export function getPermissionGroupNames(
  permissionGroupsObj,
  permissionGroupsDB
) {
  let permissionGroupNames = [];
  if (permissionGroupsObj) {
    if (Object.keys(permissionGroupsObj).length === 0) {
      permissionGroupNames.push("None");
    } else if (
      Object.keys(permissionGroupsObj).length === permissionGroupsDB.length
    ) {
      permissionGroupNames.push("All");
    }
    Object.keys(permissionGroupsObj).forEach((key) => {
      const permissionGroup = permissionGroupsDB.find(
        (permissionGroup) => permissionGroup.id === key
      );
      permissionGroupNames.push(permissionGroup.name);
    });
  }
  return permissionGroupNames.join(", ");
}

function getUsersFromPermissionGroups(permissionGroups, permissionGroupsDB) {
  let users = {};
  Object.keys(permissionGroups).forEach((key) => {
    const permissionGroupDB = permissionGroupsDB.find(
      (group) => group.id === key
    );
    if (permissionGroupDB) {
      Object.keys(permissionGroupDB.users).forEach(
        (userId) => (users[userId] = true)
      );
    }
  });
  return users;
}

export function superAdminAssignmentPermissions({
  currentDocument,
  permissionGroupsDB,
  currentUser,
  allDocuments,
}) {
  if (!currentDocument.parentId) {
    // MAIN FILE WITHOUT PARENT DEPENDENCIES
    const allUsersWithPermissions = { ...currentDocument.permissionUsers };
    const usersFromPermissionGroups = getUsersFromPermissionGroups(
      currentDocument.permissionGroups,
      permissionGroupsDB
    );
    Object.keys(usersFromPermissionGroups).forEach(
      (userId) => (allUsersWithPermissions[userId] = true)
    );
    if (allUsersWithPermissions[currentUser.id]) {
      console.log("SUPER ADMIN IS A PART OF THE PERMISSIONS.");
      return;
    } else {
      // ADD PERMISSIONS FOR THIS ADMIN.
      console.log(
        "SUPER ADMIN WILL BE ADDED AS A PART OF THE PERMISSIONS.",
        currentDocument
      );
      let permissionUsersCpy = { ...currentDocument.permissionUsers };
      permissionUsersCpy[currentUser.id] = true;
      updateDoc(currentDocument.ref, { permissionUsers: permissionUsersCpy });
    }
  } else {
    // MAIN FILE WITHOUT PARENT DEPENDENCIES
    const allUsersWithPermissions = { ...currentDocument.permissionUsers };
    const usersFromPermissionGroups = getUsersFromPermissionGroups(
      currentDocument.permissionGroups,
      permissionGroupsDB
    );
    Object.keys(usersFromPermissionGroups).forEach(
      (userId) => (allUsersWithPermissions[userId] = true)
    );
    if (allUsersWithPermissions[currentUser.id]) {
      console.log("SUPER ADMIN IS A PART OF THE PERMISSIONS.");
      return;
    } else {
      // ADD PERMISSIONS FOR THIS ADMIN IN EVERY PARENT FOLDER TOO.
      console.log(
        "SUPER ADMIN WILL BE ADDED AS A PART OF THE PERMISSIONS AND THE CURRENT PARENTS FOLDERS."
      );
      console.log("ALL DOCUMENTS..", allDocuments);
      updateParentDocuments(currentDocument, allDocuments, currentUser);
    }
  }
}

function updateParentDocuments(currentDocument, allDocuments, currentUser) {
  console.log("CURRENT DOCUMENT TO ADD SUPER USER", currentDocument);
  const permissionUsersCpy = { ...currentDocument.permissionUsers };
  permissionUsersCpy[currentUser.id] = true;
  updateDoc(currentDocument.ref, { permissionUsers: permissionUsersCpy });
  const parentDocument = allDocuments.find(
    (doc) => doc.id === currentDocument.parentId
  );
  if (parentDocument) {
    updateParentDocuments(parentDocument, allDocuments, currentUser);
  }
}

const documentTypesArr = [
  "odt",
  "doc",
  "docx",
  "csv",
  "xls",
  "xlsx",
  "xlsm",
  "ods",
  "pdf",
  "pptx",
  "png",
  "jpeg",
  "psd",
  "ai",
  "cdr",
  "svg",
  "eps",
  "file",
  TYPE_OF_FILE.FOLDER,
];

export function addNewDocumentType({ company, document }) {
  const documentTypesCpy = [...(company.document_types || [])];
  if (
    !documentTypesArr.includes(document.type) &&
    !documentTypesCpy.includes(document.type)
  ) {
    documentTypesCpy.push(document.type);
    updateDoc(company.ref, { document_types: documentTypesCpy });
  }
}

const shipmentPlaceholderFile = "Shipment #### Documents";

export function isShipmentRootFile({ scope, folderName }) {
  if (scope === dbTables.SHIPMENT_DOCUMENTS_TEMPLATE) {
    if (folderName === shipmentPlaceholderFile) {
      return true;
    }
    return false;
  } else {
    return false;
  }
}

const documentScope = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
  CUSTOMER: "CUSTOMER",
  FACTORY: "FACTORY",
  QUOTE: "QUOTE",
  PURCHASE_QUOTE: "PURCHASE_QUOTE",
};

export function getDocumentPath({
  companyId,
  mainTable,
  mainTableId,
  shipmentId,
  dbTable,
  documentId,
  documentVersionId,
  scope,
}) {
  switch (scope) {
    case documentScope.SALES_ORDER:
      return `companies/${companyId}/${mainTable}/${mainTableId}/${dbTable}/${documentId}/version/${documentVersionId}`;
    case documentScope.PURCHASE_ORDER:
      return `companies/${companyId}/${mainTable}/${mainTableId}/${dbTable}/${documentId}/version/${documentVersionId}`;
    case documentScope.FACTORY:
      return `companies/${companyId}/${mainTable}/${mainTableId}/${dbTable}/${documentId}/version/${documentVersionId}`;
    case documentScope.CUSTOMER:
      return `companies/${companyId}/${mainTable}/${mainTableId}/${dbTable}/${documentId}/version/${documentVersionId}`;
    case documentScope.SHIPMENT:
      return `companies/${companyId}/${dbTables.SHIPMENTS}/${shipmentId}/${dbTables.SHIPMENT_DOCUMENTS}/${documentId}/version/${documentVersionId}`;
    default:
      return `companies/${companyId}/${mainTable}/${mainTableId}/${dbTable}/${documentVersionId}/version/${documentId}`;
  }
}

export { shipmentPlaceholderFile, documentScope };

export const getDocumentDB = async ({
  scope,
  salesOrder,
  poId,
  shipmentId,
  documentId,
  companyId,
}) => {
  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  switch (scope) {
    case documentScope.PURCHASE_ORDER:
    case documentScope.PURCHASE_QUOTE:
      return await getDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.PURCHASE_ORDERS}/${poId}/${dbTables.PURCHASE_ORDER_DOCUMENTS}/${documentId}`
        )
      );

    case documentScope.SALES_ORDER:
    case documentScope.QUOTE:
      return await getDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.SALES_ORDER_DOCUMENTS}/${documentId}`
        )
      );

    case documentScope.SHIPMENT:
      return await getDoc(
        doc(
          firestore,
          `${companyRef}/${dbTables.SHIPMENTS}/${shipmentId}/${dbTables.SHIPMENT_DOCUMENTS}/${documentId}`
        )
      );

    default:
      return {};
  }
};

export const hasDocumentPermission = ({
  document,
  currentUser,
  permissionGroupDB,
  canSeeAllDocuments,
}) => {
  if (!document || !currentUser || !permissionGroupDB) {
    return false;
  }
  if (
    currentUser.role === userTypes.SUPER_ADMIN ||
    currentUser.role === userTypes.TRADEDASH_EMPLOYEE ||
    canSeeAllDocuments
  ) {
    return true;
  }
  const documentPermissionGroup = document.permissionGroups || {};
  const documentUserPermission = document.permissionUsers || {};
  const userPermissionList = getselectedUsersObj(
    documentUserPermission,
    documentPermissionGroup,
    permissionGroupDB
  );
  return !!userPermissionList[currentUser.id];
};

export function hasPermissionToDelete({ scope, user, isAllowed, document }) {
  const ORDER_DASHBOARD_SCOPES = [
    dbTables.SALES_ORDERS,
    dbTables.PURCHASE_ORDERS,
    dbTables.SHIPMENTS,
  ];
  if (user.role === userTypes.SUPER_ADMIN) {
    return true;
  } else if (ORDER_DASHBOARD_SCOPES.includes(scope)) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_ORDER_DASHBOARD_FILES_AND_FOLDERS
    );
  } else if (scope === dbTables.CUSTOMERS) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_CUSTOMER_FILES_AND_FOLDERS
    );
  } else if (scope === dbTables.FACTORIES) {
    return isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_DELETE_VENDOR_FILES_AND_FOLDERS
    );
  }
  return false;
}

export function canDeleteFileFolder({
  document = {},
  disabled = false,
  user = {},
  mainTable = "",
  isAllowed = () => {},
}) {
  if (document.mainFileId || document.placeholder || disabled) {
    return false;
  }
  if (
    document.scope === documentScope.SHIPMENT &&
    document.isShipmentRootTask
  ) {
    return false;
  }
  if (
    !hasPermissionToDelete({
      scope: mainTable,
      user,
      isAllowed,
      document,
    })
  ) {
    return false;
  }
  return true;
}
