import { useCallback } from "react";
import { useCompanyId } from ".";
import { dbTables } from "../api/types/dbTables";
import { useSelector } from "react-redux";
import { useUser } from "./user";
import userTypes from "../api/types/userTypes";

export function usePermissions() {
  const user = useUser() || {};
  const companyId = useCompanyId();
  const userPermissions = useSelector(
    (state) => state.data[dbTables.PERMISSIONS][user.id] || []
  );
  const roles = useSelector(
    (state) => state.data[dbTables.ROLES][companyId] || []
  );
  const userRole = roles.find((role) => role.name === user.role);
  const rolePermissions = useRolesPermisions({ userRole });
  return [...userPermissions, ...rolePermissions];
}

export function useRolesPermisions({ userRole = {} }) {
  let rolePermissions = useSelector(
    (state) => state.data[dbTables.PERMISSIONS][userRole.id]
  );

  if (rolePermissions) {
    let permissionsList = [];
    let permissionsData = {};
    rolePermissions.forEach(
      (currentPermission) =>
        (permissionsData = {
          ...permissionsData,
          ...currentPermission.permissions,
        })
    );
    Object.keys(permissionsData).forEach((key) => {
      permissionsList.push({ id: key, enabled: permissionsData[key] });
    });
    return permissionsList;
  } else {
    return [];
  }
}

export function useSummaryPermisions({ userRole = {} }) {
  return useSelector(
    (state) => state.data[dbTables.PERMISSIONS][userRole.id] || []
  );
}

export function checkSpecificPermissions({
  userPermissions = {},
  permissionToCheck = [],
}) {
  let hasPermission = false;
  permissionToCheck.forEach((permissionValue) => {
    hasPermission = hasPermission || !!userPermissions[permissionValue];
  });
  return hasPermission;
}

export function verifyPermission({ user = {}, permissionToCheck = [] }) {
  if (
    user.role === userTypes.SUPER_ADMIN ||
    user.role === userTypes.TRADEDASH_EMPLOYEE
  ) {
    return true;
  }
  let hasPermission = false;
  permissionToCheck.forEach((permissionValue) => {
    hasPermission = hasPermission || !!user.permissions[permissionValue];
  });
  return hasPermission;
}

export function useRolesPermissionList() {
  const user = useUser();
  const companyId = useCompanyId();
  const roles = useSelector(
    (state) => state.data[dbTables.ROLES][companyId] || []
  );
  const rolePermission = useRolesPermisions({
    userRole: roles.find((role) => role.name === user.role),
  });
  const documentMap = new Map();
  rolePermission.forEach((permission) => {
    const { id } = permission;
    documentMap.set(id, permission);
  });
  return documentMap;
}

export function useIsAllowedFunction() {
  const user = useUser();
  const companyId = useCompanyId();
  const userPermissions = user.permissions;
  const roles = useSelector(
    (state) => state.data[dbTables.ROLES][companyId] || []
  );
  const rolePermissions = useRolesPermisions({
    userRole: roles.find((role) => role.name === user.role),
  });
  const isAllowed = useCallback(
    (permission) => {
      if (
        user.role === userTypes.SUPER_ADMIN ||
        user.role === userTypes.TRADEDASH_EMPLOYEE
      ) {
        return true;
      } else if (userPermissions && userPermissions[permission]) {
        return true;
      }
      const rolePermission = [...rolePermissions].find((item) => {
        return item.id === permission;
      });
      return rolePermission && rolePermission.enabled;
    },
    [userPermissions, rolePermissions]
  );

  return isAllowed;
}
//

export function useIsAllowedCompany() {
  const companyId = useCompanyId();
  const companyPermissions = useSelector(
    (state) => state.data[dbTables.COMPANY_PERMISSION][companyId] || []
  );

  const isAllowed = useCallback(
    (permission) => {
      let permissionData =
        companyPermissions.find(
          (companyPermission) => companyPermission.id === permission
        ) || {};
      return permissionData.enabled;
    },
    [companyPermissions]
  );

  return isAllowed;
}

export function useCompanyIsAllowed(id, companyId) {
  const permissions = useSelector(
    (state) => state.data[dbTables.COMPANY_PERMISSION][companyId] || []
  );
  if (!companyId || !id) {
    return false;
  }
  const currentPermission = permissions.find(
    (permission) => permission.id === id
  );
  if (!currentPermission) {
    return false;
  }

  return !!currentPermission.enabled;
}
