import moment, { now } from "moment";
import React, { useState, useEffect } from "react";
import useUndo from "use-undo";
import Milestone from "../../api/model/milestone";
import { typeOfTask } from "../../api/types/dbTables";
import taskStages from "../../api/types/taskStages";
import AppConfig from "../../constants/AppConfig";
import {
  direction as globalDirection,
  expandedTaskTableColumns,
  simpleTaskTableColumns,
} from "../../helpers/constants";
import { getCorrectTimezone } from "../../helpers/ganttChart";
import { sortObjectsBy } from "../../helpers/helpers";
import { getStatusTask } from "../../helpers/tasks";
import {
  getFlagViewOptions,
  viewOptionLabels,
  viewOptionsArray,
} from "../../helpers/timelineCalendar";
import {
  actionType,
  dependencyTypesOnMove,
  getAvailableTasksToMove,
  getDependencyTasks,
  getTasksAjustedByPhase,
  labelTaskPhases,
} from "../../helpers/timelineModal";
import AttentionModal from "../Modal/AttentionModal";
import AttentionModalDescription from "./AttentionModalDescription";
import PurchaseOrderBadges from "./PurchaseOrderBadges";
import SalesOrderBadged from "./SalesOrderBadged";
import TimeLineCalendar from "./TimeLineCalendar";

function TimelineModal({
  milestonesArray,
  allTasks,
  companyUsers = [],
  salesOrder,
  purchaseOrders,
  factories,
  customers,
  onCancelTimeLineModal,
  onSubmitTimeLineModal,
  shipments,
}) {
  const ghostTasks = [...allTasks];
  const [flagDates, setFlagDates] = useState([]);
  const [tableSize, setTableSize] = useState(simpleTaskTableColumns);
  const [rangeDateCalendar, setRangeDateCalendar] = useState(null);
  const [viewOptions, setViewOptions] = useState(viewOptionsArray);
  const [milestones, setMilestones] = useState([]);
  const [taskPhases, setTaskPhases] = useState({
    [labelTaskPhases.PROPOSAL_QUOTE]: true,
    [labelTaskPhases.PRE_PRODUCTION]: true,
    [labelTaskPhases.PRODUCTION]: true,
    [labelTaskPhases.BOOKING_TRANSIT]: true,
    [labelTaskPhases.PAYMENT_BILLING]: true,
  });

  const [currentPOs, setCurrentPOs] = useState([]);
  const [currentShipments, setCurrentShipments] = useState([]);
  const [currentSO, setCurrentSO] = useState({});
  const [onHoverTaskIds, setOnHoverTaskIds] = useState(null);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    null
  );
  const [modifiedTask, setModifiedTask] = useState(null);
  const [pressedOneTime, setPressedOneTime] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [isMovingDependantTasks, setIsMovingDependantTasks] = useState(false);
  const [POAlreadySetup, setPOAlreadySetup] = useState({});
  const [shipmentOnHover, setShipmentOnHover] = useState(null);

  const [
    undoRedoState,
    {
      set: setTasksState,
      // reset: resetTasksState,
      undo: undoTasksState,
      redo: redoTasksState,
      canUndo,
      canRedo,
    },
  ] = useUndo({ tasks: allTasks, confirmedActions: [] });
  const { present: presentUndoRedoState } = undoRedoState;
  const { tasks, confirmedActions } = presentUndoRedoState;

  useEffect(() => {
    if (
      Object.keys(salesOrder).length > 0 &&
      JSON.stringify({ ...salesOrder, ref: "" }) !==
        JSON.stringify({ ...currentSO, ref: "" })
    ) {
      setCurrentSO(salesOrder);
    }
    return;
  }, [salesOrder]);

  useEffect(() => {
    setCurrentPOs(purchaseOrders);
    return;
  }, [purchaseOrders]);

  useEffect(() => {
    setMilestones(milestonesArray);
    return;
  }, [milestonesArray]);

  useEffect(() => {
    const firstTask = allTasks
      .filter((task) => !task.isPhase)
      .sort(sortObjectsBy("startDate", false))[0];
    const lastTask = allTasks
      .filter((task) => !task.isPhase)
      .sort(sortObjectsBy("finishDate", true))[0];
    const startDate = moment(firstTask.startDate).subtract(
      tableSize + 12,
      "days"
    );
    const endDate = moment(lastTask.finishDate).add(12, "days");
    setRangeDateCalendar({
      start: startDate,
      end: endDate,
    });
    return;
  }, []);

  useEffect(() => {
    if (!rangeDateCalendar) return;
    const flagDates = [];
    let { start, end } = rangeDateCalendar;
    while (start < end) {
      flagDates.push(start);
      start = moment(start).add(1, "days");
    }
    setFlagDates(flagDates);
    return;
  }, [rangeDateCalendar]);

  useEffect(() => {
    const firstTask = allTasks
      .filter((task) => !task.isPhase)
      .sort(sortObjectsBy("startDate", false))[0];
    const lastTask = allTasks
      .filter((task) => !task.isPhase)
      .sort(sortObjectsBy("finishDate", true))[0];
    const startDate = moment(firstTask.startDate).subtract(
      tableSize + 12,
      "days"
    );
    const endDate = moment(lastTask.finishDate).add(12, "days");
    setRangeDateCalendar({
      start: startDate,
      end: endDate,
    });
    return;
  }, [tableSize]);

  function handleDropTask(taskId, offset) {
    let tasksCpy = [...tasks];
    const movedTask = tasksCpy.find((task) => task.id === taskId);
    setModifiedTask({
      ...movedTask,
      offset,
      typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
    });
    if (movedTask) {
      tasksCpy = tasksCpy.map((task) => {
        if (task.isPhase) {
          return task;
        }
        //TO DO TIMEZONE
        if (taskId === task.id) {
          if (offset > 0) {
            const taskToUpdate = {
              ...task,
              startDate: moment(task.startDate)
                .add(Math.abs(offset), "days")
                .valueOf(),
              finishDate: moment(task.finishDate)
                .add(Math.abs(offset), "days")
                .valueOf(),
              hasBeenUpdated: true,
            };
            return {
              ...taskToUpdate,
              status: getStatusTask(taskToUpdate),
            };
          } else {
            const taskToUpdate = {
              ...task,
              startDate: moment(task.startDate)
                .subtract(Math.abs(offset), "days")
                .valueOf(),
              finishDate: moment(task.finishDate)
                .subtract(Math.abs(offset), "days")
                .valueOf(),
              hasBeenUpdated: true,
            };
            return {
              ...taskToUpdate,
              status: getStatusTask(taskToUpdate),
            };
          }
        }
        return task;
      });
      tasksCpy = getTasksAjustedByPhase({
        tasks: tasksCpy,
      });
      const updatedTask = tasksCpy.find((task) => task.id === taskId);
      const confirmedActionsCpy = [...confirmedActions];
      confirmedActionsCpy.push({
        ...updatedTask,
        [actionType.MOVED_TASK]: true,
        offsetDaysMoved: offset,
        typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
        creationDate: now(),
      });
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
      setPressedOneTime(false);
      const dependencyTasks = getDependencyTasks({
        task: movedTask,
        tasks: tasksCpy,
        dependencyTasks: [],
        remainingDayOffset: 0,
      });
      if (dependencyTasks.length === 0) {
        return;
      }
      setOpenModalAttention(true);
      const descriptionModalAttention = getDescription({ offset });
      setDescriptionModalAttention(descriptionModalAttention);
    }
  }

  function handleChangeTaskDuration(taskId, offset, direction) {
    let tasksCpy = [...tasks];
    const movedTask = tasksCpy.find((task) => task.id === taskId);
    setModifiedTask({
      ...movedTask,
      direction,
      offset,
      typeChange:
        direction === globalDirection.LEFT
          ? dependencyTypesOnMove.START_DATE_CHANGED
          : dependencyTypesOnMove.FINISH_DATE_CHANGED,
    });
    const newDuration = parseInt(movedTask.duration) + parseInt(offset);
    if (movedTask) {
      tasksCpy = tasksCpy.map((task) => {
        if (task.id === taskId) {
          if (direction === globalDirection.LEFT) {
            if (offset < 0) {
              const taskToUpdate = {
                ...task,
                startDate: moment(task.startDate)
                  .add(Math.abs(offset), "days")
                  .valueOf(),
                duration: newDuration,
                hasBeenUpdated: true,
              };
              return { ...taskToUpdate, status: getStatusTask(taskToUpdate) };
            } else {
              const taskToUpdate = {
                ...task,
                startDate: moment(task.startDate)
                  .subtract(Math.abs(offset), "days")
                  .valueOf(),
                duration: newDuration,
                hasBeenUpdated: true,
              };
              return {
                ...taskToUpdate,
                status: getStatusTask(taskToUpdate),
              };
            }
          } else {
            if (offset < 0) {
              const taskToUpdate = {
                ...task,
                finishDate: moment(task.finishDate)
                  .subtract(Math.abs(offset), "days")
                  .valueOf(),
                duration: newDuration,
                hasBeenUpdated: true,
              };
              return {
                ...taskToUpdate,
                status: getStatusTask(taskToUpdate),
              };
            } else {
              const taskToUpdate = {
                ...task,
                finishDate: moment(task.finishDate)
                  .add(Math.abs(offset), "days")
                  .valueOf(),
                duration: newDuration,
                hasBeenUpdated: true,
              };
              return {
                ...taskToUpdate,
                status: getStatusTask(taskToUpdate),
              };
            }
          }
        }
        return task;
      });
      tasksCpy = getTasksAjustedByPhase({
        tasks: tasksCpy,
      });
      const updatedTask = tasksCpy.find((task) => task.id === taskId);
      const confirmedActionsCpy = [...confirmedActions];
      confirmedActionsCpy.push({
        ...updatedTask,
        [actionType.CHANGED_DURATION_TASK]: true,
        newDuration,
        offsetDaysChangedDuration: offset,
        typeChange:
          direction === globalDirection.LEFT
            ? dependencyTypesOnMove.START_DATE_CHANGED
            : dependencyTypesOnMove.FINISH_DATE_CHANGED,
        creationDate: now(),
      });
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
      const dependencyTasks = getDependencyTasks({
        task: movedTask,
        tasks: tasksCpy,
        dependencyTasks: [],
        remainingDayOffset: 0,
        type:
          direction === globalDirection.LEFT
            ? dependencyTypesOnMove.START_DATE_CHANGED
            : dependencyTypesOnMove.FINISH_DATE_CHANGED,
      });
      if (dependencyTasks.length === 0) {
        return;
      }
      setOpenModalAttention(true);
      setPressedOneTime(false);
      const descriptionModalAttention = getDescription({
        offset:
          direction === globalDirection.LEFT
            ? -parseInt(offset)
            : parseInt(offset),
      });
      setDescriptionModalAttention(descriptionModalAttention);
    }
  }

  function handleChangeViewOptions(option) {
    let viewOptionsArrayCpy = [...viewOptions];
    viewOptionsArrayCpy = viewOptionsArrayCpy.map((el) => {
      if (option.label === viewOptionLabels.FULL_TABLE_DETAILS) {
        if (option.enabled) {
          setTableSize(expandedTaskTableColumns);
        } else {
          setTableSize(simpleTaskTableColumns);
        }
      }
      if (el.id === option.id) {
        return option;
      }
      return el;
    });
    setViewOptions(viewOptionsArrayCpy);
  }

  function getTasksFiltered({
    tasks,
    currentSO,
    currentPOs,
    currentShipments,
  }) {
    let tasksCpy = [
      ...tasks.filter(
        (task) => task.type === typeOfTask.SALES_ORDER || task.isPhase
      ),
    ];
    const flagViewOptions = getFlagViewOptions(viewOptions);
    let POTasksCpy = [];
    currentPOs.forEach((po) => {
      if (po.isShownInTimeline) {
        let POTasks = tasks.filter((task) => {
          if (
            task.purchaseOrderId === po.id &&
            task.type === typeOfTask.PURCHASE_ORDER
          ) {
            return true;
          }
          return false;
        });
        POTasksCpy = [...POTasksCpy, ...POTasks];
      }
    });
    tasksCpy = [...tasksCpy, ...POTasksCpy];
    let shipmentTasksCpy = [];
    currentShipments.forEach((shipment) => {
      if (shipment.checked) {
        let shipmentTasks = tasks.filter((task) => {
          if (task.shipmentId === shipment.id) {
            return true;
          }
          return false;
        });
        shipmentTasksCpy = [...shipmentTasksCpy, ...shipmentTasks];
      }
    });
    tasksCpy = [...tasksCpy, ...shipmentTasksCpy];
    if (!flagViewOptions[viewOptionLabels.AD_HOC_TASKS]) {
      tasksCpy = tasksCpy.filter((task) => !task.isAdHocTask);
    }
    if (!taskPhases[labelTaskPhases.PROPOSAL_QUOTE]) {
      tasksCpy = tasksCpy.filter((task) => task.stage !== taskStages.PROPOSAL);
    }
    if (!taskPhases[labelTaskPhases.PRE_PRODUCTION]) {
      tasksCpy = tasksCpy.filter(
        (task) => task.stage !== taskStages.PRE_PRODUCTION
      );
    }
    if (!taskPhases[labelTaskPhases.PRODUCTION]) {
      tasksCpy = tasksCpy.filter(
        (task) => task.stage !== taskStages.PRODUCTION
      );
    }
    if (!taskPhases[labelTaskPhases.BOOKING_TRANSIT]) {
      tasksCpy = tasksCpy.filter(
        (task) => task.stage !== taskStages.BOOKING_TRANSIT
      );
    }
    if (!taskPhases[labelTaskPhases.PAYMENT_BILLING]) {
      tasksCpy = tasksCpy.filter(
        (task) => task.stage !== taskStages.PAYMENT_BILLING
      );
    }
    const POsObj = {};
    currentPOs.forEach((po) => (POsObj[po.id] = po.color));
    tasksCpy = tasksCpy.map((task) => {
      if (
        task.salesOrderId === currentSO.id &&
        task.type === typeOfTask.SALES_ORDER
      ) {
        return {
          ...task,
          color: AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        };
      }
      if (task.type === typeOfTask.SHIPMENT) {
        return {
          ...task,
          color: AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        };
      }
      if (
        task.factoryId &&
        POsObj[task.purchaseOrderId] &&
        task.type === typeOfTask.PURCHASE_ORDER
      ) {
        return { ...task, color: POsObj[task.purchaseOrderId] };
      }
      return task;
    });
    return tasksCpy;
  }

  function getTasksFilteredWithoutPhases({
    tasks,
    currentSO,
    currentPOs,
    currentShipments,
  }) {
    let tasksCpy = [
      ...tasks.filter(
        (task) => task.type === typeOfTask.SALES_ORDER || task.isPhase
      ),
    ];
    const flagViewOptions = getFlagViewOptions(viewOptions);
    let POTasksCpy = [];
    currentPOs.forEach((po) => {
      if (po.isShownInTimeline) {
        let POTasks = tasks.filter((task) => {
          if (
            task.purchaseOrderId === po.id &&
            task.type === typeOfTask.PURCHASE_ORDER
          ) {
            return true;
          }
          return false;
        });
        POTasksCpy = [...POTasksCpy, ...POTasks];
      }
    });
    tasksCpy = [...tasksCpy, ...POTasksCpy];
    //
    let shipmentTasksCpy = [];
    currentShipments.forEach((shipment) => {
      if (shipment.checked) {
        let shipmentTasks = tasks.filter((task) => {
          if (task.shipmentId === shipment.id) {
            return true;
          }
          return false;
        });
        shipmentTasksCpy = [...shipmentTasksCpy, ...shipmentTasks];
      }
    });
    tasksCpy = [...tasksCpy, ...shipmentTasksCpy];
    //
    if (!flagViewOptions[viewOptionLabels.AD_HOC_TASKS]) {
      tasksCpy = tasksCpy.filter((task) => !task.isAdHocTask);
    }
    const POsObj = {};
    currentPOs.forEach((po) => (POsObj[po.id] = po.color));
    tasksCpy = tasksCpy.map((task) => {
      if (
        task.salesOrderId === currentSO.id &&
        task.type === typeOfTask.SALES_ORDER
      ) {
        return {
          ...task,
          color: AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        };
      }
      if (task.type === typeOfTask.SHIPMENT) {
        return {
          ...task,
          color: AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        };
      }
      if (
        task.factoryId &&
        POsObj[task.purchaseOrderId] &&
        task.type === typeOfTask.PURCHASE_ORDER
      ) {
        return { ...task, color: POsObj[task.purchaseOrderId] };
      }
      return task;
    });
    return tasksCpy;
  }

  function handleChangeMilestone(milestone) {
    let milestonesCpy = [...milestones];
    milestonesCpy = milestonesCpy.map((milestoneCpy) => {
      if (milestoneCpy.id === milestone.id) {
        return milestone;
      }
      return milestoneCpy;
    });
    setMilestones(milestonesCpy);
  }

  function handleDeleteMilestone(milestoneId) {
    let milestonesCpy = [...milestones];
    milestonesCpy = milestonesCpy.filter(
      (milestoneCpy) => milestoneCpy.id !== milestoneId
    );
    setMilestones(milestonesCpy);
  }

  function handleAddNewMilestone() {
    const newMilestone = new Milestone({});
    const milestonesCpy = [...milestones];
    milestonesCpy.push({ ...newMilestone });
    setMilestones(milestonesCpy);
  }

  function handleChangeTaskPhase(taskPhases) {
    setTaskPhases(taskPhases);
  }

  function isOpenAllSection1(taskPhases) {
    let open = true;
    Object.keys(taskPhases).forEach((key) => {
      if (!taskPhases[key]) {
        open = false;
      }
    });
    return open;
  }

  function handleChangeTask(task, offset, actionTypeTask) {
    let confirmedActionsCpy = [...confirmedActions];
    let tasksCpy = [...tasks];
    if (actionTypeTask === actionType.CHANGED_ASSIGNED_TO) {
      tasksCpy = tasksCpy.map((item) => {
        if (item.id === task.id) {
          return {
            ...task,
            hasBeenUpdated: true,
          };
        }
        return item;
      });
      confirmedActionsCpy.push({
        ...task,
        [actionTypeTask]: true,
        creationDate: now(),
      });
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
      return;
    } else {
      const typeChange =
        actionTypeTask === actionType.CHANGED_DURATION_TASK
          ? dependencyTypesOnMove.FINISH_DATE_CHANGED
          : dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED;
      if (offset) {
        const movedTask = tasksCpy.find((el) => el.id === task.id);
        const dependencyTasks = getDependencyTasks({
          task: movedTask,
          tasks: tasksCpy,
          dependencyTasks: [],
          remainingDayOffset: 0,
          type: typeChange,
        });
        if (dependencyTasks.length > 0) {
          setModifiedTask({ ...movedTask, offset });
          setOpenModalAttention(true);
          setPressedOneTime(false);
          const descriptionModalAttention = getDescription({
            offset,
          });
          setDescriptionModalAttention(descriptionModalAttention);
        }
      }
      tasksCpy = tasksCpy.map((item) => {
        if (item.id === task.id) {
          const taskCpy = {
            ...task,
            startDate: getCorrectTimezone({
              date: task.startDate,
              isServerTime: true,
            }).valueOf(),
            finishDate: getCorrectTimezone({
              date: task.finishDate,
              isServerTime: true,
            }).valueOf(),
          };
          return {
            ...taskCpy,
            hasBeenUpdated: true,
            status: getStatusTask(task),
          };
        }
        return item;
      });

      tasksCpy = getTasksAjustedByPhase({
        tasks: tasksCpy,
      });

      if (actionTypeTask === actionType.CHANGED_DURATION_TASK) {
        confirmedActionsCpy.push({
          ...task,
          [actionTypeTask]: true,
          newDuration: parseInt(task.duration),
          offsetDaysChangedDuration: parseInt(offset),
          typeChange: dependencyTypesOnMove.FINISH_DATE_CHANGED,
          creationDate: now(),
        });
      }
      if (actionTypeTask === actionType.MOVED_TASK) {
        confirmedActionsCpy.push({
          ...task,
          [actionTypeTask]: true,
          offsetDaysMoved: parseInt(offset),
          typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
          creationDate: now(),
        });
      }
      if (actionTypeTask === actionType.COMPLETED_TASK) {
        confirmedActionsCpy.push({
          ...task,
          [actionTypeTask]: true,
          creationDate: now(),
        });
      }
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
    }
  }

  function handleChangeOpenAllSections(value) {
    if (value) {
      return setTaskPhases({
        [labelTaskPhases.PROPOSAL_QUOTE]: true,
        [labelTaskPhases.PRE_PRODUCTION]: true,
        [labelTaskPhases.PRODUCTION]: true,
        [labelTaskPhases.BOOKING_TRANSIT]: true,
        [labelTaskPhases.PAYMENT_BILLING]: true,
      });
    }
    return setTaskPhases({
      [labelTaskPhases.PROPOSAL_QUOTE]: false,
      [labelTaskPhases.PRE_PRODUCTION]: false,
      [labelTaskPhases.PRODUCTION]: false,
      [labelTaskPhases.BOOKING_TRANSIT]: false,
      [labelTaskPhases.PAYMENT_BILLING]: false,
    });
  }

  function handleChangePOBadge({ purchaseOrder, purchaseOrders }) {
    let currentPOsCpy = [...purchaseOrders];
    currentPOsCpy = currentPOsCpy.map((po) => {
      if (po.id === purchaseOrder.id) {
        return purchaseOrder;
      }
      return po;
    });
    setCurrentPOs(currentPOsCpy);
    if (purchaseOrder.isShownInTimeline && !POAlreadySetup[purchaseOrder.id]) {
      let shipmentByPO = [
        ...shipments.filter((shipment) =>
          purchaseOrder.shipmentIds.includes(shipment.id)
        ),
      ];
      let currentShipmentsCpy = [...currentShipments];
      shipmentByPO.forEach((shipmentPO) => {
        const shipmentFound = currentShipmentsCpy.find(
          (cShipment) => cShipment.id === shipmentPO.id
        );
        if (!shipmentFound) {
          currentShipmentsCpy.push({ ...shipmentPO, checked: true });
        } else {
          currentShipmentsCpy = currentShipmentsCpy.map((cShipment) => {
            if (cShipment.id === shipmentPO.id) {
              return { ...cShipment, checked: true };
            }
            return cShipment;
          });
        }
      });
      setCurrentShipments(currentShipmentsCpy);
      const POAlreadySetupCpy = { ...POAlreadySetup };
      POAlreadySetupCpy[purchaseOrder.id] = true;
      setPOAlreadySetup(POAlreadySetupCpy);
    }
    setOnHoverTaskIds(null);
    return;
  }

  function handleSelectAllPOs(value) {
    let currentPOsCpy = [...currentPOs];
    currentPOsCpy = currentPOsCpy.map((po) => ({
      ...po,
      isShownInTimeline: value,
    }));
    setCurrentPOs(currentPOsCpy);
  }

  function handleHoverTasksIds(purchaseOrder) {
    const POTaskIds = [];
    tasks.forEach((task) => {
      if (task.purchaseOrderId === purchaseOrder.id && task.factoryId) {
        POTaskIds.push(task.id);
      }
    });
    setOnHoverTaskIds(POTaskIds);
  }

  function handleCloseMilestone(milestones) {
    let milestonesToUpdate = milestones.filter(
      (milestone) => !milestone.isToday
    );
    milestonesToUpdate = milestonesToUpdate.map((milestone) => ({
      ...milestone,
      date: moment(milestone.date).valueOf(),
    }));
    setCurrentSO({ ...currentSO, milestones: milestonesToUpdate });
  }

  function getDescription({ offset }) {
    return (
      <AttentionModalDescription
        isMovingForward={offset > 0 ? true : false}
        offset={offset}
      />
    );
  }

  function handleMovingRemainingTasks({ modifiedTask, tasks, offsetDays }) {
    let tasksCpy = [...tasks];
    const availableTasksToMove = getAvailableTasksToMove({ tasks: tasksCpy });
    const dependencyTasks =
      getDependencyTasks({
        task: modifiedTask,
        tasks: availableTasksToMove,
        dependencyTasks: [],
        remainingDayOffset: modifiedTask.offset > 0 ? offsetDays : -offsetDays,
      }) || [];
    tasksCpy = tasksCpy.map((task) => {
      if (modifiedTask.isCompletedTaskBadge && task.id === modifiedTask.id) {
        return { ...task, moved: true };
      }
      if (task.isPhase) {
        return task;
      }
      const taskToMove = dependencyTasks.find(
        (dependencyTask) => dependencyTask.id === task.id
      );
      if (taskToMove) {
        return {
          ...taskToMove,
          hasBeenUpdated: true,
          status: getStatusTask(taskToMove),
        };
      }
      return task;
    });
    return tasksCpy;
  }

  function handleMoveRemainingTasks(task) {
    let tasksCpy = [...tasks];
    const dependencyTasks = getDependencyTasks({
      task,
      tasks,
      dependencyTasks: [],
      remainingDayOffset: 0,
    });
    if (dependencyTasks.length === 0) {
      let confirmedActionsCpy = [...confirmedActions];
      confirmedActionsCpy.push({
        ...task,
        [actionType.DENIED_REMAINING_DAY_OFFSET_MOVED]: true,
        // typeChange: dependencyTypesOnMove.FINISH_DATE_CHANGED,
        creationDate: now(),
      });
      tasksCpy = tasksCpy.map((taskCpy) => {
        if (taskCpy.id === task.id) {
          return { ...taskCpy, moved: true, hasBeenUpdated: true };
        }
        return taskCpy;
      });
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
      return;
    }
    setIsMovingDependantTasks(true);
    setModifiedTask({
      ...task,
      offset: task.dayOffset,
      isCompletedTaskBadge: true,
    });
    setOpenModalAttention(true);
    setPressedOneTime(false);
    const descriptionModalAttention = getDescription({
      offset: task.dayOffset,
    });
    setDescriptionModalAttention(descriptionModalAttention);
  }

  function handleChangeSelectedShipments({ shipment }) {
    let currentShipmentsCpy = [...currentShipments];
    const shipmentFound = currentShipmentsCpy.find(
      (cShipment) => cShipment.id === shipment.id
    );
    if (!shipmentFound) {
      currentShipmentsCpy.push(shipment);
    } else {
      currentShipmentsCpy = currentShipmentsCpy.map((cShipment) => {
        if (cShipment.id === shipment.id) {
          return shipment;
        }
        return cShipment;
      });
    }
    setCurrentShipments(currentShipmentsCpy);
  }

  return (
    rangeDateCalendar && (
      <div>
        {openModalAttention && (
          <AttentionModal
            title="Attention"
            isOpen={openModalAttention}
            description={descriptionModalAttention}
            cancellable
            onClick={() => {
              if (isConfirmationModal) {
                onCancelTimeLineModal();
                setDescriptionModalAttention(null);
                setIsConfirmationModal(false);
                setOpenModalAttention(false);
                return;
              } else {
                setPressedOneTime(true);
                if (!pressedOneTime) {
                  const offsetDays = document.getElementById(
                    "remaining-offset-days"
                  ).value;
                  let tasksCpy = handleMovingRemainingTasks({
                    modifiedTask,
                    tasks,
                    offsetDays:
                      modifiedTask.direction === globalDirection.LEFT
                        ? -parseInt(offsetDays)
                        : parseInt(offsetDays),
                  });
                  tasksCpy = getTasksAjustedByPhase({
                    tasks: tasksCpy,
                  });
                  let confirmedActionsCpy = [...confirmedActions];
                  if (isMovingDependantTasks) {
                    tasksCpy = tasksCpy.map((task) => {
                      if (task.id === modifiedTask.id) {
                        return { ...task, moved: true, hasBeenUpdated: true };
                      }
                      return task;
                    });
                    confirmedActionsCpy.push({
                      ...modifiedTask,
                      [actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED]: true,
                      creationDate: now(),
                      offsetRemainingTask: parseInt(offsetDays),
                      moved: true,
                    });
                  } else {
                    confirmedActionsCpy = confirmedActionsCpy.map(
                      (confirmedAction, index) => {
                        if (index === confirmedActionsCpy.length - 1) {
                          return {
                            ...confirmedAction,
                            [actionType.MOVED_REMAINING_TASK]: true,
                            offsetRemainingTask: parseInt(offsetDays),
                          };
                        }
                        return confirmedAction;
                      }
                    );
                  }
                  setTasksState({
                    tasks: tasksCpy,
                    confirmedActions: confirmedActionsCpy,
                  });
                  setOpenModalAttention(false);
                  setDescriptionModalAttention(null);
                }
              }
            }}
            onClose={() => {
              if (!pressedOneTime) {
                setOpenModalAttention(false);
                setDescriptionModalAttention(null);
                if (isConfirmationModal) {
                  setPressedOneTime(false);
                  return setIsConfirmationModal(false);
                }
                if (isMovingDependantTasks) {
                  let confirmedActionsCpy = [...confirmedActions];
                  confirmedActionsCpy.push({
                    ...modifiedTask,
                    [actionType.DENIED_REMAINING_DAY_OFFSET_MOVED]: true,
                    // typeChange: dependencyTypesOnMove.FINISH_DATE_CHANGED,
                    creationDate: now(),
                    moved: true,
                  });
                  let tasksCpy = [...tasks];
                  tasksCpy = tasksCpy.map((taskCpy) => {
                    if (taskCpy.id === modifiedTask.id) {
                      return { ...taskCpy, moved: true, hasBeenUpdated: true };
                    }
                    return taskCpy;
                  });
                  setTasksState({
                    tasks: tasksCpy,
                    confirmedActions: confirmedActionsCpy,
                  });
                }
              }
            }}
            confirmationText="Yes"
            cancelText="No"
          />
        )}
        <SalesOrderBadged
          salesOrder={currentSO}
          purchaseOrders={currentPOs}
          isSelectedAllPOs={!currentPOs.some((po) => !po.isShownInTimeline)}
          onSelectAllPOs={handleSelectAllPOs}
          customers={customers}
          tasks={tasks}
          viewOptions={viewOptions}
          onCancelTimeLineModal={({ projectVoided }) => {
            console.log('*--')
            if (projectVoided) {
              onCancelTimeLineModal();
              return;
            }
            setIsConfirmationModal(true);
            setDescriptionModalAttention(
              <React.Fragment>
                Are you sure to discard these changes?
              </React.Fragment>
            );
            setOpenModalAttention(true);
            setPressedOneTime(false);
          }}
          onSubmitTimeLineModal={() =>
            onSubmitTimeLineModal({
              tasks: tasks.filter((task) => task.hasBeenUpdated),
              confirmedActions,
              salesOrder: currentSO,
              purchaseOrders: currentPOs,
              shipments: currentShipments,
              viewOptions,
            })
          }
        />
        <PurchaseOrderBadges
          purchaseOrders={currentPOs}
          onChangePOBadge={handleChangePOBadge}
          onMouseEnter={handleHoverTasksIds}
          onMouseLeave={() => setOnHoverTaskIds(null)}
          factories={factories}
          selectedShipments={shipments.map((shipment) => {
            const cShipment = currentShipments.find(
              (cShipment) => cShipment.id === shipment.id
            );
            if (cShipment) {
              return {
                ...shipment,
                checked: cShipment.checked,
              };
            }
            return { ...shipment, checked: false };
          })}
          onChangeSelectedShipments={handleChangeSelectedShipments}
          shipmentOnHover={shipmentOnHover}
        />
        <TimeLineCalendar
          milestones={milestones}
          ghostTasks={getTasksFiltered({
            tasks: ghostTasks,
            currentSO,
            currentPOs,
            currentShipments,
          })}
          flagDates={flagDates}
          onChangeTaskDuration={handleChangeTaskDuration}
          onDropTask={handleDropTask}
          tasks={getTasksFiltered({
            tasks,
            currentSO,
            currentPOs,
            currentShipments,
          })}
          filteredTasks={getTasksFilteredWithoutPhases({
            tasks,
            currentSO,
            currentPOs,
            currentShipments,
          })}
          companyUsers={companyUsers}
          tableSize={tableSize}
          onChangeTableSize={(size) => {
            setTableSize(size);
            if (size === simpleTaskTableColumns) {
              const fullTableDetailsCpy = viewOptionsArray.find(
                (option) => option.label === viewOptionLabels.FULL_TABLE_DETAILS
              );
              handleChangeViewOptions({
                ...fullTableDetailsCpy,
                enabled: false,
              });
            }
          }}
          viewOptionsArray={viewOptions}
          onChangeViewOptions={handleChangeViewOptions}
          isOpenAllSections={isOpenAllSection1(taskPhases)}
          onChangeOpenAllSection={handleChangeOpenAllSections}
          canRedo={canRedo}
          canUndo={canUndo}
          undoTasksState={undoTasksState}
          redoTasksState={redoTasksState}
          onChangeMilestone={handleChangeMilestone}
          onDeleteMilestone={handleDeleteMilestone}
          onAddNewMilestone={handleAddNewMilestone}
          taskPhases={taskPhases}
          onChangeTaskPhase={handleChangeTaskPhase}
          allTasks={tasks}
          onChangeTask={handleChangeTask}
          onHoverTaskIds={onHoverTaskIds}
          onCloseMilestone={handleCloseMilestone}
          onMoveRemainingTasks={handleMoveRemainingTasks}
          selectedShipments={shipments}
          shipments={shipments}
          purchaseOrders={purchaseOrders}
          salesOrder={salesOrder}
          onMouseEnterInTaskDescription={({ shipmentId }) => {
            setShipmentOnHover(shipmentId);
          }}
        />
      </div>
    )
  );
}

export default TimelineModal;
