import React, { useState, useEffect } from "react";
import UserRoles from "./UserRoles";
import UserPermissions from "./UserPermissions";
import Users from "./Users";
import { useSelector } from "react-redux";
import UserSpecificPermissions from "./UserSpecificPermissions";
import { sortObjectsBy, isTradeDashEmployee } from "../../helpers/helpers";
import { useUser, useCompanyUsers } from "../../hooks/user";
import {
  useCompanyId,
  useCurrentRolePermissions,
  useStorage,
} from "../../hooks";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";

function UserManager() {
  const [currentRole, setCurrentRole] = useState();
  const [activeUser, setActiveUser] = useState(null);
  const [currentRoles, setCurrentRoles] = useState([]);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [rolesScreenStorage = {}, setRolesScreenStorage] =
    useStorage("rolesScreen");

  const user = useUser();
  const companyId = useCompanyId();
  const factories = useFactories() || [];
  const customers = useCustomers() || [];
  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });

  const roles = useSelector((state) => state.data.roles[companyId] || []);
  useEffect(() => {
    if (!currentRole) {
      const sortedRoles = roles.sort(sortObjectsBy("name", false));
      const findRole = sortedRoles.find(
        (role) => role.id === rolesScreenStorage.role
      );
      if (findRole) {
        setCurrentRole(findRole);
      } else {
        setCurrentRole(sortedRoles[0]);
      }
    }
    setCurrentRoles(roles);
  }, [roles]);

  const permissions = useCurrentRolePermissions({
    companyId,
    role: currentRole,
    user,
  });

  if (!user) {
    return null;
  }

  const handleReadOnlyModal = () => {
    setReadOnlyModal(true);
  };
  return (
    <div className="user-manager-container">
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      <UserRoles
        activeRole={currentRole}
        changeActiveRole={(role) => {
          setCurrentRole(role);
          setRolesScreenStorage("role", role.id);
          setActiveUser(null);
        }}
        roles={currentRoles}
        companyUsers={companyUsers || []}
        updateCurrentRole={setCurrentRole}
        companyId={companyId}
        isReadOnly={isTradeDashEmployee(user)}
        handleReadOnlyModal={handleReadOnlyModal}
      />
      <UserPermissions
        role={currentRole}
        user={user}
        companyId={companyId}
        isReadOnly={isTradeDashEmployee(user)}
        handleReadOnlyModal={handleReadOnlyModal}
        permissions={permissions}
      />
      <Users
        user={user}
        role={currentRole}
        activeUser={activeUser}
        changeActiveUser={(user) => {
          const userRole = rolesScreenStorage.userRole || {};
          if (!activeUser || activeUser.id !== user.id) {
            setRolesScreenStorage("userRole", {
              ...userRole,
              [currentRole.id]: user.id,
            });
            setActiveUser(user);
          } else {
            setRolesScreenStorage("userRole", {
              ...userRole,
              [currentRole.id]: undefined,
            });
            setActiveUser(null);
          }
        }}
        companyUsers={companyUsers || []}
        isReadOnly={isTradeDashEmployee(user)}
        handleReadOnlyModal={handleReadOnlyModal}
      />
      <UserSpecificPermissions
        role={currentRole}
        user={activeUser}
        companyUsers={companyUsers || []}
        companyId={companyId}
        isReadOnly={isTradeDashEmployee(user)}
        handleReadOnlyModal={handleReadOnlyModal}
        permissions={permissions}
        factoryList={factories.sort(sortObjectsBy("name", false))}
        customerList={customers.sort(sortObjectsBy("name", false))}
      />
    </div>
  );
}

export default UserManager;
