import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class Report {
  constructor({
    id = getRandomId(),
    name = "",
    description = "",
    reportEmbed = "",
    loomEmbed = "",
    URLToUpdateReport = "",
    URLToDownloadReport = "",
    notes = "",
    permissionUsers = {},
    permissionGroups = {},
    creationDate = now(),
    active = true,
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.reportEmbed = reportEmbed;
    this.loomEmbed = loomEmbed;
    this.URLToUpdateReport = URLToUpdateReport;
    this.URLToDownloadReport = URLToDownloadReport;
    this.notes = notes;
    this.permissionUsers = permissionUsers;
    this.permissionGroups = permissionGroups;
    this.creationDate = creationDate;
    this.active = active;
  }
}
