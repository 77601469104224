import React, { useState, useEffect, useRef } from "react";
import { useFactories } from "../../hooks/factories";
import { getTheDate } from "../../helpers/helpers";
import { NotificationGeneralFilterStyled } from "./styles";
import { useCompanyUsers } from "../../hooks/user";
import FilterDisplay from "../PurchaseOrderList/FilterDisplay";
import { getToolTipArrayValue } from "../../helpers/purchaseOrderList";
import { useCustomers } from "../../hooks/customers";
import { dateFilterOptionLabel } from "../../helpers/notifications";
import GeneralFilters from "./GeneralFilters";
import { Button } from "@mui/material";
import DateRangePicker from "../DatePicker/DateRangePicker";

function NotificationGeneralFilters({
  changeFilters = () => {},
  currentFilters = {
    customer: [],
    factory: [],
    assignedTo: [],
    dateRange: { start: "", end: "" },
  },
  onResetFilters,
}) {
  const [filters, setFilters] = useState({});
  const dateRef = useRef(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [existDate, setExistDate] = useState(false);
  const showInactive = false;
  const customers = useCustomers(showInactive);
  const factories = useFactories(showInactive);
  const companyUsers = useCompanyUsers({ showInactiveUsers: false });

  useEffect(() => {
    setFilters(currentFilters);
    if (!currentFilters.dateRange) {
      setExistDate(false);
    } else {
      setExistDate(true);
    }
  }, [currentFilters]);

  function onChangeFilter(filter) {
    return function (value, filterDateOption) {
      const newFilters = { ...filters, [filter]: value, filterDateOption };
      setFilters(newFilters);
      changeFilters(newFilters, filter);
    };
  }

  function changeDate(
    date,
    filterDateOption = dateFilterOptionLabel.CHOOSE_A_DATE_RANGE
  ) {
    onChangeFilter("dateRange")(date, filterDateOption);
    setExistDate(true);
    setOpenDatePicker(false);
  }

  return (
    <NotificationGeneralFilterStyled className="filterContainer">
      <div className="tabFilterContainer">
        <GeneralFilters
          onChange={changeFilters}
          currentFilters={filters}
          enabledFields={{
            assignedTo: true,
            customer: true,
            factory: true,
          }}
          notificationStyles={true}
        />

        <Button className={"resetButton"} onClick={onResetFilters}>
          <span>RESET FILTERS</span>
        </Button>
      </div>
      <div className={"filterDisplayContainer"}>
        {filters.assignedTo && filters.assignedTo.length > 0 && (
          <FilterDisplay
            onClear={() =>
              onChangeFilter("assignedTo")([], currentFilters.filterDateOption)
            }
            label="PERSON"
            tooltip={
              getToolTipArrayValue(
                filters.assignedTo,
                companyUsers,
                "displayName"
              ).tooltip
            }
            value={
              getToolTipArrayValue(
                filters.assignedTo,
                companyUsers,
                "displayName"
              ).value
            }
          />
        )}

        {filters.customer && filters.customer.length > 0 && (
          <FilterDisplay
            onClear={() =>
              onChangeFilter("customer")([], currentFilters.filterDateOption)
            }
            label={filters.customer.length === 1 ? "Customer" : "Customers"}
            tooltip={getToolTipArrayValue(filters.customer, customers).tooltip}
            value={getToolTipArrayValue(filters.customer, customers).value}
          />
        )}
        {filters.factory && filters.factory.length > 0 && (
          <FilterDisplay
            onClear={() =>
              onChangeFilter("factory")([], currentFilters.filterDateOption)
            }
            label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
            tooltip={getToolTipArrayValue(filters.factory, factories).tooltip}
            value={getToolTipArrayValue(filters.factory, factories).value}
          />
        )}

        {existDate && (
          <React.Fragment>
            <div
              onClick={() => {
                setOpenDatePicker(true);
              }}
              ref={dateRef}
            >
              <FilterDisplay
                onClear={() => {
                  setExistDate(false);
                  setOpenDatePicker(false);
                  onChangeFilter("dateRange")(null);
                }}
                label={"Date"}
                tooltip=""
                value={
                  filters.dateRange
                    ? `${getTheDate(filters.dateRange.start)} - ${getTheDate(
                        filters.dateRange.end
                      )}`
                    : "Pick a date"
                }
                hiddenClose={true}
              />
            </div>
            {openDatePicker && (
              <DateRangePicker
                open={openDatePicker}
                el={dateRef.current}
                onClose={(ev) => {
                  setTimeout(() => {
                    setOpenDatePicker(false);
                  }, 100);
                  ev.stopPropagation();
                }}
                onChange={changeDate}
                value={filters.dateRange}
                cancelIcon={true}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </NotificationGeneralFilterStyled>
  );
}

export default NotificationGeneralFilters;
