import React, { useState, useEffect } from "react";
import { useCompanyId, usePermissionGroups } from "../../hooks";
import { useCompanyUsers } from "../../hooks/user";
import CustomModal from "../Modal/Modal";
import CreateReportForm from "./CreateReportForm";
import IntlMessages from "../../util/IntlMessages";

function CreateReport({
  openModal,
  handleClose,
  report,
  onSubmit,
  isUpdating,
  user = {},
}) {
  const [currentReport, setCurrentReport] = useState(null);
  const companyId = useCompanyId();
  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const permissionGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });
  useEffect(() => {
    setCurrentReport(report);
  }, [report]);

  function onChange({ value }) {
    setCurrentReport({ ...value });
  }

  if (!currentReport) {
    return null;
  }

  return (
    <CustomModal
      header={
        isUpdating ? (
          <IntlMessages id={"modal.settings.updateReport"} />
        ) : (
          <IntlMessages id={"modal.settings.createReport"} />
        )
      }
      isOpen={openModal}
      onClose={handleClose}
      modalStyles={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        padding: "32px 8px",
      }}
    >
      <CreateReportForm
        report={currentReport}
        onChange={onChange}
        onSubmit={({ currentReport }) => {
          onSubmit({ currentReport });
        }}
        handleClose={handleClose}
        companyUsers={companyUsers}
        permissionGroupsDB={permissionGroups}
        isUpdating={isUpdating}
        user={user}
      />
    </CustomModal>
  );
}

export default CreateReport;
