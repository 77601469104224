import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import numeral from "numeral";
import moment from "moment";
import { doc, updateDoc } from "firebase/firestore";
import { dbTables, globalScopes } from "../../../api/types/dbTables";
import { useCompany } from "../../../hooks/company";
import { useUser } from "../../../hooks/user";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { useItemsList } from "../../../hooks/itemTable";
import { colors } from "../../../assets/jss/variables";
import {
  FIELD_NAME,
  SOAmountFields,
  SOObjFields,
  deliveryWindowHeaderCells,
  propertyType,
  shipmentObjFields,
  shipmentWindowHeaderCells,
  POObjFields,
} from "../../../helpers/orderDashboard";
import ShipmentDateWindow from "../ShipmentDateWindow";
import {
  getFormattedItems,
  getItemsAmount,
  getTotalCBMFromItems,
  getTotalWeightFromItems,
} from "../../../helpers/orderDashboardRefactored";
import {
  convertDateToTimeStamp,
  getTheDate,
  inputTypeByField,
} from "../../../helpers/helpers";
import { generalPermissions, regexDecimal } from "../../../helpers/constants";
import { firestore } from "../../../firebase";
import AutoCompleteSelect from "../../AutoCompleteSelect/AutoCompleteSelect";
import CurrencyInput from "../../Inputs/CurrencyInput";
import shippingTerms from "../../../api/types/shippingTerms";
import DatePicker from "../../DatePicker/DatePicker";
import IntlMessages from "../../../util/IntlMessages";
import TooltipTD from "../../Tooltip/TooltipTD";
import { SHIPMENT_FIELDS } from "../../../helpers/shipment";
import { SALES_ORDER_FIELDS } from "../../../helpers/salesOrder";

const propertyBlockText = "This field is not editable";
const MAX_CHARACTER = 40;
const dateFields = [
  SHIPMENT_FIELDS.SHIP_DATE,
  SHIPMENT_FIELDS.DELIVERY_DATE,
  POObjFields.ORDER_READY_DATE,
];

function MetadataProperty({
  item = {},
  disabled,
  property = "",
  onSave = () => {},
  companyId,
  propertyScope = globalScopes.SALES_ORDER,
  propertyLabelStyles = {},
  propertyContainerStyles = {},
  classProperty,
  hidden = false,
  field = {},
  secondaryClassName = "",
  purchaseOrders = [],
  shipments = [],
  currentPurchaseOrder = {},
  showEmpty,
  popperClass = "propertyFields",
  currentOptions,
  customer = {},
  customerMetadatas = {},
  metadataKey = "",
  metaDataModel,
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(item[property]);
  const [isOpenList, setIsListOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState(item[property]);
  const divRef = useRef(null);
  const orgRef = useRef(null);
  const inputRef = useRef(null);
  const company = useCompany() || {};
  const [openPicker, setOpenPicker] = useState(false);
  const user = useUser();
  const isAllowed = useIsAllowedFunction();
  const itemsList = useItemsList({ currentPurchaseOrder, user: user });

  useEffect(() => {
    if (property && !editing) {
      setValue(item[property]);
    }
  }, [item[property]]);

  useEffect(() => {
    if (property && item[property] !== value) {
      setValue(item[property]);
    }

    if (property && editing && dateFields.includes(property)) {
      setOpenPicker(true);
    }
  }, [editing]);

  if (!property) {
    if (showEmpty) {
      return <div style={{ borderTop: `1px solid ${colors.dividerColor}` }} />;
    }
    return null;
  }

  const isAutocomplete = () => {
    const autocompleteType = [
      shipmentObjFields.FINAL_DESTINATION,
      shipmentObjFields.FREIGHT_FORWARDER,
      shipmentObjFields.LOADING_PORT,
      shipmentObjFields.UNLOADING_PORT,
    ];
    return autocompleteType.includes(property);
  };
  const isAutocompleteField = isAutocomplete();

  const getValue = (property) => {
    const discount = parseFloat(item.discount || 0);
    if (isAutocompleteField) {
      return value;
    }
    switch (property) {
      case FIELD_NAME.SHIPMENT_WINDOW:
        return (
          <ShipmentDateWindow
            purchaseOrders={purchaseOrders}
            shipments={shipments}
            title="Shipment Window"
            field="shipDate"
            headCells={shipmentWindowHeaderCells}
            toolTipLabel={disabled ? propertyBlockText : getValue(property)}
            propertyScope={propertyScope}
            companyId={companyId}
          />
        );
      case FIELD_NAME.DELIVERY_WINDOW:
        return (
          <ShipmentDateWindow
            purchaseOrders={purchaseOrders}
            shipments={shipments}
            title="Delivery Window"
            field="deliveryDate"
            headCells={deliveryWindowHeaderCells}
            toolTipLabel={disabled ? propertyBlockText : getValue(property)}
            propertyScope={propertyScope}
            companyId={companyId}
          />
        );
      case FIELD_NAME.UNIQUE_ITEMS:
        if (propertyScope === globalScopes.SALES_ORDER) {
          return item.items && item.items.length;
        } else if (propertyScope === globalScopes.PURCHASE_ORDER) {
          return itemsList && itemsList.length;
        } else {
          return item.items && item.items.length;
        }
      case FIELD_NAME.TOTAL_SHIPMENTS:
        if (propertyScope === globalScopes.SALES_ORDER) {
          let totalShipments = [];
          purchaseOrders.forEach((purchaseOrder) => {
            if (purchaseOrder.shipmentIds) {
              totalShipments = _.union(
                totalShipments,
                purchaseOrder.shipmentIds
              );
            }
          });
          return totalShipments && totalShipments.length;
        } else if (propertyScope === globalScopes.PURCHASE_ORDER) {
          return item.shipmentIds && item.shipmentIds.length;
        } else {
          return 0;
        }
      case FIELD_NAME.DISCOUNT:
        let currentDiscount = "";
        if (item.discount) {
          const parsedDiscount = parseFloat(item.discount);
          if (!isNaN(parsedDiscount) && parsedDiscount !== 0) {
            currentDiscount = parsedDiscount + "%";
          }
        }
        return currentDiscount;
      case FIELD_NAME.TOTAL_DISCOUNT: {
        const formattedItems = getFormattedItems({
          items: item.items,
          scope: propertyScope,
          orderId: item.id,
        });
        const total = getItemsAmount({ items: formattedItems });
        const totalDiscount = (total * discount) / 100;
        return !discount ? "" : numeral(totalDiscount).format("$0,0.00");
      }
      case FIELD_NAME.TOTAL: {
        const formattedItems = getFormattedItems({
          items: item.items,
          scope: propertyScope,
          orderId: item.id,
        });
        const total = getItemsAmount({ items: formattedItems });
        const totalDiscount = (total * discount) / 100;
        if (!discount) {
          return numeral(total).format("$0,0.00");
        }
        return numeral(total - totalDiscount).format("$0,0.00");
      }
      case FIELD_NAME.AMOUNT: {
        const formattedItems = getFormattedItems({
          items: item.items,
          scope: propertyScope,
          orderId: item.id,
        });
        const total = getItemsAmount({ items: formattedItems });
        if (!discount) {
          return numeral(total).format("$0,0.00");
        }
        return numeral(total).format("$0,0.00");
      }
      case FIELD_NAME.CBM: {
        const formattedItems = getFormattedItems({
          items: item.items,
          scope: propertyScope,
          orderId: item.id,
        });

        return numeral(getTotalCBMFromItems({ items: formattedItems })).format(
          "0,0.00"
        );
      }
      case FIELD_NAME.WEIGHT: {
        const formattedItems = getFormattedItems({
          items: item.items,
          scope: propertyScope,
          orderId: item.id,
        });
        return numeral(
          getTotalWeightFromItems({ items: formattedItems })
        ).format("0,0.00");
      }
      case FIELD_NAME.BALANCE: {
        const formattedItems = getFormattedItems({
          items: item.items,
          scope: propertyScope,
          orderId: item.id,
        });
        const total = getItemsAmount({ items: formattedItems });
        const deposit = item.deposit || 0;
        const totalDiscount = (total * discount) / 100;
        return numeral(total - totalDiscount - deposit).format("$0,0.00");
      }
      case FIELD_NAME.TOTAL_POS: {
        return purchaseOrders.length;
      }
      default:
        let itemProperty = item[property];
        if (field.propertyType === propertyType.DATE) {
          return getTheDate(itemProperty, "M/DD/YY");
        }

        if (field.propertyType === propertyType.DOLAR_AMOUNT && itemProperty) {
          let newDolarAmount = "";
          if (itemProperty) {
            const parsedDiscount = parseFloat(itemProperty);
            if (!isNaN(parsedDiscount) && parsedDiscount !== 0) {
              newDolarAmount = parsedDiscount;
            }
          }
          if (!newDolarAmount) {
            return "";
          }
          return numeral(newDolarAmount).format("$0,0.00");
        }
        if (itemProperty || itemProperty === 0) {
          return itemProperty || "";
        }

        return "";
    }
  };

  function validateToSeeAmount() {
    const propertyType = {
      [globalScopes.SALES_ORDER]: generalPermissions.SALES_ORDER_AMOUNT,
      [globalScopes.PURCHASE_ORDER]: generalPermissions.PURCHASE_ORDER_AMOUNT,
      [globalScopes.SHIPMENT]: generalPermissions.SHIPMENT_AMOUNT,
    };
    return propertyType[propertyScope] || "";
  }

  function saveMount(currentValue, property) {
    if (isAutocompleteField) {
      if ((!currentValue || !currentValue.name) && !currentValue.reset) {
        return handleClose();
      }
      const name = currentValue.name;
      onSave(name, property);
      handleClose();
      return;
    }
    if (property !== "CBM") {
      field.propertyType === propertyType.DATE
        ? onSave(+currentValue, property)
        : onSave(currentValue, property);
      handleClose();
    } else {
      if (isAllowed("modify_cbm")) {
        field.propertyType === propertyType.DATE
          ? onSave(+currentValue, property)
          : onSave(currentValue, property);
        handleClose();
      }
    }
  }

  function handleKeyDown(ev) {
    if (ev.key === "Enter" && !isPropertySelect(property)) {
      saveMount(value, property);
    }
    if (ev.key === "Escape") {
      if (openPicker) {
        setOpenPicker(false);
        return;
      } else {
        handleClose();
      }
    }
  }

  function getInputValue(currentValue) {
    if (inputTypeByField(property) === "date") {
      return getTheDate(currentValue, "YYYY-MM-DD");
    } else if (field.propertyType === propertyType.DOLAR_AMOUNT) {
      if (!currentValue) {
        return (0).toFixed(2);
      }
      return parseFloat(currentValue).toFixed(2);
    } else {
      return currentValue;
    }
  }

  function handleChangeInput(newDate) {
    if (property === "CBM") {
      setValue(newDate);
    } else if (property === "shipDates") {
      setValue([
        {
          creationDate: moment.now(),
          shipDate: convertDateToTimeStamp(newDate, "YYYY-MM-DD"),
          user: user.id,
        },
      ]);
    } else if (property === "deliveryDates") {
      setValue([
        {
          creationDate: moment.now(),
          deliveryDate: convertDateToTimeStamp(newDate, "YYYY-MM-DD"),
          user: user.id,
        },
      ]);
    } else if (inputTypeByField(property) === "date") {
      setValue(convertDateToTimeStamp(newDate, "YYYY-MM-DD"));
    } else {
      setValue(newDate);
    }
  }

  function handleAddNewElementToCompany(currentValue, property) {
    if (isAutocompleteField) {
      if (currentValue === "") {
        saveMount({ name: "", reset: true }, property);
        return;
      }
      const findValue = currentOptions.find(
        (destination) =>
          destination.name.toLowerCase() === currentValue.toLowerCase()
      );
      if (findValue) {
        saveMount(findValue, property);
      } else {
        const newMetadata = {
          ...new metaDataModel({ name: currentValue }),
        };
        updateDoc(customer.ref, {
          [metadataKey]: {
            ...customerMetadatas,
            [newMetadata.id]: newMetadata,
          },
        });

        saveMount(newMetadata, property);
      }

      return;
    }
    const valueProperty = company[property] ? [...company[property]] : [];
    if (!valueProperty.includes(currentValue)) {
      valueProperty.push(currentValue);
      updateDoc(doc(firestore, `${dbTables.COMPANIES}/${companyId}`), {
        [property]: valueProperty,
      });
      setValue(currentValue);
    }
    saveMount(currentValue, property);
  }

  function handleRemove(valueRemove, property) {
    if (isAutocompleteField) {
      const newDestination = customerMetadatas;
      delete newDestination[valueRemove.id];
      updateDoc(customer.ref, {
        [metadataKey]: {
          ...newDestination,
        },
      });
      if (valueRemove.name === value) {
        handleClose();
      }
      return;
    }
    const valueProperty = company[property] ? [...company[property]] : [];
    const newValueProperty = valueProperty.filter(
      (elem) => elem !== valueRemove
    );
    updateDoc(doc(firestore, `${dbTables.COMPANIES}/${companyId}`), {
      [property]: newValueProperty,
    });

    if (value === valueRemove) {
      saveMount("", property);
    }
  }

  function isPropertySelect(property) {
    return (
      property === shipmentObjFields.LOADING_PORT ||
      property === shipmentObjFields.UNLOADING_PORT ||
      property === shipmentObjFields.FINAL_DESTINATION ||
      property === SOObjFields.SALES_REPRESENTATIVE ||
      property === shipmentObjFields.FREIGHT_FORWARDER
    );
  }

  function getTextValue(field) {
    const textValue = {
      loadingPort: "loading port",
      unloadingPort: "unloading port",
      finalDestination: "final destination",
      [SOObjFields.SALES_REPRESENTATIVE]: "sales rep.",
      [shipmentObjFields.FREIGHT_FORWARDER]: "freight forwarder",
    };
    return textValue[field] || "loading port";
  }
  function isDateTypo() {
    return (
      inputTypeByField(property) === "array" ||
      inputTypeByField(property) === "date"
    );
  }
  const getValueToShow = () => {
    if (
      SOAmountFields.includes(property) &&
      !isAllowed(validateToSeeAmount())
    ) {
      return notAllowedToSee;
    }
    if (property === shipmentObjFields.FINAL_DESTINATION) {
      return value;
    }
    const currentValue = getValue(property);
    return currentValue || <span className="helper-span" />;
  };

  const getInputComponent = () => {
    if (isAutocompleteField) {
      const valueList = currentOptions.find((option) => option.name === value);
      return (
        <AutoCompleteSelect
          activeBlur
          options={currentOptions}
          handleAddNewElement={(value) => {
            handleAddNewElementToCompany(value, property);
          }}
          handleRemove={(value) => handleRemove(value, property)}
          sortBy={"name"}
          value={valueList || null}
          maxCharacter={MAX_CHARACTER}
          handleChange={(ev, textValue) => {
            setValue(textValue.name);
            saveMount(textValue, property);
            ev.stopPropagation();
          }}
          textValue={
            textFieldValue && textFieldValue.length <= MAX_CHARACTER
              ? `No matching ${getTextValue(property)}, press enter to save`
              : `The ${getTextValue(
                  property
                )} name can be up to ${MAX_CHARACTER} letters only`
          }
          mainClass={"autocomplete-select"}
          autoCompleteClasses={{
            option: "option",
            listbox: "listbox",
            popper: popperClass,
          }}
          textFieldClasses={{
            adornedEnd: "adornedend",
            inputAdornedEnd: "inputAdornedEnd",
            notchedOutline: "notchedOutline",
          }}
          onTextFieldChange={(ev) => {
            setTextFieldValue(ev.target.value);
            ev.stopPropagation();
          }}
          textFieldValue={textFieldValue || ""}
          editing={editing}
          handleKeyDown={handleKeyDown}
          handleClose={handleClose}
          isOpenList={isOpenList}
          handleOpenList={(value) => {
            setIsListOpen(value);
          }}
        />
      );
    }
    if (isPropertySelect(property)) {
      return (
        <AutoCompleteSelect
          activeBlur
          options={options}
          handleAddNewElement={(value) => {
            handleAddNewElementToCompany(value, property);
          }}
          handleClose={handleClose}
          setTextFieldValue={setTextFieldValue}
          handleRemove={(value) => handleRemove(value, property)}
          value={value}
          maxCharacter={MAX_CHARACTER}
          handleChange={(ev, textValue) => {
            setValue(textValue);
            saveMount(textValue, property);
            ev.stopPropagation();
          }}
          textValue={
            textFieldValue && textFieldValue.length <= MAX_CHARACTER
              ? `No matching ${getTextValue(property)}, press enter to save`
              : `The ${getTextValue(
                  property
                )} name can be up to ${MAX_CHARACTER} letters only`
          }
          mainClass={"autocomplete-select"}
          autoCompleteClasses={{
            option: "option",
            listbox: "listbox",
            popper: popperClass,
          }}
          textFieldClasses={{
            adornedEnd: "adornedend",
            inputAdornedEnd: "inputAdornedEnd",
            notchedOutline: "notchedOutline",
          }}
          onTextFieldChange={(ev) => {
            setTextFieldValue(ev.target.value);
            ev.stopPropagation();
          }}
          textFieldValue={textFieldValue}
          editing={editing}
          handleKeyDown={handleKeyDown}
          isOpenList={isOpenList}
          handleOpenList={(value) => {
            setIsListOpen(value);
          }}
        />
      );
    } else if (
      inputTypeByField(property) !== "select" &&
      field.propertyType === propertyType.DOLAR_AMOUNT
    ) {
      return (
        <CurrencyInput
          value={value}
          onChange={(ev) => {
            const currentValue = ev.target.value || "";
            const isNumber =
              regexDecimal.test(currentValue) || currentValue === "";
            if (isNumber) {
              setValue(currentValue);
            }
          }}
          editing={editing}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
        />
      );
    } else if (inputTypeByField(property) !== "select") {
      return (
        <div
          className="inputWithButton"
          onClick={(ev) => {
            if (isDateTypo(property)) {
              setOpenPicker(true);
            }
            ev.stopPropagation();
          }}
        >
          <input
            ref={inputRef}
            id={property}
            onChange={(ev) => {
              handleChangeInput(ev.target.value);
            }}
            type={
              field.propertyType === propertyType.DOLAR_AMOUNT
                ? "number"
                : isDateTypo()
                ? "date"
                : inputTypeByField(property)
            }
            value={getInputValue(value)}
            autoFocus
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
          />
        </div>
      );
    } else if (inputTypeByField(property) === "select") {
      return (
        <select
          onChange={(ev) => setValue(ev.target.value)}
          type={inputTypeByField(property)}
          value={value}
          autoFocus
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
        >
          <option disabled value="">
            - - -
          </option>
          {Object.keys(shippingTerms).map((key) => (
            <option key={key} value={key}>
              {key + " - " + shippingTerms[key]}
            </option>
          ))}
        </select>
      );
    }
  };

  const handleOnBlur = () => {
    if (openPicker) {
      return;
    }
    saveMount(value, property);
  };

  const handleClose = () => {
    setEditing(false);
    setIsListOpen(false);
    setValue(item[property]);
  };

  const handleEditing = (ev) => {
    if (!disabled) {
      if (!editing) {
        setEditing(true);
      }
    }
    ev.stopPropagation();
  };

  const notAllowedToSee = "* * * * * * * * *";
  const options = company[property] ? company[property] : [];
  const propertyStyle = classProperty ? classProperty : "sales-order-property";
  const PROPERTY_FIELDS_TO_SHOW = [
    SALES_ORDER_FIELDS.CUSTOMER_PO,
    SALES_ORDER_FIELDS.SALES_REPRESENTATIVE,
  ];

  return (
    <div
      style={{
        contentVisibility: hidden ? "hidden" : "visible",
        height: 39,
      }}
    >
      {openPicker && (
        <DatePicker
          el={inputRef.current}
          onChange={(value) => {
            const StringDate = moment(value).format("YYYY-MM-DD");
            handleChangeInput(StringDate);
            setOpenPicker(false);
          }}
          open={openPicker}
          onClose={() => setTimeout(() => setOpenPicker(false), 100)}
          value={value ? moment(getInputValue(value)) : ""}
          cancelIcon={true}
          onKeyDown={handleKeyDown}
        />
      )}
      <div
        tabIndex={0}
        ref={orgRef}
        onClick={(ev) => handleEditing(ev)}
        className={classnames(propertyStyle, { secondaryClassName })}
        style={{ ...propertyContainerStyles }}
      >
        <label style={{ ...propertyLabelStyles }}>
          {field.labelId ? (
            <IntlMessages id={field.labelId} />
          ) : (
            <IntlMessages id={property} />
          )}
        </label>
        <TooltipTD
          label={
            disabled && !PROPERTY_FIELDS_TO_SHOW.includes(property)
              ? propertyBlockText
              : getValue(property)
          }
          showToolTip={field.tooltip || false}
          id={propertyScope + "-" + property}
          style={{ ...field.styles, width: "fit-content" }}
        >
          <span className={!disabled ? "editable-field" : "not-editable-field"}>
            {getValueToShow()}
          </span>
        </TooltipTD>

        {!disabled && (
          <EditIcon
            className={"edit-icon"}
            onClick={(ev) => {
              handleEditing(ev);
            }}
          />
        )}

        {editing && (
          <div
            tabIndex={0}
            style={{ position: "absolute" }}
            className={classnames("sales-order-property", {
              editing: editing,
            })}
            onKeyDown={handleKeyDown}
          >
            {
              <section
                ref={divRef}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  minWidth: "auto",
                }}
              >
                <div style={{ flex: 1, margin: "0 6px", overflow: "hidden" }}>
                  {getInputComponent()}
                </div>

                <CheckIcon
                  className="icon"
                  onClick={(ev) => {
                    if (isPropertySelect(property)) {
                      setIsListOpen(false);
                      return handleAddNewElementToCompany(
                        textFieldValue,
                        property,
                        true
                      );
                    }
                    saveMount(value, property);
                    ev.stopPropagation();
                  }}
                  style={{ flex: "unset", flexShrink: 0 }}
                />

                <ClearIcon
                  style={{ flex: "unset", flexShrink: 0 }}
                  className="icon"
                  onMouseDown={() => handleClose()}
                />
              </section>
            }
          </div>
        )}
      </div>
    </div>
  );
}

export default MetadataProperty;
