import React, { useState } from "react";
import { usePermissions } from "../../hooks/permissions";
import { useUser, useCompanyUsers } from "../../hooks/user";
import ResizeTab from "./ResizeTab/ResizeTab";
import userTypes from "../../api/types/userTypes";
import { isTradeDashEmployee } from "../../helpers/helpers";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import ProjectTasksV2 from "./ProjectTasksV2";
import ProjectNotesV2 from "./ProjectNotesV2";
import ProjectDocumentsV2 from "./ProjectDocumentsV2";
import {
  QUERY_PARAMS_SCOPE,
  onChangeQueryParamsv2,
} from "../../helpers/orderDashboard";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "./sections/helpers";
import { useQueryParams } from "../../hooks";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useNavigate } from "react-router-dom";

export const filters = { ALL: "ALL", MINE: "MINE", COMPLETED: "COMPLETED" };

function ProjectDashboardV2({
  companyID,
  currentSalesOrder = {},
  currentPurchaseOrder,
  purchaseOrders = [],
  permissionGroups = [],
  isReadOnly = false,
  setIsLoading,
  currentShipment,
}) {
  let companyUsers = useCompanyUsers({});
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  let allCompanyUsers = useCompanyUsers({ showBotUser: true });
  const permissions = usePermissions();
  const user = useUser();
  function getPermissionToModifiedDueDate() {
    let permissionDueDate = {};
    permissions.forEach((element) => {
      if (element.id === GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES) {
        permissionDueDate = element;
      }
    });
    return (
      permissionDueDate.enabled ||
      user.role === userTypes.SUPER_ADMIN ||
      user.role === userTypes.TRADEDASH_EMPLOYEE
    );
  }

  const handleChangeShipment = (shipmentId) => {
    onChangeQueryParamsv2({
      scope: QUERY_PARAMS_SCOPE.SHIPMENT,
      shipmentId: shipmentId,
      queryParams: {
        ...queryParams,
        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
      },
      navigate,
    });
  };

  function handleReadOnlyModal() {
    setReadOnlyModal(true);
  }

  return (
    <div
      id={"project-dashboard-container"}
      className="project-dashboard-container"
      style={{ minWidth: 1650 }}
    >
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
          isRegularUser={!isTradeDashEmployee(user)}
        />
      )}
      <ProjectTasksV2
        companyId={companyID}
        salesOrder={currentSalesOrder}
        purchaseOrder={currentPurchaseOrder}
        hasPermissionToModifiedDueDate={getPermissionToModifiedDueDate()}
        companyUsers={companyUsers}
        purchaseOrders={purchaseOrders}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        setIsLoading={setIsLoading}
        currentShipment={currentShipment}
        onChangeShipment={handleChangeShipment}
      />
      <ResizeTab />

      <ProjectNotesV2
        purchaseOrder={currentPurchaseOrder}
        salesOrder={currentSalesOrder}
        companyId={companyID}
        companyUsers={allCompanyUsers}
        purchaseOrders={purchaseOrders}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        currentShipment={currentShipment}
        onChangeShipment={handleChangeShipment}
      />
      <ResizeTab direction="right" />

      <ProjectDocumentsV2
        permissionGroups={permissionGroups}
        companyID={companyID}
        currentSalesOrder={currentSalesOrder}
        currentPurchaseOrder={currentPurchaseOrder}
        user={user}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        currentShipment={currentShipment}
        onChangeShipment={handleChangeShipment}
      />
    </div>
  );
}

export default ProjectDashboardV2;
