import React, { useState, useEffect, useCallback } from "react";
import searchIcon from "../../assets/flag-icons/search_activity.svg";
// import { trackEvent } from "../../helpers/analytics";
import CancelIcon from "@mui/icons-material/Cancel";
import { debounce } from "../../helpers/helpers";
import { InputBase } from "@mui/material";
import { SearchBoxStyled } from "../../container/StyledComponent/SearchBoxStyled";

function SearchBox({
  placeholder = "Search for SO# or PO#",
  onChange = false,
  value = "",
  style = { width: 425 },
  hasBeenReseted = () => {},
  customStyle = "",
  onDebounceValue = () => {},
  filters,
  disabled = false,
}) {
  const [valueState, setValueState] = useState(value || "");
  const onDebounce = useCallback(
    debounce((filters, val) => {
      onDebounceValue(filters, val);
    }, 1250),
    []
  );
  useEffect(() => {
    if (value) {
      setValueState(value);
    } else {
      setValueState("");
    }
  }, [value]);
  function trackUse() {
    if (value) {
      // trackEvent("UsedSearchBox", { searchTerm: value });
    }
  }
  function resetValue(filters) {
    setValueState("");
    hasBeenReseted(filters, "");
  }
  return (
    <SearchBoxStyled
      className={(customStyle || "searchContainer") + " search-box-list"}
      style={style}
    >
      <InputBase
        id="generic-searchbox"
        value={valueState}
        onChange={(event) => {
          if (onChange) {
            onChange(event);
          } else {
            setValueState(event.target.value);
            onDebounce(filters, event.target.value);
          }
        }}
        placeholder={placeholder}
        className="inputSearch"
        onBlur={trackUse}
        style={{ fontSize: "14px" }}
        disabled={disabled}
      />
      {valueState ? (
        <CancelIcon className="buttonX" onClick={() => resetValue(filters)} />
      ) : (
        <img
          src={searchIcon}
          alt=""
          width="20px"
          height="20px"
          style={{ marginRight: 10 }}
        />
      )}
    </SearchBoxStyled>
  );
}

export default SearchBox;
