import React from "react";
import { orderType } from "../../helpers/salesOrder";
import { cx } from "@emotion/css";
import { Tab, Tabs } from "@mui/material";

function ProjectDocumentsTabs({
  onChange = () => {},
  value = 0,
  SODocsLength,
  PODocsLength,
}) {
  return (
    <Tabs
      value={value}
      variant="fullWidth"
      textColor="primary"
      className="custom-tabs"
      style={{
        margin: 0,
        marginTop: 8,
        position: "relative",
        bottom: -2,
      }}
      classes={{
        indicator: "custom-tab-indicator",
        flexContainer: "custom-tabs-container",
      }}
      onChange={(e, value) => onChange(value)}
    >
      <Tab
        classes={{
          selected: "active" + orderType.SALES_ORDER,
          wrapper: "wrapped",
        }}
        className={cx("custom-tab", orderType.SALES_ORDER)}
        label={`Sales Order (${SODocsLength})`}
      />
      <Tab
        classes={{
          selected: "active" + orderType.PURCHASE_ORDER,
          wrapper: "wrapped",
        }}
        className={cx("custom-tab", orderType.PURCHASE_ORDER)}
        label={`Purchase Order (${PODocsLength})`}
      />
    </Tabs>
  );
}

export default ProjectDocumentsTabs;
