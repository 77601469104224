import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";
import AppConfig from "../../constants/AppConfig";

export const ReportStyled = styled("div")(() => ({
  "&.reportContainer": {
    width: "100%",
    height: "calc(100vh - 71px)",
    "& #grid-bottom-bar": {
      display: "none !Important",
    },
    "& .URL-to-update-report-content": {
      position: "absolute",
      top: 0,
      left: 120,
      zIndex: 50,
      "& button": {
        padding: "8px 12px",
        background: "#39f",
        fontWeight: 400,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& span": {
        fontSize: 13,
        fontWeight: 400,
        margin: 4,
      },
    },
  },
}));

export const ReportDrawerStyled = styled("div")(() => ({
  "&.closed": {
    zIndex: -1,
    backgroundImage: "unset",
  },
  "&.drawerRoot": {
    position: "fixed",
    height: "100vh",
    top: 66,
    width: "calc(100vw)",
    zIndex: 999,
    display: "flex",
    flexDirection: "column",
    left: 87,
    backgroundColor: "rgba(0,0,0,0.30)",
    transition: "all 0.2s ease-in-out",
  },
  "&.closeDrawer": {
    zIndex: 0,
    backgroundColor: "transparent",
  },
  "& .containerDrawer": {
    height: "calc(100vh - 73px)",
    backgroundColor: "white",
    boxShadow: "0 2px 4px 2px #ccc",
    overflow: "hidden",
    overflowY: "auto",
    position: "sticky",
    background: "white",
    zIndex: 1,
    transition: "all 0.2s ease-in-out",
    "& .closeIconContainer": {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px",
      "& .note-secction-cancel-icon": {
        cursor: "pointer",
        position: "relative !Important",
        top: "auto !Important",
        right: "auto !Important",
      },
    },
    "& .headerContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "12px 24px 24px 0px",
      "& .search-box-list": {
        position: "relative",
        width: "360px !Important",
        right: "auto",
      },
      "& .boxed-filter": {
        userSelect: "none",
        height: 40,
        minWidth: 53,
        borderRadius: 5,
        outline: "1px solid #d2dfec",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "& .favoriteButton": {
          padding: 0,
          "&:hover": {
            background: "#F5F5F5",
          },
        },
      },
    },
  },
  "& .reportListText": {
    padding: "16px",
    margin: "8px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    "&:hover": {
      background: AppConfig.themeColors.yellowOnHover,
      "& .favorite-inactive-star": {
        display: "flex",
        opacity: "1 !important",

        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    "& .blue-bar": {
      height: "100%",
      width: 4,
      background: "#39F",
      position: "absolute",
      left: 0,
    },
    "& .report-name": {
      fontSize: 14,
      fontWeight: 500,
      color: colors.primaryDark,
      cursor: "pointer",
      width: "-webkit-fill-available",
    },
    "& .icons-container": {
      display: "flex",
      alignItems: "center",
      "& img": {
        margin: "0px 4px",
        cursor: "pointer",
      },
    },
    "& .favorite-container": {
      width: 40,
      height: 19,
      "& img": {
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .favorite-inactive-star": {
        display: "flex",
        opacity: 0,

        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  },
  "& .slideRight": {
    width: 480,
  },
  "& .slideLeft": {
    width: 0,
  },
  "& .reportPopoverContainer": {
    padding: "24px 24px 12px 24px",
    "&:hover": {
      "& img": {
        opacity: 1,
      },
    },

    "& .report-description": {
      fontSize: 13,
      color: colors.primaryDark,
      fontWeight: 400,
      width: 350,
      lineHeight: 1.5,
      "white-space": "pre-wrap",
    },
    // "& img": {
    //   color: colors.primaryDark,
    //   cursor: "pointer",
    //   opacity: 0,
    // },
  },
}));
