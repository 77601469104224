import React from "react";
import { TemplateStageStyled } from "./styles";
import IntlMessages from "../../util/IntlMessages";
import { Grid } from "@mui/material";
import { sortObjectsBy } from "../../helpers/helpers";
import classnames from "classnames";
import dependencyTypes from "../../api/types/dependencyTypes";
import EditableLabel from "../TextFields/EditableLabel";
import { Droppable, Draggable } from "react-beautiful-dnd";
import SetupNotifications from "./SetupNotifications";
import AppConfig from "../../constants/AppConfig";
import { DeleteIcon } from "../../helpers/iconSvgPath";
import { Menu as MenuIcon } from "@mui/icons-material";

function StageSection({
  stage = "PRODUCTION",
  taskList = [],
  onAdd = function (stage) {},
  onDelete = function (taskId) {},
  onChangeTask = function (task, field, value, stage) {
    return function () {};
  },
  companyId = "",
  combinedList = [],
  autofocus,
  setAutofocus,
  companyUsers = [],
  disabled,
}) {
  function handleClick(ev) {
    setAutofocus(true);
    onAdd(stage);
  }
  function handleEnter(item) {
    return function (ev) {
      if (ev.key === "Enter") {
        setAutofocus(true);
        onAdd(stage);
      }
    };
  }
  return (
    <TemplateStageStyled className="templateStageContainer">
      <Grid container className={"header"}>
        <Grid item container xs={4}>
          <span style={{ marginLeft: 16 }}>
            <IntlMessages id={stage} />
          </span>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ textAlign: "center" }}
          xs={2}
        >
          <IntlMessages id="components.factories.categorysection.dependencytype" />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ textAlign: "center" }}
          xs={1}
        >
          <IntlMessages id="components.factories.categorysection.predecessor" />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ textAlign: "center" }}
          xs={1}
        >
          <IntlMessages id="components.factories.categorysection.duration" />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ textAlign: "center" }}
          xs={1}
        >
          <IntlMessages id="components.factories.categorysection.offset" />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ textAlign: "center" }}
          xs={1}
        >
          <IntlMessages id="components.factories.categorysection.person" />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ textAlign: "center" }}
          xs={1}
        >
          <IntlMessages id="components.factories.categorysection.notificationEarly" />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          style={{ textAlign: "center" }}
          xs={1}
        >
          <IntlMessages id="components.factories.categorysection.notificationLate" />
        </Grid>

        {/*  */}
        <Grid item style={{ textAlign: "right" }} xs={1}>
          <button className={"add"} onClick={handleClick}>
            Add Task
          </button>
        </Grid>
      </Grid>

      <Droppable droppableId={stage}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={"content"}
          >
            {!taskList.length && <br></br>}
            {taskList.sort(sortObjectsBy("listIndex")).map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={disabled}
                shouldRespectForcePress
              >
                {(provided) => (
                  <Grid
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    style={{
                      padding: "8px 0",
                      ...provided.draggableProps.style,
                      background:
                        AppConfig.themeColors.salesOrderBackgroundColor,
                      margin: "1px 0px",
                    }}
                    container
                    direction="row"
                  >
                    <Grid item xs={3} className="info-container-template">
                      <span {...provided.dragHandleProps}>
                        <MenuIcon />
                      </span>
                      <div>
                        <span>
                          {"Task #" +
                            (combinedList.findIndex(
                              (indexItem) => indexItem.id === item.id
                            ) +
                              1)}
                        </span>
                      </div>
                      <input
                        onFocus={(ev) => ev.target.select()}
                        autoFocus={autofocus}
                        onKeyPress={handleEnter(item)}
                        value={item.description}
                        disabled={disabled}
                        onChange={(ev) =>
                          onChangeTask(
                            item,
                            "description",
                            ev.target.value,
                            stage
                          )
                        }
                        onBlur={(ev) =>
                          onChangeTask(
                            item,
                            "description",
                            ev.target.value || "New task",
                            stage
                          )
                        }
                        style={{ height: "25px", background: "#fff" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      justifyContent="center"
                      container
                      direction="row"
                    >
                      {combinedList.findIndex(
                        (indexItem) => indexItem.id === item.id
                      ) !== 0 && (
                        <select
                          className={classnames("assignedSelect", {
                            default: !item.dependencyType,
                          })}
                          value={item.dependencyType}
                          disabled={disabled}
                          onChange={(ev) =>
                            onChangeTask(
                              item,
                              "dependencyType",
                              ev.target.value,
                              stage
                            )
                          }
                        >
                          <option
                            className="default"
                            style={{ color: "#ccc" }}
                            value=""
                          >
                            Select one
                          </option>
                          {Object.keys(dependencyTypes).map(
                            (dependenyType, index) => (
                              <option
                                key={dependenyType + index}
                                value={dependenyType}
                              >
                                {dependencyTypes[dependenyType]}
                              </option>
                            )
                          )}
                        </select>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      container
                      direction="row"
                    >
                      {combinedList.findIndex(
                        (indexItem) => indexItem.id === item.id
                      ) !== 0 && (
                        <select
                          className={classnames(
                            "assignedSelect",
                            "taskSelect",
                            {
                              default: !item.dependency,
                            }
                          )}
                          key={item.id + "assignedSelect"}
                          disabled={disabled}
                          value={
                            combinedList
                              .map((task) => task.id)
                              .includes(item.dependency)
                              ? item.dependency
                              : ""
                          }
                          onChange={(ev) =>
                            onChangeTask(
                              item,
                              "dependency",
                              ev.target.value,
                              stage
                            )
                          }
                        >
                          <option
                            className="default"
                            style={{ color: "#ccc" }}
                            disabled
                            value=""
                          >
                            Select one
                          </option>
                          {combinedList
                            .filter((task) => task.id !== item.id)
                            .sort((task) =>
                              sortObjectsBy(
                                combinedList.findIndex(
                                  (indexItem) => indexItem.id === task.id
                                ) + 1
                              )
                            )
                            .map((task) => (
                              <option value={task.id} key={task.id + item.id}>
                                S.
                                {combinedList.findIndex(
                                  (indexItem) => indexItem.id === task.id
                                ) + 1}{" "}
                                {task.description}
                              </option>
                            ))}
                        </select>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      container
                      direction="row"
                    >
                      <EditableLabel
                        type="number"
                        disabled={disabled}
                        style={{
                          fontSize: 14,
                          minHeight: "auto",
                          minWidth: "auto",
                          flex: 0.5,
                        }}
                        onChange={(ev) =>
                          onChangeTask(
                            item,
                            "duration",
                            ev.target.value < 1 ? 1 : +ev.target.value,
                            stage
                          )
                        }
                        inputStyle={{ background: "#fff" }}
                      >
                        {item.duration || 0}
                      </EditableLabel>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      container
                      direction="row"
                    >
                      {combinedList.findIndex(
                        (indexItem) => indexItem.id === item.id
                      ) !== 0 && (
                        <EditableLabel
                          type="number"
                          disabled={disabled}
                          style={{
                            fontSize: 14,
                            minHeight: "auto",
                            minWidth: "auto",
                            flex: 0.5,
                            background: "transparent",
                          }}
                          onChange={(ev) =>
                            onChangeTask(
                              item,
                              "offset",
                              +ev.target.value,
                              stage
                            )
                          }
                          isTemplateValue={true}
                          inputStyle={{ background: "#fff" }}
                        >
                          {item.offset || 0}
                        </EditableLabel>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      container
                      direction="row"
                    >
                      <select
                        className={classnames("assignedSelect", {
                          default: !item.assignedTo,
                        })}
                        value={
                          companyUsers.find(
                            (user) => user.id === item.assignedTo
                          )
                            ? item.assignedTo
                            : ""
                        }
                        disabled={disabled}
                        onChange={(ev) =>
                          onChangeTask(
                            item,
                            "assignedTo",
                            ev.target.value,
                            stage
                          )
                        }
                      >
                        <option
                          className="default"
                          style={{ color: "#ccc" }}
                          disabled
                          value=""
                        >
                          Select one
                        </option>
                        {companyUsers
                          .sort(sortObjectsBy("displayName"))
                          .map((user) => (
                            <option key={user.id + item.id} value={user.id}>
                              @{user.displayName}
                            </option>
                          ))}
                      </select>
                    </Grid>
                    {/*  */}
                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      container
                      direction="row"
                    >
                      <SetupNotifications
                        item={{
                          ...item,
                          permissionUsers: item.userNotificationEarly || {},
                          permissionGroups: item.groupNotificationEarly || {},
                        }}
                        onSubmit={({ item }) => {
                          console.log("on submit::: ", item);
                          onChangeTask(
                            item,
                            "notificationEarly",
                            {
                              ...item,
                              userNotificationEarly: item.permissionUsers,
                              groupNotificationEarly: item.permissionGroups,
                            },
                            stage
                          );
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      container
                      direction="row"
                    >
                      <SetupNotifications
                        item={{
                          ...item,
                          permissionUsers: item.userNotificationLate || {},
                          permissionGroups: item.groupNotificationLate || {},
                        }}
                        onSubmit={({ item }) => {
                          console.log("on submit::: ", item);
                          onChangeTask(
                            item,
                            "notificationLate",
                            {
                              ...item,
                              userNotificationLate: item.permissionUsers,
                              groupNotificationLate: item.permissionGroups,
                            },
                            stage
                          );
                        }}
                      />
                    </Grid>

                    {/*  */}
                    <Grid
                      item
                      xs={1}
                      justifyContent="center"
                      container
                      style={{ alignContent: "center" }}
                    >
                      <DeleteIcon
                        onClick={() => {
                          onDelete(item.id, stage);
                        }}
                        svgClass={"delete-icon"}
                      />
                    </Grid>
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </TemplateStageStyled>
  );
}

export default StageSection;
