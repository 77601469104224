import { styled } from "@mui/material";

export const SelectAllNoneStyled = styled("div")(() => ({
  "&.selectAllNoneContainer": {
    fontSize: 12,
    fontWeight: 400,
    color: "#1C53B7",
    marginLeft: 24,
    display: "flex",
    alignItems: "center",
    "& .select-all-button": {
      margin: "0px 2px",
      cursor: "pointer",
      textDecoration: "underline",
    },
    "& .select-none-button": {
      margin: "0px 2px",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
