import React from "react";
import SalesOrderTemplate from "../SalesOrders/SalesOrderTemplate";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function SalesOrder() {
  const companies = useSelector((state) => state.data.companies);
  const { companyId } = useParams();
  const currentCompany = companies.find((company) => company.id === companyId);
  if (!currentCompany) {
    return null;
  }

  return (
    <div className="sales-order-main-container">
      <SalesOrderTemplate
        salesOrderTemplateId={currentCompany.templateId}
        companyId={currentCompany.id}
      />
    </div>
  );
}

export default SalesOrder;
