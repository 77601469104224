export function filterReports({ reports = [], user = {}, accessGroups = [] }) {
  if (reports.length === 0 || !user.id) {
    return [];
  }
  let reportsCpy = [...reports];
  reportsCpy = reportsCpy.filter((report) => report.active);
  reportsCpy = reportsCpy.filter((report) => {
    const isOnPermissionUsers = report.permissionUsers[user.id];
    let isOnPermissionGroups = false;
    const reportPermissionGroups = report.permissionGroups || {};
    Object.keys(reportPermissionGroups).forEach((permission) => {
      const isPermissionEnabled = accessGroups.find(
        (accessGroup) => accessGroup.id === permission
      );
      if (
        user.permissionGroups &&
        user.permissionGroups[permission] &&
        isPermissionEnabled &&
        !isPermissionEnabled.isInactive
      ) {
        isOnPermissionGroups = true;
      }
    });
    if (isOnPermissionUsers || isOnPermissionGroups) {
      return true;
    }
    return false;
  });
  return reportsCpy;
}
