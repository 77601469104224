import { colors } from "../../assets/jss/variables";
import { Box, Modal, Popover, styled } from "@mui/material";

export const PopoverTagStyled = styled(Popover)(() => ({
  "& .rootPoppover": {
    boxShadow: "0px 10px 50px 3px rgba(1,31,64,0.59)",
    background: "#F7F9FC",
    borderRadius: 10,
  },
  "& .popperContainer": {
    width: 324,
    height: "auto",
    minHeight: 225,
    padding: 12,
  },
  "& .popperHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 58,
    padding: 8,
  },
  "& .popperTitle": {
    fontSize: 26,
    color: colors.primaryDark,
  },
  "& .closePopperIcon": {
    color: "#C8C8C8",
    width: 20,
    height: 20,
    position: "absolute",
    top: 10,
    right: 11,
    transition: "all 250ms",
    "&:hover": {
      cursor: "pointer",
      color: "#ffb4b4",
    },
  },
  "& .popperContent": {
    padding: 8,
    width: "100%",
    maxWidth: "100%",
  },
  "& .divider": {
    width: "100%",
    height: 1,
    background: "#D2DFEC",
  },
  "& .addNewTagContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
  },
  "& .autocompleteTagWrapper": {
    width: "100%",
  },
  "& .addNewTagButton": {
    background: "#3B84B1",
    width: 36,
    color: "white",
    fontSize: "28px",
    minWidth: "36px",
    "&:hover":{
      background: "#3B84B1",
    }
  },
  "& .addNewTagButtonDissabled": {
    background: "#ccc",
  },
  "& .assignedTagsContainer": {
    display: "flex",
    alignItems: "center",
    padding: "12px 0px",
    width: "100%",
    height: "auto",
    flexFlow: "row wrap",
  },
  "& .tagContent": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#E1E5E8",
    marginRight: 10,
    marginBottom: 10,
    height: 20,
    padding: 4,
  },
  "& .tagName": {
    fontSize: 12,
    color: colors.darkGray,
  },
  "& .deleteTagContainer": {
    color: colors.darkGray,
    display: "flex",
    alignItems: "center",
    margin: "0px 4px",
  },
  "& .deleteTagIcon": {
    color: colors.darkGray,
    width: 14,
    height: 14,
    transition: "all 250ms",
    "&:hover": {
      cursor: "pointer",
      color: "#ffb4b4",
    },
  },
  "& .infoText": {
    textAlign: "right",
    fontSize: 11,
    fontFamily: "Arial",
    fontWeight: 400,
    color: colors.darkGray,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .infoIcon": {
      color: "#B2CBEA",
      height: 11,
      width: 11,
    },
  },
  "& .automaticTagsContainer": {
    display: "flex",
    alignItems: "flex-start",
    padding: "12px 0px",
    width: "100%",
    height: "auto",
    flexFlow: "column",
    "& .divider": {
      width: "80%",
      height: 1,
      background: "#D0DCEA",
      margin: "7px auto 15px auto",
    },
  },
}));

export const TagLabelStyled = styled("div")(() => ({
  "&.tagContainer": {
    width: 18,
    height: 18,
    margin: "0px 6px 0px 0px",
    position: "relative",
    top: 2,
  },
  "& .showTags": {
    margin: "0px 4px",
    opacity: 1,
    "&:hover": {
      opacity: 1,
    },
  },
  "& .hideShowTags": {
    margin: "0px 4px",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
}));

export const AttentionModalStyled = styled(Modal)(() => ({
  "&.rootModal": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .attentionModalPaper": {
      backgroundColor: "#fff",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 50%)",
      padding: 16,
      width: 500,
      height: 287,
      border: "1px solid rgba(0, 0, 0, 0.2)",
      borderRadius: "0.3rem",
    },
    "& .attentionModalHeader": {
      width: "100%",
      height: "20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 22,
      color: "#ff5f58",
      fontWeight: 700,
    },
    "& .attentionModalBody": {
      width: "100%",
      height: "50%",
      fontSize: 17,
      color: colors.primaryDark,
      padding: "16px 10px",
      textAlign: "center",
    },
    "& .attentionModalBodyFileProcessor": {
      width: "100%",
      height: "50%",
      fontSize: 17,
      color: colors.primaryDark,
      padding: "16px 10px",
      textAlign: "center",
      "& .switch": {
        minWidth: "120px !Important",
        height: 24,
        "& .round": {
          background: colors.primaryDark,
          color: colors.primaryDark,
          "&::before": {
            width: 76,
            left: 4,
            height: 18,
            fontSize: 11,
          },
        },
      },
    },
    "& .attentionModalFooter": {
      width: "100%",
      height: "30%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .buttonExpanded": {
        height: "54px",
        width: "219px",
        background: "#39f",
        margin: "0px 12px",
        fontWeight: 700,
        color: "#FFF",
      },
      "& .buttonConfirmationBlue": {
        height: "54px",
        width: "197px",
        background: "#39f",
        margin: "0px 12px",
        fontWeight: 700,
        color: "#FFF",
      },
      "& .buttonConfirmationWhite": {
        height: "54px",
        width: "197px",
        background: "rgba(168,182,200,0.15)",
        margin: "0px 12px",
        fontWeight: 700,
        color: colors.primaryDark,
      },
      "& .buttonCancelBlue": {
        height: "54px",
        width: "119px",
        background: "#39f",
        margin: "0px 12px",
        fontWeight: 700,
        color: "#FFF",
      },
      "& .buttonCancelWhite": {
        height: "54px",
        width: "119px",
        background: "rgba(168,182,200,0.15)",
        margin: "0px 12px",
        fontWeight: 700,
        color: colors.primaryDark,
      },
    },
  },
  "&.modalPermission": {
    width: "auto",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .attentionModalPaper": {
      backgroundColor: "#fff",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 50%)",
      border: "1px solid rgba(0, 0, 0, 0.2)",
      borderRadius: "0.3rem",
      maxHeight: "calc(100vh - 111px)",
      width: 700,
    },
    "& .attentionModalHeader": {
      width: "100%",
      height: "20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 22,
      color: "#ff5f58",
      fontWeight: 700,
      paddingTop: 44,
    },
    "& .permissionTable": {
      width: "100%",
      height: "50%",
      fontSize: 17,
      color: "#25486d",
      padding: "16px 3px",
      textAlign: "center",
      "& .attentionText": {
        padding: "0px 70px",
        marginBottom: 10,
      },
      "& :hover": {
        "& .MuiTableContainer-root": {
          "&::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            boxShadow: "inset 0 0 10px 10px #B1B1B1",
            border: "solid 5px transparent",
          },
        },
      },
      "& .MuiTableContainer-root": {
        maxHeight: "calc(100vh - 335px)",
        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: 17,
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          background: "transparent",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
      },
    },
    "& .tablePermission ": {
      marginTop: 20,
      "& .MuiTableCell-body": {
        padding: 0,
        border: 0,
        fontWeight: 400,
        height: 30,
        color: colors.primaryDark,
        fontSize: 17,
        "& .userData": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "3px 0px",
        },
      },
    },
    "& .attentionModalFooter": {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row-reverse",
      padding: "0px 58px 43px 58px",
      "& .buttonConfirmationBlue": {
        padding: "11px 24px",
        width: "auto",
        height: "auto",
        background: "#FFFFFF",
        border: "1px solid #8D9CB1",
        borderRadius: 5,
        color: colors.primaryDark,
        fontSize: 14,
      },
      "& .buttonCancelWhite": {
        padding: "11px 24px",
        width: "auto",
        height: "auto",
        background: "#218CFF",
        borderRadius: 5,
        color: "white",
        fontSize: 14,
      },
    },
  },
}));

export const CustomModalBoxStyled = styled(Box)(() => ({
  "&.customModalContainer": {},
  "& .closeModal": {
    color: "#25486d",
    position: "absolute",
    top: 6,
    right: 6,
    "&:hover": {
      background: "#e0e0e0",
      borderRadius: 5,
      cursor: "pointer",
    },
  },
  "& .modalHeader": {
    height: 28,
    fontWeight: 500,
  },
  "& .modalBody": {
    height: "auto",
    width: "auto",
    display: "flex",
    flexDirection: "column",
  },
  "& .modalFooter": {},
}));
