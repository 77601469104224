import React from "react";
import tradeDashLogo from "../../assets/flag-icons/tradedashFullLogo.png";
import { CircularProgress } from "@mui/material";

// import { loaderStyles } from "./styles";

const defaultStyles = {
  width: "100%",
  justifyContent: "center",
  display: "flex",
  zIndex: 1000,
  background: "transparent",
  opacity: 1,
  height: "100%",
  position: "absolute",
  alignItems: "center",
};

function Loader({ style = {}, circularProgressStyles = {} }) {
  return (
    <div
      style={{ ...defaultStyles, ...style }}
      className="tradedash-main-logo-to-animate"
    >
      <img
        src={tradeDashLogo}
        alt="tradedash-logo"
        style={{
          width: 40,
          height: 40,
          position: "absolute",
          background: "transparent",
        }}
      />
      <CircularProgress
        variant="indeterminate"
        style={{
          width: 60,
          height: 60,
          ...circularProgressStyles,
          color: "#5D92F4",
        }}
        thickness={4}
        disableShrink
      />
    </div>
  );
}

export default Loader;
