import { screenHeight } from "../../helpers/constants";
import { styled } from "@mui/material";

export const FactoryViewStyled = styled("div")(() => ({
  "&.factoryViewContainer": {
    height: screenHeight,
    overflow: "auto",
    position: "relative",
    zIndex: 0,
    margin: 16,
    "& .contentContainer": {
      display: "flex",
      height: "100%",
      overflowY: "auto",
      width: "calc(100vw - 480px)",
    },
  },
}));
