import React, { useState } from "react";
import { useSelector } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
// import UserDrawer from "../../container/DrawerContainer/UserDrawer";
import { getUserAvatar } from "../../helpers/users";
import { useUser } from "../../hooks/user";
import UserDrawer from "../../container/DrawerContainer/UserDrawer";
import { Badge } from "@mui/material";

const PageTitleBar = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useUser();
  const notifications = useSelector(
    (state) => state.data[dbTables.NOTIFICATIONS][user.id] || []
  );
  const numNotifications = notifications.filter(
    (notification) => notification.newNotification
  ).length;
  return (
    <div className="page-title d-flex justify-content-between align-items-center">
      <div
        style={{
          display: "flex",
          height: 66,
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 24px 0px 21px",
        }}
      >
        {title && (
          <div className="page-title-wrap">
            <h2 style={{ fontSize: 20 }} className="page-title-text">
              {title}
            </h2>
          </div>
        )}
        <div
          className="tradedash-logo"
          onClick={(ev) => {
            setIsOpen(true);
            ev.stopPropagation();
          }}
        >
          <Badge
            badgeContent={numNotifications}
            max={9}
            classes={{ badge: "notification-badge" }}
            overlap="circular"
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            {getUserAvatar({
              user,
              styles: {
                width: 50,
                height: 50,
                fontSize: 26,
                margin: "auto",
                cursor: "pointer",
                outline: "3px solid #000",
              },
            })}
          </Badge>
        </div>
        {isOpen && (
          <UserDrawer
            open={isOpen}
            handleIsOpen={(value) => {
              setIsOpen(value);
            }}
            user={user}
          />
        )}
      </div>
    </div>
  );
};

// default props value
PageTitleBar.defaultProps = {
  enableBreadCrumb: true,
};

export default PageTitleBar;
