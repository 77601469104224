import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isTradeDashEmployee, sortObjectsBy } from "../../helpers/helpers";
import { dbTables } from "../../api/types/dbTables";
import CustomButton from "../Buttons/CustomButton";
import { useUser } from "../../hooks/user";
import CreateReport from "../Reports/CreateReport";
import DataTable from "../DataTable/DataTable";
import IntlMessages from "../../util/IntlMessages";
import Report from "../../api/model/Report";
import { firestore } from "../../firebase";
import TooltipTD from "../Tooltip/TooltipTD";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import PendingDivider from "../Dividers/PendingDivider";
import { useParams } from "react-router-dom";
import { Switch, TableCell, TableRow } from "@mui/material";

const tableheader = [
  {
    id: 1,
    label: "generic.textForm.name",
    styles: {
      width: "50%",
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
  {
    id: 2,
    label: "generic.text.description",
    styles: {
      width: "15%",
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
  {
    id: 3,
    label: "column.settings.reportEmbed",
    onlyTDEmployee: true,
    styles: {
      width: "15%",
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
  {
    id: 4,
    label: "column.settings.loomEmbed",
    onlyTDEmployee: true,
    styles: {
      width: "15%",
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
  {
    id: 5,
    label: "generic.text.active",
    styles: {
      width: "5%",
      zIndex: 1,
      fontSize: "0.875rem",
    },
  },
];

function Reports() {
  const { companyId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const user = useUser();
  const [isUpdating, setIsUpdating] = useState(false);
  const [report, setReport] = useState({});
  const reports =
    useSelector((state) => state.data[dbTables.REPORTS][companyId]) || [];
  const cellClass = "noHover";

  function handleAddReport() {
    setReport({ ...new Report({}) });
    setIsUpdating(false);
    setOpenModal(true);
  }

  async function handleSubmit({ currentReport }) {
    setDoc(
      doc(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.REPORTS}/${currentReport.id}`
      ),
      { ...currentReport }
    );
    setOpenModal(false);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  const theadDisplay = tableheader.map((thead) => {
    if (thead.onlyTDEmployee) {
      if (isTradeDashEmployee(user)) {
        return (
          <TableCell key={thead.id} style={thead.styles}>
            <IntlMessages id={thead.label} />
          </TableCell>
        );
      }
      return null;
    } else {
      return (
        <TableCell key={thead.id} style={thead.styles}>
          <IntlMessages id={thead.label} />
        </TableCell>
      );
    }
  });

  function getRow({ report = {} }) {
    return (
      <TableRow
        key={report.id}
        className={"noHover"}
        onClick={() => {
          setReport({ ...new Report({ ...report }) });
          setIsUpdating(true);
          setOpenModal(true);
        }}
        style={{
          fontSize: "0.875rem",
        }}
      >
        <TableCell
          className={cellClass}
          style={{
            fontSize: "0.875rem",
          }}
        >
          {report.name}
        </TableCell>
        <TableCell
          className={cellClass}
          style={{
            fontSize: "0.875rem",
          }}
        >
          <TooltipTD
            label={report.description}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {report.description}
          </TooltipTD>
        </TableCell>
        {isTradeDashEmployee(user) && (
          <TableCell
            className={cellClass}
            style={{
              fontSize: "0.875rem",
            }}
          >
            {report.reportEmbed}
          </TableCell>
        )}
        {isTradeDashEmployee(user) && (
          <TableCell
            className={cellClass}
            style={{
              fontSize: "0.875rem",
            }}
          >
            {report.loomEmbed}
          </TableCell>
        )}
        <TableCell
          className={cellClass}
          style={{
            fontSize: "0.875rem",
          }}
        >
          <Switch
            checked={report.active}
            onChange={(ev, checked) => {
              updateDoc(report.ref, {
                active: checked,
              });
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onClick={(ev) => ev.stopPropagation()}
            value={report.active}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
      </TableRow>
    );
  }

  const activeReports = reports
    .filter((report) => report.active)
    .sort(sortObjectsBy("name", false))
    .map((report) => getRow({ report }));

  const inactiveReports = reports
    .filter((report) => !report.active)
    .sort(sortObjectsBy("name", false))
    .map((report) => getRow({ report }));

  const isThereInactiveReport = reports.some((report) => !report.active);
  return (
    <div
      className="settings-reports-container"
      style={{
        overflow: "scroll",
      }}
    >
      {isTradeDashEmployee(user) && (
        <div className="action-buttons-container">
          <CustomButton type="create" onClick={handleAddReport}>
            {"companytabs.user.button.addReport"}
          </CustomButton>
        </div>
      )}
      {openModal && (
        <CreateReport
          openModal={openModal}
          handleClose={handleCloseModal}
          isUpdating={isUpdating}
          report={report}
          onSubmit={handleSubmit}
          user={user}
        />
      )}

      <DataTable header={theadDisplay} className="row-hover-over">
        {activeReports}
        {isThereInactiveReport && (
          <TableRow key={"deactivated-access-groups"}>
            <TableCell colSpan={3} className={cellClass}>
              <PendingDivider
                text={"Deactivated Reports"}
                containerStyle={{ padding: "32px 0px" }}
                style={{ left: 80, right: "auto" }}
                firstLineStyles={{
                  background: "#e5edf7",
                }}
                lineNameStyles={{
                  color: "#000",
                  fontSize: 24,
                  fontWeight: 400,
                }}
              />
            </TableCell>
          </TableRow>
        )}
        {inactiveReports}
      </DataTable>
    </div>
  );
}

export default Reports;
