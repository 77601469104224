import React from "react";
import moment from "moment";
import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

const HeaderDateStyled = styled("div")(() => ({
  "&.dateSeparator": {
    padding: 8,
    backgroundColor: colors.primaryDark,
    color: "white",
    width: 260,
    fontSize: 14,
    fontWeight: "bold",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .dateMonth": {
    display: "flex",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "8px",
  },
  "& .dateQuant": {
    borderLeft: "1px solid #4b729b",
    float: "right",
    paddingLeft: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
  },
}));

function HeaderTableDate({ week, type = "Sales Orders" }) {
  return (
    <HeaderDateStyled className="dateSeparator" id="dateSeparator">
      <span className={"dateMonth"}>
        {`${moment(week.start).format("MMM D")} to ${moment(week.end).format(
          "MMM DD"
        )}`}
      </span>
      <span className={"dateQuant"}>{`${week.list.length} ${type}`}</span>
    </HeaderDateStyled>
  );
}

export default HeaderTableDate;
