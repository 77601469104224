import React from "react";
import AutocompleteUsers from "../TextFields/AutocompleteUsers";
import { sortObjectsBy } from "../../helpers/helpers";
import {
  removePermissionGroup,
  addAllUsersToPermissionGroup,
  addPermissionGroupToUser,
  removePermissionGroupFromUser,
} from "../../helpers/permissionGroups";
import PendingDivider from "../Dividers/PendingDivider";
import { updateDoc } from "firebase/firestore";
import { Switch } from "@mui/material";
import AutoCompleteUser from "../../api/model/AutoCompleteUser.model";

function TypeItem({
  permissionGroup,
  handleUpdateName,
  disabled = false,
  handleReadOnlyModal = () => {},
  isTheFirstInactiveItem = false,
  companyUsers = [],
}) {
  function onChange(userArray, lastUserArray) {
    if (disabled) {
      handleReadOnlyModal();
      return;
    }
    if (!userArray) {
      removePermissionGroup(permissionGroup, companyUsers);
      return;
    }
    if (userArray === "all") {
      addAllUsersToPermissionGroup(permissionGroup, companyUsers);
      return;
    }
    if (userArray.length > lastUserArray.length) {
      addPermissionGroupToUser(userArray, lastUserArray, permissionGroup);
    } else {
      removePermissionGroupFromUser(userArray, lastUserArray, permissionGroup);
    }
    const newUsers = {};
    userArray.forEach((user) => (newUsers[user.id] = true));
    updateDoc(permissionGroup.ref, {
      users: newUsers,
    });
  }

  function getAllGroupPermissionUsers(users, companyUsers) {
    const permissionGroups = [];
    Object.keys(users).forEach((key) => {
      const realUser = companyUsers.find((item) => item.id === key);
      if (realUser) {
        permissionGroups.push(realUser);
      }
    });
    return permissionGroups.sort(sortObjectsBy("displayName", false));
  }

  return (
    <React.Fragment>
      {isTheFirstInactiveItem && (
        <PendingDivider
          text={"Deactivated Access Groups"}
          containerStyle={{ padding: "32px 0px" }}
          style={{ left: 80, right: "auto" }}
          firstLineStyles={{
            background: "#e5edf7",
          }}
          lineNameStyles={{ color: "#000", fontSize: 24, fontWeight: 400 }}
        />
      )}

      <li className="permission-group" style={{ position: "relative" }}>
        <div
          className="type-label"
          onClick={() => handleUpdateName(permissionGroup)}
        >
          {permissionGroup.name}
        </div>
        <div className="permission-group-content">
          <div className="add-remove-all-options">
            <div
              onClick={() => onChange("all")}
              style={{
                margin: "0 8px",
                fontSize: 14,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Add all
            </div>
            <div
              style={{
                margin: "0 8px",
                fontSize: 14,
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => onChange(null)}
            >
              Remove all
            </div>

            <Switch
              checked={!permissionGroup.isInactive}
              onChange={() => {
                updateDoc(permissionGroup.ref, {
                  isInactive: !permissionGroup.isInactive,
                });
              }}
              color="primary"
            />
          </div>
          <div className="autocomplete-users-contenainer-options">
            <AutocompleteUsers
              options={companyUsers
                .sort(sortObjectsBy("displayName", false))
                .map((user) => ({
                  ...new AutoCompleteUser({
                    value: user.id,
                    label: user.displayName,
                    avatar: user.avatar,
                    firstName: user.firstName,
                    lastName: user.lastName,
                  }),
                }))}
              onChange={onChange}
              value={getAllGroupPermissionUsers(
                permissionGroup.users,
                companyUsers
              ).map((user) => ({
                ...new AutoCompleteUser({
                  value: user.id,
                  label: user.displayName,
                  avatar: user.avatar,
                  firstName: user.firstName,
                  lastName: user.lastName,
                }),
              }))}
              focused={true}
              classes={{
                input: "input",
                inputFocused: "input-focused",
                paper: "paper",
                chip: "chip",
                deleteIcon: "delete",
              }}
            />
          </div>
        </div>
      </li>
    </React.Fragment>
  );
}

export default TypeItem;
