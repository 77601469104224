import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Popover, styled } from "@mui/material";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const CalendarStyled = styled("div")(() => ({
  "&.calendarContainer": {
    display: "flex",
    flexFlow: "column nowrap",
    width: "fit-content",
    margin: "0px 8px",
    "& .rdrCalendarWrapper": {
      "& button": {
        minWidth: "0px",
        minHeight: "0px",
        margin: "0px",
      },
    },
    "& .rdrMonth": {
      width: 250,
      "& .rdrDayWeekend": {
        backgroundColor: "#f5f5f5",
        borderRadius: 0,
      },
    },
    "& .rdrMonthAndYearWrapper": {
      height: 36,
      paddingTop: 0,
      paddingBottom: 10,
    },
  },
}));

function DatePicker({
  value,
  el,
  open,
  onClose = () => {},
  onChange = () => {},
  onKeyDown,
  cancelIcon,
}) {
  return (
    <Popover
      anchorEl={el}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      classes={{ paper: "date-picker" }}
      open={open}
      onClose={() => onClose()}
      onKeyDown={onKeyDown}
    >
      {cancelIcon && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 5px 0px 0px",
          }}
        >
          <CloseIcon
            style={{
              width: 16,
              height: 16,
              color: "#FA9917",
              cursor: "pointer",
            }}
            onClick={() => onClose()}
          />
        </div>
      )}

      <CalendarStyled className="calendarContainer">
        <Calendar
          onChange={(item) => {
            onChange(moment(item).valueOf());
          }}
          date={value ? moment(value).toDate() : null}
          focusedRange={[0, 0]}
          months={1}
          color="#FA9917"
        />
      </CalendarStyled>
    </Popover>
  );
}

DatePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
