import React from "react";
import excel from "../../assets/flag-icons/excel.svg";
import pdf from "../../assets/flag-icons/pdf.svg";
import word from "../../assets/flag-icons/word.svg";
import powerPoint from "../../assets/flag-icons/power-point.svg";
import csv from "../../assets/flag-icons/csv.svg";
import png from "../../assets/flag-icons/png.svg";
import jpeg from "../../assets/flag-icons/jpeg-icon.svg";
import photoshop from "../../assets/flag-icons/photoshop.svg";
import adobe from "../../assets/flag-icons/adobe.svg";
import defaultFile from "../../assets/flag-icons/defaultFile.png";
import filePlaceholder from "../../assets/flag-icons/file-placeholder.png";
import folder from "../../assets/flag-icons/folder-placeholder.svg";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { documentScope } from "../../helpers/documents";
import { cx } from "@emotion/css";
import { TypeFileSelectorStyled } from "./styles";

function TypeFileSelector({
  className,
  version,
  type = "",
  imgStyles = {},
  currentDocument = {},
}) {
  const getFileTypeIcon = () => {
    const parseType = type.toLowerCase();
    let typeFile = "";
    switch (parseType) {
      case "odt":
      case "doc":
      case "docx":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconExcel" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={word}
            alt="word"
          />
        );
        break;
      case "csv":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconExcel" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={csv}
            alt="csv"
          />
        );
        break;
      case "xls":
      case "xlsx":
      case "xlsm":
      case "ods":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconExcel" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={excel}
            alt="excel"
          />
        );
        break;
      case "pdf":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPdf" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={pdf}
            alt="pdf"
          />
        );
        break;
      case "pptx":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPdf" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={powerPoint}
            alt="power-point"
          />
        );
        break;
      case "png":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPdf" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={png}
            alt="png"
          />
        );
        break;
      case "jpeg":
      case "jpg":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPdf" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={jpeg}
            alt="jpeg"
          />
        );
        break;
      case "psd":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPdf" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={photoshop}
            alt="psd"
          />
        );
        break;
      case "ai":
      case "cdr":
      case "svg":
      case "eps":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPdf" : "imgListItemSmallIconExcel"
            }
            style={imgStyles}
            src={adobe}
            alt="adobe"
          />
        );
        break;
      case "file":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPlaceholder" : "imgListItemSmallIcon"
            }
            style={imgStyles}
            src={filePlaceholder}
            alt="file-placeholder"
          />
        );
        break;
      case TYPE_OF_FILE.FOLDER:
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPlaceholder" : "imgListItemSmallIcon"
            }
            style={imgStyles}
            src={folder}
            alt="file-placeholder"
          />
        );
        break;
      default:
        typeFile = typeFile = (
          <img
            className={mainFile ? "imgListItemIcon" : "imgListItemSmallIcon"}
            style={imgStyles}
            src={defaultFile}
            alt="df"
          />
        );
        break;
    }
    return typeFile;
  };

  const mainFile = currentDocument.mainFile || currentDocument.placeholder;

  function getVersionBadgetyles(currentDocument = {}) {
    switch (currentDocument.scope) {
      case documentScope.SALES_ORDER:
        return "SO";
      case documentScope.PURCHASE_ORDER:
        return "PO";
      case documentScope.SHIPMENT:
        return "SH";
      default:
        return "WHITE";
    }
  }

  return (
    <TypeFileSelectorStyled className="typeFileSelectorContainer">
      {mainFile ? (
        version > 1 && (
          <label
            className={cx(
              className,
              `versionSquare${getVersionBadgetyles(currentDocument)}`
            )}
          >
            V{version}{" "}
          </label>
        )
      ) : (
        <label
          className={cx(
            className,
            `versionSquare${getVersionBadgetyles(currentDocument)}`
          )}
        >
          V{version}{" "}
        </label>
      )}
      {getFileTypeIcon()}
    </TypeFileSelectorStyled>
  );
}

export default TypeFileSelector;
