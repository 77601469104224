import React, { useState, useEffect, useCallback } from "react";
import { SearchLabelStyled } from "../Inputs/styles";
import { useStorage } from "../../hooks";
import CancelIcon from "@mui/icons-material/Cancel";
import { debounce } from "../../helpers/helpers";
import { SEARCH_BOX_TYPES } from "../../helpers/orderDashboard";
import { MagnifyingGlassIcon } from "../../helpers/iconSvgPath";

function SearchLabel({
  placeholderOnExpand,
  cookie,
  returnQuery = () => {},
  cleanSearchBox = false,
  onDisableCleanSearchBox,
  styles = {},
  storageField = "activitysearch",
  inputStyle = {},
  searchLabelId = "search-label-customized",
  type = SEARCH_BOX_TYPES.BASIC,
}) {
  const [storage, setStorage] = useStorage(storageField);
  const [query, setQuery] = useState(
    storage[cookie] ? storage[cookie].search : ""
  );

  const onDebounce = useCallback(
    debounce((value) => {
      returnQuery(value);
    }, 1250),
    []
  );

  useEffect(() => {
    if (cleanSearchBox) {
      const persitence = storage[cookie] || {};
      setQuery("");
      if (cookie) {
        setStorage(cookie, { ...persitence, search: "" }, { maxAge: 84600 });
      }

      onDisableCleanSearchBox();
      onDebounce("");
    }
  }, [cleanSearchBox]);

  const SEARCH_BOX_ACTIVE_CLASSES = {
    SKU_TABLE: "searchBoxActiveSKUTable",
    NOTES: "searchBoxActiveNotes",
    DOCUMENTS: "searchBoxActiveDocuments",
    NOTE_DOCUMENTS: "searchBoxActiveNoteDocuments",
    BASIC: "searchBoxActive",
  };

  const SEARCH_BOX_CLASSES = {
    SKU_TABLE: "searchBoxSKUTable",
    BASIC: "searchBox",
    NOTES: "searchBoxNotes",
    DOCUMENTS: "searchBoxDocuments",
    NOTE_DOCUMENTS: "searchBoxNoteDocuments",
  };

  return (
    <SearchLabelStyled
      className={
        query ? SEARCH_BOX_ACTIVE_CLASSES[type] : SEARCH_BOX_CLASSES[type]
      }
      style={{ ...styles }}
      onClick={() => {
        if (type === SEARCH_BOX_TYPES.SKU_TABLE) {
          document.getElementById(searchLabelId).focus();
        }
      }}
    >
        <input
          id={searchLabelId}
          value={query}
          onChange={(ev) => {
            const persitence = storage[cookie] || {};
            setQuery(ev.target.value);
            if (cookie) {
              setStorage(
                cookie,
                { ...persitence, search: ev.target.value },
                { maxAge: 84600 }
              );
            }
            onDebounce(ev.target.value);
          }}
          autoFocus={false}
          placeholder={placeholderOnExpand || "search"}
          className={"input"}
          style={inputStyle}
        />

      {query ? (
        <CancelIcon
          className={"buttonX"}
          onClick={() => {
            const persitence = storage[cookie] || {};
            setQuery("");
            if (cookie) {
              setStorage(
                cookie,
                { ...persitence, search: "" },
                { maxAge: 84600 }
              );
            }
            onDebounce("");
            document.getElementById(searchLabelId).focus();
          }}
        />
      ) : (
        <MagnifyingGlassIcon
          size={15}
          fill={"transparent"}
          onClick={() => {
            document.getElementById(searchLabelId).focus();
          }}
        />
      )}
    </SearchLabelStyled>
  );
}

export default SearchLabel;
