import {
  formatCash,
  formatWithComma,
  getCustomerName,
  getShipmentDestination,
} from "./helpers";
import {
  COMPANY_PERMISSION_VALUE,
  generalPermissions,
  GENERAL_PERMISSION_VALUE,
  itemTableReference,
} from "./constants";
import {
  getCartons,
  getExtendedCostPrice,
  getTotalCBM,
  getTotalWeight,
  parseNumberValue,
} from "./orderDashboardRefactored";
import { colors } from "../assets/jss/variables";
import { getEntityByField } from "./orderDashboard";

export const columnsSOShipmentTable = [
  {
    id: "itemNumber",
    label: "Item Number",
    sort: true,
    itemRedirect: true,
    style: {
      minWidth: 130,
      maxWidth: 130,
      width: 130,
      height: 40,
      padding: "0px 35px 0px 15px",
    },
    headStyle: {
      padding: "0px 30px 0px 15px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    rowStyle: { textAlign: "right" },
    tooltipStyle: {
      textDecoration: "underline",
      cursor: "pointer",
    },
    arrowStyle: {
      left: 102,
    },
  },
  {
    id: "image",
    label: "Image",
    type: "image",
    style: {
      minWidth: 100,
      maxWidth: 100,
      width: 100,
      textAlign: "left",
      height: 40,
      padding: "0px 15px 0px 5px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  {
    id: "description",
    label: "Description",
    sort: true,
    showToolTip: true,
    headStyle: {
      padding: "0px 0px 0px 10px",
    },
    style: {
      width: 502,
      textAlign: "left",
      paddingRight: 30,
      height: 40,
      minWidth: 502,
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    arrowStyle: {
      left: 86,
    },
  },
  {
    id: "salesOrderQuantity",
    label: "Order Quantity",
    sort: true,
    danger: true,
    reference: itemTableReference.MAIN_SALES_ORDER,
    companyPermission: COMPANY_PERMISSION_VALUE.SALES_ORDER_QUANTITY,
    orderQuantityCheck:
      "This item quantity does not match the quantity in the PO",
    style: {
      minWidth: 130,
      maxWidth: 130,
      width: 130,
      height: 40,
      padding: "0px 7px",
    },
    headStyle: {
      padding: "0px 15px 0px 48px",
    },
    rowStyle: { textAlign: "right", paddingRight: 12, fontWeight: 700 },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    isEditable: true,
    complexValue: (row, item = {}) => {
      const salesOrderQuantity = row.salesOrderQuantity || {};
      const salesOrderId = item.currentSalesOrderId || "";
      if (!salesOrderQuantity) {
        return 0;
      }
      return salesOrderQuantity[salesOrderId] || 0;
    },
    format: ({ value }) => formatWithComma(value),
    arrowStyle: {
      left: 105,
    },
  },
  {
    id: "salesOrderPrice",
    sort: true,
    label: "Price",
    type: "cash",
    isEditable: true,
    reference: itemTableReference.MAIN_SALES_ORDER,
    companyPermission: COMPANY_PERMISSION_VALUE.SALES_ORDER_PRICE,
    style: {
      minWidth: 120,
      maxWidth: 120,
      width: 120,
      textAlign: "right",
      height: 40,
      padding: "0px 7px",
    },
    rowStyle: { fontWeight: 700 },
    headStyle: {
      padding: "0px 25px 0px 10px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    complexValue: (row, item) => {
      const salesOrderQuantity = row.salesOrderPrice || {};
      const salesOrderId = item.currentSalesOrderId || "";
      return parseNumberValue(salesOrderQuantity[salesOrderId]);
    },
    format: ({ value }) => formatCash(value),
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
    arrowStyle: {
      left: 100,
    },
  },
  {
    id: "extendedPrice",
    label: "Extented Price",
    sort: true,
    complexValue: (row, item) => {
      const salesOrderQuantity = row.salesOrderQuantity || {};
      const salesOrderId = item.currentSalesOrderId || "";
      return getExtendedCostPrice({
        cost: row.salesOrderPrice[salesOrderId],
        quantity: salesOrderQuantity[salesOrderId],
      });
    },
    type: "cash",
    style: {
      minWidth: 155,
      maxWidth: 155,
      width: 155,
      textAlign: "right",
      height: 40,
      padding: "0px 25px 0px 42px",
    },
    headStyle: {
      padding: "0px 5px 0px 74px",
      textAlign: "left",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    arrowStyle: {
      left: 133,
    },
    format: ({ value }) => formatCash(value),
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    id: "cbmMaster",
    label: "CBM/ Master",
    sort: true,
    format: ({ value }) => parseFloat(value).toFixed(2),
    style: {
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      height: 40,
      padding: "0px 15px 0px 10px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    headStyle: {
      padding: "0px 10px 0px 40px",
    },
    arrowStyle: {
      left: 88,
    },
  },
  {
    id: "piecesPerMaster",
    label: `Pieces \u00A0\u00A0\u00A0 per Master`,
    sort: true,
    style: {
      minWidth: 108,
      maxWidth: 108,
      width: 108,
      textAlign: "right",
      height: 40,
      padding: "0px 11px 0px 0px",
    },
    headStyle: {
      textAlign: "left",
      padding: "0px 2px 0px 30px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    arrowStyle: {
      left: 98,
    },
  },
  {
    id: "cartons",
    label: "Cartons",
    sort: true,
    complexValue: (item) => {
      const salesOrderId = item.currentSalesOrderId || "";
      return getCartons({
        pieces: item.piecesPerMaster,
        quantity: item.salesOrderQuantity[salesOrderId],
      });
    },
    checkRound: true,
    style: {
      minWidth: 115,
      width: 115,
      maxWidth: 115,
      height: 40,
      padding: "0px 17px 0px 17px",
    },
    headStyle: {
      textAlign: "right",
      padding: "0px 17px 0px 15px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    arrowStyle: {
      left: 102,
    },
  },
  {
    id: "totalCbm",
    label: "Total CBM",
    sort: true,
    headStyle: {
      padding: "0px 7px 0px 68px",
    },
    style: {
      minWidth: 115,
      maxWidth: 115,
      width: 105,
      height: 40,
      padding: "0px 15px 0px 0px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    arrowStyle: {
      left: 103,
    },
    complexValue: (row) => {
      const salesOrderId = row.currentSalesOrderId || "";
      const salesOrderQuantity = row.salesOrderQuantity || {};
      return getTotalCBM({
        cbm: row.cbmMaster,
        pieces: row.piecesPerMaster,
        quantity: salesOrderQuantity[salesOrderId],
      });
    },
  },
  {
    id: "",
    label: "",
    style: {
      width: "100%",
      height: 40,
    },
  },
];
// shipment Columns
export const columnsShipmentTable = [
  {
    id: "itemNumber",
    label: "Item Number",
    sort: true,
    style: {
      height: 40,
      minWidth: 120,
      maxWidth: 120,
      width: 120,
      padding: "0px 15px 0px 19px",
    },
    headStyle: {
      padding: "0px 25px 0px 18px",
    },
    rowStyle: { textAlign: "right" },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    arrowStyle: {
      left: 74,
    },
  },
  {
    id: "image",
    label: "Image",
    type: "image",
    style: {
      textAlign: "left",
      height: 40,
      minWidth: 100,
      maxWidth: 100,
      width: 100,
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  {
    id: "purchaseOrderId",
    label: "PO/PQ",
    sort: true,
    showToolTip: true,
    poRedirect: true,
    tooltipText: ({ item = {}, purchaseOrders = [], factories = [] }) => {
      const currentPO =
        purchaseOrders.find((po) => po.id === item.purchaseOrderId) || {};

      const currentFactory =
        factories.find((factory) => factory.id === currentPO.factoryId) || {};
      return currentFactory.name || "";
    },
    style: {
      height: 40,
      minWidth: 155,
      maxWidth: 155,
      width: 155,
    },
    headStyle: {
      padding: "0px 10px",
    },
    rowStyle: {
      textAlign: "left",
      paddingRight: 12,
      fontWeight: 700,
      textDecoration: "underLine",
    },

    complexValue: (row, item = {}, purchaseOrders) => {
      const currentPO =
        purchaseOrders.find((po) => po.id === item.purchaseOrderId) || {};
      return currentPO.number || "";
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
    },
    arrowStyle: {
      left: 42,
    },
  },
  {
    id: "description",
    label: "Description",
    showToolTip: true,
    sort: true,
    style: {
      textAlign: "left",
      paddingLeft: 10,
      height: 40,
      width: 295,
      minWidth: 295,
      maxWidth: 295,
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    headStyle: {
      textAlign: "left",
      width: 308,
      minWidth: 308,
    },
    arrowStyle: {
      left: 86,
    },
  },
  {
    id: "shipmentQuantity",
    label: "Shipment Quantity",
    reference: itemTableReference.MAIN_SHIPMENT,
    companyPermission: COMPANY_PERMISSION_VALUE.SHIPMENT_QUANTITY,
    permission: GENERAL_PERMISSION_VALUE.SHIPMENT_QUANTITY,
    sort: true,
    style: {
      height: 40,
      minWidth: 110,
      maxWidth: 110,
      width: 110,
    },
    headStyle: {
      padding: "0px 10px 0px 6px",
    },
    rowStyle: { textAlign: "right", paddingRight: 12, fontWeight: 700 },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    isEditable: true,
    canBeErase: true,
    complexValue: (row, item = {}) => {
      const shipmentQuantity = row.shipmentQuantity || {};
      const shipmentId = item.currentShipmentId || "";
      return parseNumberValue(shipmentQuantity[shipmentId]);
    },
    format: ({ value }) => formatWithComma(value),
    arrowStyle: {
      left: 87,
    },
  },
  {
    id: "unitCost",
    label: "Unit Cost",
    type: "cash",
    sort: true,
    style: {
      minWidth: 110,
      maxWidth: 110,
      width: 110,
      textAlign: "right",
      height: 40,
      padding: "0px 16px 0px 10px",
    },
    headStyle: {
      padding: "0px 5px 0px 51px",
      textAlign: "left",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    format: ({ value }) => formatCash(value),
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
    arrowStyle: {
      left: 98,
    },
  },
  {
    id: "extendedCost",
    label: "Extented Cost",
    sort: true,
    complexValue: (row, item) => {
      const shipmentQuantity = row.shipmentQuantity || {};
      const shipmentId = item.currentShipmentId || "";
      return getExtendedCostPrice({
        cost: row.unitCost,
        quantity: shipmentQuantity[shipmentId],
      });
    },
    showFormatValue: true,
    type: "cash",
    style: {
      minWidth: 140,
      maxWidth: 140,
      width: 140,
      textAlign: "right",
      height: 40,
      padding: "0px 17px 0px 0px",
    },
    headStyle: {
      textAlign: "left",
      padding: "0px 5px 0px 22px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    format: ({ value }) => formatCash(value),
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
    arrowStyle: {
      left: 128,
    },
  },
  {
    id: "cbmMaster",
    label: "CBM/ Master",
    sort: true,
    format: ({ value }) => parseFloat(value).toFixed(2),
    style: {
      minWidth: 115,
      maxWidth: 115,
      width: 115,
      height: 40,
      padding: "0px 17px 0px 0px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    headStyle: {
      padding: "0px 0px 0px 41px",
    },
    arrowStyle: {
      left: 102,
    },
  },
  {
    id: "piecesPerMaster",
    label: `Pieces per Master`,
    sort: true,
    style: {
      minWidth: 105,
      maxWidth: 105,
      width: 105,
      height: 40,
      padding: "0px 12px",
    },
    headStyle: {
      textAlign: "left",
      padding: "0px 9px 0px 13px",
    },

    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    arrowStyle: {
      left: 93,
    },
  },
  {
    id: "cartons",
    label: "Cartons",
    sort: true,
    checkRound: true,
    complexValue: (row, item) => {
      return getCartons({
        quantity: row.shipmentQuantity[item.currentShipmentId],
        pieces: row.piecesPerMaster,
      });
    },
    style: {
      minWidth: 85,
      maxWidth: 85,
      width: 85,
      height: 40,
      padding: "0px 5px 0px 10px",
    },
    rowStyle: {
      textAlign: "right",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
    },
    headStyle: {
      padding: "0px 17px 0px 0px",
      textAlign: "right",
    },
    arrowStyle: {
      left: 83,
    },
  },
  {
    id: "totalCbm",
    label: "Total CBM",
    sort: true,
    style: {
      minWidth: 100,
      maxWidth: 100,
      width: 100,
      height: 40,
      padding: "0px 5px 0px 5px",
    },
    rowStyle: {
      textAlign: "right",
    },
    headStyle: {
      padding: "0px 20px",
      textAlign: "right",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    complexValue: (row, item) => {
      return getTotalCBM({
        cbm: row.cbmMaster,
        pieces: row.piecesPerMaster,
        quantity: row.shipmentQuantity[item.currentShipmentId],
      });
    },
    arrowStyle: {
      left: 88,
    },
  },
  {
    id: "weight",
    label: "Weight",
    sort: true,
    style: {
      minWidth: 115,
      maxWidth: 115,
      width: 115,
      height: 40,
      padding: "0px 7px",
    },
    headStyle: {
      padding: "0px 20px",
      textAlign: "right",
    },
    rowStyle: {
      textAlign: "right",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    complexValue: (row, item) => {
      return getTotalWeight({
        weight: row.weight,
        pieces: row.piecesPerMaster,
        quantity: row.shipmentQuantity[item.currentShipmentId],
      });
    },
    arrowStyle: {
      left: 105,
    },
  },
  {
    id: "",
    label: "",
    style: {
      minWidth: 10,
      maxWidth: 10,
      width: 10,
      height: 40,
      padding: 0,
    },
    format: () => "",
  },
  {
    id: "",
    label: "",
    style: {
      minWidth: "100%",
      maxWidth: "100%",
      width: "100%",
      height: 40,
      padding: 0,
    },
  },
];

export const columnsPOShipmentTable = [
  {
    id: "itemNumber",
    label: "Item Number",
    sort: true,
    style: {
      minWidth: 110,
      maxWidth: 110,
      width: 110,
    },
    headStyle: {
      padding: "0px 15px 0px 30px",
      height: 51,
      boxShadow: `0 2px 0 ${colors.dividerColor}`,
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    rowStyle: { textAlign: "right" },
    arrowStyle: {
      left: 78,
    },
  },
  {
    id: "image",
    label: "Image",
    type: "image",
    style: {
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      textAlign: "left",
      padding: "0px 10px 0px 0px",
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    headStyle: {
      height: 51,
      boxShadow: `0 2px 0 ${colors.dividerColor}`,
    },
  },
  {
    id: "description",
    label: "Description",
    sort: true,
    showToolTip: true,
    headStyle: {
      textAlign: "left",
      height: 51,
      boxShadow: `0 2px 0 ${colors.dividerColor}`,
    },
    style: {
      width: "30%",
      textAlign: "left",
      padding: 0,
      height: 51,
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    arrowStyle: {
      left: 77,
    },
  },
  {
    id: "purchaseOrderQuantity",
    label: "Order Quantity",
    sort: true,
    isEditable: true,
    danger: true,
    reference: itemTableReference.ITEM,
    companyPermission: COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_QUANTITY,
    style: {
      minWidth: 100,
      maxWidth: 100,
      width: 100,
      padding: "0px 10px 0px 1px",
    },
    rowStyle: { fontWeight: 700 },
    headStyle: {
      padding: "0px 5px 0px 20px",
      height: 51,
      boxShadow: `0 2px 0 ${colors.dividerColor}`,
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    arrowStyle: {
      left: 77,
    },
    format: ({ value }) => formatWithComma(value),
    orderQuantityCheck:
      "This item quantity does not match the quantity in the Sales Order",
  },
  {
    id: "unitCost",
    label: "Unit Cost",
    type: "cash",
    sort: true,
    reference: itemTableReference.ITEM,
    companyPermission: COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_PRICE,
    isEditable: true,
    style: {
      width: 95,
      minWidth: 95,
      maxWidth: 95,
      padding: "0px 5px 0px 0px",
    },
    rowStyle: { fontWeight: 700 },
    headStyle: {
      padding: "0px 11px 0px 43px",
      textAlign: "left",
      height: 51,
      boxShadow: `0 2px 0 ${colors.dividerColor}`,
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    arrowStyle: {
      left: 77,
    },
    format: ({ value }) => formatCash(value),
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    id: "extendedPrice",
    label: "Extented Cost",
    sort: true,
    complexValue: (item) => {
      return getExtendedCostPrice({
        cost: item.unitCost,
        quantity: item.purchaseOrderQuantity,
      });
    },
    type: "cash",
    style: {
      minWidth: 130,
      maxWidth: 130,
      width: 130,
      textAlign: "right",
      padding: "0px 30px 0px 0px",
    },
    headStyle: {
      textAlign: "left",
      padding: "0px 15px 0px 45px",
      height: 51,
      boxShadow: `0 2px 0 ${colors.dividerColor}`,
    },
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    arrowStyle: {
      left: 105,
    },
    format: ({ value }) => formatCash(value),
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    id: "salesOrders",
    multiHeader: true,
    label: "Sales Orders",
    subHeader: [
      {
        id: "allocation",
        label: "Quantity",
        reference: itemTableReference.SALES_ORDERS,
        orderQuantityCheck:
          "This item quantity does not match the sum of all the shipments",
        style: {
          fontWeight: 700,
          width: 87,
          minWidth: 87,
          maxWidth: 87,
          borderLeft: `solid 2px ${colors.salesOrderBorderColor}`,
          textAlign: "right",
          padding: "0px 7px 0px 0px",
        },
        headerStyle: {
          padding: "0px 5px 0px 14px",
          width: 87,
          minWidth: 87,
          maxWidth: 87,
          border: 0,
          boxShadow: `inset 2px 0px 0px 0px ${colors.salesOrderBorderColor}, 0 2px 0 ${colors.dividerColor}`,
        },
        companyPermission: COMPANY_PERMISSION_VALUE.SALES_ORDER_QUANTITY,
        bodyStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        },
        isEditable: true,
        danger: true,
        icon: { visibility: "hidden" },
        format: ({ value }) => formatWithComma(value),
      },
      {
        id: "id",
        label: "Number",
        reference: itemTableReference.SALES_ORDERS,
        soRedirect: true,
        headerStyle: {
          width: 145,
          boxShadow: `0 2px 0 ${colors.dividerColor}`,
          padding: "0px 10px 0px 6px",
          textAlign: "left",
        },
        style: {
          width: 145,
          textDecoration: "underline",
          padding: "0px 0px 0px 6px",
        },
        bodyStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        },
        select: true,
        filterBy: "purchaseOrderIds",
        format: ({ value, salesOrders = [] }) =>
          getEntityByField({
            itemList: salesOrders,
            itemId: value,
            field: "number",
          }),
      },
      {
        id: "customerId",
        label: "Customer",
        customerRedirect: true,
        select: true,
        showToolTip: true,
        showFormatValue: true,
        headerStyle: {
          padding: "0px 0px 0px 10px",
          width: 110,
          boxShadow: `inset -2px 0px 0px 0px ${colors.salesOrderBorderColor}, 0 2px 0 ${colors.dividerColor}`,
          textAlign: "left",
        },
        reference: itemTableReference.SALES_ORDERS,
        style: {
          width: 100,
          textDecoration: "underline",
          padding: "10px 0px 10px 11px",
          borderRight: `solid 2px ${colors.salesOrderBorderColor}`,
        },
        bodyStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        },
        format: ({ value, customers = [] }) =>
          getCustomerName(customers, value, true),
        icon: { visibility: "hidden" },
        filterBy: "purchaseOrderIds",
      },
    ],
    style: {
      background: colors.salesOrderBackgroundColor,
      align: "center",
      textAlign: "center",
    },
    headStyle: {
      width: 375,
      minWidth: 375,
      maxWidth: 375,
      padding: "0px 0px 0px 5px",
      backgroundColor: colors.salesOrderBackgroundColor,
      boxShadow: `inset -2px 0px 0px 0px ${colors.salesOrderBorderColor}, inset 2px 0px 0px 0px ${colors.salesOrderBorderColor}`,
    },
  },
  {
    id: "shipments",
    multiHeader: true,
    parentId: "salesOrders",
    label: "Shipments",
    subHeader: [
      {
        id: "allocation",
        label: "Quantity",
        isEditable: true,
        permission: GENERAL_PERMISSION_VALUE.SHIPMENT_QUANTITY,
        reference: itemTableReference.SHIPMENTS,
        style: {
          fontWeight: 700,
          width: 87,
          minWidth: 87,
          maxWidth: 87,
          padding: "0px 5px 0px 5px",
          borderLeft: `2px solid ${colors.shipmentBorderColor}`,
        },
        headerStyle: {
          textAlign: "right",
          padding: "0px 23px 0px 0px",
          boxShadow: `inset 2px 0px 0px 0px ${colors.shipmentBorderColor}, 0 2px 0 ${colors.dividerColor}`,
          width: 87,
          minWidth: 87,
          maxWidth: 87,
        },
        canBeErase: true,
        bodyStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        },
        icon: { visibility: "hidden" },
        format: ({ value }) => formatWithComma(value),
        canBeNew: true,
      },
      {
        id: "number",
        label: "Number",
        reference: itemTableReference.SHIPMENTS,
        style: {
          width: 120,
          minWidth: 120,
          maxWidth: 120,
          textAlign: "left",
          padding: "0px 10px 0px 5px",
        },
        bodyStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        },
        select: true,
        showBadge: true,
        filterBy: "shipmentIds",
        headerStyle: {
          width: 120,
          minWidth: 120,
          maxWidth: 120,
          boxShadow: `0 2px 0 ${colors.dividerColor}`,
          textAlign: "left",
          padding: "0px 10px 0px 5px",
        },
      },
      {
        id: "id",
        label: "Final Destination",
        showToolTip: true,
        showFormatValue: true,
        reference: itemTableReference.SHIPMENTS,
        headerStyle: {
          width: 170,
          boxShadow: `inset -2px 0px 0px 0px ${colors.shipmentBorderColor}, 0 2px 0 ${colors.dividerColor}`,
          textAlign: "left",
          padding: "0px 0px 0px 11px",
        },
        style: {
          borderRight: `2px solid ${colors.shipmentBorderColor}`,
          textAlign: "left",
          padding: "0px 10px 0px 10px",
        },
        bodyStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        },
        icon: { visibility: "hidden" },
        format: ({ value, shipments = [] }) =>
          getShipmentDestination(shipments, value, true),
      },
    ],
    style: {
      backgroundColor: `${colors.shipmentBadge}`,
      textAlign: "center",
    },
    headStyle: {
      width: 375,
      minWidth: 375,
      maxWidth: 375,
      boxShadow: `inset -2px 0px 0px 0px ${colors.shipmentBorderColor}, inset 2px 0px 0px 0px ${colors.shipmentBorderColor}`,
      border: 0,
    },
  },
];

export const csvPOHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "purchaseOrderQuantity",
    label: "Order Quantity",
  },
  {
    key: "unitCost",
    label: "Unit Cost",
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    key: "extendedCost",
    label: "Extented Cost",
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    key: "salesOrderItemQuantity",
    label: "Sales Order Quantity",
  },
  {
    key: "salesOrderItemNumber",
    label: "Sales Order Number",
  },
  {
    key: "customer",
    label: "Customer",
  },
  {
    key: "shipmentItemQuantity",
    label: "Shipment Quantity",
  },
  {
    key: "shipmentItemNumber",
    label: "Shipment Number",
  },
  {
    key: "finalDestination",
    label: "Final Destination",
  },
];

export const csvSOHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "salesOrderQuantity",
    label: "Sales Order Quantity",
  },
  {
    key: "salesOrderPrice",
    label: "Sales Order Price",
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    key: "extendedPrice",
    label: "Extented Price",
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    key: "cbmMaster",
    label: "CBM/Master",
  },
  {
    key: "piecesPerMaster",
    label: "Pieces Per Master",
  },
  {
    key: "soCartons",
    label: "Cartons",
  },
  {
    key: "totalCbm",
    label: "Total CBM",
  },
];

export const csvShipmentHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "shipmentQuantity",
    label: "Shipment Quantity",
  },
  {
    key: "unitCost",
    label: "Unit Cost",
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
  },
  {
    key: "shipmentExtendedCost",
    label: "Extented Cost",
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
  },
  {
    key: "cbmMaster",
    label: "CBM/Master",
  },
  {
    key: "piecesPerMaster",
    label: `Pieces per Master`,
  },
  {
    key: "shipmentCartons",
    label: "Cartons",
  },
  {
    key: "shipmentTotalCbm",
    label: "Total CBM",
  },
  {
    key: "weight",
    label: "Weight",
  },
];

export const pdfPOHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "image",
    label: "Image",
  },
  {
    key: "description",
    label: "Description",
    style: { halign: "left" },
  },
  {
    key: "purchaseOrderQuantity",
    label: "Order Quantity",
  },
  {
    key: "unitCost",
    label: "Unit Cost",
    type: "money",
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    key: "extendedCost",
    label: "Extented Cost",
    type: "money",
    amountPermission: generalPermissions.PURCHASE_ORDER_AMOUNT,
  },
  {
    key: "salesOrderItemQuantity",
    label: "Sales Order Quantity",
  },
  {
    key: "salesOrderItemNumber",
    label: "Sales Order Number",
  },
  {
    key: "customer",
    label: "Customer",
  },
  {
    key: "shipmentItemQuantity",
    label: "Shipment Quantity",
  },
  {
    key: "shipmentItemNumber",
    label: "Shipment Number",
  },
  {
    key: "finalDestination",
    label: "Final Destination",
    style: {
      cellWidth: 100,
      halign: "left",
    },
  },
];

export const pdfSOHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "image",
    label: "Image",
  },
  {
    key: "description",
    label: "Description",
    style: { halign: "left" },
  },
  {
    key: "salesOrderQuantity",
    label: "Order Quantity",
  },
  {
    key: "salesOrderPrice",
    label: "Price",
    type: "money",
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    key: "extendedPrice",
    label: "Extented Price",
    type: "money",
    amountPermission: generalPermissions.SALES_ORDER_AMOUNT,
  },
  {
    key: "cbmMaster",
    label: "CBM/Master",
  },
  {
    key: "piecesPerMaster",
    label: "Pieces Per Master",
  },
  {
    key: "soCartons",
    label: "Cartons",
  },
  {
    key: "totalCbm",
    label: "Total CBM",
  },
];

export const pdfShipmentHeader = [
  {
    key: "itemNumber",
    label: "Item Number",
  },
  {
    key: "image",
    label: "Image",
  },
  {
    key: "description",
    label: "Description",
    style: { halign: "left" },
  },
  {
    key: "shipmentQuantity",
    label: "Shipment Quantity",
  },
  {
    key: "unitCost",
    label: "Unit Cost",
    type: "money",
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
  },
  {
    key: "shipmentExtendedCost",
    label: "Extented Cost",
    type: "money",
    amountPermission: generalPermissions.SHIPMENT_AMOUNT,
  },
  {
    key: "cbmMaster",
    label: "CBM/Master",
  },
  {
    key: "piecesPerMaster",
    label: `Pieces per Master`,
  },
  {
    key: "shipmentCartons",
    label: "Cartons",
  },
  {
    key: "shipmentTotalCbm",
    label: "Total CBM",
  },
  {
    key: "weight",
    label: "Weight",
  },
];
