import styled from "@emotion/styled";

export const CustomerInfoStyled = styled("div")(() => ({
  "&.container": {
    flex: 1.2,
    height: "100%",
    padding: 24,
    "& ul": {
      paddingLeft: 32,
    },
  },
  "& .infoField": {
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "1px solid #E5EDF7",
    "& span": {
      width: "50%",
      margin: "0 2px",
      "&:first-child": {
        textAlign: "right",
        width: "30%",
      },
      "&:last-child": {
        textAlign: "left",
      },
    },
  },
}));

export const CustomerDocumentsStyled = styled("div")(() => ({
  "&.documentContainer": {
    flex: 1,
    height: "100%",
    paddingLeft: 12,
    maxWidth: "calc(100vw - 1110px)",
    minWidth: 350,
    overflow: "auto",
  },
}));
