import React, { useState, useEffect, useRef, useCallback } from "react";
import { POListContainer, POTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter, {
  BUTTON_FILTER_BOX_SHADOW,
} from "../../components/PurchaseOrderList/ButtonFilter";
import {
  useCompanyId,
  useJSONLocalStorage,
  useFavorites,
  useFavoritesList,
} from "../../hooks";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import { debounce, getTheDate, sortObjectsBy } from "../../helpers/helpers";
import moment from "moment";
import { useIsAllowedFunction } from "../../hooks/permissions";
import SearchBox from "../../components/SearchBox/SearchBox";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import numeral from "numeral";
import FooterTable from "../../components/Tables/FooterTable";
import { footerPurchaseOrderList } from "../../components/Managers/FooterTableManager";
import { purchaseOrderListHeader } from "../../components/Managers/TableHeaderProvider";
import {
  getListValuesFromArray,
  getToolTipArrayValue,
} from "../../helpers/purchaseOrderList";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import BasicPagination from "../../components/Tables/BasicPagination";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import { useUser, useUserCustomSetting } from "../../hooks/user";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { useTags } from "../../hooks/tags";
import {
  excludeElementsScreen,
  generalPermissions,
  headerColumns,
} from "../../helpers/constants";
import { CalendarIcon, StarIcon } from "../../helpers/iconSvgPath";
import { getStatusAndDiamondStatus } from "../../helpers/screens";
import { firestore, performanceFirebase } from "../../firebase";
import { getUserAvatar } from "../../helpers/users";
import AttentionModal from "../../components/Modal/AttentionModal";
import { filterList } from "../../helpers/salesOrderHelpers";
import { doc, updateDoc } from "firebase/firestore";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { trace } from "firebase/performance";
import StatusOrderButtons from "../../components/PurchaseOrderList/StatusOrderButtons";
import ToolsButtonScreen from "../../components/PurchaseOrderList/ToolsButtonScreen";
import { orderType } from "../../helpers/salesOrder";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import Mark from "mark.js";
import PurchaseOrdersTableRow from "./PurchaseOrderTableRow";
import { useMediaQuery } from "@mui/material";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import { cx } from "@emotion/css";
import HeaderTableDate from "../SalesOrdersScreen/HeaderTableDate";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { isEqual } from "lodash";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import LateNearDueDiamondButtons from "../../components/PurchaseOrderList/LateNearDueDiamondButtons";

const dateFields = ["Created Date", "Document Date", "Order Ready Date"];
const BODY_ID = "purchaseOrderTableRow";

function PurchaseOrdersScreen() {
  const dateRef = useRef(null);
  const matches = useMediaQuery("(max-width:1425px)");
  const screenMidSize = useMediaQuery("(max-width:1600px)");
  const { get, set } = useJSONLocalStorage("poListFilters");
  const { get: getPurchaseOrderSetup, set: setPurchaseOrderSetup } =
    useJSONLocalStorage("purchaseOrdersScreen");
  const [currentPOSetup, setCurrentPOSetup] = useState(
    getPurchaseOrderSetup() || {}
  );
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const companyId = useCompanyId();
  const favorites = useFavoritesList({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const favoritesDB = useFavorites({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const [loading, setLoading] = useState(false);
  const isAllowed = useIsAllowedFunction();

  const customers = useCustomers();
  const factories = useFactories();
  const tags = useTags();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const dataLocalStorage = get() || {};
  const [dataFiltered, setDataFiltered] = useState({
    purchaseOrders: [],
    totalAmounts: {},
  });
  const [filters, setFilters] = useState({
    completed: dataLocalStorage.completed || false,
    customer: dataLocalStorage.customer || [],
    date: dataLocalStorage.date || "",
    dateRange: dataLocalStorage.dateRange || "",
    factory: dataLocalStorage.factory || [],
    inProgress: !get() ? true : dataLocalStorage.inProgress || false,
    late: dataLocalStorage.late || false,
    nearDue: dataLocalStorage.nearDue || false,
    tag: dataLocalStorage.tag || [],
    voided: dataLocalStorage.voided || false,
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    week: dataLocalStorage.week || false,
    favorite: dataLocalStorage.favorite || false,
    user: dataLocalStorage.user || false,
    query: dataLocalStorage.query || "",
    sortedColumn: dataLocalStorage.sortedColumn || "",
    orderBy: dataLocalStorage.orderBy || "",
  });
  const [weekGroups, setWeekGroups] = useState([]);
  const [openDate, setOpenDate] = useState(false);
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const [filterDate, setFilterDate] = useState(dataLocalStorage.date || "");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    dataLocalStorage.rowsPerPage || 10
  );

  const [headerCells, setHeaderCells] = useState([]);
  const [currentFavorites, setCurrentFavorites] = useState([]);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);

  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
    }
  }

  const handleSetupStorage = ({ shipmentValue, soValue, item }) => {
    setCurrentPOSetup((oldSetup) => {
      const oldValue = oldSetup || {};
      const itemValue = oldValue[item.id] || {};
      const shipmentsSetup =
        shipmentValue === undefined ? itemValue.shipments : shipmentValue;
      const soSetup = soValue === undefined ? itemValue.SOs : soValue;
      setPurchaseOrderSetup(item.id, {
        shipments: shipmentsSetup,
        SOs: soSetup,
      });
      return {
        ...oldValue,
        [item.id]: { shipments: shipmentsSetup, SOs: soSetup },
      };
    });
  };

  useEffect(() => {
    function onAdjustHeader() {
      const { filteredList } = filterList({
        list: purchaseOrders,
        favorites: favorites,
        headerCells,
        headerColumns,
        nonLookUpfilters,
      });

      const datatoShow = filteredList.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      );
      let maxWidthPONumber = 130;
      let maxWidthSONumber = 65;
      let maxWidthCustomerName = 50;
      let maxWidthVendorName = 50;

      datatoShow.forEach((po) => {
        const poWidth = po.number.toString().length * 7;
        const soWidth = po.SONumber.toString().length * 8.3;
        const customerNameWidth = po.customerName.toString().length * 7.25;
        const vendorNameWidth = po.factoryName.toString().length * 7.25;

        if (poWidth > maxWidthPONumber) {
          maxWidthPONumber = poWidth;
        }
        if (soWidth > maxWidthSONumber) {
          maxWidthSONumber = soWidth;
        }
        if (customerNameWidth > maxWidthCustomerName) {
          maxWidthCustomerName = customerNameWidth;
        }
        if (vendorNameWidth > maxWidthVendorName) {
          maxWidthVendorName = vendorNameWidth;
        }
      });
      let userHeaderCell = userCustomSettings.POHeaderSetup || [];
      userHeaderCell = userHeaderCell.map((header) => {
        const poHeader = purchaseOrderListHeader[header.name];
        if (
          poHeader &&
          poHeader[generalPermissions.PURCHASE_ORDER_AMOUNT] &&
          !isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ) {
          return {
            ...header,
            ...poHeader,
            styles: {
              ...poHeader.styles,
              display: "none",
            },
          };
        }
        return {
          ...header,
          ...purchaseOrderListHeader[header.name],
        };
      });

      let headerCellsCpy = [...userHeaderCell];
      headerCellsCpy = headerCellsCpy.map((headerCell) => {
        if (headerCell.reference === "number") {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxWidthPONumber + 60 },
          };
        } else if (headerCell.reference === "SONumber") {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxWidthSONumber + 70 },
          };
        } else if (headerCell.reference === "customerName") {
          return {
            ...headerCell,
            styles: {
              ...headerCell.styles,
              width:
                maxWidthCustomerName + 60 >= 210
                  ? 210
                  : maxWidthCustomerName + 60,
            },
          };
        } else if (headerCell.label === "userMentionCount") {
          return {
            ...headerCell,
            item: getUserAvatar({
              user,
              styles: {
                width: 24,
                height: 24,
                position: "absolute",
                fontSize: 13,
                outline: "2px solid #000",
                marginTop: -5,
              },
              hasToolTip: false,
            }),
          };
        } else if (headerCell.reference === "factoryName") {
          return {
            ...headerCell,
            styles: {
              ...headerCell.styles,
              width:
                maxWidthVendorName + 40 >= 180 ? 180 : maxWidthVendorName + 40,
            },
          };
        } else {
          return headerCell;
        }
      });
      if (!isEqual(headerCells, headerCellsCpy)) {
        setHeaderCells(headerCellsCpy);
      }
    }
    onAdjustHeader();
  }, [
    rowsPerPage,
    page,
    nonLookUpfilters,
    purchaseOrders,
    isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT),
  ]);

  useEffect(() => {
    if (favorites.length !== currentFavorites.length) {
      setCurrentFavorites(favorites);
    }
  }, [favorites]);

  async function getPurchaseOrders() {
    console.log("lookup");
    setPreviousFilter(filters);
    const traceInstance = trace(performanceFirebase, "purchase_orders_screen");
    traceInstance.start();
    setLoading(true);

    const { status, diamondStatus } = getStatusAndDiamondStatus({ filters });

    try {
      const functions = getFunctions();
      const purchaseOrderEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview",
          env: globalEnvironment,
          params: "/purchaseorders",
        })
      );
      purchaseOrderEndpoint({
        filters: {
          ...filters,
          status,
          diamondStatus,
          dateRange: "",
          dateStart: filters.dateRange
            ? moment(filters.dateRange.start).valueOf()
            : "",
          dateEnd: filters.dateRange
            ? moment(filters.dateRange.end).valueOf()
            : "",
        },
        companyId,
        userId: user.id,
      }).then((result) => {
        const { purchaseOrdersDB } = result.data;
        setPurchaseOrders([...purchaseOrdersDB]);
        if (nonLookUpfilters.week) {
          groupByWeeks(purchaseOrdersDB);
        }
        traceInstance.stop();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      traceInstance.stop();
      setLoading(false);
    }
  }

  useEffect(() => {
    const { filteredList, totalAmounts } = filterList({
      list: purchaseOrders,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
    });
    setDataFiltered({ purchaseOrders: filteredList, totalAmounts });
    if (nonLookUpfilters.week) {
      groupByWeeks(filteredList);
    }
  }, [headerCells, currentFavorites, purchaseOrders]);

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      console.log("same filter");
      return;
    }
    setPurchaseOrders([]);
    setDataFiltered({ purchaseOrders: [], totalAmounts: {} });
    setPage(1);
    getPurchaseOrders();
    set({ ...nonLookUpfilters, ...filters, rowsPerPage });
  }, [callFunction]);

  useEffect(() => {
    set({ ...filters, ...nonLookUpfilters, rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    setPage(1);
    set({ ...filters, ...nonLookUpfilters, rowsPerPage });
    if (!purchaseOrders.length) return;
    const { filteredList, totalAmounts } = filterList({
      list: purchaseOrders,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
    });
    if (nonLookUpfilters.week) {
      groupByWeeks(filteredList);
    } else {
      setDataFiltered({ purchaseOrders: filteredList, totalAmounts });
    }
  }, [nonLookUpfilters]);

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }

  function changeNonLookUpButtonFilter(filter) {
    setNonLookUpFilters({
      ...nonLookUpfilters,
      [filter]: !nonLookUpfilters[filter],
    });
  }

  function groupByWeeks(items = []) {
    let weeks = [];
    items.forEach((item) => {
      const weekStart = moment(item.orderReadyDateWithTerms)
        .startOf("week")
        .toDate()
        .getTime();
      const weekEnd = moment(item.orderReadyDateWithTerms)
        .endOf("week")
        .toDate()
        .getTime();
      if (!weeks.find((week) => week.start === weekStart)) {
        weeks.push({
          start: item.orderReadyDateWithTerms ? weekStart : "",
          end: item.orderReadyDateWithTerms ? weekEnd : "",
          list: items
            .filter(
              (el) =>
                el.orderReadyDateWithTerms > weekStart &&
                el.orderReadyDateWithTerms < weekEnd
            )
            .sort(
              sortObjectsBy(
                nonLookUpfilters.sortedColumn,
                nonLookUpfilters.orderBy === "ASC" ? true : false
              )
            ),
        });
      }
    });
    weeks = weeks.filter((week) => week.start !== "" && week.end !== "");
    weeks = weeks.sort((a, b) => {
      if (a.start && b.start) return a.start < b.start ? -1 : 1;
      return 0;
    });
    weeks.forEach((week, index) => {
      const { totalAmounts } = filterList({
        list: week.list,
        favorites: favorites,
        headerCells,
        headerColumns,
        nonLookUpfilters,
      });
      weeks[index].totalAmounts = totalAmounts;
    });
    setWeekGroups(weeks);
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    if (!loading) {
      setTimeout(() => performMark(nonLookUpfilters.query), 150);
    }
  }, [nonLookUpfilters, loading, rowsPerPage, page]);

  function resetFilters() {
    setFilters({
      factory: [],
      customer: [],
      dateRange: "",
      date: "",
      inProgress: true,
      completed: false,
      voided: false,
      late: false,
      nearDue: false,
    });
    setNonLookUpFilters({
      ...nonLookUpfilters,
      week: false,
      query: "",
      user: false,
      favorite: false,
    });
    setFilterDate("");
    setExistsDate(false);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filterDate,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
    if (!filterDate) {
      setFilters({ ...filters, date: "" });
    }
  }

  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilterDate(field);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 50);
  }

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function renderFooter(list = [], renderFooterTable, totalAmounts) {
    return (
      <React.Fragment>
        <FooterTable
          footer={footerPurchaseOrderList(list.length, totalAmounts, isAllowed)}
          headerCells={headerCells.sort(sortObjectsBy("index", false))}
          isThereColumnSetting={true}
        />
      </React.Fragment>
    );
  }

  function getCSVBody(purchaseOrders) {
    return purchaseOrders.map((item) => {
      return {
        favorite: favorites.map((favoriteId) => favoriteId).includes(item.id)
          ? "YES"
          : "NO",
        status: item.late ? "Red" : item.nearDue ? "Orange" : "",
        progress: item.progress,
        mentions: item.totalMentions,
        PONumber: item.number,
        SONumber: item.SONumber,
        shipments:
          item.shipments.map((shipment) => shipment.number).join(", ") + " \n ",
        customerName: item.customerName,
        date:
          !item.date || item.date === ""
            ? ""
            : moment(item.date).format("M/D/YY"),
        POShipDate:
          !item.shipDate || item.shipDate === ""
            ? ""
            : moment(item.shipDate).format("M/D/YY"),
        creationDate:
          !item.creationDate || item.creationDate === ""
            ? ""
            : moment(item.creationDate).format("M/D/YY"),
        orderReadyDate:
          !item.orderReadyDate || item.orderReadyDate === ""
            ? ""
            : moment(item.orderReadyDate).format("M/D/YY"),
        factoryName: item.factoryName,
        discount: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.discount).format("0,0.00")
          : "",
        discountPercentage: item.discountPercentage || "",
        total: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.total).format("0,0.00")
          : "",
        subtotal: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.subtotal).format("0,0.00")
          : "",
        deposit: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.deposit).format("0,0.00")
          : "",
        balance: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.balance).format("0,0.00")
          : "",
        potentialLateFee: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.potentialLateFee).format("0,0.00")
          : "",
        totalWeight: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.totalWeight).format("0,0.00")
          : "",
        CBM: numeral(item.CBM).format("0,0.00"),
        shippingTerms: item.shippingTerms,
        invoice: item.invoice,
        uniqueItems: item.uniqueItems,
        totalShipments: item.shipmentIds.length,
        poLabels: getListValuesFromArray({
          arr: item.tags || [],
          list: tags || [],
          field: "name",
        }),
      };
    });
  }

  function handleUpdateItem(item, tagReference, poId) {
    let purchaseOrdersCpy = [...purchaseOrders];
    if (tagReference === dbTables.PURCHASE_ORDERS) {
      purchaseOrdersCpy = purchaseOrdersCpy.map((po) => {
        if (item.id === po.id) {
          return {
            ...item,
          };
        }
        return po;
      });
    } else if (tagReference === dbTables.SHIPMENTS) {
      purchaseOrdersCpy = purchaseOrdersCpy.map((po) => ({
        ...po,
        shipments: po.shipments.map((shipment) => {
          if (shipment.id === item.id) {
            return item;
          }
          return shipment;
        }),
      }));
    } else if (tagReference === dbTables.SALES_ORDERS) {
      purchaseOrdersCpy = purchaseOrdersCpy.map((po) => {
        if (po.id === poId) {
          return {
            ...po,
            salesOrders: po.salesOrders.map((so) => {
              if (so.id === item.id) {
                return item;
              }
              return so;
            }),
          };
        }
        return po;
      });
    }
    setPurchaseOrders(purchaseOrdersCpy);
    const { filteredList, totalAmounts } = filterList({
      list: purchaseOrdersCpy,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
    });
    if (nonLookUpfilters.week) {
      groupByWeeks(filteredList);
    } else {
      setDataFiltered({
        purchaseOrders: filteredList,
        totalAmounts,
      });
    }
  }

  function areAllPOExpanded({ purchaseOrders = [], purchaseOrderSetup = {} }) {
    const isThereASectionClosed = purchaseOrders.some((purchaseOrder) => {
      if (!purchaseOrderSetup[purchaseOrder.id]) {
        return true;
      } else if (!purchaseOrderSetup[purchaseOrder.id].shipments) {
        return true;
      }
      return false;
    });
    return !isThereASectionClosed;
  }

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounce();
  };

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={
            <React.Fragment>
              You do not have access to the shipment documents folder for this
              shipment
            </React.Fragment>
          }
          onClose={() => setOpenAttentionModal(false)}
          onClick={() => setOpenAttentionModal(false)}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <POListContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            dateFields={dateFields}
            currentFilters={filters}
            onOpenDatePicker={onOpenDatePicker}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            enabledFields={{
              customer: true,
              factory: true,
              date: true,
              tag: true,
            }}
          />
          <SearchBox
            placeholder="Search for PO or Sales Order or shipment number, etc."
            value={nonLookUpfilters.query}
            hasBeenReseted={(ev) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: "" });
            }}
            filters={nonLookUpfilters}
            onDebounceValue={(nonLookUpfilters, value) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: value });
            }}
          />
        </div>
        <div className="contentContainer">
          <div className="buttonsFilter">
            <ButtonFilter
              buttonId="list-view-button-filter-favorite"
              value={nonLookUpfilters.favorite}
              onClick={() => changeNonLookUpButtonFilter("favorite")}
              boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
              className="smallBox"
            >
              <StarIcon />
            </ButtonFilter>
            <LateNearDueDiamondButtons
              filters={filters}
              changeButtonFilter={changeButtonFilter}
            />

            <ButtonFilter
              buttonId="list-view-button-filter-useronly"
              value={nonLookUpfilters.user}
              onClick={() => changeNonLookUpButtonFilter("user")}
              toolTip="only"
              className="onlyAvatar"
            >
              {getUserAvatar({
                user,
                hasToolTip: false,
                styles: {
                  width: 24,
                  height: 24,
                  marginRight: 5,
                  fontSize: 13,
                  outline: "2px solid #000",
                },
              })}
              <span className="onlyText"> Only</span>
            </ButtonFilter>
            <StatusOrderButtons
              changeButtonFilter={changeButtonFilter}
              filters={{ ...filters, existsDate }}
            />
            <TooltipTD
              label={
                <span>
                  When filtering by week, the system will take into account
                  <br /> the Order Ready Date field as well as the vendor terms{" "}
                  <br /> measured in days
                </span>
              }
            >
              <ButtonFilter
                buttonId="list-view-button-filter-groupbyweek"
                value={nonLookUpfilters.week}
                onClick={() => changeNonLookUpButtonFilter("week")}
                className="mediumBox"
              >
                {!screenMidSize && (
                  <CalendarIcon
                    style={{ marginRight: 5 }}
                    size={24}
                    svgClass="icon"
                  />
                )}

                {screenMidSize ? "ByWeek" : "Group by Week"}
              </ButtonFilter>
            </TooltipTD>

            {filters.customer && filters.customer.length > 0 && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-customer"
                onClear={() => clearFilterData({ filterKey: "customer" })}
                label={filters.customer.length === 1 ? "Customer" : "Customers"}
                tooltip={
                  getToolTipArrayValue(filters.customer, customers).tooltip
                }
                value={getToolTipArrayValue(filters.customer, customers).value}
                filters={filters}
              />
            )}
            {filters.factory && filters.factory.length > 0 && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-factory"
                onClear={() => clearFilterData({ filterKey: "factory" })}
                label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                tooltip={
                  getToolTipArrayValue(filters.factory, factories).tooltip
                }
                value={getToolTipArrayValue(filters.factory, factories).value}
                filters={filters}
              />
            )}
            {isAllowed("see_tags") && filters.tag && filters.tag.length > 0 && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-label"
                onClear={() => clearFilterData({ filterKey: "tag" })}
                label={filters.tag.length === 1 ? "Label" : "Labels"}
                tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                value={tags && getToolTipArrayValue(filters.tag, tags).value}
                filters={filters}
              />
            )}

            {existsDate && (
              <React.Fragment>
                <div
                  onClick={() => {
                    setOpenDate(true);
                    setOpenList(true);
                  }}
                  ref={dateRef}
                >
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-date"
                    onClear={() => {
                      setFilterDate("");
                      setExistsDate(false);
                      setFilters({
                        ...filters,
                        date: "",
                        dateRange: { start: new Date(), end: new Date() },
                      });
                      setOpenDate(false);
                      setOpenList(false);
                      onDebounce();
                    }}
                    label={filters.date}
                    filters={filters}
                    tooltip={""}
                    value={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                  />
                </div>
                {openDate && (
                  <DateRangePicker
                    open={openDate}
                    el={dateRef.current}
                    onClose={handleCloseDatePicker}
                    onChange={changeDate}
                    value={filters.dateRange}
                    cancelIcon={true}
                  />
                )}
              </React.Fragment>
            )}
            <ButtonReset
              id="list-view-button-filter-reset"
              className="root"
              onClick={resetFilters}
            >
              {matches ? "RESET" : "RESET FILTERS"}
            </ButtonReset>
          </div>
          <ToolsButtonScreen
            typeSetup={columnHeaderSetup.PO_HEADER_SETUP}
            nonLookUpfilters={nonLookUpfilters}
            changeNonLookUpButtonFilter={changeNonLookUpButtonFilter}
            headerCells={headerCells}
            csvData={getCSVBody(dataFiltered.purchaseOrders)}
            onChangeHeader={(newItems) => setHeaderCells(newItems)}
            hiddenButton={{
              [orderType.PURCHASE_ORDER]: true,
              [orderType.SHIPMENT]: true,
            }}
            hiddenDataAction={(value) => {
              let purchaseOrderSetup = {};
              purchaseOrders.forEach((purchaseOrder) => {
                purchaseOrderSetup[purchaseOrder.id] = {
                  shipments: !value,
                  SOs: !value,
                };
              });
              setCurrentPOSetup({ ...purchaseOrderSetup });
              setPurchaseOrderSetup({ ...purchaseOrderSetup });
            }}
            isAllExpanded={areAllPOExpanded({
              purchaseOrders,
              purchaseOrderSetup: currentPOSetup,
            })}
            onSaveHeaders={(newItems) => {
              updateDoc(
                doc(
                  firestore,
                  `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
                ),
                {
                  POHeaderSetup: newItems,
                }
              );
            }}
          />

          <POTableContainer className="contentListPO">
            <div
              className={cx("tableContainer", {
                tableContainerWeek: nonLookUpfilters.week,
              })}
              id={BODY_ID}
            >
              {!nonLookUpfilters.week && (
                <MainTableRefactor
                  headCells={headerCells.sort(sortObjectsBy("index", false))}
                  bodyRows={dataFiltered.purchaseOrders.slice(
                    (page - 1) * rowsPerPage,
                    page * rowsPerPage
                  )}
                  renderRow={(item, index) => {
                    return (
                      <PurchaseOrdersTableRow
                        key={item.id + index}
                        item={item}
                        favorites={favorites}
                        user={user}
                        filters={filters}
                        isAllowed={isAllowed}
                        headers={headerCells.sort(
                          sortObjectsBy("index", false)
                        )}
                        tags={tags}
                        onUpdateItem={handleUpdateItem}
                        isThereColumnSetting={true}
                        purchaseOrderSetup={currentPOSetup}
                        onChangePurchaseOrderSetup={({ shipments, SOs }) => {
                          handleSetupStorage({
                            item,
                            shipmentValue: shipments,
                            soValue: SOs,
                          });
                        }}
                        onOpenAttentionModal={() => setOpenAttentionModal(true)}
                        favoritesDB={favoritesDB}
                      />
                    );
                  }}
                  footer={renderFooter(
                    dataFiltered.purchaseOrders,
                    true,
                    dataFiltered.totalAmounts
                  )}
                  filters={nonLookUpfilters}
                  onUpdateFilters={(nonLookUpfilters) => {
                    setPage(1);
                    setNonLookUpFilters({ ...nonLookUpfilters });
                  }}
                />
              )}
              {nonLookUpfilters.week && (
                <React.Fragment>
                  {weekGroups.length > 0 &&
                    weekGroups.map(
                      (week, index) =>
                        week.list.length > 0 && (
                          <div className="weekTableContainer">
                            <HeaderTableDate
                              week={week}
                              key={week.end + index}
                              type="POs"
                            />
                            <MainTableRefactor
                              headCells={headerCells.sort(
                                sortObjectsBy("index", false)
                              )}
                              bodyRows={week.list}
                              renderRow={(item) => (
                                <PurchaseOrdersTableRow
                                  item={item}
                                  filters={filters}
                                  favorites={favorites}
                                  user={user}
                                  onChangePurchaseOrderSetup={({
                                    shipments,
                                    SOs,
                                  }) => {
                                    handleSetupStorage({
                                      item,
                                      shipmentValue: shipments,
                                      soValue: SOs,
                                    });
                                  }}
                                  isAllowed={isAllowed}
                                  headers={headerCells.sort(
                                    sortObjectsBy("index", false)
                                  )}
                                  showTags={
                                    filters.tag && filters.tag.length > 0
                                      ? true
                                      : false
                                  }
                                  tags={tags}
                                  onUpdateItem={handleUpdateItem}
                                  purchaseOrderSetup={currentPOSetup}
                                  onOpenAttentionModal={() =>
                                    setOpenAttentionModal(true)
                                  }
                                  favoritesDB={favoritesDB}
                                />
                              )}
                              footer={renderFooter(
                                week.list,
                                false,
                                week.totalAmounts
                              )}
                              filters={nonLookUpfilters}
                              onUpdateFilters={(nonLookUpfilters) => {
                                setPage(1);
                                setNonLookUpFilters({ ...nonLookUpfilters });
                              }}
                              enableToSee={
                                filters.inProgress ||
                                filters.completed ||
                                filters.voided
                                  ? dataFiltered.purchaseOrders
                                  : []
                              }
                            />
                          </div>
                        )
                    )}
                </React.Fragment>
              )}
            </div>
            {!nonLookUpfilters.week && (
              <BasicPagination
                rowsPerPage={rowsPerPage}
                page={page}
                count={getCountPagination(
                  dataFiltered.purchaseOrders,
                  rowsPerPage
                )}
                onChangePagination={(event, newPage) => {
                  handleChangePage(newPage);
                }}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(+event.target.value);
                  setPage(1);
                }}
              />
            )}
          </POTableContainer>
        </div>
      </POListContainer>
    </React.Fragment>
  );
  function getCountPagination(purchaseOrders, rowsPerPage) {
    return Math.ceil(purchaseOrders.length / rowsPerPage);
  }
}

export default PurchaseOrdersScreen;
