import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Popover, styled } from "@mui/material";
import { DateRangePicker as DateRangePicker1 } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateRangePickerStyled = styled("div")(({ theme }) => ({
  "&.dateRangePickerContainer": {
    display: "flex",
    flexFlow: "column nowrap",
    width: "fit-content",
    margin: "4px 8px",
    "& .rdrCalendarWrapper": {
      "& button": {
        minWidth: "0px",
        minHeight: "0px",
        margin: "0px",
      },
      "& .rdrMonthAndYearWrapper": {
        height: 32,
        paddingTop: 0,
      },
      "& .rdrMonthsHorizontal": {
        "& .rdrMonth": {
          padding: "0px 8px 8px 8px",
        },
      },
    },
    "& .rdrMonth": {
      width: 250,
      "& .rdrDayWeekend": {
        backgroundColor: "#f5f5f5",
        borderRadius: 0,
      },
    },
    "& .rdrDefinedRangesWrapper": {
      width: 150,
      "& button": {
        color: "#25486d",
      },
    },
  },
}));

const staticRanges = [
  {
    label: "Today",
    hasCustomRendering: false,
    range: () => ({
      startDate: new Date(),
      endDate: new Date(),
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "Last 7 Days",
    hasCustomRendering: false,
    range: () => ({
      startDate: new Date(moment().subtract(7, "days")),
      endDate: new Date(),
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "Last 30 Days",
    hasCustomRendering: false,
    range: () => ({
      startDate: new Date(moment().subtract(30, "days")),
      endDate: new Date(),
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "Last 90 Days",
    hasCustomRendering: false,
    range: () => ({
      startDate: new Date(moment().subtract(90, "days")),
      endDate: new Date(),
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "This Week",
    hasCustomRendering: false,
    range: () => ({
      startDate: new Date(moment().startOf("isoWeek")),
      endDate: new Date(moment().endOf("isoWeek")),
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: "This Month",
    hasCustomRendering: false,
    range: () => ({
      startDate: new Date(moment().startOf("month")),
      endDate: new Date(moment().endOf("month")),
    }),
    isSelected() {
      return true;
    },
  },
];

function DateRangePicker({
  value,
  el,
  open,
  onClose = () => {},
  onChange = () => {},
  cancelIcon,
}) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (open) {
      setState(getRange({ startDate: value.start, endDate: value.end }));
    }
  }, [open]);

  function getRange({ startDate, endDate }) {
    if (!startDate || !endDate)
      return [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ];
    return [
      {
        startDate: moment(startDate).startOf("day").valueOf(),
        endDate: moment(endDate).endOf("day").valueOf(),
        key: "selection",
      },
    ];
  }

  function handleClose(ev) {
    onChange({ start: state[0].startDate, end: state[0].endDate });
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    onClose(ev);
  }

  return (
    <Popover
      anchorEl={el}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      classes={{ paper: "date-picker" }}
      open={open}
      onClose={(ev) => {
        handleClose(ev);
      }}
    >
      {cancelIcon && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 5px 0px 0px",
          }}
        >
          <CloseIcon
            style={{
              width: 16,
              height: 16,
              color: "#FA9917",
              cursor: "pointer",
            }}
            onClick={(ev) => {
              handleClose(ev);
            }}
          />
        </div>
      )}

      <DateRangePickerStyled className="dateRangePickerContainer">
        <DateRangePicker1
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          inputRanges={[]}
          staticRanges={staticRanges}
          rangeColors={["#FA9917"]}
          weekdayDisplayFormat="E"
        />
      </DateRangePickerStyled>
    </Popover>
  );
}

DateRangePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateRangePicker;
