import React from "react";
import { Tab, Tabs } from "@mui/material";

function DayCardTabs({
  tabs = [],
  spaced,
  value,
  onChange,
  fullWidth,
  tasks,
  expanded,
}) {
  return (
    <Tabs
      TabIndicatorProps={{ style: { display: "none" } }}
      value={expanded ? 0 : value || 0}
      className={spaced ? "spacedTabs" : "tabs"}
      variant="standard"
      onChange={(e, value) => onChange(value)}
      classes={{ flexContainer: "dayCardFlexContainer" }}
    >
      {tabs.map((tab) => (
        <Tab
          id="tabCards"
          key={tab}
          className={expanded ? "whiteTab" : spaced ? "spacedTab" : "tab"}
          classes={{ selected: "active" }}
          label={
            tab +
            ` (${
              tasks[
                tab === "LATE"
                  ? "late"
                  : tab === "DUE"
                  ? "dueDate"
                  : "startDate"
              ]
                ? tasks[
                    tab === "LATE"
                      ? "late"
                      : tab === "DUE"
                      ? "dueDate"
                      : "startDate"
                  ].length
                : 0
            })`
          }
        />
      ))}
    </Tabs>
  );
}

export default DayCardTabs;
