import { Accordion, styled } from "@mui/material";

export const CompanyTabsStyled = styled("div")(() => ({
  "&.container": {
    boxShadow: "0 2px 4px 2px #ccc",
    width: "100%",
    height: "100%",
    display: "block",
    padding: 16,
  },
  "& .root": {
    width: "100%",
  },
  "& .heading": {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  "& .gridContainer": {
    display: "grid",
    gridTemplateColumns: "15% 25% 40% 20%",
    gridTemplateRows: "max-content",
    gridGap: "15px",
    width: "50%",
    marginBottom: "16px",
  },
  "& .gridContainerUpdatedSOsAndPOs": {
    display: "grid",
    gridTemplateColumns: "15% 25% 15% 15% 15% 15%",
    gridTemplateRows: "max-content",
    gridGap: "15px",
    width: "50%",
    marginBottom: "16px",
  },
}));

export const DocumentPlaceholderStyled = styled("div")(() => ({
  "&.documentPlaceholderContainer": {
    boxShadow: "0 2px 4px 2px #ccc",
    width: "100%",
    height: "100%",
  },
  "& .document-template-container": {
    overflowX: "auto",
    display: "flex",
    margin: "5px 0px",
    height: "93%",
  },  
  "& .root": {
    width: "100%",
  },
  "& .heading": {
    fontSize: 15,
    fontWeight: 500,
  },
  "& .gridContainer": {
    display: "grid",
    gridTemplateColumns: "15% 25% 40% 20%",
    gridTemplateRows: "max-content",
    gridGap: "15px",
    width: "50%",
    marginBottom: "16px",
  },
  "& .gridContainerUpdatedSOsAndPOs": {
    display: "grid",
    gridTemplateColumns: "15% 25% 15% 15% 15% 15%",
    gridTemplateRows: "max-content",
    gridGap: "15px",
    width: "50%",
    marginBottom: "16px",
  },
}));

export const SettingsStyled = styled("div")(() => ({
  "& .settingsContainer": {
    margin: "0px 4px 4px 4px",
    boxShadow: "0 2px 4px 2px #ccc",
    width: "100%",
    height: "100%",
    display: "block",
  },
}));

export const ErrorLogStyled = styled("div")(() => ({
  "&.errorLogContainer": {
    boxShadow: "0 2px 4px 2px #ccc",
    width: "100%",
    height: "100%",
    display: "block",
    zIndex: 0,
    overflow: "auto",
  },
}));

export const AccordionImportedDataStyled = styled(Accordion)(() => ({
  "& .container": {
    margin: "0px 4px 4px 4px",
    boxShadow: "0 2px 4px 2px #ccc",
    height: "calc(100vh - 165px)",
    display: "block",
    padding: 16,
  },
  "& .expandIcon": {
    position: "absolute",
    left: "0px",
  },
  "& .content": {
    marginLeft: "32px !Important",
  },
}));
