import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccordionImportedDataStyled } from "./styles";
import { getNestedCollectionFromFirestoreTable } from "../../actions/DataActions";
import { pathToString, sortObjectsBy } from "../../helpers/helpers";
import { dbTables, apiImportDataType } from "../../api/types/dbTables";
import CreateQuote from "../apiImportData/CreateQuote";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import moment from "moment";
import { AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

function ImportedData() {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const listenAPIImportedData = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.API_IMPORTED_DATA,
      ]),
    })(dispatch)
  );

  let apiImportedData = useSelector(
    (state) => state.data.api_imported_data[companyId]
  );
  if (apiImportedData) {
    apiImportedData.sort(sortObjectsBy("date", true));
  }

  useEffect(() => {
    listenAPIImportedData();
  }, [companyId]);

  function getBuilderImport(item) {
    switch (item.type) {
      case apiImportDataType.CREATE_QUOTE:
      case apiImportDataType.UPDATE_QUOTE:
      case apiImportDataType.QUOTE_TO_SALES_ORDER:
      case apiImportDataType.UPDATE_SALES_ORDER:
      case apiImportDataType.CREATE_PURCHASE_ORDER:
      case apiImportDataType.UPDATE_PURCHASE_ORDER:
      case apiImportDataType.RFQ_TO_PURCHASE_ORDER:
      case apiImportDataType.CREATE_SALES_ORDER:
        return <CreateQuote item={item} />;

      default:
        return <div>HERE DEFAULT</div>;
    }
  }

  function getDataType(type) {
    switch (type) {
      case apiImportDataType.CREATE_SALES_ORDER:
        return "created sales order";
      case apiImportDataType.UPDATE_SALES_ORDER:
        return "updated sales order";
      case apiImportDataType.CREATE_PURCHASE_ORDER:
        return "created PO";
      case apiImportDataType.UPDATE_PURCHASE_ORDER:
        return "updated PO";
      case apiImportDataType.CREATE_QUOTE:
        return "created quote";
      case apiImportDataType.UPDATE_QUOTE:
        return "updated quote";
      case apiImportDataType.QUOTE_TO_SALES_ORDER:
        return "quote became a sales order";
      case apiImportDataType.RFQ_TO_PURCHASE_ORDER:
        return "RFQ became a purchase order";
      default:
        return "DEFAULT";
    }
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      {apiImportedData &&
        apiImportedData.map((item) => {
          const { data, date } = item;
          return (
            <AccordionImportedDataStyled key={item.id}>
              <AccordionSummary
                classes={{
                  expandIcon: "expandIcon",
                  content: "content",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={"heading"} style={{ fontSize: "1rem" }}>
                  {moment(date).format("M/D/YY hh:mm a")} &nbsp;&nbsp; was
                  successfully {getDataType(item.type)} {data.number}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                {getBuilderImport(item)}
              </AccordionDetails>
            </AccordionImportedDataStyled>
          );
        })}
    </div>
  );
}

export default ImportedData;
