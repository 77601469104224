import React from "react";
import { itemTableRowHeight } from "../../helpers/orderDashboard";
import RowSKU from "../Tables/RowSKU";
import { TableRow } from "@mui/material";

function SKURowVirtualized({
  prefix,
  index,
  style,
  data: { columns, items, classes },
  onChangeData = () => {},
  showImage,
  currentSalesOrder,
  currentPurchaseOrder,
  currentShipment = {},
  handlePhotosViewer = () => {},
  isAllowed = () => {},
  companyAllowed = () => {},
  focusPOTable = () => {},
  isVoided,
}) {
  const sortedItems = items.map((item) => ({
    ...item,
    itemNumber: item.itemNumber.toString(),
  }));
  const item = {
    ...sortedItems[index],
    currentSalesOrderId: currentSalesOrder.id,
    currentPurchaseOrder: currentPurchaseOrder.id,
    currentShipmentId: currentShipment.id,
    currentSalesOrderNumber: currentSalesOrder.number,
  };
  return (
    <TableRow
      key={`${prefix}_${item.id}_${item.itemNumber}__principalRow`}
      className={"row"}
      id={`${item.id}_TableRow`}
      style={{
        ...style,
        display: "flex",
        height: showImage
          ? itemTableRowHeight.WITH_IMAGE
          : itemTableRowHeight.NORMAL,
      }}
    >
      {columns.map((column, index) => (
        <RowSKU
          key={column.id + index}
          focusPOTable={focusPOTable}
          item={item}
          column={column}
          showImage={showImage}
          onChangeData={onChangeData}
          prefix={prefix}
          handlePhotosViewer={handlePhotosViewer}
          isAllowed={isAllowed}
          companyAllowed={companyAllowed}
          isVoided={isVoided}
        />
      ))}
    </TableRow>
  );
}

export default SKURowVirtualized;
