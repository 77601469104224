import React from "react";
import { TableCell } from "@mui/material";
import ProgressStage from "./ProgressStage";
import {
  getDashboardSearchPath,
  getTagsLabel,
  getTheDate,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import numeral from "numeral";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  generalPermissions,
  headerColumns,
  notAllowedToSee,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { NavLink } from "react-router-dom";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import { useUser } from "../../hooks/user";
import TagsModal from "../../components/Modal/TagsModal";
import { projectStages } from "./helpers";
import { dbTables } from "../../api/types/dbTables";
import { checkTagsInItem, tagsByType } from "../../hooks/tags";
import { darkBlue } from "../../helpers/variableColors";
import { getTagStatus } from "../../helpers/salesOrder";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import {
  getDiamondStatus,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";

function OrdersByPhaseTableRow({
  item,
  favorites,
  handleRemoveFavorite,
  headerStages,
  headers,
  tags,
  onUpdateItem,
  isThereColumnSetting = false,
  filters,
  favoritesDB = [],
}) {
  const mismatchTags = tagsByType(tags, TYPE_OF_TAGS.MISMATCH_TAG);
  const salesOrderIds = item.salesOrderIds || [];
  const isAllowed = useIsAllowedFunction();
  const user = useUser();

  function zeroIsBlank(item, isCBM) {
    let dformat = "$0,0.00";
    if (isCBM) {
      dformat = "0.00";
    }
    return numeral(item).format("$0,0.00") === "$0.00"
      ? " "
      : numeral(item).format(dformat);
  }

  function OBFTableRowSelector({ column, enabled, index }) {
    let hasMismatchTag;
    switch (column) {
      case headerColumns.FAVORITE:
        return (
          enabled && (
            <TableCell classes={{ root: "favorite" }} key={index}>
              {getStarFavorite({
                favorites,
                itemId: item.id,
                removeFavorite: () => {
                  removeFavorite({ favoritesDB, id: item.id });
                  handleRemoveFavorite(item);
                },
                addFavorite: () =>
                  addFavorite({
                    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
                    favoritesDB,
                    id: item.id,
                    user,
                  }),
              })}
            </TableCell>
          )
        );

      case headerColumns.DIAMOND:
        return (
          enabled && (
            <TableCell key={index} className="diamond">
              {getDiamondStatus({
                diamondStatus: item.diamondStatus,
                itemStatus: item.status,
              })}
            </TableCell>
          )
        );

      case headerColumns.PO:
        hasMismatchTag = checkTagsInItem(item, mismatchTags, filters.tag);
        return (
          enabled && (
            <TableCell key={index} className="number">
              <div className={"number-table-container"}>
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={item.id}
                    label={getTagsLabel(tags, item.tags).tagsString}
                    tags={getTagsLabel(tags, item.tags).tagsArray}
                    allTags={tags}
                    item={item}
                    currentUser={user}
                    tagReference={dbTables.PURCHASE_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: item.tags,
                      currentTags: filters.tag,
                    })}
                  />
                )}
                <NavLink
                  style={{
                    color: hasMismatchTag ? "red" : darkBlue,
                    cursor: "pointer",
                  }}
                  to={getDashboardSearchPath({
                    salesOrderId: salesOrderIds[0],
                    purchaseOrderId: item.id,
                    shipmentId: item.shipmentIds[0],
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                  })}
                  id="list-view-content-purchaseorder"
                >
                  {item.number}
                </NavLink>
              </div>
            </TableCell>
          )
        );

      case headerColumns.VENDOR:
        return (
          enabled && (
            <TableCell key={index} className="factoryName">
              <TooltipTD
                label={item.factoryName}
                style={{ width: "fit-content" }}
              >
                <NavLink
                  to={`/app/factories/${item.factoryId}`}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "fit-content" ,
                  }}
                  id="list-view-content-factory"
                >
                  {item.factoryName}
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.PO_VALUE:
        return (
          enabled && (
            <TableCell key={index} className="amount" id="total">
              {isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
                ? zeroIsBlank(item.total)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.ORDER_READY_DATE:
        return (
          enabled && (
            <TableCell key={index} className="shipDate" id="shipDate">
              {getTheDate(item.orderReadyDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.PROPOSAL_QUOTE:
        return (
          enabled && (
            <TableCell
              key={index}
              className="taskStageValue"
              style={{
                width: headerStages.proposalQuoteMinWidh,
              }}
            >
              <ProgressStage
                key={item.id + projectStages.PROPOSAL_QUOTE}
                tasks={item.proposalTasks}
                purchaseOrderId={item.id}
                salesOrderId={salesOrderIds[0]}
              />
            </TableCell>
          )
        );

      case headerColumns.PRE_PRODUCTION:
        return (
          enabled && (
            <TableCell
              key={index}
              className="taskStageValue"
              style={{
                width: headerStages.preProductionMinWidh,
              }}
            >
              <ProgressStage
                key={item.id + projectStages.PRE_PRODUCTION}
                tasks={item.preProductionTasks}
                purchaseOrderId={item.id}
                salesOrderId={salesOrderIds[0]}
              />
            </TableCell>
          )
        );

      case headerColumns.PRODUCTION:
        return (
          enabled && (
            <TableCell
              key={index}
              className="taskStageValue"
              style={{
                width: headerStages.productionMinWidh,
              }}
            >
              <ProgressStage
                key={item.id + projectStages.PRODUCTION}
                tasks={item.productionTasks}
                purchaseOrderId={item.id}
                salesOrderId={salesOrderIds[0]}
              />
            </TableCell>
          )
        );

      case headerColumns.BOOKING_TRANSIT:
        return (
          enabled && (
            <TableCell
              key={index}
              className='taskStageValue'
              style={{
                width: headerStages.bookingTransitMinWidh,
              }}
            >
              <ProgressStage
                key={projectStages.BOOKING_TRANSIT}
                tasks={item.bookingTransitTasks}
                purchaseOrderId={item.id}
                salesOrderId={salesOrderIds[0]}
              />
            </TableCell>
          )
        );

      case headerColumns.PAYMENT_BILLING:
        return (
          enabled && (
            <TableCell
              key={index}
              className='taskStageValue'
              style={{
                width: headerStages.paymentBillingMinWidh,
              }}
            >
              <ProgressStage
                key={item.id + projectStages.PAYMENT_BILLING}
                tasks={item.paymentBillingTasks}
                purchaseOrderId={item.id}
                salesOrderId={salesOrderIds[0]}
              />
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return <TableCell key={index} />;
      default:
        return <TableCell key={index} />;
    }
  }

  return (
    <MainRowStyled
      key={
        item.purchaseOrderId
          ? item.id + item.purchaseOrderId
          : item.id + salesOrderIds[0]
      }
      className={cx(mainRowClasses.POGroupTableRow, mainRowClasses.hoverEffect)}
    >
      {headers.map((header) =>
        OBFTableRowSelector({
          column: header.name,
          enabled: header.enabled,
          index: item.id + header.name,
        })
      )}
      {isThereColumnSetting && <TableCell />}
    </MainRowStyled>
  );
}

export default OrdersByPhaseTableRow;
