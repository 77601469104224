import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";
import { onHoverBackground } from "../../helpers/variableColors";

export const NotificationFiltersStyled = styled("div")(() => ({
  "&.taskFilters": {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontSize: 11,
    fontWeight: "bold",
    right: 24,
    top: 12,
    textTransform: "uppercase",
    maxWidth: "100%",
  },
  "& .filterNotificaitionContainer": {
    position: "relative",
    top: 10,
    display: "flex",
    alignItems: "center",
    gap: 10,
    "& .activeUnread": { background: "#e8f2fe" },
    "& .iconClick": {
      cursor: "pointer",
      padding: "6px 7px",
      display: "flex",
      alignItems: "center",
      borderRadius: 6,
      justifyContent: "center",
      height: 25,
    },
  },
  "& .tabFilterContainer": {
    display: "flex",
    height: 20,
    position: "relative",
    color: colors.primaryDark,
  },
  "& .filterContainer": {
    display: "flex",
    padding: "0px 8px",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
    "& img": {
      cursor: "pointer",
      margin: "0px 4px",
    },
  },

  "& .indicator": {
    position: "absolute",
    top: 25,
    width: "100%",
    backgroundColor: "rgb(204, 204, 204)",
    height: 2,
    opacity: 0.6,
  },
  "& .taskIndicator": {
    width: "85%",
    marginLeft: 10,
  },
  "& .showCompleted": {
    fontWeight: 200,
    fontSize: 11,
    "& input": {
      margin: "0 5px 2px 0",
    },
    margin: "8px 0px 8px 18px",
    opacity: 0.6,
    display: "flex",
    alignItems: "center",
  },
  "& .generalFilters": {
    display: "flex",
    alignItems: "baseline",
    fontSize: 11,
    justifyContent: "space-between",
    marginRight: 20,
    marginLeft: 5,
    "& .indicator": {
      position: "absolute",
      background: "rgb(204, 204, 204)",
      height: 2,
      width: "100%",
      top: 26,
      opacity: 0.6,
    },
  },
  "& .selectFilters": {
    display: "flex",
    justifyContent: "flex-start",
    padding: "22px 12px 17px 3px",
    maxWidth: "100%",
  },
}));

export const NotificationContainerStyled = styled("li")(() => ({
  "&.notificationContainer": {
    borderBottom: "3px solid #fff",
    borderLeft: "5px solid #fff",
    display: "flex",
    overflow: "hidden",
    height: "inherit",
  },
  "& .item": {
    width: "100%",
    minHeight: 120,
    padding: "10px 18px",
    cursor: "pointer",
    // backgroundColor: (notification) =>
    //   notification.read ? "unset" : "rgb(246, 251, 255)",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .unreadIndicator": {
      // opacity: (notification) => (!notification.read ? 1 : 0),
      backgroundColor: "#39F",
      minWidth: 8,
      width: 8,
      height: 8,
      borderRadius: "50%",
      border: "1px solid #39F",
    },
    "&:hover": {
      backgroundColor: onHoverBackground,
      "& .unreadIndicator": {
        opacity: 1,
        backgroundColor: "#39F",
        minWidth: 8,
        width: 8,
        height: 8,
        borderRadius: "50%",
        border: "1px solid #39F",
      },
    },
  },
  "& .itemWrapper": {
    width: "100%",
    height: "100%",
    fontSize: 11,
    "& .tradedash-logo-container": {
      width: 36,
      height: 36,
      marginRight: 12,
      fontSize: 22,
      borderRadius: "50%",
      "& img": {
        width: 36,
        height: "100%",
        color: "transparent",
        objectFit: "cover",
        textAlign: "center",
        textIndent: 10000,
      },
    },
  },
  "& .itemHeader": {
    display: "flex",
    alignItems: "center",
  },
  "& .itemContent": {
    marginTop: 9,
    fontSize: 14,
    justifyContent: "flex-start",
  },
  "& .avatar": {
    width: 36,
    height: 36,
    marginRight: 12,
  },
  "& .editedText": {
    position: "absolute",
    right: 16,
    bottom: 0,
    color: "#BDBDBD",
    fontSize: 12,
    fontFamily: "Roboto",
  },
}));

export const NotificationSearchboxStyled = styled("div")(() => ({
  "&.notificationHeaderSearchbox": {
    padding: "3px 20px 10px 7px",
    "& .search": {
      height: 44,
      borderRadius: 3,
      border: "1px solid #A8B9CA",
      backgroundColor: "transparent",
      fontSize: 13,
      fontFamily: "Avenir Next",
      paddingLeft: 9,
      "&::placeholder": {
        color: "#92A1B0",
      },
      "&:focus-within": {
        boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
        borderColor: "#5D92F4",
      },
    },
  },
  "@keyframes rotate": {
    from: {
      transform: "rotate(360deg)",
    },
    to: {
      transform: "rotate(0deg)",
    },
  },
}));
