import { styled } from "@mui/material";

export const PendingDividerStyled = styled("div")(() => ({
  "&.container": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  "& .firstLine": {
    width: "100%",
    height: 2,
    background: "#ff5f58",
  },
  "& .contentText": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    position: "absolute",
    right: 32,
  },
  "& .ArrowUpwardIcon": {
    background: "#FF5F58",
    borderRadius: "50%",
    color: "white",
    width: 16,
    height: 16,
  },
  "& .lineName": {
    fontSize: 11,
    padding: "0px 4px",
    color: "#ff5f58",
    textAlign: "center",
  },
}));
