import React, { useState, useRef, useEffect } from "react";
import DatePicker from "../DatePicker/DatePicker";
import CalendarIcon from "../../assets/flag-icons/calendar.svg";
import PhasePicker from "../DatePicker/PhasePicker";
import IntlMessages from "../../util/IntlMessages";
import classnames from "classnames";
import UserPicker from "../DatePicker/UserPicker";
import { getTheDate, sortObjectsBy } from "../../helpers/helpers";
import AdHocTask from "../../api/model/AdHocTask";
import AttentionModal from "../Modal/AttentionModal";
import moment from "moment";
import { AdhocTaskStyled } from "./styles";
import FilterTab from "./FilterTab";
import { getUserAvatar } from "../../helpers/users";
import { typeOfTask } from "../../api/types/dbTables";
import { GENERAL_PERMISSION_VALUE, PO_STATUS } from "../../helpers/constants";
import { useCurrentShipment } from "../../hooks/shipments";
import { getCorrectTimezone } from "../../helpers/ganttChart";
import {
  getBackgroundColorByType,
  getBorderColorByType,
} from "../../helpers/orderDashboard";
import { DefaultUserIcon } from "../../helpers/iconSvgPath";
import { Button } from "@mui/material";

function AdHocTaskComponent({
  companyUsers,
  createAdHocTask,
  salesOrder,
  purchaseOrder,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  isEditingTask,
}) {
  const [adHocTask, setAdHocTask] = useState(new AdHocTask({}));
  const [openPicker, setOpenPicker] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openPhase, setOpenPhase] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const phaseRef = useRef(null);
  const [lastElementId, setLastElementId] = useState("");
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [descriptionAttentinoModal, setDescriptionAttentinoModal] = useState(
    <React.Fragment></React.Fragment>
  );
  const [tabIndicator, setTabIndicator] = useState(typeOfTask.SALES_ORDER);
  const currentShipment = useCurrentShipment({ purchaseOrder });

  useEffect(() => {
    setEnableSubmit(verifyAdHocTask(adHocTask));
  }, [adHocTask]);

  useEffect(() => {
    setAdHocTask(new AdHocTask({}));
  }, [purchaseOrder.id]);

  function changeField(value, field, elementId) {
    setLastElementId(elementId);
    setAdHocTask({ ...adHocTask, [field]: value });
    setOpenPicker(false);
    setOpenUsers(false);
    setOpenPhase(false);
  }

  function verifyAdHocTask(adHocTask) {
    if (
      adHocTask.description !== "" &&
      adHocTask.stage !== "" &&
      adHocTask.assignedTo !== "" &&
      adHocTask.finishDate !== ""
    ) {
      if (tabIndicator === typeOfTask.SHIPMENT) {
        if (currentShipment && Object.keys(currentShipment).length > 0) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  const currentOrder = {
    [typeOfTask.SALES_ORDER]: salesOrder,
    [typeOfTask.PURCHASE_ORDER]: purchaseOrder,
    [typeOfTask.SHIPMENT]: currentShipment,
  };

  function handleOpenAttentionModal(currentOrder) {
    const currentType = {
      [typeOfTask.SALES_ORDER]: "sales order",
      [typeOfTask.PURCHASE_ORDER]: "purchase order",
      [typeOfTask.SHIPMENT]: "shipment",
    };
    const type = currentType[tabIndicator];
    setDescriptionAttentinoModal(
      <React.Fragment>
        {`This ${type} is
        ${currentOrder.status === PO_STATUS.VOIDED ? "void" : "reassigned"} and
        ad-hoc tasks`}
        <br />
        {`can no longer be created for it`}
      </React.Fragment>
    );
    setOpenAttentionModal(true);
  }

  function isVoidedOrReassignedOrder(currentOrder = {}) {
    if (
      currentOrder.status === PO_STATUS.VOIDED ||
      currentOrder.status === PO_STATUS.REASSIGNED
    ) {
      return true;
    }
    return false;
  }

  function changeFilterTab(filter) {
    if (isVoidedOrReassignedOrder(currentOrder[filter])) {
      setAdHocTask(new AdHocTask({}));
    } else {
      setAdHocTask({ ...adHocTask, shipmentId: "" });
    }
    setTabIndicator(filter);
  }
  const permissionToVerify =
    tabIndicator === typeOfTask.PURCHASE_ORDER
      ? purchaseOrder.factoryId
      : salesOrder.customerId;

  function handleCreateNewTask() {
    const isCreated = createAdHocTask({
      adHocTask: {
        ...adHocTask,
        shipmentId:
          tabIndicator === typeOfTask.SHIPMENT ? currentShipment.id : "",
      },
      type: tabIndicator,
    });
    if (isCreated) {
      setAdHocTask(new AdHocTask({}));
    } else {
      const finishDate = getCorrectTimezone({
        date: moment().valueOf(),
      });
      setAdHocTask({
        ...adHocTask,
        finishDate: finishDate.valueOf(),
      });
    }
  }

  const isOutdated = purchaseOrder.isOutdated;
  return (
    <AdhocTaskStyled
      className="adhocTaskContainer"
      style={{
        background: getBackgroundColorByType({ type: tabIndicator }),
        borderColor: getBorderColorByType({
          type: tabIndicator,
        }),
      }}
    >
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          title="Attention"
          description={descriptionAttentinoModal}
          onClick={() => {
            setOpenAttentionModal(false);
            setDescriptionAttentinoModal(<React.Fragment></React.Fragment>);
          }}
        />
      )}
      <div className={"adhocTaskTypeContainer"}>
        <div className={"tabAdhocTaskType"}>
          <FilterTab
            onClick={changeFilterTab}
            activeTab={tabIndicator}
            value={typeOfTask.SALES_ORDER}
            label="order.dashboard.adhoc.task.sales.order"
            styles={{ fontSize: 11 }}
          />
          <FilterTab
            onClick={changeFilterTab}
            activeTab={tabIndicator}
            value={typeOfTask.PURCHASE_ORDER}
            label="order.dashboard.adhoc.task.purchase.order"
            noTransform={true}
            styles={{ fontSize: 11 }}
          />
          {currentShipment && Object.keys(currentShipment).length > 0 && (
            <FilterTab
              onClick={changeFilterTab}
              activeTab={tabIndicator}
              value={typeOfTask.SHIPMENT}
              label="order.dashboard.adhoc.task.shipment"
              styles={{ fontSize: 11 }}
            />
          )}
          <div className="indicator" />
        </div>
      </div>
      <div className={"adhocTaskInfoWrapper"}>
        <div
          className={"adhocTaskInfoContainer"}
          style={{ opacity: isOutdated ? 0.5 : 1 }}
        >
          <input
            disabled={isOutdated}
            className="adhoc-task-description"
            id="description"
            value={adHocTask.description}
            onChange={(ev) => {
              if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                return;
              }
              setAdHocTask({ ...adHocTask, description: ev.target.value });
              setLastElementId("date-container");
            }}
            type="text"
            placeholder={
              tabIndicator === typeOfTask.SALES_ORDER
                ? "Add a new Sales Order task to this project"
                : tabIndicator === typeOfTask.PURCHASE_ORDER
                ? "Add a new Purchase Order task to this project"
                : "Add a new Shipment task to this project"
            }
            onKeyDown={(ev) => {
              if (ev.key === "Tab") {
                const el = document.getElementById(lastElementId);
                el.click();
              } else if (
                ev.key === "Enter" &&
                !verifyAdHocTask(adHocTask) &&
                !isEditingTask
              ) {
                handleCreateNewTask();
              }
            }}
            tabIndex={0}
            onClick={() => {
              if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                handleOpenAttentionModal(currentOrder[tabIndicator]);
              } else {
                setLastElementId("date-container");
              }
            }}
          />
          <section className="adhoc-task-section">
            <div
              id="date-container"
              style={{ cursor: isOutdated ? "initial" : "" }}
              onClick={() => {
                if (isOutdated) {
                  return;
                }
                if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                  handleOpenAttentionModal(currentOrder[tabIndicator]);
                } else {
                  setOpenPicker(true);
                  setLastElementId("users-container");
                }
              }}
              disabled={isOutdated}
              ref={dateRef}
              className={classnames("date-box", { active: openPicker })}
              tabIndex={1}
            >
              <span>
                {adHocTask.finishDate
                  ? getTheDate(adHocTask.finishDate)
                  : moment(new Date()).format("M/D/YY")}
              </span>
              {!adHocTask.finishDate && <img alt="" src={CalendarIcon} />}
            </div>
            {openPicker && (
              <DatePicker
                el={dateRef.current}
                onChange={(value) =>
                  changeField(value, "finishDate", "users-container")
                }
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                value={adHocTask.finishDate}
                cancelIcon={true}
                onKeyDown={(ev) => {
                  if (ev.key === "Tab") {
                    setOpenPicker(false);
                    const el = document.getElementById(lastElementId);
                    el.click();
                  }
                }}
              />
            )}

            <div
              id="users-container"
              ref={userRef}
              onClick={() => {
                if (isOutdated) {
                  return;
                }
                if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                  handleOpenAttentionModal(currentOrder[tabIndicator]);
                } else {
                  setOpenUsers(true);
                  setLastElementId("phase-container");
                }
              }}
              className={classnames("adhoc-task-user", { active: openUsers })}
              tabIndex={2}
              style={{ cursor: isOutdated ? "initial" : "" }}
            >
              {!adHocTask.assignedTo && (
                <DefaultUserIcon className={"default-user-icon"} />
              )}
              {adHocTask.assignedTo &&
                getUserAvatar({
                  user: companyUsers.find(
                    (companyUser) => companyUser.id === adHocTask.assignedTo
                  ),
                  styles: {
                    width: 24,
                    height: 24,
                    fontSize: 13,
                    padding: 0,
                    minWidth: 0,
                    outline: "2px solid #000",
                  },
                })}
            </div>

            {openUsers && (
              <UserPicker
                el={userRef.current}
                onChange={(value) => {
                  const user =
                    companyUsers.find((user) => user.id === value) || {};
                  const userPermissions = user.permissions || {};
                  const globalPermission =
                    tabIndicator !== typeOfTask.PURCHASE_ORDER
                      ? GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS
                      : GENERAL_PERMISSION_VALUE.ALL_VENDORS;
                  if (
                    user.role !== "SuperAdmin" &&
                    !userPermissions[permissionToVerify] &&
                    !userPermissions[globalPermission]
                  ) {
                    return;
                  }
                  changeField(value, "assignedTo", "phase-container");
                }}
                open={openUsers}
                onClose={() => setOpenUsers(false)}
                value={adHocTask.assignedTo}
                users={companyUsers
                  .filter((user) => user.active)
                  .sort(sortObjectsBy("displayName", false))}
                onKeyDown={(ev) => {
                  if (ev.key === "Tab") {
                    setOpenUsers(false);
                    const el = document.getElementById(lastElementId);
                    el.click();
                  }
                }}
                isTherePermission={true}
                permissionToVerify={permissionToVerify}
                isCustomer={tabIndicator !== typeOfTask.PURCHASE_ORDER}
              />
            )}
            <div
              id="phase-container"
              onClick={() => {
                if (isOutdated) {
                  return;
                }

                if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                  handleOpenAttentionModal(currentOrder[tabIndicator]);
                } else {
                  setOpenPhase(true);
                  setLastElementId("description");
                }
              }}
              style={{ cursor: isOutdated ? "initial" : "" }}
              ref={phaseRef}
              className="adhoc-task-phase"
              tabIndex={3}
            >
              {adHocTask.stage ? (
                <span>
                  <IntlMessages id={adHocTask.stage} />
                </span>
              ) : (
                <span
                  style={{
                    color: "#92A1B0",
                    margin: 0,
                    fontSize: 13,
                    fontWeight: 300,
                  }}
                >
                  Phase
                </span>
              )}
            </div>

            <PhasePicker
              el={phaseRef.current}
              onChange={(value) => changeField(value, "stage", "description")}
              open={openPhase}
              onClose={() => setOpenPhase(false)}
              value={adHocTask.stage}
              onKeyDown={(ev) => {
                if (ev.key === "Tab") {
                  setOpenPhase(false);
                }
              }}
            />
          </section>
        </div>
        <Button
          className={
            enableSubmit || isEditingTask ? "button-enable" : "button-disable"
          }
          variant="contained"
          disabled={enableSubmit || isEditingTask}
          onClick={() => {
            if (isReadOnly) {
              handleReadOnlyModal();
            } else {
              handleCreateNewTask();
            }
          }}
        >
          Submit
        </Button>
      </div>
    </AdhocTaskStyled>
  );
}

export default AdHocTaskComponent;
