import React, { useRef, useState, useEffect } from "react";
import {
  sortObjectsBy,
  isTradeDashEmployee,
  getDateByTimezone,
} from "../../helpers/helpers";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Cancel";
import UserPicker from "../DatePicker/UserPicker";
import LateIcon from "../../assets/flag-icons/red_diamond.svg";
import NearDueIcon from "../../assets/flag-icons/orange_diamond.svg";
import IntlMessages from "../../util/IntlMessages";
import { useIsAllowedFunction } from "../../hooks/permissions";
import CalendarIconAsset from "../../assets/flag-icons/calendar.svg";
import { CalendarIcon } from "../../helpers/iconSvgPath";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useUser } from "../../hooks/user";
import { getUserAvatar } from "../../helpers/users";
import TooltipTD from "../Tooltip/TooltipTD";
import { getNumberOfLateDays, triggerTaskTypes } from "../../helpers/tasks";
import {
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
  taskStatus,
  TASK_MOVED_STATUS,
  TASK_TYPE,
  TOOLTIP_TYPE,
} from "../../helpers/constants";
import { TaskItemContainerStyled } from "./styles";
import { getShipmentBadge } from "../../helpers/shipments";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { getCorrectTimezone } from "../../helpers/ganttChart";
import AttentionModal from "../Modal/AttentionModal";
import { useShipments } from "../../hooks/shipments";
import { cx } from "@emotion/css";
import { CircularProgress } from "@mui/material";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";

function getDiamond(status) {
  switch (status) {
    case "late":
      return (
        <img
          style={{
            opacity: 1,
            top: 10,
            right: 8,
            display: "inline",
            width: 12,
            height: 15,
          }}
          src={LateIcon}
          alt={"LateIcon"}
        />
      );
    case "nearDue":
      return (
        <img
          style={{
            opacity: 1,
            top: 10,
            right: 8,
            display: "inline",
            width: 12,
            height: 15,
          }}
          src={NearDueIcon}
          alt="nearDue"
        />
      );
    default:
      return <span style={{ minWidth: 12 }}></span>;
  }
}

function TaskItem({
  item,
  classesTask,
  isVoid,
  isReassigned,
  hasPermissionToModifiedDueDate,
  changeField,
  companyUsers,
  hasPermissionToReassign,
  user,
  changeStyleTo,
  completeTask,
  onLastFinishDate,
  openPicker,
  onOpenPicker,
  openUsers,
  onOpenUsers,
  showModalNoPermissionToReassign,
  salesOrder,
  onClickOffset,
  editingAdHocTask,
  handleChange,
  onLastElementId,
  lastElementId,
  permissionToVerify,
  onChangeShipment,
  onCleanPendingTask,
  backdropId = "",
}) {
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const isAllowed = useIsAllowedFunction();
  const currentUser = useUser();
  const isReadOnly = isTradeDashEmployee(currentUser);
  const [pending, setPending] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );
  const userTask = companyUsers.find(
    (companyUser) => companyUser.id === item.assignedTo
  );
  const activeCompanyUsers = companyUsers.filter((user) => user.active);
  const shipments = useShipments();

  function liStyles(item) {
    const enableToEdit =
      item.enableToEdit === currentUser.id &&
      item.type === TASK_TYPE.PURCHASE_ORDER
        ? { borderInline: "1px solid #d4dfed" }
        : {};

    return {
      padding: "0px 15px 0px 32px",
      zIndex: backdropId.includes(item.id) ? 1202 : "",
      ...enableToEdit,
    };
  }

  function getDisplayName(userCompleteTask) {
    const currentUser = userCompleteTask || userTask;
    const errorMessage = userCompleteTask ? (
      <IntlMessages id="components.purchaseorderdashboards.task.notassigned" />
    ) : (
      "@User"
    );
    return currentUser && currentUser.displayName
      ? "@" + currentUser.displayName
      : errorMessage;
  }

  function getTaskStatusBadgeInfo({ task, isVoid, isReassigned }) {
    const otherClass = classesTask.includes("completeSize") ? "hiddenText" : "";
    const zIndex = backdropId.includes(item.id) ? 1203 : 3;
    if (task.moved === TASK_MOVED_STATUS.WAITING) {
      return (
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            left: 25,
            margin: "0px 5px",
          }}
        >
          <CircularProgress
            style={{ position: "relative", width: 20, height: 20 }}
          />
        </div>
      );
    } else if (isVoid || isReassigned) {
      return <div className={cx("taskStatusContainer", otherClass)} />;
    } else if (task.status === taskStatus.LATE && !task.complete) {
      return (
        <div
          className={cx("taskStatusContainer", otherClass)}
          style={{ zIndex }}
        >
          <TooltipTD
            type={TOOLTIP_TYPE.DIAMOND}
            label={getNumberOfLateDays({ task: item })}
          >
            {getDiamond(item.status)}
          </TooltipTD>
        </div>
      );
    } else if (task.status === taskStatus.NEAR_DUE && !task.complete) {
      return (
        <div
          className={cx("taskStatusContainer", otherClass)}
          style={{ zIndex }}
        >
          {getDiamond(item.status)}
        </div>
      );
    } else if (task.dayOffset && task.dayOffset !== 0 && item.complete) {
      return (
        <div
          className={cx("taskStatusContainer", otherClass)}
          style={{ cursor: "pointer" }}
        >
          <span
            onClick={(ev) => {
              ev.stopPropagation();
              if (!hasPermissionToModifiedDueDate) {
                handleOpenModalAttention();
              } else if (salesOrder.status === SALES_ORDER_STATUS.VOIDED) {
                return;
              } else if (!item.moved) {
                onClickOffset({ task: item, changeStyleTo });
              }
            }}
            className={cx("dayoffset", {
              early: !item.moved && item.dayOffset < 0,
              late: !item.moved && item.dayOffset > 0,
              disabled: item.moved,
            })}
          >
            <div
              style={{ marginTop: 1, marginRight: item.dayOffset > 9 ? 1 : 0 }}
            >
              {item.dayOffset
                ? item.dayOffset > 0
                  ? `+${Math.abs(item.dayOffset)}`
                  : `-${Math.abs(item.dayOffset)}`
                : ""}
            </div>
          </span>
        </div>
      );
    } else {
      return <div className={cx("taskStatusContainer", otherClass)}></div>;
    }
  }
  const fontWeight = item.complete ? 400 : 700;

  function handleOpenModalAttention() {
    setDescriptionModalAttention(
      <React.Fragment>
        You do not have permission to change task due dates
      </React.Fragment>
    );
    setOpenModalAttention(true);
  }

  function handleCloseModalAttention() {
    setOpenModalAttention(false);
    setDescriptionModalAttention(<React.Fragment></React.Fragment>);
  }

  useEffect(() => {
    if (item.complete) {
      setPending(false);
    }
  }, [item.complete]);

  return (
    <TaskItemContainerStyled className="taskListContainer">
      {getTaskStatusBadgeInfo({ task: item, isVoid, isReassigned })}

      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          onClick={handleCloseModalAttention}
          onClose={handleCloseModalAttention}
          isOpen={openModalAttention}
        />
      )}
      <li
        id={item.id + "task-item"}
        className={cx(classesTask, item.type)}
        key={item.id}
        style={liStyles(item)}
      >
        <div style={{ flex: 1, marginLeft: 4 }} className={"taskItemContainer"}>
          <React.Fragment>
            <CustomCheckbox
              checked={item.complete || false}
              onClick={(ev) => ev.stopPropagation()}
              onChange={(ev) => {
                if (isReadOnly) {
                  return;
                }
                if (!pending) {
                  setPending(true);
                  changeStyleTo("project-task completing");
                  completeTask({
                    task: !item.factoryId ? { ...item } : item,
                    changeStyleTo,
                  });
                  ev.stopPropagation();
                }
              }}
              disabled={
                item.complete ||
                isVoid ||
                isReassigned ||
                item.enableToEdit ||
                pending
              }
              size="small"
              styles={{
                color: "#6B7A89",
                margin: 0,
                background: "white",
                marginLeft: 14,
                padding: 0,
              }}
            />
            {pending && (
              <ClearIcon
                fontSize="small"
                className="cancelIcon"
                onClick={() => {
                  setPending(false);
                  onCleanPendingTask(!item.factoryId ? { ...item } : item);
                  changeStyleTo("project-task");
                }}
              />
            )}
          </React.Fragment>
          {editingAdHocTask ? (
            <div className="description-task-container">
              <input
                id="description-task-item"
                value={item.description}
                style={{ width: "100%", height: "100%", background: "white" }}
                onChange={(ev) =>
                  handleChange(
                    "description",
                    ev.target.value,
                    triggerTaskTypes.AVOID_ACTIVITY_ENTRY
                  )
                }
                onKeyDown={(ev) => {
                  if (ev.key === "Tab") {
                    const el = document.getElementById(lastElementId);
                    el.click();
                  }
                }}
                onClick={() =>
                  onLastElementId("date-container-task-item" + item.id)
                }
              />
            </div>
          ) : (
            <label
              id={item.id + "input"}
              style={{
                margin: "12px 16px 12px 8px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
              className="task-description-wrapper"
            >
              {item.shipmentId &&
                getShipmentBadge({
                  shipment: shipments.find(
                    (shipment) => shipment.id === item.shipmentId
                  ),
                  styles: { margin: "0px 8px 2px 4px" },
                  classes: shipmentBadgeClass.secondary,
                  onChangeShipment: (shipment) => {
                    onChangeShipment(shipment.id);
                  },
                  id: "mediumInverted",
                })}
              <span className="span-task-description">{item.description}</span>
            </label>
          )}
        </div>
        <div
          id={"date-container-task-item" + item.id}
          onClick={
            !isVoid
              ? !item.complete && hasPermissionToModifiedDueDate
                ? () => {
                    if (!item.enableToEdit || editingAdHocTask) {
                      onLastFinishDate(item.finishDate);
                      onOpenPicker(true);
                      onLastElementId("users-container-task-item" + item.id);
                    }
                  }
                : () => {
                    if (item.status !== taskStatus.COMPLETE) {
                      handleOpenModalAttention();
                    }
                  }
              : null
          }
          ref={dateRef}
          className={cx("date-box", { active: openPicker })}
          style={
            editingAdHocTask
              ? {
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {}
          }
        >
          <span className="date-container" style={{ fontWeight, fontSize: 13 }}>
            {item.finishDate
              ? getDateByTimezone({
                  date: item.finishDate,
                })
              : moment(new Date()).format("M/D/YY")}
            <CalendarIcon
              style={{ marginBottom: -1, marginLeft: 4 }}
              svgClass={"calendarIcon"}
            />
          </span>
          {!item.finishDate && <img alt="" src={CalendarIconAsset} />}
        </div>
        {openPicker && (
          <DatePicker
            el={dateRef.current}
            onChange={(value) => {
              if (editingAdHocTask) {
                onLastElementId("users-container-task-item" + item.id);
                if (moment(value).startOf("day") >= moment().startOf("day")) {
                  handleChange(
                    "finishDate",
                    value,
                    triggerTaskTypes.FINZISH_DATE
                  );
                }
              } else {
                let diffDays = moment(value)
                  .endOf("day")
                  .diff(item.finishDate, "days");
                if (diffDays > 0) {
                  diffDays++;
                }
                const startDate = getCorrectTimezone({
                  date: moment(item.startDate)
                    .startOf("day")
                    .add(diffDays, "days")
                    .valueOf(),
                });
                const finishValue = moment(value).endOf("day").valueOf();
                const finishDate = getCorrectTimezone({
                  date: finishValue,
                });
                changeField({
                  taskToUpdate: {
                    ...item,
                    finishDate: finishDate.valueOf(),
                    startDate: startDate.valueOf(),
                  },
                  field: "finishDate",
                  triggerType: triggerTaskTypes.FINISH_DATE,
                });
              }
            }}
            open={openPicker}
            onClose={() => {
              onOpenPicker(false);
            }}
            value={moment(
              getDateByTimezone({
                date: item.finishDate,
              })
            )}
            cancelIcon={true}
            onKeyDown={(ev) => {
              if (ev.key === "Tab") {
                onLastElementId("users-container-task-item" + item.id);
                onOpenPicker(false);
                const el = document.getElementById(lastElementId);
                el.click();
              }
            }}
          />
        )}

        <div
          className="task-user"
          style={
            !item.complete ? { width: "23%" } : { width: "23%", opacity: 0.6 }
          }
        >
          {!item.complete ? (
            <React.Fragment>
              <TooltipTD
                label={getDisplayName()}
                // style={{ width: "100%" }}
                type={TOOLTIP_TYPE.TASK}
              >
                <div
                  id={"users-container-task-item" + item.id}
                  ref={userRef}
                  onClick={() =>
                    !isVoid &&
                    hasPermissionToReassign &&
                    isAllowed(GENERAL_PERMISSION_VALUE.REASSIGN_TASK)
                      ? (!item.enableToEdit || editingAdHocTask) &&
                        onOpenUsers(true)
                      : showModalNoPermissionToReassign()
                  }
                  className={cx("user", { active: openUsers })}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    margin: 0,
                  }}
                >
                  {item.assignedTo &&
                    userTask &&
                    getUserAvatar({
                      user: userTask,
                      styles: {
                        width: 24,
                        height: 24,
                        fontSize: 13,
                        outline: "2px solid #000",
                      },
                    })}
                  {!item.assignedTo && <img alt="" src={PersonIcon} />}
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "auto",
                      fontWeight,
                      fontSize: 13,
                      marginLeft: 5,
                    }}
                  >
                    {item.assignedTo && getDisplayName()}
                  </span>
                  <ExpandMoreIcon className="arrowButton" />
                </div>
              </TooltipTD>
              {hasPermissionToReassign && openUsers && (
                <UserPicker
                  el={userRef.current}
                  onChange={(value) => {
                    const user = companyUsers.find((user) => user.id === value);
                    const globalPermission = !item.factoryId
                      ? GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS
                      : GENERAL_PERMISSION_VALUE.ALL_VENDORS;
                    const userPermissions = user.permissions || {};
                    if (
                      user.role !== "SuperAdmin" &&
                      !userPermissions[permissionToVerify] &&
                      !userPermissions[globalPermission]
                    ) {
                      return;
                    } else if (editingAdHocTask) {
                      handleChange("assignedTo", value);
                    } else {
                      changeField({
                        taskToUpdate: { ...item, assignedTo: value },
                        field: "assignedTo",
                        triggerType: triggerTaskTypes.ASSIGNED_TO,
                      });
                    }
                  }}
                  open={openUsers}
                  onClose={() => onOpenUsers(false)}
                  value={item.assignedTo}
                  users={activeCompanyUsers.sort(
                    sortObjectsBy("displayName", false)
                  )}
                  onKeyDown={(ev) => {
                    if (ev.key === "Tab") {
                      onLastElementId("");
                      onOpenUsers(false);
                    }
                  }}
                  permissionToVerify={permissionToVerify}
                  isTherePermission={true}
                  isCustomer={!item.factoryId}
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: 0,
                }}
                className={"data"}
              >
                <TooltipTD label={getDisplayName()} type={TOOLTIP_TYPE.TASK}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    {getUserAvatar({
                      user: user,
                      styles: {
                        width: 24,
                        height: 24,
                        fontSize: 13,
                        outline: "2px solid #000",
                      },
                    })}
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "auto",
                        fontWeight,
                        fontSize: 13,
                        marginLeft: 5,
                      }}
                    >
                      {user && getDisplayName(user)}
                    </span>
                    <ExpandMoreIcon className="arrowButton" />
                  </div>
                </TooltipTD>
              </div>
            </React.Fragment>
          )}
        </div>
      </li>
    </TaskItemContainerStyled>
  );
}

export default TaskItem;
