import React, { useState } from "react";
import folderPlaceholder from "../../assets/flag-icons/folder-placeholder.svg";
import filePlaceholder from "../../assets/flag-icons/file-placeholder.png";
import orderPlaceholder from "../../assets/flag-icons/order-placeholder.png";
import EditableLabel from "../TextFields/EditableLabel";
import DocumentOptions from "./DocumentOptions";
import FoldersStructure from "./FoldersStructure";
import {
  onRenameDocumentsPlaceholders,
  isTradeDashEmployee,
  handleUploadPermissionDependencies,
} from "../../helpers/helpers";
import ModalPermission from "./ModalPermission";
import {
  getselectedUsersObj,
  isShipmentRootFile,
} from "../../helpers/documents";
import { useUser, useCompanyUsers } from "../../hooks/user";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { errorDocumentName, TYPE_OF_FILE } from "../../helpers/constants";
import AttentionModal from "../Modal/AttentionModal";
import { updateDoc } from "firebase/firestore";
import { Collapse, ListItem, ListItemIcon } from "@mui/material";
import { ListItemDocumentStyled } from "./styles";

function ListItemDocument({
  currentDocument,
  handleDeleteFolder,
  onAddNewFolder,
  documents,
  companyID,
  dbTable,
  permissionGroupsDB,
  hasParentPermission = false,
  parentDocument,
  onChangeShipment = () => {},
}) {
  const defaultFolderName = "New Folder";
  const [currentDocumentName, setCurrentDocumentName] = useState(
    currentDocument.name
  );
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");
  const [createNewFolder, setCreateNewFolder] = useState(false);
  const [open, setOpen] = useState(
    isShipmentRootFile({ scope: dbTable, folderName: currentDocument.name })
  );
  const [folderName, setFolderName] = useState(defaultFolderName);
  const [typeDocument, setTypeDocument] = useState(TYPE_OF_FILE.FOLDER);
  const [openModalPermission, setOpenModalPermission] = useState(false);
  const currentUser = useUser();
  const users = useCompanyUsers({
    showBotUser: false,
    showInactiveUsers: false,
  });
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);

  let companyUsers = [];
  if (!hasParentPermission) {
    companyUsers = users
      ? users.filter((user) => user.companyId === companyID)
      : [];
  } else {
    const selectedUsersObj = getselectedUsersObj(
      parentDocument.permissionUsers,
      parentDocument.permissionGroups,
      permissionGroupsDB
    );
    companyUsers = users
      ? users
          .filter((user) => user.companyId === companyID)
          .filter((user) => selectedUsersObj && selectedUsersObj[user.id])
      : [];
  }

  async function onUpdateDocument() {
    if (currentDocumentName.toString().trim() === "") {
      setCurrentDocumentName(currentDocument.name);
    } else {
      let newName = await onRenameDocumentsPlaceholders(
        currentDocument,
        currentUser,
        documents,
        currentDocumentName
      );
      setCurrentDocumentName(newName);
    }
  }

  function handleOnChange(event) {
    setCurrentDocumentName(event.target.value);
  }

  function handleSaveFolder() {
    if (folderName.includes("/")) {
      setOpenModalAttention(true);
      setDescriptionModalAttention(errorDocumentName.folder);
      return;
    }
    onAddNewFolder(currentDocument, folderName, typeDocument);
    setCreateNewFolder(false);
    setFolderName(defaultFolderName);
  }

  function handleCancelSaveFolder() {
    setCreateNewFolder(false);
    setFolderName(defaultFolderName);
  }

  function onOpenContentAndAddFolder(typeDocument) {
    setOpen(true);
    setCreateNewFolder(true);
    setTypeDocument(typeDocument);
    if (typeDocument === TYPE_OF_FILE.FOLDER) {
      setFolderName(defaultFolderName);
    } else {
      setFolderName("New File");
    }
  }

  function handleOpenPermissions() {
    setOpenModalPermission(true);
  }

  async function handleUploadUserPermissions(permissions) {
    const { permissionUsers, permissionGroups } = permissions;
    updateDoc(currentDocument.ref, { permissionUsers, permissionGroups });
    setOpenModalPermission(false);
    if (currentDocument.child.length > 0) {
      handleUploadPermissionDependencies(
        currentDocument,
        permissionUsers,
        permissionGroups,
        permissionGroupsDB
      );
    }
  }

  function handleClickItem() {
    setOpen(!open);
  }

  function handleReadOnlyModal() {
    setReadOnlyModal(true);
  }

  return (
    <ListItemDocumentStyled className="listenItemDocument">
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      {openModalAttention && (
        <AttentionModal
          description={descriptionModalAttention}
          isOpen={openModalAttention}
          onClose={() => setOpenModalAttention(false)}
          title={<span>Attention</span>}
          onClick={() => setOpenModalAttention(false)}
        />
      )}
      {openModalPermission && (
        <ModalPermission
          title="Access Groups"
          open={openModalPermission}
          onClose={() => setOpenModalPermission(false)}
          file={currentDocument}
          handleCancel={() => {
            setOpenModalPermission(false);
          }}
          handleDone={handleUploadUserPermissions}
          companyUsers={companyUsers}
          permissionGroupsDB={permissionGroupsDB}
          parentDocument={parentDocument}
        />
      )}
      <ListItem className={"listItemFolder"} onClick={handleClickItem}>
        {!isShipmentRootFile({
          scope: dbTable,
          folderName: currentDocument.name,
        }) && (
          <img
            className={"imgListItemIconOrderPlaceholder"}
            src={orderPlaceholder}
            alt="order"
          />
        )}
        {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
          <ListItemIcon className={"listItemIcon"}>
            <img
              className={"imgListItemIcon"}
              src={folderPlaceholder}
              alt="folder"
            />
          </ListItemIcon>
        ) : (
          <ListItemIcon className={"listItemIcon"}>
            <img
              className={"imgListItemIcon"}
              src={filePlaceholder}
              alt="folder"
            />
          </ListItemIcon>
        )}
        <div className={"listItemContent"}>
          <div className={"editableLabelContainer"}>
            <EditableLabel
              label={currentDocumentName}
              editable={true}
              disabled={isShipmentRootFile({
                scope: dbTable,
                folderName: currentDocument.name,
              })}
              onChange={handleOnChange}
              children={currentDocumentName}
              onValid={onUpdateDocument}
              isReadOnly={isTradeDashEmployee(currentUser)}
              handleReadOnlyModal={handleReadOnlyModal}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "250px",
                maxWidth: "250px",
              }}
            />
          </div>
          <DocumentOptions
            handleDeleteFolder={
              isTradeDashEmployee(currentUser)
                ? handleReadOnlyModal
                : handleDeleteFolder
            }
            currentDocument={currentDocument}
            onAddNewFolder={
              isTradeDashEmployee(currentUser)
                ? handleReadOnlyModal
                : onOpenContentAndAddFolder
            }
            handleOpenPermissions={
              isTradeDashEmployee(currentUser)
                ? handleReadOnlyModal
                : handleOpenPermissions
            }
            dbTable={dbTable}
          />
        </div>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          className={
            currentDocument.type === TYPE_OF_FILE.FOLDER
              ? "listItemDocumentCollapseItemsFolder"
              : "listItemDocumentCollapseItems"
          }
          style={{ paddingLeft: "12px" }}
        >
          {currentDocument.type === TYPE_OF_FILE.FOLDER && (
            <FoldersStructure
              documents={currentDocument.child ? currentDocument.child : []}
              handleDeleteFolder={handleDeleteFolder}
              onChangeShipment={onChangeShipment}
              onAddNewFolder={onAddNewFolder}
              handleSaveFolder={handleSaveFolder}
              createDocument={createNewFolder}
              folderName={folderName}
              onChange={(event) => setFolderName(event.target.value)}
              handleCancelSaveFolder={handleCancelSaveFolder}
              typeDocument={typeDocument}
              companyID={companyID}
              dbTable={dbTable}
              permissionGroupsDB={permissionGroupsDB}
              hasParentPermission={true}
              parentDocument={currentDocument}
              isReadOnly={isTradeDashEmployee(currentUser)}
            />
          )}
        </div>
      </Collapse>
    </ListItemDocumentStyled>
  );
}

export default ListItemDocument;
