import React, { useState, useEffect, Fragment } from "react";
import ItemTableButtons from "../Buttons/ItemTableButtons";
import { useTags, tagsByType } from "../../hooks/tags";
import { useCompanyId, useStorage } from "../../hooks/index";
import { useCustomers } from "../../hooks/customers";
import { TableContainerStyled } from "../Tables/styles";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import {
  filterSORowTableItem,
  formatCsvPOtable,
  getArrowIconSKU,
  parseRowTableItem,
  saveItem,
  updateSOTag,
} from "../../helpers/itemsTableHelper";
import { useCurrentShipment, useShipments } from "../../hooks/shipments";
import { SALES_ORDER_STATUS, TYPE_OF_TAGS } from "../../helpers/constants";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import memoize from "memoize-one";
import { FixedSizeList as List } from "react-window";
import { itemTableRowHeight } from "../../helpers/orderDashboard";
import {
  useCompanyIsAllowed,
  useIsAllowedFunction,
} from "../../hooks/permissions";
import { csvSOHeader, pdfSOHeader } from "../../helpers/itemsTableColumns";
import HeaderItemTable from "../Tables/HeaderItemTable";
import { onChangeSoSKUData } from "../../helpers/orderDashboardRefactored";
import { colors } from "../../assets/jss/variables";
import { useSalesOrders } from "../../hooks/salesOrders";
import { updateDoc } from "firebase/firestore";
import ExpandSimpleTableSKUModal from "../Modal/ExpandSimpleTableSKUModal";
import RowEmptySimple from "../Tables/RowEmptySimple";
import SKURowVirtualized from "./SKURowVirtualized";
import ViewerSKU from "./ViewerSKU";
import { trace } from "firebase/performance";
import { performanceFirebase } from "../../firebase";
import { difference } from "lodash";
import {
  Collapse,
  Grid,
  Grow,
  Snackbar,
  Table,
  TableBody,
} from "@mui/material";

const createItemData = memoize((classes = {}, columns, data) => ({
  columns,
  classes,
  items: data,
}));

function SOTableSKU({
  prefix,
  columnsTable = [],
  dataTable = [],
  currentPurchaseOrder = {},
  currentSalesOrder = {},
  purchaseOrders = [],
  focusPOTable = () => {},
  handleShowImage = () => {},
  isModal,
  modalShowImage,
  modalCleanSearchBox,
  modalOnWriteSearch,
  modalHandleSearchBox,
  modalFilterText,
  style,
  hasMismatchTag = false,
}) {
  const [imagesTableStorage, setImagesTableStorage] =
    useStorage("item_table_images");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const companyId = useCompanyId();
  const customers = useCustomers();
  const tags = useTags();
  const shipments = useShipments();
  const [showImage, setShowImage] = useState(
    !imagesTableStorage[prefix] || !!modalShowImage
  );
  const [rowTable, setRowTable] = useState(dataTable);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyTable, setIsCopyTable] = useState(true);
  const [openViewer, setOpenViewer] = useState(false);
  const [sortTable, setSortTable] = useState({
    sortBy: "itemNumber",
    asc: true,
  });
  const isAllowed = useIsAllowedFunction();
  const [expandModal, setExpandModal] = useState(false);
  const [photosViewer, setPhotosViewer] = useState([
    { src: "", number: "", description: "" },
  ]);

  const salesOrders = useSalesOrders();
  const currentShipmentDB = useCurrentShipment({
    purchaseOrder: currentPurchaseOrder,
  });

  const useCompanyAllowed = (id) => {
    return useCompanyIsAllowed(id, companyId);
  };

  useEffect(() => {
    if (difference(dataTable, rowTable)) {
      setRowTable(dataTable);
    }
  }, [dataTable]);

  const saveTags = ({ allRows }) => {
    try {
      updateSOTag({
        items: allRows,
        purchaseOrders,
        tags: tagsByType(tags, TYPE_OF_TAGS.MISMATCH_TAG),
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  async function onChangeData({ item, row }) {
    const traceInstance = trace(performanceFirebase, "dashboard_update_so_sku");
    traceInstance.start();
    setIsLoading(true);
    const newRow = onChangeSoSKUData({
      item,
      row,
      currentSalesOrder,
    });
    await saveItem({ newRow });
    const allRows = rowTable.map((row) =>
      row.id === newRow.id ? newRow : row
    );
    setRowTable(allRows);
    saveTags({ allRows });

    const cpyItems = allRows.map((soItem) => ({
      id: soItem.id,
      cbmMaster: soItem.cbmMaster,
      description: soItem.description,
      image: [soItem.image[0] || ""],
      itemNumber: soItem.itemNumber,
      orderQuantity: parseFloat(
        soItem.salesOrderQuantity[currentSalesOrder.id] || 0
      ),
      piecesPerMaster: soItem.piecesPerMaster,
      price: parseFloat(soItem.salesOrderPrice[currentSalesOrder.id] || 0),
    }));
    await updateDoc(currentSalesOrder.ref, {
      items: cpyItems,
    });
    setIsLoading(false);
    traceInstance.stop();
  }

  const handleChangeShowImage = (value) => {
    setShowImage(value);
    handleShowImage(value);
  };

  const handleChange = (event, forceStatus) => {
    const showImage = event ? event.target.checked : forceStatus;
    setImagesTableStorage(prefix, !showImage);
    setShowImage(showImage);
    handleShowImage(showImage);
  };

  const currentRowTable = filterSORowTableItem({
    rowTable,
    searchText: modalFilterText,
  });

  const actualRow = parseRowTableItem({
    rows: currentRowTable,
    resetNew: false,
    sortTable,
    currentIdPath: {
      currentShipmentId: currentShipmentDB.id,
      currentSalesOrderId: currentSalesOrder.id,
      currentPurchaseOrder: currentPurchaseOrder.id,
    },
    columnsTable,
    purchaseOrders,
  });

  function handlePhotosViewer({ open, photos = [] }) {
    setOpenViewer(open);
    if (photos.length > 0) {
      setPhotosViewer(photos);
    } else {
      setPhotosViewer([{ src: "" }]);
    }
  }

  const getHeaderByScope = () =>
    csvSOHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const getPDFHeaderByScope = () =>
    pdfSOHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const handleSorting = (column, isSorting) => {
    if (column.sort) {
      if (!isSorting) {
        setSortTable({
          sortBy: column.id,
          asc: false,
        });
      } else {
        setSortTable({
          sortBy: column.id,
          asc: !sortTable.asc,
        });
      }
    }
  };

  const itemData = createItemData(
    {},
    isCopyTable
      ? columnsTable
      : columnsTable.filter((column) => column.id !== ""),
    actualRow
  );

  const idExpandModal = isModal ? "ExpandModal" : "";
  return (
    <Fragment>
      {showImage && (
        <ViewerSKU
          showImage={showImage}
          handlePhotosViewer={handlePhotosViewer}
          photosViewer={photosViewer}
          openViewer={openViewer}
        />
      )}

      {expandModal && !isModal && (
        <ExpandSimpleTableSKUModal
          isOpen={expandModal}
          columnsTable={columnsTable}
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          rowTable={rowTable}
          setExpandModal={setExpandModal}
          showTable={true}
          key={`so_expand`}
          purchaseOrders={purchaseOrders}
          handleShowImage={(show) => {
            handleChange(false, show);
          }}
          modalShowImage={showImage}
          prefix={prefix}
          hasMismatchTag={hasMismatchTag}
          focusPOTable={focusPOTable}
        />
      )}

      <ItemTableButtons
        isModal={isModal}
        idExpandModal={idExpandModal}
        buttonsStyle={style}
        isAllOpen={true}
        handleHiddenImages={() => {
          let currentShowImage = showImage;
          handleChangeShowImage(false);
          if (currentShowImage) {
            setTimeout(() => {
              handleChangeShowImage(currentShowImage);
            }, 500);
          }
        }}
        csvData={formatCsvPOtable({
          rows: actualRow,
          shipments,
          customers,
          currentShipment: currentShipmentDB,
          currentSalesOrder,
        })}
        prefix={prefix}
        tableHeader={getHeaderByScope()}
        onExpandModal={() => setExpandModal(true)}
        pdfTableHeader={getPDFHeaderByScope()}
        handleOnCopyTable={(value) => setIsCopyTable(value)}
        cleanSearchBox={modalCleanSearchBox}
        onWriteSearch={modalOnWriteSearch}
        handleSearchBox={modalHandleSearchBox}
        handleOpenSnackBar={(value) =>
          setTimeout(() => {
            setOpenSnackBar(value);
          }, 50)
        }
        hasMismatchTag={hasMismatchTag}
      />
      <Grid container className="shipment-table-container">
        <Grid
          item
          xs={12}
          className="shipment-table-content"
          style={{ padding: "0px", position: "relative" }}
        >
          {isLoading && (
            <LoadingBackdrop
              size={60}
              backdropStyle={{
                position: "fixed",
                zIndex: 10,
              }}
              withLogo={true}
            />
          )}
          <Collapse
            className="collapse-item-table-container"
            in={true}
            timeout="auto"
            unmountOnExit
          >
            <TableContainerStyled
              className={isModal ? "" : "shipmentTableContainerV2"}
              style={{
                maxWidth: style.maxWidth,
                outline: `2px solid ${colors.dividerColor}`,
                background: "white",
                overflowY: "hidden",
                height: "auto",
              }}
              id={`SOTableContainer${idExpandModal}`}
            >
              <Table
                stickyHeader
                aria-label="customized table"
                style={{
                  ...style,
                  width: "100%",
                  background: "white",
                  position: "relative",
                  height: "calc(100vh - 185px)",
                }}
                id={`itemTable${prefix}${idExpandModal}`}
                className={idExpandModal ? "modalExpand" : ""}
              >
                <HeaderItemTable
                  columnsTable={
                    isCopyTable
                      ? columnsTable
                      : columnsTable.filter((column) => column.id !== "")
                  }
                  getArrowIcon={(value, style) =>
                    getArrowIconSKU({
                      isSorting: value,
                      additionalStyle: style,
                      sortTable,
                    })
                  }
                  handleChange={handleChange}
                  handleSorting={handleSorting}
                  handleShowImages={(value) => {
                    handleChange(false, value);
                  }}
                  prefix={prefix}
                  showImage={showImage}
                  sortTable={sortTable}
                  purchaseOrders={purchaseOrders}
                  currentShipment={currentShipmentDB}
                  isCopyTable={isCopyTable}
                />
                <TableBody>
                  {openSnackBar && (
                    <Snackbar
                      className={"snackbar-table-link"}
                      open={openSnackBar}
                      onClose={() => setOpenSnackBar(false)}
                      TransitionComponent={Grow}
                      ContentProps={{
                        "aria-describedby": "message-id",
                      }}
                      style={{ position: "absolute !important", top: 100 }}
                      autoHideDuration={1500}
                      message={
                        <span style={{ margin: "auto" }} id="message-id">
                          Copied to clipboard
                        </span>
                      }
                    />
                  )}
                  {actualRow.length === 0 && (
                    <RowEmptySimple
                      columns={columnsTable}
                      searchText={modalFilterText}
                    />
                  )}
                  <AutoSizer>
                    {({ height, width }) => {
                      const sizeRow = showImage
                        ? itemTableRowHeight.WITH_IMAGE
                        : itemTableRowHeight.NORMAL;
                      const heightSize = isCopyTable
                        ? height
                        : actualRow.length * sizeRow;
                      return (
                        <List
                          className="list"
                          height={heightSize}
                          width={width}
                          itemCount={actualRow.length}
                          itemSize={sizeRow}
                          itemData={itemData}
                        >
                          {({ data, index, style }) => {
                            return (
                              <SKURowVirtualized
                                key={index + "SOlistRow"}
                                data={data}
                                index={index}
                                style={style}
                                currentPurchaseOrder={currentPurchaseOrder}
                                currentSalesOrder={currentSalesOrder}
                                currentShipmentDB={currentShipmentDB}
                                salesOrders={salesOrders}
                                shipments={shipments}
                                showImage={showImage}
                                onChangeData={onChangeData}
                                handlePhotosViewer={handlePhotosViewer}
                                isAllowed={isAllowed}
                                companyAllowed={useCompanyAllowed}
                                focusPOTable={focusPOTable}
                                purchaseOrders={purchaseOrders}
                                prefix={prefix}
                                isVoided={
                                  currentSalesOrder.status ===
                                  SALES_ORDER_STATUS.VOIDED
                                }
                              />
                            );
                          }}
                        </List>
                      );
                    }}
                  </AutoSizer>
                </TableBody>
              </Table>
            </TableContainerStyled>
          </Collapse>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default SOTableSKU;
