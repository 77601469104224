import React from "react";
import { useUser } from "../../hooks/user";
import { useTags } from "../../hooks/tags";
import { useIsAllowedFunction } from "../../hooks/permissions";
import TagsModal from "../../components/Modal/TagsModal";
import {
  getDashboardSearchPath,
  getTagsLabel,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { NavLink } from "react-router-dom";
import { getTagStatus } from "../../helpers/salesOrder";
import { dbTables } from "../../api/types/dbTables";
import { HeaderTaskGroup } from "./styles";

function TaskGroupHeader({
  salesOrder = {},
  purchaseOrder = {},
  shipment = {},
  SONumber,
  PONumber,
  handleUpdateItem = () => {},
  currentTags = [],
  factoryName,
  customerName,
  currentGroup = [],
}) {
  const salesOrderId = salesOrder.id;
  const purchaseOrderId = purchaseOrder.id || salesOrder.purchaseOrderIds?.[0];
  const shipmentId = shipment.id;
  const salesOrderTag = salesOrder.tags || [];
  const purchaseOrderTag = purchaseOrder.tags || [];
  const user = useUser();
  const tags = useTags();
  const isAllowed = useIsAllowedFunction();

  const currentUser = useUser();

  return (
    <HeaderTaskGroup className={"poHeader"} id="headerCell">
      <div className={"groupInfoContainer"}>
        {isAllowed("see_tags") && (
          <TagsModal
            label={getTagsLabel(tags, salesOrderTag).tagsString}
            tags={getTagsLabel(tags, salesOrderTag).tagsArray}
            allTags={tags}
            isReadOnly={isTradeDashEmployee(user)}
            item={{
              ...salesOrder,
              isGroupByPO: true,
              salesOrderId,
              purchaseOrderId,
            }}
            currentUser={currentUser}
            tagReference={dbTables.SALES_ORDERS}
            onUpdateItem={handleUpdateItem}
            isThereItemRef={false}
            isWhiteIcon={true}
            status={getTagStatus({
              tags: salesOrderTag,
              currentTags: currentTags,
            })}
          />
        )}
        <NavLink
          to={getDashboardSearchPath({
            salesOrderId: salesOrderId,
            purchaseOrderId: purchaseOrderId,
            shipmentId: shipmentId,
            section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
          })}
          id={salesOrderId}
        >
          SO# {SONumber}
        </NavLink>
      </div>
      <div className={"groupInfoContainer"}>
        {isAllowed("see_tags") && (
          <TagsModal
            label={getTagsLabel(tags, purchaseOrderTag).tagsString}
            tags={getTagsLabel(tags, purchaseOrderTag).tagsArray}
            allTags={tags}
            item={{
              ...purchaseOrder,
              isGroupByPO: true,
              salesOrderId,
              purchaseOrderId,
            }}
            currentUser={currentUser}
            tagReference={dbTables.PURCHASE_ORDERS}
            isReadOnly={isTradeDashEmployee(user)}
            onUpdateItem={handleUpdateItem}
            isThereItemRef={false}
            isWhiteIcon={true}
            status={getTagStatus({
              tags: purchaseOrderTag,
              currentTags: currentTags,
            })}
          />
        )}
        <NavLink
          style={{
            color: "#FFF",
            textDecoration: "underline",
            position: "sticky",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          to={getDashboardSearchPath({
            salesOrderId: salesOrderId,
            purchaseOrderId: purchaseOrderId,
            shipmentId: shipmentId,
            section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
          })}
        >
          {
            <span
              className={"groupFilterHeader"}
              style={{
                textDecoration: "underline",
                minWidth: 100,
              }}
            >
              PO# {PONumber}
            </span>
          }
        </NavLink>
      </div>
      <div className={"groupInfoContainer"}>{customerName}</div>
      <div className={"groupInfoContainer"}>{factoryName}</div>
      <div className={"groupInfoContainer"} id="taskSize">
        {currentGroup.length} Tasks
      </div>
    </HeaderTaskGroup>
  );
}

export default TaskGroupHeader;
