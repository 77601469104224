import React, { useEffect, useState } from "react";
import _ from "lodash";
import AttentionModal from "../Modal/AttentionModal";
import { SelectAllNoneStyled } from "./styles";
import userTypes from "../../api/types/userTypes";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import { Switch } from "@mui/material";

const categories = ["Vendors", "Customers"];
function UserSpecificPermissions({
  user = {},
  role,
  companyUsers = [],
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  permissions = [],
  customerList = [],
  factoryList = [],
}) {
  const [openModal, setOpenModal] = useState(false);
  const [customerPermissions, setCustomerPermissions] = useState({});
  const [vendorPermissions, setVendorPermissions] = useState({});

  const [descriptionModal, setDescriptionModal] = useState(
    <React.Fragment></React.Fragment>
  );
  const currentUser =
    companyUsers.find((cuser) => (user ? cuser.id === user.id : {})) || {};

  useEffect(() => {
    const userPermission = currentUser.permissions || {};
    if (
      customerList.length > 0 &&
      currentUser &&
      Object.keys(userPermission).length >= 0
    ) {
      let currentPermission = {};
      const globalPermissionId = GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS;
      customerList.forEach(
        (customer) =>
          (currentPermission = {
            ...currentPermission,
            [customer.id]: !!userPermission[customer.id],
            [globalPermissionId]: !!userPermission[globalPermissionId],
          })
      );
      if (!_.isEqual(currentPermission, customerPermissions)) {
        setCustomerPermissions(currentPermission);
      }
    }
    if (
      factoryList.length > 0 &&
      currentUser &&
      Object.keys(userPermission).length >= 0
    ) {
      let currentPermission = {};
      const globalPermissionId = GENERAL_PERMISSION_VALUE.ALL_VENDORS;
      factoryList.forEach(
        (factory) =>
          (currentPermission = {
            ...currentPermission,
            [factory.id]: !!userPermission[factory.id],
            [globalPermissionId]: !!userPermission[globalPermissionId],
          })
      );
      if (!_.isEqual(currentPermission, vendorPermissions)) {
        setVendorPermissions(currentPermission);
      }
    }
  }, [currentUser]);

  function openAttentionModal({ role, category }) {
    if (role === userTypes.SUPER_ADMIN) {
      setDescriptionModal(
        <React.Fragment>
          <span>
            The Group SuperAdmin <strong>always</strong> has all the permissions
            turned on
          </span>
        </React.Fragment>
      );
    } else {
      setDescriptionModal(
        <React.Fragment>
          <span>
            This role is configured to always allow all {category} <br />
            Permission: <strong> Can see:</strong> all {category}
          </span>
        </React.Fragment>
      );
    }
    setOpenModal(true);
  }

  function closeAttentionModal() {
    setOpenModal(false);
  }

  function changePermission({ currentPermissionList, permission }) {
    const newPermission = {
      ...vendorPermissions,
      ...customerPermissions,
      [permission.value]: !currentPermissionList[permission.value],
    };
    updateDoc(doc(firestore, `${dbTables.USERS}/${currentUser.id}`), {
      permissions: {
        ...newPermission,
      },
    });
  }

  function getPermissionBy(category, value) {
    if (category === "Vendors") {
      let objToUpdate = {};
      factoryList.map((factory) => (objToUpdate[factory.id] = value));
      return { objToUpdate };
    } else if (category === "Customers") {
      let objToUpdate = {};
      customerList.map((customer) => (objToUpdate[customer.id] = value));
      return { objToUpdate };
    }
  }

  function setAllPermissions(category, value) {
    if (isReadOnly) {
      return;
    }
    const { objToUpdate } = getPermissionBy(category, value);
    const updatePermissionData = {
      ...vendorPermissions,
      ...customerPermissions,
      ...objToUpdate,
    };
    updateDoc(doc(firestore, `${dbTables.USERS}/${currentUser.id}`), {
      permissions: {
        ...updatePermissionData,
      },
    });
  }

  return (
    <div className="user-specific-permissions">
      {openModal && (
        <AttentionModal
          isOpen={openModal}
          onClose={closeAttentionModal}
          title="Attention"
          description={descriptionModal}
          onClick={closeAttentionModal}
        />
      )}
      {user &&
        currentUser &&
        categories.map((category, indexCategory) => {
          let list = [];
          let enabledAllEntity = false;
          let currentPermissionList = [];
          let globalPermission = "";
          if (category === "Vendors") {
            list = factoryList.map((item) => ({
              value: item.id,
              label: item.name,
              tooltip: "Allow view of this factory",
            }));
            currentPermissionList = vendorPermissions;
            globalPermission = GENERAL_PERMISSION_VALUE.ALL_VENDORS;
            enabledAllEntity = !!vendorPermissions[globalPermission];
          }
          if (category === "Customers") {
            list = customerList.map((item) => ({
              value: item.id,
              label: item.name,
              tooltip: "Allow view of this customer",
            }));
            currentPermissionList = customerPermissions;
            globalPermission = GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS;
            enabledAllEntity = !!customerPermissions[globalPermission];
          }
          return (
            <React.Fragment key={category + indexCategory}>
              <div className={"header-user-permission"}>
                <h6>{category}</h6>
                {role.name !== userTypes.SUPER_ADMIN && (
                  <SelectAllNoneStyled className="selectAllNoneContainer">
                    <span style={{ color: "gray" }}>
                      Can see all
                      <Switch
                        color="primary"
                        size="small"
                        checked={enabledAllEntity}
                        onChange={(e) => {
                          changePermission({
                            currentPermissionList,
                            permission: { value: globalPermission },
                          });
                        }}
                      />
                    </span>
                    {!enabledAllEntity && (
                      <div>
                        <span
                          className="select-all-button"
                          onClick={() => setAllPermissions(category, true)}
                        >
                          Select all
                        </span>
                        -
                        <span
                          className="select-none-button"
                          onClick={() => setAllPermissions(category, false)}
                        >
                          Clear
                        </span>
                      </div>
                    )}
                  </SelectAllNoneStyled>
                )}
              </div>
              <ul key={currentUser.id} style={{ marginTop: 10 }}>
                {list.map((permission) => (
                  <li
                    key={permission.value}
                    style={{ padding: 3, width: "100%" }}
                  >
                    <label
                      onClick={() => {
                        if (
                          role.name === userTypes.SUPER_ADMIN ||
                          enabledAllEntity
                        ) {
                          openAttentionModal({
                            role: currentUser.role,
                            category: category.toLocaleLowerCase(),
                          });
                        }
                      }}
                      className="switch"
                      style={{ margin: 0, minWidth: 77 }}
                    >
                      <input
                        onChange={(ev) => {
                          if (isReadOnly) {
                            handleReadOnlyModal();
                          } else {
                            changePermission({
                              currentPermissionList,
                              permission,
                            });
                          }
                        }}
                        disabled={
                          role.name === userTypes.SUPER_ADMIN ||
                          isReadOnly ||
                          enabledAllEntity
                        }
                        checked={
                          role.name === userTypes.SUPER_ADMIN ||
                          enabledAllEntity
                            ? true
                            : currentPermissionList[permission.value]
                        }
                        value={permission.value}
                        type="checkbox"
                      />
                      <span
                        data-positive="YES"
                        data-negative="NO"
                        className="slider round"
                      />
                    </label>
                    <div style={{ marginLeft: 8 }}>
                      <span className="permission-label">
                        {permission.label}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default UserSpecificPermissions;
