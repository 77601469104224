import { getRandomId } from "../../helpers/helpers";

export default class TaskSpreadSheet {
  constructor({
    id = getRandomId(),
    description = "",
    assignedTo = "",
    assignedBy = "",
    stage = "",
    dependency = "",
    dependencyType = "",
    duration = 0,
    offset = 0,
    taskNumber = "",
    listIndex = 0,
    dependsOnSOT = false,
    number = 0,
    type = "",
  }) {
    this.id = id;
    this.description = description;
    this.assignedBy = assignedBy;
    this.assignedTo = assignedTo;
    this.stage = stage;
    this.dependency = dependency;
    this.dependencyType = dependencyType;
    this.duration = duration;
    this.offset = offset;
    this.taskNumber = taskNumber;
    this.listIndex = listIndex;
    this.dependsOnSOT = dependsOnSOT;
    this.number = number;
    this.type = type;
  }
}
