import React from "react";
import { dayHeight, dayWidth } from "../../helpers/constants";
import { getBorderIndicator } from "../../helpers/timelineCalendar";

function VerticalIndicator({ date, taskOnHover, size, styles = {} }) {
  return (
    <div
      className={"vertical-indicator"}
      style={{
        width: dayWidth,
        height: dayHeight,
        position: "absolute",
        background: "#CDE7FF",
        opacity: 0.2,
        ...getBorderIndicator({ date, taskOnHover, size }),
        ...styles,
      }}
    ></div>
  );
}

export default VerticalIndicator;
