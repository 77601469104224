import React from "react";
import { TablePhaseStyled } from "./styles";
import collapseIcon from "../../assets/flag-icons/collapse-filled-icon.png";
import expandIcon from "../../assets/flag-icons/disclosure-triangle.png";
import { getTaskPhaseDiamond } from "../../helpers/timelineModal";
import TooltipTD from "../Tooltip/TooltipTD";
import {
  collapsedTaskTableColumns,
  simpleTaskTableColumns,
} from "../../helpers/constants";
import { viewOptionLabels } from "../../helpers/timelineCalendar";
import { projectStages } from "../../container/OrdersByPhase/helpers";

function TablePhase({
  styles,
  className,
  columnIndex,
  task,
  tableSize,
  onChangeTableSize,
  taskPhases,
  onChangeTaskPhase,
  flagViewOptions,
  filteredTasks,
}) {
  function getTaskPhaseContent({ columnIndex, task }) {
    return (
      task.description === projectStages.PROPOSAL_QUOTE && (
        <div className={"expandOrCollapseContainer"}>
          <TooltipTD label={"Collapse Table"}>
            <img
              className="disclosure-triangle"
              alt="collapse"
              src={collapseIcon}
              onClick={() => {
                if (tableSize !== collapsedTaskTableColumns) {
                  return onChangeTableSize(collapsedTaskTableColumns);
                }
                return onChangeTableSize(simpleTaskTableColumns);
              }}
            />
          </TooltipTD>
        </div>
      )
    );
  }

  function getPhase(columnIndex, task) {
    switch (columnIndex) {
      case 0:
        return (
          <div className={"expandOrCollapseContainer"}>
            <img
              className="disclosure-triangle"
              src={expandIcon}
              alt="disclosure-triangle"
              onClick={() =>
                onChangeTaskPhase({
                  ...taskPhases,
                  [task.description]: !taskPhases[task.description],
                })
              }
              style={
                taskPhases[task.description]
                  ? {}
                  : { transform: "rotate(270deg)" }
              }
            />
          </div>
        );
      case 1:
        return (
          <div
            className={"PhaseText"}
            style={{
              width: 210,
            }}
          >
            {!taskPhases[task.description] &&
              getTaskPhaseDiamond({
                tasks: filteredTasks.filter((el) => el.stage === task.phase),
                imgStyles: { marginRight: 4 },
              })}
            {task.description}
          </div>
        );
      case 22: {
        if (!flagViewOptions[viewOptionLabels.FULL_TABLE_DETAILS]) {
          return getTaskPhaseContent({ columnIndex, task });
        }
        return null;
      }

      case 33:
        return flagViewOptions[viewOptionLabels.FULL_TABLE_DETAILS]
          ? getTaskPhaseContent({ columnIndex, task })
          : null;

      default:
        return null;
    }
  }

  return (
    <TablePhaseStyled
      style={{
        ...styles,
        background: "#FFF",
      }}
      className={className}
      elevation={0}
    >
      {tableSize === collapsedTaskTableColumns
        ? null
        : getPhase(columnIndex, task)}
    </TablePhaseStyled>
  );
}

export default TablePhase;
