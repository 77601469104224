import React from "react";
import { globalScopes } from "../../../api/types/dbTables";

export const mismatchLabel = {
  [globalScopes.SALES_ORDER]: (
    <span>
      The quantity in one or more items does not match <br /> the quantity in
      the PO
    </span>
  ),
  [globalScopes.PURCHASE_ORDER]: (
    <span>
      The quantity of one or more items is not 100% <br /> allocated to
      shipments
    </span>
  ),
  [globalScopes.SHIPMENT]: (
    <span>
      There are one or more items in this shipment <br /> that are not a whole
      number of cartons
    </span>
  ),
};

export const ORDER_DASHBOARD_ACTUAL_VIEWS = {
  SALES_ORDER: "sales-order-info-to-scroll",
  PURCHASE_ORDER: "purchase-order-info-to-scroll",
  SHIPMENT: "shipment-info-to-scroll",
  THREE_PANEL_SECTION: "three-panel-section-to-scroll",
};
