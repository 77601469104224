import { getRandomId } from "../../helpers/helpers";

export default class PermissionGroup {
  constructor({
    id = getRandomId(),
    name = "",
    users = {},
    isInactive = false,
  }) {
    this.id = id;
    this.name = name;
    this.users = users;
    this.isInactive = isInactive;
  }
}
