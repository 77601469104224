import React, { useState, useEffect, useRef, useCallback } from "react";
import { ExpensesListContainer, ExpensesTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter from "../../components/PurchaseOrderList/ButtonFilter";
import {
  useAutoCompleteLists,
  useCompanyId,
  useGLAccountItems,
  useJSONLocalStorage,
} from "../../hooks";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import { debounce, getTheDate, sortObjectsBy } from "../../helpers/helpers";
import moment from "moment";
import { useIsAllowedFunction } from "../../hooks/permissions";
import SearchBox from "../../components/SearchBox/SearchBox";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import { ButtonGroup, useMediaQuery } from "@mui/material";
import FooterTable from "../../components/Tables/FooterTable";
import { ExpensesHeadCells } from "../../components/Managers/TableHeaderProvider";
import {
  getToolTipArrayValue,
  getToolTipMixArrayValue,
  getToolTipSingleArrayValue,
} from "../../helpers/purchaseOrderList";

import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import BasicPagination from "../../components/Tables/BasicPagination";
import {
  excludeElementsScreen,
  headerColumns,
  TOOLTIP_TYPE,
  TYPE_OF_AUTOCOMPLETE_LISTS,
} from "../../helpers/constants";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import { useUser, useUserCustomSetting } from "../../hooks/user";
import { useCustomers } from "../../hooks/customers";
import { useTags } from "../../hooks/tags";
import {
  DownloadCSVIcon,
  DownloadCSVIconv2,
  NotExportedIcon,
  PurchaseOrderIcon,
  SalesOrderIcon,
  ShipmentOrderIcon,
} from "../../helpers/iconSvgPath";
import { firestore, performanceFirebase } from "../../firebase";
import AttentionModal from "../../components/Modal/AttentionModal";
import ExpensesTableRow from "./ExpensesTableRow";
import { orderType } from "../../helpers/salesOrder";
import ExpensesModal from "../../components/PurchaseOrderDashboard/Expenses/ExpensesModal";
import {
  filterExpenseList,
  footerExpensesScreen,
  getGLAccount,
  parseCSVExpenses,
} from "../../helpers/expenses";
import IntlMessages from "../../util/IntlMessages";
import CSVModalDownload from "../../components/Modal/CSVModalDownload";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { trace } from "firebase/performance";
import { isEqual } from "lodash";
import ToolsButtonScreen from "../../components/PurchaseOrderList/ToolsButtonScreen";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import Mark from "mark.js";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";

const dateFields = ["Document Date", "Creation Date", "Exported Date"];
const hiddenStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
};

const BODY_ID = "expensesTableRow";

const TABLE_BY_SCOPE = {
  [orderType.SALES_ORDER]: dbTables.SALES_ORDERS,
  [orderType.PURCHASE_ORDER]: dbTables.PURCHASE_ORDERS,
  [orderType.SHIPMENT]: dbTables.SHIPMENTS,
};

function ExpensesScreen({ history }) {
  const dateRef = useRef(null);
  const { get, set } = useJSONLocalStorage("expensesListFilters");
  const { get: getExpensesSetup, set: setExpensesSetup } =
    useJSONLocalStorage("expensesScreen");
  const [currentExpensesSetup, setCurrentExpensesSetup] = useState(
    getExpensesSetup() || {}
  );
  const dataLocalStorage = get() || {};
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const paidToOptions = useAutoCompleteLists({
    id: TYPE_OF_AUTOCOMPLETE_LISTS.PAID_TO,
  });
  const matches = useMediaQuery("(max-width:1460px)");
  const screenMidSize = useMediaQuery("(max-width:1600px)");
  const GLAccountItems = useGLAccountItems();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [attentionModalOpen, setAttentionModalOpen] = useState(false);
  const [openExpensesModal, setOpenExpensesModal] = useState({});
  const [expenseToDelete, setExpenseToDelete] = useState({});
  const isAllowed = useIsAllowedFunction();
  const customers = useCustomers();
  const tags = useTags();
  const [filters, setFilters] = useState({
    [orderType.SHIPMENT]: !!dataLocalStorage[orderType.SHIPMENT],
    [orderType.SALES_ORDER]: !!dataLocalStorage[orderType.SALES_ORDER],
    [orderType.QUOTE]: !!dataLocalStorage[orderType.QUOTE],
    [orderType.PURCHASE_ORDER]: !!dataLocalStorage[orderType.PURCHASE_ORDER],
    [orderType.PURCHASE_QUOTE]: !!dataLocalStorage[orderType.PURCHASE_QUOTE],
    paidTo: dataLocalStorage.paidTo || [],
    glAccount: dataLocalStorage.glAccount || [],
    date: dataLocalStorage.date || "",
    dateRange: dataLocalStorage.dateRange || "",
    exported: dataLocalStorage.exported || false,
    notExported:
      dataLocalStorage.notExported === undefined
        ? true
        : !!dataLocalStorage.notExported,
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    sortedColumn: dataLocalStorage.sortedColumn || "date",
    orderBy: dataLocalStorage.orderBy || "DESC",
    query: dataLocalStorage.query || "",
  });
  const [expensesList, setExpensesList] = useState([]);
  const [dataFiltered, setDataFiltered] = useState({
    expenses: [],
    totalAmounts: {},
    totalExpenses: 0,
  });
  const [openDate, setOpenDate] = useState(false);
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const [filterDate, setFilterDate] = useState(dataLocalStorage.date || "");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const [headerCells, setHeaderCells] = useState([]);
  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
    }
  }

  const getDateFormat = ({ date, range = {} }) => {
    if (!range.start || !range.end) {
      return { range: {}, dateType: "" };
    }
    let start, end, dateType;
    if (date === "Document Date") {
      start = moment(range.start).format("YYYY-MM-DD");
      end = moment(range.end).format("YYYY-MM-DD");
      dateType = "date";
    } else if (date === "Creation Date") {
      start = moment(range.start).valueOf();
      end = moment(range.end).valueOf();
      dateType = "creationDate";
    } else {
      start = moment(range.start).format("YYYY/MM/DD");
      end = moment(range.end).format("YYYY/MM/DD");
      dateType = "exportedDate";
    }
    return { start, end, dateType };
  };

  async function getExpensesList() {
    setPreviousFilter(filters);
    const traceInstance = trace(performanceFirebase, "expenses_screen");
    traceInstance.start();
    setLoading(true);
    try {
      const functions = getFunctions();
      const expensesEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview",
          env: globalEnvironment,
          params: "/expenses",
        })
      );
      const { start, end, dateType } = getDateFormat({
        date: filters.date,
        range: filters.dateRange,
      });
      expensesEndpoint({
        filters: {
          ...filters,
          dateRange: "",
          dateStart: start,
          dateEnd: end,
          date: dateType,
        },
        companyId,
        userId: user.id,
      }).then((result) => {
        const { expensesDB = [] } = result.data;
        setExpensesList([...expensesDB]);
        traceInstance.stop();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      traceInstance.stop();
      setLoading(false);
    }
  }

  async function updateExportesList() {
    setLoading(true);
    const functions = getFunctions();
    const callable = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: "listview",
        env: globalEnvironment,
        params: "/setExported",
      })
    );

    try {
      let expensesList = dataFiltered.expenses || [];
      expensesList = expensesList.filter((expense) => !expense.isExported);
      const expensesUpdated = expensesList.map((expense) => ({
        type: expense.type,
        parentDocumentId: expense.parentDocumentId,
        id: expense.id,
        isExported: !!expense.isExported,
      }));
      const exportedDate = moment().format("YYYY/MM/DD");
      const result = await callable({
        expenses: expensesUpdated,
        exportedDate: exportedDate,
        companyId: companyId,
      });

      const { updated } = result.data;
      if (updated) {
        setExpensesList((oldExpenses) => {
          return oldExpenses.map((expense) => {
            const updatedExpense = expensesUpdated.find(
              (newExpense) => newExpense.id === expense.id
            );
            if (updatedExpense) {
              return {
                ...expense,
                isExported: true,
                exportedDate: moment(exportedDate).valueOf(),
              };
            } else {
              return expense;
            }
          });
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    const { filteredList, totalAmounts, totalExpenses } = filterExpenseList({
      list: expensesList,
      nonLookUpfilters,
      customers,
      glItems: GLAccountItems,
      isAllowed,
      user,
    });
    setDataFiltered({ expenses: filteredList, totalAmounts, totalExpenses });
  }, [headerCells, expensesList]);

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      console.log("same filter");
      return;
    }
    setExpensesList([]);
    setDataFiltered({ expenses: [], totalAmounts: {}, totalExpenses: 0 });
    getExpensesList();
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
    setPage(1);
  }, [callFunction]);

  useEffect(() => {
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    function onAdjustHeader() {
      const { filteredList } = filterExpenseList({
        list: expensesList,
        customers,
        glItems: GLAccountItems,
        nonLookUpfilters,
        isAllowed,
        user,
      });
      const datatoShow = filteredList.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      );
      let maxWidthCustomerName = 50;
      let maxOrderNumber = 70;
      datatoShow.forEach((currentExpense) => {
        const customer =
          customers.find(
            (customer) => customer.id === currentExpense.customerId
          ) || "";
        const customerNameWidth = (customer.name || "").toString().length * 4.5;
        if (customerNameWidth > maxWidthCustomerName) {
          maxWidthCustomerName = customerNameWidth;
        }
        const order = currentExpense.order || {};
        const numberSize = `${order.number || ""}`.toString().length * 7.5;
        if (numberSize > maxOrderNumber) {
          maxOrderNumber = numberSize;
        }
      });
      let userHeaderCell = userCustomSettings.expensesHeaderSetup || [];
      userHeaderCell = userHeaderCell.map((header) => {
        const headerValue = ExpensesHeadCells[header.name];

        return { ...header, ...headerValue };
      });

      let headerCellsCpy = [...userHeaderCell];
      headerCellsCpy = headerCellsCpy.map((headerCell) => {
        if (headerCell.name === headerColumns.CUSTOMER) {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxWidthCustomerName + 60 },
          };
        } else if (headerCell.name === headerColumns.ORDER_NUMBER) {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxOrderNumber + 45 },
          };
        } else {
          return headerCell;
        }
      });
      if (!isEqual(headerCells, headerCellsCpy)) {
        setHeaderCells(headerCellsCpy);
      }
    }
    onAdjustHeader();
  }, [rowsPerPage, page, nonLookUpfilters, expensesList]);

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }

  useEffect(() => {
    setPage(1);
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
    if (!expensesList.length) return;
    const { filteredList, totalAmounts, totalExpenses } = filterExpenseList({
      list: expensesList,
      customers,
      nonLookUpfilters,
      glItems: GLAccountItems,
      isAllowed,
      user,
    });

    setDataFiltered({ expenses: filteredList, totalAmounts, totalExpenses });
  }, [nonLookUpfilters]);

  function resetFilters() {
    setFilters({
      paidTo: [],
      glAccount: [],
      date: "",
      dateRange: "",
      exported: false,
      notExported: true,
      typeOrder: [],
      [orderType.SHIPMENT]: false,
      [orderType.SALES_ORDER]: false,
      [orderType.QUOTE]: false,
      [orderType.PURCHASE_ORDER]: false,
      [orderType.PURCHASE_QUOTE]: false,
    });
    setNonLookUpFilters({
      ...nonLookUpfilters,
    });
    setFilterDate("");
    setExistsDate(false);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filterDate,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
    if (!filterDate) {
      setFilters({ ...filters, date: "" });
    }
  }
  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilterDate(field);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 50);
  }

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function renderFooter(totalAmounts, totalExpenses) {
    let newFooter = footerExpensesScreen({
      totalAmounts,
      headerCells,
      totalExpenses,
    });
    return (
      <React.Fragment>
        <FooterTable
          footer={newFooter}
          headerCells={headerCells.sort(sortObjectsBy("index", false))}
        />
      </React.Fragment>
    );
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    if (!loading) {
      setTimeout(() => performMark(nonLookUpfilters.query), 150);
    }
  }, [nonLookUpfilters, loading, rowsPerPage, page]);

  function getCSVBody(expenses = [], glItems) {
    return parseCSVExpenses({
      expenses,
      glItems,
    });
  }

  function getAllFilters(arrayFilters, minNumber) {
    let numberFounded = 0;
    arrayFilters.forEach((activeFilter) => {
      if (activeFilter) {
        numberFounded++;
      }
    });
    return numberFounded >= minNumber;
  }

  function areAllExpensesExpanded({ expensesSetup }) {
    const isThereASectionClosed = expensesList.some((expense) => {
      if (!expensesSetup[expense.id]) {
        return true;
      }
      return false;
    });
    return !isThereASectionClosed;
  }

  const handleEditModal = (expenseItem) => {
    const expenseRef = doc(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${TABLE_BY_SCOPE[expenseItem.type]}/${
        expenseItem.parentDocumentId
      }/${dbTables.EXPENSES}/${expenseItem.id}`
    );
    setOpenExpensesModal({
      expenseRef,
      collection: TABLE_BY_SCOPE[expenseItem.type],
      id: expenseItem.id,
      documentId: expenseItem.parentDocumentId,
    });
  };

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounce();
  };

  const filterCustomerActive = filters.customer && filters.customer.length > 0;
  const filterPaidToActive = filters.paidTo && filters.paidTo.length > 0;
  const filterGlAccount = filters.glAccount && filters.glAccount.length > 0;
  const filterArrayActive = [
    filterCustomerActive,
    !!existsDate,
    filterCustomerActive,
    filterPaidToActive,
    filterGlAccount,
  ];
  return (
    <React.Fragment>
      {attentionModalOpen && (
        <CSVModalDownload
          isOpen={attentionModalOpen}
          description={
            dataFiltered.expenses.length < 2 ? (
              <IntlMessages id="expenses.attention.export.message.one" />
            ) : (
              <IntlMessages id="expenses.attention.export.message" />
            )
          }
          csvBody={getCSVBody(dataFiltered.expenses, GLAccountItems)}
          csvHeader={headerCells
            .filter((header) => header.enabled && header.exportLabel)
            .map((header) => ({
              ...header,
              label: header.exportLabel,
            }))}
          filename={`Expenses ${moment().format("MM-DD-YY")}.csv`}
          onClick={async () => {
            await updateExportesList();
            setAttentionModalOpen(false);
          }}
          onClose={() => setAttentionModalOpen(false)}
          title="Attention"
          cancelText="No"
          confirmationText="Yes"
          filters={filters}
          currentExpenses={dataFiltered.expenses}
        />
      )}
      {openExpensesModal && openExpensesModal.expenseRef && (
        <ExpensesModal
          open={!!openExpensesModal.collection}
          className={""}
          onClose={(expensesModify) => {
            const newExpenseList = expensesList.map((oldExpenses) => {
              const expenseModify = expensesModify.find(
                (currentExpense) => currentExpense.id === oldExpenses.id
              );
              if (expenseModify) {
                let currentData = {};
                const [firstItem] = expenseModify.items;
                if (firstItem) {
                  const currentGL = getGLAccount({
                    itemId: firstItem.GLAccountId,
                    items: GLAccountItems,
                  });
                  currentData = {
                    currentDescription: firstItem.descriptionOfCharges,
                    currentGLAccountName: `${currentGL.name || ""} - ${
                      currentGL.description || ""
                    }`,
                    currentNotes: firstItem.notes,
                  };
                }
                return { ...oldExpenses, ...expenseModify, ...currentData };
              } else {
                return oldExpenses;
              }
            });
            setExpensesList(newExpenseList);
            setOpenExpensesModal({});
          }}
          companyId={companyId}
          collection={openExpensesModal.collection}
          documentId={openExpensesModal.documentId}
          expenseRef={openExpensesModal.expenseRef}
        />
      )}
      {expenseToDelete && expenseToDelete.id && (
        <AttentionModal
          isOpen={!!expenseToDelete.id}
          description={expenseToDelete.description}
          onClick={async () => {
            await deleteDoc(
              doc(
                firestore,
                `${dbTables.COMPANIES}/${companyId}/${
                  TABLE_BY_SCOPE[expenseToDelete.type]
                }/${expenseToDelete.parentDocumentId}/${dbTables.EXPENSES}/${
                  expenseToDelete.id
                }`
              )
            );

            const filterExpensesList = expensesList.filter(
              (expenseData) => expenseData.id !== expenseToDelete.id
            );
            setExpensesList(filterExpensesList);
            setExpenseToDelete({});
          }}
          onClose={() => setExpenseToDelete({})}
          title="Attention"
          acceptBlue={true}
          cancelText="No"
          confirmationText="Yes"
          cancellable={true}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <ExpensesListContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            dateFields={dateFields}
            currentFilters={filters}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            paidToOptions={paidToOptions}
            onOpenDatePicker={onOpenDatePicker}
            enabledFields={{
              customer: true,
              paidTo: true,
              glAccount: true,
              date: true,
            }}
          />
          <SearchBox
            placeholder="Search for PO or Sales Order or shipment number, etc."
            value={nonLookUpfilters.query}
            hasBeenReseted={(nonLookUpfilters, value) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: "" });
            }}
            filters={nonLookUpfilters}
            onDebounceValue={(nonLookUpfilters, value) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: value });
            }}
          />
        </div>
        <div className="contentContainer">
          <div className="buttonsFilter">
            <ButtonGroup variant="outlined">
              <ButtonFilter
                buttonId="list-view-button-filter-exported"
                value={filters.exported}
                onClick={() => changeButtonFilter("exported")}
                className="bigBox"
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <DownloadCSVIconv2
                  width={24}
                  height={24}
                  style={{ marginRight: 6 }}
                />
                Exported
              </ButtonFilter>
              <ButtonFilter
                buttonId="list-view-button-filter-notexported"
                value={filters.notExported}
                onClick={() => changeButtonFilter("notExported")}
                className="bigBox"
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <NotExportedIcon
                  width={24}
                  height={24}
                  style={{ marginRight: 6 }}
                />
                Not Exported
              </ButtonFilter>
            </ButtonGroup>
            <ButtonGroup variant="outlined">
              <ButtonFilter
                buttonId="list-view-button-filter-quote"
                value={filters[orderType.QUOTE]}
                onClick={() => changeButtonFilter(orderType.QUOTE)}
                className="bigBox"
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  padding: "0px 16px",
                  width: "auto",
                }}
              >
                <SalesOrderIcon
                  width={24}
                  height={24}
                  style={{ marginRight: 6 }}
                />
                {!getAllFilters(filterArrayActive, 2) ? "Quotes" : "QU"}
              </ButtonFilter>
              <ButtonFilter
                buttonId="list-view-button-filter-salesorder"
                value={filters[orderType.SALES_ORDER]}
                onClick={() => changeButtonFilter(orderType.SALES_ORDER)}
                className="bigBox"
                style={{
                  borderRadius: 0,
                  padding: "0px 16px",
                  width: "auto",
                }}
              >
                <SalesOrderIcon
                  width={24}
                  height={24}
                  style={{ marginRight: 6 }}
                />
                {!getAllFilters(filterArrayActive, 2) ? "Sales Orders" : "SO"}
              </ButtonFilter>
              <ButtonFilter
                buttonId="list-view-button-filter-purchasequote"
                value={filters[orderType.PURCHASE_QUOTE]}
                onClick={() => changeButtonFilter(orderType.PURCHASE_QUOTE)}
                className="bigBox"
                style={{
                  borderRadius: 0,
                  padding: "0px 16px",
                  width: "auto",
                }}
              >
                <PurchaseOrderIcon
                  width={24}
                  height={24}
                  style={{ marginRight: 6 }}
                />
                {!getAllFilters(filterArrayActive, 2)
                  ? "Purchase Quotes"
                  : "PQ"}
              </ButtonFilter>
              <ButtonFilter
                buttonId="list-view-button-filter-purchaseorder"
                value={filters[orderType.PURCHASE_ORDER]}
                onClick={() => changeButtonFilter(orderType.PURCHASE_ORDER)}
                className="bigBox"
                style={{
                  borderRadius: 0,
                  padding: "0px 16px",
                  width: "auto",
                }}
              >
                <PurchaseOrderIcon
                  width={24}
                  height={24}
                  style={{ marginRight: 6 }}
                />
                {!getAllFilters(filterArrayActive, 2) ? "POs" : "PO"}
              </ButtonFilter>
              <ButtonFilter
                buttonId="list-view-button-filter-shipment"
                value={filters[orderType.SHIPMENT]}
                onClick={() => changeButtonFilter(orderType.SHIPMENT)}
                className="bigBox"
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  padding: "0px 16px",
                  width: "auto",
                }}
              >
                <ShipmentOrderIcon
                  width={24}
                  height={24}
                  style={{ marginRight: 6 }}
                />
                {!getAllFilters(filterArrayActive, 2) ? "Shipments" : "SH"}
              </ButtonFilter>
            </ButtonGroup>
            {filterCustomerActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-customer"
                onClear={() => clearFilterData({ filterKey: "customer" })}
                label={filters.customer.length === 1 ? "Customer" : "Customers"}
                isAllFiltersActive={
                  getAllFilters(filterArrayActive, 3) || screenMidSize
                }
                labelStyle={hiddenStyle}
                tooltip={
                  getToolTipArrayValue(filters.customer, customers).tooltip
                }
                value={getToolTipArrayValue(filters.customer, customers).value}
              />
            )}
            {filterPaidToActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-paidto"
                onClear={() => clearFilterData({ filterKey: "paidTo" })}
                label={"Paid To"}
                isAllFiltersActive={
                  getAllFilters(filterArrayActive, 3) || screenMidSize
                }
                labelStyle={hiddenStyle}
                tooltip={getToolTipSingleArrayValue(filters.paidTo).tooltip}
                value={getToolTipSingleArrayValue(filters.paidTo).value}
              />
            )}
            {filterGlAccount && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-glaccount"
                onClear={() => clearFilterData({ filterKey: "glAccount" })}
                label={
                  filters.glAccount.length === 1
                    ? "G/L Account"
                    : "G/L Accounts"
                }
                isAllFiltersActive={
                  getAllFilters(filterArrayActive, 3) || screenMidSize
                }
                labelStyle={hiddenStyle}
                tooltip={
                  getToolTipMixArrayValue({
                    arrayFilters: filters.glAccount,
                    arrayValue: GLAccountItems,
                    fields: ["name", "description"],
                  }).tooltip
                }
                value={
                  getToolTipMixArrayValue({
                    arrayFilters: filters.glAccount,
                    arrayValue: GLAccountItems,
                    fields: ["name", "description"],
                  }).value
                }
              />
            )}

            {existsDate && (
              <React.Fragment>
                <div
                  onClick={() => {
                    setOpenDate(true);
                    setOpenList(true);
                  }}
                  ref={dateRef}
                >
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-date"
                    onClear={() => {
                      setFilterDate("");
                      setExistsDate(false);
                      setFilters({
                        ...filters,
                        date: "",
                        dateRange: { start: "", end: "" },
                      });
                      setOpenDate(false);
                      setOpenList(false);
                      onDebounce();
                    }}
                    label={filters.date}
                    isAllFiltersActive={
                      getAllFilters([filterCustomerActive, !!existsDate], 3) ||
                      screenMidSize
                    }
                    labelStyle={hiddenStyle}
                    tooltip={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                    value={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                  />
                </div>
                {openDate && (
                  <DateRangePicker
                    open={openDate}
                    el={dateRef.current}
                    onClose={handleCloseDatePicker}
                    onChange={changeDate}
                    value={filters.dateRange}
                    cancelIcon={true}
                  />
                )}
              </React.Fragment>
            )}

            <ButtonReset
              id="list-view-button-filter-reset"
              className="root"
              onClick={resetFilters}
            >
              {matches ? "RESET" : "RESET FILTERS"}
            </ButtonReset>
          </div>
          <ToolsButtonScreen
            typeSetup={columnHeaderSetup.EXPENSES_HEADER_SETUP}
            nonLookUpfilters={nonLookUpfilters}
            headerCells={headerCells}
            csvButton={
              <div
                id="list-view-table-content-downloadcsv"
                className="iconContainer"
                onClick={() => setAttentionModalOpen(true)}
              >
                <TooltipTD
                  label="Export to CSV"
                  placement="top"
                  type={TOOLTIP_TYPE.DEFAULT}
                  hasArrow={false}
                  popperProps={{}}
                >
                  <DownloadCSVIcon svgClass={"csvIcon"} />
                </TooltipTD>
              </div>
            }
            onChangeHeader={(newItems) => setHeaderCells(newItems)}
            hiddenButton={{
              [orderType.PURCHASE_ORDER]: true,
              [orderType.SHIPMENT]: true,
            }}
            hiddenDataAction={(value) => {
              let newExpensesSetup = {};
              expensesList.forEach((expense) => {
                newExpensesSetup[expense.id] = !value;
              });
              setCurrentExpensesSetup({ ...newExpensesSetup });
              setExpensesSetup({ ...newExpensesSetup });
            }}
            isAllExpanded={areAllExpensesExpanded({
              expensesSetup: currentExpensesSetup,
            })}
            onSaveHeaders={(newItems) => {
              updateDoc(
                doc(
                  firestore,
                  `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
                ),
                {
                  expensesHeaderSetup: newItems,
                }
              );
            }}
          />
          <ExpensesTableContainer className="contentListExpenses">
            <div className="tableContainer" id={BODY_ID}>
              <MainTableRefactor
                headCells={headerCells.sort(sortObjectsBy("index", false))}
                bodyRows={dataFiltered.expenses.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage
                )}
                renderRow={(item, index) => {
                  return (
                    <ExpensesTableRow
                      key={item.id + index}
                      item={item}
                      user={user}
                      isAllowed={isAllowed}
                      customers={customers}
                      headers={headerCells.sort(sortObjectsBy("index", false))}
                      tags={tags}
                      filters={filters}
                      expensesSetup={currentExpensesSetup}
                      onChangeExpensesSetup={(expensesSetup) => {
                        setCurrentExpensesSetup({ ...expensesSetup });
                        setExpensesSetup({ ...expensesSetup });
                      }}
                      history={history}
                      GLAccountItems={GLAccountItems}
                      handleExpensesDelete={setExpenseToDelete}
                      handleExpenseEdit={handleEditModal}
                      tableByScope={TABLE_BY_SCOPE}
                    />
                  );
                }}
                filters={nonLookUpfilters}
                footer={renderFooter(
                  dataFiltered.totalAmounts,
                  dataFiltered.totalExpenses
                )}
                onUpdateFilters={(nonLookUpfilters) => {
                  setPage(1);
                  setNonLookUpFilters({ ...nonLookUpfilters });
                }}
              />
            </div>
            <BasicPagination
              rowsPerPage={rowsPerPage}
              page={page}
              count={getCountPagination(dataFiltered.expenses, rowsPerPage)}
              onChangePagination={(event, newPage) => {
                handleChangePage(newPage);
              }}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(+event.target.value);
                setPage(1);
              }}
            />
          </ExpensesTableContainer>
        </div>
      </ExpensesListContainer>
    </React.Fragment>
  );

  function getCountPagination(expenses, rowsPerPage) {
    return Math.ceil(expenses.length / rowsPerPage);
  }
}

export default ExpensesScreen;
