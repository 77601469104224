import React from "react";
import { useCompanyId, useData } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import ManagementDocuments from "../Documents/ManagementDocuments";
import { documentScope } from "../../helpers/documents";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { FactoryDocumentsStyled } from "./styles";

function FactoryDocuments({
  factory,
  permissionGroups,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
}) {
  const companyId = useCompanyId();
  const factoryDocuments = useData(
    [
      dbTables.COMPANIES,
      companyId,
      dbTables.FACTORIES,
      factory.id,
      dbTables.FACTORY_DOCUMENTS,
    ],
    [factory.id]
  );

  return (
    <FactoryDocumentsStyled
      className="container"
      style={{
        paddingRight: 24,
        display: "block",
      }}
    >
      <ManagementDocuments
        companyID={companyId}
        dbTable={dbTables.FACTORY_DOCUMENTS}
        mainTable={dbTables.FACTORIES}
        mainTableID={factory.id}
        documents={factoryDocuments.map((document) => ({
          ...document,
          scope: documentScope.FACTORY,
        }))}
        selectedDocumentTab={0}
        hasTitle={true}
        permissionGroupsDB={permissionGroups}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        permissionScope={GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_VENDOR_DOCUMENT}
        allowMultipleFiles
      />
    </FactoryDocumentsStyled>
  );
}

export default FactoryDocuments;
