import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import {
  getTypeFile,
  getSizeTransformedAccordingKBSize,
  sortObjectsBy,
} from "../../helpers/helpers";
import TypeFileSelector from "./TypeFileSelector";
import moment from "moment";
import ModalPermissionUsers from "./ModalPermissionUsers";
import wrongUploadFile from "../../assets/flag-icons/wrong-upload-file.png";
import IntlMessages from "../../util/IntlMessages";
import { ModalPermissionPlaceholderDocumentStyled } from "./styles";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { getNewToolTipByPermissionGroup } from "../../helpers/purchaseOrderList";
import { CustomTooltip } from "../Tooltip/CustomToolTip";

function ModalPermission({
  title,
  open,
  onClose,
  file,
  handleCancel,
  handleDone,
  companyUsers,
  uploadMultipleFiles,
  permissionGroupsDB = [],
  parentDocument = null,
}) {
  const [typeFile, setTypeFile] = useState(
    file.type === TYPE_OF_FILE.FOLDER
      ? TYPE_OF_FILE.FOLDER
      : getTypeFile(file.name)
  );
  const [permissions, setPermissions] = useState({
    permissionUsers: [],
    permissionGroups: [...permissionGroupsDB],
  });

  useEffect(() => {
    setTypeFile(
      file.type === TYPE_OF_FILE.FOLDER
        ? TYPE_OF_FILE.FOLDER
        : getTypeFile(file.name)
    );
  }, [file]);

  useEffect(() => {
    let permissionGroupsCpy = [
      ...permissions.permissionGroups.map((group) => ({
        ...group,
        enable: true,
      })),
    ];
    if (file && file.permissionGroups && file.permissionUsers) {
      permissionGroupsCpy = permissionGroupsCpy.map((group) => {
        if (file.permissionGroups[group.id]) {
          return { ...group, checked: true, enable: true };
        }
        return { ...group, enable: true, checked: false };
      });
      let permissionUsers = [];
      Object.keys(file.permissionUsers).forEach((key) => {
        const user = companyUsers.find((user) => user.id === key);
        if (user && !permissionUsers.find((pUser) => pUser.id === user.id)) {
          permissionUsers.push(user);
        }
      });
      if (parentDocument && Object.keys(parentDocument).length > 0) {
        permissionGroupsCpy = permissionGroupsCpy.map((group) => {
          if (group && parentDocument.permissionGroups[group.id]) {
            return {
              ...group,
              enable: true,
            };
          }
          return { ...group, enable: false };
        });
      }
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers,
      });
    } else {
      setPermissions({ ...permissions, permissionUsers: [] });
    }
  }, [open]);

  function onMoveUsersFromDisolvedGroup(allUsersToMove, permissionGroupsCpy) {
    permissionGroupsCpy
      .filter((permission) => permission.checked)
      .forEach((permission) => {
        Object.keys(permission.users).forEach(
          (key) => delete allUsersToMove[key]
        );
      });
    const permissionUsersCpy = [...permissions.permissionUsers];
    Object.keys(allUsersToMove).forEach((key) => {
      const user = companyUsers.find((user) => user.id === key);
      if (user) {
        permissionUsersCpy.push(user);
      }
    });
    setPermissions({
      permissionGroups: permissionGroupsCpy,
      permissionUsers: permissionUsersCpy,
    });
  }

  function onDisolveGroups(user) {
    let permissionGroupsCpy = [...permissions.permissionGroups];
    let permissionUsersCpy = [...permissions.permissionUsers];
    let allUsersToMove = {};
    let userBelongsToSomeGroup = false;
    permissionGroupsCpy = permissionGroupsCpy.map((permission) => {
      if (
        !!permission.checked &&
        (permission.users[user.id] || permission.users[user.id] === false)
      ) {
        userBelongsToSomeGroup = true;
        let usersCpy = permission.users;
        usersCpy[user.id] = false;
        Object.keys(usersCpy).forEach((key) => {
          if (usersCpy[key]) {
            allUsersToMove[key] = usersCpy[key];
          }
        });
        return { ...permission, checked: false };
      } else return permission;
    });
    if (!userBelongsToSomeGroup) {
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy.filter(
          (cUser) => cUser.id !== user.id
        ),
      });
    } else if (Object.keys(allUsersToMove).length > 0) {
      onMoveUsersFromDisolvedGroup(allUsersToMove, permissionGroupsCpy);
    } else {
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy.filter(
          (cUser) => cUser.id !== user.id
        ),
      });
    }
  }

  function handleChange(value, lastValue) {
    if (!value) {
      setPermissions({
        permissionGroups: permissions.permissionGroups.map((group) => ({
          ...group,
          checked: false,
        })),
        permissionUsers: [],
      });
      return;
    }
    value = value.filter((val) => !!val);
    const newElementA = value.filter(comparer(lastValue));
    const newElementB = lastValue.filter(comparer(value));
    const newElement = newElementA.concat(newElementB)[0];

    if (value.length > lastValue.length) {
      const permissionUsersCpy = [...permissions.permissionUsers];
      permissionUsersCpy.push(newElement);

      setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
    } else {
      const { permissionGroups } = permissions;
      if (permissionGroups.filter((group) => group.checked).length <= 0) {
        let permissionUsersCpy = [...permissions.permissionUsers];
        permissionUsersCpy = permissionUsersCpy.filter(
          (cUser) => cUser.id !== newElement.id
        );

        setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
      } else {
        onDisolveGroups(newElement);
      }
    }
  }

  function comparer(array) {
    return function (current) {
      return (
        array.filter((elem) => {
          return elem.id === current.id;
        }).length === 0
      );
    };
  }

  function handleChangeGroups(permission, checked) {
    const { permissionGroups } = permissions;
    let permissionGroupsCpy = [...permissionGroups];
    permissionGroupsCpy = permissionGroupsCpy.map((elem) => {
      if (elem.id === permission.id) {
        if (checked) {
          let usersCpy = { ...elem.users };
          Object.keys(usersCpy).map((key) => (usersCpy[key] = true));
          return { ...elem, checked, users: usersCpy };
        } else {
          return { ...elem, checked };
        }
      } else {
        return elem;
      }
    });
    if (checked) {
      let permissionUsersCpy = [...permissions.permissionUsers];
      Object.keys(permission.users).forEach((key) => {
        permissionUsersCpy = permissionUsersCpy.filter(
          (user) => user.id !== key
        );
      });
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy,
      });
    } else {
      setPermissions({ ...permissions, permissionGroups: permissionGroupsCpy });
    }
  }

  function getUserSelected(permissions) {
    const userSelected = [...permissions.permissionUsers];
    permissions.permissionGroups.forEach((group) => {
      if (group.checked) {
        Object.keys(group.users).forEach((userId) => {
          const user = companyUsers.find((user) => user.id === userId);
          if (user && !userSelected.find((el) => el.id === userId)) {
            userSelected.push(user);
          }
        });
      }
    });
    return userSelected;
  }

  function getUserAndGroupPermissionsObj() {
    let permissionUsersObj = {};
    let permissionGroupsObj = {};
    const { permissionUsers, permissionGroups } = permissions;
    permissionUsers.forEach((user) => (permissionUsersObj[user.id] = true));
    permissionGroups.forEach((group) => {
      if (!!group.checked) {
        permissionGroupsObj[group.id] = true;
      }
    });
    return {
      permissionUsers: permissionUsersObj,
      permissionGroups: permissionGroupsObj,
    };
  }

  return (
    <React.Fragment>
      <CustomModal
        isOpen={open}
        onClose={onClose}
        className="modal-container-permissions"
        key={file.id}
      >
        <ModalPermissionPlaceholderDocumentStyled
          className="dragAndDropModalDialog"
          key={file.id}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleDone(getUserAndGroupPermissionsObj());
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === "Escape") {
              handleCancel();
            }
          }}
        >
          <div className={"modalTitle"}>
            <p className={"selectDocumentType"}>
              {title && <IntlMessages id={title} />}
            </p>
            {uploadMultipleFiles && (
              <div>
                <img
                  className={"wrongUploadFile"}
                  src={wrongUploadFile}
                  alt="wrong upload file"
                />
                <p className={"onlyOneFileCanBe"}>
                  {
                    <IntlMessages id="components.documents.menupermission.onlyonefilecanbeuploadedatatime" />
                  }
                </p>
              </div>
            )}
          </div>
          <div className={"modalDocumentSectionOnUpload"}>
            <div className={"fileInformation"}>
              <TypeFileSelector
                className="classesnamesicon"
                type={typeFile}
                mainFile={true}
                placeholder={true}
                key={file.id}
              />
              <div className={"listItemTextPrimary"}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label className={"filename"}>
                    {file.name.split(".")[0]}
                  </label>
                  <label className={"filesize"}>
                    {"(" +
                      getSizeTransformedAccordingKBSize(+file.size, file.type) +
                      ")"}{" "}
                  </label>
                </div>
                <div className={"listItemTextSecondary"}>
                  <label className={"fileinfo"}>
                    Added {moment().format("h:mm a M/D/YY")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className={"modalPermissionsOnUpload"}>
            <div className={"documentType"}>
              <div>
                <label>
                  {
                    <IntlMessages id="components.documents.modalpermission.documenttype" />
                  }
                </label>
                <div className={"permissionsGroupContainer"}>
                  <div className={"multiselect"}>
                    {permissions.permissionGroups
                      .sort(sortObjectsBy("name", false))
                      .map((permission, index) => (
                        <div
                          key={permission.id + index}
                          className={"permissionContent"}
                          onClick={(ev) => {
                            if (!!permission.enable) {
                              handleChangeGroups(
                                permission,
                                !permission.checked
                              );
                            }
                            ev.stopPropagation();
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={permission.checked}
                            disabled={!permission.enable}
                            className="permissionCheck"
                          />
                          <CustomTooltip
                            label={getNewToolTipByPermissionGroup({
                              permission,
                              users: companyUsers,
                            })}
                            style={{ color: permission.enable ? "" : "#ccc" }}
                          >
                            {permission.name}
                          </CustomTooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={"viewPermissionOnUpload"}>
              <label>
                {
                  <IntlMessages id="components.documents.modalpermission.viewpermission" />
                }
              </label>
              <ModalPermissionUsers
                key={file.id}
                users={companyUsers}
                userSelected={getUserSelected(permissions)}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className={"modalButtonsSection"}>
            <button className={"btn-cancel"} onClick={handleCancel}>
              {
                <IntlMessages id="components.documents.modalpermission.cancel" />
              }
            </button>
            <button
              className={"btn-done"}
              onClick={() => {
                handleDone(getUserAndGroupPermissionsObj());
              }}
            >
              {<IntlMessages id="components.documents.modalpermission.done" />}
            </button>
          </div>
        </ModalPermissionPlaceholderDocumentStyled>
      </CustomModal>
    </React.Fragment>
  );
}

export default ModalPermission;
