import React, { useState, useCallback } from "react";
import { dbTables } from "../../api/types/dbTables";
import File from "../../api/model/file";
import { now } from "moment";
import { getRandomId, getTypeFile } from "../../helpers/helpers";
import { useDispatch } from "react-redux";
import { addGenericDataToFirestore } from "../../actions/DataActions";
import ModalPermission from "./ModalPermission";
import AttentionModal from "../Modal/AttentionModal";
import { useUser, useCompanyUsers } from "../../hooks/user";
import { addNewDocumentType, documentScope } from "../../helpers/documents";
import { useCompany } from "../../hooks/company";
// import { trackEvent } from "../../helpers/analytics";
import { storage } from "../../firebase";
import { orderType } from "../../helpers/salesOrder";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { errorDocumentName } from "../../helpers/constants";
import { hasExtension, replaceDocumentState } from "./DocumentHelper";
import { Alert } from "@mui/material";

const documentScopesObj = {
  [dbTables.SALES_ORDERS]: documentScope.SALES_ORDER,
  [dbTables.PURCHASE_ORDERS]: documentScope.PURCHASE_ORDER,
  [dbTables.SHIPMENTS]: documentScope.SHIPMENT,
  [dbTables.CUSTOMERS]: documentScope.CUSTOMER,
  [dbTables.FACTORIES]: documentScope.FACTORY,
};

function DragAndDropUploadFile({
  companyID,
  mainTable,
  mainTableID,
  dbTable,
  documents,
  permissionGroupsDB,
  disabled,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  allowMultipleFiles,
  orderIsVoided,
}) {
  const [open, setOpen] = useState(false);
  const [snackOption, setSnackOption] = useState({});
  const [file, setFile] = useState([]);
  const [fileUploading, setFileUploading] = useState([]);
  const [progressUpload, setProgressUpload] = useState(0);
  const [newFileToUpload, setNewFileToUpload] = useState([]);
  const [attentionModalDescription, setAttentionModalDescription] = useState(
    ""
  );

  const [stylesDiv, setStylesDiv] = useState(
    "drag-and-drop-upload-files-disable"
  );
  const currentUser = useUser();
  const company = useCompany();
  const companyUsers = useCompanyUsers({
    showInactiveUsers: false,
    showBotUser: false,
  });
  const dispatch = useDispatch();
  const addDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [
        dbTables.COMPANIES,
        companyID,
        mainTable,
        mainTableID,
        dbTable,
        document.id,
      ],
      document
    )(dispatch)
  );

  const addDocumentVersion = useCallback((document, documentVersionId) =>
    addGenericDataToFirestore(
      [
        dbTables.COMPANIES,
        companyID,
        mainTable,
        mainTableID,
        dbTable,
        documentVersionId,
        dbTables.DOCUMENT_VERSIONS,
        document.id,
      ],
      document
    )(dispatch)
  );

  const [uploadMultipleFiles, setUploadMultipleFiles] = useState(false);
  function handleSuccessDrag(permissions) {
    const { permissionUsers, permissionGroups } = permissions;
    newFileToUpload.forEach((currentFile) => {
      const {
        documentVersionId,
        name,
        documentId,
        version,
        lastModified,
        lastModifiedDate,
      } = currentFile;
      let { type, size } = currentFile;
      const user = currentUser.id;
      let path = name;
      let parentId = "";
      // trackEvent("PO Dashboard - Documents - Upload via Drag-and-Drop", {
      //   filename: name,
      //   version,
      // });

      const mainFile = true;
      //
      console.log("BEFORE WE GET IN");
      getDownloadURL(
        ref(
          storage,
          `${dbTables.COMPANIES}/${companyID}/${mainTable}/${mainTableID}/${dbTable}/${documentVersionId}/version/${documentId}/${name}`
        )
      ).then((url) => {
        console.log("URL ::: ", url);
        let id = documentVersionId;
        const fileVersion = new File({
          id,
          name,
          path,
          url,
          type,
          version,
          parentId,
          mainFile,
          lastModified,
          lastModifiedDate,
          size,
          user,
          createdBy: user,
          permissionUsers,
          permissionGroups,
          scope: documentScopesObj[mainTable],
        });

        addDocument({ ...fileVersion });
        id = documentId;
        const newFile = new File({
          id,
          name,
          path,
          url,
          type,
          version,
          lastModified,
          lastModifiedDate,
          size,
          user,
          createdBy: user,
          permissionUsers,
          permissionGroups,
          scope: documentScopesObj[mainTable],
          mainFileId: documentVersionId,
        });
        addDocumentVersion({ ...newFile }, documentVersionId);
        addNewDocumentType({ company, document: newFile });
      });
    });
    cleanData();
  }

  async function handleFiles(files) {
    const originalFile = [];
    const filesData = [];
    for (let i = 0; i < files.length; i++) {
      const currentFile = files[i];
      const { lastModifiedDate, size } = currentFile;
      const lastModified = now();
      const type = getTypeFile(currentFile.name);
      let documentVersionId = getRandomId();
      let documentId = getRandomId();
      let version = 1;
      let name = currentFile.name;
      const nameWithoutExtension = currentFile.name.split(".")[0];
      documents.forEach((element) => {
        const nameElement = element.name.split(".")[0];
        if (nameElement === nameWithoutExtension) {
          version = element.version + 1;
          documentVersionId = element.id;
        }
      });
      const fileData = {
        documentVersionId,
        name,
        documentId,
        version,
        type,
        lastModified,
        lastModifiedDate,
        size: size / 1000,
        permissionUsers: {},
        permissionGroups: {},
      };

      const path = `companies/${companyID}/${mainTable}/${mainTableID}/${dbTable}/${documentVersionId}/version/${documentId}/${name}`;
      originalFile.push(files[i]);
      filesData.push({ ...fileData, path });
    }
    if (filesData.length === 0) {
      setAttentionModalDescription(errorDocumentName.file);
      setUploadMultipleFiles(true);
      return;
    }
    setFile(filesData);
    setOpen(true);
    for (let j = 0; j < filesData.length; j++) {
      const fileData = filesData[j];
      const storageRef = ref(storage, fileData.path);
      const task = uploadBytesResumable(storageRef, originalFile[j]);
      task.on(
        "state_changed",
        (snapshot) => {
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressUpload((oldValues) => {
            const newValues = typeof oldValues === "object" ? oldValues : {};
            return {
              ...newValues,
              [fileData.name]: percent,
            };
          });
          if (percent === 0) {
            setFileUploading((oldFiles) => {
              const newFiles = oldFiles;
              newFiles.push(task);
              return newFiles;
            });
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          setNewFileToUpload((oldFiles) => {
            const newFiles = oldFiles;
            newFiles.push(fileData);
            return newFiles;
          });
          setFile((oldData) => replaceDocumentState({ oldData, fileData }));
        }
      );
      await task;
    }
  }

  function handleOnDrop(event, bySelect) {
    if (isReadOnly) {
      handleReadOnlyModal();
      return;
    }
    if (disabled) {
      return;
    }
    const files = bySelect
      ? [...event.target.files]
      : [...event.dataTransfer.files];
    if (files.length > 1 && !allowMultipleFiles) {
      setAttentionModalDescription("Only one file can be uploaded at time");
      setUploadMultipleFiles(true);
    }
    const filesUpload = files.filter((file) => hasExtension(file.name));
    if (filesUpload.length === 0) {
      setAttentionModalDescription(
        "Only files with an extension at the end can be uploaded. Nothing was uploaded in this case."
      );
      setUploadMultipleFiles(true);
      return;
    }
    if (filesUpload.length !== files.length) {
      setSnackOption({
        type: "warning",
        message:
          "Only files with extensions are being uploaded, the others will be skipped",
      });
    }

    setOpen(true);
    handleFiles(
      Array.from(filesUpload).sort((a, b) => {
        if (a.name < b.name) {
          return 1;
        } else if (a.name > b.name) {
          return -1;
        } else {
          return 0;
        }
      })
    );
    return;
  }

  const cleanData = () => {
    setFile([]);
    setOpen(false);
    setNewFileToUpload([]);
    setProgressUpload(0);
    setUploadMultipleFiles(false);
    handleCloseAlert();
  };

  function onClose() {
    cleanData();
  }

  function notificatorErrorRemoveUser() {
    console.log("********you cant remove");
  }
  const handleCloseAlert = (ev, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOption({});
  };

  let tabObject = {
    [dbTables.SALES_ORDERS]: orderType.SALES_ORDER,
    [dbTables.PURCHASE_ORDERS]: orderType.PURCHASE_ORDER,
    [dbTables.CUSTOMERS]: dbTables.CUSTOMERS,
    [dbTables.FACTORIES]: dbTables.FACTORIES,
  };
  return (
    <div>
      {uploadMultipleFiles && (
        <AttentionModal
          isOpen={uploadMultipleFiles}
          title="Attention"
          description={attentionModalDescription}
          onClick={() => {
            setUploadMultipleFiles(false);
            setFile({});
            setOpen(false);
          }}
        />
      )}

      {file.length > 0 && !uploadMultipleFiles && open && (
        <ModalPermission
          title="components.documents.draganddropuploadfile.modalpermission.selectdocumenttypeandviewpermissions"
          open={open}
          onClose={onClose}
          file={file}
          warningMessage={
            snackOption.message && (
              <Alert
                style={{
                  width: "100%",
                  marginBottom: 18,
                  border: "2px solid #d5b54f",
                }}
                severity={snackOption.type}
              >
                {snackOption.message}
              </Alert>
            )
          }
          handleCancel={() => {
            fileUploading.forEach((fileRef) => {
              Object.keys(fileRef).length > 0 && fileRef.cancel();
            });
            try {
              file.forEach((currentFile) => {
                if (currentFile.completed) {
                  deleteObject(ref(storage, currentFile.path));
                }
              });
            } catch (error) {
              console.log(error);
            }
            cleanData();
          }}
          handleDone={handleSuccessDrag}
          companyUsers={companyUsers}
          progressUpload={progressUpload}
          uploadMultipleFiles={uploadMultipleFiles}
          permissionGroupsDB={permissionGroupsDB}
          currentUser={currentUser.id}
          notificatorErrorRemoveUser={notificatorErrorRemoveUser}
        />
      )}
      <div
        className={stylesDiv + tabObject[mainTable]}
        style={{ opacity: orderIsVoided ? 0.35 : 1 }}
        onDrop={(dropEvent) => {
          dropEvent.preventDefault();
          if (orderIsVoided) {
            handleReadOnlyModal();
            return;
          }
          handleOnDrop(dropEvent);
          setStylesDiv("drag-and-drop-upload-files-disable");
          dropEvent.stopPropagation();
        }}
        onDragOver={(dragOverEvent) => {
          dragOverEvent.preventDefault();
          if (orderIsVoided) {
            return;
          }
          setStylesDiv("drag-and-drop-upload-files-enable");
          dragOverEvent.stopPropagation();
        }}
        onDragEnter={(event) => {
          if (orderIsVoided) {
            return;
          }
          setStylesDiv("drag-and-drop-upload-files-disable");
          event.stopPropagation();
        }}
        onDragLeave={() => {
          setStylesDiv("drag-and-drop-upload-files-disable");
        }}
      >
        <p
          style={{ fontFamily: "Avenir Next" }}
          className="drag-and-drop-upload-files-text"
        >{`Drag & Drop Files`}</p>
        <input
          id="principalDropOption"
          type="file"
          onChange={(ev) => {
            ev.preventDefault();
            if (orderIsVoided) {
              handleReadOnlyModal();
              return;
            }
            handleOnDrop(ev, true);
            setStylesDiv("drag-and-drop-upload-files-disable");
            ev.stopPropagation();
          }}
          style={{ display: "none" }}
          multiple={allowMultipleFiles}
        />
      </div>
    </div>
  );
}

export default DragAndDropUploadFile;
