import React from "react";
import IntlMessages from "../../util/IntlMessages";

import DataTable from "../DataTable/DataTable";
import { tableheader } from "./TableHeader";
import { TableCell, TableRow } from "@mui/material";

function CustomerTable({ onItemClick, customers }) {
  return (
    <DataTable
      header={tableheader.map((item, index) => (
        <TableCell key={item + index} style={{ top: 60 }}>
          <IntlMessages id={item} />
        </TableCell>
      ))}
    >
      {customers.map((customer) => (
        <TableRow
          key={customer.id}
          classes={{ hover: "hover" }}
          hover
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onItemClick(customer);
          }}
        >
          <TableCell>{customer.name}</TableCell>
          <TableCell>{customer.contactName}</TableCell>
          <TableCell>{customer.latePenaltyTerms}</TableCell>
          <TableCell>{customer.notes}</TableCell>
        </TableRow>
      ))}
    </DataTable>
  );
}

export default CustomerTable;
