import React from "react";
import { getFactoryName, sortObjectsBy } from "../../helpers/helpers";
import POBadge from "./POBadge";
import { PurchaseOrderBadgeStyled } from "./styles";

function PurchaseOrderBadges({
  purchaseOrders,
  onChangePOBadge,
  onMouseEnter,
  onMouseLeave,
  factories,
  selectedShipments,
  onChangeSelectedShipments,
  shipmentOnHover,
}) {
  return (
    <PurchaseOrderBadgeStyled
      id="purchase-order-badges-container"
      className={"purchaseOrderBadgedContainer"}
    >
      {purchaseOrders
        .sort(sortObjectsBy("number", false))
        .map((purchaseOrder) => (
          <POBadge
            key={purchaseOrder.id}
            purchaseOrder={{
              ...purchaseOrder,
              factoryName: getFactoryName(factories, purchaseOrder.factoryId),
            }}
            onChangePOBadge={onChangePOBadge}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            purchaseOrders={purchaseOrders}
            selectedShipments={selectedShipments.filter((shipment) =>
              purchaseOrder.shipmentIds.includes(shipment.id)
            )}
            onChangeSelectedShipments={onChangeSelectedShipments}
            shipmentOnHover={shipmentOnHover}
          />
        ))}
    </PurchaseOrderBadgeStyled>
  );
}

export default PurchaseOrderBadges;
