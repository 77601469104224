import React, { useState } from "react";
import _ from "lodash";
import ListPicker from "../DatePicker/ListPicker";
import TooltipTD from "../Tooltip/TooltipTD";
import iconHiddenData from "../../../src/assets/flag-icons/show_all_rows.svg";
import { MISMATCH_STATUS, notAllowedToSee } from "../../helpers/constants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getShipmentBadge } from "../../helpers/shipments";
import ShipmentsIcon from "../../assets/flag-icons/sidebar-shipments.svg";
import { CellTableShipmentStyled } from "./styles";
import itemStatus from "../../api/types/itemStatus";
import { updateDoc } from "firebase/firestore";
import SKUEditableLabel from "../TextFields/SKUEditableLabel";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { usePurchaseOrders, useSalesOrders } from "../../hooks/salesOrders";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";

const getValue = ({ column, row, item, customers, salesOrders, shipments }) => {
  let value = column.complexValue
    ? column.complexValue(row, item)
    : row[column.id];

  let valueWithFormat = column.format
    ? column.format({ value, customers, salesOrders, shipments })
    : value;
  if (row.hiddenValue) {
    value = "";
    valueWithFormat = "";
  }
  const showValueText = column.showFormatValue ? valueWithFormat : value;
  return { value, valueWithFormat, showValueText };
};

export default function CellTableShipmentV2({
  row,
  column,
  item,
  prefix,
  showIcon,
  iconToShow,
  showIconMultiRow,
  onChangeLabel,
  onSelectData,
  cursor,
  onOpenModal,
  showNewShipment,
  onOpenRow,
  showBadge,
  showImage,
  valueURL = "",
  onPhotosViewer,
  isAllowed = () => {},
  companyAllowed = () => {},
  parentRow = {},
  poisOutdated,
  hasMismatch,
  shipments,
  isVoided,
  onClickRedirect = () => {},
}) {
  const purchaseOrders = usePurchaseOrders();
  const factories = useFactories();
  const customers = useCustomers();
  const salesOrders = useSalesOrders();
  const [urlImage] = useState(
    column.type === "image" && valueURL.startsWith("http://")
      ? valueURL.replace("http://", "https://")
      : valueURL
  );
  let { value, valueWithFormat, showValueText } = getValue({
    column,
    item,
    row,
    customers,
    salesOrders,
    shipments,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const hasPermission = column.permission ? isAllowed(column.permission) : true;
  const amountPermission = column.amountPermission
    ? isAllowed(column.amountPermission)
    : true;
  const hasCompanyPermission = column.companyPermission
    ? companyAllowed(column.companyPermission)
    : true;
  const isReadOnly = row.status === itemStatus.READ_ONLY || isVoided;
  const isEditable =
    column.isEditable &&
    hasPermission &&
    hasCompanyPermission &&
    !isReadOnly &&
    amountPermission;

  const open = Boolean(anchorEl);
  const tooltipStyle = column.tooltipStyle || {};
  let showToolTip = column.showToolTip || false;
  let tooltipText = column.tooltipText
    ? column.tooltipText({ item, purchaseOrders, factories })
    : showValueText;
  const styleToolTip = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor,
    ...tooltipStyle,
  };
  const bodyStyle = column.bodyStyle || {};
  const withIconStyle = showIcon
    ? { display: "flex", alignItems: "center" }
    : {};
  let showToolTipHelper = false;
  let cartonsStyle = {};
  if (column.checkRound && value % 1 !== 0) {
    valueWithFormat = value.toFixed(1);
    cartonsStyle = { color: "red" };
    showToolTipHelper = true;
    tooltipText = "The order quantity is not a whole number of cartons";
  }
  let editableTooltip = column.showFormatValue ? valueWithFormat : value;
  if (column.orderQuantityCheck && hasMismatch) {
    showToolTip = true;
    editableTooltip = column.orderQuantityCheck;
    tooltipText = column.orderQuantityCheck;
  }
  return (
    <CellTableShipmentStyled
      className={"cellTableShipment"}
      style={{
        ...bodyStyle,
        justifyContent: showIconMultiRow ? "center" : bodyStyle.justifyContent,
      }}
    >
      {column.type === "image" && showImage && (
        <img
          style={{
            width: 50,
            cursor: "pointer",
          }}
          src={urlImage}
          onClick={(ev) => {
            onPhotosViewer();
            ev.stopPropagation();
          }}
          alt={"MenuIcon"}
        />
      )}
      {open && (
        <ListPicker
          anchorEl={anchorEl}
          onChange={async (currentItem) => {
            const shipment = currentItem;
            let { purchaseOrderIds, salesOrderIds = [] } = shipment;
            const itemSalesOrderIds = item.salesOrderIds;
            await updateDoc(shipment.ref, {
              purchaseOrderIds: _.uniq([
                ...purchaseOrderIds,
                item.currentPurchaseOrder,
              ]),
              salesOrderIds: _.uniq([...salesOrderIds, ...itemSalesOrderIds]),
            });
            onSelectData(shipment);
            setAnchorEl(null);
          }}
          open={open}
          onClose={() => setAnchorEl(null)}
          selectedValue={value}
          selectedValueTalble={row.id}
          showNewShipment={showNewShipment}
          poisOutdated={poisOutdated}
          filterBy={item[column.filterBy]}
          customerId={parentRow.customerId}
          onOpenModal={() => {
            onOpenModal();
            setAnchorEl(null);
          }}
        />
      )}
      {showBadge && (
        <div className="shipment-badge-container">
          {showIconMultiRow && (
            <div
              onClick={() => {
                onOpenRow();
              }}
              style={{
                cursor: "pointer",
                fontWeight: 700,
                ...column.icon,
              }}
            >
              <img
                src={iconHiddenData}
                alt={"iconRow"}
                style={{
                  marginRight: 5,
                }}
              />
              <span>{(item.shipmentIds || []).length}</span>
            </div>
          )}
          {!showIconMultiRow &&
            row.status !== "new" &&
            getShipmentBadge({
              shipment: shipments.find((shipment) => shipment.id === row.id),
              styles: { margin: "0px 4px 0px 0px" },
              classes: shipmentBadgeClass.mediumInverted,
              onChangeShipment: (shipment) => {
                console.log(shipment);
                onClickRedirect({
                  item: {
                    ...shipment,
                    purchaseOrderId: item.currentPurchaseOrder,
                  },
                  badgeShipment: true,
                });
              },
            })}

          {!showIconMultiRow &&
            !isReadOnly &&
            row.id === MISMATCH_STATUS.NEW &&
            row.status === MISMATCH_STATUS.INCOMPLETE && (
              <div
                style={{ width: showIcon ? "50%" : "" }}
                className="newShipmentBadge"
                onClick={(ev) => {
                  if (
                    column.select &&
                    (!row.customerId || row.id === MISMATCH_STATUS.NEW)
                  ) {
                    setAnchorEl(ev.currentTarget);
                  }
                }}
              >
                <img
                  id="sidebar-menu-icons-img"
                  width={14}
                  height={14}
                  src={ShipmentsIcon}
                  alt="ShipmentsIcon"
                  className="shipmentIcon"
                />
                Shipment
                <ArrowDropDownIcon style={{ width: 22 }} />
              </div>
            )}
        </div>
      )}
      {!showBadge && column.type !== "image" && (
        <div
          style={{
            ...withIconStyle,
            position: column.subColumn ? "relative" : "",
          }}
          className="table-shipment-wrapper"
        >
          {showIcon ? (
            <span className="disclosure-triangle-icon">{iconToShow}</span>
          ) : (
            column.id === "itemNumber" && <span className="empty-space"></span>
          )}
          {showIconMultiRow && (
            <div
              onClick={() => {
                onOpenRow();
              }}
              style={{
                cursor: "pointer",
                fontWeight: 700,
                ...column.icon,
              }}
            >
              <img
                src={iconHiddenData}
                alt={"iconRow"}
                style={{
                  marginRight: 5,
                }}
              />
              <span>{(item.salesOrders || []).length}</span>
            </div>
          )}
          {!showIconMultiRow && isEditable && amountPermission && (
            <SKUEditableLabel
              key={`${prefix}_${row.id}_editable`}
              tooltipLabel={editableTooltip}
              toolTipStyle={showToolTip ? styleToolTip : { cursor }}
              showToolTip={showToolTip}
              isNewData={row.status === MISMATCH_STATUS.NEW && column.canBeNew}
              showEditIcon={!row.hiddenValue}
              value={value}
              format={column.format}
              inputType={column.type}
              isNewValue={row.status === MISMATCH_STATUS.NEW}
              isShipmentNew={row.id === "new" && row.status === "new"}
              onChangeLabel={onChangeLabel}
              preventDelete={!column.canBeErase && row.id !== "new"}
              labelClass={"editable-text-item"}
              hiddenValue={row.hiddenValue}
              columnId={column.id}
            />
          )}

          {!showIconMultiRow && !isEditable && (
            <div
              className="cell-table-shipment-value"
              style={{
                display: "flex",
                overflow: "hidden",
                fontWeight: 400,
                marginRight:
                  column.isEditable &&
                  (isReadOnly || !hasCompanyPermission || !hasPermission)
                    ? 18
                    : null,
                ...cartonsStyle,
              }}
              id={column.id}
            >
              <TooltipTD
                label={tooltipText}
                style={showToolTip ? styleToolTip : { cursor, ...tooltipStyle }}
                showToolTip={showToolTip || showToolTipHelper}
                hasArrow={true}
                tooltipClick={(ev) => {
                  if (column.select && (!row.customerId || row.id === "new")) {
                    setAnchorEl(ev.currentTarget);
                    return;
                  }
                  const redirectAction = {
                    customer: column.customerRedirect,
                    so: column.soRedirect,
                    row,
                  };
                  if (!redirectAction.customer && !redirectAction.so) {
                    return;
                  }
                  onClickRedirect(redirectAction);
                  ev.stopPropagation();
                }}
              >
                {amountPermission ? valueWithFormat : notAllowedToSee}
              </TooltipTD>
            </div>
          )}
        </div>
      )}
    </CellTableShipmentStyled>
  );
}
