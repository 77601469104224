import React, { useState, useEffect } from "react";
import { dbTables } from "../../../api/types/dbTables";
import NotesFoldersStructure from "./NotesFoldersStructure";
import {
  convertToTree,
  getPathDocumentLocalStorage,
  getIdFromStructureFolders,
  useLocationPath,
} from "../../../helpers/helpers";
import DocumentsNoteHeader from "./DocumentsNoteHeader";
import { useUser } from "../../../hooks/user";
import { filtersDocumentsPermission } from "../../../helpers/documents";
import Mark from "mark.js";
import { ManagementDocumentNoteStyled } from "./styles";
import { TYPE_OF_FILE } from "../../../helpers/constants";
import { useJSONLocalStorage } from "../../../hooks";
import {
  getBackgroundColorByType,
  getBorderColorByType,
} from "../../../helpers/orderDashboard";
import { orderType } from "../../../helpers/salesOrder";
import { useIsAllowedFunction } from "../../../hooks/permissions";

function ManagementDocumentsNote({
  companyID,
  mainTable = dbTables.SALES_ORDERS,
  mainTableID,
  documents,
  dbTable,
  selectedDocumentTab,
  hasTitle = false,
  permissionGroupsDB,
  documentData = {},
  handleRichDocument = () => {},
  handleEditPermission = () => {},
  currentShipment = {},
  permissionScope,
}) {
  const path = useLocationPath();
  const pathLocalStorage = `${getPathDocumentLocalStorage(
    path
  )}DocumentStructure`;
  const { get, set } = useJSONLocalStorage(pathLocalStorage);
  const [documentTree, setDocumentTree] = useState([]);
  const [isDraggableDocument, setIsDraggableDocument] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const user = useUser();
  const [search, setSearch] = useState("");
  const allowed = useIsAllowedFunction();

  useEffect(() => {
    const userDocuments = filtersDocumentsPermission({
      allowed,
      documents,
      permissionGroupsDB,
      permissionScope,
      user,
    });
    const currentDocumentTree = convertToTree(userDocuments);
    const allFolders = currentDocumentTree.filter(
      (document) => document.type === TYPE_OF_FILE.FOLDER
    );
    const localStorageData = get() || {};
    const poLocalStorage = localStorageData[mainTableID] || {};
    const isFolderOpen = allFolders.every(
      (folder) => poLocalStorage[folder.id]
    );
    if (isExpand !== isFolderOpen) {
      setIsExpand(isFolderOpen);
    }
    setDocumentTree(currentDocumentTree);
  }, [documents]);

  useEffect(() => {
    setTimeout(() => {
      performMark(search);
    }, 250);
  }, [search]);

  function onDraggableDocument(document) {
    handleRichDocument(document);
    setIsDraggableDocument(true);
  }

  function onDragEnd() {
    setTimeout(() => handleRichDocument({}), 100);
    setIsDraggableDocument(false);
  }

  function findDocumentChild(document, search) {
    if (document.type !== TYPE_OF_FILE.FOLDER) {
      return false;
    }
    const isThereDocuments = document.child.filter(
      (doc) =>
        doc.name.toLowerCase().includes(search.toLowerCase()) ||
        findDocumentChild(doc, search)
    );
    if (isThereDocuments.length > 0) return true;
    return false;
  }

  function updateLocalStorageData(value) {
    const idsFolder = getIdFromStructureFolders(documentTree);
    const localStorageData = get() || {};
    const newLocalStorageData = idsFolder.reduce((obj, item) => {
      return {
        ...obj,
        [item]: value === false ? undefined : value,
      };
    }, {});
    set({
      ...localStorageData,
      [mainTableID]: {
        ...newLocalStorageData,
      },
    });
  }

  function getDocumentThree(documentTree, search) {
    if (!search) {
      return documentTree;
    }
    return documentTree.filter(
      (document) =>
        document.name.toLowerCase().includes(search.toLowerCase()) ||
        findDocumentChild(document, search)
    );
  }

  const markInstance = new Mark(
    document.getElementById("folder-estructure-list-notes")
  );
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: ["filename", "fileinfo"],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }
  let tabObject = {
    [dbTables.SALES_ORDERS]: orderType.SALES_ORDER,
    [dbTables.PURCHASE_ORDERS]: orderType.PURCHASE_ORDER,
  };

  return (
    <ManagementDocumentNoteStyled
      className={"managementDocumentContainer"}
      style={{
        background: getBackgroundColorByType({
          type: tabObject[mainTable],
        }),
        border: `2px solid ${getBorderColorByType({
          type: tabObject[mainTable],
        })}`,
        position: "relative",
      }}
    >
      <DocumentsNoteHeader
        hasTitle={hasTitle}
        handleExpandOrCollapse={(value) => {
          updateLocalStorageData(value);
          setIsExpand(value);
        }}
        isExpand={isExpand}
        onQuerySearch={(value) => setSearch(value)}
        mainTableID={mainTableID}
      />
      <div className={"folderStructureWrapper"}>
        <div
          className={"folderStructureList"}
          id="folder-estructure-list-notes"
        >
          <NotesFoldersStructure
            documents={
              documentTree ? getDocumentThree(documentTree, search) : []
            }
            markAsTemplate={
              documentTree
                ? documentTree.filter((doc) => doc.placeholder === true).length
                : 0
            }
            companyID={companyID}
            mainTable={mainTable}
            mainTableID={mainTableID}
            dbTable={dbTable}
            selectedDocumentTab={selectedDocumentTab}
            permissionGroupsDB={permissionGroupsDB}
            onDraggableDocument={onDraggableDocument}
            onDragEnd={onDragEnd}
            draggableDocument={documentData}
            isDraggableDocument={isDraggableDocument}
            documentFlat={documents}
            isExpand={isExpand}
            handleExpandOrCollapse={(value) => {
              const userDocuments = filtersDocumentsPermission({
                allowed,
                documents,
                permissionGroupsDB,
                permissionScope,
                user,
              });
              const allFolders = userDocuments.filter(
                (document) => document.type === TYPE_OF_FILE.FOLDER
              );
              const localStorageData = value || {};
              const poLocalStorage = localStorageData[mainTableID] || {};
              const isFolderOpen = allFolders.every(
                (folder) => poLocalStorage[folder.id]
              );
              if (isExpand !== isFolderOpen) {
                setIsExpand(isFolderOpen);
              }
            }}
            querySearch={search}
            handleEditPermission={handleEditPermission}
            rootStructure
            currentShipment={currentShipment}
          />
        </div>
      </div>
    </ManagementDocumentNoteStyled>
  );
}

export default ManagementDocumentsNote;
