import { generalPermissions, headerColumns } from "../../helpers/constants";
import { POObjFields } from "../../helpers/orderDashboard";

export const purchaseOrderListHeader = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    styles: {
      width: 40,
      textAlign: "center",
      padding: 9,
    },
    exportLabel: "Star",
    key: "favorite",
    searchBy: false,
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    canBeSort: false,
    filter: false,
    styles: {
      width: 50,
      textAlign: "center",
      padding: 0,
    },
    exportLabel: "Status",
    key: "status",
    searchBy: false,
  },
  [headerColumns.PROGRESS]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    canBeSort: true,
    reference: "progress",
    filter: false,
    styles: {
      width: 35,
      textAlign: "center",
      padding: "9px 16px",
    },
    exportLabel: "Completion",
    key: "progress",
    searchBy: false,
    arrowStyles: {
      left: 12,
    },
  },
  [headerColumns.MENTIONS]: {
    label: "userMentionCount",
    id: 3,
    canBeSort: true,
    reference: "totalMentions",
    item: "AVATAR",
    filter: false,
    styles: {
      width: 65,
      textAlign: "center",
      padding: "9px 0px 9px 20px",
    },
    exportLabel: "Mentions",
    key: "mentions",
    searchBy: false,
    arrowStyles: {
      left: 49,
    },
  },
  [headerColumns.PO]: {
    label: "PO/ \u00A0\u00A0\u00A0\u00A0\u00A0 Purchase Quote",
    id: 4,
    canBeSort: true,
    reference: POObjFields.NUMBER,
    filter: false,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "0px 65px 0px 20px",
    },
    exportLabel: "PO",
    key: "PONumber",
    arrowStyles: {
      top: 20,
      left: 125,
    },
    searchBy: POObjFields.NUMBER,
  },
  [headerColumns.SO]: {
    label: "Quote/Sales Order",
    id: 5,
    canBeSort: true,
    reference: "SONumber",
    filter: true,
    selected: true,
    styles: {
      width: 155,
      maxWidth: 185,
      textAlign: "left",
      padding: "0px 35px 0px 24px",
    },
    exportLabel: "SO",
    key: "SONumber",
    searchBy: "SONumber",
    arrowStyles: {
      left: 144,
    },
  },
  [headerColumns.SHIPMENTS]: {
    label: headerColumns.SHIPMENTS,
    id: 6,
    canBeSort: false,
    reference: "shipments",
    filter: true,
    selected: true,
    styles: {
      width: 125,
      textAlign: "left",
      padding: "0px 22px",
    },
    exportLabel: "Shipments",
    key: "shipments",
    searchBy: headerColumns.SHIPMENTS,
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 7,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "0px 5px",
    },
    exportLabel: headerColumns.CUSTOMER,
    key: "customerName",
    searchBy: "customerName",
    arrowStyles: {
      left: 71,
    },
  },
  [headerColumns.CREATION_DATE]: {
    label: headerColumns.CREATION_DATE,
    id: 8,
    canBeSort: true,
    reference: "creationDate",
    filter: true,
    selected: true,
    styles: {
      width: 120,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: headerColumns.CREATION_DATE,
    key: "creationDate",
    searchBy: false,
    arrowStyles: {
      left: 103,
    },
  },
  [headerColumns.ORDER_READY_DATE]: {
    label: headerColumns.ORDER_READY_DATE,
    id: 9,
    canBeSort: true,
    reference: "orderReadyDate",
    filter: true,
    selected: true,
    styles: {
      width: 105,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: headerColumns.ORDER_READY_DATE,
    key: "orderReadyDate",
    arrowStyles: {
      top: 20,
      left: 92,
    },
    searchBy: false,
  },
  [headerColumns.VENDOR]: {
    label: headerColumns.VENDOR,
    id: 10,
    canBeSort: true,
    reference: "factoryName",
    filter: true,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.VENDOR,
    key: "factoryName",
    searchBy: "factoryName",
    arrowStyles: {
      left: 66,
    },
  },
  [headerColumns.DISCOUNT_PERCENTAGE]: {
    label: headerColumns.DISCOUNT_PERCENTAGE,
    id: 11,
    canBeSort: true,
    reference: "discountPercentage",
    filter: true,
    selected: true,
    styles: {
      width: 110,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: headerColumns.DISCOUNT_PERCENTAGE,
    key: "discountPercentage",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    arrowStyles: {
      top: 20,
      left: 92,
    },
    searchBy: false,
  },
  [headerColumns.TOTAL]: {
    label: headerColumns.TOTAL,
    id: 12,
    canBeSort: true,
    reference: "total",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.TOTAL,
    key: "total",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 138,
    },
  },
  [headerColumns.POTENTIAL_LATE_FEE]: {
    label: headerColumns.POTENTIAL_LATE_FEE,
    id: 13,
    canBeSort: true,
    reference: "potentialLateFee",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: headerColumns.POTENTIAL_LATE_FEE,
    key: "potentialLateFee",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    arrowStyles: {
      top: 20,
      left: 134,
    },
    searchBy: false,
  },
  [headerColumns.CBM]: {
    label: headerColumns.CBM,
    id: 14,
    canBeSort: true,
    reference: "CBM",
    filter: true,
    selected: true,
    styles: {
      width: 110,
      textAlign: "right",
      padding: "9px 16px 9px 32px",
    },
    exportLabel: headerColumns.CBM,
    key: "CBM",
    searchBy: false,
    arrowStyles: {
      left: 98,
    },
  },
  [headerColumns.SHIPPING_TERMS]: {
    label: headerColumns.SHIPPING_TERMS,
    id: 15,
    canBeSort: true,
    reference: "shippingTerms",
    filter: true,
    selected: true,
    styles: {
      width: 130,
      textAlign: "left",
      padding: "0px 10px",
    },
    exportLabel: headerColumns.SHIPPING_TERMS,
    key: "shippingTerms",
    arrowStyles: {
      top: 20,
      left: 113,
    },
    searchBy: "shippingTerms",
  },
  [headerColumns.TOTAL_SHIPMENTS]: {
    label: headerColumns.TOTAL_SHIPMENTS,
    id: 16,
    canBeSort: false,
    reference: "totalShipments",
    filter: true,
    selected: true,
    styles: {
      width: 90,
      textAlign: "left",
      padding: "0px 7px",
    },
    exportLabel: headerColumns.TOTAL_SHIPMENTS,
    key: "totalShipments",
    searchBy: false,
  },
  [headerColumns.VENDOR_INVOICE]: {
    label: headerColumns.VENDOR_INVOICE,
    id: 17,
    canBeSort: true,
    reference: "invoice",
    filter: true,
    selected: true,
    styles: {
      width: 130,
      textAlign: "left",
      padding: "0px 15px",
    },
    exportLabel: headerColumns.VENDOR_INVOICE,
    key: "invoice",
    arrowStyles: {
      top: 20,
      left: 113,
    },
    searchBy: "invoice",
  },
  [headerColumns.UNIQUE_ITEMS]: {
    label: headerColumns.UNIQUE_ITEMS,
    id: 18,
    canBeSort: true,
    reference: "uniqueItems",
    filter: true,
    selected: true,
    styles: {
      width: 80,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: headerColumns.UNIQUE_ITEMS,
    key: "uniqueItems",
    arrowStyles: {
      top: 20,
      left: 66,
    },
    searchBy: false,
  },
  [headerColumns.DISCOUNT]: {
    label: headerColumns.DISCOUNT,
    id: 19,
    canBeSort: true,
    reference: "discount",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "0px 17px",
    },
    exportLabel: headerColumns.DISCOUNT,
    key: "discount",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 138,
    },
  },
  [headerColumns.SUB_TOTAL]: {
    label: headerColumns.SUB_TOTAL,
    id: 20,
    canBeSort: true,
    reference: "subtotal",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.SUB_TOTAL,
    key: "subtotal",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 139,
    },
  },
  [headerColumns.DEPOSIT]: {
    label: headerColumns.DEPOSIT,
    id: 21,
    canBeSort: true,
    reference: "deposit",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.DEPOSIT,
    key: "deposit",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 139,
    },
  },
  [headerColumns.BALANCE]: {
    label: headerColumns.BALANCE,
    id: 22,
    canBeSort: true,
    reference: "balance",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.BALANCE,
    key: "balance",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 139,
    },
  },
  [headerColumns.TOTAL_WEIGHT]: {
    label: headerColumns.TOTAL_WEIGHT,
    id: 23,
    canBeSort: true,
    reference: "totalWeight",
    filter: true,
    selected: true,
    styles: {
      width: 119,
      textAlign: "left",
      padding: "0px 15px 0px 26px",
    },
    arrowStyles: {
      left: 107,
    },
    exportLabel: headerColumns.TOTAL_WEIGHT,
    key: "totalWeight",
    searchBy: false,
  },
  [headerColumns.DOCUMENT_DATE]: {
    label: headerColumns.DOCUMENT_DATE,
    id: 24,
    canBeSort: true,
    reference: "date",
    filter: true,
    selected: true,
    styles: {
      width: 125,
      textAlign: "left",
      padding: "0px 7px",
    },
    exportLabel: headerColumns.DOCUMENT_DATE,
    key: "date",
    arrowStyles: {
      top: 20,
      left: 109,
    },
    searchBy: false,
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 25,
    canBeSort: false,
    reference: "extra",
    filter: false,
    selected: true,
    styles: {
      width: "100%",
      textAlign: "left",
      padding: "9px 16px",
    },
    exportLabel: "PO - Labels",
    key: "poLabels",
  },
  searchBy: false,
};

export const salesOrderHeaderCells = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    styles: {
      width: 40,
      textAlign: "center",
      padding: 9,
    },
    exportLabel: "Star",
    key: "favorite",
    searchBy: false,
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    canBeSort: false,
    filter: false,
    styles: {
      width: 50,
      textAlign: "center",
      padding: 0,
    },
    exportLabel: "Status",
    key: "status",
    searchBy: false,
  },
  [headerColumns.PROGRESS]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    canBeSort: true,
    reference: "progress",
    filter: false,
    styles: {
      width: 35,
      textAlign: "center",
      padding: "9px 16px",
    },
    exportLabel: "Completion",
    key: "progress",
    searchBy: false,
    arrowStyles: {
      left: 12,
    },
  },
  [headerColumns.MENTIONS]: {
    label: "salesOrderMention",
    id: 3,
    canBeSort: true,
    textAlign: "center",
    item: "AVATAR",
    reference: "totalMentions",
    filter: false,
    styles: {
      width: 65,
      textAlign: "center",
      padding: "9px 0px 9px 20px",
    },
    exportLabel: "Mentions",
    key: "mentions",
    searchBy: false,
    arrowStyles: {
      left: 48,
    },
  },
  [headerColumns.SO]: {
    label: "Quote/ \u00A0\u00A0\u00A0\u00A0\u00A0 Sales Order",
    id: 4,
    canBeSort: true,
    reference: "number",
    filter: false,
    selected: true,
    styles: {
      width: 155,
      textAlign: "left",
      paddingInline: "25px 95px",
      paddingBlock: 8,
    },
    arrowStyles: {
      left: 104,
      top: 20,
    },
    exportLabel: "SO Number",
    key: "SONumber",
    searchBy: "number",
  },
  [headerColumns.POS]: {
    label: "PO/ \u00A0\u00A0\u00A0\u00A0\u00A0 Purchase Quote",
    id: 5,
    canBeSort: false,
    filter: true,
    selected: true,
    styles: {
      width: 230,
      textAlign: "left",
      paddingLeft: 25,
      paddingRight: 98,
      paddingBlock: 8,
    },
    exportLabel: "POs",
    key: "pos",
    arrowStyles: {
      marginTop: 5,
    },
    dynamicPadding: ["poDiamonds", "poProgress"],
    searchBy: headerColumns.POS,
  },
  [headerColumns.SHIPMENTS]: {
    label: headerColumns.SHIPMENTS,
    id: 6,
    canBeSort: false,
    filter: true,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      paddingLeft: 25,
      paddingRight: 15,
      paddingBlock: 8,
    },
    exportLabel: "Shipments",
    key: "shipments",
    dynamicPadding: ["shipmentDiamonds", "shipmentProgress"],
    searchBy: headerColumns.SHIPMENTS,
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 7,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      paddingInline: 5,
      paddingBlock: 8,
    },
    exportLabel: "Customer",
    key: "customerName",
    searchBy: "customerName",
    arrowStyles: {
      left: 70,
    },
  },
  [headerColumns.CREATION_DATE]: {
    label: headerColumns.CREATION_DATE,
    id: 8,
    canBeSort: true,
    reference: "creationDate",
    filter: true,
    selected: true,
    styles: {
      width: 123,
      textAlign: "left",
      padding: "0px 10px 0px 21px",
    },
    exportLabel: headerColumns.CREATION_DATE,
    key: "creationDate",
    searchBy: false,
    arrowStyles: {
      left: 106,
    },
  },
  [headerColumns.SHIP_BY_DATE]: {
    label: headerColumns.SHIP_BY_DATE,
    id: 9,
    canBeSort: true,
    reference: "shipDate",
    filter: true,
    selected: true,
    styles: {
      width: 120,
      textAlign: "left",
      padding: "0px 10px 0px 23px",
    },
    exportLabel: headerColumns.SHIP_BY_DATE,
    key: "shipDate",
    searchBy: false,
    arrowStyles: {
      left: 105,
    },
  },
  [headerColumns.DOCUMENT_DATE]: {
    label: headerColumns.DOCUMENT_DATE,
    id: 25,
    canBeSort: true,
    reference: "date",
    filter: true,
    selected: true,
    styles: {
      width: 110,
      textAlign: "left",
      padding: "0px 27px",
    },
    exportLabel: headerColumns.DOCUMENT_DATE,
    key: "date",
    arrowStyles: {
      top: 20,
      left: 96,
    },
    searchBy: false,
  },
  [headerColumns.REQUIRED_DELIVERY]: {
    label: headerColumns.REQUIRED_DELIVERY,
    id: 10,
    canBeSort: true,
    reference: "deliveryDate",
    filter: true,
    selected: true,
    styles: {
      width: 100,
      textAlign: "left",
      padding: "0px 16px 0px 25px",
    },
    exportLabel: headerColumns.REQUIRED_DELIVERY,
    key: "deliveryDate",
    arrowStyles: {
      top: 20,
      left: 85,
    },
    searchBy: false,
  },
  [headerColumns.DISCOUNT_PERCENTAGE]: {
    label: headerColumns.DISCOUNT_PERCENTAGE,
    id: 11,
    canBeSort: true,
    reference: "discountPercentage",
    filter: true,
    selected: true,
    styles: {
      width: 125,
      textAlign: "left",
      padding: "0px 25px 0px 30px",
    },
    exportLabel: headerColumns.DISCOUNT_PERCENTAGE,
    key: "discountPercentage",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    arrowStyles: {
      top: 20,
      left: 106,
    },
    searchBy: false,
  },
  [headerColumns.TOTAL]: {
    label: headerColumns.TOTAL,
    id: 12,
    canBeSort: true,
    reference: "total",
    filter: true,
    selected: true,
    styles: {
      width: 135,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.TOTAL,
    key: "total",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 123,
    },
  },
  [headerColumns.POTENTIAL_LATE_PENALTY]: {
    label: headerColumns.POTENTIAL_LATE_PENALTY,
    id: 13,
    canBeSort: true,
    reference: "potentialLateFee",
    filter: true,
    selected: true,
    styles: {
      width: 135,
      textAlign: "left",
      padding: "0px 14px 0px 31px",
    },
    exportLabel: headerColumns.POTENTIAL_LATE_PENALTY,
    key: "potentialLateFee",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    arrowStyles: {
      top: 20,
      left: 122,
    },
    searchBy: false,
  },
  [headerColumns.CBM]: {
    label: headerColumns.CBM,
    id: 14,
    canBeSort: true,
    reference: "CBM",
    filter: true,
    selected: true,
    styles: {
      width: 110,
      textAlign: "right",
      padding: "9px 16px 9px 32px",
    },
    exportLabel: headerColumns.CBM,
    key: "CBM",
    searchBy: false,
    arrowStyles: {
      left: 98,
    },
  },
  [headerColumns.TOTAL_SHIPMENTS]: {
    label: headerColumns.TOTAL_SHIPMENTS,
    id: 16,
    canBeSort: false,
    reference: "totalShipments",
    filter: true,
    selected: true,
    styles: {
      width: 94,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: headerColumns.TOTAL_SHIPMENTS,
    key: "totalShipments",
    searchBy: false,
  },
  [headerColumns.SALES_INVOICE]: {
    label: headerColumns.CUSTOMER_INVOICE,
    id: 17,
    canBeSort: true,
    reference: "invoice",
    filter: true,
    selected: true,
    styles: {
      width: 115,
      textAlign: "left",
      padding: "0px 18px",
    },
    exportLabel: headerColumns.CUSTOMER_INVOICE,
    key: "invoice",
    arrowStyles: {
      top: 20,
      left: 84,
    },
    searchBy: "invoice",
  },
  [headerColumns.CUSTOMER_PO]: {
    label: headerColumns.CUSTOMER_PO,
    id: 18,
    canBeSort: true,
    reference: "customerPO",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: headerColumns.CUSTOMER_PO,
    key: "customerPO",
    arrowStyles: {
      top: 20,
      left: 104,
    },
    searchBy: "customerPO",
  },
  [headerColumns.SALES_RESP]: {
    label: headerColumns.SALES_RESP,
    id: 19,
    canBeSort: true,
    reference: "salesRepresentative",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: headerColumns.SALES_RESP,
    key: "salesRepresentative",
    arrowStyles: {
      top: 20,
      left: 84,
    },
    searchBy: "salesRepresentative",
  },
  [headerColumns.UNIQUE_ITEMS]: {
    label: headerColumns.UNIQUE_ITEMS,
    id: 20,
    canBeSort: true,
    reference: "uniqueItems",
    filter: true,
    selected: true,
    styles: {
      width: 80,
      textAlign: "left",
      padding: "0px 16px 0px 18px",
    },
    exportLabel: headerColumns.UNIQUE_ITEMS,
    key: "uniqueItems",
    arrowStyles: {
      top: 20,
      left: 66,
    },
    searchBy: false,
  },
  [headerColumns.DISCOUNT]: {
    label: headerColumns.DISCOUNT,
    id: 21,
    canBeSort: true,
    reference: "discount",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.DISCOUNT,
    key: "discount",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 139,
    },
  },
  [headerColumns.SUB_TOTAL]: {
    label: headerColumns.SUB_TOTAL,
    id: 22,
    canBeSort: true,
    reference: "subtotal",
    filter: true,
    selected: true,
    styles: {
      width: 130,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.SUB_TOTAL,
    key: "subtotal",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 119,
    },
  },
  [headerColumns.DEPOSIT]: {
    label: headerColumns.DEPOSIT,
    id: 23,
    canBeSort: true,
    reference: "deposit",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.DEPOSIT,
    key: "deposit",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 138,
    },
  },
  [headerColumns.BALANCE]: {
    label: headerColumns.BALANCE,
    id: 24,
    canBeSort: true,
    reference: "balance",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.BALANCE,
    key: "balance",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    arrowStyles: {
      left: 138,
    },
  },

  [headerColumns.REFERENCE_NUMBER]: {
    label: headerColumns.REFERENCE_NUMBER,
    id: 26,
    canBeSort: true,
    reference: "referenceNumber",
    filter: true,
    selected: true,
    styles: {
      width: 125,
      textAlign: "left",
      padding: "0px 18px",
    },
    exportLabel: headerColumns.REFERENCE_NUMBER,
    key: "referenceNumber",
    arrowStyles: {
      top: 20,
      left: 86,
    },
    searchBy: "referenceNumber",
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 27,
    canBeSort: false,
    reference: "extra",
    filter: false,
    selected: true,
    styles: {
      width: "100%",
      textAlign: "center",
      padding: "9px 16px",
    },
    exportLabel: "Sales Order - Labels",
    key: "salesOrderLabels",
  },
  searchBy: false,
};

export const tasksHeaderCells = {
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    selected: false,
    styles: {
      width: 35,
      textAlign: "center",
      padding: "0px 16px",
    },
    searchBy: false,
  },
  [headerColumns.SO]: {
    label: "Quote/Sales Order",
    id: 1,
    canBeSort: true,
    reference: "SONumber",
    filter: true,
    selected: true,
    styles: {
      width: 155,
      textAlign: "left",
      padding: "0px 27px",
      maxWidth: 155,
    },
    searchBy: "SONumber",
    arrowStyles: {
      left: 147,
    },
  },
  [headerColumns.PO]: {
    label: headerColumns.PO,
    id: 2,
    canBeSort: true,
    reference: "PONumber",
    filter: true,
    selected: true,
    styles: {
      width: 155,
      textAlign: "left",
      padding: "0px 28px",
      maxWidth: 155,
    },
    searchBy: "PONumber",
    arrowStyles: {
      left: 51,
    },
  },
  [headerColumns.TASK]: {
    label: headerColumns.TASK,
    id: 3,
    canBeSort: true,
    reference: "description",
    filter: true,
    selected: true,
    styles: {
      width: "260px",
      textAlign: "left",
      padding: "0px 16px",
    },
    searchBy: "description",
    arrowStyles: {
      left: 51,
    },
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 4,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "0px 16px",
    },
    searchBy: "customerName",
    arrowStyles: {
      left: 82,
    },
  },
  [headerColumns.VENDOR]: {
    label: headerColumns.VENDOR,
    id: 5,
    canBeSort: true,
    reference: "factoryName",
    filter: true,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "0px 16px",
    },
    searchBy: "factoryName",
    arrowStyles: {
      left: 66,
    },
  },
  [headerColumns.ASSIGNED_TO]: {
    label: headerColumns.ASSIGNED_TO,
    id: 6,
    canBeSort: true,
    reference: "assignedToName",
    filter: true,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "0px 16px",
    },
    searchBy: "assignedToName",
    arrowStyles: {
      left: 97,
    },
  },
  [headerColumns.START_DATE]: {
    label: headerColumns.START_DATE,
    id: 7,
    canBeSort: true,
    reference: "startDate",
    filter: true,
    selected: true,
    styles: {
      width: 120,
      textAlign: "left",
      padding: "0px 5px 0px 30px",
    },
    searchBy: false,
    arrowStyles: {
      left: 96,
    },
  },
  [headerColumns.DUE_DATE]: {
    label: headerColumns.DUE_DATE,
    id: 8,
    canBeSort: true,
    reference: "finishDate",
    filter: true,
    selected: true,
    styles: {
      width: 120,
      textAlign: "center",
      padding: "0px 16px",
    },
    searchBy: false,
    arrowStyles: {
      left: 95,
    },
  },
  [headerColumns.ACTION]: {
    label: headerColumns.ACTION,
    id: 9,
    canBeSort: true,
    reference: "complete",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 16px",
    },
    searchBy: false,
    arrowStyles: {
      left: 62,
    },
  },
  [headerColumns.EXTRA]: {
    label: "",
    disabledDraggable: true,
    id: 10,
    canBeSort: false,
    reference: "",
    filter: false,
    selected: false,
    styles: {
      width: "100%",
      textAlign: "left",
      padding: "0px 16px",
    },
    searchBy: false,
  },
};

export const SOPhaseHeadCells = {
  [headerColumns.EXPAND]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    filter: false,
    styles: {
      width: 35,
      padding: "9px",
      zIndex: 3,
    },
  },
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    filter: false,
    styles: {
      width: 35,
      padding: "9px",
      zIndex: 3,
    },
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    filter: false,
    styles: {
      width: 35,
      padding: "9px",
      zIndex: 3,
    },
  },
  [headerColumns.SALES_ORDER]: {
    label: headerColumns.SALES_ORDER,
    id: 3,
    canBeSort: true,
    reference: "number",
    filter: true,
    autoSize: 80,
    styles: {
      width: 120,
      padding: "0px 41px",
      textAlign: "left",
      zIndex: 3,
    },
    arrowStyles: {
      left: 118,
    },
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 4,
    autoSize: 60,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    styles: {
      width: 160,
      padding: "9px 16px",
      zIndex: 3,
    },
    arrowStyles: {
      left: 82,
    },
  },
  [headerColumns.TOTAL]: {
    label: headerColumns.TOTAL,
    id: 5,
    canBeSort: true,
    reference: "total",
    filter: true,
    styles: {
      width: 150,
      padding: "0px 33px 0px 20px",
      textAlign: "right",
      zIndex: 3,
    },
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    arrowStyles: {
      left: 122,
    },
  },
  [headerColumns.SO_SHIP_DATE]: {
    label: headerColumns.SO_SHIP_DATE,
    id: 6,
    canBeSort: true,
    reference: "shipDate",
    filter: true,
    styles: {
      width: 140,
      padding: "0px 36px 0px 10px",
      textAlign: "right",
      zIndex: 3,
    },
    arrowStyles: {
      left: 109,
    },
  },
  [headerColumns.POS]: {
    label: "PO/ \u00A0\u00A0\u00A0\u00A0 Purchase Quote",
    id: 7,
    canBeSort: true,
    reference: "quantityPOs",
    filter: true,
    styles: {
      width: 135,
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
    arrowStyles: {
      top: 20,
      left: 121,
    },
  },
  [headerColumns.SHIPMENTS]: {
    label: headerColumns.SHIPMENTS,
    id: 8,
    canBeSort: true,
    reference: "quantityShipments",
    filter: true,
    styles: {
      width: 100,
      padding: "0px 17px 0px 5px",
      textAlign: "right",
      zIndex: 3,
    },
    arrowStyles: {
      left: 88,
    },
  },
  [headerColumns.PROPOSAL_QUOTE]: {
    label: "",
    disabledDraggable: true,
    id: 9,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
  },
  [headerColumns.PRE_PRODUCTION]: {
    label: "",
    disabledDraggable: true,
    id: 10,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
  },
  [headerColumns.PRODUCTION]: {
    label: "",
    disabledDraggable: true,
    id: 11,
    filter: false,
    styles: {
      width: 55,
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
  },
  [headerColumns.BOOKING_TRANSIT]: {
    label: "",
    disabledDraggable: true,
    id: 12,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
  },
  [headerColumns.PAYMENT_BILLING]: {
    label: "",
    disabledDraggable: true,
    id: 13,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 14,
    filter: false,
    styles: {
      width: "100%",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
  },
};

export const POPhaseHeaderCells = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    styles: {
      width: 35,
      padding: "9px 16px",
    },
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    canBeSort: false,
    filter: false,
    styles: {
      width: 35,
      padding: "9px 16px",
    },
  },
  [headerColumns.PO]: {
    label: headerColumns.PO,
    id: 2,
    canBeSort: true,
    reference: "number",
    filter: false,
    selected: true,
    autoSize: 60,
    styles: {
      width: 120,
      textAlign: "left",
      paddingLeft: 20,
      padding: "0px 0px 0px 40px",
    },
    arrowStyles: {
      left: 63,
    },
  },
  [headerColumns.VENDOR]: {
    label: headerColumns.VENDOR,
    id: 3,
    canBeSort: true,
    reference: "factoryName",
    filter: true,
    selected: true,
    autoSize: 60,
    styles: {
      width: 100,
      padding: "9px 16px",
    },
    arrowStyles: {
      left: 66,
    },
  },
  [headerColumns.PO_VALUE]: {
    label: headerColumns.PO_VALUE,
    id: 4,
    canBeSort: true,
    reference: "total",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px 10px 32px",
    },
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    arrowStyles: {
      left: 139,
    },
  },
  [headerColumns.ORDER_READY_DATE]: {
    label: headerColumns.ORDER_READY_DATE,
    id: 5,
    canBeSort: true,
    reference: "shipDate",
    filter: true,
    selected: true,
    styles: {
      width: 140,
      padding: "0px 23px",
    },
    arrowStyles: {
      top: 20,
      left: 105,
    },
  },

  [headerColumns.PROPOSAL_QUOTE]: {
    label: headerColumns.PROPOSAL_QUOTE,
    id: 6,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
  },
  [headerColumns.PRE_PRODUCTION]: {
    label: headerColumns.PRE_PRODUCTION,
    id: 7,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
  },
  [headerColumns.PRODUCTION]: {
    label: headerColumns.PRODUCTION,
    id: 8,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
  },
  [headerColumns.BOOKING_TRANSIT]: {
    label: headerColumns.BOOKING_TRANSIT,
    id: 9,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
  },
  [headerColumns.PAYMENT_BILLING]: {
    label: headerColumns.PAYMENT_BILLING,
    id: 10,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
  },
  [headerColumns.EXTRA]: {
    label: "",
    disabledDraggable: true,
    id: 11,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
  },
};

export const groupBySOHeadCellsPOs = {
  [headerColumns.EXPAND]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    styles: {
      zIndex: 2,
      width: 30,
      height: 40,
      padding: "7px 4px 0px 4px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    styles: {
      zIndex: 2,
      width: 35,
      height: 40,
      padding: "7px 10px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    styles: {
      zIndex: 2,
      width: 35,
      height: 40,
      padding: "7px 10px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PO]: {
    label: headerColumns.PO,
    id: 3,
    canBeSort: true,
    reference: "number",
    autoSize: 80,
    styles: {
      width: 130,
      zIndex: 2,
      textAlign: "left",
      height: 40,
      padding: "7px 25px 0px 43px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 22,
      left: 65,
    },
  },
  [headerColumns.VENDOR]: {
    label: headerColumns.VENDOR,
    id: 4,
    canBeSort: true,
    reference: "factoryName",
    autoSize: 60,
    styles: {
      width: 180,
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 19px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 22,
      left: 68,
    },
  },
  [headerColumns.PO_VALUE]: {
    label: headerColumns.PO_VALUE,
    id: 5,
    canBeSort: true,
    reference: "total",
    styles: {
      width: 150,
      zIndex: 2,
      textAlign: "right",
      height: 40,
      padding: "7px 28px 0px 15px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    arrowStyles: {
      top: 22,
      left: 126,
    },
  },
  [headerColumns.ORDER_READY_DATE]: {
    label: headerColumns.ORDER_READY_DATE,
    id: 6,
    canBeSort: true,
    reference: "shipDate",
    styles: {
      width: 140,
      zIndex: 2,
      height: 40,
      padding: "7px 5px 0px 5px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 22,
      left: 119,
    },
  },
  [headerColumns.POS]: {
    label: " ",
    disabledDraggable: true,
    id: 8,
    styles: {
      width: 140,
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.SHIPMENTS]: {
    label: " ",
    disabledDraggable: true,
    id: 9,
    styles: {
      width: 95,
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PROPOSAL_QUOTE]: {
    label: headerColumns.PROPOSAL_QUOTE,
    id: 10,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PRE_PRODUCTION]: {
    label: headerColumns.PRE_PRODUCTION,
    id: 11,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PRODUCTION]: {
    label: headerColumns.PRODUCTION,
    id: 12,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.BOOKING_TRANSIT]: {
    label: headerColumns.BOOKING_TRANSIT,
    id: 13,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PAYMENT_BILLING]: {
    label: headerColumns.PAYMENT_BILLING,
    id: 14,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 15,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
};

export const groupBySOHeadCellsShipments = {
  [headerColumns.EXPAND]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    styles: {
      zIndex: 2,
      width: 30,
      height: 31,
      padding: "0px 4px 0px 4px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    styles: {
      zIndex: 2,
      width: 35,
      height: 31,
      padding: "0px 10px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    styles: {
      zIndex: 2,
      width: 35,
      height: 31,
      padding: "0px 10px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.SHIPMENT]: {
    label: headerColumns.SHIPMENT,
    id: 3,
    canBeSort: true,
    reference: "number",
    autoSize: 80,
    styles: {
      width: 130,
      zIndex: 2,
      textAlign: "left",
      height: 31,
      padding: "0px 16px 0px 43px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 20,
      left: 107,
    },
  },
  [headerColumns.FINAL_DESTINATION]: {
    label: headerColumns.FINAL_DESTINATION,
    id: 4,
    canBeSort: true,
    reference: "finalDestination",
    autoSize: 60,
    styles: {
      width: 160,
      zIndex: 2,
      height: 31,
      padding: "4px 16px 4px 18px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 20,
      left: 126,
    },
  },
  [headerColumns.SHIPMENT_VALUE]: {
    label: headerColumns.SHIPMENT_VALUE,
    id: 5,
    canBeSort: true,
    reference: "total",
    styles: {
      width: 150,
      zIndex: 2,
      textAlign: "left",
      height: 31,
      padding: "0px 16px 0px 25px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    [generalPermissions.SHIPMENT_AMOUNT]: true,
    arrowStyles: {
      top: 20,
      left: 127,
    },
  },
  [headerColumns.SHIP_DATE]: {
    label: headerColumns.SHIP_DATE,
    id: 6,
    canBeSort: true,
    reference: "shipDate",
    styles: {
      width: 140,
      zIndex: 2,
      height: 31,
      padding: "0px 32px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      textAlign: "right",
      background: "transparent",
    },
    arrowStyles: {
      top: 20,
      left: 111,
    },
  },
  [headerColumns.DELIVERY_DATE]: {
    label: headerColumns.DELIVERY_DATE,
    canBeSort: true,
    reference: "deliveryDate",
    id: 7,
    styles: {
      width: 140,
      zIndex: 2,
      height: 31,
      padding: "0px 32px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      textAlign: "right",
      background: "transparent",
    },
    arrowStyles: {
      top: 20,
      left: 111,
    },
  },
  [headerColumns.POS]: {
    label: " ",
    disabledDraggable: true,
    id: 8,
    styles: {
      width: 95,
      zIndex: 2,
      height: 40,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PROPOSAL_QUOTE]: {
    label: headerColumns.PROPOSAL_QUOTE,
    id: 9,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PRE_PRODUCTION]: {
    label: headerColumns.PRE_PRODUCTION,
    id: 10,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PRODUCTION]: {
    label: headerColumns.PRODUCTION,
    id: 11,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.BOOKING_TRANSIT]: {
    label: headerColumns.BOOKING_TRANSIT,
    id: 12,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PAYMENT_BILLING]: {
    label: headerColumns.PAYMENT_BILLING,
    id: 13,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 14,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
};

export const TagsHeadCells = [
  {
    label: "Label",
    enabled: true,
    id: 0,
    canBeSort: true,
    reference: "name",
    styles: {
      width: 120,
      color: "rgb(37, 72, 109)",
      padding: "9px 16px",
    },
  },
  {
    label: "Created By",
    enabled: true,
    id: 1,
    canBeSort: true,
    reference: "createdByName",
    styles: {
      width: 120,
      color: "rgb(37, 72, 109)",
      padding: "9px 16px",
    },
  },
  {
    label: "Date Created",
    enabled: true,
    id: 2,
    canBeSort: true,
    reference: "createdDate",
    styles: {
      width: 120,
      color: "rgb(37, 72, 109)",
      padding: "9px 16px",
    },
  },
  {
    label: "Updated By",
    enabled: true,
    id: 3,
    canBeSort: true,
    reference: "updatedByName",
    styles: {
      width: 120,
      color: "rgb(37, 72, 109)",
      padding: "9px 16px",
    },
  },
  {
    label: "Date Updated",
    enabled: true,
    id: 4,
    canBeSort: true,
    reference: "updatedDate",
    styles: {
      width: 120,
      color: "rgb(37, 72, 109)",
      padding: "9px 16px",
    },
  },
  {
    label: " ",
    disabledDraggable: true,
    enabled: true,
    id: 5,
    canBeSort: false,
    styles: {
      width: 80,
      color: "rgb(37, 72, 109)",
      padding: "9px 16px",
    },
  },
];

export const shipmentsHeaderCells = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    styles: {
      width: 40,
      textAlign: "center",
      padding: 9,
    },
    exportLabel: "Star",
    key: "favorite",
    searchBy: false,
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    canBeSort: false,
    filter: false,
    styles: {
      width: 50,
      textAlign: "center",
      padding: 0,
    },
    exportLabel: "Status",
    key: "status",
    searchBy: false,
  },
  [headerColumns.PROGRESS]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    canBeSort: true,
    reference: "progress",
    filter: false,
    styles: {
      width: 35,
      textAlign: "center",
      padding: "9px 16px",
    },
    exportLabel: "Completion",
    key: "progress",
    searchBy: false,
    arrowStyles: {
      left: 12,
    },
  },
  [headerColumns.MENTIONS]: {
    label: "userMentionCount",
    id: 3,
    canBeSort: true,
    reference: "totalMentions",
    item: "AVATAR",
    filter: false,
    styles: {
      width: 65,
      textAlign: "center",
      padding: "9px 0px 9px 20px",
    },
    exportLabel: "Mentions",
    key: "mentions",
    searchBy: false,
    arrowStyles: {
      left: 49,
    },
  },
  [headerColumns.SHIPMENT]: {
    label: headerColumns.SHIPMENT,
    id: 4,
    canBeSort: true,
    reference: "number",
    filter: false,
    selected: true,
    styles: {
      width: 140,
      textAlign: "left",
      padding: "0px 28px",
    },
    exportLabel: "Shipment",
    key: "number",
    searchBy: "number",
    arrowStyles: {
      left: 94,
    },
  },
  [headerColumns.SALES_ORDER]: {
    label: "Quote/Sales Order",
    id: 5,
    canBeSort: false,
    reference: "salesOrder",
    filter: true,
    selected: true,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "0px 24px",
    },
    exportLabel: "Sales Order",
    key: "salesOrder",
    searchBy: "salesOrder",
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 6,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    selected: true,
    autoSize: 40,
    styles: {
      width: 110,
      textAlign: "left",
      padding: "0px 5px",
    },
    exportLabel: "Customer",
    key: "customerName",
    searchBy: "customerName",
    arrowStyles: {
      left: 71,
    },
  },
  [headerColumns.PO]: {
    label: `${headerColumns.PO} - ${headerColumns.ORDER_READY_DATE} - ${headerColumns.VENDOR}`,
    id: 7,
    canBeSort: false,
    reference: "PO",
    filter: true,
    selected: true,
    multipleLables: true,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "0px 26px",
      letterSpacing: "0.2px",
    },
    exportLabel: "PO",
    key: "purchaseOrder",
    searchBy: headerColumns.PO_ORDER_READY_DATE_VENDOR,
  },
  [headerColumns.CREATION_DATE]: {
    label: headerColumns.CREATION_DATE,
    id: 8,
    canBeSort: true,
    reference: "creationDate",
    filter: true,
    selected: true,
    styles: {
      width: 120,
      textAlign: "center",
      padding: "9px 16px",
    },
    exportLabel: headerColumns.CREATION_DATE,
    key: "creationDate",
    searchBy: false,
    arrowStyles: {
      left: 105,
    },
  },
  [headerColumns.SHIP_DATE]: {
    label: headerColumns.SHIP_DATE,
    id: 9,
    canBeSort: true,
    reference: "shipDate",
    filter: true,
    selected: true,
    styles: {
      width: 120,
      textAlign: "right",
      padding: "0px 22px",
    },
    exportLabel: "Ship Date",
    key: "shipDate",
    searchBy: false,
    arrowStyles: {
      left: 102,
    },
  },
  [headerColumns.DELIVERY_DATE]: {
    label: headerColumns.DELIVERY_DATE,
    id: 10,
    canBeSort: true,
    reference: "deliveryDate",
    filter: true,
    selected: true,
    styles: {
      width: 112,
      textAlign: "left",
      padding: "0px 15px 0px 8px",
    },
    exportLabel: "Delivery Date",
    key: "deliveryDate",
    arrowStyles: {
      top: 20,
      left: 95,
    },
    searchBy: false,
  },
  [headerColumns.CBM]: {
    label: headerColumns.CBM,
    id: 11,
    canBeSort: true,
    reference: "CBM",
    filter: true,
    selected: true,
    styles: {
      width: 100,
      textAlign: "right",
      padding: "0px 17px",
    },
    exportLabel: "CBM",
    key: "CBM",
    searchBy: false,
    arrowStyles: {
      left: 88,
    },
  },
  [headerColumns.BOOKING]: {
    label: headerColumns.BOOKING,
    id: 12,
    canBeSort: true,
    reference: "booking",
    filter: true,
    selected: true,
    styles: {
      width: 191,
      textAlign: "left",
      padding: "9px 16px",
    },
    exportLabel: "Booking",
    key: "booking",
    searchBy: "booking",
    arrowStyles: {
      left: 72,
    },
  },
  [headerColumns.BOL]: {
    label: headerColumns.BOL,
    id: 13,
    canBeSort: true,
    reference: "billOfLading",
    filter: true,
    selected: true,
    styles: {
      width: 191,
      textAlign: "left",
      padding: "0px 17px",
    },
    exportLabel: "BOL",
    key: "billOfLading",
    searchBy: "billOfLading",
    arrowStyles: {
      left: 49,
    },
  },
  [headerColumns.CONTAINER]: {
    label: headerColumns.CONTAINER,
    id: 14,
    canBeSort: true,
    reference: "container",
    filter: true,
    selected: true,
    styles: {
      width: 135,
      textAlign: "left",
      padding: "9px 16px",
    },
    exportLabel: "Container",
    key: "container",
    searchBy: "container",
    arrowStyles: {
      left: 82,
    },
  },
  [headerColumns.SHIPPING_TERMS]: {
    label: headerColumns.SHIPPING_TERMS,
    id: 15,
    canBeSort: true,
    reference: "shippingTerms",
    filter: true,
    selected: true,
    styles: {
      width: 130,
      textAlign: "left",
      padding: "0px 15px",
    },
    exportLabel: "Shipping Terms",
    key: "shippingTerms",
    arrowStyles: {
      top: 20,
      left: 117,
    },
    searchBy: "shippingTerms",
  },
  [headerColumns.TOTAL_VALUE]: {
    label: headerColumns.TOTAL_VALUE,
    id: 16,
    canBeSort: true,
    reference: "total",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "0px 17px 0px 0px",
    },
    exportLabel: "Total Value",
    key: "total",
    arrowStyles: {
      left: 137,
    },
    [generalPermissions.SHIPMENT_AMOUNT]: true,
    searchBy: false,
  },
  [headerColumns.POTENTIAL_LATE_FEE]: {
    label: headerColumns.POTENTIAL_LATE_FEE,
    id: 17,
    canBeSort: true,
    reference: "potentialLateFee",
    filter: true,
    selected: true,
    styles: {
      width: 160,
      textAlign: "left",
      padding: "0px 17px 0px 29px",
    },
    exportLabel: "Potential Late Fee",
    key: "potentialLateFee",
    [generalPermissions.SHIPMENT_AMOUNT]: true,
    arrowStyles: {
      top: 20,
      left: 147,
    },
    searchBy: false,
  },
  [headerColumns.CUSTOMER_PO]: {
    label: headerColumns.CUSTOMER_PO,
    id: 18,
    canBeSort: true,
    reference: "customerPO",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 17px",
    },
    exportLabel: "Customer PO",
    key: "customerPO",
    arrowStyles: {
      top: 20,
      left: 105,
    },
    searchBy: "customerPO",
  },
  [headerColumns.LOADING_PORT]: {
    label: headerColumns.LOADING_PORT,
    id: 19,
    canBeSort: true,
    reference: "loadingPort",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 15px",
    },
    exportLabel: "Loading Port",
    key: "loadingPort",
    arrowStyles: {
      top: 20,
      left: 101,
    },
    searchBy: "loadingPort",
  },
  [headerColumns.UNLOADING_PORT]: {
    label: headerColumns.UNLOADING_PORT,
    id: 20,
    canBeSort: true,
    reference: "unloadingPort",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 17px",
    },
    exportLabel: "Unloading Port",
    key: "unloadingPort",
    arrowStyles: {
      top: 20,
      left: 115,
    },
    searchBy: "unloadingPort",
  },
  [headerColumns.FINAL_DESTINATION]: {
    label: headerColumns.FINAL_DESTINATION,
    id: 21,
    canBeSort: true,
    reference: "finalDestination",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 17px",
    },
    exportLabel: "Final Destination",
    key: "finalDestination",
    arrowStyles: {
      top: 20,
      left: 127,
    },
    searchBy: "finalDestination",
  },
  [headerColumns.UNIQUE_ITEMS]: {
    label: headerColumns.UNIQUE_ITEMS,
    id: 22,
    canBeSort: true,
    reference: "uniqueItems",
    filter: true,
    selected: true,
    styles: {
      width: 80,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: "Unique items",
    key: "uniqueItems",
    arrowStyles: {
      top: 20,
      left: 64,
    },
    searchBy: false,
  },
  [headerColumns.FREIGHT_FORWARDER]: {
    label: headerColumns.FREIGHT_FORWARDER,
    id: 23,
    canBeSort: true,
    reference: "freightForwarder",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: "Freight Forwarder",
    key: "freightForwarder",
    searchBy: "freightForwarder",
    arrowStyles: {
      left: 131,
    },
  },
  [headerColumns.SALES_INVOICE]: {
    label: headerColumns.CUSTOMER_INVOICE,
    id: 24,
    canBeSort: true,
    reference: "invoice",
    filter: true,
    selected: true,
    styles: {
      width: 135,
      textAlign: "left",
      padding: "0px 20px 0px 16px",
    },
    exportLabel: "Invoice",
    key: "invoice",
    arrowStyles: {
      top: 20,
      left: 83,
    },
    searchBy: "invoice",
  },
  [headerColumns.TOTAL_WEIGHT]: {
    label: headerColumns.TOTAL_WEIGHT,
    id: 25,
    canBeSort: true,
    reference: "totalWeight",
    filter: true,
    selected: true,
    styles: {
      width: 130,
      textAlign: "right",
      padding: "0px 18px 0px 15px",
    },
    exportLabel: "Total Weight",
    key: "totalWeight",
    searchBy: false,
    arrowStyles: {
      left: 116,
    },
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 26,
    canBeSort: false,
    reference: "extra",
    filter: false,
    selected: true,
    styles: {
      width: "100%",
      textAlign: "center",
      padding: "9px 16px",
    },
    exportLabel: "Shipment - Labels",
    key: "shipmentLabels",
    searchBy: false,
  },
};

export const ExpensesHeadCells = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    filter: false,
    styles: {
      width: 35,
      textAlign: "left",
      padding: "9px 7px",
    },
  },
  [headerColumns.DOCUMENT_DATE]: {
    label: headerColumns.DOCUMENT_DATE,
    id: 1,
    canBeSort: true,
    filter: false,
    styles: {
      width: 95,
      textAlign: "left",
      padding: "0px 10px 0px 18px",
    },
    exportLabel: "Document date",
    key: "documentDate",
    reference: "date",
    arrowStyles: {
      top: 20,
      left: 83,
    },
  },
  [headerColumns.PAID_TO]: {
    label: headerColumns.SUPPLIER,
    id: 2,
    canBeSort: true,
    filter: false,
    styles: {
      width: 180,
      textAlign: "left",
      padding: "0px 10px",
    },
    exportLabel: "Supplier",
    key: "paidTo",
    reference: "paidTo",
    arrowStyles: {
      left: 66,
    },
  },
  [headerColumns.ITEM_TYPE]: {
    label: headerColumns.ITEM_TYPE,
    id: 3,
    canBeSort: true,
    filter: true,
    styles: {
      width: 160,
      textAlign: "left",
      padding: "9px 12px",
    },
    exportLabel: "Item Type",
    key: "itemType",
    reference: "type",
    arrowStyles: {
      left: 77,
    },
  },
  [headerColumns.ORDER_NUMBER]: {
    label: headerColumns.ORDER_NUMBER,
    id: 4,
    canBeSort: true,
    filter: true,
    styles: {
      width: 110,
      textAlign: "left",
      padding: "9px 12px",
    },
    exportLabel: "Number",
    key: "orderNumber",
    reference: "orderNumber",
    arrowStyles: {
      left: 66,
    },
  },
  [headerColumns.GL_ACCOUNT]: {
    label: headerColumns.GL_ACCOUNT,
    id: 5,
    canBeSort: true,
    filter: true,
    styles: {
      width: 170,
      textAlign: "left",
      padding: "0px 11px",
    },
    exportLabel: "G/L Account",
    key: "GLAccountName",
    reference: "currentGLAccountName",
    arrowStyles: {
      left: 93,
    },
  },
  [headerColumns.DESCRIPTION]: {
    label: headerColumns.DESCRIPTION,
    id: 6,
    canBeSort: true,
    filter: true,
    styles: {
      width: 170,
      textAlign: "left",
      padding: "0px 11px",
    },
    exportLabel: "Description",
    key: "descriptionOfCharges",
    reference: "currentDescription",
    arrowStyles: {
      left: 87,
    },
  },
  [headerColumns.NOTES]: {
    label: headerColumns.NOTES,
    id: 7,
    canBeSort: true,
    filter: true,
    styles: {
      width: 150,
      textAlign: "left",
      padding: "0px 11px",
    },
    exportLabel: "Notes",
    key: "notes",
    reference: "currentNotes",
    arrowStyles: {
      left: 53,
    },
  },
  [headerColumns.FORECAST]: {
    label: headerColumns.FORECAST,
    id: 8,
    canBeSort: false,
    filter: true,
    styles: {
      width: 160,
      textAlign: "right",
      padding: "0px 18px",
    },
    exportLabel: "Forecast",
    key: "forecast",
    reference: "forecast",
  },
  [headerColumns.ACTUAL]: {
    label: headerColumns.ACTUAL,
    id: 9,
    canBeSort: false,
    filter: true,
    styles: {
      width: 160,
      textAlign: "right",
      padding: "0px 18px",
    },
    exportLabel: "Actual",
    key: "actual",
    reference: "actual",
  },
  [headerColumns.DELTA]: {
    label: headerColumns.DELTA,
    id: 10,
    canBeSort: false,
    filter: true,
    styles: {
      width: 110,
      textAlign: "right",
      padding: "0px 18px",
    },
    exportLabel: "Delta",
    key: "delta",
    reference: "delta",
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 11,
    canBeSort: true,
    filter: true,
    styles: {
      width: 110,
      textAlign: "left",
      padding: "0px 15px",
    },
    exportLabel: "Customer",
    key: "customer",
    reference: "currentCustomerName",
    arrowStyles: {
      left: 79,
    },
  },
  [headerColumns.CUSTOMER_PO]: {
    label: headerColumns.CUSTOMER_PO,
    id: 12,
    canBeSort: true,
    filter: true,
    styles: {
      width: 110,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: "Customer PO",
    key: "customerPO",
    reference: "currentCustomerPO",
    arrowStyles: {
      top: 20,
      left: 82,
    },
  },
  [headerColumns.REFERENCE_NUMBER]: {
    label: "Reference No.",
    id: 13,
    canBeSort: true,
    filter: true,
    styles: {
      width: 120,
      textAlign: "left",
      padding: "0px 21px",
    },
    exportLabel: "Reference number",
    key: "referenceNumber",
    reference: "currentReferenceNumber",
    arrowStyles: {
      top: 20,
      left: 87,
    },
  },
  [headerColumns.CREATION_DATE]: {
    label: headerColumns.CREATION_DATE,
    id: 14,
    canBeSort: true,
    filter: true,
    styles: {
      width: 100,
      textAlign: "left",
      padding: "0px 10px 0px 23px",
    },
    exportLabel: "Creation date",
    key: "creationDate",
    reference: "creationDate",
    arrowStyles: {
      top: 20,
      left: 75,
    },
  },
  [headerColumns.EXPORTED_DATE]: {
    label: headerColumns.EXPORTED_DATE,
    id: 15,
    canBeSort: true,
    filter: true,
    styles: {
      width: 100,
      textAlign: "left",
      padding: "0px 16px",
    },
    exportLabel: "Exported date",
    key: "exportedDate",
    reference: "exportedDate",
    unmovable: false,
    arrowStyles: {
      top: 20,
      left: 75,
    },
  },
  [headerColumns.ED_TOOL]: {
    label: "",
    disabledDraggable: true,
    id: 16,
    canBeSort: false,
    filter: false,
    styles: {
      width: 110,
      textAlign: "center",
      padding: "9px 12px",
    },
    exportLabel: "",
    key: "edTool",
  },
};
