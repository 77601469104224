import React from "react";
import excel from "../../assets/flag-icons/excel.svg";
import pdf from "../../assets/flag-icons/pdf.svg";
import word from "../../assets/flag-icons/word.svg";
import defaultFile from "../../assets/flag-icons/defaultFile.png";
import filePlaceholder from "../../assets/flag-icons/file-placeholder.png";
import folderPlaceholder from "../../assets/flag-icons/folder-placeholder.svg";
import { TYPE_OF_FILE } from "../../helpers/constants";

function TypeFileSelector({
  className,
  version,
  type,
  mainFile,
  parentId,
  placeholder,
}) {
  const getFileTypeIcon = () => {
    let typeFile = "";
    switch (type) {
      case "odt":
      case "doc":
      case "docx":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconExcel" : "imgListItemSmallIconExcel"
            }
            src={word}
            alt="word"
          />
        );
        break;
      case "xls":
      case "xlsx":
      case "ods":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconExcel" : "imgListItemSmallIconExcel"
            }
            src={excel}
            alt="excel"
          />
        );
        break;
      case "pdf":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPdf" : "imgListItemSmallIconExcel"
            }
            src={pdf}
            alt="pdf"
          />
        );
        break;
      case "file":
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPlaceholder" : "imgListItemSmallIcon"
            }
            src={filePlaceholder}
            alt="file-placeholder"
          />
        );
        break;
      case TYPE_OF_FILE.FOLDER:
        typeFile = (
          <img
            className={
              mainFile ? "imgListItemIconPlaceholder" : "imgListItemSmallIcon"
            }
            src={folderPlaceholder}
            alt="folder-placeholder"
          />
        );
        break;
      default:
        typeFile = typeFile = (
          <img
            className={mainFile ? "imgListItemIcon" : "imgListItemSmallIcon"}
            src={defaultFile}
            alt="df"
          />
        );
        break;
    }
    return typeFile;
  };

  return (
    <div>
      {mainFile ? (
        !placeholder &&
        version > 1 && <label className={className}> V{version} </label>
      ) : (
        <label className={className}> V{version} </label>
      )}
      {getFileTypeIcon()}
    </div>
  );
}

export default TypeFileSelector;
