import React, { useState } from "react";
import {
  getTagsLabel,
  getTheDate,
  sortObjectsBy,
  isTradeDashEmployee,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import FilterTablePO from "./FilterTablePO";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { TableCell } from "@mui/material";
import numeral from "numeral";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import ProgressStage from "./ProgressStage";
import { NavLink } from "react-router-dom";
import TagsModal from "../../components/Modal/TagsModal";
import {
  BACKDROP_TYPE,
  generalPermissions,
  GENERAL_PERMISSION_VALUE,
  headerColumns,
  notAllowedToSee,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import { dbTables } from "../../api/types/dbTables";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../actions/DataActions";
import { useStorage } from "../../hooks";
import { checkTagsInItem, tagsByType } from "../../hooks/tags";
import { darkBlue } from "../../helpers/variableColors";
import { getTagStatus } from "../../helpers/salesOrder";
import FilterTableShipment from "./FilterTableShipment";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { colors } from "../../assets/jss/variables";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { cx } from "@emotion/css";
import {
  getDiamondStatus,
  getDisclouserArrow,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";

function GroupBySalesOrder({
  item = {},
  favorites = [],
  user,
  onUpdatePhasesWidth,
  headerStages = {},
  handleRemoveChargedSPTaskAndPOs,
  tags,
  onUpdateItem,
  headers,
  isThereProposalQuoteColumn,
  filters,
  onOpenAttentionModal,
  favoritesDB = [],
  setInternalLoading = () => {},
}) {
  const mismatchTags = tagsByType(tags, TYPE_OF_TAGS.MISMATCH_TAG);
  const filterTag = filters.tag || [];
  const [expanded, setExpanded] = useState(false);
  const [SOTaskByPhases, setSOTaskByPhases] = useState({});
  const [POWithTaskByPhases, setPOWithTaskByPhases] = useState([]);
  const [shipmentWithTaskByPhases, setShipmentWithTaskByPhases] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [, setBackdropStorage] = useStorage("backdrop");
  const [, setTableItemStorage] = useStorage("tableItems");
  const handleBackdropStorage = (type) => {
    setBackdropStorage("lastBackdrop", {
      type: type,
      isOpen: true,
      id: type,
      infoCard: true,
    });
  };

  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };

  const [purchaseOrderTableFilter, setPurchaseOrderTableFilter] = useState({
    sortedColumn: "number",
    orderBy: "ASC",
  });
  const [shipmentTableFilter, setShipmentTableFilter] = useState({
    sortedColumn: "number",
    orderBy: "ASC",
  });
  const isAllowed = useIsAllowedFunction();
  // const classes = useOBPTableRowSOStyles();

  async function handleChargeSOTasksAndPOs(companyId, salesOrderId) {
    try {
      const functions = getFunctions();
      const callableEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview2",
          params: "/orderbyphasetasks",
          env: globalEnvironment,
        })
      );
      const result = await callableEndpoint({
        companyId,
        salesOrderId,
        userId: user.id,
      });

      const { SOTaskByPhases, purchaseOrders, shipments } = result.data;
      onUpdatePhasesWidth(SOTaskByPhases, purchaseOrders, item.id);
      setSOTaskByPhases(SOTaskByPhases);
      setPOWithTaskByPhases(
        purchaseOrders.sort(
          sortObjectsBy(
            purchaseOrderTableFilter.sortedColumn,
            purchaseOrderTableFilter.orderBy === "ASC" ? true : false
          )
        )
      );
      setShipmentWithTaskByPhases(
        shipments.sort(
          sortObjectsBy(
            shipmentTableFilter.sortedColumn,
            shipmentTableFilter.orderBy === "ASC" ? true : false
          )
        )
      );
      setLoading(false);
      setInternalLoading(false);
      setExpanded(!expanded);
    } catch (error) {
      console.log("Error ->", error);
      setLoading(false);
      setInternalLoading(false);
      setExpanded(!expanded);
    }
  }

  function handleUpdatePOItem(item, tagReference) {
    let POWithTaskByPhasesCpy = [...POWithTaskByPhases];
    POWithTaskByPhasesCpy = POWithTaskByPhasesCpy.map((po) => {
      if (item.id === po.id) {
        return {
          ...item,
        };
      }
      return po;
    });
    setPOWithTaskByPhases(POWithTaskByPhasesCpy);
  }

  function handleUpdateShipmentItem(item, tagReference) {
    let shipmentWithTaskByPhasesCpy = [...shipmentWithTaskByPhases];
    shipmentWithTaskByPhasesCpy = shipmentWithTaskByPhasesCpy.map(
      (shipment) => {
        if (item.id === shipment.id) {
          return {
            ...item,
          };
        }
        return shipment;
      }
    );
    setShipmentWithTaskByPhases(shipmentWithTaskByPhasesCpy);
  }

  const openSalesOrderRow = () => {
    if (expanded) {
      handleRemoveChargedSPTaskAndPOs(item.id);
      setExpanded(!expanded);
    } else {
      setLoading(true);
      setInternalLoading(true);
      handleChargeSOTasksAndPOs(item.companyId, item.id);
    }
  };
  function OBFGroupBySOTableRowSelector({ column, enabled, index }) {
    let hasMismatchTag;
    const purchaseOrders = item.purchaseOrders || [];
    switch (column) {
      case headerColumns.EXPAND:
        return (
          enabled && (
            <TableCell
              key={index}
              className="expandAndCollapse"
              style={{ overflow: "unset" }}
            >
              {getDisclouserArrow({
                handleChangeSetup: openSalesOrderRow,
                currentSetup: expanded,
                type: "orderGroup",
                min: 0,
                items: purchaseOrders.filter(
                  (purchaseOrder) =>
                    isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS) ||
                    isAllowed(purchaseOrder.factoryId)
                ),
              })}
            </TableCell>
          )
        );

      case headerColumns.FAVORITE:
        return (
          enabled && (
            <TableCell
              key={index}
              className="favorite"
              style={{ overflow: "unset" }}
            >
              {getStarFavorite({
                favorites,
                itemId: item.id,
                addFavorite: () =>
                  addFavorite({
                    favoritesDB,
                    id: item.id,
                    user,
                  }),
                removeFavorite: () =>
                  removeFavorite({ favoritesDB, id: item.id }),
              })}
            </TableCell>
          )
        );

      case headerColumns.DIAMOND:
        return (
          enabled && (
            <TableCell
              key={index}
              className="diamond"
              style={{ overflow: "unset" }}
            >
              {getDiamondStatus({
                diamondStatus: item.diamondStatus,
                itemStatus: item.status,
              })}
            </TableCell>
          )
        );

      case headerColumns.SALES_ORDER:
        const [firstPO = {}] = item.purchaseOrders || [];
        hasMismatchTag = checkTagsInItem(item, mismatchTags, filters.tag);
        const salesOrderTag = item.tags || [];
        return (
          enabled && (
            <TableCell key={index} className="number">
              <div className={"number-table-container"}>
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={item.id}
                    label={getTagsLabel(tags, salesOrderTag).tagsString}
                    tags={getTagsLabel(tags, salesOrderTag).tagsArray}
                    allTags={tags}
                    item={item}
                    currentUser={user}
                    tagReference={dbTables.SALES_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: salesOrderTag,
                      currentTags: filterTag,
                    })}
                  />
                )}
                <NavLink
                  to={getDashboardSearchPath({
                    salesOrderId: item.id,
                    purchaseOrderId: firstPO.id,
                    shipmentId: firstPO.shipmentIds
                      ? firstPO.shipmentIds[0]
                      : "",
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                  })}
                  style={{
                    color: hasMismatchTag ? "red" : darkBlue,
                  }}
                  id="list-view-content-salesorder"
                >
                  <span id={item.id}>{item.number}</span>
                </NavLink>
              </div>
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER:
        return (
          enabled && (
            <TableCell key={index} className="customerName">
              <TooltipTD
                label={item.customerName}
                style={{ width: "fit-content", display: "flex" }}
              >
                <NavLink
                  to={`/app/customers/${item.customerId}`}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                  id="list-view-content-customer"
                >
                  {item.customerName}
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.TOTAL:
        return (
          enabled && (
            <TableCell key={index} className="amount" id="total">
              {isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
                ? numeral(item.total).format("$0,0.00")
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.SO_SHIP_DATE:
        return (
          enabled && (
            <TableCell key={index} className="shipDate" id="shipDate">
              {getTheDate(item.shipDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.POS:
        return (
          enabled && (
            <TableCell key={index} className="quantityPOs" id="totalPO">
              {item.quantityPOs}
            </TableCell>
          )
        );

      case headerColumns.SHIPMENTS:
        return (
          enabled && (
            <TableCell key={index} className="quantityPOs" id="totalShipment">
              {item.quantityShipments}
            </TableCell>
          )
        );

      case headerColumns.PROPOSAL_QUOTE:
        return (
          enabled &&
          isThereProposalQuoteColumn && (
            <TableCell
              key={index}
              className="proposalTasks"
              style={
                Object.keys(SOTaskByPhases).length > 0
                  ? { width: headerStages.proposalQuoteMinWidh }
                  : {}
              }
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.proposalTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.PRE_PRODUCTION:
        return (
          enabled && (
            <TableCell
              key={index}
              className="preProductionTasks"
              style={
                Object.keys(SOTaskByPhases).length > 0
                  ? {
                      width: headerStages.preProductionMinWidh,
                    }
                  : {}
              }
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.preProductionTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.PRODUCTION:
        return (
          enabled && (
            <TableCell
              key={index}
              className="productionTasks"
              style={
                Object.keys(SOTaskByPhases).length > 0
                  ? {
                      width: headerStages.productionMinWidh,
                    }
                  : {}
              }
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.productionTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.BOOKING_TRANSIT:
        return (
          enabled && (
            <TableCell
              key={index}
              className="bookingTransitTasks"
              style={
                Object.keys(SOTaskByPhases).length > 0
                  ? {
                      width: headerStages.bookingTransitMinWidh,
                    }
                  : {}
              }
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.bookingTransitTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.PAYMENT_BILLING:
        return (
          enabled && (
            <TableCell key={index} className="paymentBillingTasks">
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.paymentBillingTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return enabled && <TableCell className="extra" />;

      default:
        return <TableCell key={index} />;
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <MainRowStyled
        key={item.id}
        className={cx(
          mainRowClasses.SOGroupTableRow,
          mainRowClasses.hoverEffect
        )}
        style={expanded ? { background: colors.salesOrderBackgroundColor } : {}}
      >
        {headers.map((header) =>
          OBFGroupBySOTableRowSelector({
            column: header.name,
            enabled: header.enabled,
            index: item.id + header.name,
          })
        )}
      </MainRowStyled>
      {expanded && Object.keys(POWithTaskByPhases).length > 0 && (
        <React.Fragment>
          <td
            colSpan={16}
            style={{
              outline: `10px solid ${colors.salesOrderBackgroundColor}`,
              outlineOffset: -10,
              overflow: "auto",
              background: colors.salesOrderBackgroundColor,
            }}
          >
            {POWithTaskByPhases && (
              <FilterTablePO
                purchaseOrderList={POWithTaskByPhases.filter(
                  (purchaseOrder) =>
                    isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS) ||
                    isAllowed(purchaseOrder.factoryId)
                ).sort(
                  sortObjectsBy(
                    purchaseOrderTableFilter.sortedColumn,
                    purchaseOrderTableFilter.orderBy === "ASC" ? true : false
                  )
                )}
                headerStages={headerStages}
                topHeader={headers}
                filtersTable={purchaseOrderTableFilter}
                onUpdateFilters={(filters) =>
                  setPurchaseOrderTableFilter(filters)
                }
                onUpdateItem={handleUpdatePOItem}
                tags={tags}
                isAllowed={isAllowed}
                handleCheckItemTag={(currentItem) => {
                  return checkTagsInItem(
                    currentItem,
                    mismatchTags,
                    filters.tag
                  );
                }}
                isThereProposalQuoteColumn={isThereProposalQuoteColumn}
                filters={filters}
                onSetBackdrop={(isRedirect) => {
                  if (isRedirect) {
                    setBackdrop({ type: BACKDROP_TYPE.PURCHASE_ORDER_INFO });
                    setTableItemStorage("purchaseOrder", true);
                  }
                  handleBackdropStorage(BACKDROP_TYPE.PURCHASE_ORDER_INFO);
                }}
                stylesTable={{
                  background: colors.primaryLight,
                  marginRight: 16,
                }}
                tableRowHeaderStyles={{
                  background: colors.primaryLight,
                }}
              />
            )}
            <div
              style={{
                height: 20,
                background: "transparent",
              }}
            />

            {shipmentWithTaskByPhases && (
              <FilterTableShipment
                shipmentList={shipmentWithTaskByPhases
                  .filter(
                    (shipment) =>
                      isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS) ||
                      isAllowed(shipment.customerId)
                  )
                  .sort(
                    sortObjectsBy(
                      shipmentTableFilter.sortedColumn,
                      shipmentTableFilter.orderBy === "ASC" ? true : false
                    )
                  )}
                headerStages={headerStages}
                topHeader={headers}
                filtersTable={shipmentTableFilter}
                onUpdateFilters={(filters) => setShipmentTableFilter(filters)}
                onUpdateItem={handleUpdateShipmentItem}
                tags={tags}
                isAllowed={isAllowed}
                handleCheckItemTag={(currentItem) => {
                  return checkTagsInItem(
                    currentItem,
                    mismatchTags,
                    filters.tag
                  );
                }}
                isThereProposalQuoteColumn={isThereProposalQuoteColumn}
                filters={filters}
                onSetBackdrop={(type, id, scrollProperty) => {
                  setBackdrop({ type, id, scrollProperty });
                  handleBackdropStorage(type);
                }}
                stylesTable={{
                  background: colors.shipmentBadge,
                  marginRight: 16,
                }}
                tableRowHeaderStyles={{
                  background: colors.shipmentBadge,
                }}
                salesOrder={item}
                onOpenAttentionModal={onOpenAttentionModal}
              />
            )}
          </td>
          {expanded && <div style={{ height: 10, display: "table-row" }} />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default GroupBySalesOrder;
