import React, { useEffect, useState, useRef } from "react";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import SalesOrderContainer from "./SalesOrderContainer";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import IntlMessages from "../../util/IntlMessages";
import WithConfirmation from "../ConfirmationDialog/WithConfirmation";
import TemplatesPopover from "./TemplatesPopover";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import {
  getDataFromFirestore,
  sortObjectsBy,
  validateTaskTemplate,
  verifyTaskTemplateErrors,
} from "../../helpers/helpers";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { useCompany } from "../../hooks/company";
import { firestore } from "../../firebase";
import { useCompanyUsers } from "../../hooks/user";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  onSnapshot,
  query,
  updateDoc,
  writeBatch,
  orderBy,
} from "firebase/firestore";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

function FactoryTemplateModal({
  open,
  onClose,
  companyId,
  factory,
  isReadOnly = false,
  summaryFactories,
}) {
  const company = useCompany() || {};
  const buttonRef = useRef(null);
  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const [currentSOTemplate, setCurrentSOTemplate] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [salesOrderTasks, setSalesOrderTasks] = useState([]);
  const [savingData, setSavingData] = useState(false);

  const [taskList, setTaskList] = useState([]);
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);
  const [changed, setChanged] = useState(false);
  const [cloningFrom, setCloningFrom] = useState("");
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
  const [snackbarDescription, setSnackbarDescription] = useState("");
  const [openCloneTemplate, setOpenCloneTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (factory && open) {
      setIsLoading(true);
      return onSnapshot(
        collection(
          firestore,
          `${companyRef}/${dbTables.FACTORIES}/${factory.id}/${dbTables.MINI_PROJECT_TEMPLATE}`
        ),
        (snapshot) => {
          if (savingData) {
            return;
          }
          if (snapshot.docs.length > 0) {
            setTaskList(
              snapshot.docs.map((doc) => ({
                ...doc.data(),
                type: doc.data().type || typeOfTask.PURCHASE_ORDER,
                ref: doc.ref,
              }))
            );
            setIsLoading(false);
          } else {
            setTaskList([]);
            setIsLoading(false);
          }
        }
      );
    }
  }, [companyId, factory.id, open]);

  useEffect(() => {
    getDocs(
      query(
        collection(firestore, `${companyRef}/${dbTables.SALES_ORDER_TEMPLATE}`),
        orderBy("version", "desc"),
        limit(1)
      )
    ).then((snapshot) => {
      setTemplateList(
        snapshot.docs.map((doc) => ({ ...doc.data(), ref: doc.ref }))
      );
      setCurrentSOTemplate(snapshot.docs[0].id);
    });
  }, []);

  useEffect(() => {
    if (currentSOTemplate && factory && open) {
      let factoryCpy = factory;
      const factoryIndexes = factoryCpy.vendorTemplateIndexes || {};
      if (cloningFrom && cloningFrom.id) {
        factoryCpy = cloningFrom;
      }
      getDocs(
        collection(
          firestore,
          `${companyRef}/${dbTables.SALES_ORDER_TEMPLATE}/${currentSOTemplate}/${dbTables.SALES_ORDER_TASKS_TEMPLATE}`
        )
      ).then((snapshot) => {
        const salesOrderTasks = snapshot.docs
          .map((doc) => {
            const templateTask = doc.data();
            const templateIndex = factoryCpy.isOutdated
              ? templateTask.listIndex
              : factoryIndexes[templateTask.id] || templateTask.listIndex;
            return {
              ...templateTask,
              listIndex: templateIndex,
              type: typeOfTask.SALES_ORDER,
              ref: doc.ref,
            };
          })
          .sort(sortObjectsBy("number"));
        setSalesOrderTasks(salesOrderTasks);
      });
    }
  }, [currentSOTemplate, factory.id, open, cloningFrom.id]);

  async function saveMiniProjectTemplate(templateTasks) {
    setSavingData(true);
    setIsLoading(true);
    const batch = writeBatch(firestore);
    summaryFactories.forEach((summary) => {
      let needUpdate = true;
      const factories = summary.factories.map((summaryFactory) => {
        if (summaryFactory.id !== factory.id) {
          return {
            ...summaryFactory,
          };
        } else {
          const { isOutdated, ...factoryData } = summaryFactory;
          needUpdate = true;
          return {
            ...factoryData,
          };
        }
      });
      if (needUpdate) {
        updateDoc(summary.ref, { factories });
      }
    });
    if (cloningFrom && cloningFrom.id) {
      const originalTask = await getDocs(
        collection(
          firestore,
          `${companyRef}/${dbTables.FACTORIES}/${factory.id}/${dbTables.MINI_PROJECT_TEMPLATE}`
        )
      );
      originalTask.docs.forEach((doc) => deleteDoc(doc.ref));
    }
    templateTasks
      .filter((task) => task.type !== typeOfTask.SALES_ORDER)
      .forEach((task) => {
        const randomId = task.id;
        batch.set(
          doc(
            firestore,
            `${companyRef}/${dbTables.FACTORIES}/${factory.id}/${dbTables.MINI_PROJECT_TEMPLATE}/${randomId}`
          ),
          {
            ...task,
            id: randomId,
          }
        );
      });
    let vendorTemplateIndexes = {};
    templateTasks.forEach(
      (task) => (vendorTemplateIndexes[task.id] = task.listIndex)
    );

    updateDoc(
      doc(firestore, `${companyRef}/${dbTables.FACTORIES}/${factory.id}`),
      {
        vendorTemplateIndexes,
      }
    );
    setChanged(false);
    batch.commit();
    closeTemplate();
  }

  function closeTemplate() {
    onClose();
    setChanged(false);
    setCloningFrom("");
    setTaskList([]);
    setSavingData(false);
  }

  function openClone() {
    if (isReadOnly) {
      setReadOnlyModal(true);
    } else {
      setOpenCloneTemplate(true);
    }
  }

  async function cloneTemplate(templateToClone) {
    setIsLoading(true);
    const factoryToClone = await getDataFromFirestore([
      dbTables.COMPANIES,
      companyId,
      dbTables.FACTORIES,
      templateToClone.id,
    ]);

    // Pending
    setChanged(true);
    setCloningFrom(factoryToClone);
    const taskClonedSnap = await getDocs(
      collection(
        firestore,
        `${companyRef}/${dbTables.FACTORIES}/${templateToClone.id}/${dbTables.MINI_PROJECT_TEMPLATE}`
      )
    );
    const taskCloned = taskClonedSnap.docs.map((doc) => ({
      ...doc.data(),
      type: doc.data().type || typeOfTask.PURCHASE_ORDER,
      ref: doc.ref,
    }));
    setTaskList(taskCloned);
    const soTaskSnap = await getDocs(
      collection(
        firestore,
        `${companyRef}/${dbTables.SALES_ORDER_TEMPLATE}/${currentSOTemplate}/${dbTables.SALES_ORDER_TASKS_TEMPLATE}`
      )
    );
    const soTask = soTaskSnap.docs
      .map((doc) => {
        const templateTask = doc.data();
        let templateIndex =
          factoryToClone.vendorTemplateIndexes &&
          factoryToClone.vendorTemplateIndexes[templateTask.id];
        return {
          ...doc.data(),
          listIndex: templateIndex,
          type: typeOfTask.SALES_ORDER,
          ref: doc.ref,
        };
      })
      .sort(sortObjectsBy("number"));
    setSalesOrderTasks(soTask);
    setIsLoading(false);
  }
  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      className="dialog-mini-template"
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          document.getElementById("icon-to-call").click();
        }
      }}
    >
      {isLoading && <LoadingBackdrop withLogo={true} />}

      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      <CustomSnackbar
        variant="error"
        open={snackbarIsOpen}
        message={snackbarDescription}
        handleCloseSnackbar={() => setSnackbarIsOpen(false)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      />
      <TemplatesPopover
        onItemClick={cloneTemplate}
        onClose={() => setOpenCloneTemplate(false)}
        open={openCloneTemplate}
        anchorEl={buttonRef.current}
        companyId={companyId}
      />
      <DialogTitle className="sales-order-modal-title">
        <WithConfirmation
          text={"Discard changes and go Back?"}
          disabled={!changed || isReadOnly}
        >
          <ArrowBackIcon id="icon-to-call" onClick={closeTemplate} />
        </WithConfirmation>
        <WithConfirmation
          text={"Discard changes and go Back?"}
          disabled={!changed || isReadOnly}
        >
          <div onClick={closeTemplate}>
            <IntlMessages id="generic.text.back" />
          </div>
        </WithConfirmation>
        <span
          style={{
            position: "absolute",
            top: 16,
            margin: "0 auto",
            fontSize: 24,
            right: "calc(50% - 240px)",
          }}
        >
          {company.name} - {factory && factory.name}
        </span>
      </DialogTitle>
      <DialogContent style={{ margin: "0px 10px", padding: 0 }}>
        {templateList.length > 0 && templateList[0].version && (
          <div className="header-container">
            <label>{"Sales Order  V" + templateList[0].version} </label>
            <button ref={buttonRef} onClick={openClone}>
              {
                <IntlMessages id="components.factories.factorytemplatemodal.clonetemplate" />
              }
            </button>
          </div>
        )}
        <SalesOrderContainer
          onValidate={(tasks) => {
            const isValidate = validateTaskTemplate({
              templateTasks: tasks,
              users: companyUsers,
            });
            if (!isValidate) {
              setSnackbarDescription(
                `  Please make sure to fill in all fields on all tasks before
                  saving`
              );
              setSnackbarIsOpen(true);
              return false;
            }
            const salesOrderTasks =
              tasks.filter((task) => task.type === typeOfTask.SALES_ORDER) ||
              [];
            const purchaseOrderTasks =
              tasks.filter((task) => task.type !== typeOfTask.SALES_ORDER) ||
              [];
            if (purchaseOrderTasks.length === 0) {
              setSnackbarDescription(" At least one vendor task is required");
              setSnackbarIsOpen(true);
              return false;
            }
            const { status } = verifyTaskTemplateErrors({
              isPOVerifier: true,
              SOTaskTemplate: salesOrderTasks,
              POTaskTemplate: purchaseOrderTasks,
            });
            if (status === 400) {
              setSnackbarDescription(
                "Template cannot have a circular dependency"
              );
              setSnackbarIsOpen(true);
              return false;
            }
            return true;
          }}
          changed={changed}
          setChanged={setChanged}
          factoryId={factory ? factory.id : ""}
          companyId={companyId}
          onSave={saveMiniProjectTemplate}
          onCancel={closeTemplate}
          salesOrderTasks={salesOrderTasks}
          taskList={taskList}
          cloningFrom={cloningFrom}
          disabled={isReadOnly}
          companyUsers={companyUsers}
        />
      </DialogContent>
    </Dialog>
  );
}

export default FactoryTemplateModal;
