import { colors } from "../../assets/jss/variables";
import { ButtonGroup, Popover, styled } from "@mui/material";

export const OrderStatusButtons = styled(ButtonGroup)(({ theme }) => ({
  "&.rootGroup": {
    "& .text": { fontSize: "0.85em", whiteSpace: "nowrap" },
    "& .icons": {
      marginRight: "1%",
    },
    [theme.breakpoints.down("md")]: {
      "& .icons": {
        display: "none",
      },
    },
    [theme.breakpoints.down("lg")]: {
      "& .icons": {
        width: 15,
        height: 15,
      },
    },
  },
}));

export const PopoverToolStyled = styled(Popover)(() => ({
  "&.popoverTool": {
    "& .MuiPopover-paper": {
      marginTop: 5,
      background: "transparent",
      boxShadow: "unset",
    },
    "& .popoverContent": {
      position: "relative",
      backgroundColor: "#fff",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      margin: "10px 10px 10px 5px",
      borderRadius: "10px",
      "&::before": {
        backgroundColor: "white",
        content: '""',
        display: "block",
        position: "absolute",
        width: 20,
        height: 20,
        top: -6,
        transform: "rotate(45deg)",
        left: "calc(50% - 6px)",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 1)",
        borderRadius: "5px",
      },
    },

    "& .menuItems": {
      borderRadius: "10px",
      "& .MuiFormControlLabel-root": {
        margin: "unset",
      },
      "& .MuiTypography-root": {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px",
      },

      height: 40,
      background: "white",
      "&:hover": {
        backgroundColor: "#FEFCE7",
      },
      "&:active": {
        "& .checkIcon": {
          backgroundColor: "#218CFF",
          "&::before": {
            display: "block",
            width: 15,
            height: 15,
            backgroundImage:
              "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
              " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
              "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
            marginTop: 2,
          },
        },
      },
    },
    "& .checkRoot": {
      padding: 0,
      marginInline: 8,
      "& .MuiIconButton-label": {
        boxShadow: "0.5px 0.5px 5px rgba(0, 0, 0, 0.5)",
      },
      "& .checkIcon": {
        borderRadius: 3,
        width: 15,
        height: 15,
        outline: "1px solid #6B7A89",
        "&::before": {
          content: "''",
        },
      },
      "&:hover": {
        backgroundColor: "transparent",
        "& .checkIcon": {
          outline: "2px solid #6B7A89",
        },
      },
    },

    "& .checkedIcon": {
      outlineColor: "#218CFF !important",
      backgroundColor: "#218CFF",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 15,
        height: 15,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
  },
}));

export const ToolButtonsStyled = styled("div")(() => ({
  "&.toolsContainer": {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 25,
    marginBottom: 5,
    gap: 24,
    "& button": {
      minWidth: 36,
    },
    "& .csvIcon": {
      transition: "all 0.3s ease",
      transform: "translateY(0)",
    },
    "& .disabledButton": {
      opacity: 0.5,
    },

    "& .iconContainer": {
      width: 40,
      height: 35,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      borderRadius: 5,
      justifyContent: "center",
      "&:hover": {
        background: colors.backgroundWhite,
        "& .expandRow": {
          "& path": {
            strokeWidth: 2.5,
          },
        },
        "& .gearClose": {
          fill: colors.primaryDark,
        },
        "& .csvIcon": {
          "& .arrow": {
            stroke: colors.primaryDark,
            strokeWidth: 1,
          },
          "& .arrowContainer": {
            strokeWidth: 3,
          },
        },
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
        "& .gearClose": {
          width: 22,
          height: 22,
          transform: "rotate(45deg)",
          transition: "transform .5s ease-in-out",
          fill: colors.primaryDark,
        },
        "& .expandRow": {
          "& .item1": {
            transform: "translateY(1px)",
          },
          "& .item3": {
            transform: "translateY(-1px)",
          },
          "& path": {
            strokeWidth: 3,
          },
        },
        "& .csvIcon": {
          "& .arrow": {
            transform: "translateY(3px)",
          },
        },
      },
    },

    "& .csv": {
      "& span": {
        display: "flex",
      },
    },
  },
}));
