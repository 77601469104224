import React from "react";
import { dayHeight, dayWidth, TOOLTIP_TYPE } from "../../helpers/constants";
import TooltipTD from "../Tooltip/TooltipTD";
import VerticalIndicator from "./VerticalIndicator";
import { Paper } from "@mui/material";

function CalendarHeader({
  className,
  styles,
  text,
  milestone,
  rowIndex,
  verticalIndicator,
  taskOnHover,
  date,
}) {
  function getMilestoneStyles(milestone, rowIndex) {
    let milestoneStyles = {
      width: dayWidth - 2,
      height: dayHeight,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeft: `2px solid ${milestone.color}`,
      borderRight: `2px solid ${milestone.color}`,
    };
    if (milestone.isToday) {
      milestoneStyles = {
        ...milestoneStyles,
        background: milestone.color,
        color: "#fff",
      };
      if (rowIndex === 1) {
        return {
          ...milestoneStyles,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderTop: `2px solid ${milestone.color}`,
        };
      }
      return {
        ...milestoneStyles,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottom: `2px solid ${milestone.color}`,
      };
    } else if (rowIndex === 1) {
      return {
        ...milestoneStyles,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderTop: `2px solid ${milestone.color}`,
      };
    }
    return {
      ...milestoneStyles,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      borderBottom: `2px solid ${milestone.color}`,
    };
  }

  return (
    <Paper className={className} style={styles} elevation={0}>
      {milestone ? (
        <TooltipTD
          label={milestone.name}
          type={rowIndex === 2 && TOOLTIP_TYPE.CALENDAR_HEADER}
        >
          <div
            className={"milestone"}
            style={getMilestoneStyles(milestone, rowIndex)}
          >
            {text}
          </div>
        </TooltipTD>
      ) : (
        <span style={{ zIndex: 1 }}>{text}</span>
      )}
      {verticalIndicator && (
        <VerticalIndicator
          taskOnHover={taskOnHover}
          date={date}
          size={3}
          styles={{ opacity: 0.8 }}
        />
      )}
    </Paper>
  );
}

export default CalendarHeader;
