import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

const searchStyles = {
  color: "#A8B9CA",
  fontSize: 13,
  zIndex: 1,
  width: 32,
  float: "right",
  borderRadius: 4,
  marginRight: 5,
  top: -4,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  transition: "width 0.8s",
  "& input": {
    width: 0,
    padding: 0,
    transition: "width 0.8s",
  },
  "&:hover": {
    cursor: "pointer",
  },
};

export const SearchLabelStyled = styled("div")(() => ({
  "&.containerSearchBox": {
    color: "#A8B9CA",
    fontSize: 13,
    zIndex: 1,
    marginRight: 5,
    position: "relative",
    top: 2,
    display: "flex",
    justifyContent: "flex-end",
  },
  "&.searchBox": {
    ...searchStyles,
  },
  "&.searchBoxNotes": {
    ...searchStyles,
    marginRight: 12,
    width: 32,
    "&:hover": {
      cursor: "auto",
    },
    "&:after": {
      content: '""',
      width: 2,
      height: 20,
      backgroundColor: colors.primaryDark,
    },
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      width: "280px !Important",
      transition: "width 0.8s",
      border: "1px solid #5D92F4",
      "& input": {
        width: "100%",
        padding: "0 6px",
      },
      "&:after": {
        content: '""',
        width: 0,
        backgroundColor: "transparent",
      },
    },
    "& svg": {
      margin: "0px 8px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  "&.searchBoxDocuments": {
    ...searchStyles,
    marginRight: 12,
    width: 32,
    "&:hover": {
      cursor: "auto",
    },
    "&:after": {
      content: '""',
      width: 2,
      height: 20,
      backgroundColor: colors.primaryDark,
    },
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      width: "200px !Important",
      transition: "width 0.8s",
      border: "1px solid #5D92F4",
      backgroundColor: "#fff",
      "& input": {
        width: "100%",
        padding: "0 6px",
      },
      "&:after": {
        content: '""',
        width: 0,
        backgroundColor: "transparent",
      },
    },
    "& svg": {
      margin: "0px 8px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  "&.searchBoxNoteDocuments": {
    ...searchStyles,
    width: 32,
    "&:hover": {
      cursor: "auto",
    },
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      width: "255px !Important",
      transition: "width 0.8s",
      border: "1px solid #5D92F4",
      backgroundColor: "#fff",
      "& input": {
        width: "100%",
        padding: "0 6px",
      },
    },
    "& svg": {
      margin: "0px 8px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  "&.searchBoxSKUTable": {
    ...searchStyles,
    width: 36,
    height: 36,
    justifyContent: "flex-end",
    "&:hover": {
      background: colors.backgroundWhite,
      cursor: "pointer",
    },
    "&:active": {
      background: colors.backgroundWhiteAction,
    },
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      width: "275px !Important",
      transition: "width 0.8s",
      border: "1px solid #5D92F4",
      backgroundColor: "#fff",
      height: 28,
      "& input": {
        width: "100%",
        padding: "0 6px",
      },
      "& svg": {
        width: 14,
        height: 14,
        marginRight: 8,
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    "& svg": {
      width: 22,
      height: 22,
      margin: "0px 8px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  "&.searchBoxActive": {
    ...searchStyles,
    width: "230px !Important",
  },
  "&.searchBoxActiveDocuments": {
    ...searchStyles,
    width: "200px !Important",
    border: "1px solid #D2DFEC",
    backgroundColor: "#fff",
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      width: "200px !Important",
      transition: "width 0.8s",
      border: "1px solid #5D92F4",
    },
    "& input": {
      width: "100%",
      padding: "0 6px",
    },
  },
  "&.searchBoxActiveNotes": {
    ...searchStyles,
    width: "280px !Important",
    transition: "width 0.8s",
    border: "1px solid #D2DFEC",
    marginRight: 12,
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      width: "280px !Important",
      transition: "width 0.8s",
      border: "1px solid #5D92F4",
    },
    "& input": {
      width: "100%",
      padding: "0 6px",
    },
  },
  "&.searchBoxActiveSKUTable": {
    ...searchStyles,
    width: "275px !Important",
    border: "1px solid #D2DFEC",
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      width: "275px !Important",
      transition: "width 0.8s",
      border: "1px solid #5D92F4",
    },
    "& input": {
      width: "100%",
      padding: "0 6px",
    },
    "& svg": {
      width: 14,
      height: 14,
      marginRight: 8,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  "&.searchBoxActiveNoteDocuments": {
    ...searchStyles,
    width: "255px !Important",
    border: "1px solid #D2DFEC",
    backgroundColor: "#fff",
    "&:focus-within": {
      boxShadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem",
      width: "255px !Important",
      transition: "width 0.8s",
      border: "1px solid #5D92F4",
    },
    "& input": {
      width: "100%",
      padding: "0 6px",
    },
  },
  "& .magnifyingglass": {
    float: "right",
  },
  "& .magnifyingglassOnExpandedInput": {
    float: "right",
    marginTop: 5,
  },
  "& .placeholder": {
    float: "right",
    marginRight: 5,
  },
  "& input": {
    width: "100%",
    border: "none",
    lineHeight: 2,
    paddingInline: 5,
    color: colors.primaryDark,
    "&::placeholder": {
      color: "#D2DFEC",
    },
  },
  "& .buttonX": {
    background: "#FFF",
    color: "darkGray",
    width: 15,
    height: 15,
    borderRadius: "50%",
    margin: "0px 8px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
