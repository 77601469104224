import React from "react";
import { GENERAL_PERMISSION_VALUE } from "../../../helpers/constants";
import { getFactoryByField } from "../../../helpers/helpers";
import { WarningVendorIcon } from "../../../helpers/iconSvgPath";
import { orderType } from "../../../helpers/salesOrder";
import { useCompanyId } from "../../../hooks";
import { useCurrentPurchaseOrder } from "../../../hooks/salesOrders";
import TooltipTD from "../../Tooltip/TooltipTD";
import { POMetadataContainterStyled } from "../styles";
import VendorDashboardTemplate from "../VendorDashboardTemplate";
import IntlMessages from "../../../util/IntlMessages";
import { colors } from "../../../assets/jss/variables";
import { useFactories } from "../../../hooks/factories";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { useUser } from "../../../hooks/user";

import POMetadataInfo from "./POMetadataInfo";
import POSKUSection from "./POSKUSection";
import { cx } from "@emotion/css";

function PurchaseOrderMetadata({ vendorPermission }) {
  const purchaseOrder = useCurrentPurchaseOrder();
  const user = useUser() || {};
  const companyId = useCompanyId();
  const factories = useFactories();
  const isAllowed = useIsAllowedFunction();

  if (!vendorPermission) {
    return (
      <div className="blockContainer">
        <p className="blockText">
          You do not have permission to see this vendor
        </p>
      </div>
    );
  }

  return (
    <div className={"purchase-order-info"} id="purchase-order-info">
      {purchaseOrder.id && (
        <POMetadataContainterStyled
          className={cx(
            "poContainer",
            `content-card-content purchase-order-content`
          )}
        >
          <div className={"poTitleContent"}>
            <span style={{ color: colors.darkGray, addingRight: 8 }}>
              <IntlMessages
                id={
                  purchaseOrder.type === orderType.PURCHASE_QUOTE
                    ? "order.type.purchase.quote"
                    : "order.type.purchase.order"
                }
              />
              :
            </span>
            <span>{purchaseOrder.number}</span>
            <span style={{ color: colors.darkGray }}>Vendor:</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {purchaseOrder.isOutdated && (
                <TooltipTD
                  label={
                    <span>
                      The template for this vendor is pending validation
                      <br /> since the last change to the Company Template
                    </span>
                  }
                  style={{ height: 18 }}
                >
                  <WarningVendorIcon
                    width={21}
                    height={18}
                    style={{ marginBottom: 1 }}
                  />
                </TooltipTD>
              )}
              {getFactoryByField({
                factories,
                factoryId: purchaseOrder.factoryId,
                field: "number",
              })}
            </div>
            <span>
              {getFactoryByField({
                factories,
                factoryId: purchaseOrder.factoryId,
                field: "name",
              })}
            </span>
            {purchaseOrder.isOutdated &&
              isAllowed(
                GENERAL_PERMISSION_VALUE.MODIFY_FACTORIES_TEMPLATES
              ) && (
                <VendorDashboardTemplate
                  vendor={factories.find(
                    (factory) => factory.id === purchaseOrder.factoryId
                  )}
                  purchaseOrder={purchaseOrder}
                  user={user}
                  companyId={companyId}
                />
              )}
          </div>
          <POMetadataInfo />
          <POSKUSection
            purchaseOrder={purchaseOrder}
            companyId={companyId}
            user={user}
            key={"SectionPoSKU"}
          />
        </POMetadataContainterStyled>
      )}
    </div>
  );
}

export default PurchaseOrderMetadata;
