import React, { Fragment } from "react";
import { TableCell } from "@mui/material";
import { TableRowStyled } from "./styles";
import { getDataSubTable } from "../../helpers/itemsTableHelper";
import { generalPermissions } from "../../helpers/constants";
import RowSimpleV2 from "./RowSimpleV2";

function RowCollapseV2({
  item = {},
  columns,
  showImage,
  onOpenModal = () => {},
  onChangeData,
  indexRowParent,
  salesOrders = [],
  shipments = [],
  prefix,
  handlePhotosViewer = () => {},
  isAllowed = () => {},
  companyAllowed = () => {},
  handleOpenRow = () => {},
  rowIsOpen = false,
  poisOutdated = false,
  isVoided = false,
  onClickRedirect,
  backdropStateId,
}) {
  function getLabelWidth({ salesOrdersSize, shipmentsSize }) {
    if (salesOrdersSize < 10 && shipmentsSize < 10) {
      return 10;
    } else if (salesOrdersSize < 99 && shipmentsSize < 99) {
      return 18;
    }
    return 25;
  }
  const itemSO = item.salesOrders || [];
  let hasOneItem = itemSO.length <= 1;
  if (hasOneItem) {
    hasOneItem = itemSO.every((salesOrderItem) => {
      const shipments = salesOrderItem.shipments || [];
      return shipments.length <= 1;
    });
  }
  // PENDING
  function getDataSubheader() {
    const subSubCellData = getDataSubTable({ item, columns });
    const bodyTableSubHeader = subSubCellData.subBodyTable || [];
    const { salesOrdersSize, shipmentsSize } = subSubCellData;
    const labelWidth = getLabelWidth({ salesOrdersSize, shipmentsSize });
    const allTextWitTotal = [
      <div>
        <label>Total Customers:</label>{" "}
        <label style={{ width: labelWidth }}> {salesOrdersSize}</label>
      </div>,
      <div>
        <label>Total Shipments:</label>{" "}
        <label style={{ width: labelWidth }}> {shipmentsSize}</label>
      </div>,
    ];
    const subBodyTable = subSubCellData.subBodyTable || [];
    const table = subBodyTable.map((dataRow, dataRowIndex) => (
      <TableRowStyled
        className={"body"}
        key={`${prefix}_${dataRow.id}_${dataRow.nameArray}${dataRowIndex}`}
        id={`sub${prefix}_${item.id}${dataRowIndex}`}
      >
        {dataRowIndex === 0 && (
          <TableCell
            className={"totalCellShipment"}
            colSpan={
              isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT) ? 6 : 4
            }
            rowSpan={bodyTableSubHeader.length}
            align={"right"}
            style={{
              padding: "0px 25% 0px 16px",
              background: "white",
            }}
            id={"totalLabels"}
          >
            {allTextWitTotal.map((text, index) => (
              <div className={"totalCustomersAndShipments"} key={text + index}>
                {text}
              </div>
            ))}
          </TableCell>
        )}
        {dataRow.map((data, indexData) => {
          let subBodyStyle = {
            ...data.columnStyle,
            ...data.style,
            borderBottomColor:
              dataRowIndex === bodyTableSubHeader.length - 1
                ? ""
                : data.borderColor,
          };

          return (
            <RowSimpleV2
              key={data.id + indexData}
              item={item}
              row={data.row}
              parentRow={data.parentRow}
              indexParent={data.indexParent + 1}
              index={data.indexChild}
              onChangeData={onChangeData}
              salesOrders={salesOrders}
              shipments={shipments}
              handlePhotosViewer={handlePhotosViewer}
              onOpenModal={onOpenModal}
              prefix={prefix}
              isAllowed={isAllowed}
              companyAllowed={companyAllowed}
              poisOutdated={poisOutdated}
              isVoided={isVoided}
              onClickRedirect={onClickRedirect}
              column={{
                ...data,
                parentId: data.id,
                subColumn: true,
                style: {
                  ...subBodyStyle,
                },
              }}
            />
          );
        })}
      </TableRowStyled>
    ));
    return table;
  }

  let style = {};
  if (item.id === backdropStateId) {
    style = { zIndex: 1202, position: "relative" };
  }
  //
  return (
    <Fragment>
      <TableRowStyled
        key={`${prefix}_${item.id}_${item.itemNumber}_${indexRowParent}_principalRow`}
        className={"body"}
        id={`${prefix}_${item.id}`}
        style={style}
      >
        {columns.map((column, index) => (
          <RowSimpleV2
            key={column.id + index}
            row={item}
            item={item}
            column={{
              ...column,
              style: {
                ...column.style,
                borderBottom:
                  !column.multiHeader && rowIsOpen && !hasOneItem ? "none" : "",
              },
            }}
            index={index}
            open={rowIsOpen}
            showImage={showImage}
            onOpenRow={() => handleOpenRow(item)}
            isMultiHeader={true}
            onOpenModal={onOpenModal}
            onChangeData={onChangeData}
            salesOrders={salesOrders}
            shipments={shipments}
            prefix={prefix}
            handlePhotosViewer={handlePhotosViewer}
            isAllowed={isAllowed}
            companyAllowed={companyAllowed}
            poisOutdated={poisOutdated}
            isVoided={isVoided}
            onClickRedirect={onClickRedirect}
          />
        ))}
      </TableRowStyled>
      {rowIsOpen && getDataSubheader()}
    </Fragment>
  );
}

export default RowCollapseV2;
