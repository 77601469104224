import React, { useState, useEffect } from "react";
import _ from "lodash";
import Modal from "../Modal/Modal";
import IntlMessages from "../../util/IntlMessages";
import CloseIcon from "@mui/icons-material/Close";
import { stateToHTML } from "draft-js-export-html";
import {
  getCustomerName,
  getFactoryName,
  hasFeatureFlagPermission,
  pathToString,
  sortObjectsBy,
} from "../../helpers/helpers";
import AttentionModal from "../Modal/AttentionModal";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import {
  useCompanyId,
  useDocumentOrder,
  usePermissionGroups,
} from "../../hooks";
import { orderType } from "../../helpers/salesOrder";
import RichText from "./RichText";
import {
  convertFromRaw,
  EditorState,
  convertToRaw,
  CharacterMetadata,
  ContentBlock,
} from "draft-js";
import {
  featureFlagNames,
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  PO_STATUS,
} from "../../helpers/constants";

import GreenSmallSwitch from "../Inputs/GreenSmallSwitch";
import { ModalWarningStyled } from "./style";
import {
  NoteSectionBodyContainerStyled,
  NotesSectionStyled,
} from "../PurchaseOrderDashboard/styles";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { firestore, storage } from "../../firebase";
import FooterNoteSection from "./FooterNoteSection";
import DocumentsNote from "./FoldersStructure/DocumentsNote";
import { useUser } from "../../hooks/user";
import { dbTables } from "../../api/types/dbTables";
import {
  blockSaveStyle,
  getButtonIcon,
  getDocumentErrorInfo,
  getParseHtml,
  getShipmentMentions,
  getStatusClass,
  getUsersName,
  handleAddData,
  handleUserDisplay,
  hasPermission,
  inlineImages,
  INLINE_STYLES,
  orderPromiseDocs,
  verifyDocumentsPermissions,
  getDocumentMentionData,
} from "./ComponentHelper";
import userTypes from "../../api/types/userTypes";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { documentScope } from "../../helpers/documents";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { getShipmentsByPOs } from "../../helpers/salesOrderHelpers";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import moment from "moment";
import { cx } from "@emotion/css";

const WARNING_MODAL = "WARNING";

const DATA_TYPE = {
  GROUP: "group",
  USER: "user",
};

function NotesSection({
  openModalNotes,
  onCloseModalNotes,
  users,
  handleSubmit,
  typeNote,
  editingNote,
  currentNote,
  salesOrder,
  purchaseOrder,
  currentShipment = {},
  purchaseOrders = [],
  cleanReplyActivity = () => {},
}) {
  const companyId = useCompanyId();
  const featureFlags = useFeatureFlags({ companyId });
  const allowed = useIsAllowedFunction();
  const initState =
    editingNote && currentNote.mentionsFormat
      ? convertFromRaw(JSON.parse(currentNote.mentionsFormat))
      : "";
  const [editorState, setEditorState] = useState(() =>
    editingNote && currentNote.mentionsFormat
      ? EditorState.createWithContent(initState)
      : EditorState.createEmpty()
  );
  const navigatorData = navigator || {};
  const userData = navigatorData.userAgentData || {};
  const OS = userData.platform || "";
  const user = useUser();
  const hasFlagPermission = hasFeatureFlagPermission({
    featureFlags,
    user,
    featureFlagName: featureFlagNames.DOCUMENT_IN_NOTES,
  });
  const [lockNote, setLockNote] = useState(!!currentNote.lockNote);
  const [temporalNote, setTemporalNote] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [documentError, setDocumentError] = useState({});
  const [poDocumentId, setPODocumentId] = useState(purchaseOrder.id);
  const [shipmentDocumentId, setShipmentDocumentId] = useState(
    currentShipment.id
  );
  const [documentsDB, setDocumentDB] = useState({});

  const [documentData, setDocumentData] = useState({});
  const [isUnmounted, setUnmounted] = useState(false);
  const [documentPermission, setDocumentPermission] = useState({});
  const [modalType, setModalType] = useState(WARNING_MODAL);
  const [hasMention, setHasMention] = useState(
    !_.isEmpty(currentNote.userMentions)
  );
  const [isDocumentOpen, setIsDocumentOpen] = useState(false);
  const [closed, setClosed] = useState(true);
  const [urlUploaded, setUrlUploaded] = useState([]);
  const [selectedButton, setSelectedButton] = useState(typeNote);
  const [isLoading, setIsLoading] = useState(false);
  const [shipments, setShipments] = useState([]);
  const permissionGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });
  const [permissionToVerify, setPermissionToVerify] = useState("");
  const [globalPermission, setGlobalPermission] = useState("");

  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [saveDraftModal, setSaveDraftModal] = useState(false);

  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );
  const customers = useCustomers();
  const factories = useFactories();
  const filterShipment = shipments.filter((shipment) => {
    const currentPurchaseOrderIds = shipment.purchaseOrderIds || [];
    const purchaseOrderIds = salesOrder.purchaseOrderIds || [];
    if (selectedButton === NOTES_SCOPE.SALES_ORDER) {
      return currentPurchaseOrderIds.some((id) =>
        purchaseOrderIds.includes(id)
      );
    } else if (selectedButton === NOTES_SCOPE.PURCHASE_ORDER) {
      return currentPurchaseOrderIds.includes(purchaseOrder.id);
    } else {
      return currentShipment.id === shipment.id;
    }
  });

  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  const salesOrderDocuments = useDocumentOrder({
    documentScope: dbTables.SALES_ORDER_DOCUMENTS,
    orderId: salesOrder.id,
  });

  const handleCloseModal = ({ isDeleted = false, incompleted = false }) => {
    if (editingNote && !isDeleted) {
      updateDoc(currentNote.ref, { editing: false });
    }
    let id = getCurrentOrderId();
    cleanReplyActivity();
    if (!incompleted) {
      deleteUnsaveImages(urlUploaded);
      if (temporalNote) {
        deleteDoc(temporalNote.ref);
      }
    }
    let memoryData = false;
    if (incompleted && !isEmpty() && !editingNote) {
      let documentObjIds = {};
      const state = editorState.getCurrentContent();
      const options = {
        entityStyleFn: (entity) => {
          const entityType = entity.get("type").toLowerCase();
          if (entityType.includes("mention")) {
            const data = entity.getData();
            const mention = data.mention;
            documentObjIds = getDocumentMentionData({
              mention,
              oldDocumentId: documentObjIds,
            });
            return {
              element: "span",
            };
          }
        },
      };
      stateToHTML(state, options);

      memoryData = {
        mentionsFormat: JSON.stringify(convertToRaw(state)),
        urlUploaded,
        lockNote,
        path: `${dbTables.USERS}/${user.id}/${dbTables.TEMPORAL_NOTE}/${id}`,
        typeNote: selectedButton,
        documentsMentions: documentObjIds,
      };
    }
    if (temporalNote && temporalNote.id !== id) {
      deleteDoc(temporalNote.ref);
    }
    setSelectedButton(NOTES_SCOPE.SALES_ORDER);
    onCloseModalNotes({ memoryData: { ...memoryData } });
  };

  useEffect(() => {
    if (selectedButton === NOTES_SCOPE.PURCHASE_ORDER) {
      setPermissionToVerify(purchaseOrder.factoryId);
      setGlobalPermission(GENERAL_PERMISSION_VALUE.ALL_VENDORS);
    } else {
      setPermissionToVerify(salesOrder.customerId);
      setGlobalPermission(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS);
    }
  }, [selectedButton]);

  useEffect(() => {
    setIsLoading(true);
    setShipmentsDB();
    if (editingNote) {
      updateDoc(currentNote.ref, { editing: true });
    }
  }, []);

  const setShipmentsDB = async () => {
    const newShipments = await getShipmentsByPOs({
      purchaseOrders,
      path: pathToString([dbTables.COMPANIES, companyId, dbTables.SHIPMENTS]),
    });
    setShipments(newShipments);
    getTemporalNote();
  };

  const getCurrentOrderId = () => {
    switch (selectedButton) {
      case NOTES_SCOPE.PURCHASE_ORDER:
        return purchaseOrder.id;
      case NOTES_SCOPE.SALES_ORDER:
        return salesOrder.id;
      default:
        return currentShipment.id;
    }
  };

  const getTemporalNote = async () => {
    let id = getCurrentOrderId();
    const temporalNoteSnap = await getDoc(
      doc(
        firestore,
        `${dbTables.USERS}/${user.id}/${dbTables.TEMPORAL_NOTE}/${id}`
      )
    );
    if (temporalNoteSnap.exists()) {
      const now = moment();
      const fecha = moment(temporalNoteSnap.data().createDate, "YYYY-MM-DD");
      const diff = now.diff(fecha, "days");
      if (diff > 30) {
        deleteDoc(temporalNoteSnap.ref);
      } else {
        const currentTemporalData = temporalNoteSnap.data();
        loadOldDocumentData({
          documentsMentions: currentTemporalData.documentsMentions,
        });
        setTemporalNote({
          ...currentTemporalData,
          ref: temporalNoteSnap.ref,
          id: temporalNoteSnap.id,
        });
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(currentTemporalData.mentionsFormat))
          )
        );
        setLockNote(currentTemporalData.lockNote);
        setUrlUploaded(currentTemporalData.urlUploaded);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (poDocumentId) {
      const currentDocument = documentsDB[poDocumentId];
      let canSearch = true;
      if (editingNote && currentDocument) {
        canSearch = currentDocument.some((doc) => doc.oldData);
      }
      if (canSearch) {
        getDocs(
          collection(
            firestore,
            `${companyRef}/${dbTables.PURCHASE_ORDERS}/${poDocumentId}/${dbTables.PURCHASE_ORDER_DOCUMENTS}`
          )
        ).then((poDocuments) => {
          setDocumentDB((oldDocument) => {
            return {
              ...oldDocument,
              [poDocumentId]: poDocuments.docs.map((doc) => ({
                ...doc.data(),
                purchaseOrderId: poDocumentId,
                ref: doc.ref,
                documentId: doc.id,
                scope: documentScope.PURCHASE_ORDER,
              })),
            };
          });
        });
      }
    }
  }, [poDocumentId]);

  useEffect(() => {
    if (shipmentDocumentId) {
      const currentDocument = documentsDB[shipmentDocumentId];
      let canSearch = true;
      if (editingNote && currentDocument) {
        canSearch = currentDocument.some((doc) => doc.oldData);
      }
      if (canSearch) {
        getDocs(
          collection(
            firestore,
            `${companyRef}/${dbTables.SHIPMENTS}/${shipmentDocumentId}/${dbTables.SHIPMENT_DOCUMENTS}`
          )
        ).then((shipmentDocuments) => {
          setDocumentDB((oldDocument) => {
            return {
              ...oldDocument,
              [shipmentDocumentId]: shipmentDocuments.docs.map((doc) => ({
                ...doc.data(),
                shipmentId: shipmentDocumentId,
                ref: doc.ref,
                documentId: doc.id,
                originalId: shipmentDocumentId,
                scope: documentScope.SHIPMENT,
              })),
            };
          });
        });
      }
    }
  }, [shipmentDocumentId]);

  const alertUser = (e) => {
    setUnmounted(true);
    e.preventDefault();
    setTimeout(() => {
      onCloseModalNotes(false);
    }, 100);
  };

  const loadOldDocumentData = ({ documentsMentions = {} }) => {
    const promiseArray = [];
    for (const id in documentsMentions) {
      const document = documentsMentions[id];
      const docId = document.documentId;
      if (
        document.scope === documentScope.SHIPMENT &&
        document.parentId !== shipmentDocumentId
      ) {
        const shipmentDoc = getDoc(
          doc(
            firestore,
            `${companyRef}/${dbTables.SHIPMENTS}/${document.parentId}/${dbTables.SHIPMENT_DOCUMENTS}/${docId}`
          )
        );
        promiseArray.push(shipmentDoc);
      } else if (
        document.scope === documentScope.PURCHASE_ORDER &&
        document.parentId !== poDocumentId
      ) {
        const poDoc = getDoc(
          doc(
            firestore,
            `${companyRef}/${dbTables.PURCHASE_ORDERS}/${document.parentId}/${dbTables.PURCHASE_ORDER_DOCUMENTS}/${docId}`
          )
        );
        promiseArray.push(poDoc);
      }
    }
    const resolvePromises = async (arrayPromises) => {
      const resolve = await Promise.all(arrayPromises);
      const newDocumentsObject = orderPromiseDocs(resolve);
      setDocumentDB((oldDocument) => {
        return {
          ...oldDocument,
          ...newDocumentsObject,
        };
      });
    };
    resolvePromises(promiseArray);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    if (editingNote) {
      loadOldDocumentData({ documentsMentions: currentNote.documentsMentions });
    }
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    if (isUnmounted) {
      deleteUnsaveImages(urlUploaded);
    }
  }, [isUnmounted]);

  function isEmpty() {
    const contentState = editorState.getCurrentContent();
    return !contentState.hasText();
  }

  function verifyExistsUserMentionWithouPermission(
    userMentions = {},
    users,
    selectedButton
  ) {
    let usersWithoutPermission = [];
    Object.keys(userMentions).forEach((key) => {
      const user = users.find((user) => user.id === key) || {};
      const userPermissions = user.permissions;
      if (!user.id) {
        return;
      }
      if (selectedButton === NOTES_SCOPE.PURCHASE_ORDER) {
        if (
          user.role !== userTypes.SUPER_ADMIN &&
          !userPermissions[purchaseOrder.factoryId] &&
          !userPermissions[GENERAL_PERMISSION_VALUE.ALL_VENDORS]
        ) {
          usersWithoutPermission.push(user);
        }
      } else {
        if (
          user.role !== userTypes.SUPER_ADMIN &&
          !userPermissions[salesOrder.customerId] &&
          !userPermissions[GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS]
        ) {
          usersWithoutPermission.push(user);
        }
      }
    });
    return { usersWithoutPermission };
  }

  function handleSaveNote() {
    const contentState = editorState.getCurrentContent();
    const lastBlock = contentState.getLastBlock();
    const lastBlockKey = lastBlock.getKey();
    const lastBlockText = lastBlock.getText();
    const newBlock = new ContentBlock({
      key: lastBlockKey,
      type: lastBlock.getType(),
      text: lastBlockText + " ",
      characterList: lastBlock
        .getCharacterList()
        .push(CharacterMetadata.create()),
    });
    const newBlockMap = contentState
      .getBlockMap()
      .delete(lastBlockKey)
      .set(newBlock.getKey(), newBlock);
    const newContentState = contentState.merge({
      blockMap: newBlockMap,
      selectionAfter: contentState.getSelectionAfter().merge({
        anchorKey: newBlock.getKey(),
        anchorOffset: newBlock.getLength(),
        focusKey: newBlock.getKey(),
        focusOffset: newBlock.getLength(),
      }),
    });
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    //
    let allUserMentions = {};
    let newNotes = convertToRaw(newEditorState.getCurrentContent());
    const oldList = currentNote.urlList || [];
    const imagesInNote = [];
    const state = newEditorState.getCurrentContent();
    const permissionGroupObjIds = {};
    const shipmentObjIds = {};
    const userObjIds = {};
    let documentObjIds = {};
    const options = {
      inlineStyles: INLINE_STYLES,
      blockStyleFn: (block) => {
        return blockSaveStyle(block.getType());
      },
      entityStyleFn: (entity) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType.includes("mention")) {
          const data = entity.getData();
          const mention = data.mention;
          let classes = selectedButton + "MentionClass";
          if (mention.isShipment) {
            shipmentObjIds[mention.id] = true;
            classes =
              selectedButton === NOTES_SCOPE.SHIPMENT
                ? "reverseShipmentClass"
                : "mentionsShipmenClass";
          } else {
            if (mention.isDocument) {
              documentObjIds = getDocumentMentionData({
                mention,
                oldDocumentId: documentObjIds,
              });
            }
            if (mention.dataType === DATA_TYPE.GROUP) {
              permissionGroupObjIds[mention.id] = true;
            } else {
              userObjIds[mention.id] = true;
            }
            if (!mention.isDocument && !mention.isShipment) {
              const usersMention = handleAddData({
                id: mention.id,
                permissionGroupsDB: permissionGroups,
                users: users,
              });
              allUserMentions = { ...allUserMentions, ...usersMention };
            }
            if (mention.isDocument) {
              classes = "documentsMentionClass";
            }
          }
          const dataMentionId = mention.id;
          return {
            element: "span",
            attributes: {
              "data-mention-id": dataMentionId,
              "data-mention-type": mention.type || "",
              "data-mention-scope": mention.scope || "",
              "data-note-scope": selectedButton,
              "data-note-version": mention.documentVersion || "",
              "data-note-po": mention.purchaseOrderId || purchaseOrder.id,
              "data-note-shipment":
                mention.shipmentId || currentShipment.id || "",
              "data-original-id": mention.originalId || "",
              class: classes,
            },
            style: {
              cursor: mention.isDocument ? "pointer" : "",
            },
          };
        } else if (entityType.includes("image")) {
          const image = entity.getData();
          const { dataImage, src } = inlineImages(image, imagesInNote);
          imagesInNote.push(src);
          return dataImage;
        }
      },
    };
    const html = stateToHTML(state, options);
    const documents = Object.values(documentsDB).flat(1);
    const {
      folderHasPermission,
      fileHasPermission,
      allowSave,
      usersWithoutDocPermissions,
    } = verifyDocumentsPermissions({
      documentsIds: documentObjIds,
      userMentions: allUserMentions,
      users,
      allDocuments: [...salesOrderDocuments, ...documents],
      permissionGroups,
      purchaseOrders,
      shipments,
      currentUser: user,
      allowed,
    });

    const { usersWithoutPermission } = verifyExistsUserMentionWithouPermission(
      allUserMentions,
      users,
      selectedButton
    );
    if (usersWithoutPermission.length > 0) {
      setDescriptionModalAttention(
        <React.Fragment>
          {getUsersName(usersWithoutPermission)} <br />
          {usersWithoutPermission.length === 1 ? "is" : "are"} not authorized
          for{" "}
          {selectedButton === NOTES_SCOPE.PURCHASE_ORDER
            ? getFactoryName(factories, purchaseOrder.factoryId)
            : getCustomerName(customers, salesOrder.customerId)}
          . <br /> Please remove{" "}
          {usersWithoutPermission.length === 1
            ? getUsersName(usersWithoutPermission)
            : "those names"}{" "}
          to be <br /> able to save this note
        </React.Fragment>
      );
      setModalType(WARNING_MODAL);
      setOpenModalAttention(true);
      return;
    } else {
      let parseHTML = getParseHtml({
        html,
        documentsDB,
        permissionGroups,
        salesOrderDocuments,
        shipments,
        users,
        purchaseOrders,
        documentObjIds,
      });
      const missingUrlList = urlUploaded.filter(
        (url) => !imagesInNote.includes(url)
      );
      deleteUnsaveImages(missingUrlList);
      if (editingNote) {
        const eraseUrlList = oldList.filter(
          (url) => !imagesInNote.includes(url)
        );
        deleteUnsaveImages(eraseUrlList);
      }
      const newSubmitData = {
        mentionsFormat: JSON.stringify(newNotes),
        typeScope: selectedButton,
        editing: editingNote,
        userMentions: allUserMentions,
        detail: parseHTML,
        lockNote: !hasMention ? false : lockNote,
        urlList: imagesInNote,
        id: currentNote.id,
        shipmentObjIds,
        permissionGroupObjIds,
        userObjIds,
        documentObjIds,
        threadId: currentNote.threadId,
        iconIndex: currentNote.iconIndex,
        temporalNote,
      };
      if (!allowSave && hasFlagPermission) {
        setSubmitData(newSubmitData);

        setDocumentError({
          folderHasPermission,
          fileHasPermission,
          usersWithoutDocPermissions,
          isOpen: true,
        });
      } else {
        handleSubmit(newSubmitData);
        setSelectedButton(NOTES_SCOPE.SALES_ORDER);
      }
    }
  }

  function getUserMentions() {
    const currentPermissionGroups = permissionGroups
      .sort(sortObjectsBy("name", false))
      .map((permissionGroup, index) => {
        const { prefix, suffix } = handleUserDisplay({
          id: permissionGroup.id,
          permissionGroups,
          users,
        });
        return {
          name: `!__${permissionGroup.id}__!`,
          prefix,
          suffix,
          id: permissionGroup.id,
          hasBlock: !hasPermission({
            permissionGroup,
            globalPermission,
            permissionToVerify,
            users,
          }),
          lastOne: index === permissionGroups.length - 1,
          dataType: "group",
        };
      });
    const currentUsers = users
      .sort(sortObjectsBy("displayName", false))
      .map((user) => ({
        name: "@" + user.displayName,
        suffix: "@" + user.displayName,
        id: user.id,
        dataType: "user",
        hasBlock: !hasPermission({
          permissionGroup: { ...user, dataType: "user" },
          globalPermission,
          permissionToVerify,
          users,
        }),
      }));
    return [...currentPermissionGroups, ...currentUsers];
  }

  const handleSaveDraft = () => {
    if (isEmpty() || editingNote) {
      if (temporalNote) {
        deleteDoc(temporalNote.ref);
      }
      onCloseModalNotes(false);
    } else {
    }
    setSaveDraftModal(true);
  };

  const getDescriptionLock = () => {
    return (
      <ModalWarningStyled className={"modalStyle"}>
        <span className="On">Everyone in the company</span>
        <GreenSmallSwitch
          onChange={() => setLockNote(!lockNote)}
          checked={lockNote}
          width={155}
          height={23}
          positiveText="Only Tagged"
          negativeText="Everyone"
        />

        <span className="Off">Only people tagged in the note</span>
      </ModalWarningStyled>
    );
  };

  const deleteUnsaveImages = (list = []) => {
    const currentList = list;
    currentList.forEach((url) => {
      deleteObject(ref(storage, url));
    });
    return;
  };

  return (
    <NotesSectionStyled
      className="notesSectionContainer"
      onKeyDown={(ev) => {
        if (documentPermission.id) {
          return;
        }
        if (ev.key === "Escape") {
          if (saveDraftModal) {
            return;
          }
          if (documentError.isOpen) {
            setDocumentError({});
            return;
          }
          if (openModalAttention) {
            setOpenModalAttention(false);
            return;
          }
          handleSaveDraft();
        }
        const auxKey = ev.ctrlKey || ev.metaKey;
        if (ev.key === "Enter" && auxKey && !isEmpty()) {
          handleSaveNote();
        }
      }}
    >
      {documentError.isOpen && (
        <AttentionModal
          isOpen={!!documentError.isOpen}
          description={getDocumentErrorInfo({ ...documentError, users })}
          onClick={() => {
            handleSubmit(submitData);
          }}
          onClose={() => {
            setTimeout(() => {
              setDocumentError({});
            }, 100);
          }}
          title="Attention"
          confirmationText="Leave permissions as they are"
          cancelText="Return to edit note"
          cancellable
          classNameModal="modalPermission"
          bodyClassname="permissionTable"
        />
      )}
      {openModalAttention && (
        <AttentionModal
          isOpen={openModalAttention}
          description={
            modalType === WARNING_MODAL
              ? descriptionModalAttention
              : getDescriptionLock()
          }
          onClick={(ev) => {
            setTimeout(() => {
              setOpenModalAttention(false);
            }, 100);
          }}
          onClose={() => {
            setOpenModalAttention(false);
          }}
          title={
            modalType === WARNING_MODAL ? "Attention" : "Who can see this note?"
          }
          confirmationText={modalType === WARNING_MODAL ? "Ok" : "Done"}
          styleModal={{ width: 532, height: 344 }}
        />
      )}
      {saveDraftModal && (
        <AttentionModal
          isOpen={saveDraftModal}
          description="Do you want to save this note as a draft?"
          blockKeydown
          onClick={() => {
            handleCloseModal({ incompleted: true });
          }}
          onClose={(ev, reason) => {
            if (reason) {
              setTimeout(() => setSaveDraftModal(false), 100);
            } else {
              handleCloseModal({ incompleted: false });
            }
          }}
          title={"Save as draft"}
          confirmationText={"Yes"}
          cancellable
          acceptBlue
        />
      )}

      <Modal
        modalId="modal-notes"
        hasToogle={false}
        isOpen={openModalNotes}
        onClose={(ev) => {
          if (saveDraftModal) {
            return;
          }
          handleSaveDraft(ev);
        }}
        className={cx(`modal-notes-section`, {
          marginMoved: isDocumentOpen && hasFlagPermission,
          marginInit: closed,
        })}
        header={<h2 className="title-note">Add Note</h2>}
        headerStyles={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        modalStyles={{
          padding: "32px 44px 32px 38px",
        }}
        hasCloseIcon={!isDocumentOpen}
      >
        <NoteSectionBodyContainerStyled className="noteSectionBodyContainer">
          {isLoading && <LoadingBackdrop withLogo={true} />}
          {isDocumentOpen && hasFlagPermission && (
            <div
              className={cx("document-note-open", {
                slideRight: isDocumentOpen,
                slideLeft: closed,
              })}
            >
              {!closed && (
                <DocumentsNote
                  companyId={companyId}
                  currentPurchaseOrder={purchaseOrder}
                  currentSalesOrder={salesOrder}
                  user={user}
                  permissionGroupsDB={permissionGroups}
                  purchasesOrderDocuments={documentsDB[poDocumentId]}
                  salesOrderDocuments={salesOrderDocuments}
                  shipmentDocuments={documentsDB[shipmentDocumentId]}
                  documentData={documentData}
                  handleRichDocument={setDocumentData}
                  companyUsers={users}
                  documentPermission={documentPermission}
                  setDocumentPermission={setDocumentPermission}
                  poDocumentId={poDocumentId}
                  shipmentDocumentId={shipmentDocumentId}
                  handlePOId={setPODocumentId}
                  handleShipmentId={setShipmentDocumentId}
                  shipments={shipments}
                  purchaseOrders={purchaseOrders}
                  setDocumentDB={setDocumentDB}
                  documentsDB={documentsDB}
                  closeIcon={
                    <CloseIcon
                      className="note-secction-cancel-icon"
                      onClick={handleSaveDraft}
                      style={{
                        position: "absolute",
                        right: 6,
                        top: 6,
                        height: 24,
                        width: 24,
                      }}
                    />
                  }
                />
              )}
            </div>
          )}

          {isLoading && <LoadingBackdrop />}
          <div className="selector-type">
            <button
              onClick={() => setSelectedButton(NOTES_SCOPE.SALES_ORDER)}
              className={cx(
                "btnStyle",
                getStatusClass({
                  scope: NOTES_SCOPE.SALES_ORDER,
                  selectedButton,
                })
              )}
              disabled={
                (currentNote.threadId || editingNote) &&
                selectedButton !== NOTES_SCOPE.SALES_ORDER
              }
            >
              {getButtonIcon({
                currentNote,
                typeButton: NOTES_SCOPE.SALES_ORDER,
                selectedButton,
                editingNote,
                currentShipment,
              })}
              {salesOrder.type === orderType.SALES_ORDER ? (
                <IntlMessages id="components.purchaseorderdashboard.salesorder" />
              ) : (
                <IntlMessages id="components.purchaseorderdashboard.quote" />
              )}
            </button>
            <button
              onClick={() => setSelectedButton(NOTES_SCOPE.PURCHASE_ORDER)}
              disabled={
                ((currentNote.threadId || editingNote) &&
                  selectedButton !== NOTES_SCOPE.PURCHASE_ORDER) ||
                purchaseOrder.status === PO_STATUS.VOIDED
              }
              className={cx(
                "btnStyle",
                getStatusClass({
                  scope: NOTES_SCOPE.PURCHASE_ORDER,
                  selectedButton,
                })
              )}
            >
              {getButtonIcon({
                currentNote,
                typeButton: NOTES_SCOPE.PURCHASE_ORDER,
                selectedButton,
                editingNote,
                currentShipment,
              })}
              {purchaseOrder.type === orderType.PURCHASE_ORDER ? (
                <IntlMessages id="components.purchaseorderdashboard.purchaseorder" />
              ) : (
                <IntlMessages id="components.purchaseorderdashboard.purchasequote" />
              )}
            </button>
            <button
              onClick={() => setSelectedButton(NOTES_SCOPE.SHIPMENT)}
              disabled={
                ((currentNote.threadId || editingNote) &&
                  selectedButton !== NOTES_SCOPE.SHIPMENT) ||
                !currentShipment.id
              }
              className={cx(
                "btnStyle",
                getStatusClass({
                  scope: NOTES_SCOPE.SHIPMENT,
                  selectedButton,
                })
              )}
              style={{ opacity: !currentShipment.id ? 0.6 : "" }}
            >
              {getButtonIcon({
                currentNote,
                typeButton: NOTES_SCOPE.SHIPMENT,
                selectedButton,
                editingNote,
                currentShipment,
              })}
              {<IntlMessages id="components.purchaseorderdashboard.shipment" />}
            </button>
          </div>
          <RichText
            shipmentMentions={getShipmentMentions({
              shipments: filterShipment,
              typeNote,
            })}
            userMentions={getUserMentions()}
            editorState={editorState}
            setEditorState={setEditorState}
            saveNote={handleSaveNote}
            typeNote={selectedButton}
            setHasMention={setHasMention}
            note={currentNote}
            companyId={companyId}
            setIsLoading={setIsLoading}
            urlUploaded={urlUploaded}
            setUrlUploaded={setUrlUploaded}
            isDocumentOpen={isDocumentOpen}
            documentData={documentData}
            purchaseOrders={purchaseOrders}
            shipments={shipments}
            hasFlagPermission={hasFlagPermission}
            handleDocumentComponent={() => {
              if (isDocumentOpen) {
                setClosed(true);
                setTimeout(() => setIsDocumentOpen(false), 200);
              } else {
                setClosed(false);
                setIsDocumentOpen(true);
              }
            }}
          />
          <FooterNoteSection
            OS={OS}
            handleDelete={() => {
              deleteDoc(currentNote.ref);
              handleCloseModal({ isDeleted: true });
            }}
            editingNote={editingNote}
            handleClick={() => {
              if (!documentPermission.id && !openModalAttention) {
                handleSaveNote();
              }
            }}
            hasMention={hasMention}
            isEmpty={isEmpty}
            lockNote={lockNote}
            handleModalAtention={() => setOpenModalAttention(true)}
            setModalType={setModalType}
          />
        </NoteSectionBodyContainerStyled>
      </Modal>
    </NotesSectionStyled>
  );
}

export default NotesSection;
