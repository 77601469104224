import React, { useState, useEffect } from "react";
import { ResizeTabStyled } from "./styles";
import Replay from "@mui/icons-material/Replay";

function ResizeTab({ direction = "left" }) {
  const [mouseIsDown, setMouseIsDown] = useState(false);

  useEffect(() => {
    const dashboardEl = document.getElementById("project-dashboard-container");
    if (mouseIsDown && dashboardEl) {
      dashboardEl.addEventListener("mousemove", handleMouseMove);
    }
    return () =>
      dashboardEl &&
      dashboardEl.removeEventListener("mousemove", handleMouseMove);
  }, [mouseIsDown]);

  function handleMouseMove(ev) {
    let leftEl, rightEl, auxEl;
    const dashboardEl = document.getElementById("project-dashboard-container");
    const totalWidth = dashboardEl.offsetWidth;
    const mouseOffset = 100;
    if (direction === "left") {
      leftEl = document.getElementById("dashboard-tasks");
      rightEl = document.getElementById("dashboard-activities");
      auxEl = document.getElementById("dashboard-documents");
      if (leftEl && rightEl) {
        const screenPercentage =
          ((ev.pageX -
            dashboardEl.offsetLeft -
            dashboardEl.style.paddingLeft -
            mouseOffset) /
            totalWidth) *
          100;
        const secondWidth =
          100 -
          ((ev.pageX -
            dashboardEl.offsetLeft -
            dashboardEl.style.paddingLeft -
            mouseOffset) /
            totalWidth) *
            100 -
          (auxEl.clientWidth / totalWidth) * 100;
        if (
          (screenPercentage / 100) * totalWidth >= 400 &&
          (screenPercentage / 100) * totalWidth <= totalWidth - 860
        ) {
          leftEl.style.width = screenPercentage + "%";
          rightEl.style.width = secondWidth + "%";
          auxEl.style.minWidth = auxEl.style.width;
          leftEl.style.minWidth = "400px";
        }
        // rightEl.style.width = secondWidth + "%";
        // auxEl.style.minWidth = auxEl.style.width;
        // leftEl.style.minWidth = "400px";
      }
    } else {
      leftEl = document.getElementById("dashboard-documents");
      rightEl = document.getElementById("dashboard-activities");
      auxEl = document.getElementById("dashboard-tasks");
      if (leftEl && rightEl) {
        const screenPercentage =
          100 -
          ((ev.pageX -
            dashboardEl.offsetLeft -
            dashboardEl.style.paddingLeft -
            mouseOffset) /
            totalWidth) *
            100;
        const secondWidth =
          (ev.clientX / totalWidth) * 100 -
          (auxEl.clientWidth / totalWidth) * 100;

        if (
          (screenPercentage / 100) * totalWidth >= 430 &&
          (screenPercentage / 100) * totalWidth <= totalWidth - 830
        ) {
          leftEl.style.width = screenPercentage + "%";
          rightEl.style.width = secondWidth + "%";
          auxEl.style.minWidth = auxEl.style.width;
          leftEl.style.minWidth = "430px";
        }
      }
    }
  }

  function onMouseDown(ev) {
    ev.preventDefault();
    document.getElementById("project-dashboard-container").style.cursor =
      "grabbing";
    setMouseIsDown(true);
    document.addEventListener("mouseup", onMouseUp);
  }
  function onMouseUp(ev) {
    setMouseIsDown(false);
    document.getElementById("project-dashboard-container").style.cursor =
      "default";
    document.removeEventListener("mouseup", onMouseUp);
  }
  function onClick(ev) {
    let leftEl, rightEl, auxEl;
    leftEl = document.getElementById("dashboard-tasks");
    rightEl = document.getElementById("dashboard-activities");
    auxEl = document.getElementById("dashboard-documents");
    leftEl.style.width = "33%";
    rightEl.style.width = "33%";
    auxEl.style.width = "34%";
    leftEl.style.minWidth = "22%";
    rightEl.style.minWidth = "22%";
    auxEl.style.minWidth = "22%";
    ev.stopPropagation();
  }

  return (
    <ResizeTabStyled className="resizeTabContainer">
      <div className={"rotatedObj"}>
        <span onClick={onClick}>
          Reset Section Width
          <Replay className="replayIcon" direction="rtl" />
        </span>
        <img
          onMouseDown={onMouseDown}
          src={require("../../../assets/flag-icons/vert-ham.png")}
          alt=""
        ></img>
      </div>
    </ResizeTabStyled>
  );
}

export default ResizeTab;
