import React from "react";
import { TableCell } from "@mui/material";
import { TableRowStyled } from "./styles";

function RowEmpty({ subHeaders = [], prefix, multiHeaderId = [], filterText }) {
  console.log(filterText);
  const style = !filterText
    ? {
        paddingLeft: 365,
        textAlign: "left",
      }
    : {
        textAlign: "center",
      };
  return (
    <TableRowStyled key={`${prefix}_empty`} className={"body"}>
      <TableCell
        className="emptyCellStyle"
        key={`${prefix}_empty`}
        style={style}
        id="footerCell"
        colSpan={6}
        align={"center"}
      >
        {!filterText
          ? "This table is empty"
          : "The current filter selection does not have any results"}
      </TableCell>
      {multiHeaderId.length > 0 &&
        subHeaders.map((subColumn, index) => (
          <TableCell
            className={"head"}
            key={`${prefix}_subHeader_${(subColumn.number, index)}`}
            style={{
              ...subColumn.columnStyle,
            }}
          />
        ))}
    </TableRowStyled>
  );
}

export default RowEmpty;
