import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TooltipTD from "../Tooltip/TooltipTD";

export function CircularStaticProgress({
  value,
  thickness,
  style,
  size,
  label,
  className,
}) {
  return (
    <TooltipTD
      label={label}
      style={{ position: "relative" }}
      showToolTip={!!label}
      className={className}
    >
      <CircularProgress
        variant="determinate"
        size={size}
        thickness={thickness}
        value={value}
        style={{ ...style, zIndex: 1, position: "inherit" }}
      />
      <CircularProgress
        variant="determinate"
        size={size}
        thickness={thickness}
        value={100}
        style={{ color: "#eeeeee", position: "absolute", left: 0, zIndex: 0 }}
      />
    </TooltipTD>
  );
}
