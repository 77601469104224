import React, { useState } from "react";
import addDocumentIcon from "../../assets/flag-icons/add-document-2.svg";
import uploadDocumentIcon from "../../assets/flag-icons/upload-document-2.svg";
import SearchLabel from "../Inputs/SearchLabel";
import { ManagementDocumentHeaderStyled } from "./styles";
import { SEARCH_BOX_TYPES } from "../../helpers/orderDashboard";
import { colors } from "../../assets/jss/variables";

function ManagementDocumentsHeader({
  handleAddNewFolder,
  handleAddNewFile,
  hasTitle,
  disabled,
  handleExpandOrCollapse,
  isExpand,
  onQuerySearch,
  mainTable,
  mainTableID,
  isVoided,
}) {
  const [cleanSearchBox, setCleanSearchBox] = useState(false);
  return (
    <ManagementDocumentHeaderStyled className="documentsHeader">
      <div>
        <button
          className={"buttonExpandCollapse"}
          onClick={() => handleExpandOrCollapse(!isExpand)}
        >
          {isExpand ? "COLLAPSE ALL" : "EXPAND All"}
        </button>
      </div>
      {hasTitle && (
        <span
          style={{
            color: colors.primaryDark,
            fontSize: 17,
            fontWeight: "bold",
            margin: "0px 8px",
          }}
        >
          {hasTitle ? "Documents" : ""}
        </span>
      )}
      <div className="documentsHeaderButtonAddDocument">
        <SearchLabel
          cookie={mainTableID}
          returnQuery={(query) => {
            onQuerySearch(query);
          }}
          placeholderOnExpand="Search for document name"
          cleanSearchBox={cleanSearchBox}
          onDisableCleanSearchBox={() => setCleanSearchBox(false)}
          styles={{ marginRight: 6 }}
          storageField="documentsearch"
          searchLabelId="management-documents-search-box"
          type={SEARCH_BOX_TYPES.DOCUMENTS}
        />

        <img
          className={"documentsIconAddDocument"}
          onClick={disabled ? null : handleAddNewFolder}
          src={addDocumentIcon}
          alt="add-document"
          height={18}
          width={18}
          style={{ margin: "0px 8px", cursor: isVoided ? "unset" : "" }}
        />
        <img
          className={"documentsIconAddDocument"}
          onClick={disabled ? null : handleAddNewFile}
          src={uploadDocumentIcon}
          alt="add-document"
          width={21}
          style={{ cursor: isVoided ? "unset" : "" }}
        />
      </div>
    </ManagementDocumentHeaderStyled>
  );
}

export default ManagementDocumentsHeader;
