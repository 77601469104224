import { Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { dayHeight, dayWidth } from "../../helpers/constants";
import {
  getPhaseClassName,
  viewOptionLabels,
} from "../../helpers/timelineCalendar";
import { labelTaskPhases } from "../../helpers/timelineModal";
import VerticalIndicator from "./VerticalIndicator";

function CalendarPhase({
  className,
  styles,
  date,
  milestone,
  task,
  verticalIndicator,
  taskOnHover,
  flagViewOptions,
  currentUser,
}) {
  const phaseOffset =
    moment(task.finishDate).diff(moment(task.startDate), "days") + 1;
  const minimiumPhaseDays = {
    [labelTaskPhases.PROPOSAL_QUOTE]: 6,
    [labelTaskPhases.PRE_PRODUCTION]: 6,
    [labelTaskPhases.PRODUCTION]: 5,
    [labelTaskPhases.BOOKING_TRANSIT]: 7,
    [labelTaskPhases.PAYMENT_BILLING]: 7,
  };
  const renderPhase = phaseOffset >= minimiumPhaseDays[task.description];
  return (
    <Paper className={className} style={styles} elevation={0}>
      {!!flagViewOptions[viewOptionLabels.SECTION_DIVIDERS] &&
        renderPhase &&
        moment(date).valueOf() >= task.startDate &&
        moment(date).valueOf() <= task.finishDate && (
          <div
            className={getPhaseClassName(task, date)}
            style={{
              background: "#8D9CB1",
              width: dayWidth,
              height: 3,
              position: "absolute",
            }}
          >
            {getPhaseClassName(task, date) === "middle-phase" && (
              <span
                style={{
                  zIndex: 2,
                  width: 130,
                  height: dayHeight,
                  position: "absolute",
                  background: "transparent",
                  top: -13,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {task.description}
              </span>
            )}
          </div>
        )}
      {milestone && (
        <div
          style={{
            background: milestone.color,
            width: 2,
            height: "100%",
            marginLeft: "auto",
            marginRight: 1,
          }}
        />
      )}
      {verticalIndicator && (
        <VerticalIndicator taskOnHover={taskOnHover} date={date} size={1} />
      )}
    </Paper>
  );
}

export default CalendarPhase;
