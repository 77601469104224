import React from "react";
import taskStages from "../api/types/taskStages";

import {
  ExpensesIcon,
  SalesOrderIcon,
  PurchaseOrderIcon,
  ShipmentOrderIcon,
  ReportsIcon,
  TaskIcon,
  OrdersByPhaseIcon,
  WeekIcon,
  VendorsIcon,
  CustomersIcon,
  SettingsIcon,
} from "./iconSvgPath";

export const GENERAL_PERMISSION_VALUE = {
  PURCHASE_ORDER_AMOUNT: "purchase_order_amount",
  SALES_ORDER_AMOUNT: "sales_order_amount",
  MODIFY_FACTORIES_TEMPLATES: "modify_factory_template",
  MODIFY_DUE_DATES: "modify_due_dates",
  CREATE_SHIPMENTS: "create_shipments",
  SHIPMENT_QUANTITY: "shipment_quantity",
  SHIPMENT_DELIVERY_DATE: "shipment_delivery_date",
  SHIPMENT_SHIP_DATE: "shipment_ship_date",
  CREATE_USERS: "create_users",
  MODIFY_USERS: "modify_users",
  CHANGE_USER_STATUS: "change_user_status",
  SHIPMENT_AMOUNT: "shipment_amount",
  ALL_CUSTOMERS: "all_customers",
  ALL_VENDORS: "all_vendors",
  ORDER_READY_DATE: "order_ready_date",
  RECEIVE_NOTIFICATION_FROM_API: "receive_notification_from_API",
  NOTIFICATION_UPDATE_CREATE_VENDOR_TEMPLATE:
    "notification_update_create_vendor_template",
  REASSIGN_TASK: "reassign_task",
  UN_ASSIGN_TAGS: "un-assign_tags",
  ASSIGN_TAGS: "assign_tags",
  DELETE_TAGS: "delete_tags",
  RENAME_TAGS: "rename_tags",
  CREATE_TAGS: "create_tags",
  SEE_TAGS: "see_tags",
  EDIT_ROLES_NAMES: "edit_roles_names",
  CAN_SEE_ALL_NOTES: "can_see_all_notes",
  CAN_DELETE_VENDOR_FILES_AND_FOLDERS: "can_delete_vendor_files_and_folders",
  CAN_DELETE_CUSTOMER_FILES_AND_FOLDERS:
    "can_delete_customer_files_and_folders",
  CAN_VOID_PURCHASE_ORDERS: "can_void_purchase_orders",
  CAN_VOID_PURCHASE_QUOTES: "can_void_purchase_quotes",
  CAN_DELETE_ORDER_DASHBOARD_FILES_AND_FOLDERS:
    "can_delete_order_dashboard_files_and_folders",
  CAN_ENTER_EXPENSES_MANUALLY: "can_enter_expenses_manually",
  CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP: "can_enter_expenses_via_drag_and_drop",
  CAN_SEE_ALL_EXPENSES: "can_see_all_expenses",
  CAN_SEE_ALL_VENDOR_DOCUMENT: "can_see_all_vendor_documents",
  CAN_SEE_ALL_CUSTOMER_DOCUMENT: "can_see_all_customer_documents",
  CAN_SEE_ALL_DASHBOARD_DOCUMENT: "can_see_all_dashboard_documents",
  CAN_CREATE_CUSTOMER: "can_create_customers",
  CAN_CREATE_VENDORS: "can_create_vendors",
  CAN_CHANGE_VENDOR_PAYMENT_TERMS: "can_change_vendor_payment_terms",
  CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF:
    "can_see_only_expenses_entered_by_self",
  CAN_DELETE_ANY_EXPENSE: "can_delete_any_expense",
  CAN_ONLY_DELETE_EXPENSE_ENTERED_BY_SELF:
    "can_only_delete_expense_entered_by_self",
  CAN_EDIT_ANY_EXPENSE: "can_edit_any_expense",
  CAN_ONLY_EDIT_EXPENSE_ENTERED_BY_SELF:
    "can_only_edit_expense_entered_by_self",
  CAN_EDIT_PO_DISCOUNT_PERCENTAGE: "can_edit_po_discount_percentage",
  //
  REPORTS_VIEW: "reports_view",
  WEEK_VIEW: "week_view",
  SALES_ORDERS_VIEW: "sales_orders_view",
  PURCHASE_ORDERS_VIEW: "purchase_orders_view",
  TASKS_VIEW: "tasks_view",
  SHIPMENTS_VIEW: "shipments_view",
  ORDERS_BY_PHASE_VIEW: "orders_by_phase_view",
  FACTORIES_VIEW: "factories_view",
  CUSTOMERS_VIEW: "customers_view",
  EXPENSES_VIEW: "expenses_view",
  TAGS_VIEW: "tags_view",
  SETTINGS: "settings",
  // settings tabs
  SETTINGS_LABELS: "settings_labels",
  SETTINGS_REPORTS: "settings_reports",
  SETTINGS_USERS: "settings_users",
  SETTINGS_ROLES: "settings_roles",
  SETTINGS_PLACEHOLDER: "settings_placeholder",
  SETTINGS_ACCESS_GROUPS: "settings_access_groups",
  SETTINGS_TEMPLATE: "settings_template",
  SETTINGS_GL_ACCOUNTS: "settings_gl_accounts",
  SETTINGS_PROJECTS: "settings_projects",
  SETTINGS_IMPORTED_DATA: "settings_imported_data",
  SETTINGS_API_DATA: "settings_api_data",
  SETTINGS_API_ERROR_LOG: "settings_api_error_log",
  SETTINGS_API_CONFIGURATION: "settings_api_configuration",
  SETTINGS_BACKEND_ERRORLOG: "settings_backend_errorlog",
};

export const CATEGORY_PERMISSION = {
  SCREEN_ACCESS: "Screen Access",
  GENERAL_PERMISSION: "General Permissions",
  DOLAR_AMOUNT: "Can see dollar amounts $$$",
  TASKS: "Tasks",
  SHIPMENTS: "Shipments",
  EXPENSES: "Expenses",
  LABELS: "Labels",
  NOTIFICATIONS: "Notifications",
  FILES_FOLDER: "Files and Folders",
  OTHER: "Other",
  SETTINGS_PERMISSIONS: "Settings Permission",
};

export const ROLES_PERMISSION_CATEGORY = [
  {
    id: CATEGORY_PERMISSION.SCREEN_ACCESS,
  },
  {
    id: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    categories: [
      CATEGORY_PERMISSION.DOLAR_AMOUNT,
      CATEGORY_PERMISSION.TASKS,
      CATEGORY_PERMISSION.SHIPMENTS,
      CATEGORY_PERMISSION.EXPENSES,
      CATEGORY_PERMISSION.LABELS,
      CATEGORY_PERMISSION.NOTIFICATIONS,
      CATEGORY_PERMISSION.FILES_FOLDER,
      CATEGORY_PERMISSION.OTHER,
    ],
  },
];

// TO DO

export const featureFlagNames = {
  TIMELINE_V1: "timeline_v1",
  TIMELINE_V2: "timeline_v2",
  SPLIT_SHIPMENTS_V1: "split_shipments_v1",
  EXPENSES_V1: "Expenses v1",
  DOCUMENT_IN_NOTES: "documents_notes_v1",
  THREAD_NOTES: "thread_in_notes",
  ORDER_DASHBOARD_V2: "order_dashboard_v2",
};

export const COMPANY_PERMISSION_VALUE = {
  SALES_ORDER_QUANTITY: "sales_order_quantity",
  SALES_ORDER_PRICE: "sales_order_price",
  PURCHASE_ORDER_QUANTITY: "purchase_order_quantity",
  PURCHASE_ORDER_PRICE: "purchase_order_price",
  SHIPMENT_QUANTITY: "shipment_quantity",
  CUSTOMER_PO: "customer_po",
  SALES_REP: "sales_rep",
  SHIP_BY_DATE: "ship_by_date",
  ORDER_READY_DATE: "order_ready_date",
};

export const FONTSIZE_NOTES = {
  NORMAL: 14,
  MEDIUM: 20,
  LARGE: 27,
  HUGE: 36,
  MENTION: 13,
};

export const FONTSIZE_ACTIVITY_ENTRY = {
  NORMAL: 14,
  MEDIUM: 20,
  LARGE: 27,
  HUGE: 36,
};

export const FONT_FAMILY_NOTES = "monospace";

export const screenPermissionLabels = [
  {
    label: "Reports",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.REPORTS_VIEW,
    icon: <ReportsIcon height={24} width={24} />,
  },
  {
    label: "Sales Orders",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
    icon: <SalesOrderIcon height={24} width={24} />,
  },
  {
    label: "Purchase Orders",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.PURCHASE_ORDERS_VIEW,
    icon: <PurchaseOrderIcon height={24} width={24} />,
  },
  {
    label: "Tasks",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.TASKS_VIEW,
    icon: <TaskIcon height={24} width={24} />,
  },

  {
    label: "Shipments",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
    icon: <ShipmentOrderIcon height={24} width={24} />,
  },
  {
    label: "Order By Phase",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.ORDERS_BY_PHASE_VIEW,
    icon: <OrdersByPhaseIcon height={24} width={24} />,
  },
  {
    label: "Week",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.WEEK_VIEW,
    icon: <WeekIcon height={24} width={24} />,
  },

  {
    label: "Vendors",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.FACTORIES_VIEW,
    icon: <VendorsIcon height={24} width={24} />,
  },
  {
    label: "Customers",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.CUSTOMERS_VIEW,
    icon: <CustomersIcon height={24} width={24} />,
  },

  {
    label: "Expenses",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.EXPENSES_VIEW,
    permissionFlag: featureFlagNames.EXPENSES_V1,
    icon: <ExpensesIcon height={24} width={24} />,
  },
  {
    label: "Settings",
    category: CATEGORY_PERMISSION.SCREEN_ACCESS,
    value: GENERAL_PERMISSION_VALUE.SETTINGS,
    icon: <SettingsIcon height={24} width={24} />,
    tabs: [
      {
        label: "Labels",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_LABELS,
      },
      {
        label: "Reports",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_REPORTS,
      },
      {
        label: "Users",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_USERS,
      },
      {
        label: "Roles",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_ROLES,
      },
      {
        label: "Document Placeholders",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_PLACEHOLDER,
      },
      {
        label: "Access Groups",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_ACCESS_GROUPS,
      },
      {
        label: "Company Template",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_TEMPLATE,
      },
      {
        label: "G/L Accounts",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_GL_ACCOUNTS,
      },
      { label: "Projects", value: GENERAL_PERMISSION_VALUE.SETTINGS_PROJECTS },
      {
        label: "Imported Data",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_IMPORTED_DATA,
      },
      {
        label: "API Imported Data",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_API_DATA,
      },
      {
        label: "API Error Log",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_API_ERROR_LOG,
      },
      {
        label: "API Settings",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_API_CONFIGURATION,
      },
      {
        label: "Back-End Error Log",
        value: GENERAL_PERMISSION_VALUE.SETTINGS_BACKEND_ERRORLOG,
      },
    ],
  },
];

export const permissionsLabels = [
  {
    label: "Sales Ordes/Quotes",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.DOLAR_AMOUNT,
    value: GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT,
  },
  {
    label: "PO",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.DOLAR_AMOUNT,
    value: GENERAL_PERMISSION_VALUE.PURCHASE_ORDER_AMOUNT,
  },
  {
    label: "Shipment",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.DOLAR_AMOUNT,
    value: GENERAL_PERMISSION_VALUE.SHIPMENT_AMOUNT,
  },
  // ---
  {
    label: "Modify due date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.TASKS,
    value: GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES,
  },
  {
    label: "Reassign to another person",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.TASKS,
    value: GENERAL_PERMISSION_VALUE.REASSIGN_TASK,
  },
  // ---
  {
    label: "Create shipments",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.SHIPMENTS,
    value: GENERAL_PERMISSION_VALUE.CREATE_SHIPMENTS,
  },
  {
    label: "Modify item quantities",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.SHIPMENTS,
    value: GENERAL_PERMISSION_VALUE.SHIPMENT_QUANTITY,
  },
  {
    label: "Modify shipment delivery date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.SHIPMENTS,
    value: GENERAL_PERMISSION_VALUE.SHIPMENT_DELIVERY_DATE,
  },
  {
    label: "Modify shipment ship date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.SHIPMENTS,
    value: GENERAL_PERMISSION_VALUE.SHIPMENT_SHIP_DATE,
  },
  // --
  {
    label: "Enter expenses manually",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_MANUALLY,
  },
  {
    label: "Enter expenses via drag-and-drop",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_ENTER_EXPENSES_VIA_DRAG_AND_DROP,
  },
  {
    label: "See all expenses",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_EXPENSES,
  },
  {
    label: "See only expenses entered by self",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ONLY_EXPENSES_ENTERED_BY_SELF,
  },
  {
    label: "Edit any expense",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_EDIT_ANY_EXPENSE,
  },
  {
    label: "Only edit expenses entered by self",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_ONLY_EDIT_EXPENSE_ENTERED_BY_SELF,
  },
  {
    label: "Delete any expense",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_DELETE_ANY_EXPENSE,
  },
  {
    label: "Only delete expenses entered by self",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.EXPENSES,
    value: GENERAL_PERMISSION_VALUE.CAN_ONLY_DELETE_EXPENSE_ENTERED_BY_SELF,
  },
  // --
  {
    label: "Create",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.CREATE_TAGS,
  },
  {
    label: "Rename",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.RENAME_TAGS,
  },
  {
    label: "Delete",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.DELETE_TAGS,
  },
  {
    label: "Assign",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.ASSIGN_TAGS,
  },
  {
    label: "Un-assign",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.UN_ASSIGN_TAGS,
  },
  {
    label: "See labels",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.LABELS,
    value: GENERAL_PERMISSION_VALUE.SEE_TAGS,
  },
  // --
  {
    label: "Update vendor template",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.NOTIFICATIONS,
    value: GENERAL_PERMISSION_VALUE.NOTIFICATION_UPDATE_CREATE_VENDOR_TEMPLATE,
  },
  {
    label: "API errors",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.NOTIFICATIONS,
    value: GENERAL_PERMISSION_VALUE.RECEIVE_NOTIFICATION_FROM_API,
  },

  // --
  {
    label: "Delete - vendor",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_DELETE_VENDOR_FILES_AND_FOLDERS,
  },
  {
    label: "Delete - customer",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_DELETE_CUSTOMER_FILES_AND_FOLDERS,
  },
  {
    label: "Delete - Order Dashboard",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value:
      GENERAL_PERMISSION_VALUE.CAN_DELETE_ORDER_DASHBOARD_FILES_AND_FOLDERS,
  },

  {
    label: "See all - vendor documents",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_VENDOR_DOCUMENT,
  },
  {
    label: "See all - customer documents",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_CUSTOMER_DOCUMENT,
  },
  {
    label: "See all - Order Dashboard documents",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.FILES_FOLDER,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT,
  },
  // --
  {
    label: "Can void Purchase Orders",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_ORDERS,
  },
  {
    label: "Can void Purchase Quotes",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_VOID_PURCHASE_QUOTES,
  },
  {
    label: "See all activity stream notes",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_NOTES,
  },
  {
    label: "Edit vendor template",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.MODIFY_FACTORIES_TEMPLATES,
  },

  {
    label: "Modify Order Ready Date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.ORDER_READY_DATE,
  },
  {
    label: "Can edit roles names",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.EDIT_ROLES_NAMES,
  },
  {
    label: "Can create new users",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CREATE_USERS,
  },

  {
    label: "Can edit users",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.MODIFY_USERS,
  },

  {
    label: "Can activate/deactivate users",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CHANGE_USER_STATUS,
  },
  {
    label: "Can edit PO discount",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_EDIT_PO_DISCOUNT_PERCENTAGE,
  },

  {
    label: "Can create customers",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_CREATE_CUSTOMER,
  },
  {
    label: "Can create vendors",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_CREATE_VENDORS,
  },
  {
    label: "Can change vendor payment terms",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    subCategory: CATEGORY_PERMISSION.OTHER,
    value: GENERAL_PERMISSION_VALUE.CAN_CHANGE_VENDOR_PAYMENT_TERMS,
  },
];

export const CompanyPermissionsData = [
  //item table
  {
    prefix: "Can modify:",
    label: "Sales Order quantity",
    value: COMPANY_PERMISSION_VALUE.SALES_ORDER_QUANTITY,
  },
  {
    prefix: "Can modify:",
    label: "Sales Order price",
    value: COMPANY_PERMISSION_VALUE.SALES_ORDER_PRICE,
  },
  {
    prefix: "Can modify:",
    label: "Purchase Order quantity",
    value: COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_QUANTITY,
  },
  {
    prefix: "Can modify:",
    label: "Purchase Order price",
    value: COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_PRICE,
  },
  {
    prefix: "Can modify:",
    label: "Shipment quantity",
    value: COMPANY_PERMISSION_VALUE.SHIPMENT_QUANTITY,
  },
  {
    prefix: "Can modify:",
    label: "Customer PO",
    value: COMPANY_PERMISSION_VALUE.CUSTOMER_PO,
  },
  {
    prefix: "Can  modify:",
    label: "Order Ready Date",
    category: CATEGORY_PERMISSION.GENERAL_PERMISSION,
    value: COMPANY_PERMISSION_VALUE.ORDER_READY_DATE,
  },
  {
    prefix: "Can modify:",
    label: "Sales Rep",
    value: COMPANY_PERMISSION_VALUE.SALES_REP,
  },
  {
    prefix: "Can modify:",
    label: "Ship By Date",
    value: COMPANY_PERMISSION_VALUE.SHIP_BY_DATE,
  },
];

export const screenHeight = "calc(100vh - 98px)";
export const topScreen = 21;

export const searchableProperties = [
  "number",
  "customer",
  "factory",
  "loadingPort",
  "unloadingPort",
  "finalDestination",
  "billOfLading",
  "shippingTerms",
  "freightForwarder",
  "container",
  "booking",
  "assignedToName",
  "customerName",
  "factoryName",
  "purchaseOrderNumber",
  "salesOrderNumber",
  "description",
];

export const TASK_TYPE = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
};

export const typeModalPermissions = {
  FILE_ROOT_UPLOAD: "FILE_ROOT_UPLOAD",
  FILE_CHILD_UPLOAD: "FILE_CHILD_UPLOAD",
  VIEW_PERMISSIONS: "FILE_VIEW_PERMISSIONS",
};

export const notAllowedToSee = "********";

export const headerColumns = {
  FAVORITE: "favorite",
  DIAMOND: "diamond",
  PROGRESS: "progress",
  MENTIONS: "mentions",
  PO: "PO",
  SO: "SO",
  SOs: "Sales Orders",
  CUSTOMER: "Customer",
  PO_DATE: "PO Date",
  PO_SHIP_DATE: "PO Ship Date",
  PO_DELIVERY_DATE: "PO Delivery Date",
  VENDOR: "Vendor",
  PO_VALUE: "PO Value",
  DEPOSIT: "Deposit",
  BALANCE: "Balance",
  POTENTIAL_LATE_PENALTY: "Potential Late Penalty",
  POTENTIAL_LATE_FEE: "Potential Late Fee",
  CBM: "CBM",
  SHIPPING_TERMS: "Shipping Terms",
  LOADING_PORT: "Loading Port",
  UNLOADING_PORT: "Unloading Port",
  FINAL_DESTINATION: "Final Destination",
  EXTRA: "Extra",
  POS: "POs",
  SO_DATE: "Document Date",
  SHIP_DATE: "Ship Date",
  DELIVERY_DATE: "Delivery Date",
  VALUE: "Value",
  BOOKING: "Booking",
  BILL_OF_LADING: "Bill Of Lading",
  CONTAINER: "Container",
  FREIGHT_FORWARDER: "Freight Forwarder",
  INVOICE: "Invoice",
  CUSTOMER_PO: "Customer PO",
  SALES_RESP: "Sales Rep.",
  TASK: "Task",
  ASSIGNED_TO: "Assigned To",
  START_DATE: "Start Date",
  DUE_DATE: "Due Date",
  ACTION: "Action",
  PROPOSAL_QUOTE: "Proposal/Quote",
  PRE_PRODUCTION: "Pre Production",
  PRODUCTION: "Production",
  BOOKING_TRANSIT: "Booking & Transit",
  PAYMENT_BILLING: "Payment & Billing",
  SALES_ORDER: "Sales Order",
  TOTAL: "Total",
  SO_SHIP_DATE: "SO Ship Date",
  EXPAND: "Expand",
  SETTINGS: "Settings",
  SHIPMENT: "Shipment",
  BOL: "BOL",
  TOTAL_VALUE: "Total Value",
  SHIPPING_DOCUMENTS: "Shipping Documents",
  SHIPMENTS: "Shipments",
  SHIP_BY_DATE: "Ship by Date",
  TOTAL_SHIPMENTS: "Total Shipments",
  UNIQUE_ITEMS: "Unique Items",
  VENDOR_INVOICE: "Vendor Invoice",
  CREATION_DATE: "Created Date",
  ORDER_READY_DATE: "Order Ready Date",
  DOCUMENT_DATE: "Document Date",
  REQUIRED_DELIVERY: "Required Delivery",
  DISCOUNT: "Discount",
  DISCOUNT_PERCENTAGE: "Discount Percentage",
  SUB_TOTAL: "Subtotal",
  SALES_INVOICE: "Sales Invoice",
  CUSTOMER_INVOICE: "Invoice to Customer",
  TOTAL_WEIGHT: "Total Weight",
  SHIPMENT_VALUE: "Shipment Value",
  REFERENCE_NUMBER: "Reference Number",
  PAID_TO: "Paid To",
  ITEM_TYPE: "Item Type",
  ORDER_NUMBER: "Number",
  GL_ACCOUNT: "G/L Account",
  DESCRIPTION: "Description",
  NOTES: "Notes",
  FORECAST: "Forecast",
  ACTUAL: "Actual",
  DELTA: "%Δ",
  ED_TOOL: "ED Tool",
  EXPORTED_DATE: "Exported Date",
  SUPPLIER: "Supplier",
  PO_ORDER_READY_DATE_VENDOR: "PO - Order Ready Date - Vendor",
};

export const generalPermissions = {
  SALES_ORDER_AMOUNT: GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT,
  PURCHASE_ORDER_AMOUNT: "purchase_order_amount",
  SHIPMENT_AMOUNT: "shipment_amount",
};

export const featureFlagNamesArr = [
  featureFlagNames.TIMELINE_V1,
  featureFlagNames.TIMELINE_V2,
  featureFlagNames.SPLIT_SHIPMENTS_V1,
  featureFlagNames.EXPENSES_V1,
  featureFlagNames.DOCUMENT_IN_NOTES,
  featureFlagNames.THREAD_NOTES,
  featureFlagNames.ORDER_DASHBOARD_V2,
];

export const dayWidth = 27;
export const dayHeight = 32;
export const expandedTaskTableColumns = 34;
export const simpleTaskTableColumns = 23;
export const collapsedTaskTableColumns = 5;

export const taskTableHeader = {
  STATUS: "STA.",
  REF: "REF.",
  PO: "PO",
  DESCRIPTION: "TASK NAME",
  PREDECESOR: "PRED.",
  DEPENDENCY_TYPE: "DEP.",
  DURATION: "DUR.",
  START_DATE: "START DATE",
  END_DATE: "END DATE",
  ASSIGNED_TO: "ASSIGNED TO",
};

export const typeTaskTemplateError = {
  CIRCULAR_DEPENDENCY_PO: "Circular dependency in PO",
  CIRCULAR_DEPENDENCY_SO: "Circular dependency in SO",
  TASK_DEPENDS_ON_ITSELF: "Task depends on itself",
  THERE_IS_NO_DEPENDENCY_TASK: "There is no dependency task",
  THERE_IS_NO_LEAF_TASK: "There is no leaf task",
  SUCCESS: "success",
};

export const snackbarStatus = {
  WARNING: "warning",
  ERROR: "error",
  SUCCESS: "success",
};

export const taskStatus = {
  LATE: "late",
  NEAR_DUE: "nearDue",
  IN_PROGRESS: "inProgress",
  NOT_STARTED: "notStarted",
  COMPLETE: "complete",
};

export const userFields = {
  PERMISION_GROUPS: "permissionGroups",
  DISPLAY_NAME: "displayName",
  CELL_PHONE: "cellPhone",
  EMAIL: "email",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  ROLE: "role",
  TYPE_OF_USER: "typeOfUser",
  AVATAR: "avatar",
  TIMEZONE: "timezone",
};

export const reportFields = {
  NAME: "name",
  DESCRIPTION: "description",
  REPORT_EMBED: "reportEmbed",
  LOOM_EMBED: "loomEmbed",
  permissions: "permissions",
  URL_TO_UPDATE_REPORT: "URLToUpdateReport",
  URL_TO_DOWNLOAD_REPORT: "URLToDownloadReport",
  NOTES: "notes",
};

export const customerVendorFields = {
  NAME: "name",
  NUMBER: "number",
};

export const GLAccountFields = {
  NAME: "name",
  DESCRIPTION: "description",
  ACTIVE: "active",
};

export const EXPENSES_HEADER_LABELS = {
  DATE: "Date",
  PAID_TO: "Supplier",
  GL_ACCOUNT: "G/L Account",
  DESCRIPTION_OF_CHARGES: "Description of Charges",
  NOTES: "Notes",
  FORECAST: "Forecast",
  ACTUAL: "Actual",
};

export const statusBlockAuth = {
  BLOCK_COMPANY: "blockCompany",
  BLOCK_USER: "blockUser",
};

export const typeOfUser = [
  { value: "ART_DEPT", label: "Art Dept" },
  { value: "FINANCE_ACCOUNTING", label: "Finance/Accounting" },
  { value: "LOGISTICS", label: "Logistics" },
  { value: "MANAGEMENT", label: "Management" },
  { value: "SALES", label: "Sales" },
  { value: "SUPPLY_CHAIN_OPERATIONS", label: "Supply Chain Operations" },
  { value: "WAREHOUSE", label: "Warehouse" },
  { value: "SEPARATOR", label: "" },
  { value: "OTHERS", label: "Other " },
];

export const errorDocumentName = {
  folder: `The character “/” is not allowed to be part of a folder name`,
  file: `The character “/” is not allowed to be part of a file name`,
};

export const regexEmailValidator =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regexDecimal = /^([0-9]+([.][0-9]{0,2})?|[.][0-9]{1,2})$/;
export const regexInteger = /^[0-9]+$/;

export const direction = {
  LEFT: "left",
  RIGHT: "right",
};

export const BACKDROP_TYPE = {
  SHIPMENT_INFO: "shipmentInfo",
  TASK: "task",
  NOTE: "note",
  SALES_ORDER_INFO: "salesOrderInfo",
  PURCHASE_ORDER_INFO: "purchaseOrderInfo",
  SHIPMENT_FOLDER: "folderShipment",
  FOLDER: "folder",
  ITEM_TABLE_PO: "itemTablePO",
  MENTION: "message",
  BADGE_NUMBER: "badge-notification-number",
};

export const CHANGE_TABLE_TYPE = {
  SIMPLE_SELECT: "select",
  SIMPLE_LABEL: "label",
  SALES_ORDER_SELECT: "SALES_ORDER_SELECT",
  SHIPMENT_SELECT: "SHIPMENT_SELECT",
  SALES_ORDER_LABEL: "SALES_ORDER_LABEL",
  SHIPMENT_LABEL: "SHIPMENT_LABEL",
};

export const PREFIX_ITEM_TABLE = {
  SHIPMENT: "shipment",
  PURCHASE_ORDER: "purchaseOrder",
  SALES_ORDER: "salesOrder",
};

export const TYPE_OF_TAGS = {
  REGULAR_TAG: "REGULAR_TAG",
  MISMATCH_TAG: "QUANTITY_MISMATCH_TAG",
  FILE_TAG: "FILE_PLACEHOLDER_TAG",
  DEADLINE_TAG: "DEADLINE_ADJUSTMENT_TAG",
  EXPENSES_TAG: "EXPENSES_TAG",
};
export const LABEL_INFO = "System Labels";

export const AUTOMATIC_SYSTEM_LABEL = {
  SALES_ORDER_MISMATCH: "Sales Order ↔ PO Quantity Mismatch",
  SHIPMENT_MISMATCH: "Shipment Quantity Mismatch",
  TASK_DEADLINE: "Task Deadline Adjustment",
  SALES_ORDER_FILE: "Sales Order Pending Files",
  PURCHASE_ORDER_FILE: "PO Pending Files",
  SHIPMENT_FILE: "Shipment Pending Files",
  NO_FORECASTED_EXPENSES_ASSIGNED: "No Forecasted Expenses Assigned",
  NO_ACTUAL_EXPENSES_ASSIGNED: "No Actual Expenses Assigned",
  FORECASTED_EXPENSES_ASIGNED: "Forecasted Expenses Assigned",
  ACTUAL_EXPENSES_ASIGNED: "Actual Expenses Assigned",
};

export const MISMATCH_STATUS = {
  NEW: "new",
  INCOMPLETE: "incomplete",
};

export const itemTableReference = {
  SHIPMENTS: "SHIPMENTS",
  SALES_ORDERS: "SALES_ORDERS",
  ITEM: "ITEM",
  MAIN_SHIPMENT: "MAIN_SHIPMENT",
  MAIN_SALES_ORDER: "MAIN_SALES_ORDER",
};

export const ITEM_TABLE_ACTION = {
  ASSIGNED_SHIPMENT: "ASSIGNED_SHIPMENT",
  CREATED_SHIPMENT: "CREATED_SHIPMENT",
};

export const ACTIVITIES_STREAM_STATUS = {
  NEW: "new",
  UPDATED: "update",
  ERASE: "erase",
};

export const ACTIVITIES_STREAM_TYPE = {
  NEW_ITEM_SHIPMENT: "ADD_SHIPMENT",
  UPDATED_ITEM_SHIPMENT: "CHANGE_ITEM_SHIPMENT",
  NOTE: "NOTE",
  REASSIGNED_TASK: "REASSIGNED_TASK",
};

export const PO_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VOIDED: "VOIDED",
  REASSIGNED: "REASSIGNED",
};

export const PO_DIAMOND_STATUS = {
  LATE: "LATE",
  NEAR_DUE: "NEAR_DUE",
};

export const SALES_ORDER_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VOIDED: "VOIDED",
};

export const SALES_ORDER_DIAMOND_STATUS = {
  LATE: "LATE",
  NEAR_DUE: "NEAR_DUE",
};

export const SHIPMENT_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VOIDED: "VOIDED",
};

export const SHIPMENT_DIAMOND_STATUS = {
  LATE: "LATE",
  NEAR_DUE: "NEAR_DUE",
};

export const TOOLTIP_TYPE = {
  DIAMOND: "diamond",
  FILTER: "filter",
  CALENDAR_HEADER: "calendarHeader",
  BADGE: "badge",
  TAGS: "tags",
  DEFAULT: "default",
  BUTTON_FILTER: "buttonFilter",
  ICON: "icon",
  TASK: "task",
  EXPANDBADGE: "expandBadge",
};

export const TASK_MOVED_STATUS = {
  WAITING: "waiting",
};

export const NOTES_SCOPE = {
  SALES_ORDER: "SON",
  PURCHASE_ORDER: "PON",
  SHIPMENT: "SHIPMENT",
};

export const TASK_FILTER = {
  ALL: "ALL",
  MINE: "MINE",
};

export const STAGE_LIST = [
  taskStages.PROPOSAL,
  taskStages.PRE_PRODUCTION,
  taskStages.PRODUCTION,
  taskStages.BOOKING_TRANSIT,
  taskStages.PAYMENT_BILLING,
];

export const RICH_STYLE = {
  BOLD: "BOLD",
  ITALIC: "ITALIC",
  UNDERLINE: "UNDERLINE",
  ORDER_LIST: "ordered-list-item",
  UNORDER_LIST: "unordered-list-item",
};

export const NOTIFICATION_SCOPE = {
  SO_MENTION: "SO_MENTION",
  PO_MENTION: "PO_MENTION",
  SHIPMENT_MENTION: "SHIPMENT_MENTION",
  SO_TASK_CREATED: "SO_TASK_CREATED",
  PO_TASK_CREATED: "PO_TASK_CREATED",
  SHIPMENT_TASK_CREATED: "SHIPMENT_TASK_CREATED",
  SO_TASK_LATE: "SO_TASK_LATE",
  PO_TASK_LATE: "PO_TASK_LATE",
  SHIPMENT_TASK_LATE: "SHIPMENT_TASK_LATE",
  SO_TASK_EARLY: "SO_TASK_EARLY",
  PO_TASK_EARLY: "PO_TASK_EARLY",
  SHIPMENT_TASK_EARLY: "SHIPMENT_TASK_EARLY",
  API_ERROR_LOG: "API_ERROR_LOG",
  TRADEDASH_WARNING_NOTIFICATION: "TRADEDASH_WARNING_NOTIFICATION",
};

export const TYPE_OF_FILE = {
  FOLDER: "folder",
  FILE: "file",
};

export const TYPE_OF_AUTOCOMPLETE_LISTS = {
  PAID_TO: "PAID_TO",
  LOADING_PORT: "LOADING_PORT",
  UNLOADING_PORT: "UNLOADING_PORT",
};

export const TYPE_NOTIFICATION = {
  DIRECT_MESSAGE: "directMessage",
  ADHOC_TASK: "adHocTask",
  COMPLETE_EARLY: "completeEarly",
  COMPLETE_LATE: "completeLate",
};

export const FILTER_NOTIFICATION = {
  ALL: "ALL",
  DMs: "DMs",
  AD_HOC_TASK: "AD_HOC_TASK",
  COMPLETE_EARLY: "COMPLETE_EARLY",
  COMPLETE_LATE: "COMPLETE_LATE",
};

export const BY_FILTER_NOTIFICATION = {
  [FILTER_NOTIFICATION.DMs]: TYPE_NOTIFICATION.DIRECT_MESSAGE,
  [FILTER_NOTIFICATION.AD_HOC_TASK]: TYPE_NOTIFICATION.ADHOC_TASK,
  [FILTER_NOTIFICATION.COMPLETE_EARLY]: TYPE_NOTIFICATION.COMPLETE_EARLY,
  [FILTER_NOTIFICATION.COMPLETE_LATE]: TYPE_NOTIFICATION.COMPLETE_LATE,
};

export const notesFilters = {
  ALL: "ALL",
  ACTIVITY: "ACTIVITY",
  NOTES: "NOTES",
  MENTIONS: "MENTIONS",
  THREADS: "Threads",
};

export const excludeElementsScreen = [
  "#soDate",
  "#shipDate",
  "#startDate",
  "#dueDate",
  "#createDate",
  "#deliveryDate",
  "#exportedDate",
  "#total",
  "#weight",
  "#subtotal",
  "#deposit",
  "#balance",
  "#CBM",
  "#totalShipment",
  "#totalPO",
  "#uniqueItems",
  "#discount",
  "#percentage",
  "#lateFee",
  "#orderDate",
  "#poDate",
  "#actionButton",
  "#headerCell",
  "#taskSize",
  "#viewLess",
  "#viewAll",
  "#lateDays",
  "#actualExpanded",
  "#deltaExpanded",
  "#forecastExpanded",
  "#tabCards > *",
  "#title > *",
  "#taskSize > *",
  "#dateSeparator > *",
  "#headerCell > *",
  "#emptyMessage > *",
  "#footerCell > *",
  "#forecast > *",
  "#actual > *",
  "#delta > *",
];

export const LOCAL_STORAGE_KEY = {
  projectTasks: "projectTasks",
};

export const ROUTES_PERMISSION = {
  "/app/reports": {
    permissionId: GENERAL_PERMISSION_VALUE.REPORTS_VIEW,
  },
  "/app/sales_orders": {
    permissionId: GENERAL_PERMISSION_VALUE.SALES_ORDERS_VIEW,
  },
  "/app/purchase_orders": {
    permissionId: GENERAL_PERMISSION_VALUE.PURCHASE_ORDERS_VIEW,
  },
  "/app/tasks": {
    permissionId: GENERAL_PERMISSION_VALUE.TASKS_VIEW,
  },
  "/app/shipments": {
    permissionId: GENERAL_PERMISSION_VALUE.SHIPMENTS_VIEW,
  },
  "/app/orders_by_phase": {
    permissionId: GENERAL_PERMISSION_VALUE.ORDERS_BY_PHASE_VIEW,
  },
  "/app/week": {
    permissionId: GENERAL_PERMISSION_VALUE.WEEK_VIEW,
  },
  "/app/factories": {
    permissionId: GENERAL_PERMISSION_VALUE.FACTORIES_VIEW,
  },
  "/app/customers": {
    permissionId: GENERAL_PERMISSION_VALUE.CUSTOMERS_VIEW,
  },
  "/app/expenses": {
    permissionId: GENERAL_PERMISSION_VALUE.EXPENSES_VIEW,
    permissionFlag: featureFlagNames.EXPENSES_V1,
  },
  "/app/admin": {
    alwayShow: true,
  },
  "/app/dashboard": {
    alwayShow: true,
  },
};
