import React, { useState, useEffect, useRef, useCallback } from "react";
import { SOListContainer, SOTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter, {
  BUTTON_FILTER_BOX_SHADOW,
} from "../../components/PurchaseOrderList/ButtonFilter";
import {
  useCompanyId,
  useJSONLocalStorage,
  useFavorites,
  useFavoritesList,
} from "../../hooks";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import { debounce, getTheDate, sortObjectsBy } from "../../helpers/helpers";
import moment from "moment";
import { useIsAllowedFunction } from "../../hooks/permissions";
import SearchBox from "../../components/SearchBox/SearchBox";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import numeral from "numeral";
import FooterTable from "../../components/Tables/FooterTable";
import { footerSalesOrderList } from "../../components/Managers/FooterTableManager";
import { salesOrderHeaderCells } from "../../components/Managers/TableHeaderProvider";
import {
  getListValuesFromArray,
  getToolTipArrayValue,
} from "../../helpers/purchaseOrderList";
import { filterList, orderTypeSort } from "../../helpers/salesOrderHelpers";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import BasicPagination from "../../components/Tables/BasicPagination";
import {
  PO_STATUS,
  excludeElementsScreen,
  generalPermissions,
  headerColumns,
} from "../../helpers/constants";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import { useUser, useUserCustomSetting } from "../../hooks/user";
import { useCustomers } from "../../hooks/customers";
import { useFactories } from "../../hooks/factories";
import { useTags } from "../../hooks/tags";
import { CalendarIcon, StarIcon } from "../../helpers/iconSvgPath";
import { getStatusAndDiamondStatus } from "../../helpers/screens";
import { firestore, performanceFirebase } from "../../firebase";
import { getUserAvatar } from "../../helpers/users";
import AttentionModal from "../../components/Modal/AttentionModal";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { trace } from "firebase/performance";
import { doc, updateDoc } from "firebase/firestore";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import StatusOrderButtons from "../../components/PurchaseOrderList/StatusOrderButtons";
import ToolsButtonScreen from "../../components/PurchaseOrderList/ToolsButtonScreen";
import { orderType } from "../../helpers/salesOrder";
import Mark from "mark.js";
import { useFeatureFlags } from "../../hooks/featureFlags";
import SalesOrdersTableRow from "./SalesOrdersTableRow";
import { useMediaQuery } from "@mui/material";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import { cx } from "@emotion/css";
import HeaderTableDate from "./HeaderTableDate";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { isEqual } from "lodash";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import LateNearDueDiamondButtons from "../../components/PurchaseOrderList/LateNearDueDiamondButtons";

const dateFields = [
  "Created Date",
  "Document Date",
  "Ship by Date",
  "Required Delivery",
];
const hiddenStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
};

const BODY_ID = "salesOrderTableRow";

function SalesOrdersScreen() {
  const matches = useMediaQuery("(max-width:1460px)");
  const screenMidSize = useMediaQuery("(max-width:1600px)");
  const dateRef = useRef(null);
  const { get, set } = useJSONLocalStorage("soListFilters");
  const { get: getSalesOrderSetup, set: setSalesOrderSetup } =
    useJSONLocalStorage("salesOrdersScreen");
  const [currentSOSetup, setCurrentSOSetup] = useState(
    getSalesOrderSetup() || {}
  );
  const dataLocalStorage = get() || {};
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const companyId = useCompanyId();
  const favorites = useFavoritesList({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const favoritesDB = useFavorites({ dbTable: dbTables.SALES_ORDER_FAVORITES });
  const [loading, setLoading] = useState(false);
  const isAllowed = useIsAllowedFunction();
  const customers = useCustomers();
  const factories = useFactories();
  const tags = useTags();
  const [filters, setFilters] = useState({
    factory: dataLocalStorage.factory || [],
    customer: dataLocalStorage.customer || [],
    tag: dataLocalStorage.tag || [],
    dateRange: dataLocalStorage.dateRange || "",
    date: dataLocalStorage.date || "",
    inProgress: !get() ? true : dataLocalStorage.inProgress || false,
    completed: dataLocalStorage.completed || false,
    voided: dataLocalStorage.voided || false,
    late: dataLocalStorage.late || false,
    nearDue: dataLocalStorage.nearDue || false,
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    week: dataLocalStorage.week || false,
    poDiamonds: dataLocalStorage.poDiamonds || false,
    poProgress: dataLocalStorage.poProgress || false,
    shipmentDiamonds: dataLocalStorage.shipmentDiamonds || false,
    shipmentProgress: dataLocalStorage.shipmentProgress || false,
    favorite: dataLocalStorage.favorite || false,
    user: dataLocalStorage.user || false,
    query: dataLocalStorage.query || "",
    sortedColumn: dataLocalStorage.sortedColumn || "",
    orderBy: dataLocalStorage.orderBy || "",
  });
  const [salesOrders, setSalesOrders] = useState([]);
  const [dataFiltered, setDataFiltered] = useState({
    salesOrders: [],
    totalAmounts: {},
  });
  const [weekGroups, setWeekGroups] = useState([]);
  const [openDate, setOpenDate] = useState(false);
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const [filterDate, setFilterDate] = useState(dataLocalStorage.date || "");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const [headerCells, setHeaderCells] = useState([]);
  const [currentFavorites, setCurrentFavorites] = useState([]);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const featureFlags = useFeatureFlags({ companyId });
  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
    }
  }

  async function getSalesOrders() {
    console.log("lookup");
    setPreviousFilter(filters);
    const traceInstance = trace(performanceFirebase, "sales_orders_screen");
    traceInstance.start();
    setLoading(true);
    const { status, diamondStatus } = getStatusAndDiamondStatus({ filters });
    try {
      const functions = getFunctions();
      const salesOrderEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview",
          env: globalEnvironment,
          params: "/salesorders",
        })
      );

      salesOrderEndpoint({
        companyId: companyId,
        userId: user.id,
        filters: {
          ...filters,
          status,
          diamondStatus,
          dateRange: "",
          dateStart: filters.dateRange
            ? moment(filters.dateRange.start).valueOf()
            : "",
          dateEnd: filters.dateRange
            ? moment(filters.dateRange.end).valueOf()
            : "",
        },
      }).then((result) => {
        const { salesOrdersDB } = result.data;
        setSalesOrders([...salesOrdersDB]);
        if (nonLookUpfilters.week) {
          groupByWeeks(salesOrdersDB);
        }
        traceInstance.stop();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      traceInstance.stop();
      setLoading(false);
    }
  }

  useEffect(() => {
    const { filteredList, totalAmounts } = filterList({
      list: salesOrders,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
    });
    setDataFiltered({ salesOrders: filteredList, totalAmounts });
    if (nonLookUpfilters.week) {
      groupByWeeks(filteredList);
    }
  }, [headerCells, currentFavorites, salesOrders]);

  useEffect(() => {
    if (favorites.length !== currentFavorites.length) {
      setCurrentFavorites(favorites);
    }
  }, [favorites]);

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      console.log("same filter");
      return;
    }
    setSalesOrders([]);
    setDataFiltered({ salesOrders: [], totalAmounts: {} });
    getSalesOrders();
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
    setPage(1);
  }, [callFunction]);

  useEffect(() => {
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => performMark(nonLookUpfilters.query), 150);
    }
  }, [nonLookUpfilters, loading, rowsPerPage, page]);

  useEffect(() => {
    function onAdjustHeader() {
      const { filteredList } = filterList({
        list: salesOrders,
        favorites: favorites,
        headerCells,
        headerColumns,
        nonLookUpfilters,
      });
      const datatoShow = filteredList.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      );
      let maxWidthPONumber = 130;
      let maxWidthSONumber = 150;
      let maxWidthCustomerName = 50;
      let hasVoidedPOMargin = 0;
      datatoShow.forEach((so) => {
        const soWidth = so.number.toString().length * 8.3;
        const customerNameWidth = so.customerName.toString().length * 7.5;

        so.purchaseOrders.forEach((po) => {
          const poWidth = po.number.toString().length * 7;
          if (poWidth > maxWidthPONumber) {
            maxWidthPONumber = poWidth;
          }
          if (po.status === PO_STATUS.VOIDED) {
            hasVoidedPOMargin = 28;
          }
        });
        if (soWidth > maxWidthSONumber) {
          maxWidthSONumber = soWidth;
        }
        if (customerNameWidth > maxWidthCustomerName) {
          maxWidthCustomerName = customerNameWidth;
        }
      });
      let userHeaderCell = userCustomSettings.SOHeaderSetup || [];
      userHeaderCell = userHeaderCell.map((header) => {
        const headerValue = salesOrderHeaderCells[header.name];
        if (
          headerValue &&
          headerValue[generalPermissions.SALES_ORDER_AMOUNT] &&
          !isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ) {
          return {
            ...header,
            ...headerValue,
            styles: {
              ...headerValue.styles,
              display: "none",
            },
          };
        }
        return { ...header, ...headerValue };
      });

      let headerCellsCpy = [...userHeaderCell];
      headerCellsCpy = headerCellsCpy.map((headerCell) => {
        if (headerCell.label === "POs") {
          return {
            ...headerCell,
            styles: {
              ...headerCell.styles,
              width: maxWidthPONumber + 90 + hasVoidedPOMargin,
            },
          };
        } else if (headerCell.reference === "number") {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxWidthSONumber + 70 },
          };
        } else if (headerCell.label === "salesOrderMention") {
          return {
            ...headerCell,
            item: getUserAvatar({
              user,
              styles: {
                width: 24,
                height: 24,
                position: "absolute",
                fontSize: 13,
                outline: "2px solid #000",
                marginTop: -5,
              },
              hasToolTip: false,
            }),
          };
        } else if (headerCell.reference === "customerName") {
          return {
            ...headerCell,
            styles: {
              ...headerCell.styles,
              width:
                maxWidthCustomerName + 70 >= 220
                  ? 220
                  : maxWidthCustomerName + 70,
            },
          };
        } else {
          return headerCell;
        }
      });
      if (!isEqual(headerCells, headerCellsCpy)) {
        setHeaderCells(headerCellsCpy);
      }
    }
    onAdjustHeader();
  }, [
    rowsPerPage,
    page,
    nonLookUpfilters,
    salesOrders,
    isAllowed(generalPermissions.SALES_ORDER_AMOUNT),
  ]);

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }

  function changeNonLookUpButtonFilter(filter) {
    setNonLookUpFilters({
      ...nonLookUpfilters,
      [filter]: !nonLookUpfilters[filter],
    });
  }

  function groupByWeeks(items = []) {
    items.sort(sortObjectsBy("shipDate", true));
    let weeks = [];
    items.forEach((item) => {
      const weekStart = moment(item.shipDate)
        .startOf("week")
        .toDate()
        .getTime();
      const weekEnd = moment(item.shipDate).endOf("week").toDate().getTime();
      if (!weeks.find((week) => week.start === weekStart)) {
        weeks.push({
          start: weekStart ? weekStart : "",
          end: weekEnd ? weekEnd : "",
          list: items
            .filter((SO) => SO.shipDate > weekStart && SO.shipDate < weekEnd)
            .sort(
              sortObjectsBy(
                nonLookUpfilters.sortedColumn,
                nonLookUpfilters.orderBy === orderTypeSort.ASC ? true : false
              )
            ),
        });
      }
    });
    weeks = weeks.filter((week) => week.start !== "" && week.end !== "");
    weeks.forEach((week, index) => {
      const { totalAmounts } = filterList({
        list: week.list,
        favorites: favorites,
        headerCells,
        headerColumns,
        nonLookUpfilters,
      });
      weeks[index].totalAmounts = totalAmounts;
    });
    setWeekGroups(weeks);
  }

  useEffect(() => {
    setPage(1);
    set({ ...filters, ...nonLookUpfilters, date: filterDate, rowsPerPage });
    if (!salesOrders.length) return;
    const { filteredList, totalAmounts } = filterList({
      list: salesOrders,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
    });
    if (nonLookUpfilters.week) {
      groupByWeeks(filteredList);
    } else {
      setDataFiltered({ salesOrders: filteredList, totalAmounts });
    }
  }, [nonLookUpfilters]);

  function resetFilters() {
    setFilters({
      factory: [],
      customer: [],
      dateRange: "",
      date: "",
      inProgress: true,
      completed: false,
      voided: false,
      late: false,
      nearDue: false,
    });
    setNonLookUpFilters({
      ...nonLookUpfilters,
      week: false,
      poDiamonds: false,
      poProgress: false,
      shipmentDiamonds: false,
      shipmentProgress: false,
      query: "",
      user: false,
      favorite: false,
    });
    setFilterDate("");
    setExistsDate(false);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filterDate,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
    if (!filterDate) {
      setFilters({ ...filters, date: "" });
    }
  }
  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilterDate(field);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 50);
  }

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function renderFooter(list = [], renderFooterTable, totalAmounts) {
    return (
      <React.Fragment>
        <FooterTable
          footer={footerSalesOrderList(list.length, totalAmounts, isAllowed)}
          headerCells={headerCells.sort(sortObjectsBy("index", false))}
          isThereColumnSetting={true}
        />
      </React.Fragment>
    );
  }
  function getCSVBody(salesOrders = []) {
    return salesOrders.map((item) => {
      const balance = +item.amount - +item.deposit;
      return {
        favorite: favorites.map((favoriteId) => favoriteId).includes(item.id)
          ? "YES"
          : "NO",
        status: item.late ? "Red" : item.nearDue ? "Orange" : "",
        progress: (
          (+item.completedTasks * 100) /
          +item.totalTasks /
          100
        ).toFixed(2),
        mentions: item.totalMentions,
        SONumber: item.number,
        pos: item.purchaseOrders.map((po) => po.number).join(", ") + " \n ",
        shipments:
          item.shipments.map((shipment) => shipment.number).join(", ") + " \n ",
        customerName: item.customerName,
        date:
          !item.date || item.date === ""
            ? ""
            : moment(item.date).format("M/D/YY"),
        shipDate:
          !item.shipDate || item.shipDate === ""
            ? ""
            : moment(item.shipDate).format("M/D/YY"),
        deliveryDate:
          !item.deliveryDate || item.deliveryDate === ""
            ? ""
            : moment(item.deliveryDate).format("M/D/YY"),
        creationDate:
          !item.creationDate || item.creationDate === ""
            ? ""
            : moment(item.creationDate).format("M/D/YY"),
        discount: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.discount).format("0,0.00")
          : "",
        total: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.total).format("0,0.00")
          : "",
        subtotal: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.subtotal).format("0,0.00")
          : "",
        deposit: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.deposit).format("0,0.00")
          : "",
        balance: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(balance).format("0,0.00")
          : "",
        potentialLateFee: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
          ? numeral(item.potentialLateFee).format("0,0.00")
          : "",
        CBM: numeral(item.CBM).format("0,0.00"),
        shippingTerms: item.shippingTerms,
        totalShipments: item.shipments.length || 0,
        invoice: item.invoice,
        customerPO: item.customerPO,
        salesRepresentative: item.salesRepresentative,
        uniqueItems: item.uniqueItems,
        discountPercentage: item.discountPercentage || "",
        referenceNumber: item.referenceNumber,
        salesOrderLabels: getListValuesFromArray({
          arr: item.tags || [],
          list: tags || [],
          field: "name",
        }),
      };
    });
  }

  function handleUpdateItem(item, tagReference) {
    let salesOrdersCpy = [...salesOrders];
    if (tagReference === dbTables.SALES_ORDERS) {
      salesOrdersCpy = salesOrdersCpy.map((so) => {
        if (item.id === so.id) {
          return {
            ...item,
          };
        }
        return so;
      });
    } else if (tagReference === dbTables.PURCHASE_ORDERS) {
      salesOrdersCpy = salesOrdersCpy.map((so) => {
        if (item.salesOrderId === so.id) {
          let purchaseOrdersCpy = [...so.purchaseOrders];
          purchaseOrdersCpy = purchaseOrdersCpy.map((po) => {
            if (po.id === item.id) {
              return {
                ...item,
              };
            }
            return po;
          });
          return {
            ...so,
            purchaseOrders: purchaseOrdersCpy,
          };
        }
        return so;
      });
    } else if (tagReference === dbTables.SHIPMENTS) {
      salesOrdersCpy = salesOrdersCpy.map((so) => {
        if (so.shipments && so.shipments.length > 0) {
          let shipmentsCpy = [...so.shipments];
          shipmentsCpy = shipmentsCpy.map((shipment) => {
            if (shipment.id === item.id) {
              return {
                ...item,
              };
            }
            return shipment;
          });
          return {
            ...so,
            shipments: shipmentsCpy,
          };
        }
        return so;
      });
    }
    setSalesOrders(salesOrdersCpy);
    const { filteredList, totalAmounts } = filterList({
      list: salesOrdersCpy,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
    });
    if (nonLookUpfilters.week) {
      groupByWeeks(filteredList);
    } else {
      setDataFiltered({
        salesOrders: filteredList,
        totalAmounts,
      });
    }
  }

  function isDisabledPOsHeader() {
    const header = headerCells.find(
      (header) => header.name === headerColumns.POS
    );
    if (header) {
      return !header.enabled;
    }
    return null;
  }

  function isDisabledShipmentHeader() {
    const header = headerCells.find(
      (header) => header.name === headerColumns.SHIPMENTS
    );
    if (header) {
      return !header.enabled;
    }
    return null;
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  function areAllSOExpanded({ salesOrders, salesOrderSetup }) {
    const isThereASectionClosed = salesOrders.some((salesOrder) => {
      if (!salesOrderSetup[salesOrder.id]) {
        return true;
      } else if (
        !salesOrderSetup[salesOrder.id].POs ||
        !salesOrderSetup[salesOrder.id].shipments
      ) {
        return true;
      }
      return false;
    });
    return !isThereASectionClosed;
  }

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounce();
  };

  const filterFactoryActive = filters.factory?.length > 0;
  const filterTagActive = filters.tag?.length > 0;
  const filterCustomerActive = filters.customer?.length > 0;

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={
            <React.Fragment>
              You do not have access to the shipment documents folder for this
              shipment
            </React.Fragment>
          }
          onClose={() => setOpenAttentionModal(false)}
          onClick={() => setOpenAttentionModal(false)}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <SOListContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            dateFields={dateFields}
            currentFilters={filters}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            onOpenDatePicker={onOpenDatePicker}
            enabledFields={{
              customer: true,
              factory: true,
              date: true,
              tag: true,
            }}
          />
          <SearchBox
            placeholder="Search for PO or Sales Order or shipment number, etc."
            value={nonLookUpfilters.query}
            hasBeenReseted={(nonLookUpfilters, value) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: "" });
            }}
            filters={nonLookUpfilters}
            onDebounceValue={(nonLookUpfilters, value) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: value });
            }}
          />
        </div>
        <div className="contentContainer">
          <div className="buttonsFilter">
            <ButtonFilter
              buttonId="list-view-button-filter-favorite"
              value={nonLookUpfilters.favorite}
              onClick={() => changeNonLookUpButtonFilter("favorite")}
              className="smallBox"
              boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
            >
              <StarIcon />
            </ButtonFilter>
            <LateNearDueDiamondButtons
              filters={filters}
              changeButtonFilter={changeButtonFilter}
            />
            <ButtonFilter
              buttonId="list-view-button-filter-useronly"
              value={nonLookUpfilters.user}
              onClick={() => changeNonLookUpButtonFilter("user")}
              toolTip="only"
              className="onlyAvatar"
            >
              {getUserAvatar({
                user,
                hasToolTip: false,
                styles: {
                  width: 24,
                  height: 24,
                  marginRight: 5,
                  fontSize: 13,
                  outline: "2px solid #000",
                },
              })}
              <span className="onlyText"> Only</span>
            </ButtonFilter>
            <StatusOrderButtons
              changeButtonFilter={changeButtonFilter}
              filters={{ ...filters, existsDate }}
            />
            <ButtonFilter
              buttonId="list-view-button-filter-groupbyweek"
              value={nonLookUpfilters.week}
              onClick={() => changeNonLookUpButtonFilter("week")}
              className="mediumBox"
            >
              {!screenMidSize && (
                <CalendarIcon
                  style={{ marginRight: 5 }}
                  size={24}
                  svgClass="icon"
                />
              )}

              {screenMidSize ? "ByWeek" : "Group by Week"}
            </ButtonFilter>
            {filterCustomerActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-customer"
                onClear={() => clearFilterData({ filterKey: "customer" })}
                label={filters.customer.length === 1 ? "Customer" : "Customers"}
                labelStyle={hiddenStyle}
                tooltip={
                  getToolTipArrayValue(filters.customer, customers).tooltip
                }
                value={getToolTipArrayValue(filters.customer, customers).value}
                filters={filters}
              />
            )}
            {filterFactoryActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-factory"
                onClear={() => clearFilterData({ filterKey: "factory" })}
                label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                tooltip={
                  getToolTipArrayValue(filters.factory, factories).tooltip
                }
                value={getToolTipArrayValue(filters.factory, factories).value}
                filters={filters}
              />
            )}
            {isAllowed("see_tags") && filterTagActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-label"
                onClear={() => {
                  clearFilterData({ filterKey: "tag" });
                }}
                label={filters.tag.length === 1 ? "Label" : "Labels"}
                tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                value={tags && getToolTipArrayValue(filters.tag, tags).value}
                filters={filters}
              />
            )}

            {existsDate && (
              <React.Fragment>
                <div
                  onClick={() => {
                    setOpenDate(true);
                    setOpenList(true);
                  }}
                  ref={dateRef}
                >
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-date"
                    filters={filters}
                    onClear={() => {
                      setFilterDate("");
                      setExistsDate(false);
                      setFilters({
                        ...filters,
                        date: "",
                        dateRange: { start: new Date(), end: new Date() },
                      });
                      setOpenDate(false);
                      setOpenList(false);
                      onDebounce();
                    }}
                    label={filters.date}
                    labelStyle={hiddenStyle}
                    tooltip={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                    value={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                  />
                </div>
                {openDate && (
                  <DateRangePicker
                    open={openDate}
                    el={dateRef.current}
                    onClose={handleCloseDatePicker}
                    onChange={changeDate}
                    value={filters.dateRange}
                    cancelIcon={true}
                  />
                )}
              </React.Fragment>
            )}
            <ButtonReset
              id="list-view-button-filter-reset"
              className="root"
              onClick={resetFilters}
            >
              {matches ? "RESET" : "RESET FILTERS"}
            </ButtonReset>
          </div>
          <ToolsButtonScreen
            typeSetup={columnHeaderSetup.SO_HEADER_SETUP}
            nonLookUpfilters={nonLookUpfilters}
            changeNonLookUpButtonFilter={changeNonLookUpButtonFilter}
            headerCells={headerCells}
            csvData={getCSVBody(dataFiltered.salesOrders)}
            onChangeHeader={(newItems) => {
              setHeaderCells(newItems);
            }}
            disabledButtons={{
              [orderType.PURCHASE_ORDER]: isDisabledPOsHeader(),
              [orderType.SHIPMENT]: isDisabledShipmentHeader(),
            }}
            hiddenDataAction={(value) => {
              let salesOrderSetup = {};
              salesOrders.forEach((salesOrder) => {
                salesOrderSetup[salesOrder.id] = {
                  POs: !value,
                  shipments: !value,
                };
              });
              setCurrentSOSetup({ ...salesOrderSetup });
              setSalesOrderSetup({ ...salesOrderSetup });
            }}
            isAllExpanded={areAllSOExpanded({
              salesOrders,
              salesOrderSetup: currentSOSetup,
            })}
            onSaveHeaders={(newItems) => {
              updateDoc(
                doc(
                  firestore,
                  `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
                ),
                {
                  SOHeaderSetup: newItems,
                }
              );
            }}
          />
          <SOTableContainer className="contentListSO">
            <div
              className={cx("tableContainer", {
                tableContainerWeek: nonLookUpfilters.week,
              })}
              id={BODY_ID}
            >
              {!nonLookUpfilters.week && (
                <MainTableRefactor
                  headCells={headerCells.sort(sortObjectsBy("index", false))}
                  bodyRows={dataFiltered.salesOrders.slice(
                    (page - 1) * rowsPerPage,
                    page * rowsPerPage
                  )}
                  nonLookUpfilters={nonLookUpfilters}
                  renderRow={(item, index) => {
                    return (
                      <SalesOrdersTableRow
                        key={item.id + index}
                        item={item}
                        favorites={favorites}
                        user={user}
                        isAllowed={isAllowed}
                        customers={customers}
                        nonLookUpfilters={nonLookUpfilters}
                        headers={headerCells.sort(
                          sortObjectsBy("index", false)
                        )}
                        tags={tags}
                        onUpdateItem={handleUpdateItem}
                        isThereColumnSetting={true}
                        filters={filters}
                        salesOrderSetup={currentSOSetup}
                        onChangeSalesOrderSetup={(salesOrderSetup) => {
                          setCurrentSOSetup({ ...salesOrderSetup });
                          setSalesOrderSetup({ ...salesOrderSetup });
                        }}
                        onOpenAttentionModal={() => setOpenAttentionModal(true)}
                        favoritesDB={favoritesDB}
                        featureFlags={featureFlags}
                      />
                    );
                  }}
                  footer={renderFooter(
                    dataFiltered.salesOrders,
                    true,
                    dataFiltered.totalAmounts
                  )}
                  filters={nonLookUpfilters}
                  onUpdateFilters={(nonLookUpfilters) => {
                    setPage(1);
                    setNonLookUpFilters({ ...nonLookUpfilters });
                  }}
                />
              )}
              {nonLookUpfilters.week &&
                weekGroups.map(
                  (week, index) =>
                    week.list.length > 0 && (
                      <div className="weekTableContainer">
                        <HeaderTableDate week={week} key={week.end + index} />
                        <MainTableRefactor
                          headCells={headerCells
                            .sort(sortObjectsBy("index", false))
                            .map((cell) => {
                              if (
                                cell.id === 4 &&
                                (nonLookUpfilters.poDiamonds ||
                                  nonLookUpfilters.poProgress)
                              ) {
                                return {
                                  ...cell,
                                  styles: {
                                    ...cell.styles,
                                    width: cell.styles.width + 42,
                                  },
                                };
                              }
                              return cell;
                            })}
                          bodyRows={week.list}
                          renderRow={(item) => (
                            <SalesOrdersTableRow
                              item={item}
                              favorites={favorites}
                              user={user}
                              isAllowed={isAllowed}
                              customers={customers}
                              nonLookUpfilters={nonLookUpfilters}
                              headers={headerCells.sort(
                                sortObjectsBy("index", false)
                              )}
                              showTags={filterTagActive}
                              tags={tags}
                              onUpdateItem={handleUpdateItem}
                              filters={filters}
                              salesOrderSetup={currentSOSetup}
                              onChangeSalesOrderSetup={(salesOrderSetup) => {
                                setCurrentSOSetup({ ...salesOrderSetup });
                                setSalesOrderSetup({ ...salesOrderSetup });
                              }}
                              onOpenAttentionModal={() =>
                                setOpenAttentionModal(true)
                              }
                              favoritesDB={favoritesDB}
                              featureFlags={featureFlags}
                            />
                          )}
                          footer={renderFooter(
                            week.list,
                            false,
                            week.totalAmounts
                          )}
                          screenName="SalesOrders"
                          filters={nonLookUpfilters}
                          onUpdateFilters={(nonLookUpfilters) => {
                            setPage(1);
                            setNonLookUpFilters({ ...nonLookUpfilters });
                          }}
                          enableToSee={
                            filters.inProgress ||
                            filters.completed ||
                            filters.voided
                              ? dataFiltered.salesOrders
                              : []
                          }
                        />
                      </div>
                    )
                )}
            </div>
            {!nonLookUpfilters.week && (
              <BasicPagination
                rowsPerPage={rowsPerPage}
                page={page}
                count={getCountPagination(
                  dataFiltered.salesOrders,
                  rowsPerPage
                )}
                onChangePagination={(event, newPage) => {
                  handleChangePage(newPage);
                }}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(+event.target.value);
                  setPage(1);
                }}
              />
            )}
          </SOTableContainer>
        </div>
      </SOListContainer>
    </React.Fragment>
  );

  function getCountPagination(salesOrders, rowsPerPage) {
    return Math.ceil(salesOrders.length / rowsPerPage);
  }
}

export default SalesOrdersScreen;
