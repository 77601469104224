import React, { useEffect, useState } from "react";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import { TagsHeadCells } from "../../components/Managers/TableHeaderProvider";
import {
  getCompanyUserDisplayName,
  sortObjectsBy,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import { useCompanyId, useJSONLocalStorage } from "../../hooks";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { TagsViewStyled } from "./styles";
import TagsTableRow from "./TagsTableRow";
import { useUser, useCompanyUsers } from "../../hooks/user";
import { LABEL_INFO, TYPE_OF_TAGS } from "../../helpers/constants";
import {
  tagsByType,
  useTags,
  tagsExcludes,
  createMismatchTags,
} from "../../hooks/tags";
import { Divider, List, ListItem } from "@mui/material";

function TagsScreen() {
  const tags = useTags();
  const companyId = useCompanyId();
  const companyUsers = useCompanyUsers({});
  const user = useUser();
  const isAllowed = useIsAllowedFunction();
  const { get } = useJSONLocalStorage("tagsScreen");

  const [nonLookUpfilters, setNonLookUpFilters] = useState(
    get()
      ? {
          user: get().user || false,
          sortedColumn: get().sortedColumn || "",
          orderBy: get().orderBy || "",
        }
      : {
          user: false,
          sortedColumn: "number",
          orderBy: "ASC",
        }
  );
  const regularTags = tagsByType(tags, TYPE_OF_TAGS.REGULAR_TAG);
  const specialTags = tagsExcludes(tags, [TYPE_OF_TAGS.REGULAR_TAG]);
  useEffect(() => {
    createMismatchTags(tags, companyId);
  }, []);
  return (
    <TagsViewStyled className="tagsViewContainer">
      {isAllowed("see_tags") ? (
        <React.Fragment>
          <MainTableRefactor
            headCells={TagsHeadCells}
            bodyRows={regularTags
              .map((tag) => ({
                ...tag,
                createdByName: getCompanyUserDisplayName(
                  companyUsers,
                  tag.createdBy
                ),
                updatedByName: getCompanyUserDisplayName(
                  companyUsers,
                  tag.updatedBy
                ),
              }))
              .sort(
                sortObjectsBy(
                  nonLookUpfilters.sortedColumn,
                  nonLookUpfilters.orderBy === "ASC" ? true : false
                )
              )}
            renderRow={(item) => {
              return (
                <TagsTableRow
                  key={item.id}
                  item={item}
                  user={user}
                  isAllowed={isAllowed}
                  companyUsers={companyUsers}
                  tags={tags}
                  isReadOnly={isTradeDashEmployee(user)}
                />
              );
            }}
            filters={nonLookUpfilters}
            onUpdateFilters={(nonLookUpfilters) => {
              setNonLookUpFilters({ ...nonLookUpfilters });
            }}
          />
          <Divider className={"divider"} />
          <div className={"infoContainer"}>
            <span className={"infoText"}>{LABEL_INFO}</span>
          </div>
          <List classes={{ root: "tagList" }}>
            {specialTags.map((tag, index) => {
              if (index === 1 || index === 4 || index === 5) {
                return (
                  <React.Fragment key={tag.id + index}>
                    <ListItem style={{ paddingLeft: 0 }} key={tag.id}>
                      {tag.name}
                    </ListItem>
                    <div className="divider" />
                  </React.Fragment>
                );
              }
              return (
                <ListItem style={{ paddingLeft: 0 }} key={tag.id}>
                  {tag.name}
                </ListItem>
              );
            })}
          </List>
        </React.Fragment>
      ) : (
        <div className={"withoutPermissionTextContainer"}>
          <span className={"withoutPermissionText"}>
            You do not have permissions to see labels
          </span>
        </div>
      )}
    </TagsViewStyled>
  );
}

export default TagsScreen;
