import React from "react";
import Loader from "../General/Loader";
import { CircularProgress, styled } from "@mui/material";

function LoadingBackdrop({
  backdropStyle = {},
  size,
  styles = {},
  withLogo = false,
}) {
  return (
    <LoaderStyled className='loader' style={backdropStyle}>
      {withLogo ? (
        <Loader style={{ ...styles, width: size || 60, height: size || 60 }} />
      ) : (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          thickness={4}
          style={{
            color: "#5d92f4",
            ...styles,
            width: size || 60,
            height: size || 60,
          }}
        />
      )}
    </LoaderStyled>
  );
}

const LoaderStyled = styled("div")(() => ({
  "&.loader": {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    backgroundSize: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default LoadingBackdrop;
