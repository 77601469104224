import React, { useState } from "react";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { BACKDROP_TYPE, PREFIX_ITEM_TABLE } from "../../../helpers/constants";
import { columnsSOShipmentTable } from "../../../helpers/itemsTableColumns";
import {
  useCurrentPurchaseOrder,
  usePurchaseOrders,
} from "../../../hooks/salesOrders";
import { useSOItemList } from "../../../hooks/itemTable";
import { getDashboardSearchPath } from "../../../helpers/helpers";
import { ORDER_DASHBOARD_ACTUAL_VIEWS, mismatchLabel } from "./helpers";
import { useQueryParams } from "../../../hooks";
import { globalScopes } from "../../../api/types/dbTables";
import { getSOMismatchTable } from "../../../helpers/itemsTableHelper";
import SOTableSKU from "../SOTableSKU";
import itemStatus from "../../../api/types/itemStatus";
import AttentionModal from "../../Modal/AttentionModal";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../../actions/DataActions";
import { useNavigate } from "react-router-dom";

// import { trackEvent } from "../../helpers/analytics";

function SectionSoSKU({ salesOrder, user }) {
  const purchaseOrders = usePurchaseOrders();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [modalAttention, setModalAttention] = useState(false);
  const isAllowed = useIsAllowedFunction();
  const purchaseOrder = useCurrentPurchaseOrder();
  const soItemsList = useSOItemList({
    salesOrder: salesOrder,
    purchaseOrders,
    user: user,
    purchaseOrder,
  });

  function attentionItemsBackdrop() {
    setModalAttention(
      <React.Fragment>
        {`This item is not part of any PO in this project`}
      </React.Fragment>
    );
  }

  const focusPOTable = (item = {}) => {
    const itemPurchaseOrderId = item.purchaseOrderId;

    if (itemStatus.READ_ONLY === item.status) {
      return attentionItemsBackdrop();
    }
    let newQuerySearch = {};
    if (purchaseOrder.id !== itemPurchaseOrderId) {
      const newCurrentPO = purchaseOrders.find(
        (po) => po.id === itemPurchaseOrderId
      );

      newQuerySearch = {
        ...queryParams,
        purchaseOrderId: newCurrentPO.id,
        shipmentId: newCurrentPO.shipmentIds[0] || "",
        section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
      };
    } else {
      newQuerySearch = {
        ...queryParams,
        section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
      };
    }
    navigate(
      getDashboardSearchPath({
        ...newQuerySearch,
      })
    );
    setTimeout(() => {
      setBackdropPanelSection({
        type: BACKDROP_TYPE.ITEM_TABLE_PO,
        id: item.id,
        dispatch,
      });
    }, 1000);
  };

  const hasMismatch = getSOMismatchTable({
    currentSalesOrder: salesOrder,
    soItemsList: soItemsList,
  });

  return (
    <div id={"item-table-SO"}>
      {!!modalAttention && (
        <AttentionModal
          title="Attention"
          description={modalAttention}
          isOpen={!!modalAttention}
          onClick={() => setModalAttention(false)}
        />
      )}

      <SOTableSKU
        prefix={PREFIX_ITEM_TABLE.SALES_ORDER}
        columnsTable={columnsSOShipmentTable.filter((column) => {
          if (column.amountPermission && !isAllowed(column.amountPermission)) {
            return false;
          }
          return true;
        })}
        dataTable={soItemsList}
        style={{ maxWidth: 1700 }}
        currentSalesOrder={salesOrder}
        currentPurchaseOrder={purchaseOrder}
        purchaseOrders={purchaseOrders}
        focusPOTable={focusPOTable}
        hasMismatchTag={
          hasMismatch ? mismatchLabel[globalScopes.SALES_ORDER] : false
        }
      />
    </div>
  );
}

export default SectionSoSKU;
