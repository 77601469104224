/**
 * App.js Layout Start Here
 */
import React, { useEffect } from "react";
import IconPO from "../assets/flag-icons/purchaseordericon.png";
import IconExpenses from "../assets/flag-icons/expensesicon.png";
import IconSO from "../assets/flag-icons/salesordersicon.png";
import IconShipment from "../assets/flag-icons/shipmenticon.png";
import IconWeek from "../assets/flag-icons/weekicon.png";
import IconTask from "../assets/flag-icons/tasksicon.png";
import IconReport from "../assets/flag-icons/reporticon.png";
import IconTradedash from "../assets/flag-icons/tradedash.png";
import IconByPhase from "../assets/flag-icons/orderbyphaseicon.png";

import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  getDataFromFirestore,
  getCompanyUsersDataFromFirestore,
  getUsersDataFromFirestore,
} from "../actions/DataActions";
import { dbTables } from "../api/types/dbTables";
import { dispatchUser } from "../actions/AuthActions";
import userTypes from "../api/types/userTypes";
import {
  globalEnvironment,
  globalEnvironments,
} from "../constants/globalVariables";
import { auth, firestore } from "../firebase";
import {
  identifyFullStory,
  identifyHighlight,
  identifyPendo,
} from "../helpers/analytics";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged, updateEmail } from "firebase/auth";
import { Helmet } from "react-helmet";
import { getCustomerName } from "../helpers/helpers";
import { useCustomers } from "../hooks/customers";
import { useCurrentSalesOrder } from "../hooks/salesOrders";
import { CLIENT_IS_OFFLINE } from "../actions/types";
import AttentionModal from "../components/Modal/AttentionModal";
/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const customers = useCustomers();
  const currentSalesOrder = useCurrentSalesOrder({});
  const [companies] = useSelector((state) => state.data.companies);
  const stateUser = useSelector((state) => state.data.currentUser) || {};
  const dispatch = useDispatch();
  const clientIsOffline = useSelector((state) => state.data.clientIsOffline);
  useEffect(() => {
    if (
      companies &&
      stateUser.role &&
      stateUser.role !== userTypes.TRADEDASH_EMPLOYEE &&
      !companies.enabled
    ) {
      sendUser(null);
      navigate("/signin");
      auth.signOut();
    }
  }, [companies]);

  const PENDO_ENVIRONMENTS = [
    globalEnvironments.STAGING,
    globalEnvironments.TRAINING,
    globalEnvironments.EXHART_PRODUCTION,
  ];

  const FULL_STORY_ENVIRONMENTS = [globalEnvironments.EXHART_PRODUCTION];
  const HIGHLIGHT_IO_ENVIRONMENTS = [globalEnvironments.EXHART_PRODUCTION];

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        try {
          const snapUser = await getDoc(
            doc(firestore, `${dbTables.USERS}/${user.uid}`)
          );
          if (!snapUser.data() || Object.keys(snapUser.data()).length === 0) {
            sendUser(null);
            navigate("/signin");
            auth.signOut();
            return;
          }
          const currentUser = snapUser.data();
          if (currentUser.companyId) {
            const companyDBSnap = await getDoc(
              doc(firestore, `${dbTables.COMPANIES}/${currentUser.companyId}`)
            );

            if (
              snapUser.data().role !== userTypes.TRADEDASH_EMPLOYEE &&
              companyDBSnap.data() &&
              !companyDBSnap.data().enabled
            ) {
              sendUser(null);
              navigate("/signin");
              auth.signOut();
              return;
            }
            if (
              companyDBSnap.data() &&
              FULL_STORY_ENVIRONMENTS.includes(globalEnvironment)
            ) {
              const companyDB = companyDBSnap.data();
              identifyFullStory({
                company: companyDB,
                currentUser: currentUser,
              });
            }
            if (
              companyDBSnap.data() &&
              PENDO_ENVIRONMENTS.includes(globalEnvironment)
            ) {
              identifyPendo({
                company: companyDBSnap.data(),
                currentUser: currentUser,
              });
            }
            if (
              companyDBSnap.data() &&
              HIGHLIGHT_IO_ENVIRONMENTS.includes(globalEnvironment)
            ) {
              identifyHighlight({
                company: companyDBSnap.data(),
                currentUser: currentUser,
              });
            }
          }
          const isEmployee = currentUser.role === userTypes.TRADEDASH_EMPLOYEE;
          if (!currentUser.magicMailActive) {
            updateEmail(user, currentUser.email)
              .then(() => {
                updateDoc(doc(firestore, `${dbTables.USERS}/${user.uid}`), {
                  magicMailActive: true,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
          setBasicInformation(snapUser.data());
          if (user && location.pathname.includes("/links")) {
            return;
          } else if (!location.pathname.includes("/app/")) {
            const pathRedirect = isEmployee ? "/app/admin" : "/app/tasks";
            navigate(pathRedirect, {});
            return;
          }
        } catch (error) {
          console.log("ERROR FOUND: ", error);
        }
      } else if (location.pathname.includes("/links")) {
        return;
      } else {
        sendUser(null);
        if (!location.pathname.includes("/signin"))
          return navigate("signin", {});
      }
    });
  }, []);

  function listenToCompanies(user) {
    getDataFromFirestore(dbTables.COMPANIES, user)(dispatch);
  }

  function sendUser(user) {
    dispatchUser(user)(dispatch);
  }

  function listenToAllUsers(user) {
    getDataFromFirestore(dbTables.USERS, user)(dispatch);
  }

  function listenToUsers(companyId) {
    getCompanyUsersDataFromFirestore(dbTables.USERS, companyId)(dispatch);
  }

  function listenCurrentUser(user) {
    getUsersDataFromFirestore(user.id)(dispatch);
  }

  function setBasicInformation(currentUser) {
    listenCurrentUser(currentUser);
    sendUser(currentUser ? currentUser : null);
    listenToCompanies(currentUser);
    if (currentUser.role === userTypes.TRADEDASH_EMPLOYEE) {
      listenToAllUsers(currentUser);
    } else {
      listenToUsers(currentUser.companyId);
    }
  }

  function getTitleBar() {
    const parsePathName = location.pathname;

    if (parsePathName.includes("/app/admin")) {
      return { title: "Admin Settings" };
    }
    if (parsePathName.includes("/app/factories")) {
      return { title: "Vendors", icon: IconTradedash };
    }
    if (parsePathName.includes("/app/customers")) {
      return { title: "Customers", icon: IconTradedash };
    }
    if (parsePathName.includes("/links")) {
      return { title: "Documents Download", icon: IconTradedash };
    }
    if (parsePathName.includes("/app/dashboard")) {
      const number = currentSalesOrder.number || "Sales Order";
      return {
        title: `${number.toString()} |
      ${getCustomerName(customers, currentSalesOrder.customerId, true)}`,
        icon: IconTradedash,
        personalTitle: true,
      };
    }

    const routeToTitle = {
      "/app/expenses": { title: "Expenses", icon: IconExpenses },
      "/app/orders_by_phase": { title: "Orders By Phase", icon: IconByPhase },
      "/app/purchase_orders": { title: "Purchase Orders", icon: IconPO },
      "/app/dashboard": { title: "" },
      "/app/reports": { title: "Reports", icon: IconReport },
      "/app/sales_orders": { title: "Sales Orders", icon: IconSO },
      "/app/shipments": { title: "Shipments", icon: IconShipment },
      "/app/tasks": { title: "Tasks", icon: IconTask },
      "/app/week": { title: "Weekly View", icon: IconWeek },
      "/signin": { title: "Sign in", icon: IconTradedash },
    };

    return routeToTitle[parsePathName] || {};
  }
  const { title = "", icon, personalTitle } = getTitleBar();
  return (
    <div className="content-wrapper">
      {clientIsOffline && (
        <AttentionModal
          isOpen={clientIsOffline}
          title="TradeDash cannot be reached"
          description="Please wait a moment and try again"
          onClose={() => {
            dispatch({
              type: CLIENT_IS_OFFLINE,
              payload: false,
            });
          }}
          onClick={() => {
            dispatch({
              type: CLIENT_IS_OFFLINE,
              payload: false,
            });
          }}
        />
      )}
      <Outlet />
      <Helmet>
        {!personalTitle && <title>{`Tradedash | ${title}`}</title>}
        {personalTitle && <title>{title}</title>}
        <meta name="description" content="Tradedash Maps" />
        <link rel="shortcut icon" href={icon || IconTradedash} />
      </Helmet>
    </div>
  );
}
export default Layout;
