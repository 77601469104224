import React, { useState, useEffect, Fragment } from "react";
import ItemTableButtons from "../Buttons/ItemTableButtons";
import { useTags } from "../../hooks/tags";
import { useCompanyId, useStorage } from "../../hooks/index";
import { useCustomers } from "../../hooks/customers";
import { TableContainerStyled } from "../Tables/styles";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import {
  addItemActivityStream,
  filterShipmentRowTableItem,
  formatCsvPOtable,
  getArrowIconSKU,
  parseRowTableItem,
  saveItem,
  updateShipmentItems,
  updateShipmentTag,
} from "../../helpers/itemsTableHelper";
import { useShipments } from "../../hooks/shipments";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import memoize from "memoize-one";
import { FixedSizeList as List } from "react-window";
import { itemTableRowHeight } from "../../helpers/orderDashboard";
import {
  useCompanyIsAllowed,
  useIsAllowedFunction,
} from "../../hooks/permissions";
import { useCompanyUsers, useUser } from "../../hooks/user";
import {
  csvShipmentHeader,
  pdfShipmentHeader,
} from "../../helpers/itemsTableColumns";
import HeaderItemTable from "../Tables/HeaderItemTable";
import { onChangeSKUShipment } from "../../helpers/orderDashboardRefactored";
import { colors } from "../../assets/jss/variables";
import SKURowVirtualized from "./SKURowVirtualized";
import RowEmptySimple from "../Tables/RowEmptySimple";
import ViewerSKU from "./ViewerSKU";
import ExpandSimpleTableSKUModal from "../Modal/ExpandSimpleTableSKUModal";
import { itemTableReference } from "../../helpers/constants";
import { trace } from "firebase/performance";
import { performanceFirebase } from "../../firebase";
import { difference } from "lodash";
import {
  Collapse,
  Grid,
  Grow,
  Snackbar,
  Table,
  TableBody,
} from "@mui/material";
import { useAllPOItems } from "../../hooks/itemTable";

const createItemData = memoize((classes = {}, columns, data) => ({
  columns,
  classes,
  items: data,
}));

function ShipmentSKUTable({
  prefix,
  showTable = false,
  columnsTable = [],
  dataTable = [],
  style = {},
  currentPurchaseOrder = {},
  currentSalesOrder = {},
  purchaseOrders = [],
  focusPOTable = () => {},
  allPoItems = [],
  handleShowImage = () => {},
  isModal,
  parentShipment,
  modalShowImage,
  modalCleanSearchBox,
  modalOnWriteSearch,
  modalHandleSearchBox,
  modalFilterText,
  onChangeShipment,
  hasMismatchTag = false,
}) {
  const [imagesTableStorage, setImagesTableStorage] =
    useStorage("item_table_images");
  const companyId = useCompanyId();
  const customers = useCustomers();
  const tags = useTags();
  const shipments = useShipments();
  const currentUser = useUser();
  const [showImage, setShowImage] = useState(
    !imagesTableStorage[prefix] || !!modalShowImage
  );
  const [rowTable, setRowTable] = useState(dataTable);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyTable, setIsCopyTable] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [sortTable, setSortTable] = useState({
    sortBy: "itemNumber",
    asc: true,
  });
  const isAllowed = useIsAllowedFunction();
  const [expandModal, setExpandModal] = useState(false);
  const [photosViewer, setPhotosViewer] = useState([
    { src: "", number: "", description: "" },
  ]);
  const alItemList = useAllPOItems();
  const companyUsers = useCompanyUsers({ id: companyId });

  const currentShipmentDB = parentShipment;

  const useCompanyAllowed = (id) => {
    return useCompanyIsAllowed(id, companyId);
  };

  useEffect(() => {
    if (difference(dataTable, rowTable)) {
      setRowTable(dataTable);
    }
  }, [dataTable]);

  const saveTags = ({ shipmentRow, row, currentShipmentId }) => {
    const currentPO = purchaseOrders.find(
      (po) => po.id === row.purchaseOrderId
    );
    const currentPOList = alItemList[currentPO.itemTableId];
    try {
      updateShipmentTag({
        items: currentPOList.map((item) => {
          const matchingItem = shipmentRow.find((row) => row.id === item.id);
          return matchingItem ? matchingItem : item;
        }),
        allPurchaseOrders: purchaseOrders,
        currentItem: row,
        currentPurchaseOrder,
        currentShipmentId,
        tags,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  async function onChangeData({ item, row }) {
    const traceInstance = trace(
      performanceFirebase,
      "dashboard_update_shipment_sku"
    );
    traceInstance.start();
    setIsLoading(true);
    const currentShipmentId = currentShipmentDB.id;
    const newRow = onChangeSKUShipment({
      item,
      row,
      currentShipmentId,
    });
    await saveItem({ newRow });
    const allRows = rowTable.map((row) =>
      row.id === newRow.id ? newRow : row
    );
    setRowTable(allRows);
    saveTags({ shipmentRow: allRows, row: newRow, currentShipmentId });
    const rowShipment = currentShipmentDB;
    addItemActivityStream({
      row,
      item,
      reference: itemTableReference.MAIN_SHIPMENT,
      currentShipmentId,
      user: currentUser,
      currentPurchaseOrder,
      companyUsers: companyUsers,
      currentShipment: rowShipment,
    });

    await updateShipmentItems({
      row,
      reference: itemTableReference.MAIN_SHIPMENT,
      allRows,
      currentPurchaseOrder,
      currentShipment: rowShipment,
      currentItem: item,
      setRedirectShipment: (row) => {
        onChangeShipment({ ...rowShipment, erased: row.erased });
      },
    });
    setIsLoading(false);
    traceInstance.stop();
  }

  const handleChange = (event, forceStatus) => {
    const showImage = event ? event.target.checked : forceStatus;
    setImagesTableStorage(prefix, !showImage);
    setShowImage(showImage);
    handleShowImage(showImage);
  };

  const currentRowTable = filterShipmentRowTableItem({
    rowTable,
    searchText: modalFilterText,
    purchaseOrders,
  });

  const actualRow = parseRowTableItem({
    rows: currentRowTable,
    resetNew: false,
    sortTable,
    currentIdPath: {
      currentShipmentId: currentShipmentDB.id,
      currentSalesOrderId: currentSalesOrder.id,
      currentPurchaseOrder: currentPurchaseOrder.id,
    },
    columnsTable,
    purchaseOrders,
  });

  function handlePhotosViewer({ open, photos = [] }) {
    setOpenViewer(open);
    if (photos.length > 0) {
      setPhotosViewer(photos);
    } else {
      setPhotosViewer([{ src: "" }]);
    }
  }

  const getHeaderByScope = () =>
    csvShipmentHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const getPDFHeaderByScope = () =>
    pdfShipmentHeader.filter((header) => {
      if (header.amountPermission && !isAllowed(header.amountPermission)) {
        return false;
      }
      return true;
    });

  const handleChangeShowImage = (value) => {
    setShowImage(value);
    handleShowImage(value);
  };

  const handleSorting = (column, isSorting) => {
    if (column.sort) {
      if (!isSorting) {
        setSortTable({
          sortBy: column.id,
          asc: false,
        });
      } else {
        setSortTable({
          sortBy: column.id,
          asc: !sortTable.asc,
        });
      }
    }
  };

  const itemData = createItemData(
    {},
    isCopyTable
      ? columnsTable
      : columnsTable.filter((column) => column.id !== ""),
    actualRow
  );
  const idExpandModal = isModal ? "ExpandModal" : "";
  return (
    <Fragment>
      {showImage && (
        <ViewerSKU
          handlePhotosViewer={handlePhotosViewer}
          openViewer={openViewer}
          photosViewer={photosViewer}
          showImage={showImage}
        />
      )}
      {expandModal && !isModal && (
        <ExpandSimpleTableSKUModal
          isOpen={expandModal}
          columnsTable={columnsTable}
          currentPurchaseOrder={currentPurchaseOrder}
          currentSalesOrder={currentSalesOrder}
          currentShipment={currentShipmentDB}
          rowTable={rowTable}
          setExpandModal={setExpandModal}
          showTable={showTable}
          key={`sh_expand`}
          purchaseOrders={purchaseOrders}
          handleShowImage={(show) => {
            handleChange(false, show);
          }}
          modalShowImage={showImage}
          prefix={prefix}
          parentShipment={currentShipmentDB}
          onChangeShipment={onChangeShipment}
          allPoItems={allPoItems}
          hasMismatchTag={hasMismatchTag}
          focusPOTable={focusPOTable}
        />
      )}

      {showTable && (
        <ItemTableButtons
          isModal={isModal}
          idExpandModal={idExpandModal}
          buttonsStyle={style}
          isAllOpen={true}
          handleHiddenImages={() => {
            let currentShowImage = showImage;
            handleChangeShowImage(false);
            if (currentShowImage) {
              setTimeout(() => {
                handleChangeShowImage(currentShowImage);
              }, 500);
            }
          }}
          csvData={formatCsvPOtable({
            rows: actualRow,
            shipments,
            customers,
            currentShipment: currentShipmentDB,
            currentSalesOrder,
          })}
          prefix={prefix}
          tableHeader={getHeaderByScope()}
          onExpandModal={() => setExpandModal(true)}
          pdfTableHeader={getPDFHeaderByScope()}
          handleOnCopyTable={(value) => setIsCopyTable(value)}
          cleanSearchBox={modalCleanSearchBox}
          onWriteSearch={modalOnWriteSearch}
          handleSearchBox={modalHandleSearchBox}
          handleOpenSnackBar={(value) =>
            setTimeout(() => {
              setOpenSnackBar(value);
            }, 50)
          }
          hasMismatchTag={hasMismatchTag}
        />
      )}
      <Grid container className="shipment-table-container">
        <Grid
          item
          xs={12}
          className="shipment-table-content"
          style={{ padding: !showTable ? "0px" : "", position: "relative" }}
        >
          {isLoading && (
            <LoadingBackdrop
              size={60}
              backdropStyle={{
                position: "fixed",
                zIndex: 10,
              }}
              withLogo={true}
            />
          )}
          <Collapse
            className="collapse-item-table-container"
            in={showTable}
            timeout="auto"
            unmountOnExit
          >
            <TableContainerStyled
              className={isModal ? "" : "shipmentTableContainerV2"}
              style={{
                maxWidth: style.maxWidth || "",
                outline: `2px solid ${colors.dividerColor}`,
                background: "white",
                height: "auto",
                overflowY: "hidden",
              }}
              id={`${prefix}TableContainer${idExpandModal}`}
            >
              <Table
                stickyHeader
                aria-label="customized table"
                style={{
                  width: "100%",
                  ...style,
                  background: "white",
                  position: "relative",
                  height: "calc(100vh - 185px)",
                }}
                id={`itemTable${prefix}${idExpandModal}`}
              >
                <HeaderItemTable
                  columnsTable={
                    isCopyTable
                      ? columnsTable
                      : columnsTable.filter((column) => column.id !== "")
                  }
                  getArrowIcon={(value, style) =>
                    getArrowIconSKU({
                      isSorting: value,
                      additionalStyle: style,
                      sortTable,
                    })
                  }
                  handleChange={handleChange}
                  handleSorting={handleSorting}
                  handleShowImages={(value) => {
                    setImagesTableStorage(prefix, !value);
                    setShowImage(value);
                    handleShowImage(value);
                  }}
                  prefix={prefix}
                  showImage={showImage}
                  sortTable={sortTable}
                  purchaseOrders={purchaseOrders}
                  currentShipment={currentShipmentDB}
                  isCopyTable={isCopyTable}
                />
                {showTable && (
                  <TableBody>
                    {openSnackBar && (
                      <Snackbar
                        className={"snackbar-table-link"}
                        open={openSnackBar}
                        onClose={() => setOpenSnackBar(false)}
                        TransitionComponent={Grow}
                        ContentProps={{
                          "aria-describedby": "message-id",
                        }}
                        style={{ position: "absolute !important", top: 100 }}
                        autoHideDuration={1500}
                        message={
                          <span style={{ margin: "auto" }} id="message-id">
                            Copied to clipboard
                          </span>
                        }
                      />
                    )}
                    <AutoSizer>
                      {({ height, width }) => {
                        const sizeRow = showImage
                          ? itemTableRowHeight.WITH_IMAGE
                          : itemTableRowHeight.NORMAL;
                        const heightSize = isCopyTable
                          ? height
                          : actualRow.length * sizeRow;
                        return (
                          <List
                            key={prefix + actualRow.length}
                            className={"list"}
                            height={heightSize}
                            width={width}
                            itemCount={actualRow.length}
                            itemSize={sizeRow}
                            itemData={itemData}
                          >
                            {({ data, index, style }) => {
                              return (
                                <SKURowVirtualized
                                  key={index + prefix + "listRow"}
                                  prefix={prefix}
                                  index={index}
                                  style={style}
                                  data={data}
                                  onChangeData={onChangeData}
                                  currentPurchaseOrder={currentPurchaseOrder}
                                  currentSalesOrder={currentSalesOrder}
                                  currentShipment={currentShipmentDB}
                                  showImage={showImage}
                                  handlePhotosViewer={handlePhotosViewer}
                                  isAllowed={isAllowed}
                                  companyAllowed={useCompanyAllowed}
                                  isVoided={false}
                                  focusPOTable={focusPOTable}
                                />
                              );
                            }}
                          </List>
                        );
                      }}
                    </AutoSizer>
                    {actualRow.length === 0 && (
                      <RowEmptySimple
                        columns={columnsTable}
                        searchText={modalFilterText}
                      />
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainerStyled>
          </Collapse>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default ShipmentSKUTable;
