import React, { useState } from "react";
import SearchLabel from "../../Inputs/SearchLabel";
import { ManagementDocumentNoteHeaderStyled } from "./styles";
import { SEARCH_BOX_TYPES } from "../../../helpers/orderDashboard";

function DocumentsNoteHeader({
  hasTitle,
  handleExpandOrCollapse,
  isExpand,
  onQuerySearch,
  mainTableID,
}) {
  const [cleanSearchBox, setCleanSearchBox] = useState(false);

  return (
    <ManagementDocumentNoteHeaderStyled className="documentsHeader">
      <div>
        <button
          className={"buttonExpandCollapse"}
          onClick={() => handleExpandOrCollapse(!isExpand)}
        >
          {isExpand ? "COLLAPSE ALL" : "EXPAND All"}
        </button>
      </div>
      {hasTitle && (
        <span
          style={{
            color: "#25486D",
            fontSize: 17,
            fontWeight: "bold",
            margin: "0px 8px",
          }}
        >
          {hasTitle ? "Documents" : ""}
        </span>
      )}
      <div className={"documentsHeaderButtonAddDocument"}>
        <SearchLabel
          cookie={mainTableID}
          returnQuery={(query) => {
            onQuerySearch(query);
          }}
          placeholderOnExpand="Search for document or folder name"
          cleanSearchBox={cleanSearchBox}
          onDisableCleanSearchBox={() => setCleanSearchBox(false)}
          styles={{ marginRight: 18 }}
          storageField="documentsearch"
          searchLabelId="document-notes-search-box"
          type={SEARCH_BOX_TYPES.NOTE_DOCUMENTS}
        />
      </div>
    </ManagementDocumentNoteHeaderStyled>
  );
}

export default DocumentsNoteHeader;
