import React, { useState, useEffect } from "react";
import moment from "moment";
import DayCardTabs from "./DayCardTabs";
import TaskCard from "./TaskCard";
import VirtualScrollList from "./VirtualScrollList";
// import ZoomOutMap from "@mui/icons-material/ZoomOutMap";
import CollapseIcon from "../../assets/flag-icons/collapseIcon.png";
import ExpandIcon from "../../assets/flag-icons/expand.png";
import { useJSONLocalStorage } from "../../hooks";

function DayCard({
  day = { label: "Wednesday", date: moment() },
  tasks,
  onExpand = (index) => {},
  index,
  expanded,
  onComplete,
  nonLookUpfilters,
  className,
  showTags,
  tags = [],
  onUpdateItem,
  user,
  isAllowed,
  filters,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  factories = [],
}) {
  const today = day.date.isSame(moment(), "day");
  const tabs = today ? ["LATE", "DUE", "STARTS"] : ["DUE", "STARTS"];
  const { get, set } = useJSONLocalStorage("weekTabs");
  const [selectedTab, setSelectedTab] = useState(getSelectedTab(today, day));

  function getSelectedTab(today, day) {
    const dateFormat = day.dateFormated;
    if (!today && get() && get()[dateFormat] > 1) {
      return 0;
    }
    return get() && get()[dateFormat] ? get()[dateFormat] : 0;
  }
  useEffect(() => {
    const tabPositionLocalStorage = get();
    if (!tabPositionLocalStorage && selectedTab !== 0) {
      setSelectedTab(0);
    }
  }, [day.date]);

  function getStatusTasks({ today, expanded, tab, selectedTab, day }) {
    if (!expanded) {
      if (today) {
        if (!get() || !get()[day.dateFormated]) {
          return "LATE";
        }
        return get()[day.dateFormated] === 0
          ? "LATE"
          : get()[day.dateFormated] === 1
          ? "DUE"
          : "STARTS";
      }
      if (!get() || !get()[day.dateFormated]) {
        return "DUE";
      }
      return get()[day.dateFormated] === 0 ? "DUE" : "STARTS";
    } else {
      return tab;
    }
  }

  function getTasksList(tasks, tab) {
    if (tab === "LATE") {
      return tasks["late"] || [];
    }
    if (tab === "DUE") {
      return tasks["dueDate"] || [];
    }
    if (tab === "STARTS") {
      return tasks["startDate"] || [];
    }
  }

  function getExpandedStatus(tasks, tab) {
    if (tab === "LATE") {
      if (
        getTasksList(tasks, tab).length >= getTasksList(tasks, "DUE").length ||
        getTasksList(tasks, tab).length >= getTasksList(tasks, "STARTS").length
      ) {
        return true;
      }
      return false;
    } else if (tab === "DUE") {
      if (
        getTasksList(tasks, tab).length >= getTasksList(tasks, "LATE").length ||
        getTasksList(tasks, tab).length >= getTasksList(tasks, "STARTS").length
      ) {
        return true;
      }
      return false;
    } else if (tab === "STARTS") {
      if (
        getTasksList(tasks, tab).length > getTasksList(tasks, "DUE").length ||
        getTasksList(tasks, tab).length > getTasksList(tasks, "LATE").length
      ) {
        return true;
      }
      return false;
    }
  }

  function getDayCardStyles(day) {
    if (day.label === "Monday") {
      return {
        boxShadow: "rgba(38, 73, 110, 0.3) 1px 0px 4px 3px",
        background: "#B3D7FD",
        zIndex: 1,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      };
    } else if (day.label === "Sunday") {
      return {
        boxShadow: "rgba(38, 73, 110, 0.3) 1px 0px 4px 3px",
        background: "#B3D7FD",
        zIndex: 1,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
      };
    } else {
      return {
        boxShadow: "rgba(38, 73, 110, 0.3) 1px 0px 4px 3px",
        background: "#B3D7FD",
        zIndex: 1,
        borderRadius: 5,
      };
    }
  }

  return (
    <div
      key={day.label}
      id={day.label}
      data-expanded={expanded}
      className={className}
      style={today ? getDayCardStyles(day) : { borderTop: "16px solid white" }}
    >
      <section className={"header"}>
        <div id="title">
          <span>{today ? "Today" : day.label}</span>
          <p>
            {today ? day.date.format("dddd, MMM D") : day.date.format("MMM D")}
          </p>
        </div>
        <button
          onClick={(ev) => {
            expanded
              ? onExpand({}, day.label)
              : onExpand({ day, tasks, onExpand, index }, day.label);
          }}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            padding: 6,
            color: "#6F7E8E",
          }}
        >
          {expanded ? (
            <React.Fragment>
              <span style={{ height: 10 }} id="viewLess">
                View Less
              </span>
              {/* <ZoomIn style={{ width: 12 }} /> */}
              <img src={CollapseIcon} alt="collapse" style={{ margin: 0 }} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span style={{ height: 10 }} id="viewAll">
                View All
              </span>
              <img src={ExpandIcon} alt="expand" style={{ margin: 0 }} />
              {/* <ZoomOutMap style={{ width: 12, color: "#6F7E8E" }} /> */}
            </React.Fragment>
          )}
        </button>
      </section>
      {expanded && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {tabs.map((tab, index) => (
            <div
              key={tab + index}
              style={
                !today
                  ? {
                      flex: 1,
                      margin: 8,
                      zIndex: 0,
                      height: "100%",
                    }
                  : getExpandedStatus(tasks, tab)
                  ? {
                      flex: 2,
                      margin: 8,
                      zIndex: 0,
                      height: "100%",
                    }
                  : {
                      flex: 1,
                      margin: 8,
                      zIndex: 0,
                      height: "100%",
                    }
              }
            >
              <DayCardTabs
                tabs={[tab]}
                value={index || 0}
                tasks={tasks}
                onChange={() => {}}
                expanded={expanded}
              />
              <VirtualScrollList
                className={"content"}
                itemHeight={"calc(90vh - 424px)"}
                renderItem={(task, indexTask) => (
                  <TaskCard
                    key={
                      task.id +
                      task.purchaseOrderId +
                      task.salesOrderId +
                      indexTask
                    }
                    task={task}
                    onComplete={onComplete}
                    nonLookUpfilters={nonLookUpfilters}
                    showTags={showTags}
                    tags={tags}
                    onUpdateItem={onUpdateItem}
                    user={user}
                    isAllowed={isAllowed}
                    filters={filters}
                    factories={factories}
                  />
                )}
                list={getTasksList(
                  tasks,
                  getStatusTasks({ today, expanded, tab, selectedTab, day })
                )}
                expanded={!today ? expanded : getExpandedStatus(tasks, tab)}
              />
            </div>
          ))}
        </div>
      )}
      {!expanded && (
        <React.Fragment>
          <DayCardTabs
            tabs={tabs}
            spaced={today}
            value={(get() && get()[day.dateFormated]) || 0}
            fullWidth
            onChange={(value) => {
              setSelectedTab(value);
              set({
                ...get(),
                [day.dateFormated]: value === 0 ? undefined : value,
              });
            }}
            tasks={tasks}
          />

          <VirtualScrollList
            className={"content"}
            itemHeight={"calc(90vh - 424px)"}
            renderItem={(task, index) => {
              return (
                <TaskCard
                  key={
                    task.id + task.purchaseOrderId + task.salesOrderId + index
                  }
                  task={task}
                  onComplete={onComplete}
                  nonLookUpfilters={nonLookUpfilters}
                  showTags={showTags}
                  tags={tags}
                  onUpdateItem={onUpdateItem}
                  user={user}
                  isAllowed={isAllowed}
                  filters={filters}
                  isReadOnly={isReadOnly}
                  handleReadOnlyModal={handleReadOnlyModal}
                  factories={factories}
                />
              );
            }}
            list={getTasksList(
              tasks,
              getStatusTasks({
                today,
                expanded,
                tab: 0,
                selectedTab,
                day,
              })
            )}
            expanded={expanded}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default DayCard;
