import React from "react";
import { NavLink } from "react-router-dom";
import userTypes from "../../api/types/userTypes";
import TagsModal from "../../components/Modal/TagsModal";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  getProgressValue,
  getDashboardSearchPath,
  getTagsLabel,
  sortObjectsBy,
  verifyVendorIcon,
} from "../../helpers/helpers";

import FactoryIcon from "../../assets/flag-icons/sidebar-factories.svg";
import { dbTables } from "../../api/types/dbTables";
import { DiamondIcon } from "../../helpers/iconSvgPath";
import {
  GENERAL_PERMISSION_VALUE,
  PO_DIAMOND_STATUS,
  PO_STATUS,
} from "../../helpers/constants";
import { checkSpecificPermissions } from "../../hooks/permissions";
import { getTagStatus } from "../../helpers/salesOrder";
import moment from "moment";
import { colors } from "../../assets/jss/variables";
import HiddenDataIconAnimated from "../../helpers/iconAnimation/HiddenDataIconAnimated";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";

function POsDetailItemv2({
  item,
  user,
  onUpdateItem,
  isTradeDashEmployee,
  filters = {},
  poProgress,
  poDiamonds,
  tags,
  isAllowed,
  salesOrderSetup = {},
  factories,
  onChangeSalesOrderSetup = () => {},
  poNameWidth = 100,
}) {
  const currentTags = filters.tag || [];
  const purchaseOrders = item.purchaseOrders
    .filter(
      (purchaseOrder) =>
        user.role === userTypes.SUPER_ADMIN ||
        user.role === userTypes.TRADEDASH_EMPLOYEE ||
        checkSpecificPermissions({
          userPermissions: user.permissions,
          permissionToCheck: [
            purchaseOrder.factoryId,
            GENERAL_PERMISSION_VALUE.ALL_VENDORS,
          ],
        })
    )
    .sort(sortObjectsBy("number", false));

  const isOpen =
    (salesOrderSetup[item.id] && salesOrderSetup[item.id].POs) ||
    purchaseOrders.length <= 1;

  let hasTag = false;
  if (!isOpen) {
    purchaseOrders.forEach((po) => {
      const poTags = po.tags || [];
      hasTag = poTags.some((tag) => currentTags.includes(tag)) || hasTag;
    });
  }

  const getDiamond = (purchaseOrder) => {
    if (!poDiamonds) {
      return <div />;
    } else {
      if (purchaseOrder.status === PO_STATUS.VOIDED) {
        return <div className="diamond-container" />;
      }
      if (purchaseOrder.diamondStatus === PO_DIAMOND_STATUS.LATE) {
        return (
          <div className="diamond-container">
            <TooltipTD label="Late">
              <DiamondIcon color={colors.diamondRed} />
            </TooltipTD>
          </div>
        );
      } else if (purchaseOrder.diamondStatus === PO_DIAMOND_STATUS.NEAR_DUE) {
        return (
          <div className="diamond-container">
            <TooltipTD label="Due Today or Tomorrow">
              <DiamondIcon color={colors.diamondOrange} />
            </TooltipTD>
          </div>
        );
      }
      return <div className="diamond-container" />;
    }
  };

  const getProgress = (purchaseOrder) => {
    if (!poProgress) {
      return <div />;
    } else {
      if (purchaseOrder.status === PO_STATUS.VOIDED) {
        return <div className="progress-container" />;
      }

      return (
        <CircularStaticProgress
          className="progress-container"
          label={purchaseOrder.completedTasks + "/" + purchaseOrder.totalTasks}
          value={+getProgressValue(purchaseOrder)}
          thickness={16}
          style={{
            transform: "rotate(270deg)",
            color: "#39F",
          }}
          size={15}
        />
      );
    }
  };
  let paddingValue = 0;
  if (!poDiamonds) {
    paddingValue = paddingValue + 15;
  }
  if (!poProgress) {
    paddingValue = paddingValue + 15;
  }
  const hiddenStyle = { paddingRight: paddingValue };

  return (
    <div
      className="poDetailItems"
      style={{
        height: isOpen
          ? purchaseOrders.length * 21.05 + (purchaseOrders.length - 1) * 5
          : 23,
      }}
    >
      <div
        className="poListanimated"
        style={{ opacity: isOpen ? 1 : 0, zIndex: isOpen ? 1 : 0 }}
      >
        {purchaseOrders.map((purchaseOrder, index) => {
          const currentVendor = factories.find(
            (factory) => factory.id === purchaseOrder.factoryId
          );
          const factoryFilter = filters.factory;
          const showIconVendor = verifyVendorIcon({
            factoryFilter,
            item: purchaseOrder,
            currentVendor,
          });
          const salesOrders = item.salesOrders || [];
          const salesOrder =
            salesOrders.find((so) => {
              const purchaseOrderIds = so.purchaseOrderIds || [];
              return purchaseOrderIds.includes(purchaseOrder.id);
            }) || {};

          const salesOrderId = salesOrder.id || item.id;
          const purchaseOrderTags = purchaseOrder.tags || [];
          return (
            <div
              key={purchaseOrder.id + index}
              style={{
                ...hiddenStyle,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {getDiamond(purchaseOrder)}
              {getProgress(purchaseOrder)}

              {isAllowed("see_tags") && (
                <TagsModal
                  key={purchaseOrder.id}
                  label={getTagsLabel(tags, purchaseOrderTags).tagsString}
                  tags={getTagsLabel(tags, purchaseOrderTags).tagsArray}
                  allTags={tags}
                  item={{ ...purchaseOrder, salesOrderId }}
                  currentUser={user}
                  tagReference={dbTables.PURCHASE_ORDERS}
                  onUpdateItem={onUpdateItem}
                  isThereItemRef={false}
                  isReadOnly={isTradeDashEmployee(user)}
                  status={getTagStatus({
                    tags: purchaseOrderTags,
                    currentTags: currentTags,
                  })}
                />
              )}
              {showIconVendor && (
                <TooltipTD label={currentVendor.name}>
                  <img
                    src={FactoryIcon}
                    alt={"FactoryIcon"}
                    style={{
                      width: 11,
                      marginRight: 5,
                      marginBottom: 5,
                    }}
                  />
                </TooltipTD>
              )}

              <NavLink
                to={
                  !(
                    user.role === userTypes.SUPER_ADMIN ||
                    user.role === userTypes.TRADEDASH_EMPLOYEE ||
                    checkSpecificPermissions({
                      userPermissions: user.permissions,
                      permissionToCheck: [
                        purchaseOrder.factoryId,
                        GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                      ],
                    })
                  )
                    ? "#"
                    : getDashboardSearchPath({
                        salesOrderId,
                        purchaseOrderId: purchaseOrder.id,
                        shipmentId: item.id,
                        section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                      })
                }
                style={{
                  color: "#25486D",
                  textDecoration: "underline",
                  overflow: "visible",
                  marginLeft: 2,
                  minWidth: poNameWidth,
                  textAlign: "left",
                }}
                id="list-view-content-purchaseorder"
              >
                {purchaseOrder.number}
              </NavLink>

              <span style={{ marginInline: 20, width: 60, minWidth: 60 }}>
                {purchaseOrder.orderReadyDate
                  ? moment(purchaseOrder.orderReadyDate).format("M/D/YY")
                  : ""}
              </span>
              <TooltipTD label={currentVendor.name} style={{ height: 21 }}>
                <NavLink
                  to={
                    !(
                      user.role === userTypes.SUPER_ADMIN ||
                      user.role === userTypes.TRADEDASH_EMPLOYEE ||
                      checkSpecificPermissions({
                        userPermissions: user.permissions,
                        permissionToCheck: [
                          currentVendor.id,
                          GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                        ],
                      })
                    )
                      ? "#"
                      : `/app/factories/${currentVendor.id}`
                  }
                  style={{
                    textDecoration: "underline",
                    overflow: "hidden",
                    color: "#25486D",
                    textOverflow: "ellipsis",
                  }}
                  id="list-view-content-factory"
                >
                  <span className="span-description">{currentVendor.name}</span>
                </NavLink>
              </TooltipTD>
            </div>
          );
        })}
      </div>
      {purchaseOrders.length > 0 && (
        <div
          className="po-iconContainerDisplay"
          style={{ opacity: !isOpen ? 1 : 0, zIndex: !isOpen ? 1 : 0 }}
        >
          {HiddenDataIconAnimated({
            styles: {
              cursor: "pointer",
              display: "flex",
            },
            color: hasTag ? colors.diamondRed : "",
            onClick: () => {
              onChangeSalesOrderSetup({
                ...salesOrderSetup,
                [item.id]: {
                  ...salesOrderSetup[item.id],
                  POs: !salesOrderSetup[item.id]
                    ? true
                    : !salesOrderSetup[item.id].POs,
                },
              });
            },
          })}
          <span style={{ marginLeft: 6, fontWeight: 500 }}>
            {purchaseOrders.length}
          </span>
        </div>
      )}
    </div>
  );
}

export default POsDetailItemv2;
