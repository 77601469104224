import React from "react";
import moment from "moment";
import TooltipTD from "../components/Tooltip/TooltipTD";

import { getCustomerName, getFactoryName, sortObjectsBy } from "./helpers";
import userTypes from "../api/types/userTypes";
import { dbTables } from "../api/types/dbTables";
import { firestore } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { activityScopes } from "./activitiesStream";
import { GENERAL_PERMISSION_VALUE } from "./constants";
import { verifyPermission } from "../hooks/permissions";
import { ShipmentBadge } from "../container/StyledComponent/BadgeStyled";
import { cx } from "@emotion/css";

function getShipmentTooltipLabel({ shipment }) {
  let label = "";
  const { finalDestination, shipDate, deliveryDate } = shipment;
  if (finalDestination) {
    label = label + finalDestination;
  }
  if (shipDate) {
    if (finalDestination) {
      label = label + ", ";
    }
    label = label + "Shipping on " + moment(shipDate).format("M/D/YY");
  }
  if (deliveryDate) {
    if (shipDate) {
      label = label + ", ";
    }
    label = label + "Delivery on " + moment(deliveryDate).format("M/D/YY");
  }
  return label;
}

function getShipmentInfo({ shipment = {} }) {
  const { finalDestination, shipDate, deliveryDate } = shipment;
  let prefix = shipment.number;
  let suffix = "";
  if (!finalDestination && !shipDate && !deliveryDate) {
    return { prefix, suffix };
  }
  prefix = `${prefix}: `;
  if (finalDestination) {
    suffix = suffix + finalDestination;
    if (shipDate || deliveryDate) {
      suffix = suffix + ", ";
    }
  }
  if (shipDate) {
    suffix = suffix + "Shipping on " + moment(shipDate).format("M/D/YY");
    if (deliveryDate) {
      suffix = suffix + ", ";
    }
  }
  if (deliveryDate) {
    suffix = suffix + "Delivery on " + moment(deliveryDate).format("M/D/YY");
  }
  return { prefix, suffix };
}

export function getShipmentBadge({
  shipment,
  styles = {},
  classes,
  onChangeShipment = () => {},
  tooltipStyle = {},
  showToolTip = true,
  id = "",
}) {
  if (shipment) {
    return (
      <TooltipTD
        label={getShipmentTooltipLabel({ shipment })}
        style={tooltipStyle}
        showToolTip={showToolTip}
      >
        <ShipmentBadge
          id={id}
          className={cx("badgeStyle", classes)}
          style={{
            ...styles,
          }}
          onClick={(ev) => {
            onChangeShipment(shipment, ev);
          }}
        >
          <p>{shipment.number}</p>
        </ShipmentBadge>
      </TooltipTD>
    );
  }
  return null;
}

export const orderNavList = ({
  salesOrders,
  queryParam,
  customers,
  shipments,
  shipmentClass,
  purchaseOrders,
  factories,
  user,
}) => {
  const salesorderList = salesOrders.map((so) => ({
    id: so.id,
    name: so.number,
    divider: true,
    selected: so.id === queryParam.salesOrderId,
    subTitle: getCustomerName(customers, so.customerId, true),
    userMentions: so.userMentions || {},
    purchaseOrderIds: so.purchaseOrderIds,
    status: so.status,
  }));

  const shipmentList = shipments.map((shipment) => ({
    id: shipment.id,
    name: getShipmentBadge({
      shipment: shipment,
      classes: shipmentClass,
      tooltipPlacement: "bottom",
    }),
    divider: false,
    subTitle: shipment.finalDestination || "",
    userMentions: shipment.userMentions || {},
  }));
  let poList = purchaseOrders
    .filter((po) =>
      verifyPermission({
        user: user,
        permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      })
    )
    .map((po) => ({
      id: po.id,
      name: po.number,
      divider: true,
      selected: po.id === queryParam.purchaseOrderId,
      subTitle: getFactoryName(factories, po.factoryId),
      userMentions: po.userMentions || {},
      status: po.status,
      shipmentIds: po.shipmentIds || [],
    }));

  return {
    [activityScopes.PURCHASE_ORDER]: {
      list: poList,
      scope: "purchase-order-tab-",
      id: queryParam.purchaseOrderId,
      sizeNumber: Math.max(...poList.map((po) => po.name.length)),
      sizeSubtitle: Math.max(...poList.map((po) => po.subTitle.length)),
    },
    [activityScopes.SHIPMENT]: {
      list: shipmentList,
      scope: "shipment-tab-",
      id: queryParam.shipmentId,
      sizeNumber: 6,
      sizeSubtitle: Math.max(
        ...shipmentList.map((shipment) => shipment.subTitle.length)
      ),
    },
    [activityScopes.SALES_ORDER]: {
      list: salesorderList,
      scope: activityScopes.SALES_ORDER,
      id: queryParam.shipmentId,
      sizeNumber: Math.max(...salesorderList.map((so) => so.name.length)),
      sizeSubtitle: Math.max(...salesorderList.map((so) => so.subTitle.length)),
    },
  };
};

export function getPOShipments({ shipments = [], purchaseOrder = {} }) {
  if (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds.length > 0) {
    const currentShipments = [];
    purchaseOrder.shipmentIds.forEach((shipmentId) => {
      const shipment = shipments.find((shipment) => shipment.id === shipmentId);
      if (shipment) {
        currentShipments.push(shipment);
      }
    });
    const sortShipments = currentShipments.sort(sortObjectsBy("number", false));
    return sortShipments.map((shipment, index) => ({ ...shipment, index }));
  }
  return [];
}
export async function hasShipmentRootFolderPermission({ shipment, user }) {
  function existsPermissionGroup({
    permissionGroupsDocument = {},
    permissionGroupsUser = {},
  }) {
    let exist = false;
    Object.keys(permissionGroupsDocument).forEach((key) => {
      if (permissionGroupsDocument[key] && !!permissionGroupsUser[key]) {
        exist = true;
      }
    });
    return exist;
  }
  const shipmentDocumentSnap = await getDocs(
    query(
      collection(
        firestore,
        `${dbTables.COMPANIES}/${shipment.companyId}/${dbTables.SHIPMENTS}/${shipment.id}/${dbTables.SHIPMENT_DOCUMENTS}`
      ),
      where("isShipmentRootTask", "==", true)
    )
  );
  const shipmentDocument = shipmentDocumentSnap.docs.map((doc) =>
    doc.data()
  )[0];
  if (!shipmentDocument) {
    return false;
  }
  if (
    user.role === userTypes.SUPER_ADMIN ||
    (shipmentDocument.permissionUsers &&
      !!shipmentDocument.permissionUsers[user.id]) ||
    existsPermissionGroup({
      permissionGroupsDocument: shipmentDocument.permissionGroups,
      permissionGroupsUser: user.permissionGroups,
    })
  ) {
    return true;
  }
  return false;
}

export { getShipmentTooltipLabel };
export { getShipmentInfo };
