import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import { sortObjectsBy, pathToString } from "../../helpers/helpers";
import { ErrorLogStyled } from "./styles";
import { getNestedCollectionFromFirestoreTable } from "../../actions/DataActions";
import { TableCell, TableRow } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import moment from "moment";
import { useParams } from "react-router-dom";

function ErrorLog() {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const listenErrorLog = useCallback(() =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([
        dbTables.COMPANIES,
        companyId,
        dbTables.API_ERROR_LOG,
      ]),
    })(dispatch)
  );

  let errorLogDB = useSelector((state) => state.data.api_error_log[companyId]);
  if (errorLogDB) {
    errorLogDB.sort(sortObjectsBy("date", true));
  }

  useEffect(() => {
    listenErrorLog();
  }, [companyId]);
  console.log("ERROR LOG DB", errorLogDB);
  const tbodyDisplay =
    errorLogDB &&
    errorLogDB.map((item) => {
      return (
        <TableRow key={item.id}>
          <TableCell>{moment(item.date).format("M/D/YY hh:mm:ss a")}</TableCell>
          <TableCell style={{ textAlign: "left" }}>
            {item.error_message_id}
          </TableCell>
          <TableCell>
            {item.description}{" "}
            <span
              style={{
                background: "lightgray",
                borderRadius: 9,
                padding: "2px 6px",
                fontSize: 12,
                fontWeight: 500,
                color: "#25486d",
              }}
            >
              {item.end_point}
            </span>{" "}
          </TableCell>
        </TableRow>
      );
    });

  const thead = ["Date and Time", "Error ID", "Description"];

  const theadDisplay = thead.map((thead) => {
    return (
      <TableCell
        style={thead === "Description" ? { width: "80%" } : { width: "10%" }}
      >
        {thead}
      </TableCell>
    );
  });

  return (
    <ErrorLogStyled className="errorLogContainer">
      <DataTable header={theadDisplay} className="row-hover-over">
        {tbodyDisplay}
      </DataTable>
    </ErrorLogStyled>
  );
}

export default ErrorLog;
