import moment from "moment";
import { getRandomId } from "../../helpers/helpers";
import { taskStatus, triggerTaskTypes } from "../../helpers/tasks";
import {
  PO_DIAMOND_STATUS,
  SALES_ORDER_DIAMOND_STATUS,
  SHIPMENT_DIAMOND_STATUS,
  TASK_FILTER,
  TASK_MOVED_STATUS,
} from "../../helpers/constants";
import { getShipmentValue } from "../../helpers/orderDashboard";
import {
  getCorrectTimezone,
  taskCompletionVerifier,
} from "../../helpers/ganttChart";
import { typeOfTask } from "../../api/types/dbTables";

export const getTaskUpdated = ({
  task,
  user,
  purchaseOrder,
  salesOrder,
  shipments,
  companyId,
}) => {
  const notificationId = getRandomId();
  const dayOffset = moment()
    .startOf("day")
    .diff(moment(task.finishDate).startOf("day"), "days");
  const { duration, startDate, finishDate } = taskCompletionVerifier({
    task: {
      ...task,
      startDate: getCorrectTimezone({
        date: task.startDate,
        isServerTime: true,
      }).valueOf(),
      finishDate: getCorrectTimezone({
        date: task.finishDate,
        isServerTime: true,
      }).valueOf(),
    },
  });
  const fieldsToUpdate = {
    complete: !task.complete,
    completedBy: user.id,
    dayOffset: dayOffset,
    status: taskStatus.COMPLETE,
    moved: dayOffset !== 0 ? TASK_MOVED_STATUS.WAITING : true,
    notificationId,
    PONumber: purchaseOrder.number,
    SONumber: salesOrder.number,
    customerId: salesOrder.customerId,
    factoryId:
      task.type === typeOfTask.SALES_ORDER ? "" : purchaseOrder.factoryId,
    duration,
    finishDate,
    startDate,
    triggerType: triggerTaskTypes.COMPLETE,
    templateDuration: task.duration,
  };
  const notificationTask = {
    ...fieldsToUpdate,
    ...task,
    salesOrderId: salesOrder.id,
    purchaseOrderId: purchaseOrder.id,
    customerId: salesOrder.customerId,
    factoryId: purchaseOrder.factoryId,
    PONumber: purchaseOrder.number,
    SONumber: salesOrder.number,
    shipmentNumber: getShipmentValue({
      shipments,
      shipmentId: task.shipmentId,
      field: "number",
    }),
    isAdHocTask: false,
    companyId: companyId,
    createdBy: user.id,
  };
  return { fieldsToUpdate, dayOffset, notificationId, notificationTask };
};

export const getListIndexAdHoc = ({
  salesOrderTasks,
  purchaseOrderTasks,
  adHocTask,
}) => {
  let POTasksLenght = purchaseOrderTasks.filter(
    (item) => item.stage === adHocTask.stage
  ).length;
  let SOTasksLenght = salesOrderTasks.filter(
    (item) => item.stage === adHocTask.stage
  ).length;

  const adHocIndex = POTasksLenght + SOTasksLenght + 1;
  return adHocIndex;
};

const filterTask = ({ task, showCompleted, listTask = [] }) => {
  if (showCompleted) {
    return true;
  }
  const currentTask = listTask.find((item) => item.id === task.id) || {};
  const hasPendingTask = !currentTask.complete || !currentTask.moved;
  return (
    !task.complete ||
    task.moved === TASK_MOVED_STATUS.WAITING ||
    (!task.moved &&
      (task.type === typeOfTask.SALES_ORDER ? hasPendingTask : true))
  );
};

export const filterTaskList = ({
  salesOrderTasks,
  taskFilter,
  user,
  showCompleted,
  purchaseOrderTasks,
  shipmentTasks,
  pendingToCompleted = {},
}) => {
  const SOTasks = [...salesOrderTasks.map((task) => ({ ...task }))]
    .filter((task) =>
      taskFilter === TASK_FILTER.MINE ? task.assignedTo === user.id : true
    )
    .filter((task) =>
      filterTask({
        task,
        showCompleted: showCompleted || pendingToCompleted[task.id],
        listTask: salesOrderTasks,
      })
    );
  const POTasks = [
    ...purchaseOrderTasks.filter(
      (task) => task.type === typeOfTask.PURCHASE_ORDER
    ),
    ...shipmentTasks,
  ]
    .filter((task) =>
      taskFilter === TASK_FILTER.MINE ? task.assignedTo === user.id : true
    )
    .filter((task) =>
      filterTask({
        task,
        showCompleted: showCompleted || pendingToCompleted[task.id],
        listTask: purchaseOrderTasks,
      })
    );
  return { SOTasks, POTasks };
};

export function getDiamondStatusFromOrders({
  purchaseOrder = {},
  salesOrder = {},
  shipment = {},
}) {
  if (
    purchaseOrder.diamondStatus === PO_DIAMOND_STATUS.LATE ||
    salesOrder.diamondStatus === SALES_ORDER_DIAMOND_STATUS.LATE ||
    (shipment && shipment.diamondStatus === SHIPMENT_DIAMOND_STATUS.LATE)
  ) {
    return PO_DIAMOND_STATUS.LATE;
  } else if (
    purchaseOrder.diamondStatus === PO_DIAMOND_STATUS.NEAR_DUE ||
    salesOrder.diamondStatus === SALES_ORDER_DIAMOND_STATUS.NEAR_DUE ||
    (shipment && shipment.diamondStatus === SHIPMENT_DIAMOND_STATUS.NEAR_DUE)
  ) {
    return PO_DIAMOND_STATUS.NEAR_DUE;
  } else {
    return "";
  }
}
