import { colors } from "../../assets/jss/variables";
import { Table, styled } from "@mui/material";

export const MainTableStyled = styled(Table)(() => ({
  "&.mainTable": {
    tableLayout: "fixed",
    borderCollapse: "collapse !Important",
    maxHeight: "95%",
    height: "auto",
    "& .sortedHeaderCell": {
      "& svg": {
        color: "#39f",
      },
    },
    "& .headerCell": {
      color: colors.primaryDark,
      background: colors.tableHeaderColor,
      zIndex: 100,
      minHeight: 45,
      top: 0,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "16.41px",
      height: 51,
      "& svg": {
        opacity: 0,
      },
      "&:hover": {
        "& svg": {
          opacity: 1,
        },
      },
    },
    "& .headerLabel": {
      display: "inline-block",
      verticalAlign: "middle",
    },
    "& .headerArrow": {
      verticalAlign: "middle",
      position: "absolute",
      top: "40%",
    },
    "& .columnSettings": {
      padding: "0px 4px !Important",
      height: 45,
      width: 35,
      backgroundColor: colors.skyBlue,
      color: colors.primaryDark,
    },
  },
}));
