import React, { useRef } from "react";
import { Cancel } from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@mui/material";

const MultiSelectContainerStyled = styled(FormControl)(({ theme }) => ({
  "&.multiSelectContainer": {
    marginTop: 8,
    marginLeft: 5,
    marginBottom: 8,
    width: 251,
    "& .select": {
      borderStyle: "groove",
      borderWidth: 1,
      borderColor: "#ccc",
      marginRight: 0,
      "&:before": { borderBottom: "0px" },
      "&:after": { borderBottom: "0px" },
      "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
        borderBottom: "0px",
      },
      "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):after": {
        borderBottom: "0px",
      },
    },
    "& .menu": {
      height: 300,
    },

    "& .chips": {
      display: "flex",
      flexWrap: "wrap",
    },
    "& .chip": {
      margin: 2,
      fontSize: "12px",
    },
    "& .noLabel": {
      marginTop: theme.spacing(3),
    },
  },
}));

function MultiSelectField({
  value,
  onChange,
  onDeletePermission = () => {},
  options = [],
  required = false,
  disabled,
}) {
  const selectRef = useRef(null);
  return (
    <MultiSelectContainerStyled
      className="multiSelectContainer"
      ref={selectRef}
      id="form-multiselect-container"
    >
      <Select
        className={"select"}
        id="mutiple-chip-checkbox"
        multiple
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        renderValue={(selected) => (
          <div className={"chips"}>
            {selected.map((value) => (
              <Chip
                className={"chip"}
                size="small"
                key={value.id}
                label={value.name}
                clickable
                sx={{ m: "2px" }}
                deleteIcon={<Cancel onMouseDown={(e) => e.stopPropagation()} />}
                onDelete={(e) => {
                  if (!disabled) onDeletePermission(value);
                  e.stopPropagation();
                }}
              />
            ))}
          </div>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250,
              width: 150,
              fontSize: 12,
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option} style={{ padding: 0 }}>
            <Checkbox
              checked={value.filter((v) => v.id === option.id).length > 0}
              size={"small"}
              color={"primary"}
              style={{ transform: "scale(0.8)", padding: 5 }}
            />
            <ListItemText
              disableTypography
              key={option.id}
              primary={option.name}
              style={{ fontSize: 12, fontWeight: 400 }}
            />
          </MenuItem>
        ))}
      </Select>
    </MultiSelectContainerStyled>
  );
}
export default MultiSelectField;
