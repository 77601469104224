import React, { useState } from "react";
import PermissionsToNofity from "./PermissionNotification";

function SetupNotifications({ match, onSubmit, item = {} }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <button
        style={{
          height: 25,
          background: "#ccc",
          color: "#000",
        }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Change users
      </button>

      {isOpen && (
        <PermissionsToNofity
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          item={item}
          onSubmit={({ item }) => {
            onSubmit({ item });
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default SetupNotifications;
