import React from "react";
import { TableCell } from "@mui/material";
import { TableRowStyled } from "./styles";

function RowEmptySimple({ columns, searchText }) {
  const style = {
    paddingLeft: 365,
    textAlign: "left",
  };
  return (
    <TableRowStyled key="SKU_empty_row" className="body">
      <TableCell
        className="emptyCellStyle"
        key="SKU_empty"
        style={style}
        id="footerCell"
        colSpan={columns.reduce((value, current) => {
          if (value.id) {
            return 2;
          } else {
            return (value = value + (current.subHeader ? 0 : 1));
          }
        })}
        align={"center"}
      >
        {!searchText
          ? "This table is empty"
          : "The current filter selection does not have any results"}
      </TableCell>
    </TableRowStyled>
  );
}

export default RowEmptySimple;
