import React, { useState, useEffect } from "react";
import PlayIcon from "../../assets/flag-icons/play-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import ReportPopover from "./ReportPopover";
import { sortObjectsBy } from "../../helpers/helpers";
import SearchBox from "../../components/SearchBox/SearchBox";
import FavoriteStar from "../../assets/flag-icons/favorite-star.png";
import ButtonFilter, {
  BUTTON_FILTER_BOX_SHADOW,
} from "../../components/PurchaseOrderList/ButtonFilter";
import { useFavorites, useFavoritesList } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import { useUser } from "../../hooks/user";
import FavoriteStarInactive from "../../assets/flag-icons/star_outline.png";
import { addFavorite, removeFavorite } from "../../helpers/favoritesHelper";
import Mark from "mark.js";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { Modal, ClickAwayListener } from "@mui/material";
import { ReportDrawerStyled } from "../ReportScreen/styles";
import { cx } from "@emotion/css";
import { StarIcon } from "../../helpers/iconSvgPath";

const ReportDrawer = ({
  open,
  handleIsOpen,
  onChange,
  reports,
  currentReport = {},
}) => {
  const [closing, setClosing] = useState(false);
  const [divRef, setDivRef] = useState({ current: undefined });
  const [openModal, setOpenModal] = useState(false);
  const [filters, setFilters] = useState({
    favorite: false,
    searchbox: "",
  });
  useEffect(() => {
    if (open && closing) {
      setTimeout(() => setClosing(false), 200);
    }
  }, [open]);

  const favorites = useFavoritesList({ dbTable: dbTables.REPORT_FAVORITES });
  const favoritesDB = useFavorites({ dbTable: dbTables.REPORT_FAVORITES });
  const user = useUser();

  function getFilteredReports({ reports = [], filters }) {
    let reportsCpy = [...reports];
    if (filters.favorite) {
      reportsCpy = reportsCpy.filter((report) => favorites.includes(report.id));
    }
    if (filters.searchbox) {
      reportsCpy = reportsCpy.filter((report) =>
        report.name.toLowerCase().includes(filters.searchbox.toLowerCase())
      );
    }
    return reportsCpy.sort(sortObjectsBy("name", false));
  }

  useEffect(() => {
    performMark(filters.searchbox);
  }, [filters.searchbox]);

  useEffect(() => {
    document.getElementById("generic-searchbox").focus();
  }, []);

  const markInstance = new Mark(document.getElementById("reports-wrapper"));
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  return (
    <ReportDrawerStyled
      className={cx("drawerRoot", {
        closeDrawer: !open,
      })}
    >
      <ClickAwayListener
        onClickAway={() => {
          console.log(closing, open);
          if (!closing && open) {
            handleIsOpen();
          }
        }}
      >
        <div
          ref={(el) =>
            el && !divRef.current ? setDivRef({ current: el }) : null
          }
          className={cx("containerDrawer", {
            slideLeft: !open,
            slideRight: open,
          })}
        >
          <div className={"closeIconContainer"}>
            <CloseIcon
              className="note-secction-cancel-icon"
              onClick={() => handleIsOpen()}
              style={{ position: "absolute", right: 15, top: 15 }}
            />
          </div>
          <div className={"headerContainer"}>
            {/* FAVORITE BUTTON AND SEARCHBOX */}

            <ButtonFilter
              buttonId="reports-view-button-filter-favorite"
              value={filters.favorite}
              onClick={() =>
                setFilters({ ...filters, favorite: !filters.favorite })
              }
              className="smallBox"
              style={{
                width: 40,
              }}
              boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
            >
              <StarIcon />
            </ButtonFilter>

            <SearchBox
              placeholder="Search report titles"
              value={filters.searchbox}
              filters={filters}
              onDebounceValue={(filters, value) => {
                setFilters({ ...filters, searchbox: value });
              }}
              hasBeenReseted={() => setFilters({ ...filters, searchbox: "" })}
            />
          </div>
          <div id="reports-wrapper">
            {getFilteredReports({ filters, reports }).map((report) => {
              const isSelected = report.id === currentReport.id;
              return (
                <div className={"reportListText"} key={report.id}>
                  {isSelected && <div className="blue-bar" />}
                  <div className="favorite-container">
                    {favorites.includes(report.id) ? (
                      <img
                        onClick={() =>
                          removeFavorite({
                            favoritesDB,
                            id: report.id,
                          })
                        }
                        src={FavoriteStar}
                        alt=""
                        width={16}
                        height={16}
                      />
                    ) : (
                      <img
                        onClick={() =>
                          addFavorite({
                            dbTable: dbTables.REPORT_FAVORITES,
                            id: report.id,
                            user,
                            favoritesDB,
                          })
                        }
                        className="favorite-inactive-star"
                        src={FavoriteStarInactive}
                        alt=""
                        width={16}
                        height={16}
                      />
                    )}
                  </div>

                  <span
                    className="report-name"
                    onClick={() => {
                      onChange({ report });
                      handleIsOpen();
                    }}
                  >
                    {report.name}
                  </span>
                  <div className="icons-container">
                    <ReportPopover report={report} />
                    {report.loomEmbed ? (
                      <TooltipTD label="Explanation video">
                        <img
                          src={PlayIcon}
                          width={18}
                          height={18}
                          alt="video"
                          onClick={() => setOpenModal(report.id)}
                        />
                      </TooltipTD>
                    ) : (
                      <div
                        style={{ width: 18, height: 18, margin: "0px 4px" }}
                      />
                    )}
                    {openModal && openModal === report.id && (
                      <Modal
                        open={openModal}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex: "auto",
                        }}
                        onClose={() => setOpenModal(false)}
                      >
                        <iframe
                          title={report.name}
                          width="940"
                          height="529"
                          src={report.loomEmbed}
                          frameborder="0"
                          webkitallowfullscreen={true}
                          mozallowfullscreen={true}
                          allowFullScreen={true}
                        ></iframe>
                      </Modal>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </ClickAwayListener>
    </ReportDrawerStyled>
  );
};

export default ReportDrawer;
