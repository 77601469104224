import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { dbTables } from "../../api/types/dbTables";
import TagsModal from "../../components/Modal/TagsModal";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { LOCAL_STORAGE_KEY, SHIPMENT_STATUS } from "../../helpers/constants";
import {
  getDashboardSearchPath,
  getTagsLabel,
  isTradeDashEmployee,
} from "../../helpers/helpers";
import { FolderIcon } from "../../helpers/iconSvgPath";
import { getTagStatus } from "../../helpers/salesOrder";
import {
  getShipmentBadge,
  hasShipmentRootFolderPermission,
} from "../../helpers/shipments";
import { useStorage } from "../../hooks";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";

function ShipmentItem({
  itemShipment,
  tags,
  isAllowed,
  user,
  filterTag,
  onUpdateItem = () => {},
  salesOrder,
  purchaseOrderId,
  onOpenAttentionModal,
  onSetBackdrop,
}) {
  const shipmentTags = itemShipment.tags || [];
  const isVoided = itemShipment.status === SHIPMENT_STATUS.VOIDED;
  const navigate = useNavigate();
  const [projectTaskStorage, setProjectTaskStorage] = useStorage(
    LOCAL_STORAGE_KEY.projectTasks
  );

  const onChangeDocumentTab = (id) => {
    const documentTab = projectTaskStorage.documentTab;
    setProjectTaskStorage("documentTab", {
      ...documentTab,
      [id]: 1,
    });
  };

  return (
    <div className={"number-table-container"}>
      {isAllowed("see_tags") && (
        <TagsModal
          key={itemShipment.id}
          label={getTagsLabel(tags, shipmentTags).tagsString}
          tags={getTagsLabel(tags, shipmentTags).tagsArray}
          allTags={tags}
          item={itemShipment}
          currentUser={user}
          tagReference={dbTables.SHIPMENTS}
          onUpdateItem={onUpdateItem}
          isThereItemRef={false}
          isReadOnly={isTradeDashEmployee(user)}
          status={getTagStatus({
            tags: shipmentTags,
            currentTags: filterTag,
          })}
        />
      )}
      <NavLink
        to={
          isVoided
            ? false
            : getDashboardSearchPath({
                salesOrderId: salesOrder.id,
                purchaseOrderId: purchaseOrderId,
                shipmentId: itemShipment.id,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
              })
        }
        style={{
          color: "#25486D",
          textDecoration: "none",
          overflow: "visible",
          marginLeft: 0,
        }}
        id="list-view-content-shipment"
      >
        {getShipmentBadge({
          shipment: itemShipment,
          styles: { marginBotton: 2 },
          classes: shipmentBadgeClass.mediumInverted,
        })}
      </NavLink>
      {!isVoided && (
        <div
          id="list-view-content-shipmentfolder"
          className={"folder-shipment-icon"}
          onClick={async (ev) => {
            const hasPermision = await hasShipmentRootFolderPermission({
              shipment: itemShipment,
              user,
            });
            if (!hasPermision) {
              onOpenAttentionModal();
            } else {
              navigate(
                getDashboardSearchPath({
                  salesOrderId: salesOrder.id,
                  purchaseOrderId: purchaseOrderId,
                  shipmentId: itemShipment.id,
                  fileId: itemShipment.id,
                  section: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                })
              );
              onChangeDocumentTab(purchaseOrderId);
            }
          }}
        >
          <FolderIcon size={12} style={{ marginTop: 6, marginLeft: 7 }} />
        </div>
      )}
    </div>
  );
}

export default ShipmentItem;
