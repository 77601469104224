import React, { useState, useEffect } from "react";
import LateIcon from "../../assets/flag-icons/red_diamond.svg";
import LateIconYellow from "../../assets/flag-icons/orange_diamond.svg";
import GreenDiamond from "../../assets/flag-icons/green_diamond.svg";
import WhiteDiamond from "../../assets/flag-icons/white_diamond.svg";
import {
  getDateByTimezone,
  getDashboardSearchPath,
  getTheDate,
} from "../../helpers/helpers";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { NavLink } from "react-router-dom";
import { useStorage } from "../../hooks";
import {
  BACKDROP_TYPE,
  headerColumns,
  TOOLTIP_TYPE,
} from "../../helpers/constants";
import { getUserAvatar } from "../../helpers/users";
import { getNumberOfLateDays } from "../../helpers/tasks";
import CustomCheckbox from "../../components/Inputs/CustomCheckbox";
import { getShipmentBadge } from "../../helpers/shipments";
import { typeOfTask } from "../../api/types/dbTables";
import { getBackgroundColorByType } from "../../helpers/orderDashboard";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../actions/DataActions";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { TableCell } from "@mui/material";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";

function POTaskRow({
  task: item,
  index,
  onComplete,
  headerCells,
  companyUsers,
}) {
  const [completing, setCompleting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [, setStorage] = useStorage(item.purchaseOrderId);
  const dispatch = useDispatch();
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };

  const setCurrentShipment = (shipment, isHighlighting) => {
    if (item.type === typeOfTask.SHIPMENT && shipment) {
      if (isHighlighting) {
        setBackdrop({ type: BACKDROP_TYPE.SHIPMENT_INFO });
      }
    }
  };

  useEffect(() => {
    if (completed) {
      onComplete(item, index);
      setCompleting(false);
    }
  }, [completed]);

  function POTaskRowSelector({ column, enabled, index }) {
    const currentShipment = item.shipment || {};
    const purchaseOrderId = item.purchaseOrderId || item.purchaseOrder.id;
    switch (column) {
      case headerColumns.DIAMOND:
        return (
          enabled && (
            <TableCell className="diamond">
              {item.status === "late" && (
                <TooltipTD
                  label={getNumberOfLateDays({ task: item })}
                  type={TOOLTIP_TYPE.DIAMOND}
                >
                  <img src={LateIcon} width={12} height={16} alt="" />
                </TooltipTD>
              )}
              {item.status === "nearDue" && (
                <TooltipTD
                  label="Due Today or Tomorrow"
                  type={TOOLTIP_TYPE.DIAMOND}
                >
                  <img src={LateIconYellow} width={12} height={16} alt="" />
                </TooltipTD>
              )}
              {item.status === "inProgress" && (
                <TooltipTD label="In Progress" type={TOOLTIP_TYPE.DIAMOND}>
                  <img src={GreenDiamond} width={12} height={16} alt="" />
                </TooltipTD>
              )}
              {item.status === "notStarted" && (
                <TooltipTD label="Not Started" type={TOOLTIP_TYPE.DIAMOND}>
                  <img src={WhiteDiamond} width={12} height={16} alt="" />
                </TooltipTD>
              )}
              {item.status === "complete" && (
                <TooltipTD label="Completed">
                  <CustomCheckbox
                    disabled={true}
                    checked={true}
                    styles={{
                      background: "white",

                      color: "#6B7A89",
                      margin: 0,
                    }}
                    size="small"
                  />
                </TooltipTD>
              )}
            </TableCell>
          )
        );

      case headerColumns.TASK:
        return (
          enabled && (
            <TableCell className="description">
              <div className="description-wrapper">
                {item.type === typeOfTask.SHIPMENT && (
                  <div className="shipment-badge-container">
                    <NavLink
                      to={getDashboardSearchPath({
                        salesOrderId: item.salesOrderId,
                        purchaseOrderId,
                        shipmentId: currentShipment.id,
                        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                      })}
                      style={{
                        textDecoration: "unset",
                        overflow: "visible",
                        marginLeft: -5,
                      }}
                    >
                      {getShipmentBadge({
                        shipment: currentShipment,
                        classes: shipmentBadgeClass.secondary,
                        styles: { margin: "0px 4px 0px 0px" },
                      })}
                    </NavLink>
                  </div>
                )}
                <div className="task-description-container">
                  <TooltipTD label={item.description}>
                    <NavLink
                      className="ellipsisText"
                      to={getDashboardSearchPath({
                        salesOrderId: item.salesOrderId,
                        purchaseOrderId,
                        shipmentId: currentShipment.id,
                        taskId: item.id,
                        section:
                          ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                      })}
                      onClick={() => {
                        setCurrentShipment(currentShipment);
                        setStorage("taskTab", 0);
                        setStorage("taskFilter", "ALL");
                        if (item.complete) {
                          setStorage("showCompleted", true);
                        }
                      }}
                      onContextMenu={() => {
                        setStorage("taskTab", 0);
                        setStorage("taskFilter", "ALL");
                        setCurrentShipment(currentShipment);
                        if (item.complete) {
                          setStorage("showCompleted", true);
                        }
                      }}
                    >
                      {item.description}
                    </NavLink>
                  </TooltipTD>
                </div>
              </div>
            </TableCell>
          )
        );

      case headerColumns.ASSIGNED_TO:
        return (
          enabled && (
            <TableCell className="assignedToName">
              <div style={{ display: "flex", alignItems: "center" }}>
                {getUserAvatar({
                  user: companyUsers.find(
                    (user) => user.id === item.assignedTo
                  ),
                  styles: {
                    width: 24,
                    height: 24,
                    fontSize: 13,
                    outline: "2px solid #000",
                  },
                })}
                <span style={{ position: "relative", paddingLeft: 5 }}>
                  {item.assignedToName}
                </span>
              </div>
            </TableCell>
          )
        );

      case headerColumns.START_DATE:
        return (
          enabled && (
            <TableCell className="date" id="startDate">
              {getTheDate(item.startDate)}
            </TableCell>
          )
        );

      case headerColumns.DUE_DATE:
        return (
          enabled && (
            <TableCell className="date" id="dueDate">
              {getDateByTimezone({
                date: item.finishDate,
              })}
            </TableCell>
          )
        );

      case headerColumns.ACTION:
        return (
          enabled && (
            <TableCell className={"checkBoxCell"} id="actionButton">
              {!item.complete ? (
                <div className={"checkBox"}>
                  <CustomCheckbox
                    checked={item.complete || false}
                    styles={{
                      width: 17,
                      height: 17,
                      background: "white",
                    }}
                    onChange={() => {
                      setCompleting(true);
                      setTimeout(() => setCompleted(true), 1000);
                    }}
                  />
                  <span className={"checkBoxMarkDone"}>Mark Done</span>
                </div>
              ) : (
                <div>
                  <CustomCheckbox
                    checked={item.complete}
                    styles={{ width: 17, height: 17, color: "#6B7A89" }}
                  />
                </div>
              )}
              {completing && <div className={"completing-task"} />}
            </TableCell>
          )
        );
      case headerColumns.EXTRA:
        return <TableCell />;

      default:
        return <TableCell />;
    }
  }

  return (
    <MainRowStyled
      key={
        item.purchaseOrderId
          ? item.id + item.purchaseOrderId
          : item.id + item.salesOrderId
      }
      className={mainRowClasses.POTaskRow}
      style={{
        background: getBackgroundColorByType({ type: item.type }),
      }}
    >
      {headerCells.map((header) =>
        POTaskRowSelector({
          column: header.name,
          enabled: header.enabled,
          index: item.id + header.name,
        })
      )}
    </MainRowStyled>
  );
}

export default POTaskRow;
