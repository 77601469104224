import { colors } from "../../../assets/jss/variables";
import AppConfig from "../../../constants/AppConfig";
import { Modal, Popover, styled } from "@mui/material";

export const ExpandedActivityModalStyled = styled(Modal)(({ theme }) => ({
  "&.modalActivity": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& :active": {
      "& .type": {
        opacity: 1,
      },
      "& .timestamp": {
        opacity: 1,
      },
      "& .icons": {
        opacity: 1,
      },
      "& .iconResize": {
        opacity: 1,
      },
      "& .bodyNoteExpand": {
        "&::-webkit-scrollbar": {
          backgroundColor: "#DFDDDD",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#B1B1B1",
        },
      },
    },
    "& .expandModalBody": {
      maxHeight: "calc(100vh - 130px)",
      zIndex: 2,
      "&:hover": {
        "& .activity-notification-read": {
          width: 8,
          height: 8,
          position: "absolute",
          background: "#fff",
          border: " 1px solid #39f",
          borderRadius: "50%",
          cursor: "pointer",
          top: "inherit",
        },
        "& .type": {
          opacity: 1,
        },
        "& .timestamp": {
          opacity: 1,
        },
        "& .icons": {
          opacity: 1,
        },
        "& .iconResize": {
          opacity: 1,
        },
        "& .bodyNoteExpand": {
          "&::-webkit-scrollbar": {
            backgroundColor: "#DFDDDD",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#B1B1B1",
          },
        },
      },
    },
    "& .note-detail": {
      maxHeight: "calc(100vh - 205px)",
    },
    "& .dragImages": {
      position: "absolute",
      top: 0,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: "4px 5px 0px 5px",
      "& .iconResize": {
        cursor: "grab",
        opacity: 0,
        "& :active": {
          cursor: "grabbing",
        },
      },
    },
    "& .bodyNoteExpand": {
      overflow: "auto",
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
      },
    },
  },
  "& .headerTitle": {
    paddingRight: 30,
    marginBottom: 4,
    fontWeight: 400,
    fontSize: 13,
    "& .type": {
      opacity: 0,
    },
    "& .timestamp": {
      opacity: 0,
    },
    "& .icons": {
      opacity: 0,
      fontSize: "10px !important",
      fontWeight: "400 !important",
    },
  },

  "& .activityStyle": {
    borderRadius: 4,
    color: colors.primaryDark,
    margin: `4px 6px 12px 16px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "96%",
    flexDirection: "column",
    lineHeight: 1.8,
    position: "relative",
    "& .iconVisibility": {
      opacity: 0,
    },
    "&:hover": {
      "& .readMore": {
        color: colors.primaryDark,
        borderBottom: `${colors.primaryDark} solid 0.5px`,
      },
      "& .iconVisibility": {
        opacity: 1,
      },
    },
    "& .headerNote": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "4px 14px 4px min(calc(20% - 35px),75px)",
      gap: 10,
      width: "100%",
      height: 28,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      "& .threadStyle": {
        background: "#FFFFFF",
        borderRadius: 4,
        paddingInline: 8,
        cursor: "pointer",
        fontSize: 13,
        fontWeight: 400,
        color: "#000000",
        height: 20,
        display: "flex",
        alignItems: "center",
        margin: "auto",
        overflow: "hidden",
        "& span": {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
      "& .SALES_ORDER": {
        border: "1px solid #6D8F0B",
      },
      "& .PURCHASE_ORDER": {
        border: "1px solid #3F96E6",
      },
      "& .SHIPMENT": {
        border: "1px solid #CB5EDE",
      },
      "& .QUOTE": {
        border: "1px solid #6D8F0B",
      },
      "& .PURCHASE_QUOTE": {
        border: "1px solid #3F96E6",
      },
      "& .icons": {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: 700,
        fontSize: 12,
        alignItems: "center",
        width: 85,
      },
      "& .scope": {
        fontSize: 13,
      },
      background: "#A4D2FC",
    },
    "& .activity-scope-label": {
      position: "absolute",
      right: 0,
      zIndex: 2,
      top: 0,
      width: 45,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 700,
      height: 28,
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        display: "block",
        flexDirection: "row",
        // justifyContent: "space-between",
        // alignItems: "center",
        padding: "4px 14px 4px 74px",
        gap: 10,
        width: "100%",
        height: 28,
        marginBottom: 14,
        "& .icons": {
          display: "flex",
          justifyContent: "flex-end",
          fontWeight: 700,
          fontSize: 12,
          alignItems: "center",
        },
        background: "#A4D2FC",
        position: "absolute",
        left: 0,
      },
    },
  },

  "& .SON": {
    outline: `2px solid ${colors.salesOrderBorderColor}`,
    backgroundColor: colors.salesOrderBackgroundColor,
    "& .readMore": {
      backgroundColor: colors.salesOrderBackgroundColor,
    },
    "& .headerNote": {
      backgroundColor: colors.salesOrderBorderColor,
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: colors.salesOrderBorderColor,
      },
    },
  },
  "& .PON": {
    outline: "2px solid #A4D2FC",
    backgroundColor: colors.purchaseOrderBackgroundColor,
    "& .readMore": {
      backgroundColor: colors.purchaseOrderBackgroundColor,
    },
    "& .headerNote": {
      backgroundColor: "#A4D2FC",
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: "#A4D2FC",
      },
    },
  },

  "& .SHIPMENT": {
    outline: "2px solid #E5BFEC",
    backgroundColor: colors.shipmentBadge,
    "& .readMore": {
      backgroundColor: colors.shipmentBadge,
    },
    "& .headerNote": {
      backgroundColor: "#E5BFEC",
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: "#E5BFEC",
      },
    },
  },

  "& .bodyNote": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 7,
    "& .avatarNote": {
      width: 44,
      height: 40,
      marginInline: 12,
      position: "absolute",
    },
    "& .readGrid": {
      width: 25,
      position: "absolute",
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .activity-notification-unread": {
        width: 8,
        height: 8,
        top: 22,
        background: "#39f",
        border: "1px solid #39f",
        borderRadius: "50%",
        cursor: "pointer",
      },
    },
    "& .note-detail": {
      width: "100%",
      overflowWrap: "break-word",
      padding: "14px 20px 0px 74px",
      "& p": {
        marginBottom: 0,
        fontSize: 12,
        "& .styles-activity-stream-from-backend-red": {
          color: "red",
        },
        "& .styles-activity-stream-from-backend-green": {
          color: "green",
        },
        "& .styles-activity-stream-from-backend-bold": {
          fontWeight: 700,
        },
        "& .checkbox-input-done": {
          position: "p",
          top: 2,
        },
      },
      "& .activityNote": {
        fontSize: 14,
        fontFamily: "monospace",
        "& p": {
          fontSize: 14,
          overflowWrap: "anywhere",
        },
        "& figure": {
          margin: 0,
          height: "auto",
        },
      },
    },
    "& .SONMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: "1px solid #948686",
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },
    "& .documentsMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: "1px solid #948686",
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
      "&:hover": {
        background: " #948686",
      },
      "&:active": {
        background: "#615E5E",
        border: "1px solid #615E5E",
      },
    },
    "& .SHIPMENTMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: "1px solid #948686",
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },

    "& .PONMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: `1px solid ${colors.darkGray}`,
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },
    "& .mentionsShipmenClass": {
      background: colors.shipmentBadge,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: `2px solid ${colors.shipmentBorderColor}`,
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },
    "& .mentionsShipmenClassUpdated": {
      background: colors.shipmentBadge,
      borderRadius: 4,
      padding: "1px 7px",
      border: `2px solid ${colors.shipmentBorderColor}`,
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },
    "& .reverseShipmentClass": {
      background: "#ffffff",
      borderRadius: 4,
      padding: " 1px 7px",
      border: `2px solid ${colors.shipmentBorderColor}`,
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },

    "& ul": {
      paddingInlineStart: 30,
      display: "flow-root",
      "& li": {
        display: "list-item !important",
        listStyleType: "disc",
      },
    },
    "& ol": {
      paddingInlineStart: 30,
      display: "flow-root",
      "& li": {
        display: "list-item !important",
      },
      "& ol": {
        paddingInlineStart: 60,
      },
    },
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 12,
    width: "-webkit-fill-available",
    margin: "12px 16px 12px 74px",
    "& .username": { color: "#9D9D9D" },
    "& .readMore": {
      fontStyle: "italic",
      fontWeight: 400,
      color: "#9D9D9D",
      height: 16,
      width: 68,
      textAlign: "center",
      borderBottom: "#9D9D9D solid 0.5px",
      cursor: "pointer",
      marginRight: 16,
      padding: "0px 4px 0px 6px",
    },
    "& .text-info-edit": {
      color: "#9D9D9D",
    },
  },
}));

export const ActivityStyled = styled("div")(({ theme }) => ({
  "&.animated": {
    position: "relative",
    transition: "all 1s ease-in-out",
  },
  "& .expandIcon": {
    position: "absolute",
    zIndex: 10,
    bottom: -9,
    right: -9,
    borderRadius: 15,
    boxShadow: "-1px 1px 5px 1px rgba(0,0,0, 0.30)",
    background: "white",
    cursor: "pointer",
    "&:hover": {
      "& .lineColor": {
        stroke: "white",
      },
      "& .circleColor": {
        fill: "#1581BF",
      },
    },
  },
  "& .headerTitle": {
    paddingRight: 30,
    marginBottom: 4,
    fontWeight: 400,
    fontSize: 13,
    "& .type": {
      opacity: 0,
    },
    "& .timestamp": {
      opacity: 0,
    },
    "& .icons": {
      opacity: 0,
      fontSize: "10px !important",
      fontWeight: "400 !important",
    },
  },

  "& .activityStyle": {
    borderRadius: 4,
    color: colors.primaryDark,
    margin: `4px 6px 12px 16px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "96%",
    flexDirection: "column",
    lineHeight: 1.8,
    position: "relative",
    "& .iconVisibility": {
      opacity: 0,
    },
    "&:hover": {
      "& .readMore": {
        color: colors.primaryDark,
        borderBottom: `${colors.primaryDark} solid 0.5px`,
      },
      "& .iconVisibility": {
        opacity: 1,
      },
    },
    "& .headerNote": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "4px 14px 4px min(calc(20% - 35px),75px)",
      gap: 10,
      width: "100%",
      height: 28,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      "& .threadStyle": {
        background: "#FFFFFF",
        borderRadius: 4,
        paddingInline: 8,
        cursor: "pointer",
        fontSize: 13,
        fontWeight: 400,
        color: "#000000",
        height: 20,
        display: "flex",
        alignItems: "center",
        margin: "auto",
        overflow: "hidden",
        "& span": {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
      "& .SALES_ORDER": {
        border: "1px solid #6D8F0B",
      },
      "& .PURCHASE_ORDER": {
        border: "1px solid #3F96E6",
      },
      "& .SHIPMENT": {
        border: "1px solid #CB5EDE",
      },
      "& .QUOTE": {
        border: "1px solid #6D8F0B",
      },
      "& .PURCHASE_QUOTE": {
        border: "1px solid #3F96E6",
      },
      "& .icons": {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: 700,
        fontSize: 12,
        alignItems: "center",
        width: 85,
      },
      "& .scope": {
        fontSize: 13,
      },
      background: "#A4D2FC",
    },
    "& .activity-scope-label": {
      position: "absolute",
      right: 0,
      zIndex: 2,
      top: 0,
      width: 45,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 700,
      height: 28,
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        display: "block",
        flexDirection: "row",
        // justifyContent: "space-between",
        // alignItems: "center",
        padding: "4px 14px 4px 74px",
        gap: 10,
        width: "100%",
        height: 28,
        marginBottom: 14,
        "& .icons": {
          display: "flex",
          justifyContent: "flex-end",
          fontWeight: 700,
          fontSize: 12,
          alignItems: "center",
        },
        background: "#A4D2FC",
        position: "absolute",
        left: 0,
      },
    },
  },

  "& .SON": {
    outline: `2px solid ${colors.salesOrderBorderColor}`,
    backgroundColor: colors.salesOrderBackgroundColor,
    "& .readMore": {
      backgroundColor: colors.salesOrderBackgroundColor,
    },
    "& .headerNote": {
      backgroundColor: colors.salesOrderBorderColor,
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: colors.salesOrderBorderColor,
      },
    },
  },
  "& .PON": {
    outline: "2px solid #A4D2FC",
    backgroundColor: colors.purchaseOrderBackgroundColor,
    "& .readMore": {
      backgroundColor: colors.purchaseOrderBackgroundColor,
    },
    "& .headerNote": {
      backgroundColor: "#A4D2FC",
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: "#A4D2FC",
      },
    },
  },

  "& .SHIPMENT": {
    outline: "2px solid #E5BFEC",
    backgroundColor: colors.shipmentBadge,
    "& .readMore": {
      backgroundColor: colors.shipmentBadge,
    },
    "& .headerNote": {
      backgroundColor: "#E5BFEC",
    },
    "& .note-detail": {
      "& .subHeaderNote": {
        backgroundColor: "#E5BFEC",
      },
    },
  },

  "& .bodyNote": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 7,
    "& .avatarNote": {
      width: 44,
      height: 40,
      marginInline: 12,
      position: "absolute",
    },
    "& .readGrid": {
      width: 25,
      position: "absolute",
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .activity-notification-unread": {
        width: 8,
        height: 8,
        top: 22,
        background: "#39f",
        border: "1px solid #39f",
        borderRadius: "50%",
        cursor: "pointer",
      },
    },
    "& .note-detail": {
      width: "100%",
      overflowWrap: "break-word",
      padding: "14px 20px 0px 74px",
      "& p": {
        marginBottom: 0,
        fontSize: 12,
        "& .styles-activity-stream-from-backend-red": {
          color: "red",
        },
        "& .styles-activity-stream-from-backend-green": {
          color: "green",
        },
        "& .styles-activity-stream-from-backend-bold": {
          fontWeight: 700,
        },
        "& .checkbox-input-done": {
          position: "p",
          top: 2,
        },
      },
      "& .activityNote": {
        fontSize: 14,
        fontFamily: "monospace",
        "& p": {
          fontSize: 14,
          overflowWrap: "anywhere",
        },
        "& figure": {
          margin: 0,
          height: "auto",
        },
      },
    },
    "& .SONMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: "1px solid #948686",
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },
    "& .documentsMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: "1px solid #948686",
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
      "&:hover": {
        background: " #948686",
      },
      "&:active": {
        background: "#615E5E",
        border: "1px solid #615E5E",
      },
    },
    "& .SHIPMENTMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: "1px solid #948686",
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },

    "& .PONMentionClass": {
      background: colors.gray,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: `1px solid ${colors.darkGray}`,
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },
    "& .mentionsShipmenClass": {
      background: colors.shipmentBadge,
      borderRadius: 4,
      padding: "1px 7px",
      marginInline: 3,
      border: `2px solid ${colors.shipmentBorderColor}`,
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },
    "& .mentionsShipmenClassUpdated": {
      background: colors.shipmentBadge,
      borderRadius: 4,
      padding: "1px 7px",
      border: `2px solid ${colors.shipmentBorderColor}`,
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },
    "& .reverseShipmentClass": {
      background: "#ffffff",
      borderRadius: 4,
      padding: " 1px 7px",
      border: `2px solid ${colors.shipmentBorderColor}`,
      "& *": {
        fontSize: "13px !important",
        textDecoration: "unset !important",
        fontStyle: "unset !important",
        color: `${colors.primaryDark} !important`,
      },
    },

    "& ul": {
      paddingInlineStart: 30,
      display: "flow-root",
      "& li": {
        display: "list-item !important",
        listStyleType: "disc",
      },
    },
    "& ol": {
      paddingInlineStart: 30,
      display: "flow-root",
      "& li": {
        display: "list-item !important",
      },
      "& ol": {
        paddingInlineStart: 60,
      },
    },
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 12,
    width: "-webkit-fill-available",
    margin: "12px 16px 12px 74px",
    "& .username": { color: "#9D9D9D" },
    "& .readMore": {
      fontStyle: "italic",
      fontWeight: 400,
      color: "#9D9D9D",
      height: 16,
      width: 68,
      textAlign: "center",
      borderBottom: "#9D9D9D solid 0.5px",
      cursor: "pointer",
      marginRight: 16,
      padding: "0px 4px 0px 6px",
    },
    "& .text-info-edit": {
      color: "#9D9D9D",
    },
  },
}));

export const ReadByPopoverContainerStyled = styled(Popover)(({ theme }) => ({
  "&.readByPopoverContainer": {
    "& .readByPopoverContainer": {
      padding: 20,
      minWidth: 260,
      "& span": {
        fontSize: 11,
        margin: "0px 6px",
        minWidth: 60,
      },
      "& .read-user-item": {
        display: "flex",
        alignItems: "center",
        height: 24,
        color: AppConfig.themeColors.blueTextColor,
      },
      "& .unread-user-item": {
        display: "flex",
        alignItems: "center",
        height: 24,
        opacity: 0.4,
      },
    },
  },
}));
