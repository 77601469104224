import { styled } from "@mui/material";

export const ResizeTabStyled = styled("div")(({ theme }) => ({
  "&.resizeTabContainer": {
    width: 15,
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
  "& .rotatedObj": {
    width: 300,
    height: 300,
    display: "flex",
    position: "relative",
    transform: "rotate(-90deg)",
    margin: 2,
    "& span": {
      color: "#c8c8c8",
      fontSize: 10,
      "& svg": {
        height: 13,
        width: 13,
        objectFit: "contain",
        position: "absolute",
        top: -2,
        left: -15,
        transform: "rotateX(180deg) rotateZ(90deg)",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    "& img": {
      height: 36,
      width: 10,
      display: "inline-block",
      transformOrigin: "center",
      transform: "rotate(90deg)",
      margin: "2px 0",
      position: "absolute",
      top: -15,
      left: 270,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
}));
