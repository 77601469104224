import { styled } from "@mui/material";

export const LegendContainer = styled("div")(() => ({
  "&.root": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  "& .legendContainer": {
    display: "flex",
    fontSize: 12,
    alignItems: "center",
    right: "4px",
    "& span": {
      marginLeft: 10,
      marginRight: 10,
    },
  },
}));
