import { colors } from "../../assets/jss/variables";
import AppConfig from "../../constants/AppConfig";
import { darkBlue, onHoverBackground } from "../../helpers/variableColors";
import { styled, keyframes, Popover, Collapse } from "@mui/material";
import { ArrowsToSortIcon } from "../../helpers/iconSvgPath";

const enableBorder = keyframes`
  0% {
    border: 1px solid ${colors.dividerColor};
  }
  25% {
    border: 2px solid ${colors.dividerColor};
  }
  50% {
    border: 3px solid ${colors.dividerColor};
  }
  75% {
    border: 4px solid ${colors.dividerColor};
  }
  100% {
    border: 4px solid ${colors.dividerColor};
  }
`;

const disableBorder = keyframes`
  0% {
    border: 4px solid ${colors.dividerColor};
  }
  25% {
    border: 4px solid ${colors.dividerColor};
  } 
  50% {
    border: 3px solid ${colors.dividerColor};
  }
  75% {
    border: 2px solid ${colors.dividerColor};
  }
  100% {
    border: 1px solid ${colors.dividerColor};
  }
`;

export const ProjectNotesContainerStyled = styled("div")(() => ({
  "&.orderDashboardNotesContainer": {
    flex: 1,
    position: "relative",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: "0 2px 3px 1px #dddddd",
    "& .mentions-filter": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: 6,
      "& .badge-notification-number": {
        backgroundColor: "#e02020",
        borderRadius: "50%",
        marginLeft: -20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        marginTop: -17,
        position: "relative",
        overflow: "hidden",
        textDecoration: "none",
        fontSize: 9,
        height: 12,
        width: 12,
        top: 0,
        right: -9,
        marginRight: 6,
        lineHeight: "12px",
        "&:hover": {
          cursor: "pointer",
        },
        "& .label-activity-number": {
          margin: 0,
          height: 11,
        },
      },
    },
    "& .content-card-header": {
      padding: "12px 20px 20px 16px",
      paddingBottom: 0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      fontSize: 14,
      position: "relative",
      fontWeight: "normal",
      "& .subContainerFilter": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: 8,
        paddingRight: 9,
      },
      "& .project-tasks": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: 40,
        "& .title": {
          fontSize: 23,
          fontWeight: "normal",
          paddingLeft: 4,
          color: colors.primaryDark,
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxHeight: 60,
          display: "flex",
          alignItems: " center",
        },

        "& .general-filters": {
          display: "flex",
          justifyContent: "space-between",
          fontSize: 11,
          position: "relative",
          "& .indicator": {
            position: "absolute",
            background: "rgb(204, 204, 204)",
            height: 2,
            width: "98%",
            top: 26,
            opacity: 0.6,
          },
        },
      },
    },
    "& .content-card-content": {
      flex: 1,
      fontSize: 13,
      fontWeight: "normal",
      position: "relative",
      color: colors.primaryDark,
      marginBottom: 8,
      borderRadius: 10,
      padding: "5px 2px 0px 2px",
      background: "#fff",
    },
  },
  "&.active": {
    animation: `${enableBorder} 1s ease-in`,
    border: `4px solid ${colors.dividerColor}`,
  },
  "&.inactive": {
    animation: `${disableBorder} 1s ease-in`,
    border: `1px solid ${colors.dividerColor}`,
  },

  "& .contentCenter": {
    height: "100%",
    display: "flex",
    width: "100%",
    "& .contentNotes": {
      width: "100%",
    },
    "& .content-card-content": {
      height: "80%",
    },
  },
  "&.PURCHASE_ORDER": {
    borderColor: `#3F96E6 !important`,
  },
  "&.SALES_ORDER": {
    borderColor: `#6D8F0B !important`,
  },
  "&.PURCHASE_QUOTE": {
    borderColor: `#3F96E6 !important`,
  },
  "&.QUOTE": {
    borderColor: `#6D8F0B !important`,
  },
  "&.SHIPMENT": {
    borderColor: `#CB5EDE !important`,
  },
}));

export const TitleExpandModalStyled = styled("div")(() => ({
  "&.titleExpandModalContainerStyled": {
    position: "absolute",
    top: 32,
    left: 35,
    fontSize: 14,
    fontWeight: 400,
    display: "flex",
    gap: 8,
    "& .principalTitle": {
      color: colors.darkGray,
    },
    "& .subtitle": {
      color: colors.primaryDark,
      marginRight: 38,
    },
  },
}));

export const DocumentPanelContainerStyled = styled("div")(() => ({
  "&.documentPanelContainer": {
    background: "lightblue",
    flex: 1,
    position: "relative",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: "0 2px 3px 1px #dddddd",
    height: "100%",
    "& .content-card-header": {
      padding: "12px 24px 20px 16px",
      paddingBottom: 0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      fontSize: 14,
      position: "relative",
      fontWeight: "normal",
      "& .documents-title": {
        fontSize: 23,
        fontWeight: "normal",
        paddingLeft: 4,
        color: "#25486d",
        display: "flex",
        alignItems: "center",
      },
      "& .custom-tabs": {
        minHeight: "0px !important",
      },
      "& .custom-tabs-container": {
        height: 40,
        "& .SALES_ORDER": {
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
          border: `1px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activeSALES_ORDER": {
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
          border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
        "& .PURCHASE_ORDER": {
          background: `${AppConfig.themeColors.purchaseOrderBackgroundColor} !important`,
          border: `1px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activePURCHASE_ORDER": {
          background: `${AppConfig.themeColors.purchaseOrderBackgroundColor} !important`,
          border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
      },
    },

    "& .custom-tab": {
      padding: 0,
      minHeight: "0px !important",
      height: 40,
      fontSize: 15,
      backgroundColor: "#cbddf0",
      borderColor: "#90aed1",
      margin: "0px 6px",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      "&:active": {
        transform: "none",
      },
      "& .disabled": {
        backgroundColor: "#dce6f0",
        opacity: 0.3,
      },
      "& .reassigned": {
        maxWidth: "360px !important",
      },
    },

    "& .content-card-content": {
      flex: 1,
      fontSize: 13,
      fontWeight: "normal",
      position: "relative",
      color: colors.primaryDark,
      borderRadius: 10,
      padding: "20px 10px 0px 16px",
      background: "#fff",
      height: "100%",
      "& #folder-estructure-list": {
        height: "calc(100vh - 260px) !Important",
        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: 16,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
          borderRadius: 7,
        },
        "&::-webkit-scrollbar-track": {
          border: " solid 1px transparent",
        },
        "&:hover": {
          "&::-webkit-scrollbar-thumb": {
            boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
            borderBlock: "1px solid transparent",
            borderInline: "5px solid transparent",
          },
        },
      },
    },
    "& .documentScrollContainer": {
      paddingBottom: 0,
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
  },
}));

export const ShipmentTabsContainerStyled = styled("div")(() => ({
  "&.TabsContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 40,
    margin: "0px 16px 0px 16px",
    position: "relative",
    "& .custom-popover": {
      zIndex: 100,
      "& .custom-root": {
        maxHeight: "calc(100vh - 400px)",
        minWidth: 150,
        overflowY: "auto",
        "& .custom-menu-item": {
          color: colors.primaryDark,
          fontSize: 14,
        },
      },
    },
  },
  "& .customTabs": {
    margin: "8px 0px 0px -5px",
    position: "relative",
    bottom: -2,
  },
  "& .customTabIndicator": {
    display: "none",
  },
  "& .customTabsContainer": {
    height: 40,
    marginLeft: 24,
  },
  "& .scrollButtons": {
    width: 20,
  },
  "& .customTab": {
    padding: "0px 12px",
    minHeight: "0px !Important",
    height: 40,
    fontSize: 15,
    backgroundColor: "#EFF8FF",
    margin: "0px 6px",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    "& .disabled": {
      backgroundColor: "#dce6f0",
      opacity: 0.3,
    },

    "& .reassigned": {
      width: 300,
      "& .wrapped-po": {
        width: "100%",
        "& .tabLabel": {
          width: "100% !Important",
        },
      },
    },
    "& .wrapped-shipment": {
      minHeight: "0px !important",
      height: 40,
      padding: 15,
      margin: 0,
      width: "auto",
      minWidth: 180,
      alignItems: "flex-start",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      "&:hover": {
        ".tabLabel + img": {
          display: "inline",
        },
      },
    },
  },
  "& .activeTab": {
    backgroundColor: colors.shipmentBadge,
    border: `2px solid ${colors.shipmentBorderColor}`,
    borderBottom: "transparent",
    fontWeight: "bold !important",
    boxShadow: "0px -10px 10px rgb(0 0 0 / 25%)",
    zIndex: 3,
    "&:focus": {
      border: `2px solid ${colors.shipmentBorderColor}`,
      borderBottom: "transparent",
    },
  },
  "& .shipmentInfo": {
    zIndex: 16,
  },
}));

export const TabLabelStyled = styled("div")(() => ({
  "&.tabLabelContainer": {
    width: "100%",
    textAlign: "left",
  },
  "& .tabInfoContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "auto",
    "& .tab-label-container": {
      width: "-webkit-fill-available",
      display: "flex",
      alignItems: "center",
      "& .tabLabel": {
        width: "100%",
        fontSize: 15,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        // fontWeight: 500,
        color: colors.primaryDark,
        "& + img": {
          display: "none",
        },
      },
      "& .tabLabel:hover": {},
    },
    "& .tab-po-information": {
      width: 100,
      height: 21,
      gap: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: 2,
      "& .img-favorite": {
        height: 15,
        opacity: 0,
        zIndex: 1,
      },
      "&:hover": {
        "& .img-favorite": {
          opacity: "1 !Important",
        },
      },
      "& .avatarHoverEffect": {
        "&:hover": {
          outline: `1px solid ${colors.primaryDark} !Important`,
        },
      },
    },
  },
  "& .progress": {
    height: 2,
    width: "100%",
    backgroundColor: "#c7d4e1",
    "& div": {
      backgroundColor: AppConfig.themeColors.blue,
      height: 2,
      transition: "all 0.3s",
    },
  },
}));

export const ShipmentTitleContainer = styled("div")(() => ({
  "&.shipmentTitleContainer": {
    display: "flex",
    alignItems: "center",
    padding: "0px 15px 0px 54px",
    position: "relative",
    "& .stc-shipment-number": {
      display: "flex",
      alignItems: "center",
      width: 270,
      marginRight: 20,
    },
    "& .stc-shipment-customer": {
      width: 548,
    },
    "& .shipmentBadge": {
      marginLeft: 6,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "3px 15px 2px 12px",
      background: "white",
      border: `2px solid ${colors.shipmentBorderColor}`,
      borderRadius: 12,
      color: colors.primaryDark,
      fontSize: 16,
      height: 24,
      fontWeight: 700,
      marginTop: 1,
    },
  },
}));
export const PurchaseOrderNavigationStyled = styled("div")(() => ({
  "&.purchaseOrderNavigationContainer": {
    height: 40,
    width: "100%",

    "& .customTabsContainer": {
      "@media screen and (max-width: 1920px)": {
        "& .custom-scroller": {
          maxWidth: 1600,
        },
      },
      "& .custom-tabs-container": {
        height: 40,
        "& .SALES_ORDER": {
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
          border: `1px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activeSALES_ORDER": {
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
          border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
        "& .PURCHASE_ORDER": {
          background: `${AppConfig.themeColors.purchaseOrderBackgroundColor} !important`,
          border: `1px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activePURCHASE_ORDER": {
          background: `${AppConfig.themeColors.purchaseOrderBackgroundColor} !important`,
          border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
      },
      "& .custom-tabs-container-first": {
        height: 40,
        marginLeft: -180,
      },
      "& .custom-tabs": {
        minHeight: 0,
      },
      "& .custom-tab": {
        padding: "0px 12px",
        minHeight: 0,
        height: 40,
        fontSize: 15,
        backgroundColor: "#cbddf0",
        borderColor: "#90aed1",
        margin: "0 6px",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,

        "&:active": {
          transform: "none",
        },
        "&.disabled": {
          backgroundColor: "#dce6f0",
          opacity: 0.3,
        },
        "&.reassigned": {
          maxWidth: 360,
          "& .wrapped-po": {
            width: "100%",
            "& .tabLabel": {
              width: "100% !important",
            },
          },
        },
      },
      "& .custom-tab.active": {
        backgroundColor: "white",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        zIndex: 10,
        "&:hover": {
          backgroundColor: "white",
        },
        "&:focus": {
          border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor} !important`,
          borderBottom: "0px !important",
        },
      },
      "&custom-tab:hover": {
        backgroundColor: "#c2daf7",
      },
      "& .wrapped": {
        minHeight: 0,
        height: 40,
        padding: 8,
        margin: 0,
        "& .tabLabel": {
          fontSize: 15,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
        },
        "& .tabLabel:hover": {
          "& + img": {
            display: "inline",
          },
        },
        "& .progress": {
          height: 2,
          width: "100%",
          backgroundColor: AppConfig.themeColors.primaryLight,
        },
      },
      "& .wrapped-po": {
        minHeight: 0,
        height: 40,
        padding: 15,
        margin: 0,
        width: "auto",
        minWidth: 180,
        alignItems: "flex-start",
        "&:hover": {
          "& .tabLabel + img": {
            display: "inline",
          },
        },
      },
      "& .tab-label": {
        fontSize: 10,
        textAlign: "left",
        "& span": {
          margin: 0,
        },
      },
      "& .tab-label-container": {
        lineHeight: 1.5,
        padding: 0,
        margin: 0,
        textAlign: "left",
      },
    },
    "& .activeTab": {
      backgroundColor: `${colors.purchaseOrderBackgroundColor} !important`,
      border: ` 2px solid ${colors.purchaseOrderBorderColor}`,
      borderBottom: "transparent",
      zIndex: 2,
      fontWeight: "bold !important",
    },
  },

  "& .purchaseOrderInfo": {
    zIndex: 15,
  },
  "& .shipmentInfobody": {
    zIndex: 15,
  },
  "& .shipmentDetailContainer": {
    position: "relative",
    backgroundColor: colors.shipmentBadge,
    border: `2px solid ${colors.shipmentBorderColor}`,
    borderRadius: 10,
    padding: "0px 16px 0px 0px",
    "& .shipment-title-container": {
      display: "flex",
      alignItems: "center",
      padding: "19px 15px 0px 10px",
      position: "relative",
      "& .stc-shipment-number": {
        display: "flex",
        alignItems: "center",
        width: 270,
        marginRight: 20,
      },
      "& .stc-shipment-customer": {
        width: 548,
      },
      "& .shipmentBadge": {
        marginLeft: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px 15px 2px 12px",
        background: "white",
        border: `2px solid ${colors.shipmentBorderColor}`,
        borderRadius: 12,
        color: colors.primaryDark,
        fontSize: 16,
        height: 24,
        fontWeight: 700,
        marginTop: 1,
      },
    },
  },
  "& .header": {
    backgroundColor: `${colors.salesOrderBackgroundColor} !important`,
    borderRadius: 10,
  },
  "& .borderColor": {
    border: `2px solid ${colors.salesOrderBorderColor} !important`,
  },
  "& .poContainer": {
    background: `${colors.purchaseOrderBackgroundColor} !important`,
    border: ` 2px solid ${colors.purchaseOrderBorderColor} !important`,
    padding: "20px 16px 20px 10px !important",
    marginBottom: "19px !important",
  },
  "& .container": {
    display: "grid",
    gridTemplateColumns: "repeat(5, 271px)",
    gridGap: "1px 20px",
  },
}));

export const ShipmentInfoGridStyled = styled("div")(() => ({
  "&.newShipmentDetailContainer": {
    backgroundColor: AppConfig.themeColors.white,
    borderRadius: 6,
    padding: "32px 55px 0px 35px;",
    height: 340,
    overflowY: "auto",
    "& .shipment-title-container": {
      display: "flex",
      alignItems: "center",
      marginBottom: 17,
      padding: 0,
      position: "sticky",
      left: 0,
      "& .stc-shipment-number": {
        width: 265,
        marginRight: 20,
        display: "flex",
        alignItems: "center",
      },
      "& .stc-shipment-customer": {
        width: 536,
      },
      "& .shipmentBadge": {
        marginLeft: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px 15px 2px 12px",
        background: colors.shipmentBadge,
        border: `2px solid ${colors.shipmentBorderColor}`,
        borderRadius: 12,
        color: colors.primaryDark,
        fontSize: 16,
        height: 24,
        fontWeight: 700,
        marginTop: 3,
      },
    },
  },
  "& .shipmentInfoContainer": {
    display: "grid",
    gridTemplateColumns: "repeat(5, 271px)",
    gridGap: "0px 20px",
    marginBottom: 16,
    "& .sales-order-property": {
      borderTop: `1px solid ${colors.dividerColor}`,
      width: "100%",
      "& .edit-icon": {
        left: 235,
      },
    },
  },
  "& .numberBadge": {
    fontSize: 14,
    padding: "3px 8px",
    fontWeight: 700,
    background: AppConfig.themeColors.shipmentColor,
    borderRadius: 8,
    marginLeft: 18,
  },
  "& .cancelButton": {
    fontSize: 14,
    background: " #FFFFFF",
    border: "1px solid #8D9CB1",
    bordeRadius: 5,
    color: "#334C73",
  },
  "& .doneButton": {
    width: 153,
    fontWeight: 700,
    fontSize: 14,
    backgroundColor: "#218CFF",
    marginRight: 17,
  },
}));

export const TaskItemContainerStyled = styled("div")(() => ({
  "&.taskListContainer": {
    "& .taskPOItemWrapper": {
      background: "white",
    },
    "& .hiddenText": {
      height: "0 !important",
      transition: "height 2s ease-out",
      overflow: "hidden",
    },
    "& .taskSOItemWrapper": {
      background: "#E6F0F9",
    },
    "& .taskItemContainer": {
      display: "flex",
      userSelect: "none",
      alignItems: "center",
      height: "100%",
      "& .description-task-container": {
        width: "100%",
        height: "25px",
        margin: "0px 8px",
      },
      "& .cancelIcon": {
        position: "absolute",
        right: 5,
        width: 15,
        height: 15,
        zIndex: 1,
        color: colors.diamondRed,
      },
      "& .task-description-wrapper": {
        "& .span-task-description": {
          display: "-webkit-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
        },
      },
    },
    "& .taskStatusContainer": {
      maxWidth: 32,
      minWidth: 32,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 3,
      left: 25,
    },
  },
}));

const adhocTaskBox = {
  height: "100%",
  minWidth: 50,
  borderLeft: `1px solid ${AppConfig.themeColors.dividerColor}`,
  padding: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  fontWeight: 400,
  userSelect: "none",
};

export const ActivityWidgedContainerStyled = styled("div")(() => ({
  "&.recentActivityContainer": {
    "& .notificationUnreadContainer": {
      background: "white",
      position: "sticky",
      top: 0,
      zIndex: 14,
      "& .notificationActivities": {
        height: 25,
        marginRight: 27,
        background: colors.primaryDark,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "sticky",
        top: 0,
        zIndex: 14,
        borderRadius: 3,
        marginLeft: 15,
        "&:hover": {
          cursor: "pointer",
        },
        "& .textUnreadActivities": {
          height: 25,
          color: "white",
          fontSize: 14,
          fontWeight: 700,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 8px",
        },
        "& .cancelIcon": {
          background: colors.primaryDark,
          color: "#fff",
          width: 13,
          height: 13,
          borderRadius: "50%",
          margin: "0px 8px",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },

    "& .activityWidget": {
      position: "relative",
      height: "100%",
      fontSize: 12,
      margin: "4px 1px 0px 0px",
      maxHeight: "calc(100vh - 270px)",
      overflowY: "scroll",
      overflowX: "hidden",
      paddingBottom: 15,
      "&::-webkit-scrollbar": {
        backgroundColor: "transparent",
        width: 16,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 7,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
          borderBlock: "1px solid transparent",
          borderInline: "5px solid transparent",
        },
      },
      "& .activityScrollContainer": {
        "& .title-today": {
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          position: "sticky",
          top: -4,
          zIndex: 10,
          background: "white",
        },
        "& .title-today-unread-activities": {
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          position: "sticky",
          top: 0,
          zIndex: 10,
          marginLeft: -3,
        },
        "& .title-yesterday": {
          width: 72,
          marginLeft: -6,
          display: "flex",
          fontWeight: 400,
        },
        "& .title-past": {
          width: 64,
          display: "flex",
          justifyContent: "center",
          fontWeight: 600,
        },
        "& .activity-stream-title-today": {
          margin: 0,
          height: 19,
          width: 68,
          color: colors.primaryDark,
          fontWeight: 500,
          fontSize: 13,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
        },
        "& .empty-stream-title": {
          marginTop: "25%",
          marginLeft: "10%",
          marginRight: "10%",
          color: colors.primaryDark,
          fontSize: 36,
          display: "flex",
          textAlign: "center",
          fontWeight: "bold",
        },
        "& .activity-stream-title-yesterday": {
          margin: 0,
          height: 19,
          width: 72,
          color: colors.primaryDark,
          fontFamily: "Roboto",
          fontSize: 15,
          fontWeight: 400,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
        },
      },
      "& .body-note": {
        width: "100%",
        overflowWrap: "break-word",
      },
      "& .load-activities-container": {
        background: colors.primaryDark,
        width: 331,
        fontSize: 16,
        fontWeight: 700,
        padding: 10,
        height: "auto",
        borderRadius: 4,
        marginTop: 39,
        color: "white",
        cursor: "pointer",
        textAlign: "center",
        marginLeft: 47,
        '& input[type="number"]': {
          WebkitAppearance: "none",
          appearance: "none",
          fontSize: "1.06em",
          "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
            height: 30,
            width: 30,
            opacity: 1,
          },
        },
      },
      "& .activity-stream-search-text": {
        marginLeft: 47,
        marginTop: 64,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 24,
        color: colors.primaryDark,
        fontWeight: 700,
        textAlign: "center",
      },
      "& ul": {
        position: "relative",
      },
      "& .timeline": {
        width: 1,
        height: "100%",
        position: "absolute",
        backgroundColor: "#bbb",
        left: 27,
      },
      "& .recent-activity-line": {
        width: "100%",
        height: 20,
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 46,
        paddingRight: 6,
        "& .recent-activity-content": {
          width: "100%",
        },
      },
      "& .option-to-edit-activity-container": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 50,
        marginTop: 0,
        transition: "all 2s",
        transform: "none",
        top: -16,
        "&.dissapear": {
          zIndex: 0,
          transform: "translateY(-70px)",
        },
        "& .activity-to-edit": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: `1px solid ${colors.primaryLight}`,
          borderTop: "transparent",
          borderRadius: 4,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          backgroundColor: "white",
          color: colors.primaryDark,
          padding: "0px 16px",
          margin: "0px 10px 0px 26px",
          width: "100%",
          height: "100%",
        },
      },
      "& li": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        "&:hover": {
          "& .activity-notification-read": {
            width: 8,
            height: 8,
            top: 22,
            background: "#fff",
            border: "1px solid #39f",
            borderRadius: "50%",
            "&:hover": {
              background: "#fff",
              border: "1px solid #39f",
              borderRadius: "50%",
            },
            cursor: "pointer",
          },
        },
        "& .activity-scope": {
          position: "absolute",
          right: 12,
          top: 13,
          "& .activity-edit-and-delete-option": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& label": {
              margin: 0,
            },
            "& .circular-progress": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& .circular-progress-MU": {
                position: "absolute !important",
                top: -6,
                left: -6,
                zIndex: 1,
              },
            },
          },
        },
        "& .activity-edited": {
          "& .text-info-edit": {
            color: "#bdbdbd",
            fontSize: 12,
            fontFamily: "Roboto",
          },
          "& .activity-edit-and-delete-option": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& label": {
              margin: 0,
            },
            "& .circular-progress": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& .circular-progress-MU": {
                position: "absolute !important",
                top: -6,
                left: -6,
                zIndex: 1,
              },
            },
          },
        },
        "& .edit-note": {
          margin: 0,
          padding: "0px 24px",
          fontWeight: 700,
          color: "#578ec7",
          "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
          },
        },
        "& .edit-icon": {
          color: "#ff9494",
          width: 13,
          height: 13,
          position: "relative",
          left: 9,
          bottom: -1,
          "&:hover": {
            cursor: "pointer",
          },
        },
        "& .delete-note-activity-stream": {
          color: "#ff9494",
          width: 12,
          height: 12,
          "&:hover": {
            cursor: "pointer",
          },
        },
        "& .activity-timestamp": {
          fontSize: 11,
          textAlign: "center",
          color: "#798a9b",
          width: 52,
          backgroundColor: "white",
          padding: "8px 0",
          flex: "0 0 auto",
        },
        "& .activity-avatar": {
          // display: 'inline-block',
        },
        "& .activity-text": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 16,
          marginRight: 16,
          fontSize: 12,
          lineHeight: 1.8,
          width: "-webkit-fill-available",
          "& p": {
            marginBottom: 0,
            fontSize: 12,
            "& .styles-activity-stream-from-backend-red": {
              color: "red",
            },
            "& .styles-activity-stream-from-backend-green": {
              color: "green",
            },
            "& .styles-activity-stream-from-backend-bold": {
              fontWeight: 700,
            },
            "& .checkbox-input-done": {
              position: "relative",
              top: 2,
            },
          },
          "& .activity-notification-read": {
            width: 8,
            height: 8,
            position: "absolute",
            "&:hover": {
              background: "#fff",
              border: "1px solid #39f",
              borderRadius: "50%",
            },
            cursor: "pointer",
            top: "inherit",
            right: 18,
          },
          "& .activity-notification-unread": {
            background: "#39f",
            width: 8,
            height: 8,
            position: "absolute",
            borderRadius: "50%",
            border: "1px solid #39f",
            cursor: "pointer",
            top: "inherit",
            right: 18,
          },
        },
        "& span.activity-time": {
          display: "block",
          fontSize: 12,
        },
        "& span.badge": {
          position: "absolute",
          right: "auto",
          top: 0,
          left: -35,
          minHeight: "1.125rem",
          minWidth: "1.125rem",
          fontSize: 0,
        },
      },
    },
  },
}));

export const AdhocTaskStyled = styled("div")(() => ({
  "&.adhocTaskContainer": {
    padding: "0px 6px 16px 13px",
    margin: "8px 3px 8px 14px",
    border: "2px solid #B6C193",
    borderRadius: 3,
  },
  "& .tabAdhocTaskType": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    position: "relative",
    "& .verticalDivider": {
      height: "42px",
      width: "1px",
      backgroundColor: "#e0e0e0",
      marginRight: "25px",
      marginLeft: "22px",
    },
    "& .indicator": {
      position: "absolute",
      background: "rgb(204, 204, 204)",
      height: 2,
      width: "95%",
      top: 34,
      opacity: 0.6,
    },
  },
  "& .adhocTaskTypeContainer": {
    display: "flex",
    alignItems: "flex-start",
    height: 40,
  },

  "& .adhocTaskInfoWrapper": {
    backgroundColor: "transparent",
    position: "sticky",
    top: 0,
    zIndex: 4,
    display: "flex",
    justifyContent: "center",
    "& .button-enable": {
      background: "lightgray",
      color: "darkslategrey",
      borderRadius: 4,
      height: 40,
      width: "10%",
      margin: 2,
      fontSize: 12,
      fontWeight: 500,
      border: `1px solid ${AppConfig.themeColors.dividerColor}`,
    },
    "& .button-disable": {
      background: "#39f",
      borderRadius: 4,
      height: 40,
      width: "10%",
      margin: 2,
      fontSize: 12,
      fontWeight: 500,
      color: "white",
      border: `1px solid ${AppConfig.themeColors.dividerColor}`,
    },
  },

  "& .adhocTaskInfoContainer": {
    width: "100%",
    height: 40,
    backgroundColor: "white",
    border: "1px solid #a8b9ca",
    top: -8,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    zIndex: 10,
    margin: 2,
    padding: 4,
    "&:hover": {
      "& .adhoc-task-description": {
        "&::placeholder": {
          color: "#53575B",
        },
      },

      "& .adhoc-task-phase": {
        "& p": {
          color: "#53575B !Important",
        },
      },

      "& .adhoc-task-user": {
        "& .default-user-icon": {
          "& circle": {
            fill: "#53575B",
          },
        },
      },
    },
    "& .adhoc-task-description": {
      "&::placeholder": {
        color: "#92A1B0",
        fontWeight: 300,
        fontSize: 13,
      },
    },

    "& input": {
      width: "55%",
      padding: 8,
      background: "white",
      top: 0,
      border: "none",
      flex: 1,
    },
    "& .adhoc-task-section": {
      height: "100%",
      cursor: "pointer",
      width: "fit-content",
      display: "flex",
      justifyContent: "flex-end",
      "& .date-box": {
        ...adhocTaskBox,
      },
      "& .date-box.active::after": {
        content: '""',
        width: "50%",
        position: "absolute",
        bottom: 1,
      },
      "& .adhoc-task-user.active::after": {
        content: '""',
        width: "50%",
        position: "absolute",
        bottom: 1,
      },
      "& .adhoc-task-user": {
        ...adhocTaskBox,
        minWidth: 36,
        width: 36,
        "& img": {
          objectFit: "contain",
          borderRadius: "50%",
          backgroundColor: "$primary",
        },
      },
      "& .adhoc-task-shipment": {
        ...adhocTaskBox,
        minWidth: 64,
        width: "fit-content",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
      "& .adhoc-task-phase": {
        ...adhocTaskBox,
        maxWidth: "20%",
        "& span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    },
  },
}));

export const ProjectTaskFiltersStyled = styled("div")(() => ({
  "&.projectTaskFilterContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 10,
    "& .title": {
      fontSize: 23,
      fontWeight: "normal",
      paddingLeft: 8,
      color: colors.primaryDark,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxHeight: 60,
      display: "flex",
      alignItems: "center",
      "& .timeline-icon": {
        marginRight: 8,
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  },

  "& .projectTaskFilters": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "left",
    fontSize: 11,
    marginRight: 6,

    "& .show-completed": {
      textTransform: "uppercase",
      fontWeight: 200,
      fontSize: 11,
      "& span": {
        "& .MuiIconButton-label": {
          boxShadow: "1px 2px 2px rgb(0 0 0 / 25%)",
          width: 13,
          height: 13,
        },
      },
      "& .sc-text-container": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        position: "relative",
        "& span": {
          position: "relative",
          top: 2,
        },
      },
      "& input": {
        margin: "0 5px 2px 0",
        "&:hover": {
          cursor: "pointer",
        },
      },
      margin: "5px 0px 8px 18px",
      opacity: 1,
      display: "flex",
      alignItems: "center",
    },
    "& .general-filters": {
      display: "flex",
      position: "relative",
      height: 30,
      "& .indicator": {
        position: "absolute",
        background: "rgb(204, 204, 204)",
        height: 2,
        width: "100%",
        top: 26,
        opacity: 0.6,
      },
    },
  },
}));
export const ArrowsToSortIconStyled = styled(ArrowsToSortIcon)(() => ({
  "&.headerArrowButton": {
    display: "inline-block",
    position: "absolute",
    left: "auto",
  },
}));

export const ShipmentDateWindowPopoverStyled = styled(Popover)(() => ({
  "&.shipmentDateWindowPopoverContainer": {
    "& .shipmentDateWindowPaperContainer": {
      padding: "0px 24px 24px 24px",
      minWidth: 382,
      "& .sdw-title-container": {
        fontSize: 20,
        fontWeight: 400,
        color: colors.darkGray,
        height: 59,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: `2px solid ${colors.darkGray}`,
      },
      "& .sdw-body-container": {},
    },
    "& .sortedHeaderCell": {
      backgroundColor: "transparent",
      zIndex: 100,
      top: 155,
      minHeight: 45,
      height: 45,
      color: colors.primaryDark,
      fontSize: 13,
      fontWeight: 500,
      "& svg": {
        color: "#39f",
      },
    },
    "& .date": {
      fontSize: 14,
      color: darkBlue,
      padding: "2px 16px",
      borderBottom: "none",
      textAlign: "right",
    },
    "& .number": {
      fontSize: 14,
      color: darkBlue,
      padding: "2px 16px",
      borderBottom: "none",
    },
    "& .finalDestination": {
      fontSize: 14,
      color: darkBlue,
      padding: "2px 16px",
      borderBottom: "none",
    },

    "& .headerCell": {
      backgroundColor: "transparent",
      zIndex: 100,
      top: 155,
      height: 45,
      minHeight: 45,
      color: colors.primaryDark,
      fontSize: 13,
      fontWeight: 500,
      "& svg": {
        opacity: 0,
      },
      "&:hover": {
        "& svg": {
          opacity: 1,
        },
      },
    },

    "& .valueContainer": {
      fontSize: 13,
      paddingTop: 4,
    },
    "& .tableHeaderContainer": {
      background: "white",
    },
    "& .tableRow": {
      "&:hover": {
        background: onHoverBackground,
      },
    },
  },
}));

export const ShipmentDateWindowStyled = styled("div")(() => ({
  "&.shipmentDateWindowContainer": {},
}));

export const POVendorTemplateButtonsStyled = styled("div")(() => ({
  "&.containerPO": {
    marginLeft: 125,
    display: "flex",
    "& .buttonPOVendor": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 6px",
      background: "#218CFF",
      borderRadius: 5,
      fontWeight: 700,
      lineHeight: 16,
      textAlign: "center",
      color: "white",
      minWidth: 95,
      "& .MuiButton-label": {
        height: "100%",
        lineHeight: "initial",
      },
    },
  },
  "&.taskContainer": {
    marginLeft: 18,
    display: "flex",
    "& .buttonPOVendor": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 6px",
      background: "#218CFF",
      borderRadius: 5,
      fontWeight: 700,
      lineHeight: 16,
      textAlign: "center",
      color: "white",
      minWidth: 95,
      "& .MuiButton-label": {
        height: "100%",
        lineHeight: "initial",
      },
    },
  },
}));

export const CreateNoteCollapseStyled = styled(Collapse)(() => ({
  "&.createNoteCollapse": {
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      flexDirection: "column",
    },
    "& .noteButton": {
      color: colors.primaryDark,
      width: "33.33%",
      alignItems: "center",
      fontWeight: 400,
      fontSize: 14,
      textAlign: "center",
      padding: "12px 0px",
      height: 40,
      "&:hover": {
        cursor: "pointer",
        fontWeight: 700,
      },
      "&:focus": {
        fontWeight: 600,
      },
      "&:active": {
        transform: "unset",
      },
    },
    "& .SON": {
      background: colors.salesOrderBackgroundColor,
      border: `2px solid ${colors.salesOrderBorderColor} !important`,
      "&:hover": {
        background: "#9DC725",
        borderColor: "#6D8F0B !important",
      },
      "&:focus": {
        background: colors.backgroundGreen,
        borderColor: "#8CAB33 !important",
      },
    },
    "& .SON-active": {
      background: colors.backgroundGreen,
      border: "2px solid #8CAB33 !important",
      "&:hover": {
        background: "#9DC725",
        borderColor: "#6D8F0B !important",
      },
    },
    "& .PON": {
      background: colors.purchaseOrderBackgroundColor,
      border: `2px solid ${colors.purchaseOrderBorderColor} !important`,
      "&:hover": {
        background: "#5FA7E9",
        borderColor: "#1D7CD3 !important",
      },
      "&:focus": {
        background: "#97CCFD",
        borderColor: "#3F96E7 !important",
      },
    },
    "PON-active": {
      background: "#97CCFD",
      border: "2px solid #3F96E7 !important",
      "&:hover": {
        background: "#5FA7E9",
        borderColor: "#1D7CD3 !important",
      },
    },
    "& .SHIPMENT": {
      background: colors.shipmentBadge,
      border: `2px solid ${colors.shipmentBorderColor} !important`,
      "&:hover": {
        background: "#D885E8",
        borderColor: "#BA1ED8 !important",
      },
      "&:focus": {
        background: "#EBB3F5",
        borderColor: "#CB5EDE !important",
      },
    },
    "SHIPMENT-active": {
      background: "#EBB3F5",
      border: "2px solid #CB5EDE !important",
      "&:hover": {
        background: "#D885E8",
        borderColor: "#BA1ED8 !important",
      },
    },
  },
}));

const slideInRightKF = keyframes`
  from {
    width: 0; 
  }
  to {
    width: 578px;
  }
`;

const slideInLeftKF = keyframes`
  from {
    width: 578px;
  }
  to {
    width: 0;
  }
`;

const opacityRightKF = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const opacityLeftKF = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const NotesSectionStyled = styled("div")(() => ({
  "&.notesSectionContainer": {},
}));

export const NoteSectionBodyContainerStyled = styled("div")(() => ({
  "&.noteSectionBodyContainer": {
    height: "calc(100vh - 170px)",
    width: 690,
    "& .selector-type": {
      padding: "39px 0px 20px 0px",
      display: "flex",
      "& .btn-notes": {
        margin: 0,
        borderRadius: 0,
        width: "33.33%",
        background: "#fff",
        color: "#25486d",
        height: 40,
        border: "1px solid #a8b9ca",
        fontSize: 16,
        fontWeight: 400,
        "&:first-child": {
          borderRadius: "3px 0px 0px 3px",
        },
        "&:last-child": {
          borderRadius: "0px 3px 3px 0px",
        },
      },
      "& .btn-notes:active": {
        transform: "none !important",
      },
      "& .btn-notes-SON": {
        background: "#39f",
        border: "1px solid #39f",
        color: "#fff",
      },
      "& .btn-notes-PON": {
        background: "#39f",
        border: "1px solid #39f",
        color: "#fff",
      },
      "& .btn-notes-SHIPMENT": {
        background: "#39f",
        border: "1px solid #39f",
        color: "#fff",
      },
    },
    "& .badge-notes": {
      margin: "0px 0px 12px 21px",
      fontSize: 12,
      fontWeight: 500,
    },
    "& .section-content-notes": {
      margin: "0px 21px",
      height: "auto",
      display: "flex",
      "& div": {
        width: "100%",
        height: "100%",
        "& div": {
          height: "100%",
          "& textarea": {
            position: "absolute !important",
          },
        },
        "& .user-mention": {
          fontWeight: 500,
        },
        "& .user-mention-disabled": {
          fontWeight: 500,
          opacity: 0.5,
        },
      },
    },
    "& .section-bottom": {
      margin: "15px 21px 0px 21px",
      display: "flex",
      "& .section-info-container": {
        alignItems: "flex-end",
        width: "65%",
        display: "flex",
        "& .info": {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          "& div": {
            fontSize: 12,
            color: "gray",
          },
        },
      },
      "& .delete-note-section": {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 1,
        "& img": {
          width: 12,
          height: 13,
          "&:hover": {
            cursor: "pointer",
          },
        },
        "& span": {
          color: "#ff9494",
          margin: 0,
          padding: "0px 4px",
          fontSize: 12,
          fontWeight: 400,
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      "& .section-actions-buttons": {
        width: "45%",
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        "& .btn-attach-icon": {
          background: "#ccc",
          height: 40,
          width: 58,
          border: "1px solid $sales-order-color",
        },
        "& .btn-submit": {
          background: "#39f",
          margin: 0,
          borderRadius: 4,
          height: 40,
          width: 130,
          fontSize: 14,
        },
        "& .label-description": {
          color: "#828282",
          fontSize: 12,
          textAlign: "right",
          marginTop: 14,
          marginBottom: 9,
        },
      },
    },
  },
  "& .slideRight": {
    animationName: slideInRightKF,
    animationDuration: "0.2s",
    "& div:first-child": {
      animationName: opacityRightKF,
      animationDuration: "0.3s",
    },
  },
  "& .slideLeft": {
    animationName: slideInLeftKF,
    animationDuration: "0.2s",
    "& div:first-child": {
      animationName: opacityLeftKF,
      animationDuration: "0.25s",
    },
  },
  "& .btnStyle": {
    margin: 0,
    borderRadius: 0,
    width: "33.33%",
    color: colors.primaryDark,
    height: 40,
    fontSize: 16,
    fontWeight: 400,
    "&:hover": {
      cursor: "pointer",
      fontWeight: 700,
    },
    "&:focus": {
      fontWeight: 600,
    },
    "&:active": {
      transform: "unset",
    },
    "&:first-child": {
      borderRadius: "3px 0px 0px 3px",
    },
    "&:last-child": {
      borderRadius: "0px 3px 3px 0px",
    },
  },
  "& .SON": {
    background: colors.salesOrderBackgroundColor,
    border: `2px solid ${colors.salesOrderBorderColor} !important`,
    "&:hover": {
      background: "#9DC725",
      borderColor: "#6D8F0B !important",
    },
    "&:focus": {
      background: colors.backgroundGreen,
      borderColor: "#8CAB33 !important",
    },
  },
  "& .SON-active": {
    background: colors.backgroundGreen,
    border: "2px solid #8CAB33 !important",
    "&:hover": {
      background: "#9DC725",
      borderColor: "#6D8F0B !important",
    },
  },
  "& .PON": {
    background: colors.purchaseOrderBackgroundColor,
    border: `2px solid ${colors.purchaseOrderBorderColor} !important`,
    "&:hover": {
      background: "#5FA7E9",
      borderColor: "#1D7CD3 !important",
    },
    "&:focus": {
      background: "#97CCFD",
      borderColor: "#3F96E7 !important",
    },
  },
  "& .PON-active": {
    background: "#97CCFD",
    border: "2px solid #3F96E7 !important",
    "&:hover": {
      background: "#5FA7E9",
      borderColor: "#1D7CD3 !important",
    },
  },
  "& .SHIPMENT": {
    background: colors.shipmentBadge,
    border: `2px solid ${colors.shipmentBorderColor} !important`,
    "&:hover": {
      background: "#D885E8",
      borderColor: "#BA1ED8 !important",
    },
    "&:focus": {
      background: "#EBB3F5",
      borderColor: "#CB5EDE !important",
    },
  },
  "& .SHIPMENT-active": {
    background: "#EBB3F5",
    border: "2px solid #CB5EDE !important",
    "&:hover": {
      background: "#D885E8",
      borderColor: "#BA1ED8 !important",
    },
  },
  "& .buttonContainer": {
    width: "100%",
    "& .MuiButton-outlined.Mui-disabled": {
      alignItems: "center",
      fontWeight: 400,
      fontSize: 14,
      textAlign: "center",
      padding: "12px 0px",
      height: 40,
      opacity: 0.6,
      color: colors.primaryDark,
    },
  },
}));

export const POMetadataContainterStyled = styled("div")(() => ({
  "&.poContainer": {
    "& .poTitleContent": {
      padding: "10px 19px 0px 55px",
      fontSize: 20,
      display: "flex",
      marginBottom: "5px",
      alignItems: "center",
      gap: 15,
    },
  },
}));

export const OrderDashboardContainerStyled = styled("div")(({ theme }) => ({
  "&.orderDashboardContainer": {
    overflowY: "hidden",
    overflowX: "auto",
    height: "calc(100vh - 65px)",
    margin: "4px 32px 0px 16px",

    "& .sales-order-info-container": {
      width: "fit-content",
      border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
      background: AppConfig.themeColors.salesOrderBackgroundColor,
      borderRadius: 10,
      marginTop: 12,
      transition: "height 0.8s, opacity 1.2s ease-in-out",
      opacity: 1,
      height: "calc(100vh - 90px)",
      overflow: "hidden",
      [theme.breakpoints.up("md")]: {
        width: "fit-content",
        minWidth: "calc(100vw - 180px)",
      },
      [theme.breakpoints.up("sm")]: {
        width: "fit-content",
        minWidth: "calc(100vw - 180px)",
      },
      [theme.breakpoints.up("xl")]: {
        width: "inherit",
      },
      "& .so-info-backdrop": {
        zIndex: 1202,
        position: "relative",
        background: colors.salesOrderBackgroundColor,
      },
      "& .blockContainer": {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .blockText": {
          color: colors.dangerRed,
          fontSize: 21,
        },
      },
      "& .sales-order-info": {
        padding: 20,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        height: "100%",
        "& .metadata-info-container": {
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          position: "relative",
          height: "100%",
          "& #item-table-SO": {
            height: "100%",
            minWidth: "calc(100vw - 180px)",
            "& #itemTablesalesOrder": {
              maxHeight: "calc(100vh - 441px)",
            },
            "& #itemTableSO": {
              maxHeight: "calc(100vh - 441px)",
            },
          },
        },
        "& .metadata-info": {
          display: "grid",
          gridGap: "1px 20px",
          gridTemplateColumns: "repeat(5, 281px)",
          padding: "8px 0px 5px 35px",
        },
      },

      "& .above-purchase-order-navigation": {
        height: 8,
        width: "100%",
      },
      "& .above-shipment-navigation": {
        height: 8,
        width: "100%",
      },
    },
    // SALES ORDER INFO CONTAINER
    "& .sales-order-rendering": {
      height: "calc(100vh - 90px)",
      "& .min-height-metadata-content-so": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        opacity: 1,
        height: "calc(100vh - 148px)",
      },
      "& .purchase-order-info-container-hidden": {
        border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        background: AppConfig.themeColors.purchaseOrderBackgroundColor,
        margin: "0px 14px 14px 14px",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        position: "relative",
        opacity: 1,

        "& .min-height-metadata-content-po": {
          opacity: 0,
          height: 16,
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
        "& .shipment-info-container-hidden": {
          opacity: 0,
          height: 0,
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
      },
    },
    "& .purchase-order-rendering": {
      "& .min-height-metadata-content-so": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        opacity: 0,
        height: 12,
      },
      // PURCHASE ORDER INFO CONTAINER
      "& .purchase-order-info-container": {
        border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        background: AppConfig.themeColors.purchaseOrderBackgroundColor,
        margin: "0px 14px 14px 14px",
        borderRadius: 10,
        position: "relative",
        opacity: 1,
        "& .purchase-order-info": {
          padding: 20,
          position: "relative",
          "& .sales-order-info": {
            background: "transparent",
            padding: 10,
          },
          "& #purchaseOrderTableContainer": {
            maxHeight: "calc(100vh - 522px)",
          },
        },
      },
      "& .purchase-order-info-container-hidden": {
        "& .min-height-metadata-content-po": {
          opacity: 0,
          height: "0px",
          transition: "height 0.8s, opacity 1.2s ease-in-out",
          "& .shipment-info-container-hidden": {
            "& .min-height-metadata-content-shipment": {
              opacity: 0,
              height: 0,
              transition: "height 0.8s, opacity 1.2s ease-in-out",
            },
          },
        },
      },
      "& .purchase-order-info-container-visible": {
        overflow: "hidden",
        height: "calc(100vh - 156px)",
        "& .min-height-metadata-content-po": {
          height: "calc(100vh - 213px)",
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
      },
      "& .shipment-info-container": {
        margin: "0px 14px 0px 14px",
        border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
        background: AppConfig.themeColors.shipmentColor,
        position: "relative",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        "& .min-height-metadata-content-shipment": {
          height: 16,
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
      },
      //
    },
    "& .shipment-rendering": {
      // SHIPMENT INFO CONTAINER
      "& .shipment-info-container": {
        margin: "0px 14px 14px 14px",
        border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
        background: AppConfig.themeColors.shipmentColor,
        position: "relative",
        borderRadius: 10,
        opacity: 1,
        overflow: "hidden",
        "& .shipment-info": {
          padding: 20,
          height: "auto",
          "& .shipmentInfoBody": {
            height: "100%",
            display: "flex",
            position: "relative",
            textAlign: "left",
            flexDirection: "column",
          },
          "& .sales-order-info": {
            background: "transparent",
          },
          "& #itemTableshipment": {
            maxHeight: "calc(100vh - 512px)",
          },
        },
      },
      "& .purchase-order-info-container": {
        border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        background: AppConfig.themeColors.purchaseOrderBackgroundColor,
        margin: "0px 14px 14px 14px",
        borderRadius: 10,
        position: "relative",
      },

      "& .shipment-info-container-hidden": {
        "& .min-height-metadata-content-shipment": {
          opacity: 0,
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
      },
      "& .shipment-info-container-visible": {
        height: "calc(100vh - 184px)",
        "& .min-height-metadata-content-shipment": {
          height: "calc(100vh - 155px)",
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
      },
      //

      "& .min-height-metadata-content-so": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        opacity: 0,
        height: 12,
      },
      "& .min-height-metadata-content-po": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        opacity: 0,
        height: 12,
      },
    },
    "& .three-panel-rendering": {
      height: 0,
      opacity: 0,
      transition: "height 0.8s, opacity 1.2s ease-in-out",
      margin: 0,
    },
    "& .min-height-metadata-content-three-panel-section": {
      margin: "4px 0px 12px 0px",
      transformOrigin: "bottom",
      background: "white",
      "& .three-panel-section": {
        height: "100%",
        "& .project-dashboard-container": {
          height: "100%",
          minHeight: "inherit !Important",
        },
        "& .three-panel-section-empty": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "inherit",
          fontSize: 21,
          color: colors.dangerRed,
        },
      },
    },
    "& .min-height-metadata-content-three-panel-section-hidden": {
      height: 0,
      transition: "height 0.8s, opacity 1.2s ease-in-out",
    },
    "& .min-height-metadata-content-three-panel-section-visible": {
      opacity: 1,
      height: "calc(100vh - 84px)",
      transition: "height 0.8s, opacity 1.2s ease-in-out",
    },

    "& .three-panel-section-skeleton": {
      height: 0,
      background: "white",
    },
  },
}));

export const TaskPanelStyled = styled("div")(() => ({
  "&.tasksPanelContainer": {
    position: "relative",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: "0px 2px 3px 1px #dddddd",
    "& .content-card-header": {
      padding: "12px 19px 0px 9px",
    },

    "& .content-card-content": {
      flex: 1,
      fontSize: 13,
      fontWeight: "normal",
      position: "relative",
      color: colors.primaryDark,
      marginBottom: 8,
      borderRadius: 10,
      padding: "20px 16px 0px 16px",
      background: "#fff",
      "& .task-category": {
        "& .task-category-title-container": {
          borderRadius: 50,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          userSelect: "none",
          marginTop: 23,
        },
        "& .task-category-title": {
          fontWeight: 600,
        },
      },
    },
  },
  "& .taskScrollContainer": {
    paddingBottom: 0,
    // margin: 8px;
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    overflowX: "hidden",
    marginRight: 3,
    "& .expand-icon": {
      transition: "all 0.5s",
    },
    "& .task-category": {
      "& .task-category-title-container": {
        borderRadius: 50,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        userSelect: "none",
        marginTop: 23,
      },
      "& .task-category-title": {
        fontWeight: 600,
      },
    },
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-track:vertical": {
      marginTop: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
}));

export const OrderDashboardNavigationStyled = styled("div")(() => ({
  "&.orderDashboardNavigationStyles": {
    right: 4,
    width: 24,
    position: "absolute",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "30px 0px",
    transition: "all 0.4s",
    height: "calc(100vh - 62px)",
    "& .dashboard-nav-button": {
      width: 18,
      height: 65,
      marginBottom: 10,
      maxHeight: 90,
      minHeight: 30,
      border: "0.5px solid #c8c8c8",
      boxSizing: "border-box",
      borderRadius: 2,
      alignItems: "center",
      display: "flex",
      transition: "width 0.3s ease-in-out",
      "&:hover": {
        width: 24,
        cursor: "pointer",
        borderWidth: 2,
      },
      "& .dashboard-nav-icon": {
        color: "#c8c8c8",
        fill: "#c8c8c8",
        margin: "0px 1px",
        width: "-webkit-fill-available",
      },
      "& .dashboard-mui-icon": {
        color: "#c8c8c8",
        width: "-webkit-fill-available",
        height: 18,
        transform: "rotate(90deg)",
      },
    },

    "& .dashboard-nav-button-sales-order": {
      background: AppConfig.themeColors.salesOrderBackgroundColor,
      opacity: 0.5,
      "&:hover": {
        border: `1px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
        background: colors.backgroundGreen,
        "& .dashboard-nav-icon": {
          color: "#989898",
          fill: "#989898",
        },
        "& .dashboard-mui-icon": {
          color: "#989898",
        },
      },
      "&:active": {
        background: colors.backgroundGreenAction,
      },
    },
    "& .sales-order-info-to-scroll-active": {
      border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
      opacity: 1,
      width: 22,
      transition: "width 0.3s ease-in-out",
      "& .dashboard-nav-icon": {
        fill: `${AppConfig.themeColors.blueTextColor} !Important`,
      },
    },
    "& .purchase-order-info-to-scroll-active": {
      border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
      opacity: 1,
      width: 22,
      transition: "width 0.3s ease-in-out",
      "& .dashboard-nav-icon": {
        fill: `${AppConfig.themeColors.blueTextColor} !Important`,
      },
    },
    "& .shipment-info-to-scroll-active": {
      border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
      opacity: 1,
      width: 22,
      transition: "width 0.3s ease-in-out",
      "& .dashboard-nav-icon": {
        fill: `${AppConfig.themeColors.blueTextColor} !Important`,
      },
    },
    "& .three-panel-section-to-scroll-active": {
      border: `2px solid ${AppConfig.themeColors.blueTextColor}`,
      opacity: 1,
      width: 22,
      "& .dashboard-mui-icon": {
        color: `${AppConfig.themeColors.blueTextColor} !Important`,
      },
    },
    "& .dashboard-nav-button-purchase-order": {
      background: AppConfig.themeColors.purchaseOrderBackgroundColor,
      "&:hover": {
        background: colors.backgroundBlue,
        border: `1px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        "& .dashboard-nav-icon": {
          color: "#989898",
          fill: "#989898",
        },
        "& .dashboard-mui-icon": {
          color: "#989898",
        },
      },
      "&:active": {
        background: colors.backgroundBlueAction,
      },
    },
    "& .dashboard-nav-button-shipment": {
      background: AppConfig.themeColors.shipmentColor,
      "&:hover": {
        border: `1px solid ${AppConfig.themeColors.shipmentBordeColor}`,
        background: colors.backgroundPink,
        "& .dashboard-nav-icon": {},
        "& .dashboard-mui-icon": {},
      },
      "&:active": {
        background: colors.backgroundPinkAction,
      },
      "& .dashboard-nav-icon": {},
      "& .dashboard-mui-icon": {},
    },
    "& .dashboard-nav-button-shipment-disabled": {
      cursor: "not-allowed !Important",
    },
    "& .dashboard-nav-button-three-panel": {
      background: "#fff",
      "&:hover": {
        border: `1px solid ${AppConfig.themeColors.grey}`,
        background: colors.backgroundWhite,
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
      },
    },
  },
}));
