import React, { useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import Company from "../../api/model/company";
import UploadAvatar from "../General/UploadAvatar";
import CustomModal from "../Modal/Modal";
import CustomButton from "../Buttons/CustomButton";
import IntlMessages from "../../util/IntlMessages";
import Role from "../../api/model/Role";
import User from "../../api/model/user";
import { getRandomId } from "../../helpers/helpers";
import FormTextField from "../TextFields/FormTextField";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import userTypes from "../../api/types/userTypes";
import SpreadSheetOptions from "./SpreadSheetOptions";
import SalesOrderTemplateObj from "../../api/model/salesOrderTemplate";
import Loader from "../General/Loader";
import { rolePermissions } from "../../helpers/googleSpreadSheets";
import { taskStatus, triggerTaskTypes } from "../../helpers/tasks";
import { triggerCustomerTypes } from "../../helpers/customers";
import { triggerFactoryTypes } from "../../helpers/factories";
import { now } from "moment";
import ItemTable from "../../api/model/ItemTable";
import { firestore } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { FormGroup, Switch, TextField } from "@mui/material";

function CompanyModal({ open, onClose, company, onSubmit, companies }) {
  const [selectedCompany, setSelectedCompany] = useState(
    new Company({
      id: getRandomId(),
    })
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [user, setUser] = useState(
    new User({ id: getRandomId(), role: userTypes.SUPER_ADMIN })
  );
  const [enableSpreadSheet, setEnableSpreadSheet] = useState(false);
  const [spreadSheetMessage, setSpreadSheetMessage] = useState("");
  const [isSuccessSpreadSheetMessage, setIsSuccessSpreadSheetMessage] =
    useState(false);
  const [spreadSheetData, setSpreadSheetData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!company) {
      setSelectedCompany(
        new Company({
          id: getRandomId(),
        })
      );
      setErrorMessage("");
    } else {
      setSelectedCompany(company);
      setErrorMessage("");
      setUser(new User({ id: getRandomId(), role: userTypes.SUPER_ADMIN }));
    }
    if (!open) {
      setUser(new User({ id: getRandomId(), role: userTypes.SUPER_ADMIN }));
    }
  }, [open]);

  function onChangeField(field, event) {
    setCompanyName(event.target.value);
    const selectedCompanyName = event.target.value
      .replace(/\s\s+/g, " ")
      .trim();
    setSelectedCompany({ ...selectedCompany, [field]: selectedCompanyName });
    const companyNameValid = event.target.value.replace(/\s\s+/g, " ").trim();
    if (!selectedCompanyName) {
      return setErrorMessage("errormessage.nocompanyname");
    }
    if (selectedCompanyName.length > 55) {
      return setErrorMessage("errormessage.companynametoolong");
    }
    if (companyNameValid.match(/[<>!@#$%^&*]+/i)) {
      return setErrorMessage("errormessage.specialcharacters");
    }
    return setErrorMessage("");
  }
  const onChangeFieldValue = (field) => (value) => {
    setSelectedCompany({ ...selectedCompany, [field]: value });
  };

  function onChange(field) {
    return function (ev) {
      if (ev.target.value[0] !== "@") {
        if (field === "displayName") {
          setUser({ ...user, [field]: "@" });
        } else {
          setUser({ ...user, [field]: ev.target.value });
        }
      } else {
        setUser({ ...user, [field]: ev.target.value });
      }
    };
  }

  function getCompletedTasks(taskCompleted) {
    if (taskCompleted === 0) {
      return 0;
    }
    return taskCompleted - 1;
  }

  function getSOCompletedTasks(salesOrder, purchaseOrders) {
    console.log("getSOCompletedTasks", { salesOrder, purchaseOrders });
    let totalCompletedTasks = getCompletedTasks(salesOrder.taskCompleted);
    purchaseOrders.forEach((po) => {
      totalCompletedTasks += getCompletedTasks(po.taskCompleted);
    });
    console.log(
      "RETURNING TOTAL COMPLETED SO TASKS: ",
      salesOrder.number,
      " ****** ",
      totalCompletedTasks
    );
    return { totalCompletedTasks };
  }

  async function handleSubmit() {
    let superAdminSnapUserDB = await getDocs(
      query(
        collection(firestore, `${dbTables.USERS}`),
        where("email", "==", user.email.trim())
      )
    );

    if (superAdminSnapUserDB.size > 0) {
      setSnackbarMessage("companyusers.validate.exists.email");
      setOpenSnackbar(true);
      return;
    }
    superAdminSnapUserDB = await getDocs(
      query(
        collection(firestore, `${dbTables.USERS}`),
        where("cellPhone", "==", user.cellPhone)
      )
    );
    if (
      user.cellPhone !== "" &&
      user.cellPhone !== "+" &&
      superAdminSnapUserDB.size > 0
    ) {
      setSnackbarMessage("companyusers.validate.exists.phonenumber");
      setOpenSnackbar(true);
      return;
    }
    const parseDisplayName = user.displayName.slice(1).toLowerCase().trim();
    if (!parseDisplayName) {
      setSnackbarMessage("companyusers.validate.required.displayname");
      return setOpenSnackbar(true);
    }
    if (user.displayName.match(/[<>!#$%^&*\s]+/i)) {
      setSnackbarMessage("companyusers.validate.error.displayname");
      setOpenSnackbar(true);
      return;
    } else if (
      user.cellPhone.length < 5 &&
      user.cellPhone !== "" &&
      user.cellPhone !== "+"
    ) {
      setSnackbarMessage("companyusers.validate.error.nonvalidphone");
      setOpenSnackbar(true);
      return;
    } else {
      const name = userTypes.SUPER_ADMIN;
      const role = new Role({ name });
      const companyRef = `${dbTables.COMPANIES}/${selectedCompany.id}`;

      setDoc(doc(firestore, `${companyRef}/${dbTables.ROLES}/${role.id}`), {
        ...role,
      });
      setDoc(doc(firestore, `${dbTables.USERS}/${user.id}`), {
        ...user,
        companyId: selectedCompany.id,
        cellPhone: user.cellPhone.replace(/[^0-9+]/g, ""),
        displayName: user.displayName.slice(1),
      });
      // HERE STARTION TO ADD SPREADSHEET VALUE
      if (enableSpreadSheet) {
        setLoading(true);
        const {
          usersDB,
          customersDB,
          factoriesDB,
          permissionGroupsDB,
          SODocumentTemplateDB,
          PODocumentTemplateDB,
          shipmentDocumentTemplateDB,
          rolesDB,
          SOTemplateDB,
          POTemplateDB,
          POSpreadSheetDB,
          SOSpreadSheetDB,
          SOActivityStreamDB,
          POActivityStreamDB,
          tagsDB,
          shipmentsSpreadSheetDB,
          productsSpreadSheetDB,
          itemTableSpreadSheetDB,
        } = spreadSheetData;

        const usersDBToRegister = usersDB.filter(
          (userDB) => userDB.id !== user.id
        );

        let userBatch = writeBatch(firestore);
        usersDBToRegister.forEach((user) => {
          userBatch.set(getRandomId(), {
            ...user,
            companyId: selectedCompany.id,
          });
        });
        userBatch
          .commit()
          .then(() => console.log("SUCCESSFULLY SAVED USERS..."));

        let tagBatch = writeBatch(firestore);
        tagsDB.forEach((tag) => {
          tagBatch.set(
            doc(firestore, `${companyRef}/${dbTables.TAGS}/${tag.id}`),
            {
              ...tag,
            }
          );
        });
        tagBatch.commit().then(() => console.log("SUCCESSFULLY SAVED TAGS..."));

        const customersSummary = [];
        const customerSummaryId = getRandomId();
        let customerBatch = writeBatch(firestore);
        customersDB.forEach((customer) => {
          customerBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.CUSTOMERS}/${customer.id}`
            ),
            {
              ...customer,
              customersSummaryId: customerSummaryId,
              triggerType: triggerCustomerTypes.DISMISS_TRIGGER,
            }
          );
          customersSummary.push({
            id: customer.id,
            name: customer.name,
            number: customer.number,
            inactive: false,
          });
        });
        customerBatch
          .commit()
          .then(() => console.log("SUCCESSFULLY SAVED CUSTOMERS..."));
        console.log("customers summary", customersSummary);

        setDoc(
          doc(
            firestore,
            `${companyRef}/${dbTables.CUSTOMERS_SUMMARY}/${customerSummaryId}`
          ),
          {
            id: customerSummaryId,
            customers: customersSummary,
            size: customersDB.length,
            create: now(),
          }
        );

        const factoriesSummary = [];
        const factorySummaryId = getRandomId();
        let factoryBatch = writeBatch(firestore);
        factoriesDB.forEach((factory) => {
          factoryBatch.set(
            doc(firestore, `${companyRef}/${dbTables.FACTORIES}/${factory.id}`),
            {
              ...factory,
              factoriesSummaryId: factorySummaryId,
              triggerType: triggerFactoryTypes.DISMISS_TRIGGER,
            }
          );
          factoriesSummary.push({
            id: factory.id,
            name: factory.name,
            number: factory.number,
            inactive: false,
          });

          let templateBatch = writeBatch(firestore);
          POTemplateDB.forEach((task) => {
            delete task.taskNumber;
            templateBatch.set(
              doc(
                firestore,
                `${companyRef}/${dbTables.FACTORIES}/${factory.id}/${dbTables.MINI_PROJECT_TEMPLATE}/${task.id}`
              ),
              { ...task }
            );
          });
          templateBatch
            .commit()
            .then(() => console.log("SUCCESSFULLY SAVED FACTORY TEMPLATE..."));
        });
        factoryBatch
          .commit()
          .then(() => console.log("SUCCESSFULLY SAVED factories..."));
        console.log("factories summary", factoriesSummary);
        setDoc(
          doc(
            firestore,
            `${companyRef}/${dbTables.FACTORIES_SUMMARY}/${factorySummaryId}`
          ),
          {
            id: factorySummaryId,
            factories: factoriesSummary,
            size: factoriesDB.length,
            create: now(),
          }
        );

        let permissionBatch = writeBatch(firestore);
        permissionGroupsDB.forEach((permission) => {
          permissionBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.PERMISSION_GROUPS}/${permission.id}`
            ),
            { ...permission }
          );
        });
        permissionBatch
          .commit()
          .then(() => console.log("SUCCESSFULLY SAVED PERMISSIONS..."));

        let SODocumentTemplateBatch = writeBatch(firestore);
        SODocumentTemplateDB.forEach((SODocument) => {
          SODocumentTemplateBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.SALES_ORDER_DOCUMENTS_TEMPLATE}/${SODocument.id}`
            ),
            { ...SODocument }
          );
        });
        SODocumentTemplateBatch.commit().then(() =>
          console.log("SUCCESSFULLY SAVED SO DOCUMENT TEMPLATE...")
        );

        let PODocumentTemplateBatch = writeBatch(firestore);
        PODocumentTemplateDB.forEach((PODocument) => {
          PODocumentTemplateBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.PURCHASE_ORDER_DOCUMENTS_TEMPLATE}/${PODocument.id}`
            ),
            { ...PODocument }
          );
        });
        PODocumentTemplateBatch.commit().then(() =>
          console.log("SUCCESSFULLY SAVED PO DOCUMENT TEMPLATE...")
        );
        // SHIPMENT TEMPLATE
        let shipmentDocumentTemplateBatch = writeBatch(firestore);
        shipmentDocumentTemplateDB.forEach((shipmentDocument) => {
          shipmentDocumentTemplateBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.SHIPMENT_DOCUMENTS_TEMPLATE}/${shipmentDocument.id}`
            ),
            { ...shipmentDocument }
          );
        });
        shipmentDocumentTemplateBatch
          .commit()
          .then(() =>
            console.log("SUCCESSFULLY SAVED SHIPMENT DOCUMENT TEMPLATE...")
          );
        //
        // PRODUCT LIST
        let productsBatch = writeBatch(firestore);
        productsSpreadSheetDB.forEach((product) => {
          productsBatch.set(
            doc(firestore, `${companyRef}/${dbTables.PRODUCTS}/${product.id}`),
            { ...product }
          );
        });
        productsBatch
          .commit()
          .then(() => console.log("SUCCESSFULLY SAVED PRODUCTS..."));
        //
        let roleBatch = writeBatch(firestore);
        rolesDB.forEach((role) => {
          if (role.enabled === "Yes") {
            const rolePermissionBatch = writeBatch(firestore);
            rolePermissions.forEach((permission) => {
              rolePermissionBatch.set(
                doc(
                  firestore,
                  `${companyRef}/${dbTables.ROLES}/${role.id}/${dbTables.PERMISSIONS}/${permission.id}`
                ),
                { ...permission }
              );
            });
            rolePermissionBatch
              .commit()
              .then(() => console.log("SUCCESSFULLY SAVED ROLE PERMISSION..."));
          }

          roleBatch.set(
            doc(firestore, `${companyRef}/${dbTables.ROLES}/${role.id}`),
            { ...role }
          );
        });
        roleBatch
          .commit()
          .then(() => console.log("SUCCESSFULLY SAVED ROLE..."));

        const salesOrderTemplateObj = new SalesOrderTemplateObj({
          user: user.id,
          version: 1,
        });

        await setDoc(
          doc(
            firestore,
            `${companyRef}/${dbTables.SALES_ORDER_TEMPLATE}/${salesOrderTemplateObj.id}`
          ),
          {
            ...salesOrderTemplateObj,
          }
        );

        let SOTemplateBatch = writeBatch(firestore);
        SOTemplateDB.forEach((task) => {
          delete task.taskNumber;
          SOTemplateBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.SALES_ORDER_TEMPLATE}/${salesOrderTemplateObj.id}/${dbTables.SALES_ORDER_TASKS_TEMPLATE}/${task.id}`
            ),
            { ...task }
          );
        });
        SOTemplateBatch.commit().then(() =>
          console.log("SUCCESSFULLY SAVED SOTemplateBatch...")
        );

        // START VERIFYING HERE..
        console.log("NOW CREATING SOS");
        let SOsBatch = writeBatch(firestore);
        for (let index = 0; index < SOSpreadSheetDB.length; index++) {
          const purchaseOrders = POSpreadSheetDB.filter((po) =>
            po.salesOrderIds.includes(SOSpreadSheetDB[index].id)
          );
          const { totalCompletedTasks } = getSOCompletedTasks(
            SOSpreadSheetDB[index],
            purchaseOrders
          );

          SOsBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.SALES_ORDERS}/${SOSpreadSheetDB[index].id}`
            ),
            {
              ...SOSpreadSheetDB[index],
              companyId: selectedCompany.id,
              completedTasks: totalCompletedTasks,
            }
          );
        }
        SOsBatch.commit().then(() =>
          console.log("SUCCESSFULLY SAVED SOsBatch...")
        );

        setTimeout(() => {
          let POsBatch = writeBatch(firestore);
          console.log("NOW CREATING POS");
          POSpreadSheetDB.forEach((purchaseOrder) => {
            // ITEM TABLE:
            const itemTable = new ItemTable({
              companyId: selectedCompany.id,
              version: 1,
            });
            setDoc(
              doc(
                firestore,
                `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.ITEM_TABLE}/${itemTable.id}`
              ),
              {
                ...itemTable,
              }
            );

            POsBatch.set(
              doc(
                firestore,
                `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}`
              ),
              {
                ...purchaseOrder,
                companyId: selectedCompany.id,
                completedTasks: getCompletedTasks(purchaseOrder.taskCompleted),
                itemTableId: itemTable.id,
              }
            );

            const itemsSpreadSheet = itemTableSpreadSheetDB.filter(
              (item) => item.purchaseOrderId === purchaseOrder.id
            );
            let itemTableBatch = writeBatch(firestore);
            itemsSpreadSheet.forEach((doc) => {
              itemTableBatch.set(
                doc(
                  firestore,
                  `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.ITEM_TABLE}/${itemTable.id}/${dbTables.ITEMS}/${doc.id}`
                ),
                { ...doc, companyId: selectedCompany.id }
              );
            });
            itemTableBatch
              .commit()
              .then(() => console.log("SUCCESSFULLY SAVED itemTableBatch..."));
          });
          POsBatch.commit().then(() =>
            console.log("SUCCESSFULLY SAVED POsBatch...")
          );
        }, 15000);

        let SOActivityStreamBatch = writeBatch(firestore);
        SOActivityStreamDB.forEach((SOActivity) => {
          SOActivityStreamBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.SALES_ORDERS}/${SOActivity.salesOrderId}/${dbTables.ACTIVITIES}/${SOActivity.id}`
            ),
            { ...SOActivity, companyId: selectedCompany.id }
          );
        });
        SOActivityStreamBatch.commit().then(() =>
          console.log("SUCCESSFULLY SAVED SOActivityStreamBatch...")
        );

        let POActivityStreamBatch = writeBatch(firestore);
        POActivityStreamDB.forEach((POActivity) => {
          POActivityStreamBatch.set(
            doc(
              firestore,
              `${companyRef}/${dbTables.PURCHASE_ORDERS}/${POActivity.purchaseOrderId}/${dbTables.ACTIVITIES}/${POActivity.id}`
            ),
            { ...POActivity, companyId: selectedCompany.id }
          );
        });
        POActivityStreamBatch.commit().then(() =>
          console.log("SUCCESSFULLY SAVED POActivityStreamBatch...")
        );

        console.log("WAITING 45 SECONDS");
        setTimeout(async () => {
          for (let indexPO = 0; indexPO < POSpreadSheetDB.length; indexPO++) {
            const POTasksSnapDB = await getDocs(
              query(
                collection(
                  firestore,
                  `${companyRef}/${dbTables.PURCHASE_ORDERS}/${POSpreadSheetDB[indexPO].id}/${dbTables.PURCHASE_ORDER_TASKS}`
                ),
                orderBy("number", "asc")
              )
            );
            const POTasks = POTasksSnapDB.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            }));
            console.log("PO TASKS", POTasks);
            console.log(
              "TASK COMPLETED",
              POSpreadSheetDB[indexPO].taskCompleted
            );

            let POTasksBatch = writeBatch(firestore);
            let POLastTaskToCompleteBatch = writeBatch(firestore);
            POTasks.forEach((task, index) => {
              if (index < POSpreadSheetDB[indexPO].taskCompleted - 1) {
                POTasksBatch.set(
                  doc(
                    firestore,
                    `${companyRef}/${dbTables.PURCHASE_ORDERS}/${POSpreadSheetDB[indexPO].id}/${dbTables.PURCHASE_ORDER_TASKS}/${task.id}`
                  ),
                  {
                    ...task,
                    complete: true,
                    moved: true,
                    status: taskStatus.COMPLETE,
                    completedBy: task.assignedTo,
                    triggerType: triggerTaskTypes.DISMISS_TRIGGER,
                  }
                );
              } else if (index === POSpreadSheetDB[indexPO].taskCompleted - 1) {
                POLastTaskToCompleteBatch.set(
                  doc(
                    firestore,
                    `${companyRef}/${dbTables.PURCHASE_ORDERS}/${POSpreadSheetDB[indexPO].id}/${dbTables.PURCHASE_ORDER_TASKS}/${task.id}`
                  ),
                  {
                    ...task,
                    complete: true,
                    moved: true,
                    status: "complete",
                    completedBy: task.assignedTo,
                    dayOffset: 0,
                    triggerType: triggerTaskTypes.COMPLETE,
                  }
                );
              }
            });
            POTasksBatch.commit().then(() =>
              console.log("SUCCESSFULLY SAVED POTasksBatch...")
            );
            POLastTaskToCompleteBatch.commit().then(() =>
              console.log("SUCCESSFULLY SAVED POLastTaskToCompleteBatch...")
            );
          }

          for (let indexSO = 0; indexSO < SOSpreadSheetDB.length; indexSO++) {
            const SOTasksSnapDB = await getDocs(
              query(
                collection(
                  `${companyRef}/${dbTables.SALES_ORDERS}/${SOSpreadSheetDB[indexSO].id}/${dbTables.SALES_ORDER_TASKS}`
                ),
                orderBy("number", "asc")
              )
            );
            const SOTasks = SOTasksSnapDB.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            }));
            console.log("SO TASKS", SOTasks);
            console.log(
              "TASK COMPLETED",
              SOSpreadSheetDB[indexSO].taskCompleted
            );

            let SOTasksBatch = writeBatch(firestore);
            let SOLastTaskToCompleteBatch = writeBatch(firestore);
            for (let indexTask = 0; indexTask < SOTasks.length; indexTask++) {
              if (indexTask < SOSpreadSheetDB[indexSO].taskCompleted - 1) {
                SOTasksBatch.set(
                  doc(
                    firestore,
                    `${companyRef}/${dbTables.SALES_ORDERS}/${SOSpreadSheetDB[indexSO].id}/${dbTables.SALES_ORDER_TASKS}/${SOTasks[indexTask].id}`
                  ),
                  {
                    ...SOTasks[indexTask],
                    complete: true,
                    moved: true,
                    status: taskStatus.COMPLETE,
                    completedBy: SOTasks[indexTask].assignedTo,
                    triggerType: triggerTaskTypes.DISMISS_TRIGGER,
                  }
                );
                console.log("COMPLETE SO TASK...");
              } else if (
                indexTask ===
                SOSpreadSheetDB[indexSO].taskCompleted - 1
              ) {
                setDoc(
                  doc(
                    firestore,
                    `${companyRef}/${dbTables.SALES_ORDERS}/${SOSpreadSheetDB[indexSO].id}/${dbTables.SALES_ORDER_TASKS}/${SOTasks[indexTask].id}`
                  ),
                  {
                    ...SOTasks[indexTask],
                    complete: true,
                    moved: true,
                    status: taskStatus.COMPLETE,
                    completedBy: SOTasks[indexTask].assignedTo,
                    dayOffset: 0,
                    triggerType: triggerTaskTypes.COMPLETE,
                  }
                );
              }
            }
            SOTasksBatch.commit().then(() =>
              console.log("SUCCESSFULLY SAVED SOTasksBatch...")
            );
            SOLastTaskToCompleteBatch.commit().then(() =>
              console.log("SUCCESSFULLY SAVED LAST SO TASK TO COMPLETE")
            );
          }

          // SHIPMENTS
          let shipmentsBatch = writeBatch(firestore);
          console.log("CREATING SHIPMENTS");
          shipmentsSpreadSheetDB.forEach((shipment) => {
            shipmentsBatch.set(
              doc(
                firestore,
                `${companyRef}/${dbTables.SHIPMENTS}/${shipment.id}`
              ),
              {
                ...shipment,
                companyId: selectedCompany.id,
                completedTasks: getCompletedTasks(shipment.taskCompleted),
              }
            );
          });
          shipmentsBatch
            .commit()
            .then(() => console.log("SUCCESSFULLY SAVED shipmentsBatch..."));
          //

          onSubmit({ ...selectedCompany });
          setCompanyName("");
          setUser(new User({ id: getRandomId(), role: userTypes.SUPER_ADMIN }));
          setLoading(false);
        }, 45000);
      } else {
        onSubmit({ ...selectedCompany });
        setCompanyName("");
        setUser(new User({ id: getRandomId(), role: userTypes.SUPER_ADMIN }));
      }
      //
    }
  }

  function handleKeyPress(keyEvent) {
    if (errorMessage || !selectedCompany.name) {
      return;
    }
    if (keyEvent.key === "Enter") {
      companies.find(
        (item) => item.name.toLowerCase() === selectedCompany.name.toLowerCase()
      )
        ? setErrorMessage("errormessage.companynameissue")
        : handleSubmit();
    }
  }

  function handleSuccessSpreadSheet(spreadSheetData) {
    console.log("SPREAD SHEET DATA", spreadSheetData);
    setIsSuccessSpreadSheetMessage(true);
    setSpreadSheetMessage("Successfull imported");
    setSpreadSheetData(spreadSheetData);
    setUser({
      ...spreadSheetData.superAdmin,
      companyId: selectedCompany.id,
      displayName: "@" + spreadSheetData.superAdmin.displayName,
    });
    setLoading(false);
  }

  function handleSuccessGoogleOAuthLogged() {
    console.log("handleSuccessGoogleOAuthLogged");
    setIsSuccessSpreadSheetMessage(true);
    setSpreadSheetMessage("Successfull Loged");
  }

  return (
    <CustomModal
      isOpen={open}
      onClose={loading ? () => {} : onClose}
      header={<IntlMessages id={"companymodal.custommodal.createcompany"} />}
      className="new-company-modal"
    >
      <form
        onSubmit={(ev) => {
          companies.find(
            (item) =>
              item.name.toLowerCase() === selectedCompany.name.toLowerCase()
          )
            ? setErrorMessage("errormessage.companynameissue")
            : handleSubmit();
          ev.preventDefault();
        }}
      >
        {loading && (
          <Loader
            style={{
              justifyContent: "center",
              display: "flex",
              zIndex: 1000,
              alignItems: "flex-start",
              top: 166,
            }}
          />
        )}

        {openSnackbar && (
          <CustomSnackbar
            open={openSnackbar}
            message={snackbarMessage || "empty"}
            variant="error"
            handleCloseSnackbar={() => setOpenSnackbar(false)}
            autoHideDuration={52500}
          />
        )}
        <div className="companyInfo">
          <div className="avatar">
            Company Avatar
            <UploadAvatar
              onUpload={onChangeFieldValue("url_logo")}
              obj={selectedCompany}
              storageLocation={`${dbTables.COMPANIES}/${selectedCompany.id}`}
            />
          </div>
          <div className="name">
            Company Name:
            <TextField
              onKeyPress={handleKeyPress}
              helperText={
                errorMessage ? <IntlMessages id={errorMessage} /> : ""
              }
              error={openSnackbar ? "" : errorMessage}
              label={"Name"}
              variant="outlined"
              fullWidth
              autoFocus
              value={companyName}
              onChange={(event) => {
                onChangeField("name", event);
              }}
            />
          </div>
        </div>
        <div className="bodyNewCompany">
          <div className="metadata">
            <FormGroup className="userAvatar">
              Super Admin User
              <UploadAvatar
                obj={{
                  url_logo: user.avatar,
                  name: user.firstName,
                }}
                storageLocation={`${dbTables.USERS}/${user.id}`}
                onUpload={(url) => setUser({ ...user, avatar: url })}
                className="user-avatar"
                style={{
                  zIndex: 10,
                  opacity: 0,
                  width: 80,
                  height: 80,
                  borderRadius: "50%",
                  position: "absolute",
                  cursor: "pointer",
                }}
                classNameHover="avatar-hover-userform"
              />
            </FormGroup>
            <FormGroup>
              <FormTextField
                width={250}
                label="generic.textForm.displayName"
                value={user.displayName}
                onChange={onChange("displayName")}
                placeholder="userscompany.createuserform.displayname.required"
                required={true}
                inputProps={{ maxLength: 20, minLength: 2 }}
                name="displayname"
                disabled={enableSpreadSheet}
              />
              <FormTextField
                label="generic.textForm.email"
                width={250}
                value={user.email}
                onChange={onChange("email")}
                placeholder="userscompany.createuserform.email.required"
                required={true}
                type="email"
                fullWidth={true}
                autoFocus={true}
                disabled={enableSpreadSheet}
              />
              <FormTextField
                width={250}
                label="generic.textForm.firstName"
                value={user.firstName}
                onChange={onChange("firstName")}
                placeholder="userscompany.createuserform.firstname.required"
                required={true}
                inputProps={{ maxLength: 20, minLength: 2 }}
                disabled={enableSpreadSheet}
              />
              <FormTextField
                width={250}
                label="generic.textForm.lastName"
                value={user.lastName}
                onChange={onChange("lastName")}
                placeholder="userscompany.createuserform.lastname.required"
                required={true}
                inputProps={{ maxLength: 20, minLength: 2 }}
                disabled={enableSpreadSheet}
              />
              <FormTextField
                label="generic.textForm.cellPhone"
                value={user.cellPhone}
                onChange={(event) => setUser({ ...user, cellPhone: event })}
                required={false}
                width={250}
                type="phone"
                autoFocus={true}
                disabled={enableSpreadSheet}
              />
              <FormTextField
                width={250}
                label="generic.textForm.role"
                value={user.role}
                onChange={onChange("role")}
                placeholder="userscompany.createuserform.role.required"
                required={true}
                disabled={enableSpreadSheet}
              />
            </FormGroup>
          </div>
        </div>

        <div className="buttomAction">
          <CustomButton
            noSubmit
            margin="0px 8px"
            type="cancel"
            onClick={() => {
              setCompanyName("");
              setEnableSpreadSheet(false);
              setErrorMessage("");
              setSpreadSheetMessage("");
              onClose();
            }}
          >
            {"generic.text.cancel"}
          </CustomButton>
          <CustomButton
            disabled={
              errorMessage ||
              !selectedCompany.name ||
              (enableSpreadSheet && !isSuccessSpreadSheetMessage)
            }
            margin="0 0 0 8px"
            type={"success"}
          >
            {company ? "generic.text.update" : "generic.text.create"}
          </CustomButton>
        </div>
      </form>
      <div>
        <Switch
          checked={enableSpreadSheet}
          onChange={() => {
            setEnableSpreadSheet(!enableSpreadSheet);
            setIsSuccessSpreadSheetMessage(false);
            setSpreadSheetMessage("");
          }}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        {spreadSheetMessage && (
          <div
          // style={
          //   isSuccessSpreadSheetMessage
          //     ? styles.spreadsheetMessageGreen
          //     : styles.spreadsheetMessageRed
          // }
          >
            {spreadSheetMessage}
          </div>
        )}
        {enableSpreadSheet && (
          <SpreadSheetOptions
            handleShowMessage={(message) => {
              setIsSuccessSpreadSheetMessage(false);
              setSpreadSheetMessage(message);
              setSpreadSheetData({});
              setLoading(false);
            }}
            handleSuccessSpreadSheet={handleSuccessSpreadSheet}
            handleSetLoader={() => setLoading(true)}
            handleSuccessGoogleOAuthLogged={handleSuccessGoogleOAuthLogged}
          />
        )}
      </div>
    </CustomModal>
  );
}

export default CompanyModal;
