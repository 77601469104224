import React from "react";
import newFilePlaceholder from "../../assets/flag-icons/new-file-placeholder.svg";
import { isShipmentRootFile } from "../../helpers/documents";
import { TYPE_OF_FILE } from "../../helpers/constants";
import {
  DeleteIconv2,
  FolderIconv2,
  PermissionIcon,
} from "../../helpers/iconSvgPath";
import { PlaceholderDocumentOptionStyled } from "./styles";

function DocumentOptions({
  currentDocument,
  handleDeleteFolder,
  onAddNewFolder,
  handleOpenPermissions,
  dbTable,
}) {
  return (
    <PlaceholderDocumentOptionStyled className="documentOptionContainer">
      <div className={"content"}>
        {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
          <FolderIconv2
            onClick={(event) => {
              onAddNewFolder(TYPE_OF_FILE.FOLDER);
              event.stopPropagation();
            }}
            width={23}
            height={22}
          />
        ) : (
          <div style={{ width: 23, height: 22 }} />
        )}
      </div>
      <div className={"content"}>
        {currentDocument.type === TYPE_OF_FILE.FOLDER ? (
          <img
            src={newFilePlaceholder}
            alt="file"
            onClick={(event) => {
              onAddNewFolder("file");
              event.stopPropagation();
            }}
            style={{ width: 20, height: 21 }}
          />
        ) : (
          <div style={{ width: 20, height: 21 }} />
        )}
      </div>
      <div className={"content"}>
        <PermissionIcon
          onClick={(event) => {
            handleOpenPermissions();
            event.stopPropagation();
          }}
          width={26}
          height={23}
        />
      </div>
      {!isShipmentRootFile({
        scope: dbTable,
        folderName: currentDocument.name,
      }) && (
        <div className={"content"}>
          <DeleteIconv2
            svgClass={"imgListItemIconDeletePlaceholder"}
            onClick={() => {
              handleDeleteFolder(currentDocument);
            }}
            width={20}
            height={22}
          />
        </div>
      )}
    </PlaceholderDocumentOptionStyled>
  );
}

export default DocumentOptions;
