import { Paper } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import React, { useMemo } from "react";
import { dayHeight, dayWidth } from "../../helpers/constants";
import { sortObjectsBy } from "../../helpers/helpers";
import {
  convertHex,
  getCalendarItemClass,
  getDate,
  getMilestone,
  getOnHoverClass,
  getWeekendClass,
  isThereGhostTask,
  isVerticalHighlighed,
} from "../../helpers/timelineCalendar";
import { useUser } from "../../hooks/user";
import CalendarHeader from "./CalendarHeader";
import CalendarPhase from "./CalendarPhase";
import OptionsTableContainer from "./OptionsTableContainer";
import ResizableComponent from "./ResizableComponent";
import TablePhase from "./TablePhase";
import TaskTableContent from "./TaskTableContent";
import TaskTableHeader from "./TaskTableHeader";
import VerticalIndicator from "./VerticalIndicator";

const fixedRowCount = 3;
const zero = 0;

function CellRendererItem({
  columnIndex,
  rowIndex,
  style,
  flagDates,
  milestones,
  flagViewOptions,
  tasks,
  taskOnHover,
  tableSize,
  onChangeTableSize,
  onChangeViewOptions,
  isOpenAllSections,
  onChangeOpenAllSection,
  canRedo,
  canUndo,
  undoTasksState,
  redoTasksState,
  onChangeMilestone,
  onDeleteMilestone,
  onAddNewMilestone,
  onCloseMilestone,
  taskPhases,
  onChangeTaskPhase,
  allTasks,
  companyUsers,
  onChangeTask,
  onMoveRemainingTasks,
  ghostTasks,
  onDropTask,
  onChangeTaskDuration,
  onUserOnHover,
  onTaskOnHover,
  isToUpdate,
  viewOptionsArray,
  selectedShipments,
  onMouseEnterInTaskDescription,
  isAbleToModifyTaskDueDate,
  hasPermissionToReassign,
  filteredTasks,
  orders,
}) {
  const currentUser = useUser();
  const memoizedToRender = useMemo(() => {
    return render({ rowIndex, columnIndex, style });
  }, [columnIndex, rowIndex, isToUpdate, tasks]);

  function render({ rowIndex, style, columnIndex }) {
    let milestone = getMilestone({
      day: flagDates[columnIndex],
      milestones: milestones,
      flagViewOptions,
    });
    let additionalStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      color: "#334C73",
      fontWeight: 400,
    };
    const date = flagDates[columnIndex];
    let calendarMonthStyles = {};
    let calendarDayStyles = {
      borderBottom: "none",
      height: dayHeight,
    };
    if (
      getDate({ date, unitTime: "month" }) ===
      getDate({ date, unitTime: "day" })
    ) {
      calendarMonthStyles = {
        ...calendarMonthStyles,
        borderLeft: "1px solid white",
      };
    }

    const task = tasks.sort(sortObjectsBy("numberIndex", false))[rowIndex - 3];
    const onHoverStyles = getOnHoverClass({ taskOnHover, task });
    const verticalIndicator = isVerticalHighlighed({ date, taskOnHover });

    if (rowIndex >= zero && rowIndex < fixedRowCount) {
      if (columnIndex >= zero && columnIndex < tableSize) {
        if (rowIndex >= zero && rowIndex <= zero + 1) {
          return (
            <OptionsTableContainer
              styles={{ ...style, borderRadius: 0 }}
              columnIndex={columnIndex}
              tableSize={tableSize}
              onChangeTableSize={onChangeTableSize}
              viewOptionsArray={viewOptionsArray || []}
              milestones={milestones || []}
              rowIndex={rowIndex}
              onChangeViewOptions={onChangeViewOptions}
              isOpenAllSections={isOpenAllSections}
              onChangeOpenAllSection={onChangeOpenAllSection}
              canRedo={canRedo}
              canUndo={canUndo}
              undoTasksState={undoTasksState}
              redoTasksState={redoTasksState}
              onChangeMilestone={onChangeMilestone}
              onDeleteMilestone={onDeleteMilestone}
              onAddNewMilestone={onAddNewMilestone}
              onCloseMilestone={onCloseMilestone}
              flagViewOptions={flagViewOptions}
            />
          );
        } else if (rowIndex === 2) {
          return (
            <TaskTableHeader
              styles={{
                ...style,
                borderRadius: 0,
                ...onHoverStyles,
              }}
              columnIndex={columnIndex}
              tableSize={tableSize}
              onChangeTableSize={onChangeTableSize}
              isOpenAllSections={isOpenAllSections}
              onChangeOpenAllSection={onChangeOpenAllSection}
              flagViewOptions={flagViewOptions}
            />
          );
        }
        return (
          <Paper
            style={{
              ...style,
              borderRadius: 0,
              ...onHoverStyles,
            }}
            elevation={0}
          ></Paper>
        );
      } else {
        if (rowIndex === 0) {
          return (
            <CalendarHeader
              styles={{
                ...style,
                ...additionalStyles,
                ...calendarMonthStyles,
                borderRadius: 0,
                background: "#E9E9E9",
                fontWeight: 700,
              }}
              text={moment(date).date() === 15 && moment(date).format("MMMM")}
            />
          );
        } else if (rowIndex === 1 || rowIndex === 2) {
          return (
            <CalendarHeader
              className={classNames(
                "calendarHeader",
                getWeekendClass(date, flagViewOptions)
              )}
              styles={{
                ...style,
                ...additionalStyles,
                ...calendarDayStyles,
                ...onHoverStyles,
                borderBottom: rowIndex === 2 ? "1px solid #C6E1FF" : "0px",
              }}
              text={
                rowIndex === 1
                  ? moment(flagDates[columnIndex]).format("D")
                  : moment(flagDates[columnIndex]).format("dd")[0]
              }
              milestone={milestone}
              rowIndex={rowIndex}
              verticalIndicator={verticalIndicator}
              taskOnHover={taskOnHover}
              date={date}
            />
          );
        }
      }
    }
    const predecesorTask = tasks.find((el) => el.id === task.dependency);
    if (columnIndex >= zero && columnIndex < tableSize) {
      if (task.isPhase) {
        return (
          <TablePhase
            styles={{
              ...style,
              borderRadius: 0,
              background: "#D8E3F0",
            }}
            columnIndex={columnIndex}
            task={task}
            className={
              columnIndex === 0
                ? "startPhase"
                : columnIndex === 1
                ? "descriptionPhase"
                : "endPhase"
            }
            tableSize={tableSize}
            onChangeTableSize={onChangeTableSize}
            taskPhases={taskPhases}
            onChangeTaskPhase={onChangeTaskPhase}
            flagViewOptions={flagViewOptions}
            filteredTasks={filteredTasks}
          />
        );
      }
      return (
        <TaskTableContent
          columnIndex={columnIndex}
          task={task}
          predecesorTask={predecesorTask}
          styles={{ ...style, borderRadius: 0 }}
          onHoverStyles={{ ...onHoverStyles }}
          companyUsers={companyUsers}
          onMouseEnter={(userId) => onUserOnHover(userId)}
          onMouseLeave={() => onUserOnHover(null)}
          tableSize={tableSize}
          onChangeTask={onChangeTask}
          onMoveRemainingTasks={onMoveRemainingTasks}
          selectedShipments={selectedShipments}
          menuOptions={viewOptionsArray}
          flagViewOptions={flagViewOptions}
          onMouseEnterInTaskDescription={onMouseEnterInTaskDescription}
          alltasks={allTasks}
          isAbleToModifyTaskDueDate={isAbleToModifyTaskDueDate}
          hasPermissionToReassign={hasPermissionToReassign}
          orders={orders}
        />
      );
    } else {
      const ghostTask = ghostTasks.sort(sortObjectsBy("numberIndex", false))[
        rowIndex - 3
      ];
      if (!task) {
        return;
      }
      if (task && task.isPhase) {
        return (
          <CalendarPhase
            className={classNames(
              "phase",
              getWeekendClass(date, flagViewOptions)
            )}
            styles={{
              ...style,
              ...additionalStyles,
              borderRadius: 0,
            }}
            date={date}
            milestone={milestone}
            task={task}
            verticalIndicator={verticalIndicator}
            taskOnHover={taskOnHover}
            flagViewOptions={flagViewOptions}
            currentUser={currentUser}
          />
        );
      }
      if (rowIndex >= fixedRowCount && task && date) {
        if (
          getDate({
            date: task.startDate,
          }) === getDate({ date })
        ) {
          return (
            <Paper
              id={task.id + "paperGrid"}
              style={{
                ...style,
                ...additionalStyles,
                borderRadius: 0,
                ...onHoverStyles,
              }}
              className={classNames(
                "paperTask",
                getWeekendClass(date, flagViewOptions),
                getCalendarItemClass({ date, task, currentUser }) + task.type
              )}
              onDragStart={(ev) => {
                ev.dataTransfer.setData("id", task.id);
                ev.dataTransfer.setData("duration", task.duration);
              }}
              elevation={0}
            >
              {isThereGhostTask({ task, ghostTask, flagViewOptions }) &&
                getDate({
                  date: ghostTask.startDate,
                }) === getDate({ date }) && (
                  <div
                    className={"ghostTask"}
                    style={{
                      width: dayWidth * ghostTask.duration,
                      background: convertHex(task.color, 0.6),
                      border: `2px dashed ${task.color}`,
                    }}
                  ></div>
                )}
              <ResizableComponent
                task={task}
                onHover={(value) => {
                  return onTaskOnHover({ ...value });
                }}
                onDropTask={onDropTask}
                onChangeTaskDuration={onChangeTaskDuration}
                taskOnHover={taskOnHover}
                predecesorTask={predecesorTask}
                flagViewOptions={flagViewOptions}
                tasks={tasks}
              />
            </Paper>
          );
        } else {
          return (
            <Paper
              onDragOver={(ev) => {
                ev.preventDefault();
              }}
              className={classNames(
                "draggable",
                getWeekendClass(date, flagViewOptions)
              )}
              style={{
                ...style,
                ...additionalStyles,
                borderRadius: 0,
                height: dayHeight,
                alignItems: "flex-start",
              }}
              elevation={0}
            >
              <div
                className={
                  getCalendarItemClass({ date, task, currentUser }) + task.type
                }
                style={{
                  ...additionalStyles,
                  ...onHoverStyles,
                  height: dayHeight - 1,
                  width: dayWidth,
                  position: "absolute",
                  borderColor: task.color,
                }}
              ></div>
              {milestone && (
                <div
                  className="milesone-line-divider"
                  style={{
                    background: milestone.color,
                    width: 2,
                    height: "100%",
                    marginLeft: "auto",
                    marginRight: 1,
                    zIndex: 1,
                  }}
                />
              )}
              {isThereGhostTask({ task, ghostTask, flagViewOptions }) &&
                getDate({
                  date: ghostTask.startDate,
                }) === getDate({ date }) && (
                  <div
                    className={"ghostTask"}
                    style={{
                      width: dayWidth * ghostTask.duration,
                      background: convertHex(task.color, 0.6),
                      border: `2px dashed ${task.color}`,
                    }}
                  ></div>
                )}

              {verticalIndicator && (
                <VerticalIndicator
                  taskOnHover={taskOnHover}
                  date={date}
                  size={1}
                />
              )}
            </Paper>
          );
        }
      }
    }
  }
  return memoizedToRender;
}

export default CellRendererItem;
