import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";
import { orderType } from "../../helpers/salesOrder";
import { PO_STATUS } from "../../helpers/constants";

export default class PurchaseOrder {
  constructor({
    id = getRandomId(),
    amount = parseFloat(0.0),
    factoryId = "",
    number = "",
    date = null,
    CBM = "",
    invoice = "",
    shipDate = null,
    orderReadyDate = null,
    shipDates = [],
    weight = "",
    deposit = "",
    potentialLateFee = parseFloat(0.0),
    uniqueItems = 0,
    balance = "",
    shippingTerms = "",
    creationDate = now(),
    companyId = "",
    salesOrderIds = [],
    completedTasks = 0,
    totalTasks = 0,
    updated_by = "",
    tags = [],
    type = orderType.PURCHASE_ORDER,
    status = PO_STATUS.IN_PROGRESS,
    diamondStatus = "",
    shipmentIds = [],
    discount = 0,
    itemTableId = "",
  }) {
    this.id = id;
    this.amount = amount;
    this.factoryId = factoryId;
    this.CBM = CBM;
    this.companyId = companyId;
    this.invoice = invoice;
    this.weight = weight;
    this.uniqueItems = uniqueItems;
    this.balance = balance;
    this.completedTasks = completedTasks;
    this.creationDate = creationDate;
    this.date = date;
    this.deposit = deposit;
    this.number = number.toString();
    this.potentialLateFee = potentialLateFee;
    this.salesOrderIds = salesOrderIds;
    this.shipDate = shipDate;
    this.orderReadyDate = orderReadyDate;
    this.shipDates = shipDates;
    this.shippingTerms = shippingTerms;
    this.totalTasks = totalTasks;
    this.updated_by = updated_by;
    this.tags = tags;
    this.type = type;
    this.status = status;
    this.diamondStatus = diamondStatus;
    this.shipmentIds = shipmentIds;
    this.discount = discount;
    this.itemTableId = itemTableId;
  }
}
