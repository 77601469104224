import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REPORTS_VIEW } from "../../actions/types";
import { dbTables, reduxState } from "../../api/types/dbTables";
import {
  useCompanyId,
  useJSONLocalStorage,
  usePermissionGroups,
} from "../../hooks";
import ReportDrawer from "../DrawerContainer/ReportDrawer";
import { ReportStyled } from "./styles";
import axios from "axios";
import Loader from "../../components/General/Loader";
import AttentionModal from "../../components/Modal/AttentionModal";
import moment from "moment";
import { updateDoc } from "firebase/firestore";
import { useUser } from "../../hooks/user";
import { filterReports } from "../../helpers/reports";

function ReportScreen() {
  const [open, setOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(
    <React.Fragment></React.Fragment>
  );
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const user = useUser();
  const companyId = useCompanyId();
  let reports =
    useSelector((state) => state.data[dbTables.REPORTS][companyId]) || [];
  const accessGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });

  const { get: getReportViewStorage, set: setReportViewStorage } =
    useJSONLocalStorage("reports_view");
  const reports_view = useSelector(
    (state) => state.data[reduxState.REPORTS_VIEW]
  ) || { open: false };
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(reports_view.open);
    const reportIdStorage =
      getReportViewStorage() && getReportViewStorage().report_id;
    if (reportIdStorage) {
      const currentReport = filterReports({ reports, user, accessGroups }).find(
        (report) => report.id === reportIdStorage
      );
      if (currentReport) {
        setCurrentReport({ ...currentReport });
      }
    }
    setOpen(reports_view.open);
  }, [reports_view.open]);

  return (
    <ReportStyled className="reportContainer">
      {currentReport.URLToUpdateReport && (
        <div className="URL-to-update-report-content">
          <button
            onClick={async () => {
              console.log("UPDATING...");
              setLoading(true);
              const response = await axios.get(currentReport.URLToUpdateReport);
              if (response.status === 200) {
                updateDoc(currentReport.ref, {
                  lastUpdated: moment.now(),
                });
                setCurrentReport({
                  ...currentReport,
                  lastUpdated: moment.now(),
                });
                setLoading(false);
                setDescriptionModal(
                  <React.Fragment>
                    The report has been updated successfully
                  </React.Fragment>
                );
                setOpenAttentionModal(true);
              } else {
                setDescriptionModal(
                  <React.Fragment>
                    Something went wrong updating the report, contact with
                    TradeDash support
                  </React.Fragment>
                );
                setOpenAttentionModal(true);
              }
            }}
          >
            Update Report
          </button>

          {currentReport.lastUpdated && (
            <div>
              <span>
                Last updated{" "}
                {moment(currentReport.lastUpdated).format(
                  "MMM DD, YYYY h:mm a"
                )}
              </span>
            </div>
          )}
        </div>
      )}
      {currentReport.URLToDownloadReport && (
        <div className="URL-to-update-report-content" style={{ left: 272 }}>
          <button
            onClick={async () => {
              try {
                const element = document.getElementById(
                  "report-to-download-csv"
                );
                element.click();
                setDescriptionModal(
                  <React.Fragment>
                    The report has been downloaded successfully
                  </React.Fragment>
                );
                setOpenAttentionModal(true);
              } catch (error) {
                console.log(error);
                setDescriptionModal(
                  <React.Fragment>
                    Something went wrong downloading the report, contact with
                    TradeDash support
                  </React.Fragment>
                );
                setOpenAttentionModal(true);
              }
            }}
          >
            Download Report Data
          </button>
          <a
            id="report-to-download-csv"
            href={currentReport.URLToDownloadReport}
            style={{ display: "none" }}
          >
            Link
          </a>
        </div>
      )}
      {loading && (
        <Loader
          style={{
            top: 0,
            left: 0,
            background: "rgb(0,0,0,0.15)",
          }}
        />
      )}
      {openAttentionModal && (
        <AttentionModal
          title=""
          description={descriptionModal}
          isOpen={openAttentionModal}
          onClick={() => setOpenAttentionModal(false)}
          onClose={() => setOpenAttentionModal(false)}
        />
      )}
      <ReportDrawer
        open={open}
        handleIsOpen={() => {
          dispatch({
            type: REPORTS_VIEW,
            table: reduxState.REPORTS_VIEW,
            payload: {
              open: false,
            },
          });
        }}
        onChange={({ report }) => {
          setCurrentReport(report);
          setReportViewStorage({
            report_id: report.id,
          });
        }}
        reports={filterReports({ reports, user, accessGroups })}
        currentReport={currentReport}
      />
      {currentReport.reportEmbed && (
        <iframe
          id={"report-embed-id"}
          title="title"
          style={{ width: "100%", height: "100%" }}
          src={currentReport.reportEmbed}
        />
      )}
    </ReportStyled>
  );
}

export default ReportScreen;
