import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { contextMenu, Item, Menu } from "react-contexify";
import { useSelector } from "react-redux";
import { dbTables } from "../../api/types/dbTables";
import userTypes from "../../api/types/userTypes";
import copyLink from "../../assets/flag-icons/copy-link-icon.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { firestore } from "../../firebase";
import { TYPE_OF_FILE } from "../../helpers/constants";
// import { trackEvent } from "../../helpers/analytics";
import {
  canDeleteFileFolder,
  createActivityCopyLink,
  createActivityDownload,
  documentScope,
  moveToRootLevelWithDependencies,
  superAdminAssignmentPermissions,
  updateParentFolderTouched,
} from "../../helpers/documents";
import { getRandomId } from "../../helpers/helpers";
import {
  CancelIcon,
  CopyLinkIcon,
  DeleteIconv2,
  DownloadIcon,
  EditFileIcon,
  FolderIconv2,
  MoveToRootIcon,
  NewVersionIcon,
  PermissionIcon,
  UploadFileIcon,
} from "../../helpers/iconSvgPath";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { usePurchaseOrders, useSalesOrders } from "../../hooks/salesOrders";
import IntlMessages from "../../util/IntlMessages";
import ViewerSKU from "../PurchaseOrderDashboard/ViewerSKU";
import { Portal } from "@mui/base";
import { Grow, Snackbar } from "@mui/material";
import { PDFPortalStyled } from "../Tables/styles";

const typeImage = ["png", "jpg", "jpeg", "svg"];
const previewAvaliable = [...typeImage, "pdf"];

function MenuOptions({
  onAddNewFolder,
  onUploadFiles,
  onRename,
  onPermission,
  onDeleteEvent,
  currentDocument,
  iconUploadNewVersionFile,
  type,
  selectedDocumentTab,
  companyID,
  mainTableID,
  dbTable,
  mainTable,
  disabled,
  currentUser,
  onOpenSnackBar,
  calledByMenuOptions,
  documentFlat,
  onShowModal,
  permissionGroupsDB,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  companyUsers = [],
  isOrderVoided,
}) {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [photosViewer, setPhotosViewer] = useState([
    { src: "", number: "", description: "" },
  ]);
  const [showPDF, setShowPDF] = useState(false);
  const salesOrders = useSalesOrders();
  const purchaseOrders = usePurchaseOrders();
  const shipments = useSelector((state) => state.data.shipments[companyID]);
  const documentScopesToGenerateActivity = [
    documentScope.SALES_ORDER,
    documentScope.PURCHASE_ORDER,
    documentScope.SHIPMENT,
  ];
  const isAllowed = useIsAllowedFunction();
  const documentType = (currentDocument.type || "").toLowerCase();

  function onCopyLink() {
    if (!navigator.onLine) {
      onOpenSnackBar("Copy link");
      contextMenu.hideAll();
      return;
    }
    if (documentType === TYPE_OF_FILE.FOLDER) {
      // trackEvent("PO Dashboard - Documents - Folder - Link Copied");
    } else {
      // trackEvent("PO Dashboard - Documents - File - Link Copied");
    }
    if (disabled) {
      contextMenu.hideAll();
      return;
    }
    const copyLink = document.getElementById("inputURLDocumentFile");
    const link = copyLink.value;
    copyLink.select();
    document.execCommand("copy");
    setOpenSnackBar(true);
    // IMPLEMENTING TO ADD SUPER ADMIN IF IT IS NOT A PART OF THIS DOCUMENT

    const randomId = getRandomId();
    setDoc(doc(firestore, `${dbTables.SHARED_LINKS}/${randomId}`), {
      id: randomId,
      companyId: companyID,
      parentCollection:
        currentDocument.scope === documentScope.SHIPMENT
          ? dbTables.SHIPMENTS
          : mainTable,
      parentDocumentId:
        currentDocument.scope === documentScope.SHIPMENT
          ? currentDocument.shipmentId
          : mainTableID,
      collection:
        currentDocument.scope === documentScope.SHIPMENT
          ? dbTables.SHIPMENT_DOCUMENTS
          : dbTable,
      documentId: currentDocument.id,
      userId: currentUser.id,
      link,
      mainFileId: currentDocument.mainFileId,
    });
    if (currentUser.role === userTypes.SUPER_ADMIN) {
      superAdminAssignmentPermissions({
        currentDocument,
        permissionGroupsDB,
        currentUser,
        allDocuments: documentFlat,
      });
    }

    if (documentScopesToGenerateActivity.includes(currentDocument.scope)) {
      let order = {};
      if (currentDocument.scope === documentScope.SALES_ORDER) {
        const salesOrder = salesOrders.find(
          (salesOrder) => salesOrder.id === mainTableID
        );

        order = {
          ...salesOrder,
          unreadActivityUsers: [...salesOrder.unreadActivityUsers],
        };
      } else if (currentDocument.scope === documentScope.PURCHASE_ORDER) {
        const purchaseOrder = purchaseOrders.find(
          (purchaseOrder) => purchaseOrder.id === mainTableID
        );
        order = {
          ...purchaseOrder,
          unreadActivityUsers: purchaseOrder.unreadActivityUsers
            ? [...purchaseOrder.unreadActivityUsers]
            : [],
        };
      } else if (currentDocument.scope === documentScope.SHIPMENT) {
        const currentShipment = shipments.find(
          (shipment) => shipment.id === currentDocument.shipmentId
        );
        if (currentShipment && currentShipment.ref) {
          if (currentShipment.unreadActivityUsers) {
            order = {
              ...currentShipment,
              unreadActivityUsers: [...currentShipment.unreadActivityUsers],
            };
          } else {
            order = { ...currentShipment, unreadActivityUsers: [] };
          }
        }
      }
      createActivityCopyLink({
        companyID,
        mainTable:
          currentDocument.scope === documentScope.SHIPMENT
            ? dbTables.SHIPMENTS
            : mainTable,
        mainTableID:
          currentDocument.scope === documentScope.SHIPMENT
            ? currentDocument.shipmentId
            : mainTableID,
        currentUser,
        currentDocument,
        scope: currentDocument.scope,
        companyUsers: companyUsers,
        order,
      });
    }
    contextMenu.hideAll();
  }

  function handleClose() {
    setOpenSnackBar(false);
  }

  async function handleImgDownload(url) {
    if (!navigator.onLine) {
      onOpenSnackBar(
        documentType === TYPE_OF_FILE.FOLDER
          ? "Folder download"
          : "File download"
      );
      return;
    }
    // IMPLEMENTING TO ADD SUPER ADMIN IF IT IS NOT A PART OF THIS DOCUMENT
    if (currentUser.role === userTypes.SUPER_ADMIN) {
      superAdminAssignmentPermissions({
        currentDocument,
        permissionGroupsDB,
        currentUser,
        allDocuments: documentFlat,
      });
    }

    const mainTableId =
      currentDocument.scope === documentScope.SHIPMENT
        ? currentDocument.shipmentId
        : mainTableID;

    if (documentScopesToGenerateActivity.includes(currentDocument.scope)) {
      let order = {};
      if (currentDocument.scope === documentScope.SALES_ORDER) {
        const salesOrder = salesOrders.find(
          (salesOrder) => salesOrder.id === mainTableID
        );

        order = {
          ...salesOrder,
          unreadActivityUsers: [...salesOrder.unreadActivityUsers],
        };
      } else if (currentDocument.scope === documentScope.PURCHASE_ORDER) {
        const purchaseOrder = purchaseOrders.find(
          (purchaseOrder) => purchaseOrder.id === mainTableID
        );
        order = {
          ...purchaseOrder,
          unreadActivityUsers: purchaseOrder.unreadActivityUsers
            ? [...purchaseOrder.unreadActivityUsers]
            : [],
        };
      } else if (currentDocument.scope === documentScope.SHIPMENT) {
        const currentShipment = shipments.find(
          (shipment) => shipment.id === currentDocument.shipmentId
        );
        if (currentShipment && currentShipment.ref) {
          if (currentShipment.unreadActivityUsers) {
            order = {
              ...currentShipment,
              unreadActivityUsers: [...currentShipment.unreadActivityUsers],
            };
          } else {
            order = { ...currentShipment, unreadActivityUsers: [] };
          }
        }
      }

      createActivityDownload({
        companyID,
        currentDocument,
        userId: currentUser.id,
        mainTableID: mainTableId,
        mainTable,
        scope: currentDocument.scope,
        isLandingPage: false,
        companyUsers: companyUsers,
        order,
      });
    }
    if (documentType === TYPE_OF_FILE.FOLDER) {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - Folder - Download");
      //
      const parentCollection =
        currentDocument.scope === documentScope.SHIPMENT
          ? dbTables.SHIPMENTS
          : mainTable;
      const parentDocumentId =
        currentDocument.scope === documentScope.SHIPMENT
          ? currentDocument.shipmentId
          : mainTableID;
      const collection =
        currentDocument.scope === documentScope.SHIPMENT
          ? dbTables.SHIPMENT_DOCUMENTS
          : dbTable;
      const documentId = currentDocument.id;
      let a = document.createElement("a");
      a.href = `${getFunctionByName({
        name: "downloadDocument",
        env: globalEnvironment,
        params: `?companyId=${companyID}&parentCollection=${parentCollection}&parentDocumentId=${parentDocumentId}&collection=${collection}&documentId=${documentId}&userId=${currentUser.id}`,
      })}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - File - Download");
      //
      window
        .fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = currentDocument.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }
  }

  function getUrlToLandingPage() {
    const randomId = getRandomId();
    const link = `${window.location.origin}/links/${randomId}`;
    return link;
  }

  function handleDelete(event) {
    if (documentType === TYPE_OF_FILE.FOLDER && navigator.onLine) {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - Folder - Delete");
      //
    } else if (documentType !== TYPE_OF_FILE.FOLDER && navigator.onLine) {
      // HERE PENDO
      // trackEvent("PO Dashboard - Documents - File - Delete");
      //
    }
    // IMPLEMENTING TO ADD SUPER ADMIN IF IT IS NOT A PART OF THIS DOCUMENT
    if (currentUser.role === userTypes.SUPER_ADMIN) {
      superAdminAssignmentPermissions({
        currentDocument,
        permissionGroupsDB,
        currentUser,
        allDocuments: documentFlat,
      });
    }
    //
    onDeleteEvent(event, currentDocument);
  }
  function handlePhotosViewer({ open, photos = [] }) {
    setOpenViewer(open);
    if (photos.length > 0) {
      setPhotosViewer(photos);
    } else {
      setPhotosViewer([{ src: "" }]);
    }
  }

  return (
    <div style={!calledByMenuOptions ? { position: "relative" } : {}}>
      <ViewerSKU
        handlePhotosViewer={handlePhotosViewer}
        photosViewer={photosViewer}
        openViewer={openViewer}
        showImage={openViewer}
      />
      <Portal>
        {showPDF && (
          <PDFPortalStyled
            className={"portalPDF"}
            onClick={(ev) => {
              setShowPDF(false);
              ev.stopPropagation();
            }}
            onKeyDown={(ev) => {
              if (ev.key === "Escape") {
                setShowPDF(false);
              }
            }}
          >
            <CancelIcon
              svgClass="cancel-icon-pdf"
              onClick={() => setShowPDF(false)}
            />
            <iframe
              title="pdf"
              src={
                documentType === "pdf"
                  ? currentDocument.url
                  : `https://docs.google.com/gview?url=${encodeURIComponent(
                      currentDocument.url
                    )}&embedded=true`
              }
              className={"expense-pdf"}
            />
          </PDFPortalStyled>
        )}
      </Portal>
      {openSnackBar && (
        <Snackbar
          className={"snackbar-copy-link"}
          open={openSnackBar}
          onClose={handleClose}
          TransitionComponent={Grow}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          autoHideDuration={1500}
          message={
            <div>
              <img src={copyLink} alt="copy link" />
              <span id="message-id">Link Copied</span>
            </div>
          }
        />
      )}
      <Menu id={currentDocument.id} className="menu-options">
        {previewAvaliable.includes(documentType) &&
          !currentDocument.placeholder && (
            <Item
              onClick={() => {
                if (typeImage.includes(documentType)) {
                  handlePhotosViewer({
                    open: true,
                    photos: [
                      {
                        src: currentDocument.url,
                        number: "",
                        description: currentDocument.name,
                      },
                    ],
                  });
                } else if (documentType === "pdf") {
                  setShowPDF(true);
                }
                contextMenu.hideAll();
              }}
              className="menu-options-items"
            >
              <RemoveRedEyeIcon style={{ marginLeft: 5, color: "#578EC7" }} />
              <div className="container-text" style={{ marginLeft: 6 }}>
                {<IntlMessages id="components.documents.menuoptions.preview" />}
              </div>
            </Item>
          )}

        {documentType === TYPE_OF_FILE.FOLDER && !isOrderVoided && (
          <Item
            onClick={() => {
              if (isReadOnly) {
                handleReadOnlyModal();
              } else if (!disabled) {
                onUploadFiles();
              }
              contextMenu.hideAll();
            }}
            className="menu-options-items"
          >
            <div className="container-img" style={{ padding: 0 }}>
              <UploadFileIcon />
            </div>
            <div className="container-text">
              <IntlMessages id="components.documents.menuoptions.uploadfile" />
            </div>
          </Item>
        )}
        {documentType === TYPE_OF_FILE.FOLDER &&
          !disabled &&
          !isOrderVoided && (
            <Item
              onClick={(click) => {
                if (isReadOnly) {
                  handleReadOnlyModal();
                } else if (!disabled) {
                  onAddNewFolder();
                }
                contextMenu.hideAll();
                click.event.stopPropagation();
              }}
              className="menu-options-items"
            >
              <div className="container-img">
                <FolderIconv2 width={16} height={16} />
              </div>
              <div className="container-text">
                {
                  <IntlMessages id="components.documents.menuoptions.newfolder" />
                }
              </div>
            </Item>
          )}
        {documentType !== TYPE_OF_FILE.FOLDER &&
          currentDocument.mainFile &&
          !currentDocument.placeholder &&
          !disabled &&
          !isOrderVoided && (
            <Item
              onClick={() => {
                if (isReadOnly) {
                  handleReadOnlyModal();
                } else {
                  iconUploadNewVersionFile();
                }
                contextMenu.hideAll();
              }}
              className="menu-options-items"
            >
              <div className="container-img">
                <NewVersionIcon svgClass="img-options-icons" />
              </div>
              <div className="container-text">
                <IntlMessages id="components.documents.menuoptions.uploadnewversion" />
              </div>
            </Item>
          )}
        {!currentDocument.placeholder && (
          <Item onClick={onCopyLink} className="menu-options-items">
            <div className="container-img">
              <CopyLinkIcon svgClass="img-options-icons" />
            </div>
            <div className="container-text">
              {<IntlMessages id="components.documents.menuoptions.copylink" />}
            </div>
          </Item>
        )}
        {!disabled &&
          !isOrderVoided &&
          !currentDocument.placeholder &&
          !currentDocument.mainFileId &&
          !(
            currentDocument.scope === documentScope.SHIPMENT &&
            currentDocument.isShipmentRootTask
          ) && (
            <Item
              onClick={(click) => {
                if (isReadOnly) {
                  handleReadOnlyModal();
                } else {
                  contextMenu.hideAll();
                  onRename();
                }
                contextMenu.hideAll();
                click.event.stopPropagation();
              }}
              className="menu-options-items"
            >
              <div className="container-img">
                <EditFileIcon width={16} height={16} />
              </div>
              <div className="container-text">
                {<IntlMessages id="components.documents.menuoptions.rename" />}
              </div>
            </Item>
          )}
        {!currentDocument.mainFileId &&
          !currentDocument.placeholder &&
          !disabled &&
          !isOrderVoided && (
            <Item
              onClick={() => {
                if (isReadOnly) {
                  handleReadOnlyModal();
                } else {
                  onPermission();
                }
                contextMenu.hideAll();
              }}
              className="menu-options-items"
            >
              <div className="container-img">
                <PermissionIcon />
              </div>
              <div className="container-text">
                {
                  <IntlMessages id="components.documents.menuoptions.permissions" />
                }
              </div>
            </Item>
          )}
        {!currentDocument.placeholder && (
          <Item
            onClick={() => {
              handleImgDownload(currentDocument.url);
              contextMenu.hideAll();
            }}
            className="menu-options-items"
          >
            <div className="container-img">
              <DownloadIcon />
            </div>
            <div className="container-text">
              {<IntlMessages id="components.documents.menuoptions.download" />}
            </div>
          </Item>
        )}
        {!currentDocument.placeholder &&
          currentDocument.arrayPath &&
          currentDocument.arrayPath.length > 1 &&
          currentDocument.scope !== documentScope.SHIPMENT &&
          !isOrderVoided && (
            <Item
              onClick={() => {
                if (isReadOnly) {
                  handleReadOnlyModal();
                  return;
                }
                if (!navigator.onLine) {
                  onOpenSnackBar("Move to top");
                  return;
                }
                const allChildDocument = documentFlat.filter(
                  (doc) => doc.parentId === currentDocument.id
                );
                if (allChildDocument.length === currentDocument.child.length) {
                  moveToRootLevelWithDependencies(
                    currentDocument,
                    currentDocument.name,
                    "",
                    "",
                    currentUser
                  );
                  updateParentFolderTouched(
                    currentDocument,
                    documentFlat,
                    currentUser
                  );
                  if (currentUser.role === userTypes.SUPER_ADMIN) {
                    superAdminAssignmentPermissions({
                      currentDocument,
                      permissionGroupsDB,
                      currentUser,
                      allDocuments: documentFlat,
                    });
                  }
                } else {
                  onShowModal();
                }
                contextMenu.hideAll();
              }}
              className="menu-options-items"
            >
              <div className="container-img">
                <MoveToRootIcon svgClass="img-options-icons" />
              </div>
              <div className="container-text">
                {
                  <IntlMessages id="components.documents.menuoptions.moveToTopLevel" />
                }
              </div>
            </Item>
          )}
        {canDeleteFileFolder({
          document: currentDocument,
          disabled,
          mainTable,
          user: currentUser,
          isAllowed: isAllowed,
        }) &&
          !isOrderVoided && (
            <Item
              onClick={() => {
                if (isReadOnly) {
                  handleReadOnlyModal();
                } else {
                  handleDelete();
                }
                contextMenu.hideAll();
              }}
              className="menu-options-items"
            >
              <div className="container-img">
                <DeleteIconv2 svgClass="img-options-icons" />
              </div>
              <div className="container-text">
                {<IntlMessages id="generic.text.delete" />}
              </div>
            </Item>
          )}

        <input
          style={{ position: "absolute", left: "-9999%" }}
          type="text"
          value={getUrlToLandingPage()}
          id="inputURLDocumentFile"
        />
      </Menu>
    </div>
  );
}

export default MenuOptions;
