import React, { useEffect, useRef, useState } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { colors } from "../../assets/jss/variables";
import TooltipTD from "../Tooltip/TooltipTD";
import { regexDecimal, regexInteger } from "../../helpers/constants";
import {
  ClickAwayListener,
  InputBase,
  Typography,
  styled,
} from "@mui/material";

const type = {
  CASH: "cash",
  NUMBER: "number",
};

const InputField = styled(InputBase)(({ theme }) => ({
  fontSize: 14,
  background: "transparent",
  border: "1px solid #ccc",
  color: colors.primaryDark,
  maxWidth: 70,
  height: 25,
  padding: "0px 0px 0px 3px",
  fontWeight: 700,
}));

const SKUEditableLabel = ({
  value,
  showEditIcon,
  format,
  disabled,
  isNewData,
  inputType = type.NUMBER,
  hiddenValue,
  tooltipLabel,
  toolTipStyle,
  showToolTip,
  isNewValue,
  onChangeLabel,
  preventDelete,
  columnId,
}) => {
  const [temporalLabel, setTemporalLabel] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const temporalValue = isEditing ? temporalLabel : value;
  const temporalFormatLabel = format
    ? format({ value: temporalValue })
    : temporalValue || "";

  useEffect(() => {
    if (isEditing && inputRef && inputRef.current) {
      inputRef.current.select();
    }
  }, [isEditing]);

  useEffect(() => {
    if (temporalLabel !== value) {
      setTemporalLabel(value);
    }
  }, [value]);

  const handleValidData = async () => {
    let newValue = parseFloat(temporalLabel);
    const checkValue = isNewValue
      ? false
      : parseFloat(newValue) === parseFloat(value);
    const isNewZero = isNewValue && newValue === 0;
    if (isNaN(newValue) || checkValue || isNewZero) {
      setTemporalLabel(value);
    } else if (newValue === 0 && preventDelete) {
      await onChangeLabel("1");
    } else {
      await onChangeLabel(temporalLabel);
      if (newValue === 0) {
        setTemporalLabel(value);
      }
    }
    setIsEditing(false);
  };

  return (
    <TooltipTD
      style={{
        ...toolTipStyle,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
      tooltipClick={(ev) => {
        if (isEditing || disabled) {
          return;
        }
        setIsEditing(true);
        ev.stopPropagation();
      }}
      label={tooltipLabel}
      showToolTip={showToolTip && !isEditing}
    >
      {!isEditing && (
        <div
          style={{ width: "100%", display: "flex", alignItems: "center" }}
          id={columnId}
        >
          <Typography
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100%",
              fontWeight: 700,
              fontSize: 14,
              color: isNewData ? "#BBC7D4" : "",
            }}
          >
            {`${hiddenValue ? "" : temporalFormatLabel}`}
          </Typography>
          {showEditIcon && (
            <EditIcon
              className={"edit-icon"}
              style={{
                marginLeft: 4,
                marginTop: -3,
                fontSize: 14,
                color: `${colors.primaryDark}`,
              }}
            />
          )}
        </div>
      )}
      {isEditing && (
        <ClickAwayListener
          onClickAway={() => {
            handleValidData();
          }}
        >
          <InputField
            autoFocus
            style={{ width: "85%" }}
            value={temporalValue}
            inputRef={inputRef}
            inputProps={{
              pattern: "[0-9]*",
            }}
            onChange={(e) => {
              const currentValue = e.target.value;
              const numStr = currentValue.replace(/[^0-9.,]/g, "");
              if (
                inputType === type.NUMBER &&
                (numStr === "" || regexInteger.test(numStr))
              ) {
                setTemporalLabel(numStr);
              } else if (
                inputType === type.CASH &&
                (numStr === "" || regexDecimal.test(numStr))
              ) {
                setTemporalLabel(numStr);
              }
            }}
            onKeyDown={(ev) => {
              if (ev.key === "Escape") {
                setIsEditing(false);
                setTemporalLabel(value);
              }
              if (ev.key === "Enter") {
                handleValidData();
                setIsEditing(false);
              }
            }}
          />
        </ClickAwayListener>
      )}
    </TooltipTD>
  );
};

export default SKUEditableLabel;
