import React from "react";
import { taskStatus } from "./constants";
import { getRandomId, sortObjectsBy } from "./helpers";
import lateIcon from "../assets/flag-icons/red_diamond.svg";
import nearDueIcon from "../assets/flag-icons/orange_diamond.svg";
import taskStages from "../api/types/taskStages";
import moment from "moment";

export function getTasksByPhases(tasks) {
  if (!tasks) {
    return {
      proposalTasks: [],
      preProductionTasks: [],
      productionTasks: [],
      bookingTransitTasks: [],
      paymentBillingTasks: [],
    };
  }
  const proposalTasks = tasks.filter((task) => task.stage === "PROPOSAL");
  const preProductionTasks = tasks.filter(
    (task) => task.stage === "PRE_PRODUCTION"
  );
  const productionTasks = tasks.filter((task) => task.stage === "PRODUCTION");
  const bookingTransitTasks = tasks.filter(
    (task) => task.stage === "BOOKING_TRANSIT"
  );
  const paymentBillingTasks = tasks.filter(
    (task) => task.stage === "PAYMENT_BILLING"
  );
  return {
    proposalTasks,
    preProductionTasks,
    productionTasks,
    bookingTransitTasks,
    paymentBillingTasks,
  };
}

function getStartDate(tasks) {
  if (tasks.length === 0) {
    return null;
  }
  const task = tasks.sort(sortObjectsBy("startDate", false))[0];
  return task.startDate;
}

function getFinishDate(tasks) {
  if (tasks.length === 0) {
    return null;
  }
  const task = tasks.sort(sortObjectsBy("finishDate", true))[0];
  return task.finishDate;
}

function reorderPhaseTasks({ tasks, phaseDescription, offset, phase }) {
  tasks.unshift({
    id: getRandomId(),
    listIndex: 0,
    description: phaseDescription,
    isPhase: true,
    startDate: getStartDate(tasks),
    finishDate: getFinishDate(tasks),
    phase,
  });
  tasks = tasks
    .sort(sortObjectsBy("sortedListIndex", false))
    .map((task, index) => {
      return { ...task, numberIndex: index + offset };
    });
  return tasks;
}

export function getTasksAjustedByPhase({ tasks }) {
  let tasksCpy = [...tasks];
  tasksCpy = tasksCpy.map((task) => {
    if (task.isPhase) {
      const filteredTasksPhase = tasksCpy.filter(
        (taskCpy) => taskCpy.stage === task.phase
      );
      return {
        ...task,
        startDate: getStartDate(filteredTasksPhase),
        finishDate: getFinishDate(filteredTasksPhase),
      };
    }
    return task;
  });
  return tasksCpy;
}

const labelTaskPhases = {
  PROPOSAL_QUOTE: "Proposal/Quote",
  PRE_PRODUCTION: "Pre-Production",
  PRODUCTION: "Production",
  BOOKING_TRANSIT: "Booking & Transit",
  PAYMENT_BILLING: "Payment & Billing",
};

export function builderTasksInPhases({ tasks = [] }) {
  let {
    proposalTasks,
    preProductionTasks,
    productionTasks,
    bookingTransitTasks,
    paymentBillingTasks,
  } = getTasksByPhases(tasks);
  let offset = 0;
  proposalTasks = reorderPhaseTasks({
    tasks: proposalTasks,
    phaseDescription: labelTaskPhases.PROPOSAL_QUOTE,
    offset,
    phase: taskStages.PROPOSAL,
  });
  offset += proposalTasks.length;
  preProductionTasks = reorderPhaseTasks({
    tasks: preProductionTasks,
    phaseDescription: labelTaskPhases.PRE_PRODUCTION,
    offset: offset,
    phase: taskStages.PRE_PRODUCTION,
  });
  offset += preProductionTasks.length;
  productionTasks = reorderPhaseTasks({
    tasks: productionTasks,
    phaseDescription: labelTaskPhases.PRODUCTION,
    offset: offset,
    phase: taskStages.PRODUCTION,
  });
  offset += productionTasks.length;
  bookingTransitTasks = reorderPhaseTasks({
    tasks: bookingTransitTasks,
    phaseDescription: labelTaskPhases.BOOKING_TRANSIT,
    offset: offset,
    phase: taskStages.BOOKING_TRANSIT,
  });
  offset += bookingTransitTasks.length;
  paymentBillingTasks = reorderPhaseTasks({
    tasks: paymentBillingTasks,
    phaseDescription: labelTaskPhases.PAYMENT_BILLING,
    offset: offset,
    phase: taskStages.PAYMENT_BILLING,
  });
  return {
    sortedTasksByNumberAndPhases: [
      ...proposalTasks,
      ...preProductionTasks,
      ...productionTasks,
      ...bookingTransitTasks,
      ...paymentBillingTasks,
    ],
  };
}

export function getTaskPhaseDiamond({ tasks, imgStyles }) {
  const isLate = tasks.some((task) => task.status === taskStatus.LATE);
  const isNearDue = tasks.some((task) => task.status === taskStatus.NEAR_DUE);
  if (isLate) {
    return <img src={lateIcon} alt="late" style={imgStyles} />;
  } else if (isNearDue) {
    return <img src={nearDueIcon} alt="nearDue" style={imgStyles} />;
  }
  return null;
}

function getAvailableTasksToMove({ tasks }) {
  let tasksCpy = [...tasks];
  tasksCpy = tasksCpy.filter(
    (task) => task.status !== taskStatus.COMPLETE && !task.isAdHocTask
  );
  return tasksCpy;
}

function getDependencyTasks({
  task,
  tasks,
  dependencyTasks,
  remainingDayOffset,
  type = dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
}) {
  let dependencies = [];
  if (type === dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED) {
    dependencies = tasks.filter((taskCpy) => taskCpy.dependency === task.id);
  } else if (type === dependencyTypesOnMove.START_DATE_CHANGED) {
    dependencies = tasks.filter(
      (taskCpy) =>
        taskCpy.dependency === task.id &&
        taskCpy.dependencyType === dependencyTypes.START_START
    );
  } else if (type === dependencyTypesOnMove.FINISH_DATE_CHANGED) {
    dependencies = tasks.filter(
      (taskCpy) =>
        taskCpy.dependency === task.id &&
        (taskCpy.dependencyType === dependencyTypes.FINISH_START ||
          taskCpy.dependencyType === dependencyTypes.FINISH_FINISH)
    );
  }

  dependencies.forEach((dependencyTask) => {
    dependencyTasks.push({
      ...dependencyTask,
      startDate: moment(dependencyTask.startDate)
        .add(remainingDayOffset, "days")
        .startOf("day")
        .valueOf(),
      finishDate: moment(dependencyTask.finishDate)
        .add(remainingDayOffset, "days")
        .endOf("day")
        .valueOf(),
    });
    getDependencyTasks({
      task: dependencyTask,
      tasks,
      dependencyTasks,
      remainingDayOffset,
      type: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
    });
  });
  return dependencyTasks;
}

const dependencyTypesOnMove = {
  START_DATE_CHANGED: "START_DATE_CHANGED",
  FINISH_DATE_CHANGED: "FINISH_DATE_CHANGED",
  START_DATE_AND_FINISH_DATE_CHANGED: "START_DATE_AND_FINISH_DATE_CHANGED",
};

const actionType = {
  MOVED_TASK: "movedTask",
  MOVED_REMAINING_TASK: "movedRemainingTask",
  CHANGED_DURATION_TASK: "changedDurationTask",
  CONFIRMED_REMAINING_DAY_OFFSET_MOVED: "confirmedRemainingDayOffsetMoved",
  DENIED_REMAINING_DAY_OFFSET_MOVED: "deniedRemainingDayOffsetMoved",
  COMPLETED_TASK: "completedTask",
  CHANGED_ASSIGNED_TO: "changedAssignedTo",
};

const dependencyTypes = {
  START_START: "SS",
  FINISH_START: "FS",
  FINISH_FINISH: "FF",
};

export {
  labelTaskPhases,
  getAvailableTasksToMove,
  getDependencyTasks,
  actionType,
  dependencyTypesOnMove,
  dependencyTypes,
};
