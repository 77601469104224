import React, { useState, useEffect } from "react";
import moment from "moment";
import { useUser } from "../../hooks/user";

import AttentionModal from "../Modal/AttentionModal";
import { getStatusTask } from "../../helpers/tasks";
import { useSelector } from "react-redux";
// import { trackEvent } from "../../helpers/analytics";
import TaskItem from "./TaskItem";
import TaskOptions from "./TaskOptions";
import { getActivityEntryFromMovedTask } from "../../helpers/orderDashboard";
import {
  dependencyTypesOnMove,
  getAvailableTasksToMove,
  getDependencyTasks,
} from "../../helpers/timelineModal";
import Loader from "../General/Loader";
import { salesOrderTriggerTypes } from "../../helpers/salesOrder";
import { typeOfTask } from "../../api/types/dbTables";
import {
  PO_DIAMOND_STATUS,
  PO_STATUS,
  SALES_ORDER_DIAMOND_STATUS,
  SHIPMENT_DIAMOND_STATUS,
  taskStatus,
} from "../../helpers/constants";
import { useShipments } from "../../hooks/shipments";
import { updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";

function Task({
  item,
  user,
  completeTask = () => {},
  onClickOffset = () => {},
  companyId,
  salesOrder,
  purchaseOrder,
  hasPermissionToModifiedDueDate,
  companyUsers,
  allTaskList,
  permissionToVerify,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleUpdateTotalTask = () => {},
  currentShipment,
  hasPermissionToReassign,
  onChangeShipment,
  onCleanPendingTask = () => {},
  pendingToCompleted = {},
  showCompleted,
  taskFilter,
  setPendingToComplete,
  handleIsEditingTask,
  backdropId,
}) {
  const authUser = useUser();
  const [userEditId, setUserEditId] = useState(item.enableToEdit);
  const [isPermissionModal, setIsPermissionModal] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [timer, setTimer] = useState("");
  const [lastFinishDate, setLastFinishDate] = useState("");
  const [modalDescriptionNew, setmodalDescriptionNew] = useState(false);
  const [pressedOneTime, setPressedOneTime] = useState(false);

  const [countingSeconds, setCountingSeconds] = useState(
    parseInt(
      (moment(item.creationDate).add(7, "seconds").valueOf() -
        moment().valueOf()) /
        1000
    )
  );
  const [classesTask, setClassesTask] = useState("project-task");
  const [dayOffsetRemainigTasks, setDayOffsetRemainigTasks] = useState(1);
  const [dayOffsetTask, setDayOffsetTask] = useState(0);
  const [newClassToDessapear, setNewClassToDissapear] = useState(
    "option-to-edit-container"
  );
  const [openPicker, setOpenPicker] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [editingAdHocTask, setEditingAdHocTask] = useState(false);
  const [currentTask, setCurrentTask] = useState(item);
  const [lastElementId, setLastElementId] = useState("");
  const [loader, setLoader] = useState(false);
  const currentUser = useSelector((state) => state.authUser.user);
  const shipments = useShipments();

  useEffect(() => {
    setCurrentTask({ ...item });
  }, [item]);

  useEffect(() => {
    if (userEditId) {
      if (!editingAdHocTask && !timer) {
        setTimer(
          setInterval(() => {
            setCountingSeconds((t) => (t === 6 ? 6 - 1 : t - 1));
          }, 1000)
        );
      } else if (editingAdHocTask && timer) {
        clearInterval(timer);
        setCountingSeconds(6);
        setTimer(false);
      }
    }
  }, [editingAdHocTask]);

  useEffect(() => {
    if (userEditId && !editingAdHocTask && timer && countingSeconds <= -1) {
      setNewClassToDissapear("option-to-edit-container dissapear");
      clearInterval(timer);
      setTimeout(() => {
        setUserEditId(false);
        if (item.type === typeOfTask.SALES_ORDER) {
          if (
            salesOrder.status !== SALES_ORDER_DIAMOND_STATUS.LATE &&
            item.status === taskStatus.LATE
          ) {
            updateDoc(salesOrder.ref, {
              diamondStatus: SALES_ORDER_DIAMOND_STATUS.LATE,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });
          } else if (
            !salesOrder.status &&
            item.status === taskStatus.NEAR_DUE
          ) {
            updateDoc(salesOrder.ref, {
              diamondStatus: SALES_ORDER_DIAMOND_STATUS.NEAR_DUE,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });
          }
        } else if (item.type === typeOfTask.PURCHASE_ORDER) {
          if (
            purchaseOrder.status !== PO_DIAMOND_STATUS.LATE &&
            item.status === taskStatus.LATE
          ) {
            updateDoc(purchaseOrder.ref, {
              diamondStatus: PO_DIAMOND_STATUS.LATE,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });
            if (salesOrder.status !== SALES_ORDER_DIAMOND_STATUS.LATE) {
              updateDoc(salesOrder.ref, {
                diamondStatus: SALES_ORDER_DIAMOND_STATUS.LATE,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            }
          } else if (
            !purchaseOrder.status &&
            item.status === taskStatus.NEAR_DUE
          ) {
            updateDoc(purchaseOrder.ref, {
              diamondStatus: PO_DIAMOND_STATUS.NEAR_DUE,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });
            if (!salesOrder.status) {
              updateDoc(salesOrder.ref, {
                diamondStatus: SALES_ORDER_DIAMOND_STATUS.NEAR_DUE,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            }
          }
        } else if (item.type === typeOfTask.SHIPMENT) {
          const shipment = shipments.find(
            (shipment) => shipment.id === item.shipmentId
          );
          if (shipment) {
            if (
              shipment.status !== SHIPMENT_DIAMOND_STATUS.LATE &&
              item.status === taskStatus.LATE
            ) {
              updateDoc(shipment.ref, {
                diamondStatus: SHIPMENT_DIAMOND_STATUS.LATE,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            } else if (
              !shipment.status &&
              item.status === taskStatus.NEAR_DUE
            ) {
              updateDoc(shipment.ref, {
                diamondStatus: SHIPMENT_DIAMOND_STATUS.NEAR_DUE,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            }
          }
        }

        setClassesTask("project-task");
        updateDoc(item.ref, { enableToEdit: false });
        clearInterval(timer);
      }, 2000);
    }
  }, [countingSeconds]);

  useEffect(() => {
    item.complete
      ? setClassesTask("project-task complete")
      : userEditId && editingAdHocTask
      ? setClassesTask("project-task to-edit")
      : setClassesTask("project-task");
  }, [editingAdHocTask]);

  useEffect(() => {
    const isCompleted = item.complete && item.status === "complete";
    if (
      (classesTask.includes("pendingSize") ||
        classesTask.includes("pendingOpacity")) &&
      isCompleted
    ) {
      if (showCompleted) {
        setClassesTask("project-task completeOpacity");
      } else {
        console.log(item.moved, item);
        if (item.moved === true) {
          setClassesTask("project-task completeSize");
          setTimeout(() => {
            if (pendingToCompleted[item.id]) {
              onCleanPendingTask(item);
            }
          }, 2000);
        } else {
          setClassesTask("project-task pendingOpacity");
        }
      }
    }
  }, [item.complete, item.status, item.moved]);

  useEffect(() => {
    if (
      classesTask.includes("completeSize") ||
      classesTask.includes("pendingOpacity")
    ) {
      setClassesTask("project-task complete");
    }
  }, [showCompleted, taskFilter]);

  function changeStyleTo(value) {
    setClassesTask(value);
  }

  function changeField({ taskToUpdate, field, triggerType }) {
    console.log("CHANGE FIELD", field, taskToUpdate);
    if (field === "assignedTo") {
      // trackEvent("PO Dashboard - Tasks - Reassigned", {
      //   from: item.assignedTo,
      //   to: value
      // });
    }
    if (field === "finishDate") {
      if (
        moment(item.finishDate).startOf("day").valueOf() ===
        moment(taskToUpdate.finishDate).startOf("day").valueOf()
      ) {
        setOpenPicker(false);
        return;
      }
      updateDoc(item.ref, {
        ...taskToUpdate,
        updated_by: authUser.id,
        movedDate: false,
        status: getStatusTask(taskToUpdate),
        triggerType: triggerType,
      });
      const currentDayOffset = moment(taskToUpdate.finishDate)
        .startOf("day")
        .diff(moment(lastFinishDate).startOf("day"), "days");
      setDayOffsetRemainigTasks(Math.abs(currentDayOffset));
      setDayOffsetTask(currentDayOffset);
      if (taskToUpdate.type === typeOfTask.SALES_ORDER) {
        setLoader(true);
        try {
          const functions = getFunctions();
          const callable = httpsCallableFromURL(
            functions,
            getFunctionByName({
              name: `moveDependencyTasks`,
              env: globalEnvironment,
              params: `/verifyDependencies/?companyId=${companyId}&&salesOrderId=${salesOrder.id}&&taskId=${taskToUpdate.id}&&type=${dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED}`,
            })
          );
          callable().then((result) => {
            const { data } = result;
            if (data.length > 0) {
              setOpenModalAttention(true);
              setPressedOneTime(false);
            } else {
              onMovingTasks({
                accepted: false,
                dayOffsetTask: currentDayOffset,
                dayOffsetRemainigTasks: Math.abs(currentDayOffset),
                item: taskToUpdate,
              });
            }
            setLoader(false);
          });
          // trackEvent("PO Dashboard - Tasks - Updated Due Date", {
          //   description: item.description,
          //   dayOffset: dayOffsetRemainigTasks
          // });
        } catch (error) {}
      } else {
        const availableTasksToMove = getAvailableTasksToMove({
          tasks: allTaskList,
        });
        let dependencyTasks = getDependencyTasks({
          task: taskToUpdate,
          tasks: availableTasksToMove,
          dependencyTasks: [],
          remainingDayOffset: 1,
        });
        if (dependencyTasks.length !== 0) {
          setOpenModalAttention(true);
          setPressedOneTime(false);
        } else {
          onMovingTasks({
            accepted: false,
            dayOffsetTask: currentDayOffset,
            dayOffsetRemainigTasks: Math.abs(currentDayOffset),
            item: taskToUpdate,
          });
        }
      }
    } else {
      updateDoc(item.ref, {
        ...taskToUpdate,
        updated_by: authUser.id,
        triggerType: triggerType,
      });
    }
    setOpenPicker(false);
    setOpenUsers(false);
  }

  async function onMovingTasks({
    item,
    accepted,
    dayOffsetTask,
    dayOffsetRemainigTasks,
  }) {
    if (isPermissionModal) return setOpenModalAttention(false);
    setOpenModalAttention(false);
    if (accepted) {
      const shipment = shipments.find(
        (shipment) => shipment.id === item.shipmentId
      ) || { id: "" };
      const functions = getFunctions();
      const callable = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: `moveDependencyTasks`,
          env: globalEnvironment,
        })
      );
      callable({
        companyId: companyId,
        salesOrderId: salesOrder.id,
        purchaseOrderId: purchaseOrder.id,
        shipmentId: shipment.id,
        taskId: item.id,
        dayOffset: dayOffsetTask,
        remainingDayOffset:
          dayOffsetTask >= 0 ? dayOffsetRemainigTasks : -dayOffsetRemainigTasks,
        user: authUser.id,
        type: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
        taskType: item.type,
      });
      // trackEvent("PO Dashboard - Tasks - Updated Due Date", {
      //   description: item.description,
      //   dayOffset: dayOffsetRemainigTasks
      // });
    }
    setLastFinishDate("");
    const salesOrderCpy = {
      ...salesOrder,
      unreadActivityUsers: salesOrder.unreadActivityUsers
        ? [...salesOrder.unreadActivityUsers]
        : [],
    };
    const purchaseOrderCpy = {
      ...purchaseOrder,
      unreadActivityUsers: purchaseOrder.unreadActivityUsers
        ? [...purchaseOrder.unreadActivityUsers]
        : [],
    };

    let shipmentCpy = {};
    if (currentShipment && currentShipment.ref) {
      if (currentShipment.unreadActivityUsers) {
        shipmentCpy = {
          ...currentShipment,
          unreadActivityUsers: [...currentShipment.unreadActivityUsers],
        };
      } else {
        shipmentCpy = { ...currentShipment, unreadActivityUsers: [] };
      }
    }

    const cpyOrderType = {
      [typeOfTask.SALES_ORDER]: salesOrderCpy,
      [typeOfTask.PURCHASE_ORDER]: purchaseOrderCpy,
      [typeOfTask.SHIPMENT]: shipmentCpy,
    };
    const cpyOrder = cpyOrderType[item.type] || {};
    console.log("cpyOrder", cpyOrder);
    getActivityEntryFromMovedTask({
      task: item,
      diffDaysRemainingTasks: dayOffsetRemainigTasks,
      diffDaysTask: dayOffsetTask,
      isDependantTaskMoved: accepted,
      isLate: dayOffsetTask > 0,
      typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
      user: authUser,
      scope: cpyOrder.type || "",
      companyUsers,
      companyId: companyId,
      order: cpyOrder,
    });
  }

  const modalDescription = isPermissionModal ? (
    "You don't have permission to change a task's due date"
  ) : (
    <span className="task-sheet-modal-input">
      Move all dependant tasks{" "}
      <input
        style={{ width: 54 }}
        value={dayOffsetRemainigTasks}
        type="number"
        onChange={(ev) => {
          const value = ev.target.value;
          if (value > 0) {
            setDayOffsetRemainigTasks(
              value > 364 ? dayOffsetRemainigTasks : value
            );
          } else {
            setDayOffsetRemainigTasks(1);
          }
        }}
      />
      {dayOffsetRemainigTasks > 1 ? "days" : "day"}
      <span
        style={{
          color:
            moment(item.finishDate)
              .startOf("day")
              .diff(moment(lastFinishDate).startOf("day"), "days") < 0
              ? "green"
              : "red",
        }}
      >
        {moment(item.finishDate)
          .startOf("day")
          .diff(moment(lastFinishDate).startOf("day"), "days") > 0
          ? " later?"
          : " earlier?"}
      </span>
    </span>
  );

  function showModalNoPermissionToReassign() {
    setmodalDescriptionNew("You do not have permission to reassing a task");
    setOpenModalAttention(true);
    setIsPermissionModal(true);
    setPressedOneTime(false);
  }

  const isVoided = () => {
    const orderByType = {
      [typeOfTask.SALES_ORDER]: salesOrder,
      [typeOfTask.PURCHASE_ORDER]: purchaseOrder,
      [typeOfTask.SHIPMENT]: currentShipment,
    };
    const order = orderByType[item.type] || {};
    return order.status === PO_STATUS.VOIDED;
  };

  const isVoid = isVoided();
  const isReassigned = purchaseOrder.status === PO_STATUS.REASSIGNED;

  function handleChange(field, value, triggerType) {
    setCurrentTask({
      ...currentTask,
      [field]: value,
      triggerType: triggerType,
    });
    setOpenPicker(false);
    setOpenUsers(false);
  }

  return (
    <React.Fragment>
      {loader && (
        <Loader
          style={{
            justifyContent: "center",
            display: "flex",
            zIndex: 1000,
            alignItems: "center",
            top: 0,
            left: 0,
          }}
        />
      )}
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={modalDescriptionNew || modalDescription}
          onClick={() => {
            setPressedOneTime(true);
            if (!pressedOneTime) {
              onMovingTasks({
                accepted: true,
                dayOffsetTask,
                dayOffsetRemainigTasks,
                item,
              });
            }
          }}
          onClose={() => {
            setPressedOneTime(true);
            if (!pressedOneTime) {
              setIsPermissionModal(false);
              setmodalDescriptionNew(false);
              onMovingTasks({
                accepted: false,
                dayOffsetTask,
                dayOffsetRemainigTasks,
                item,
              });
            }
          }}
          isOpen={openModalAttention}
          cancellable={!isPermissionModal}
          confirmationText="Ok"
        />
      )}
      <TaskItem
        item={currentTask}
        classesTask={classesTask}
        isVoid={isVoid}
        backdropId={backdropId}
        isReassigned={isReassigned}
        hasPermissionToModifiedDueDate={hasPermissionToModifiedDueDate}
        changeField={changeField}
        companyUsers={companyUsers}
        hasPermissionToReassign={hasPermissionToReassign}
        user={user}
        changeStyleTo={changeStyleTo}
        completeTask={completeTask}
        onChangeShipment={onChangeShipment}
        onCleanPendingTask={onCleanPendingTask}
        onLastFinishDate={(value, enabled) => {
          setLastFinishDate(value);
          if (enabled) {
            setEditingAdHocTask(true);
          }
        }}
        onOpenPicker={(value) => {
          if (isReadOnly) {
            handleReadOnlyModal();
          } else {
            setOpenPicker(value);
          }
        }}
        openPicker={openPicker}
        openUsers={openUsers}
        onOpenUsers={(value) => {
          if (isReadOnly) {
            handleReadOnlyModal();
          } else {
            setOpenUsers(value);
          }
        }}
        showModalNoPermissionToReassign={showModalNoPermissionToReassign}
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
        onClickOffset={onClickOffset}
        editingAdHocTask={editingAdHocTask}
        handleChange={handleChange}
        lastElementId={lastElementId}
        onLastElementId={(value) => setLastElementId(value)}
        permissionToVerify={permissionToVerify}
      />
      {userEditId === currentUser.id && (
        <TaskOptions
          item={currentTask}
          newClassToDessapear={newClassToDessapear}
          countingSeconds={countingSeconds}
          currentUser={currentUser}
          handleUpdateTotalTask={handleUpdateTotalTask}
          onEditTask={(value) => {
            clearInterval(timer);
            setEditingAdHocTask(value);
            handleIsEditingTask(value);
          }}
          editingAdHocTask={editingAdHocTask}
          recoverLastTask={() => setCurrentTask(item)}
          companyUsers={companyUsers}
          permissionToVerify={permissionToVerify}
          userEditId={userEditId}
          handleIsEditingTask={handleIsEditingTask}
        />
      )}
    </React.Fragment>
  );
}

export default Task;
