import React, { useEffect, useState } from "react";
import { colors } from "../../assets/jss/variables";
import { Popover, styled } from "@mui/material";

const CustomToolTipPopoverStyled = styled(Popover)(() => ({
  "&.customTooltipPopover": {
    "& .customTooltipPaper": {
      padding: "4px 16px",
      overflow: "auto",
      zIndex: 9999,
      background: "#F7F9FC",
      boxShadow: "0px 10px 50px 3px rgba(1,31,64,0.65)",
      borderRadius: 10,
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        },
      },
      "&::-webkit-scrollbar": {
        backgroundColor: "transparent",
        width: 17,
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: "transparent",
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
    "& .customTooltipLabel": {
      pointerEvents: "auto",
      maxHeight: "calc(100vh - 350px)",
    },
  },
}));

export const CustomTooltip = ({ label, children, style }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(null);

  useEffect(() => {
    const timer =
      isHovered &&
      setTimeout(() => {
        setAnchorEl(isHovered);
      }, 800);
    return () => {
      clearTimeout(timer);
    };
  }, [isHovered]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span
        aria-owns={!!anchorEl ? "custom-tooltip" : undefined}
        aria-haspopup="true"
        onMouseOverCapture={(ev) => setIsHovered(ev.currentTarget)}
        onMouseLeave={() => setIsHovered(false)}
        className="labelText"
        style={style}
      >
        {children}
      </span>
      <CustomToolTipPopoverStyled
        id="custom-tooltip"
        className={"customTooltipPopover"}
        classes={{
          paper: "customTooltipPaper",
        }}
        style={{ marginLeft: 12 }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        PaperProps={{
          onMouseLeave: handlePopoverClose,
        }}
        onClose={handlePopoverClose}
        onClick={(ev) => ev.stopPropagation()}
      >
        <div className={"customTooltipLabel"}>{label}</div>
      </CustomToolTipPopoverStyled>
    </div>
  );
};
