import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { dbTables } from "../../api/types/dbTables";
import { firestore } from "../../firebase";
import { isTradeDashEmployee } from "../../helpers/helpers";
import { purchaseOrderTriggerTypes } from "../../helpers/purchaseOrder";
import { useSummaryFactories } from "../../hooks/factories";
import FactoryTemplateModal from "../Factories/FactoryTemplateModal";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { POVendorTemplateButtonsStyled } from "./styles";
import { Button } from "@mui/material";

function VendorDashboardTemplate({
  vendor = {},
  purchaseOrder = {},
  user,
  companyId,
  taskInfo = false,
  typeClass = "containerPO",
}) {
  const summaryFactories = useSummaryFactories();
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentVendor, setCurrentVendor] = useState();
  const canCreateTask = !vendor.isOutdated;
  const margin = taskInfo ? 18 : 60;

  useEffect(() => {
    if (purchaseOrder.id && vendor.id && openTemplateModal) {
      const getUpdateVendor = async () => {
        const currentVendorSnap = await getDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.FACTORIES}/${vendor.id}`
          )
        );
        setCurrentVendor({ ...currentVendorSnap.data() });
      };
      getUpdateVendor();
    }
  }, [vendor.id, openTemplateModal]);

  useEffect(() => {
    if (!purchaseOrder.isOutdated) {
      setLoading(false);
    }
  }, [purchaseOrder]);

  if (!purchaseOrder.isOutdated) {
    return null;
  }
  const blockStyle = canCreateTask
    ? { marginLeft: 0 }
    : { background: "#E1E1E1", opacity: "unset" };
  return (
    <POVendorTemplateButtonsStyled className={typeClass}>
      {!canCreateTask && (
        <Button
          className="buttonPOVendor"
          onClick={() => setOpenTemplateModal(true)}
        >
          Open vendor template
        </Button>
      )}
      <Button
        className="buttonPOVendor"
        style={{ marginLeft: margin, ...blockStyle }}
        disabled={!canCreateTask || loading}
        onClick={() => {
          if (!loading) {
            updateDoc(purchaseOrder.ref, {
              triggerType: purchaseOrderTriggerTypes.BUILDING_TASKS,
            });
          }
          setLoading(true);
        }}
      >
        Create tasks
        {loading && <LoadingBackdrop size={24} />}
      </Button>
      {vendor.id && openTemplateModal && (
        <FactoryTemplateModal
          factory={{ ...currentVendor, ...vendor }}
          companyId={companyId}
          onClose={() => setOpenTemplateModal(false)}
          open={openTemplateModal}
          isReadOnly={isTradeDashEmployee(user)}
          summaryFactories={summaryFactories}
        />
      )}
    </POVendorTemplateButtonsStyled>
  );
}

export default VendorDashboardTemplate;
