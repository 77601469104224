import React from "react";
import { HiddenDataIconStyled } from "./styles";
import { cx } from "@emotion/css";

function HiddenDataIconAnimated({
  styles,
  color,
  onClick = () => {},
  size,
  className,
}) {
  return (
    <HiddenDataIconStyled
      color={color}
      style={styles}
      onClick={onClick}
      svgClass={cx("expandRow", className)}
      size={size}
    />
  );
}

export default HiddenDataIconAnimated;
