import React from "react";
import EditableLabel from "../TextFields/EditableLabel";
import CustomButton from "../Buttons/CustomButton";
import folderPlaceholder from "../../assets/flag-icons/folder-placeholder.svg";
import filePlaceholder from "../../assets/flag-icons/file-placeholder.png";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { ListItem, ListItemIcon } from "@mui/material";
import { CreateNewFolderContainerStyled } from "./styles";

function CreateNewFolder({
  folderName,
  handleSaveFolder,
  handleCancelSaveFolder,
  onChange,
  typeDocument,
}) {
  return (
    <CreateNewFolderContainerStyled className="createNewFolderContainer">
      <ListItem className={"listItemFolder"}>
        <ListItemIcon className={"listItemIcon"}>
          {typeDocument === TYPE_OF_FILE.FOLDER ? (
            <img
              src={folderPlaceholder}
              className={"imgListItemIcon"}
              alt="folder"
            />
          ) : (
            <img
              src={filePlaceholder}
              className={"imgListItemIcon"}
              alt="file"
            />
          )}
        </ListItemIcon>
        <EditableLabel
          editable={true}
          onChange={onChange}
          autofocus={true}
          onFocus={(event) => event.target.select()}
          editing={true}
          style={{ height: "auto", maxWidth: "185px", minWidth: "185px" }}
          defaultChildren={
            typeDocument === TYPE_OF_FILE.FOLDER ? "New Folder" : "New File"
          }
        >
          {folderName}
        </EditableLabel>
        <div className={"buttonOptions"}>
          <CustomButton
            onClick={() => handleSaveFolder(typeDocument)}
            type="success"
          >
            {"documents.createnewfolder.save"}
          </CustomButton>
          <CustomButton onClick={handleCancelSaveFolder} type="danger">
            {"documents.createnewfolder.cancel"}
          </CustomButton>
        </div>
      </ListItem>
    </CreateNewFolderContainerStyled>
  );
}

export default CreateNewFolder;
