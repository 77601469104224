import React, { useState } from "react";
import { dbTables } from "../../../api/types/dbTables";
import { documentScope, getselectedUsersObj } from "../../../helpers/documents";
import { ProjectDocumentNoteStyled } from "./styles";
import {
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  PO_STATUS,
  typeModalPermissions,
} from "../../../helpers/constants";
import NoteDocumentsTab from "./NoteDocumentsTab";
import ManagementDocumentsNote from "./ManagementDocumentsNote";
import ModalPermission from "../../Documents/ModalPermission";
import {
  getFactoryName,
  handleUpdateNotePermissionDependencies,
} from "../../../helpers/helpers";
import NavPicker from "../../DatePicker/NavPicker";
import { useFactories } from "../../../hooks/factories";
import { getPOShipments, getShipmentBadge } from "../../../helpers/shipments";
import {
  ArrowDownIcon,
  PurchaseOrderIcon,
  ShipmentOrderIcon,
} from "../../../helpers/iconSvgPath";
import { cx } from "@emotion/css";
import { shipmentBadgeClass } from "../../../container/StyledComponent/BadgeStyled";

function DocumentsNote({
  companyId,
  currentSalesOrder = {},
  user,
  permissionGroupsDB,
  salesOrderDocuments,
  purchasesOrderDocuments = [],
  shipmentDocuments = [],
  documentData = {},
  handleRichDocument = () => {},
  closeIcon,
  companyUsers,
  setDocumentPermission,
  documentPermission,
  handlePOId,
  handleShipmentId,
  shipments = [],
  purchaseOrders = [],
  poDocumentId = "",
  shipmentDocumentId = "",
  setDocumentDB,
  documentsDB,
}) {
  const [selectedDocumentTab, setSelectedDocumentTab] = useState(0);
  const [scope, setScope] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const factories = useFactories();

  const changeDocumentTab = (tab) => {
    setSelectedDocumentTab(tab);
  };
  const currentPurchaseOrder = purchaseOrders.find(
    (po) => po.id === poDocumentId
  );
  const currentShipment =
    shipments.find((shipment) => shipment.id === shipmentDocumentId) || {};

  async function handleUpdatePermissions(newPermision) {
    const scope = documentPermission.scope;
    const { permissionUsers, permissionGroups } = newPermision;
    const documentsUpdated = await handleUpdateNotePermissionDependencies({
      documentData: documentPermission,
      permissionUsers,
      permissionGroups,
      rootDocument: documentPermission.id,
      permissionGroupsDB,
      currentUser: user,
      users: companyUsers,
    });
    if (
      scope === documentScope.PURCHASE_ORDER ||
      scope === documentScope.SHIPMENT
    ) {
      const idScope =
        scope === documentScope.PURCHASE_ORDER
          ? poDocumentId
          : shipmentDocumentId;

      const newDocumentsDB = documentsDB[idScope].map((doc) => {
        const newDocument = documentsUpdated.find(
          (newDoc) => newDoc.id === doc.id
        );
        if (!newDocument) {
          return doc;
        } else {
          return {
            ...doc,
            permissionGroups: newDocument.permissionGroups,
            permissionUsers: newDocument.permissionUsers,
          };
        }
      });
      setDocumentDB({
        ...documentsDB,
        [idScope]: newDocumentsDB,
      });
    }

    setDocumentPermission({});
  }

  const getDocumentByTab = () => {
    if (selectedDocumentTab === 0) {
      return salesOrderDocuments.map((document) => ({
        ...document,
        scope: documentScope.SALES_ORDER,
      }));
    } else {
      return [
        ...purchasesOrderDocuments.map((document) => ({
          ...document,
          scope: documentScope.PURCHASE_ORDER,
        })),
        ...shipmentDocuments.map((document) => ({
          ...document,
          scope: documentScope.SHIPMENT,
        })),
      ];
    }
  };
  const filterPOs = purchaseOrders.filter(
    (purchaseOrder) => purchaseOrder.status !== PO_STATUS.VOIDED
  );
  const filterShipments = getPOShipments({
    shipments,
    purchaseOrder: currentPurchaseOrder,
  });
  const poList = filterPOs.map((po) => ({
    id: po.id,
    name: po.number,
    divider: true,
    selected: po.id === currentPurchaseOrder.id,
    subTitle: getFactoryName(factories, po.factoryId, true),
    userMentions: po.userMentions || {},
    scope: NOTES_SCOPE.PURCHASE_ORDER,
  }));

  const shipmentList = filterShipments.map((shipment) => ({
    id: shipment.id,
    name: getShipmentBadge({
      shipment: shipment,
      classes: shipmentBadgeClass.medium,
      tooltipPlacement: "bottom",
    }),
    divider: false,
    subTitle: shipment.finalDestination || "",
    userMentions: shipment.userMentions || {},
    scope: NOTES_SCOPE.SHIPMENT,
  }));
  const currentList = {
    [NOTES_SCOPE.PURCHASE_ORDER]: {
      list: poList,
      scope: "purchase-order-tab-",
      id: currentPurchaseOrder.id,
      sizeNumber: Math.max(...poList.map((po) => po.name.length)),
      sizeSubtitle: Math.max(...poList.map((po) => po.subTitle.length)),
    },
    [NOTES_SCOPE.SHIPMENT]: {
      list: shipmentList,
      scope: "shipment-tab-",
      id: currentShipment.id,
      sizeNumber: 6,
      sizeSubtitle: Math.max(
        ...shipmentList.map((shipment) => shipment.subTitle.length)
      ),
    },
  };
  const isActive = (targetScope) => {
    if (targetScope === scope) {
      return `NOTE-${targetScope}-active`;
    } else {
      return `NOTE-${targetScope}`
    }
  };

  const getCurrentUsers = () => {
    const parentDocument = documentPermission.parentDocument || {};
    const parentUserList = parentDocument.permissionUsers || {};
    const parentGroupList = parentDocument.permissionGroups || {};

    if (!parentDocument.id) {
      return companyUsers;
    }

    const parentUsersPermission = getselectedUsersObj(
      parentUserList,
      parentGroupList,
      permissionGroupsDB
    );

    return companyUsers.filter(
      (userList) => parentUsersPermission[userList.id]
    );
  };

  return (
    <ProjectDocumentNoteStyled className="documentNoteContentCard">
      {/* //TODO */}
      {anchorEl && (
        <NavPicker
          anchorEl={anchorEl}
          open={!!anchorEl}
          list={currentList[scope].list}
          onClose={() => {
            setAnchorEl(null);
            setScope("");
          }}
          selectedId={currentList[scope].id}
          scope={currentList[scope].scope}
          user={user}
          navStyles={{ top: 80 }}
          sizeNumber={currentList[scope].sizeNumber}
          sizeSubtitle={currentList[scope].sizeSubtitle}
          onClickAction={(id, scope) => {
            if (scope === NOTES_SCOPE.PURCHASE_ORDER) {
              handlePOId(id);
              const newPO = purchaseOrders.find((po) => po.id === id) || {};
              const shipmentsIds = newPO.shipmentIds || [];
              if (!shipmentsIds.includes(shipmentDocumentId)) {
                handleShipmentId(shipmentsIds[0] || "");
              }
            } else {
              handleShipmentId(id);
            }
            setSelectedDocumentTab(1);
            setAnchorEl(null);
            setScope("");
          }}
          hiddenAvatar
        />
      )}
      {documentPermission.id && (
        <ModalPermission
          companyUsers={getCurrentUsers()}
          currentUser={user.id}
          handleCancel={() => setDocumentPermission({})}
          handleDone={handleUpdatePermissions}
          notificatorErrorRemoveUser={() => {}}
          onClose={() => setDocumentPermission({})}
          onlyTitle
          open={!!documentPermission.id}
          parentDocument={documentPermission.parentDocument}
          permissionGroupsDB={permissionGroupsDB}
          title="Access Groups"
          typePermissionModal={typeModalPermissions.VIEW_PERMISSIONS}
          file={documentPermission}

        />
      )}
      {closeIcon}
      <div className={"cardHeader"}>
        <span className="documents-title">Documents</span>
        {filterPOs.length > 0 && (
          <div style={{ display: "flex" }}>
            <div
              className={cx("title-list", isActive(NOTES_SCOPE.PURCHASE_ORDER))}
              onClick={(ev) => {
                setAnchorEl(ev.currentTarget);
                setScope(NOTES_SCOPE.PURCHASE_ORDER);
              }}
              id="long-button"
            >
              <PurchaseOrderIcon />
              <ArrowDownIcon
                svgClass="arrowIcon"
                width={12}
                style={{ marginLeft: 4 }}
              />
            </div>
            {filterShipments.length > 0 && (
              <div
                className={cx("title-list", isActive(NOTES_SCOPE.SHIPMENT))}
                onClick={(ev) => {
                  setAnchorEl(ev.currentTarget);
                  setScope(NOTES_SCOPE.SHIPMENT);
                }}
              >
                <ShipmentOrderIcon />
                <ArrowDownIcon
                  svgClass="arrowIcon"
                  width={12}
                  style={{ marginLeft: 4 }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <NoteDocumentsTab
        onChange={changeDocumentTab}
        value={selectedDocumentTab}
        currentPurchaseOrder={currentPurchaseOrder}
      />

      <div
        className="content-card-content"
        style={{
          flexDirection: "column",
          height: "100%",
          display: "flex",
        }}
      >
        <div className={"documentScrollContainers"}>
          {companyId && (
            <ManagementDocumentsNote
              companyID={companyId}
              documents={getDocumentByTab()}
              permissionGroupsDB={permissionGroupsDB}
              dbTable={
                selectedDocumentTab === 0
                  ? dbTables.SALES_ORDER_DOCUMENTS
                  : dbTables.PURCHASE_ORDER_DOCUMENTS
              }
              mainTable={
                selectedDocumentTab === 0
                  ? dbTables.SALES_ORDERS
                  : dbTables.PURCHASE_ORDERS
              }
              mainTableID={
                selectedDocumentTab === 0
                  ? currentSalesOrder.id
                  : currentPurchaseOrder.id
              }
              selectedDocumentTab={selectedDocumentTab}
              documentData={documentData}
              handleRichDocument={(document) => {
                if (document.id) {
                  handleRichDocument({
                    ...document,
                    purchaseOrderId: poDocumentId,
                    shipmentId: shipmentDocumentId,
                  });
                } else {
                  handleRichDocument(document);
                }
              }}
              handleEditPermission={setDocumentPermission}
              currentShipment={currentShipment}
              permissionScope={
                GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT
              }
            />
          )}
        </div>
      </div>
    </ProjectDocumentNoteStyled>
  );
}

export default DocumentsNote;
