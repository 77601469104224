import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const ManagementDocumentPlaceholderStyled = styled("div")(() => ({
  "&.managementDocumentContainer": {},
  "& .salesOrderHeaderTitles": {
    display: "flex",
    // background: "$primary_light",
    height: 56,
  },
  "& .salesOrderTitleOrder": {
    width: 70,
    textAlign: "center",
    alignSelf: "center",
    fontWeight: 700,
    color: "#25486d",
  },
  "& .salesOrderTitleDocumentType": {
    textAlign: "center",
    alignSelf: "center",
    fontWeight: 700,
    color: "#25486d",
  },
  "& .folderEstructureListTemplate": {
    overflow: "auto",
    userSelect: "none",
    height: "100%",
    padding: "0px 18px",
  },
}));

export const ManagementPlaceholderDocumentHeaderStyled = styled("div")(() => ({
  "&.documentHeader": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 70,
    padding: "0px 10px",
    backgroundColor: "white",
    color: "#25486d",
    position: "sticky",
    top: 0,
    zIndex: 5,
    "& .button-expand-collapse": {
      background: "white",
      color: "#25486d",
      border: "1px solid #25486d",
      height: 20,
      fontSize: 12,
      fontWeight: 600,
      padding: 0,
      width: 80,
      minWidth: "70px !Important",
      margin: 0,
    },
    "& .documents-header-title-document": {
      fontSize: 19,
      fontWeight: 500,
    },
    "& .documents-header-button-add-document": {
      display: "flex",
      alignItems: "center",
      "& .add-new-folder": {
        margin: 4,
        height: 20,
        display: "flex",
        alignItems: "baseline",
        "&:active": {
          transform: "scale(0.9)",
        },
        "&:hover": {
          cursor: "pointer",
        },
        "& span": {
          fontSize: 13,
          fontWeight: 600,
          paddingLeft: 4,
          position: "relative",
          top: -1,
        },
        "& .img-new-folder-placeholder": {
          width: 16,
          height: 16,
        },
        "& .documents-icon-add-document": {
          paddingRight: 10,
        },
      },
    },
  },
  "& .headerTitleAndButtons": {
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "30px",
  },
}));

export const PlaceholderDocumentOptionStyled = styled("div")(() => ({
  "&.documentOptionContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .labelDeletePlaceholder": {
    fontWeight: 600,
    marginLeft: 10,
    marginBottom: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  "& .content": {
    margin: "0px 2px",
    display: "flex",
    alignItems: "baseline",
  },
  "& .contentImg": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const ListItemDocumentStyled = styled("div")(() => ({
  "&.listenItemDocument": {},
  "& .listItemFolder": {
    paddingTop: 8,
    paddingBottom: 8,
    cursor: "pointer",
    height: 60,
  },
  "& .listItemIcon": {
    minWidth: "0 !important",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      margin: 8,
    },
  },
  "& .listItemContent": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  "& .editableLabelContainer": {
    width: "35%",
  },
  "& .imgListItemIcon": {
    width: 39,
    height: 31,
    marginLeft: 16,
  },
  "& .imgListItemIconOrderPlaceholder": {
    width: 20,
    height: 10,
  },
  "& .listItemDocumentCollapseItemsFolder": {
    "& div": {
      "& ul": {
        marginLeft: 24,
        marginRight: 0,
      },
    },
  },
  "& .listemItemDocument": {
    "& div": {
      "& div": {
        "& div": {},
      },
    },
  },
  "& .listItemDocumentCollapseItems": {
    "& div": {
      marginRight: 5,
      paddingLeft: 0,
    },
  },
}));

export const LegendDocumentsStyled = styled("div")(() => ({
  "&.legendContainer": {
    display: "flex",
    justifyContent: "center",
    fontSize: 12,
    alignItems: "center",
    marginTop: 5,
    "& span": {
      margin: "5px 24px 0px 4px",
    },
  },
}));

export const ModalPermissionPlaceholderDocumentStyled = styled("div")(() => ({
  "&.dragAndDropModalDialog": {
    "& .modalTitle": {
      height: 85,
      fontSize: 20,
      textAlign: "center",
      letterSpacing: 0.43,
      color: "#25486d",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .selectDocumentType": {
      color: "#25486d",
      fontSize: 20,
      letterSpacing: 0.43,
      textAlign: "center",
      marginBottom: 0,
    },
    "& .wrongUploadFileContainer": {
      display: "flex",
      justifyContent: "center",
      paddingTop: 16,
    },
    "& .wrongUploadFile": {
      width: 25,
      height: 25,
    },
    "& .onlyOneFileCanBe": {
      color: "#f36",
      fontSize: 16,
      letterSpacing: 0.34,
      marginBottom: 0,
      paddingLeft: 5,
    },
    "& .modalDocumentSectionOnUpload": {
      height: 120,
      background: "#e9f4ff",
      display: "flex",
      alignItems: "center",
      paddingLeft: 50,
      paddingRight: 50,
      justifyContent: "space-between",
      minHeight: 66,
    },
    "& .modalDocumentSectionUploaded": {
      height: 120,
      background: "#e9f4ff",
      display: "flex",
      alignItems: "center",
      paddingLeft: 50,
      paddingRight: 50,
      justifyContent: "center",
      minHeight: 66,
    },
    "& .fileInformation": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
    "& .listItemTextPrimary": {
      paddingLeft: 8,
      "& label": {
        margin: 0,
      },
    },
    "& .filename": {
      fontSize: 14,
      fontWeight: 600,
      color: "#798a9b",
      letterSpacing: 0.3,
      position: "relative",
    },
    "& .filesize": {
      fontSize: 12,
      color: "#798a9b",
      paddingLeft: 10,
    },
    "& .listItemTextSecondary": {},
    "& .fileinfo": {
      fontSize: 12,
      color: "#798a9b",
      letterSpacing: 0.3,
      margin: 0,
    },
    "& .progressBar": {
      background: "none",
      "& div": {
        backgroundColor: "none",
        borderRadius: 100,
        "& div": {
          backgroundColor: "#39f",
        },
      },
    },
    "& .successfulUpload": {
      width: 16,
      height: 16,
    },
    "& .modalPermissionsOnUpload": {
      height: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
    },
    "& .documentType": {
      margin: 5,
      marginLeft: "auto",
      width: "25%",
      "& label": {
        display: "block",
        height: 14,
        color: colors.darkGray,

        fontSize: 12,
        letterSpacing: 0.26,
        margin: 0,
      },
      "& select": {
        marginLeft: 0,
        boxSizing: "border-box",
        height: 42,
        borderRadius: 5,
        backgroundColor: "#fff",
        border: "1px solid #a8b9ca",
        width: "100%",
      },
    },
    "& .permissionsGroupContainer": {},
    "& .multiselect": {},
    "& .permissionContent": {
      display: "flex",
      padding: "6px 4px",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "auto",
      lineHeight: 1.5,
      "& .labelText": {
        paddingLeft: 8,
        height: "auto",
        fontSize: 12,
        cursor: "pointer",
      },
      "& .permissionCheck": {
        cursor: "pointer",
      },
    },
    "& .viewPermissionOnUpload": {
      margin: 5,
      width: "65%",
      minWidth: 550,
      "& label": {
        display: "block",
        height: 14,
        width: 100,
        color: colors.darkGray,

        fontSize: 12,
        letterSpacing: 0.26,
        margin: 0,
      },
      "& input": {
        marginLeft: 0,
      },
    },
    "& .modalButtonsSection": {
      height: 150,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .btn-cancel": {
        height: 54,
        width: 119,
        color: "#25486d",
        fontSize: 14,
        background: "rgba(168, 182, 200, 0.15)",
        boxSizing: "border-box",
        borderRadius: 3,
        border: "1px solid #a8b6c8",
      },
      "& .btn-done ": {
        width: 219,
        height: 54,
        background: "#39f",
        color: "#fff",
        fontSize: 14,
        fontWeight: 700,
      },
    },
  },
}));

export const CreateNewFolderContainerStyled = styled("div")(() => ({
  "&.createNewFolderContainer": {},
  "& .listItemFolder": {
    paddingTop: 8,
    paddingBottom: 8,
    cursor: "pointer",
    height: 60,
    "& .editable-label": {
      width: "100% !Important",
      margin: "0px 4px !Important",
      "& .input-editable-label": {
        width: "100% !Important",
      },
    },
  },
  "& .listItemIcon": {
    minWidth: 0,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    img: {
      margin: "1px 8px",
    },
  },
  "& .imgListItemIcon": {
    width: 39,
    height: 31,
    marginLeft: 16,
  },
  "& .buttonOptions": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    "& button": {
      minWidth: 45,
      height: 25,
      margin: 2,
    },
  },
}));
