import React, { useState, useEffect } from "react";
import PendingDivider from "../Dividers/PendingDivider";
import Activity from "./Activities/Activity";
import { BACKDROP_TYPE, featureFlagNames } from "../../helpers/constants";
import { useCompanyId, usePermissionGroups, useQueryParams } from "../../hooks";

import { hasFeatureFlagPermission } from "../../helpers/helpers";
import ExpandActivityModal from "./Activities/ExpandActivityModal";
import { handleMarkAsRead } from "../../helpers/activitiesStream";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { useSalesOrders } from "../../hooks/salesOrders";
import { useBackdropState } from "../../hooks/user";
import { onClickMentions } from "./Activities/helpers";
import { useNavigate } from "react-router-dom";

function FilterActivities({
  filterActivities,
  title,
  users,
  onEditNote,
  unreadActivities = [],
  firstUnreadActivity,
  scrolled,
  currentFirstActivityUnread,
  querySearch,
  salesOrder,
  purchaseOrder,
  index = 0,
  isAllActivitiesEmpty = false,
  openModalNotes,
  purchaseOrders,
  currentUser,
  setAttentionData = () => {},
  currentShipment,
  isAllowed = () => {},
  currentNote,
  onReplyActivity,
  onShowThread,
  noteThread,
  hasThreadPermission,
}) {
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const backdropState = useBackdropState();
  const companyId = useCompanyId();
  const [activityModal, setActivityModal] = useState({});
  const featureFlags = useFeatureFlags({ companyId });
  const salesOrders = useSalesOrders();
  const query = useQueryParams() || {};
  const permissionGroupDB = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });

  useEffect(() => {
    const fullHeight = getFullHeight(filterActivities);
    setHeight(fullHeight);
  }, [filterActivities]);

  const addMentionBackdrop = async ({ ev, activity }) => {
    const currentSalesOrder =
      salesOrders.find((so) => so.id === activity.salesOrderId) || salesOrder;
    const hasFlagPermission = hasFeatureFlagPermission({
      featureFlags,
      user: currentUser,
      featureFlagName: featureFlagNames.DOCUMENT_IN_NOTES,
    });
    onClickMentions({
      activity,
      companyId,
      currentUser,
      event: ev,
      hasFlagPermission,
      isAllowed,
      navigate,
      permissionGroupDB,
      purchaseOrders,
      query,
      salesOrder,
      salesOrders,
      setAttentionData,
      currentSalesOrder,
    });
  };

  function getFullHeight(filterActivities) {
    let height = 0;
    const arrDivElements = [];
    filterActivities.forEach((activity) => {
      const divElement = document.getElementById(activity.id);
      if (divElement) {
        arrDivElements.push(divElement);
      }
    });
    arrDivElements.forEach((element) => {
      height += element.clientHeight + 12;
    });
    return height;
  }
  return (
    <React.Fragment>
      {activityModal && activityModal.id && (
        <ExpandActivityModal
          activity={activityModal}
          openModalActivity={!!activityModal.id}
          onAddMentionBackdrop={addMentionBackdrop}
          onCloseModalNotes={() => {
            setActivityModal({});
          }}
          onMarkRead={(userModal) =>
            handleMarkAsRead({
              activity: activityModal,
              currentShipment,
              purchaseOrder,
              salesOrder,
              user: userModal,
            })
          }
        />
      )}
      <div
        className={
          querySearch === "" &&
          (unreadActivities.length > 0 || scrolled === "in-progress")
            ? "title-today"
            : "title-today-unread-activities"
        }
      >
        {filterActivities.length > 0 && (
          <p className={"activity-stream-title-today"}>{title}</p>
        )}
        {isAllActivitiesEmpty &&
          index === 0 &&
          filterActivities.length === 0 && (
            <p className={"empty-stream-title"}>
              {"The current filter selection does not have any results"}
            </p>
          )}
      </div>
      <React.Fragment>
        <div className="timeline" style={{ height: height }} />
        {filterActivities &&
          filterActivities.map((activity, index) => {
            const focusActivity =
              backdropState.id.includes(activity.id) &&
              backdropState.type === BACKDROP_TYPE.NOTE;
            const isFirstUnreadActivity =
              (querySearch === "" &&
                firstUnreadActivity &&
                activity.id === firstUnreadActivity.id) ||
              (currentFirstActivityUnread &&
                activity.id === currentFirstActivityUnread.id);
            return (
              <React.Fragment key={activity.id + index + "fragment"}>
                <Activity
                  activityIndex={title === "Today" && index < 8 ? index : -1}
                  key={activity.id + index + "Activity"}
                  focusActivity={focusActivity}
                  activity={activity}
                  users={users}
                  onEditNote={onEditNote}
                  openModalNotes={openModalNotes}
                  querySearch={querySearch}
                  salesOrder={salesOrder}
                  purchaseOrder={purchaseOrder}
                  addMentionBackdrop={addMentionBackdrop}
                  handleOpenModal={setActivityModal}
                  currentNote={currentNote}
                  onReplyActivity={onReplyActivity}
                  onShowThread={onShowThread}
                  noteThread={noteThread}
                  hasThreadPermission={hasThreadPermission}
                />
                {!noteThread && isFirstUnreadActivity && (
                  <div
                    id="unread-line-activity"
                    className="recent-activity-line"
                  >
                    <div className="recent-activity-content">
                      <PendingDivider text="New Activity" />
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        <div className="activity-timestamp-line" />
      </React.Fragment>
    </React.Fragment>
  );
}

export default FilterActivities;
