import React, { useState } from "react";
import { getSpreadSheetByName } from "../../helpers/helpers";

import {
  buildUsersSpreadSheetIntoObject,
  buildPermissionGroupsSpreadSheetIntoObject,
  buildCustomersSpreadSheetIntoObject,
  buildFactoriesSpreadSheetIntoObject,
  buildDocumentTemplateSpreadSheetIntoObject,
  buildRoleSpreadSheetIntoObject,
  buildSOTemplateSpreadSheetIntoObject,
  buildPOTemplateSpreadSheetIntoObject,
  buildSOSpreadSheetIntoObject,
  buildPOSpreadSheetIntoObject,
  buildActivityStreamSpreadSheetIntoSOandPO,
  buildTagsSpreadSheetIntoObject,
  buildShipmentsSpreadSheetIntoObject,
  buildProductsSpreadSheetIntoObject,
  buildItemTableSpreadSheetIntoObject,
} from "../../helpers/googleSpreadSheets";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleSignInButton from "./GoogleSignInButton";

function SpreadSheetOptions({
  handleShowMessage,
  handleSuccessSpreadSheet,
  handleSetLoader,
  handleSuccessGoogleOAuthLogged,
}) {
  let spreadSheetData = {};
  const [sheetID, setSheetID] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [spreadSheetOptions, setSpreadSheetOptions] = useState([]);

  async function handleValidate() {
    console.log("STARTED HANDLE VALIDATE");
    const customersSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Customers"
    );
    const {
      statusCustomers,
      messageCustomers,
      customersDB,
    } = buildCustomersSpreadSheetIntoObject(customersSpreadSheet.values);

    if (statusCustomers !== 200) {
      handleShowMessage(messageCustomers);
      return;
    }
    spreadSheetData.customersDB = customersDB;

    const factoriesSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Vendors"
    );
    const {
      statusFactories,
      messageFactories,
      factoriesDB,
    } = buildFactoriesSpreadSheetIntoObject(factoriesSpreadSheet.values);

    if (statusFactories !== 200) {
      handleShowMessage(messageFactories);
      return;
    }
    spreadSheetData.factoriesDB = factoriesDB;

    const usersSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Users"
    );
    if (
      usersSpreadSheet.error &&
      Object.keys(usersSpreadSheet.error).length > 0
    ) {
      handleShowMessage(usersSpreadSheet.error.message);
    }
    const { status, message, usersDB } = await buildUsersSpreadSheetIntoObject({
      users: usersSpreadSheet.values,
      factories: spreadSheetData.factoriesDB,
      customers: spreadSheetData.customersDB,
    });
    if (status !== 200) {
      handleShowMessage(message);
      return;
    }
    spreadSheetData.usersDB = usersDB;

    const permissionGroupsSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Permission Groups"
    );

    const {
      statusPermissionGroups,
      messagePermissionGroups,
      permissionGroupsDB,
    } = buildPermissionGroupsSpreadSheetIntoObject(
      permissionGroupsSpreadSheet.values,
      spreadSheetData.usersDB
    );
    if (statusPermissionGroups !== 200) {
      handleShowMessage(messagePermissionGroups);
      return;
    }
    spreadSheetData.permissionGroupsDB = permissionGroupsDB;

    const documentPlaceholdersSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Document Placeholders"
    );

    const {
      statusDocumentTemplate,
      messageDocumentTemplate,
      SODocumentTemplateDB,
      PODocumentTemplateDB,
      shipmentDocumentTemplateDB,
    } = buildDocumentTemplateSpreadSheetIntoObject(
      documentPlaceholdersSpreadSheet.values,
      spreadSheetData.permissionGroupsDB
    );
    if (statusDocumentTemplate !== 200) {
      handleShowMessage(messageDocumentTemplate);
      return;
    }
    spreadSheetData.SODocumentTemplateDB = SODocumentTemplateDB;
    spreadSheetData.PODocumentTemplateDB = PODocumentTemplateDB;
    spreadSheetData.shipmentDocumentTemplateDB = shipmentDocumentTemplateDB;
    const rolesSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Roles"
    );

    const {
      statusRole,
      messageRole,
      rolesDB,
      usersDB: newUsersDB,
      superAdmin,
    } = buildRoleSpreadSheetIntoObject(
      rolesSpreadSheet.values,
      spreadSheetData.usersDB
    );
    spreadSheetData.superAdmin = superAdmin;

    if (statusRole !== 200) {
      handleShowMessage(messageRole);
      return;
    }
    spreadSheetData.rolesDB = rolesDB;
    spreadSheetData.usersDB = newUsersDB;
    spreadSheetData.superAdmin = superAdmin;
    const SOTemplateSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "SO Template"
    );
    const {
      statusSOTemplate,
      messageSOTemplate,
      SOTaskTemplateSpreadSheetDB,
    } = buildSOTemplateSpreadSheetIntoObject(
      SOTemplateSpreadSheet.values,
      spreadSheetData.usersDB
    );
    if (statusSOTemplate !== 200) {
      handleShowMessage(messageSOTemplate);
      return;
    }
    spreadSheetData.SOTemplateDB = SOTaskTemplateSpreadSheetDB;
    const POTemplateSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "PO Template"
    );
    const {
      statusPOTemplate,
      messagePOTemplate,
      POTaskTemplateSpreadSheetDB,
    } = buildPOTemplateSpreadSheetIntoObject(
      POTemplateSpreadSheet.values,
      spreadSheetData.SOTemplateDB,
      spreadSheetData.usersDB
    );
    if (statusPOTemplate !== 200) {
      handleShowMessage(messagePOTemplate);
      return;
    }
    spreadSheetData.POTemplateDB = POTaskTemplateSpreadSheetDB;

    const tagsSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Labels"
    );
    const {
      statusTags,
      messageTags,
      tagsSpreadSheetDB,
    } = buildTagsSpreadSheetIntoObject(
      tagsSpreadSheet.values,
      spreadSheetData.usersDB
    );
    if (statusTags !== 200) {
      handleShowMessage(messageTags);
      return;
    }
    spreadSheetData.tagsDB = tagsSpreadSheetDB;

    const SOSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Sales Orders"
    );
    const {
      statusSO,
      messageSO,
      SOSpreadSheetDB,
    } = buildSOSpreadSheetIntoObject({
      SOSpreadSheet: SOSpreadSheet.values,
      customersDB: spreadSheetData.customersDB,
      userDB: spreadSheetData.usersDB[0],
      tags: spreadSheetData.tagsDB,
    });
    if (statusSO !== 200) {
      handleShowMessage(messageSO);
      return;
    }
    spreadSheetData.SOSpreadSheetDB = SOSpreadSheetDB;

    const POSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "POs"
    );
    const {
      statusPO,
      messagePO,
      POSpreadSheetDB,
    } = buildPOSpreadSheetIntoObject({
      POSpreadSheet: POSpreadSheet.values,
      factoriesDB: spreadSheetData.factoriesDB,
      SOSpreadSheetDB: spreadSheetData.SOSpreadSheetDB,
      userDB: spreadSheetData.usersDB[0],
      tags: spreadSheetData.tagsDB,
    });
    if (statusPO !== 200) {
      handleShowMessage(messagePO);
      return;
    }
    spreadSheetData.POSpreadSheetDB = POSpreadSheetDB;

    const activityStreamSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Activity Stream"
    );
    const {
      statusActivityStream,
      messageActivityStream,
      SOActivityStreamDB,
      POActivityStreamDB,
    } = buildActivityStreamSpreadSheetIntoSOandPO(
      activityStreamSpreadSheet.values,
      spreadSheetData.SOSpreadSheetDB,
      spreadSheetData.POSpreadSheetDB,
      spreadSheetData.usersDB
    );
    if (statusActivityStream !== 200) {
      handleShowMessage(messageActivityStream);
      return;
    }
    spreadSheetData.SOActivityStreamDB = SOActivityStreamDB;
    spreadSheetData.POActivityStreamDB = POActivityStreamDB;

    //
    const shipmentsSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Shipments"
    );
    const {
      messageShipments,
      statusShipments,
      shipmentsSpreadSheetDB,
    } = buildShipmentsSpreadSheetIntoObject({
      shipmentsSpreadSheet: shipmentsSpreadSheet,
      tags: spreadSheetData.tagsDB,
      userDB: spreadSheetData.usersDB,
      salesOrderId: spreadSheetData.SOSpreadSheetDB[0].id || "",
      purchaseOrderId: spreadSheetData.POSpreadSheetDB[0].id || "",
      customerId: spreadSheetData.customersDB[0].id,
    });
    if (statusShipments !== 200) {
      handleShowMessage(messageShipments);
      return;
    }
    spreadSheetData.shipmentsSpreadSheetDB = shipmentsSpreadSheetDB;
    //
    // PRODUCTS::
    const productsSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Products"
    );
    const {
      messageProducts,
      statusProducts,
      productsSpreadSheetDB,
    } = buildProductsSpreadSheetIntoObject({
      productsSpreadSheet: productsSpreadSheet,
    });
    if (statusProducts !== 200) {
      handleShowMessage(messageProducts);
      return;
    }
    spreadSheetData.productsSpreadSheetDB = productsSpreadSheetDB;
    //
    // ITEM TABLE::
    const itemTableSpreadSheet = await getSpreadSheetByName(
      sheetID,
      accessToken,
      "Item Table"
    );
    const {
      messageItemTable,
      statusItemTable,
      itemTableSpreadSheetDB,
      purchaseOrdersDBCpy,
      shipmentsDBCpy,
    } = buildItemTableSpreadSheetIntoObject({
      itemTableSpreadSheet: itemTableSpreadSheet,
      salesOrdersDB: spreadSheetData.SOSpreadSheetDB,
      purchaseOrdersDB: spreadSheetData.POSpreadSheetDB,
      productsDB: spreadSheetData.productsSpreadSheetDB,
      shipmentsDB: spreadSheetData.shipmentsSpreadSheetDB,
    });
    if (statusItemTable !== 200) {
      handleShowMessage(messageItemTable);
      return;
    }
    spreadSheetData.itemTableSpreadSheetDB = itemTableSpreadSheetDB;
    spreadSheetData.POSpreadSheetDB = purchaseOrdersDBCpy;
    spreadSheetData.shipmentsSpreadSheetDB = shipmentsDBCpy;
    //SOSpreadSheetDB
    let SOSpreadSheetDBCpy = [...spreadSheetData.SOSpreadSheetDB];
    const purchaseOrders = spreadSheetData.POSpreadSheetDB;
    SOSpreadSheetDBCpy = SOSpreadSheetDBCpy.map((salesOrder) => {
      const purchaseOrderIds = [];
      purchaseOrders.forEach((po) => {
        if (po.salesOrderIds && po.salesOrderIds.includes(salesOrder.id)) {
          purchaseOrderIds.push(po.id);
        }
      });
      return { ...salesOrder, purchaseOrderIds };
    });
    spreadSheetData.SOSpreadSheetDB = SOSpreadSheetDBCpy;
    // HANDLE SUCCESS SPREAD SHEET
    handleSuccessSpreadSheet(spreadSheetData);
  }

  const responseGoogle = async (response) => {
    console.log(response);
    const { access_token } = response;
    const sheetID = "1roC3odmeSz6bCiDsmK1X7LGom4ZPtZa74PGFTRFxTGo";
    const spreadSheetOptions = await getSpreadSheetByName(
      sheetID,
      access_token,
      "Sheet1"
    );
    console.log("-----responseGoogle", spreadSheetOptions);

    if (
      spreadSheetOptions.error &&
      Object.keys(spreadSheetOptions.error).length > 0
    ) {
      setSpreadSheetOptions([]);
      handleShowMessage(spreadSheetOptions.error.message);
      return;
    } else {
      handleSuccessGoogleOAuthLogged();
    }
    const { values } = spreadSheetOptions;
    const spreadSheetOptionsArr = [];
    values.forEach((element, index) => {
      if (index === 0) return;
      if (element[0]) {
        spreadSheetOptionsArr.push({ name: element[0], id: element[1] || "" });
      }
    });
    setAccessToken(access_token);
    setSpreadSheetOptions(spreadSheetOptionsArr);
  };

  return (
    <div>
      <div
        style={{
          padding: "8px 28px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <GoogleOAuthProvider clientId="725773308876-3d2dn58b4m3o8h16ak0utc3d2odcoq1u.apps.googleusercontent.com">
          <GoogleSignInButton
            onSuccess={responseGoogle}
            onError={responseGoogle}
          />
        </GoogleOAuthProvider>
        <div className="spreadsheet-options-container">
          <select
            onChange={(ev) => setSheetID(ev.target.value)}
            style={{ height: 42, borderRadius: 3 }}
          >
            <option value="">Select spreadsheet</option>
            {spreadSheetOptions.map((spreadSheet, index) => {
              return (
                <option
                  key={spreadSheet.id + index}
                  value={spreadSheet.id}
                  disabled={!spreadSheet.id}
                >
                  {spreadSheet.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div
        style={{
          padding: 8,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          style={{ background: "#39F" }}
          onClick={() => {
            handleSetLoader();
            handleValidate();
          }}
        >
          Verify
        </button>
      </div>
    </div>
  );
}

export default SpreadSheetOptions;
