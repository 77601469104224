import { TYPE_OF_FILE } from "../../helpers/constants";
import { now } from "moment/moment";
import { documentScope } from "../../helpers/documents";
import { cx } from "@emotion/css";

export const hasExtension = (fileName) => /\.[^/.]+$/.test(fileName);

export const getDocumentId = (currentDocument) => {
  let idShipmentFolder = currentDocument.isShipmentRootTask
    ? currentDocument.shipmentId
    : currentDocument.id;
  if (!currentDocument.mainFile) {
    idShipmentFolder = currentDocument.mainFileId || currentDocument.id;
  }
  const idVersion =
    currentDocument.type !== TYPE_OF_FILE.FOLDER
      ? `_V${currentDocument.version || 1}`
      : "";

  const idPrefix =
    currentDocument.scope === documentScope.SHIPMENT
      ? `${currentDocument.scope}_${idShipmentFolder}`
      : currentDocument.mainFileId || currentDocument.id;

  return `list-item-document_${idPrefix}${idVersion}`;
};

export function getListItemClassName(currentDocument, open) {
  let className = "";
  const scope = currentDocument.scope;
  let folderScope = `F_${scope}`;
  if (currentDocument.type !== TYPE_OF_FILE.FOLDER) {
    className = cx("listItemFile", scope);
  } else if (open) {
    folderScope = currentDocument.isShipmentRootTask ? "" : folderScope;
    className = cx("listItemFolderOpen", folderScope);
  } else {
    folderScope = currentDocument.isShipmentRootTask ? "" : folderScope;
    className = cx("listItemFolderClose", folderScope);
  }

  return className;
}

export const replaceDocumentState = ({ oldData, fileData, extraData = {} }) =>
  oldData.map((file) => {
    if (file.name === fileData.name) {
      return {
        ...fileData,
        ...extraData,
        completed: true,
      };
    } else {
      return { ...file, ...extraData };
    }
  });

export function verifyFolderContainPlaceholderFile({
  folder = {},
  documents = [],
  currentUser = {},
  updateDoc = () => {},
}) {
  let hasFileTemplate = false;
  const folderChilds = documents.filter((doc) => doc.parentId === folder.id);
  if (folderChilds.length === 0) {
    if (folder.placeholder) {
      updateDoc(folder.ref, {
        lastModified: now(),
        created: now(),
        placeholder: false,
        createdBy: currentUser.id,
        user: currentUser.id,
      });
    } else {
      updateDoc(folder.ref, { lastModified: now(), user: currentUser.id });
    }
  }
  folderChilds.forEach((child) => {
    if (
      child.placeholder &&
      child.type === TYPE_OF_FILE.FOLDER &&
      verifyFolderContainPlaceholderFile(child, documents)
    ) {
      hasFileTemplate = true;
    }
    if (child.placeholder && child.type !== TYPE_OF_FILE.FOLDER) {
      hasFileTemplate = true;
    }
  });
  if (!hasFileTemplate) {
    if (folder.placeholder) {
      updateDoc(folder.ref, {
        lastModified: now(),
        created: now(),
        placeholder: false,
        createdBy: currentUser.id,
        user: currentUser.id,
      });
    } else {
      updateDoc(folder.ref, { lastModified: now(), user: currentUser.id });
    }
  }

  return hasFileTemplate;
}
