import React, { useState, useEffect, useRef } from "react";
import NotificationsHeader from "../../components/Notifications/NotificationsHeader";
import NotificationsContent from "../../components/Notifications/NotificationsContent";
import { useCompanyUsers, useUser } from "../../hooks/user";
import { dbTables } from "../../api/types/dbTables";
import { getNotificationText, sortObjectsBy } from "../../helpers/helpers";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NotificationsFilters from "../../components/Notifications/NotificationsFilters";
import { firestore } from "../../firebase";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  BY_FILTER_NOTIFICATION,
  FILTER_NOTIFICATION,
  GENERAL_PERMISSION_VALUE,
} from "../../helpers/constants";
import { filterNotifications } from "./helperNotification";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { useJSONLocalStorage } from "../../hooks";
import {
  LOAD_NOTIFICATIONS,
  MARK_READ_LOADED_NOTIFICATIONS,
} from "../../actions/types";
import { dateFilterOptionLabel } from "../../helpers/notifications";
import Loader from "../../components/General/Loader";

function NotificationsContainer({
  open = false,
  onClose = () => {},
  history,
  handleOpenModal,
  handleLoading = () => {},
}) {
  const { get, set } = useJSONLocalStorage("notifications");
  const storageNotifications = get() || {};
  let storageActiveTab =
    storageNotifications.activeTab || FILTER_NOTIFICATION.DMs;
  let storageFilterUnread = !!storageNotifications.storageFilterUnread;
  let storageAssignedTo = storageNotifications.assignedTo || [];
  let storageCustomer = storageNotifications.customer || [];
  let storageFactory = storageNotifications.factory || [];
  let storageDate = storageNotifications.date || "";
  let storageFilterDateOption =
    storageNotifications.filterDateOption ||
    dateFilterOptionLabel.CHOOSE_A_DATE_RANGE;
  let storageDateRange = storageNotifications.dateRange || {
    start: null,
    end: null,
  };
  let storageSearchQuery = storageNotifications.searchQuery || "";

  if (moment().format("MM/DD/YYYY") !== storageDate) {
    resetToDefaultValues();
  }

  const user = useUser();
  const users = useCompanyUsers({});
  const [settingsAreOpen, setSettingsAreOpen] = useState(false);
  const [unReadNotifications, setUnReadNotifications] = useState(
    !!storageFilterUnread
  );
  const [searchQuery, setSearchQuery] = useState(storageSearchQuery);
  const [activeTab, setActiveTab] = useState(storageActiveTab);
  const [filters, setFilters] = useState({
    assignedTo: storageAssignedTo,
    factory: storageFactory,
    customer: storageCustomer,
    dateRange: storageDateRange,
    filterDateOption: storageFilterDateOption,
  });
  const isAllowed = useIsAllowedFunction();
  const isAbleToModifyTaskDueDate = isAllowed(
    GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const loadedNotifications = useSelector(
    (state) => state.data.loadedNotifications
  );

  const scrollerRef = useRef(null);
  function resetToDefaultValues() {
    storageActiveTab = FILTER_NOTIFICATION.DMs;
    storageAssignedTo = [];
    storageCustomer = [];
    storageFactory = [];
    storageDateRange = { start: null, end: null };
    storageFilterUnread = "";
  }
  useEffect(() => {
    if (open) {
      markNotifications();
      loadNotifications({ filters, activeTab });
    }
  }, [open]);

  useEffect(() => {
    set({
      ...storageNotifications,
      ...filters,
      date: moment().format("MM/DD/YYYY"),
    });
  }, [filters]);

  const getNotification = () => {
    let loadedNotificationsCpy = [
      ...getLoadedNotifications({
        loadedNotifications,
        activeTab,
      }),
    ];
    loadedNotificationsCpy = loadedNotificationsCpy.filter((notification) => {
      const { start, end } = filters.dateRange;
      if (
        notification.creationDate >= moment(start).startOf("day").valueOf() &&
        notification.creationDate <= moment(end).endOf("day").valueOf()
      ) {
        return true;
      }
      return false;
    });
    if (unReadNotifications) {
      loadedNotificationsCpy = loadedNotificationsCpy.filter((notification) => {
        if (!notification.read) {
          return true;
        }
        return false;
      });
    }
    return loadedNotificationsCpy.sort(sortObjectsBy("creationDate", true));
  };

  function getLoadedNotifications({
    loadedNotifications = {},
    activeTab = "",
  }) {
    if (!loadedNotifications[activeTab]) {
      return [];
    }
    return loadedNotifications[activeTab].data;
  }

  const currentNotifications = getNotification();

  function markNotifications() {
    const functions = getFunctions();
    const callable = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: `userNotifications`,
        env: globalEnvironment,
        params: `/newNotifications?userId=${user.id}`,
      })
    );
    callable().then((result) => {
      console.log("mark all notifications successfull");
    });
  }

  async function markAllRead() {
    setIsLoading(true);
    // DISPATCH A REDUX TO MARK ALL AS READ::
    dispatch({
      type: MARK_READ_LOADED_NOTIFICATIONS,
    });
    //
    // CALL TO THE BACKEND IN ORDER TO MARK ALL NOTIFICATIONS AL READ
    const functions = getFunctions();
    const callable = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: `userNotifications`,
        env: globalEnvironment,
        params: `/markAllAsRead?userId=${user.id}`,
      })
    );
    callable().then((result) => {
      console.log("mark all notifications as read successfull");
    });
    setIsLoading(false);
  }

  function onToggle(notification) {
    updateDoc(notification.ref, {
      read: !notification.read,
    });
    dispatch({
      type: LOAD_NOTIFICATIONS,
      payload: { ...notification, read: !notification.read },
      isFromListener: true,
    });
  }

  async function loadNotifications({ filters = {}, activeTab = "" }) {
    if (
      loadedNotifications &&
      loadedNotifications[activeTab] &&
      loadedNotifications[activeTab].dateRange
    ) {
      const dateRangeLoaded = loadedNotifications[activeTab].dateRange;
      const { start, end } = dateRangeLoaded;
      const { dateRange } = filters;
      const filterStart = moment(dateRange.start).startOf("day").valueOf();
      const reduxStart = moment(start).startOf("day").valueOf();
      const filterEnd = moment(dateRange.end).endOf("day").valueOf();
      const reduxEnd = moment(end).endOf("day").valueOf();
      if (filterStart >= reduxStart && filterEnd <= reduxEnd) {
        return;
      }
    }
    if (filters.dateRange && filters.dateRange.start) {
      setIsLoading(true);
      const { start, end } = filters.dateRange;
      const startDate = moment(start).startOf("day").valueOf();
      const endDate = moment(end).endOf("day").valueOf();

      let notificationQuery = query(
        collection(
          firestore,
          `${dbTables.USERS}/${user.id}/${dbTables.NOTIFICATIONS}`
        ),
        where("creationDate", ">=", startDate),
        where("creationDate", "<=", endDate)
      );

      if (activeTab !== FILTER_NOTIFICATION.ALL) {
        notificationQuery = query(
          notificationQuery,
          where("type", "==", BY_FILTER_NOTIFICATION[activeTab])
        );
      }
      const notificationsByDateSnap = await getDocs(notificationQuery);
      const isOnly = activeTab === FILTER_NOTIFICATION.DMs;
      const notificationsByDate = notificationsByDateSnap.docs.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
        userMentions: {
          ...doc.data().userMentions,
          [user.id]: isOnly
            ? true
            : !!doc.data().userMentions && doc.data().userMentions[user.id],
        },
      }));
      dispatch({
        payload: {
          data: notificationsByDate,
          dateRange: filters.dateRange,
        },
        table: activeTab,
        type: LOAD_NOTIFICATIONS,
      });
      setIsLoading(false);
      return true;
    } else {
      const limitNotification = 20;
      setIsLoading(true);
      let notificationQuery = collection(
        firestore,
        `${dbTables.USERS}/${user.id}/${dbTables.NOTIFICATIONS}`
      );

      if (activeTab !== FILTER_NOTIFICATION.ALL) {
        notificationQuery = query(
          notificationQuery,
          where("type", "==", BY_FILTER_NOTIFICATION[activeTab])
        );
      }
      notificationQuery = query(
        notificationQuery,
        orderBy("creationDate", "desc"),
        limit(limitNotification)
      );
      const notificationsSnapDB = await getDocs(notificationQuery);
      const notificationsDB = notificationsSnapDB.docs.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
      }));
      const endDate = moment.now();
      if (notificationsDB.length === 0) {
        setIsLoading(false);
        setFilters({
          ...filters,
          dateRange: {
            start: moment().startOf("month").valueOf(),
            end: endDate,
          },
          filterDateOption: dateFilterOptionLabel.CURRENT_MONTH,
        });
        return false;
      }

      const startDate =
        notificationsDB[notificationsDB.length - 1].creationDate;
      setFilters({
        ...filters,
        dateRange: {
          start: startDate,
          end: endDate,
        },
        filterDateOption: dateFilterOptionLabel.CHOOSE_A_DATE_RANGE,
      });

      dispatch({
        payload: {
          data: notificationsDB,
          dateRange: {
            start: startDate,
            end: endDate,
          },
        },
        table: activeTab,
        type: LOAD_NOTIFICATIONS,
      });
      setIsLoading(false);
    }
    return false;
  }

  async function onChangeFilters(filters, filter) {
    setFilters({ ...filters });
    loadNotifications({ filters, activeTab });
  }

  function handleResetFilters() {
    setFilters({ ...filters, assignedTo: [], customer: [], factory: [] });
    setActiveTab(FILTER_NOTIFICATION.DMs);
    setSearchQuery("");
    setUnReadNotifications(false);
  }

  async function changeFilterTab(filter) {
    set({ ...storageNotifications, activeTab: filter });
    setActiveTab(filter);
    loadNotifications({
      filters,
      activeTab: filter,
    });
  }

  function getHeightContainerScroll(filters) {
    const { customer = [], factory = [], assignedTo = [], dateRange } = filters;
    const existsUsers = assignedTo.length > 0 ? 1 : 0;
    const existsCustomer = customer.length > 0 ? 1 : 0;
    const existsFactory = factory.length > 0 ? 1 : 0;
    const existsDate = !dateRange ? 0 : 1;
    let totalResult = existsUsers + existsCustomer + existsFactory + existsDate;
    if (totalResult === 0) {
      return "calc(100vh - 303px)";
    } else if (totalResult <= 2) {
      return "calc(100vh - 350px)";
    } else {
      return "calc(100vh - 400px)";
    }
  }

  useEffect(() => {
    const notificationScrollContainer = document.getElementById(
      "notification-scroll-container"
    );
    if (notificationScrollContainer) {
      notificationScrollContainer.scrollTo({
        top: 0,
      });
    }
  }, [searchQuery]);

  function queryFiltered({ searchText, notifications, users }) {
    if (!searchText) return notifications;
    return notifications.filter((notification) => {
      return getNotificationText(
        {
          ...notification,
        },
        users
      )
        .replace(/<[^>]*>/gi, "")
        .replace(/\s+/g, " ")
        .trim()
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase());
    });
  }

  return (
    <div>
      {isLoading && <Loader />}
      <NotificationsHeader
        onGearClick={() => setSettingsAreOpen(!settingsAreOpen)}
        onChangeSearch={(searchTerm) => {
          setSearchQuery(searchTerm);
          set({ ...storageNotifications, searchQuery: searchTerm });
        }}
        searchQuery={searchQuery}
      />
      <NotificationsFilters
        activeTab={activeTab}
        changeFilterTab={changeFilterTab}
        filters={filters}
        changeFilters={onChangeFilters}
        onResetFilters={handleResetFilters}
        onFilterClick={() => {
          set({
            ...storageNotifications,
            storageFilterUnread: !unReadNotifications,
          });
          setUnReadNotifications(!unReadNotifications);
        }}
        onMarkClick={markAllRead}
        unReadNotifications={unReadNotifications}
      />
      {queryFiltered({
        searchText: searchQuery,
        notifications: filterNotifications({
          currentNotifications,
          filters,
        }),
        users,
      }).length === 0 ? (
        <div
          className={"noResultText"}
          style={{
            height: getHeightContainerScroll(filters),
            fontSize: 26,
            textAlign: "center",
            color: "#174176",
            padding: "36px 16px",
          }}
        >
          The current filter selection does not have any results
        </div>
      ) : (
        <div
          id="notification-scroll-container"
          style={{
            height: getHeightContainerScroll(filters),
            overflowY: "auto",
          }}
          ref={scrollerRef}
        >
          <NotificationsContent
            closeDrawer={() => {
              onClose();
            }}
            filteredNotifications={queryFiltered({
              searchText: searchQuery,
              notifications: filterNotifications({
                currentNotifications,
                filters,
              }),
              users,
            }).sort(sortObjectsBy("creationDate", true))}
            onToggle={onToggle}
            isAbleToModifyTaskDueDate={isAbleToModifyTaskDueDate}
            history={history}
            handleOpenModal={handleOpenModal}
            handleLoading={handleLoading}
            searchText={searchQuery}
          />
        </div>
      )}
    </div>
  );
}

export default NotificationsContainer;
