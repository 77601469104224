import React from "react";
import { collapsedTaskTableColumns, dayWidth } from "../../helpers/constants";
import { OptionsTableContainerPaperStyled } from "./styles";
import undoIcon from "../../assets/flag-icons/undo-filled-icon.png";
import ViewOptions from "./ViewOptions";
import Milestones from "./Milestones";
import TooltipTD from "../Tooltip/TooltipTD";
import { viewOptionLabels } from "../../helpers/timelineCalendar";
import { Button } from "@mui/material";

const optionsTable = {
  VIEW_OPTIONS: "VIEW_OPTIONS",
  VIEW_OPTIONS_COLLAPSED: "VIEW_OPTIONS_COLLAPSED",
  EXPAND_COLLAPSE_ALL_SECTIONS: "EXPAND_COLLAPSE_ALL_SECTIONS",
  UNDO: "UNDO",
  REDO: "REDO",
  MILESTONES: "MILESTONES",
};

function OptionsTableContainer({
  styles,
  columnIndex,
  tableSize,
  onChangeTableSize,
  viewOptionsArray,
  milestones,
  rowIndex,
  onChangeViewOptions,
  isOpenAllSections,
  onChangeOpenAllSection,
  canRedo,
  canUndo,
  undoTasksState,
  redoTasksState,
  onChangeMilestone,
  onDeleteMilestone,
  onAddNewMilestone,
  onCloseMilestone,
  flagViewOptions,
}) {
  const customStyles = { position: "absolute", zIndex: 1 };

  function getOptionContent({ key, columnIndex, rowIndex }) {
    switch (key) {
      case optionsTable.VIEW_OPTIONS:
        return (
          <div
            key={columnIndex + rowIndex}
            className="view-options"
            style={{ ...customStyles, width: dayWidth * 5 + 8, marginLeft: 9 }}
          >
            <ViewOptions
              viewOptionsArray={viewOptionsArray}
              tableSize={tableSize}
              onChangeViewOptions={onChangeViewOptions}
            />
          </div>
        );

      case optionsTable.VIEW_OPTIONS_COLLAPSED:
        return (
          <div
            key={columnIndex + rowIndex}
            className={"undoContainer"}
            style={{ ...customStyles, width: dayWidth * 3, border: "none" }}
          >
            <Button
              className={"undoContainer"}
              variant="contained"
              color="primary"
              onClick={undoTasksState}
              style={{
                width: dayWidth,
                display: "flex",
                justifyContent: "center",
                opacity: canUndo ? 1 : 0.6,
                minWidth: 27,
                border: "none",
              }}
            >
              <TooltipTD label={"Undo"}>
                <img
                  className="undo"
                  src={undoIcon}
                  alt="undo"
                  style={{ margin: "auto" }}
                  onClick={undoTasksState}
                />
              </TooltipTD>
            </Button>
            <Button
              className={"redoContainer"}
              variant="contained"
              color="primary"
              onClick={redoTasksState}
              style={{
                opacity: canRedo ? 1 : 0.6,
                minWidth: 27,
                width: dayWidth,
                border: "none",
              }}
            >
              <TooltipTD label={"Redo"}>
                <img
                  className="redo"
                  src={undoIcon}
                  alt="redo"
                  style={{
                    margin: "auto",
                    transform: "rotateY(180deg)",
                  }}
                  onClick={redoTasksState}
                />
              </TooltipTD>
            </Button>
          </div>
        );

      case optionsTable.EXPAND_COLLAPSE_ALL_SECTIONS:
        return (
          <div
            key={columnIndex + rowIndex}
            className="close-all-sections"
            style={{ ...customStyles, width: dayWidth * 4 + 17 }}
          >
            <Button
              className={"buttonViewOptions"}
              variant="contained"
              color="primary"
              onClick={() => onChangeOpenAllSection(!isOpenAllSections)}
            >
              {isOpenAllSections ? "Close all sections" : "Open all sections"}
            </Button>
          </div>
        );

      case optionsTable.UNDO:
        return (
          <div
            key={columnIndex + rowIndex}
            className={"undoAndRedoContainer"}
            style={{ ...customStyles, width: dayWidth * 5 }}
          >
            <Button
              className={"undoContainer"}
              variant="contained"
              color="primary"
              onClick={undoTasksState}
              style={{ opacity: canUndo ? 1 : 0.6 }}
            >
              <img
                className="undo"
                src={undoIcon}
                alt="undo"
                style={{ margin: "0px 4px" }}
              />
              <span>Undo</span>
            </Button>
            <Button
              className={"redoContainer"}
              variant="contained"
              color="primary"
              onClick={redoTasksState}
              style={{ opacity: canRedo ? 1 : 0.6 }}
            >
              <span>Redo</span>
              <img
                className="redo"
                src={undoIcon}
                alt="redo"
                style={{ transform: "rotateY(180deg)", margin: "0px 4px" }}
              />
            </Button>
          </div>
        );
      case optionsTable.MILESTONES:
        return (
          <div
            key={columnIndex + rowIndex}
            className="milestones"
            style={{
              ...customStyles,
              width: dayWidth * 5,
              padding: "0px 10px",
            }}
          >
            <Milestones
              milestones={milestones}
              tableSize={tableSize}
              onChangeMilestone={onChangeMilestone}
              onDeleteMilestone={onDeleteMilestone}
              onAddNewMilestone={onAddNewMilestone}
              onCloseMilestone={onCloseMilestone}
            />
          </div>
        );

      default:
        return null;
    }
  }

  function getFullOptionTableContainer({ columnIndex, rowIndex }) {
    if (rowIndex === 0) {
      switch (columnIndex) {
        case 0:
          return tableSize === collapsedTaskTableColumns
            ? getOptionContent({
                key: optionsTable.VIEW_OPTIONS_COLLAPSED,
                columnIndex,
                rowIndex,
              })
            : getOptionContent({
                key: optionsTable.VIEW_OPTIONS,
                columnIndex,
                rowIndex,
              });
        case 6:
          return getOptionContent({
            key: optionsTable.EXPAND_COLLAPSE_ALL_SECTIONS,
            columnIndex,
            rowIndex,
          });

        case 11:
          return getOptionContent({
            key: optionsTable.UNDO,
            columnIndex,
            rowIndex,
          });

        case 27:
          return getOptionContent({
            key: optionsTable.MILESTONES,
            columnIndex,
            rowIndex,
          });

        default:
          return null;
      }
    }
  }

  function getSimpleOptionTableContainer({ columnIndex, rowIndex }) {
    if (rowIndex === 0) {
      switch (columnIndex) {
        case 0:
          return tableSize === collapsedTaskTableColumns
            ? getOptionContent({
                key: optionsTable.VIEW_OPTIONS_COLLAPSED,
                columnIndex,
                rowIndex,
              })
            : getOptionContent({
                key: optionsTable.VIEW_OPTIONS,
                columnIndex,
                rowIndex,
              });
        case 6:
          return getOptionContent({
            key: optionsTable.EXPAND_COLLAPSE_ALL_SECTIONS,
            columnIndex,
            rowIndex,
          });

        case 11:
          return getOptionContent({
            key: optionsTable.UNDO,
            columnIndex,
            rowIndex,
          });

        case 16:
          return getOptionContent({
            key: optionsTable.MILESTONES,
            columnIndex,
            rowIndex,
          });

        default:
          return null;
      }
    }
  }

  function getCollapsedOptionTableContainer({ columnIndex, rowIndex }) {
    if (rowIndex === 0) {
      switch (columnIndex) {
        case 1:
          return getOptionContent({
            key: optionsTable.VIEW_OPTIONS_COLLAPSED,
            columnIndex,
            rowIndex,
          });

        default:
          return null;
      }
    } else {
      switch (columnIndex) {
        case 1:
          return (
            tableSize === collapsedTaskTableColumns && (
              <div
                key={columnIndex + rowIndex}
                className={"viewOptionsAndMilestonesContainer"}
                style={{ ...customStyles, width: dayWidth * 3 }}
              >
                <ViewOptions
                  viewOptionsArray={viewOptionsArray}
                  tableSize={tableSize}
                  styles={{
                    minWidth: dayWidth,
                    width: dayWidth,
                    border: "none",
                  }}
                  onChangeViewOptions={onChangeViewOptions}
                />
                <Milestones
                  milestones={milestones}
                  tableSize={tableSize}
                  styles={{
                    minWidth: dayWidth,
                    width: dayWidth,
                    border: "none",
                  }}
                  onChangeMilestone={onChangeMilestone}
                  onDeleteMilestone={onDeleteMilestone}
                  onAddNewMilestone={onAddNewMilestone}
                  onCloseMilestone={onCloseMilestone}
                />
              </div>
            )
          );
        default:
          return null;
      }
    }
  }

  return (
    <OptionsTableContainerPaperStyled
      className="optionsTableContainer"
      style={{
        ...styles,
      }}
      elevation={0}
    >
      {tableSize === collapsedTaskTableColumns
        ? getCollapsedOptionTableContainer({ columnIndex, rowIndex })
        : flagViewOptions[viewOptionLabels.FULL_TABLE_DETAILS]
        ? getFullOptionTableContainer({ columnIndex, rowIndex })
        : getSimpleOptionTableContainer({ columnIndex, rowIndex })}
    </OptionsTableContainerPaperStyled>
  );
}

export default OptionsTableContainer;
