import React from "react";
import IntlMessages from "../../util/IntlMessages";
import InfoIcon from "../../assets/flag-icons/info-icon.svg";
import {
  LockNoteIconModal,
  SendIcon,
  TrashCanIcon,
  UnlockNoteIcon,
} from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";

const LOCK_MODAL = "LOCK";

function FooterNoteSection({
  handleDelete,
  editingNote,
  hasMention,
  lockNote,
  setModalType,
  handleModalAtention,
  isEmpty,
  OS,
  handleClick,
}) {
  const getLockIcon = () => {
    if (lockNote) {
      return (
        <TooltipTD
          label={"Who can see this note"}
          style={{
            marginRight: 30,
          }}
        >
          <LockNoteIconModal
            onClick={() => {
              setModalType(LOCK_MODAL);
              handleModalAtention();
            }}
            style={{ cursor: "pointer" }}
            width={22}
            height={24}
          />
        </TooltipTD>
      );
    } else {
      return (
        <TooltipTD
          label={"Who can see this note"}
          style={{
            marginRight: 30,
          }}
        >
          <UnlockNoteIcon
            onClick={() => {
              setModalType(LOCK_MODAL);
              handleModalAtention();
            }}
            style={{ cursor: "pointer" }}
            width={22}
            height={24}
          />
        </TooltipTD>
      );
    }
  };
  return (
    <div className="section-bottom">
      {editingNote && (
        <div
          className="delete-note-section"
          style={{ cursor: "pointer" }}
          onClick={handleDelete}
        >
          <TrashCanIcon color={"#FF9494"} />
          <span
            style={{
              padding: "0px 4px",
              marginTop: "2px",
              marginLeft: "3px",
              color: "#FF9494",
            }}
          >
            DELETE NOTE
          </span>
        </div>
      )}
      <div className="section-info-container">
        <div className="info">
          <img
            src={InfoIcon}
            alt={"InfoIcon"}
            style={{
              color: "#B2CBEA",
              width: 20,
              height: 20,
              marginRight: 3,
              marginTop: -3,
            }}
          />
          <div>
            <span>- This note is editable for 6 seconds after submitting</span>
            <br />
            <span>
              - User's names in grey do not have permission to see this Sales
              Order/PO
            </span>
          </div>
        </div>
      </div>
      <div className="section-actions-buttons">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {hasMention && getLockIcon()}
          <button
            disabled={isEmpty()}
            onClick={handleClick}
            className="btn-submit"
            onKeyDown={(ev) => ev.preventDefault()}
            onKeyUp={(ev) => ev.preventDefault()}
          >
            {
              <IntlMessages id="components.purchaseorderdashboard.notessection.send" />
            }
            <SendIcon color={"white"} style={{ marginLeft: 16, width: 14 }} />
          </button>
        </div>
        <label className="label-description">
          <strong>{`${
            OS.includes("Windows") ? "Ctrl + Enter" : "⌘ + Return"
          }`}</strong>{" "}
          to send
        </label>
      </div>
    </div>
  );
}

export default FooterNoteSection;
