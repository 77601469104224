import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class ItemTable {
  constructor({
    id = getRandomId(),
    version = 0,
    companyId = "",
    creationDate = now(),
  }) {
    this.id = id;
    this.version = version;
    this.companyId = companyId;
    this.creationDate = creationDate;
  }
}
