import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import SidebarContent from "./SidebarContent";
import { cx } from "@emotion/css";

function Sidebar() {
  const settings = useSelector((state) => state.settings);
  const { isDarkSidenav } = settings;
  return (
    <Fragment>
      <div className={"rct-sidebar"}>
        <div
          className={cx("rct-sidebar-content", {
            "sidebar-overlay-dark": isDarkSidenav,
            "sidebar-overlay-light": !isDarkSidenav,
          })}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SidebarContent />
        </div>
      </div>
    </Fragment>
  );
}

export default Sidebar;
