import React, { useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import Xarrow from "react-xarrows";
import { typeOfTask } from "../../api/types/dbTables";
import { dayHeight, dayWidth, taskStatus } from "../../helpers/constants";
import {
  getReferenceTask,
  getTaskBackgroundColor,
  getTaskDiamond,
  viewOptionLabels,
} from "../../helpers/timelineCalendar";
import {
  getArrowConfiguration,
  getEndAnchor,
  getStartAnchor,
} from "../../helpers/timelineTaskContainer";
import { ResizableComponentStyled } from "./styles";

function ResizableComponent({
  task,
  onChangeTaskDuration,
  onDropTask,
  onHover,
  taskOnHover,
  predecesorTask,
  flagViewOptions,
  tasks,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const timer =
      isHovered &&
      setTimeout(() => {
        onHover({
          ...task,
          ids: [task.id],
          verticalIndicator: true,
          reference: getReferenceTask({ task, predecesorTask }),
        });
      }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isHovered]);

  function verifyShowArrow({ task, predecesorTask, tasks }) {
    if (!task || !task.id || !predecesorTask) {
      return false;
    }
    return setTimeout(() => {
      const dependencyElement = document.getElementById(predecesorTask.id);
      const currentElement = document.getElementById(task.id);
      if (!dependencyElement || !currentElement) {
        return false;
      }
      // let taskIndex = 0,
      //   predecesorTaskIndex = 0;
      // tasks
      //   .filter((task) => !task.isPhase)
      //   .every((el, index) => {
      //     if (el.id === task.id) {
      //       taskIndex = index;
      //       return false;
      //     } else if (el.id === predecesorTask.id) {
      //       predecesorTaskIndex = index;
      //     }
      //     return true;
      //   });
      // if (taskIndex - predecesorTaskIndex === 1) {
      //   if (
      //     moment(task.startDate)
      //       .startOf("day")
      //       .diff(moment(predecesorTask.finishDate).startOf("day"), "days") ===
      //     1
      //   ) {
      //     return false;
      //   }
      // }
      return true;
    }, 1500);
  }

  function getXArrows({ task, tasks, taskOnHover }) {
    const dependencyTasks = tasks.filter((el) => el.dependency === task.id);
    const taskElem = document.getElementById(task.id);
    const dependencyTaskElem = document.getElementById(task.dependency);
    let arrowConfiguration = getArrowConfiguration(task, predecesorTask);
    const {
      curveness = 2,
      // _cpx1Offset = 50,
      // _cpy1Offset = 100,
      // _cpx2Offset = 10,
      // _cpy2Offset = 10,
    } = arrowConfiguration;
    return (
      <React.Fragment>
        <Xarrow
          key={task.id}
          start={task.id}
          end={task.dependency}
          strokeWidth={
            taskOnHover.id === task.id || taskOnHover.id === task.dependency
              ? 2.5
              : 0.5
          }
          headSize={4}
          tailSize={4}
          startAnchor={getStartAnchor(task)}
          endAnchor={getEndAnchor(task)}
          showHead={false}
          showTail={true}
          curveness={curveness}
          _cpx1Offset={0}
          _cpx2Offset={0}
          _cpy1Offset={0}
          _cpy2Offset={0}
          zIndex={1000}
          showXarrow={taskElem && dependencyTaskElem}
        />

        {dependencyTasks.map((dTask) => {
          const taskElem = document.getElementById(task.id);
          const dependencyTaskElem = document.getElementById(dTask.id);

          let arrowConfiguration = getArrowConfiguration(dTask, task);
          const {
            curveness = 2,
            // _cpx1Offset = 50,
            // _cpy1Offset = 100,
            // _cpx2Offset = 10,
            // _cpy2Offset = 10,
          } = arrowConfiguration;
          return (
            <Xarrow
              key={task.id}
              start={dTask.id}
              end={task.id}
              strokeWidth={
                taskOnHover.id === task.id || taskOnHover.id === task.dependency
                  ? 2.5
                  : 0.5
              }
              headSize={4}
              tailSize={4}
              startAnchor={getStartAnchor(dTask)}
              endAnchor={getEndAnchor(dTask)}
              showHead={false}
              showTail={true}
              curveness={curveness}
              _cpx1Offset={0}
              _cpx2Offset={0}
              _cpy1Offset={0}
              _cpy2Offset={0}
              zIndex={1000}
              showXarrow={taskElem && dependencyTaskElem}
            />
          );
        })}
      </React.Fragment>
    );
  }

  function getTaskOnHoverBackground(taskOnHover, task) {
    if (taskOnHover && taskOnHover.ids.includes(task.id)) {
      return task.color;
    }
    return task.isAdHocTask
      ? "#B9B3B3"
      : getTaskBackgroundColor({ task, isWhite: true });
  }
  const enableResizing = task.status !== taskStatus.COMPLETE;

  return (
    <ResizableComponentStyled className="resizableContainer">
      <Rnd
        size={{ width: dayWidth * task.duration, height: dayHeight - 1 }}
        default={{
          x: 0,
          y: 0,
          width: dayWidth * task.duration,
          height: dayHeight,
        }}
        resizeGrid={[dayWidth, 1]}
        dragGrid={[dayWidth, 1]}
        dragAxis="x"
        enableResizing={{ left: enableResizing, right: enableResizing }}
        className={"rndContainer"}
        onResizeStop={(ev, dir, ref, delta) => {
          const offset = Math.round(delta.width / dayWidth);
          if (offset === 0) {
            return;
          }
          onChangeTaskDuration(task.id, offset, dir);
          return;
        }}
        onDragStop={(ev, data) => {
          const offset = Math.round(data.x / dayWidth);
          setIsDragging(false);
          if (offset === 0) {
            return;
          }
          onDropTask(task.id, offset);
          return;
        }}
        onDragStart={() => setIsDragging(true)}
        style={{
          background: getTaskOnHoverBackground(taskOnHover, task),
          opacity: task.status === taskStatus.COMPLETE ? 0.3 : 1,
          border: `1px solid ${task.color}`,
        }}
        disableDragging={task.status === taskStatus.COMPLETE}
        minWidth={dayWidth}
      >
        {isDragging ? (
          <span className="text-description">{task.description}</span>
        ) : (
          <div
            className={"taskContainerBox"}
            id={task.id}
            onMouseEnter={() => {
              setIsHovered(true);
              return;
            }}
            onMouseLeave={() => {
              setIsHovered(false);
              onHover({
                id: null,
                ids: [],
                verticalIndicator: false,
                top: 0,
                left: 0,
              });
            }}
          >
            {task.type === typeOfTask.PURCHASE_ORDER && (
              <span
                style={{
                  background: task.color,
                  width: 5,
                  height: dayHeight - 1,
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                }}
              />
            )}
            <span className="text-description">
              {flagViewOptions[viewOptionLabels.DIAMOND_IN_TASK_BARS] &&
                getTaskDiamond({
                  status: task.status,
                  styles: { height: 15, width: 10, margin: "0px 4px 0px 0px" },
                  isOutlined: true,
                })}
              {task.description}
            </span>
          </div>
        )}
      </Rnd>
      {flagViewOptions[viewOptionLabels.ARROWS] &&
        isHovered &&
        verifyShowArrow({ task, predecesorTask, tasks }) &&
        getXArrows({ task, tasks, taskOnHover })}
    </ResizableComponentStyled>
  );
}

export default ResizableComponent;
