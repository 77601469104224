import React, { useState, useEffect, useRef, useCallback } from "react";
import { OrderPhaseListContainer, OrderPhaseTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter, {
  BUTTON_FILTER_BOX_SHADOW,
} from "../../components/PurchaseOrderList/ButtonFilter";
import SearchBox from "../../components/SearchBox/SearchBox";

import {
  useCompanyId,
  useFavorites,
  useFavoritesList,
  useJSONLocalStorage,
} from "../../hooks";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import {
  checkSpecificPermissions,
  useIsAllowedFunction,
} from "../../hooks/permissions";
import moment from "moment";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import { debounce, getTheDate, sortObjectsBy } from "../../helpers/helpers";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";

import {
  SOPhaseHeadCells,
  POPhaseHeaderCells,
} from "../../components/Managers/TableHeaderProvider";
// import { trackEvent } from "../../helpers/analytics";
import {
  footerOrdersByPhasePOs,
  footerOrdersByPhaseSOs,
} from "../../components/Managers/FooterTableManager";
import FooterTable from "../../components/Tables/FooterTable";
import numeral from "numeral";
import { getToolTipArrayValue } from "../../helpers/purchaseOrderList";
import BasicPagination from "../../components/Tables/BasicPagination";
import {
  columnHeaderSetup,
  orderByPhaseSOSetup,
} from "../../helpers/screenHeaderSetup";
import {
  AUTOMATIC_SYSTEM_LABEL,
  generalPermissions,
  GENERAL_PERMISSION_VALUE,
  headerColumns,
  excludeElementsScreen,
} from "../../helpers/constants";
import { useUser, useUserCustomSetting } from "../../hooks/user";
import userTypes from "../../api/types/userTypes";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { useTags } from "../../hooks/tags";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import { getStatusAndDiamondStatus } from "../../helpers/screens";
import { firestore, performanceFirebase } from "../../firebase";
import AttentionModal from "../../components/Modal/AttentionModal";
import { doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { trace } from "firebase/performance";
import Mark from "mark.js";
import OrdersByPhaseTableRow from "./OrdersByPhaseTableRow";
import GroupBySalesOrder from "./GroupBySalesOrder";
import { useMediaQuery } from "@mui/material";
import { isEqual } from "lodash";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import { StarIcon } from "../../helpers/iconSvgPath";
import DiamondLegend from "../../components/LegendStatus/DiamondLegend";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import LateNearDueDiamondButtons from "../../components/PurchaseOrderList/LateNearDueDiamondButtons";

const dateFields = ["Created Date", "Document Date", "Order Ready Date"];
const dateGroupBySOFields = ["SO Ship Date"];
const BODY_ID = "orderByPhaseTableRow";

const minWidthPhases = {
  proposalQuoteMinWidh: 139,
  preProductionMinWidh: 134,
  productionMinWidh: 118,
  bookingTransitMinWidh: 156,
  paymentBillingMinWidh: 157,
};

function OrdersByPhase() {
  const dateRef = useRef(null);
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const factories = useFactories();
  const [data, setData] = useState([]);
  const isAllowed = useIsAllowedFunction();
  const [openDate, setOpenDate] = useState(false);
  const { get, set } = useJSONLocalStorage("PuchaseOrderPhaseScreenFilters");
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const dataLocalStorage = get() || {};
  const [filters, setFilters] = useState({
    customer: dataLocalStorage.customer || [],
    date: dataLocalStorage.date || "",
    dateRange: dataLocalStorage.dateRange || "",
    factory: dataLocalStorage.factory || [],
    late: !!dataLocalStorage.late,
    nearDue: !!dataLocalStorage.nearDue,
    tag: dataLocalStorage.tag || [],
    orderBy: dataLocalStorage.orderBy || "ASC",
    sortedColumn: dataLocalStorage.sortedColumn || "number",
    groupBySO: !!dataLocalStorage.groupBySO,
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const customers = useCustomers();
  const tags = useTags();
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const soFavoritesDB = useFavorites({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const soFavorites = useFavoritesList({
    dbTable: dbTables.SALES_ORDER_FAVORITES,
  });
  const poFavoritesDB = useFavorites({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const poFavorites = useFavoritesList({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });

  const [loading, setLoading] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const [totalData, setTotalData] = useState(0);
  const [totalAmountPOs, setTotalAmountPOs] = useState(
    numeral(0).format("$0,0.00")
  );
  const [totalAmountSOs, setTotalAmountSOs] = useState(
    numeral(0).format("$0,0.00")
  );
  const companyId = useCompanyId();
  const [SOTaskByPhasesAndPOs, setSOTaskByPhasesAndPOs] = useState({});
  const [headerStages, setHeaderStages] = useState(minWidthPhases);
  //IMPLEMENTATION FOR SORTING HEAD CELLS
  const [headerCells, setHeaderCells] = useState([]);
  //
  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
    }
  }

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  function changeButtonFilter(filter) {
    let newFilter = { ...filters, [filter]: !filters[filter] };
    if (filter === "groupBySO") {
      setExistsDate(false);
      newFilter = { ...newFilter, dateRange: "", date: "" };
    }
    setFilters({ ...newFilter });
    onDebounce();
  }

  useEffect(() => {
    setTotalAmountPOs(numeral(0).format("$0,0.00"));
    setTotalAmountSOs(numeral(0).format("$0,0.00"));
    setTotalData(0);
    setPage(1);
  }, []);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      setLoading(false);
      return;
    }

    set({
      ...filters,
      rowsPerPage,
    });
    if (!loading) {
      setLoading(true);
      getSOorPOwithTasks(1, rowsPerPage);
    }
  }, [callFunction]);

  useEffect(() => {
    const dataTable = data || [];
    let userHeaderCell = filters.groupBySO
      ? orderByPhaseSOSetup
      : userCustomSettings.orderByPhasePOSetup || [];
    if (filters.groupBySO) {
      userHeaderCell = userHeaderCell.map((header) => {
        const cellHeader = SOPhaseHeadCells[header.name];
        const style = cellHeader.styles;
        let widthSize = style.width;
        if (cellHeader.autoSize) {
          for (let i = 0; i < dataTable.length; i++) {
            const so = dataTable[i];
            let itemData = so[cellHeader.reference] || "";
            const itemSize =
              itemData.toString().length * 7.5 + cellHeader.autoSize;
            widthSize = widthSize > itemSize ? widthSize : itemSize;
          }
        }
        if (
          cellHeader[generalPermissions.SALES_ORDER_AMOUNT] &&
          !isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ) {
          return {
            ...header,
            ...cellHeader,
            headerWidth: widthSize,
            enabled:
              header.name === headerColumns.PROPOSAL_QUOTE
                ? getPOProposalQuoteHeader()
                : header.enabled,
            styles: {
              ...cellHeader.styles,
              width: widthSize,
            },
          };
        } else {
          return {
            ...header,
            ...cellHeader,
            styles: {
              ...cellHeader.styles,
              width: widthSize,
            },
            headerWidth: widthSize,
            enabled:
              header.name === headerColumns.PROPOSAL_QUOTE
                ? getPOProposalQuoteHeader()
                : header.enabled,
          };
        }
      });
    } else {
      userHeaderCell = userHeaderCell.filter(
        (header) => !!POPhaseHeaderCells[header.name]
      );
      userHeaderCell = userHeaderCell.map((header) => {
        const poHeader = POPhaseHeaderCells[header.name];
        const style = poHeader.styles;
        let widthSize = style.width || 0;
        if (poHeader.autoSize) {
          dataTable.forEach((so) => {
            let itemData = so[poHeader.reference] || "";
            const itemSize =
              itemData.toString().length * 7.5 + poHeader.autoSize;
            widthSize = widthSize > itemSize ? widthSize : itemSize;
          });
        }
        if (
          poHeader[generalPermissions.PURCHASE_ORDER_AMOUNT] &&
          !isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ) {
          return {
            ...header,
            ...poHeader,
            headerSize: widthSize,
            styles: {
              ...poHeader.styles,
              width: widthSize,
            },
          };
        }
        return {
          ...header,
          ...POPhaseHeaderCells[header.name],
          headerSize: widthSize,
          styles: {
            ...poHeader.styles,
            width: widthSize,
          },
        };
      });
    }
    if (!isEqual(userHeaderCell, headerCells)) {
      setHeaderCells(userHeaderCell);
    }
  }, [
    data,
    isAllowed(generalPermissions.SALES_ORDER_AMOUNT),
    isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT),
  ]);

  function getMaxTasksStages(SOTaskByPhases) {
    let proposal = 0;
    let preProduction = 0;
    let production = 0;
    let booking = 0;
    let payment = 0;
    Object.keys(SOTaskByPhases).forEach((key) => {
      const SOTaskByPhasesCpy = SOTaskByPhases[key]["SOTaskByPhases"];
      if (SOTaskByPhasesCpy.proposalTasks.length > proposal) {
        proposal = SOTaskByPhasesCpy.proposalTasks.length;
      }
      if (SOTaskByPhasesCpy.preProductionTasks.length > preProduction) {
        preProduction = SOTaskByPhasesCpy.preProductionTasks.length;
      }
      if (SOTaskByPhasesCpy.proposalTasks.length > production) {
        production = SOTaskByPhasesCpy.proposalTasks.length;
      }
      if (SOTaskByPhasesCpy.bookingTransitTasks.length > booking) {
        booking = SOTaskByPhasesCpy.bookingTransitTasks.length;
      }
      if (SOTaskByPhasesCpy.paymentBillingTasks.length > payment) {
        payment = SOTaskByPhasesCpy.paymentBillingTasks.length;
      }
      const purchaseOrderCpy = SOTaskByPhases[key]["purchaseOrders"];

      purchaseOrderCpy.forEach((purchaseOrder) => {
        if (purchaseOrder.proposalTasks.length > proposal) {
          proposal = purchaseOrder.proposalTasks.length;
        }
        if (purchaseOrder.preProductionTasks.length > preProduction) {
          preProduction = purchaseOrder.preProductionTasks.length;
        }
        if (purchaseOrder.productionTasks.length > production) {
          production = purchaseOrder.productionTasks.length;
        }
        if (purchaseOrder.bookingTransitTasks.length > booking) {
          booking = purchaseOrder.bookingTransitTasks.length;
        }
        if (purchaseOrder.paymentBillingTasks.length > payment) {
          payment = purchaseOrder.paymentBillingTasks.length;
        }
      });
    });
    return {
      proposal: proposal * 24 + 55,
      preProduction: preProduction * 24 + 55,
      production: production * 24 + 55,
      booking: booking * 24 + 55,
      payment: payment * 24 + 55,
    };
  }

  function handleRemoveChargedSPTaskAndPOs(salesOrderId) {
    const SOTaskByPhasesAndPOsCpy = { ...SOTaskByPhasesAndPOs };
    delete SOTaskByPhasesAndPOsCpy[salesOrderId];
    setSOTaskByPhasesAndPOs(SOTaskByPhasesAndPOsCpy);
    const { proposal, preProduction, production, booking, payment } =
      getMaxTasksStages({
        ...SOTaskByPhasesAndPOsCpy,
      });
    setHeaderStages({
      proposalQuoteMinWidh:
        proposal > minWidthPhases.proposalQuoteMinWidh
          ? proposal
          : minWidthPhases.proposalQuoteMinWidh,
      preProductionMinWidh:
        preProduction > minWidthPhases.preProductionMinWidh
          ? preProduction
          : minWidthPhases.preProductionMinWidh,
      productionMinWidh:
        production > minWidthPhases.productionMinWidh
          ? production
          : minWidthPhases.productionMinWidh,
      bookingTransitMinWidh:
        booking > minWidthPhases.bookingTransitMinWidh
          ? booking
          : minWidthPhases.bookingTransitMinWidh,
      paymentBillingMinWidh:
        payment > minWidthPhases.paymentBillingMinWidh
          ? payment
          : minWidthPhases.paymentBillingMinWidh,
    });
  }

  function getMaxTasksStagesPOs(purchaseOrders = []) {
    let proposal = 0;
    let preProduction = 0;
    let production = 0;
    let booking = 0;
    let payment = 0;

    purchaseOrders.forEach((purchaseOrder) => {
      if (purchaseOrder.proposalTasks.length > proposal) {
        proposal = purchaseOrder.proposalTasks.length;
      }
      if (purchaseOrder.preProductionTasks.length > preProduction) {
        preProduction = purchaseOrder.preProductionTasks.length;
      }
      if (purchaseOrder.productionTasks.length > production) {
        production = purchaseOrder.productionTasks.length;
      }
      if (purchaseOrder.bookingTransitTasks.length > booking) {
        booking = purchaseOrder.bookingTransitTasks.length;
      }
      if (purchaseOrder.paymentBillingTasks.length > payment) {
        payment = purchaseOrder.paymentBillingTasks.length;
      }
    });
    return {
      proposal: proposal * 24 + 55,
      preProduction: preProduction * 24 + 55,
      production: production * 24 + 55,
      booking: booking * 24 + 55,
      payment: payment * 24 + 55,
    };
  }

  function handleSetMaxStagesByPOs(purchaseOrders) {
    const { proposal, preProduction, production, booking, payment } =
      getMaxTasksStagesPOs(purchaseOrders);

    setHeaderStages({
      proposalQuoteMinWidh:
        proposal > headerStages.proposalQuoteMinWidh
          ? proposal
          : headerStages.proposalQuoteMinWidh,
      preProductionMinWidh:
        preProduction > headerStages.preProductionMinWidh
          ? preProduction
          : headerStages.preProductionMinWidh,
      productionMinWidh:
        production > headerStages.productionMinWidh
          ? production
          : headerStages.productionMinWidh,
      bookingTransitMinWidh:
        booking > headerStages.bookingTransitMinWidh
          ? booking
          : headerStages.bookingTransitMinWidh,
      paymentBillingMinWidh:
        payment > headerStages.paymentBillingMinWidh
          ? payment
          : headerStages.paymentBillingMinWidh,
    });
  }

  function handleUpdatePhasesWidth(
    SOTaskByPhases,
    purchaseOrders,
    salesOrderId
  ) {
    setSOTaskByPhasesAndPOs({
      ...SOTaskByPhasesAndPOs,
      [salesOrderId]: { SOTaskByPhases, purchaseOrders },
    });
    const { proposal, preProduction, production, booking, payment } =
      getMaxTasksStages({
        ...SOTaskByPhasesAndPOs,
        [salesOrderId]: { SOTaskByPhases, purchaseOrders },
      });

    setHeaderStages({
      proposalQuoteMinWidh:
        proposal > headerStages.proposalQuoteMinWidh
          ? proposal
          : headerStages.proposalQuoteMinWidh,
      preProductionMinWidh:
        preProduction > headerStages.preProductionMinWidh
          ? preProduction
          : headerStages.preProductionMinWidh,
      productionMinWidh:
        production > headerStages.productionMinWidh
          ? production
          : headerStages.productionMinWidh,
      bookingTransitMinWidh:
        booking > headerStages.bookingTransitMinWidh
          ? booking
          : headerStages.bookingTransitMinWidh,
      paymentBillingMinWidh:
        payment > headerStages.paymentBillingMinWidh
          ? payment
          : headerStages.paymentBillingMinWidh,
    });
  }

  async function getSOorPOwithTasks(page, rowsPerPage) {
    setPreviousFilter(filters);
    const traceInstance = trace(performanceFirebase, "phase_view_db_lookup");
    traceInstance.start();
    const { diamondStatus } = getStatusAndDiamondStatus({ filters });
    try {
      const functions = getFunctions();
      const orderByPhaseEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview2",
          params: "/orderbyphase",
          env: globalEnvironment,
        })
      );
      orderByPhaseEndpoint({
        filters: {
          ...filters,
          diamondStatus,
          dateRange: "",
          searchText: filters.query || "",
          limitFrom: (page - 1) * rowsPerPage,
          limitUntil: page * rowsPerPage,
          dateStart: filters.dateRange
            ? moment(filters.dateRange.start).valueOf()
            : "",
          dateEnd: filters.dateRange
            ? moment(filters.dateRange.end).valueOf()
            : "",
        },
        companyId,
        userId: user.id,
      }).then((result) => {
        const {
          purchaseOrders,
          totalData,
          totalAmountPOs,
          salesOrders,
          totalAmountSOs,
        } = result.data;

        setTotalData(totalData);
        if (filters.groupBySO) {
          setData(salesOrders);
          setTotalAmountSOs(numeral(totalAmountSOs).format("$0,0.00"));
        } else {
          setTotalAmountPOs(numeral(totalAmountPOs).format("$0,0.00"));
          setData(purchaseOrders);
          handleSetMaxStagesByPOs(purchaseOrders);
        }
        traceInstance.stop();
        setLoading(false);
      });
    } catch (error) {
      console.log("error", error);
      traceInstance.stop();
      setLoading(false);
    }
  }

  function filterList(list) {
    if (!list) {
      return;
    }
    let filteredList = [...list];
    if (filters.groupBySO) {
      filteredList = filteredList.filter(
        (item) =>
          user.role === userTypes.SUPER_ADMIN ||
          user.role === userTypes.TRADEDASH_EMPLOYEE ||
          checkSpecificPermissions({
            userPermissions: user.permissions,
            permissionToCheck: [
              item.customerId,
              GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
            ],
          })
      );
    } else {
      filteredList = filteredList.filter(
        (item) =>
          user.role === userTypes.SUPER_ADMIN ||
          user.role === userTypes.TRADEDASH_EMPLOYEE ||
          checkSpecificPermissions({
            userPermissions: user.permissions,
            permissionToCheck: [
              item.factoryId,
              GENERAL_PERMISSION_VALUE.ALL_VENDORS,
            ],
          })
      );
    }

    return filteredList;
  }

  function resetFilters() {
    const dateRange = {
      start: moment().subtract(1, "month").startOf("day").valueOf(),
      end: moment().add(60, "days").endOf("day").valueOf(),
    };
    setFilters({
      ...filters,
      week: filters.week,
      factory: [],
      customer: [],
      dateRange,
      date: "Created Date",
      query: "",
      groupBySO: false,
      late: false,
      favorite: false,
      nearDue: false,
      tag: [],
    });
    setExistsDate(true);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
  }

  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilters({ ...filters, date: field, dateRange: "" });
    setTimeout(() => setOpenDate(true), 50);
  }

  function getPOProposalQuoteHeader() {
    const userOBPPOSetup = userCustomSettings.orderByPhasePOSetup;
    const proposalColumn = userOBPPOSetup.find(
      (header) => header.name === headerColumns.PROPOSAL_QUOTE
    );
    return proposalColumn.enabled;
  }

  function renderItem(item, index) {
    if (!loading) {
      return (
        <GroupBySalesOrder
          key={item.id}
          item={item}
          index={index}
          customer={
            customers.find((customer) => customer.id === item.customerId)
              ? customers.find((customer) => customer.id === item.customerId)
                  .name
              : ""
          }
          favorites={soFavorites}
          user={user}
          onUpdatePhasesWidth={handleUpdatePhasesWidth}
          handleRemoveChargedSPTaskAndPOs={handleRemoveChargedSPTaskAndPOs}
          headerStages={headerStages}
          tags={tags}
          onUpdateItem={handleUpdateItem}
          headers={headerCells.sort(sortObjectsBy("index", false))}
          isThereProposalQuoteColumn={getPOProposalQuoteHeader()}
          filters={filters}
          data={data}
          onOpenAttentionModal={() => setOpenAttentionModal(true)}
          favoritesDB={soFavoritesDB}
          setInternalLoading={setInternalLoading}
        />
      );
    }
  }

  function handleChangePage(newPage) {
    setPage(newPage);
    setLoading(true);
    setCallFunction(false);
    getSOorPOwithTasks(newPage, rowsPerPage);
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    if (!loading) {
      setTimeout(() => performMark(filters.query), 150);
    }
  }, [loading, rowsPerPage, internalLoading]);

  function renderFooter(list = [], isAllowed = () => {}, renderFooterTable) {
    return (
      <React.Fragment>
        <FooterTable
          footer={
            filters.groupBySO
              ? footerOrdersByPhaseSOs(totalData, totalAmountSOs, isAllowed)
              : footerOrdersByPhasePOs(totalData, totalAmountPOs, isAllowed)
          }
          headerCells={headerCells.sort(sortObjectsBy("index", false))}
          isThereColumnSetting={filters.groupBySO ? false : true}
        />
      </React.Fragment>
    );
  }

  function handleRemoveFavorite(item) {
    if (filters.favorite) {
      const newPOfilter = data.filter((po) => po.id !== item.id);
      const totalAmount = Number(totalAmountPOs.replace(/[^0-9.-]+/g, ""));
      setTotalData(totalData - 1);
      setTotalAmountPOs(numeral(totalAmount - item.amount).format("$0,0.00"));
      handleSetMaxStagesByPOs(newPOfilter);
      setData(newPOfilter);
    }
  }

  function handleUpdateItem(item, tagReference) {
    let dataCpy = [...data];
    dataCpy = dataCpy.map((el) => {
      if (item.id === el.id) {
        return {
          ...item,
        };
      }
      return el;
    });
    setData(dataCpy);
  }

  function getTableHeader() {
    if (filters.groupBySO) {
      return headerCells.sort(sortObjectsBy("index", false)).map((cell) => {
        switch (cell.name) {
          case headerColumns.PROPOSAL_QUOTE:
            return {
              ...cell,
              styles: {
                ...cell.styles,
                width: headerStages.proposalQuoteMinWidh,
              },
            };
          case headerColumns.PRE_PRODUCTION:
            return {
              ...cell,
              styles: {
                ...cell.styles,
                width: headerStages.preProductionMinWidh,
              },
            };
          case headerColumns.PRODUCTION:
            return {
              ...cell,
              styles: {
                ...cell.styles,
                width: headerStages.productionMinWidh,
              },
            };
          case headerColumns.BOOKING_TRANSIT:
            return {
              ...cell,
              styles: {
                ...cell.styles,
                width: headerStages.bookingTransitMinWidh,
              },
            };
          case headerColumns.PAYMENT_BILLING:
            return {
              ...cell,
              styles: {
                ...cell.styles,
                width: headerStages.paymentBillingMinWidh,
              },
            };
          default:
            return cell;
        }
      });
    } else {
      return headerCells
        .filter((head) => head.selected !== false)
        .sort(sortObjectsBy("index", false))
        .map((cell) => {
          switch (cell.name) {
            case headerColumns.PROPOSAL_QUOTE:
              return {
                ...cell,
                styles: {
                  ...cell.styles,
                  width: headerStages.proposalQuoteMinWidh,
                },
              };
            case headerColumns.PRE_PRODUCTION:
              return {
                ...cell,
                styles: {
                  ...cell.styles,
                  width: headerStages.preProductionMinWidh,
                },
              };
            case headerColumns.PRODUCTION:
              return {
                ...cell,
                styles: {
                  ...cell.styles,
                  width: headerStages.productionMinWidh,
                },
              };
            case headerColumns.BOOKING_TRANSIT:
              return {
                ...cell,
                styles: {
                  ...cell.styles,
                  width: headerStages.bookingTransitMinWidh,
                },
              };
            case headerColumns.PAYMENT_BILLING:
              return {
                ...cell,
                styles: {
                  ...cell.styles,
                  width: headerStages.paymentBillingMinWidh,
                },
              };
            default:
              return cell;
          }
        });
    }
  }

  const clearFilterData = ({ filterKey, value = [] }) => {
    setFilters({ ...filters, [filterKey]: value });
    onDebounce();
  };

  const matches = useMediaQuery("(max-width:1425px)");
  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={
            <React.Fragment>
              You do not have access to the shipment documents folder for this
              shipment
            </React.Fragment>
          }
          onClose={() => setOpenAttentionModal(false)}
          onClick={() => setOpenAttentionModal(false)}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}

      <OrderPhaseListContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            currentFilters={filters}
            onOpenDatePicker={onOpenDatePicker}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            enableExport={false}
            dateFields={filters.groupBySO ? dateGroupBySOFields : dateFields}
            enabledFields={{
              customer: true,
              factory: true,
              date: true,
              tag: true,
            }}
            tagFilters={[
              AUTOMATIC_SYSTEM_LABEL.SHIPMENT_FILE,
              !filters.groupBySO ? AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_FILE : "",
            ]}
            filterData="name"
          />
          <SearchBox
            placeholder={
              filters.groupBySO
                ? "Search for SO #, Customers, PO # or Vendor"
                : "Search for PO # or Vendor"
            }
            value={filters.query}
            hasBeenReseted={(filters, value) => {
              clearFilterData({ filterKey: "query", value: "" });
            }}
            filters={filters}
            onDebounceValue={(filters, value) => {
              setFilters({ ...filters, query: value });
              setCallFunction(true);
            }}
          />
        </div>
        <div className="contentContainer">
          <DiamondLegend
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 8,
            }}
          />
          <div className="buttonsFilter">
            <ButtonFilter
              buttonId="list-view-button-filter-favorite"
              value={filters.favorite}
              onClick={() => changeButtonFilter("favorite")}
              className="smallBox"
              boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
            >
              <StarIcon />
            </ButtonFilter>
            <LateNearDueDiamondButtons
              filters={filters}
              changeButtonFilter={changeButtonFilter}
            />

            <ButtonFilter
              buttonId="list-view-button-filter-groupbyso"
              value={filters.groupBySO}
              onClick={() => {
                setCallFunction(true);
                changeButtonFilter("groupBySO");
              }}
              className="bigBox"
            >
              Group by Sales Order
            </ButtonFilter>

            {filters.customer && filters.customer.length > 0 && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-customer"
                onClear={() => clearFilterData({ filterKey: "customer" })}
                label={filters.customer.length === 1 ? "Customer" : "Customers"}
                tooltip={
                  getToolTipArrayValue(filters.customer, customers).tooltip
                }
                value={getToolTipArrayValue(filters.customer, customers).value}
                filters={filters}
              />
            )}
            {filters.factory && filters.factory.length > 0 && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-factory"
                onClear={() => clearFilterData({ filterKey: "factory" })}
                label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                tooltip={
                  getToolTipArrayValue(filters.factory, factories).tooltip
                }
                value={getToolTipArrayValue(filters.factory, factories).value}
                filters={filters}
              />
            )}
            {isAllowed("see_tags") && filters.tag && filters.tag.length > 0 && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-label"
                onClear={() => clearFilterData({ filterKey: "tag" })}
                label={filters.tag.length === 1 ? "Label" : "Labels"}
                tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                value={tags && getToolTipArrayValue(filters.tag, tags).value}
                filters={filters}
              />
            )}

            {existsDate && (
              <React.Fragment>
                <div
                  onClick={() => {
                    setOpenDate(true);
                    setOpenList(true);
                  }}
                  ref={dateRef}
                >
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-date"
                    onClear={() => {
                      setExistsDate(false);
                      setFilters({
                        ...filters,
                        date: "",
                        dateRange: { start: new Date(), end: new Date() },
                      });
                      setOpenDate(false);
                      setOpenList(false);
                      onDebounce();
                    }}
                    label={filters.date}
                    filters={filters}
                    value={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                  />
                </div>
                {openDate && (
                  <DateRangePicker
                    open={openDate}
                    el={dateRef.current}
                    onClose={handleCloseDatePicker}
                    onChange={changeDate}
                    value={filters.dateRange}
                    cancelIcon={true}
                  />
                )}
              </React.Fragment>
            )}
            <ButtonReset
              id="list-view-button-filter-reset"
              className="root"
              onClick={resetFilters}
            >
              {matches ? "RESET" : "RESET FILTERS"}
            </ButtonReset>
          </div>
          <div className="contentSettings">
            {!filters.groupBySO && (
              <MultipleSelect
                items={getTableHeader().sort(sortObjectsBy("index", false))}
                onChange={setHeaderCells}
                onClose={() => {
                  let newItemsCpy = [...headerCells];
                  newItemsCpy = newItemsCpy.map((item) => ({
                    enabled: item.enabled,
                    index: item.index,
                    name: item.name,
                    unmovable: item.unmovable || false,
                  }));
                  updateDoc(
                    doc(
                      firestore,
                      `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
                    ),
                    {
                      orderByPhasePOSetup: newItemsCpy,
                    }
                  );
                }}
                type={columnHeaderSetup.ORDER_BY_PHASE_PO_SETUP}
              />
            )}
          </div>

          <OrderPhaseTableContainer className="orderPhaseList">
            <div className="tableContainer" id={BODY_ID}>
              {filters.groupBySO && (
                <MainTableRefactor
                  isThereColumnSetting={false}
                  headCells={getTableHeader()}
                  bodyRows={filterList(data)}
                  renderRow={renderItem}
                  footer={renderFooter(filterList(data), isAllowed, true)}
                  filters={filters}
                  onUpdateFilters={(filters) => {
                    setFilters({ ...filters });
                    onDebounce();
                  }}
                />
              )}
              {!filters.groupBySO && (
                <MainTableRefactor
                  isThereColumnSetting={true}
                  onChange={(newItems) => {
                    setHeaderCells(newItems);
                  }}
                  headCells={getTableHeader()}
                  bodyRows={filterList(data)}
                  renderRow={(item, index) => (
                    <OrdersByPhaseTableRow
                      key={item.id + index}
                      item={item}
                      filters={filters}
                      favorites={poFavorites}
                      handleRemoveFavorite={handleRemoveFavorite}
                      headerStages={headerStages}
                      headers={headerCells.sort(sortObjectsBy("index", false))}
                      tags={tags}
                      onUpdateItem={handleUpdateItem}
                      isThereColumnSetting={!filters.groupBySO && !loading}
                      favoritesDB={poFavoritesDB}
                    />
                  )}
                  footer={renderFooter(filterList(data), isAllowed, true)}
                  filters={filters}
                  onUpdateFilters={(filters) => {
                    setFilters({ ...filters });
                    onDebounce();
                  }}
                />
              )}
            </div>
            <BasicPagination
              rowsPerPage={rowsPerPage}
              page={page}
              count={getCountPagination(totalData, rowsPerPage)}
              onChangePagination={(event, newPage) => {
                handleChangePage(newPage);
              }}
              onChangeRowsPerPage={(event) => {
                const valuePage = parseInt(event.target.value);
                setRowsPerPage(valuePage);
                setPage(1);
                setLoading(true);
                getSOorPOwithTasks(1, valuePage);
              }}
            />
          </OrderPhaseTableContainer>
        </div>
      </OrderPhaseListContainer>
    </React.Fragment>
  );

  function getCountPagination(totalData, rowsPerPage) {
    return Math.ceil(totalData / rowsPerPage);
  }
}

export default OrdersByPhase;
