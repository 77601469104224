import React from "react";
import MenuOptions from "./MenuOptions";
import menuOptionsIcon from "../../assets/flag-icons/menu-options-icon.png";

function MenuSelector({
  onOpenContentAndAddFolder,
  companyID,
  mainTable,
  mainTableID,
  dbTable,
  onUploadFiles,
  handleRename,
  onPermission,
  handleDeleteFolder,
  currentDocument,
  iconUploadNewVersionFile,
  handleEnableRightClick,
  selectedDocumentTab,
  disabled,
  currentUser,
  onOpenSnackBar,
  documentFlat,
  onShowModal,
  permissionGroupsDB,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  companyUsers,
  isOrderVoided,
}) {
  return (
    <React.Fragment>
      <div
        id="img-menu-options-container"
        className={"dotsImage"}
        onClick={(ev) => handleEnableRightClick(ev)}
      >
        <img
          className="img-menu-options"
          src={menuOptionsIcon}
          alt={"options"}
        />
      </div>
      <MenuOptions
        disabled={disabled}
        onAddNewFolder={onOpenContentAndAddFolder}
        onUploadFiles={onUploadFiles}
        onRename={handleRename}
        onPermission={onPermission}
        onDeleteEvent={handleDeleteFolder}
        currentDocument={currentDocument}
        iconUploadNewVersionFile={iconUploadNewVersionFile}
        type="child"
        companyID={companyID}
        mainTable={mainTable}
        mainTableID={mainTableID}
        dbTable={dbTable}
        selectedDocumentTab={selectedDocumentTab}
        currentUser={currentUser}
        onOpenSnackBar={onOpenSnackBar}
        calledByMenuOptions={true}
        documentFlat={documentFlat}
        onShowModal={onShowModal}
        permissionGroupsDB={permissionGroupsDB}
        isReadOnly={isReadOnly}
        handleReadOnlyModal={handleReadOnlyModal}
        companyUsers={companyUsers}
        isOrderVoided={isOrderVoided}
      />
    </React.Fragment>
  );
}

export default MenuSelector;
