import User from "../api/model/user";
import PermissionGroup from "../api/model/permissionGroup";
import Customer from "../api/model/customer";
import Factory from "../api/model/factory";
import File from "../api/model/file";
import Role from "../api/model/Role";
import { getRandomId } from "./helpers";
import TaskSpreadSheet from "../api/model/TaskSpreadSheet";
import SalesOrder from "../api/model/salesOrder";
import moment from "moment";
import PurchaseOrder from "../api/model/purchaseOrder";
import Activity from "../api/model/activity";
import Tag from "../api/model/tag";
import { dbTables, typeOfTask } from "../api/types/dbTables";
import Shipment from "../api/model/Shipment";
import Item from "../api/model/Item";
import numeral from "numeral";
import taskStages from "../api/types/taskStages";
import { firestore } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

function getVendorsIds(enabled, factories) {
  if (enabled === "Yes") {
    const factoryIds = {};
    factories.forEach((factory) => (factoryIds[factory.id] = true));
    return factoryIds;
  }
  return {};
}

function getCustomersIds(enabled, customers) {
  if (enabled === "Yes") {
    const customerIds = {};
    customers.forEach((customer) => (customerIds[customer.id] = true));
    return customerIds;
  }
  return {};
}

export async function buildUsersSpreadSheetIntoObject({
  users,
  factories,
  customers,
}) {
  let usersDB = [];
  users.forEach((user, index) => {
    const vendorPermissions = getVendorsIds(user[7], factories);
    const customerPermissions = getCustomersIds(user[8], customers);

    if (index !== 0) {
      usersDB.push({
        ...new User({
          id: getRandomId(),
          firstName: user[0],
          lastName: user[1],
          displayName: user[2],
          cellPhone: "+" + user[3],
          email: user[4],
          avatar: user[6],
          permissions: { ...vendorPermissions, ...customerPermissions },
        }),
      });
    }
  });
  console.log("USER DB", usersDB);
  const isDuplicateDisplayName = verifyDuplicate(usersDB, "displayName");
  const isDuplicateEmail = verifyDuplicate(usersDB, "email");
  const isDuplicateCellPhone = verifyDuplicate(usersDB, "cellPhone");
  const { existPhoneNumber, phoneNumbers } = await verifyPhoneNumberExist(
    usersDB
  );
  if (isDuplicateDisplayName) {
    return { status: 400, message: "Duplicated user names" };
  } else if (isDuplicateEmail) {
    return { status: 400, message: "Duplicated user email" };
  } else if (isDuplicateCellPhone) {
    return { status: 400, message: "Duplicated user cellphone" };
  } else if (existPhoneNumber) {
    return {
      status: 400,
      message: `${phoneNumbers.length === 1 ? "This" : "These"} phone ${
        phoneNumbers.length === 1 ? "number" : "numbers"
      } already exist in database: ${phoneNumbers.join(", ")}`,
    };
  } else {
    return { status: 200, message: "Success", usersDB };
  }
}

export function buildPermissionGroupsSpreadSheetIntoObject(
  permissionGroups,
  usersDB
) {
  let permissionGroupsDB = [];
  let userNotFound = 0;
  permissionGroups.forEach((permissionGroup, index) => {
    const id = getRandomId();
    if (index !== 0) {
      let users = {};
      const arrayNameUsers = permissionGroup[1].split(",");
      arrayNameUsers.forEach((displayName) => {
        const user = usersDB.find((user) => user.displayName === displayName);
        if (user) {
          users[user.id] = true;
          usersDB.forEach((userDB) => {
            if (userDB.id === user.id) {
              const permissionGroupsCpy = userDB.permissionGroups
                ? userDB.permissionGroups
                : {};
              permissionGroupsCpy[id] = true;
              userDB["permissionGroups"] = permissionGroupsCpy;
            }
          });
        } else {
          userNotFound++;
        }
      });
      permissionGroupsDB.push({
        ...new PermissionGroup({ id, name: permissionGroup[0], users }),
      });
    }
  });
  const isDuplicateName = verifyDuplicate(permissionGroupsDB, "name");
  if (userNotFound > 0) {
    return {
      statusPermissionGroups: 400,
      messagePermissionGroups:
        "Permission group does not contain a exists user",
    };
  } else if (isDuplicateName) {
    return {
      statusPermissionGroups: 400,
      messagePermissionGroups: "Duplicate permission group name",
    };
  } else {
    return {
      statusPermissionGroups: 200,
      messagePermissionGroups: "Success",
      permissionGroupsDB,
    };
  }
}

export function buildCustomersSpreadSheetIntoObject(customers) {
  const customersDB = [];
  customers.forEach((customer, index) => {
    if (index !== 0) {
      customersDB.push({
        ...new Customer({
          id: getRandomId(),
          number: customer[0],
          name: customer[1],
        }),
      });
    }
  });
  const isDuplicateName = verifyDuplicate(customersDB, "name");
  if (isDuplicateName) {
    return {
      statusCustomers: 400,
      messageCustomers: "Duplicate customer name",
    };
  } else {
    return {
      statusCustomers: 200,
      messageCustomers: "Success",
      customersDB,
    };
  }
}

export function buildFactoriesSpreadSheetIntoObject(factories) {
  const factoriesDB = [];
  factories.forEach((factory, index) => {
    if (index !== 0) {
      factoriesDB.push({
        ...new Factory({
          id: getRandomId(),
          number: factory[0],
          name: factory[1],
        }),
      });
    }
  });
  const isDuplicateName = verifyDuplicate(factoriesDB, "name");
  if (isDuplicateName) {
    return {
      statusFactories: 400,
      messageFactories: "Duplicate factory name",
    };
  } else {
    return {
      statusFactories: 200,
      messageFactories: "Success",
      factoriesDB,
    };
  }
}

function getDocumentId(document, documentTemplateDB) {
  const arrayDocumentName = document[2].split("/");
  const documentName = arrayDocumentName[arrayDocumentName.length - 1];
  const documentDB = documentTemplateDB.find(
    (document) => document.name === documentName
  );
  if (documentDB) {
    return documentDB.id;
  } else {
    return "";
  }
}

function getPermissionGroups(document, permissionGroupsDB) {
  let permissionNotFound = 0;
  const arrayPermissionGroupsName = document[3].split(",");
  const permissionGroups = {};
  arrayPermissionGroupsName.forEach((permissionName) => {
    const permissionGroupDB = permissionGroupsDB.find(
      (permission) => permission.name === permissionName
    );
    if (permissionGroupDB) {
      permissionGroups[permissionGroupDB.id] = true;
    } else {
      permissionNotFound++;
    }
  });

  return { permissionGroups, permissionNotFound };
}

export function buildDocumentTemplateSpreadSheetIntoObject(
  documentTemplate,
  permissionGroupsDB
) {
  const SODocumentTemplateDB = [];
  const PODocumentTemplateDB = [];
  const shipmentDocumentTemplateDB = [];
  let permissionNotFound = 0;
  documentTemplate.forEach((document, index) => {
    if (index !== 0) {
      const {
        permissionGroups,
        permissionNotFound: notFound,
      } = getPermissionGroups(document, permissionGroupsDB);
      permissionNotFound += parseInt(notFound);
      if (document[4] === "SO") {
        SODocumentTemplateDB.push({
          ...new File({
            id: getRandomId(),
            name: document[1],
            parent: document[2],
            type: document[0],
            path: document[2] ? document[2] + "/" + document[1] : document[1],
            parentId:
              document[2] !== ""
                ? getDocumentId(document, SODocumentTemplateDB)
                : "",
            mainFile: document[2] === "" ? true : false,
            permissionGroups,
          }),
        });
      } else if (document[4] === "PO") {
        PODocumentTemplateDB.push({
          ...new File({
            id: getRandomId(),
            name: document[1],
            parent: document[2],
            type: document[0],
            path: document[2] ? document[2] + "/" + document[1] : document[1],
            parentId:
              document[2] !== ""
                ? getDocumentId(document, PODocumentTemplateDB)
                : "",
            mainFile: document[2] === "" ? true : false,
            permissionGroups,
          }),
        });
      } else {
        shipmentDocumentTemplateDB.push({
          ...new File({
            id: getRandomId(),
            name: document[1],
            parent: document[2],
            type: document[0],
            path: document[2] ? document[2] + "/" + document[1] : document[1],
            parentId:
              document[2] !== ""
                ? getDocumentId(document, shipmentDocumentTemplateDB)
                : "",
            mainFile: document[2] === "" ? true : false,
            permissionGroups,
          }),
        });
      }
    }
  });
  if (permissionNotFound > 0) {
    return {
      statusDocumentTemplate: 400,
      messageDocumentTemplate:
        "Permission name not found in Document placeholder spreadsheet",
    };
  } else {
    return {
      statusDocumentTemplate: 200,
      messageDocumentTemplate: "Success",
      SODocumentTemplateDB,
      PODocumentTemplateDB,
      shipmentDocumentTemplateDB,
    };
  }
}

export function buildRoleSpreadSheetIntoObject(roles, usersDB) {
  const rolesDB = [];
  let userNotFound = 0;
  let superAdmin = {};
  roles.forEach((role, index) => {
    if (index !== 0) {
      const arrayDisplayNameUsers = role[1] ? role[1].split(",") : [];
      arrayDisplayNameUsers.forEach((displayName) => {
        const userDB = usersDB.find((user) => user.displayName === displayName);
        if (userDB) {
          usersDB.forEach((user) => {
            if (user.displayName === displayName) {
              return (user.role = role[0]);
            }
          });
        } else {
          userNotFound++;
        }
      });
      if (role[0] === "SuperAdmin") {
        superAdmin = usersDB.find((userDB) => userDB.displayName === role[1]);
      } else {
        rolesDB.push({ ...new Role({ name: role[0] }), enabled: role[2] });
      }
    }
  });
  if (userNotFound > 0) {
    return {
      statusRole: 400,
      messageRole: "User not found in Role spread sheet",
    };
  } else {
    return {
      statusRole: 200,
      messageRole: "Success",
      rolesDB,
      usersDB,
      superAdmin,
    };
  }
}

const templatePhase = {
  "Proposal/Quote": taskStages.PROPOSAL,
  "Pre-production": taskStages.PRE_PRODUCTION,
  Production: taskStages.PRODUCTION,
  "Booking & Transit": taskStages.BOOKING_TRANSIT,
  "Payment & Billing": taskStages.PAYMENT_BILLING,
};

export function buildSOTemplateSpreadSheetIntoObject(SOTemplate, usersDB) {
  let SOTaskTemplateSpreadSheetDB = [];
  let userNotFound = 0;
  SOTemplate.forEach((task, index) => {
    if (index !== 0) {
      const user = usersDB.find((user) => user.displayName === task[8]);
      if (user) {
        SOTaskTemplateSpreadSheetDB.push({
          ...new TaskSpreadSheet({
            taskNumber: task[0],
            type: typeOfTask.SALES_ORDER,
            stage: templatePhase[task[2]],
            description: task[3],
            dependency: task[4],
            dependencyType: task[5],
            duration: parseInt(task[6]),
            offset: parseInt(task[7]) || 0,
            listIndex: parseInt(task[9]),
            assignedTo: user.id,
            number: index,
          }),
        });
      } else {
        userNotFound++;
      }
    }
  });
  if (userNotFound > 0) {
    return {
      statusSOTemplate: 400,
      messageSOTemplate: "User not found in SO Template spread sheet",
    };
  } else {
    let SOTaskPredecesorNotFound = 0;
    SOTaskTemplateSpreadSheetDB = SOTaskTemplateSpreadSheetDB.map(
      (SOTask, index) => {
        if (index === 0) {
          return SOTask;
        } else {
          const dependencyTask = SOTaskTemplateSpreadSheetDB.find(
            (task) => task.taskNumber === SOTask.dependency
          );
          if (dependencyTask) {
            return { ...SOTask, dependency: dependencyTask.id };
          } else {
            SOTaskPredecesorNotFound++;
            return SOTask;
          }
        }
      }
    );
    if (SOTaskPredecesorNotFound > 0) {
      return {
        statusSOTemplate: 400,
        messageSOTemplate:
          "SO Template Task not found a predecesor in SO Template spread sheet",
      };
    } else {
      return {
        statusSOTemplate: 200,
        messageSOTemplate: "Success",
        SOTaskTemplateSpreadSheetDB,
      };
    }
  }
}

export function buildPOTemplateSpreadSheetIntoObject(
  POTemplate,
  SOTemplate,
  usersDB
) {
  let POTaskTemplateSpreadSheetDB = [];
  let userNotFound = 0;
  POTemplate.forEach((task, index) => {
    if (index !== 0) {
      const user = usersDB.find((user) => user.displayName === task[8]);
      if (user) {
        POTaskTemplateSpreadSheetDB.push({
          ...new TaskSpreadSheet({
            taskNumber: task[0],
            type:
              task[1] === "TRUE"
                ? typeOfTask.SHIPMENT
                : typeOfTask.PURCHASE_ORDER,
            stage: templatePhase[task[2]],
            description: task[3],
            dependency: task[4],
            dependencyType: task[5],
            duration: parseInt(task[6]),
            offset: parseInt(task[7]) || 0,
            listIndex: parseInt(task[9]),
            assignedTo: user.id,
            dependsOnSOT: task[4].includes("S") ? true : false,
            number: index,
          }),
        });
      } else {
        userNotFound++;
      }
    }
  });
  if (userNotFound > 0) {
    return {
      statusPOTemplate: 400,
      messagePOTemplate: "User not found in PO Template spread sheet",
    };
  } else {
    let SOTaskPredecesorNotFound = 0;
    let POTaskPredecesorNotFound = 0;
    POTaskTemplateSpreadSheetDB = POTaskTemplateSpreadSheetDB.map(
      (POTask, index) => {
        if (POTask.dependency.includes("S")) {
          const dependencyTask = SOTemplate.find(
            (task) => task.taskNumber === POTask.dependency
          );
          if (dependencyTask) {
            return { ...POTask, dependency: dependencyTask.id };
          } else {
            SOTaskPredecesorNotFound++;
            return POTask;
          }
        } else {
          const dependencyTask = POTaskTemplateSpreadSheetDB.find(
            (task) => task.taskNumber === POTask.dependency
          );
          if (dependencyTask) {
            return { ...POTask, dependency: dependencyTask.id };
          } else {
            POTaskPredecesorNotFound++;
            return POTask;
          }
        }
      }
    );
    if (SOTaskPredecesorNotFound > 0) {
      return {
        statusPOTemplate: 400,
        messagePOTemplate:
          "PO Template Task not found a Sales Order predecesor in PO Template spread sheet",
      };
    } else if (POTaskPredecesorNotFound > 0) {
      return {
        statusPOTemplate: 400,
        messagePOTemplate:
          "PO Template Task not found a PO predecesor in PO Template spread sheet",
      };
    } else {
      return {
        statusPOTemplate: 200,
        messagePOTemplate: "Success",
        POTaskTemplateSpreadSheetDB,
      };
    }
  }
}

//
export function buildTagsSpreadSheetIntoObject(tags, usersDB) {
  let tagsSpreadSheetDB = [];
  let userNotFound = 0;
  tags.forEach((tag, index) => {
    if (index !== 0) {
      const user = usersDB.find((user) => user.displayName === tag[1]);
      if (user) {
        tagsSpreadSheetDB.push({
          ...new Tag({
            name: tag[0],
            createdBy: user.id,
          }),
        });
      } else {
        userNotFound++;
      }
    }
  });
  console.log("TAGS SCPREAD SHEET.....", tagsSpreadSheetDB);
  if (userNotFound > 0) {
    return {
      statusTags: 400,
      messageTags: "User not found in tags spread sheet",
    };
  } else {
    return {
      statusTags: 200,
      messageTags: "Success",
      tagsSpreadSheetDB,
    };
  }
}
//

function getAmount(amount) {
  if (amount === 0 || !amount) {
    return "0.00";
  } else {
    const amountArr = amount.split(",");
    let newValue = "";
    amountArr.forEach((val) => (newValue += val));
    return parseFloat(newValue);
  }
}

function getTagIds(tagNames, tags) {
  const tagIds = [];
  let tagNotFoundCpy = 0;
  if (tagNames) {
    const tagNamesArr = tagNames.split(",");
    tagNamesArr.forEach((val) => {
      const tag = tags.find((tag) => tag.name.trim() === val.trim());
      if (tag) {
        tagIds.push(tag.id);
      } else {
        tagNotFoundCpy++;
      }
    });
    return {
      tagNotFoundCpy,
      tagIds,
    };
  }
  return {
    tagNotFoundCpy: 0,
    tagIds: [],
  };
}

export function buildSOSpreadSheetIntoObject({
  SOSpreadSheet,
  customersDB,
  userDB,
  tags,
}) {
  const SOSpreadSheetDB = [];
  let customerNotFound = 0;
  let tagNotFound = 0;
  SOSpreadSheet.forEach((so, index) => {
    if (index !== 0) {
      const customer = customersDB.find((customer) => customer.name === so[1]);
      const { tagNotFoundCpy, tagIds } = getTagIds(so[8], tags);
      tagNotFound += tagNotFoundCpy;
      if (customer) {
        SOSpreadSheetDB.push({
          ...new SalesOrder({
            number: so[0],
            customerId: customer.id,
            date: moment(so[2]).valueOf(),
            shipDate: moment(so[3]).valueOf(),
            shipDates: getDate(userDB.id, so[3], "shipDate"),
            salesRepresentative: so[4],
            invoice: so[5],
            deposit: getAmount(so[6]),
            potentialLateFee: getAmount(so[7]),
            tags: tagIds,
          }),
          taskCompleted: parseInt(so[9]),
        });
      } else {
        customerNotFound++;
      }
    }
  });
  if (customerNotFound > 0) {
    return {
      statusSO: 400,
      messageSO: "Customer not found in Sales Order spread sheet",
    };
  } else if (tagNotFound > 0) {
    return {
      statusSO: 400,
      messageSO: "Tag not found in Sales Order spread sheet",
    };
  } else {
    return {
      statusSO: 200,
      messageSO: "Success",
      SOSpreadSheetDB,
    };
  }
}

export function buildPOSpreadSheetIntoObject({
  POSpreadSheet,
  factoriesDB,
  SOSpreadSheetDB,
  userDB,
  tags,
}) {
  const POSpreadSheetDB = [];
  let factoriesNotFound = 0;
  let SOparentNotFound = 0;
  let tagNotFound = 0;
  POSpreadSheet.forEach((po, index) => {
    if (index !== 0) {
      const SOParent = SOSpreadSheetDB.find((so) => so.number === po[1]);
      const factory = factoriesDB.find((factory) => factory.name === po[2]);
      const { tagNotFoundCpy, tagIds } = getTagIds(po[9], tags);
      tagNotFound += tagNotFoundCpy;

      if (factory && SOParent) {
        POSpreadSheetDB.push({
          ...new PurchaseOrder({
            number: po[0],
            salesOrderIds: [SOParent.id],
            factoryId: factory.id,
            date: moment(po[3]).valueOf(),
            shippingTerms: po[4],
            potentialLateFee: getAmount(po[5]),
            deposit: getAmount(po[6]),
            invoice: po[7],
            tags: tagIds,
          }),
          taskCompleted: parseInt(po[10]),
        });
      } else if (!factory) {
        factoriesNotFound++;
      } else if (!SOParent) {
        SOparentNotFound++;
      }
    }
  });
  if (factoriesNotFound > 0) {
    return {
      statusPO: 400,
      messagePO: "Factory not found in POs spread sheet",
    };
  } else if (SOparentNotFound > 0) {
    return {
      statusPO: 400,
      messagePO: "Sales Order parent not found in POs spread sheet",
    };
  } else if (tagNotFound) {
    return {
      statusPO: 400,
      messagePO: "Tag not found in POs spread sheet",
    };
  } else {
    return {
      statusPO: 200,
      messagePO: "Success",
      POSpreadSheetDB,
    };
  }
}

export function buildActivityStreamSpreadSheetIntoSOandPO(
  ActivityStreampreadSheet,
  SOSpreadSheetDB,
  POSpreadSheetDB,
  usersDB
) {
  const SOActivityStreamDB = [];
  const POActivityStreamDB = [];
  let SONumberNotFound = 0;
  let PONumerNotFound = 0;
  let userNotFound = 0;

  ActivityStreampreadSheet.forEach((activity, index) => {
    if (index !== 0) {
      if (activity.length === 0 || activity[0] === "") {
        return;
      }
      if (!activity[1] || activity[1] === "") {
        const salesOrder = SOSpreadSheetDB.find(
          (so) => so.number === activity[0]
        );
        const user = usersDB.find((user) => user.displayName === activity[2]);
        if (salesOrder && user) {
          SOActivityStreamDB.push({
            ...new Activity({
              salesOrderId: salesOrder.id,
              user: user.id,
              creationDate: parseInt(activity[3]),
              type: activity[4],
              detail: activity[5],
              scope: "SALES_ORDER",
            }),
          });
        } else if (!salesOrder) {
          SONumberNotFound++;
        } else if (!user) {
          userNotFound++;
        }
      } else {
        const salesOrder = SOSpreadSheetDB.find(
          (so) => so.number === activity[0]
        );
        const purchaseOrder = POSpreadSheetDB.find(
          (po) => po.number === activity[1]
        );
        const user = usersDB.find((user) => user.displayName === activity[2]);
        if (salesOrder && purchaseOrder && user) {
          POActivityStreamDB.push({
            ...new Activity({
              salesOrderId: salesOrder.id,
              purchaseOrderId: purchaseOrder.id,
              user: user.id,
              creationDate: parseInt(activity[3]),
              type: activity[4],
              detail: activity[5],
              scope: "PURCHASE_ORDER",
            }),
          });
        } else if (!salesOrder) {
          SONumberNotFound++;
        } else if (!purchaseOrder) {
          PONumerNotFound++;
        } else if (!user) {
          userNotFound++;
        }
      }
    }
  });
  if (SONumberNotFound > 0) {
    return {
      statusActivityStream: 400,
      messageActivityStream:
        "SO number not found in Activity Stream spread sheet",
    };
  } else if (PONumerNotFound > 0) {
    return {
      statusActivityStream: 400,
      messageActivityStream:
        "PO number not found in Activity Stream spread sheet",
    };
  } else if (userNotFound) {
    return {
      statusActivityStream: 400,
      messageActivityStream: "User not found in Activity Stream spread sheet",
    };
  } else {
    return {
      statusActivityStream: 200,
      messageActivityStream: "Success",
      SOActivityStreamDB,
      POActivityStreamDB,
    };
  }
}

export function buildShipmentsSpreadSheetIntoObject({
  shipmentsSpreadSheet,
  userDB,
  tags,
  salesOrderId,
  purchaseOrderId,
  customerId,
}) {
  console.log("SHIPMENT::::", shipmentsSpreadSheet, tags);
  const shipmentsSpreadSheetDB = [];
  let tagNotFound = 0;
  shipmentsSpreadSheet.values.forEach((shipment, index) => {
    if (index !== 0) {
      const { tagNotFoundCpy, tagIds } = getTagIds(shipment[14], tags);
      tagNotFound += tagNotFoundCpy;

      shipmentsSpreadSheetDB.push({
        ...new Shipment({
          number: shipment[0],
          date: moment(shipment[1]).valueOf(),
          shipDate: shipment[2] ? moment(shipment[2]).valueOf() : "",
          shipDates: shipment[2]
            ? getDate(userDB.id, shipment[2], "shipDate")
            : "",
          deliveryDate: shipment[3] ? moment(shipment[3]).valueOf() : "",
          deliveryDates: shipment[3]
            ? getDate(userDB.id, shipment[3], "deliveryDate")
            : "",
          booking: shipment[4],
          billOfLading: shipment[5],
          container: shipment[6],
          freightForwarder: shipment[7],
          loadingPort: shipment[8],
          unloadingPort: shipment[9],
          finalDestination: shipment[10],
          shippingTerms: shipment[11],
          invoice: getAmount(shipment[12]),
          customerPO: shipment[13],
          tags: tagIds,
          customerId,
        }),
        taskCompleted: parseInt(shipment[15]) || 0,
        mainSalesOrderId: salesOrderId,
        mainPurchaseOrderId: purchaseOrderId,
      });
    }
  });
  if (tagNotFound > 0) {
    return {
      statusShipments: 400,
      messageShipments: "Tag not found in Shipments spread sheet",
    };
  } else {
    return {
      statusShipments: 200,
      messageShipments: "Success",
      shipmentsSpreadSheetDB,
    };
  }
}

export function buildProductsSpreadSheetIntoObject({ productsSpreadSheet }) {
  console.log("PRODUCTS::::", productsSpreadSheet);
  const productsSpreadSheetDB = [];
  productsSpreadSheet.values.forEach((item, index) => {
    if (index !== 0 && item[0]) {
      productsSpreadSheetDB.push({
        ...new Item({
          itemNumber: item[0],
          description: item[1],
          unitCost: numeral(item[2]).format("0.00"),
          vendorNumber: item[4],
          vendorName: item[5],
          piecesPerMaster: item[6],
          cbmMaster: item[7],
          weight: item[8],
          image: item[9],
          status: "",
        }),
        price: numeral(item[3]).format("0.00"),
      });
    }
  });

  return {
    statusProducts: 200,
    messageProducts: "Success",
    productsSpreadSheetDB,
  };
}

function getShipmentValues({ item, shipmentsNotFound, shipmentsDB, columns }) {
  const shipmentNumbers = item[4];
  const shipmentIds = [];
  const shipmentQuantity = {};
  const shipmentsBySO = [];
  if (shipmentNumbers) {
    shipmentNumbers.split(",").forEach((shipmentNumber) => {
      const shipment = shipmentsDB.find(
        (shipment) => shipment.number === shipmentNumber
      );
      const index = columns.indexOf(shipmentNumber);
      if (shipment) {
        shipmentIds.push(shipment.id);
        shipmentQuantity[shipment.id] = item[index];
        shipmentsBySO.push({
          id: shipment.id,
          number: shipment.number,
          allocation: item[index],
        });
      } else {
        shipmentsNotFound++;
      }
    });
  }
  return {
    shipmentIds,
    shipmentQuantity,
    shipmentsBySO,
  };
}

function getShipmentIds({ currentShipmentIds, newShipmentIds }) {
  const currentShipmentIdsCpy = [...currentShipmentIds];
  newShipmentIds.forEach((shipmentId) => {
    if (!currentShipmentIds.includes(shipmentId)) {
      currentShipmentIdsCpy.push(shipmentId);
    }
  });
  return currentShipmentIdsCpy;
}

function getPurchaseOrderIds({ currentPurchaseOrderIds, newPurchaseOrderId }) {
  const currentPurchaseOrderIdsCpy = [...currentPurchaseOrderIds];
  if (!currentPurchaseOrderIdsCpy.includes(newPurchaseOrderId)) {
    currentPurchaseOrderIdsCpy.push(newPurchaseOrderId);
  }
  return currentPurchaseOrderIdsCpy;
}

export function buildItemTableSpreadSheetIntoObject({
  itemTableSpreadSheet,
  salesOrdersDB,
  purchaseOrdersDB,
  productsDB,
  shipmentsDB,
}) {
  console.log("PRODUCTS DB:: ", productsDB);
  const itemTableSpreadSheetDB = [];
  let salesOrdersNotFound = 0;
  let purchaseOrdersNotFound = 0;
  let productsNotFound = 0;
  let shipmentsNotFound = 0;
  const productsNotFoundArr = [];
  const columns = itemTableSpreadSheet.values[0];
  let purchaseOrdersDBCpy = [...purchaseOrdersDB];
  let shipmentsDBCpy = [...shipmentsDB];
  itemTableSpreadSheet.values.forEach((item, index) => {
    if (index !== 0 && item[0]) {
      const salesOrder = salesOrdersDB.find(
        (salesOrder) => salesOrder.number === item[1]
      );
      const purchaseOrder = purchaseOrdersDB.find(
        (purchaseOrder) => purchaseOrder.number === item[2]
      );
      const product = productsDB.find(
        (product) => product.itemNumber === item[0]
      );

      if (salesOrder && purchaseOrder && product) {
        const {
          shipmentIds,
          shipmentQuantity,
          shipmentsBySO,
        } = getShipmentValues({
          item,
          shipmentsNotFound,
          shipmentsDB,
          columns: columns,
        });
        itemTableSpreadSheetDB.push({
          ...new Item({
            ...product,
            salesOrderQuantity: {
              [salesOrder.id]: getAmount(item[6]),
            },
            salesOrderPrice: {
              [salesOrder.id]: product.price,
            },
            purchaseOrderQuantity: getAmount(item[7]),
            salesOrderIds: [salesOrder.id],
            shipmentIds,
            shipmentQuantity,
            salesOrders: [
              {
                id: salesOrder.id,
                number: salesOrder.number,
                customerId: salesOrder.customerId,
                allocation: getAmount(item[6]),
                shipments: shipmentsBySO,
              },
            ],
            purchaseOrderId: purchaseOrder.id,
          }),
        });
        purchaseOrdersDBCpy = purchaseOrdersDBCpy.map((po) => {
          if (po.id === purchaseOrder.id) {
            return {
              ...po,
              shipmentIds: getShipmentIds({
                currentShipmentIds: po.shipmentIds,
                newShipmentIds: shipmentIds,
              }),
            };
          }
          return po;
        });
        shipmentsDBCpy = shipmentsDBCpy.map((shipment) => {
          if (shipmentIds.includes(shipment.id)) {
            return {
              ...shipment,
              purchaseOrderIds: getPurchaseOrderIds({
                currentPurchaseOrderIds: shipment.purchaseOrderIds,
                newPurchaseOrderId: purchaseOrder.id,
              }),
            };
          }
          return shipment;
        });
      } else if (!salesOrder) {
        salesOrdersNotFound++;
      } else if (!purchaseOrder) {
        purchaseOrdersNotFound++;
      } else if (!product) {
        productsNotFound++;
        productsNotFoundArr.push(item[0]);
      }
    }
  });
  if (salesOrdersNotFound > 0) {
    return {
      statusItemTable: 400,
      messageItemTable: "Sales Order not found in the Item Table spreadsheet.",
      itemTableSpreadSheetDB,
    };
  } else if (purchaseOrdersNotFound > 0) {
    return {
      statusItemTable: 400,
      messageItemTable:
        "Purchase Order not found in the Item Table spreadsheet.",
      itemTableSpreadSheetDB,
    };
  } else if (productsNotFound > 0) {
    return {
      statusItemTable: 400,
      messageItemTable: `Product not found in the Item Table spreadsheet: ${productsNotFoundArr}`,
      itemTableSpreadSheetDB,
    };
  } else if (shipmentsNotFound > 0) {
    return {
      statusItemTable: 400,
      messageItemTable: "Shipment not found in the Item Table spreadsheet.",
      itemTableSpreadSheetDB,
    };
  } else {
    return {
      statusItemTable: 200,
      messageItemTable: "Success",
      itemTableSpreadSheetDB,
      purchaseOrdersDBCpy,
      shipmentsDBCpy,
    };
  }
}

function getDate(userId, date, field) {
  return [
    {
      creationDate: moment().valueOf(),
      [field]: moment(date).valueOf(),
      user: userId,
    },
  ];
}

function verifyDuplicate(array, field) {
  const fieldArray = array.map((item) => item[field]);
  const isDuplicate = fieldArray.some(
    (item, index) => fieldArray.indexOf(item) !== index
  );
  return isDuplicate;
}

async function verifyPhoneNumberExist(users) {
  let existPhoneNumber = false;
  const phoneNumbers = [];
  for (let index = 0; index < users.length; index++) {
    const userDBSnap = await getDocs(
      query(
        collection(firestore, dbTables.USERS),
        where("cellPhone", "==", users[index].cellPhone)
      )
    );
    console.log("VERIFY PHONE NUMBERS....", userDBSnap.size);
    if (userDBSnap.size > 0) {
      phoneNumbers.push(users[index].cellPhone);
      existPhoneNumber = true;
    }
  }
  return {
    existPhoneNumber,
    phoneNumbers,
  };
}

export const rolePermissions = [
  {
    id: "assign_tags",
    enabled: true,
    permission: "assign_tags",
  },
  {
    id: "create_tags",
    enabled: true,
    permission: "create_tags",
  },
  {
    id: "customers_view",
    enabled: true,
    permission: "customers_view",
  },

  {
    id: "delete_tags",
    enabled: true,
    permission: "delete_tags",
  },
  {
    id: "factories_view",
    enabled: true,
    permission: "factories_view",
  },

  {
    id: "modify_cbm",
    enabled: true,
    permission: "modify_cbm",
  },
  {
    id: "modify_company_template",
    enabled: true,
    permission: "modify_company_template",
  },
  {
    id: "modify_customers",
    enabled: true,
    permission: "modify_customers",
  },
  {
    id: "modify_due_dates",
    enabled: true,
    permission: "modify_due_dates",
  },
  {
    id: "modify_factory_template",
    enabled: true,
    permission: "modify_factory_template",
  },
  {
    id: "orders_by_phase_view",
    enabled: true,
    permission: "orders_by_phase_view",
  },
  {
    id: "permissions_view",
    enabled: true,
    permission: "permissions_view",
  },
  {
    id: "purchase_order_amount",
    enabled: true,
    permission: "purchase_order_amount",
  },
  {
    id: "purchase_orders_view",
    enabled: true,
    permission: "purchase_orders_view",
  },
  {
    id: "reassign_PO",
    enabled: true,
    permission: "reassign_PO",
  },
  {
    id: "reassign_task",
    enabled: true,
    permission: "reassign_task",
  },
  {
    id: "receive_notification_from_API",
    enabled: true,
    permission: "receive_notification_from_API",
  },
  {
    id: "rename_tags",
    enabled: true,
    permission: "rename_tags",
  },
  {
    id: "sales_order_amount",
    enabled: true,
    permission: "sales_order_amount",
  },
  {
    id: "sales_orders_view",
    enabled: true,
    permission: "sales_orders_view",
  },
  {
    id: "see_tags",
    enabled: true,
    permission: "see_tags",
  },
  {
    id: "settings",
    enabled: true,
    permission: "settings",
  },
  {
    id: "tags_view",
    enabled: true,
    permission: "tags_view",
  },
  {
    id: "tasks_view",
    enabled: true,
    permission: "tasks_view",
  },
  {
    id: "shipments_view",
    enabled: true,
    permission: "shipments_view",
  },
  {
    id: "un-assign_tags",
    enabled: true,
    permission: "un-assign_tags",
  },
  {
    id: "week_view",
    enabled: true,
    permission: "week_view",
  },
];
