import React from "react";
import newFilePlaceholder from "../../assets/flag-icons/new-file-placeholder.svg";
import { LegendDocumentsStyled } from "./styles";
import {
  DeleteIconv2,
  FolderIconv2,
  PermissionIcon,
} from "../../helpers/iconSvgPath";

function LegendDocumentOptions() {
  return (
    <LegendDocumentsStyled className="legendContainer">
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Legend:
      </span>
      <div
        style={{
          fontStyle: "italic",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FolderIconv2 width={17} height={17} />
        <span>New Folder</span>
        <img
          src={newFilePlaceholder}
          alt={"new"}
          width={24}
          height={24}
          style={{
            padding: "3px 4px",
          }}
        />
        <span>New File</span>

        <PermissionIcon />
        <span>Permissions</span>

        <DeleteIconv2 />
        <span>Delete</span>
      </div>
    </LegendDocumentsStyled>
  );
}

export default LegendDocumentOptions;
