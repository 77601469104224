import React from "react";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { getUserAvatar } from "../../helpers/users";

export const OptionAutocomplete = ({ inputProps, option, state }) => {
  const selected = state.selected;
  return (
    <div {...inputProps} className="poppoverOption">
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          height: "inherit",
        }}
      >
        <CustomCheckbox checked={selected} />
        <div
          className="selectedText"
          style={{
            ...option.styles,
          }}
        >
          {option.user &&
            getUserAvatar({
              user: option.user,
              styles: {
                width: 20,
                height: 20,
                margin: 3,
                fontSize: 10,
                outline: "2px solid #000",
              },
            })}
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {option.label}
          </span>
        </div>
      </div>
    </div>
  );
};
