import React, { useState, useEffect } from "react";
import {
  PurchaseOrderIcon,
  SalesOrderIcon,
  ShipmentOrderIcon,
} from "../../helpers/iconSvgPath";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "./sections/helpers";
import { useOrderDashboardActualView } from "../../hooks/user";
import { OrderDashboardNavigationStyled } from "./styles";
import {
  useOrderDashboardSetup,
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
  usePurchaseOrders,
} from "../../hooks/salesOrders";
import {
  getDashboardSearchPath,
  getPurchaseOrdersPermission,
} from "../../helpers/helpers";
import { useQueryParams } from "../../hooks";
import { PlayArrow } from "@mui/icons-material";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { verifyPermission } from "../../hooks/permissions";
import TooltipTD from "../Tooltip/TooltipTD";
import { getOperatingSystem, OS_NAME } from "../../helpers/auth";
import { useNavigate } from "react-router-dom";
import { cx } from "@emotion/css";

function DashboardNavigationBar({ user }) {
  const salesOrder = useCurrentSalesOrder({});
  const purchaseOrder = useCurrentPurchaseOrder();
  const actualView = useOrderDashboardActualView();
  const { shipmentId, purchaseOrderId } = useOrderDashboardSetup();
  const queryParams = useQueryParams();
  const purchaseOrders = usePurchaseOrders();
  const navigate = useNavigate();
  const OS = getOperatingSystem();
  const newPurchaseOrderId = getPurchaseOrdersPermission({
    purchaseOrders,
    queryParams,
    user,
  });

  const getElementToFocus = (idElement) => {
    navigate(
      getDashboardSearchPath({
        ...queryParams,
        purchaseOrderId: newPurchaseOrderId,
        section: idElement,
      }),
      { replace: true }
    );
  };

  function moveByArrow({ toIndex = "" }) {
    getElementToFocus(SECTION_BY_NUMBERS[toIndex]);
  }

  const SECTION_BY_STRING = {
    [ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER]: 1,
    [ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER]: 2,
    [ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT]: 3,
    [ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION]: 4,
  };

  const SECTION_BY_NUMBERS = {
    1: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
    2: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
    3: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
    4: ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
  };

  const [pressedKey, setPressedKey] = useState("");

  const ARRAY_OF_OPTIONS = ["1", "2", "3", "4"];
  const MAC_KEY_BOARD = {
    OPTION: OS === OS_NAME.MAC ? "altKey" : "ctrlKey",
    COMMAND: OS === OS_NAME.MAC ? "metaKey" : "altKey",
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event[MAC_KEY_BOARD.COMMAND] &&
          event[MAC_KEY_BOARD.OPTION] &&
          ARRAY_OF_OPTIONS.includes(event.key)) ||
        event.key === "PageUp" ||
        event.key === "PageDown"
      ) {
        setPressedKey(event.key);
      }
    };

    const handleKeyUp = (event) => {
      setPressedKey("");
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const SHORT_CUTS = OS === OS_NAME.MAC ? "⌥⌘" : "Ctrl + Alt + ";
  useEffect(() => {
    switch (pressedKey) {
      case "1":
        return getElementToFocus(ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER);
      case "2":
        if (purchaseOrderId) {
          getElementToFocus(ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER);
        }
        break;
      case "3":
        if (shipmentId) {
          getElementToFocus(ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT);
        }
        break;
      case "4":
        if (purchaseOrderId) {
          getElementToFocus(ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION);
        }
        break;
      default:
        break;
    }
    if (pressedKey === "PageUp") {
      pageUp({ actualView, vendorPermission });
    } else if (pressedKey === "PageDown") {
      pageDown({ actualView, vendorPermission });
    }
  }, [pressedKey]);

  const customerPermission = verifyPermission({
    user: user,
    permissionToCheck: [
      salesOrder.customerId,
      GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
    ],
  });

  const vendorPermission = verifyPermission({
    user: user,
    permissionToCheck: [
      purchaseOrder.factoryId,
      GENERAL_PERMISSION_VALUE.ALL_VENDORS,
    ],
  });

  if (!customerPermission) {
    return null;
  }

  function pageUp({ actualView, vendorPermission }) {
    if (actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER) {
      return;
    }
    if (!purchaseOrderId) {
      moveByArrow({
        toIndex: 1,
      });
      return;
    }
    let currentSection = SECTION_BY_STRING[actualView] - 1;
    if (currentSection === 3 && !shipmentId) {
      currentSection--;
    }
    if (currentSection > 2 && !vendorPermission) {
      moveByArrow({
        toIndex: 2,
      });
      return;
    }
    moveByArrow({
      toIndex: currentSection,
    });
  }

  function pageDown({ actualView, vendorPermission }) {
    let currentSection = SECTION_BY_STRING[actualView];
    if (!purchaseOrderId) {
      moveByArrow({
        toIndex: 1,
      });
      return;
    }
    if (
      actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION ||
      (!vendorPermission && currentSection === 2)
    ) {
      return;
    }
    currentSection = currentSection + 1;
    if (currentSection === 3 && !shipmentId) {
      currentSection++;
    }
    if (currentSection > 2 && !vendorPermission) {
      moveByArrow({
        toIndex: 1,
      });
      return;
    }
    moveByArrow({
      toIndex: currentSection,
    });
  }

  return (
    <OrderDashboardNavigationStyled className="orderDashboardNavigationStyles">
      <TooltipTD label={"Page up"}>
        <PlayArrow
          onClick={(ev) => {
            pageUp({ actualView, vendorPermission });
          }}
          style={{
            rotate: "270deg",
            cursor:
              actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER
                ? "not-allowed"
                : "pointer",
            color:
              actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER
                ? "gray"
                : "black",
          }}
        />
      </TooltipTD>
      <TooltipTD label={`Jump to Sales Order section: ${SHORT_CUTS}1`}>
        <div
          className={cx(
            "dashboard-nav-button",
            "dashboard-nav-button-sales-order",
            actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER
              ? `${ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER}-active`
              : ""
          )}
          onClick={() => {
            getElementToFocus(ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER);
          }}
          style={{ cursor: "pointer" }}
        >
          <SalesOrderIcon svgClass={"dashboard-nav-icon"} />
        </div>
      </TooltipTD>
      <TooltipTD
        label={
          purchaseOrderId
            ? `Jump to PO section: ${SHORT_CUTS}2`
            : "There are no POs associated with this Sales Order"
        }
      >
        <div
          onClick={() => {
            if (purchaseOrderId) {
              getElementToFocus(ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER);
            }
          }}
          className={cx(
            "dashboard-nav-button",
            "dashboard-nav-button-purchase-order",
            actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER
              ? `${ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER}-active`
              : "",
            !purchaseOrderId ? "dashboard-nav-button-shipment-disabled" : ""
          )}
          style={{ cursor: "pointer" }}
        >
          <PurchaseOrderIcon
            svgClass={"dashboard-nav-icon"}
            style={{ margin: "0px 2px" }}
          />
        </div>
      </TooltipTD>
      {vendorPermission && (
        <TooltipTD
          label={
            shipmentId
              ? `Jump to Shipment section: ${SHORT_CUTS}3`
              : "There are no shipments associated with this Purchase Order"
          }
        >
          <div
            onClick={() => {
              if (shipmentId) {
                getElementToFocus(ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT);
              }
            }}
            className={cx(
              "dashboard-nav-button",
              "dashboard-nav-button-shipment",
              actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT
                ? `${ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT}-active`
                : "",
              !shipmentId ? "dashboard-nav-button-shipment-disabled" : ""
            )}
            style={{ cursor: "pointer" }}
          >
            <ShipmentOrderIcon svgClass={"dashboard-nav-icon"} />
          </div>
        </TooltipTD>
      )}

      {(vendorPermission ||
        newPurchaseOrderId !== queryParams.purchaseOrderId) && (
        <TooltipTD
          label={
            purchaseOrderId
              ? `Jump to bottom section: ${SHORT_CUTS}4`
              : "There are no POs associated with this Sales Order"
          }
        >
          <div
            onClick={() => {
              if (purchaseOrderId) {
                getElementToFocus(
                  ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
                );
              }
            }}
            className={cx(
              "dashboard-nav-button",
              "dashboard-nav-button-three-panel",
              actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
                ? `${ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION}-active`
                : "",
              !purchaseOrderId ? "dashboard-nav-button-shipment-disabled" : ""
            )}
            id={"taskPanelButton"}
            style={{
              cursor: "pointer",
              marginBottom: 0,
            }}
          >
            <DehazeIcon className={"dashboard-mui-icon"} />
          </div>
        </TooltipTD>
      )}
      <TooltipTD label={"Page down"}>
        <PlayArrow
          onClick={(ev) => {
            pageDown({
              actualView,
              vendorPermission,
            });
          }}
          style={{
            rotate: "90deg",
            cursor:
              actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION ||
              (!vendorPermission && SECTION_BY_STRING[actualView] === 2)
                ? "not-allowed"
                : "pointer",
            color:
              actualView === ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION ||
              (!vendorPermission && SECTION_BY_STRING[actualView] === 2)
                ? "gray"
                : "black",
          }}
        />
      </TooltipTD>
    </OrderDashboardNavigationStyled>
  );
}

export default DashboardNavigationBar;
