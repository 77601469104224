import React from "react";
import IntlMessages from "../../util/IntlMessages";

function CustomButton({
  children,
  onClick,
  type,
  className,
  disabled = false,
  id,
  style = {},
  noSubmit,
  containerColor = "white",
  priorityView = 0,
  styleContainer = {},
}) {
  return (
    <div
      style={{
        ...styleContainer,
        position: "sticky",
        background: containerColor,
        zIndex: priorityView,
        display: "flex",
        alignItems: "center",
      }}
    >
      <button
        style={style}
        onClick={onClick}
        className={className ? className : "button-" + type}
        type={!noSubmit ? "submit" : "button"}
        disabled={disabled}
      >
        {children && <IntlMessages id={children} />}
      </button>
    </div>
  );
}

export default CustomButton;
