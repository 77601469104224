import React from "react";
import PurchaseOrderTask from "../../api/model/purchaseOrderTask";
import EditableLabel from "../TextFields/EditableLabel";
import { Menu as MenuIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Draggable } from "react-beautiful-dnd";
import dependencyTypes from "../../api/types/dependencyTypes";
import IntlMessages from "../../util/IntlMessages";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { typeOfTask } from "../../api/types/dbTables";
import { validateDataTaskTemplate } from "../../helpers/helpers";
import SetupNotifications from "../SalesOrders/SetupNotifications";
import AppConfig from "../../constants/AppConfig";
import { Grid, styled } from "@mui/material";

function CategorySection({
  title,
  list = [],
  onAdd,
  selectOptions,
  onChangeSelect,
  onChange,
  provided,
  salesOrderTasks,
  onDelete,
  selectUsers,
  completeList,
  companyUsers = [],
  purchaseOrderTasks = [],
}) {
  const taskList = [];
  completeList.forEach((list) => {
    taskList.push(...list);
  });

  function getSalesOrderDependency(salesOrderTasks, dependency) {
    let numberToDependency = 0;
    salesOrderTasks.forEach((task, index) => {
      if (task.id === dependency) {
        numberToDependency = index + 1;
      }
    });
    return "S." + numberToDependency + " ";
  }

  function getDependencyOptions({ options, item }) {
    if (!options) {
      return [];
    }
    let optionsCpy = [...options];
    if (item.type === typeOfTask.SHIPMENT) {
      optionsCpy = optionsCpy.filter((option) => option.value !== item.id);
    } else {
      optionsCpy = options.filter(
        (option) =>
          option.value !== item.id && option.type !== typeOfTask.SHIPMENT
      );
    }

    return optionsCpy.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }

  return (
    <CategoryTaskSectionStyled className="task-section">
      <Grid
        container
        style={{
          fontSize: 14,
          padding: "8px 8px 0px 8px",
          fontWeight: "bold",
          background: AppConfig.themeColors.tableHeaderColor,
          borderTop: `1px solid ${AppConfig.themeColors.dividerColor}`,
          position: "sticky",
          zIndex: 1,
          height: 25,
        }}
      >
        <Grid xs={10} item>
          {title && <IntlMessages id={title} />}
        </Grid>
        <Grid container xs={2} justifyContent="flex-end" item>
          {onAdd && (
            <span
              onClick={() =>
                onAdd(
                  new PurchaseOrderTask({
                    description: "task",
                    type: typeOfTask.PURCHASE_ORDER,
                  })
                )
              }
              className="add-button"
            >
              {
                <IntlMessages id="components.factories.categorysection.addtask" />
              }
            </span>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        className="sales-order-toolbar"
        style={{
          background: AppConfig.themeColors.tableHeaderColor,
          borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
          position: "sticky",
          zIndex: 1,
        }}
      >
        <Grid container xs={6}>
          <Grid xs={2} item>
            {<IntlMessages id="components.factories.categorysection.order" />}
          </Grid>
          <Grid xs={5} justifyContent="flex-start" item>
            {<IntlMessages id="components.factories.taskdescription" />}
          </Grid>
          <Grid xs={2} item>
            {
              <IntlMessages id="components.factories.categorysection.dependencytype" />
            }
          </Grid>
          <Grid xs={3} item>
            {
              <IntlMessages id="components.factories.categorysection.predecessor" />
            }
          </Grid>
        </Grid>
        <Grid container xs={6}>
          <Grid xs={1} item>
            {onAdd && (
              <IntlMessages id="components.factories.categorysection.duration" />
            )}
          </Grid>
          <Grid xs={1} item>
            {onAdd && (
              <IntlMessages id="components.factories.categorysection.offset" />
            )}
          </Grid>
          <Grid xs={2} item>
            {onAdd && (
              <IntlMessages id="components.factories.categorysection.person" />
            )}
          </Grid>
          <Grid
            xs={3}
            justifyContent="flex-end"
            item
            style={{ textAlign: "center" }}
          >
            {onAdd && (
              <IntlMessages id="components.factories.categorysection.notificationEarly" />
            )}
          </Grid>
          <Grid
            xs={3}
            justifyContent="flex-end"
            item
            style={{ textAlign: "center" }}
          >
            {onAdd && (
              <IntlMessages id="components.factories.categorysection.notificationLate" />
            )}
          </Grid>
          <Grid xs={1} item style={{ textAlign: "center" }}>
            {onAdd && (
              <IntlMessages id="components.factories.categorysection.isShipment" />
            )}
          </Grid>
          <Grid
            xs={1}
            justifyContent="flex-end"
            item
            style={{ textAlign: "center" }}
          >
            {onAdd && (
              <IntlMessages id="components.factories.categorysection.action" />
            )}
          </Grid>
        </Grid>
      </Grid>

      <ul
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{ paddingRight: 0, margin: "20px 16px" }}
      >
        {list.map((item, index) => {
          if (!item.duration) {
            item.duration = 0;
          }
          let realTask = item;

          if (item.type === typeOfTask.SALES_ORDER) {
            return (
              <Draggable
                isDragDisabled={true}
                key={onAdd ? item.id : item.id + "SOT"}
                index={index}
                draggableId={item.id}
              >
                {(providedDrag) => {
                  return (
                    <Grid
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                      alignItems="center"
                      key={item.id}
                      style={{
                        ...providedDrag.draggableProps.style,
                        backgroundColor: realTask
                          ? realTask.status === "new"
                            ? "rgba(66, 245, 138,0.1)"
                            : realTask.status === "modified"
                            ? "rgba(255, 195, 92,0.1)"
                            : AppConfig.themeColors.salesOrderBackgroundColor
                          : AppConfig.themeColors.salesOrderBackgroundColor,
                        paddingRight: 16,
                        paddingLeft: 16,
                        margin: "1px 0px",
                      }}
                      className="sales-order-form-input"
                    >
                      <Grid container xs={6}>
                        <Grid xs={2} wrap="nowrap" alignItems="center" item>
                          <span style={{ minWidth: 24, minHeight: 24 }} />
                          <label>
                            {"Task #S." +
                              (realTask.number || realTask.listIndex) +
                              " "}
                          </label>
                        </Grid>
                        <Grid
                          xs={5}
                          alignItems="center"
                          justifyContent="flex-start"
                          item
                        >
                          <label>
                            {realTask ? realTask.description : item.description}
                          </label>
                        </Grid>

                        <Grid xs={2} item>
                          <label>{item.dependencyType}</label>
                        </Grid>
                        <Grid alignItems="center" xs={3} item>
                          <label>
                            {salesOrderTasks.find(
                              (task) => task.id === item.dependency
                            )
                              ? getSalesOrderDependency(
                                  salesOrderTasks,
                                  item.dependency
                                ) +
                                salesOrderTasks.find(
                                  (task) => task.id === item.dependency
                                ).description
                              : ""}
                          </label>
                        </Grid>
                      </Grid>
                      <Grid container xs={6}>
                        <Grid alignItems="center" xs={1} item>
                          {onAdd && (
                            <label type="number" style={{ margin: 0 }}>
                              {item.duration}
                            </label>
                          )}
                        </Grid>
                        <Grid alignItems="center" xs={1} item>
                          {onAdd && (
                            <label type="number" style={{ margin: 0 }}>
                              {item.offset}
                            </label>
                          )}
                        </Grid>
                        <Grid alignItems="center" xs={2} item>
                          {onAdd && (
                            <p style={{ margin: 0 }}>
                              {selectUsers.find(
                                (user) => user.value === realTask.assignedTo
                              ) &&
                                selectUsers.find(
                                  (user) => user.value === realTask.assignedTo
                                ).label}
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item xs={3} />
                        <Grid item xs={1} />
                        <Grid item xs={1} />
                      </Grid>
                    </Grid>
                  );
                }}
              </Draggable>
            );
          }
          return (
            <Draggable
              key={onAdd ? item.id : item.id + "SOT"}
              index={index}
              draggableId={item.id}
            >
              {(providedDrag, snapshot) => {
                return (
                  <Grid
                    container
                    alignItems="center"
                    ref={providedDrag.innerRef}
                    {...providedDrag.draggableProps}
                    {...providedDrag.dragHandleProps}
                    key={item.id}
                    style={{
                      ...providedDrag.draggableProps.style,
                      backgroundColor: validateDataTaskTemplate({
                        poTasks: [realTask],
                        salesOrderTasks,
                        users: companyUsers,
                        poList: purchaseOrderTasks,
                      })
                        ? item.type === typeOfTask.PURCHASE_ORDER
                          ? AppConfig.themeColors.purchaseOrderBackgroundColor
                          : AppConfig.themeColors.shipmentColor
                        : "rgba(255, 0, 0,0.1)",
                      paddingRight: 16,
                      paddingLeft: 16,
                      margin: "1px 0px",
                    }}
                    className="sales-order-form-input"
                  >
                    <Grid container xs={6}>
                      <Grid
                        xs={2}
                        wrap="nowrap"
                        alignItems="center"
                        item
                        style={{ display: "flex" }}
                      >
                        <MenuIcon style={{ marginBottom: 1 }} />

                        <label>
                          {"Task #P." +
                            (realTask.number || realTask.listIndex) +
                            " "}
                        </label>
                      </Grid>
                      <Grid
                        xs={5}
                        alignItems="center"
                        justifyContent="flex-start"
                        item
                        style={{ alignSelf: "center" }}
                      >
                        {onAdd && (
                          <EditableLabel
                            style={{
                              margin: "0px 12px 0px 0px",
                              width: "fit-content",
                            }}
                            inputStyle={{
                              minWidth: 320,
                              background: "#fff",
                            }}
                            onValid={(ev) =>
                              onChangeSelect({
                                event: { ...ev, sourceItem: item },
                                value: ev.target.value,
                                field: "description",
                              })
                            }
                            onChange={(ev) =>
                              onChangeSelect({
                                event: { ...ev, sourceItem: item },
                                value: ev.target.value,
                                field: "description",
                              })
                            }
                          >
                            {realTask ? realTask.description : item.description}
                          </EditableLabel>
                        )}
                      </Grid>

                      <Grid xs={3} item style={{ display: "flex" }}>
                        {onAdd &&
                        title ===
                          "components.factories.salesordercontainer.proposal" &&
                        index === 0 ? null : (
                          <select
                            style={{ margin: 0, background: "#fff" }}
                            onChange={(event) =>
                              onChangeSelect({
                                event: { ...event, sourceItem: item },
                                value: event.target.value,
                                field: "dependencyType",
                              })
                            }
                            defaultValue=""
                            value={item.dependencyType}
                          >
                            <option value="" disabled>
                              Select One
                            </option>
                            {Object.keys(dependencyTypes).map((option) => (
                              <option key={option} value={option}>
                                {`${option} - ${dependencyTypes[option]}`}
                              </option>
                            ))}
                          </select>
                        )}
                      </Grid>

                      <Grid
                        alignItems="center"
                        xs={2}
                        item
                        style={{ display: "flex" }}
                      >
                        {title ===
                          "components.factories.salesordercontainer.proposal" &&
                        index === 0 ? null : (
                          <select
                            style={{
                              margin: 0,
                              background: "#fff",
                            }}
                            placeholder="Dependency"
                            onChange={(event) =>
                              onChangeSelect({
                                event: { ...event, sourceItem: item },
                                value: event.target.value,
                                field: "dependency",
                              })
                            }
                            defaultValue=""
                            value={
                              [
                                ...salesOrderTasks.map((task) => ({
                                  ...task,
                                })),
                                ...taskList,
                              ]
                                .map((task) => task.id)
                                .includes(item.dependency)
                                ? item.dependency
                                : ""
                            }
                          >
                            <option value="" disabled>
                              Select One
                            </option>
                            {getDependencyOptions({
                              options: selectOptions,
                              item,
                            })}
                          </select>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container xs={6}>
                      <Grid
                        alignItems="center"
                        xs={1}
                        item
                        style={{ display: "flex" }}
                      >
                        {onAdd && (
                          <EditableLabel
                            type="number"
                            style={{ margin: 0, width: 70 }}
                            onChange={(ev) => {
                              if (ev.target.value < 1) {
                                return;
                              }

                              onChangeSelect({
                                event: { ...ev, sourceItem: item },
                                value: parseInt(ev.target.value),
                                field: "duration",
                              });
                            }}
                            inputStyle={{ background: "#fff" }}
                          >
                            {item.duration}
                          </EditableLabel>
                        )}
                      </Grid>
                      <Grid
                        alignItems="center"
                        xs={1}
                        item
                        style={{ display: "flex" }}
                      >
                        {onAdd && (
                          <EditableLabel
                            type="number"
                            style={{ margin: 0, width: 70 }}
                            onChange={(ev) =>
                              onChangeSelect({
                                event: { ...ev, sourceItem: item },
                                value: parseInt(ev.target.value),
                                field: "offset",
                              })
                            }
                            isTemplateValue={true}
                            inputStyle={{ background: "#fff" }}
                          >
                            {item.offset}
                          </EditableLabel>
                        )}
                      </Grid>
                      <Grid
                        alignItems="center"
                        xs={2}
                        item
                        style={{ display: "flex" }}
                      >
                        <select
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            background: "#fff",
                          }}
                          placeholder="Assigned To"
                          onChange={(event) =>
                            onChangeSelect({
                              event: { ...event, sourceItem: item },
                              value: event.target.value,
                              field: "assignedTo",
                            })
                          }
                          defaultValue=""
                          value={
                            companyUsers.find(
                              (user) => user.id === realTask.assignedTo
                            )
                              ? realTask.assignedTo
                              : ""
                          }
                        >
                          <option disabled value="">
                            Select One
                          </option>
                          {selectUsers &&
                            selectUsers.map((option) => (
                              <option
                                style={{ fontWeight: "bold" }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </option>
                            ))}
                        </select>
                      </Grid>
                      <Grid
                        xs={3}
                        alignItems="center"
                        justifyContent="flex-end"
                        item
                        style={{ textAlign: "center" }}
                      >
                        {onAdd && (
                          <SetupNotifications
                            item={{
                              ...item,
                              permissionUsers: item.userNotificationEarly || {},
                              permissionGroups:
                                item.groupNotificationEarly || {},
                            }}
                            onSubmit={({ item }) => {
                              console.log("on submit::: 11 ", item);
                              const {
                                permissionUsers,
                                permissionGroups,
                                ...rest
                              } = item;
                              onChangeSelect({
                                event: { sourceItem: item },
                                value: {
                                  ...rest,
                                  userNotificationEarly: permissionUsers,
                                  groupNotificationEarly: permissionGroups,
                                },
                                field: "notificationEarly",
                              });
                            }}
                          />
                        )}
                      </Grid>
                      <Grid
                        xs={3}
                        alignItems="center"
                        justifyContent="flex-end"
                        item
                        style={{ textAlign: "center" }}
                      >
                        {onAdd && (
                          <SetupNotifications
                            item={{
                              ...item,
                              permissionUsers: item.userNotificationLate || {},
                              permissionGroups:
                                item.groupNotificationLate || {},
                            }}
                            onSubmit={({ item }) => {
                              console.log("on submit::: ", item);
                              const {
                                permissionUsers,
                                permissionGroups,
                                ...rest
                              } = item;
                              onChangeSelect({
                                event: { sourceItem: item },
                                value: {
                                  ...rest,
                                  userNotificationLate: permissionUsers,
                                  groupNotificationLate: permissionGroups,
                                },
                                field: "notificationLate",
                              });
                            }}
                          />
                        )}
                      </Grid>
                      <Grid
                        alignItems="center"
                        xs={1}
                        item
                        justifyContent="center"
                        style={{ textAlign: "center" }}
                      >
                        <CustomCheckbox
                          onChange={(event) => {
                            onChangeSelect({
                              event: { ...event, sourceItem: item },
                              value:
                                item.type !== typeOfTask.SHIPMENT
                                  ? typeOfTask.SHIPMENT
                                  : typeOfTask.PURCHASE_ORDER,
                              field: "type",
                            });
                          }}
                          checked={item.type === typeOfTask.SHIPMENT}
                          styles={{ background: "#fff" }}
                        />
                      </Grid>

                      <Grid
                        xs={1}
                        alignItems="center"
                        justifyContent="flex-end"
                        item
                        style={{ textAlign: "center" }}
                      >
                        {onAdd && (
                          <DeleteIcon
                            id="delete-icon"
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => onDelete(item, item.stage)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
            </Draggable>
          );
        })}
        {provided.placeholder}
      </ul>
    </CategoryTaskSectionStyled>
  );
}

const CategoryTaskSectionStyled = styled("div")(() => ({
  "&.task-section": {
    "& ul": {
      "& .sales-order-form-input": {
        padding: 8,
      },
      "& label": {
        flex: 0.5,
        margin: "4px 0px",
        pointerEvents: "none",
      },
      "& .editable-label": {
        "& label": {
          margin: "4px 0px !important",
          pointerEvents: "auto",
        },
        minHeight: "auto",
        minWidth: "auto",
        flex: 0.5,
      },
      "& select": {
        width: "100px",
      },
      "&:select:disabled": {
        opacity: 0.2,
      },
    },
  },
}));

export default CategorySection;
