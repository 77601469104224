import React from "react";
import UserNotificationSetting from "./UserNotificationSetting";

const settingOptions = [
  {
    label:
      "Receive email notifications for notes where I am tagged (@{username})",
    property: "messages",
    description: [
      "When set to YES, an email will be sent as a notification when @{username} is tagged in a note. If set to NO, please remember to check TradeDash regularly for important communications. ",
    ],
  },
  {
    label: "Pending and Past Due Tasks Morning Email",
    property: "tasks",
    description: [
      "When set to YES, an email reminder will be sent each morning if there are any late Tasks or if any Tasks are due that day or the next.",
    ],
  },
];

function UserProfileHeader({ currentUser }) {
  return (
    <div
      className={"user-profile-header"}
      style={{
        padding: 0,
        marginInline: 12,
      }}
    >
      {settingOptions.map((setting, index) => (
        <UserNotificationSetting
          key={setting + index}
          currentUser={currentUser}
          setting={{
            ...setting,
            label: setting.label.replace("{username}", currentUser.displayName),
          }}
        />
      ))}
    </div>
  );
}

export default UserProfileHeader;
