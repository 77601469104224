import moment from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class Customer {
  constructor({
    id = getRandomId(),
    name = "",
    number = "",
    latePenaltyTerms = "",
    notes = "",
    contactName = "",
    avatar = "",
    customersSummaryId = "",
    created = moment().valueOf(),
    finalDestinations = {},
  }) {
    this.id = id;
    this.name = `${name}`.trim();
    this.number = number;
    this.latePenaltyTerms = latePenaltyTerms;
    this.notes = notes;
    this.contactName = contactName;
    this.avatar = avatar;
    this.customersSummaryId = customersSummaryId;
    this.created = created;
    this.finalDestinations = finalDestinations;
  }
}
