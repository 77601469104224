import React, { useState, useEffect } from "react";

import {
  getTypeFile,
  getFileName,
  getSizeTransformedAccordingKBSize,
  getFolderSize,
  sortObjectsBy,
} from "../../helpers/helpers";
import TypeFileSelector from "./TypeFileSelector";
import moment from "moment";
import sucessfullUpload from "../../assets/flag-icons/sucessfull-upload.png";
import ModalPermissionUsers from "./ModalPermissionUsers";
import wrongUploadFile from "../../assets/flag-icons/wrong-upload-file.png";
import IntlMessages from "../../util/IntlMessages";
import { typeModalPermissions, TYPE_OF_FILE } from "../../helpers/constants";
import { ModalPermissionStyled } from "./styles";
import { useUser } from "../../hooks/user";
import { getNewToolTipByPermissionGroup } from "../../helpers/purchaseOrderList";
import { CustomTooltip } from "../Tooltip/CustomToolTip";
import { LinearProgress } from "@mui/material";

function ModalPermission({
  title,
  onlyTitle = false,
  open,
  warningMessage,
  file,
  handleCancel,
  handleDone,
  companyUsers,
  progressUpload = 0,
  uploadMultipleFiles,
  permissionGroupsDB = [],
  currentUser,
  notificatorErrorRemoveUser,
  typePermissionModal,
  parentDocument = null,
}) {
  const [typeFile, setTypeFile] = useState(file.name && getTypeFile(file.name));
  const [permissions, setPermissions] = useState({
    permissionUsers: [],
    permissionGroups: [...permissionGroupsDB],
  });
  const user = useUser();
  useEffect(() => {
    if (!file.length) {
      setTypeFile(file.name && getTypeFile(file.name));
    }
  }, [file]);

  useEffect(() => {
    let permissionGroupsCpy = [
      ...permissions.permissionGroups.map((group) => ({
        ...group,
        enable: true,
      })),
    ];
    if (file) {
      const firstFile = file.length ? file[0] : file;
      if (firstFile.permissionGroups && firstFile.permissionUsers) {
        permissionGroupsCpy = permissionGroupsCpy.map((group) => {
          if (firstFile.permissionGroups[group.id]) {
            return { ...group, checked: true, enable: true };
          }
          return { ...group, enable: true, checked: false };
        });
        let permissionUsers = [
          ...companyUsers.filter((user) => user.id === currentUser),
        ];
        Object.keys(firstFile.permissionUsers).forEach((key) => {
          const user = companyUsers.find((user) => user.id === key);
          if (user && !permissionUsers.find((pUser) => pUser.id === user.id)) {
            permissionUsers.push(user);
          }
        });
        if (parentDocument && Object.keys(parentDocument).length > 0) {
          permissionGroupsCpy = permissionGroupsCpy.map((group) => {
            if (group && parentDocument.permissionGroups[group.id]) {
              return {
                ...group,
                enable: true,
              };
            }
            return { ...group, enable: false };
          });
        }
        setPermissions({
          permissionGroups: permissionGroupsCpy,
          permissionUsers,
        });
      } else {
        setPermissions({
          ...permissions,
          permissionUsers: companyUsers.filter(
            (user) => user.id === currentUser
          ),
        });
      }
    }
  }, [open]);

  function onMoveUsersFromDisolvedGroup(allUsersToMove, permissionGroupsCpy) {
    permissionGroupsCpy
      .filter((permission) => permission.checked)
      .forEach((permission) => {
        Object.keys(permission.users).forEach(
          (key) => delete allUsersToMove[key]
        );
      });
    const permissionUsersCpy = [...permissions.permissionUsers];
    Object.keys(allUsersToMove).forEach((key) => {
      const user = companyUsers.find((user) => user.id === key);
      if (user && !permissionUsersCpy.find((pUser) => pUser.id === user.id)) {
        permissionUsersCpy.push(user);
      }
    });
    setPermissions({
      permissionGroups: permissionGroupsCpy,
      permissionUsers: permissionUsersCpy,
    });
  }

  function onDisolveGroups(user) {
    let permissionGroupsCpy = [...permissions.permissionGroups];
    let permissionUsersCpy = [...permissions.permissionUsers];
    let allUsersToMove = {};
    let userBelongsToSomeGroup = false;
    permissionGroupsCpy = permissionGroupsCpy.map((permission) => {
      if (
        !!permission.checked &&
        (permission.users[user.id] || permission.users[user.id] === false)
      ) {
        userBelongsToSomeGroup = true;
        let usersCpy = permission.users;
        usersCpy[user.id] = false;
        Object.keys(usersCpy).forEach((key) => {
          if (usersCpy[key]) {
            allUsersToMove[key] = usersCpy[key];
          }
        });
        return { ...permission, checked: false };
      } else return permission;
    });
    if (!userBelongsToSomeGroup) {
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy.filter(
          (cUser) => cUser.id !== user.id
        ),
      });
    } else if (Object.keys(allUsersToMove).length > 0) {
      onMoveUsersFromDisolvedGroup(allUsersToMove, permissionGroupsCpy);
    } else {
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy.filter(
          (cUser) => cUser.id !== user.id
        ),
      });
    }
  }

  const searchValue = (value) => {
    const isUserExist = value.find((user) => {
      return currentUser === user.id;
    });
    return !!isUserExist;
  };

  function handleChange(value, lastValue) {
    value = value.filter((val) => !!val);
    const newElementA = value.filter(comparer(lastValue));
    const newElementB = lastValue.filter(comparer(value));
    const newElement = newElementA.concat(newElementB)[0];
    console.log({
      newElement,
      newElementA,
      newElementB,
    });
    const isEraseCurrentUser = searchValue(value);
    if (!isEraseCurrentUser) {
      return;
    }
    if (value.length > lastValue.length) {
      const permissionUsersCpy = [...permissions.permissionUsers];
      permissionUsersCpy.push(newElement);
      setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
    } else {
      const { permissionGroups } = permissions;
      if (permissionGroups.filter((group) => group.checked).length <= 0) {
        let permissionUsersCpy = [...permissions.permissionUsers];
        permissionUsersCpy = permissionUsersCpy.filter(
          (cUser) => cUser.id !== newElement.id
        );
        setPermissions({ ...permissions, permissionUsers: permissionUsersCpy });
      } else {
        onDisolveGroups(newElement);
      }
    }
  }

  function comparer(array) {
    return function (current) {
      return (
        array.filter((elem) => {
          return elem.id === current.id;
        }).length === 0
      );
    };
  }

  function handleChangeGroups(permission, checked) {
    const { permissionGroups } = permissions;
    let permissionGroupsCpy = [...permissionGroups];
    permissionGroupsCpy = permissionGroupsCpy.map((elem) => {
      if (elem.id === permission.id) {
        if (checked) {
          let usersCpy = { ...elem.users };
          Object.keys(usersCpy).map((key) => (usersCpy[key] = true));
          return { ...elem, checked, users: usersCpy };
        } else {
          return { ...elem, checked };
        }
      } else {
        return elem;
      }
    });
    if (checked) {
      let permissionUsersCpy = [...permissions.permissionUsers];
      Object.keys(permission.users).forEach((key) => {
        permissionUsersCpy = permissionUsersCpy.filter(
          (user) => user.id !== key
        );
      });
      setPermissions({
        permissionGroups: permissionGroupsCpy,
        permissionUsers: permissionUsersCpy,
      });
    } else {
      if (permission.users[currentUser]) {
        const permissionUsersCpy = [...permissions.permissionUsers];
        if (!permissionUsersCpy.find((user) => user.id === currentUser)) {
          permissionUsersCpy.push(
            companyUsers.find((user) => user.id === currentUser)
          );
          setPermissions({
            permissionGroups: permissionGroupsCpy,
            permissionUsers: permissionUsersCpy,
          });
        } else {
          setPermissions({
            ...permissions,
            permissionGroups: permissionGroupsCpy,
          });
        }
      } else {
        setPermissions({
          ...permissions,
          permissionGroups: permissionGroupsCpy,
        });
      }
    }
  }

  function getUserSelected(permissions) {
    const userSelected = [...permissions.permissionUsers];
    permissions.permissionGroups.forEach((group) => {
      if (group.checked) {
        Object.keys(group.users).forEach((userId) => {
          const user = companyUsers.find((user) => user.id === userId);
          if (user && !userSelected.find((el) => el.id === userId)) {
            userSelected.push(user);
          }
        });
      }
    });
    return userSelected;
  }

  function getUserAndGroupPermissionsObj() {
    let permissionUsersObj = {};
    let permissionGroupsObj = {};
    const { permissionUsers, permissionGroups } = permissions;
    permissionUsers.forEach((user) => (permissionUsersObj[user.id] = true));
    permissionGroups.forEach((group) => {
      if (!!group.checked) {
        permissionGroupsObj[group.id] = true;
      }
    });
    return {
      permissionUsers: permissionUsersObj,
      permissionGroups: permissionGroupsObj,
    };
  }

  function handleSelectAllPermissionGroups(permissions) {
    let permissionGroupsCpy = [...permissions.permissionGroups];
    permissionGroupsCpy = permissionGroupsCpy.map((permissionGroup) => ({
      ...permissionGroup,
      checked: permissionGroup.enable,
    }));
    setPermissions({
      ...permissions,
      permissionGroups: permissionGroupsCpy,
    });
  }

  function handleSelectNonePermissionGroups(permissions) {
    let permissionGroupsCpy = [...permissions.permissionGroups];
    let permissionUsersCpy = [user];
    permissionGroupsCpy = permissionGroupsCpy.map((permissionGroup) => ({
      ...permissionGroup,
      checked: false,
    }));

    setPermissions({
      ...permissions,
      permissionGroups: permissionGroupsCpy,
      permissionUsers: permissionUsersCpy,
    });
  }

  const allowedToSave = () => {
    if (file.length) {
      return (
        typePermissionModal !== typeModalPermissions.VIEW_PERMISSIONS &&
        !file.every((f) => f.completed)
      );
    } else {
      return (
        typePermissionModal !== typeModalPermissions.VIEW_PERMISSIONS &&
        progressUpload !== 100
      );
    }
  };

  const getImageCompleted = (completed) => {
    if (completed) {
      return (
        <img
          className={"successfulUpload"}
          src={sucessfullUpload}
          alt="success"
        />
      );
    } else {
      return <div style={{ width: 16 }} />;
    }
  };
  const sortingFiles = () =>
    file.sort((a, b) => {
      if (a.completed && !b.completed) {
        return 1;
      } else if (!a.completed && b.completed) {
        return -1;
      } else {
        return 0;
      }
    });

  return (
    <ModalPermissionStyled
      isOpen={open}
      className="modal-container-permissions"
      id="modal-container-permission"
      modalClassName="modalClass"
      hasCloseIcon={false}
      bodyStyles={{
        width: 800,
      }}
    >
      <div className="modalTitle">
        <p className={"selectDocumentType"}>
          {title && !onlyTitle && <IntlMessages id={title} />}
          {onlyTitle && title}
        </p>
        {uploadMultipleFiles && (
          <div className={"wrongUploadFileContainer"}>
            <img
              className={"wrongUploadFile"}
              src={wrongUploadFile}
              alt="wrong upload file"
            />
            <p className={"onlyOneFileCanBe"}>
              {
                <IntlMessages id="components.documents.menupermission.onlyonefilecanbeuploadedatatime" />
              }
            </p>
          </div>
        )}
      </div>
      {warningMessage}
      {!file.length ? (
        <div
          className={
            typePermissionModal !== typeModalPermissions.VIEW_PERMISSIONS
              ? "modalDocumentSectionOnUpload"
              : "modalDocumentSectionUploaded"
          }
        >
          <div className={"fileInformation"}>
            <TypeFileSelector
              className="classesnamesicon"
              type={typeFile}
              placeholder={true}
              imgStyles={{ width: 32, height: 32, margin: 6 }}
              currentDocument={{
                mainFile: true,
              }}
            />
            <div className={"listItemTextPrimary"}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className={"filename"}>
                  {file.type === TYPE_OF_FILE.FOLDER
                    ? file.name
                    : file.name && getFileName(file.name)}
                </label>
              </div>

              <div className={"listItemTextSecondary"}>
                <label className={"fileinfo"}>
                  Added {moment().format("h:mm a M/D/YY")}
                </label>
                <label className={"filesize"}>
                  {file.type === TYPE_OF_FILE.FOLDER
                    ? "(" +
                      getSizeTransformedAccordingKBSize(
                        getFolderSize(0, file.child),
                        file.type
                      ) +
                      ")"
                    : "(" +
                      getSizeTransformedAccordingKBSize(+file.size, file.type) +
                      ")"}
                </label>
              </div>
            </div>
          </div>

          {typePermissionModal !== typeModalPermissions.VIEW_PERMISSIONS && (
            <div className={"fileInformation"}>
              <div className={"progressBar"}>
                <LinearProgress
                  variant="buffer"
                  value={progressUpload}
                  valueBuffer={0}
                  style={{ height: 8, width: 130 }}
                />
              </div>
              {typePermissionModal !== typeModalPermissions.VIEW_PERMISSIONS &&
                progressUpload === 100 && (
                  <img
                    className={"successfulUpload"}
                    src={sucessfullUpload}
                    alt="success"
                  />
                )}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            maxHeight: 230,
            overflow: "auto",
            marginBottom: 5,
          }}
        >
          {sortingFiles().map((f) => {
            const progressValue = progressUpload[f.name] || 0;
            return (
              <div
                className={
                  typePermissionModal !== typeModalPermissions.VIEW_PERMISSIONS
                    ? "modalDocumentSectionOnUpload"
                    : "modalDocumentSectionUploaded"
                }
                style={{ height: "auto" }}
                key={f.name + f.size}
              >
                <div className={"fileInformation"}>
                  <TypeFileSelector
                    className="classesnamesicon"
                    type={f.name && getTypeFile(f.name)}
                    placeholder={true}
                    imgStyles={{ width: 32, height: 32, margin: 6 }}
                    currentDocument={{
                      mainFile: true,
                    }}
                  />
                  <div className={"listItemTextPrimary"}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className={"filename"}>
                        {f.type === TYPE_OF_FILE.FOLDER
                          ? f.name
                          : f.name && getFileName(f.name)}
                      </label>
                    </div>

                    <div className={"listItemTextSecondary"}>
                      <label className={"fileinfo"}>
                        Added {moment().format("h:mm a M/D/YY")}
                      </label>
                      <label className={"filesize"}>
                        {f.type === TYPE_OF_FILE.FOLDER
                          ? "(" +
                            getSizeTransformedAccordingKBSize(
                              getFolderSize(0, f.child),
                              f.type
                            ) +
                            ")"
                          : "(" +
                            getSizeTransformedAccordingKBSize(+f.size, f.type) +
                            ")"}
                      </label>
                    </div>
                  </div>
                </div>
                {typePermissionModal !==
                  typeModalPermissions.VIEW_PERMISSIONS && (
                  <div className={"fileInformation"}>
                    <div className={"progressBar"}>
                      <LinearProgress
                        variant="buffer"
                        value={f.completed ? 100 : progressValue}
                        valueBuffer={0}
                        style={{ height: 8, width: 130 }}
                      />
                    </div>
                    {typePermissionModal !==
                      typeModalPermissions.VIEW_PERMISSIONS &&
                      getImageCompleted(f.completed)}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {typePermissionModal !== typeModalPermissions.FILE_CHILD_UPLOAD && (
        <div className={"modalPermissionsOnUpload"}>
          <div className={"documentType"}>
            <div className={"documentTypeTitleContainer"}>
              <label>
                {
                  <IntlMessages id="components.documents.modalpermission.documenttype" />
                }
              </label>
              <div className={"selectAllNoneContainer"}>
                <span
                  className="select-all-button"
                  onClick={() => handleSelectAllPermissionGroups(permissions)}
                >
                  Select all
                </span>
                -
                <span
                  className="select-none-button"
                  onClick={() => handleSelectNonePermissionGroups(permissions)}
                >
                  Clear
                </span>
              </div>
            </div>
            <div className={"permissionsGroupContainer"}>
              <div className={"multiselect"}>
                {permissions.permissionGroups
                  .sort(sortObjectsBy("name", false))
                  .map((permission, index) => (
                    <div
                      key={permission.id + index}
                      className={"permissionContent"}
                      onClick={(ev) => {
                        if (!!permission.enable) {
                          handleChangeGroups(permission, !permission.checked);
                        }
                        ev.stopPropagation();
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={permission.checked}
                        disabled={!permission.enable}
                        className="permissionCheck"
                      />
                      <CustomTooltip
                        label={getNewToolTipByPermissionGroup({
                          permission,
                          users: companyUsers,
                        })}
                        style={{ color: permission.enable ? "" : "#ccc" }}
                      >
                        {permission.name}
                      </CustomTooltip>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div
            className={"viewPermissionOnUpload"}
            style={{
              width: "100%",
            }}
          >
            <label>
              {
                <IntlMessages id="components.documents.modalpermission.viewpermission" />
              }
            </label>
            <ModalPermissionUsers
              users={companyUsers}
              userSelected={getUserSelected(permissions)}
              handleChange={handleChange}
              cancellableDeleteCurrentUser={true}
              currentUser={currentUser}
              notificatorErrorRemoveUser={notificatorErrorRemoveUser}
            />
          </div>
        </div>
      )}
      <div className={"modalButtonsSection"}>
        <button className="btn-cancel" onClick={handleCancel} autoFocus={true}>
          {<IntlMessages id="components.documents.modalpermission.cancel" />}
        </button>
        <button
          className="btn-done"
          onClick={() => {
            handleDone(getUserAndGroupPermissionsObj());
          }}
          disabled={allowedToSave()}
        >
          {<IntlMessages id="components.documents.modalpermission.done" />}
        </button>
      </div>
    </ModalPermissionStyled>
  );
}

export default ModalPermission;
