import React, { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import { getTheDate, getTextFromType } from "../../../helpers/helpers";
import { getUserAvatar } from "../../../helpers/users";
import {
  activityScopes,
  activityType,
} from "../../../helpers/activitiesStream";

import {
  ExpandActivityIcon,
  InfoIcon,
  LockNoteIcon,
  ReplyIcon,
} from "../../../helpers/iconSvgPath";
import TooltipTD from "../../Tooltip/TooltipTD";
import { NOTES_SCOPE } from "../../../helpers/constants";
import ReadByPopover from "./ReadByPopover";
import { emojisIcon } from "../../../helpers/emojisHelper";
import { cx } from "@emotion/css";
import { CircularProgress, Collapse } from "@mui/material";

const salesOrderScope = [activityScopes.SALES_ORDER, activityScopes.QUOTE];
const purchaseOrderScope = [
  activityScopes.PURCHASE_ORDER,
  activityScopes.PURCHASE_QUOTE,
];

function PaperNote({
  activity,
  users,
  alreadyReadNote,
  user,
  username,
  handleMarkAsRead,
  getLabelScope,
  addMentionBackdrop = () => {},
  handleOpenModal = () => {},
  onReplyActivity = () => {},
  onShowThread = () => {},
  noteThread,
  hasThreadPermission,
  focusActivity,
}) {
  const [hasReadMore, setHasReadMore] = useState(false);
  const [displayAll, setDisplayAll] = useState(true);
  const [updatingReadNote, setUpdatingReadNote] = useState(false);
  const ref = useRef();
  const contentRef = useRef();
  useEffect(() => {
    if (activity.type === activityType.NOTE) {
      const element = ref.current || {};
      const heightClient = element?.clientHeight || 23;
      if (!hasReadMore && heightClient >= 245) {
        setHasReadMore(true);
        setDisplayAll(false);
      } else if (heightClient <= 245 && hasReadMore) {
        setHasReadMore(false);
        setDisplayAll(true);
      }
    }
  }, [activity.detail]);

  function enableToEditStyles(enabledToEdit) {
    if (enabledToEdit) {
      return {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      };
    }
    return {};
  }
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target"] }),
    };
  };

  const getLockIcon = () => {
    if (activity.lockNote) {
      return (
        <TooltipTD
          hasArrow={true}
          label={"Only users tagged in this note can see it"}
          style={{ display: "flex" }}
        >
          <LockNoteIcon size={16} style={{ marginRight: 15 }} />
        </TooltipTD>
      );
    } else {
      return <div />;
    }
  };

  const getActivityClass = () => {
    return salesOrderScope.includes(activity.scope)
      ? NOTES_SCOPE.SALES_ORDER
      : purchaseOrderScope.includes(activity.scope)
      ? NOTES_SCOPE.PURCHASE_ORDER
      : NOTES_SCOPE.SHIPMENT;
  };

  const numberOfReplies = activity.numberOfReplies
    ? `(${(activity.numberOfReplies || 0) + 1})`
    : "";
  const isThread = activity.numberOfReplies || activity.threadId;
  const noteIsFinish =
    !activity.enableToEdit && activity.type === activityType.NOTE;
  const showDataThread = hasThreadPermission && noteIsFinish && !noteThread;
  return (
    <li
      ref={contentRef}
      style={
        activity.enableToEdit === user.id
          ? {
              borderBottomLeftRadius: "0px",
              marginBottom: 0,
              borderBottomRightRadius: "0px",
              paddingRight: 6,
            }
          : {
              paddingRight: 6,
            }
      }
    >
      <div className="activity-timestamp">
        <span className="timestamp" style={{ marginLeft: 5 }}>
          {getTheDate(activity.creationDate, "h:mm a")}
        </span>
      </div>

      <div
        id={"activity-backdrop-" + activity.id}
        className={cx("activityStyle", getActivityClass())}
        style={{
          zIndex: focusActivity ? 1201 : 12,
          ...enableToEditStyles(activity.enableToEdit),
        }}
      >
        {activity.type !== activityType.WITHOUT_TITLE ? (
          <div className="headerNote">
            <span style={{ width: isThread ? 35 : "50%" }}>
              {getTextFromType(activity.type)}
            </span>
            {showDataThread && isThread && (
              <div
                className={cx("threadStyle", activity.scope)}
                onClick={() => {
                  onShowThread(activity);
                }}
              >
                {emojisIcon[activity.iconIndex] && (
                  <img
                    width={16}
                    style={{ marginRight: 6 }}
                    src={emojisIcon[activity.iconIndex].src}
                    alt={emojisIcon[activity.iconIndex].alt}
                  />
                )}
                <span>Show this thread {numberOfReplies}</span>
                {activity.numberOfReplies && (
                  <TooltipTD
                    label="This is the first note of this thread"
                    showToolTip
                  >
                    <InfoIcon style={{ marginLeft: 5, marginBottom: -2 }} />
                  </TooltipTD>
                )}
              </div>
            )}
            <div className="icons">
              {showDataThread && !isThread && (
                <TooltipTD
                  style={{
                    cursor: "pointer",
                    marginRight: 15,
                    display: "flex",
                  }}
                  label={"Reply to this note"}
                >
                  <ReplyIcon
                    height={15}
                    onClick={() => onReplyActivity(activity)}
                  />
                </TooltipTD>
              )}
              {getLockIcon()}
              {activity.readByUsers &&
                Object.keys(activity.readByUsers).length > 0 && (
                  <ReadByPopover activity={activity} users={users} />
                )}
              <span className="scope">{getLabelScope(activity.scope)}</span>
            </div>
          </div>
        ) : (
          <div className="activity-scope-label">
            {getLabelScope(activity.scope)}
          </div>
        )}
        <div
          className={"bodyNote"}
          style={{
            paddingTop: activity.type !== activityType.WITHOUT_TITLE ? 7 : 0,
          }}
        >
          <div className="avatarNote">
            {activity.user &&
              getUserAvatar({
                user: users.find((user) => user.id === activity.user),
                styles: {
                  outline: "2px solid #000",
                  zIndex: 1,
                  top: activity.type !== activityType.WITHOUT_TITLE ? 0 : 20,
                },
              })}
          </div>

          {!hasReadMore && (
            <div
              id={"note-detail" + activity.id}
              ref={ref}
              className={"note-detail"}
              dangerouslySetInnerHTML={createMarkup(activity.detail)}
              onClick={(ev) => addMentionBackdrop({ ev, activity })}
              style={{
                padding:
                  activity.type !== activityType.WITHOUT_TITLE
                    ? "14px 20px 0px 74px"
                    : "0px 20px 0px 74px",
              }}
            />
          )}
          {hasReadMore && (
            <Collapse in={displayAll} collapsedSize={235}>
              <div
                ref={ref}
                id={"note-detail" + activity.id}
                className={"note-detail"}
                dangerouslySetInnerHTML={createMarkup(activity.detail)}
                onClick={(ev) => addMentionBackdrop({ ev, activity })}
              />
            </Collapse>
          )}
          <div className="readGrid">
            {updatingReadNote ? (
              <CircularProgress size={12} />
            ) : (
              alreadyReadNote && (
                <div
                  onClick={() => {
                    setUpdatingReadNote(true);
                    handleMarkAsRead();
                    setTimeout(() => {
                      setUpdatingReadNote(false);
                    }, 500);
                  }}
                  className={
                    alreadyReadNote === "read"
                      ? "activity-notification-read"
                      : "activity-notification-unread"
                  }
                />
              )
            )}
          </div>
        </div>
        <div className={"footer"}>
          {activity.user && <span className="username">{username}</span>}
          <div>
            {hasReadMore && (
              <span
                className="readMore"
                onClick={() => {
                  setDisplayAll(!displayAll);
                  if (!!displayAll) {
                    setTimeout(() => {
                      contentRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }, 300);
                  }
                }}
              >
                {displayAll ? ` Show Less ` : ` Read more `}
              </span>
            )}
          </div>
          {activity.type === activityType.NOTE && (
            <ExpandActivityIcon
              onClick={() => handleOpenModal(activity)}
              svgClass={cx("expandIcon", "iconVisibility")}
            />
          )}
        </div>
      </div>
    </li>
  );
}

export default PaperNote;
