import React from "react";
import {
  collapsedTaskTableColumns,
  dayHeight,
  dayWidth,
  expandedTaskTableColumns,
  simpleTaskTableColumns,
  taskTableHeader,
} from "../../helpers/constants";
import TooltipTD from "../Tooltip/TooltipTD";
import { TaskTableHeaderStyled } from "./styles";
import expandIcon from "../../assets/flag-icons/collapse-filled-icon.png";
import expandSectionIcon from "../../assets/flag-icons/Combined Shape.png";
import {
  TABLE_HEADERS_GANTT_CHART,
  viewOptionLabels,
} from "../../helpers/timelineCalendar";

function TaskTableHeader({
  styles,
  columnIndex,
  tableSize,
  onChangeTableSize,
  isOpenAllSections,
  onChangeOpenAllSection,
  flagViewOptions,
}) {
  function getHeader({ key }) {
    switch (key) {
      case TABLE_HEADERS_GANTT_CHART.STATUS:
        return tableSize !== collapsedTaskTableColumns ? (
          <div
            className={"tableHeaderText"}
            style={{
              width: dayWidth * 3,
              height: dayHeight,
              cursor: "pointer",
            }}
          >
            <TooltipTD label={"Status"}>{taskTableHeader.STATUS}</TooltipTD>
          </div>
        ) : (
          <div
            className={"tableHeaderText"}
            style={{ width: dayWidth * 3, height: dayHeight }}
          >
            <div style={{ width: dayWidth }}>
              <TooltipTD
                label={
                  isOpenAllSections ? "Close all sections" : "Open all sections"
                }
              >
                <img
                  style={{
                    transform: isOpenAllSections
                      ? "rotate(270deg)"
                      : "rotate(90deg)",
                    width: 8,
                    height: 14,
                    cursor: "pointer",
                  }}
                  src={expandSectionIcon}
                  alt="expand"
                  onClick={() => onChangeOpenAllSection(!isOpenAllSections)}
                />
              </TooltipTD>
            </div>
            <div style={{ width: dayWidth }}>
              <TooltipTD label={"Expand table"}>
                <img
                  style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                  src={expandIcon}
                  alt="expand"
                  onClick={() => {
                    if (flagViewOptions[viewOptionLabels.FULL_TABLE_DETAILS]) {
                      console.log("enter here");
                      return onChangeTableSize(expandedTaskTableColumns);
                    }
                    return onChangeTableSize(simpleTaskTableColumns);
                  }}
                />
              </TooltipTD>
            </div>
          </div>
        );

      case TABLE_HEADERS_GANTT_CHART.REFERENCE:
        return (
          tableSize === expandedTaskTableColumns && (
            <div
              className={"tableHeaderText"}
              style={{ width: dayWidth * 2, height: dayHeight }}
            >
              <TooltipTD label={"Reference"}>{taskTableHeader.REF}</TooltipTD>
            </div>
          )
        );
      case TABLE_HEADERS_GANTT_CHART.TASK_NAME:
        return (
          <div
            className={"tableHeaderText"}
            style={{
              width: dayWidth * 11,
              height: dayHeight,
              justifyContent: "flex-start",
            }}
          >
            {taskTableHeader.DESCRIPTION}
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.PREDECESOR:
        return (
          <div
            className={"tableHeaderText"}
            style={{ width: dayWidth * 2, height: dayHeight }}
          >
            <TooltipTD label="Predecessor">
              {taskTableHeader.PREDECESOR}
            </TooltipTD>
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.DEPENDENCY:
        return (
          <div
            className={"tableHeaderText"}
            style={{ width: dayWidth * 2, height: dayHeight }}
          >
            <TooltipTD label="Dependency type">
              {taskTableHeader.DEPENDENCY_TYPE}
            </TooltipTD>
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.DURATION:
        return (
          <div
            className={"tableHeaderText"}
            style={{ width: dayWidth * 2, height: dayHeight }}
          >
            <TooltipTD label="Duration">{taskTableHeader.DURATION}</TooltipTD>
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.START_DATE:
        return (
          <div
            className={"tableHeaderText"}
            style={{ width: dayWidth * 3, height: dayHeight }}
          >
            {taskTableHeader.START_DATE}
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.END_DATE:
        return (
          <div
            className={"tableHeaderText"}
            style={{ width: dayWidth * 3, height: dayHeight }}
          >
            {taskTableHeader.END_DATE}
          </div>
        );
      case TABLE_HEADERS_GANTT_CHART.ASSIGNED_TO:
        return (
          <div
            className={"tableHeaderText"}
            style={{ width: dayWidth * 5, height: dayHeight }}
          >
            {taskTableHeader.ASSIGNED_TO}
          </div>
        );
      default:
        return "default header";
    }
  }

  function getFullTableHeader(columnIndex) {
    switch (columnIndex) {
      case 1:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.STATUS });
      case 4:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.REFERENCE });
      case 6:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.TASK_NAME });
      case 17:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.PREDECESOR });
      case 19:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.DEPENDENCY });
      case 21:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.DURATION });
      case 23:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.START_DATE });
      case 26:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.END_DATE });
      case 29:
        return getHeader({ key: TABLE_HEADERS_GANTT_CHART.ASSIGNED_TO });
      default:
        return null;
    }
  }
  return (
    <TaskTableHeaderStyled
      style={styles}
      elevation={0}
      className="taskTableHeaderContainer"
    >
      {tableSize === collapsedTaskTableColumns ||
      flagViewOptions[viewOptionLabels.FULL_TABLE_DETAILS]
        ? getFullTableHeader(columnIndex)
        : null}
    </TaskTableHeaderStyled>
  );
}

export default TaskTableHeader;
