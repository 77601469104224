
import Task from "./task";


export default class PurchaseOrderTask extends Task {
  constructor({ listIndex = 0, ...rest }) {
    super(rest);
    this.listIndex = listIndex;
  }
}
