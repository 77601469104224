import React from "react";
import TaskListScreen from "../../container/TaskListScreen/TaskListScreen";
import ReportScreen from "../../container/ReportScreen/ReportScreen";
import SalesOrdersScreen from "../../container/SalesOrdersScreen/SalesOrdersScreen";
import PurchaseOrderScreen from "../../container/PurchaseOrdersScreen/PurchaseOrderScreen";
import ShipmentsScreen from "../../container/Shipments/ShipmentsScreen";
import OrdersByPhase from "../../container/OrdersByPhase/OrdersByPhase";
import WeeklyView from "../../container/WeeklyView/WeeklyView";
import ExpensesScreen from "../../container/ExpensesScreen/ExpensesScreen";

export const ROUTES = [
  {
    menu_title: "Reports",
    path: "reports",
    element: <ReportScreen />,
  },
  {
    menu_title: "Sales Orders",
    path: "sales_orders",
    element: <SalesOrdersScreen />,
  },
  {
    menu_title: "Purchase Orders",
    path: "purchase_orders",
    element: <PurchaseOrderScreen />,
  },
  {
    menu_title: "Tasks",
    path: "tasks",
    element: <TaskListScreen />,
  },
  {
    menu_title: "Shipments",
    path: "shipments",
    element: <ShipmentsScreen />,
  },
  {
    menu_title: "Orders by Phase",
    path: "orders_by_phase",
    element: <OrdersByPhase />,
  },
  {
    menu_title: "Week",
    path: "week",
    element: <WeeklyView />,
  },
  {
    menu_title: "Expenses",
    path: "expenses",
    element: <ExpensesScreen />,
  },
];
