import React, { useState, useEffect, useRef, useCallback } from "react";
import { FilterTaskContainer, TaskTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter from "../../components/PurchaseOrderList/ButtonFilter";
import { useCompanyId, useJSONLocalStorage } from "../../hooks";
import {
  getTheDate,
  useTaskNotificationCreator,
  sortObjectsBy,
  getRandomId,
  taskNotificationType,
  debounce,
} from "../../helpers/helpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  dbTables,
  typeOfTask,
  USER_CUSTOM_SETTINGS,
} from "../../api/types/dbTables";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import moment from "moment";
import SearchBox from "../../components/SearchBox/SearchBox";
import POTaskRow from "./POTaskRow";
import EmptyMessage from "../../components/General/EmptyMessage";
import { tasksHeaderCells } from "../../components/Managers/TableHeaderProvider";
import { getToolTipArrayValue } from "../../helpers/purchaseOrderList";
import AttentionModal from "../../components/Modal/AttentionModal";
// import { taskData } from "../../helpers/TaskData";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import BasicPagination from "../../components/Tables/BasicPagination";
import {
  excludeElementsScreen,
  headerColumns,
  taskStatus,
  TYPE_OF_TAGS,
} from "../../helpers/constants";
import { filterTaskList, triggerTaskTypes } from "../../helpers/tasks";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import {
  useUser,
  useCompanyUsers,
  useUserCustomSetting,
} from "../../hooks/user";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { useTags } from "../../hooks/tags";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import { getFilteredTaskStatus } from "../../helpers/screens";
import {
  getCorrectTimezone,
  taskCompletionVerifier,
} from "../../helpers/ganttChart";
import { firestore, performanceFirebase } from "../../firebase";
import { trace } from "firebase/performance";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { doc, updateDoc } from "firebase/firestore";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import Mark from "mark.js";
import TaskTableRow from "./TaskTableRow";
import { useMediaQuery } from "@mui/material";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import TaskGroupHeader from "./TaskGroupHeader";
import { cx } from "@emotion/css";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { isEqual } from "lodash";
import DiamondLegend from "../../components/LegendStatus/DiamondLegend";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import TaskStatusFilters from "../../components/ListViews/TaskStatusFilters";

const dateFields = ["Start Date", "Due Date"];
const groupByPOFields = [
  headerColumns.DIAMOND,
  headerColumns.TASK,
  headerColumns.ASSIGNED_TO,
  headerColumns.START_DATE,
  headerColumns.DUE_DATE,
  headerColumns.ACTION,
  headerColumns.EXTRA,
];

const BODY_ID = "taskListTableRow";

function TaskListScreen() {
  const dateRef = useRef(null);
  const { get, set } = useJSONLocalStorage("taskScreenFilters");
  const dataLocalStorage = get() || {};
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const companyId = useCompanyId();
  const tags = useTags();
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [POGroups, setPOGroups] = useState([]);

  const isAllowed = useIsAllowedFunction();
  const [filters, setFilters] = useState({
    assignedTo: dataLocalStorage.assignedTo || [],
    customer: dataLocalStorage.customer || [],
    factory: dataLocalStorage.factory || [],
    tag: dataLocalStorage.tag || [],
    completed: dataLocalStorage.completed || false,
    groupByPOs: dataLocalStorage.groupByPOs || false,
    inProgress: dataLocalStorage.inProgress || false,
    late: dataLocalStorage.late || false,
    nearDue: dataLocalStorage.nearDue || false,
    notStarted: dataLocalStorage.notStarted || false,
    date: dataLocalStorage.date || false,
    dateRange: dataLocalStorage.dateRange || "",
  });

  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    query: dataLocalStorage.query || "",
    sortedColumn: dataLocalStorage.sortedColumn || "number",
    orderBy: dataLocalStorage.orderBy || "ASC",
    groupByPOs: dataLocalStorage.groupByPOs || false,
  });
  const [loading, setLoading] = useState(false);
  const customers = useCustomers();
  const factories = useFactories();
  const companyUsers = useCompanyUsers({ id: companyId });
  const currentUser = useUser();
  const [filterDate, setFilterDate] = useState(
    dataLocalStorage.date || "Due Date"
  );
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const [openDate, setOpenDate] = useState(false);
  const createTaskNotification = useTaskNotificationCreator();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [descriptionAttentionModal, setDescriptionAttentionModal] =
    useState("");
  const [lastFilter, setLastFilter] = useState("");
  const [headerCells, setHeaderCells] = useState([]);

  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  const onDebounceMark = useCallback(
    debounce(() => {
      performMark(nonLookUpfilters.query);
    }, 300),
    [nonLookUpfilters.query]
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
      setPage(1);
    }
  }

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      return;
    }
    setTasks([]);
    setFilteredTasks([]);
    setLoading(true);
    set({ ...nonLookUpfilters, ...filters, rowsPerPage });
    getTasks();
    setPage(1);
  }, [callFunction]);

  useEffect(() => {
    setPage(1);
    set({ ...filters, ...nonLookUpfilters, rowsPerPage });
    if (!tasks.length) return;
    const filteredList = filterTaskList({
      list: tasks,
      headerCells,
      nonLookUpfilters,
    });
    if (nonLookUpfilters.groupByPOs) {
      groupTasksByPO(filteredList);
    } else {
      setFilteredTasks(filteredList);
    }
  }, [nonLookUpfilters]);

  useEffect(() => {
    set({ ...filters, ...nonLookUpfilters, rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    function onAdjustHeader() {
      const filteredList = filterTaskList({
        list: tasks,
        headerCells,
        nonLookUpfilters,
      });
      const datatoShow = filteredList.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      );

      let maxWidthPONumber = 45;
      let maxWidthSONumber = 85;
      let maxAssignedToName = 85;
      let maxCustomerName = 50;
      let maxVendorName = 50;
      let maxTaskDescription = 150;
      datatoShow.forEach((task) => {
        const poWidth = task.PONumber
          ? task.PONumber.toString().length * 7.5
          : 0;
        const soWidth = task.SONumber
          ? task.SONumber.toString().length * 7.5
          : 0;
        const assignedNameWidth = task.assignedToName
          ? task.assignedToName.toString().length * 8
          : 0;
        const customerNameWidth = task.customerName
          ? task.customerName.toString().length * 7.25
          : 0;
        const vendorNameWidth = task.factoryName
          ? task.factoryName.toString().length * 7.25
          : 0;
        const descriptionWidth = task.description
          ? task.description.toString().length * 8
          : 0;
        if (poWidth > maxWidthPONumber) {
          maxWidthPONumber = poWidth;
        }
        if (soWidth > maxWidthSONumber) {
          maxWidthSONumber = soWidth;
        }
        if (assignedNameWidth > maxAssignedToName) {
          maxAssignedToName = assignedNameWidth;
        }
        if (customerNameWidth > maxCustomerName) {
          maxCustomerName = customerNameWidth;
        }
        if (vendorNameWidth > maxVendorName) {
          maxVendorName = vendorNameWidth;
        }
        if (descriptionWidth > maxTaskDescription) {
          maxTaskDescription = descriptionWidth;
        }
      });

      let userHeaderCell = userCustomSettings.taskHeaderSetup || [];
      userHeaderCell = userHeaderCell.map((header) => {
        return { ...header, ...tasksHeaderCells[header.name] };
      });

      let headerCellsCpy = [...userHeaderCell];
      headerCellsCpy = headerCellsCpy.map((headerCell) => {
        if (headerCell.reference === "PONumber") {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxWidthPONumber + 90 },
          };
        } else if (headerCell.reference === "SONumber") {
          return {
            ...headerCell,
            styles: {
              ...headerCell.styles,
              width: maxWidthSONumber + 90,
              maxWidth: "unset",
            },
          };
        } else if (headerCell.reference === "assignedToName") {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxAssignedToName + 40 },
          };
        } else if (headerCell.reference === "customerName") {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxCustomerName + 60 },
          };
        } else if (headerCell.reference === "factoryName") {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxVendorName + 40 },
          };
        } else if (headerCell.reference === "description") {
          return {
            ...headerCell,
            styles: { ...headerCell.styles, width: maxTaskDescription + 70 },
          };
        } else {
          return headerCell;
        }
      });
      setHeaderCells(headerCellsCpy);
    }
    onAdjustHeader();
  }, [rowsPerPage, page, nonLookUpfilters, tasks]);

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }

  function groupTasksByPO(tasks) {
    const purchaseOrderTasksGrouped = {};
    tasks.forEach((task) => {
      purchaseOrderTasksGrouped[task.PONumber]
        ? purchaseOrderTasksGrouped[task.PONumber].push(task)
        : (purchaseOrderTasksGrouped[task.PONumber] = [task]);
    });
    setPOGroups(purchaseOrderTasksGrouped);
  }

  async function getTasks() {
    setPreviousFilter(filters);
    const traceInstance = trace(performanceFirebase, "task_view_db_lookup");
    traceInstance.start();
    setLoading(true);
    const { status } = getFilteredTaskStatus({ filters });
    const filtersToSend = {
      ...filters,
      dateStart: filters.dateRange
        ? moment(filters.dateRange.start).valueOf()
        : "",
      dateEnd: filters.dateRange ? moment(filters.dateRange.end).valueOf() : "",
      status,
    };
    delete filtersToSend.dateRange;
    try {
      const functions = getFunctions();
      const tasksEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview",
          env: globalEnvironment,
          params: "/tasks",
        })
      );
      tasksEndpoint({
        companyId: companyId,
        userId: user.id,
        filters: filtersToSend,
      }).then((result) => {
        const { tasksDB } = result.data;
        setTasks([...tasksDB]);

        const filteredList = filterTaskList({
          list: tasksDB,
          headerCells,
          nonLookUpfilters,
        });
        setFilteredTasks(filteredList);
        if (nonLookUpfilters.groupByPOs) {
          groupTasksByPO(filteredList);
        }
        traceInstance.stop();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      traceInstance.stop();
      setLoading(false);
    }
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => markInstance.mark(keyword, options), 150);
      },
    });
  }

  useEffect(() => {
    if (!loading) {
      onDebounceMark();
    }
  }, [
    nonLookUpfilters,
    loading,
    rowsPerPage,
    page,
    nonLookUpfilters.groupByPOs,
  ]);

  useEffect(() => {
    const filteredList = filterTaskList({
      list: tasks,
      headerCells,
      nonLookUpfilters,
    });
    setFilteredTasks(filteredList);
    if (nonLookUpfilters.groupByPOs) {
      groupTasksByPO(filteredList);
    }
  }, [headerCells]);

  useEffect(() => {
    if (window.location.href.includes("redirectFromEmail")) {
      const assignedTo = [];
      assignedTo.push(user.id);
      setFilters({
        factory: [],
        customer: [],
        assignedTo,
        late: true,
        favorite: false,
        nearDue: true,
        inProgress: false,
        complete: false,
      });
      setNonLookUpFilters({
        query: "",
      });
      getTasks();
    }
  }, []);

  function toggleTask(task) {
    const dayOffset = moment()
      .startOf("day")
      .diff(moment(task.finishDate).startOf("day"), "days");
    const newArray = [...tasks];
    const { duration, startDate, finishDate } = taskCompletionVerifier({
      task: {
        ...task,
        startDate: getCorrectTimezone({
          date: task.startDate,
          isServerTime: true,
        }).valueOf(),
        finishDate: getCorrectTimezone({
          date: task.finishDate,
          isServerTime: true,
        }).valueOf(),
      },
    });
    const taskIndex = newArray.findIndex(
      (item) =>
        task.id === item.id &&
        task.salesOrderId === item.salesOrderId &&
        task.purchaseOrderId === item.purchaseOrderId
    );
    if (!task.complete) {
      const notificationId = getRandomId();
      if (dayOffset !== 0) {
        const currentShipment = task.shipment || {};
        createTaskNotification({
          task: {
            ...task,
            completedBy: currentUser.id,
            PONumber: task.PONumber,
            SONumber: task.SONumber,
            shipmentNumber: currentShipment.number,
            SOT: !task.factoryId ? true : false,
            duration,
          },
          randomId: notificationId,
          type: taskNotificationType.COMPLETED,
        });
      }
      newArray[taskIndex] = {
        ...newArray[taskIndex],
        complete: !task.complete,
        completedBy: user.id,
        status: taskStatus.COMPLETE,
        completing: false,
      };
      setTasks(newArray);
      const filteredList = filterTaskList({
        list: newArray,
        headerCells,
        nonLookUpfilters,
      });
      if (nonLookUpfilters.groupByPOs) {
        groupTasksByPO(filteredList);
      } else {
        setFilteredTasks(filteredList);
      }
      const fieldsToUpdate = {
        complete: !task.complete,
        completedBy: currentUser.id,
        status: taskStatus.COMPLETE,
        dayOffset,
        moved: true,
        triggerType: triggerTaskTypes.COMPLETE,
        duration,
        startDate,
        finishDate,
      };
      if (task.type === typeOfTask.SHIPMENT) {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${task.shipmentId}/${dbTables.SHIPMENT_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      } else if (task.type === typeOfTask.SALES_ORDER) {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${task.salesOrderId}/${dbTables.SALES_ORDER_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      } else {
        updateDoc(
          doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${task.purchaseOrderId}/${dbTables.PURCHASE_ORDER_TASKS}/${task.id}`
          ),
          {
            ...fieldsToUpdate,
          }
        );
      }
    }
  }

  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilterDate(field);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 50);
  }

  function resetFilters() {
    setFilters({
      assignedTo: [user.id],
      factory: [],
      customer: [],
      dateRange: "",
      date: "",
      late: true,
      nearDue: false,
      inProgress: false,
      notStarted: false,
      completed: false,
    });

    setNonLookUpFilters({ ...nonLookUpfilters, query: "", groupByPOs: false });
    setFilterDate("");
    setExistsDate(false);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filterDate,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
    if (!filterDate) {
      setFilters({ ...filters, date: "" });
    }
  }

  const matches = useMediaQuery("(max-width:1425px)");

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function handleOpenAttentionModal(value) {
    if (value === "adding-button-filters-complete") {
      setDescriptionAttentionModal(
        <React.Fragment>
          Are you sure that you want to load ALL the completed tasks?
          <br />
          There could be many thousands and take a long time. It is a good idea
          to select a date range first.
        </React.Fragment>
      );
      setLastFilter("filters");
    } else {
      setDescriptionAttentionModal(
        <React.Fragment>
          Are you sure that you want to remove the date filter? <br /> This will
          load ALL the completed tasks and there could be many thousands and
          take a long time. It is a good idea first remove the completed task
          filter.
        </React.Fragment>
      );
      setLastFilter("filters");
    }
    setOpenAttentionModal(true);
  }

  function changeNonLookUpButtonFilter(filter) {
    setNonLookUpFilters({
      ...nonLookUpfilters,
      [filter]: !nonLookUpfilters[filter],
    });
  }

  function handleUpdateItem(item, tagReference) {
    let tasksCpy = [...tasks];
    if (tagReference === dbTables.SALES_ORDERS) {
      tasksCpy = tasksCpy.map((task) => {
        if (task.salesOrderId === item.salesOrderId) {
          return { ...task, salesOrder: item };
        }
        return task;
      });
    } else {
      tasksCpy = tasksCpy.map((task) => {
        const purchaseOrderId = task.purchaseOrderId || task.purchaseOrder.id;
        if (purchaseOrderId === item.purchaseOrderId) {
          return { ...task, purchaseOrder: item };
        }
        return task;
      });
    }
    setTasks([...tasksCpy]);
    const filteredList = filterTaskList({
      list: tasksCpy,
      headerCells,
      nonLookUpfilters,
    });
    setFilteredTasks(filteredList);
    if (nonLookUpfilters.groupByPOs) {
      groupTasksByPO(filteredList);
    }
  }

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounce();
  };

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={descriptionAttentionModal}
          title="Attention"
          cancellable={true}
          onClose={() => {
            setOpenAttentionModal(false);
            setLastFilter("");
            setDescriptionAttentionModal(<React.Fragment></React.Fragment>);
            setOpenList(false);
          }}
          onClick={() => {
            setOpenList(false);
            if (lastFilter === "filters") {
              changeFilters({
                ...filters,
                completed: !filters["completed"],
              });
            }
            setOpenAttentionModal(false);
            setDescriptionAttentionModal(<React.Fragment></React.Fragment>);
            setLastFilter("");
          }}
          confirmationText="Proceed"
          cancelText="Cancel"
          acceptBlue={false}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}

      <FilterTaskContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            currentFilters={filters}
            enabledFields={{
              customer: true,
              assignedTo: true,
              factory: true,
              date: true,
              tag: true,
            }}
            enableExport={false}
            onOpenDatePicker={onOpenDatePicker}
            dateFields={dateFields}
            tagFilters={[TYPE_OF_TAGS.MISMATCH_TAG, TYPE_OF_TAGS.FILE_TAG]}
          />
          <SearchBox
            placeholder="Search for PO or Sales Order or shipment number, Task, etc."
            onDebounceValue={(nonLookUpfilters, value) => {
              setNonLookUpFilters({
                ...nonLookUpfilters,
                query: value,
              });
            }}
            value={nonLookUpfilters.query}
            filters={nonLookUpfilters}
            hasBeenReseted={(nonLookUpfilters, value) => {
              setNonLookUpFilters({
                ...nonLookUpfilters,
                query: value,
              });
            }}
          />
        </div>
        <div className="contentContainer">
          <div className="contentOptions">
            <DiamondLegend />
            <div className="buttonsFilter">
              <TaskStatusFilters
                filters={filters}
                changeButtonFilter={changeButtonFilter}
                existsDate={existsDate}
                handleOpenAttentionModal={() => {
                  setOpenList(true);
                  handleOpenAttentionModal("adding-button-filters-complete");
                }}
                changeFilters={changeFilters}
              />

              <ButtonFilter
                buttonId="list-view-button-filter-groupbypo"
                value={nonLookUpfilters.groupByPOs}
                onClick={() => changeNonLookUpButtonFilter("groupByPOs")}
              >
                {matches ? <span>ByPO</span> : <span>Group By PO</span>}
              </ButtonFilter>

              {filters.assignedTo && filters.assignedTo.length > 0 && (
                <FilterDisplay
                  clearIconId="list-view-filter-badge-clear-assignedto"
                  onClear={() => clearFilterData({ filterKey: "assignedTo" })}
                  label="Assigned To"
                  tooltip={
                    getToolTipArrayValue(
                      filters.assignedTo,
                      companyUsers,
                      "displayName"
                    ).tooltip
                  }
                  value={
                    getToolTipArrayValue(
                      filters.assignedTo,
                      companyUsers,
                      "displayName"
                    ).value
                  }
                  filters={filters}
                />
              )}
              {filters.customer && filters.customer.length > 0 && (
                <FilterDisplay
                  clearIconId="list-view-filter-badge-clear-customer"
                  onClear={() => clearFilterData({ filterKey: "customer" })}
                  label={
                    filters.customer.length === 1 ? "Customer" : "Customers"
                  }
                  tooltip={
                    getToolTipArrayValue(filters.customer, customers).tooltip
                  }
                  value={
                    getToolTipArrayValue(filters.customer, customers).value
                  }
                  filters={filters}
                />
              )}
              {filters.factory && filters.factory.length > 0 && (
                <FilterDisplay
                  clearIconId="list-view-filter-badge-clear-factory"
                  onClear={() => clearFilterData({ filterKey: "factory" })}
                  label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                  tooltip={
                    getToolTipArrayValue(filters.factory, factories).tooltip
                  }
                  value={getToolTipArrayValue(filters.factory, factories).value}
                  filters={filters}
                />
              )}

              {isAllowed("see_tags") &&
                filters.tag &&
                filters.tag.length > 0 && (
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-label"
                    onClear={() => clearFilterData({ filterKey: "tag" })}
                    label={filters.tag.length === 1 ? "Label" : "Labels"}
                    tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                    value={
                      tags && getToolTipArrayValue(filters.tag, tags).value
                    }
                    filters={filters}
                  />
                )}

              {existsDate && (
                <React.Fragment>
                  <div
                    onClick={() => {
                      setOpenDate(true);
                      setOpenList(true);
                    }}
                    ref={dateRef}
                  >
                    <FilterDisplay
                      clearIconId="list-view-filter-badge-clear-date"
                      onClear={() => {
                        setFilterDate("");
                        setExistsDate(false);
                        setFilters({
                          ...filters,
                          date: "",
                          dateRange: "",
                        });
                        setOpenDate(false);
                        setOpenList(false);
                        onDebounce();
                      }}
                      label={filters.date}
                      filters={filters}
                      value={
                        filters.dateRange
                          ? `${getTheDate(
                              filters.dateRange.start
                            )} - ${getTheDate(filters.dateRange.end)}`
                          : "Pick a date"
                      }
                    />
                  </div>
                  {openDate && (
                    <DateRangePicker
                      open={openDate}
                      el={dateRef.current}
                      onClose={handleCloseDatePicker}
                      onChange={changeDate}
                      value={filters.dateRange}
                      cancelIcon={true}
                    />
                  )}
                </React.Fragment>
              )}
              <ButtonReset
                id="list-view-button-filter-reset"
                className="root"
                onClick={resetFilters}
              >
                {matches ? "RESET" : "RESET FILTERS"}
              </ButtonReset>
            </div>
          </div>
          <div className="contentSettings">
            {!nonLookUpfilters.groupByPOs && (
              <MultipleSelect
                items={headerCells.sort(sortObjectsBy("index", false))}
                onChange={setHeaderCells}
                type={columnHeaderSetup.TASK_HEADER_SETUP}
                onClose={() => {
                  let newItemsCpy = [...headerCells];
                  newItemsCpy = newItemsCpy.map((item) => ({
                    enabled: item.enabled,
                    index: item.index,
                    name: item.name,
                    unmovable: item.unmovable || false,
                  }));
                  updateDoc(
                    doc(
                      firestore,
                      `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
                    ),
                    {
                      taskHeaderSetup: newItemsCpy,
                    }
                  );
                }}
              />
            )}
          </div>
          <TaskTableContainer
            className={cx("contentListTask", {
              groupContainer: nonLookUpfilters.groupByPOs,
            })}
            id={BODY_ID}
          >
            {nonLookUpfilters.groupByPOs &&
              (Object.keys(POGroups).length > 0 ? (
                Object.keys(POGroups).map((key) => {
                  const item = POGroups[key][0];
                  const {
                    salesOrder = {},
                    purchaseOrder = {},
                    shipment,
                    customerName,
                    SONumber,
                    PONumber,
                    factoryName,
                  } = item;
                  return (
                    <React.Fragment>
                      <TaskGroupHeader
                        SONumber={SONumber}
                        PONumber={PONumber}
                        currentTags={filters.tag}
                        handleUpdateItem={handleUpdateItem}
                        purchaseOrder={purchaseOrder}
                        salesOrder={salesOrder}
                        shipment={shipment}
                        customerName={customerName}
                        factoryName={factoryName}
                        currentGroup={POGroups[key]}
                      />

                      <MainTableRefactor
                        headCells={headerCells
                          .filter((cell) => groupByPOFields.includes(cell.name))
                          .sort(sortObjectsBy("index", false))}
                        bodyRows={POGroups[key]}
                        renderRow={(item, index) => (
                          <POTaskRow
                            key={item.id + index}
                            task={item}
                            index={index}
                            onComplete={toggleTask}
                            headerCells={headerCells
                              .filter((cell) =>
                                groupByPOFields.includes(cell.name)
                              )
                              .sort(sortObjectsBy("index", false))}
                            companyUsers={companyUsers}
                          />
                        )}
                        screenName="TaskListScreen"
                        filters={nonLookUpfilters}
                        onUpdateFilters={(nonLookUpfilters) => {
                          setPage(1);
                          setNonLookUpFilters({ ...nonLookUpfilters });
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "white",
                          height: 38,
                          width: "100%",
                        }}
                      />
                    </React.Fragment>
                  );
                })
              ) : (
                <div>
                  <EmptyMessage />
                </div>
              ))}
            {!nonLookUpfilters.groupByPOs && (
              <MainTableRefactor
                headCells={headerCells.sort(sortObjectsBy("index", false))}
                bodyRows={filteredTasks.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage
                )}
                renderRow={(item, index) => {
                  return (
                    <TaskTableRow
                      key={item.id + index}
                      task={item}
                      index={index}
                      onComplete={toggleTask}
                      companyUsers={companyUsers}
                      filters={filters}
                      tags={tags}
                      onUpdateItem={handleUpdateItem}
                      user={currentUser}
                      isAllowed={isAllowed}
                      headerCells={headerCells.sort(
                        sortObjectsBy("index", false)
                      )}
                    />
                  );
                }}
                filters={nonLookUpfilters}
                onUpdateFilters={(nonLookUpfilters) => {
                  setPage(1);
                  setNonLookUpFilters({ ...nonLookUpfilters });
                }}
              />
            )}
          </TaskTableContainer>
        </div>
        {!nonLookUpfilters.groupByPOs && (
          <BasicPagination
            paginationClass="taskPagination"
            rowsPerPage={rowsPerPage}
            page={page}
            count={getCountPagination(filteredTasks, rowsPerPage)}
            onChangePagination={(event, newPage) => {
              handleChangePage(newPage);
            }}
            onChangeRowsPerPage={(event) => {
              setRowsPerPage(+event.target.value);
              setPage(1);
            }}
            style={{}}
          />
        )}
      </FilterTaskContainer>
    </React.Fragment>
  );

  function getCountPagination(filteredTasks, rowsPerPage) {
    return Math.ceil(filteredTasks.length / rowsPerPage);
  }
}

export default TaskListScreen;
