import { colors } from "../../assets/jss/variables";
import { TableCell, TableContainer, TableRow, styled } from "@mui/material";

export const CellSKUHeader = styled(TableCell)(() => ({
  "&.headerRoot": {
    paddingBlock: "0px !important",
    fontSize: "0.85em",
    fontWeight: 500,
    lineHeight: "1.5rem",
    backgroundColor: colors.skyBlue,
    color: colors.primaryDark,
    border: 0,
  },
  "&.sorting": {
    "& .headerArrowButton": {
      position: "absolute",
      opacity: 0,
      top: 20,
    },
    "&:hover": {
      "& .headerArrowButton": { opacity: "1 !important" },
    },
  },
  "& .typeImage": {
    cursor: "pointer",
  },
}));

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  "&.shipmentTableContainerV2": {
    // maxHeight: "calc(100vh - 535px)",
    // minHeight: "calc(100vh - 535px)",

    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 18,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 8,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
  "& .list": {
    overflowY: "auto !Important",
    overflowX: "hidden !Important",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 18,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 8,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
  "& .row": {
    display: "block !important",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    boxSizing: "border-box",
    minWidth: "100%",
    width: "100%",
    background: "#fff",
    borderBottom: `1px solid ${colors.whiteBlue}`,
    "&:hover": {
      "& .styleCellShipment": {
        backgroundColor: "#fefce7 !important",
      },
    },
    "& .styleCellShipment": {
      fontSize: 14,
      backgroundColor: "white",
      color: colors.primaryDark,
      borderColor: colors.dividerColor,
      padding: "7px 10px",
      "& .edit-icon": {
        visibility: "hidden",
      },
      "& .totalCellShipment": {
        fontSize: 14,
        backgroundColor: "white",
        color: colors.primaryDark,
        borderColor: colors.dividerColor,

        padding: "7px 10px",
      },
      "&:hover": {
        "& .edit-icon": {
          visibility: "visible",
        },
      },
    },
  },
  "& .cell": {
    display: "block",
    flexGrow: 0,
    flexShrink: 0,
  },
  "& .expandingCell": {
    flex: 1,
  },
}));

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&.body": {
    "& .emptyCellStyle": {
      fontSize: 14,
      lineHeight: "16px",
      letterSpacing: "0.295em",
      textTransform: "uppercase",
      height: 40,
      backgroundColor: "white",
      color: colors.primaryDark,
      borderColor: colors.dividerColor,
    },
    "& .styleCellShipment": {
      fontSize: 14,
      backgroundColor: "white",
      color: colors.primaryDark,
      borderColor: colors.dividerColor,

      padding: "0px",
      "& .edit-icon": {
        visibility: "hidden",
      },
      "& .totalCellShipment": {
        fontSize: 14,
        backgroundColor: "white",
        color: colors.primaryDark,
        borderColor: colors.dividerColor,

        padding: "7px 10px",
      },
      "&:hover": {
        "& .edit-icon": {
          visibility: "visible",
        },
      },
    },
    "&:hover": {
      "& .styleCellShipment": {
        backgroundColor: "#fefce7 !important",
      },
    },
  },
  "& .totalCustomersAndShipments": {
    "& label": {
      margin: 0,
      color: colors.darkGray,
      fontSize: 14,
      fontWeight: 400,
    },
  },
}));

export const TableCellsStyled = styled("div")(({ theme }) => ({
  root: {
    display: "block",
    flex: 1,
  },
  table: {
    height: "100%",
    width: "100%",
  },

  thead: {},
  tbody: {
    width: "100%",
  },

  headerRow: {},

  column: {},
}));
export const PDFPortalStyled = styled("div")(({ theme }) => ({
  "&.portalPDF": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "1%",
    display: "flex",
    background: "rgba(0, 0, 0, 0.3)",
    justifyContent: "center",
    "& .pg-viewer-wrapper": {
      width: "100%",
      "& .react-grid-Container": {
        width: "100%",
      },
    },
    "& .document-container": {
      width: "98%",
      border: `2px ${colors.primaryDark} solid`,
    },
    "& iframe": {
      width: "100%",
      height: "100%",
      zIndex: 2,
    },
    "& .cancel-icon-pdf": {
      width: 24,
      height: 24,
      padding: 4,
      border: "2px solid",
      borderRadius: "50%",
      position: "absolute",
      right: 22,
      cursor: "pointer",
      color: "white",
      background: "white",
      zIndex: 10,
      marginTop: 5,
      "&:hover": {
        background: "gray",
        color: "gray",
      },
    },
  },
}));

export const CellTableShipmentStyled = styled("div")(() => ({
  "&.cellTableShipment": {
    height: "100%",
    "& .shipment-badge-container": {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      "& .newShipmentBadge": {
        background: "white",
        fontWeight: 400,
        border: "1px solid #25486D",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        height: 26,
        "& .shipmentIcon": {
          marginRight: 5,
          marginLeft: 10,
          marginBottom: 2,
        },
        "&:hover": {
          fontWeight: 500,
          cursor: "pointer",
          border: "2px solid #25486D",
        },
      },
    },
    "& .table-shipment-wrapper": {
      opacity: 1,
      overflow: "hidden",
      display: "flex",
      "& .arrow": {
        color: colors.primaryDark,
        transition: "transform 0.3s ease-in-out",
      },
      "& .editable-label": {
        "& .editable-text-item": {
          marginLeft: 10,
        },
        "& .input-editable-label": {
          maxWidth: 70,
        },
      },
      "& .disclosure-triangle-icon": {
        height: 24,
        cursor: "pointer",
        width: 24,
      },
      "& .empty-space": {
        width: 24,
        height: 24,
      },
    },
  },
}));
