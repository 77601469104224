import React, { Fragment } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { itemTableRowHeight } from "../../helpers/orderDashboard";
import {
  ITEM_TABLE_ACTION,
  GENERAL_PERMISSION_VALUE,
  itemTableReference,
} from "../../helpers/constants";
import {
  getDangerStyle,
  getDependencyShipmentList,
  haveMultipleItems,
} from "../../helpers/itemsTableHelper";
import { colors } from "../../assets/jss/variables";
import CellTableShipmentV2 from "./CellTableShipmentV2";
import defaultImage from "../../assets/flag-icons/no_image_available.jpg";
import { TableCell } from "@mui/material";

function RowSimpleV2({
  column,
  row,
  index,
  prefix,
  parentStyle = {},
  showImage = false,
  showIconMultiRow = false,
  open = false,
  isMultiHeader,
  onOpenRow = () => {},
  onOpenModal = () => {},
  onChangeData = () => {},
  salesOrders = [],
  shipments = [],
  item,
  parentRow = null,
  handlePhotosViewer,
  isAllowed = () => {},
  companyAllowed = () => {},
  purchaseOrders = [],
  poisOutdated,
  isVoided,
  onClickRedirect,
}) {
  let value = row[column.id];
  if (column.id === "image") {
    value = row[column.id][0] || defaultImage;
  }
  let simpleDataArray = haveMultipleItems({ row, isMultiHeader });
  let cellClassName = "styleCellShipment";
  const mismatchStatus = item.mismatchStatus || {};
  let hasMismatch = false;
  if (!column.multiHeader) {
    let style = {
      ...parentStyle,
      ...column.style,
      ...column.rowStyle,
    };
    style = getDangerStyle({
      prefix,
      column,
      mismatch: mismatchStatus,
      row,
      style,
    });
    hasMismatch = style.color === "red";
    const showIcon = index === 0 && !simpleDataArray && isMultiHeader;
    let cursor = column.select || column.poRedirect ? "pointer" : "";
    let backgroundBlenMode = null;
    if (
      column.reference === itemTableReference.SALES_ORDERS &&
      (column.id === "allocation" ||
        column.id === "number" ||
        column.id === "customerId" ||
        column.id === "id")
    ) {
      backgroundBlenMode = colors.salesOrderBackgroundColor;
    } else if (
      column.reference === itemTableReference.SHIPMENTS &&
      (column.id === "allocation" ||
        column.id === "number" ||
        column.id === "id")
    ) {
      backgroundBlenMode = colors.shipmentBadge;
    }
    if (
      column.id === "number" &&
      column.reference === itemTableReference.SALES_ORDERS
    ) {
      if (row.number === item.currentSalesOrderNumber) {
        style = { ...style, textDecoration: "unset", cursor: "unset" };
        cursor = "unset";
      }
    }

    const iconToShow = (
      <KeyboardArrowDownIcon
        onClick={onOpenRow}
        className="arrow"
        style={{
          transform: !open ? "rotate(-90deg)" : "none",
          color: mismatchStatus.shipment ? "red" : "",
          fontSize: "1.5rem",
        }}
      />
    );
    return (
      <Fragment>
        <TableCell
          id={column.id}
          key={`${prefix}_${column.id}_${row.id}_cell`}
          style={{
            ...style,
            height: showImage
              ? itemTableRowHeight.WITH_IMAGE
              : itemTableRowHeight.NORMAL,
            position: "relative",
            textDecoration: !showIconMultiRow ? style.textDecoration : "unset",
          }}
          align={"center"}
          className={cellClassName}
        >
          {backgroundBlenMode && (
            <div
              className="identifier-to-add"
              style={{
                width: "100%",
                height: "100%",
                background: backgroundBlenMode,
                position: "absolute",
                opacity: 0.55,
                top: 0,
                left: 0,
              }}
            ></div>
          )}
          <CellTableShipmentV2
            column={column}
            item={item}
            prefix={prefix}
            isAllowed={isAllowed}
            companyAllowed={companyAllowed}
            cursor={cursor}
            showIconMultiRow={showIconMultiRow}
            onOpenRow={onOpenRow}
            openIcon={open}
            iconToShow={iconToShow}
            showBadge={column.showBadge}
            parentRow={parentRow}
            purchaseOrders={purchaseOrders}
            poisOutdated={poisOutdated}
            isVoided={isVoided}
            hasMismatch={hasMismatch}
            shipments={shipments}
            onChangeLabel={(temporalLabel) => {
              onChangeData({
                item,
                row: { ...row, [column.id]: temporalLabel },
                parentRow,
                reference: column.reference,
              });
            }}
            onSelectData={(shipment) => {
              let rowToUpdate = { ...row };
              if (column.reference === itemTableReference.SHIPMENTS) {
                rowToUpdate = {
                  ...row,
                  id: shipment.id,
                  number: shipment.number,
                  customerId: shipment.customerId,
                  prevId: row.id,
                  action: ITEM_TABLE_ACTION.ASSIGNED_SHIPMENT,
                };
              }
              onChangeData({
                item,
                row: rowToUpdate,
                parentRow,
                reference: column.reference,
                shipmentReference: shipment,
              });
            }}
            row={row}
            showIcon={showIcon}
            onOpenModal={() =>
              onOpenModal({
                item,
                row,
                parentRow,
                reference: column.reference,
              })
            }
            showNewShipment={
              column.reference === itemTableReference.SHIPMENTS &&
              isAllowed(GENERAL_PERMISSION_VALUE.CREATE_SHIPMENTS)
            }
            valueURL={value}
            showImage={showImage}
            onClickRedirect={onClickRedirect}
            onPhotosViewer={() => {
              if (item.image && item.image.length !== 0) {
                handlePhotosViewer({
                  open: true,
                  photos: [
                    ...item.image.map((el) => ({
                      src: el || defaultImage,
                      number: item.itemNumber,
                      description: item.description,
                    })),
                  ],
                });
              }
            }}
          />
        </TableCell>
      </Fragment>
    );
  } else {
    const listValue = getDependencyShipmentList({ column, value, row });
    const firstValue = listValue[0] || [];
    const shipmentsValue = firstValue.shipments || [];
    const columnStyle = { ...column.style, ...column.rowStyle };
    const salesOrderItems = row.salesOrders || [];
    const hiddenShipment =
      column.id === "shipments"
        ? salesOrderItems.length > 1 || listValue.length > 1
        : listValue.length > 1;
    const showIconMultiRow =
      listValue && !simpleDataArray && !open && hiddenShipment;
    return column.subHeader.map((subColumn, subColumnIndex) => (
      <RowSimpleV2
        key={subColumn.id + subColumnIndex}
        row={firstValue}
        column={{ ...subColumn, subColumn: true }}
        parentStyle={{
          ...columnStyle,
          borderBottomColor:
            !open || column.id === "shipments" || shipmentsValue.length <= 1
              ? ""
              : columnStyle.background || "",
        }}
        open={open}
        showImage={showImage}
        showIconMultiRow={showIconMultiRow}
        onOpenModal={onOpenModal}
        onChangeData={onChangeData}
        index={parseInt(subColumnIndex / column.subHeader.length)}
        salesOrders={salesOrders}
        shipments={shipments}
        onOpenRow={onOpenRow}
        parentId={column.parentId}
        prefix={prefix}
        item={item}
        handlePhotosViewer={handlePhotosViewer}
        isAllowed={isAllowed}
        companyAllowed={companyAllowed}
        poisOutdated={poisOutdated}
        isVoided={isVoided}
        onClickRedirect={onClickRedirect}
        parentRow={
          column.id === "salesOrders" ? null : item[column.parentId][0]
        }
      />
    ));
  }
}

export default RowSimpleV2;
