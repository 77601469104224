import React, { useState, useEffect } from "react";
import { useStorage } from "../../hooks";
import SearchBox from "../SearchBox/SearchBox";
import { List, AutoSizer } from "react-virtualized";
import NavItem from "../../components/NavItem/NavItem";
import { colors } from "../../assets/jss/variables";

const overscanRowCount = 2;
const itemHeight = 68;
const storageField = "searchSummary";

function LeftColumn({
  title,
  query = "",
  onChangeInactive = () => {},
  showInactive,
  onChangeQuery,
  cookie,
  checkBoxLabel = "",
  navList = [],
  onAddNewItem = () => {},
  allowToCreate = false,
  redirectPath,
  isEmpty,
}) {
  const [storage, setStorage] = useStorage(storageField);
  const storageValue = storage[cookie] || {};
  const cookieValue = storageValue.search || "";
  const [showSearch, setShowSearch] = useState(!!cookieValue);

  useEffect(() => {
    if (!!cookieValue) {
      onChangeQuery(cookieValue);
    }
  }, []);
  const rowRenderer = ({ index, style }) => (
    <NavItem
      key={`${navList[index]}_${index}`}
      item={navList[index]}
      index={index}
      style={{ ...style, whiteSpace: "pre-wrap" }}
      path={redirectPath}
    />
  );

  return (
    <section className={"leftColumn"}>
      <header>
        <div className="title">
          <span className="titleName">{title}</span>
          {!allowToCreate && (
            <button className="titleButtom" onClick={onAddNewItem}>
              + Add New
            </button>
          )}
        </div>
        <div className="inactiveItems" onClick={onChangeInactive}>
          <input type="checkbox" checked={showInactive} />
          <div className="inactiveItemsText">{checkBoxLabel}</div>
        </div>
        <SearchBox
          placeholder="Search"
          onChange={(ev) => {
            onChangeQuery(ev.target.value);
            const persitence = storage[cookie] || {};
            setStorage(
              cookie,
              { ...persitence, search: ev.target.value },
              { maxAge: 84600 }
            );
          }}
          value={query}
          style={{ width: "100%", position: "unset", marginBottom: 9 }}
          hasBeenReseted={(ev) => {
            onChangeQuery("");
            setShowSearch(!showSearch);
            const persitence = storage[cookie] || {};
            setStorage(
              cookie,
              { ...persitence, search: "" },
              { maxAge: 84600 }
            );
          }}
        />
      </header>
      <div style={{ height: "100%", maxHeight: "calc(100vh - 245px)" }}>
        {navList.length === 0 ? (
          <div
            style={{
              color: colors.primaryDark,
              fontSize: 20,
              fontWeight: 700,
              lineHeight: 1.3,
              padding: "57px 64px",
              textAlign: "center",
            }}
          >
            {isEmpty
              ? "The list is empty"
              : "The current search does not have any results"}
          </div>
        ) : (
          <AutoSizer id={"list-root-nav"}>
            {({ width, height }) => (
              <List
                height={height}
                overscanCount={overscanRowCount}
                rowCount={navList.length}
                rowHeight={itemHeight}
                rowRenderer={rowRenderer}
                width={width}
                id="leftColumnScroller"
              />
            )}
          </AutoSizer>
        )}
      </div>
    </section>
  );
}

export default LeftColumn;
