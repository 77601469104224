import { colors } from "../../assets/jss/variables";
import { Popover, styled } from "@mui/material";

export const NavPickerPopoverStyled = styled(Popover)(({ theme }) => ({
  "&.navPickerPopoverContainer": {
    "& .paper": {
      display: "flex",
      padding: "8px 0px",
      alignItems: "flex-start",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        backgroundColor: "transparent",
        width: 16,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 7,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
          borderBlock: "1px solid transparent",
          borderInline: "5px solid transparent",
        },
      },
    },
    "& .listContainer": {
      display: "flex",
      padding: "5px 25px 5px 16px",
      cursor: "pointer",
      alignItems: "center",
      width: "100%",
      "&:hover": {
        background: "#f5f5f5",
      },
      "& .avatarContainer": {
        zIndex: 2,
      },
      "& .avatarNavLink": {
        cursor: "pointer",
        "&:hover": {
          outline: `${colors.primaryDark} solid 2px !important`,
        },
      },

      "& .itemListNumber": {
        color: colors.primaryDark,
      },
      "& .item-divider": {
        marginInline: "12px 18px",
        width: 13,
        height: "100%",
        marginTop: 6,
      },
      "& .item-subtitle": {
        color: colors.primaryDark,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 240,
      },
    },
    "& .selectedStyle": {
      boxShadow: "4px 0px 0px 0px #218cff inset",
    },
    "& .NavPicker": {
      "& .MuiPopover-paper": {
        width: "auto",
        minWidth: 175,
        minHeight: 35,
        background: "#ffffff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: 5,
        padding: "5px 0px",
        maxHeight: "calc(100vh - 65px)",
        "& .Mui-selected": {
          backgroundColor: " unset",
        },
      },
    },

    "& .listNavpick": {
      display: "flex",
      padding: "5px 25px 5px 16px",
      cursor: "pointer",
      alignItems: "center",

      "& .itemListNumber": {
        color: colors.primaryDark,
        backgroundColor: "unset !important",
      },
      "& .item-divider": {
        marginInline: "12px 18px",
        width: 13,
        height: "100%",
        marginTop: 6,
        backgroundColor: "unset !important",
      },
      "& .item-subtitle": {
        color: colors.primaryDark,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 240,
        backgroundColor: "unset !important",
      },
    },
  },
}));

export const MultiUserPickerPopoverStyled = styled(Popover)(({ theme }) => ({
  "&.multiUserPickerPopoverContainer": {
    "& .container": {
      minWidth: 135,
      display: "flex",
      alignItems: "center",
      paddingRight: 10,
    },
    "& .listContainer": {
      margin: 0,
      padding: 0,
      "& :hover": {
        backgroundColor: "#5D92F4",
      },
    },
    "& .listItem": {},
  },
}));

export const OnlyFromStyled = styled("div")(() => ({
  "&.onlyFromContainer": {
    display: "flex",
    alignItems: "center",
  },
  "& .oneUser": {
    minWidth: 70,
    maxWidth: 110,
    paddingRight: 10,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    gap:4
  },
  "& .onlyFromData": {
    minWidth: 70,
    maxWidth: 115,
    display: "inline-block",
    height: 18,
  },
  "& .onlyFrom": {
    display: "inline-block",
    color: colors.primaryDark,
    fontWeight: 700,
    fontSize: 12,
    position: "relative",
    top: -5,
    padding: 0,
  },
}));

export const ShipmentPopoverStyled = styled(Popover)(({ theme }) => ({
  "&.shipmentPopoverContainer": {
    "& .paper": {
      boxShadow: "0px 10px 50px 3px rgba(1,31,64,0.59)",
      background: "#F7F9FC",
      borderRadius: 10,
      "& .shipment-popover-container": {
        maxHeight: "calc(100vh - 210px)",
        overflow: "auto",
        margin: "8px 6px 8px 16px",

        "& .shipment-content": {
          display: "flex",
          alignItems: "center",
          gap: 16,
          margin: "6px 6px 6px 0px",
          "& .shipment-content-final-destination": {
            fontSize: 14,
            fontWeight: 400,
            color: colors.primaryDark,
            width: 150,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },

        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: 16,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
          borderRadius: 7,
        },
        "&::-webkit-scrollbar-track": {
          border: " solid 1px transparent",
        },
        "&:hover": {
          "&::-webkit-scrollbar-thumb": {
            boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
            borderBlock: "1px solid transparent",
            borderInline: "5px solid transparent",
          },
        },
      },
    },
  },
}));
