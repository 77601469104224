import numeral from "numeral";
import ShipmentItem from "../api/model/ShipmentItem";
import { globalScopes } from "../api/types/dbTables";
import { MISMATCH_STATUS, itemTableReference } from "./constants";
import { getDashboardSearchPath } from "./helpers";

export function getItemsAmount({ items = [] }) {
  return items.reduce((prevValue, currentValue) => {
    return (prevValue +=
      parseFloat(currentValue.orderQuantity) * parseFloat(currentValue.price));
  }, 0);
}

export function getTotalCBMFromItems({ items = [], decimals = 2 }) {
  let total = 0;
  if (items && items.length) {
    items.forEach((item) => {
      const cbm = +item.cbmMaster;
      const cbmRoundedUp = Math.round((cbm + Number.EPSILON) * 100) / 100;
      // console.log("CBM:: ", cbm, "Rounded Up:: ", cbmRoundedUp);
      let orderQuantity = +item.orderQuantity;
      const piecesPerMaster = +item.piecesPerMaster;
      let totalCBM = 0;
      if (piecesPerMaster === 0) {
        totalCBM = 0;
      } else {
        totalCBM = cbmRoundedUp * (orderQuantity / piecesPerMaster);
      }
      const totalCBMRoundedUp =
        Math.round((totalCBM + Number.EPSILON) * 100) / 100;
      total = total + totalCBMRoundedUp;
    });
  }
  // console.log("TOTAL::: ", total);
  return total.toFixed(decimals);
}

export function getTotalWeightFromItems({ items = [], decimals = 2 }) {
  let sumTotalWeight = 0;
  items.forEach((item) => {
    const orderQuantity = Number(item.orderQuantity);
    const piecesPerMaster = Number(item.piecesPerMaster);
    const weight = Number(item.weight) || 0;
    const weightRoundedUp = Math.round((weight + Number.EPSILON) * 100) / 100;
    // console.log("WEIGHT:: ", weight, "Rounded Up:: ", weightRoundedUp);
    const cartons = orderQuantity / piecesPerMaster;
    let totalWeight = 0;
    if (piecesPerMaster === 0) {
      totalWeight = 0;
    } else {
      totalWeight = cartons * weightRoundedUp;
    }
    const totalWeightRoundedUp =
      Math.round((totalWeight + Number.EPSILON) * 100) / 100;
    sumTotalWeight += totalWeightRoundedUp;
  });
  // console.log("SUM TOTAL WEIGHT::: ", sumTotalWeight);
  return sumTotalWeight.toFixed(decimals);
}

export function getFormattedItems({ items = [], scope, orderId }) {
  if (scope === globalScopes.SALES_ORDER) {
    return items;
  } else if (scope === globalScopes.PURCHASE_ORDER) {
    return items;
  } else if (scope === globalScopes.SHIPMENT) {
    return items.map((item) => ({
      ...item,
      orderQuantity: item.shipmentQuantity[orderId],
      price: item.unitCost,
    }));
  } else {
    return [];
  }
}

export const getExtendedCostPrice = ({ cost, quantity }) => {
  if (!quantity || !cost) {
    return 0;
  }
  const parseQuantity = parseNumberValue(quantity);
  const parseCost = parseNumberValue(cost);
  return parseNumberValue(parseCost * parseQuantity);
};

export const parseNumberValue = (value = 0) => {
  const parseValue = parseFloat(value);
  return isFinite(parseValue) ? parseValue : 0;
};

export const getCartons = ({ quantity, pieces }) => {
  if (!quantity || !pieces) {
    return 0;
  }
  const parseQuantity = parseNumberValue(quantity);
  const parsePieces = parseNumberValue(pieces);
  return parseNumberValue(parseQuantity / parsePieces);
};

export const getTotalCBM = ({ cbm, pieces, quantity }) => {
  if (!quantity || !cbm || !pieces) {
    return numeral(0).format("0,0.00");
  }
  const parseCBM = parseNumberValue(cbm);
  const cbmRoundedUp = Math.round((parseCBM + Number.EPSILON) * 100) / 100;
  const parsePieces = parseNumberValue(pieces);
  const parseQuantity = parseNumberValue(quantity);
  const totalCBM = parseNumberValue(
    cbmRoundedUp * (parseQuantity / parsePieces)
  );
  const totalCBMRoundedUp = Math.round((totalCBM + Number.EPSILON) * 100) / 100;
  // console.log("TOTAL CBM ROUND UP ", totalCBMRoundedUp);
  return numeral(totalCBMRoundedUp).format("0,0.00");
};

export const getTotalWeight = ({ quantity, pieces, weight }) => {
  if (!quantity || !weight || !pieces) {
    return numeral(0).format("0,0.00");
  }
  const parseWeight = parseNumberValue(weight);
  const weightRoundedUp =
    Math.round((parseWeight + Number.EPSILON) * 100) / 100;

  const parsePieces = parseNumberValue(pieces);
  const parseQuantity = parseNumberValue(quantity);
  const totalWeight = parseNumberValue(
    (parseQuantity / parsePieces) * weightRoundedUp
  );
  const totalWeightRoundedUp =
    Math.round((totalWeight + Number.EPSILON) * 100) / 100;
  return numeral(totalWeightRoundedUp).format("0,0.00");
};

function getTotalAllocation(shipmentQuantityValue) {
  if (Object.keys(shipmentQuantityValue).length > 0) {
    const quantitiesValue = { ...shipmentQuantityValue, new: 0 };
    return Object.values(quantitiesValue).reduce(
      (a, b) => parseInt(a) + parseInt(b)
    );
  }
  return 0;
}

export const replaceItemShipment = ({
  salesOrder,
  row,
  shipmentsIds,
  isAddingItem,
}) => {
  const cpyShipmentsIds = shipmentsIds;
  if (isAddingItem) {
    const newSipments = salesOrder.shipments.map((shipmentItem) => {
      if (
        shipmentItem.id === row.id ||
        (shipmentItem.id === row.prevId && shipmentItem.status !== "new")
      ) {
        if (
          row.id !== MISMATCH_STATUS.NEW &&
          !cpyShipmentsIds.includes(row.id)
        ) {
          cpyShipmentsIds.push(row.id);
        }
        const newRow = {
          ...row,
          status:
            row.id === MISMATCH_STATUS.NEW ? MISMATCH_STATUS.INCOMPLETE : "",
        };
        return {
          ...shipmentItem,
          ...newRow,
        };
      }
      return { ...new ShipmentItem({ ...shipmentItem }) };
    });
    return { newSipments, newShipmentsIds: cpyShipmentsIds };
  } else {
    const newSipments = salesOrder.shipments.filter(
      (shipment) => shipment.id !== row.id
    );
    const newShipmentsIds = cpyShipmentsIds.filter((id) => id !== row.id);
    return { newSipments, newShipmentsIds };
  }
};

export const onChangeSoSKUData = ({ item, row, currentSalesOrder }) => {
  let itemCpy = { ...item };
  const mismatchStatus = row.mismatchStatus || item.mismatchStatus || {};
  let salesOrdersCpy = [...itemCpy.salesOrders];
  let salesOrderPrice = row.salesOrderPrice;
  let newSaleOrderQuantity = row.salesOrderQuantity;
  if (typeof newSaleOrderQuantity !== "object") {
    newSaleOrderQuantity = {
      ...item.salesOrderQuantity,
      [currentSalesOrder.id]: row.salesOrderQuantity,
    };
  }
  if (typeof salesOrderPrice !== "object") {
    salesOrderPrice = {
      ...item.salesOrderPrice,
      [currentSalesOrder.id]: parseFloat(row.salesOrderPrice),
    };
  }
  const newQuantityValue = row.salesOrderQuantity;
  salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
    if (salesOrder.id === currentSalesOrder.id) {
      const allocation = parseFloat(
        newQuantityValue[salesOrder.id] || newQuantityValue
      );

      return {
        ...salesOrder,
        allocation,
      };
    }
    return salesOrder;
  });

  let newMismatchSO = mismatchStatus;
  const mismatchSO =
    parseInt(row.purchaseOrderQuantity) !==
    getTotalAllocation(newSaleOrderQuantity);
  if (mismatchSO) {
    newMismatchSO = {
      purchaseOrder: mismatchSO,
      salesOrder: !!mismatchStatus.salesOrder,
      shipments:
        mismatchStatus.shipments === undefined
          ? true
          : !!mismatchStatus.shipments,
    };
  } else {
    newMismatchSO = {
      purchaseOrder: false,
      salesOrder: false,
      shipments:
        mismatchStatus.shipments === undefined
          ? true
          : !!mismatchStatus.shipments,
    };
  }
  return {
    ...itemCpy,
    salesOrderQuantity: newSaleOrderQuantity,
    salesOrders: salesOrdersCpy,
    salesOrderPrice: salesOrderPrice,
    mismatchStatus: {
      ...newMismatchSO,
    },
  };
};

export const onChangeDataPO = ({ item, row }) => {
  let itemCpy = { ...item };
  let salesOrdersCpy = [...itemCpy.salesOrders];
  const mismatchStatus = row.mismatchStatus || item.mismatchStatus || {};
  salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
    if (salesOrder.id === row.id || salesOrder.id === row.prevId) {
      return {
        ...salesOrder,
        ...row,
      };
    }
    return salesOrder;
  });
  let newSaleOrderQuantity = {
    ...item.salesOrderQuantity,
    [row.id]: parseFloat(row.allocation),
  };

  const newSOQuantity = getTotalAllocation(newSaleOrderQuantity);
  let newMismatchSO = mismatchStatus;
  const mismatchSO =
    parseInt(item.purchaseOrderQuantity) !== parseInt(newSOQuantity);
  if (newMismatchSO) {
    newMismatchSO = {
      purchaseOrder: mismatchSO,
      salesOrder: !!mismatchStatus.salesOrder,
      shipments:
        mismatchStatus.shipments === undefined
          ? true
          : !!mismatchStatus.shipments,
    };
  } else {
    newMismatchSO = {
      purchaseOrder: false,
      salesOrder: false,
      shipments:
        mismatchStatus.shipments === undefined
          ? true
          : !!mismatchStatus.shipments,
    };
  }
  return {
    ...itemCpy,
    salesOrders: salesOrdersCpy,
    salesOrderQuantity: newSaleOrderQuantity,
    mismatchStatus: { ...newMismatchSO },
  };
};

export const onChangeSKUShipment = ({ item, row, currentShipmentId }) => {
  let itemCpy = { ...item };
  let shipmentIdsCpy = [...item.shipmentIds];
  const mismatchStatus = row.mismatchStatus || item.mismatchStatus || {};
  let newMismatchItem = mismatchStatus;
  const keyAllocation = item.currentShipmentId || item.currentSalesOrderId;
  let salesOrdersCpy = [...itemCpy.salesOrders];
  let newQuantityValue = 0;
  salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
    let salesOrderShipment = salesOrder.shipments || [];
    if (!parseInt(row.shipmentQuantity) > 0) {
      salesOrderShipment = salesOrder.shipments.filter(
        (shipment) => shipment.id !== currentShipmentId
      );
      shipmentIdsCpy = shipmentIdsCpy.filter((id) => id !== currentShipmentId);
    }
    const newShipments = salesOrderShipment.map((shipment) => {
      if (shipment.id !== MISMATCH_STATUS.NEW) {
        newQuantityValue =
          shipment.id === currentShipmentId
            ? parseInt(row.shipmentQuantity) + newQuantityValue
            : parseInt(shipment.allocation) + newQuantityValue;
      }
      if (shipment.id === currentShipmentId) {
        return { ...shipment, allocation: row.shipmentQuantity };
      }
      return shipment;
    });

    return {
      ...salesOrder,
      shipments: newShipments,
    };
  });
  const parseShipmentQuantity = parseFloat(row.shipmentQuantity);
  const newShipmentQuantity = {
    ...item.shipmentQuantity,
    [keyAllocation]: row.shipmentQuantity,
  };
  if (parseShipmentQuantity === 0) {
    delete newShipmentQuantity[keyAllocation];
  }

  const totalShipmentsValue = Object.values(newShipmentQuantity).reduce(
    (a, b) => parseInt(a, 10) + parseInt(b, 10),
    0
  );

  newMismatchItem = {
    salesOrder: !!mismatchStatus.salesOrder,
    purchaseOrder: !!mismatchStatus.purchaseOrder,
    shipments:
      parseInt(row.purchaseOrderQuantity) !== parseInt(totalShipmentsValue),
  };
  return {
    ...itemCpy,
    shipmentQuantity: newShipmentQuantity,
    salesOrders: salesOrdersCpy,
    shipmentIds: shipmentIdsCpy,
    mismatchStatus: {
      ...newMismatchItem,
    },
  };
};

export const changeDataShipment = ({
  item,
  row,
  parentRow,
  reference,
  currentShipmentId,
  currentSalesOrder,
  currentPurchaseOrder,
}) => {
  let itemCpy = { ...item };
  let shipmentIdsCpy = [...item.shipmentIds];
  const mismatchStatus = row.mismatchStatus || item.mismatchStatus || {};
  let newMismatchItem = mismatchStatus;
  const isAddingItem = parseInt(row.allocation) > 0;
  switch (reference) {
    case itemTableReference.SHIPMENTS: {
      let salesOrdersCpy = [...itemCpy.salesOrders];
      salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
        if (salesOrder.id === parentRow.id) {
          const newData = replaceItemShipment({
            row,
            salesOrder,
            shipmentsIds: shipmentIdsCpy,
            isAddingItem,
          });
          shipmentIdsCpy = newData.newShipmentsIds;
          return {
            ...salesOrder,
            shipments: newData.newSipments,
          };
        }
        return salesOrder;
      });
      let newShipmentQuantity = {
        ...item.shipmentQuantity,
        [row.id]: row.allocation,
      };

      if (!isAddingItem) {
        delete newShipmentQuantity[row.id];
      }
      let parseShipmentQuantity = {};
      shipmentIdsCpy.forEach((id) => {
        if (newShipmentQuantity[id]) {
          parseShipmentQuantity = {
            ...parseShipmentQuantity,
            [id]: newShipmentQuantity[id],
          };
        }
      });
      const totalShipmentsValue = Object.values(parseShipmentQuantity).reduce(
        (a, b) => parseInt(a, 10) + parseInt(b, 10),
        0
      );

      return {
        ...itemCpy,
        salesOrders: salesOrdersCpy,
        shipmentIds: shipmentIdsCpy,
        shipmentQuantity: parseShipmentQuantity,
        purchaseOrderId: itemCpy.purchaseOrderId || currentPurchaseOrder.id,
        mismatchStatus: {
          ...mismatchStatus,
          shipments:
            totalShipmentsValue !== parseInt(itemCpy.purchaseOrderQuantity, 10),
        },
      };
    }
    case itemTableReference.SALES_ORDERS: {
      let salesOrdersCpy = [...itemCpy.salesOrders];
      salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
        if (salesOrder.id === row.id || salesOrder.id === row.prevId) {
          return {
            ...salesOrder,
            ...row,
          };
        }
        return salesOrder;
      });
      let newSaleOrderQuantity = {
        ...item.salesOrderQuantity,
        [row.id]: parseFloat(row.allocation),
      };

      const newSOQuantity = getTotalAllocation(newSaleOrderQuantity);
      let newMismatchSO = mismatchStatus;
      const mismatchSO =
        parseInt(item.purchaseOrderQuantity) !== parseInt(newSOQuantity);
      if (newMismatchSO) {
        newMismatchSO = {
          purchaseOrder: mismatchSO,
          salesOrder: !!mismatchStatus.salesOrder,
          shipments:
            mismatchStatus.shipments === undefined
              ? true
              : !!mismatchStatus.shipments,
        };
      } else {
        newMismatchSO = {
          purchaseOrder: false,
          salesOrder: false,
          shipments:
            mismatchStatus.shipments === undefined
              ? true
              : !!mismatchStatus.shipments,
        };
      }
      return {
        ...itemCpy,
        salesOrders: salesOrdersCpy,
        salesOrderQuantity: newSaleOrderQuantity,
        mismatchStatus: { ...newMismatchSO },
      };
    }

    case itemTableReference.ITEM:
      const salesOrderQuantity = getTotalAllocation(item.salesOrderQuantity);
      let newShipmentQuantity = {
        ...item.shipmentQuantity,
        [row.id]: row.allocation,
      };
      const mismatchItem =
        parseInt(row.purchaseOrderQuantity) !== parseInt(salesOrderQuantity);
      const totalShipmentsValue = Object.values(newShipmentQuantity).reduce(
        (a, b) => parseInt(a, 10) + parseInt(b, 10),
        0
      );
      if (mismatchItem) {
        newMismatchItem = {
          salesOrder: mismatchItem,
          purchaseOrder: !!mismatchStatus.purchaseOrder,
          shipments:
            parseInt(row.purchaseOrderQuantity) !==
            parseInt(totalShipmentsValue),
        };
      } else {
        newMismatchItem = {
          purchaseOrder: false,
          salesOrder: false,
          shipments:
            parseInt(row.purchaseOrderQuantity) !==
            parseInt(totalShipmentsValue),
        };
      }
      return {
        ...itemCpy,
        ...row,
        mismatchStatus: {
          ...newMismatchItem,
        },
      };

    case itemTableReference.MAIN_SHIPMENT: {
      const keyAllocation = item.currentShipmentId || item.currentSalesOrderId;
      let salesOrdersCpy = [...itemCpy.salesOrders];
      let newQuantityValue = 0;
      salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
        let salesOrderShipment = salesOrder.shipments || [];
        if (!parseInt(row.shipmentQuantity) > 0) {
          salesOrderShipment = salesOrder.shipments.filter(
            (shipment) => shipment.id !== currentShipmentId
          );
          shipmentIdsCpy = shipmentIdsCpy.filter(
            (id) => id !== currentShipmentId
          );
        }
        const newShipments = salesOrderShipment.map((shipment) => {
          if (shipment.id !== MISMATCH_STATUS.NEW) {
            newQuantityValue =
              shipment.id === currentShipmentId
                ? parseInt(row.shipmentQuantity) + newQuantityValue
                : parseInt(shipment.allocation) + newQuantityValue;
          }
          if (shipment.id === currentShipmentId) {
            return { ...shipment, allocation: row.shipmentQuantity };
          }
          return shipment;
        });

        return {
          ...salesOrder,
          shipments: newShipments,
        };
      });
      const parseShipmentQuantity = parseFloat(row.shipmentQuantity);
      const newShipmentQuantity = {
        ...item.shipmentQuantity,
        [keyAllocation]: row.shipmentQuantity,
      };
      if (parseShipmentQuantity === 0) {
        delete newShipmentQuantity[keyAllocation];
      }

      const totalShipmentsValue = Object.values(newShipmentQuantity).reduce(
        (a, b) => parseInt(a, 10) + parseInt(b, 10),
        0
      );

      newMismatchItem = {
        salesOrder: !!mismatchStatus.salesOrder,
        purchaseOrder: !!mismatchStatus.purchaseOrder,
        shipments:
          parseInt(row.purchaseOrderQuantity) !== parseInt(totalShipmentsValue),
      };
      return {
        ...itemCpy,
        shipmentQuantity: newShipmentQuantity,
        salesOrders: salesOrdersCpy,
        shipmentIds: shipmentIdsCpy,
        mismatchStatus: {
          ...newMismatchItem,
        },
      };
    }

    case itemTableReference.MAIN_SALES_ORDER: {
      let salesOrdersCpy = [...itemCpy.salesOrders];
      let salesOrderPrice = row.salesOrderPrice;
      let newSaleOrderQuantity = row.salesOrderQuantity;
      if (typeof newSaleOrderQuantity !== "object") {
        newSaleOrderQuantity = {
          ...item.salesOrderQuantity,
          [currentSalesOrder.id]: row.salesOrderQuantity,
        };
      }
      if (typeof salesOrderPrice !== "object") {
        salesOrderPrice = {
          ...item.salesOrderPrice,
          [currentSalesOrder.id]: parseFloat(row.salesOrderPrice),
        };
      }
      const newQuantityValue = row.salesOrderQuantity;
      salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
        if (salesOrder.id === currentSalesOrder.id) {
          const allocation = parseFloat(
            newQuantityValue[salesOrder.id] || newQuantityValue
          );

          return {
            ...salesOrder,
            allocation,
          };
        }
        return salesOrder;
      });

      let newMismatchSO = mismatchStatus;
      const mismatchSO =
        parseInt(row.purchaseOrderQuantity) !==
        getTotalAllocation(newSaleOrderQuantity);
      if (mismatchSO) {
        newMismatchSO = {
          purchaseOrder: mismatchSO,
          salesOrder: !!mismatchStatus.salesOrder,
          shipments:
            mismatchStatus.shipments === undefined
              ? true
              : !!mismatchStatus.shipments,
        };
      } else {
        newMismatchSO = {
          purchaseOrder: false,
          salesOrder: false,
          shipments:
            mismatchStatus.shipments === undefined
              ? true
              : !!mismatchStatus.shipments,
        };
      }
      return {
        ...itemCpy,
        salesOrderQuantity: newSaleOrderQuantity,
        salesOrders: salesOrdersCpy,
        salesOrderPrice: salesOrderPrice,
        mismatchStatus: {
          ...newMismatchSO,
        },
      };
    }
    default:
      return itemCpy;
  }
};

export const onChangePODataShipment = ({
  item,
  row,
  parentRow,
  reference,
  currentPurchaseOrder,
}) => {
  let itemCpy = { ...item };
  let shipmentIdsCpy = [...item.shipmentIds];
  const mismatchStatus = row.mismatchStatus || item.mismatchStatus || {};
  let newMismatchItem = mismatchStatus;
  const isAddingItem = parseInt(row.allocation) > 0;
  switch (reference) {
    case itemTableReference.SHIPMENTS: {
      let salesOrdersCpy = [...itemCpy.salesOrders];
      salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
        if (salesOrder.id === parentRow.id) {
          const newData = replaceItemShipment({
            row,
            salesOrder,
            shipmentsIds: shipmentIdsCpy,
            isAddingItem,
          });
          shipmentIdsCpy = newData.newShipmentsIds;
          return {
            ...salesOrder,
            shipments: newData.newSipments,
          };
        }
        return salesOrder;
      });
      let newShipmentQuantity = {
        ...item.shipmentQuantity,
        [row.id]: row.allocation,
      };

      if (!isAddingItem) {
        delete newShipmentQuantity[row.id];
      }
      let parseShipmentQuantity = {};
      shipmentIdsCpy.forEach((id) => {
        if (newShipmentQuantity[id]) {
          parseShipmentQuantity = {
            ...parseShipmentQuantity,
            [id]: newShipmentQuantity[id],
          };
        }
      });
      const totalShipmentsValue = Object.values(parseShipmentQuantity).reduce(
        (a, b) => parseInt(a, 10) + parseInt(b, 10),
        0
      );

      return {
        ...itemCpy,
        salesOrders: salesOrdersCpy,
        shipmentIds: shipmentIdsCpy,
        shipmentQuantity: parseShipmentQuantity,
        purchaseOrderId: itemCpy.purchaseOrderId || currentPurchaseOrder.id,
        mismatchStatus: {
          ...mismatchStatus,
          shipments:
            totalShipmentsValue !== parseInt(itemCpy.purchaseOrderQuantity, 10),
        },
      };
    }
    case itemTableReference.SALES_ORDERS: {
      let salesOrdersCpy = [...itemCpy.salesOrders];
      salesOrdersCpy = salesOrdersCpy.map((salesOrder) => {
        if (salesOrder.id === row.id || salesOrder.id === row.prevId) {
          return {
            ...salesOrder,
            ...row,
          };
        }
        return salesOrder;
      });
      let newSaleOrderQuantity = {
        ...item.salesOrderQuantity,
        [row.id]: parseFloat(row.allocation),
      };

      const newSOQuantity = getTotalAllocation(newSaleOrderQuantity);
      let newMismatchSO = mismatchStatus;
      const mismatchSO =
        parseInt(item.purchaseOrderQuantity) !== parseInt(newSOQuantity);
      if (newMismatchSO) {
        newMismatchSO = {
          purchaseOrder: mismatchSO,
          salesOrder: !!mismatchStatus.salesOrder,
          shipments:
            mismatchStatus.shipments === undefined
              ? true
              : !!mismatchStatus.shipments,
        };
      } else {
        newMismatchSO = {
          purchaseOrder: false,
          salesOrder: false,
          shipments:
            mismatchStatus.shipments === undefined
              ? true
              : !!mismatchStatus.shipments,
        };
      }
      return {
        ...itemCpy,
        salesOrders: salesOrdersCpy,
        salesOrderQuantity: newSaleOrderQuantity,
        mismatchStatus: { ...newMismatchSO },
      };
    }

    case itemTableReference.ITEM:
      const salesOrderQuantity = getTotalAllocation(item.salesOrderQuantity);
      let newShipmentQuantity = {
        ...item.shipmentQuantity,
        [row.id]: row.allocation,
      };
      const mismatchItem =
        parseInt(row.purchaseOrderQuantity) !== parseInt(salesOrderQuantity);
      const totalShipmentsValue = Object.values(newShipmentQuantity).reduce(
        (a, b) => parseInt(a, 10) + parseInt(b, 10),
        0
      );
      if (mismatchItem) {
        newMismatchItem = {
          salesOrder: mismatchItem,
          purchaseOrder: !!mismatchStatus.purchaseOrder,
          shipments:
            parseInt(row.purchaseOrderQuantity) !==
            parseInt(totalShipmentsValue),
        };
      } else {
        newMismatchItem = {
          purchaseOrder: false,
          salesOrder: false,
          shipments:
            parseInt(row.purchaseOrderQuantity) !==
            parseInt(totalShipmentsValue),
        };
      }
      return {
        ...itemCpy,
        ...row,
        mismatchStatus: {
          ...newMismatchItem,
        },
      };

    default:
      return itemCpy;
  }
};

//

export function updateShipmentParams({
  shipmentId,
  purchaseOrder,
  queryParams,
}) {
  if (purchaseOrder && purchaseOrder.id) {
    if (!purchaseOrder.shipmentIds || purchaseOrder.shipmentIds.length === 0) {
      return getDashboardSearchPath({
        ...queryParams,
        shipmentId: "",
      });
    }
    if (!shipmentId) {
      const shipmentIdToAdd = purchaseOrder.shipmentIds[0];
      return getDashboardSearchPath({
        ...queryParams,
        shipmentId: shipmentIdToAdd,
      });
    } else {
      if (!purchaseOrder.shipmentIds.includes(shipmentId)) {
        return getDashboardSearchPath({
          ...queryParams,
          shipmentId: purchaseOrder.shipmentIds[0],
        });
      }
      return "";
    }
  } else {
    return "";
  }
}
