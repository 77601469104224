import {
  GET_DATA,
  ADD_DATA,
  DELETE_DATA,
  UPDATE_DATA,
  GET_DATA_WITH_CHILD,
  ADD_DATA_WITH_CHILD,
  UPDATE_DATA_WITH_CHILD,
  DELETE_DATA_WITH_CHILD,
  UNSUBSCRIBE_CURRENT_CUSTOMER,
  UNSUBSCRIBE_CURRENT_FACTORY,
  UNSUBSCRIBE_CURRENT_SHIPMENT,
  UNSUBSCRIBE_PURCHASE_ORDERS,
  DASHBOARD_BACKDROP,
  UNSUBSCRIBE_ITEMS,
  UNSUBSCRIBE_COLLECTION,
  ACTIVITY_STREAM,
  DELETE_ACTIVITY_STREAM,
  REPORTS_VIEW,
  ACTIVITY_NOTE_DATE,
  QUERY_PARAMS,
  GET_SO_DATA,
  UNSUBSCRIBE_SHIPMENTS,
  UNSUBSCRIBE_ROLE_PERMISSIONS,
  LOAD_NOTIFICATIONS,
  MARK_READ_LOADED_NOTIFICATIONS,
  CLEAN_UNSUBSCRIBE_ITEMS,
  ORDER_DASHBOARD_SETUP,
  CLIENT_IS_OFFLINE,
} from "../actions/types";
import { dbTables, reduxState } from "../api/types/dbTables";
import { FILTER_NOTIFICATION, TYPE_NOTIFICATION } from "../helpers/constants";

const loadedNotifications = {
  [FILTER_NOTIFICATION.ALL]: {
    dateRange: { start: null, end: null },
    data: [],
  },
  [FILTER_NOTIFICATION.DMs]: {
    dateRange: { start: null, end: null },
    data: [],
  },
  [FILTER_NOTIFICATION.AD_HOC_TASK]: {
    dateRange: { start: null, end: null },
    data: [],
  },
  [FILTER_NOTIFICATION.COMPLETE_EARLY]: {
    dateRange: { start: null, end: null },
    data: [],
  },
  [FILTER_NOTIFICATION.COMPLETE_LATE]: {
    dateRange: { start: null, end: null },
    data: [],
  },
};

const INITIAL_STATE = {
  tabId: Math.random().toString(36).substr(2, 9),
  companies: [],
  factories: [],
  users: [],
  customers: [],
  sales_order_tasks: [],
  [dbTables.PURCHASE_ORDER_TASKS]: [],
  [dbTables.PURCHASE_ORDERS]: [],
  [dbTables.SALES_ORDER_DOCUMENTS]: [],
  [dbTables.PURCHASE_ORDER_DOCUMENTS]: [],
  [dbTables.FACTORY_DOCUMENTS]: [],
  [dbTables.CUSTOMER_DOCUMENTS]: [],
  [dbTables.PERMISSIONS]: [],
  [dbTables.NOTIFICATIONS]: [],
  sales_order_tasks_template: [],
  sales_orders: [],
  documents: [],
  document_versions: [],
  sales_order_documents_template: [],
  purchase_order_documents_template: [],
  roles: [],
  company_permission: [],
  permission_groups: [],
  document_users: [],
  imported_data_QB: [],
  api_error_log: [],
  backend_error_log: [],
  api_imported_data: [],
  tags: [],
  feature_flags: [],
  customers_summary: [],
  factories_summary: [],
  [dbTables.SHIPMENTS]: [],
  shipment_documents_template: [],
  shipment_tasks: {},
  shipment_documents: {},
  [reduxState.SALES_ORDER_ACTIVITIES]: {},
  [reduxState.PURCHASE_ORDER_ACTIVITIES]: {},
  [reduxState.SHIPMENT_ACTIVITIES]: {},
  [dbTables.CUSTOM_SETTINGS]: [],
  [dbTables.REPORTS]: [],
  [dbTables.GL_ACCOUNTS]: [],
  [dbTables.AUTOCOMPLETE_LISTS]: [],
  [dbTables.DRAFTS]: [],
  [dbTables.SALES_ORDER_FAVORITES]: [],
  [dbTables.PURCHASE_ORDER_FAVORITES]: [],
  [dbTables.SHIPMENT_FAVORITES]: [],
  [dbTables.REPORT_FAVORITES]: [],
  loadedNotifications: loadedNotifications,
  orderDashboardSetup: {
    salesOrderId: "",
    purchaseOrderId: "",
    shipmentId: "",
  },
  clientIsOffline: false,
};

const dataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DATA:
      return { ...state, [action.table]: action.payload };
    case GET_SO_DATA:
      return {
        ...state,
        [action.table]: {
          ...state[action.table],
          [action.id]: action.payload,
        },
      };

    case ADD_DATA:
      return {
        ...state,
        [action.table]: [...state[action.table], action.payload],
      };
    case UPDATE_DATA:
      const itemIndex = state[action.table].findIndex(
        (item) => item.id === action.payload.id
      );
      const newArray = [...state[action.table]];
      newArray[itemIndex] = action.payload;
      return { ...state, [action.table]: newArray };
    case DELETE_DATA:
      const actionTable = state[action.table];
      if (!actionTable) {
        return state;
      }
      return {
        ...state,
        [action.table]: state[action.table].filter(
          (item) => item.id !== action.payload
        ),
      };

    case GET_DATA_WITH_CHILD:
      return {
        ...state,
        [action.table]: {
          ...state[action.table],
          [action.childKey]: action.payload,
        },
      };
    case ADD_DATA_WITH_CHILD:
      return {
        ...state,
        [action.table]: {
          ...state[action.table],
          [action.childKey]: [
            ...state[action.table][action.childKey],
            action.payload,
          ],
        },
      };
    case UPDATE_DATA_WITH_CHILD:
      const childItemIndex = state[action.table][action.childKey].findIndex(
        (item) => item.id === action.payload.id
      );
      const childNewArray = [...state[action.table][action.childKey]];
      childNewArray[childItemIndex] = action.payload;
      return {
        ...state,
        [action.table]: {
          ...state[action.table],
          [action.childKey]: childNewArray,
        },
      };
    case DELETE_DATA_WITH_CHILD:
      return {
        ...state,
        [action.table]: {
          ...state[action.table],
          [action.childKey]: state[action.table][action.childKey].filter(
            (item) => item.id !== action.payload
          ),
        },
      };
    case DASHBOARD_BACKDROP:
      return { ...state, dashboardBackdrop: action.payload };
    case QUERY_PARAMS: {
      return { ...state, queryParams: action.payload };
    }

    case ACTIVITY_NOTE_DATE:
      return { ...state, [action.table]: action.payload };
    case UNSUBSCRIBE_CURRENT_CUSTOMER:
      return { ...state, unsubscribeCurrentCustomer: action.payload };
    case UNSUBSCRIBE_CURRENT_FACTORY:
      return { ...state, unsubscribeCurrentFactory: action.payload };
    case UNSUBSCRIBE_CURRENT_SHIPMENT:
      return { ...state, unsubscribeCurrentShipment: action.payload };
    case UNSUBSCRIBE_PURCHASE_ORDERS:
      return { ...state, unsubscribePurchaseOrders: action.payload };
    case UNSUBSCRIBE_ROLE_PERMISSIONS:
      return { ...state, unsubscribeRolePermissions: action.payload };
    case UNSUBSCRIBE_SHIPMENTS:
      return {
        ...state,
        unsubscribeShipments: action.payload,
      };

    case UNSUBSCRIBE_ITEMS:
      return {
        ...state,
        unsubscribeItems: {
          ...state.unsubscribeItems,
          ...action.payload,
        },
      };

    case CLEAN_UNSUBSCRIBE_ITEMS:
      return {
        ...state,
        unsubscribeItems: {},
        items: {},
      };

    case UNSUBSCRIBE_COLLECTION: {
      const { reduxStateRefCallback, reduxStateRef, documentId, callback } =
        action.payload;
      const unsubscribeReduxCpy = {
        ...state[reduxStateRefCallback],
      };
      const reduxStateCpy = {
        ...state[reduxStateRef],
      };

      if (unsubscribeReduxCpy) {
        Object.keys(unsubscribeReduxCpy).forEach((key) => {
          if (key !== documentId) {
            unsubscribeReduxCpy[key]();
            delete unsubscribeReduxCpy[key];
            delete reduxStateCpy[key];
          }
        });
      }
      return {
        ...state,
        [reduxStateRefCallback]: {
          ...unsubscribeReduxCpy,
          [documentId]: callback,
        },
        [reduxStateRef]: reduxStateCpy,
      };
    }
    case ACTIVITY_STREAM: {
      const { reduxState } = action;
      const { activities, documentId } = action.payload;
      const stateId = state[reduxState][documentId] || [];
      let activitiesCpy = [...stateId];
      activities.forEach((activity) => {
        const newActivity = activitiesCpy.find(
          (activityCpy) => activityCpy.id === activity.id
        );
        if (!newActivity) {
          activitiesCpy.push(activity);
        }
      });
      return {
        ...state,
        [reduxState]: {
          [documentId]: activitiesCpy,
        },
      };
    }

    case DELETE_ACTIVITY_STREAM: {
      const { payload, table, documentId } = action;
      let activitiesCpy = [...state[table][documentId]];
      activitiesCpy = activitiesCpy.filter(
        (activityCpy) => activityCpy.id !== payload.id
      );
      return {
        ...state,
        [table]: {
          [documentId]: [...activitiesCpy],
        },
      };
    }

    case REPORTS_VIEW: {
      const { payload, table } = action;
      return {
        ...state,
        [table]: {
          ...payload,
        },
      };
    }

    case LOAD_NOTIFICATIONS:
      {
        const { payload, table, isFromListener = false } = action;
        if (isFromListener) {
          let table = "";
          switch (payload.type) {
            case TYPE_NOTIFICATION.ADHOC_TASK:
              table = FILTER_NOTIFICATION.AD_HOC_TASK;
              break;
            case TYPE_NOTIFICATION.COMPLETE_EARLY:
              table = FILTER_NOTIFICATION.COMPLETE_EARLY;
              break;
            case TYPE_NOTIFICATION.COMPLETE_LATE:
              table = FILTER_NOTIFICATION.COMPLETE_LATE;
              break;
            case TYPE_NOTIFICATION.DIRECT_MESSAGE:
              table = FILTER_NOTIFICATION.DMs;
              break;
            default:
              table = FILTER_NOTIFICATION.ALL;
              break;
          }
          console.log("TABLE::: ", table);
          let allNotificationsCpy = [];
          if (
            state.loadedNotifications[FILTER_NOTIFICATION.ALL] &&
            state.loadedNotifications[FILTER_NOTIFICATION.ALL].dateRange
          ) {
            allNotificationsCpy = [
              ...state.loadedNotifications[FILTER_NOTIFICATION.ALL].data,
            ];
            console.log("ALL NOTIFICATIONS CPY::: ", allNotificationsCpy);
            const notificationFound = allNotificationsCpy.find(
              (notification) => notification.id === payload.id
            );
            if (notificationFound) {
              allNotificationsCpy = allNotificationsCpy.map((notification) => {
                if (notification.id === payload.id) {
                  return { ...payload };
                }
                return notification;
              });
            } else {
              allNotificationsCpy.push({ ...payload });
            }
          }

          if (table !== FILTER_NOTIFICATION.ALL) {
            console.log("ENTRO OTRO:: ");
            if (
              state.loadedNotifications[table] &&
              state.loadedNotifications[table].dateRange
            ) {
              let notificationsCpy = [...state.loadedNotifications[table].data];
              console.log("table NOTIFICATIONS CPY::: ", notificationsCpy);
              const notificationFound = notificationsCpy.find(
                (notification) => notification.id === payload.id
              );

              if (notificationFound) {
                notificationsCpy = notificationsCpy.map((notification) => {
                  if (notification.id === payload.id) {
                    return { ...payload };
                  }
                  return notification;
                });
              } else {
                notificationsCpy.push({ ...payload });
              }
              if (state.loadedNotifications[FILTER_NOTIFICATION.ALL]) {
                return {
                  ...state,
                  loadedNotifications: {
                    ...state.loadedNotifications,
                    [table]: {
                      dateRange: state.loadedNotifications[table].dateRange,
                      data: notificationsCpy,
                    },
                    [FILTER_NOTIFICATION.ALL]: {
                      dateRange:
                        state.loadedNotifications[FILTER_NOTIFICATION.ALL]
                          .dateRange,
                      data: allNotificationsCpy,
                    },
                  },
                };
              } else {
                return {
                  ...state,
                  loadedNotifications: {
                    ...state.loadedNotifications,
                    [table]: {
                      dateRange: state.loadedNotifications[table].dateRange,
                      data: notificationsCpy,
                    },
                  },
                };
              }
            }
          } else {
            return {
              ...state,
              loadedNotifications: {
                ...state.loadedNotifications,
                [FILTER_NOTIFICATION.ALL]: {
                  dateRange:
                    state.loadedNotifications[FILTER_NOTIFICATION.ALL]
                      .dateRange,
                  data: allNotificationsCpy,
                },
              },
            };
          }
        } else {
          return {
            ...state,
            loadedNotifications: {
              ...state.loadedNotifications,
              [table]: { ...payload },
            },
          };
        }
      }
      break;

    case MARK_READ_LOADED_NOTIFICATIONS: {
      const loadedNotificationsCpy = { ...state.loadedNotifications };
      Object.keys(loadedNotificationsCpy).forEach((table) => {
        if (loadedNotificationsCpy[table].dateRange.start) {
          let dataCpy = [...loadedNotificationsCpy[table].data];
          dataCpy = dataCpy.map((el) => ({ ...el, read: true }));
          loadedNotificationsCpy[table].data = dataCpy;
        }
      });
      return {
        ...state,
        loadedNotifications: loadedNotificationsCpy,
      };
    }

    case ORDER_DASHBOARD_SETUP: {
      const { payload } = action;
      return {
        ...state,
        orderDashboardSetup: { ...payload },
      };
    }

    case CLIENT_IS_OFFLINE: {
      const { payload } = action;
      return {
        ...state,
        clientIsOffline: payload,
      };
    }
    default:
      return state;
  }
};

export default dataReducer;
