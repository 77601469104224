import { styled } from "@mui/material";
import { HiddenDataIcon } from "../iconSvgPath";

export const HiddenDataIconStyled = styled(HiddenDataIcon)(() => ({
  "&.expandRow": {
    "& .item1": {
      transition: "all 0.3s ease",
      transform: "translateY(0)",
    },
    "& .item3": {
      transition: "all 0.3s ease",
      transform: "translateY(0)",
    },
    "&:active": {
      "& .item1": {
        transform: "translateY(1px)",
      },
      "& .item3": {
        transform: "translateY(-1px)",
      },
      "& path": {
        strokeWidth: 2.5,
      },
    },
  },
}));
