import {
  GENERAL_PERMISSION_VALUE,
  TYPE_OF_FILE,
} from "../../../helpers/constants";
import {
  documentScope,
  getDocumentDB,
  hasDocumentPermission,
} from "../../../helpers/documents";
import { getDashboardSearchPath } from "../../../helpers/helpers";
import { verifyPermission } from "../../../hooks/permissions";

export function onClickMentions({
  event,
  hasFlagPermission,
  activity,
  salesOrders,
  salesOrder,
  setAttentionData,
  companyId,
  currentUser,
  navigate,
  permissionGroupDB,
  purchaseOrders,
  query,
  isAllowed,
  currentSalesOrder
}) {
  if (!hasFlagPermission) {
    return;
  }
  const element = event.target;
  const elType = element.getAttribute("data-mention-type");
  const elId = element.getAttribute("data-mention-id");
  const elScope = element.getAttribute("data-mention-scope");
  const elPOId = element.getAttribute("data-note-po");
  const elShipmentId = element.getAttribute("data-note-shipment");
  const elOriginalId = element.getAttribute("data-original-id");
  const elVersion = element.getAttribute("data-note-version");
  const idVersion = elType !== TYPE_OF_FILE.FOLDER ? `_V${elVersion}` : "";
  if (element && elType && elId) {
    const idScope = elScope === documentScope.SHIPMENT ? "SHIPMENT_" : "";
    const mentionData = {
      id: `list-item-document_${idScope}${elId}${idVersion}`,
      scope: elScope,
      type: elType,
      poId: elPOId,
      shipmentId: elShipmentId,
      mentionId: elId,
      originalId: elOriginalId,
      version: elVersion,
      activity,
    };
    addMentionBackdrop({
      mentionData,
      currentSalesOrder,
      setAttentionData,
      companyId,
      currentUser,
      navigate,
      permissionGroupDB,
      purchaseOrders,
      query,
      isAllowed,
    });
  }
}

const addMentionBackdrop = async ({
  mentionData,
  currentSalesOrder,
  setAttentionData = () => {},
  isAllowed = () => {},
  companyId,
  currentUser,
  permissionGroupDB,
  purchaseOrders,
  query,
  navigate,
}) => {
  const {
    id,
    scope,
    type,
    poId = "",
    shipmentId = "",
    mentionId = "",
    originalId = "",
  } = mentionData;
  const currentType = type === TYPE_OF_FILE.FOLDER ? "Folder" : "File";

  let document = await getDocumentDB({
    salesOrder: currentSalesOrder,
    scope,
    shipmentId,
    documentId: originalId || mentionId,
    poId,
    companyId: companyId,
  });
  if (!document.exists()) {
    setAttentionData({
      isOpen: true,
      text: `This ${
        type === TYPE_OF_FILE.FOLDER ? "folder" : "file"
      } has been deleted`,
    });
    return;
  }
  document = { ...document.data(), scope: document.data().scope || scope };
  const hasPermission = hasDocumentPermission({
    currentUser,
    document,
    permissionGroupDB,
    canSeeAllDocuments: isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT
    ),
  });
  if (!hasPermission) {
    setAttentionData({
      isOpen: true,
      text: `You do not have access to ${currentType} ${document.name}`,
    });
    return;
  }
  newBackdropDocument({
    soId: currentSalesOrder,
    poId,
    shipmentId,
    purchaseOrders,
    setAttentionData,
    document,
    onAddFileId: (ids) => {
      navigate(
        getDashboardSearchPath({
          ...query,
          ...ids,
        })
      );
    },
    id,
    query,
    verifyVendor: (vendorId) =>
      verifyPermission({
        user: currentUser,
        permissionToCheck: [vendorId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      }),
  });
  return;
};

export const newBackdropDocument = ({
  poId,
  shipmentId,
  purchaseOrders,
  setAttentionData,
  document,
  onAddFileId,
  id,
  query,
  verifyVendor,
}) => {
  let idVersion = id.replace("list-item-document_", "");
  idVersion = idVersion.replace("SHIPMENT_", "");
  switch (document.scope) {
    case documentScope.PURCHASE_ORDER:
    case documentScope.PURCHASE_QUOTE:
      const currentPO = purchaseOrders.find((po) => po.id === poId);
      if (!currentPO) {
        setAttentionData({
          isOpen: true,
          text: `You do not have access to purchase order ${document.name}`,
        });
        return;
      }
      const hasPermission = verifyVendor(currentPO.factoryId);
      if (hasPermission) {
        onAddFileId({
          fileId: idVersion,
          purchaseOrderId: poId,
          shipmentId: currentPO.shipmentIds[0] || "",
        });
      } else {
        setAttentionData({
          isOpen: true,
          text: "You do not have permission to see that vendor",
        });
      }

      break;
    case documentScope.SALES_ORDER:
    case documentScope.QUOTE:
      onAddFileId({ fileId: idVersion });
      break;

    default:
      const existInPO =
        purchaseOrders.find(
          (po) =>
            po.id === query.purchaseOrderId &&
            po.shipmentIds.includes(shipmentId)
        ) || purchaseOrders.find((po) => po.shipmentIds.includes(shipmentId));
      if (!existInPO) {
        setAttentionData({
          isOpen: true,
          text: "this file is attached to a shipment that is void and no longer connected to this project",
        });
        return;
      }
      const hasShipmentPermission = verifyVendor(existInPO.factoryId);
      if (hasShipmentPermission) {
        onAddFileId({
          fileId: idVersion,
          purchaseOrderId: existInPO.id,
          shipmentId: shipmentId,
        });
      } else {
        setAttentionData({
          isOpen: true,
          text: "You do not have permission to see that vendor",
        });
      }
      break;
  }
};
