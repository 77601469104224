import React from "react";
import { PendingDividerStyled } from "./styles";

function PendingDivider({
  text,
  style = {},
  containerStyle = {},
  firstLineStyles = {},
  lineNameStyles = {},
}) {
  return (
    <PendingDividerStyled className="container" style={containerStyle}>
      <div className={"firstLine"} style={firstLineStyles}></div>
      <div className={"contentText"} style={style}>
        <span className={"lineName"} style={lineNameStyles}>
          {text}
        </span>
      </div>
    </PendingDividerStyled>
  );
}

export default PendingDivider;
