import React, { useState } from "react";
import { ShipmentOrderIcon } from "../../helpers/iconSvgPath";
import {
  getShipmentBadge,
  getShipmentTooltipLabel,
} from "../../helpers/shipments";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import {
  ShipmentPickerContainerStyled,
  ShipmentPickerPopoverContainerStyled,
} from "./styles";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";

function ShipmentPicker({ selectedShipments = [], onChangeSelectedShipments }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ShipmentPickerContainerStyled className="shipmentPickerContainer">
      <div className={"arrowDropDownCircleWrapper"} onClick={handleClick}>
        <ShipmentOrderIcon width={22} height={22} />
      </div>
      {Boolean(anchorEl) && (
        <ShipmentPickerPopoverContainerStyled
          id="simple-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "shipmentPickerPaperContainer" }}
          className="shipmentPickerPopoverContainer"
        >
          {selectedShipments.map((shipment) => (
            <div key={shipment.id} className="shipment-item-container">
              <CustomCheckbox
                onClick={() =>
                  onChangeSelectedShipments({
                    shipment: { ...shipment, checked: !shipment.checked },
                  })
                }
                checked={shipment.checked}
              />
              {getShipmentBadge({
                shipment,
                styles: { marginBotton: 2 },
                classes: shipmentBadgeClass.medium,
                showToolTip: false,
              })}
              <span style={{ margin: "2px 6px" }}>
                {getShipmentTooltipLabel({
                  shipment: { ...shipment, number: null },
                })}
              </span>
            </div>
          ))}
        </ShipmentPickerPopoverContainerStyled>
      )}
    </ShipmentPickerContainerStyled>
  );
}

export default ShipmentPicker;
