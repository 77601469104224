import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCollectionToFirestore } from "../../actions/DataActions";
import { dbTables } from "../../api/types/dbTables";
import File from "../../api/model/file";
import FoldersStructure from "./FoldersStructure";
import {
  verifyDuplicateTemplateNames,
  convertToTree,
  isTradeDashEmployee,
  getRandomId,
} from "../../helpers/helpers";
import ManagementDocumentsHeader from "./ManagementDocumentsHeader";
import IntlMessages from "../../util/IntlMessages";
import { now } from "moment";
import AttentionModal from "../Modal/AttentionModal";
import { ManagementDocumentPlaceholderStyled } from "./styles";
import { useUser } from "../../hooks/user";
import ReadOnlyModal from "../Modal/ReadOnlyModal";
import { usePermissionGroups } from "../../hooks";
import { TYPE_OF_FILE } from "../../helpers/constants";
import { deleteDoc } from "firebase/firestore";

function ManagementDocuments({
  companyID,
  documents,
  title,
  dbTable,
  onChangeShipment = () => {},
}) {
  const defaultFolderName = "New Folder";
  const defaultFileName = "New File";
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );
  const [documentToDelete, setDocumentToDelete] = useState({});

  const dispatch = useDispatch();
  const addDocument = useCallback((document) =>
    addCollectionToFirestore(
      dbTables.COMPANIES,
      companyID,
      dbTable,
      document
    )(dispatch)
  );
  const [documentTree, setDocumentTree] = useState([]);
  const [createDocument, setCreateDocument] = useState(false);
  const [typeDocument, setTypeDocument] = useState("");
  const [folderName, setFolderName] = useState("");
  const [openReadOnlyModal, setReadOnlyModal] = useState(false);

  const currentUser = useUser();
  const isReadOnly = isTradeDashEmployee(currentUser);

  const permissionGroupsDB = usePermissionGroups({
    companyId: companyID,
    filteringByInactive: true,
  });

  useEffect(() => {
    const currentDocumentTree = convertToTree(documents);
    setDocumentTree(currentDocumentTree);
  }, [documents]);

  function handleAddNewFolder(event) {
    if (isReadOnly) {
      setReadOnlyModal(true);
      return;
    }
    setFolderName(defaultFolderName);
    setCreateDocument(true);
    setTypeDocument(TYPE_OF_FILE.FOLDER);
  }

  function handleSaveFolder(type) {
    const id = getRandomId();
    const name = verifyDuplicateTemplateNames(
      folderName.trim()
        ? folderName
        : type === TYPE_OF_FILE.FOLDER
        ? defaultFolderName
        : defaultFileName,
      documents,
      type
    );
    const path = name;
    const mainFile = true;

    let placeholder = true;
    if (type === TYPE_OF_FILE.FOLDER) {
      placeholder = false;
    }
    const listIndex = documents.length;
    const user = currentUser.id;
    const lastModified = now();
    const lastModifiedDate = now();
    const folder = new File({
      id,
      name,
      path,
      type,
      listIndex,
      mainFile,
      placeholder,
      user,
      lastModified,
      lastModifiedDate,
    });
    addDocument({ ...folder });
    setCreateDocument(false);
    setFolderName(defaultFolderName);
  }

  function handleCancelSaveFolder() {
    setCreateDocument(false);
    setFolderName(defaultFolderName);
  }

  function handleChangeFolderName(event) {
    setFolderName(event.target.value);
  }

  function handleDeleteDocumentChilds(document) {
    document.child.forEach((element) => {
      if (element.type === TYPE_OF_FILE.FOLDER) {
        handleDeleteDocumentChilds(element);
        deleteDoc(element.ref);
      } else {
        deleteDoc(element.ref);
      }
    });
  }

  function handleDeleteFolder(document) {
    console.log("DOCUMENT", document);
    if (document.type !== TYPE_OF_FILE.FOLDER) {
      deleteDoc(document.ref);
    } else {
      if (document.child.length === 0) {
        deleteDoc(document.ref);
      } else {
        handleDeleteDocumentChilds(document);
        deleteDoc(document.ref);
      }
    }
    onCloseModalAttention();
  }

  function handleAddNewFile() {
    if (isReadOnly) {
      setReadOnlyModal(true);
      return;
    }
    setFolderName(defaultFileName);
    setCreateDocument(true);
    setTypeDocument("file");
  }

  function onAddNewFolder(document, currentName, typeDocument) {
    const name = verifyDuplicateTemplateNames(
      currentName,
      document.child,
      typeDocument
    );

    const id = getRandomId();
    let { path } = document;
    path = `${path}/${name}`;
    const folder = new File({
      id,
      name,
      parent: document.name,
      path,
      parentId: document.id,
      type: typeDocument,
      user: currentUser.id,
      lastModified: now(),
      lastModifiedDate: now(),
    });
    addDocument({ ...folder });
  }

  function handleConfirmToDeleteDocument(document) {
    setDocumentToDelete(document);
    setDescriptionModalAttention(
      <React.Fragment>
        Do you want to permanently delete placeholder{" "}
        {document.type === TYPE_OF_FILE.FOLDER ? TYPE_OF_FILE.FOLDER : "file"}{" "}
        <strong>{document.name}</strong>?<br />
        This cannot be undone
      </React.Fragment>
    );
    setOpenModalAttention(true);
  }

  function onCloseModalAttention() {
    setOpenModalAttention(false);
    setDescriptionModalAttention(<React.Fragment></React.Fragment>);
    setDocumentToDelete({});
  }

  return (
    <ManagementDocumentPlaceholderStyled className="managementDocumentContainer">
      {openModalAttention && (
        <AttentionModal
          isOpen={openModalAttention}
          description={descriptionModalAttention}
          title="Attention"
          onClick={() => {
            handleDeleteFolder(documentToDelete);
          }}
          onClose={onCloseModalAttention}
          confirmationText="Yes"
          cancelText="No"
          cancellable={true}
        />
      )}
      {openReadOnlyModal && (
        <ReadOnlyModal
          isOpen={openReadOnlyModal}
          onClick={() => setReadOnlyModal(false)}
          onClose={() => setReadOnlyModal(false)}
        />
      )}
      <ManagementDocumentsHeader
        title={title}
        handleAddNewFolder={handleAddNewFolder}
        handleAddNewFile={handleAddNewFile}
        dbTable={dbTable}
      />
      <div className={"salesOrderHeaderTitles"}>
        <div className={"salesOrderTitleOrder"}>
          <IntlMessages id="components.documenttemplate.managmentdocuments.order" />
        </div>
        <div className={"salesOrderTitleDocumentType"}>
          <IntlMessages id="components.documenttemplate.managmentdocuments.documenttype" />
        </div>
      </div>
      <div className={"folderEstructureListTemplate"}>
        <FoldersStructure
          documents={documentTree ? documentTree : []}
          createDocument={createDocument}
          onChangeShipment={onChangeShipment}
          typeDocument={typeDocument}
          folderName={folderName}
          onChange={handleChangeFolderName}
          handleSaveFolder={handleSaveFolder}
          handleCancelSaveFolder={handleCancelSaveFolder}
          handleDeleteFolder={handleConfirmToDeleteDocument}
          companyID={companyID}
          dbTable={dbTable}
          onAddNewFolder={onAddNewFolder}
          permissionGroupsDB={permissionGroupsDB}
          isReadOnly={isReadOnly}
        />
      </div>
    </ManagementDocumentPlaceholderStyled>
  );
}

export default ManagementDocuments;
