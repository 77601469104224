import React, { useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { PREFIX_ITEM_TABLE, TOOLTIP_TYPE } from "../../helpers/constants";
import {
  AdvertiseIconIcon,
  CopyItemTable,
  DownloadCSVIcon,
  ExpandTableIcon,
  HiddenDataIcon,
} from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";
import { SKUContainerButtonStyled } from "./styles";
import { CloudDownload as CloudDownloadIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SearchLabel from "../Inputs/SearchLabel";
import AttentionModal from "../Modal/AttentionModal";
import { SEARCH_BOX_TYPES } from "../../helpers/orderDashboard";
import { cx } from "@emotion/css";
import { colors } from "../../assets/jss/variables";

const titleText = {
  [PREFIX_ITEM_TABLE.PURCHASE_ORDER]: "Purchase Order Item Table",
  [PREFIX_ITEM_TABLE.SALES_ORDER]: "Sales Order Item Table",
  [PREFIX_ITEM_TABLE.SHIPMENT]: "Shipment Item Table",
};

function ItemTableButtons({
  isModal,
  buttonsStyle,
  prefix,
  isAllOpen,
  onCollapseAll = () => {},
  csvData,
  tableHeader,
  onExpandModal,
  pdfTableHeader,
  handleOnCopyTable = () => {},
  handleOpenSnackBar = () => {},
  idExpandModal,
  cleanSearchBox = false,
  onWriteSearch = () => {},
  handleSearchBox = () => {},
  handleHiddenImages = () => {},
  hasMismatchTag = false,
}) {
  const [attentionModalText, setAttentionModalText] = useState("");
  const containerClass = isModal ? "itemsButtonsModal" : "itemsButtons";

  const copyTable = () => {
    const element = document.getElementById(
      `itemTable${prefix}${idExpandModal}`
    );
    let body = document.body;
    let range;
    let sel;

    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(element);
        sel.addRange(range);
        document.execCommand("Copy");
      } catch (e) {
        range.selectNode(element);
        sel.addRange(range);
        document.execCommand("Copy");
      }
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(element);
      range.select();
      document.execCommand("Copy");
    }
    if (sel) {
      sel.removeAllRanges();
    }
    handleOnCopyTable(true);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function createFile(url) {
    try {
      let response = await fetch(url.replace("http://", "https://"), {
        method: "GET",
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      let data = await response.blob();
      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([data], "test.jpg", metadata);
      const base64 = await toBase64(file);
      return base64;
    } catch (error) {
      console.log("-----", error);
    }
  }

  const exportPDF = async () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = titleText[prefix];
    const headers = [pdfTableHeader.map((header) => header.label)];
    let rawImages = {};
    const pdfData = [];
    for (let i = 0; i < csvData.length; i++) {
      const data = csvData[i];
      const dataContainer = [];
      for (let j = 0; j < pdfTableHeader.length; j++) {
        const header = pdfTableHeader[j];
        const value = data[header.key];
        let contentData = header.type ? `$${value}` : value;
        if (value === "" || !value) {
          contentData = "";
        }
        if (header.key === "image" && value) {
          contentData = `${i}_${j}`;
          const base64 = await createFile(value);
          rawImages = { ...rawImages, [contentData]: base64 };
        }
        const style = header.style || {};
        dataContainer.push({
          content: contentData,
          styles: {
            halign: "center",
            valign: "middle",
            ...style,
          },
        });
      }
      pdfData.push(dataContainer);
    }
    let content = {
      startY: 50,
      head: headers,
      body: pdfData,
      headStyles: {
        fillColor: [202, 224, 252],
        textColor: colors.primaryDark,
        halign: "center",
      },
      columnStyles: {
        1: { minCellWidth: 60, minCellHeight: 60 },
      },
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.05,
      },
      // pageBreak: 'always',
      rowPageBreak: "avoid",
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable({
      ...content,
      didDrawCell: async (data) => {
        if (data.section === "body" && data.column.index === 1) {
          const [text = ""] = data.cell.text;
          if (text !== "") {
            doc.addImage(
              rawImages[text],
              "JPEG",
              data.cell.x + 2,
              data.cell.y + 2,
              50,
              50
            );
          }
        }
      },
    });

    doc.save(`${prefix}ItemTable.pdf`);
  };
  return (
    <SKUContainerButtonStyled
      className={containerClass}
      style={{
        maxWidth: buttonsStyle.maxWidth || "",
      }}
      id={`${prefix}Item-table-buttons`}
    >
      {!!attentionModalText && (
        <AttentionModal
          title="Attention"
          description={attentionModalText}
          isOpen={!!attentionModalText}
          onClick={() => {
            setAttentionModalText("");
          }}
          cancellable={false}
          confirmationText="Ok"
          onClose={() => setAttentionModalText("")}
        />
      )}
      {isModal && (
        <SearchLabel
          returnQuery={(query) => {
            onWriteSearch(query);
          }}
          placeholderOnExpand="Search for item number, description, etc."
          cleanSearchBox={cleanSearchBox}
          onDisableCleanSearchBox={() => handleSearchBox(false)}
          styles={{ marginRight: 18 }}
          searchLabelId="item-table-button-search-box"
          type={SEARCH_BOX_TYPES.SKU_TABLE}
        />
      )}
      <div
        className={cx(prefix, "iconContainer")}
        style={{ display: "none" }}
        onClick={async () => await exportPDF()}
      >
        <TooltipTD
          label="Export to PDF"
          placement="top"
          type={TOOLTIP_TYPE.DEFAULT}
          hasArrow={false}
          popperProps={{}}
          style={{ height: 24, width: 24 }}
        >
          <CloudDownloadIcon className="pdfIcon" />
        </TooltipTD>
      </div>
      {hasMismatchTag && (
        <div
          className={cx(prefix, "iconContainer")}
          onClick={async () => setAttentionModalText(hasMismatchTag)}
        >
          <TooltipTD label={hasMismatchTag}>
            <AdvertiseIconIcon />
          </TooltipTD>
        </div>
      )}
      {prefix === PREFIX_ITEM_TABLE.PURCHASE_ORDER && (
        <div className={cx(prefix, "iconContainer")}>
          <TooltipTD
            label={isAllOpen ? "Collapse all row" : "Expand all row"}
            type={TOOLTIP_TYPE.DEFAULT}
            hasArrow={false}
            popperProps={{}}
            style={{ height: 24, width: 24 }}
          >
            <HiddenDataIcon
              onClick={() => {
                onCollapseAll();
              }}
              svgClass="expandRow"
            />
          </TooltipTD>
        </div>
      )}

      <div className={cx(prefix, "iconContainer")}>
        <TooltipTD
          label="Copy Table"
          placement="top"
          type={TOOLTIP_TYPE.DEFAULT}
          hasArrow={false}
          popperProps={{}}
          style={{ height: 24, width: 24 }}
        >
          <CopyItemTable
            svgClass="copyIcon"
            onClick={() => {
              handleHiddenImages();
              onCollapseAll(true);
              handleOnCopyTable(false);
              setTimeout(() => {
                handleOpenSnackBar(true);
                copyTable();
              }, 200);
            }}
          />
        </TooltipTD>
      </div>

      <div className={cx(prefix, "iconContainer")}>
        <CSVLink
          data={csvData}
          headers={tableHeader}
          filename={`Item Table-${prefix}-${moment().format("MM-DD-YY")}.csv`}
          style={{ height: 24, width: 24 }}
        >
          <TooltipTD
            label="Export to CSV"
            placement="top"
            type={TOOLTIP_TYPE.DEFAULT}
            hasArrow={false}
            popperProps={{}}
            style={{ height: 24, width: 24, display: "flex" }}
          >
            <DownloadCSVIcon svgClass="csvIcon" />
          </TooltipTD>
        </CSVLink>
      </div>

      {!isModal && (
        <div className={cx(prefix, "iconContainer")}>
          <TooltipTD
            label="Expand Table"
            placement="top"
            type={TOOLTIP_TYPE.DEFAULT}
            hasArrow={false}
            popperProps={{}}
            style={{ height: 24, width: 24 }}
          >
            <ExpandTableIcon
              onClick={onExpandModal}
              style={{
                cursor: "pointer",
              }}
              svgClass="expandTableIcon"
            />
          </TooltipTD>
        </div>
      )}
    </SKUContainerButtonStyled>
  );
}

export default ItemTableButtons;
